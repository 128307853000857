import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import { object, func } from 'prop-types';
import { TextArea } from 'shared/styleguide/atoms/Input/TextArea';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import NewButton from 'shared/styleguide/atoms/Buttons/NewButton';
import {
  ErrorText,
  HeadlineText,
} from 'shared/styleguide/typography';
import MDView from 'shared/styleguide/atoms/Markdown/MDView';
import Box from 'shared/styleguide/atoms/Box';
import Checkbox from 'shared/styleguide/atoms/Switches/Checkbox';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

import {
  createReleaseNote, updateReleaseNote, setMode,
} from 'shared/modules/releasenotes/redux/actions';

const validate = (values) => {
  const errors = {};
  if (!values.releaseNote) {
    errors.releaseNote = 'Release Note content is required';
  }

  if (!moment(values.releaseDate, 'YYYY-MM-DD', true).isValid()) {
    errors.releaseDate = 'Invalid Release Date (required)';
  }

  return errors;
};

const formattedDate = (date) => {
  return moment(date).utc().format('YYYY-MM-DD');
};

const EditForm = ({
  releaseNote, releaseNotes, user, createReleaseNote, updateReleaseNote, setMode,
}) => {
  const { releaseNoteDate } = releaseNotes;

  const initial = !releaseNote
    ? (
      {
        releaseNote: '',
        releaseDate: '',
        releaseStatus: false,
      }
    ) : (
      {
        releaseNote: releaseNote.notes,
        releaseDate: formattedDate(releaseNote.releaseDate),
        releaseStatus: releaseNote.status === 'published',
      }
    );

  return (
    <Formik
      initialValues={initial}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validate}
      onSubmit={(values) => {
        if (!releaseNoteDate) {
          createReleaseNote({
            releaseDate: values.releaseDate,
            notes: values.releaseNote,
            initiator: user.user.username,
            status: !values.releaseStatus ? 'draft' : 'published',
          });
        } else {
          updateReleaseNote(
            formattedDate(releaseNoteDate),
            {
              newReleaseDate: values.releaseDate,
              notes: values.releaseNote,
              lastEditedBy: user.user.username,
              status: !values.releaseStatus ? 'draft' : 'published',
            },
          );
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
        errors,
        isSubmitting,
      }) => (
        <form id="editreleasenoteform" onSubmit={handleSubmit}>
          <Box padding="medium">
            <TextField
              placeholder="Release Date"
              helperText="YYYY-MM-DD"
              id="releaseDate"
              name="releaseDate"
              value={values.releaseDate || ''}
              onChange={handleChange}
            />
            {errors
              && <div><ErrorText>{errors.releaseDate}</ErrorText></div>}
            <Checkbox
              label="Published"
              name="status"
              checked={values.releaseStatus || false}
              onCheck={(e) => setFieldValue('releaseStatus', e.target.checked)}
            />
            <TextArea
              label="Release Note"
              helperText="Content (Markdown)"
              placeholder=""
              id="releaseNote"
              name="releaseNote"
              value={values.releaseNote || ''}
              onChange={handleChange}
              maxRows={18}
              rows={18}
            />
            {errors
              && <div><ErrorText>{errors.releaseNote}</ErrorText></div>}
            <Box direction="row" justify="flex-end">
              <NewButton
                variant="contained"
                style={{ marginRight: 5 }}
                label={isSubmitting ? 'Saving...' : 'Save'}
                color="secondary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
              <NewButton
                color="default"
                variant="outlined"
                style={{ marginRight: 5 }}
                label="Reset"
                onClick={() => {
                  resetForm();
                }}
                disabled={isSubmitting}
              />
              <NewButton
                variant="outlined"
                label="Cancel"
                color="default"
                onClick={() => { setMode('view'); }}
                disabled={isSubmitting}
              />
            </Box>
            <Box margin={{ top: 'small', right: 'xsmall' }}>
              <TextLink
                style={{ alignSelf: 'flex-end' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
              >
                Markdown Help
              </TextLink>
            </Box>
          </Box>
          <Box padding="medium">
            <Typography variant="caption" color="textSecondary" gutterBottom>
              Markdown Preview:
            </Typography>
            <Box as={Paper} padding="medium">
              <Box padding={{ bottom: 'medium' }}>
                <HeadlineText>
                  Release Notes for {values.releaseDate}
                </HeadlineText>
              </Box>
              <MDView value={values.releaseNote || ''} />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

EditForm.propTypes = {
  createReleaseNote: func,
  releaseNote: object,
  releaseNotes: object,
  setMode: func,
  updateReleaseNote: func,
  user: object,
};

const mapStateToProps = (state) => {
  return {
    releaseNotes: state.releaseNotes,
    user: state.user,
  };
};

const mapDispatchToProps = {
  createReleaseNote, updateReleaseNote, setMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
