import React, { useState } from 'react';
import { object } from 'prop-types';
import {
  Accordion, AccordionActions, AccordionDetails, AccordionSummary, Typography,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import Box from 'shared/styleguide/atoms/Box';
import TeamLink from 'shared/modules/team/Link';
import Loading from 'shared/styleguide/atoms/Loading';
import { consolidateTeamPermissions } from 'shared/modules/team/redux/utils';
import { useGetAccountCollaboratorsQuery } from 'shared/modules/team/redux/query';
import { remMapper } from 'shared/styleguide/theme/spacing';

export const AdminInfo = ({ account }) => {
  const [expanded, setExpanded] = useState(false);
  const {
    data: collaborators, error, isLoading, isSuccess,
  } = useGetAccountCollaboratorsQuery(account.id);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      css={(theme) => ({ borderLeft: `8px solid ${theme.color.green}`, marginBottom: remMapper('medium') })}
    >
      <AccordionSummary size="small" expandIcon={<ExpandMoreOutlined />}>
        <Typography variant="body1">View Collaborators</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {
          isLoading && <Loading />
        }
        {
          isSuccess && (
            <div>
              <Box margin={{ bottom: 'xsmall' }}>
                {
                    (collaborators).map((c) => (
                      <Typography color="textPrimary" variant="body1" key={c.sourceId}>
                        {c.accessedByName} - <Typography color="textSecondary" variant="inherit" component="span"><strong>{c.accessedByEmail}</strong></Typography>
                      </Typography>
                    ))
                  }
              </Box>

            </div>
          )
          }
      </AccordionDetails>
      <AccordionActions>
        <TeamLink>Manage Collaborators</TeamLink>
      </AccordionActions>
    </Accordion>
  );
};

AdminInfo.propTypes = {
  account: object,
};

AdminInfo.displayName = 'AdminInfo';

export default AdminInfo;
