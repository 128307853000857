import makeApiAction from 'shared/utils/redux/apiActions';

export const CSR_CREATE_REQUEST = 'CSR_CREATE_REQUEST';
export const CSR_CREATE_SUCCESS = 'CSR_CREATE_SUCCESS';
export const CSR_CREATE_FAILURE = 'CSR_CREATE_FAILURE';
export const CSR_RESET = 'CSR_RESET';

const createCSR = (csr) => makeApiAction(
  ({ api }) => api().post('/ssl/csrs', csr),
  CSR_CREATE_REQUEST,
  CSR_CREATE_SUCCESS,
  CSR_CREATE_FAILURE,
  {},
  true,
);

function shouldCreateCSR(state) {
  const { csr } = state;

  return !csr.isFetching;
}

export function createCSRAction(csr) {
  return (dispatch, getState) => {
    if (shouldCreateCSR(getState(), csr)) {
      return dispatch(createCSR(csr));
    }

    return null;
  };
}

export function reset() {
  return {
    type: CSR_RESET,
  };
}
