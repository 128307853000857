import qs from 'qs';

import { validSchemeRegex } from '../constants';

const parse = require('url-parse');

export const from = (matches) => {
  const matchDisplay = {};

  let queryCounter = 0;
  matches.forEach((match) => {
    const defaultValues = {
      value: match.value,
      compare: match.compare || 'equals',
    };

    if (match.field === 'argument') {
      if (!matchDisplay.query) {
        matchDisplay.query = [];
      }

      matchDisplay.query[queryCounter] = {
        key: match.argName,
        ...defaultValues,
      };
      queryCounter += 1;
    } else {
      const field = match.field === 'normalizedPath' ? 'path' : match.field;
      matchDisplay[field] = {
        ...defaultValues,
      };
    }
  });

  return matchDisplay;
};

export const to = (rewrites) => {
  const rewriteDisplay = {};
  let queryMode = 'update';
  const argRewrites = [];
  rewrites.forEach((rewrite) => {
    if (rewrite['arg-key'] || rewrite['arg-value']) {
      argRewrites.push(rewrite);
    } else {
      Object.entries(rewrite).forEach(([property, value]) => {
        if (['scheme', 'path', 'host'].includes(property)) {
          rewriteDisplay[property] = {
            value,
          };
        }

        if (property === 'args') {
          // null values may exist because of "replace-all" rewrite
          // but do not need to be accounted for in UI
          const queries = Object.entries(value)
            .filter(([_, val]) => val !== null)
            .map(([key, val]) => {
              return {
                key,
                value: val,
              };
            });
          rewriteDisplay.query = [...queries];
          queryMode = 'replace-all';
        }
      });
    }
  });

  if (argRewrites.length > 0) {
    const queries = argRewrites.map((rewrite) => {
      return {
        key: rewrite['arg-key'],
        value: rewrite['arg-value'],
      };
    });

    rewriteDisplay.query = [...queries];
    queryMode = 'update';
  }

  return [rewriteDisplay, queryMode];
};

export const fieldValue = (field) => {
  const value = field.value || '';
  switch (field.compare) {
    case 'starts-with':
      return `(${value})*`;
    case 'ends-with':
      return `*(${value})`;
    case 'contains':
      return `*(${value})*`;
    default:
      return value;
  }
};

export const fieldKey = (field) => {
  const key = field.key || '';
  switch (field.compare) {
    case 'exists':
      return `(${key})`;
    case 'empty':
      return `!(${key})`;
    default:
      return key;
  }
};

export const constructFromUrl = (from) => {
  const {
    scheme, host, path, query,
  } = from;

  const queryParts = [];
  if (query) {
    query.filter((q) => q.key).forEach((q) => {
      if (['exists', 'empty'].includes(q.compare)) {
        queryParts.push(fieldKey(q));
      } else {
        queryParts.push(`${q.key}=${fieldValue(q)}`);
      }
    });
  }

  let url = '';

  if (scheme?.value) {
    url += `${fieldValue(scheme)}://`;
  }

  if (host?.value) {
    url += fieldValue(host);
  } else {
    url += (scheme?.value && (path?.value || queryParts.length)) ? '[request domain]' : '';
  }

  if (path?.value) {
    url += fieldValue(path);
  }

  if (queryParts?.length) {
    url += `?${queryParts.join('&')}`;
  }

  return url;
};

export const constructToUrl = (from, to) => {
  const {
    scheme, host, path, query,
  } = to;

  const queries = {};
  if (query) {
    query.filter((q) => q.key).forEach((q) => {
      queries[q.key] = q.value;
    });
  }

  let url = '';

  if (scheme?.value) {
    url += `${scheme.value}://`;
  }

  if (host?.value) {
    url += fieldValue(host);
  } else {
    url += (scheme?.value && !from.host?.value && (path?.value || Object.keys(queries)?.length) ? '[request domain]' : from.host?.value) || '';
  }

  if (path?.value) {
    url += fieldValue(path);
  } else {
    url += from.path?.value || '';
  }

  if (Object.keys(queries)?.length) {
    url += qs.stringify({ ...queries }, { addQueryPrefix: true, encode: false });
  }

  return url;
};

export const getCurrentOption = (options, value, initial) => {
  return options.find((option) => option.value === (value || initial)) || '';
};

export const createMatches = (from) => {
  const matches = [];

  Object.entries(from)
    .forEach(([field, value]) => {
      const match = {};
      if (field === 'query') {
        value
          .filter((query) => query.key)
          .forEach((query) => {
            const queryMatch = {};
            queryMatch.field = 'argument';
            queryMatch.argName = query.key.trim();

            if (query.value) {
              queryMatch.value = query.value.trim();
            }

            if (query.compare) {
              queryMatch.compare = query.compare;
            }

            matches.push(queryMatch);
          });
      } else if (value.value) {
        match.field = field === 'path' ? 'normalizedPath' : field;

        const fieldValue = field === 'host' ? value.value.toLowerCase() : value.value;
        match.value = fieldValue.trim();

        if (value.compare) {
          match.compare = value.compare;
        }

        matches.push(match);
      }
    });

  return matches;
};

export const createRuleRewrites = (from, to, queryMode) => {
  const mainRewrite = {
    action: 'set-redirect',
  };

  const queryRewrites = [];

  Object.entries(to).forEach(([field, value]) => {
    if (field !== 'query' && value.value) {
      const fieldValue = field === 'host' ? value.value.toLowerCase() : value.value;
      mainRewrite[field] = fieldValue.trim();
    }

    if (field === 'query') {
      const queryValues = to.query.filter((query) => query.key && query.value);

      if (queryMode === 'replace-all') {
        const queries = {};
        if (queryValues.length === 0) {
          // use nulled "from" keys, otherwise gw2 strips the args object
          from.query.forEach((query) => {
            queries[query.key] = null;
          });
        } else {
          queryValues.forEach((query) => {
            queries[query.key.trim()] = query.value.trim();
          });
        }

        // add args object to single rewrite
        mainRewrite.args = queries;
      } else if (queryMode === 'update') {
        // create a separate action for each
        queryValues.forEach((query) => {
          const rewrite = {};
          rewrite.action = 'set-redirect';
          rewrite['arg-key'] = query.key.trim();
          rewrite['arg-value'] = query.value.trim();
          queryRewrites.push(rewrite);
        });
      }
    }
  });

  return [mainRewrite, ...queryRewrites];
};

export const parseValues = (simpleValues, values) => {
  const decodedSimpleValues = {
    from: decodeURIComponent(simpleValues.from),
    to: decodeURIComponent(simpleValues.to),
  };
  // check for scheme-only (does not parse correctly)
  const fromSchemeMatch = decodedSimpleValues.from.match(validSchemeRegex);
  const toSchemeMatch = decodedSimpleValues.to.match(validSchemeRegex);

  const fromScheme = fromSchemeMatch?.[1] || null;
  const toScheme = toSchemeMatch?.[1] || null;

  const parsedPaths = {
    from: fromScheme ? { protocol: fromScheme } : parse(decodedSimpleValues.from, {}),
    to: toScheme ? { protocol: toScheme } : parse(decodedSimpleValues.to, {}),
  };

  const newValues = { ...values };

  // if the path includes other url parts, we can mutate, but only if the compare method is "equals" (default)
  // otherwise it's impossible to know how to set individiual compare methods

  Object.entries(parsedPaths).forEach(([type, parsedPath]) => {
    if (parsedPath.host) {
      newValues[type].host = {
        value: parsedPath.host,
      };
    }

    if (parsedPath.protocol) {
      newValues[type].scheme = {
        value: parsedPath.protocol.replace(':', ''), // remove ':'
      };
    }

    const query = qs.parse(parsedPath.query, { ignoreQueryPrefix: true });

    if (Object.keys(query).length) {
      const queries = [];
      Object.entries(query)
        .forEach(([key, val], index) => {
          queries[index] = {
            key,
            value: val,
          };
        });
      newValues[type].query = queries;
    }

    newValues[type].path = {
      value: parsedPath.pathname,
    };

    if (!parsedPath.protocol && !parsedPath.host) {
      // check if pathname contains domain
      const domainRegex = new RegExp(/^[a-z0-9-.]+\.[a-z0-9-]+\/?$/); // sub.domain.com(/)
      const domainAndPathRegex = new RegExp(/^[a-z0-9-.]+\.[a-z0-9-]+\/.+$/); // sub.domain.com/somepath

      if (parsedPath.pathname.match(domainRegex)) {
        newValues[type].host = {
          value: parsedPath.pathname.replace('/', ''), // remove trailing slash on domain
        };

        newValues[type].path = {
          value: null,
        };
      }

      if (parsedPath.pathname.match(domainAndPathRegex)) {
        const parts = parsedPath.pathname.split(/\/(.+)/);
        newValues[type].host = {
          value: parts[0],
        };
        newValues[type].path = {
          value: `/${parts[1]}`,
        };
      }
    }
  });

  return newValues;
};
