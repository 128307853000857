import React, { Fragment } from 'react';
import {
  object, func, number, bool, string, array,
} from 'prop-types';
import {
  Typography,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import CircleCheck from 'shared/styleguide/Icons/CheckCircleOutline';
import { ErrorText } from 'shared/styleguide/typography';

import ChangeDiskSize from '../ChangeSize';
import DiskJob from './DiskJob';

export const DiskModifications = ({
  accountId,
  planSize,
  isUpgradable,
  onChangeCommitted,
  onGetDiskUpgradeJobStatus,
  onChange,
  newSize,
  purchaseSuccess,
  diskJob,
  diskAddonPurchase,
  planTotalSize,
  showDiskChange,
  onShowDiskChange,
  errors,
  modificationType,
  recentDiskJobs,
}) => {
  const showDiskChangeSection = !purchaseSuccess && !diskJob && !diskAddonPurchase.data?.jobId;

  // simple upgrade purchase and upgrade in progress or waiting for job from pusher
  const hasSimpleUpgradePurchaseOrJob = (diskJob || diskAddonPurchase.data?.jobId);

  const hasTicketJobPurchase = purchaseSuccess && !diskJob && diskAddonPurchase.data?.ticketId;

  return (
    <Fragment>
      {
        hasTicketJobPurchase
        && (
          <Box
            column
            align="center"
            justify="flex-start"
          >
            <CircleCheck
              style={{
                width: 72,
                height: 72,
              }}
              color="secondary"
            />
            <Box
              padding="medium"
            >
              <Box margin={{ bottom: 'xsmall' }}>
                <Typography variant="h5" component="p" align="center">
                  {diskAddonPurchase.data.solution}
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      }
      {
        hasSimpleUpgradePurchaseOrJob
        && (
          <DiskJob
            diskJob={diskJob}
            modificationType={modificationType}
            newSize={newSize}
            onGetDiskUpgradeJobStatus={onGetDiskUpgradeJobStatus}
          />
        )
      }
      {
        errors
        && (
          <Box align="center" margin={{ bottom: 'small' }} style={{ width: '100%' }}>
            <ErrorText>{errors}</ErrorText>
          </Box>
        )
      }
      {
        showDiskChangeSection
        && (
          <ChangeDiskSize
            accountId={accountId}
            planSize={planSize}
            planTotalSize={planTotalSize}
            isUpgradable={isUpgradable}
            newSize={newSize}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            onCancel={onShowDiskChange}
            recentDiskJobs={recentDiskJobs}
            showDiskChange={showDiskChange}
            onShowDiskChange={onShowDiskChange}
          />

        )
      }
    </Fragment>
  );
};

DiskModifications.propTypes = {
  accountId: number,
  diskAddonPurchase: object,
  diskJob: object,
  errors: string,
  isUpgradable: bool,
  modificationType: string,
  newSize: number,
  onCancel: func.isRequired,
  onChange: func.isRequired,
  onChangeCommitted: func.isRequired,
  onGetDiskUpgradeJobStatus: func.isRequired,
  onShowDiskChange: func.isRequired,
  planSize: number,
  planTotalSize: number,
  purchaseSuccess: bool,
  recentDiskJobs: array,
  showDiskChange: bool,
  showUpgradeStatus: bool,
  sortedMounts: array,
  updatedAddons: array,
};

export default DiskModifications;
