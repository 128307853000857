import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Divider } from '@material-ui/core';
import { object } from 'prop-types';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Card from 'shared/styleguide/atoms/Card/Card';
import DashboardCardHeading from 'shared/modules/dashboard/components/DashboardCardHeading';
import Box from 'shared/styleguide/atoms/Box';

export const CollabAccounts = ({ account, permissions }) => {
  return (
    <Fragment>
      <DashboardCardHeading>Accounts</DashboardCardHeading>
      <Box as={Card} margin={{ bottom: 'large' }} padding="medium">
        <Box css={{ width: '100%' }}>
          <Typography variant="body1">You are a collaborator on the following accounts:
          </Typography>
          <Box padding={{ left: 'medium' }} margin={{ top: 'xsmall' }}>
            {
              permissions.canAccessAccounts
                // eslint-disable-next-line eqeqeq
                .filter((a) => a.targetId != account.id)
                .map((a) => {
                  return (
                    <Box key={a.targetId} direction="column" gap="xsmall">
                      <Link to={`/account/${a.targetId}`}>
                        <Typography variant="body1" component="div">
                          {a.accessToName}
                          {!!a.accessToCompany && ` (${a.accessToCompany})`}
                        </Typography>
                      </Link>
                    </Box>
                  );
                })
            }
          </Box>
          {
            account.isCollaborator
            && (
              <Box margin={{ top: 'small' }} css={{ width: '100%' }}>
                <Divider />
                <Box margin={{ top: 'small' }} direction="row">
                  <Typography variant="body1">Host your own WordPress websites at Pagely!
                  </Typography>
                </Box>
                <Box margin={{ top: 'xsmall' }} direction="row">
                  <Button
                    component={Link}
                    variant="contained"
                    to={{
                      pathname: '/signup',
                      state: {
                        user: account,
                      },
                    }}
                  >
                    Choose A Hosting Plan
                  </Button>
                </Box>
              </Box>
            )
          }
        </Box>
      </Box>
    </Fragment>
  );
};

CollabAccounts.propTypes = {
  account: object.isRequired,
  permissions: object.isRequired,
};

export default CollabAccounts;
