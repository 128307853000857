/* eslint-disable react/display-name */
/* eslint react/no-multi-comp: "off", react/prop-types: "off" */
/* eslint-disable no-underscore-dangle */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EditIcon from '@material-ui/icons/Edit';
import {
  CheckCircle, AddCircleOutline, RemoveCircleOutline, Warning,
} from '@material-ui/icons';
import { Typography, IconButton, useTheme } from '@material-ui/core';

import { useHistory } from 'react-router';
import { arrayOfPropTypes, propTypesNonRequired as linkPropTypes } from 'shared/modules/ssl/models/domainCertLink/domainCertLink';
import ReactTable from 'shared/styleguide/organisms/ReactTable';

import AppLink from 'shared/modules/apps/Link';
import {
  activateDomainCert,
  deactivateDomainCert,
} from 'shared/modules/ssl/redux/certDomainLink/actions';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Box from 'shared/styleguide/atoms/Box';
import DestructiveAction from 'shared/styleguide/molecules/DestructiveAction';
import LoadingCard from 'shared/styleguide/molecules/LoadingCard';

export const DomainsList = (props) => {
  const {
    apps, match, domains, link,
  } = props;

  const history = useHistory();
  const theme = useTheme();

  // why push history instead of link?
  const editConfig = (match, original) => history.push(`/account/${match.params.accountID}/ssl/cert/${match.params.certID}/app/${original.appId}/domain/${original.aliasId}`); // eslint-disable-line max-len

  const renderToggle = (link) => {
    let Icon;
    let color;

    if (link.active) {
      return (
        <DestructiveAction
          icon={<RemoveCircleOutline />}
          title="Deactivate"
          explainerText="Are you sure you want to deactivate the certificate for this domain?"
          onConfirm={() => {
            props.deactivate(link.certId, link.appId, link.aliasId);
          }}
        >
          Deactivate
        </DestructiveAction>
      );
    } else {
      Icon = AddCircleOutline;
      color = theme.palette.success.main;

      return (
        <IconButton
          key="toggle"
          onClick={() => {
            props.activate(link.certId, link.appId, link.aliasId);
          }}
          title="Activate"
          disabled={link.status === 'loading'}
          size="small"
        >
          <Icon
            css={{ color }}
            color="inherit"
          />
        </IconButton>
      );
    }
  };

  const columns = [
    {
      Header: 'Domain',
      accessor: 'domain',
      Cell: ({ row: { original } }) => {
        return (
          <Box direction="row" align="center">
            <CheckCircle
              color="secondary"
              style={{
                color: original.active ? theme.palette.secondary.main : undefined,
              }}
            />
            <TextLink
              to={`/account/${match.params.accountID}/ssl/cert/${match.params.certID}/app/${original.appId}/domain/${original.aliasId}`}
              title={original.domain}
            >
              {original.domain}
            </TextLink>
          </Box>
        );
      },
      props: {
        css: {
          maxWidth: 350,
        },
      },
    },
    {
      Header: 'App',
      accessor: 'appId',
      Cell: ({ row: { original } }) => {
        const app = apps.reduce((current, app) => (app.id === original.appId ? app : current));
        return (<AppLink appId={app.id} subsection="domains">{app.label || app.name}</AppLink>);
      },
    },
    {
      Header: (<span>Redirect Non-SSL</span>),
      accessor: 'redirect',
      Cell: ({ row: { original: { redirect } } }) => (
        <span>
          {redirect ? 'yes' : 'no'}
        </span>
      ),
    },
    {
      Header: 'HTTP2',
      accessor: 'http2',
      Cell: ({ row: { original: { http2 } } }) => (
        <span>
          {http2 ? 'yes' : 'no'}
        </span>
      ),
    },
    {
      Header: 'TLS Cipher',
      id: 'TLS Cipher',
      accessor: (row) => [row.minTlsVersion, row.tlsCipher],
      Cell: ({ row: { original: { tlsCipher } } }) => {
        const versions = {
          'legacy': '1.0+',
          'modern': '1.2+',
          'edge': '1.3+',
        };

        let cipher = tlsCipher || 'legacy';
        if (tlsCipher === 'Compatible') {
          cipher = 'legacy';
        }
        if (cipher === 'Modern') {
          cipher = 'modern';
        }

        return (
          <Box row align="center">
            {
              cipher === 'legacy' && (
                <Warning fontSize="small" css={(theme) => ({ color: theme.palette.warning.main })} />
              )
            }
            <Typography component="span">
              {`${cipher}/${versions[cipher]}`}
            </Typography>
          </Box>
        );
      },
    },
    {
      Header: 'HSTS',
      accessor: 'hstsTimeout',
      width: 100,
      Cell: ({ row: { original: { hstsTimeout } } }) => {
        if (!hstsTimeout) {
          return <span>disabled</span>;
        }

        switch (hstsTimeout) {
          case 300:
            return <span>5 Minutes</span>;
          case 3600:
            return <span>1 Hour</span>;
          case 2592000:
            return <span>30 Days</span>;
          case 15768000:
            return <span>6 Months</span>;
          case 31536000:
            return <span>1 Year</span>;
          default:
            return <span>{`${hstsTimeout} Seconds`}</span>;
        }
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 40,
      props: {
        align: 'center',
      },
      Cell: ({ row: { original } }) => {
        return (
          <div css={{ marginRight: 10 }}>
            <IconButton
              key="edit"
              onClick={() => editConfig(match, original)}
              title="Edit"
              disabled={props.link.status === 'loading'}
              size="small"
            >
              <EditIcon />
            </IconButton>
            {renderToggle(original)}
          </div>
        );
      },
    },
  ];

  if (apps.length === 0) {
    return null;
  }

  if (link.status === 'loading') {
    return <LoadingCard />;
  }

  return (
    <Fragment>
      <ReactTable
        data={domains}
        columns={columns}
        sort
        paginate
      />
    </Fragment>
  );
};

DomainsList.propTypes = {
  activate: PropTypes.func.isRequired,
  apps: PropTypes.array,
  deactivate: PropTypes.func.isRequired,
  domains: arrayOfPropTypes,
  link: PropTypes.shape(linkPropTypes),
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  apps: state.apps.data,
  link: state.certDomainLink,
  domains: state.certDomains.domains,
});
const mapDispatchToProps = {
  activate: activateDomainCert,
  deactivate: deactivateDomainCert,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainsList);
