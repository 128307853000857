import React from 'react';
import {
  number, array, bool,
} from 'prop-types';

import {
  Typography,
} from '@material-ui/core';

import Layout from 'shared/styleguide/molecules/Layout';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';
import * as diskPropTypes from 'shared/modules/analytics/models/propTypes';

import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import InfoText from 'shared/styleguide/molecules/InfoText';
import { filterOutCustomMounts } from './diskUtils';

import DiskPieByServer from './Graphs/DiskPieByServer';
import DiskLineByVolume from './Graphs/DiskLineByVolume';
import PlanSummary from './PlanSummary';

const DiskSummary = (props) => {
  const {
    isUpgradable,
    mounts,
    accountId,
    mountUsage,
  } = props;
  if (mountUsage.status === 'success' && mountUsage.data.length === 0) {
    return (
      <Box margin={{ top: 'large' }} style={{ width: '100%' }}>
        <Empty>No Disk data available.</Empty>
      </Box>
    );
  }

  const filteredMounts = filterOutCustomMounts(mounts);

  return (
    <Layout title="Disk">
      <ErrorBoundary>
        <Box margin={{ bottom: 'mediumLarge' }}>
          <PlanSummary
            {...props}
          />
        </Box>
      </ErrorBoundary>
      <Box margin={{ bottom: 'mediumLarge' }}>
        <Typography variant="h3" gutterBottom>Disk Usage Overview</Typography>
        <InfoText>
          Click graphs to see more details in&nbsp;
          <TextLink to={`/account/${accountId}/analytics`}>
            PressFormance
          </TextLink>. Usage data is updated every 6 hours.
        </InfoText>
        {
          !isUpgradable && (
            <InfoText type="warning" noMargin>
              This account appears to have a custom configuration, data displayed may not be accurate.
            </InfoText>
          )
        }
      </Box>
      <Box margin={{ bottom: 'mediumLarge' }}>
        {
          Object.values(filteredMounts)
            .sort((a, b) => {
              return a.petname >= b.petname ? 1 : -1;
            })
            .map((server) => {
              return (
                <DiskPieByServer
                  key={`${server.petname}-apps`}
                  accountId={accountId}
                  serverName={server.petname}
                  multiServer={Object.keys(filteredMounts).length > 1}
                />
              );
            })
        }
      </Box>
      {
        Boolean(mountUsage.data?.series.length) && (
          <DiskLineByVolume
            accountId={accountId}
            diskUsage={mountUsage}
            mounts={mounts}
          />
        )
      }
    </Layout>
  );
};

DiskSummary.propTypes = {
  accountId: number,
  hasPermissionToChangeDisk: bool,
  isUpgradable: bool,
  mounts: array,
  mountUsage: diskPropTypes.usage,
};

export default DiskSummary;
