import styled from '@emotion/styled';

import TextField from '@material-ui/core/TextField';

const NarrowTextField = styled(TextField)`
  width: 96px;
  position: relative;
  top: -2px;
  input {
    letter-spacing: 0.9px;
  }
`;

export default NarrowTextField;
