import React, { useEffect, useState } from 'react';
import { array, object, func } from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import Empty from 'shared/styleguide/atoms/Empty';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import { fetchAccessControlForApp, fetchGeoIdentifiers } from '../../redux/accessControl/actions';
import CustomConfigDescription from '../../CustomConfigDescription';
import FormContent from './FormContent';

export const AccessControls = ({
  app, getAccessList, access, customConfig, fetchGeoIdentifiers, __storybookMocks,
}) => {
  const [wizardOpen, setWizardOpen] = useState(false);
  const [locales, setLocales] = useState(__storybookMocks?.locales || []);

  const fetchGeoOptions = async () => {
    const response = await fetchGeoIdentifiers();
    const locales = response.data.map((locale) => {
      return {
        ...locale,
        value: locale.id,
      };
    });
    setLocales([...locales]);
  };

  const handleSetWizardOpen = (value) => {
    setWizardOpen(value);
  };

  useEffect(() => {
    // if access.params.appId !== our app, or its pristine
    if (access.status === 'pristine' || access.params.appId !== app.data.id) {
      getAccessList(app.data.id);
    }
  }, [access.status, access.params.appId, app.data.id, getAccessList]);

  useEffect(() => {
    if (!locales?.length) {
      fetchGeoOptions();
    }
  }, []);

  if (['loading', 'pristine'].includes(access.status) || !locales.length) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (access.status === 'failed' && access.statusCode !== 422) {
    return (
      <div>
        <Empty>
          {access.errMessage || 'An error occurred.'}
        </Empty>
      </div>
    );
  }

  const existingRules = access.data.map((a) => {
    return `${a.path}.${a.match}`;
  });

  return (
    <div>
      <CustomConfigDescription customConfig={customConfig} />

      <Box direction="row" justify="space-between" align="center" margin={{ bottom: 'small' }}>
        <Typography variant="h3">Access Control</Typography>
        {
          !wizardOpen && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleSetWizardOpen(true)}
            >
              New Access Rule
            </Button>
          )
        }
      </Box>
      {
        wizardOpen && (
          <FormContent
            appId={app.data.id}
            locales={locales}
            mode="create"
            onSetWizardOpen={handleSetWizardOpen}
            existingRules={existingRules}
          />
        )
      }
      {
        access.data.length === 0
          ? (
            <Empty>
              There are no Access Control rules yet.
            </Empty>
          ) : (
            access.data.map((record) => {
              return (
                <FormContent
                  key={record.idKey}
                  appId={app.data.id}
                  locales={locales}
                  record={record}
                  mode="edit"
                />
              );
            })
          )
      }
    </div>
  );
};

AccessControls.propTypes = {
  access: object.isRequired,
  app: object.isRequired,
  customConfig: array,
  fetchGeoIdentifiers: func.isRequired,
  getAccessList: func.isRequired,
};

export default connect(
  (state) => {
    return ({
      app: state.app.app,
      access: state.ares.access,
    });
  },
  {
    fetchGeoIdentifiers,
    getAccessList: fetchAccessControlForApp,
  },
)(AccessControls);
