/* eslint-disable no-param-reassign */
import initializeBugsnag, { mockClient } from './bugsnag';

let client = null;

if (!client) {
  if (process.env.NODE_ENV === 'test' || process.env.STORYBOOK_RUNNING) {
    client = mockClient;
  } else {
    client = initializeBugsnag();
  }
}

const notifyOptions = (options) => (event) => {
  let meta = {};

  if (options.context) {
    event.context = options.context;
  }

  if (options.user) {
    event.setUser(options.user);
  }

  if (options.request) {
    meta = {
      statusCode: options.request.err?.statusCode,
      message: options.request.err?.message,
      fullError: JSON.stringify(options.request.err, null, 2),
    };
  }

  if (options.metaData) {
    meta = {
      ...meta,
      ...options.metaData,
    };
  }

  if (Object.keys(meta).length) {
    event.addMetadata('error', meta);
  }

  if (options.severity) {
    event.severity = options.severity;
  }

  if (options.unhandled) {
    event.unhandled = options.unhandled;
  }
};

export default {
  error: (error, options) => {
    if (process.env.NODE_ENV === 'production') {
      client.notify(error, options ?? notifyOptions(options));
    } else if (process.env.NODE_ENV !== 'test' && !process.env.STORYBOOK_RUNNING) {
      // eslint-disable-next-line
      console.error('📝', error, options);
    }
  },
  log: (...args) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('BugSnag 🔥', ...args);
    } else {
      // if we want to do something else on production one day, we can.
      client.leaveBreadcrumb(...args);
    }
  },
  client,
};
