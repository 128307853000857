import React, { useState, useRef } from 'react';
import {
  array, func, number, string,
} from 'prop-types';
import { css } from '@emotion/react';
import { connect } from 'react-redux';
import {
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { consolidateErrors } from 'shared/utils/validation';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { remMapper } from 'shared/styleguide/theme/spacing';

import { setHeaders as createOrUpdateList } from '../../redux/headers/actions';

import AccessHeadersDetails from './ListDetails';

export const NewList = ({
  onClose, onValidate, createNewList, appId, listLabel, domainsList, mode,
}) => {
  const [status, setStatus] = useState('pristine');
  const [name, setName] = useState('');
  const [changed, setChanged] = useState(false);

  const formRef = useRef();

  const handleSetChanged = (value) => {
    setChanged(value);
  };

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
    setName('');
    onClose();
  };

  const handleSubmit = async (values, actions) => {
    // contruct object to send
    const [body, shouldReturn] = onValidate({ name, ...values }, actions, mode);
    if (shouldReturn) {
      return;
    }

    setStatus('loading');
    try {
      await createNewList(appId, body);

      setStatus('success');
      setTimeout(() => {
        setStatus('pristine');
        setChanged(false);
        resetForm();
      }, 3000);
    } catch (err) {
      setStatus('pristine');
      actions.setErrors({
        'general': consolidateErrors(err),
        ...err.response.data.body,
      });
    }
  };

  const ListDetails = AccessHeadersDetails;

  return (
    <Box
      as={Paper}
      padding="small"
      margin={{ bottom: 'medium' }}
      css={css`
        position: relative;
      `}
      role="region"
    >
      <IconButton
        onClick={resetForm}
        css={css`
          position: absolute;
          top: ${remMapper('xsmall')};
          right: ${remMapper('xsmall')};
        `}
      >
        <Close />
      </IconButton>

      <ListDetails
        initialValues={{}}
        mode={mode}
        onSubmit={handleSubmit}
        formRef={formRef}
        domainsList={domainsList}
        onSetChanged={handleSetChanged}
      />
      <Box justify="flex-end" direction="row" flex={1}>
        <Button
          aria-label="save or submit"
          status={status}
          onClick={submitForm}
          variant={changed ? 'contained' : 'outlined'}
          disabled={!changed}
          color="secondary"
        >
          Save {listLabel}
        </Button>
      </Box>
    </Box>
  );
};

NewList.propTypes = {
  appId: number,
  createNewList: func,
  domainsList: array,
  listLabel: string,
  mode: string,
  onClose: func,
  onValidate: func,
};

export default connect(
  null,
  {
    createNewList: createOrUpdateList,
  },
)(NewList);
