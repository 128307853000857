import React from 'react';
import PropTypes from 'prop-types';
import RSelect, { components } from 'react-select';
import { Typography, useTheme } from '@material-ui/core';

const Option = (props) => {
  return (
    <components.Option {...props}>
      <Typography
        variant="body1"
        color="inherit"
        component="span"
      >
        {props.children}
      </Typography>
    </components.Option>

  );
};

export const themeProps = (muiTheme) => {
  if (!muiTheme) {
    return {};
  }
  return {
    theme: (theme) => ({
      ...theme,
      'borderRadius': muiTheme.borderRadius,
      'height': '40px',
      'box-sizing': 'border-box',
      'colors': {
        ...theme.colors,
        danger: muiTheme.palette.error.main,
        primary: muiTheme.palette.primary.main, // selected state
        primary25: muiTheme.color400, // hover over menu items
        neutral20: muiTheme.color400, // border
        neutral30: muiTheme.color900, // hover over menu
      },
    }),
    styles: {
      container: (base) => {
        return ({
          ...base,
          color: muiTheme.color700,
        });
      },
      menu: (base) => {
        return ({
          ...base,
          color: muiTheme.palette.text.primary,
          backgroundColor: muiTheme.color100,
          zIndex: 4,
        });
      },
      input: (base) => ({
        ...base,
        color: muiTheme.palette.text.primary,
      }),
      singleValue: (base) => ({
        ...base,
        color: muiTheme.palette.text.primary,
      }),
      multiValue: (base) => ({
        ...base,
        color: muiTheme.palette.text.secondary,
        backgroundColor: muiTheme.color100,
      }),
      multiValueLabel: (base) => ({
        ...base,
        color: muiTheme.palette.text.secondary,
        padding: '4px 5px',
      }),
      placeholder: (base, { isDisabled }) => ({
        ...base,
        color: isDisabled ? muiTheme.color900 : 'inherit',
      }),
      valueContainer: (base, { isDisabled }) => ({
        ...base,
        color: isDisabled ? muiTheme.color500 : 'inherit',
        padding: '1px 12px',
        minHeight: '38px',
      }),
      control: (base, { isDisabled }) => {
        return ({
          ...base,
          'color': isDisabled ? muiTheme.color500 : 'inherit',
          'backgroundColor': muiTheme.color100,
        });
      },
    },
  };
};

const Select = ({ styles = {}, ...rest }) => {
  const muiTheme = useTheme();

  const tProps = { ...themeProps(muiTheme) };

  tProps.styles = {
    ...tProps.styles,
    ...styles,
  };

  return (
    <RSelect
      isClearable={false}
      components={{ Option }}
      {...rest}
      {...tProps}
    />
  );
};
Select.propTypes = {
  ...RSelect.propTypes,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  style: PropTypes.object,
  styles: PropTypes.object,
  value: PropTypes.any,
};

export default Select;
