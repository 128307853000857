import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import Tooltip from '@material-ui/core/Tooltip';

import Box from 'shared/styleguide/atoms/Box';

const accountShortcuts = [
  {
    name: 'app',
    idVar: 'appId',
    Icon: AppsIcon,
  },
];

const constructShortcutUrl = (accountId, shortcut, subIds) => {
  const baseLink = `/account/${accountId}`;
  let link;

  if (shortcut.name === 'account') {
    link = baseLink;
  }

  if (shortcut.name !== 'account' && subIds[shortcut.idVar]) {
    link = `${baseLink}/${shortcut.name}/${subIds[shortcut.idVar]}`;
  }

  return link;
};

const ShortcutsIcon = ({
  shortcut, accountId, subIds, classes,
}) => {
  const { Icon, disabled, text } = shortcut;

  const link = constructShortcutUrl(accountId, shortcut, subIds);

  if (!link) return null;

  return (
    <Tooltip title={shortcut.name} placement="top">
      <Link to={link}>
        <IconButton
          className={classes.shortcutIcon}
          disabled={disabled}
          title={text}
        >
          <Icon />
        </IconButton>
      </Link>
    </Tooltip>
  );
};

ShortcutsIcon.propTypes = {
  accountId: PropTypes.number,
  appId: PropTypes.number,
  classes: PropTypes.object,
  Icon: PropTypes.node,
  shortcut: PropTypes.object,
  subIds: PropTypes.object,
};

const ConfigsShortcuts = ({ accountId, appId }) => {
  const classes = makeStyles(() => ({
    root: {
      height: 48,
      alignItems: 'center',
    },
    shortcutIcon: {
      marginRight: 8,
      width: 30,
      height: 30,
      padding: 1,
    },
  }))();

  return (
    <Box flex={1} row align="center" classes={classes}>
      {
        accountShortcuts
          .map((shortcut, index) => {
            return (
              <ShortcutsIcon
                key={`${shortcut.name}-${index}`}
                accountId={accountId}
                subIds={{ appId }}
                shortcut={shortcut}
                classes={classes}
                index={index}
              />
            );
          })
      }
    </Box>
  );
};

ConfigsShortcuts.propTypes = {
  accountId: PropTypes.number,
  appId: PropTypes.number,
};

export default ConfigsShortcuts;
