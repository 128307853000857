import makeApiAction from 'shared/utils/redux/apiActions';
import * as SERVERCONFIG from './constants';

export const reloadPool = (poolId) => makeApiAction(
  ({ api }) => api().post('serverconfig/webconfig/pool-reload', { poolId }),
  SERVERCONFIG.SERVERCONFIG_POOL_RELOAD_REQUEST,
  SERVERCONFIG.SERVERCONFIG_POOL_RELOAD_SUCCESS,
  SERVERCONFIG.SERVERCONFIG_POOL_RELOAD_FAILURE,
  null,
  true, // return promise
);

export const getServerList = () => makeApiAction(
  ({ api }) => api().get('/serverconfig/servers'),
  SERVERCONFIG.GET_SERVER_LIST_REQUEST,
  SERVERCONFIG.GET_SERVER_LIST_SUCCESS,
  SERVERCONFIG.GET_SERVER_LIST_FAILURE,
  {},
  true,
);
