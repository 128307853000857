import React, { useState } from 'react';
import ObjectInspector from 'react-inspector';

import {
  Paper, Tabs, Tab, TextField,
} from '@material-ui/core';

import { useFormikContext } from 'formik';
import MDView from 'shared/styleguide/atoms/Markdown/MDView';
import Box from 'shared/styleguide/atoms/Box';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

import {
  TYPE_MIGRATION,
  TYPE_CREDENTIALS,
  TYPE_EVENT,
  TYPE_ISSUE,
  TYPE_ADMIN,
  TYPE_CONTACT,
  TYPE_CONFIG,
  TYPE_REPORT,
  TYPE_SYSTEM,
  TYPE_PROMISE,
  TYPE_SHARED,
} from 'admin/modules/notes/constants';
import NoteTypeSelect from 'admin/modules/notes/components/NoteTypeSelect';
import Select from 'shared/styleguide/atoms/Select/Select';
import Checkbox from 'shared/styleguide/atoms/Switches/Checkbox';
import KeyValEdit from './KeyValEdit';
import { NoteForm } from '../../types';
import { clientExpiryOptions } from '../../expiryUtils';

export const NoteEdit = () => {
  const [view, setView] = useState('edit');

  const formikProps = useFormikContext<NoteForm>();

  const {
    title,
    noteType,
    note,
    noteContentType,
    expires,
    burnAfterReading,
  } = formikProps.values;
  const renderJsonObject = (noteString) => {
    let data;
    try {
      data = window.JSON.parse(noteString);
    } catch (e) {
      return (
        <div>Invalid JSON</div>
      );
    }

    return (
      <ObjectInspector
        key="jsonview"
        data={data}
        expandPaths={['$', '$.*']}
      />
    );
  };

  const renderEditorForContentType = (contentType) => {
    switch (contentType) {
      case 'text/plain':
      case 'application/json':
      case 'text/yaml':
        return (
          <Box
            direction="column"
            flex={1}
            key="edit"
            gap="small"
          >
            <TextField
              id="note"
              name="note"
              value={note}
              fullWidth
              onChange={formikProps.handleChange}
              helperText={formikProps.errors?.note}
              css={{ flex: '1' }}
              multiline
              minRows={10}
              variant="outlined"
              style={{
                flex: '1',
              }}
            />
            {noteContentType === 'application/json' && (
              <Paper
                style={{
                  marginTop: 8,
                  padding: 10,
                }}
              >
                {renderJsonObject(note)}
              </Paper>
            )}
          </Box>
        );
      case 'text/markdown':
      default:
        return (
          <Box direction="column" flex={1}>
            <Tabs
              value={view}
              onChange={(event, newValue) => {
                setView(newValue);
              }}
              aria-label="team subnavigation"
            >
              <Tab label="Edit" value="edit" />
              <Tab label="Preview" value="preview" />
            </Tabs>

            {
              view === 'edit' && (
                <Box padding={{ top: 'small', bottom: 'small' }} flex={1} gap="xsmall">
                  <TextField
                    name="note"
                    id="mdedit"
                    fullWidth
                    variant="outlined"
                    value={note}
                    inputProps={{
                      style: { height: '100%' },
                    }}
                    style={{
                      flex: '1',
                    }}
                    minRows={10}
                    multiline
                    onChange={formikProps.handleChange}
                    helperText={formikProps.errors?.note}
                    error={Boolean(formikProps.errors?.note)}
                    placeholder="Add a description..."
                  />
                  <TextLink
                    style={{ alignSelf: 'flex-end' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
                  >
                    Markdown Help
                  </TextLink>
                </Box>
              )
            }
            {
              view === 'preview' && (
                <Box margin={{ top: 'small' }} flex={1}>
                  <Paper
                    style={{
                      height: '100%',
                    }}
                  >
                    <MDView value={note || '(preview)'} />
                  </Paper>
                </Box>
              )
            }
          </Box>
        );
    }
  };

  const renderExpirySelection = () => {
    return (
      <Box direction="column" flex={1} gap="small" margin={{ bottom: 'small' }}>
        <Select
          key="expires"
          name="expires"
          id="expires"
          label="Expiration"
          fullWidth
          placeholder="Select an expiration..."
          value={clientExpiryOptions.find((i) => JSON.stringify(i.value) === JSON.stringify(expires))}
          onChange={({ value }) => formikProps.setFieldValue('expires', value)}
          options={clientExpiryOptions}
        />
      </Box>
    );
  };

  const renderMultiType = () => {
    const options = [
      {
        label: 'Plain Text',
        value: 'text/plain',
      },
      {
        label: 'Markdown',
        value: 'text/markdown',
      },
      {
        label: 'JSON',
        value: 'application/json',
      },
      {
        label: 'YAML',
        value: 'text/yaml',
      },
    ];

    return (
      <Box direction="column" flex={1} gap="small">
        <Select
          key="noteContentType"
          name="noteContentType"
          id="noteContentType"
          label="Content Type"
          fullWidth
          value={options.find((i) => i.value === noteContentType)}
          onChange={({ value }) => formikProps.setFieldValue('noteContentType', value)}
          options={options}
        />
        {renderEditorForContentType(noteContentType)}
      </Box>
    );
  };

  const renderEditorForNoteType = (type) => {
    let data;
    switch (type) {
      case TYPE_SHARED:
        return (
          <>
            <Box>
              {/* @ts-ignore */}
              <Checkbox
                key="burnAfterReading"
                name="burnAfterReading"
                id="burnAfterReading"
                label="Burn After Reading"
                checked={burnAfterReading ?? false}
                onChange={() => {
                  formikProps.setFieldValue('burnAfterReading', !burnAfterReading);
                }}
              />
            </Box>
            {renderExpirySelection()}
            {renderEditorForContentType('text/plain')}
          </>
        );
      case TYPE_CONFIG: {
        return renderMultiType();
      }
      case TYPE_MIGRATION:
      case TYPE_CREDENTIALS:
      case TYPE_CONTACT:
        try {
          data = JSON.parse(note);
        } catch (e) {
          data = [];
        }
        return (
          <KeyValEdit
            data={data}
            handleChange={(next) => formikProps.setFieldValue('note', JSON.stringify(next))}
          />
        );
      case TYPE_REPORT:
      case TYPE_SYSTEM:
        return renderEditorForContentType('text/plain');
      case TYPE_ADMIN:
      case TYPE_EVENT:
      case TYPE_ISSUE:
      case TYPE_PROMISE:
      default:
        return (
          renderEditorForContentType('text/markdown')
        );
    }
  };

  return (
    <Box flex={1} direction="column" padding={{ left: 'medium', right: 'medium', bottom: 'medium' }}>
      <Box>
        <TextField
          name="title"
          key="title"
          value={title}
          fullWidth
          placeholder="Title"
          margin="dense"
          onChange={formikProps.handleChange}
          error={Boolean(formikProps.errors?.title)}
          helperText={formikProps.errors?.title}
        />
        <NoteTypeSelect
          key="noteType"
          name="noteType"
          value={noteType}
          label="Note Type"
          margin="dense"
          fullWidth
          onChange={formikProps.handleChange}
          error={Boolean(formikProps.errors?.noteType)}
          helperText={formikProps.errors?.noteType}
        />
      </Box>
      <Box flex={1}>
        {renderEditorForNoteType(noteType)}
      </Box>
    </Box>
  );
};

export default NoteEdit;
