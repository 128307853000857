import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import type { Location } from 'history';
import type { match as Match } from 'react-router';
import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { useSsoSelect } from '../../hooks/useSsoSelect';
import type { RetrieveSsoUrls } from '../../types/AccountUrl';
import PasswordField from './PasswordField';
import SsoField from './SsoField';
import { Errors, validateForm } from './Utils/Validate';

interface Props {
  retrieveSsoUrls: RetrieveSsoUrls;
  handleLogin?: (username: any, password: any, mfa: any, preserveUrl?: Record<string, any>) => ((dispatch: any, getState: any) => any);
  match: Match;
  location: Location;
  preserveUrl?: boolean;
  isFetching: boolean;
  errMessage: string;
  isAdmin?: boolean;
}

const LoginForm: React.FC<Props> = ({ retrieveSsoUrls: getSsoUrls, isAdmin = false, ...props }) => {
  const [usePassword, setUsePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [mfa, setMfa] = useState('');
  const [formErrors, setErrors] = useState<Errors>({});
  const {
    setUsername,
    state: {
      isFetching,
      error,
      ssoUrls,
      username,
      submitted,
    },
    searchUsername,
  } = useSsoSelect(getSsoUrls);

  useEffect(() => {
    if (isAdmin) {
      setUsername('admin');
      searchUsername('admin');
    }
  }, []);

  const fieldToRender = useMemo(() => {
    return ssoUrls.length > 0 && !usePassword
      && <SsoField setUsePassword={setUsePassword} ssoUrls={ssoUrls} />;
  }, [ssoUrls, usePassword]);

  const buttonLabel = (!submitted && isFetching) ? 'Just a moment...' : 'Continue';

  const onSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    const { handleLogin } = props;

    if (!submitted) {
      searchUsername();
      return;
    }

    if (ssoUrls.length > 0 && !usePassword) {
      return;
    }

    const errors = validateForm(username, password, mfa);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    handleLogin(username, password, mfa, props.preserveUrl && { ...props.match, ...props.location });
  }, [username, password, mfa, props, searchUsername, submitted, ssoUrls, usePassword]);

  const usernameChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUsePassword(false);
    setUsername(value);
    if (/^.*@.*\..{2,}$/.test(value)) {
      searchUsername(value);
    }
  }, [setUsername, searchUsername]);

  return (
    <Box as="form" onSubmit={onSubmit}>
      <TextField
        testId="username-field"
        fullWidth
        margin="normal"
        id="username"
        placeholder="Username"
        onChange={usernameChanged}
        error={Boolean(error) || Boolean(props.errMessage)}
        hintText={error}
        disabled={false}
        name="username"
        autoComplete="username"
      />
      <PasswordField
        visible={(ssoUrls.length === 0 || usePassword) && submitted}
        isFetching={props.isFetching}
        errMessage={props.errMessage}
        formErrors={formErrors}
        setErrors={setErrors}
        setPassword={setPassword}
        setMfa={setMfa}
      />
      {
        submitted ? (fieldToRender) : (
          <Box margin={{ top: 'large' }}>
            <Button
              data-testid="user-search-button"
              variant="contained"
              fullWidth
              type="submit"
              label={buttonLabel}
              color="secondary"
              disabled={isFetching || username === ''}
            />
          </Box>
        )
      }
    </Box>
  );
};

export default LoginForm;
