import React, { useEffect } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import {
  Typography, Paper,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// styleguide
import Box from 'shared/styleguide/atoms/Box';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';

import { getInvoices } from 'shared/modules/billing/redux/actions';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import SortableTable from 'shared/styleguide/molecules/Table/SortableTable';
import Empty from 'shared/styleguide/atoms/Empty';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

import { formatDate, localizeCurrency } from 'shared/utils';
import { getHttp } from 'shared/http';
import { tagMap } from './utils';

const formatOps = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

/* eslint-disable react/prop-types */
const InvoiceRow = ({ customerId, ...invoice }) => {
  const classes = makeStyles({ body: { maxWidth: '30vw', wordBreak: 'break-word' } })();

  const downloadInvoice = async () => {
    const response = await getHttp().get(`/go-billing/account/${customerId}/invoice/${invoice.id}/download`);

    window.open(response.data.url, '_blank');
  };

  return (
    <TableRow>
      <TableCell classes={classes}>
        <Typography variant="subtitle1">{invoice.id}</Typography>
      </TableCell>
      <TableCell
        // align="center"
        classes={classes}
      >
        {formatDate(invoice.createdAt, formatOps)}
      </TableCell>
      <TableCell
        // align="center"
        classes={classes}
      >
        {localizeCurrency(invoice.total / 100) || '$??,???.??'}
      </TableCell>
      <TableCell
        align="center"
        classes={classes}
      >
        <TextLink
          onClick={downloadInvoice}
          color="textSecondary"
        >
          Download PDF
        </TextLink>
      </TableCell>
      <TableCell align="right">
        <GhostTag noMargin color={tagMap[invoice.status].color}>{tagMap[invoice.status].label}</GhostTag>
      </TableCell>
    </TableRow>
  );
};
/* eslint-enable react/prop-types */

export const Invoices = ({ getInvoices, account, invoices }) => {
  useEffect(() => {
    if (account.billingCustomerId) {
      if (invoices.status === 'pristine' || (invoices.status === 'success' && invoices.params.customerId !== account.billingCustomerId)) {
        getInvoices(account.billingCustomerId);
      }
    }
  }, [invoices.status, invoices.params, account.billingCustomerId, getInvoices]);

  if (account.billingCustomerId && ['loading', 'pristine'].includes(invoices.status)) {
    return (
      <Box as={Paper} padding="medium">
        <Empty>
          <LoadingDots />
        </Empty>
      </Box>
    );
  }

  return (
    <>
      <SortableTable
        headCells={[
          {
            id: 'id',
            numeric: true,
            label: 'Invoice #',
          },
          {
            id: 'created_on',
            numeric: false,
            sort: false,
            label: 'Invoice Date',
          },
          {
            id: 'total',
            numeric: true,
            label: 'Amount',
          },
          {
            id: 'pdf',
            numeric: false,
            sort: false,
            label: 'PDF',
            align: 'center',
          },
          {
            id: 'status',
            numeric: false,
            label: 'Status',
            align: 'right',
          },
        ]}
        rows={invoices.data}
        paginate
        pageLength={12}
        renderRow={(invoice) => {
          return (
            <InvoiceRow
              key={invoice.id}
              {...invoice}
              customerId={account.billingCustomerId}
            />
          );
        }}
      />
    </>
  );
};

Invoices.propTypes = {
  account: object,
  getInvoices: func,
  invoices: object,
};

export default connect(
  (state) => ({
    account: state.account,
    invoices: state.billing.invoices,
  }),
  {
    getInvoices,
  },
)(Invoices);
