export const FETCH_CONFIGS_REQUEST = 'FETCH_CONFIGS_REQUEST';
export const FETCH_CONFIGS_SUCCESS = 'FETCH_CONFIGS_SUCCESS';
export const FETCH_CONFIGS_FAILURE = 'FETCH_CONFIGS_FAILURE';
export const FETCH_CONFIGS_RESET = 'FETCH_CONFIGS_RESET';

export const headCells = [
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'TYPE',
  },
  {
    id: 'appId',
    align: 'left',
    disablePadding: false,
    label: 'APPID',
  },
  {
    id: 'file',
    align: 'left',
    disablePadding: false,
    label: 'NAME',
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'STATUS',
  },
  {
    id: 'modifiedDate',
    align: 'left',
    disablePadding: false,
    label: 'MODIFIED',
  },
  {
    id: 'shortcuts',
    align: 'left',
    disablePadding: false,
    label: 'SHORTCUTS',
  },
];
