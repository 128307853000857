import React from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import Select from 'shared/styleguide/atoms/Select/Select';

import { addProperty } from '../redux/actions';
import Properties from './Properties';
import {
  getAvailableNonBlockRulesOptions, getOptions, getConfigType,
} from '../helpers';

const NonBlockRules = ({
  state, propLevels, value, addProperty, schema,
}) => {
  const configType = getConfigType(state.config);
  const optsArr = Object.keys(getOptions(propLevels, schema, configType));

  let ruleName = 'rules';
  if (configType === 'miss-rules') {
    ruleName = configType;
  }
  const optsNonMatch = optsArr.filter((x) => x !== ruleName);
  if (optsNonMatch.length === 0) {
    return '';
  }
  const optsAvailable = getAvailableNonBlockRulesOptions(value, propLevels, schema, configType);
  const optsAvailableSelect = optsAvailable.map(((item) => ({
    value: item,
    label: item,
  })));

  return (
    <div style={{
      marginBottom: 10,
      marginTop: 10,
      borderBottom: '1px solid #DBDBDB',
      padding: '0 20px 20px 20px',
    }}
    >
      <div style={{ maxWidth: 200, marginBottom: 10 }}>
        <Select
          onChange={(e) => {
            if (e.value !== null) {
              addProperty(propLevels, e.value, schema, configType);
            }
          }}
          options={optsAvailableSelect}
          placeholder="Add Property..."
          value="Add Property..."
        />
      </div>
      <Properties
        value={value}
        propLevels={propLevels}
      />
    </div>
  );
};

NonBlockRules.propTypes = {
  addProperty: any,
  propLevels: any,
  schema: any,
  state: any,
  value: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, {
  addProperty,
})(NonBlockRules);
