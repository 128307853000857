import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Box from 'shared/styleguide/atoms/Box';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Paper from 'shared/styleguide/atoms/Paper/Paper';
import {
  fetchApp as fetchAppAction,
  resetApp as resetAppAction,
} from 'shared/modules/app/redux/actions';
import LoadingCard from 'shared/styleguide/molecules/LoadingCard';
import { fetchDomainsForApp } from './redux/domains/actions';
import SFTPView from './routes/SSHDetails';

const SftpOnly = ({ app, primaryDomain }) => {
  return (
    <div data-testid="app-only-minimal">
      <Box as={Paper} padding="small" margin={{ bottom: 'small' }}>
        <Typography variant="h2" color="textPrimary" gutterBottom>
          {app.label || app.name}
        </Typography>
        <Box direction="row" gap="xsmall" align="center">
          <TextLink
            variant="subtitle2"
            color="textSecondary"
            noUnderline
            href={`http://${primaryDomain.fqdn}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {primaryDomain.fqdn}
          </TextLink>
        </Box>
        <Box direction="row" gap="xsmall" margin={{ top: 'xsmall', bottom: 'xsmall' }}>
          <Typography component="span" variant="body2" color="textSecondary">App ID:</Typography>
          <Typography component="span" variant="body2" color="textPrimary">{app.id}</Typography>
        </Box>
      </Box>
      <SFTPView />
    </div>
  );
};

const SftpOnlyLoader = ({
  app, appDomains, fetchApp, resetApp, fetchDomains,
}) => {
  const match = useRouteMatch();

  useEffect(() => {
    if (app.status === 'pristine' || app.params.appId !== (match?.params as any)?.appId) {
      fetchApp((match.params as any)?.appId);
    }
    if (appDomains.status === 'pristine' || appDomains.params.appId !== (match?.params as any)?.appId) {
      fetchDomains((match.params as any)?.appId);
    }

    return () => {
      resetApp();
    };
  }, []);

  if (['loading', 'pristine'].includes(app.status) || ['loading', 'pristine'].includes(appDomains.status)) {
    return (
      <LoadingCard />
    );
  }

  const primaryDomain = appDomains.data.find((d) => d.primary) || null;

  return (
    <SftpOnly app={app.data} primaryDomain={primaryDomain} />
  );
};

export default connect(
  // @ts-ignore
  ({ app, ...state }) => ({
    app: app.app,
    appDomains: app.appDomains,
  }),
  {
    fetchApp: fetchAppAction,
    fetchDomains: fetchDomainsForApp,
    resetApp: resetAppAction,
  },
)(SftpOnlyLoader);
