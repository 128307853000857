import React from 'react';
import { object } from 'prop-types';

import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CopyButton from 'shared/styleguide/molecules/CopyButton';
import {
  formatMiB, formatDate, hashCode, dateOptions,
} from 'shared/utils';
import SortableTable from 'shared/styleguide/molecules/Table/SortableTable';

/* eslint-disable react/prop-types */
const Row = ({
  path, used, ...rest
}) => {
  const classes = makeStyles({ body: { maxWidth: '30vw', wordBreak: 'break-word' } })();

  return (
    <TableRow>
      <TableCell classes={classes}>
        {path} <CopyButton text={path} message="Location copied" />
      </TableCell>
      <TableCell
        align="left"
        classes={classes}
      >
        {formatMiB(used)}
      </TableCell>
      <TableCell
        align="left"
        classes={classes}
      >
        {rest['used-per']}
      </TableCell>
      <TableCell align="left">{formatDate(rest['modified-time'], dateOptions)}</TableCell>
    </TableRow>
  );
};
/* eslint-enable react/prop-types */

const LargeFiles = ({ report }) => {
  const largeFiles = report['large-files'];
  return (

    <SortableTable
      headCells={[
        {
          id: 'path',
          numeric: false,
          disablePadding: false,
          label: 'Location',
        },
        {
          id: 'used',
          numeric: true,
          disablePadding: false,
          label: 'Usage',
          align: 'left',
        },
        {
          id: 'used-per',
          numeric: true,
          disablePadding: false,
          label: 'Percentage',
          align: 'left',
        },
        {
          id: 'modified-time',
          numeric: true,
          disablePadding: false,
          label: 'Modified',
          align: 'left',
        },
      ]}
      rows={largeFiles}
      paginate
      pageLength={30}
      renderRow={(path) => {
        return (
          <Row
            key={hashCode(path.path)}
            {...path}
          />
        );
      }}
    />
  );
};

LargeFiles.propTypes = {
  apps: object.isRequired,
  report: object.isRequired,
};

export default LargeFiles;
