export const TOOLS_SHOW_CONFIG = 'TOOLS_SHOW_CONFIG';
export const TOOLS_LOAD_OPTIONS = 'TOOLS_LOAD_OPTIONS';
export const TOOLS_CHANGE_CONFIG = 'TOOLS_CHANGE_CONFIG';

export const TOOLS_UNDO = 'TOOLS_UNDO';
export const TOOLS_REDO = 'TOOLS_REDO';

export const TOOLS_UPDATE_ITEM = 'TOOLS_UPDATE_ITEM';
export const TOOLS_UPDATE_KEY = 'TOOLS_UPDATE_KEY';

export const TOOLS_UPDATE_MAP_KEY = 'TOOLS_UPDATE_MAP_KEY';
export const TOOLS_UPDATE_MAP_VAL = 'TOOLS_UPDATE_MAP_VAL';

export const TOOLS_ADD_SUBPROPERTY = 'TOOLS_ADD_SUBPROPERTY';
export const TOOLS_ADD_SUBMAPPROPERTY = 'TOOLS_ADD_SUBMAPPROPERTY';
export const TOOLS_ADD_PROPERTY = 'TOOLS_ADD_PROPERTY';
export const TOOLS_ADD_RULEBLOCK = 'TOOLS_ADD_RULEBLOCK';

export const TOOLS_DELETE_ITEM = 'TOOLS_DELETE_ITEM';
export const TOOLS_DELETE_RULEBLOCK = 'TOOLS_DELETE_RULEBLOCK';

export const TOOLS_SET_PROPLEVELS = 'TOOLS_SET_PROPLEVELS';
export const TOOLS_SET_ERRORS = 'TOOLS_SET_ERRORS';
export const TOOLS_SET_JSON_MODAL_OPEN = 'TOOLS_SET_JSON_MODAL_OPEN';
export const TOOLS_SET_IMPORT_MODAL_OPEN = 'TOOLS_SET_IMPORT_MODAL_OPEN';
export const TOOLS_SET_REDIRECTS_WIZARD_MODAL_OPEN = 'TOOLS_SET_REDIRECTS_WIZARD_MODAL_OPEN';

export const TOOLS_JSON_OUTPUT_ERROR = 'TOOLS_JSON_OUTPUT_ERROR';
export const TOOLS_JSON_OUTPUT_SUCCESS = 'TOOLS_JSON_OUTPUT_SUCCESS';
export const TOOLS_JSON_MODAL_OPEN = 'TOOLS_JSON_MODAL_OPEN';

export const TOOLS_IMPORT_ERROR = 'TOOLS_IMPORT_ERROR';
export const TOOLS_IMPORT_SUCCESS = 'TOOLS_IMPORT_SUCCESS';
export const TOOLS_IMPORT_EXAMPLE = 'TOOLS_IMPORT_EXAMPLE';
export const TOOLS_CLEAR_IMPORT_ERROR = 'TOOLS_CLEAR_IMPORT_ERROR';

export const TOOLS_REDIRECTS_WIZARD_ERROR = 'TOOLS_REDIRECTS_WIZARD_ERROR';
export const TOOLS_SUBMIT_REDIRECTS_WIZARD = 'TOOLS_SUBMIT_REDIRECTS_WIZARD';

export const TOOLS_SCHEMA_PARSE = 'TOOLS_SCHEMA_PARSE';
export const TOOLS_SCHEMA_PARSE_ERROR = 'TOOLS_SCHEMA_PARSE_ERROR';
