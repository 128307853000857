import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
// https://stackoverflow.com/questions/53595935/how-can-i-make-react-portal-work-with-react-hook
import { copyStyles } from './utils';

const PopupWindowWithHooks = ({
  width = 600, height = 400, left = 200, top = 200, ...props
}) => {
  const [containerEl] = useState(document.createElement('div'));
  let externalWindow = null;

  const constructWindowParams = () => {
    return `width=${width},height=${height},left=${left},top=${top},location=0,toolbar=0,menubar=0`;
  };

  useEffect(
    () => {
      externalWindow = window.open(
        '',
        '',
        constructWindowParams(),
      );

      copyStyles(document, externalWindow.document);

      externalWindow.document.body.appendChild(containerEl);
      externalWindow.addEventListener('beforeunload', () => {
        props.onClose();
      });
      return function cleanup() {
        externalWindow.close();
        externalWindow = null;
      };
    },
    [],
  );

  return ReactDOM.createPortal(props.children, containerEl);
};

export default PopupWindowWithHooks;
