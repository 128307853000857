import { UserPool } from 'shared/modules/account/redux/sso/types';
import * as SSO_POOL from 'shared/modules/account/redux/sso/constants';
import { ACCOUNT_TAGS_SUCCESS } from 'shared/modules/account/redux/constants';
import type { Tag as AccountTag } from 'shared/modules/metadata/types';
import type { StatusKeys } from '../../routes/Settings/SsoSettings/constants';
import * as SSO from './constants';

interface State {
  readonly userPool: null | UserPool;
  readonly status: StatusKeys;
  [key: string]: any;
}

interface Action {
  type: string;
  [key: string]: any;
}

const defaultState: State = {
  status: 'unknown',
  userPool: null,
};

export function sso(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case SSO.CREATE_USER_POOL_SUCCESS:
      return {
        ...state,
        userPool: action.data,
        status: 'enabled',
      };
    case SSO.UPDATE_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case SSO.FINISH_SAML_SETUP_SUCCESS:
      return {
        ...state,
        userPool: action.data,
        status: 'test',
      };
    case SSO_POOL.SSO_POOL_REQUEST: {
      return {
        ...state,
        userPool: null,
      };
    }
    case SSO_POOL.SSO_POOL_FAILURE:
      return {
        ...state,
        userPool: null,
      };
    case SSO_POOL.SSO_POOL_SUCCESS: {
      const userPool: UserPool | undefined = action.data;
      let { status } = state;
      if (userPool) {
        status = userPool.identityProviders.length > 0 ? 'active' : 'finish';
      }

      return {
        ...state,
        userPool: action.data,
        status,
      };
    }
    case SSO.DELETE_IDP_SUCCESS: {
      const userPool = { ...state.userPool };
      // Remove the deleted IdP from the list.
      userPool.identityProviders = userPool.identityProviders.filter((idp) => idp.providerName !== action.idpName);
      return {
        ...state,
        userPool,
      };
    }
    case SSO.DELETE_POOL_SUCCESS:
      return {
        ...state,
        userPool: null,
        status: 'disabled',
      };
    case ACCOUNT_TAGS_SUCCESS: {
      const tags = action.data.data as AccountTag[];
      const ssoAccess = tags.filter((tag) => tag.tagId === 'account:sso').length > 0;
      const initialStatus = ssoAccess ? 'disabled' : 'unavailable';
      const isInitial = isInitialStatus(state.status) || state.userPool === null;
      return {
        ...state,
        status: isInitial ? initialStatus : state.status,
      };
    }
    default:
      return state;
  }
}

function isInitialStatus(status: StatusKeys): status is ('unknown' | 'unavailable' | 'disabled') {
  return ['unknown', 'unavailable', 'disabled'].includes(status);
}
