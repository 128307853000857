import makeApiAction from 'shared/utils/redux/apiActions';
import { getHttp } from 'shared/http';
import * as RELEASE_NOTES from './constants';

export const getReleaseNotes = (minDate = null, maxDate = null) => makeApiAction(
  ({ api }) => api().get(
    minDate && maxDate ? (
      `/releasenotes/mgmt?minDate=${minDate}&maxDate=${maxDate}`
    ) : (
      '/releasenotes/mgmt'
    ),
  ),
  RELEASE_NOTES.RELEASE_NOTES_GET_REQUEST,
  RELEASE_NOTES.RELEASE_NOTES_GET_SUCCESS,
  RELEASE_NOTES.RELEASE_NOTES_GET_FAILURE,
  { minDate, maxDate },
  true,
);

export const createReleaseNote = (notes) => makeApiAction(
  ({ api }) => api().post('/releasenotes/mgmt', { ...notes, project: 'mgmt' }),
  RELEASE_NOTES.RELEASE_NOTES_CREATE_REQUEST,
  RELEASE_NOTES.RELEASE_NOTES_CREATE_SUCCESS,
  RELEASE_NOTES.RELEASE_NOTES_CREATE_FAILURE,
  { notes },
  true,
);

export const updateReleaseNote = (releaseDate, update) => makeApiAction(
  ({ api }) => {
    return api().patch(`/releasenotes/mgmt/${releaseDate}`, update);
  },
  RELEASE_NOTES.RELEASE_NOTES_UPDATE_REQUEST,
  RELEASE_NOTES.RELEASE_NOTES_UPDATE_SUCCESS,
  RELEASE_NOTES.RELEASE_NOTES_UPDATE_FAILURE,
  { releaseDate, update },
  true,
);

export const deleteReleaseNote = (releaseDate) => makeApiAction(
  ({ api }) => {
    return api().delete(`/releasenotes/mgmt/${releaseDate}`);
  },
  RELEASE_NOTES.RELEASE_NOTES_DELETE_REQUEST,
  RELEASE_NOTES.RELEASE_NOTES_DELETE_SUCCESS,
  RELEASE_NOTES.RELEASE_NOTES_DELETE_FAILURE,
  { releaseDate },
  true,
);

export const setMode = (mode, releaseNoteDate = null) => ({
  type: RELEASE_NOTES.RELEASE_NOTES_SET_MODE,
  value: { mode, releaseNoteDate },
});
