import React, { useContext } from 'react';
import { node, number } from 'prop-types';
import AccountContext from 'shared/modules/account/context/AccountContext';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

const SSLLink = ({ children, certId, ...props }) => {
  const accountId = useContext(AccountContext).id;

  return <TextLink to={`/account/${accountId}/ssl${certId ? `/cert/${certId}` : ''}`} {...props}>{children}</TextLink>;
};

SSLLink.propTypes = {
  certId: number,
  children: node,
};

SSLLink.displayName = 'SSLLink';

export default SSLLink;
