import React, { useContext, Fragment } from 'react';
import PropTypes, { func } from 'prop-types';
import { connect } from 'react-redux';

import AdminContext from 'shared/modules/permissions/context/AdminContext';
import Toast from 'shared/styleguide/molecules/Toast';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

export const ApiErrorMessage = ({ api: { fatalResponse, ...api }, resetApiErrors }) => {
  const isAdmin = useContext(AdminContext);
  let message = null;

  if (fatalResponse) {
    if (isAdmin) {
      message = (
        <div>
          API Error: Status Code
          {' '}
          {fatalResponse.status}
          <br />
          method &quot;
          {fatalResponse.config.method}
          &quot; failed on
          {' '}
          {fatalResponse.config.url}
          {fatalResponse.data.message && (
            <Fragment>
              <br />
              message:
              {' '}
              <pre>{fatalResponse.data.message}</pre>
            </Fragment>
          )}
        </div>
      );
    } else {
      message = fatalResponse.headers['x-request-id']
        ? `There was an issue with an API call... please refer a developer to "Request ID
      ${fatalResponse.headers['x-request-id']}". You should reload the app.`
        : 'There was an error making an API call. You should reload the app to ensure clean data.';
    }
  } else if (isAdmin) {
    message = (
      <span>
        Source: {api.requestor}
        <br />
        {api.error && api.error.message}
      </span>
    );
  } else {
    message = (
      <span>
        There was an error making an API call. Please contact support or check the&nbsp;
        <TextLink
          href="https://status.pagely.com"
        >
          Status Page
        </TextLink>
        . Your ad blocker may be blocking important network requests.
      </span>
    );
  }

  return (
    <Toast
      isOpen
      message={message}
      textColor="initial"
      autoHideDuration={600000}
      onClose={() => resetApiErrors()}
      type="danger"
    />
  );
};

ApiErrorMessage.propTypes = {
  api: PropTypes.shape({
    fatalResponse: PropTypes.object,
  }).isRequired,
  resetApiErrors: func.isRequired,
};

const mapStateToProps = (state) => ({
  api: state.api,
});

export default connect(mapStateToProps, (dispatch) => { return ({ resetApiErrors: () => { dispatch({ type: 'API_RESET' }); } }); })(ApiErrorMessage);
