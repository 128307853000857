import React, { useState, useEffect, Fragment } from 'react';
import { object } from 'prop-types';
import qs from 'qs';
import moment from 'moment-timezone';

import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';

import { getInitialDates } from '../analyticsUtils';

import { DISK_EARLIEST_DATE, DEFAULT_DAY_SPREAD } from '../../constants';

import LoadDiskByMount from './LoadDiskByMount';
import LoadDiskByPath from './LoadDiskByPath';

import AnalyticsDatePicker from '../AnalyticsDatePicker';

const paths = ['byMount', 'byPath'];
const type = 'disk';

export const Disk = (props) => {
  const [dates, setDates] = useState({
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const [startValue, endValue] = getInitialDates(DISK_EARLIEST_DATE, DEFAULT_DAY_SPREAD[type]);

    const location = qs.parse(props.location?.search, { ignoreQueryPrefix: true });
    const query = location?.[paths[0]] || location?.[paths[1]];

    setDates({
      fromDate: query?.fromDate ? moment(query.fromDate) : startValue,
      toDate: query?.toDate ? moment(query.toDate) : endValue,
    });
  }, []); //eslint-disable-line

  const handleDatesChange = (calendarDates) => {
    setDates({
      fromDate: calendarDates.startDate ?? dates.fromDate,
      toDate: calendarDates.endDate ?? dates.toDate,
    });
  };

  if (!dates.fromDate || !dates.toDate) {
    return <Loading />;
  }

  return (
    <Fragment>
      <Box margin={{ top: 'medium', bottom: 'medium' }}>
        <Typography variant="h6">PressFormance data is updated every 6 hours.</Typography>
      </Box>
      <AnalyticsDatePicker
        start={dates.fromDate}
        end={dates.toDate}
        onDatesChange={handleDatesChange}
        type={type}
      />
      <Box margin={{ top: 'large' }}>
        <Box margin={{ bottom: 'small' }}>
          <Typography variant="h2">Overview</Typography>
        </Box>
        <LoadDiskByMount
          startDate={dates.fromDate}
          endDate={dates.toDate}
          type={type}
          {...props}
        />
      </Box>
      <Box margin={{ top: 'large' }}>
        <Box margin={{ bottom: 'small' }}>
          <Typography variant="h2">Details</Typography>
        </Box>
        <LoadDiskByPath
          startDate={dates.fromDate}
          endDate={dates.toDate}
          type="disk"
          {...props}
        />
      </Box>
    </Fragment>
  );
};

Disk.propTypes = {
  location: object.isRequired,
};

export default Disk;
