import React, { useEffect, Fragment } from 'react';
import { useFormikContext } from 'formik';

import {
  Typography,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';

import type { App } from 'shared/types/App';
import { sortValidations } from 'shared/modules/app/routes/DNS/utils';
import { useMergeState } from 'shared/hooks/useMergeState';
import Loading from 'shared/styleguide/atoms/Loading';
import * as JOBSTATUS from 'shared/modules/status/redux/constants';
import Stepper from 'shared/styleguide/molecules/Stepper';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { PRISTINE, LOADING, SUCCESS } from 'shared/utils/redux/constants';
import { PCDN_REGEX } from 'shared/modules/dns/constants';

import { FormikValues } from './types';
import Validations from './components/Validations';

interface Props {
  app: App;
  nameserverChanges: React.ReactNode[] | React.ReactNode;
}

const viewDnsRecordsText = [
  'You can also view these records at any time in the DNS section under the appropriate zone,',
  ' or in the App view under the DNS tab.',
];

interface DnsProps {
  step: number;
  app: App;
  dns: Record<string, any>;
  dnsJobs: Record<string, any>;
  getAllValidations: (appId: number) => void;
  onClose: () => void;
}

const DNSInstructions = ({
  step, app, dns, dnsJobs, getAllValidations, onClose,
}: DnsProps) => {
  const { values } = useFormikContext<FormikValues>();
  const { state, mergeState } = useMergeState({
    validationJobs: [],
    status: PRISTINE,
    started: Date.now(),
  });
  const { validationJobs, status, started } = state;

  const jobs = Object.values(dnsJobs);
  const appDomainIds = app.aliases
    .filter((a) => !a.fqdn.match('sites.pressdns.com'))
    .map((a) => a.id) || [];

  const handleGetValidations = async () => {
    mergeState({ status: LOADING });
    await getAllValidations(app.id);

    const duration = Math.floor(Date.now() - Number(started));
    if (duration < 5000) {
      // wait total of 5 seconds before setting as SUCCESS, in case it went too fast to read the text
      setTimeout(
        () => mergeState({ status: SUCCESS }),
        5000 - duration,
      );
    } else {
      mergeState({ status: SUCCESS });
    }
  };

  const successJobs = jobs.filter((j) => j.overallStatus === JOBSTATUS.SUCCESS && appDomainIds.includes(Number(j.externalId)));

  useEffect(() => {
    // check if all validation jobs for all app domains are complete
    if (
      appDomainIds.length > 0
      && successJobs.length > 0
      && successJobs.length === appDomainIds.length
      // @ts-ignore
      && validationJobs.length === 0
    ) {
      // add the successful job to validationJobs
      mergeState({ validationJobs: successJobs.map((j) => Number(j.externalId)) });
    }
  }, [successJobs, validationJobs, appDomainIds]);

  useEffect(() => {
    if (status === PRISTINE
      // @ts-ignore
      && validationJobs.length > 0
    ) {
      // all validation jobs are complete for all app domains
      // so fetch the validation rules to display
      handleGetValidations();
    }
  }, [validationJobs, status]);

  // merge domain fqdn into validation data
  const validationRecords = dns.data.map((record) => {
    const domain = app.aliases.find((d) => d.id === record.domainId);
    return {
      fqdn: domain.fqdn,
      isPressDns: domain.isPressDns,
      ...record,
    };
  });

  // if Mercury, we only need to validate PressDNS to kick off the flow
  const domainsToValidate = app.fsa ? app.aliases.reduce((acc, d) => {
    if (d.isPressDns === true && !d.fqdn?.match(PCDN_REGEX)) {
      acc.push(d.id.toString());
    }
    return acc;
  }, []) : [];

  const { validations } = sortValidations(validationRecords, domainsToValidate, 'non-acm', true, true, true);

  const willRequireMercuryDnsChangesLater = appDomainIds.length > domainsToValidate.length;

  const getContent = () => {
    if (status !== SUCCESS) {
      // we havent fetched the validation rules yet
      return (
        <Box margin={{ bottom: 'small' }}>
          <Loading />
          <Box margin={{ top: 'medium' }}>
            <Typography color="textPrimary" variant="body1" paragraph align="center">Validating your App&apos;s domain records.</Typography>
            <Typography color="textPrimary" variant="body1" gutterBottom align="center">Once completed, we will display any DNS changes that are required.</Typography>
            <Typography color="textPrimary" variant="body1" align="center" component="span">{viewDnsRecordsText[0]}</Typography>
            <Typography color="textPrimary" variant="body1" align="center" component="span">{viewDnsRecordsText[1]}</Typography>
          </Box>
        </Box>
      );
    }

    if (validations.length > 0) {
      return <Validations app={app} validations={validations} />;
    }

    return (
      <Box margin={{ top: 'small', bottom: 'small' }}>
        <Typography color="textPrimary" variant="subtitle1">
          {
            willRequireMercuryDnsChangesLater
              ? 'This app includes non-PressDNS domains, so you will be prompted to make DNS changes during the Mercury setup process as necessary.'
              : 'Your DNS records are already set correctly, no changes required.'
          }
        </Typography>
      </Box>
    );
  };

  return (
    <Fragment>
      <DialogContent>
        <Box margin={{ bottom: 'small' }}>
          <Typography color="textPrimary" variant="subtitle1"><strong>Your App is now being created!</strong></Typography>
        </Box>
        {getContent()}
      </DialogContent>
      <DialogActions>
        <Box margin={{ top: 'small' }} direction="row" justify="space-between" align="center" flex={1}>
          <div>
            <Button disabled variant="outlined" color="default">
              Back
            </Button>
          </div>
          <Box
            css={{ minWidth: '50%' }}
          >
            <Stepper
              startingStep={1}
              activeStep={step}
              classes={makeStyles({ root: { padding: 0 } })()}
              showLabels={false}
              steps={values.stepperSteps}
            />
          </Box>

          <div>
            <Button
              variant="outlined"
              color="default"
              onClick={() => onClose()}
            >
              Close
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Fragment>
  );
};

export default DNSInstructions;
