import React, { useState, useCallback } from 'react';
import {
  object, func, bool, number,
} from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography } from '@material-ui/core';

import MDView from 'shared/styleguide/atoms/Markdown/MDView';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import { flattenVariables, replaceVariables } from 'shared/utils';
import { consolidateErrors } from 'shared/utils/validation';

import isPagelyAdmin from 'shared/utils/isAdmin';
import * as API from 'shared/utils/redux/constants';
import {
  templateTypes, urgencyTagMap, readStatusMap, TARGET_ID, URGENT, statusColorMap,
} from '../../constants';

import { updateAlertReadStatus, fetchTargetAlerts } from '../../redux/actions';

const AlertItem = ({
  accountId, alertItem, defaultExpanded, fetchTargetAlerts, updateAlertReadStatus, user,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded || false);
  const [readStatus, setReadStatus] = useState(API.PRISTINE);
  const [readStatusErrors, setReadStatusErrors] = useState(null);

  const isAdmin = isPagelyAdmin(user);

  const handleUpdateReadStatus = async (readStatus) => {
    setReadStatus('loading');
    try {
      await updateAlertReadStatus(alertItem.id, readStatus);
      setReadStatus(API.SUCCESS);
      setReadStatusErrors(null);
      fetchTargetAlerts(accountId, { includeTemplate: templateTypes.PUSH });
    } catch (err) {
      setReadStatus('failed');
      if (err.statusCode !== 422) {
        setReadStatusErrors('Unable to update alert read status');
      } else {
        setReadStatusErrors(consolidateErrors(err));
      }
    }
  };

  const alertMessage = () => {
    const { message, prefix } = alertItem.context;

    const templateVariables = {
      [TARGET_ID]: alertItem.targetId.split(':')[1],
      ...flattenVariables(alertItem.context),
    };

    const alertMessage = alertItem.template ? replaceVariables(alertItem.template, templateVariables) : message;

    return (!isAdmin && prefix) ? `${prefix}\n\n${alertMessage}` : alertMessage;
  };

  const memoizedAlertMessage = useCallback(alertMessage, [isAdmin, alertItem]);

  return (
    <Accordion square expanded={expanded} onChange={() => setExpanded(!expanded)} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
        <Box flex={1} justify="space-between" direction="row">
          <Box direction="row" gap="large" align="center">
            <Box>
              <Typography variant="subtitle1">
                {alertItem.alertType}
              </Typography>
            </Box>
            <Box direction="row">
              <GhostTag color={statusColorMap[alertItem.status]}>{alertItem.status}</GhostTag>
              {
                alertItem.urgency === URGENT
                && (
                  <GhostTag color={urgencyTagMap[alertItem.urgency]}>{alertItem.urgency}</GhostTag>
                )
              }
            </Box>
          </Box>
          <Box direction="row" align="center">
            <Typography variant="subtitle1" color="textSecondary">
              {moment.utc(alertItem.createdAt?.date || alertItem.createdAt).format('LLL')}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box direction="column" flex={1}>
          <Box flex={1}>
            {
              alertItem.template
              && (
                <Box flex={1} margin={{ bottom: 'small' }}>
                  <MDView value={memoizedAlertMessage()} />
                </Box>
              )
            }
            {
              !isAdmin
              && (
                <Box direction="row" justify="flex-end" align="center">
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => handleUpdateReadStatus(!alertItem.readStatus ? readStatusMap.READ : readStatusMap.UNREAD)}
                      disabled={readStatus === API.LOADING}
                    >
                      {!alertItem.readStatus ? 'Mark As Read' : 'Mark As Unread'}
                    </Button>
                  </Box>
                </Box>
              )
            }
            {
              readStatusErrors
              && (
                <Box align="center" margin={{ top: 'medium' }}>
                  <ErrorText>{readStatusErrors}</ErrorText>
                </Box>
              )
            }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

AlertItem.propTypes = {
  accountId: number,
  alertItem: object,
  defaultExpanded: bool,
  fetchTargetAlerts: func,
  params: object,
  updateAlertReadStatus: func,
  user: object,
};

export default connect(
  (state) => ({ user: state.user.user }),
  {
    updateAlertReadStatus,
    fetchTargetAlerts,
  },
)(AlertItem);
