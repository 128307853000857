import React from 'react';
import { ProgressBar } from 'shared/styleguide/molecules/ProgressView';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { localizeCurrency } from 'shared/utils';
import { additionalAddonsFilter } from './addonUtils';
import { limitedAddonKeys } from './constants';
import { PlanData } from './types';

const AddonRowWithStatusBar = ({
  title, description, cost, percentage,
}: {title: string; description: string; cost: number; percentage: number }) => {
  const displayDanger = percentage >= 85;
  return (
    <div
      data-testid="limited-addon"
      css={{
        paddingBottom: remMapper('xxsmall'),
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: remMapper('small'),
      }}
    >
      <div css={{
        flex: 1,
        marginRight: remMapper('medium'),
      }}
      >
        <span css={{ marginBottom: remMapper('xxsmall'), display: 'block' }}><strong>{title}</strong></span>
        <span css={{ marginBottom: remMapper('xxsmall'), display: 'block' }}>{description}</span>
        <ProgressBar percentage={percentage} displayDanger={displayDanger} />
      </div>
      <div css={{ display: 'flex' }}><strong css={{ alignSelf: 'flex-end' }}>{localizeCurrency(cost / 100)}</strong>
      </div>
    </div>
  );
};

const LimitedAddon = (
  {
    addons,
    limits,
    limitName,
  }: {
    addons: PlanData['limitedAddons'];
    limits: PlanData['limits'];
    limitName: 'cdn' | 'apps' | 'bandwidth' | 'disk';
  },
) => {
  let addonKey;
  let title;
  let unit;
  switch (limitName) {
    case 'apps':
      addonKey = limitedAddonKeys.wordpress;
      title = 'WordPress Apps';
      unit = '';
      break;
    case 'disk':
      addonKey = limitedAddonKeys.storage;
      title = 'Disk';
      unit = 'GiB';
      break;
    case 'cdn':
      addonKey = limitedAddonKeys.cdn;
      title = 'CDN';
      unit = 'GiB';
      break;
    case 'bandwidth':
      addonKey = limitedAddonKeys.bandwidth;
      title = 'Bandwidth';
      unit = 'GiB';
      break;
    default:
      throw new Error(`Unable to display limited addon details for given limit name: ${limitName}`);
  }
  const addonEntries = Object.entries(addons).filter(([key, _]) => key.toLowerCase().match(addonKey));
  const cost = addonEntries.reduce((total, [_, addon]) => total + addon.amount || 0, 0);
  let includedAddonUnits = addonEntries.filter(([_, addon]) => !additionalAddonsFilter(addon)).reduce((total, [_, addon]) => total + addon.quantity, 0);
  includedAddonUnits = Number(includedAddonUnits.toFixed(2));
  let additionalAddonUnits = addonEntries.filter(([_, addon]) => additionalAddonsFilter(addon)).reduce((total, [_, addon]) => total + addon.quantity, 0);
  additionalAddonUnits = Number(additionalAddonUnits.toFixed(2));
  const used = limits[limitName].used ? Number(limits[limitName].used.toFixed(2)) : 0;
  const percentage = (limits[limitName].used / limits[limitName].limit) * 100;
  let description = `${includedAddonUnits} ${unit} included, ${used} ${unit} used`;
  if (additionalAddonUnits > 0) {
    description = `${includedAddonUnits} ${unit} included, ${additionalAddonUnits} ${unit} additional, ${used} ${unit} used`;
  }
  return (
    <AddonRowWithStatusBar title={title} cost={cost} percentage={percentage} description={description} />
  );
};

export const LimitedAddons = ({ addons, limits }: { addons: PlanData['limitedAddons']; limits: PlanData['limits'] }): JSX.Element => {
  return (
    <div>
      {
        (['apps', 'disk', 'bandwidth', 'cdn'] as const).map((limitName, i) => (
          <LimitedAddon key={`limitName-${i}`} addons={addons} limits={limits} limitName={limitName} />
        ))
      }
    </div>
  );
};
