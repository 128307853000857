import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { localizeCurrency } from 'shared/utils';
import { PlanData } from './types';

export const PlanHeader = ({ planData }: { planData: PlanData }): JSX.Element => {
  return (
    <>
      <div>
        <Typography variant="h3"><strong>{planData.planName}</strong></Typography>
        Renews {planData.billingFrequency}, next renewal will be on {moment(planData.nextBillingDate).format('MMMM Do YYYY')}
      </div>
      <div css={{
        textAlign: 'right',
      }}
      >
        <Typography variant="h3"><strong>{localizeCurrency(planData.planCost)}</strong></Typography>
        <div css={{
          textTransform: 'capitalize',
        }}
        >
          {planData.billingFrequency} billing
        </div>
      </div>
    </>
  );
};
