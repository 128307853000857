import React, { Fragment } from 'react';
import { array, string, object } from 'prop-types';
import { Divider, Typography, TextField } from '@material-ui/core';

import Loading from 'shared/styleguide/atoms/Loading';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import { consolidateErrors } from 'shared/utils/validation';

import { hashCode } from 'shared/utils';
// import { redirectTesterHops as hops } from '../../../mocks';
import Hop from './Hop';

const Hops = ({ status, hops, error }) => {
  return (
    <Fragment>
      <Divider />
      {
        error?.current && (
          <Box padding="medium">
            <ErrorText>
              {consolidateErrors(error.current)}
            </ErrorText>
          </Box>
        )
      }
      {
        status === 'loading' && (
          <Box padding="medium">
            <Loading />
          </Box>
        )
      }
      {
        status === 'success' && (
          <Box padding="medium" direction="column" gap="small">
            <Typography variant="subtitle1" gutterBottom>Result</Typography>
            {
                hops.map((hop) => {
                  return (
                    <Box key={hashCode(hop.url)}>
                      <Hop {...hop} />
                    </Box>
                  );
                })
              }
            <Box>
              <Typography variant="subtitle1" gutterBottom>Destination URL</Typography>
              <Box>
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={[...hops].slice(-1)[0].url}
                />

              </Box>
            </Box>
          </Box>
        )
      }

    </Fragment>
  );
};

Hops.propTypes = {
  error: object,
  hops: array,
  status: string,
};

export default Hops;
