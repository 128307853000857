import React, { Component } from 'react';
import { func, object } from 'prop-types';

import ArrowIcon from '@material-ui/icons/ArrowRightAlt';

import {
  DialogTitle,
  DialogContent,
  Dialog,
  Grid,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';

import Select from 'shared/styleguide/atoms/Select/Select';

import SimpleRedirect from './Templates/Simple';
import scss from './Editor.scss';

// this will get configs (display)
// and make new configs
// and save configs
// and apply configs

export default class AddRedirect extends Component {
  static propTypes = {
    app: object,
    onAddConfig: func,
  };

  state = {
    sourcePath: '',
    destinationPath: '',
    modalOpen: false,
    template: { value: 'Simple', label: 'Simple' },
  };

  handleAdd = () => {
    const { sourcePath, destinationPath } = this.state;

    // set up the config object
    const pathToPath = {
      [sourcePath]: destinationPath,
    };

    this.setState({ sourcePath: '', destinationPath: '' });
    this.props.onAddConfig(pathToPath);
  };

  handleAddConfig = () => {
    // we assume that the newConfig is already properly configured
    // do some config magic

    // cleanup
    // clear inline form
    this.setState({ modalOpen: false, sourcePath: '', destinationPath: '' });
  };

  handleSetState = (key, value) => {
    this.setState({ [key]: value });
  };

  renderModal = () => {
    const { modalOpen, template } = this.state;

    return (
      <div>
        <Dialog
          open={modalOpen}
          onClose={() => { this.handleSetState('modalOpen', false); }}
          scroll="paper"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title"><span style={{ color: 'white' }}>New custom redirect</span></DialogTitle>
          <DialogContent>
            <div className={scss.rowSection}>
              <Grid
                container
                spacing={32}
                alignItems="center"
              >
                <Grid className={scss.leftColumn} item md={2}>
                  <Typography variant="h2">
                    Type:
                  </Typography>
                </Grid>
                <Grid item md={10}>
                  <Select
                    options={[{ value: 'Simple', label: 'Simple' }]}
                    value={template}
                    onChange={(value) => { this.handleSetState('template', value); }}
                  />
                </Grid>
              </Grid>
            </div>
            <SimpleRedirect
              appName={this.props.app.name}
              onAddConfig={this.handleAddConfig}
              onClose={() => { this.handleSetState('modalOpen', false); }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  render() {
    const { sourcePath, destinationPath } = this.state;
    return (
      <div>
        {/* <Button color="primary" onClick={() => { this.handleSetState('modalOpen', true); }}>Open modal</Button> */}
        <Grid container alignItems="baseline" justifyContent="space-around">
          <TextField
            className={scss.textfield}
            id="source-path"
            placeholder="Source Path"
            value={sourcePath}
            onChange={(e) => this.handleSetState('sourcePath', e.target.value)}
            margin="dense"
          />
          <ArrowIcon style={{ margin: '0 8px' }} size="small" />
          <TextField
            className={scss.textfield}
            id="dest-path"
            placeholder="Destination Path"
            value={destinationPath}
            onChange={(e) => this.handleSetState('destinationPath', e.target.value)}
            margin="dense"
          />
          <Button style={{ minWidth: 96 }} onClick={this.handleAdd} variant="outlined" disabled={!sourcePath || !destinationPath}>
            Add
          </Button>
        </Grid>
        {/* <Button onClick={() => this.setState({ modalOpen: true })}>More Options</Button>
        { this.renderModal()} */}
      </div>
    );
  }
}
