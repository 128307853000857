import React from 'react';
import {
  object, string,
} from 'prop-types';

import { Typography, Breadcrumbs } from '@material-ui/core';
import Link from '@material-ui/core/Link';

import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';
import { ErrorText } from 'shared/styleguide/typography';
import Empty from 'shared/styleguide/atoms/Empty';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import CollabRedirect from 'shared/styleguide/organisms/CollabRedirect';

import * as MODES from '../constants';

const GetComponentByMode = (account, mode, message) => {
  switch (mode) {
    case MODES.FAILED:
      return <Empty><ErrorText>{message}</ErrorText></Empty>;
    case MODES.EMPTY:
      return <Empty>{message}</Empty>;
    case MODES.COLLAB:
      return <CollabRedirect account={account} text="manage disks" />;
    case MODES.LOADING:
    default:
      return <Loading />;
  }
};

const DiskFallbackHeader = ({ account, mode, message }) => {
  return (
    <Box>
      <Typography color="textPrimary" variant="h1" gutterBottom>Disk</Typography>
      <Box margin={{ top: 'medium' }} style={{ width: '100%' }}>
        {
          GetComponentByMode(account, mode, message)
        }
      </Box>
    </Box>
  );
};

DiskFallbackHeader.propTypes = {
  account: object,
  message: string,
  mode: string,
};

export default DiskFallbackHeader;
