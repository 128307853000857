import React, { useContext } from 'react';
import { node, number } from 'prop-types';
import AccountContext from 'shared/modules/account/context/AccountContext';
import CircleLink from 'shared/styleguide/atoms/Links/CircleLink';

export const PaymentLink = ({ children, ...props }) => {
  const account = useContext(AccountContext);
  return <CircleLink color="secondary" to={`/account/${account.id}/billing/payment`} {...props}>{children}</CircleLink>;
};

PaymentLink.propTypes = {
  accountId: number,
  children: node,
};
export const InvoicesLink = ({ children, ...props }) => {
  const account = useContext(AccountContext);
  return <CircleLink color="secondary" to={`/account/${account.id}/billing/invoices`} {...props}>{children}</CircleLink>;
};

InvoicesLink.propTypes = {
  accountId: number,
  children: node,
};

PaymentLink.displayName = 'PaymentLink';

export const PlanLink = ({ children }) => {
  const accountId = useContext(AccountContext).id;
  return <CircleLink color="secondary" to={`/account/${accountId}/billing/plan`}>{children}</CircleLink>;
};

PlanLink.propTypes = {
  accountId: number,
  children: node,
};

PlanLink.displayName = 'PlanLink';
