/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Card } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import { roleStrings } from 'shared/modules/permissions/user/actions';
import AccountAccess from '../../../account/components/AccountAccess';

const OtherAccounts = () => {
  return (
    <div>
      <Typography variant="h5" color="textPrimary" gutterBottom>Are you looking for a different account?</Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>You have access to the following accounts:</Typography>
      <Card>
        <Box padding="small">
          <AccountAccess gridProps={{ xs: 12, sm: 4, lg: 3 }}>
            {(props) => {
              return (
                <Link to={`/account/${props.targetId}`}>

                  <Typography color="textPrimary" variant="body1">{props.accessToCompany}</Typography>
                  <Typography color="textSecondary" variant="body2">{props.accessToName}</Typography>
                  <Typography color="textSecondary" variant="body2">{roleStrings[props.role]}</Typography>

                  {
                    props.mfaRequired && (
                      <Box padding={{ left: 'small', bottom: 'xsmall' }}>
                        <Typography variant="caption" color="secondary">This account requires MFA</Typography>
                      </Box>
                    )
                  }
                </Link>
              );
            }}
          </AccountAccess>

        </Box>
      </Card>
    </div>
  );
};

export default OtherAccounts;
