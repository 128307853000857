import React, { useEffect } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';

import SnackBar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

import {
  getReleaseNotes,
} from 'shared/modules/releasenotes/redux/actions';
import Layout from 'shared/styleguide/molecules/Layout';
import colors from 'shared/styleguide/theme';

import ViewReleaseNotes from '../components/ViewReleaseNotes';

const StyledSnackBar = withStyles({
  root: {
    backgroundColor: colors.navy40,
  },
})(SnackbarContent);

const ListReleaseNotes = ({ getReleaseNotes, releaseNotes }) => {
  useEffect(() => {
    getReleaseNotes();
  }, [getReleaseNotes]);

  return (
    <Layout title="Release Notes">
      <ViewReleaseNotes releaseNotes={releaseNotes} />
      {
        releaseNotes && releaseNotes.apiErrors
        && (
          <SnackBar
            key="releaseNoteApiError"
            open
            autoHideDuration={20000}
          >
            <StyledSnackBar message={releaseNotes.apiErrors} />
          </SnackBar>
        )
      }
    </Layout>
  );
};

ListReleaseNotes.propTypes = {
  getReleaseNotes: func,
  releaseNotes: object.isRequired,
};

export default connect(
  (state) => ({ releaseNotes: state.releaseNotes }),
  {
    getReleaseNotes,
  },
)(ListReleaseNotes);
