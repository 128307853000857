import React, { Fragment, useEffect } from 'react';
import { object, string, func } from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { getServerInfo } from 'shared/modules/server/redux/actions';

import DiskSummaryLoader from './routes/DiskSummary/DiskSummaryLoader';
import LegacyDiskSummaryLoader from './routes/DiskSummary/Legacy/DiskSummaryLoader';
import P20Graphs from './routes/DiskSummary/P20Summary';
import FullReport from './routes/FullReport';
import DiskFallbackHeader from './components/DiskFallbackHeader';
import * as MODES from './constants';
import AdBlockerBanner from '../../styleguide/organisms/Banners/AdBlocker';

export const DiskUsage = ({
  match, account, getServerInfo,
}) => {
  useEffect(() => {
    if (!account.servers[0]?.label) {
      // this gets called too many times, needs to be debounced
      getServerInfo(account.id);
    }
  }, []);

  if (account.isCollaborator) {
    return <DiskFallbackHeader account={account} mode={MODES.COLLAB} />;
  }

  if (account.servers.length === 0) {
    return <DiskFallbackHeader account={account} mode={MODES.EMPTY} message="No server disk data available" />;
  }

  if (account.isOnP20Account) {
    return (
      <P20Graphs
        account={account}
      />
    );
  }

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={(props) => {
          if (account.billingSource === 'chargebee') {
            return (
              <Fragment>
                <AdBlockerBanner />
                <DiskSummaryLoader
                  {...props}
                  accountId={account.id}
                  account={account}
                />
              </Fragment>
            );
          }

          return (
            <Fragment>
              <AdBlockerBanner />
              <LegacyDiskSummaryLoader
                {...props}
                accountId={account.id}
                account={account}
              />
            </Fragment>
          );
        }}
      />
      <Route
        path={`${match.path}/:serverId/:timestamp/report`}
        render={(props) => (
          <Fragment>
            <AdBlockerBanner />
            <FullReport {...props} parentPath={match.url} />
          </Fragment>
        )}
      />
    </Switch>
  );
};

DiskUsage.propTypes = {
  account: object.isRequired,
  getServerInfo: func,
  match: object.isRequired,
  parentPath: string,
};

export default connect(
  (state) => ({
    account: state.account,
    mountUsage: state.analytics.disk.byMount.usage,
  }),
  {
    getServerInfo,
  },
)(DiskUsage);
