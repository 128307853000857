import qs from 'qs';
import tracker from 'shared/3rdparty/pageTracking';
import makeApiAction, { ApiAction } from 'shared/utils/redux/apiActions';
import type {
  App, Credentials, CustomIp, LegacyDomainAction, SqlServerInfo, Tag,
} from 'shared/types/App';
import type { NumberLike } from 'shared/types/types';
import * as APP from './constants';

interface Data {
  name?: string;
  accountId?: NumberLike;
  primaryDomain?: string;
  multisite?: boolean;
  multisiteType?: string;
}

export const createApp = (data: Data): ApiAction<App> => makeApiAction<App>(
  ({ api }) => {
    tracker.push({
      event: 'atomic_app_created',
      account_id: data?.accountId,
      primaryDomain: data.primaryDomain.toLowerCase(),
    });

    return api().post('/apps', { ...data, name: data.name.toLowerCase(), primaryDomain: data.primaryDomain.toLowerCase() });
  },
  APP.APP_CREATE_REQUEST,
  APP.APP_CREATE_SUCCESS,
  APP.APP_CREATE_FAILURE,
  {},
  true,
);

export const setAppCreateModalOpen = (open: boolean): Record<string, any> => {
  return {
    type: APP.APP_CREATE_MODAL_OPEN,
    open,
  };
};

export const deleteApp = (appId: NumberLike, metadata: Record<string, any>): ApiAction<App> => makeApiAction <App>(
  ({ api }) => {
    tracker.push({
      event: 'atomic_app_deleted',
      account_id: metadata?.accountId,
      appId,
      primaryDomain: metadata?.primaryDomain.toLowerCase(),
    });

    return api().delete(`/apps/${appId}`);
  },
  APP.APP_DELETE_REQUEST,
  APP.APP_DELETE_SUCCESS,
  APP.APP_DELETE_FAILURE,
  { appId },
  true,
);

type ToggleApp = (appId: NumberLike, metadata: Record<string, any>, actionOnly: boolean) => ApiAction<App>;
export const disableApp: ToggleApp = (appId, metadata, actionOnly) => makeApiAction<App>(
  ({ api }) => {
    tracker.push({
      event: 'atomic_app_disabled',
      account_id: metadata?.accountId,
      ...metadata,
    });

    return api().post(`/apps/${appId}/disable`);
  },
  APP.APP_DISABLE_REQUEST,
  APP.APP_DISABLE_SUCCESS,
  APP.APP_DISABLE_FAILURE,
  { appId, metadata },
  true,
  actionOnly,
);

export const enableApp: ToggleApp = (appId, metadata, actionOnly) => makeApiAction<App>(
  ({ api }) => {
    tracker.push({
      event: 'atomic_app_enabled',
      account_id: metadata?.accountId,
      ...metadata,
    });

    return api().post(`/apps/${appId}/activate`);
  },
  APP.APP_ENABLE_REQUEST,
  APP.APP_ENABLE_SUCCESS,
  APP.APP_ENABLE_FAILURE,
  { appId, metadata },
  true,
  actionOnly,
);

interface UpdateData {
  type: 'server-type' | 'php-version';
  appId: NumberLike;
  update: {
    phpVersion?: `php${number}`;
    serverType?: 'nginx' | 'nginx-apache';
  };
}

type UpdateAppConfig = (ud: UpdateData, metadata: Record<string, any>) => ApiAction<App>;
export const updateAppConfig: UpdateAppConfig = ({ appId, type, update }, metadata) => makeApiAction<App>(
  ({ api }) => {
    tracker.push({
      event: 'atomic_app_modified',
      account_id: metadata?.accountId,
      ...metadata,
    });
    return api().post(`/apps/${appId}/${type}`, update);
  },
  APP.APP_UPDATE_CONFIG_REQUEST,
  APP.APP_UPDATE_CONFIG_SUCCESS,
  APP.APP_UPDATE_CONFIG_FAILURE,
);

export const updateApp = ({ appId, update }: UpdateData): ApiAction<App> => makeApiAction<App>(
  ({ api }) => {
    return api().patch(`/apps/${appId}`, update);
  },
  APP.UPDATE_APP_REQUEST,
  APP.UPDATE_APP_SUCCESS,
  APP.UPDATE_APP_FAILURE,
  {},
  true,
);

export const enableSFTP = (appId: NumberLike): ApiAction<App> => makeApiAction<App>(
  ({ api }) => api().post(`/apps/${appId}/ftp`),
  APP.APP_ENABLE_SFTP_REQUEST,
  APP.APP_ENABLE_SFTP_SUCCESS,
  APP.APP_ENABLE_SFTP_FAILURE,
  { ftpEnabled: true },
);

export const disableSFTP = (appId: NumberLike): ApiAction<App> => makeApiAction<App>(
  ({ api }) => api().delete(`/apps/${appId}/ftp`),
  APP.APP_DISABLE_SFTP_REQUEST,
  APP.APP_DISABLE_SFTP_SUCCESS,
  APP.APP_DISABLE_SFTP_FAILURE,
  { ftpEnabled: false },
);

interface AppIdObj { appId: NumberLike }

export const getSqlInfo = ({ appId }: AppIdObj): ApiAction<SqlServerInfo> => makeApiAction<SqlServerInfo>(
  ({ api }) => api().get(`/apps/${appId}/sql`),
  APP.APP_GET_SQL_INFO_REQUEST,
  APP.APP_GET_SQL_INFO_SUCCESS,
  APP.APP_GET_SQL_INFO_FAILURE,
);

export const getCustomIps = ({ appId }: AppIdObj): ApiAction<CustomIp[]> => makeApiAction<CustomIp[]>(
  ({ api }) => api().get(`/apps/${appId}/ips`),
  APP.APP_GET_IP_LIST_REQUEST,
  APP.APP_GET_IP_LIST_SUCCESS,
  APP.APP_GET_IP_LIST_FAILURE,
  {
    appId,
  },
  true,
);

export const fetchApp = (appId: NumberLike, additionalParams: Record<string, any>): ApiAction<App> => makeApiAction<App>(
  ({ api }) => {
    const params = qs.stringify({
      includePrimaryServer: 1,
      includeAliases: 1,
      ...additionalParams,
    }, { addQueryPrefix: true });

    return api().get(`/apps/${appId}${params}`);
  },
  APP.APP_FETCH_REQUEST,
  APP.APP_FETCH_SUCCESS,
  APP.APP_FETCH_FAILURE,
  { appId, includePrimaryServer: true, includeAliases: true },
  true,
);

export const resetApp = (): { type: 'APP_RESET' } => ({
  type: APP.APP_RESET,
});

export const fetchTagsForApp = (appId: NumberLike): ApiAction<Tag[]> => makeApiAction<Tag[]>(
  ({ api }) => api().get(`/metadata/apps/${appId}/tags`),
  APP.APP_TAGS_FETCH_REQUEST,
  APP.APP_TAGS_FETCH_SUCCESS,
  APP.APP_TAGS_FETCH_FAILURE,
  { appId },
);

export const fetchCredentialsForApp = (appId: NumberLike): ApiAction<Credentials> => makeApiAction<Credentials>(
  ({ api }) => api().get(`/apps/${appId}/credentials`),
  APP.APP_CREDENTIALS_FETCH_REQUEST,
  APP.APP_CREDENTIALS_FETCH_SUCCESS,
  APP.APP_CREDENTIALS_FETCH_FAILURE,
  { appId },
);

export const fetchFtpCredentialsForApp = (appId: NumberLike): ApiAction<Credentials> => makeApiAction<Credentials>(
  ({ api }) => api().get(`/apps/${appId}/ftp/credentials`),
  APP.APP_FTP_CREDENTIALS_FETCH_REQUEST,
  APP.APP_FTP_CREDENTIALS_FETCH_SUCCESS,
  APP.APP_FTP_CREDENTIALS_FETCH_FAILURE,
  { appId },
);

type FlushAliasCache = (appId: NumberLike, aliasId: NumberLike) => ApiAction<LegacyDomainAction>;
export const flushAliasCache: FlushAliasCache = (appId, aliasId) => makeApiAction<LegacyDomainAction>(
  ({ api }) => api().post(`/apps/${appId}/aliases/${aliasId}/presscache`),
  APP.APP_DOMAIN_FLUSH_CACHE_REQUEST,
  APP.APP_DOMAIN_FLUSH_CACHE_SUCCESS,
  APP.APP_DOMAIN_FLUSH_CACHE_FAILURE,
  {
    appId,
    aliasId,
  },
);
interface ResetAppOptions {
  appId: NumberLike;
  data: Record<string, any>;
}
export const resetAppOptions = ({ appId, data }: ResetAppOptions): ApiAction<LegacyDomainAction> => makeApiAction<LegacyDomainAction>(
  ({ api }) => api().post(`/apps/${appId}/reset-options`, { actions: data }),
  APP.APP_RESET_OPTIONS_REQUEST,
  APP.APP_RESET_OPTIONS_SUCCESS,
  APP.APP_RESET_OPTIONS_FAILURE,
  null,
  true, // return promise
);
