import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Accordion,
} from '@material-ui/core';

import { useMergeState } from 'shared/hooks/useMergeState';
import Box from 'shared/styleguide/atoms/Box';

import { ButtonStatus } from 'shared/styleguide/atoms/Buttons/NewButton';
import { generateValidationRules, getValidationForDomain, triggerValidation } from '../../../redux/dns/actions';
import ValidationItemDetails from './ValidationItemDetails';
import ValidationItemSummary from './ValidationItemSummary';

interface Props {
  type: string;
  domain: Record<string, any>;
  job: Record<string, any>;
  defaultExpanded: boolean | null;
}

const ValidationItem = ({
  type = '', domain, job, defaultExpanded = undefined,
}: Props) => {
  const dispatch = useDispatch();
  const { state, mergeState } = useMergeState({
    expanded: defaultExpanded !== undefined ? defaultExpanded : Boolean(!domain.validated),
    generateStatus: 'pristine',
    triggerStatus: 'pristine',
    fetchStatus: 'pristine',
    validationStatus: 'pristine',
  });

  const {
    validationStatus, fetchStatus, generateStatus, triggerStatus, expanded,
  } = state;

  let domainStatus = domain.validated ? 'valid' : 'invalid';
  if (!['success', 'pristine'].includes(validationStatus) || domain.validated === null) {
    domainStatus = 'validating';
  }

  const generateValidationsForDomain = async () => {
    mergeState({ generateStatus: 'loading' });
    await dispatch(generateValidationRules({ domainId: domain.domainId }));
    mergeState({ generateStatus: 'pristine' });
  };

  const triggerValidationsForDomain = async () => {
    mergeState({
      triggerStatus: 'loading',
      fetchStatus: 'pristine',
    });
    await dispatch(triggerValidation({ appId: 'doesnt matter', domainId: domain.domainId }));
    mergeState({
      triggerStatus: 'pristine',
    });
  };

  const getValidationsForDomain = async () => {
    mergeState({
      fetchStatus: 'loading',
      validationStatus: 'loading',
    });
    await dispatch(getValidationForDomain(domain.domainId));
    // we have fetched the validation blob
    mergeState({
      fetchStatus: 'success',
      validationStatus: 'success',
    });
  };

  useEffect(() => {
    const status = job?.overallStatus || domain.jobStatus;
    if (['PENDING', 'RUNNING'].includes(status) && ['success', 'pristine'].includes(validationStatus) && fetchStatus === 'pristine') {
      // validation job is running
      mergeState({ validationStatus: status });
    }

    if (status === 'SUCCESS' && fetchStatus === 'pristine') {
      // validation job completed, but we still need the entire validation blob
      getValidationsForDomain();
    }
  }, [job, domain.jobId, domain.jobStatus, validationStatus, fetchStatus]);

  useEffect(() => {
    if (domain?.partial && fetchStatus !== 'loading') {
      // the job is updated by pusher but is missing data
      // so we need to refetch
      getValidationsForDomain();
    }
  }, [domain, fetchStatus]);

  const showValidationItem = domain.validationRules.records.reduce((acc, r) => {
    return r.records.length > 0;
  }, false);

  if (!showValidationItem) {
    // no records, so don't display anthing
    // we're doing fancy pressDns record consolidating that only
    // shows NS records for the apex
    // so this is a possibility
    return null;
  }

  return (
    <Box direction="column">
      <Accordion expanded={expanded} onChange={() => mergeState({ expanded: !expanded })}>
        <ValidationItemSummary
          domain={domain}
          domainStatus={domainStatus}
          type={type}
        />
        <ValidationItemDetails
          generateStatus={generateStatus as ButtonStatus}
          triggerStatus={triggerStatus as ButtonStatus}
          domain={domain}
          generateValidationsForDomain={generateValidationsForDomain}
          triggerValidationsForDomain={triggerValidationsForDomain}
        />
      </Accordion>
    </Box>
  );
};

export default ValidationItem;
