import tracker from 'shared/3rdparty/pageTracking';
import { getHttp } from 'shared/http';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as DNS from './constants';

export const getDNSZones = ({ accountId }) => makeApiAction(
  ({ api }) => api()
    .get(`/dns/account/${accountId}/zone`),
  DNS.GET_DNS_ZONE_REQUEST,
  DNS.GET_DNS_ZONE_SUCCESS,
  DNS.GET_DNS_ZONE_FAILURE,
  { accountId },
  true,
);

export const createZone = ({ accountId, appId, domain }) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_dns_zone_created',
    });

    return api()
      .post(`/dns/account/${accountId}/app/${appId}/zone`, { domain, addDefaultRecords: true });
  },
  DNS.CREATE_DNS_ZONE_REQUEST,
  DNS.CREATE_DNS_ZONE_SUCCESS,
  DNS.CREATE_DNS_ZONE_FAILURE,
  { accountId },
);

export const deleteZone = ({ accountId, appId, zoneId }) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_dns_zone_deleted',
    });
    return api()
      .delete(`/dns/account/${accountId}/app/${appId}/zone/${zoneId}`);
  },
  DNS.DELETE_DNS_ZONE_REQUEST,
  DNS.DELETE_DNS_ZONE_SUCCESS,
  DNS.DELETE_DNS_ZONE_FAILURE,
  { accountId, extId: zoneId },
);

// records

export const getDNSfromAppId = ({ accountId, appId }) => makeApiAction(
  ({ api }) => api()
    .get(`/dns/account/${accountId}/app/${appId}/zone`),
  DNS.GET_DNS_RECORD_REQUEST,
  DNS.GET_DNS_RECORD_SUCCESS,
  DNS.GET_DNS_RECORD_FAILURE,
  { accountId, appId },
);

export const getDNSRecords = ({ accountId, zoneId }) => makeApiAction(
  ({ api }) => api()
    .get(`/dns/account/${accountId}/zone/${zoneId}`),
  DNS.GET_DNS_RECORD_REQUEST,
  DNS.GET_DNS_RECORD_SUCCESS,
  DNS.GET_DNS_RECORD_FAILURE,
  { accountId, zoneId },
);

export const updateDNSRecord = ({
  accountId, zoneId, appId, name, type, values, ttl,
}) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_update_dns_record',
    });

    return api()
      .patch(`/dns/account/${accountId}/app/${appId}/zone/${zoneId}`, {
        name,
        type,
        values,
        ttl: parseInt(ttl, 10),
      });
  },
  DNS.UPDATE_DNS_RECORD_REQUEST,
  DNS.UPDATE_DNS_RECORD_SUCCESS,
  DNS.UPDATE_DNS_RECORD_FAILURE,
  { accountId },
  true,
);

export const addGoogleRecords = ({
  accountId, zoneId, appId,
}) => makeApiAction(
  ({ api }) => api()
    .post(`/dns/account/${accountId}/app/${appId}/zone/${zoneId}/google`),
  DNS.UPDATE_DNS_RECORD_REQUEST,
  DNS.UPDATE_DNS_RECORD_SUCCESS,
  DNS.UPDATE_DNS_RECORD_FAILURE,
  { accountId },
  true,
);

export const deleteDNSRecord = ({
  accountId, zoneId, name, type, appId,
}) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_delete_dns_record',
    });
    return api()
      .delete(`dns/account/${accountId}/app/${appId}/zone/${zoneId}/${type}/${name}`);
  },
  DNS.DELETE_DNS_RECORD_REQUEST,
  DNS.DELETE_DNS_RECORD_SUCCESS,
  DNS.DELETE_DNS_RECORD_FAILURE,
  { accountId },
);

export const resetDNSRecords = () => ({
  type: DNS.DNS_RECORDS_RESET,
});

export const fetchAvailability = (accountId, domains) => makeApiAction(
  ({ api }) => api().post('dns/availability', {
    domains,
    accountId,
  }),
  DNS.FETCH_AVAILIBILITY_REQUEST,
  DNS.FETCH_AVAILIBILITY_SUCCESS,
  DNS.FETCH_AVAILIBILITY_FAILURE,
  {},
  true,
);

export const pointToCdn = (domainId) => makeApiAction(
  ({ api }) => api().patch(`dns/domains/${domainId}/point-cdn`, {}),
  DNS.POINT_TO_CDN_REQUEST,
  DNS.POINT_TO_CDN_SUCCESS,
  DNS.POINT_TO_CDN_FAILURE,
  { domainId },
  true,
);

export const revertDns = (domainId) => makeApiAction(
  ({ api }) => api().post(`dns/domains/${domainId}/revert`, {}),
  DNS.REVERT_DNS_TO_PREVIOUS_REQUEST,
  DNS.REVERT_DNS_TO_PREVIOUS_SUCCESS,
  DNS.REVERT_DNS_TO_PREVIOUS_FAILURE,
  { domainId },
  true,
);
