import React, { Fragment } from 'react';
import { array, func } from 'prop-types';

import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { PCDN_REGEX } from 'shared/modules/dns/constants';

const ValidationWrapper = ({ domains, onRequestValidation }) => {
  const handleRequestValidation = () => {
    onRequestValidation(domains
      .filter((d) => !d.fqdn?.match(PCDN_REGEX))
      .reduce((acc, current) => {
        acc[current.id] = true;
        return acc;
      }, {}));
  };

  return (
    <>
      <Box direction="row" align="center" justify="space-between">
        <Box padding="xxsmall">
          <Typography
            variant="caption"
            color="textSecondary"
          >
            A <strong>Valid Hosting Status</strong> means that all of our validation rules are passing.
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            You can view validation rules for a domain once you click &quot;Validate&quot;.
          </Typography>
        </Box>
        {
          Boolean(domains.length) && (
            <Box direction="row" padding="small" justify="flex-end">
              <Box justify="flex-end">
                <Button
                  variant="outlined"
                  onClick={handleRequestValidation}
                >
                  Validate All Domains
                </Button>
              </Box>
            </Box>
          )
        }
      </Box>
    </>
  );
};

ValidationWrapper.propTypes = {
  domains: array,
  onRequestValidation: func.isRequired,
};

export default ValidationWrapper;
