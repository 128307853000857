export const GET_DNS_ZONE_REQUEST = 'GET_DNS_ZONE_REQUEST';
export const GET_DNS_ZONE_SUCCESS = 'GET_DNS_ZONE_SUCCESS';
export const GET_DNS_ZONE_FAILURE = 'GET_DNS_ZONE_FAILURE';

export const CREATE_DNS_ZONE_REQUEST = 'CREATE_DNS_ZONE_REQUEST';
export const CREATE_DNS_ZONE_SUCCESS = 'CREATE_DNS_ZONE_SUCCESS';
export const CREATE_DNS_ZONE_FAILURE = 'CREATE_DNS_ZONE_FAILURE';

export const DELETE_DNS_ZONE_REQUEST = 'DELETE_DNS_ZONE_REQUEST';
export const DELETE_DNS_ZONE_SUCCESS = 'DELETE_DNS_ZONE_SUCCESS';
export const DELETE_DNS_ZONE_FAILURE = 'DELETE_DNS_ZONE_FAILURE';

export const DNS_ZONES_RESET = 'DNS_ZONES_RESET';

// records

export const GET_DNS_RECORD_REQUEST = 'GET_DNS_RECORD_REQUEST';
export const GET_DNS_RECORD_SUCCESS = 'GET_DNS_RECORD_SUCCESS';
export const GET_DNS_RECORD_FAILURE = 'GET_DNS_RECORD_FAILURE';

export const UPDATE_DNS_RECORD_REQUEST = 'UPDATE_DNS_RECORD_REQUEST';
export const UPDATE_DNS_RECORD_SUCCESS = 'UPDATE_DNS_RECORD_SUCCESS';
export const UPDATE_DNS_RECORD_FAILURE = 'UPDATE_DNS_RECORD_FAILURE';

export const DELETE_DNS_RECORD_REQUEST = 'DELETE_DNS_RECORD_REQUEST';
export const DELETE_DNS_RECORD_SUCCESS = 'DELETE_DNS_RECORD_SUCCESS';
export const DELETE_DNS_RECORD_FAILURE = 'DELETE_DNS_RECORD_FAILURE';

export const DNS_RECORDS_RESET = 'DNS_RECORDS_RESET';

export const FETCH_AVAILIBILITY_REQUEST = 'FETCH_AVAILIBILITY_REQUEST';
export const FETCH_AVAILIBILITY_SUCCESS = 'FETCH_AVAILIBILITY_SUCCESS';
export const FETCH_AVAILIBILITY_FAILURE = 'FETCH_AVAILIBILITY_FAILURE';

export const POINT_TO_CDN_REQUEST = 'POINT_TO_CDN_REQUEST';
export const POINT_TO_CDN_SUCCESS = 'POINT_TO_CDN_SUCCESS';
export const POINT_TO_CDN_FAILURE = 'POINT_TO_CDN_FAILURE';

export const REVERT_DNS_TO_PREVIOUS_REQUEST = 'REVERT_DNS_TO_PREVIOUS_REQUEST';
export const REVERT_DNS_TO_PREVIOUS_SUCCESS = 'REVERT_DNS_TO_PREVIOUS_SUCCESS';
export const REVERT_DNS_TO_PREVIOUS_FAILURE = 'REVERT_DNS_TO_PREVIOUS_FAILURE';
