import React from 'react';
import GeneralIcon from '@material-ui/icons/Description';
import CredentialsIcon from '@material-ui/icons/LockOutlined';
import ConfigIcon from '@material-ui/icons/Dns';
import ContactIcon from '@material-ui/icons/ContactMail';
import IssueIcon from '@material-ui/icons/ReportProblem';
import EventIcon from '@material-ui/icons/Event';
import ReportIcon from '@material-ui/icons/Assessment';
import PromiseIcon from '@material-ui/icons/Loyalty';
import {
  TYPE_CONTACT,
  TYPE_CREDENTIALS,
  TYPE_ISSUE,
  TYPE_CONFIG,
  TYPE_REPORT,
  TYPE_EVENT,
  TYPE_PROMISE,
  TYPE_MIGRATION,
  TYPE_SHARED,
} from 'admin/modules/notes/constants';

export const IconMap = (type) => {
  switch (type) {
    case TYPE_CONTACT:
    case TYPE_SHARED:
      return ContactIcon;
    case TYPE_EVENT:
      return EventIcon;
    case TYPE_REPORT:
      return ReportIcon;
    case TYPE_CONFIG:
      return ConfigIcon;
    case TYPE_ISSUE:
      return IssueIcon;
    case TYPE_PROMISE:
      return PromiseIcon;
    case TYPE_MIGRATION:
    case TYPE_CREDENTIALS:
      return CredentialsIcon;
    default:
      return GeneralIcon;
  }
};

const NoteIcon = (props) => {
  const Icon = IconMap(props.type);
  return <Icon style={props.style} {...props} />;
};

export default NoteIcon;
