export default class UrlProps {
  constructor(address) {
    this.source = address;
    this.address = this.source.trim();
    this.wildcardCount = (address.match(/\*/g) || []).length;
    this.setUrlParts();
    this.setPathParts();
  }

  isRelativePath = () => {
    const regex = /^[/]{1}[^/.]+/;
    const output = this.address.match(regex);
    if (output !== null && output.length > 0) {
      return true;
    }
  };

  getHostnamePath = () => {
    const regex = /^([A-z0-9-.]+\.[A-z0-9-]{2,})([/].*)$/;
    const output = this.address.match(regex);
    if (output !== null && output[1] !== '' && output[2] !== '') {
      return [output[1], output[2]];
    }
  };

  isSchemeHostnamePath = () => {
    const regex = /(http[s]?){1}/;
    const output = this.address.match(regex);
    if (output !== null && output[0] !== '') {
      return true;
    }
  };

  setUrlParts = () => {
    this.trailingSlash = false;

    if (this.address.substring(this.address.length - 1) === '/') {
      this.address = this.address.substring(0, this.address.length - 1);
      this.trailingSlash = true;
    }

    if (this.address.substring(this.address.length - 3) === '[/]') {
      this.address = this.address.substring(0, this.address.length - 3);
      this.trailingSlash = 'optional';
    }

    if (this.isRelativePath()) {
      this.path = this.address;
      return;
    }

    if (this.getHostnamePath()) {
      [this.host, this.path] = this.getHostnamePath();
      return;
    }

    if (this.isSchemeHostnamePath()) {
      const scheme_arr_parts = this.address.split('://');
      [this.scheme] = scheme_arr_parts;
      const host_arr_parts = scheme_arr_parts[1].split('/');
      [this.host] = host_arr_parts;
      this.path = scheme_arr_parts[1].substring(scheme_arr_parts[1].indexOf('/'));
    }
  };

  setPathParts() {
    if (this.path) {
      const regex = /^\/|\/+$/;
      const trimmed = this.path.replace(regex, '');
      this.pathParts = trimmed.split('/');
    }
  }
}
