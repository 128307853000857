import qs from 'qs';
import { App } from 'shared/types/App';
import { coreApi } from 'shared/utils/redux/query';

export const adminApi = coreApi.injectEndpoints({
  endpoints: (build) => ({
    getAccountsWithFilters: build.query({
      query: ({ searchString, filter }: { searchString: string; filter: string }) => {
        const params = qs.stringify({ search: searchString, filter: filter || undefined }, { addQueryPrefix: true });
        return `/accounts${params}`;
      },
      transformResponse: (response: { data: any }, meta, arg) => response.data || [],
    }),
    getAppsWithFilters: build.query({
      query: ({ appSearchString, activeOnly }: { appSearchString: any; activeOnly: number }) => {
        const possibleId = Number(appSearchString);
        if (Number.isInteger(possibleId)) {
          return `/apps/${appSearchString}`;
        }
        const options = { activeOnly: activeOnly || undefined };

        const params = qs.stringify({ aliasSearch: [`*${appSearchString}*`], ...options }, { addQueryPrefix: true });
        return `/apps${params}`;
      },
      transformResponse: (response: App & { data: App[] }, meta, arg) => {
        if (response.accountId) {
          return [response];
        }
        return response.data || [];
      },
    }),
    getServerList: build.query({
      query: () => { return '/serverconfig/servers'; },
      transformResponse: (response: { data: any }, meta, arg) => response.data.data || [],
    }),
    reloadServerPool: build.mutation({
      query: (poolId) => ({
        url: '/serverconfig/webconfig/pool-reload',
        method: 'POST',
        body: { poolId },
      }),
    }),
  }),
});

export const {
  useGetAccountsWithFiltersQuery,
  useLazyGetAppsWithFiltersQuery,
  useGetAppsWithFiltersQuery,
  useGetServerListQuery,
  useReloadServerPoolMutation,
} = adminApi;
