import React from 'react';
import {
  shape, bool, string, array,
} from 'prop-types';
import {
  Typography,
} from '@material-ui/core';

import Card from 'shared/styleguide/atoms/Card/Card';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import styles from 'shared/modules/app/components/App.scss';
import { WOOSAAS_PREVIEW_URL_REGEX } from 'shared/utils/regexConstants';

export const previewUrlErrorText = 'Unable to locate Preview URL';
const CredentialsPreview = ({ app }) => {
  let previewUrl;

  if (app.loaded && app.data.aliases.length) {
    previewUrl = app.data.aliases.filter((alias) => alias.fqdn.match('sites.pressdns.com') || alias.fqdn.match(WOOSAAS_PREVIEW_URL_REGEX))[0]?.fqdn ?? previewUrlErrorText;
  }

  const previewUrlOrErrorText = previewUrl !== previewUrlErrorText ? `http://${previewUrl}` : previewUrlErrorText;
  const adminPreviewUrlOrErrorText = previewUrl !== previewUrlErrorText ? `http://${previewUrl}/wp-admin` : previewUrlErrorText;

  const previewData = [
    [
      'Preview URL',
      previewUrl
        ? (
          <TextLink
            href={`http://${previewUrl}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            key="preview-url-link"
            id="preview-url-link"
          >
            {
              previewUrlOrErrorText
            }
          </TextLink>
        ) : (
          <div className={styles.credentialsDots}>
            <LoadingDots />
          </div>
        ),
    ],
    [
      'Preview Admin Login',
      previewUrl
        ? (
          <TextLink
            href={`http://${previewUrl}/wp-admin`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            key="preview-admin-url-link"
            id="preview-admin-url-link"
          >
            {
              adminPreviewUrlOrErrorText
            }
          </TextLink>
        ) : (
          <div className={styles.credentialsDots}>
            <LoadingDots />
          </div>
        ),
    ],
  ];

  return (
    <Card column className={styles.credentialsSections}>
      <Typography variant="h3">
        Preview Mode
      </Typography>
      <TwoColumn
        key="preview-columns"
        data={previewData}
        className={styles.credentialsData}
      />
      <Typography variant="body1" paragraph>
        All new sites are provisioned in what we call <em>Preview Mode</em> and use a preview URL that allows you to build and test your site prior to going live. The preview URL bypasses our <em>PressCACHE</em> feature, allowing you to view adjusted pages in their new state immediately. When the site switches to the live URL, caching then activates resulting in faster load times for visitors.
      </Typography>
      <Typography variant="body1">
        To learn more about <em>Preview Mode</em>, please check out our support article:&nbsp;
        <TextLink
          href="https://support.pagely.com/hc/en-us/articles/360034968292-Understanding-Preview-Mode"
          key="preview-admin-url-link"
        >
          Understanding Preview Mode
        </TextLink>.
      </Typography>
    </Card>
  );
};

CredentialsPreview.propTypes = {
  app: shape({
    loaded: bool,
    data: shape({
      name: string,
      aliases: array,
    }),
  }),
};

export default CredentialsPreview;
