import * as AS from './constants';

import { ACCOUNTS_SEARCH_SET_FILTER } from './constants';

export const resetSearch = () => ({
  type: AS.APPS_SEARCH_RESET,
});

export const setFilter = (filter) => ({
  type: ACCOUNTS_SEARCH_SET_FILTER,
  filter,
});
