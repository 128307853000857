import React from 'react';

import { Typography } from '@material-ui/core';

import Card from 'shared/styleguide/atoms/Card/Card';
import Layout from 'shared/styleguide/molecules/Layout';
import exampleGraph from 'shared/styleguide/assets/example-graph.png';

import { view, img, text } from './Analytics.scss';

const AnalyticsPlaceholder = () => (
  <Layout title="PressFormance">
    <Card>
      <div className={view}>
        <div className={text}>
          <Typography>
            This feature is not available for your configuration.
          </Typography>
        </div>
        <div className={img}>
          <img src={exampleGraph} alt="Example of a CPU Graph" />
        </div>
        <div className={text}>
          <Typography>
            This is an example graph of what you would be able to see if you upgraded to a VPS
          </Typography>
        </div>
      </div>
    </Card>
  </Layout>
);

export default AnalyticsPlaceholder;
