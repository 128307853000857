import React from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

export default styled(({ ...props }) => <Typography variant="h1" {...props} />)`
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.38;
  letter-spacing: -0.3px;
  text-transform: uppercase;
`;
