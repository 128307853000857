import React, {
  useState, Fragment,
} from 'react';
import { object, number } from 'prop-types';

import { Typography } from '@material-ui/core';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';

import CreateIntegration from 'shared/modules/integrations/components/CreateIntegration';
import { UPLOAD } from 'shared/modules/integrations/constants';
import { Content } from 'shared/modules/integrations';
import { dateFieldSorter } from 'shared/utils/sorting';

export const Integrations = ({
  integrations, accountId, app, apps,
}) => {
  const [creatorOpen, setCreatorOpen] = useState(false);

  const appIntegrations = integrations.data?.filter((integration) => {
    return integration.appIds.includes(Number(app.data.id)) && integration.type === UPLOAD;
  }).sort(dateFieldSorter('created', 'desc'));

  const apisLoaded = [integrations, apps, app].every((state) => state.status === 'success');

  let view = 'loading';
  if (apisLoaded) {
    view = appIntegrations.length ? 'list' : 'empty';
  }

  return (
    <Fragment>
      <Box>
        <Box direction="row" justify="space-between" margin={{ top: 'medium', bottom: 'small' }} align="center">
          <Typography variant="h3">CI/CD Integrations</Typography>
          {
            !creatorOpen && view !== 'loading'
            && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setCreatorOpen(true)}
              >
                Create new CI/CD Integration
              </Button>
            )
          }
        </Box>
        <Box>
          {
            creatorOpen && (
              <Box>
                <CreateIntegration
                  onClose={() => setCreatorOpen(false)}
                  accountId={accountId}
                  apps={apps.data}
                  app={app}
                />
              </Box>
            )
          }
          <Content type={view} integrations={appIntegrations} />
        </Box>
      </Box>
    </Fragment>
  );
};

Integrations.propTypes = {
  accountId: number,
  app: object,
  apps: object,
  integrations: object,
};

export default Integrations;
