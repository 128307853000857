import React from 'react';
import qs from 'qs';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

export const ToolTsipBox = styled(Box)`
  background-color: #FFFFFF;
  color: inherit;
  font-size: inherit;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  border: 1px solid #CCCCCC;
  padding: 8px 9px 4px;
`;

export const ToolTipBox = styled(Box)(
  ({ theme }) => {
    const gridColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];

    return (css`
    background-color: ${theme.palette.background.paper};
    color: inherit;
    font-size: inherit;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
    border: 1px solid ${gridColor};
    padding: 8px 9px 4px;
  `);
  },
);

export const LegendTypography = styled(Typography)`
  font-size: 12px;
  margin-top: 2px;
  word-break: break-all;
`;

export const graphMap = (dimensions) => {
  return {
    overview: {
      params: {
        pathType: dimensions.type,
      },
      tag: 'type',
      view: 'absolute',
      tooltip: 'Type',
      title: 'Overview',
    },
    app: {
      params: { appIds: dimensions.appIds, pathType: ['app'] },
      tag: 'appIds',
      view: 'relative',
      tooltip: 'App',
      title: 'Apps',
    },
    homedir: {
      params: { username: dimensions.username, pathType: ['homedir'] },
      tag: 'username',
      view: 'relative',
      tooltip: 'Home Dir',
      title: 'Home Directories',
    },
    gitrepo: {
      params: { appIds: dimensions.appIds, pathType: ['gitrepo'] },
      tag: 'appIds',
      view: 'relative',
      tooltip: 'Git Repo',
      title: 'Git Repos',
    },
  };
};

export const graphTypesSorter = {
  homedir: 0,
  app: 1,
  gitrepo: 2,
};

export const goToPressFormance = (history, accountId, queryParams) => () => {
  // remove dates if params are provided
  const {
    fromDate, toDate, serverName, pathType, ...rest // eslint-ignore-line
  } = queryParams;
  if (pathType) {
    rest.type = pathType;
  }
  const paramString = queryParams ? `?${qs.stringify({ byPath: { ...rest } })}` : '';
  history.push(`/account/${accountId}/analytics/disk-usage${paramString}`);
};

export const graphSymbol = (id, color, size) => {
  const dimensions = {
    height: size,
    width: size,
    minHeight: size,
    minWidth: size,
  };

  const dots = {
    backgroundColor: '#e8ebee',
    backgroundImage: 'radial-gradient(circle, #ffffff 20%, transparent 10%), radial-gradient(circle, #ffffff 20%, transparent 10%)',
    backgroundSize: '5px 5px',
    backgroundPosition: '0 0, 5px 5px',
  };

  const lines = {
    background: 'linear-gradient(-45deg, #aeb6bd 15%, #90a1ad 15%, #90a1ad 35%, #aeb6bd 35%, #aeb6bd 65%, #90a1ad 65%, #90a1ad 85%, #aeb6bd 85%)',
    backgroundSize: '11px 11px',
    backgroundPosition: '20px 20px',
  };

  let graphSymbolStyles = { backgroundColor: color };

  if (id === 'unused') {
    graphSymbolStyles = dots;
  }

  if (id === 'other') {
    graphSymbolStyles = lines;
  }

  return <Box style={{ ...graphSymbolStyles, ...dimensions }} />;
};

export const sliceLabel = (e) => {
  if (e.id !== 'unused') {
    return (
      <span style={{ fontSize: 14 }}>{e.value >= 5 ? `${e.value}%` : ''}</span>
    );
  }
};
