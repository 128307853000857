import React, { Fragment } from 'react';

import {
  Typography,
  TableCell,
  TableRow,
} from '@material-ui/core';

import CopyButton from 'shared/styleguide/molecules/CopyButton';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';
import { isJsonValid } from 'shared/utils/isJsonValid';

interface Props {
  rule: Record<string, any>;
}

const ruleValidTag = (valid) => {
  return valid
    ? <GhostTag color="green">Valid</GhostTag>
    : <GhostTag color="red">Invalid</GhostTag>;
};

const Rule = ({ rule }: Props) => {
  let displayValue;
  let displayCurrent;
  let showCopy = false;
  if (rule.AliasTarget) {
    displayValue = `Alias: ${rule.AliasTarget.DNSName}`;
    if (!rule.current) {
      displayCurrent = null;
    } else if (isJsonValid(rule.current)) {
      // is json
      displayCurrent = JSON.parse(rule.current)?.cdnDomain;
    } else {
      // is string
      displayCurrent = rule.current;
    }
  } else {
    displayValue = rule.value;
    displayCurrent = rule.current;
    showCopy = true;
  }

  let niceName;
  switch (rule.name) {
    case undefined:
    case '.':
      niceName = '@';
      break;
    default:
      niceName = rule.name;
  }

  const currentWordBreak = (!displayCurrent || displayCurrent.includes(' ')) ? 'normal' : 'break-all';

  return (
    <Fragment>
      <TableRow>
        <TableCell colSpan={1}>
          <Typography>{rule.type}</Typography>
          {
            rule.type === 'CNAME' && (
              <Typography variant="caption" color="textSecondary">recommended</Typography>
            )
          }
        </TableCell>
        {
          rule.type !== 'NS'
          && (
            <TableCell css={{ wordBreak: 'break-all' }}>
              <Box direction="row" padding={{ bottom: 'xxsmall' }} align="center">
                <Typography component="span" variant="body2">
                  {niceName}
                </Typography>
                {
                  niceName !== '@' && <CopyButton text={niceName} message={`${niceName} copied`} size="small" fontSize="small" />
                }
              </Box>
            </TableCell>
          )
        }
        <TableCell>
          <Box direction="row" padding={{ bottom: 'xxsmall' }} align="center">
            <Typography component="span" variant="body2" css={{ paddingRight: '0.3rem', minWidth: 100, wordBreak: 'break-all' }}>
              {displayValue}
            </Typography>
            {
              showCopy && <CopyButton text={rule.value} message={`${rule.value} copied`} size="small" fontSize="small" />
            }
          </Box>
          {
            rule.type !== 'NS' && (
              <div>
                <Typography component="span" variant="caption" color="textSecondary">Currently set: </Typography>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                  css={{ wordBreak: currentWordBreak }}
                >
                  {displayCurrent || 'No records found'}
                </Typography>
              </div>
            )
          }
        </TableCell>
        <TableCell>
          {ruleValidTag(rule.validated)}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default Rule;
