import React from 'react';
import {
  object, string,
} from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';
import Box from 'shared/styleguide/atoms/Box';
import { routes } from './routes';

const Settings = ({ match }) => {
  return (
    <Box padding="medium">
      <Typography variant="h2" gutterBottom>Admin Settings</Typography>
      <Route
        path={`${match.path}/:view*`}
        render={(props) => (<Navigation {...props} routes={routes} parentPath={match.url} />)}
      />
      <Switch>
        {
          routes.concat().reverse().map((child) => {
            return (
              <Route key={child.to} path={`${match.url}/${child.to}`} component={child.component} />
            );
          })
        }
      </Switch>
    </Box>
  );
};

Settings.propTypes = {
  match: object.isRequired,
  parentPath: string,
};

export default Settings;
