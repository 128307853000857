import apiReducer, {
  baseState,
} from 'shared/utils/redux/apiReducer';
import * as BILLING from './constants';

export const diskAddonCalculation = apiReducer(
  BILLING.GET_DISK_ADDON_CALCULATION_REQUEST,
  BILLING.GET_DISK_ADDON_CALCULATION_SUCCESS,
  BILLING.GET_DISK_ADDON_CALCULATION_FAILURE,
  BILLING.GET_DISK_ADDON_CALCULATION_RESET,
  baseState,
);

export const diskAddonPurchase = apiReducer(
  BILLING.SIMPLE_DISK_ADDON_PURCHASE_REQUEST,
  BILLING.SIMPLE_DISK_ADDON_PURCHASE_SUCCESS,
  BILLING.SIMPLE_DISK_ADDON_PURCHASE_FAILURE,
  BILLING.SIMPLE_DISK_ADDON_PURCHASE_RESET,
  baseState,
);
