export const tagsByServer = (state) => {
  // expecting state to be a the server reducer state

  const uniqueServers = {};
  state.data.forEach((tag) => {
    if (!uniqueServers[tag.serverId]) {
      // add server to unique servers
      uniqueServers[tag.serverId] = {
        serverId: tag.serverId,
        tags: [tag.tagId],
      };
    } else {
      uniqueServers[tag.serverId].tags.push(tag.tagId);
    }
  });

  return Object.values(uniqueServers);
};
