/* eslint-disable max-len */
import React from 'react';
import SVGIcon from '@material-ui/core/SvgIcon';

const Bandwidth = () => (
  // eslint-disable-next-line react/no-unknown-property
  <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <defs />
    <g id="Bandwidth-Management" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Account-Home" transform="translate(-113.000000, -338.000000)" fill="currentColor">
        <g id="wifi-tethering-3" transform="translate(113.000000, 338.000000)">
          <path d="M9.4736844,7.57894752 C8.43157912,7.57894752 7.57894752,8.43157912 7.57894752,9.4736844 C7.57894752,10.5157897 8.43157912,11.3684213 9.4736844,11.3684213 C10.5157897,11.3684213 11.3684213,10.5157897 11.3684213,9.4736844 C11.3684213,8.43157912 10.5157897,7.57894752 9.4736844,7.57894752 L9.4736844,7.57894752 Z M15.157895,9.4736844 C15.157895,6.34736855 12.6000003,3.78947376 9.4736844,3.78947376 C6.34736855,3.78947376 3.78947376,6.34736855 3.78947376,9.4736844 C3.78947376,11.557895 4.92631589,13.357895 6.63157908,14.4000003 L7.57894752,12.7894739 C6.4421054,12.126316 5.68421064,10.8947371 5.68421064,9.56842125 C5.68421064,7.48421068 7.38947384,5.77894749 9.4736844,5.77894749 C11.557895,5.77894749 13.2631582,7.48421068 13.2631582,9.56842125 C13.2631582,10.9894739 12.5052634,12.2210529 11.3684213,12.7894739 L12.3157897,14.4000003 C14.0210529,13.357895 15.157895,11.557895 15.157895,9.4736844 L15.157895,9.4736844 Z M9.4736844,0 C4.26315798,0 0,4.26315798 0,9.4736844 C0,12.9789476 1.89473688,16.0105266 4.7368422,17.621053 L5.68421064,16.0105266 C3.41052639,14.6842108 1.89473688,12.2210529 1.89473688,9.4736844 C1.89473688,5.30526327 5.30526327,1.89473688 9.4736844,1.89473688 C13.6421055,1.89473688 17.0526319,5.30526327 17.0526319,9.4736844 C17.0526319,12.3157897 15.5368424,14.6842108 13.2631582,16.0105266 L14.2105266,17.621053 C17.0526319,16.0105266 18.9473688,12.8842108 18.9473688,9.4736844 C18.9473688,4.26315798 14.6842108,0 9.4736844,0 L9.4736844,0 Z" id="Shape" />
        </g>
      </g>
    </g>
  </svg>
);
Bandwidth.propTypes = SVGIcon.propTypes;
Bandwidth.displayName = 'BandwidthIcon';

export default Bandwidth;
