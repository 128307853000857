import makeApiAction, { ApiAction } from 'shared/utils/redux/apiActions';
import type { NumberLike } from 'shared/types/types';
import type { UserPool } from 'shared/modules/account/redux/sso/types';
import * as SSO from './constants';
import type { StatusKeys } from '../../routes/Settings/SsoSettings/constants';

export const createUserPool = (ownerUserId: NumberLike, companyName: string): ApiAction<UserPool> => makeApiAction(
  ({ api }) => {
    const userType = 'Pagely.Model.Accounts.Account';
    const identifier = `${companyName.toLowerCase().replace(/\s+/g, '-')}-${ownerUserId}`;

    return api().post('/auth/sso/pools', {
      identifier,
      userType,
      requireMfa: false, // Require MFA is no longer a thing, always set it to false.
      ownerUserId,
    });
  },
  SSO.CREATE_USER_POOL_REQUEST,
  SSO.CREATE_USER_POOL_SUCCESS,
  SSO.CREATE_USER_POOL_FAILED,
  { ownerUserId },
);

export const finishSamlSetup = (userPoolId: string, samlUrl: string, idpName: string): ApiAction<UserPool> => makeApiAction(
  ({ api }) => api().post(`auth/sso/pools/${userPoolId}/idp/saml`, { samlUrl, idpName }),
  SSO.FINISH_SAML_SETUP_REQUEST,
  SSO.FINISH_SAML_SETUP_SUCCESS,
  SSO.FINISH_SAML_SETUP_FAILED,
  { userPoolId, samlUrl, idpName },
);

export const updateStatus = (status: StatusKeys): { type: typeof SSO.UPDATE_STATUS; status: StatusKeys } => ({
  type: SSO.UPDATE_STATUS,
  status,
});

export const deleteIdentityProvider = (poolId: string, idpName: string): ApiAction<null> => makeApiAction(
  ({ api }) => api().delete(`/auth/sso/pools/${poolId}/idp?idpName=${idpName}`),
  SSO.DELETE_IDP_REQUEST,
  SSO.DELETE_IDP_SUCCESS,
  SSO.DELETE_IDP_FAILED,
  { poolId, idpName },
);

export const deleteUserPool = (poolId: string): ApiAction<null> => makeApiAction(
  ({ api }) => api().delete(`/auth/sso/pools/${poolId}`),
  SSO.DELETE_POOL_REQUEST,
  SSO.DELETE_POOL_SUCCESS,
  SSO.DELETE_POOL_FAILED,
  { poolId },
);
