import React from 'react';
import PropTypes from 'prop-types';

import Box from 'shared/styleguide/atoms/Box';
import { ArticlePropTypes } from './models';
import Article from './Article';

const ArticlesList = ({
  topic = 'key',
  articles, max = 5, boxProps, columns = 1,
}) => {
  const items = articles.slice(0, max);
  let chunks = [];
  if (items.length) {
    chunks = items
      .reduce((acc, a, i) => {
        const temp = [...acc];
        const index = Math.floor(i / columns);
        if (!temp[index]) {
          temp[index] = [];
        }
        temp[index].push(a);
        return temp;
      }, []);
  }
  const remainder = items.length % columns;

  if (remainder > 0 && chunks[chunks.length - 1]) {
    const placeholders = Array.from({ length: (columns - remainder) }, (_, i) => { return { type: 'placeholder', id: `placeholder-${i}` }; });
    chunks[chunks.length - 1].push(...placeholders);
  }

  return (
    <Box>
      {
        chunks.map((chunk, i) => {
          return (
            <Box key={`${topic}-row-${i}`} {...boxProps} gap="medium" flex={1} margin={{ bottom: 'medium' }}>
              {
                chunk.map((article) => <Article key={`${topic}-${article.id}`} article={article} articleProps={boxProps?.articleProps} />)
              }
            </Box>
          );
        })
      }

    </Box>
  );
};
ArticlesList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape(ArticlePropTypes)),
  boxProps: PropTypes.object,
  columns: PropTypes.number,
  max: PropTypes.number,
  topic: PropTypes.string.isRequired,
};

export default ArticlesList;
