import PropTypes from 'prop-types';
import { getAvatarURL } from 'shared/utils';
import {
  getPool,
  getTitle,
  getPhone,
} from './formatters';

export const propTypes = {
  active: PropTypes.bool.isRequired,
  avatarURL: PropTypes.string,
  billingCustomerId: PropTypes.string,
  companyName: PropTypes.string,
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
  pool: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  servers: PropTypes.array,
};

export const arrayOfPropTypes = PropTypes
  .arrayOf(PropTypes.shape(propTypes).isRequired).isRequired;

export default function formatAccount(account) {
  return {
    ...account,
    pool: getPool(account),
    title: getTitle(account),
    phone: getPhone(account.phone),
    avatarURL: getAvatarURL(account),
    servers: account.servers || [],
  };
}
