import React, { Component, Fragment } from 'react';
import {
  object, arrayOf, func, shape, string, number, bool,
} from 'prop-types';
import { connect } from 'react-redux';

import { Divider } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Empty from 'shared/styleguide/atoms/Empty';
import Loading from 'shared/styleguide/atoms/Loading';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Layout from 'shared/styleguide/molecules/Layout';
import Display2Text from 'shared/styleguide/typography/Display2Text';
import CollabRedirect from 'shared/styleguide/organisms/CollabRedirect';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';

import { requestCdn } from 'shared/modules/cdn/redux/actions';

import CDNStats from 'shared/modules/analytics/components/Stats/CDN';
import ArticlesList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';

import { heading } from './CDN.scss';
import { articles } from '../constants';
import CDNListTable from '../components/CDNListTable';

export class CDN extends Component {
  static propTypes = {
    account: object.isRequired,
    cdn: shape({
      loading: bool,
      data: arrayOf(
        shape({
          id: number,
          cdnUrl: string,
          originUrl: string,
        }),
      ),
    }).isRequired,
    fetchCDN: func.isRequired,
    history: object.isRequired,
    location: object.isRequired,
  };

  state = {
    filter: '',
  };

  componentDidMount() {
    const { fetchCDN, account } = this.props;
    fetchCDN(account.id);
  }

  handleEdit = (app) => {
    const { history, location } = this.props;
    history.push({
      pathname: `${location.pathname}/${app.appId}`,
      state: { extUrl: app.extUrl },
    });
  };

  render() {
    const { cdn, account } = this.props;
    const cdnList = cdn.data;
    return (
      <Layout
        title={(
          <div className={heading}>
            <Display2Text>PressCDN Zones</Display2Text>
          </div>
        )}
      >
        {account.hasSubscription && (
          <Fragment>
            <Box margin={{ bottom: 'xsmall' }}>
              <Typography variant="h3">CDN Usage</Typography>
            </Box>
            <Box
              as={Paper}
              margin={{ bottom: 'mediumLarge' }}
              flex={1}
              padding="medium"
              justify="flex-start"
            >
              <div>
                <CDNStats />
              </div>
            </Box>
          </Fragment>
        )}
        <Box
          direction="row"
          align="flex-end"
          justify="space-between"
          margin={{ bottom: 'xxsmall' }}
        >
          <Typography variant="h3" gutterBottom>
            Manage CDN Zones
          </Typography>
          <SearchBar
            className="search"
            text="Filter Zones"
            onChange={(filter) => this.setState({ filter })}
          />
        </Box>
        <Box>
          {cdn.loading ? (
            <Loading />
          ) : (
            <Box>
              {account.isCollaborator && (
                <CollabRedirect account={account} text="manage CDN" />
              )}
              <Box>
                {!account.isCollaborator && cdnList.length === 0 && (
                  <Box flex={1} padding="xsmall">
                    <Empty>You have no CDN Zones</Empty>
                  </Box>
                )}
                {!account.isCollaborator && cdnList.length > 0 && (
                  <CDNListTable
                    cdnList={cdnList}
                    filter={this.state.filter}
                    onEdit={this.handleEdit}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Box padding={{ top: 'large', bottom: 'medium' }}>
          <Divider />
        </Box>
        <Typography variant="h3" gutterBottom>
          Common CDN Questions
        </Typography>
        <ArticlesList
          topic="cdn"
          columns={3}
          boxProps={{
            row: true,
            wrap: 'wrap',
          }}
          articles={articles}
        />
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    cdn: state.cdn.cdnForAccount,
    account: state.account,
  }),
  {
    fetchCDN: requestCdn,
  },
)(CDN);
