import React from 'react';
import { styled } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core';
import type { Typography as ITypography } from '@material-ui/core/styles/createTypography';
import { Typography } from '@material-ui/core';

export { default as Display1Text } from './Display1Text';
export { default as Display2Text } from './Display2Text';
export { default as HeadlineText } from './HeadlineText';
export { default as MutedHeadlineText } from './MutedHeadlineText';

const fontFamily = '"Roobert", "Helvetica", "Arial", sans-serif';

export const themePresets: Partial<ITypography> = {
  fontSize: 16,
  fontFamily,
  h1: {
    fontFamily,
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },
  h2: {
    fontFamily,
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  h3: {
    fontFamily,
    fontSize: '1.25rem', // 20px
    fontWeight: 600,
  },
  h4: {
    fontFamily,
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    fontFamily,
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    fontFamily,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  subtitle1: {
    fontFamily,
    fontSize: '1rem', // 16px
    lineHeight: '1.4',
  },
  subtitle2: {
    fontFamily,
    fontSize: '0.9rem', // 14.4px
    lineHeight: '1.4',
  },
  body1: {
    fontFamily,
    fontSize: '1rem', // 16px
    lineHeight: '1.4',
  },
  body2: {
    fontFamily,
    fontSize: '0.9rem', // 14.4px
    lineHeight: '1.4',
  },
  button: {
    fontFamily,
    fontSize: '0.9rem', // 14px
    // textTransform: 'uppercase',
  },
  caption: {
    fontFamily,
    fontSize: '0.75rem', // 12px
    letterSpacing: '0.3px',
    lineHeight: '16px',
  },
};

export const ColorText = styled('span')<Theme, { color: string }>(({ color }) => ({ color }));

export const CodeText = styled('pre')({
  fontSize: 16,
  fontFamily: "'Andale Mono', monospace",
});

export const BorderedCodeText = styled('span')({
  display: 'inline',
  fontSize: 14,
  color: '#1b6395',
  backgroundColor: '#f4fafe',
  border: '1px solid #b3d1e6',
  borderRadius: 4,
  margin: '0 0 0 5px',
  padding: '0 3px',
  fontFamily: "'Andale Mono', monospace",
});

export const ErrorText = ({ children, ...props }) => (
  <Typography variant="body1" color="error" {...props}>{children}</Typography>
);

export const FloatingErrorText = styled(({ ...props }) => <Typography variant="body1" component="span" color="error" {...props} />)({
  position: 'absolute',
  top: 40,
});
