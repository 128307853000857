import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BlockIcon from '@material-ui/icons/Block';

import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import styles from './styles';

const useStyles = makeStyles(styles);

const DisabledBanner = () => {
  const classes = useStyles();

  return (
    <ListItem
      disableGutters
      classes={{
        root: classes.listItem,
      }}
    >
      <Box column flex={1} justify="center">
        <Box row flex={1} justify="center">
          <Box row flex={1}>
            <ListItemIcon className={classes.listItemIcon}>
              <BlockIcon className={classes.disabledIcon} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
            >
              <ErrorText>This account is disabled</ErrorText>
            </ListItemText>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

DisabledBanner.propTypes = {};

export default (DisabledBanner);
