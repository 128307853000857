import moment from 'moment';

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const parsedDate = (value) => {
  return moment.utc(value).unix();
};

export const dateFieldSorter = (fieldName, sortDirection) => {
  return (a, b) => {
    const one = parsedDate(a[fieldName]?.date ? a[fieldName].date : (a[fieldName] || a.timestamp));
    const two = parsedDate(b[fieldName]?.date ? b[fieldName].date : (b[fieldName] || b.timestamp));
    return sortDirection === 'desc' ? two - one : one - two;
  };
};
