import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LazyLog } from 'react-lazylog';
import { useSelector } from 'react-redux';
import { Whatshot } from '@material-ui/icons';
import { formatDistanceToNow } from 'date-fns';
import Box from 'shared/styleguide/atoms/Box';
import {
  useGetSharedNoteByIdQuery,
  useGetSharedNotesForAccountQuery,
} from 'admin/modules/notes/query';
import { Account } from 'shared/modules/account/types';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Loading from 'shared/styleguide/atoms/Loading';
import { ErrorText } from 'shared/styleguide/typography';
import { NumberLike } from 'shared/types/types';
import InfoText from 'shared/styleguide/molecules/InfoText';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

const AccordionNoteItem = ({
  from,
  expires,
  burnAfterReading,
  noteId,
}: {
  from: string;
  expires: string;
  noteId: NumberLike;
  burnAfterReading?: boolean;
}) => {
  const [skip, setSkip] = useState(true);
  const expirationDate = new Date(expires);
  const distance = formatDistanceToNow(expirationDate);
  const expireString = expires ? `Expires in ${distance}` : 'Never expires';

  const { data, error, isSuccess } = useGetSharedNoteByIdQuery(noteId, {
    skip,
  });
  let content = <Loading />;
  if (error) {
    content = (
      <ErrorText>
        An unexpected error occured while fetching shared note.
      </ErrorText>
    );
  }
  if (isSuccess) {
    content = (
      <Box>
        <LazyLog
          text={data.note}
          height={400}
          selectableLines
          extraLines={1}
          enableSearch
        />
        <Box padding={{ top: 'small' }} flex="column">
          <CopyButton text={data.note} message="Copied note contents">
            <Button
              color="secondary"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Copy Note Content
            </Button>
          </CopyButton>
        </Box>
      </Box>
    );
  }
  return (
    <Accordion>
      <AccordionSummary
        onClick={() => setSkip(false)}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box direction="row" justify="space-between" flex={1}>
          <Typography variant="subtitle1">
            <b>Author:</b> {from}
          </Typography>
          <Box direction="row">
            <GhostTag>{expireString}</GhostTag>
            {burnAfterReading && (
              <GhostTag>
                <Whatshot css={{ color: 'red' }} />
                burns after reading
              </GhostTag>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        css={{
          flexDirection: 'column',
        }}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  );
};

export const ListView = (): JSX.Element => {
  const accountId = useSelector(
    (state: { account: Account }) => state.account.id,
  );
  const { data, isLoading, error } = useGetSharedNotesForAccountQuery({
    accountId,
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return (
      <ErrorText>
        An unexpected error occured while fetching shared notes list.
      </ErrorText>
    );
  }
  if (!data || data?.data?.length === 0) {
    return null;
  }
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Shared Notes
      </Typography>
      <InfoText>
        Once a &quot;burns after reading&quot; note is opened, you will not be
        able to read it after refreshing this page.
      </InfoText>
      <Box>
        {data.data.map((note) => (
          <AccordionNoteItem
            key={`${note.id}`}
            from={note.authorName}
            expires={note.expires}
            burnAfterReading={note.burnAfterReading}
            noteId={note.id}
          />
        ))}
      </Box>
    </>
  );
};
