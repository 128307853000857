import React, { useState, useEffect } from 'react';
import {
  object, string, func,
} from 'prop-types';
import { connect } from 'react-redux';

import { Paper, Divider } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import TabPanel from 'shared/styleguide/molecules/HorizontalNavigation/TabPanel';
import { ErrorText } from 'shared/styleguide/typography';
import { consolidateErrors } from 'shared/utils/validation';

import { getEmailTemplates, sendTestEmail, updateTemplate } from '../../redux/actions';
import Edit from './Edit';
import Preview from './Preview';

import { NOTICE_COLORS } from '../../constants';

export const EmailRoutes = ({
  templateId, template, emailStatus, getEmailTemplates, user, dispatchPreviewEmail, updateTemplate,
}) => {
  const [view, setView] = useState('edit');
  const [templateState, setTemplateState] = useState(template);
  const [variableSubstitutions, setVariableSubstitutions] = useState({});
  const [emailAddress, setEmailAddress] = useState('');

  const [testState, setTestState] = useState('initial');
  const [updateState, setUpdateState] = useState('initial');
  const [errors, setErrors] = useState('');

  // needs to load emails if not already loaded
  const fetchTemplates = async () => {
    if (emailStatus === 'pristine') {
      const response = await getEmailTemplates();
      setTemplateState(response.data.find((t) => t.id === templateId));
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [emailStatus]);

  const handleSendTestEmail = async (e) => {
    e.preventDefault();
    const testEmail = e.target.testEmail.value;

    const body = {
      to: [{
        name: user.name,
        email: testEmail,
      }],
      subject: templateState.subject,
      body: templateState.value,
      variables: variableSubstitutions,
    };

    const { noticeConfig } = templateState;
    if (noticeConfig.text) {
      body.noticeConfig = {
        text: noticeConfig.text,
        class: !noticeConfig.class ? NOTICE_COLORS.gray : noticeConfig.class,
      };
    }

    setEmailAddress(testEmail);
    setTestState('loading');
    await dispatchPreviewEmail(body);
    setTestState('success');
    setTimeout(() => {
      setTestState('initial');
    }, 5000);
  };

  const handleUpdateTemplate = async () => {
    const body = {
      data: templateState.value,
      subject: templateState.subject,
    };

    const { noticeConfig } = templateState;
    if (noticeConfig.text) {
      body.noticeConfig = {
        text: noticeConfig.text,
        class: noticeConfig.class ?? NOTICE_COLORS.gray,
      };
    }

    setUpdateState('loading');
    try {
      await updateTemplate({ templateId, body });
      setUpdateState('success');
      setTimeout(() => { setUpdateState('initial'); }, 2000);
    } catch (err) {
      setUpdateState('failed');
      setErrors(consolidateErrors(err));
    }
  };

  if (emailStatus === 'pristine' || emailStatus === 'loading' || !templateState) {
    return <Loading />;
  }

  return (
    <Box>
      <Box margin={{ bottom: 'medium' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={AdapterLink} color="inherit" to="/email">
            Templates
          </Link>
          <Typography color="textPrimary">{templateId}</Typography>
        </Breadcrumbs>
      </Box>
      <Paper>
        <Box padding="medium">
          <Tabs
            value={view}
            aria-label="email nav tabs"
            indicatorColor="primary"
            onChange={(e, value) => setView(value)}
          >
            <Tab
              value="edit"
              label="Edit"
            />
            <Tab
              value="preview"
              label="Preview"
            />
          </Tabs>

          <TabPanel value={view} index="edit" key="edit">
            <Edit templateState={templateState} onSetTemplateState={setTemplateState} />
          </TabPanel>
          <TabPanel value={view} index="preview" key="preview">
            <Preview templateState={templateState} setVariableSubstitutions={setVariableSubstitutions} />
          </TabPanel>

          <Box padding={{ top: 'medium', bottom: 'medium' }}>
            <Divider />
          </Box>
          <Box direction="row" justify="space-between" align="center">
            <Box>
              <Box
                as="form"
                direction="row"
                gap="small"
                align="center"
                onSubmit={handleSendTestEmail}
                id="send_test_email"
              >
                <Box>
                  <WideTextField
                    name="testEmail"
                    id="testEmail"
                    type="email"
                    margin="dense"
                    placeholder="example@email.com"
                    onChange={(e) => setEmailAddress(e.target.value)}
                    value={emailAddress}
                  />
                </Box>
                <Box>
                  <Button
                    status={testState}
                    type="submit"
                    variant="outlined"
                    disabled={!emailAddress}
                  >
                    Send Test Email
                  </Button>
                </Box>
              </Box>
              {
                testState === 'success' ? (
                  <Typography color="secondary" variant="subtitle2">
                    Check your email for test email
                  </Typography>
                ) : (
                  <Typography color="textSecondary" variant="subtitle2">
                    Email will be sent with replaced variables from Preview screen
                  </Typography>
                )
              }
            </Box>
            <Button
              variant="contained"
              status={updateState}
              onClick={handleUpdateTemplate}
            >
              Update
            </Button>
          </Box>
          {
            errors && (
              <ErrorText>{errors}</ErrorText>
            )
          }
        </Box>
      </Paper>
    </Box>
  );
};

EmailRoutes.propTypes = {
  dispatchPreviewEmail: func,
  emailStatus: string,
  getEmailTemplates: func,
  match: object.isRequired,
  parentPath: string,
  template: object,
  templateId: string,
  updateTemplate: func,
  user: object,
};

export default connect(
  (state, props) => {
    const { params: { templateId } } = props.match;

    return {
      template: state.email.directory[templateId],
      templateId,
      emailStatus: state.email.status,
      user: state.user.user,
    };
  },
  {
    getEmailTemplates,
    updateTemplate,
    dispatchPreviewEmail: sendTestEmail,
  },
)(EmailRoutes);
