import React from 'react';
import cx from 'classnames';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTheme } from '@material-ui/core';
import Box from '../Box';

const StyledBox = styled(Box)(
  css`
    padding: 50px;
    border-radius: 8px;
    box-sizing: border-box;
  `,
  ({ theme }) => (css`
    color: ${theme.palette?.text.disabled};
    border: 1px solid ${theme.palette?.grey[200]};
    background: ${theme.palette?.background.contrast}
  `),
);

const Empty = ({ className = '', children, ...rest }) => {
  const theme = useTheme();
  return (
    <StyledBox
      direction="column"
      justify="center"
      align="center"
      className={cx(className)}
      theme={theme}
      {...rest}
    >
      {children}
    </StyledBox>
  );
};

Empty.displayName = 'EmptyState';

export default Empty;
