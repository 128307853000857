import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import { ErrorText } from 'shared/styleguide/typography';
import colors from 'shared/styleguide/theme';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import { baseStatePropTypes } from 'shared/utils/redux/apiReducer';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import {
  resetMfaAccount,
  resetMfaAdmin,
  resetMfaResetState,
} from 'shared/modules/authentication/redux/actions';

export class ResetMFA extends React.Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    resetMfa: PropTypes.shape(baseStatePropTypes),
    resetMfaAccount: PropTypes.func,
    resetMfaAdmin: PropTypes.func,
    resetMfaResetState: PropTypes.func,
  };

  state = {
    email: '',
  };

  componentWillUnmount() {
    this.props.resetMfaResetState();
  }

  handleRequest = () => {
    return this.props.accountType === 'account'
      ? this.props.resetMfaAccount(this.state.email)
      : this.props.resetMfaAdmin(this.state.email);
  };

  render() {
    let error;
    if (this.props.resetMfa.done) {
      return (
        <Typography variant="body2">An email has been sent to the provided address. Please see it for further instructions.</Typography>
      );
    }

    if (this.props.resetMfa.apiErrorStatusCode) {
      switch (this.props.resetMfa.apiErrorStatusCode) {
        case 422:
          error = (
            <ErrorText color={colors.red10}>
              {
                this.props.resetMfa.validation.body.email.messages.map((msg) => <div key={msg}>{msg}</div>)
              }
            </ErrorText>
          );
          break;
        case 404:
          error = (
            <ErrorText color={colors.red10}>Could not find a user with that email.</ErrorText>
          );
          break;
        default:
          error = (
            <Typography variant="body2" color={colors.red10}>An error happened when processing the request</Typography>
          );
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          margin="normal"
          id="email"
          placeholder="Email"
          errorText={error}
          onChange={(e) => this.setState({ email: e.target.value })}
        />
        <Box margin={{ top: 'small' }}>
          <Button
            variant="contained"
            onClick={this.handleRequest}
            disabled={this.props.resetMfa.done || !this.state.email || !this.state.email.match(/.+@.+/)}
          >
            Reset Multi-Factor Authentication
          </Button>
        </Box>
      </div>
    );
  }
}

const mapDispatchToProps = {
  resetMfaResetState,
  resetMfaAccount,
  resetMfaAdmin,
};

const mapStateToProps = (state) => ({
  resetMfa: state.requests.resetMfa,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetMFA);
