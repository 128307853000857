/* eslint-disable */
import React from 'react';

import CheckIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import {Typography, Link} from '@material-ui/core';

import { roleStrings, roles as ROLES } from 'shared/modules/permissions/user/actions';
import Box from 'shared/styleguide/atoms/Box';
import InfoText from 'shared/styleguide/molecules/InfoText';
import Linker from 'shared/styleguide/atoms/Links/Linker';

import scss from './Team.scss';

const getMessageRoleDescription = (role) => {
  switch (role) {
    case ROLES.SUPERADMIN: 
    case ROLES.SUBADMIN: 
    case ROLES.TECH:
      return "all alerts except billing";
    case ROLES.BILLING:
      return "billing alerts only";
    case ROLES.APPONLY:
    case ROLES.APPONLYMINIMAL:        
      return "no alerts";
  }
}

export default ({ role }) => {
  const getDescription = () => {
    switch (role) {
      case ROLES.SUPERADMIN: return (
        <ul className={scss.roleDescriptions} data-testid="roles-superadmin">
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage All Sites </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage All Support Tickets </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage Billing </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage Non-Admin Users </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Create New Admin Users (Account Owner Only) </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Force 2-Factor Access (Account Owner Only) </li>
        </ul>
      );
      case ROLES.SUBADMIN: return (
        <ul className={scss.roleDescriptions} data-testid="roles-subadmin">
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage All Sites </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage All Support Tickets </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage Billing </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage All Site-Level Users (App-Only/App-Only-Minimal Users) </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Account-Level Users </li>
        </ul>
      );
      case ROLES.TECH: return (
        <ul className={scss.roleDescriptions} data-testid="roles-tech">
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage All Sites </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Submit Tickets </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage Their Own Tickets </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Live Chat </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> SSH Access </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Users </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Access Billing Details </li>
        </ul>
      );
      case ROLES.BILLING: return (
        <ul className={scss.roleDescriptions} data-testid="roles-billing">
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage Billing </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Submit Tickets </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage Their Own Tickets </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Live Chat </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Sites/Technical Details </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Plans/Add-Ons </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Users </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Access Billing Details </li>
        </ul>
      );
      case ROLES.APPONLY: return (
        <ul className={scss.roleDescriptions} data-testid="roles-apponly">
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Make Changes to a Single Site </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Submit Tickets </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Manage Their Own Tickets </li>
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Live Chat </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Sites </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> SSH Access </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Plans/Add-Ons </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Users </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Access Billing Details </li>
        </ul>
      );
      case ROLES.APPONLYMINIMAL: return (
        <ul className={scss.roleDescriptions} data-testid="roles-apponlyminimal">
          <li><CheckIcon style={{ verticalAlign: 'bottom' }} color="secondary" /> Access App Files and Database </li>
          <li><CheckIcon style={{ verticalAlign: 'middle' }} color="secondary" /> Manage Their Own SSH/SFTP Keys </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Access Support </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Access Billing </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Manage Sites </li>
          <li><BlockIcon style={{ verticalAlign: 'bottom' }} color="error" /> Access Anything Other Than App Files and Database </li>
        </ul>
      );
      default: return null;
    }
  };

  if (!role || !getDescription()) {
    return null;
  }

  return (
    <Box margin={{ top: 'medium' }} data-testid="roles-descriptions">
      <Box margin={{ bottom: 'xsmall' }} direction="row" justify="flex-start" gap="small" align="center">
        <InfoText
          href="https://support.pagely.com/hc/en-us/articles/227131167-Collaborator-Management-"
          noMargin
        >
          {roleStrings[role]}
        </InfoText>
        <Box>
          <Typography variant="body2" component="span" color="textSecondary">By default, this role will get <Link component={Linker} to="team/alerts">{getMessageRoleDescription(role)}</Link>.</Typography>
        </Box>
      </Box>
      {getDescription()}
    </Box>
  );
};
