/* eslint-disable max-len */
import React from 'react';
import SVGIcon from '@material-ui/core/SvgIcon';

const Disk = () => (
  // eslint-disable-next-line react/no-unknown-property
  <svg width="15px" height="18px" viewBox="0 0 15 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <g id="App-Management" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Account-Home" transform="translate(-454.000000, -338.000000)" fill="currentColor" fillRule="nonzero">
        <g id="harddisk-copy" transform="translate(454.000000, 338.000000)">
          <path d="M1.8,0 L12.6,0 C13.5941125,0 14.4,0.80588745 14.4,1.8 L14.4,16.2 C14.4,17.1941125 13.5941125,18 12.6,18 L1.8,18 C0.80588745,18 0,17.1941125 0,16.2 L0,1.8 C0,0.80588745 0.80588745,0 1.8,0 Z M7.2,1.8 C4.21766235,1.8 1.8,4.21766235 1.8,7.2 C1.8,10.179 4.221,12.6 7.29,12.6 L6.498,10.593 C6.255,10.161 6.399,9.612 6.831,9.36 L7.605,8.91 C8.037,8.667 8.586,8.811 8.838,9.243 L10.566,11.421 C11.808,10.431 12.6,8.91 12.6,7.2 C12.6,4.21766235 10.1823376,1.8 7.2,1.8 Z M7.2,6.3 C7.69705627,6.3 8.1,6.70294373 8.1,7.2 C8.1,7.69705627 7.69705627,8.1 7.2,8.1 C6.70294373,8.1 6.3,7.69705627 6.3,7.2 C6.3,6.70294373 6.70294373,6.3 7.2,6.3 Z M2.7,14.4 C2.20294373,14.4 1.8,14.8029437 1.8,15.3 C1.8,15.7970563 2.20294373,16.2 2.7,16.2 C3.19705627,16.2 3.6,15.7970563 3.6,15.3 C3.6,14.8029437 3.19705627,14.4 2.7,14.4 Z M7.281,10.143 L9.522,15.822 L11.853,14.472 L8.055,9.693 L7.281,10.143 Z" id="Shape" />
        </g>
      </g>
    </g>
  </svg>
);
Disk.propTypes = SVGIcon.propTypes;
Disk.displayName = 'DiskIcon';

export default Disk;
