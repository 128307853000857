import React from 'react';
import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import Banner from 'shared/styleguide/molecules/Banner';

/**
 * Should not be used with user-provided strings.
 */
function DangerouslyLinkifyText({ text }: { text: string }): JSX.Element {
  const replaceWithLinks = (str) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    return str.replace(regex, '<a href="$1" style="color:#EA0FC1" target="_blank" rel="noopener noreferrer">$1</a>');
  };

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: replaceWithLinks(text) }} />;
}

const NotificationBanner = (): JSX.Element => {
  const today = Date.now();
  // could be provided by an api
  const hideDate = Date.UTC(2023, 10, 17, 7);
  // could also be provided by an api
  const copy = 'PHP 8.0 will be permanently phased out very soon! Please review our status page for more info https://status.pagely.com/incidents/z61lqmdzcmxq';

  const shouldShow = today < hideDate;
  return (
    shouldShow && (
      <Banner
        icon={<Warning />}
        margin={{ bottom: 'mediumLarge' }}
        subheading={(
          <Typography variant="subtitle1" component="span" color="inherit">
            <strong>IMPORTANT:&nbsp;</strong>
            <DangerouslyLinkifyText text={copy} />
          </Typography>
        )}
        type="success"
      />
    )
  );
};

export default NotificationBanner;
