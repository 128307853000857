import React, { useState, useLayoutEffect } from 'react';
import {
  number, object,
} from 'prop-types';
import { Typography } from '@material-ui/core';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import ProgressView from 'shared/styleguide/molecules/ProgressView';
import Box from 'shared/styleguide/atoms/Box';

const ProgressStatus = ({
  appId, integration,
}) => {
  const total = 210; // 3min 30sec
  const end = (new Date()).getTime() + (total * 1000);

  const progressInitialState = {
    end,
    percentage: 0,
  };

  const [progress, setProgress] = useState(progressInitialState);

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      const now = (new Date()).getTime();
      if (progress && now < progress.end) {
        // processing
        const duration = (progress.end - now) / 1000;
        const percentage = ((total - duration) / total) * 100;
        setProgress({ ...progress, percentage });
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [appId, integration.id, progress]);

  return (
    <Box as={Paper}>
      <Box padding="large">
        <Typography variant="subtitle2">
          Creating integration... <br />Please note that it may take a few minutes for
          the process to complete. Once it is ready, webhook information will be available here.
        </Typography>
        <Box margin={{ top: 'small' }}>
          <ProgressView
            value1={String(Math.floor(progress.percentage))}
            value2="100%"
            percentage={progress.percentage}
            threshold={null}
            errorText="Error: Unable to provide progress data, please refresh the page."
          />
        </Box>
      </Box>
    </Box>
  );
};

ProgressStatus.propTypes = {
  appId: number.isRequired,
  integration: object.isRequired,
};

export default ProgressStatus;
