import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import ReloadIcon from '@material-ui/icons/Autorenew';

import { getAllValidationMessages } from 'shared/utils/validation';
import Loading from 'shared/styleguide/atoms/Loading';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';

import ConfigsList from '../components/ConfigsList';
import ConfigsSelector from '../components/ConfigsSelector';

import { fetchConfigs } from '../redux/actions';

export const ConfigsView = ({
  configs, fetchConfigs, account, apps,
}) => {
  const [scope, setScope] = useState('account');
  const [message, setMessage] = useState('Please select a scope.');
  const [required, setRequired] = useState([]);
  const [filter, setFilter] = useState({
    configType: 'vhosts',
    poolId: null,
    serverId: null,
    accountId: account.id,
    appId: null,
  });

  const checkRequired = (scope) => {
    const req = [];
    setRequired(req);

    if (scope.includes('app') && !filter.appId) {
      req.push('app');
    }

    if (scope.includes('server') && !filter.serverId) {
      req.push('server');
    }

    if (!filter.configType) {
      req.push('configType');
    }

    if (req.length > 0) {
      const message = `Missing selections for ${scope}: [${req.join(', ')}]`;
      setMessage(message);
      setRequired(req);

      return false;
    }
    setMessage(null);
    return true;
  };

  useEffect(() => {
    if (checkRequired(scope)) {
      fetchConfigs(scope, filter);
    }
  }, [fetchConfigs, filter, scope, message]);

  const handleSetFilter = (param, value) => {
    if (value) {
      setFilter({ ...filter, [param]: value });
    } else {
      const { [param]: _, ...rest } = filter;
      setFilter({ ...rest });
    }
  };

  const handleSetScope = (value) => {
    setScope(value);

    const poolId = ['pool', 'pool-account', 'pool-app'].includes(value) ? account.data.defPoolId : null;
    const accountId = ['account', 'server-account', 'pool-account'].includes(value) ? account.id : null;

    const serverIds = account.servers.map((s) => s.id);
    let serverId;
    if (serverIds.length === 1) {
      serverId = ['server', 'server-account', 'server-app'].includes(value) ? serverIds[0] : null;
    }

    const appIds = apps.data.map((a) => a.id);

    setFilter({
      ...filter,
      poolId,
      accountId,
      serverId,
      appId: appIds.length === 1 ? appIds[0] : null,
    });
  };

  const scopeList = [
    { value: 'app', label: 'App' },
    { value: 'account', label: 'Account' },
    { value: 'server', label: 'Server' },
    { value: 'pool', label: 'Pool' },
    { value: 'server-account', label: 'Server/Account' },
    { value: 'server-app', label: 'Server/App' },
    { value: 'pool-account', label: 'Pool/Account' },
    { value: 'pool-app', label: 'Pool/App' },
  ];

  const appsList = apps.data.map((app) => {
    return {
      value: app.id,
      label: `[${app.id}] ${app.name}`,
    };
  });

  const serversList = account.servers.map((server) => {
    return {
      value: server.id,
      label: `[${server.id}] ${server.hostname}`,
    };
  });

  // when status is implemented
  // const statusList = [
  //   { value: null, label: 'All' },
  //   { value: 'pending', label: 'Pending' },
  //   { value: 'published', label: 'Published' },
  // ];

  const configTypeList = [
    { value: 'vhosts', label: 'Vhosts' },
    { value: 'upstreams', label: 'Upstreams' },
    { value: 'ip-lists', label: 'Ip Lists' },
    { value: 'global', label: 'Global' },
  ];

  return (
    <Box margin={{ top: 'medium' }}>
      <Box
        direction="row"
        justify="space-between"
        flex={1}
        align="center"
      >
        <ConfigsSelector
          scopeList={scopeList}
          configTypeList={configTypeList}
          appsList={appsList}
          serversList={serversList}
          // statusList={statusList} // when status is implemented
          scope={scope}
          filter={filter}
          required={required}
          onSetScope={handleSetScope}
          onSetFilter={handleSetFilter}
        />
        <Box direction="row" padding={{ top: 'small', left: 'xxsmall' }} align="flex-end">
          {
            configs.status === 'loading'
              ? (
                <Box css={{ padding: 7 }}>
                  <Loading size="small" margin={{ top: 0 }} />
                </Box>
              ) : (
                <Tooltip title="Refresh configs" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => fetchConfigs(scope, filter)}
                  >
                    <ReloadIcon />
                  </IconButton>
                </Tooltip>
              )
          }
        </Box>
      </Box>
      <Box align="center" margin={{ top: 'medium' }}>
        {
          message && configs.status !== 'loading'
          && (
            <Box align="center" margin={{ top: 'large' }}>
              <Typography align="center">{message}</Typography>
            </Box>
          )
        }
        {
          !message && configs.status === 'success' && configs.data.length > 0
          && <ConfigsList items={configs.data} accountId={account.id} />
        }
        {
          !message && configs.status === 'success' && configs.data.length === 0
          && (
            <Box margin={{ top: 'large' }} style={{ width: '100%' }}>
              <Empty>No results</Empty>
            </Box>
          )
        }
        {
          configs.status === 'failed'
          && (
            <Box align="center" margin={{ top: 'large' }}>
              <ErrorText align="center">Failed to load configs</ErrorText>
              {
                configs.apiErrors
                && <ErrorText align="center">{configs.apiErrors}: <br />{getAllValidationMessages(configs)}</ErrorText>
              }
            </Box>
          )
        }
      </Box>
    </Box>
  );
};

ConfigsView.propTypes = {
  account: PropTypes.object,
  apps: PropTypes.object,
  configs: PropTypes.shape({
    apiErrors: PropTypes.string,
    data: PropTypes.array.isRequired,
    status: PropTypes.string,
  }),
  fetchConfigs: PropTypes.func.isRequired,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    configs: state.aresConfig.configs,
    account: state.account,
    apps: state.apps,
  };
};

export default connect(mapStateToProps, { fetchConfigs })(ConfigsView);
