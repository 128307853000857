import type { StoreState } from 'shared/types/types';
import type { DomainToModify } from './types';

export const hasChanges = (domainsToModify: DomainToModify[] = []): boolean => {
  const domains = domainsToModify.filter((d) => d.delete || (!d.delete && d.fqdn));
  return domains.length > 0;
};

export const getChangesMessages = (domainsToModify: DomainToModify[] = []): string => {
  const messages = [];

  const domainsToCreate = domainsToModify.filter((d) => !d.delete && d.fqdn);
  const domainsToDelete = domainsToModify.filter((d) => d.delete);

  if (domainsToCreate.length > 0) {
    messages.push(` ${domainsToCreate.length} new domain${domainsToCreate.length > 1 ? 's' : ''}.`);
  }

  if (domainsToDelete.length > 0) {
    messages.push(` ${domainsToDelete.length} domain${domainsToDelete.length > 1 ? 's' : ''} deleted.`);
  }

  return messages.join('');
};

export const getFailureMessages = (appDomains: StoreState): string => {
  const createFailures = appDomains.failures?.create || [];
  const deleteFailures = appDomains.failures?.delete || [];
  if (createFailures.length === 0 && deleteFailures.length === 0) {
    return '';
  }

  const failureMessages = [];

  if (createFailures.length > 0) {
    failureMessages.push(`The following domains failed to be added: ${createFailures.join(', ')}`);
  }

  if (deleteFailures.length > 0) {
    const deleteFqdns = appDomains.data.reduce((acc, d) => {
      if (deleteFailures.includes(d.id)) {
        acc.push(d.fqdn);
      }
      return acc;
    }, []);

    failureMessages.push(`The following domains failed to be deleted: ${deleteFqdns.join(', ')}`);
  }

  return failureMessages.join('; ');
};
