import React, {
  useState, useEffect,
} from 'react';
import {
  object, func, number, string,
} from 'prop-types';
import moment from 'moment';
import {
  Typography, IconButton, Tooltip,
} from '@material-ui/core';

import ReloadIcon from '@material-ui/icons/Refresh';

import Box from 'shared/styleguide/atoms/Box';

import * as STATUS from 'shared/modules/status/redux/constants';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import CircleCheck from 'shared/styleguide/Icons/CheckCircleOutline';

export const DiskJob = ({
  onGetDiskUpgradeJobStatus,
  newSize,
  diskJob,
  modificationType,
}) => {
  const [startTime, setStartTime] = useState(null);
  const [runtime, setRuntime] = useState(0);

  useEffect(() => {
    const created = diskJob?.created?.date || diskJob?.created;
    if (created && !startTime) {
      setStartTime(moment.utc(created));
    }

    const interval = setInterval(() => {
      if (startTime) {
        // show duration since job started
        const diff = moment().diff(startTime);
        const duration = moment.duration(diff);
        const minutes = Math.floor(duration.asMinutes());
        const seconds = moment(diff).format('ss');
        setRuntime(`${minutes}:${seconds}`);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startTime, diskJob]);

  return (
    <Box align="center" margin={{ bottom: 'xsmall' }}>
      <Box align="center">
        {
          diskJob?.overallStatus === STATUS.SUCCESS
            ? (
              <Box
                column
                align="center"
                justify="flex-start"
              >
                <CircleCheck
                  style={{
                    width: 72,
                    height: 72,
                  }}
                  color="secondary"
                />
                <Box
                  direction="column"
                  gap="xsmall"
                  margin={{ top: 'small' }}
                >
                  <Box margin={{ bottom: 'xsmall' }}>
                    <Typography variant="h3" align="center">
                      DISK {modificationType} COMPLETE!
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5" component="p" align="center">
                      New Disk Size: {diskJob.detail?.primary?.jenkinsParams?.SIZE || newSize} GiB
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box align="center" margin={{ top: 'small' }}>
                <Typography variant="h3" align="center" gutterBottom>
                  DISK {modificationType || 'MODIFICATION'} IN PROGRESS
                </Typography>
                <Box direction="row" align="center">

                  <Box margin={{ right: 'small' }}>
                    <Typography variant="h5" component="p">Status:</Typography>
                  </Box>
                  <Box>
                    <GhostTag color={STATUS.tagMap[diskJob?.overallStatus || STATUS.PENDING]}>{diskJob?.overallStatus || STATUS.PENDING}</GhostTag>
                  </Box>
                  <Box>
                    <Tooltip title={`Check disk ${modificationType} job status`} placement="top">
                      <IconButton
                        onClick={() => onGetDiskUpgradeJobStatus()}
                      >
                        <ReloadIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Box align="center">
                  <Box margin={{ bottom: 'xsmall' }}>
                    <Typography variant="h5" component="p" align="center" gutterBottom>
                      Running time: {startTime ? runtime : STATUS.PENDING}
                    </Typography>
                  </Box>
                  <Typography variant="h5" component="p" align="center">
                    Disk modifications usually take 2-5 minutes to complete.
                  </Typography>
                </Box>
              </Box>
            )
        }
      </Box>
    </Box>
  );
};

DiskJob.propTypes = {
  diskJob: object,
  modificationType: string,
  newSize: number,
  onGetDiskUpgradeJobStatus: func.isRequired,
};

export default DiskJob;
