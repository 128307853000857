export const headCells = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
  },
  {
    id: 'accountId',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'ACCOUNT OWNER',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'CREATED',
  },
  {
    id: 'alertType',
    numeric: false,
    disablePadding: false,
    label: 'TYPE',
  },
  {
    id: 'associatedTicketId',
    numeric: false,
    disablePadding: false,
    label: 'SHORTCUTS',
  },
];
