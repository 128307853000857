import React, {
  useState, useEffect, Fragment,
} from 'react';
import {
  number, func, object, string,
} from 'prop-types';
import qs from 'qs';
import moment from 'moment-timezone';
import styled from '@emotion/styled';

import {
  Paper, Typography,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';
import LoadingCard from 'shared/styleguide/molecules/LoadingCard';
import SupportLink from 'shared/modules/support/Link';

import Graph from './Chart/Graph';

import FilterButtons from './FilterButtons';
import Filters from './Filters';

import { getInitialDates } from './analyticsUtils';
import { DISK_EARLIEST_DATE, DEFAULT_DAY_SPREAD, DIMENSIONS_TYPE_MAP } from '../constants';

const GraphBox = styled(Box)`
  min-width: 600px;
`;

const LoadData = ({
  accountId,
  apps,
  getDimensions,
  getData, chartData, dimensions, path,
  startDate,
  endDate,
  legendPosition,
  defaultLeftAxis,
  defaultRightAxis,
  type,
  ...props
}) => {
  const [typeSelection, setTypeSelection] = useState('initial');
  const [typeSelectionStatus, setTypeSelectionStatus] = useState('initial');
  const [flush, setFlush] = useState(false);
  const [options, setOptions] = useState({});

  const [displayFilters, setDisplayFilters] = useState(false);

  const location = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const query = location?.[path];

  // get fresh dimensions
  useEffect(() => {
    let defaultOptions = {
      fromDate: startDate.format(),
      toDate: endDate.format(),
    };

    if (location[path]) {
      const { startDate, endDate, ...rest } = location[path];
      defaultOptions = { ...rest };
    }

    setOptions(defaultOptions);
    getDimensions(accountId, defaultOptions);
  }, []); // eslint-disable-line

  // update options when dates change
  useEffect(() => {
    if (startDate.format('YYYY-MM-D') !== moment(options.fromDate).tz('UTC').format('YYYY-MM-D')
      || endDate.format('YYYY-MM-D') !== moment(options.toDate).tz('UTC').format('YYYY-MM-D')) {
      let updatedOptions = {
        fromDate: startDate.format(),
        toDate: endDate.format(),
      };

      if (query) {
        updatedOptions = {
          ...updatedOptions,
          ...query, // overrides dates with query dates
        };
      } else {
        updatedOptions = {
          ...options,
          ...updatedOptions, // use new calendar picker dates
        };
      }

      setFlush(true);
      setOptions(updatedOptions);
    }
    // eslint-disable-next-line
  }, [accountId, startDate, endDate, options]);

  // get data when options change
  useEffect(() => {
    if (options.fromDate && options.toDate && dimensions.status === 'success' && accountId && dimensions.params?.accountId === accountId) {
      getData(accountId, options);
      if (flush && getData.flush) {
        getData.flush();
      }
    }
  }, [accountId, dimensions, getData, options, flush]); // eslint-disable-line

  useEffect(() => {
    if (chartData.status === 'success' && typeSelectionStatus === 'loading') {
      setTypeSelectionStatus('success');
    }
  }, [chartData.status, typeSelectionStatus]);

  // location isn't a type by default, but we want to handle it as if it was
  // set inital location options from dimensions
  useEffect(() => {
    if (!query && (typeSelection === 'initial' && !options.location && dimensions.params?.accountId === accountId && dimensions.data?.dimensions?.location)) {
      const updatedOptions = {
        ...options,
        location: dimensions.data.dimensions.location,
      };
      setFlush(true);
      setOptions(updatedOptions);
      setTypeSelection('location');
    }
  }, [options, accountId, dimensions, typeSelection]);

  const handleDisplayFilters = () => {
    setDisplayFilters(!displayFilters);
  };

  const handleUpdateOptions = (newOptions, searchString) => {
    let search = searchString;
    if (!searchString) {
      search = qs.stringify({
        [path]: {
          ...newOptions,
        },
      }, { addQueryPrefix: true });
    }

    setOptions(newOptions);

    props.history.replace({
      search,
    });
  };

  const handleChangeFilters = (column, option) => (e) => {
    const temp = { ...options };

    if (!temp[column]) {
      temp[column] = [];
    }

    temp[column] = e.target.checked ? [...temp[column], option] : temp[column].filter((o) => o !== option);

    setFlush(false);
    handleUpdateOptions(temp);
  };

  const handleSetTypeFilter = (column) => (e) => {
    // setTypeSelectionStatus('loading');

    const option = e.target.value;
    setTypeSelection(option);
    // discard types and type children
    const {
      homedir, app, username, appIds, location, ...temp
    } = options;

    if (column === 'type') {
      // create a type
      temp[column] = [option];
      const [childDimension] = DIMENSIONS_TYPE_MAP[option];
      // add new type child
      temp[childDimension] = dimensions.data.dimensions[childDimension];

      if (temp.location) {
        // location is not really a type option
        delete temp.type;
      }
    }

    setFlush(false);
    handleUpdateOptions(temp);
  };

  const handleSelectAll = (column) => (e) => {
    const temp = { ...options };
    temp[column] = e.target.checked ? dimensions.data.dimensions[column] : [];
    setFlush(false);
    handleUpdateOptions(temp);
  };

  const handleResetFilters = () => {
    const location = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    let search = '';
    const [start, end] = getInitialDates(DISK_EARLIEST_DATE, DEFAULT_DAY_SPREAD[type]);
    let resetDates = {
      fromDate: start.format(),
      toDate: end.format(),
    };

    if (location.search) {
      search = qs.stringify({
        [path]: {
          startDate: startDate.format(),
          endDate: endDate.format(),
        },
      }, { addQueryPrefix: true });
      resetDates = {
        fromDate: startDate.format(),
        toDate: endDate.format(),
      };
    }

    setTypeSelection('initial');
    setFlush(true);
    handleUpdateOptions(resetDates, search);
  };

  if ([dimensions.status, chartData.status, typeSelectionStatus].some((x) => ['pristine', 'loading'].includes(x))) {
    return <LoadingCard />;
  }

  const selectableFilters = Object.values(dimensions.data.dimensions)
    .find((v) => v.length > 1);

  return (
    <Paper>
      <Box flex={1} direction="column" padding="small">
        <Fragment>
          <Box>
            <FilterButtons
              displayFilters={displayFilters}
              onDisplayFilters={handleDisplayFilters}
              onResetFilters={handleResetFilters}
              startDate={startDate}
              endDate={endDate}
              chartData={chartData}
              location={props.location}
              options={options}
              path={path}
              selectableFilters={selectableFilters}
            />
            {
              selectableFilters
              && (
                <Filters
                  displayFilters={displayFilters}
                  onSelectAll={handleSelectAll}
                  onChangeFilters={handleChangeFilters}
                  onSetTypeFilter={handleSetTypeFilter}
                  dimensions={dimensions.data.dimensions}
                  options={options}
                  type={type}
                  path={path}
                  apps={apps}
                  typeMap={DIMENSIONS_TYPE_MAP[typeSelection]}
                />
              )
            }
          </Box>
          {
            chartData.status === 'success' && chartData.data.series.length === 0
            && (
              <Box flex={1} padding="small">
                <Empty>No data to show, try selecting different dates or filters</Empty>
              </Box>
            )
          }
          {
            chartData.status === 'success' && chartData.data.series.length > 0
            && (
              <GraphBox>
                <Graph
                  data={chartData.data}
                  columns={dimensions.data.columns}
                  defaultLeftAxis={defaultLeftAxis}
                  defaultRightAxis={defaultRightAxis}
                  legendPosition="right"
                  showXLabel={false}
                  showYLabel={false}
                  maxXTicks={10}
                  path={path}
                  apps={apps}
                />
              </GraphBox>

            )
          }
          {
            chartData.status === 'failed' && (
              <Box flex={1} padding="small">
                <Empty>
                  <Typography>No analytics were loaded.
                    This could be due to the account still provisioning, or no analytics could be loaded due to ad blockers.
                    If you are using an adblocker, please either disable your ad blockers or white-list calls starting with &quot;/api/analytics&quot;.
                    After fixing this, please reload your browser to ensure proper data. If this problem persists, please <SupportLink>contact support</SupportLink>.
                  </Typography>
                </Empty>
              </Box>
            )
          }
        </Fragment>
      </Box>
    </Paper>
  );
};

LoadData.propTypes = {
  accountId: number,
  apps: object,
  chartData: object.isRequired,
  defaultLeftAxis: string,
  defaultRightAxis: string,
  dimensions: object.isRequired,
  endDate: object,
  getData: func.isRequired,
  getDimensions: func.isRequired,
  history: object.isRequired,
  legendPosition: string,
  location: object.isRequired,
  path: string.isRequired,
  startDate: object,
  type: string.isRequired,
};

export default (LoadData);
