import makeApiAction from 'shared/utils/redux/apiActions';
import * as DISK from './constants';

export const fetchStorageVolumes = (accountId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/storage/${accountId}/volumes`),
  DISK.GET_VOLUME_STORAGE_REQUEST,
  DISK.GET_VOLUME_STORAGE_SUCCESS,
  DISK.GET_VOLUME_STORAGE_FAILURE,
  { accountId },
  true,
);

export const requestFullReport = (accountId, serverId) => makeApiAction(
  ({ api }) => {
    return api().post(`/serverconfig/servers/${serverId}/detailed-report`, { accountId });
  },
  DISK.REQUEST_REPORT_REQUEST,
  DISK.REQUEST_REPORT_SUCCESS,
  DISK.REQUEST_REPORT_FAILURE,
  { accountId },
  true,
);

export const getReport = (accountId, serverId, timestamp) => makeApiAction(
  ({ api }) => {
    return api().get(`/analytics/accounts/${accountId}/disk-report/${serverId}/${timestamp}`);
  },
  DISK.GET_REPORT_REQUEST,
  DISK.GET_REPORT_SUCCESS,
  DISK.GET_REPORT_FAILURE,
  { accountId, serverId, timestamp },
  true,
);

export const listReports = (accountId) => makeApiAction(
  ({ api }) => {
    return api().get(`/analytics/accounts/${accountId}/disk-report`);
  },
  DISK.GET_ALL_REPORTS_REQUEST,
  DISK.GET_ALL_REPORTS_SUCCESS,
  DISK.GET_ALL_REPORTS_FAILURE,
  { accountId },
  true,
);
