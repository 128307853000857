export const GET_RATE_LIMITS_REQUEST = 'ARES_GET_RATE_LIMITS_REQUEST';
export const GET_RATE_LIMITS_SUCCESS = 'ARES_GET_RATE_LIMITS_SUCCESS';
export const GET_RATE_LIMITS_FAILURE = 'ARES_GET_RATE_LIMITS_FAILURE';

export const GET_RATE_LIMIT_REQUEST = 'ARES_GET_RATE_LIMIT_REQUEST';
export const GET_RATE_LIMIT_SUCCESS = 'ARES_GET_RATE_LIMIT_SUCCESS';
export const GET_RATE_LIMIT_FAILURE = 'ARES_GET_RATE_LIMIT_FAILURE';

export const CREATE_RATE_LIMITS_REQUEST = 'ARES_CREATE_RATE_LIMITS_REQUEST';
export const CREATE_RATE_LIMITS_SUCCESS = 'ARES_CREATE_RATE_LIMITS_SUCCESS';
export const CREATE_RATE_LIMITS_FAILURE = 'ARES_CREATE_RATE_LIMITS_FAILURE';

export const SET_RATE_LIMITS_REQUEST = 'ARES_SET_RATE_LIMITS_REQUEST';
export const SET_RATE_LIMITS_SUCCESS = 'ARES_SET_RATE_LIMITS_SUCCESS';
export const SET_RATE_LIMITS_FAILURE = 'ARES_SET_RATE_LIMITS_FAILURE';

export const DELETE_RATE_LIMITS_REQUEST = 'ARES_DELETE_RATE_LIMITS_REQUEST';
export const DELETE_RATE_LIMITS_SUCCESS = 'ARES_DELETE_RATE_LIMITS_SUCCESS';
export const DELETE_RATE_LIMITS_FAILURE = 'ARES_DELETE_RATE_LIMITS_FAILURE';
