import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';

const steps = ['Let\'s Encrypt', 'Apply Certificate', 'Test Site'];

const CSRStepper = ({ step }) => (
  <Stepper activeStep={step} alternativeLabel classes={makeStyles({ root: { width: '100%' } })()}>
    {
      steps.map((stepContent, index) => {
        return (
          <Step completed={step > index} key={index}>
            <StepLabel>{stepContent}</StepLabel>
          </Step>
        );
      })
    }
  </Stepper>
);

CSRStepper.propTypes = {
  step: PropTypes.number,
};

export default CSRStepper;
