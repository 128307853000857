import React, { Component } from 'react';
import { connect } from 'react-redux';

import { object, func } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Loading from 'shared/styleguide/atoms/Loading';
import SearchBar from 'shared/styleguide/molecules/SearchBar';

import { getBannedPlugins } from 'shared/modules/support/redux/actions';

import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Card from 'shared/styleguide/atoms/Card';

class BannedPluginsList extends Component {
  state = {
    filter: '',
  };

  componentDidMount() {
    if (this.props.bannedPlugins.status === 'pristine') {
      this.props.getBannedPlugins();
    }
  }

  render() {
    const { bannedPlugins } = this.props;

    if (bannedPlugins.status === 'loading') {
      return (
        <div>
          <div style={{ textAlign: 'right', marginBottom: 20 }}>
            <Loading />
          </div>
        </div>
      );
    }

    const pluginsList = {};

    bannedPlugins.data.forEach((plugin) => {
      if (!pluginsList[plugin.name]) {
        pluginsList[plugin.name] = plugin;
      }
    });

    const list = Object.keys(pluginsList);

    const filteredList = list
      .filter(((r) => r && r.toLowerCase().match(this.state.filter)));

    return (
      <div>
        <Box margin={{ bottom: 'small' }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Displaying {filteredList.length}/{list.length}
          </Typography>
          <SearchBar
            text="Filter Plugins"
            onChange={(filter) => this.setState({ filter })}
          />
        </Box>
        <Box direction="row" wrap="wrap" justify="flex-start">
          {
            filteredList
              .sort(((a, b) => a.localeCompare(b)))
              // .map((p) => <Paper key={p} className={styles.plugin}>{p}</Paper>)
              .map((p) => <Box key={p} margin={{ right: 'xxsmall', bottom: 'xsmall' }}><GhostTag>{p}</GhostTag></Box>)
          }
        </Box>
      </div>
    );
  }
}

BannedPluginsList.propTypes = {
  bannedPlugins: object,
  getBannedPlugins: func,
};

export default connect(
  ({ support: { bannedPlugins } }) => ({ bannedPlugins }),
  {
    getBannedPlugins,
  },
)(BannedPluginsList);
