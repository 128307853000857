export const GET_VOLUME_STORAGE_REQUEST = 'DISK_GET_VOLUME_STORAGE_REQUEST';
export const GET_VOLUME_STORAGE_FAILURE = 'DISK_GET_VOLUME_STORAGE_FAILURE';
export const GET_VOLUME_STORAGE_SUCCESS = 'DISK_GET_VOLUME_STORAGE_SUCCESS';

export const REQUEST_REPORT_REQUEST = 'DISK_REQUEST_REPORT_REQUEST';
export const REQUEST_REPORT_FAILURE = 'DISK_REQUEST_REPORT_FAILURE';
export const REQUEST_REPORT_SUCCESS = 'DISK_REQUEST_REPORT_SUCCESS';

export const GET_REPORT_REQUEST = 'DISK_GET_REPORT_REQUEST';
export const GET_REPORT_FAILURE = 'DISK_GET_REPORT_FAILURE';
export const GET_REPORT_SUCCESS = 'DISK_GET_REPORT_SUCCESS';

export const GET_ALL_REPORTS_REQUEST = 'DISK_GET_ALL_REPORTS_REQUEST';
export const GET_ALL_REPORTS_FAILURE = 'DISK_GET_ALL_REPORTS_FAILURE';
export const GET_ALL_REPORTS_SUCCESS = 'DISK_GET_ALL_REPORTS_SUCCESS';

export const RESET_USAGE = 'DISK_USAGE_RESET';
