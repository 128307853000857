import React, { useCallback } from 'react';
import { object, number, func } from 'prop-types';
import { connect } from 'react-redux';
import {
  Typography, Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

import Empty from 'shared/styleguide/atoms/Empty';
import colors from 'shared/styleguide/theme';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { getAllValidationMessages } from 'shared/utils/validation';

import { simpleDiskUpgrade } from 'shared/modules/billing/redux/disk/actions';

import DiskChangeQuote from './DiskChangeQuote';

const useStyles = makeStyles(() => {
  return ({
    root: {
    },
    track: {
      height: 7,
    },
    rail: {
      height: 7,
    },
    mark: {
      height: 7,
    },
    thumbColorPrimary: {
      color: colors.secondary,
    },
    valueLabel: {
      color: colors.secondary,
    },
  });
});

const valuetext = (value) => {
  return `${value}GiB`;
};

const DISK_UPGRADE_STEP = 10;

export const DiskChange = ({
  accountId,
  planSize,
  planTotalSize,
  diskAddonPurchase,
  diskAddonCalculation,
  newSize,
  onChange,
  onChangeCommitted,
  simpleDiskUpgrade,
}) => {
  const classes = useStyles();
  const marks = [
    {
      value: planTotalSize,
      label: `${planTotalSize}GiB`,
    },
  ];

  const handlePurchaseStorage = () => {
    simpleDiskUpgrade({
      accountId, newSize,
    });
  };

  const apiErrors = () => {
    let errors;
    if (diskAddonCalculation.status === 'failed') {
      errors = getAllValidationMessages(diskAddonCalculation);
    }
    if (diskAddonPurchase.status === 'failed') {
      errors = getAllValidationMessages(diskAddonPurchase);
    }

    return errors;
  };

  const memoizedApiResponse = useCallback(apiErrors, [diskAddonCalculation, diskAddonPurchase]);

  return (
    <Box margin={{ bottom: 'small' }}>
      <Grid container alignContent="center" alignItems="center">
        <Grid item md={8} style={{ minHeight: 200 }}>
          {
            planSize && planTotalSize
            && (
              <Box column flex={1}>
                <Box justify="flex-end" align="left" padding={{ left: 'medium', right: 'medium' }} style={{ minHeight: 200 }}>
                  <Slider
                    classes={classes}
                    value={newSize || planTotalSize}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-small-steps"
                    step={DISK_UPGRADE_STEP}
                    marks={marks}
                    min={planSize}
                    max={planTotalSize + 100}
                    valueLabelDisplay="on"
                    onChange={onChange}
                    onChangeCommitted={onChangeCommitted}
                  />
                  <Box align="left" margin={{ top: 'small' }} style={{ minHeight: 50 }}>
                    <Typography variant="h5" align="left">
                      {
                        (newSize === 0 || newSize === planTotalSize) && 'Drag slider to modify storage'
                      }
                      {
                        newSize !== 0 && newSize > planTotalSize && `Add ${newSize - planTotalSize} GiB`
                      }
                      {
                        newSize !== 0 && newSize < planTotalSize && `Subtract ${planTotalSize - newSize} GiB`
                      }
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )
          }
        </Grid>
        <Grid item md={4}>
          {
            (diskAddonCalculation.status === 'pristine' || (diskAddonCalculation.status === 'success' && diskAddonCalculation.data.requestedStorage === diskAddonCalculation.data.currentStorage))
            && (
              <Box padding={{ left: 'large' }}>
                <Empty style={{ textAlign: 'center' }}>
                  A preview of modifications will appear here
                </Empty>
              </Box>
            )
          }
          {
            memoizedApiResponse()
            && (
              <Box
                column
                align="center"
                justify="flex-start"
              >
                <Typography variant="h5" align="center">
                  {
                    memoizedApiResponse()
                  }
                </Typography>
                <Box margin={{ top: 'small' }}>
                  <Link
                    to={`/account/${accountId}/support/submit`}
                  >
                    <Button
                      color="default"
                      variant="contained"
                    >
                      Contact Support
                    </Button>
                  </Link>
                </Box>
              </Box>
            )
          }
          {
            diskAddonCalculation.status === 'success'
            && diskAddonCalculation.data.requestedStorage !== diskAddonCalculation.data.currentStorage
            && (diskAddonPurchase.status !== 'success' || diskAddonPurchase.params?.accountId !== accountId)
            && !memoizedApiResponse()
            && (
              <DiskChangeQuote
                planTotalSize={planTotalSize}
                newSize={newSize}
                addonCalculation={diskAddonCalculation}
                diskAddonPurchase={diskAddonPurchase}
                onPurchase={handlePurchaseStorage}
              />
            )
          }
          {
            diskAddonCalculation.status === 'loading'
            && (
              <Box padding={{ bottom: 'medium' }}>
                <Loading />
              </Box>
            )
          }
        </Grid>
      </Grid>
    </Box>
  );
};

DiskChange.propTypes = {
  accountId: number,
  diskAddonCalculation: object,
  diskAddonPurchase: object,
  newSize: number,
  onChange: func,
  onChangeCommitted: func,
  onPurchase: func,
  planSize: number,
  planTotalSize: number,
  simpleDiskUpgrade: func.isRequired,
};

export default connect(
  (state) => {
    return {
      diskAddonCalculation: state.billing.diskAddonCalculation,
      // diskAddonPurchase: MOCKS.purchaseSuccessTicket,
      diskAddonPurchase: state.billing.diskAddonPurchase,
    };
  },
  {
    simpleDiskUpgrade,
  },
)(DiskChange);
