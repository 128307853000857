import React from 'react';
import {
  Divider,
  Typography,
} from '@material-ui/core';

import {
  Form, Formik, Field, ErrorMessage, FormikConfig,
} from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import { ErrorText } from 'shared/styleguide/typography';
import Loading from 'shared/styleguide/atoms/Loading';
import InfoText from 'shared/styleguide/molecules/InfoText';
import { ValidateTicketParams, useValidateTicketMutation } from '../query';

export const ValidateTicket = (): JSX.Element => {
  const { tokenId } = useParams<{ tokenId: string }>();
  const [validateTicket, { isSuccess, isError, isLoading }] = useValidateTicketMutation();
  const onSubmit = (values: FormValues) => {
    const { username, password, pin } = values;
    const payload: ValidateTicketParams = { tokenId };
    if (username && password) {
      payload.username = username;
      payload.password = password;
    } else if (pin) {
      payload.pin = pin;
    }
    validateTicket(payload);
  };
  if (isSuccess) {
    return (
      <Typography variant="h4" align="center"> Ticket Validated. Thank you.</Typography>
    );
  }
  if (isError) {
    return (
      <ErrorText align="center">There was an error validating your ticket. Please try again later.</ErrorText>
    );
  }
  if (isLoading) {
    <Loading />;
  }
  return (
    <ValidationForm onSubmit={onSubmit} />
  );
};

const ValidationSchema = Yup.object().shape({
  password: Yup.string().when('pin', {
    is: (val: string) => !val || val.length === 0,
    then: () => Yup.string().required('Password is required.'),
    otherwise: () => Yup.string(),
  }),
  pin: Yup.string().when(['username', 'password'], {
    is: (username: string, password: string) => !username && !password,
    then: () => Yup.string().required('PIN is required.'),
    otherwise: () => Yup.string(),
  }),
  username: Yup.string().when('pin', {
    is: (val: string) => !val || val.length === 0,
    then: () => Yup.string().required('Username is required.'),
    otherwise: () => Yup.string(),
  }),
}, [['pin', 'password'], ['pin', 'username']]);

interface FormValues extends Yup.InferType<typeof ValidationSchema> { }

function ValidationForm({ onSubmit }: { onSubmit: FormikConfig<FormValues>['onSubmit']}): JSX.Element {
  const initialValues: FormValues = { username: '', password: '', pin: '' };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
    >
      {(formik) => {
        const unfilledError = ['username', 'password', 'pin'].every((field) => field in formik.errors);
        return (
          <Form>
            <Box align="center">
              <Typography variant="h4" gutterBottom>
                Support Authentication
              </Typography>
              <InfoText>This authentication only valid for 10 minutes.</InfoText>
              <Field as={TextField} name="username" type="text" placeholder="Username" />
              {!unfilledError && <ErrorMessage name="username" />}

              <Field as={TextField} name="password" type="password" placeholder="Password" />
              {!unfilledError && <ErrorMessage name="password" />}

              <Divider />
              <Typography variant="h6" gutterBottom> OR </Typography>

              <Field as={TextField} name="pin" type="text" placeholder="PIN" />
              {!unfilledError && <ErrorMessage name="pin" />}

              {unfilledError && (
                <Box margin="small">
                  <ErrorText>Username and Password or PIN must be supplied.</ErrorText>
                </Box>
              )}
              <Box margin={{ top: 'small' }}>
                <Button
                  type="submit"
                  variant="contained"
                >
                  Authenticate
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ValidateTicket;
