import React from 'react';

import Routes from './routes';

const BillngProviderRouter = (props) => {
  return (
    <Routes {...props} />
  );
};

export default BillngProviderRouter;
