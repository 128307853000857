export const TYPE_ADMIN = 'admin';
export const TYPE_EVENT = 'event';
export const TYPE_CONFIG = 'config';
export const TYPE_ISSUE = 'issue';
export const TYPE_CREDENTIALS = 'credentials';
export const TYPE_CONTACT = 'contact';
export const TYPE_REPORT = 'report';
export const TYPE_SYSTEM = 'system';
export const TYPE_PROMISE = 'promise';
export const TYPE_MIGRATION = 'migration';
export const TYPE_SHARED = 'shared';

export const noteTypes = {
  'Note': TYPE_ADMIN,
  'Issue': TYPE_ISSUE,
  'Promise': TYPE_PROMISE,
  'br1': 'break',
  'Config': TYPE_CONFIG,
  'Credentials': TYPE_CREDENTIALS,
  'Migration': TYPE_MIGRATION,
  'Contact': TYPE_CONTACT,
  'Report': TYPE_REPORT,
  'br2': 'break',
  'Event': TYPE_EVENT,
  'System Issue': TYPE_SYSTEM,
  'Shared': TYPE_SHARED,
};
