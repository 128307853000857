import { css } from '@emotion/react';
import { Theme } from '@material-ui/core';

export const makeThemeVariables = (theme: Theme): ReturnType<typeof css> => css`
    --color-000: ${theme.color000};
    --color-100: ${theme.color100};
    --color-200: ${theme.color200};
    --color-300: ${theme.color300};
    --color-400: ${theme.color400};
    --color-500: ${theme.color500};
    --color-600: ${theme.color600};
    --color-700: ${theme.color700};
    --color-800: ${theme.color800};
    --color-900: ${theme.color900};


    --color-primary: ${theme.palette.primary.main};
    --color-secondary: ${theme.palette.secondary.main};
    --color-accent: ${theme.palette.accent.main};
    --color-textPrimary: ${theme.palette.text.primary};
    --color-textSecondary: ${theme.palette.text.secondary};
    --color-background: ${theme.palette.background.default};
    --color-border: ${theme.palette.background.border};
    --color-surface: ${theme.palette.background.paper};
    --color-contrast-background: ${theme.palette.background.contrast};
    --color-monochrome-gray100: ${theme.color400};
    --color-monochrome-gray200: ${theme.color500};

    --color-link: ${theme.palette.text.link};
    
    --glow-shadow: 
      0 2px 20px -2px rgba(0,206,142,0.75),
      0 4px 10px -4px rgba(0,206,142,0.5);
  `;
