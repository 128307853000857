import React from 'react';

import {
  Typography,
  AccordionDetails,
} from '@material-ui/core';

import AdminContext from 'shared/modules/permissions/context/AdminContext';
import Box from 'shared/styleguide/atoms/Box';
import Button, { ButtonStatus } from 'shared/styleguide/atoms/Buttons/NewButton';
import {
  dateOptions, formatDate,
} from 'shared/utils';

import ValidationItemRule from './ValidationItemRule';

interface Props {
  generateStatus: ButtonStatus;
  triggerStatus: ButtonStatus;
  domain: Record<string, any>;
  generateValidationsForDomain: () => void;
  triggerValidationsForDomain: () => void;
}

const ValidationItemDetails = ({
  generateStatus, triggerStatus, domain, generateValidationsForDomain, triggerValidationsForDomain,
}: Props) => {
  const showOperatorColumn = domain.validationRules.records.every((r) => r.records.length > 1);

  return (
    <AccordionDetails>
      <Box flex={1}>
        <Box>
          {
            domain.validationRules.records.map((rule, index) => (
              <Box key={`${domain.id}-${index}`} margin={{ top: 'xsmall' }}>
                <ValidationItemRule rule={rule} showOperatorColumn={showOperatorColumn} />
              </Box>
            ))
          }
        </Box>
        <Box direction="row" justify="flex-start" margin={{ top: 'small' }} gap="medium">
          <Box direction="row" gap="xsmall">
            <Typography color="textSecondary" variant="caption" component="span">Last Validated:</Typography>
            <Typography color="textPrimary" variant="caption" component="span">
              {
                domain.lastChecked ? (
                  formatDate(domain.lastChecked, dateOptions)
                ) : (
                  'never'
                )
              }
            </Typography>
          </Box>
          <Box direction="row" gap="xsmall">
            <Typography color="textSecondary" variant="caption" component="span">Last Successful:</Typography>
            <Typography color="textPrimary" variant="caption" component="span">
              {
                domain.lastValid ? (
                  formatDate(domain.lastValid, dateOptions)
                ) : (
                  'never'
                )
              }
            </Typography>
          </Box>
        </Box>
        <Box direction="row-reverse" justify="space-between" margin={{ top: 'small' }} align="center">
          <Box direction="row" align="center" gap="small">
            <AdminContext.Consumer>
              {
                (isAdmin) => (
                  isAdmin && (
                    <div>
                      <Button
                        color="secondary"
                        variant="contained"
                        disabled={generateStatus === 'loading'}
                        status={generateStatus}
                        onClick={() => generateValidationsForDomain()}
                      >
                        Regenerate Validation Rules
                      </Button>
                    </div>
                  )
                )
              }
            </AdminContext.Consumer>
            <div>
              <Button
                color="secondary"
                variant="contained"
                disabled={triggerStatus === 'loading'}
                status={triggerStatus}
                onClick={() => triggerValidationsForDomain()}
              >
                Validate
              </Button>
            </div>
          </Box>
          <span>
            {/* <ErrorText>
                Error messages if we got any
              </ErrorText> */}
          </span>
        </Box>
      </Box>
    </AccordionDetails>
  );
};

export default ValidationItemDetails;
