import {
  clearSubmitErrors,
  startSubmit,
  stopSubmit,
  setSubmitFailed,
  setSubmitSucceeded,
} from 'redux-form';
import store from 'store2';
import type { Dispatch } from 'redux';
import type { History } from 'history';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { loginLogout } from 'shared/modules/authentication/redux/actions'; // eslint-disable-line import/no-cycle
import logger from 'shared/3rdparty/logger';
import { getHttp } from 'shared/http';
import { API_REQUEST_START, API_REQUEST_STOP, API_FATAL } from 'shared/modules/webapp/redux/reducer';
import type { Http } from 'shared/http';
import type { Collection, RecordType } from 'shared/baseStates';
import { NumberLike } from 'shared/types/types';
import {
  Action, isReduxFormAction, Params,
} from './types';

interface CallerParams {
  api: (options?: AxiosRequestConfig) => Partial<Http> | Http;
  history: History<unknown>;
}

type Caller<ResponseShape> = (opt1: CallerParams) => Promise<AxiosResponse<ResponseShape>>;
export type ApiAction<ResponseShape> =
  (dispatch: Dispatch, getState: () => unknown, { api, history }: CallerParams)
    => Promise<ResponseShape>;

export default function makeApiAction<ResponseShape>(
  caller: Caller<ResponseShape>,
  requestTypeValue: string,
  successTypeValue: string,
  failTypeValue: string,
  params: Params = null,
  returnPromise = false,
  actionOnly = false,
): ApiAction<ResponseShape> {
  let requestType = requestTypeValue;
  let successType = successTypeValue;
  let failType = failTypeValue;

  if (actionOnly) {
    requestType += '_ACTION_ONLY';
    successType += '_ACTION_ONLY';
    failType += '_ACTION_ONLY';
  }

  let action: Partial<Action> = {
    type: requestType,
  };
  if (params) {
    action = {
      ...action,
      ...params,
      params,
    };
  }

  return (dispatch: Dispatch, getState: () => unknown, { api, history }: CallerParams) => {
    dispatch({
      type: API_REQUEST_START,
    });

    let reduxFormId;
    if (isReduxFormAction(action)) {
      reduxFormId = action.reduxFormId || action.params.reduxFormId;
      dispatch(clearSubmitErrors(action.reduxFormId));
      dispatch(startSubmit(action.reduxFormId));
    }

    dispatch(action as Action);
    return caller({ api: api || getHttp, history }) // this is a fallback for tests
      .then((res: AxiosResponse<ResponseShape>): ResponseShape => {
        const data = Array.isArray(res) ? res.map((d) => d.data ?? {}) : (res.data ?? {});

        const successAction = {
          ...action,
          type: successType,
          statusCode: res.status,
          data,
        };

        if (action.params?.successDelay) {
          setTimeout(() => {
            dispatch(successAction);
          }, action.params.successDelay);
        } else {
          dispatch(successAction);
        }

        dispatch({
          type: API_REQUEST_STOP,
        });
        if (reduxFormId) {
          dispatch(stopSubmit(reduxFormId));
          dispatch(setSubmitSucceeded(reduxFormId));
        }
        return data as ResponseShape;
      })
      .catch((err) => {
        // console.log(err); // uncomment this to debug node errors
        if (
          err.response?.status >= 500
            || err.response?.status === 405
        ) {
          const error = err instanceof Error ? err : new Error(err);
          logger.error(error, { context: 'apiAction 405 or >= 500', request: { ...action, ...err.config, err } });

          dispatch({
            type: failType,
            errMessage: 'Something bad happened',
            statusCode: 0,
          });
          dispatch({
            ...err,
            type: API_FATAL,
            requestor: requestType,
            error: JSON.stringify(err),
          });
          dispatch({
            type: API_REQUEST_STOP,
          });
          if (returnPromise) {
            return new Promise((resolve, reject) => reject(err));
          }
        }

        if (err.response) {
          switch (err.response.status) {
            case 403:
              dispatch({
                ...action,
                type: failType,
                errMessage: err.response.data,
                statusCode: err.response.status,
              });
              break;
            case 413: { // "Request Entity Too Large" error code (aka. "attachment too large")
              dispatch({
                ...action,
                type: failType,
                errMessage: err.response.data,
                statusCode: err.response.status,
              });
              break;
            }
            case 401:
              store.clear();
              dispatch(loginLogout()(dispatch, getState, { history, api }));
              break;
            case 422:
              if (reduxFormId) {
                dispatch(setSubmitFailed(reduxFormId));
                dispatch(stopSubmit(reduxFormId, err.response.data.body));
              }
              dispatch({
                ...action,
                type: failType,
                errMessage: err.message,
                statusCode: err.response.status,
                validation: err.response.data,
              });
              break;
            case 404: {
              // I guess as long as it doesn't FATAL its fine...
              // we don't want to log this to our error logger

              dispatch({
                ...action,
                type: failType,
                errMessage: err.message,
                statusCode: err.response.status, // we're switching on this, so its never going to be undefined
                response: JSON.stringify(err.response),
              });

              // dispatch(Object.assign({}, action, {
              //   type: successType,
              //   status: 404,
              //   statusCode: 404,
              //   data: {},
              // }));
              break;
            }
            default: {
              if (err.response?.data?.message) {
                logger.error(err, { context: `apiAction status ${err.response.status}`, request: { ...action, ...err.config }, metaData: err.response });
                dispatch({
                  ...action,
                  type: failType,
                  errMessage: err.response.data.message,
                  statusCode: err.response.status || 0,
                  response: err.response,
                });
              } else {
                logger.error(err, { context: `apiAction status ${err.response.status}`, request: action, metaData: err.response });
                dispatch({
                  ...action,
                  type: failType,
                  errMessage: err.message,
                  statusCode: err.response ? err.response.status || 0 : 0,
                  response: err.response,
                });
              }
              break;
            }
          }
        } else if (typeof err.response === 'undefined') {
          logger.log('🚨', failType, err);
          if (process.env.NODE_ENV === 'development') {
            console.log('🚨 TODO: wrap network request with msw');
          }
        } else {
          dispatch({
            type: failType,
            errMessage: err.toString(),
            statusCode: 0,
          });
        }

        dispatch({
          type: API_REQUEST_STOP,
        });
        if (returnPromise) {
          throw err;
        }
      });
  };
}

/** @deprecated */
export function defaultShould(stateKey: string) {
  return function shouldPeformAction(state: Record<string, Collection>, id: NumberLike): boolean {
    const key = state[stateKey];

    return !(key.isFetching || key.id === id);
  };
}

/** @deprecated */
export function collectionShould(collection: string) {
  return function shouldPeformCollectionAction(state: Record<string, Collection>): boolean {
    return !state[collection].isFetching;
  };
}

/** @deprecated */
export function recordShould(collection: string) {
  return function shouldPeformRecordAction(state: Record<string, Collection>, id: NumberLike): boolean {
    const record = state[collection].records.find((n: RecordType) => n.id === id);

    return !(!record || record.isFetching);
  };
}

export const makeSimpleApiAction = (
  caller: Caller<CallerParams>,
  requestType: string,
  successType: string,
  failType: string,
  params: Params = {},
): ApiAction<void> => {
  const action: Partial<Action> = {
    ...params,
    type: requestType,
  };

  return (dispatch: Dispatch, getState: () => unknown, { api, history }: CallerParams) => {
    dispatch({
      type: API_REQUEST_START,
    });
    if (isReduxFormAction(action)) {
      dispatch(clearSubmitErrors(action.reduxFormId));
      dispatch(startSubmit(action.reduxFormId));
    }
    dispatch(action as Action);
    return caller({ api: api || getHttp, history }) // this is a fallback for tests
      .then((res: AxiosResponse) => {
        const data = Array.isArray(res) ? res.map((d) => d.data) : res.data;

        dispatch({ ...action, type: successType, data });

        dispatch({
          type: API_REQUEST_STOP,
        });
        if (isReduxFormAction(action)) {
          dispatch(stopSubmit(action.reduxFormId));
        }
      })
      .catch((err) => {
        dispatch({
          ...action,
          type: failType,
          statusCode: err.response ? err.response.status || 0 : 0,
        });

        dispatch({
          type: API_REQUEST_STOP,
        });
      });
  };
};
