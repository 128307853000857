import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  withStyles, Dialog, DialogTitle, DialogContent, DialogActions, Typography,
} from '@material-ui/core';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { uploadCert, reset } from 'shared/modules/ssl/redux/cert/actions';
import ValidatingTextFileSelect from 'shared/styleguide/molecules/ValidatingTextFileSelect/ValidatingTextFileSelect';
import Box from 'shared/styleguide/atoms/Box';

import { certValidate } from 'shared/modules/ssl/certValidator';
import style from './styles';

export class UploadCert extends React.Component {
  static propTypes = {
    cert: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    doing: PropTypes.bool.isRequired,
    done: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    serverValidationMessage: PropTypes.string,
    setTitle: PropTypes.func.isRequired,
    uploadCert: PropTypes.func.isRequired,
  };

  state = {
    cert: null,
  };

  onChange = (text, validated) => {
    this.setState({
      cert: text,
      validated,
    });
  };

  handleContinue = () => this.props.history.push(
    `/account/${this.props.match.params.accountID}/ssl/cert/${this.props.cert.id}/link`,
  );

  handleSubmit = () => {
    this.props.uploadCert(this.props.match.params.accountID, this.state.cert);
  };

  handleClose = () => {
    this.props.history.push(`/account/${this.props.match.params.accountID}/ssl`);
  };

  render() {
    const {
      classes, cert, doing, done,
    } = this.props;
    let serverError = null;
    if (cert.requestErr) {
      switch (cert.statusCode) {
        case 422:
          serverError = cert.validation.body.certificate.messages.map((msg) => <div key={msg}>{msg}</div>);
          break;
        default:
          serverError = cert.errMessage;
          break;
      }
    }

    return (
      <Dialog open onClose={this.handleClose}>
        <DialogTitle>
          Upload A Certificate
        </DialogTitle>
        <DialogContent>
          <ValidatingTextFileSelect
            fileRequirements=".crt,.txt,.pem"
            onChange={this.onChange}
            doing={doing}
            done={done}
            validator={certValidate}
            serverValidationMessage={serverError}
          />
          <div className={classes.uploadCertSubs}>
            <div style={{ margin: 5 }}>
              <Typography variant="body2" color="textSecondary">If you are having issues pasting your certificate, please use the .crt/.cert/.pem file which you should have received from your provider by clicking the &quot;Select File&quot; button above.</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Box direction="row" justify="flex-end" padding="small">
            {done ? ([
              <span key="span">&nbsp;</span>,
              <Button
                color="secondary"
                variant="contained"
                key="button"
                label="Continue"
                onClick={this.handleContinue}
              />,
            ]) : ([
              <Button
                color="secondary"
                variant="contained"
                css={{ width: 150 }}
                key="submit"
                label="Submit"
                onClick={this.handleSubmit}
                disabled={!this.state.validated || doing || done}
              />,
            ])}
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  cert: state.cert,
  doing: state.cert.isFetching,
  done: !!state.cert.id,
  validationMessage: state.cert.errMessage,
});

const mapDispatchToProps = {
  uploadCert,
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(UploadCert));
