import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useQuery from 'shared/utils/hooks/useQueryParam';
import Loading from 'shared/styleguide/atoms/Loading';
import NoteSidebar from './List';
import NoteCreate from './Create';
import NoteViewer from './View';
import NoteEditor from './Edit';

export const Notes = (props) => {
  const { match } = props;
  const queryParams = useQuery();
  const { account, user } = useSelector((state) => state);

  const { noteView, noteId, noteType } = queryParams;

  switch (noteView) {
    case 'create': {
      return <NoteCreate user={user} account={account} match={props.match} accountID={match.params.accountID} />;
    }
    case 'view': {
      return <NoteViewer account={account} match={props.match} basePath={match.url} noteId={noteId} noteType={noteType} />;
    }
    case 'edit': {
      return <NoteEditor user={user} account={account} match={props.match} basePath={match.url} noteId={noteId} />;
    }
    default: {
      return account.id ? (
        <NoteSidebar account={account} />
      ) : (<Loading />);
    }
  }
};

Notes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Notes;
