import { startConfiguration, delayConfiguration } from 'pusher-redux';
import store2 from 'store2';
import logger from 'shared/3rdparty/logger';

export const CONSTANTS = {
  STATUS_UPDATE: 'PUSHER_STATUS_UPDATE',
  ADMIN_STATUS_UPDATE: 'PUSHER_ADMIN_STATUS_UPDATE',
  ALERT: 'PUSHER_ALERT',
};

export const EVENTS = {
  STATUS_UPDATE: 'status_update',
  ALERT: 'alert',
};

export default (store) => {
  delayConfiguration(store, process.env.REACT_APP_PUSHER_KEY, {
    authEndpoint: '/api/auth/socket',
    encrypted: true,
    cluster: process.env.REACT_APP_PUSHER_REGION || 'us2',
  });
};

export const configurePusher = () => {
  const token = store2.get('atomic_token');
  if (token) {
    try {
      startConfiguration({
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    } catch (err) {
      logger.error(err);
    }
  }
};
