export const isJsonValid = (jsonString) => {
  if (jsonString === null || jsonString === '') {
    return true;
  }
  if (typeof jsonString === 'string') {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      // nothingggg
    }
  }
  return false;
};
