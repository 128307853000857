import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { RouteChildrenProps } from 'react-router';
import Box from 'shared/styleguide/atoms/Box';
import { useLazyGetCredentialsForCustomerByChannelIdQuery } from 'shared/utils/redux/query';
import Loading from 'shared/styleguide/atoms/Loading';
import { ErrorText } from 'shared/styleguide/typography';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Card from 'shared/styleguide/atoms/Card';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import logger from 'shared/3rdparty/logger';
import { migrationSecretsPath } from './constants';

interface Props extends RouteChildrenProps<{ channelId: string }> { }

const parseSecret = (data: Record<string, any>) => {
  if (!data?.secret) return 'No secret to parse.';
  let parsedData;
  try {
    parsedData = JSON.stringify(JSON.parse(data.secret), null, 2);
  } catch (e) {
    logger.error(e, data);
    parsedData = 'Error attempting to parse secret.';
  }
  return parsedData;
};

const MigrationSecretsForm = ({ match }: Props): JSX.Element => {
  const [trigger, result] = useLazyGetCredentialsForCustomerByChannelIdQuery();
  const { data, error, isLoading } = result;

  let content = null;
  if (isLoading) {
    content = <Loading />;
  } else if (error) {
    content = <ErrorText>{JSON.stringify(error)}</ErrorText>;
  } else if (data) {
    content = parseSecret(data);
  }

  const { channelId: channelIdFromParams } = match.params;
  useEffect(() => {
    if (channelIdFromParams) {
      trigger(channelIdFromParams);
    }
  }, [channelIdFromParams, trigger]);
  const [channelId, setChannelId] = useState(channelIdFromParams);

  const fetchSecrets = (e) => {
    e.preventDefault();
    if (channelId) {
      window.history.replaceState(null, null, `${migrationSecretsPath}/${channelId}`);
      trigger(channelId);
    }
  };

  return (
    <form onSubmit={fetchSecrets}>
      <Box padding="small">
        <Typography variant="h2" gutterBottom>Migration Secrets</Typography>
        <Box as={Card} margin={{ bottom: 'small' }} padding="small">
          <Typography>Fetch migration secrets by channel id:</Typography>
          <Box direction="row" align="center" flex="1">
            <TextField
              variant="outlined"
              margin="dense"
              css={{
                width: '350px',
              }}
              placeholder="Channel ID"
              value={channelId}
              style={{ marginRight: 20 }}
              onChange={(e) => setChannelId(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!channelId}
            >
              Fetch
            </Button>
          </Box>
        </Box>
        <Box padding={{ top: 'small' }}>
          <pre>
            {content}
          </pre>
        </Box>
      </Box>

    </form>
  );
};

export default MigrationSecretsForm;
