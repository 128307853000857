import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import store2 from 'store2';
import config from 'shared/config';

const getToken = () => store2.get('atomic_token');
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCloneLogsByAppId: builder.query<{ data: any[] }, string | number>({
      query: (appId) => `serverconfig/logs/sync/app/${appId}`,
    }),
    getCredentialsForCustomerByChannelId: builder.query<{
      secret?: string;
    }, string>({
      query: (channelId) => `apps/channel/${channelId}/migration-request`,
    }),
  }),
});

export const {
  useGetCloneLogsByAppIdQuery,
  useLazyGetCredentialsForCustomerByChannelIdQuery,
} = coreApi;
