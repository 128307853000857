export const getValidationMessageForProperty = (state, sectionName, propertyName) => {
  if (
    state.validation
    && state.validation.status === 'ERROR'
    && state.validation[sectionName][propertyName]
  ) {
    return state.validation[sectionName][propertyName].messages.join(', ');
  }
  return null;
};

export const getAllValidationsWithKeys = (err) => {
  if (err.response) {
    const { response: { data: { body } } } = err;
    return flattenValidation(body);
  }
  return null; // idk what to put here
};

export const getAllValidationMessages = (state) => {
  if (
    state.validation
    && state.validation.status === 'ERROR'
  ) {
    const errors = [];
    Object.keys(state.validation).forEach((section) => {
      if (section === 'status') {
        return;
      }
      Object.keys(state.validation[section]).forEach((prop) => {
        errors.push(...state.validation[section][prop].messages);
      });
    });
    return errors.length ? errors.join(', ') : null;
  }

  const err = state;
  if (err.response) {
    const { response: { data: { body, path, query } } } = err;
    const validationMessage = [];

    if (err.response.body) { // sometimes the validation is here...
      const errors = Object.entries(body)
        .filter(([, value]) => value.messages.length)
        .map(([key, value]) => {
          const messages = value.messages.join(', ');
          return `${key}: ${messages}`;
        }).join('; ');

      validationMessage.push(errors || 'Something went wrong');
    }

    if (body) {
      /* eslint-disable prefer-destructuring */
      const errors = Object.entries(body)
        .filter(([, value]) => value.messages.length)
        .map(([key, value]) => {
          let messages = '';
          if (Array.isArray(value)) {
            messages = value.messages.join(', ');
          } else {
            messages = value.messages;
          }
          return `${key}: ${messages}`;
        })
        .join('; ');
      validationMessage.push((errors || 'Something went wrong'));
    }
    if (path) {
      const errors = Object.entries(path)
        .filter(([, value]) => value.messages.length)
        .map(([key, value]) => {
          let messages = '';
          if (Array.isArray(value)) {
            messages = value.messages.join(', ');
          } else {
            messages = value.messages;
          }
          return `${key}: ${messages}`;
        })
        .join('; ');
      validationMessage.push((errors || 'Something went wrong'));
    }
    if (query) {
      const errors = Object.entries(query)
        .filter(([, value]) => value.messages.length)
        .map(([key, value]) => {
          let messages = '';
          if (Array.isArray(value)) {
            messages = value.messages.join(', ');
          } else {
            messages = value.messages;
          }
          return `${key}: ${messages}`;
        })
        .join('; ');
      validationMessage.push((errors || 'Something went wrong'));
    }
    /* eslint-enable */

    return validationMessage.join('; ');
  }

  return null;
};

export const flattenValidation = (validationMessages) => {
  const returnable = {};
  Object.entries(validationMessages).forEach(([key, value]) => {
    returnable[key] = value.messages.join(', ');
  });
  return returnable;
};

/**
 * Consolidate error messages into a single string
 * @param {object} err
 * @param {?string} filterKey
 * @param {boolean} removeKeys
 */
export const consolidateErrors = (err, filterKey = null, removeKeys = false) => {
  if (err.response) {
    if (err.response.body || err.response.data?.body) {
      let bodyErrors = err.response.body || err.response.data?.body;
      if (filterKey) {
        bodyErrors = Object.keys(bodyErrors)
          .filter((key) => [filterKey].includes(key))
          .reduce((carry, k) => {
            // eslint-disable-next-line no-param-reassign
            carry[k] = bodyErrors[k];
            return carry;
          }, {});
      }
      const errors = Object.entries(bodyErrors).map(([key, value]) => {
        const messages = value.messages.join(', ');
        return removeKeys ? messages : `${key}: ${messages}`;
      }).join('; ');
      return errors || 'Something went wrong';
    } else if (err.response.data && err.response.data.message) {
      return err.response.data.message;
    } else if (err.response.data || err.response.status === 422) {
      return getAllValidationMessages(err);
    } else if (err.response.message) {
      return err.response.message;
    }
  }

  return err.message || 'Something went wrong';
};

export const validateEmail = (input) => {
  if (!input) {
    return 'Required';
  } else if (!/[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,63}/.test(input)) {
    return 'Invalid email address';
  }
};

const hasSequentialCharacters = (s) => {
  const pw = s.toLowerCase();
  let currentSequenceLength = 1;
  for (let i = 0; i < pw.length - 1; i++) {
    if (pw.charCodeAt(i) === pw.charCodeAt(i + 1) - 1) {
      currentSequenceLength++;
      if (currentSequenceLength >= 4) {
        return true;
      }
    } else {
      currentSequenceLength = 1;
    }
  }

  return false;
};

export const validatePassword = (input) => {
  /**
   * Password Requirements:
      Must be at least 8 characters long
      Must not have 4 or more consecutive repeated characters
      Must match at least two of the following rules:
      - one lower and one uppercase letter
      - one number
      - one non-alphanumeric character (symbols, unicode, etc)
      Must not include 4 consecutive sequential characters (abcd, 1234, etc)
   */
  const problems = [];
  const validRules = [true, true, true, true];

  if (input.length < 8) {
    problems.push('Must be at least 8 characters long');
    validRules[0] = false;
  }
  if ((/(.)\1{3}/g).test(input)) {
    problems.push('Must not have 4 or more consecutive repeated characters');
    validRules[1] = false;
  }

  let validationCounter = 0;

  // - one lower and one uppercase letter
  if ((/(?=.*[a-z])(?=.*[A-Z])/).test(input)) {
    validationCounter++;
  }

  // one number
  if ((/(\d)+/).test(input)) {
    validationCounter++;
  }

  // one non-alphanumber character
  if ((/[^A-Za-z0-9]/g).test(input)) {
    validationCounter++;
  }

  if (validationCounter < 2) {
    problems.push('Must be a stronger password');
    validRules[2] = false;
  }

  if (hasSequentialCharacters(input)) {
    problems.push('Must not include 4 consecutive sequential characters (abcd, 1234, etc)');
    validRules[3] = false;
  }

  if (problems.length > 0) {
    return [problems[0], validRules];
  } else {
    return [null, validRules];
  }
};
