import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  object, number, string,
} from 'prop-types';

import { Typography } from '@material-ui/core';

import graphColors from 'shared/styleguide/theme/graphColors';
import * as diskPropTypes from 'shared/modules/analytics/models/propTypes';
import Box from 'shared/styleguide/atoms/Box';

import { formatMiB } from 'shared/utils/';

import {
  ToolTipBox, LegendTypography, graphSymbol, goToPressFormance,
} from './graphUtils';

import { mockPieData } from '../../../mocks/pieChart';

import DiskPie from './DiskPie';

const sliceLabel = (e) => {
  if (e.id !== 'unused') {
    return (
      <tspan style={{ fontSize: 14 }}>{e.value >= 5 ? `${e.value}%` : ''}</tspan>
    );
  }
};

// eslint-disable-next-line react/display-name
const toolTip = (tooltip, view) => (e) => {
  const { data } = e.datum;
  const usage = view === 'absolute' ? `${data.value}% (${data.size})` : data.size;
  let content;
  if (data.id === 'unused') {
    content = <Box row gap="xsmall"><Typography variant="body2">Unused</Typography></Box>;
  } else {
    content = (
      <Fragment>
        {graphSymbol(data.id, e.datum.color, 16)}
        <Box row gap="xsmall">
          <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}><strong>{tooltip}:</strong> {data.label}</Typography>
          <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}><strong>Usage: </strong>{usage}</Typography>
        </Box>
      </Fragment>
    );
  }

  return (
    <ToolTipBox
      direction="row"
      align="flex-start"
      gap="xsmall"
    >
      {content}
    </ToolTipBox>
  );
};

const formatData = (series, graphMap, graphType, appNames) => {
  const { tag, view } = graphMap;

  let totalPercent = 0;
  let totalSize = 0;
  const data = series
    .filter((s) => s.values.length)
    .map((location, index) => {
      const values = location.values.filter((p) => p[2]).map((value) => {
        const size = formatMiB(value[2]);
        return {
          percentage: value[1],
          size,
          sizeRaw: Math.round(value[2], false),
        };
      });

      const latestValues = values.slice(-1)[0];
      const valuePercentage = latestValues.percentage < 1 ? Math.ceil(latestValues.percentage) : Math.round(latestValues.percentage);
      const value = view === 'absolute' ? valuePercentage : latestValues.sizeRaw;

      totalPercent += latestValues.percentage;
      totalSize += latestValues.sizeRaw;

      const id = location.tags[tag];
      let label;
      if (['app', 'gitrepo'].includes(graphType)) {
        label = appNames[id]?.label || appNames[id]?.name;
      }

      return {
        id,
        step: index,
        label: label ? `${id} - ${label}` : id,
        size: latestValues?.size,
        value,
      };
    })
    .sort((a, b) => {
      return b.value - a.value;
    });

  return [data, totalSize, totalPercent];
};

const DiskPieWrapper = ({
  diskUsage, graphType, graphMap, history, queryParams, accountId, appNames,
}) => {
  const { view, tooltip } = graphMap;

  let adjustedData = mockPieData;

  const [data, totalSize, totalPercent] = formatData(diskUsage.series, graphMap, graphType, appNames);

  if (view === 'absolute' && Math.ceil(totalPercent) < 100) {
    // add free space segment for overview
    const unusedPercentage = 100 - Math.ceil(totalPercent);
    data.push({
      id: 'unused',
      step: data.length,
      label: 'unused',
      size: '',
      value: unusedPercentage,
    });
  }

  adjustedData = data;

  if (view === 'relative') {
    // convert values to percent to ensure a value will be 1% or greater, otherwise it will not display
    adjustedData = data.map((d) => {
      const item = { ...d };
      item.value = Math.ceil((item.value / totalSize) * 100);
      return item;
    });
  }

  const legend = data.map((item, i) => {
    const { id } = item;

    let label;
    if (['app', 'gitrepo'].includes(graphType)) {
      label = appNames[id]?.label || appNames[id]?.name;
    }

    return (
      <Box direction="row" gap="xsmall" padding={{ top: 'xxsmall' }} key={id}>
        {graphSymbol(id, graphColors[i], 14)}
        <Box>
          <LegendTypography>{label || id}</LegendTypography>
        </Box>
      </Box>
    );
  });

  return (
    <Box direction="row" justify="center">
      <Box>
        <DiskPie
          data={adjustedData}
          link={goToPressFormance(history, accountId, queryParams)}
          graphColors={graphColors}
          toolTip={toolTip(tooltip, view)}
          sliceLabel={view === 'absolute' ? sliceLabel : () => { }}
          centerText={(totalSize > 999 ? `${(totalSize / 1000).toFixed(2)} GiB` : `${Math.ceil(totalSize)} MiB`)}
        />
      </Box>
      <Box
        margin={{ left: 'small' }}
        padding={{ top: 'xsmall' }}
        justify="center"
        direction="column"
        style={{ minWidth: 150 }}
      >
        {legend}
      </Box>
    </Box>
  );
};

DiskPieWrapper.propTypes = {
  accountId: number,
  appNames: object,
  data: object,
  diskUsage: diskPropTypes.usage,
  graphMap: object,
  graphType: string,
  history: object,
  queryParams: object,
};

export default withRouter(DiskPieWrapper);
