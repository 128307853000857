import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadioButton from '@material-ui/core/Radio';
import {
  string, any, bool, node,
} from 'prop-types';

const RadioButton = ({ labelPosition, ...props }) => {
  return (
    <FormControlLabel
      {...props}
      labelPlacement={labelPosition}
      control={(
        <MuiRadioButton />
      )}
      disabled={props.disabled}
      value={props.value}
      label={props.label}
    />
  );
};

RadioButton.propTypes = {
  disabled: bool,
  label: node,
  labelPosition: string,
  value: any,
};

export default RadioButton;
