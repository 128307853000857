import React from 'react';
import {
  shape, func, arrayOf, bool, number, object, array,
} from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import GoIcon from '@material-ui/icons/Reply';
import {
  Typography, IconButton,
} from '@material-ui/core';

import { collaboratorPropTypes } from 'shared/modules/account/models/model';
import { roleStrings, roles as ROLES } from 'shared/modules/permissions/user/actions';
import Box from 'shared/styleguide/atoms/Box';

import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import Avatar from 'shared/styleguide/molecules/Avatar';
import EditCollaborator from './EditCollaborator';

export class Collaborator extends React.Component {
  static propTypes = {
    accountId: number.isRequired,
    apps: arrayOf(shape({
    })).isRequired,
    collab: shape(collaboratorPropTypes).isRequired,
    isPagelyAdmin: bool.isRequired,
    isYou: bool.isRequired,
    removeCollaborator: func.isRequired,
    userPool: object,
  };

  state = {
    open: false,
    values: [...this.props.collab.appIds],
  };

  renderAddDialog = () => {
    const { collab } = this.props;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        PaperProps={{
          style: { overflow: 'visible' },
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Edit Collaborator Permissions</DialogTitle>
        <EditCollaborator
          {...this.props}
          onSetOpen={(openState) => this.setState({ open: openState })}
          collaborator={collab}
          editing
        />
      </Dialog>
    );
  };

  render() {
    const { collab, apps, isPagelyAdmin } = this.props;
    const { open } = this.state;

    return (
      <TableRow hover data-testid="collaborator-row">
        <TableCell>
          <Box direction="row">
            <Avatar
              fullName={collab.accessedByName}
              email={collab.accessedByEmail}
            />
            <Box column margin={{ left: 'xsmall' }}>
              <Typography variant="h5" style={{ lineHeight: '0.7rem' }}>
                {collab.accessedByName}
                {
                  isPagelyAdmin && (
                    <IconButton
                      id="pagely-collab-link"
                      component={RouterLink}
                      to={`/account/${collab.sourceId}/settings/profile`}
                      size="small"
                      style={{ transform: 'scaleX(-1)' }}
                    >
                      <GoIcon fontSize="small" />
                    </IconButton>
                  )
                }
              </Typography>
              <Box direction="row" align="center" margin={{ top: 'xsmall' }} style={{ lineHeight: '0.7rem' }}>
                <TextLink
                  href={`mailto:${collab.accessedByEmail}`}
                  noUnderline
                >
                  {collab.accessedByEmail}
                </TextLink>
                {
                  isPagelyAdmin && (
                    <span css={{ color: 'var(--color-textSecondary)' }}>
                      <CopyButton
                        size="small"
                        color="inherit"
                        text={collab.accessedByEmail}
                        message="email copied to clipboard"
                      />
                    </span>
                  )
                }
              </Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box direction="row" align="center">
            {roleStrings[collab.role]}
          </Box>
        </TableCell>
        <TableCell>
          <Box direction="row" wrap="wrap" gap="xxsmall">
            {
              (collab.appIds)
                .map((t) => {
                  const allAppId = 0;
                  // determine the label
                  const appTag = t === allAppId
                    ? { id: allAppId, name: 'All Apps' }
                    : (
                      // real app label
                      apps.find((r) => r.id === t)
                      // fallback if app doesn't exist
                      || { id: -1, name: 'Unknown App' }
                    );

                  return (
                    <GhostTag
                      data-testid="app-tag"
                      key={`${collab.sourceId}-${t}`}
                      color={!appTag.id ? 'green' : 'orange'}
                      noMargin
                    >
                      {appTag.label || appTag.name}
                    </GhostTag>
                  );
                })
            }
          </Box>
        </TableCell>
        <TableCell>
          {
            collab.role < ROLES.PRIMARY && (
              <IconButton
                data-testid="edit-role"
                id="edit-role"
                onClick={() => { this.setState({ open: true }); }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )
          }
        </TableCell>
        {
          open
          && (this.renderAddDialog(this.props))
        }
      </TableRow>
    );
  }
}

Collaborator.propTypes = {
  accountId: number,
  addCollaborator: func,
  apps: array,
  collab: object,
  isYou: bool,
  removeCollaborator: func,
};

export default Collaborator;
