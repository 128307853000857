import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import apiReducer, {
  baseCollectionState,
} from 'shared/utils/redux/apiReducer';
import { UPDATE_APP_SUCCESS } from 'shared/modules/app/redux/constants';
import * as APPS from './constants';

const baseApps = apiReducer(
  APPS.APPS_REQUEST,
  APPS.APPS_SUCCESS,
  APPS.APPS_FAILURE,
  LOGIN_LOGOUT_SUCCESS,
  baseCollectionState,
);

export const apps = (state = { ...baseCollectionState, directory: {} }, action) => {
  switch (action.type) {
    case APPS.APP_CREATE_SUCCESS: {
      return {
        ...state,
        doing: false,
        done: true,
        loading: false,
        data: [...state.data, action.data],
      };
    }
    case APPS.APP_DELETE_SUCCESS:
      return {
        doing: false,
        done: true,
        loading: false,
        loaded: true,
        data: state.data.filter((a) => a.id !== action.data.id),
      };
    case UPDATE_APP_SUCCESS: {
      const data = [...state.data];
      const updatedAppIndex = state.data.findIndex((app) => app.id === action.data.id);
      // this removes primary domain :(
      data.splice(updatedAppIndex, 1, { ...action.data, primaryDomain: state.data[updatedAppIndex].primaryDomain });
      return {
        ...state,
        data,
      };
    }
    default: {
      return baseApps(state, action);
    }
  }
};
