import { NumberLike } from 'shared/types/types';
import { coreApi } from 'shared/utils/redux/query';
import { Note, NotesForAccountIdResponse } from './types';

const api = coreApi.enhanceEndpoints({
  addTagTypes: ['NotesList'],
});

export const notesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotesForAccount: build.query<NotesForAccountIdResponse, { accountId: NumberLike }>({
      query: ({ accountId }) => {
        // more options: https://storybook.dev.pagely.com/master-api/pagely.html#tag/Notes/paths/~1notes/get
        return `/notes?accountId=${accountId}`;
      },
      providesTags: ['NotesList'],
    }),
    getSharedNotesForAccount: build.query<NotesForAccountIdResponse, { accountId: NumberLike }>({
      query: ({ accountId }) => {
        return `/notes/shared/${accountId}`;
      },
      providesTags: ['NotesList'],
    }),
    getSharedNoteById: build.query<Note, NumberLike>({
      query: (noteId) => {
        return `/notes/shared/${noteId}`;
      },
    }),
    getNoteById: build.query<Note, NumberLike>({
      query: (noteId) => {
        return `/notes/${noteId}`;
      },
      providesTags: ['NotesList'],
    }),
    createNote: build.mutation({
      query: (body) => ({
        url: '/notes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NotesList'],
    }),
    updateNote: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/notes/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['NotesList'],
    }),
    deleteNote: build.mutation({
      query: (id) => ({
        url: `/notes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NotesList'],
    }),
  }),
});

export const {
  useGetNotesForAccountQuery,
  useGetNoteByIdQuery,
  useGetSharedNoteByIdQuery,
  useGetSharedNotesForAccountQuery,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useCreateNoteMutation,
} = notesApi;
