/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,

} from '@material-ui/core';

import * as STATUS from 'shared/modules/status/redux/constants';
import { fetchJobById } from 'shared/modules/status/redux/actions';
import { triggerValidation } from '../../../redux/dns/actions';

import SelectDomains from './SelectDomains';
import ValidateDomains from './Validate';

// eslint-disable-next-line react/display-name
const ValidationModal = ({
  domains, isOpen, setOpen, defaultValidations, app, triggerValidation, jobs, fetchJobById,
}) => {
  const [view, setView] = useState('select');
  const [selectedDomains, setSelectedDomains] = useState(Object.keys(defaultValidations));

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClose={() => { setOpen(false); setView('select'); }}
    >
      <DialogTitle>DNS Validation</DialogTitle>
      {
        view === 'select' && (
          <SelectDomains
            domains={domains}
            setView={setView}
            setSelectedDomains={setSelectedDomains}
            defaultValidations={defaultValidations}
            triggerValidation={triggerValidation}
            app={app}
          />
        )
          }
      {
        view === 'validate' && (
          <ValidateDomains
            domains={domains}
            setView={setView}
            setOpen={setOpen}
            app={app}
            jobs={jobs}
            selectedDomains={selectedDomains}
          />
        )
      }
    </Dialog>
  );
};

export default connect(
  (state) => ({
    app: state.app.app,
    jobs: state.status[STATUS.jobTypes.dnsValidation].jobs,
  }),
  {
    triggerValidation,
    fetchJobById,
  },
)(ValidationModal);
