import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, withStyles } from '@material-ui/core';
import AutoComplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import { states } from 'shared/utils/constants';
import Box from 'shared/styleguide/atoms/Box';
import HeadlineText from 'shared/styleguide/typography/HeadlineText';
import { CSRActions } from './CSRStepper';
import styles from './styles';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  ignoreCase: true,
});

export class OptionalForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = this.props.fields;

  handleFieldUpdate = (field) => (event, value) => {
    this.setState({ [field]: value || '' });
  };

  goBack = () => {
    const { onSubmit } = this.props;
    onSubmit({}, 0);
  };

  doSubmit = (localityName, stateOrProvinceName, organizationalUnitName, emailAddress) => {
    const { onSubmit } = this.props;
    onSubmit({
      localityName, stateOrProvinceName, organizationalUnitName, emailAddress,
    }, 3);
  };

  render() {
    const {
      localityName, stateOrProvinceName, organizationalUnitName, emailAddress, countryName,
    } = this.state;
    const { classes } = this.props;

    const statesInput = (params) => (
      <TextField
        {...params}
        fullWidth
        margin="normal"
        placeholder="State/Province"
      />
    );

    return (
      <form
        onSubmit={(event) => {
          event.preventDefault();
          this.doSubmit(localityName, stateOrProvinceName, organizationalUnitName, emailAddress);
        }}
        className={classes.csrModalContainer}
      >
        <DialogContent>
          <HeadlineText>Optional Fields</HeadlineText>
          <Box justify="space-between" direction="row">
            <TextField
              style={{ width: '45%' }}
              margin="normal"
              placeholder="City/Locality"
              value={localityName}
              onChange={this.handleFieldUpdate('localityName')}
              name="localityName"
            />
            {
              countryName === 'United States' ? (
                <AutoComplete
                  style={{ width: '45%' }}
                  inputValue={stateOrProvinceName}
                  onChange={this.handleFieldUpdate('stateOrProvinceName')}
                  filterOptions={filterOptions}
                  options={states.map((s) => s.abbr)}
                  renderInput={statesInput}
                />
              ) : (
                <TextField
                  style={{ width: '45%' }}
                  fullWidth
                  margin="normal"
                  placeholder="State/Province"
                  value={stateOrProvinceName}
                  onChange={this.handleFieldUpdate('stateOrProvinceName')}
                />
              )
            }
          </Box>

          <TextField
            fullWidth
            margin="normal"
            placeholder="Organization Unit"
            value={organizationalUnitName}
            onChange={this.handleFieldUpdate('organizationalUnitName')}
            name="organizationalUnitName"
          />
          <br />
          <TextField
            fullWidth
            margin="normal"
            placeholder="Email"
            value={emailAddress}
            onChange={this.handleFieldUpdate('emailAddress')}
            name="emailAddress"
          />
        </DialogContent>
        <CSRActions step={2} onClick={this.goBack} />
      </form>
    );
  }
}

export default withStyles(styles)(OptionalForm);
