import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import type { UserPool } from 'shared/modules/account/redux/sso/types';
import type { Account } from 'shared/modules/account/types';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import { StatusKeys } from '../constants';

interface Props {
  account: Account;
  userPool: UserPool | null;
  finishSamlSetup: (userPoolId: string, samlUrl: string, idpName: string) => Promise<UserPool>;
  updateStatus: (status: StatusKeys) => void;
  updateAccount: (accountId: number, options?: Partial<Account>) => Promise<Account>;
}

interface Errors {
  samlUrl?: string;
  idpName?: string;
}

const Complete: React.FC<Props> = ({
  userPool, finishSamlSetup, updateStatus, account, updateAccount,
}) => {
  const [samlUrl, setSamlUrl] = useState('');
  const [idpName, setIdpName] = useState('');
  const [errors, setErrors] = useState<Errors>({});

  const samlUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSamlUrl(value);
    const errs = { ...errors };
    delete errs.samlUrl;
    setErrors(errs);
  };
  const idpNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setIdpName(value);
    const errs = { ...errors };
    delete errs.idpName;
    setErrors(errs);
  };

  const submit = () => {
    const err: Errors = {};
    if (samlUrl.trim() === '') {
      err.samlUrl = 'Metadata URL must not be blank.';
    } else if (!/^https?:\/\/.*/i.test(samlUrl)) {
      err.samlUrl = 'Metadata URL must be a URL.';
    }
    if (idpName.trim() === '') {
      err.idpName = 'Identity Provider Name must not be blank.';
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }

    finishSamlSetup(userPool.identifier, samlUrl, idpName);
    if (account.mfaSecondaryEnforced) {
      updateAccount(account.id, {
        mfaSecondaryEnforced: false,
      });
    }
  };

  return (
    <Box gap="medium" direction="column" fullWidth>
      <Box fullWidth>
        <Typography variant="body2" color="textSecondary">Step 2</Typography>
        <Typography variant="h5">Assign SAML Configuration</Typography>
      </Box>
      <Box>
        <Typography variant="body1">
          Once you have added the previous values to your identity provider service, you should get back an <b>Identity Provider Metadata</b> URL.
          Please provide that URL along with the Identity Provider&rsquo;s name below.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">
          If you were only given a link to download a metadata file, and not a URL, copy the link to the file and provide that below.
        </Typography>
      </Box>
      <Box>
        <TextField
          testId="idp-name-field"
          fullWidth
          margin="normal"
          id="idpName"
          placeholder="Identity Provider Name"
          onChange={idpNameChange}
          error={Boolean(errors.idpName)}
          hintText={errors.idpName}
          disabled={false}
          name="idpName"
        />
        <TextField
          testId="saml-url-field"
          fullWidth
          margin="normal"
          id="samlUrl"
          placeholder="Metadata URL"
          onChange={samlUrlChange}
          error={Boolean(errors.samlUrl)}
          hintText={errors.samlUrl}
          disabled={false}
          name="samlUrl"
        />
      </Box>
      <Box justify="space-between" direction="row" fullWidth>
        <TextLink href="https://pagely.zendesk.com/hc/en-us/articles/4410414546331">Common Identity Providers and their setup.</TextLink>
        <Button data-testid="sso-previous-page" onClick={() => updateStatus('enabled')}>
          Previous Page
        </Button>
        <Button data-testid="sso-continue-setup" onClick={() => submit()}>
          Continue Setup
        </Button>
      </Box>
    </Box>
  );
};

export default Complete;
