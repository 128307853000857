import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Typography } from '@material-ui/core';
import Select from 'shared/styleguide/atoms/Select/Select';

import HeadlineText from 'shared/styleguide/typography/HeadlineText';
import Box from 'shared/styleguide/atoms/Box';
import { PRESSDNS_REGEX } from 'shared/modules/dns/constants';
import { CSRActions } from './CSRStepper';
import styles from './styles';

export class SelectDomain extends Component {
  static propTypes = {
    appsById: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    fields: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    commonName: this.props.fields.commonName,
    formErrors: {},
  };

  commonNameChange = (commonName) => {
    this.setState({ commonName, formErrors: {} });
  };

  validate = (commonName) => {
    const errors = {};

    if (!commonName) errors.commonName = 'Certificate subject is required';

    return errors;
  };

  goBack = () => {
    const { onSubmit } = this.props;
    onSubmit({}, 1);
  };

  doSubmit = (commonName) => {
    const { onSubmit } = this.props;
    const formErrors = this.validate(commonName);

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors });
    } else {
      onSubmit({ commonName }, 1);
    }
  };

  // this function will filter out any domains that are not allowed
  // to be set for Let's Encrypt main domain
  // wildcards, pressdns, and now FSA-enabled
  filterAllowedDomains = (domain) => {
    if (domain.fqdn.match(PRESSDNS_REGEX)) {
      return false;
    }
    // this is the tough one
    const fsaRecordForDomain = this.props.appsById?.[domain.appId]?.fsa;
    if (fsaRecordForDomain) {
      // sorry its just easier if its nested
      if (['active', 'pending'].includes(fsaRecordForDomain.status) && fsaRecordForDomain.sslProvider === 'ACM') {
        return false;
      }
    }
    return true;
  };

  render() {
    const { commonName } = this.state;
    const { classes } = this.props;

    return (
      <form
        onSubmit={(event) => {
          event.preventDefault();
          this.doSubmit(commonName);
        }}
        className={classes.csrModalContainer}
      >
        <DialogContent>
          <HeadlineText gutterBottom>Select Domain</HeadlineText>
          <Typography variant="body1">Generate a Certificate Signing Request that you will submit to your Certificate Authority (CA)</Typography>
          <Box padding={{ top: 'medium' }}>
            <Typography variant="caption" component="label" htmlFor="domainselect">Common Name (full domain name)</Typography>
            <Select
              id="domainselect"
              options={this.props.domains
                .filter(this.filterAllowedDomains)
                .map((d) => ({ label: d.fqdn, value: d.fqdn }))}
              styles={{
                container: (base) => ({ ...base, width: '100%' }),
              }}
              onChange={(o) => this.commonNameChange(o.value)}
              searchPromptText="Begin typing one of your domain names"
            />
          </Box>
        </DialogContent>
        <CSRActions step={0} onClick={this.goBack} disabled={!commonName} />
      </form>
    );
  }
}

export default withStyles(styles)(SelectDomain);
