import React, { Fragment } from 'react';
import PropTypes, { shape } from 'prop-types';
import { Link } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AppsIcon from '@material-ui/icons/Apps';
import DnsIcon from '@material-ui/icons/Dns';
import LockIcon from '@material-ui/icons/Lock';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';
import ReactTable from 'shared/styleguide/organisms/ReactTable';
import { AppRecordPropTypes } from 'shared/modules/apps/models';
import { remMapper } from 'shared/styleguide/theme/spacing';

export const AppsList = (props) => {
  const { apps } = props;

  const accountShortcuts = [
    {
      name: '',
      Icon: DashboardIcon,
    },
    {
      name: 'apps',
      Icon: AppsIcon,
    },
    {
      Icon: DnsIcon,
      name: 'dns',
    },
    {
      Icon: LockIcon,
      name: 'ssl',
    },
    {
      Icon: CloudCircleIcon,
      name: 'cdn',
    },
  ];

  const getNameCell = ({ row }) => {
    return (
      <TextLink
        variant="body2"
        to={`/account/${row.original.accountId}/apps/${row.original.id}/overview`}
      >
        {
          row.original.label && (
            <Fragment>
              {row.original.label}
              <br />
            </Fragment>
          )
        }
        {row.original.name}
      </TextLink>
    );
  };

  const getIDCell = ({ row }) => {
    return (
      <TextLink
        variant="body2"
        to={`/account/${row.original.accountId}/apps/${row.original.id}/overview`}
      >
        {row.original.id}
      </TextLink>
    );
  };

  const getStatusCell = ({ row }) => {
    return (
      <Box css={{
        gap: remMapper('xxsmall'),
      }}
      >
        {row.original.dateDeleted ? (
          <div>
            <GhostTag color="red">Deleted</GhostTag>
          </div>
        ) : (
          <div>
            {row.original.active ? (
              <GhostTag color="green">Active</GhostTag>
            ) : (
              <GhostTag color="blue">Deactivated</GhostTag>
            )}
          </div>
        )}
        {
          row.original.status === 'q' && (
            <GhostTag color="orange">Provisioning</GhostTag>
          )
        }
      </Box>
    );
  };

  const getShortcutsCell = ({ row }) => {
    return (
      <Fragment>
        {
          accountShortcuts.map((shortcut, index) => {
            const { Icon, disabled, text } = shortcut;
            return (
              <Link to={`account/${row.original.accountId}/${shortcut.name}`} key={`${shortcut.name}-${index}`}>
                <IconButton
                  style={{
                    marginRight: 8, width: 30, height: 30, padding: 1,
                  }}
                  disabled={disabled}
                  key={shortcut.name}
                  title={text}
                >
                  <Icon
                    key={`shortcut-${index}`}
                  />
                </IconButton>
              </Link>
            );
          })
        }
      </Fragment>
    );
  };

  const columns = [
    {
      Header: 'App name',
      accessor: 'name',
      Cell: getNameCell,
    },
    {
      Header: 'App ID',
      accessor: 'id',
      Cell: getIDCell,
    },
    {
      Header: 'Account ID',
      accessor: 'accountId',
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: getStatusCell,
    },
    {
      Header: 'Shortcuts',
      accessor: 'shortcuts',
      Cell: getShortcutsCell,
      props: {
        align: 'right',
      },
    },
  ];

  return (
    <>
      <ReactTable
        columns={columns}
        data={apps}
        paginate
        sort
      />
    </>
  );
};

AppsList.propTypes = {
  apps: PropTypes.arrayOf(shape(AppRecordPropTypes)),
};

export default AppsList;
