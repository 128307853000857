import { deepClone } from 'shared/utils';

import redirectsWizardTemplate from '../../json/redirects-wizard-template.json';
import UrlPropsClass from './UrlPropsClass';
import { compact } from '../../helpers';

// take url parts and convert wildcards to regex string with named vars
export const matchRegex = (inputArray) => {
  // add start of line regex operator
  let output = '^';
  let i = 1;
  inputArray.pathParts.forEach((part) => {
    const variable = `(?<captured_var${i}>.*)`;
    if (part === '*') {
      // wildcard, prepend with slash and add to $i counter for naming captured_var
      output += `/${part.replace('*', variable)}`;
      i++;
    } else {
      // non-wildcard, prepend with slash
      output += `/${part}`;
    }
  });
  // re-add trailing slash if defined
  if (inputArray.trailingSlash === true) {
    output += '/';
  }
  if (inputArray.trailingSlash === 'optional') {
    output += '/?';
  }
  // add end of line regex operator
  output += '$';
  return output;
};

// create a match hosts rule
export const matchScheme = (obj) => {
  if (obj.scheme !== '') {
    const output = {
      field: 'scheme',
      value: obj.scheme,
    };
    return output;
  }
};

// create a match hosts rule
export const matchHost = (obj) => {
  if (obj.host !== '') {
    const output = {
      field: 'host',
      value: obj.host,
    };
    return output;
  }
};

// create a default matchExcept rule
export const matchExceptDefault = (regex) => {
  const output = {
    field: 'normalizedPath',
    compare: 'regex',
    value: regex,
    operator: 'or',
  };
  return output;
};

// create a matchExcept rule from target to prevent redirect loops
export const matchExceptRegex = (inputArray) => {
  // add start of line regex operator
  let output = '^';
  inputArray.pathParts.forEach((part) => {
    const variable = '(.*)';
    if (part === '*') {
      // wildcard, prepend with slash
      output += `/${part.replace('*', variable)}`;
    } else {
      // non-wildcard, prepend with slash
      output += `/${part}`;
    }
  });
  // re-add trailing slash if defined
  if (inputArray.trailingSlash === true) {
    output += '/';
  }
  if (inputArray.trailingSlash === 'optional') {
    output += '/?';
  }
  // add end of line regex operator
  output += '$';
  return output;
};

// remap url parts as type/value arrays
export const actionsOutput = (source_array, target_array) => {
  let part_rep = target_array.path.replace(/[*]/g, '^*^');
  if (target_array.trailingSlash === true) {
    part_rep = `${part_rep}/`;
  }
  let sub_parts = part_rep.split('^');
  sub_parts = compact(sub_parts);
  let i = 1;
  let k = 0;
  const path = [];
  sub_parts.forEach((sub_part) => {
    if (sub_part === '*') {
      path[k] = { type: 'var', value: `captured_var${i}` };
      if (i < source_array.wildcardCount) {
        // go to next named var
        i++;
      }
    } else {
      path[k] = { type: 'string', value: sub_part };
    }
    k++;
  });
  return path;
};

export const createMatchExcept = (matchExceptDefaultRegex, targetMatchExceptRegex) => {
  // add matchExcept
  const matchExcept = [];
  matchExcept[0] = {};
  matchExcept[0].field = 'normalizedPath';
  // matchExcept compare if target contains named vars from match
  matchExcept[0].compare = 'regex';
  // matchExcept value
  matchExcept[0].value = targetMatchExceptRegex;
  // additional default matchExcept rule
  if (matchExceptDefaultRegex !== '') {
    matchExcept[0].operator = 'or';
    matchExcept[1] = matchExceptDefault(matchExceptDefaultRegex);
  }
  return matchExcept;
};

export const createConfig = (source, target, exitcode, matchExceptDefaultRegex) => {
  if (source === '' || target === '') {
    return false;
  }

  const sourceUrlProps = new UrlPropsClass(source);
  const targetUrlProps = new UrlPropsClass(target);

  if (!sourceUrlProps?.pathParts || !targetUrlProps?.pathParts) {
    return false;
  }

  // setup config
  const config = deepClone(redirectsWizardTemplate);
  config.redirects.exitCode = exitcode;
  config.redirects.rules[0].name = `Redirect from ${source} to ${target}`;

  if (sourceUrlProps.wildcardCount > 0 || sourceUrlProps.trailingSlash === 'optional') {
    config.redirects.rules[0].match[0].value = matchRegex(sourceUrlProps);
    config.redirects.rules[0].match[0].compare = 'regex';
    if (sourceUrlProps.pathParts[0].includes('*')) {
      // add matchExcept
      config.redirects.rules[0].matchExcept = createMatchExcept(matchExceptDefaultRegex, matchExceptRegex(targetUrlProps));
    } else {
      delete config.redirects.rules[0].matchExcept;
    }
  } else {
    config.redirects.rules[0].match[0].value = sourceUrlProps.path;
    delete config.redirects.rules[0].matchExcept;
  }

  // create match scheme rule from source if defined
  if (sourceUrlProps.scheme !== undefined) {
    const len = config.redirects.rules[0].match.length;
    config.redirects.rules[0].match[len] = {};
    config.redirects.rules[0].match[len] = matchScheme(sourceUrlProps);
  }

  // create match host rule from source if defined
  if (sourceUrlProps.host !== undefined) {
    const len = config.redirects.rules[0].match.length;
    config.redirects.rules[0].match[len] = {};
    config.redirects.rules[0].match[len] = matchHost(sourceUrlProps);
  }

  if (targetUrlProps.scheme !== undefined && targetUrlProps.scheme !== sourceUrlProps.scheme) {
    config.redirects.rules[0].actions[0].scheme = targetUrlProps.scheme;
  }

  if (targetUrlProps.host !== undefined && targetUrlProps.host !== sourceUrlProps.host) {
    config.redirects.rules[0].actions[0].host = targetUrlProps.host;
  }

  // actions path
  config.redirects.rules[0].actions[0].path = actionsOutput(sourceUrlProps, targetUrlProps);

  // output json
  return JSON.stringify(config, null, 2);
};
