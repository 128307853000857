import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { Typography, Paper } from '@material-ui/core';

import store2 from 'store2';
import { fetchDomainsForAccount } from 'shared/modules/app/redux/domains/actions';
import { fetchAccountTags } from 'shared/modules/account/redux/actions';
import { fetchCertsForAccount } from 'shared/modules/ssl/redux/certs/actions';
import { isGateway2Enabled } from 'shared/utils/functions';
import Layout from 'shared/styleguide/molecules/Layout';
import LoadingCard from 'shared/styleguide/molecules/LoadingCard';
import { fetchServerTagsForAccount } from '../server/redux/actions';

import CertAddModal from './routes/AddCertificate/CertAddModal';
import CertList from './routes/CertificatesList';
import CertificateLoader from './routes/Certificate';

export const SslLoader = ({
  match, tags, certs, accountId, __storybookMocks, ...props
}): React.ReactElement => {
  const [state, setState] = useState(__storybookMocks?.state || 'loading');

  useEffect(() => {
    // check for new domains if an app was added
    props.fetchDomainsForAccount(accountId);
    return () => {
      // clear pagination!
      const sslPagination = store2.namespace('pagination.ssl');
      sslPagination.clearAll();
    };
  }, []);

  // not fetching certs here so that the list can manage that

  useEffect(() => {
    // what we show depends on the tags
    if (tags.server.status === 'pristine' || tags.server.params.accountId !== accountId) {
      props.fetchServerTags(accountId);
    }
    if (tags.account.status === 'pristine' || tags.account.params.accountId !== accountId) {
      props.fetchAccountTags(accountId);
    }

    if (state === 'loading' && tags.account.status === 'success' && tags.server.status === 'success') {
      setState('success');
    }
  }, [tags.server.status, tags.account.status, tags.server.params, tags.account.params]);

  if (state === 'loading') {
    return (
      <Layout
        title="SSL Certificate Manager"
      >
        <LoadingCard />
      </Layout>
    );
  }

  const isAresEnabled = isGateway2Enabled(tags.server.data, tags.account.data);

  if (!isAresEnabled) {
    return (
      <Layout
        title="SSL Certificate Manager"
      >
        <Paper style={{ padding: 20 }}>
          <Typography variant="body1">
            SSL management is not available for your account right now. Please request access to SSL Management by opening a support ticket.
          </Typography>
        </Paper>
      </Layout>
    );
  }

  return (
    <Switch>
      <Route
        render={() => (
          // @ts-ignore
          <CertAddModal
            match={match}
            {...props}
          />
        )}
        path={`${match.path}/add`}
      />
      <Route
        component={CertificateLoader}
        path={`${match.path}/cert/:certID`}
      />
      <Route
        component={CertList}
      />
    </Switch>
  );
};

export default connect(
  (state: any) => ({
    tags: state.tags,
    certs: state.certs,
    accountId: state.account.id,
  }), {
    fetchDomainsForAccount,
    fetchServerTags: fetchServerTagsForAccount,
    fetchAccountTags,
    fetchCerts: fetchCertsForAccount,
  },
)(SslLoader);
