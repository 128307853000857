import React, { Fragment, useContext } from 'react';
import {
  Typography, Divider, Checkbox,
} from '@material-ui/core';

import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Box from 'shared/styleguide/atoms/Box';
import DestructiveAction from 'shared/styleguide/molecules/DestructiveAction';

import UserContext from 'shared/modules/permissions/context/UserContext';

interface Props {
  state: Record<string, any>;
  mergeState: any;
  onDisableFsa: any;
}

const TeardownFsa = ({
  state, mergeState, onDisableFsa,
}: Props): React.ReactElement => {
  const userIsAdmin = useContext(UserContext).adminAccount;

  const handleProceed = (() => {
    onDisableFsa(false, { abortRunning: true });
  });

  return (
    <>
      {
        userIsAdmin && (
          <DestructiveAction
            fullWidth
            onConfirm={handleProceed}
            startIcon={<SettingsBackupRestoreIcon />}
            explainerText="You are tearing down Mercury Dynamic Site Acceleration for this app."
            secondaryText={(
              <Fragment>
                <Box direction="column" gap="small">
                  <Typography variant="body2">
                    This will abort any running steps and then start the disable-Mercury process from the beginning.
                  </Typography>
                  <Typography variant="body2">
                    If you do not have an SSL certificate for any app domains installed or opt not to create a Lets Encrypt SSL certificate when disabling, <strong>those domains will not be accessible via https after conversion</strong>, but you can add an SSL certificate after conversion.
                  </Typography>
                  <Typography variant="body2">
                    If you have any http to https redirects set up and no SSL certificate on any app domains, you should remove those now before proceeding to avoid downtime.
                  </Typography>
                  <div>
                    <Typography component="span" variant="caption">Recommended: </Typography>
                    <Box direction="row" justify="flex-start" align="center">
                      <Checkbox
                        checked={state.createLECertificate}
                        onChange={(e) => { mergeState({ createLECertificate: e.target.checked }); }}
                      />
                      <Typography component="div" variant="body2">Create a Lets Encrypt SSL certificate for all app domains.</Typography>
                    </Box>
                  </div>
                </Box>
                <Box padding={{ top: 'small', bottom: 'small' }}>
                  <Divider />
                </Box>
                <div>
                  <Typography component="span" variant="caption">Optional: </Typography>
                  <Box direction="row" justify="flex-start" align="center">
                    <Checkbox
                      checked={state.force}
                      onChange={(e) => { mergeState({ force: e.target.checked }); }}
                    />
                    <Typography component="div" variant="body2">Skip checks and force disable. <strong>WARNING: This may cause site downtime!</strong></Typography>
                  </Box>
                </div>
              </Fragment>
            )}
            loading={state.disable === 'loading'}
          >
            Teardown Mercury
          </DestructiveAction>
        )
    }
    </>
  );
};

export default TeardownFsa;
