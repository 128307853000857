import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Pagination from '@material-ui/lab/Pagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import Box from 'shared/styleguide/atoms/Box';
import { getComparator, stableSort } from 'shared/utils/sorting';

import Empty from 'shared/styleguide/atoms/Empty';
import ListHead from '../CollapsibleTable/ListHead';

const FIRST_PAGE = 1;
const ROWS_PER_PAGE = 10;

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const SortableTableContainer = ({
  onSetOrder = () => { },
  onSetOrderBy = () => { },
  rows,
  ...props
}) => {
  const [order, setOrder] = useState(props.order || 'desc');
  const [orderBy, setOrderBy] = useState(props.orderBy || 'createdDate');

  const sortedItems = stableSort(rows, getComparator(order, orderBy));

  const memoizedSortedItems = useCallback(sortedItems, [sortedItems, rows, order, orderBy]);

  const handleSetOrderBy = (value) => {
    setOrderBy(value);
    onSetOrderBy(value);
  };

  const handleSetOrder = (value) => {
    setOrder(value);
    onSetOrder(value);
  };

  return (
    <SortableTable
      {...props}
      rows={memoizedSortedItems}
      onSetOrder={handleSetOrder}
      onSetOrderBy={handleSetOrderBy}
    />
  );
};

SortableTableContainer.propTypes = {
  onSetOrder: PropTypes.func,
  onSetOrderBy: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rows: PropTypes.array.isRequired,
};

export const SortableTable = ({
  pageLength = ROWS_PER_PAGE,
  headCells,
  rows,
  paginate,
  renderRow,
  onSetOrder,
  onSetOrderBy,
  ...props
}) => {
  const [page, setPage] = useState(FIRST_PAGE);
  const [order, setOrder] = useState(props.order || 'desc');
  const [orderBy, setOrderBy] = useState(props.orderBy || 'createdDate');

  const handleSetOrderBy = (value) => {
    setOrderBy(value);
    onSetOrderBy(value);
  };

  const handleSetOrder = (value) => {
    setOrder(value);
    onSetOrder(value);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    handleSetOrder(isAsc ? 'desc' : 'asc');
    handleSetOrderBy(property);
  };

  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <Table aria-label="sortable table">
          <ListHead
            classes={classes}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {
              rows
                .slice((page - 1) * pageLength, paginate ? (page - 1) * pageLength + pageLength : undefined)
                .map(renderRow)
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        (rows.length > pageLength && paginate) && (
          <Box margin={{ top: 'small' }} padding={{ bottom: 'small' }} justify="center" direction="row">
            <Pagination
              shape="rounded"
              count={Math.ceil(rows.length / pageLength)}
              page={page}
              onChange={handleChangePage}
            />
          </Box>
        )
      }
      {rows.length === 0 && (
        <Box padding="medium">
          <Empty>No rows to display</Empty>
        </Box>
      )}
    </>
  );
};

SortableTable.propTypes = {
  headCells: PropTypes.array,
  onSetOrder: PropTypes.func.isRequired,
  onSetOrderBy: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  pageLength: PropTypes.number,
  paginate: PropTypes.bool,
  renderRow: PropTypes.func,
  rows: PropTypes.array,
};

export default SortableTableContainer;
