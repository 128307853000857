import React from 'react';
import { number } from 'prop-types';

const TextMask = ({ length = 29 }) => {
  const dots = Array(length).fill('•').join('');

  return (
    <span style={{ fontSize: '1.6rem' }}>
      {dots}
    </span>
  );
};

TextMask.propTypes = {
  length: number,
};

export default TextMask;
