import React, {
  Fragment, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Add from '@material-ui/icons/Add';
import {
  Paper, Table, TableBody, TableContainer, Typography,
} from '@material-ui/core';

import { useCallApi } from 'shared/hooks/useCallApi';
import { PRISTINE, LOADING, SUCCESS } from 'shared/utils/redux/constants';
import { bulkModifyDomains, bulkModifyDomainsReset } from 'shared/modules/app/redux/domains/actions';
import { getAllValidations } from 'shared/modules/app/redux/dns/actions';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import { useMergeState } from 'shared/hooks/useMergeState';
import { fetchApp } from 'shared/modules/app/redux/actions';

import { ErrorText } from 'shared/styleguide/typography';
import { hasChanges, getChangesMessages, getFailureMessages } from './utils';
import DomainRows from './DomainRows';
import SaveButton from './SaveButton';

export const ModifyDomains = ({
  appId,
  onClose,
  appDomains,
  isFsa,
  fsaStatus,
  getAllValidations,
  bulkModifyDomains,
  bulkModifyDomainsReset,
  fetchApp,
  __storybookMocks,
}) => {
  let initialState = {
    domainsToModify: [],
    showErrors: [],
  };

  if (__storybookMocks?.state) {
    initialState = { ...initialState, ...__storybookMocks.state };
  }

  const { state, mergeState } = useMergeState(initialState);
  let modifyDomains = useCallApi(appDomains);

  if (__storybookMocks?.modifyDomains) {
    modifyDomains = { ...modifyDomains, ...__storybookMocks.modifyDomains };
  }

  const { domainsToModify, showErrors } = state;

  const filterDomainsToChange = () => {
    // once the store has been updated with newly created domains
    // we can remove successful creates and deletes from local state

    const domainIdentifiers = { ids: [], fqdns: [] };
    appDomains.data.forEach((d) => {
      domainIdentifiers.ids.push(d.id);
      domainIdentifiers.fqdns.push(d.fqdn);
    });

    const remainingDomainsToModify = domainsToModify.filter((d) => {
      // create
      if (!d.delete && !domainIdentifiers.fqdns.includes(d.fqdn)) {
        return true;
      }

      // delete
      if (d.delete && domainIdentifiers.ids.includes(d.id)) {
        return true;
      }

      return false;
    });

    mergeState({
      domainsToModify: remainingDomainsToModify,
    });
  };

  const hasFailures = modifyDomains.statusCode === 207;

  const domainsWithDeleteFlag = appDomains.data.map((domain) => {
    const temp = { ...domain };
    if (domainsToModify.find((d) => d.delete && d.id === domain.id)) {
      temp.delete = true;
    }
    return temp;
  });

  const domainsToCreate = domainsToModify.filter((d) => !d.delete);

  const displayRows = [...domainsToCreate, ...domainsWithDeleteFlag];

  useEffect(() => {
    if (modifyDomains.status === SUCCESS) {
      filterDomainsToChange();
    }
  }, [modifyDomains.status]);

  useEffect(() => {
    if (
      modifyDomains.status === SUCCESS
      && modifyDomains.displayStatus === PRISTINE
      && !hasFailures
    ) {
      // success, and timeout is over, and there's no failures
      // so we can fetch app aliases, close and go to default domains view

      fetchApp(appId);
      getAllValidations(appId);

      onClose();
    }
  }, [modifyDomains, onClose]);

  useEffect(() => {
    return function cleanup() {
      bulkModifyDomainsReset();
    };
  }, []);

  const handleEditDomain = (value, tempId) => {
    const itemIndex = domainsToModify.findIndex((d) => !d.delete && d.tempId === tempId);
    const domains = [...domainsToModify];

    domains[itemIndex].fqdn = value;

    mergeState({
      domainsToModify: domains,
      showErrors: false,
    });
  };

  const handleDeleteDomain = (id, fqdn, tempId) => {
    const modifyIndex = domainsToModify.findIndex((d) => d.tempId && d.tempId === tempId);

    if (modifyIndex > -1) {
      // it's a pending new domain
      const domains = [...domainsToModify];
      domains.splice(modifyIndex, 1);
      mergeState({
        domainsToModify: domains,
        showErrors: false,
      });

      return;
    }

    // it's an existing domain
    mergeState({
      domainsToModify: [
        ...domainsToModify,
        {
          id,
          fqdn,
          delete: true,
        },
      ],
      showErrors: false,
    });
  };

  const handleRestoreDomain = (domainId) => {
    const deleteIndex = domainsToModify.findIndex((d) => d.delete && d.id === domainId);
    if (deleteIndex === -1) {
      return;
    }

    const domains = [...domainsToModify];
    domains.splice(deleteIndex, 1);

    mergeState({
      domainsToModify: domains,
      showErrors: false,
    });
  };

  const handleSetPressDns = (value, tempId) => {
    const createIndex = domainsToModify.findIndex((d) => d.tempId === tempId);
    const domains = [...domainsToModify];

    domains[createIndex].isPressDns = value;

    mergeState({
      domainsToModify: domains,
      showErrors: false,
    });
  };

  const handleAddDomain = () => {
    // just a filter so we don't allow multiple empty "create" domain rows
    const domains = domainsToModify.filter((d) => d.delete || (!d.delete && d.fqdn));

    const newDomain = {
      tempId: uuidv4(),
    };

    mergeState({
      domainsToModify: [
        newDomain,
        ...domains,
      ],
      showErrors: false,
    });
  };

  const cancelChanges = () => {
    mergeState({
      domainsToModify: [],
      showErrors: false,
    });
    onClose(false);
  };

  const saveChanges = () => {
    const domainsToCreate = domainsToModify.filter((d) => !d.delete && d.fqdn)
      .map((d) => {
        return { fqdn: d.fqdn, isPressDns: d.isPressDns };
      });
    const domainIdsToDelete = domainsToModify.filter((d) => d.delete).map((d) => d.id);

    modifyDomains.call(
      bulkModifyDomains,
      [
        appId,
        {
          domainsToCreate,
          domainIdsToDelete,
        },
      ],
      null,
      'bulk-modify',
    );

    mergeState({
      showErrors: true,
    });
  };

  return (
    <Fragment>
      <Box direction="row" justify="space-between" gap="medium" margin={{ top: 'xsmall', bottom: 'xsmall' }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleAddDomain}
          disabled={[LOADING, SUCCESS].includes(modifyDomains.displayStatus)}
          startIcon={<Add color="action" />}
        >
          Add Domain
        </Button>
        <Box direction="row" align="center" gap="xsmall">
          {
            hasChanges(domainsToModify)
            && (
              <Box margin={{ right: 'xsmall' }}>
                <Typography variant="body1" color="textPrimary">
                  Unsaved changes: {getChangesMessages(domainsToModify)}
                </Typography>
              </Box>
            )
          }
          <Button
            color="default"
            variant="outlined"
            disabled={modifyDomains.displayStatus === LOADING}
            onClick={() => cancelChanges()}
          >
            {
              hasChanges(domainsToModify) ? 'Cancel Changes' : 'Close'
            }
          </Button>
          <SaveButton
            saveChanges={saveChanges}
            domainsToModify={domainsToModify}
            modifyDomains={modifyDomains}
            isFsa={isFsa}
            fsaStatus={fsaStatus}
          />
        </Box>
      </Box>
      {
        showErrors && (
          <Box margin={{ bottom: 'xsmall' }}>
            <ErrorText> {modifyDomains.errorMessage || getFailureMessages(appDomains)} </ErrorText>
          </Box>
        )
      }
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            <DomainRows
              displayRows={displayRows}
              onEditDomain={handleEditDomain}
              onSetPressDns={handleSetPressDns}
              onRestoreDomain={handleRestoreDomain}
              onDeleteDomain={handleDeleteDomain}
              domainsToModify={domainsToModify}
              modifyDomains={modifyDomains}
              isFsa={isFsa}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

ModifyDomains.propTypes = {
  appDomains: PropTypes.object,
  appId: PropTypes.number,
  bulkModifyDomains: PropTypes.func,
  bulkModifyDomainsReset: PropTypes.func,
  fetchApp: PropTypes.func,
  fsaStatus: PropTypes.string,
  getAllValidations: PropTypes.func,
  isFsa: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapDispatchToProps = {
  bulkModifyDomains,
  bulkModifyDomainsReset,
  fetchApp,
  getAllValidations,
};

export default connect(null, mapDispatchToProps)(ModifyDomains);
