export const STATUS_OPTIONS = {
  unknown: {
    color: 'smoke',
    text: 'unknown',
  },
  unavailable: {
    color: 'smoke',
    text: 'Unavailable',
  },
  disabled: {
    color: 'red',
    text: 'Disabled',
  },
  enabled: {
    color: 'lightBlue',
    text: 'Enabled',
  },
  finish: {
    color: 'lightBlue',
    text: 'Enabled',
  },
  test: {
    color: 'lightBlue',
    text: 'Enabled',
  },
  active: {
    color: 'green',
    text: 'Active',
  },
} as const;

export type StatusKeys = keyof typeof STATUS_OPTIONS; // "unavailable" | "disabled" | "enabled" | "active"
