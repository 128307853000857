import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CircleCI = (props) => {
  return (
    <SvgIcon
      viewBox="-4 -4 76 76"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="matrix(2.791226 0 0 2.791226 71.368826 91.588681)">
        <circle r="2.73" cy="-21.349" cx="-14.275" />
        <path d="M-14.275-32.813c-5.342 0-9.83 3.654-11.103 8.598a.56.56 0 0 0-.019.136c0 .3.244.546.546.546h4.623a.54.54 0 0 0 .495-.32s.007-.013.01-.02c.953-2.056 3.034-3.483 5.45-3.483 3.317 0 6.006 2.688 6.006 6.005s-2.688 6.005-6.005 6.005c-2.415 0-4.496-1.427-5.45-3.483-.003-.007-.01-.02-.01-.02-.088-.194-.282-.32-.495-.32h-4.623c-.302 0-.546.244-.546.546a.54.54 0 0 0 .019.136c1.273 4.944 5.762 8.598 11.103 8.598C-7.942-9.89-2.8-15.022-2.8-21.353s-5.133-11.464-11.464-11.464z" />
      </g>
    </SvgIcon>
  );
};

CircleCI.displayName = 'CircleCIIcon';
export default CircleCI;
