import React, { useState } from 'react';
import { array, number } from 'prop-types';

import Pagination from '@material-ui/lab/Pagination';
import Box from 'shared/styleguide/atoms/Box';
import AlertItem from './AlertItem';

const PAGE_LENGTH = 10;

const AlertsList = ({ accountId, alerts }) => {
  const [page, setPage] = useState(1);

  const index = (page * PAGE_LENGTH) - PAGE_LENGTH;

  return (
    <Box margin={{ top: 'small' }}>
      {
        alerts
          .slice(index, index + PAGE_LENGTH)
          .map((alertItem) => {
            return (
              <Box key={`list-${alertItem.id}`}>
                <AlertItem accountId={accountId} alertItem={alertItem} />
              </Box>
            );
          })
      }
      {
        alerts.length > PAGE_LENGTH && (
          <Box margin={{ top: 'small' }} justify="center" direction="row">
            <Pagination
              shape="rounded"
              count={Math.ceil(alerts.length / PAGE_LENGTH)}
              page={page}
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </Box>
        )
      }
    </Box>
  );
};

AlertsList.propTypes = {
  accountId: number,
  alerts: array,
};

export default AlertsList;
