import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTheme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useSelector } from 'react-redux';
import Box from 'shared/styleguide/atoms/Box';
import Linker from 'shared/styleguide/atoms/Links/Linker';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

// see: https://metastatuspage.com/api#status
type Indicator = 'none' | 'minor' | 'major' | 'critical' | 'loading' | 'unknown';
const IndicatorButton: React.FC<{ indicator: Indicator }> = ({ indicator }) => {
  const { palette } = useTheme();

  const shadowParamsOne = '0 13px 27px -5px';
  const shadowParamsTwo = '0 8px 16px -8px';
  let buttonStyle: { [K in 'backgroundColor' | 'boxShadow']: CSSProperties['color'] };
  switch (indicator) {
    case 'none':
      buttonStyle = {
        backgroundColor: palette.colors.green,
        boxShadow: `
          ${shadowParamsOne} rgba(0,206,142,0.25),
          ${shadowParamsTwo} rgba(0,206,142,0.3)
        `,
      };
      break;
    case 'minor':
      buttonStyle = {
        backgroundColor: palette.colors.yellow,
        boxShadow: `
          ${shadowParamsOne} rgba(255,239,0,0.25),
          ${shadowParamsTwo} rgba(255,239,0,0.3)
        `,
      };
      break;
    case 'major':
      buttonStyle = {
        backgroundColor: palette.colors.orange,
        boxShadow: `
          ${shadowParamsOne} rgba(254,112,0,0.25),
          ${shadowParamsTwo} rgba(254,112,0,0.3)
        `,
      };
      break;
    case 'critical':
      buttonStyle = {
        backgroundColor: palette.colors.red,
        boxShadow: `
          ${shadowParamsOne} rgba(255,1,0,0.25),
          ${shadowParamsTwo} rgba(255,1,0,0.3)
        `,
      };
      break;
    case 'loading':
    default:
      buttonStyle = {
        backgroundColor: palette.colors.blue,
        boxShadow: `
          ${shadowParamsOne} rgba(5,167,255,0.25),
          ${shadowParamsTwo} rgba(5,167,255,0.3)
        `,
      };
  }
  const color = ['none', 'minor'].includes(indicator) ? palette.common.black : palette.common.white;
  return (
    <Button
      variant="contained"
      css={({
        'backgroundColor': buttonStyle.backgroundColor,
        color,
        'height': '40px',
        'width': '256px',
        'border': 'none',
        'boxShadow': buttonStyle.boxShadow,
        '&:hover': {
          backgroundColor: buttonStyle.backgroundColor,
          color,

        },
      })}
    >
      {indicator === 'loading' ? 'Loading' : 'System Status'}
    </Button>
  );
};

const SystemStatus = ({ __storybookMocks }): React.ReactElement => {
  const [status, setStatus] = useState<{ status: { indicator: Indicator } }>(__storybookMocks || ({ status: { indicator: 'loading' } }));
  const accountId = useSelector((state: any) => state.account?.id);
  const to = accountId ? `/account/${accountId}/support/status` : null;
  const href = !accountId ? 'https://pagely.statuspage.io/' : null;

  const fetchStatus = async () => {
    try {
      const response = await axios.get('https://pagely.statuspage.io/index.json');
      setStatus({ status: response.data?.status });
    } catch {
      setStatus({ status: { indicator: 'unknown' } });
    }
  };

  useEffect(() => {
    if (__storybookMocks) return;
    fetchStatus();
  }, []);

  return (
    <Box
      padding={{
        top: 'none', bottom: 'large', left: 'small', right: 'small',
      }}
      row
      align="center"
      justify="center"
      as={Linker}
      to={to}
      href={href}
      css={{
        '&:hover': {
          textDecoration: 'none',
        },
      }}
    >
      <IndicatorButton indicator={status.status.indicator} />
    </Box>
  );
};

export default React.memo(SystemStatus);
