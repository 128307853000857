import tracker from 'shared/3rdparty/pageTracking';
import makeApiAction from 'shared/utils/redux/apiActions';

import {
  addSubscriptionToCollaborator,
  fetchAccountCollaborators,
  addCollaborator,
  removeCollaborator,
  resetCollaborator,
} from 'shared/modules/team/redux/actions';

import {
  twoFactorSetupRequest,
  twoFactorVerificationRequest,
  twoFactorSetupRequestForAdmin,
  twoFactorVerificationRequestForAdmin,
  twoFactorVerificationReset,
  twoFactorDisableRequest,
  twoFactorDisableReset,
} from 'shared/modules/authentication/redux/mfa/actions';

import {
  getSSHPublicKeys,
  addSSHPublicKey,
  deleteSSHPublicKey,
  resetSSHPublicKey,
} from 'shared/modules/security/redux/actions';

import {
  emailAddressChangeRequest,
  emailAddressChangeReset,
  emailAddressVerifyRequest,
  resetAccountValidation,
  checkEmailChangeToken,
} from './authorization/actions';

import {
  fetchUserPool,
} from './sso/actions';

import * as ACCOUNT from './constants';

export const pollAccount = (chargebeeId) => makeApiAction(
  ({ api }) => api().get(`/accounts/signup-check/${chargebeeId}`),
  'SIGNUP_CHECK_R',
  'SIGNUP_CHECK_S',
  'SIGNUP_CHECK_F',
  null,
  true,
);

export const fetchAccount = (accountID) => makeApiAction(
  ({ api }) => api().get(`/accounts/${accountID}`),
  ACCOUNT.ACCOUNT_REQUEST,
  ACCOUNT.ACCOUNT_SUCCESS,
  ACCOUNT.ACCOUNT_FAILURE,
  { accountID },
  true,
);

export const fetchAccountOnly = (accountId) => async (dispatch, getState, { history, api }) => {
  return api().get(`/accounts/${accountId}`);
};

export const updateAccount = (accountID, update, reduxFormId) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_account_modified',
      ...update,
    });
    return api().patch(`/accounts/${accountID}`, update);
  },
  ACCOUNT.ACCOUNT_UPDATE_REQUEST,
  ACCOUNT.ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT.ACCOUNT_UPDATE_FAILURE,
  { accountID, ...update, reduxFormId },
  true,
);

export const deleteAccount = (accountID, accountData, reduxFormId) => makeApiAction(
  ({ api }) => api().post(`/accounts/${accountID}/cancel`, accountData),
  ACCOUNT.ACCOUNT_UPDATE_REQUEST,
  ACCOUNT.ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT.ACCOUNT_UPDATE_FAILURE,
  { accountID, ...accountData, reduxFormId },
  true,
);

export const deleteAccountAsAdmin = (accountID, accountData, reduxFormId) => makeApiAction(
  ({ api }) => api().post(`/accounts/${accountID}/cancel-by-admin`, accountData),
  ACCOUNT.ACCOUNT_UPDATE_REQUEST,
  ACCOUNT.ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT.ACCOUNT_UPDATE_FAILURE,
  { accountID, ...accountData, reduxFormId },
  true,
);

export const disableAccountAsAdmin = (accountID, data) => makeApiAction(
  ({ api }) => api().post(`/accounts/${accountID}/disable`, data),
  'ACCOUNT_REQUEST_DISABLE_ACCOUNT',
  'ACCOUNT_SUCCESS_DISABLE_ACCOUNT',
  'ACCOUNT_FAILURE_DISABLE_ACCOUNT',
  {},
  true,
);

export const enableAccountAsAdmin = (accountID, data) => makeApiAction(
  ({ api }) => api().post(`/accounts/${accountID}/enable`, data),
  'ACCOUNT_REQUEST_ENABLE_ACCOUNT',
  'ACCOUNT_SUCCESS_ENABLE_ACCOUNT',
  'ACCOUNT_FAILURE_ENABLE_ACCOUNT',
  {},
  true,
);

export const createAccount = (options) => makeApiAction(
  ({ api }) => {
    const {
      firstName,
      lastName,
      email,
      password,
      companyName,
      billingPlanId,
      region,
      addons,
      tos,
      addressInfo,
      captchaToken,
      cycle,
      billingOptions,
      kountSessionId,
    } = options;

    return api({ timeout: 40000 }).post('/go-billing/accounts', {
      firstName,
      lastName,
      email,
      companyName,
      password,
      planId: `${billingPlanId}`,
      regionId: region,
      addons,
      billingSource: 'chargebee',
      billingFrequency: cycle,
      acceptTos: tos,
      captchaToken,
      kountSessionId,
      ...addressInfo,
      ...billingOptions,
    });
  },
  ACCOUNT.ACCOUNT_CREATE_REQUEST,
  ACCOUNT.ACCOUNT_CREATE_SUCCESS,
  ACCOUNT.ACCOUNT_CREATE_FAILURE,
  {
  },
  true,
);

export const fetchAccountTags = (accountId) => makeApiAction(
  ({ api }) => api().get(`/metadata/accounts/${accountId}/tags`),
  ACCOUNT.ACCOUNT_TAGS_REQUEST,
  ACCOUNT.ACCOUNT_TAGS_SUCCESS,
  ACCOUNT.ACCOUNT_TAGS_FAILURE,
  { accountId },
  true,
);

export const getSecretAnswer = (accountId) => makeApiAction(
  ({ api }) => api().get(`/accounts/${accountId}/secret-answer`, {
  }),
  ACCOUNT.ACCOUNT_SECRET_GET_REQUEST,
  ACCOUNT.ACCOUNT_SECRET_GET_SUCCESS,
  ACCOUNT.ACCOUNT_SECRET_GET_FAILURE,
  { accountId },
  true,
);

export {
  emailAddressChangeRequest,
  emailAddressChangeReset,
  emailAddressVerifyRequest,
  checkEmailChangeToken,
  resetAccountValidation,
  addSubscriptionToCollaborator,
  fetchAccountCollaborators,
  addCollaborator,
  removeCollaborator,
  resetCollaborator,
  twoFactorSetupRequest,
  twoFactorVerificationRequest,
  twoFactorSetupRequestForAdmin,
  twoFactorVerificationRequestForAdmin,
  twoFactorVerificationReset,
  twoFactorDisableRequest,
  twoFactorDisableReset,
  getSSHPublicKeys,
  addSSHPublicKey,
  deleteSSHPublicKey,
  resetSSHPublicKey,
  fetchUserPool,
};
