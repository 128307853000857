import React, { Component, Fragment } from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import CircularStatus from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip, withStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

import Box from 'shared/styleguide/atoms/Box';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Select from 'shared/styleguide/atoms/Select/Select';
import { Checkbox } from 'shared/styleguide/atoms/Switches';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import {
  deactivateDomainCert,
  resetCertDomainLink, revertDomainCert,
} from 'shared/modules/ssl/redux/certDomainLink/actions';
import {
  register, getStatus, reset, setDomain,
  checkSite, checkCdn,
} from 'shared/modules/ssl/redux/letsencrypt/actions';
import { fetchCertsForAccount } from 'shared/modules/ssl/redux/certs/actions';
import { requestCdnForApp } from 'shared/modules/cdn/redux/actions';
import { getAllValidationMessages } from 'shared/utils/validation';
import {
  ACTIVE, PENDING, PENDING_INACTIVE,
} from 'shared/modules/app/routes/FSA/constants';
import Toast from 'shared/styleguide/molecules/Toast';

import { getAppsById } from 'shared/modules/app/redux/selectors';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import SupportLink from 'shared/modules/support/Link';
import Stepper from './LEStepper';
import { jssStyles as styles } from './styles';

const DomainsList = styled.div`
  max-height: 125px;
  overflow-y: scroll;
  overflow-y: overlay;
  width: 70%;
  margin-bottom: 1px;
`;

const DomainsListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 600px;
`;

const timers = {
  checkCdn: 0,
  checkSite: 0,
  checkStatus: 0,
  overall: 0,
};

export class LetsEncrypt extends Component {
  static propTypes = {
    appsById: PropTypes.object.isRequired,
    cdn: PropTypes.object,
    certDomainLink: PropTypes.object,
    checkCdn: PropTypes.func.isRequired,
    checkSite: PropTypes.func.isRequired,
    classes: PropTypes.object,
    closeModal: PropTypes.func,
    deactivate: PropTypes.func.isRequired,
    domains: PropTypes.array.isRequired,
    getJobStatus: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    letsencryptStatus: PropTypes.shape({
      active: PropTypes.bool,
      aliasId: PropTypes.number,
      appId: PropTypes.number,
      cdnChecked: PropTypes.bool,
      cdnChecking: PropTypes.bool,
      certId: PropTypes.number,
      domain: PropTypes.string,
      id: PropTypes.number,
      isFetching: PropTypes.bool,
      message: PropTypes.string,
      siteChecked: PropTypes.bool,
      siteChecking: PropTypes.bool,
      status: PropTypes.string,
      step: PropTypes.string,
      submitted: PropTypes.bool,
      validation: PropTypes.object,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        accountID: PropTypes.string,
      }),
    }).isRequired,
    quickTimeout: PropTypes.bool,
    registerDomain: PropTypes.func.isRequired,
    requestCdnForApp: PropTypes.func.isRequired,
    resetCertDomainLink: PropTypes.func.isRequired,
    resetCerts: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    revert: PropTypes.shape({
      done: PropTypes.bool,
      loading: PropTypes.bool,
      success: PropTypes.bool,
    }),
    revertDomainCert: PropTypes.func.isRequired,
    setDomainToRegister: PropTypes.func.isRequired,

    setTitle: PropTypes.func.isRequired,
  };

  state = {
    domains: [],
    selectedAlternateDomains: [],
    siteChecksTriggered: false,
    statusCheckTriggered: false,
    certFetched: false,
    timedOut: false,
    domainFilter: '',
  };

  componentDidMount() {
    this.props.setTitle('Let\'s Encrypt');
    this.props.resetCertDomainLink();
    if (this.state.domains.length === 0 && this.props.domains.length > 0) {
      this.setState({
        domains: this.props.domains.map((d) => d.fqdn),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      certDomainLink, resetCerts, setTitle, letsencryptStatus: {
        isFetching, status, step, siteChecked, cdnChecked, id, domain,
      },
    } = this.props;
    if (certDomainLink.deactivated) {
      this.props.closeModal();
      return;
    }

    if (!isFetching) {
      const accountID = parseInt(this.props.match.params.accountID, 10);

      if (prevProps.letsencryptStatus.domain !== domain) {
        this.setState({
          selectedAlternateDomains: this.getMatchingAppDomains()
            .map((item) => item.fqdn),
        });
      }

      if (!this.state.certFetched && status === 'COMPLETE' && step === 'FETCH_CERTIFICATE' && siteChecked && cdnChecked) {
        setTitle('Test Your Site');
        this.setState({
          certFetched: true,
        });
        resetCerts(accountID);
      }

      if (status === 'COMPLETE' && step === 'FETCH_CERTIFICATE' && !this.state.siteChecksTriggered) {
        this.setState({
          siteChecksTriggered: true,
        });
        this.props.requestCdnForApp(this.props.letsencryptStatus.appId);
        this.doStartTimeout();
        this.doCheckSite();
        this.doCheckCdn();
      }

      if (id && !this.state.statusCheckTriggered) {
        this.setState({
          statusCheckTriggered: true,
        });
        this.doGetStatus();
      }
    }

    if (this.state.domains.length === 0 && this.props.domains.length > 0) {
      this.setState({
        domains: this.props.domains.map((d) => d.fqdn),
      });
    }
  }

  componentWillUnmount() {
    this.props.resetStatus();
    this.props.resetCertDomainLink();
    if (timers.checkCdn) {
      clearTimeout(timers.checkCdn);
    }
    if (timers.checkSite) {
      clearTimeout(timers.checkSite);
    }
    if (timers.checkStatus) {
      clearTimeout(timers.checkStatus);
    }
    if (timers.overall) {
      clearTimeout(timers.overall);
    }
  }

  doGetStatus = () => {
    const { letsencryptStatus: { status, step } } = this.props;
    if (status === 'COMPLETE' && step === 'FETCH_CERTIFICATE') return;
    if (status === 'FAILED') return;

    if (!this.props.letsencryptStatus.isFetching && this.props.letsencryptStatus.id) {
      this.props.getJobStatus(this.props.letsencryptStatus.id);
      timers.checkStatus = setTimeout(this.doGetStatus, 5000);
    }
  };

  doCheckSite = () => {
    if (this.props.letsencryptStatus.siteChecked) return;

    if (!this.props.letsencryptStatus.siteChecking) {
      this.props.checkSite(`https://${this.props.letsencryptStatus.domain}/.well-known/pagely/gateway2`);
      timers.checkSite = setTimeout(this.doCheckSite, 5000);
    }
  };

  doCheckCdn = () => {
    if (this.props.letsencryptStatus.cdnChecked) return;
    if (!this.props.cdn.loaded) {
      timers.checkCdn = setTimeout(this.doCheckCdn, 5000);
      return;
    }

    if (!this.props.letsencryptStatus.cdnChecking) {
      const url = this.props.cdn.data.extSSLUrl || `${this.props.letsencryptStatus.appId}-presscdn-pagely.netdna-ssl.com`;
      this.props.checkCdn(`https://${url}/.well-known/pagely/gateway2`);
      timers.checkCdn = setTimeout(this.doCheckCdn, 5000);
    }
  };

  doStartTimeout = () => {
    // only start this once!
    if (!timers.overall) {
      timers.overall = setTimeout(this.doTimeout, this.props.quickTimeout ? 5000 : 60000 * 5);
    }
  };

  doTimeout = () => {
    if (timers.checkCdn) {
      clearTimeout(timers.checkCdn);
    }
    if (timers.checkSite) {
      clearTimeout(timers.checkSite);
    }
    if (timers.checkStatus) {
      clearTimeout(timers.checkStatus);
    }
    if (timers.overall) {
      clearTimeout(timers.overall);
    }

    this.setState({
      timedOut: true,
    });
  };

  handleSubmit = (event) => {
    const { match: { params: { accountID } }, letsencryptStatus: { domain } } = this.props;
    event.preventDefault();
    this.props.registerDomain(accountID, domain, this.state.selectedAlternateDomains);
    this.props.setTitle('Validating Certificate - This may take a few minutes');
  };

  getStep = () => {
    switch (this.props.letsencryptStatus.status) {
      case null:
        return 0;
      case 'COMPLETE':
        return 2;
      default:
        return 1;
    }
  };

  getMatchingAppDomains = () => this.props.domains.filter(
    (item) => item.appId === this.props.letsencryptStatus.appId
      && item.fqdn !== this.props.letsencryptStatus.domain
      && !item.fqdn.match(/sites\.pressdns\.com$/),
  );

  // this function will filter out any domains that are not allowed
  // to be set for Let's Encrypt main domain
  // wildcards, pressdns, and now FSA-enabled
  filterAllowedDomains = (domain) => {
    if (domain.fqdn.match(/\.pressdns\.com$/)) {
      return false;
    }
    if (domain.fqdn.startsWith('*.')) {
      return false;
    }
    // this is the tough one
    const fsaRecordForDomain = this.props.appsById?.[domain.appId]?.fsa;
    if (fsaRecordForDomain) {
      // sorry its just easier if its nested
      if ([ACTIVE, PENDING, PENDING_INACTIVE].includes(fsaRecordForDomain.status) && fsaRecordForDomain.sslProvider === 'ACM') {
        return false;
      }
    }
    return true;
  };

  toggleAltDomain = (domain) => {
    if (this.state.selectedAlternateDomains.indexOf(domain) > -1) {
      this.setState((state) => ({
        selectedAlternateDomains: state.selectedAlternateDomains.filter(
          (item) => item !== domain,
        ),
      }));
    } else {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const domains = [...this.state.selectedAlternateDomains];
      domains.push(domain);
      this.setState({
        selectedAlternateDomains: domains,
      });
    }
  };

  toggleAllDomains = () => {
    if (this.state.selectedAlternateDomains.length > 0) {
      this.setState({
        selectedAlternateDomains: [],
      });
    } else {
      this.setState({
        selectedAlternateDomains: this.getMatchingAppDomains()
          .map((item) => item.fqdn),
      });
    }
  };

  renderHeader = () => {
    if (!this.props.letsencryptStatus.submitted) {
      return 'Select a site to add an automatically renewing Let\'s Encrypt Domain Validated certificate';
    }

    switch (this.props.letsencryptStatus.status) {
      case 'FAILED':
        if (this.props.letsencryptStatus.validation
          && this.props.letsencryptStatus.validation.body
          && this.props.letsencryptStatus.validation.body.domain) {
          return this.props.letsencryptStatus.validation.body.domain.messages.map(
            (msg) => (
              <div key={msg}>{msg}</div>
            ),
          );
        } else if (this.props.letsencryptStatus.validation
          && this.props.letsencryptStatus.validation.body) {
          return getAllValidationMessages(this.props.letsencryptStatus);
        }
        return 'There was an issue validating the domain.';
      case 'COMPLETE':
        if (this.props.letsencryptStatus.siteChecked && this.props.letsencryptStatus.cdnChecked) {
          return (
            <Typography align="center">
              Success! Your site will momentarily be secure. <br />It may take a few more seconds to get
              the server configuration pushed out, but feel free to start testing.
            </Typography>
          );
        }
        return 'Almost done, just checking to make sure your site is properly working.';
      default:
        return 'Registering your domain with Lets Encrypt and setting up the domain... this can take a few minutes.';
    }
  };

  renderAltDomains = () => {
    let doms = this.getMatchingAppDomains();

    if (doms.length === 0) {
      return null;
    }

    doms = doms
      .filter((domain) => domain.fqdn.match(this.state.domainFilter))
      .map(
        (item) => (
          <div key={item.fqdn}>
            <Checkbox
              label={item.fqdn}
              checked={this.state.selectedAlternateDomains.indexOf(item.fqdn) > -1}
              onCheck={() => this.toggleAltDomain(item.fqdn)}
            />
          </div>
        ),
      );

    return (
      <div
        style={{
          width: 600,
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <Box
          align="center"
          direction="row"
          margin={{ bottom: 'small' }}
        >
          <Typography>
            Include the following domains on the same certificate?&nbsp;
          </Typography>
          <Tooltip
            title={
              (
                <Typography variant="body1" css={{ color: 'var(--color-background)' }}>
                  Usually used to include the &quot;www&quot; subdomain with the non-www domain.
                  <br />
                  Note that all selected domains <strong>must still be active and pointing to the same app</strong> in
                  order for the certificate to be automatically renewed in three months!
                  <br />
                  If you have domains which will be managed separately, we suggest generating
                  a separate Let&apos;s Encrypt certificate for each of them.
                </Typography>
              )
            }
          >
            <InfoIcon color="primary" />
          </Tooltip>
        </Box>
        <div>
          <SearchBar
            text="Search Domains"
            onChange={(filter) => this.setState({ domainFilter: filter })}
          />
          <DomainsListContainer>
            <DomainsList>
              {doms}
            </DomainsList>
            <div>
              <Button
                color="default"
                variant="outlined"
                label="Toggle All"
                onClick={this.toggleAllDomains}
              />
            </div>
          </DomainsListContainer>
        </div>

      </div>
    );
  };

  renderContent = () => {
    const {
      history, classes, letsencryptStatus, match: { params: { accountID } },
    } = this.props;

    const domains = this.props.domains
      .filter(this.filterAllowedDomains)
      .map((d) => ({
        label: d.fqdn,
        value: d.fqdn,
        appId: d.appId,
      }));

    if (!letsencryptStatus.submitted) {
      return (
        <Box
          direction="column"
          gap="small"
        >
          <Select
            options={
              domains
            }
            onChange={(d) => this.props.setDomainToRegister(d.value, d.appId)}
            styles={{
              container: (base) => ({ ...base, width: 600 }),
            }}
            value={letsencryptStatus.domain ? domains.find((domain) => domain.fqdn === domain.value) : null}
            isClearable={false}
            placeholder="Select a domain name"
          />

          {this.renderAltDomains()}

          <Typography component="div" variant="body2" color="textSecondary" style={{ marginTop: 10 }}>
            Note: Please <SupportLink>contact support</SupportLink> if you would like to create a Wildcard LetsEncrypt Certificate.
          </Typography>
          <Box align="center" margin="xsmall">
            <Button
              variant="contained"
              disabled={!letsencryptStatus.domain}
              onClick={this.handleSubmit}
              label="Apply Certificate"
            />
          </Box>
          <Box align="center" margin={{ bottom: 'small' }}>
            <Typography color="textSecondary" component="div">
              Let’s Encrypt provides rate limits to ensure fair usage. Some notable rate limits are:
              <ul>
                <li>Certificates per Registered Domain, 50 per week</li>
                <li>Failed Validation, 5 failures per account, per hostname, per hour</li>
              </ul>
            </Typography>
            <Box align="center">
              <TextLink
                href="http://bit.ly/2Tm1c4t"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Let&apos;s Encrypt Documentation
              </TextLink>
            </Box>
          </Box>
        </Box>
      );
    }

    switch (letsencryptStatus.status) {
      case 'FAILED': {
        let error = '';

        if (letsencryptStatus.validation && letsencryptStatus.validation.body) {
          error = Object.values(letsencryptStatus.validation.body).reduce(
            (carry, item) => item.messages.join(', '),
            '',
          );
        }
        if (letsencryptStatus.message) {
          if (letsencryptStatus.message.indexOf('Challenge failed (response:') > -1) {
            const len = letsencryptStatus.message.length;
            const strippedError = JSON.parse(letsencryptStatus.message.substring(28, len - 2));

            if (strippedError.error.detail.includes('CAA')) {
              error = (
                <Fragment>
                  DNS challenge failed with status {strippedError.error.status}.
                  <div style={{ textAlign: 'center' }}>
                    Detailed Error: <pre>{strippedError.error.type}</pre>
                    {strippedError.error.detail}
                  </div>
                </Fragment>
              );
            } else if (strippedError.validationRecord?.length > 0) {
              error = (
                <Fragment>
                  DNS challenge failed. You may need to update your DNS record to point to the following:
                  <pre>
                    {
                      !strippedError.error.detail.includes('CAA')
                      && strippedError?.validationRecord?.[0]?.addressesResolved[0]
                      && (`A Record: @ Points to: ${strippedError.validationRecord[0].addressesResolved[0]}`)
                    }
                    {
                      strippedError?.validationRecord?.[1]?.hostname
                      && (`CNAME Record: www Points to: ${strippedError.validationRecord[1].hostname}`)
                    }
                  </pre>
                  <div style={{ textAlign: 'center' }}>
                    Detailed Error: <pre>{strippedError.error.type}</pre>
                    {strippedError.error.detail}
                  </div>
                </Fragment>
              );
            } else {
              error = (
                <Fragment>
                  DNS challenge failed with status {strippedError.error.status}.
                  <div style={{ textAlign: 'center' }}>
                    Detailed Error:
                    <pre>{strippedError.error.type}</pre>
                    {strippedError.error.detail}
                  </div>
                </Fragment>
              );
            }
          } else {
            error = letsencryptStatus.message;
          }
        }

        return (
          <ErrorBoundary>
            <div
              className={`${classes.letsencryptSubs} ${classes.letsencryptErrors}`}
            >
              {error}
            </div>
          </ErrorBoundary>
        );
      }
      case 'COMPLETE':
        if (letsencryptStatus.step === 'FETCH_CERTIFICATE' && letsencryptStatus.cdnChecked && letsencryptStatus.siteChecked) {
          return (
            <div
              className={classes.letsencryptSubs}
            >
              <TextLink
                href={`https://${letsencryptStatus.domain}`}
                rel="noopener noreferrer"
                target="_blank"
                color="textPrimary"
              >
                {`https://${letsencryptStatus.domain}`}
              </TextLink>
              <br /><br />
              <div
                className={classes.letsencryptSubs}
              >
                <div
                  className={`${classes.letsencryptCompleteButtonsContainer} ${classes.letsencryptSmallerText}`}
                >
                  <div
                    className={classes.letsencryptCompleteButton}
                  >
                    <Button
                      color="error"
                      label="Revert Certificate"
                      disabled={this.props.revert.done || this.props.revert.loading}
                      onClick={() => this.props.revertDomainCert(
                        letsencryptStatus.certId,
                        letsencryptStatus.aliasId,
                      )}
                    />
                  </div>
                  <div
                    className={classes.letsencryptCompleteButton}
                  >
                    <Button
                      label="Advanced Configuration"
                      color="default"
                      // eslint-disable-next-line max-len
                      onClick={() => history.replace(`/account/${accountID}/ssl/cert/${letsencryptStatus.certId}/domain/${letsencryptStatus.aliasId}`)}
                    />
                  </div>
                  <div
                    className={classes.letsencryptCompleteButton}
                  >
                    <Button
                      varian="contained"
                      label="Looks Good!"
                      onClick={() => history.replace(`/account/${accountID}/ssl/cert/${letsencryptStatus.certId}`)}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return (
          <Box
            className={classes.letsencryptSubs}
            direction="column"
            gap="small"
          >
            {
              this.state.timedOut && (
                <Toast
                  isOpen
                  type="danger"
                  message="We have not been able to verify your certificate was properly installed. Please contact support for verification."
                  closable
                  textColor="textPrimary"
                  to={`/account/${accountID}/support`}
                />
              )
            }
            <Box>
              {this.props.letsencryptStatus.siteChecked ? (
                <span style={{
                  color: styles.letsencryptCircularStatus.color,
                }}
                >
                  Site is accessible
                </span>
              ) : (
                <span>
                  Checking Site... <CircularStatus color="secondary" size={18} />
                </span>
              )}
            </Box>
            <Box>
              {this.props.letsencryptStatus.cdnChecked ? (
                <span style={{
                  color: styles.letsencryptCircularStatus.color,
                }}
                >
                  CDN is accessible
                </span>
              ) : (
                <span>
                  Checking CDN... <CircularStatus color="secondary" size={18} />
                </span>
              )}
            </Box>
          </Box>
        );
      default:
        return (
          <div
            className={classes.letsencryptSubs}
          >
            <CircularStatus
              color="secondary"
              size={50}
            />
            <Box margin="medium">
              Registering & Validating Certificate
            </Box>
          </div>
        );
    }
  };

  renderActions = () => {
    const { classes } = this.props;
    switch (this.props.letsencryptStatus.status) {
      case null:
        return (
          <div
            className={classes.letsencryptActionsContainer}
          >
            <span />
          </div>
        );
      case 'FAILED':
        return (
          <Box
            direction="row"
            justify="flex-end"
            flex={1}
          >
            <Button
              key="button"
              variant="outlined"
              label="Try Again"
              onClick={this.props.resetStatus}
            />
          </Box>
        );
      case 'COMPLETE':
        return (
          <div
            className={classes.letsencryptActionsContainer}
          >
            <span />
          </div>
        );
      default:
        return (
          <div
            className={classes.letsencryptActionsContainer}
          >
            <span />
          </div>
        );
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classes.letsencryptContainer}
      >
        <div
          className={classes.letsencryptSubs}
        >
          <Stepper step={this.getStep()} />
          <Typography variant="h5" gutterBottom>{this.renderHeader()}</Typography>
          <Divider />
        </div>
        {this.renderContent()}
        <Box margin={{ top: 'small' }} flex={1}>
          {this.renderActions()}
        </Box>
      </div>
    );
  }
}

const ModalWrapper = (props) => {
  const handleClose = () => {
    const { history, match: { params: { accountID } } } = props;
    history.push(`/account/${accountID}/ssl`);
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth classes={{ paper: props.classes.dialog }}>
      <div>
        <DialogTitle>
          Let&apos;s Encrypt Certificate
        </DialogTitle>
        <DialogContent classes={{ root: props.classes.dialog }}>
          <LetsEncrypt {...props} />
        </DialogContent>
      </div>
    </Dialog>
  );
};

ModalWrapper.propTypes = {
  classes: object,
  history: object,
  match: object,
};

const mapStateToProps = (state) => {
  return {
    letsencryptStatus: state.letsencryptStatus,
    certDomainLink: state.certDomainLink,
    domains: state.domains.records,
    revert: state.linkedCertRevert,
    cdn: state.cdn.cdnApp,
    appsById: getAppsById(state),
  };
};

const mapDispatchToProps = {
  resetCerts: fetchCertsForAccount,
  resetCertDomainLink,
  resetStatus: reset,
  registerDomain: register,
  getJobStatus: getStatus,
  setDomainToRegister: setDomain,
  deactivate: deactivateDomainCert,
  checkSite,
  checkCdn,
  revertDomainCert,
  requestCdnForApp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(ModalWrapper),
);
