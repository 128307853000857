import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { StyleGuildTextFieldProps } from 'shared/styleguide/atoms/Input/TextField';
import { inline } from './styles';

interface Props {
  code?: boolean;
}

export const TextArea = (props: Props & StyleGuildTextFieldProps): JSX.Element => {
  const {
    code, rows = 8, maxRows = 12, ...rest
  } = props;
  const taStyle = code ? { ...inline.textArea, ...inline.textAreaCode } : inline.textArea;
  const classes = makeStyles({ root: taStyle })();

  return (
    <TextField
      InputProps={{ classes }}
      type={code ? 'pre' : 'textarea'}
      variant="outlined"
      maxRows={maxRows}
      minRows={rows}
      fullWidth
      multiline
      spellCheck="false"
      {...rest}
    />
  );
};

export default TextArea;
