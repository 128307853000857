import { getIps } from '../../../utils';

export const validate = (values, mode, existingRules) => {
  const errors = {};

  const body = {
    match: values.match,
    path: values.path,
    geoInverse: false, // default value (not nullable)
    // the following will be erased if not set
    // erase requires empty string/array ("null" will ignore)
    authUsername: '',
    authPassword: '',
    ipListType: '',
    ipListName: '',
    ips: [],
    geoCountries: [],
    forbiddenMessage: values.forbiddenMessage || '',
    errorLogMessage: values.errorLogMessage || '',
  };

  if (!values.preset && !body.path) {
    errors.pathSection = 'Must select a Path';
    return [null, errors];
  }

  if (values.preset === 'custom') {
    if (!body.match) {
      errors.match = 'Must select Path match';
    }

    if (!body.path) {
      errors.path = 'Path must not be empty';
    }
  }

  if (values.preset && values.preset !== 'custom') {
    body.match = 'preset';
    body.path = values.preset;
  }

  if (mode === 'create' && existingRules?.includes(`${body.path}.${body.match}`)) {
    errors.general = 'Rule with same path parameters already exists';

    return [null, errors];
  }

  if (!values.auth && !values.ip && !values.geo) {
    errors.general = 'At least one of Auth, IP List or Geo must be configured';
    return [null, errors];
  }

  if (values.auth) {
    if (!values.authUsername) {
      errors.authUsername = 'User Name must not be empty';
    }

    if (mode === 'create' && values.authUsername && !values.authPassword) {
      errors.authPassword = 'Password must not be empty';
    }

    Object.assign(body, {
      authUsername: values.authUsername,
      authPassword: values.authPassword || null,
    });
  }

  if (values.ip) {
    if (!values.ipListType) {
      errors.ipListType = 'Must select IP List access';
    }

    Object.assign(body, {
      ipListType: values.ipListType,
      ipListName: values.ipListName,
      ips: values.ipsString ? getIps(values.ipsString) : [],
    });

    if (!body.ips.length) {
      errors.ips = 'IP List must not be empty';
    }
  }

  if (values.geo) {
    if (!values.geoCountries?.length) {
      errors.geoCountries = 'Country list must not be empty';
    }

    if (values.geoInverse === undefined) {
      errors.geoInverse = 'Must select Geo access';
    }

    Object.assign(body, {
      geoCountries: values.geoCountries ? values.geoCountries.filter((c) => c).map((option) => option.id) : [],
      geoInverse: values.geoInverse,
    });
  }

  if (Object.keys(errors).length) {
    return [null, errors];
  }

  return [body, null];
};
