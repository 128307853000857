import React from 'react';
import styled from '@emotion/styled';

import Typography from '@material-ui/core/Typography';

const StyledTypography = styled(Typography)`
  min-height: 1.8rem;
`;

const DashboardCardHeading = ({ ...props }) => {
  return (
    <Typography
      variant="h3"
      {...props}
      gutterBottom
    />
  );
};

export default DashboardCardHeading;
