import React, { useState, Fragment, useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import { Paper, Typography } from '@material-ui/core';

import NarrowTextField from 'shared/styleguide/atoms/Input/CodeField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import { consolidateErrors } from 'shared/utils/validation';
import { ErrorText } from 'shared/styleguide/typography';

import { twoFactorDisableRequest } from 'shared/modules/account/redux/actions';

import MFAFlow from 'shared/modules/authentication/components/MFAFlow';

const MFAEnabled = ({ requestDisable, account }) => {
  const [showCodeField, setShowCodeField] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState(null);

  const handleRequestDisable = async () => {
    try {
      await requestDisable(account.id, verificationCode);
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
  };

  return (
    <Box>
      <Box direction="row" justify="space-between" align="center" padding={{ top: 'small', bottom: 'small' }}>
        <Box direction="row" gap="xsmall">
          <Box>
            <Typography variant="h4" color="textPrimary" gutterBottom>Status:</Typography>
          </Box>
          <Box>
            <GhostTag color="green">Enabled</GhostTag>
          </Box>
        </Box>
        <Box>
          <Button
            onClick={() => setShowCodeField(true)}
          >
            Disable MFA
          </Button>
        </Box>
      </Box>

      <Typography variant="body2" color="textSecondary">For additional security, you will be prompted to enter a unique code when you log in.</Typography>

      {
        showCodeField && (
          <Box direction="row" gap="medium" align="center" padding={{ top: 'small' }}>
            <Box>
              <NarrowTextField
                id="code"
                autoComplete="off"
                placeholder="Code"
                value={verificationCode}
                onChange={(e) => { setVerificationCode(e.target.value); }}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                margin="dense"
                variant="outlined"
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                disabled={!verificationCode}
                onClick={handleRequestDisable}
              >
                Verify and Disable
              </Button>
            </Box>
          </Box>
        )
      }
      {
        errors && (
          <ErrorText>{errors}</ErrorText>
        )
      }
    </Box>
  );
};

MFAEnabled.propTypes = {
  account: object.isRequired,
  requestDisable: func.isRequired,
};

const MFADisabled = () => {
  const [showSetup, setShowSetup] = useState(false);
  return (
    <Fragment>
      <Box>
        <Box direction="row" justify="space-between" align="center" padding={{ top: 'small', bottom: 'small' }}>
          <Box direction="row" gap="xsmall">
            <Box>
              <Typography variant="h4" color="textPrimary" gutterBottom>Status:</Typography>
            </Box>
            <Box>
              <GhostTag color="red">Disabled</GhostTag>
            </Box>
          </Box>
          <Box>
            <Button
              onClick={() => setShowSetup(true)}
            >
              Enable MFA
            </Button>
          </Box>
        </Box>

        <Typography variant="body2" color="textSecondary">Multi-Factor Authentication is another security layer that can be enabled to prevent unauthorized access to your user account. While enabled, you will need to enter an additional code when logging in and changing sensitive information.</Typography>
      </Box>
      {
        showSetup && (
          <MFAFlow />
        )
      }
    </Fragment>
  );
};

const MFA = ({ account, twoFactorDisableRequest }) => {
  const [mfaEnabled, setMfaEnabled] = useState(account.mfaEnabled);

  useEffect(() => {
    setMfaEnabled(account.mfaEnabled);
  }, [account.mfaEnabled]);

  return (
    <Box direction="column" gap="medium">
      <Paper>
        <Box padding="medium">
          <Typography variant="h4" color="textPrimary" gutterBottom>Multi-Factor Authentication (MFA)</Typography>
          {
            mfaEnabled ? (
              <MFAEnabled requestDisable={twoFactorDisableRequest} account={account} />
            ) : (
              <MFADisabled />
            )
          }
        </Box>
      </Paper>
    </Box>
  );
};

MFA.propTypes = {
  account: object.isRequired,
  twoFactorDisableRequest: func.isRequired,
};

export default connect(
  (state) => ({ account: state.account }),
  {
    twoFactorDisableRequest,
  },
)(MFA);
