import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import { useGetNotesLeaderboardQuery } from '../query';

const sortByWeekThenMonth = (a, b) => {
  if (a.lastWeek > b.lastWeek) {
    return -1;
  }

  if (a.lastWeek < b.lastWeek) {
    return 1;
  }

  if (a.threeMonths > b.threeMonths) {
    return -1;
  }

  if (a.threeMonths < b.threeMonths) {
    return 1;
  }

  return 0;
};

const Leaderboard = () => {
  const { data: records, isLoading, error } = useGetNotesLeaderboardQuery({});

  let content;

  if (isLoading) {
    content = (
      <Loading />
    );
  } else if (error) {
    content = (
      <Box margin={{ top: 'large', bottom: 'small' }} className="has-error">
        {/* @ts-ignore */}
        <Typography color="error">{error.error}</Typography>
      </Box>
    );
  } else {
    let weekTotal = 0;
    let monthTotal = 0;

    const data = records.data.slice(0).sort(sortByWeekThenMonth);

    content = (
      <Box direction="column" gap="small" margin={{ top: 'medium' }}>
        {data.map((leader, i) => {
          weekTotal += leader.lastWeek;
          monthTotal += leader.threeMonths;
          return (
            <Box direction="row" align="center" gap="small" data-testid="leaderitem" key={leader.username}>
              <Avatar>{i + 1}</Avatar>
              <Box direction="column">
                <Typography><strong>{leader.name}</strong></Typography>
                <Typography color="textSecondary">
                  <span>last week: {leader.lastWeek}</span> / <span>last 3 months: {leader.threeMonths}</span>
                </Typography>
              </Box>
            </Box>
          );
        })}
        <Box margin={{ top: 'small' }}>
          <Typography>
            <strong>Total:</strong> last week {weekTotal} / last 3 months {monthTotal}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box direction="column" padding="small">
      <Typography variant="h3">Notes Leaderboard</Typography>
      {content}
    </Box>
  );
};

export default Leaderboard;
