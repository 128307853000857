import React, { useState } from 'react';
import {
  number, object, oneOfType, string,
} from 'prop-types';
import {
  Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Box from 'shared/styleguide/atoms/Box';

const Hop = (props) => {
  const { url, statusCode, headers } = props;
  const [showAdditional, setShowAdditional] = useState(false);

  const classes = makeStyles((theme) => {
    const borderColor = theme.palette.grey[400];
    return ({
      container: {
        border: `1px solid ${borderColor}`,
      },
      item: {
        'padding': theme.spacing(1),
        'wordBreak': 'break-word',
        '&:first-of-type': {
          borderRight: `1px solid ${borderColor}`,
          color: theme.palette.grey[700],
        },
      },
    });
  })();

  const row = makeStyles((theme) => {
    const borderColor = theme.palette.grey[400];

    return ({
      container: {
        '&:not(:last-of-type)': {
          borderBottom: `1px solid ${borderColor}`,
        },
      },
    });
  })();

  const sortedHeaderNames = headers ? Object.keys(headers)
    .sort((a, b) => {
      const aName = a.toUpperCase();
      const bName = b.toUpperCase();
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    }) : [];

  return (
    <Box>
      <Grid container classes={classes}>
        <Grid container classes={row}>
          <Grid item classes={classes} xs={3}>
            <Typography variant="body2">
              URL
            </Typography>
          </Grid>
          <Grid item classes={classes} xs={9}>
            <Typography>
              {url}
            </Typography>
          </Grid>
        </Grid>
        <Grid container classes={row}>
          <Grid item classes={classes} xs={3}>
            <Typography variant="body2">
              Status Code
            </Typography>
          </Grid>
          <Grid item classes={classes} xs={9}>
            <Typography>
              {statusCode}
            </Typography>
          </Grid>
        </Grid>
        {
          showAdditional && headers
          && sortedHeaderNames
            .map((name) => {
              return (
                <Grid container classes={row} key={name}>
                  <Grid item classes={classes} xs={3}>
                    <Typography variant="body2">
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item classes={classes} xs={9}>
                    <Typography>
                      {headers[name].join('; ')}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })
        }
        {
          props.error && (
            <Grid container classes={row}>
              <Grid item classes={classes} xs={3}>
                <Typography variant="body2" color="error">
                  Error
                </Typography>
              </Grid>
              <Grid item classes={classes} xs={9}>
                <Typography>
                  {props.error}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      </Grid>
      {
        headers && (
          <Box margin={{ top: 'xxsmall' }}>
            <TextLink
              variant="subtitle2"
              onClick={() => setShowAdditional(!showAdditional)}
            >
              {showAdditional ? 'Hide' : 'Show'} Additional Headers
            </TextLink>
          </Box>
        )
      }
    </Box>
  );
};

Hop.propTypes = {
  error: string,
  headers: object,
  statusCode: oneOfType([string, number]),
  url: string,
};

export default Hop;
