import React, { useEffect, Fragment } from 'react';
import { object } from 'prop-types';

import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';

import { inputs } from '../constants';

const ToQueries = ({
  values, form,
}) => {
  useEffect(() => {
    if (!values.to.query || values.to.query?.length === 0) {
      form.addQuery('to');
    }
  }, [values.to.query, form]);

  return (
    <Fragment>
      {
        values.to.query?.length > 0 && values.to.query.map((query, index) => {
          return (
            <Box
              key={`to-query-${index}`}
              padding={{ top: 'xsmall', bottom: 'xsmall', left: 'small' }}
              direction="row"
              alignItems="center"
              flex={1}
              align="center"
            >
              <Box css={{ flexBasis: '49%' }}>
                <TextField
                  variant="outlined"
                  value={query.key || ''}
                  onChange={(e) => form.changeQuery('to', index, 'key', e.target.value)}
                  placeholder={inputs.query.keyPlaceholder}
                  autoComplete="off"
                  margin="dense"
                  fullWidth
                  multiline
                  disabled={form.status === 'loading'}
                />
              </Box>
              <Box css={{ flexBasis: 'auto' }}>
                <Box align="center" padding="xxsmall">
                  <strong>=</strong>
                </Box>
              </Box>
              <Box css={{ flexBasis: '49%' }}>
                <TextField
                  variant="outlined"
                  value={query.value || ''}
                  onChange={(e) => form.changeQuery('to', index, 'value', e.target.value)}
                  placeholder={inputs.query.valuePlaceholder}
                  autoComplete="off"
                  margin="dense"
                  fullWidth
                  multiline
                  disabled={form.status === 'loading'}
                />
              </Box>
              <Box css={{ flexBasis: 'auto' }}>
                <IconButton
                  onClick={() => form.deleteQuery('to', index)}
                  title="Delete"
                  size="small"
                >
                  <Delete color="inherit" />
                </IconButton>
              </Box>
            </Box>
          );
        })
      }
    </Fragment>
  );
};

ToQueries.propTypes = {
  form: object,
  values: object,
};

export default ToQueries;
