import { coreApi } from 'shared/utils/redux/query';
import { Billing } from './types';

const api = coreApi.enhanceEndpoints({
  addTagTypes: ['PaymentPlan'],
});

export const billingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionIdsByAccountId: builder.query<string[], string | number>({
      query: (accountId) => `go-billing/account/${accountId}/subscriptions`,
      providesTags: ['PaymentPlan'],
    }),
    getSubscriptionBySubscriptionIdAndAccountId: builder.query<
      Billing.SubscriptionBySubscriptionIdAndAccountId.GoSubscription,
      { accountId: string; subscriptionId: string }
    >({
      query: ({ accountId, subscriptionId }: { accountId: string | number; subscriptionId: string | number }) => `go-billing/account/${accountId}/subscription/${subscriptionId}`,
      providesTags: ['PaymentPlan'],
    }),
    getSubscriptionPlansAndAddonCatalog: builder.query<
      Billing.SubscriptionPlansAndAddonCatalog.Catalog, null
    >({
      query: () => 'go-billing/catalog',
      providesTags: ['PaymentPlan'],
    }),
    getAccountLimitsByAccountId: builder.query<
      Billing.AccountLimits.Limits,
      string | number
    >({
      query: (accountId) => `accounts/${accountId}/limits`,
      providesTags: ['PaymentPlan'],
    }),
    updateSubscriptionPlan: builder.mutation({
      query: ({
        customerId, subscriptionId, planId, billingFrequency, addons,
      }: {
        customerId: string;
        subscriptionId: string;
        billingFrequency: string;
        planId?: string;
        addons?: Billing.SubscriptionBySubscriptionIdAndAccountId.Addons;
      }) => ({
        url: `go-billing/account/${customerId}/subscription/${subscriptionId}`,
        method: 'PATCH',
        body: {
          billingFrequency,
          ...planId && { planId },
          ...addons && { addons },
        },
      }),
      invalidatesTags: ['PaymentPlan'],
    }),
    purchaseLtsAddon: builder.mutation({
      query: (accountId) => ({
        url: `billing/accounts/${accountId}/php-lts`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetSubscriptionIdsByAccountIdQuery,
  useGetSubscriptionBySubscriptionIdAndAccountIdQuery,
  useGetSubscriptionPlansAndAddonCatalogQuery,
  useGetAccountLimitsByAccountIdQuery,
  useUpdateSubscriptionPlanMutation,
  usePurchaseLtsAddonMutation,
} = billingApi;
