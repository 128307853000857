import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Typography,
} from '@material-ui/core';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Box from 'shared/styleguide/atoms/Box';
import ResetMFA from 'shared/modules/authentication/components/ResetMFA';
import ResetMfaSet from 'shared/modules/authentication/components/ResetMFASet';
import { jssStyles } from 'shared/modules/authentication/components/styles';

export class MFAReset extends Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    errMessage: PropTypes.string.isRequired,
    handleLogin: PropTypes.func.isRequired,
    headerText: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    match: PropTypes.object,
    mfaOptional: PropTypes.bool,
    preserveUrl: PropTypes.bool,
    requestErr: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    headerText: 'Reset Multi-Factor Authentication',
    preserveUrl: false,
  };

  validate = (username, password) => {
    const errors = {};

    if (!username) errors.username = 'Username is required';
    if (!password) errors.password = 'Password is required';

    return errors;
  };

  render() {
    const { match } = this.props;

    if (match.params.token) {
      return (
        <>
          <Typography variant="h4" gutterBottom>Disable Multi-Factor Authentication</Typography>
          <ResetMfaSet
            match={match}
            accountType={this.props.accountType}
          />
          <Box align="center" margin={{ top: 'medium' }}>
            <TextLink color="textPrimary" noUnderline to="/">Cancel</TextLink>
          </Box>
        </>
      );
    }

    return (
      <Box align="center">
        <Typography variant="h4" gutterBottom>
          Reset Multi-Factor Authentication
        </Typography>
        <ResetMFA
          accountType={this.props.accountType}
        />
        <Box align="center" margin={{ top: 'medium' }}>
          <TextLink color="textPrimary" noUnderline to="/help">Cancel</TextLink>
        </Box>
      </Box>
    );
  }
}

export default withStyles(jssStyles)(MFAReset);
