import * as base from 'shared/baseStates';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import apiReducer, {
  baseState,
  load,
  success,
  fail,
} from 'shared/utils/redux/apiReducer';
import * as ACCOUNT from './constants';

export const emailChangeInitialState = {
  changeRequestSuccess: null,
  verifyRequestSuccess: null,
  ...base.record(),
};

export function emailChangeRequest(state = emailChangeInitialState, action) {
  switch (action.type) {
    case ACCOUNT.EMAIL_CHANGE_REQUEST_REQUEST:
      return { ...state, ...base.request('Updating your email address...') };
    case ACCOUNT.EMAIL_CHANGE_REQUEST_SUCCESS:
      return {
        ...state,
        ...base.success(),
        changeRequestSuccess: true,
      };
    case ACCOUNT.EMAIL_CHANGE_REQUEST_FAILURE:
      return {
        ...state,
        ...base.failure(action.errMessage, action.statusCode, action.validation),
        changeRequestSuccess: false,
      };
    case ACCOUNT.EMAIL_CHANGE_REQUEST_RESET:
    case LOGIN_LOGOUT_SUCCESS:
      return { ...emailChangeInitialState };
    default:
      return state;
  }
}

export const emailVerifyInitialState = {
  ...base.record(),
};

export function emailVerifyRequest(state = emailVerifyInitialState, action) {
  switch (action.type) {
    case ACCOUNT.EMAIL_VERIFY_REQUEST_REQUEST:
      return { ...state, ...base.request('Verifying your email address...') };
    case ACCOUNT.EMAIL_VERIFY_REQUEST_SUCCESS:
      return {
        ...state,
        ...base.success(),
        verifyRequestSuccess: true,
      };
    case ACCOUNT.EMAIL_VERIFY_REQUEST_FAILURE:
      return {
        ...state,
        ...base.failure(action.errMessage, action.statusCode, action.validation),
        verifyRequestSuccess: false,
      };
    case ACCOUNT.EMAIL_VERIFY_REQUEST_RESET:
    case LOGIN_LOGOUT_SUCCESS:
      return { ...emailVerifyInitialState };
    default:
      return state;
  }
}

export const checkEmailToken = apiReducer(
  ACCOUNT.EMAIL_CHECK_TOKEN_REQUEST,
  ACCOUNT.EMAIL_CHECK_TOKEN_SUCCESS,
  ACCOUNT.EMAIL_CHECK_TOKEN_FAILURE,
  'ACCOUNT_RESET', // technically not used
  { ...baseState },
  load,
  load,
  success,
  fail,
);
