import React from 'react';
import {
  spinner, bounce1, bounce2, bounce3,
} from './Dots.scss';

const LoadingDots = (props) => (
  <div {...props} className={spinner}>
    <div className={bounce1} />
    <div className={bounce2} />
    <div className={bounce3} />
  </div>
);

export default LoadingDots;
