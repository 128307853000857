import React, {
  useCallback, Fragment,
} from 'react';

import ReloadIcon from '@material-ui/icons/Refresh';
import Pagination from '@material-ui/lab/Pagination';
import {
  Typography, IconButton,
} from '@material-ui/core';

import Loading from 'shared/styleguide/atoms/Loading';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import { ErrorText } from 'shared/styleguide/typography';

import LogItem from './LogItem';
import { useJobList } from '../hooks/useJobList';

export const Content = ({
  name, jobList, type, __storybookMocks, fetchedOnce,
}) => { // eslint-disable-line
  const {
    loadingJobs, jobsItems, page, setPage, setIndex, pageLength, count, errors,
  } = jobList;
  const initialJobLoading = !fetchedOnce && loadingJobs;

  if (errors) {
    return (
      <Box align="center" margin={{ top: 'medium', bottom: 'medium' }}>
        <ErrorText>{errors}</ErrorText>
      </Box>
    );
  }

  if (!fetchedOnce || initialJobLoading) {
    return <Loading />;
  }

  if (fetchedOnce && !loadingJobs && !jobsItems.length) {
    return (
      <Empty>
        There are no {name} deployment logs to display. Logs are retained for 1 month.
      </Empty>
    );
  }

  if (fetchedOnce && jobsItems.length > 0) {
    return (
      <Fragment>
        {
          jobsItems
            .map((item) => (
              <LogItem
                key={`log-${item.job.type}-${item.job.id}`}
                job={item.job}
                type={type}
                integrationName={item.integrationName}
                __storybookMocks={__storybookMocks}
                {...item.specialProps}
              />
            ))
        }
        {
          jobsItems.length > pageLength && (
            <Box margin={{ top: 'small' }} justify="center" direction="row">
              <Pagination
                shape="rounded"
                count={count}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                  setIndex(((value - 1) * pageLength));
                }}
              />
            </Box>
          )
        }
      </Fragment>
    );
  }
};

export const LogsSection = ({
  jobs, name, integrations, type, __storybookMocks,
}) => {
  const unmemoizedJobList = useJobList(jobs[type], integrations);
  const tempJobList = useCallback(unmemoizedJobList as unknown as any, [unmemoizedJobList, jobs, integrations]);

  const jobList = __storybookMocks?.[type]?.list ? {
    ...tempJobList,
    ...__storybookMocks[type].list,
  } : { ...tempJobList };

  const subsequentJobLoading = jobs.fetchedOnce[type] && jobList.loadingJobs;

  return (
    <Box margin={{ bottom: 'medium' }}>
      <Box direction="row" justify="space-between" align="center">
        <Typography data-testid="log-section-heading" variant="h3">{name} Deployments</Typography>
        <Box direction="row">
          {
            subsequentJobLoading
              ? (
                <Box css={{ padding: 16, marginRight: 6 }}>
                  {/* @ts-ignore */}
                  <Loading size="small" margin={{ top: 0 }} />
                </Box>
              ) : (
                <IconButton
                  onClick={() => jobs.get(type)}
                >
                  <ReloadIcon />
                </IconButton>
              )
          }
        </Box>
      </Box>
      <Content
        name={name}
        jobList={jobList}
        type={type}
        __storybookMocks={__storybookMocks}
        fetchedOnce={jobs?.fetchedOnce?.[type]}
      />
    </Box>
  );
};

export default LogsSection;
