import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import { AUTH_TYPE } from 'shared/utils/constants';
import * as ALERTS from './constants';

export const fetchAlerts = (options) => {
  return makeApiAction(
    ({ api }) => {
      let query = '';
      if (options && Object.keys(options).length) {
        query = qs.stringify(options, { addQueryPrefix: true });
      }
      return api().get(`/status/alerts${query}`);
    },
    ALERTS.FETCH_ALERTS_REQUEST,
    ALERTS.FETCH_ALERTS_SUCCESS,
    ALERTS.FETCH_ALERTS_FAILURE,
    null,
    true,
  );
};

export const resetAlerts = () => ({
  type: ALERTS.FETCH_ALERTS_RESET,
});

export const fetchTargetAlerts = (targetId, options) => makeApiAction(
  ({ api }) => {
    let query = '';
    if (options && Object.keys(options).length) {
      query = qs.stringify(options, { addQueryPrefix: true });
    }
    return api().get(`/status/alerts/target/${AUTH_TYPE.account}:${targetId}${query}`);
  },
  ALERTS.FETCH_ALERTS_REQUEST,
  ALERTS.FETCH_ALERTS_SUCCESS,
  ALERTS.FETCH_ALERTS_FAILURE,
  { targetId },
  true,
);

export const updateAlertReadStatus = (alertId, readStatus) => makeApiAction(
  ({ api }) => api().put(`/status/alerts/id/${alertId}/read-status/${readStatus}`),
  ALERTS.UPDATE_READ_STATUS_REQUEST,
  ALERTS.UPDATE_READ_STATUS_SUCCESS,
  ALERTS.UPDATE_READ_STATUS_FAILURE,
  { readStatus },
  true,
);
