import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const InfoVideo = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 14">
    <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-125.000000, -71.000000)" fill="currentColor" fillRule="nonzero">
        <g id="video-plus-outline" transform="translate(125.000000, 71.000000)">
          <path d="M8,6 L8,11 L6,11 L6,6 L8,6 Z M8,3 L8,5 L6,5 L6,3 L8,3 Z M12,2 L12,12 L2,12 L2,2 L12,2 M13,0 L1,0 C0.45,0 0,0.525 0,1.16666667 L0,12.8333333 C0,13.475 0.45,14 1,14 L13,14 C13.55,14 14,13.475 14,12.8333333 L14,8.75 L18,13.4166667 L18,0.583333333 L14,5.25 L14,1.16666667 C14,0.525 13.55,0 13,0" id="Shape" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

InfoVideo.displayName = 'InfoVideoIcon';

export default InfoVideo;
