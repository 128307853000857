import React from 'react';
import { array, string } from 'prop-types';

import { useTheme } from '@material-ui/core';
import { ColorText, ErrorText } from 'shared/styleguide/typography';

const Errors = ({ errorData, errorHeading }) => {
  const theme = useTheme();
  if (Array.isArray(errorData)) {
    const errorList = errorData.map((err, i) => {
      let params = '';
      let additionalMessages = '';
      if (err.params) {
        params = (
          <span
            style={{ fontWeight: 600 }}
          >
            {JSON.stringify(err.params)}
          </span>
        );
      }
      if (err.type === 'json') {
        additionalMessages = (
          <div>
            <div>Please check for (and remove) dangling commas and inline comments (JSON5 syntax is not supported at this time.) </div>
            <div>Debug your json here: <a href="https://jsonlint.com/" target="_blank" rel="nofollow noopener noreferrer">jsonlint.com</a></div>
          </div>
        );
      }
      return (
        <div key={`${err.toString()}-${i}`}>
          <li
            style={{ fontSize: '90%', wordBreak: 'break-word' }}
          >
            <ColorText
              color="textPrimary"
            >
              {err.dataPath} {err.message} {params}
            </ColorText>
          </li>
          {additionalMessages
            && (
              <li
                style={{ fontSize: '90%', wordBreak: 'break-word' }}
              >
                <ColorText
                  color="textPrimary"
                >
                  {additionalMessages}
                </ColorText>
              </li>
            )}
        </div>
      );
    });
    return (
      <div
        style={{
          border: `1px solid ${theme.palette.error.main}`, background: theme.palette.background.default, padding: 5, marginTop: 20,
        }}
      >
        <div
          key={errorHeading}
          style={{ paddingLeft: 30, paddingTop: 10 }}
        >
          <ErrorText style={{ fontWeight: 600 }}>
            {errorHeading}
          </ErrorText>
        </div>
        <ul style={{ fontSize: '90%', marginTop: 0 }}>
          {errorList}
        </ul>
      </div>
    );
  } else {
    return null;
  }
};

Errors.propTypes = {
  errorData: array,
  errorHeading: string,
};

export default Errors;
