import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Loading from 'shared/styleguide/atoms/Loading';
import { SUCCESS } from 'shared/utils/redux/constants';
import { requestCertIfNeeded, reset } from 'shared/modules/ssl/redux/cert/actions';
import { propTypes } from 'shared/modules/ssl/models/certificate';
import Layout from 'shared/styleguide/molecules/Layout';
import Card from 'shared/styleguide/atoms/Card/Card';
import Box from 'shared/styleguide/atoms/Box';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Linker from 'shared/styleguide/atoms/Links/Linker';
import AdminContext from 'shared/modules/permissions/context/AdminContext';
import CertDomainLoader from '../EditCertificateModal';
import CertificateSettings from '../EditCertificateModal/CertificateSettings';
import BulkDomainActions from '../EditCertificateModal/BulkDomainActions';
import LinkToDomainDialog from '../LinkDomains';
import CertDomainsList from './DomainsList';
import CertHeading from './CertHeading';

const Certificate = (props) => {
  const { cert, match } = props;
  const isAdmin = useContext(AdminContext);

  const [openModal, setOpenModal] = useState(false);
  const {
    subjectData,
  } = cert;

  return (
    <div style={{ width: '100%' }}>
      <Layout title="Certificate Details">
        <Box margin={{ bottom: 'medium' }}>
          <Card column>
            <div css={{
              width: '100%',
            }}
            >
              <Typography variant="h3">{subjectData.name}</Typography>

              <div style={{ display: 'flex', flexFlow: 'row ' }}>
                <CertHeading {...props.cert} />

                {
                  isAdmin && (
                    <div>
                      <Button style={{ whiteSpace: 'nowrap' }} onClick={() => { setOpenModal(true); }}>Bulk Actions</Button>
                      {
                        openModal && (
                          // @ts-ignore
                          <BulkDomainActions match={props.match} open onClose={() => setOpenModal(false)} />
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </Card>
        </Box>

        <Box direction="row" justify="space-between" margin={{ bottom: 'xsmall' }} align="flex-end">
          <Typography component="div" variant="h3">Domains</Typography>
          <div>
            <Button
              component={Linker}
              variant="contained"
              to={`${match.url}/link`}
            >
              Link to Domains
            </Button>
          </div>
        </Box>
        <Box>
          <CertDomainsList
            match={match}
          />
        </Box>
      </Layout>
    </div>
  );
};

Certificate.propTypes = {
  cert: PropTypes.shape(propTypes),
  editLink: PropTypes.func,
  match: PropTypes.object.isRequired,
};

const CertificateLoader = ({ cert, ...props }) => {
  const match = useRouteMatch();

  useEffect(() => {
    const certIdAsInt = parseInt((match.params as any).certID, 10);
    if (cert.status === 'pristine' || (cert.params.certId !== certIdAsInt)) {
      props.requestCert((match.params as any).certID);
    }
  }, [match, cert.status, cert.params, props.requestCert]);

  useEffect(() => {
    return () => {
      props.resetCert();
    };
  }, []);

  if (cert.status !== SUCCESS) {
    return <Loading />;
  }

  return (
    <div>
      <Route
        path={match.path}
        render={() => <Certificate cert={cert} match={match} />}
        key={cert.id}
      />
      <Switch
        key="subroutes"
      >
        <Route
          path={`${match.path}/link`}
          component={LinkToDomainDialog}
        />
        <Route
          path={`${match.path}/app/:appID/domain/:aliasID`}
          render={(renderProps) => (
            <CertDomainLoader {...renderProps}>
              {/* @ts-ignore */}
              <CertificateSettings {...renderProps} />
            </CertDomainLoader>
          )}
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({ cert: state.cert });
const mapDispatchToProps = {
  resetCert: reset,
  requestCert: requestCertIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateLoader);
