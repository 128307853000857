import React from 'react';
import {
  bool, object, string,
} from 'prop-types';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  useTheme, Button,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => {
    return (isPropValid(prop)
      // don't forward color because 100% of the time its going to be error
      && !['isAnimated', 'color'].includes(prop)) || prop === 'startIcon';
  },
})(({
  variant, initialize = false, isAnimated = false, theme,
}) => {
  const initialIsErrorColor = initialize || isAnimated;

  const colors = variant === 'contained' ? {
    color: theme.palette.common.white,
    highlight: theme.palette.common.white,
    background: theme.palette.error.main,
    border: 'none',
  } : {
    // outlined
    color: initialize ? theme.palette.error.main : theme.palette.text.primary,
    highlight: theme.palette.error.main,
    background: theme.palette.background.paper,
    border: `1px solid ${initialIsErrorColor ? theme.palette.error.main : theme.palette.text.primary}88`,
  };

  return css`
    transition: all 0.1s ease-out;
    background-color: ${colors.background};
    color: ${colors.color};
    border: ${colors.border};
    &:hover {
      color: ${colors.highlight};
      border-color: ${colors.highlight};
      background-color: ${colors.background};
      filter: brightness(97%);
    }
  `;
});

const AnimatedButton = ({
  children, style, isAnimated, ...props
}) => {
  return (
    <StyledButton
      {...props}
      isAnimated={isAnimated}
      style={{
        ...style,
        color: 'transparent',
      }}
    >
      {children}
    </StyledButton>
  );
};

AnimatedButton.propTypes = {
  isAnimated: bool,
  style: object,
};

const DangerButton = ({
  children, ...props
}) => {
  const theme = useTheme();

  const {
    variant, status, loading, success, label, style, ...buttonProps
  } = props;

  const animationStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  };

  if (variant === 'contained') {
    // overrides the icon color prop
    animationStyle.color = theme.color300;
  } else {
    animationStyle.color = theme.palette.error.main;
  }

  const animatedButtonProps = {
    style: animationStyle,
    size: 24,
  };

  const isAnimated = loading || status === 'loading' || success || status === 'success';

  if (isAnimated) {
    return (
      <AnimatedButton {...buttonProps} variant={variant} style={style} isAnimated>
        {label || children}
        <span style={{ color: animationStyle.color }}>
          {
            loading || status === 'loading'
              ? <CircularProgress {...animatedButtonProps} color="inherit" />
              : <CheckIcon {...animatedButtonProps} color="inherit" />
          }
        </span>
      </AnimatedButton>
    );
  }

  return (
    <StyledButton
      {...buttonProps}
      style={style}
      theme={theme}
      variant={variant}
      isAnimated={isAnimated}
    >
      {children}
    </StyledButton>
  );
};

DangerButton.defaultProps = {
  variant: 'outlined',
  status: 'pristine',
  loading: false,
  success: false,
};

DangerButton.propTypes = {
  disabled: bool,
  initialize: bool,
  label: string,
  loading: bool,
  status: string,
  success: bool,
  variant: string,
};

export default DangerButton;
