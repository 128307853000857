import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { components } from 'react-select';
import Typography from '@material-ui/core/Typography';
import Box from 'shared/styleguide/atoms/Box';

export const Option = ({ app, rest }) => {
  return (
    <Fragment>
      <Box justify="space-between">
        <span>
          <Typography variant="subtitle2" color="textPrimary" component="span">
            {app.label || app.name}
          </Typography>
          <Typography variant="caption" color="textSecondary" component="span" style={{ paddingLeft: 8 }}>
            ID: {app.id}
          </Typography>
        </span>
      </Box>
    </Fragment>
  );
};

Option.propTypes = {
  app: object,
  rest: object,
};

const CustomOption = (props) => {
  const { innerProps, data: app, ...rest } = props;

  return (
    <components.Option {...props}>
      <Option app={app} rest={rest} />
    </components.Option>

  );
};

CustomOption.propTypes = {
  data: object.isRequired,
  innerProps: object.isRequired,
};

export default CustomOption;
