import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { Divider, Typography } from '@material-ui/core';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

class KeyValEdit extends Component {
  static propTypes = {
    data: PropTypes.array,
    handleChange: PropTypes.func,
  };

  addItem = () => {
    this.props.handleChange([
      ...this.props.data,
      { label: '', value: '', key: (new Date()).getMilliseconds() },
    ]);
  };

  removeItem = (index) => {
    this.props.handleChange([
      ...this.props.data.slice(0, index),
      ...this.props.data.slice(index + 1),
    ]);
  };

  updateItemLabel(index, label) {
    this.props.handleChange([
      ...this.props.data.slice(0, index),
      { ...this.props.data[index], label },
      ...this.props.data.slice(index + 1),
    ]);
  }

  updateItemValue(index, value) {
    this.props.handleChange([
      ...this.props.data.slice(0, index),
      { ...this.props.data[index], value },
      ...this.props.data.slice(index + 1),
    ]);
  }

  renderValueField(item, index) {
    switch (item.type) {
      case 'password':
        return (
          <div style={{ display: 'inline-block', width: 256 }}>
            <TextField
              margin="dense"
              placeholder="Value"
              type="password"
              defaultValue={item.value}
              onChange={(event, value) => this.updateItemValue(index, value)}
            />
          </div>
        );
      default:
        return (
          <TextField
            margin="dense"
            id="value"
            placeholder="Value"
            defaultValue={item.value}
            onChange={(event, value) => this.updateItemValue(index, value)}
            type={item.type}
          />
        );
    }
  }

  render() {
    const items = this.props.data.map((item, index) => (
      <Box key={item.key || item.label} margin={{ top: 'small', bottom: 'small' }}>
        <Box direction="row" align="center" justify="space-between">
          <TextField
            variant="outlined"
            margin="dense"
            id="label"
            placeholder="Label"
            defaultValue={item.label}
            onChange={(event, value) => this.updateItemLabel(index, value)}
          />
          <IconButton onClick={() => this.removeItem(index)}>
            <RemoveIcon />
          </IconButton>
        </Box>
        <Box>
          {this.renderValueField(item, index)}
        </Box>
        <Divider variant="fullWidth" css={{ backgroundColor: 'aliceBlue', marginTop: 8 }} />
      </Box>
    ));

    return (
      <div>
        <Typography variant="caption" gutterBottom component="div">
          You can add an &quot;instructions&quot; item, which will always appear at the top of list
        </Typography>
        {items}
        <Button variant="outlined" onClick={this.addItem} startIcon={<AddIcon />}>
          Add Row
        </Button>
      </div>
    );
  }
}

export default KeyValEdit;
