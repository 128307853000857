import React, { useCallback } from 'react';
import { object, bool, string } from 'prop-types';
import { LazyLog } from 'react-lazylog';
import moment from 'moment';

import { useLocation } from 'react-router-dom';

import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Typography, IconButton, AccordionDetails, AccordionSummary, Accordion,
} from '@material-ui/core';

import { useMergeState } from 'shared/hooks/useMergeState';
import { ErrorText } from 'shared/styleguide/typography';
import Empty from 'shared/styleguide/atoms/Empty';
import Loading from 'shared/styleguide/atoms/Loading';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import * as STATUS from 'shared/modules/status/redux/constants';

import { useLogs } from '../hooks/useLogs';
import LogMeta from './LogMeta';

export const LogItem = ({
  integrationName, defaultExpanded, params, job, type, __storybookMocks,
}) => {
  const { state, mergeState } = useMergeState({ expanded: defaultExpanded || false });
  const { expanded } = state;
  const location = useLocation();

  const unmemoizedLogs = useLogs(job, type, expanded);
  const tempLogs = useCallback(unmemoizedLogs, [unmemoizedLogs, job, expanded]);

  const logs = __storybookMocks?.[type]?.logs ? {
    ...tempLogs,
    ...__storybookMocks[type].logs,
  } : { ...tempLogs };

  const {
    started, link, displayStatus, formattedLogs, status, fetchedOnce, errors,
  } = logs;

  const initialLoading = !fetchedOnce && ['pristine', 'loading'].includes(status);

  return (
    <Accordion data-testid={`logitem-${type}-${job.id}`} expanded={expanded} onChange={() => mergeState({ expanded: !expanded })} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
        <Box flex={1} justify="space-between" direction="row">
          <Box direction="row" align="center" gap="small">
            {
              !!integrationName && <Typography data-testid="integration-name" variant="subtitle1">{integrationName}</Typography>
            }
            <Typography data-testid="started-date" variant="subtitle1" color="textSecondary">
              {!!started && moment.utc(started).format('D MMM, YYYY HH:mm:ss')}
            </Typography>
            <Box margin={{ left: 'small' }}>
              <CopyButton text={`${window.location.origin}${location.pathname}${link}`} message="Copied Log URL">
                <IconButton
                  size="small"
                  variant="contained"
                  onClick={(e) => { e.stopPropagation(); }}
                >
                  <ShareIcon fontSize="small" />
                </IconButton>
              </CopyButton>
            </Box>
          </Box>
          <Box direction="row" gap="small">
            <Box>
              <GhostTag color={STATUS.tagMap[displayStatus]}>{displayStatus}</GhostTag>
            </Box>
            {
              (status === 'loading' && formattedLogs) && (
                <Box>
                  <Loading data-testid="summary-loading" size="small" margin={{}} />
                </Box>
              )
            }
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails data-testid={`logdetails-${type}-${job.id}`}>
        <Box flex={1}>
          {
            initialLoading && (
              <Box margin={{ bottom: 'small' }} align="center" flex={1}>
                <Loading data-testid="details-loading" text="Fetching logs" margin={{}} />
              </Box>
            )
          }
          {
            status === 'success' && !formattedLogs && (
              <Box margin={{ top: 'small' }} flex={1}>
                <Empty>No logs currently available for this deployment.</Empty>
              </Box>
            )
          }
          {
            ['success', 'failed'].includes(status) && errors && (
              <Box align="center" margin={{ bottom: 'medium' }} flex={1}>
                <ErrorText>{errors}</ErrorText>
              </Box>
            )
          }
          {
            (!initialLoading && formattedLogs) && (
              <Box flex={1} data-testid={`logdata-${type}-${job.id}`}>
                <LogMeta
                  logs={logs}
                />
                <Box flex={1}>
                  <LazyLog
                    extraLines={1}
                    enableSearch
                    text={formattedLogs}
                    height={400}
                    caseInsensitive
                    follow={!params?.line}
                    scrollToLine={params?.line}
                    selectableLines
                  />
                </Box>
              </Box>
            )
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

LogItem.propTypes = {
  defaultExpanded: bool,
  integrationName: string,
  job: object,
  logItem: object,
  params: object,
  type: string,
};

export default LogItem;
