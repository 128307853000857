import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { Typography, useTheme } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import Select from 'shared/styleguide/atoms/Select/Select';
import Box from 'shared/styleguide/atoms/Box';

import { NOTICE_COLORS } from '../../constants';

const EditView = ({ templateState, onSetTemplateState }) => {
  const [noticeColor, setNoticeColor] = useState(templateState.noticeConfig.class || '');
  const handleChange = (field) => (e) => {
    onSetTemplateState({ ...templateState, [field]: e.target.value });
  };

  const handleChangeNoticeText = (e) => {
    onSetTemplateState({
      ...templateState,
      noticeConfig: {
        ...templateState.noticeConfig,
        text: e.target.value,
        class: templateState.noticeConfig.class || NOTICE_COLORS.gray,
      },
    });
  };

  const handleChangeNoticeColor = (value) => {
    setNoticeColor(value);
    onSetTemplateState({
      ...templateState,
      noticeConfig: {
        ...templateState.noticeConfig,
        class: value,
      },
    });
  };

  const noticeColorOptions = Object.values(NOTICE_COLORS)
    .map((a) => ({
      label: a,
      value: a,
    }));

  const theme = useTheme();

  return (
    <Box direction="row" justify="space-between" align="flex-start" gap="large" padding={{ top: 'medium' }}>
      <Box flex={1}>
        <Box direction="row" gap="xsmall">
          <Typography variant="subtitle1" color="textSecondary">
            <strong>TEMPLATE ID:</strong>
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">{templateState.id}</Typography>
        </Box>
        <Box>
          {
            templateState.subject && (
              <WideTextField
                width={{ width: '100%' }}
                value={templateState.subject}
                onChange={handleChange('subject')}
                placeholder="Subject"
              />
            )
          }
        </Box>
        <Box direction="row" gap="small" justify="space-between" align="center" padding={{ bottom: 'small' }}>
          <Box flex={1}>
            <WideTextField
              value={templateState.noticeConfig.text}
              onChange={handleChangeNoticeText}
              placeholder="Notice Text"
            />
          </Box>
          <Box style={{ width: 250 }}>
            <Typography variant="caption" gutterBottom>Notice color</Typography>
            <Select
              key="noticeColorSelect"
              options={noticeColorOptions}
              onChange={(c) => handleChangeNoticeColor(c?.value || '')}
              value={noticeColor ? {
                label: (noticeColor || ''),
                value: (noticeColor || ''),
              } : ''}
              isClearable
            />
          </Box>
        </Box>
        <TextareaAutosize
          css={{
            padding: '12px 10px',
            backgroundColor: 'white',
            color: theme.palette.text.primary,
            ...theme.typography.body2,
          }}
          minRows={8}
          onChange={handleChange('value')}
          value={templateState.value}
        />
      </Box>
      <Box>
        <Typography variant="subtitle1" color="textSecondary">
          <strong>AVAILABLE VARIABLES:</strong>
        </Typography>
        {
          templateState.variables ? templateState.variables
            .filter((v) => {
              return !['NOTICE_TEXT', 'NOTICE_COLOR'].includes(v.id);
            })
            .map((variable) => (
              <WideTextField
                key={variable.id}
                value={variable.id}
                placeholder={variable.label}
              />
            )) : (<Typography variant="subtitle1" color="textSecondary">No Variables Available</Typography>)
        }
      </Box>
    </Box>
  );
};

EditView.propTypes = {
  onSetTemplateState: func.isRequired,
  templateState: object.isRequired,
};

export default EditView;
