import React from 'react';
import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import SupportLink from 'shared/modules/support/Link';

const UnavailableCopy: React.FC = () => {
  return (
    <Box fullWidth direction="column" align="center">
      <Box fullWidth align="center" margin={{ top: 'large', bottom: 'large' }}>
        <Typography variant="body1">
          This is a paid feature that you can have enabled to integrate with your identity provider.
        </Typography>
        <Typography variant="body1">
          <SupportLink>
            Speak with an account manager
          </SupportLink> about enabling SAML integration.
        </Typography>
      </Box>
    </Box>
  );
};

export default UnavailableCopy;
