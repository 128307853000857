import React from 'react';
import { array } from 'prop-types';

import { Typography } from '@material-ui/core';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import ProgressView from 'shared/styleguide/molecules/ProgressView';
import { truncateDecimals } from 'shared/utils';

const Volumes = ({ volumes }) => {
  return (
    <Box as={Paper} padding="small" margin={{ top: 'xsmall' }}>
      <Box direction="column" margin={{ bottom: 'small' }} gap="large">
        {
          volumes.map((volume) => (
            <Box key={volume.volumeId}>
              <Box direction="row" gap="xsmall">
                <Typography variant="h5" component="div" gutterBottom><strong>ID:</strong></Typography>
                <Typography variant="h5" component="div" gutterBottom>{volume.volumeId}</Typography>
              </Box>
              <Box>
                <ProgressView
                  value1={`${truncateDecimals(volume.used, 2)}`}
                  value2={`${truncateDecimals(volume.size, 2)} GiB`}
                  percentage={(volume.used / volume.size) * 100}
                />
              </Box>
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};

Volumes.propTypes = {
  volumes: array.isRequired,
};

export default Volumes;
