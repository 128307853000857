import React from 'react';
import { Typography } from '@material-ui/core';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { localizeCurrency } from 'shared/utils';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { planIdToName } from './constants';
import { PlanData } from './types';

export const PlanSummary = ({
  planData,
  isSwitchingPlan,
  selectedPlanId,
  isFetchingSubscription,
  subscriptionPlanId,
  switchPlan,
  setOptimisticLimitsData,
}: {
  planData: PlanData;
  isSwitchingPlan: boolean;
  selectedPlanId: string;
  isFetchingSubscription: boolean;
  subscriptionPlanId: string;
  switchPlan: () => void;
  setOptimisticLimitsData: (limits: PlanData['limits']) => void;
}): JSX.Element => {
  return (
    <>
      <div css={(theme) => ({
        borderBottom: `1px dashed ${theme.color400}`, paddingBottom: remMapper('xxsmall'), display: 'flex', justifyContent: 'space-between', marginBottom: remMapper('small'),
      })}
      >
        <div><strong>Plan Total</strong></div>
        <div><strong>{localizeCurrency(planData.planCost)}</strong></div>
      </div>
      <div css={(theme) => ({
        borderBottom: `1px dashed ${theme.color400}`, paddingBottom: remMapper('xxsmall'), display: 'flex', justifyContent: 'space-between', marginBottom: remMapper('small'),
      })}
      >
        <div><strong>Additional Upgrades</strong></div>
        <div><strong>{localizeCurrency(planData.additionalCost)}</strong></div>
      </div>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div><Typography variant="h3"><strong>Total {planData.billingFrequency}</strong></Typography></div>
        <div><Typography variant="h3"><strong>{localizeCurrency(planData.totalCost)}</strong></Typography></div>
      </div>
      <div css={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: remMapper('medium'),
      }}
      >
        {(isSwitchingPlan || isFetchingSubscription) && (
        <LoadingDots />
        )}
        {(selectedPlanId && selectedPlanId !== subscriptionPlanId) && !isSwitchingPlan && !isFetchingSubscription && (
        <Button
          disabled={!planData.ableToSwitchPlans}
          data-testid="switch-plan-button"
          variant="contained"
          color="secondary"
          css={{
            'height': '40px',
            'width': '256px',
          }}
          onClick={() => {
            switchPlan();
            setOptimisticLimitsData(planData.limits);
          }}
        > Switch to {planIdToName[selectedPlanId]}
        </Button>
        )}
      </div>
    </>
  );
};
