import React from 'react';
import { object } from 'prop-types';

import {
  Typography, FormControl, FormControlLabel, Radio, RadioGroup, TextField,
} from '@material-ui/core';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Checkbox from 'shared/styleguide/atoms/Switches/Checkbox';

import { pathPresetLabelsHash } from '../constants';
import { getErrorMessages } from '../../../utils';

const Path = ({
  values,
  form,
}) => {
  const presets = Object.entries(pathPresetLabelsHash).map(([preset, params]) => {
    let withExclusions;
    let excludedItems;
    let padding = null;

    if (params.exclusions) {
      withExclusions = <span css={{ fontSize: '.8rem' }}>&nbsp;(with exclusions)</span>;

      excludedItems = (
        <Typography variant="caption" color="textSecondary">
          excluding admin-ajax.php, load-styles.php, load-scripts.php
        </Typography>
      );

      padding = { top: 'xxsmall', bottom: 'xxsmall' };
    }

    return (
      <Box key={preset} direction="row" align="center" justify="flex-start" padding={padding}>
        <Radio value={preset} />
        <Box direction="column">
          <Typography color="textPrimary">
            {params.name}
            {withExclusions}
          </Typography>
          {excludedItems}
        </Box>
      </Box>
    );
  });

  return (
    <Box direction="column" flex={1}>
      <Box padding={{ bottom: 'xsmall' }}>
        <Typography gutterBottom variant="h5" color="initial"><strong>Path</strong></Typography>
      </Box>
      <FormControl>
        <RadioGroup value={values?.preset || form.initialValues?.preset} onChange={(e) => form.handleChange('preset', e.target.value)}>
          <Box direction="column">
            {presets}
            <Box direction="row" align="flex-start" justify="flex-start" padding={{ top: 'xxsmall' }}>
              <Radio value="custom" />
              <Box>
                <TextField
                  id="path"
                  name="path"
                  autoComplete="off"
                  onChange={(e) => form.handleChange('path', e.target.value)}
                  value={values.path}
                  variant="outlined"
                  margin="dense"
                  css={{ marginTop: 0 }}
                  error={Boolean(form.errors?.path)}
                  helperText={form.errors?.path ? getErrorMessages(form.errors.path).join('; ') : null}
                  placeholder="/example-path"
                />
                {
                  (values.preset === 'custom') && (
                    <RadioGroup value={values.match} onChange={(e) => form.handleChange('match', e.target.value)} row>
                      <FormControlLabel labelPlacement="end" value="starts-with" control={<Radio />} label="Starts with" />
                      <FormControlLabel labelPlacement="end" value="equals" control={<Radio />} label="Equals" />
                    </RadioGroup>
                  )
                }
              </Box>
            </Box>
          </Box>
        </RadioGroup>
        <Box />
      </FormControl>
      {
        form.errors?.pathSection && (
          <Box align="center" margin={{ top: 'small' }}>
            <ErrorText>{form.errors.pathSection}</ErrorText>
          </Box>
        )
      }
      {
        form.errors?.match && (
          <Box align="center" margin={{ top: 'small' }}>
            <ErrorText>{form.errors.match}</ErrorText>
          </Box>
        )
      }
    </Box>
  );
};

Path.propTypes = {
  form: object,
  values: object,
};

export default Path;
