import React from 'react';
import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import { DNS_DOCS } from 'shared/utils/constants';
import ArticleList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';

const articles = [
  {
    id: 1,
    url: 'https://support.pagely.com/hc/en-us/articles/200237390-How-Do-I-Point-My-Domain-to-Pagely-',
    title: 'How Do I Point My Domain to Pagely?',
    body: 'There are 3 ways to point your domain traffic to Pagely. These are via PressDNS, CNAME record, and A record/IP address.',
  },
  {
    id: 2,
    url: 'https://support.pagely.com/hc/en-us/articles/213428437-Using-PressDNS-',
    title: 'Using PressDNS™',
    body: 'To use PressDNS, you’ll need to point your nameservers to the correct location. To put it simply, your nameservers say where to find your DNS records.',
  },
  {
    id: 3,
    url: DNS_DOCS.url,
    title: 'DNS Propagation',
    body: DNS_DOCS.text,
  },
];

const Footer = () => (
  <Box>
    <Typography variant="h3" gutterBottom>Common DNS Questions</Typography>
    <ArticleList
      topic="dns"
      columns={3}
      boxProps={{
        row: true,
        wrap: 'wrap',
      }}
      articles={articles}
    />
  </Box>
);

export default Footer;
