import React, {
  useEffect, useCallback, useState, Fragment,
} from 'react';
import {
  func, bool, array,
} from 'prop-types';
import moment from 'moment-timezone';
import {
  Typography,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import { formatDurationFromSeconds } from 'shared/utils';

import { RECENT_HOURS_LIMIT } from '../../../constants';
import DiskWarningBanner from '../../../components/DiskWarningBanner';

const RecentModificationsMessage = ({ recentDiskJobs, hasRemainingTime, onSetHasRemainingTime }) => {
  const [countdown, setCountdown] = useState(null);

  const readyTime = recentDiskJobs?.[0]?.completed ? moment(recentDiskJobs[0].completed).add(RECENT_HOURS_LIMIT, 'hours').tz('UTC') : null;

  const localReadyDate = !!readyTime && moment(readyTime).local().format('h:ma, MMMM Do, YYYY');

  const setModifyTimeAndCountdown = (readyTime) => {
    const diff = readyTime ? moment().unix() - readyTime.unix() : 0;
    if (diff < 0) {
      onSetHasRemainingTime(diff < 0);
      setCountdown(formatDurationFromSeconds(diff));
    } else {
      onSetHasRemainingTime(false);
    }
  };

  const memoizedSetModifyTimeAndCountdown = useCallback(setModifyTimeAndCountdown, []);

  useEffect(() => {
    if (readyTime && !hasRemainingTime) {
      // set immediately
      memoizedSetModifyTimeAndCountdown(readyTime);
    }

    const interval = setInterval(() => {
      if (readyTime) {
        memoizedSetModifyTimeAndCountdown(readyTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [readyTime, hasRemainingTime, memoizedSetModifyTimeAndCountdown]);

  if (!hasRemainingTime) {
    return null;
  }

  return (
    <Fragment>
      <DiskWarningBanner>
        <Box>
          <Typography variant="h5" component="p" align="center" gutterBottom>
            A disk modification was made within the past {RECENT_HOURS_LIMIT} hours.
          </Typography>
        </Box>
        <Box margin={{ bottom: 'small' }}>
          <Typography variant="h5" component="p" align="center">
            You can perform another modification at: {localReadyDate} (local time)
          </Typography>
        </Box>
        <Typography variant="h5" component="p" align="center">
          Time remaining: {countdown}
        </Typography>
      </DiskWarningBanner>
    </Fragment>
  );
};

RecentModificationsMessage.propTypes = {
  hasRemainingTime: bool,
  onSetHasRemainingTime: func,
  recentDiskJobs: array,
};

export default RecentModificationsMessage;
