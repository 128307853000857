import axios from 'axios';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as SUPPORT from '../constants';

export const resetTicket = () => ({
  type: SUPPORT.SUPPORT_TICKET_RESET,
});

export const resetAttachments = () => ({
  type: SUPPORT.SUPPORT_ATTACHMENTS_RESET,
});

export const resetAttachmentErrors = () => ({
  type: SUPPORT.SUPPORT_ATTACHMENTS_RESET_ERRORS,
});

export const createTicket = (data) => makeApiAction(
  ({ api }) => api().post('/support/tickets', data),
  SUPPORT.SUPPORT_CREATE_TICKET_REQUEST,
  SUPPORT.SUPPORT_CREATE_TICKET_SUCCESS,
  SUPPORT.SUPPORT_CREATE_TICKET_FAILURE,
);

export const uploadAttachments = (file) => makeApiAction(
  () => axios.post(`${process.env.REACT_APP_ZENDESK_UPLOADS_URL}?filename=${file.name}`, file, {
    headers: {
      'Content-Type': `${file.type}`,
    },
  }),
  SUPPORT.SUPPORT_UPLOAD_ATTACHMENTS_REQUEST,
  SUPPORT.SUPPORT_UPLOAD_ATTACHMENTS_SUCCESS,
  SUPPORT.SUPPORT_UPLOAD_ATTACHMENTS_FAILURE,
  { file },
);

export const deleteAttachment = (id) => ({
  type: SUPPORT.SUPPORT_DELETE_ATTACHMENT,
  data: id,
});

export const getTickets = (accountId) => makeApiAction(
  ({ api }) => api().get(`/support/tickets?accountId=${accountId}`),
  SUPPORT.SUPPORT_GET_TICKETS_REQUEST,
  SUPPORT.SUPPORT_GET_TICKETS_SUCCESS,
  SUPPORT.SUPPORT_GET_TICKETS_FAILURE,
);

export const getTicket = (ticketId) => makeApiAction(
  ({ api }) => api().get(`/support/tickets/${ticketId}`),
  SUPPORT.SUPPORT_GET_TICKET_REQUEST,
  SUPPORT.SUPPORT_GET_TICKET_SUCCESS,
  SUPPORT.SUPPORT_GET_TICKET_FAILURE,
);

export const updateTicket = (ticketId, data) => makeApiAction(
  ({ api }) => api().patch(`/support/tickets/${ticketId}`, data),
  SUPPORT.SUPPORT_UPDATE_TICKET_REQUEST,
  SUPPORT.SUPPORT_UPDATE_TICKET_SUCCESS,
  SUPPORT.SUPPORT_UPDATE_TICKET_FAILURE,
  { data },
);

export const getBannedPlugins = () => makeApiAction(
  ({ api }) => api().get('/support/banned-modules'),
  SUPPORT.SUPPORT_GET_BANNED_PLUGINS_REQUEST,
  SUPPORT.SUPPORT_GET_BANNED_PLUGINS_SUCCESS,
  SUPPORT.SUPPORT_GET_BANNED_PLUGINS_FAILURE,
);

export const addBannedPlugins = (data) => makeApiAction(
  ({ api }) => api().post('/support/banned-modules', data),
  SUPPORT.SUPPORT_ADD_BANNED_PLUGINS_REQUEST,
  SUPPORT.SUPPORT_ADD_BANNED_PLUGINS_SUCCESS,
  SUPPORT.SUPPORT_ADD_BANNED_PLUGINS_FAILURE,
);
