import React from 'react';
import { string, bool } from 'prop-types';

import {
  CheckCircleOutline, InfoOutlined, Warning,
} from '@material-ui/icons';
import { mdiAlertOutline } from '@mdi/js';
import { Typography, useTheme, SvgIcon } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

const InfoText = ({
  href = '', to = '', color = 'textSecondary', variant = 'body2', type = 'info', showIcon = true, noMargin = false, children, ...rest
}) => {
  const theme = useTheme();

  let linkProps = null;
  if (to || href) {
    linkProps = to ? { to } : { href };
  }

  const iconSize = variant === 'body2' ? 'small' : 'medium';

  const iconMap = {
    info: <InfoOutlined color="primary" fontSize={iconSize} />,
    success: <CheckCircleOutline color="secondary" fontSize={iconSize} />,
    warning: <SvgIcon style={{ color: theme.palette.warning.main }} fontSize={iconSize}><path d={mdiAlertOutline} /></SvgIcon>,
    danger: <Warning color="error" fontSize={iconSize} style={{ marginRight: 8 }} />,
  };

  return (
    <Box direction="row" margin={{ bottom: noMargin ? 0 : 'xsmall' }} justify="flex-start" gap="xxsmall" align="center" {...rest}>
      {
        showIcon && <Box>{iconMap[type]}</Box>
      }
      <Box css={{ marginTop: variant === 'body2' ? 1 : 0 }}>
        {
          (to || href) ? (
            <TextLink
              variant={variant}
              color={color}
              target="_blank"
              rel="noopener nofollow noreferrer"
              {...linkProps}
            >
              {children}
            </TextLink>

          ) : <Typography component="div" variant={variant} color={color}>{children}</Typography>
        }
      </Box>
    </Box>
  );
};

InfoText.propTypes = {
  color: string,
  href: string,
  noMargin: bool,
  showIcon: bool,
  to: string,
  type: string,
  variant: string,
};

export default InfoText;
