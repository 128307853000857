import React from 'react';
import {
  object, number, bool,
} from 'prop-types';
import { connect } from 'react-redux';

import { createRedirect } from '../../redux/redirects/actions';
import { from, to } from './utils';

import FormContent from './FormContent';

export const RedirectsItem = ({
  redirect, appId, updated,
}) => {
  const [toData, initialQueryMode] = to(redirect.ruleRewrite);
  const currentValues = {
    from: from(redirect.ruleMatch?.match),
    to: toData,
    enabled: redirect.enabled,
    statusCode: redirect.statusCode,
    description: redirect.description,
  };

  return (
    <FormContent
      appId={appId}
      currentValues={currentValues}
      mode="edit"
      redirect={redirect}
      initialQueryMode={initialQueryMode}
      updated={updated}
    />
  );
};

RedirectsItem.propTypes = {
  appId: number,
  redirect: object,
  updated: bool,
};

export default connect(
  null,
  {
    createRedirect,
  },
)(RedirectsItem);
