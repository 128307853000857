import React, { Fragment, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import cx from 'classnames';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import KebabIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';

import SystemStatus from 'shared/modules/support/routes/Status/SystemStatus';
import { mobileStyles, mobileSidebarWidth } from './styles';
import { Sidebar } from '.'; //eslint-disable-line
import ErrorBoundary from '../../ErrorBoundary';

const MobileNav = (props) => {
  const mobileClasses = makeStyles(mobileStyles)();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Fragment>
      <nav style={{ width: mobileSidebarWidth }}>
        <IconButton onClick={handleDrawerToggle}>
          <KebabIcon />
        </IconButton>
        <Sidebar {...props} />
      </nav>
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        className={cx(mobileClasses.drawer, {
          [mobileClasses.drawerOpen]: mobileOpen,
          [mobileClasses.drawerClose]: !mobileOpen,
        })}
        classes={{
          paper: cx({
            [mobileClasses.drawerOpen]: mobileOpen,
            [mobileClasses.drawerClose]: !mobileOpen,
          }),
        }}
      >
        <ListItemIcon>
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </ListItemIcon>
        <Sidebar {...props} isMobile />
        <ErrorBoundary>
          <SystemStatus />
        </ErrorBoundary>
      </Drawer>
    </Fragment>
  );
};

MobileNav.propTypes = {
  account: object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.object,
};

export default MobileNav;
