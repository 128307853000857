import React, { ReactElement, useEffect, useState } from 'react';

import type { App } from 'shared/types/App';
import Box from 'shared/styleguide/atoms/Box';
import { isArray, isObject } from 'shared/utils/types';
import { jobTypes, SUCCESS } from 'shared/modules/status/redux/constants';
import Loading from 'shared/styleguide/atoms/Loading';

import {
  ACTIVE, ACTIVE_MODIFYING, INACTIVE, PENDING, PENDING_INACTIVE,
} from './constants';
import Disabled from './Disabled';
import Partial from './Partial';
import Enabled from './Enabled';

interface Props {
  app: App;
  state: Record<string, any>;
  mergeState: any;
  fsaRecord: Record<string, any>;
  fsaJob: any;
  validationRecords: any;
  dnsJobs: Record<string, any>;
  onFetchAllValidations: any;
  onDisableFsa: any;
  onEnableFsa: any;
  onGetTtlsForZones: any;
  fetchStatusJob: any;
}

export const Display = ({
  app,
  state,
  mergeState,
  fsaRecord,
  fsaJob,
  validationRecords,
  dnsJobs,
  onFetchAllValidations,
  onEnableFsa,
  onDisableFsa,
  onGetTtlsForZones,
  fetchStatusJob,
}: Props): ReactElement => {
  const { fsaSetup, fsaDisable, fsaSetupCloudflare } = jobTypes;

  const getIsInitialComplete = () => {
    let complete = false;
    const jobIsFinished = fsaJob?.overallStatus === SUCCESS;
    switch (fsaJob?.type) {
      case fsaSetupCloudflare:
      case fsaSetup:
        complete = fsaRecord?.status === ACTIVE && jobIsFinished;
        break;
      case fsaDisable:
        complete = fsaRecord?.status === INACTIVE && jobIsFinished;
        break;
      default:
        break;
    }

    return complete;
  };

  const [complete, setComplete] = useState(getIsInitialComplete());

  let detailArray = [];
  if (isArray(fsaJob?.detail)) {
    detailArray = fsaJob?.detail;
  } else if (isObject(fsaJob?.detail)) {
    detailArray = Object.values(fsaJob?.detail);
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (fsaJob?.overallStatus === SUCCESS && !complete) {
      // wait 3 seconds then hide the status display
      const successTimerId = setTimeout(
        () => setComplete(true),
        3000,
      );
      return () => clearTimeout(successTimerId);
    }

    if (fsaJob?.overallStatus !== SUCCESS && complete) {
      setComplete(false);
    }
  }, [fsaJob, complete]);

  // on job create, there's no detail (empty array), so we have to wait for an "update" for it to be populated (object)
  const jobHasDetail = detailArray.length > 0;

  // To determine what will be displayed, the status job takes precedence over the fsa record
  // because it may be more current, since pusher will send updates if something is initiated
  // after the fsa record was initially fetched.
  let currentStatus = null;
  if (fsaJob) {
    const jobIsFinished = (fsaJob?.overallStatus === SUCCESS) && complete;

    switch (fsaJob.type) {
      case fsaSetupCloudflare:
      case fsaSetup:
        if (jobIsFinished) {
          currentStatus = ACTIVE;
        } else {
          currentStatus = fsaJob.detail?.modifyDomains ? ACTIVE_MODIFYING : PENDING;
        }
        break;
      case fsaDisable:
        currentStatus = jobIsFinished ? INACTIVE : PENDING_INACTIVE;
        break;
      default:
        break;
    }
  } else if (fsaRecord) {
    currentStatus = fsaRecord.status;
  } else {
    currentStatus = INACTIVE;
  }

  if (currentStatus === INACTIVE) {
    return (
      <Disabled
        app={app}
        state={state}
        onEnableFsa={onEnableFsa}
      />
    );
  }

  if (currentStatus === ACTIVE) {
    return (
      <Enabled
        app={app}
        state={state}
        mergeState={mergeState}
        onDisableFsa={onDisableFsa}
        onGetTtlsForZones={onGetTtlsForZones}
      />
    );
  }

  if (fsaJob?.overallStatus !== PENDING && jobHasDetail && !complete) {
    return (
      <Partial
        app={app}
        state={state}
        fsaRecord={fsaRecord}
        job={fsaJob}
        validationRecords={validationRecords}
        dnsJobs={dnsJobs}
        fetchStatusJob={fetchStatusJob}
        onFetchAllValidations={onFetchAllValidations}
        onEnableFsa={onEnableFsa}
        onDisableFsa={onDisableFsa}
        mergeState={mergeState}
      />
    );
  }

  return (
    <Box padding={{ top: 'small', bottom: 'medium' }}>
      <Loading margin={{ top: null }} />
    </Box>
  );
};

export default Display;
