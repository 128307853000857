/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import {
  Typography,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import { isArray } from 'shared/utils/types';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import SupportLink from 'shared/modules/support/Link';
import InfoText from 'shared/styleguide/molecules/InfoText';
import { DNS_DOCS } from 'shared/utils/constants';
import { PCDN_REGEX } from 'shared/modules/dns/constants';

import { remMapper } from 'shared/styleguide/theme/spacing';
import ValidationItem from './ValidationItem';
import { sortValidations } from '../utils';

const Validate = ({
  domains, jobs,
  setView, setOpen, selectedDomains,
}) => {
  const filteredDomains = domains.filter((d) => !d.fqdn?.match(PCDN_REGEX));
  const legacyValidations = [];
  filteredDomains
    .forEach((d) => {
      if (!d.validationRules?.records || !isArray(d.validationRules?.records)
      ) {
        legacyValidations.push(d.fqdn);
      }
    });

  if (legacyValidations.length > 0) {
    return (
      <Fragment>
        <DialogContent>
          <Box padding="large">
            <Typography variant="subtitle1" color="error" paragraph>One or more of your domains has legacy validation records.</Typography>
            <Typography variant="subtitle2" paragraph>Please <SupportLink>report this to support</SupportLink> to have it corrected. </Typography>
            <Typography variant="subtitle1">Domains requiring validation regeneration: [{legacyValidations.join('\n')}]</Typography>
          </Box>
        </DialogContent>
        <DialogActions />
      </Fragment>
    );
  }

  const { validations } = sortValidations(filteredDomains, selectedDomains);
  const validationsRequiringChanges = validations.filter((v) => !v.validated);

  return (
    <Fragment>
      <DialogContent>
        <Box css={(theme) => ({
          marginBottom: remMapper('xsmall'),
        })}
        >
          <strong>
            You have {validationsRequiringChanges.length} record{validationsRequiringChanges.length !== 1 ? 's' : ''} that require{validationsRequiringChanges.length === 1 ? 's' : ''} changes.
          </strong>
        </Box>
        <Typography variant="body1" color="textSecondary">Please visit your domain registrar (for nameserver changes) or DNS provider (for all other changes) and configure the DNS records to match these values</Typography>
        <div css={{
          marginTop: remMapper('small'),
          marginLeft: '-2px',
        }}
        >
          <InfoText href={DNS_DOCS.url}>{DNS_DOCS.text}</InfoText>
        </div>
        <Box
          css={css`
          min-height: 300px;
          overflow: auto;
        `}
          direction="column"
          padding="xsmall"
          gap="small"
        >
          {
            validations
              .map((domain) => (
                <ValidationItem
                  domain={domain}
                  key={`${domain.domainId}-${domain.displayName}`}
                  job={jobs?.[domain.jobId]}
                />
              ))
          }
        </Box>

      </DialogContent>
      <DialogActions>
        <Box direction="row" justify="space-between" align="center" flex={1} padding={{ left: 'xsmall', right: 'xsmall' }}>
          <span>
            <Button
              color="default"
              variant="outlined"
              onClick={() => setView('select')}
            >
              Select Domains
            </Button>
          </span>
          <span>
            <Button
              color="default"
              variant="outlined"
              onClick={() => {
                setView('select');
                setOpen(false);
              }}
            >
              Done
            </Button>
          </span>
        </Box>
      </DialogActions>
    </Fragment>
  );
};

export default Validate;
