/* eslint-disable react/prop-types */
import React from 'react';
import { array } from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backup from './Backup';

const BackupsList = ({ backups }) => {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Created</TableCell>
            <TableCell align="center">Posts</TableCell>
            <TableCell align="center">Plugins</TableCell>
            <TableCell align="center">Themes </TableCell>
            <TableCell align="right">Backup Links</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            backups
              // filter out backups that don't have any files
              .filter((r) => (r.uid !== 'newstyle' && r.sqlSize !== 0 && r.fileSize !== 0))
              // put them in newest-first order
              .reverse()
              .map((backup) => (
                <Backup key={backup.updated} backup={backup} />
              ))
}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BackupsList.propTypes = {
  backups: array.isRequired,
};

export default BackupsList;
