import React, { Fragment, useEffect } from 'react';
import {
  Paper, Typography, Divider, Checkbox,
} from '@material-ui/core';

import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import InfoText from 'shared/styleguide/molecules/InfoText';
import DestructiveAction from 'shared/styleguide/molecules/DestructiveAction';
import Banner from 'shared/styleguide/molecules/Banner';
import { DNS_DOCS } from 'shared/utils/constants';
import MDView from 'shared/styleguide/atoms/Markdown/MDView';

import { App } from 'shared/types/App';
import { roundedUpDuration } from './utils';

interface Props {
  app: App;
  state: Record<string, any>;
  mergeState: any;
  onDisableFsa: any;
  onGetTtlsForZones: any;
}

const Enabled = ({
  app, state, mergeState, onDisableFsa, onGetTtlsForZones,
}: Props) => {
  useEffect(() => {
    onGetTtlsForZones();
  }, []);
  return (
    <Paper>
      {
        !app.active && (
          <Banner
            subheading={(
              <span>App must be active in order to disable Mercury Dynamic Site Acceleration.</span>
            )}
            type="info"
            showIcon
            dismissable
            padding="small"
            rounded="top"
          />
        )
      }
      <Box padding="small" justify="space-between" direction="row">
        <Box>
          <Box direction="row" align="center" gap="xsmall" padding={{ bottom: 'xsmall' }}>
            <Typography component="span" variant="h5">Status: </Typography>
            <span>
              <GhostTag color="green">Enabled</GhostTag>
            </span>
          </Box>
          <InfoText href="https://support.pagely.com/hc/en-us/articles/360051132192">What is Mercury Dynamic Site Acceleration?</InfoText>
        </Box>
        <Box>
          {
            // TODO: this is where the cool stats go
            // <Button>Set Security Policy</Button>
          }
          <DestructiveAction
            fullWidth
            onConfirm={() => onDisableFsa()}
            startIcon={<SettingsBackupRestoreIcon />}
            explainerText="You are disabling Mercury Dynamic Site Acceleration."
            secondaryText={(
              <Fragment>
                <Box direction="column" gap="medium">
                  <div>
                    This process may take up to 72 hours to complete, depending on domain DNS propagation times. Estimated propagation time: {roundedUpDuration(state.ttl)}
                  </div>
                  <div>
                    <InfoText href={DNS_DOCS.url}>{DNS_DOCS.text}</InfoText>
                  </div>
                  <div>
                    If you do not have an SSL certificate for any app domains installed or opt not to create a Lets Encrypt SSL certificate when disabling, <strong>those domains will not be accessible via https after conversion</strong>, but you can add an SSL certificate after conversion.
                  </div>
                  <div>
                    If you have any http to https redirects set up and no SSL certificate on any app domains, you should remove those now before proceeding to avoid downtime.
                  </div>
                  <div>
                    <Typography component="span" variant="caption">Recommended: </Typography>
                    <Box direction="row" justify="flex-start" align="center">
                      <Checkbox
                        checked={state.createLECertificate}
                        onChange={(e) => { mergeState({ createLECertificate: e.target.checked }); }}
                      />
                      <Typography component="div" variant="body2">Create a Lets Encrypt SSL certificate for all app domains.</Typography>
                    </Box>
                  </div>
                </Box>
                <Box padding={{ top: 'small', bottom: 'small' }}>
                  <Divider />
                </Box>
                <div>
                  <Typography component="span" variant="caption">Optional: </Typography>
                  <Box direction="row" justify="flex-start" align="center">
                    <Checkbox
                      checked={state.force}
                      onChange={(e) => { mergeState({ force: e.target.checked }); }}
                    />
                    <Typography component="div" variant="body2">Skip checks and force disable. <strong>WARNING: This may cause site downtime!</strong></Typography>
                  </Box>
                </div>
              </Fragment>
            )}
            loading={state.disable === 'loading'}
            disabled={!app.active || !state.hasLoadedTtl}
          >
            Disable
          </DestructiveAction>
        </Box>
      </Box>      {
        state.errors && (
          <Box padding={{ left: 'small', right: 'small', bottom: 'small' }}>
            <Typography component="span" color="error"><MDView value={state.errors} /></Typography>
          </Box>
        )
      }
    </Paper>
  );
};

export default Enabled;
