import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { object } from 'prop-types';
import AlertsDisplay from './routes/AlertsDisplay';

const Alerts = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/alerts`}
        render={(props) => (
          <AlertsDisplay
            {...props}
          />
        )}
      />
    </Switch>
  );
};

Alerts.propTypes = {
  match: object,
};

export default Alerts;
