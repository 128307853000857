import Profile from './Profile/Profile';
import Security from './Security';

export const routes = [
  {
    label: 'Profile',
    to: 'profile',
    match: new RegExp(/\/settings\/profile$/), // eslint-disable-line
    component: Profile,
  },
  {
    label: 'Security',
    to: 'security',
    match: new RegExp(/\/settings\/security$/), // eslint-disable-line
    component: Security,
  },
];
