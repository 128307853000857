import React from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import TrashIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

import Row from 'shared/styleguide/atoms/Row';
import TextField from 'shared/styleguide/atoms/Input/TextField';

import {
  updateItem, deleteItem,
} from '../redux/actions';

import { getOptions } from '../helpers';

const SubPropArrayInput = ({
  propLevels, value, schema, type, updateItem, deleteItem,
}) => {
  const optionsSubArr = getOptions(propLevels, schema, type);

  const optionsSubArrKeys = Object.keys(optionsSubArr);

  if (optionsSubArr === '') {
    // sub single value
    optionsSubArrKeys[0] = '';
  }

  let valueArray;
  if (!value) {
    valueArray = [''];
  } else {
    valueArray = value;
  }

  const items = valueArray.map((subVal, subValKey) => {
    return (
      <Row key={`subArrayInput-${subValKey}`}>
        <div style={{ width: '100%' }} key={`subselectval-${subValKey}`}>
          <TextField
            name={`subArrayInputVal.${propLevels}.${subValKey}`}
            key={`subArrayInputVal.${propLevels}.${subValKey}`}
            autoComplete="off"
            fullWidth
            multiline
            margin="dense"
            value={subVal}
            onChange={(e) => updateItem(`${propLevels}.${subValKey}`, e.currentTarget.value)}
          />
        </div>
        <div
          style={{
            width: 38, marginLeft: 10, paddingTop: 5, paddingBottom: 5,
          }}
        >
          <IconButton
            color="secondary"
            size="small"
            name="deleteSubArrayInput"
            onClick={() => deleteItem(`${propLevels}.${subValKey}`)}
          >
            <TrashIcon />
          </IconButton>
        </div>
      </Row>
    );
  });

  return items;
};

SubPropArrayInput.propTypes = {
  deleteItem: any,
  propLevels: any,
  schema: any,
  state: any,
  type: any,
  updateItem: any,
  value: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, {
  updateItem, deleteItem,
})(SubPropArrayInput);
