/* eslint-disable no-param-reassign */
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { getDeployEnvironment } from '../config';

export const mockClient = {
  notify: () => { },
  addMetadata: () => { },
  setUser: () => { },
  setContext: () => { },
  user: {},
  leaveBreadcrumb: () => {},
};

const initialize = () => {
  const releaseStage = getDeployEnvironment();

  const config = {
    apiKey: process.env.REACT_APP_BUGSNAG_KEY || '',
    appType: `mgmt_atomic_client-${process.env.BUILD}`,
    releaseStage,
    enabledReleaseStages: ['production', 'staging'],
    appVersion: process.env.TAG_VERSION || '0.0.1',
    onError: (event) => {
      if (event.request.url.includes('fe-atomic')) {
        return false;
      }

      if (event.errors[0].errorClass === 'AbortError') { // we don't need to see abort errors
        return false;
      }

      if (!event.metaData?.error) {
        if (event.originalError.toJSON) {
          event.addMetadata('error', 'json', event.originalError.toJSON());
        } else {
          event.addMetadata('error', 'error', event.originalError);
        }
      }

      if (event.originalError.isAxiosError) {
        // we're dealing with an API error here
        if ([401, 403, 404].includes(event.originalError.response.status)) {
          return false;
        }

        event.addMetadata('error', 'response', event.originalError.response);
        event.groupingHash = event.originalError.message;
        event.severity = 'info';

        // if its a 401 login error... don't show it.
        if (event.context === '/login' && event.groupingHash === 'Request failed with status code 401') {
          return false;
        }
      }
    },
  };

  if (process.env.NODE_ENV === 'test') {
    config.logger = null;
  }

  const bugsnagClient = Bugsnag.start({
    ...config,
    plugins: [new BugsnagPluginReact(React)],
  });

  return bugsnagClient;
};

export default initialize;
