import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from 'shared/styleguide/atoms/Box';
import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';

interface HorizontalNavigationProps {
  extraTabs?: any[];
  fallback?: string;
  match?: Record<string, any>;
  parentPath?: string;
  routes: any[];
}

const HorizontalNavigation = ({
  match, parentPath, routes, fallback = '', extraTabs,
}: HorizontalNavigationProps): JSX.Element => {
  const value = match?.params?.view ?? fallback ?? routes[0].to;

  return (
    <Box margin={{ bottom: 'mediumLarge' }}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        aria-label="support nav tabs"
        indicatorColor="primary"
      >
        {
          extraTabs?.map((tab) => (
            <Tab
              key={tab.label}
              component={AdapterLink as any}
              to={tab.to}
              value={tab.value}
              label={tab.label}
              disableRipple
            />
          ))
        }
        {
          routes?.map((child) => {
            return (
              <Tab
                key={child.to}
                component={AdapterLink as any}
                to={`${parentPath}/${child.to}`}
                value={(child.to).replace('/', '') ?? fallback}
                label={child.label}
                disableRipple
              />
            );
          })
        }
      </Tabs>
    </Box>
  );
};

export default HorizontalNavigation;
