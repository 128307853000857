import React from 'react';
import {
  number, string, bool, object, func, node,
} from 'prop-types';
import styled from '@emotion/styled';
import {
  Typography, IconButton, CircularProgress, Tooltip,
} from '@material-ui/core';

import ReloadIcon from '@material-ui/icons/Refresh';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import ProgressView from 'shared/styleguide/molecules/ProgressView';
import * as STATUS from 'shared/modules/status/redux/constants';
import colors from 'shared/styleguide/theme';
import { truncateDecimals } from 'shared/utils';

const THRESHOLD = 85;

const ReportsReloadButton = styled(IconButton)`
  padding: 5px;
`;

export const DiskProgressView = ({
  serverName,
  serverHostname,
  usage,
  storageMax,
  label,
  multiServer,
  match,
  serverId,
  hasReport,
  requestFullReport,
  reportJob,
  onHandleGetDiskReportJobStatus,
  diskReportsStatus,
  onSetDiskReportsStatus,
  isAdmin,
  diskJob,
  planTotalSize,
  newSize,
  purchaseSuccess,
  isUpgradable,
  modificationSize,
  modificationText,
}) => {
  const handleRequestReport = () => {
    onSetDiskReportsStatus('initial');
    requestFullReport(match.params.accountID, serverId);
  };
  const creatingDiskReport = STATUS.runningStatuses.includes(reportJob?.overallStatus);
  const fetchingNewReports = reportJob?.overallStatus === STATUS.SUCCESS && ['initial', 'loading'].includes(diskReportsStatus);

  const reportServerHostname = reportJob?.detail?.generating?.jenkinsParams?.Server;

  const generatingReport = ((creatingDiskReport || fetchingNewReports) && reportServerHostname === serverHostname);

  let isUpgrading = false;
  let upgradeSuccess = false;

  let maxSize = planTotalSize;

  const isRefreshed = !purchaseSuccess && diskJob;

  if (!isUpgradable || isRefreshed) {
    // storageMax is from stats, newSize is from billing
    // custom setups billing size likely to be wrong, so use stats
    maxSize = storageMax;
  }

  const usagePercentage = truncateDecimals((usage / maxSize) * 100, 1);

  const remainingPercentage = 100 - usagePercentage;

  const diskMessage = usagePercentage > 100
    ? `Disk space exceeded  (${truncateDecimals(-remainingPercentage, 2)}% over)`
    : `Disk space low (${truncateDecimals(remainingPercentage, 2)}% remaining)`;

  if (diskJob && diskJob.overallStatus !== STATUS.SUCCESS) {
    isUpgrading = true;
  }

  if (diskJob && diskJob.overallStatus === STATUS.SUCCESS) {
    upgradeSuccess = true;
    isUpgrading = false;
  }

  const bufferValue = (maxSize / newSize) * 100;

  return (
    <Box
      padding={{
        top: 'small', bottom: 'small',
      }}
    >
      <Box>
        <ProgressView
          value1={`${usage}`}
          value2={`${upgradeSuccess ? modificationSize : maxSize} GiB`}
          value3={isUpgrading && `${modificationText} to ${modificationSize} GiB`}
          buffer={isUpgrading && bufferValue}
          variant={isUpgrading ? 'buffer' : 'determinate'}
          percentage={upgradeSuccess ? (usage / modificationSize) * 100 : usagePercentage}
          threshold={isUpgrading ? 100 : THRESHOLD}
          errorText={`${diskMessage} - consider removing unused files or upgrading disk size`}
          showDanger={!isUpgrading}
        />
        <Box
          row
          gap="small"
          align="center"
          justify="space-between"
          margin={{
            top: 'xsmall', bottom: 'xsmall',
          }}
        >
          <Box>
            <Typography variant="h5" color="textSecondary" gutterBottom>
              <strong>Server:</strong> {serverName}{multiServer ? ` (${label})` : null}
            </Typography>
          </Box>
          <Box direction="row" gap="small" align="center">
            {
              hasReport && !generatingReport
              && (
                <Box margin={{ right: 'medium' }}>
                  <TextLink title={`.../${hasReport}/report/summary`} to={`${match.url}/${serverId}/${hasReport}/report/summary`}>
                    View latest report
                  </TextLink>
                </Box>
              )
            }
            <Box direction="row" align="center">
              <Box>
                <Button
                  onClick={handleRequestReport}
                  variant="outlined"
                  disabled={generatingReport}
                  endIcon={generatingReport && <CircularProgress color="secondary" size={20} />}
                >
                  {
                    generatingReport
                      ? 'Generating Report'
                      : 'Request Report'
                  }
                </Button>
              </Box>
              {
                (generatingReport || isAdmin)
                && (
                  <Box>
                    <Tooltip title="Check report creation job status" placement="top">
                      <ReportsReloadButton
                        onClick={() => onHandleGetDiskReportJobStatus()}
                      >
                        <ReloadIcon />
                      </ReportsReloadButton>
                    </Tooltip>
                  </Box>
                )
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DiskProgressView.propTypes = {
  diskJob: object,
  diskReportsStatus: string,
  hasReport: node,
  isAdmin: bool,
  isUpgradable: bool,
  label: string,
  match: object,
  modificationSize: number,
  modificationText: string,
  multiServer: bool,
  newSize: number,
  onHandleGetDiskReportJobStatus: func,
  onSetDiskReportsStatus: func,
  planTotalSize: number,
  purchaseSuccess: bool,
  reportJob: object,
  requestFullReport: func,
  serverHostname: string,
  serverId: number,
  serverName: string,
  storageMax: number,
  usage: number,
  user: string,
};

export default DiskProgressView;
