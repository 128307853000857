/* eslint-disable react/prop-types */
import React, { useState, Fragment } from 'react';
import { css } from '@emotion/react';
import {
  Typography,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import { remMapper } from 'shared/styleguide/theme/spacing';

// eslint-disable-next-line react/display-name
export default ({
  domains, setView, defaultValidations, app, triggerValidation, setSelectedDomains,
}) => {
  const [filter, setFilter] = useState('');
  const [selected, setSelected] = useState(defaultValidations);

  const handleChange = (event) => {
    setSelected({ ...selected, [event.target.name]: event.target.checked });
  };

  const selectAll = (event) => {
    if (event.target.checked) {
      const all = {};
      domains.forEach((d) => {
        all[d.domainId] = true;
      });
      setSelected(all);
    } else {
      setSelected({});
    }
  };

  const handleNext = () => {
    setSelectedDomains(Object.keys(selected).filter((key) => selected[key]));
    // queue up validations for all selected domains
    Object.entries(selected)
      .reduce((acc, [key, value]) => { if (value) acc.push(key); return acc; }, [])
      .forEach((domain) => {
        triggerValidation({ domainId: domain, appId: app.id });
      });
    // advance to next screen
    setView('validate');
  };

  const numberSelected = Object.values(selected).filter(Boolean).length;

  return (
    <Fragment>
      <DialogContent>
        <Box justify="space-between" align="flex-start">
          <Box>
            <Typography variant="subtitle1" color="textPrimary">Select domains to validate:</Typography>
            <Box
              css={css`
                position: relative;
              `}
            >
              <FormControlLabel
                size="small"
                control={<Checkbox checked={numberSelected === domains.length} onChange={selectAll} name="Select All" />}
                label="Select All"
              />
            </Box>
          </Box>
          <SearchBar
            css={{
              width: '100%',
              marginBottom: remMapper('small'),
            }}
            text="Search Domains"
            onChange={(filter) => setFilter(filter)}
          />
        </Box>
        <Box
          css={css`
          overflow: auto;
        `}
        >
          <FormGroup>
            {
              domains
                .filter((domain) => (domain.domainName ?? domain.fqdn).match(filter)) // this is so that newly-created domains that don't have their full record can be validated
                .map((domain) => (
                  <FormControlLabel
                    key={domain.domainId}
                    control={<Checkbox checked={(selected[domain.domainId ?? domain.id]) ?? false} onChange={handleChange} name={String(domain.domainId ?? domain.id)} />}
                    label={domain.fqdn}
                  />
                ))
            }
          </FormGroup>
        </Box>

      </DialogContent>
      <DialogActions>
        <Box direction="row" justify="space-between" align="flex-start" flex={1}>
          <Typography component="span" variant="caption" color="textSecondary">
            <span>
              {numberSelected} domain{numberSelected === 1 || 's'} selected
            </span>
          </Typography>
          <span>
            <Button
              disabled={numberSelected === 0}
              variant="outlined"
              onClick={handleNext}
            >
              next
            </Button>
          </span>
        </Box>
      </DialogActions>
    </Fragment>
  );
};
