import React from 'react';
import {
  node, object, oneOfType, string,
} from 'prop-types';
import logger from 'shared/3rdparty/logger';
import Empty from 'shared/styleguide/atoms/Empty';

class ErrorBoundary extends React.Component {
  static propTypes = {
    location: oneOfType([object, string]),
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location !== this.props.location) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error) {
    // Display fallback UI
    this.setState({ hasError: true, error }); //eslint-disable-line
    logger.error(error, {
      context: 'Error Boundary triggered',
      request: this.props.location,
      metaData: error,
      severity: 'error',
      unhandled: true,
    });

    console.log('🔥🔥🔥🔥', error); //eslint-disable-line
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Empty>
          Sorry - We couldn&apos;t load this right now
          <br />
        </Empty>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
