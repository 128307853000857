import React from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import Row from 'shared/styleguide/atoms/Row';
import NewButton from 'shared/styleguide/atoms/Buttons/NewButton';

import { addRuleBlock } from '../redux/actions';
import BlockRules from './BlockRules';
import NonBlockRules from './NonBlockRules';

import { sortRuleBlocks, getConfigType } from '../helpers';

const RuleBlocks = ({
  state, propLevels, value, addRuleBlock, schema,
}) => {
  const configType = getConfigType(state.config);
  function handleSortRuleBlocks(obj) {
    return sortRuleBlocks(obj);
  }

  let ruleName = 'rules';
  if (configType === 'miss-rules') {
    ruleName = configType;
  }
  const propLevelsNew = `${propLevels}.${ruleName}.0`;
  const sortedRuleBlocks = handleSortRuleBlocks(value[ruleName][0]);

  return (
    <div>
      <Typography variant="body2">
        <span style={{ fontWeight: '600', fontSize: '1.2rem' }}>{ruleName}:</span>
      </Typography>
      <div>
        <div>
          <div>
            <NonBlockRules
              value={sortedRuleBlocks.ruleBlockValues}
              propLevels={propLevelsNew}
            />
          </div>
        </div>
        <Row
          style={{
            alignItems: 'center', justifyContent: 'left', marginTop: 30, marginBottom: 30,
          }}
        >
          <div>
            <NewButton
              variant="contained"
              name="match"
              onClick={(e) => addRuleBlock(propLevelsNew, e.currentTarget.name, schema)}
            >Add Match Block
            </NewButton>
          </div>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <NewButton
              variant="contained"
              name="matchExcept"
              onClick={(e) => addRuleBlock(propLevelsNew, e.currentTarget.name, schema)}
            >Add MatchExcept Block
            </NewButton>
          </div>
          <div>
            <NewButton
              variant="contained"
              name="actions"
              onClick={(e) => addRuleBlock(propLevelsNew, e.currentTarget.name, schema)}
            >Add Actions Block
            </NewButton>
          </div>
        </Row>
        <div>
          {sortedRuleBlocks.ruleBlocks.map((value, idx) => (
            <div
              style={{ marginTop: 20 }}
              key={`ruleBlocks-${idx}`}
            >
              <Typography variant="body2">
                <span style={{ fontWeight: '600', fontSize: '1.2rem' }}>{Object.keys(value)[0]}:</span>
              </Typography>
              <BlockRules
                value={value}
                propLevels={propLevelsNew}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

RuleBlocks.propTypes = {
  addRuleBlock: any,
  propLevels: any,
  schema: any,
  state: any,
  value: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, { addRuleBlock })(RuleBlocks);
