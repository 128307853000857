import React, { Component, Fragment } from 'react';
import { object, string, func } from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';
import TrashIcon from '@material-ui/icons/RemoveCircleOutline';
import XIcon from '@material-ui/icons/Clear';
import { Tooltip } from '@material-ui/core';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import colors from 'shared/styleguide/theme';
import ExpandingTextField from 'shared/styleguide/molecules/ExpandingTextField';
import Select from 'shared/styleguide/atoms/Select/Select';
import ConfirmableButton from 'shared/styleguide/atoms/Buttons/ConfirmableButton';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';

import isPagelyAdmin from 'shared/utils/isAdmin';
import { deleteDNSRecord, updateDNSRecord } from 'shared/modules/dns/redux/actions';
import styles from '../DNS.scss';
import { RECORD_TYPES } from '../constants';

const contactSupportMessage = 'Please contact support if you need this record to be edited';

class DNSItem extends Component {
  static propTypes = {
    accountId: string,
    deleteDNSRecord: func,
    record: object,
    updateDNSRecord: func,
    user: object,
    zone: object,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: this.props.record.ResourceRecords // TODO: figure out why this is here
        ? this.props.record.ResourceRecords.map((i) => i.Value)
        : [`Alias: ${this.props.record.AliasTarget.DNSName}`],
      ttl: this.props.record.TTL,
      errors: {
        subdomain: '',
      },
      editing: false,
    };

    this.type = RECORD_TYPES.find((t) => t.value === this.props.record.Type);

    const { record } = props;

    this.values = record.ResourceRecords
      ? record.ResourceRecords.map((i) => i.Value)
      : [''];
  }

  mapTTL = (ttl) => {
    if (Number.isNaN(ttl)) {
      return '';
    }
    switch (ttl) {
      case 60: return '1m';
      case 300: return '5m';
      case 900: return '15m';
      case 3600: return '1h';
      case 43200: return '12h';
      case 172800: return '48h';
      default: return `${ttl}s`;
    }
  };

  validate = (values) => {
    const errors = {
      values: !values ? 'You must add a valid value' : '',
    };

    if (this.props.record.Type === 'SPF' && values) {
      const concat = values.indexOf('"') > -1 ? '' : 'SPF values must be enclosed in quotation marks (")';
      errors.values = errors.values.concat(concat);
    }
    this.setState({ errors });

    return !errors.subdomain && !errors.values;
  };

  handleSave = (e) => {
    e.preventDefault();

    // validate form
    const isValid = this.validate(this.values.join(''));

    if (!isValid) {
      return;
    }

    const { record } = this.props;

    // set TTL if not already set
    let ttl = this.state.ttl; //eslint-disable-line
    if (!this.state.ttl || this.state.ttl === 'auto') {
      ttl = record.TTL;
    }

    const data = {
      name: record.Name,
      type: record.Type,
      values: (this.values.filter((v) => !!v)),
      ttl,
      accountId: this.props.accountId,
      appId: this.props.zone.appId,
      zoneId: this.props.zone.extId,
    };

    // submit data
    this.props.updateDNSRecord(data)
      .then(() => {
        this.setState({ editing: false });
      })
      .catch((err) => {
        this.setState({
          errors: {
            general: `Error: ${err}`,
          },
        });
      });
  };

  renderEdit = () => {
    const { record, user } = this.props;

    const options = [
      { label: '1m', value: 60 },
      { label: '5m', value: 300 },
      { label: '1h', value: 3600 },
      { label: '12h', value: 43200 },
      { label: '1d', value: 86400 },
    ];

    const isAdmin = isPagelyAdmin(user.user);

    return (
      <Fragment>
        <TableRow key={record.domainId}>
          <TableCell key="type"><strong>{record.Type}</strong></TableCell>
          <TableCell key="name" align="left">{record.Name.replace('\\052', '*')}</TableCell>
          <TableCell key="value" align="left">
            <ExpandingTextField
              id="edit-record"
              fullWidth
              onChange={(inputs) => { this.values = inputs; }}
              displayOnly={!this.state.editing}
              inputs={
                record.ResourceRecords
                  ? record.ResourceRecords.map((i) => i.Value)
                  : ['']
              }
              disableAdd={!this.type.expanding}
              error={this.state.errors.values}
            />
          </TableCell>
          <TableCell key="ttl" align="left">
            {record.TTL && (
              <Select
                className={styles.select}
                options={options}
                defaultValue={options.find((option) => option.value === parseInt(record.TTL, 10))}
                onChange={(d) => this.setState({ ttl: d.value })}
                isClearable={false}
                isSearchable={false}
              />
            )}
          </TableCell>
          <TableCell key="actions" align="left">
            {
              record.readOnly && !isAdmin
                ? (
                  <Tooltip title={contactSupportMessage}>
                    <span>
                      <GhostTag color="smoke" label="Pagely-Defined" />
                    </span>
                  </Tooltip>
                )
                : (
                  <Fragment>
                    <Tooltip title="Save">
                      <IconButton
                        onClick={this.handleSave}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel Editing">
                      <IconButton
                        onClick={() => this.setState({
                          // eslint-disable-next-line react/no-unused-state
                          values: this.props.record.ResourceRecords
                            ? this.props.record.ResourceRecords.map((i) => i.Value)
                            : [`Alias: ${this.props.record.AliasTarget.DNSName}`],
                          ttl: this.props.record.TTL,
                          errors: {
                            subdomain: '',
                          },
                          editing: false,
                        })}
                      >
                        <XIcon />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                )
            }
          </TableCell>
        </TableRow>
        {
          this.state.errors.general && (
            <TableRow>
              <ErrorText>{this.state.errors.general}</ErrorText>
            </TableRow>
          )
        }
      </Fragment>
    );
  };

  render() {
    const { record, deleteDNSRecord, user } = this.props;

    const isAdmin = isPagelyAdmin(user.user);

    // Show EDIT or VIEW state, connect in order to update or delete
    return this.state.editing ? (this.renderEdit())
      : (
        <TableRow key={record.domainId}>
          <TableCell key="type"><strong>{record.Type}</strong></TableCell>
          <TableCell key="name" align="left">{record.Name.replace('\\052', '*')}</TableCell>
          <TableCell key="value" align="left" css={{ wordBreak: record.Type === 'TXT' && 'break-all' }}>
            {
              (record.ResourceRecords
                || [{ Value: `Alias: ${this.props.record.AliasTarget.DNSName}` }])
                .map((i) => <div key={i.Value}>{i.Value}</div>)
            }
          </TableCell>
          <TableCell key="ttl" align="left">{this.mapTTL(parseInt(record.TTL, 10))}</TableCell>
          <TableCell key="actions" align="left">
            {
              record.readOnly && !isAdmin
                ? (
                  <Tooltip title={contactSupportMessage}>
                    <span>
                      <GhostTag color="smoke" label="Pagely-Defined" />
                    </span>
                  </Tooltip>
                )
                : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>

                    {record.readOnly && (
                      <Tooltip title={contactSupportMessage}>
                        <span>
                          <GhostTag color="smoke" label="Pagely-Defined" />
                        </span>
                      </Tooltip>
                    )}
                    {
                      this.type && (
                        <Box direction="row" align="center" justify="center">
                          <Tooltip title="Edit DNS">
                            <IconButton
                              style={{
                                height: 'auto',
                                width: 'auto',
                              }}
                              onClick={() => this.setState({ editing: true })}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <ConfirmableButton
                            icon={TrashIcon}
                            activeColor={colors.red10}
                            callback={() => {
                              deleteDNSRecord({
                                accountId: this.props.accountId,
                                zoneId: this.props.zone.extId,
                                name: record.Name,
                                type: record.Type,
                                appId: this.props.zone.appId,
                              });
                            }}
                            tooltip="Delete"
                          />
                        </Box>
                      )
                    }
                  </div>
                )
            }
          </TableCell>
        </TableRow>
      );
  }
}

export default connect(
  (state) => ({ user: state.user }),
  {
    deleteDNSRecord,
    updateDNSRecord,
  },
)(DNSItem);
