import { roles } from 'shared/modules/permissions/user/actions';

import { routes as teamChildren } from 'shared/modules/team/routes';
import { routes as billingChildren } from 'shared/modules/billing/routes/routes';
import { routes as supportChildren } from 'shared/modules/support/routes';
import { routes as analyticsChildren } from 'shared/modules/analytics/routes';
import { children as appChildren } from 'shared/modules/app/routes/children';
import { children as configsChildren } from 'admin/modules/aresconfig/routes/children';
import { getDeployEnvironment } from 'shared/config';
import { FLAGS } from 'shared/flags';

export const sitemap = ({ permissions, account }) => {
  const { isAdmin } = permissions;
  const isNotDisabled = (!account.disabled || isAdmin);

  const role = isAdmin ? roles.PRIMARY : (permissions?.directory?.[account.id]?.role || 0);
  const baseRoute = `/account/${account.id}`;

  return [
    {
      label: 'Account Search',
      match: new RegExp('^/$'),
      route: '/account-search',
      show: [isAdmin],
    },
    {
      label: 'Account Settings',
      match: new RegExp('/settings/profile'),
      route: `${baseRoute}/settings/profile`,
      show: [isAdmin],
    },
    {
      label: 'Dashboard',
      match: new RegExp(`^${baseRoute}$`),
      route: `${baseRoute}`,
      show: [true],
    },
    {
      label: 'Alerts',
      match: new RegExp('^/account/\\d+/alerts'),
      route: `${baseRoute}/alerts`,
      show: [true],
    },
    {
      label: 'Team',
      match: new RegExp('/team'),
      route: `${baseRoute}/team`,
      show: [isNotDisabled, role >= roles.SUBADMIN],
      children: (role >= roles.SUBADMIN || isAdmin) ? teamChildren : null,
    },
    {
      label: 'Payment and Plan',
      match: new RegExp('/billing'),
      route: `${baseRoute}/billing`,
      show: [(role >= roles.SUBADMIN || role === roles.BILLING)],
      children: !account.isCollaborator ? billingChildren : null,
    },
    {
      label: 'PressFormance',
      match: new RegExp('/analytics'),
      route: `${baseRoute}/analytics`,
      show: [isNotDisabled],
      children: analyticsChildren,
    },
    {
      label: 'Apps',
      match: new RegExp('/apps'),
      parentId: new RegExp(/\/apps\/(\d+)/),
      route: `${baseRoute}/apps`,
      requiresParentId: true,
      show: [isNotDisabled, (role >= roles.SUBADMIN || role === roles.TECH || role === roles.APPONLY)],
      children: appChildren,
    },
    {
      label: 'Disk',
      match: new RegExp(/\/account\/(\d+)\/disk/),
      route: `${baseRoute}/disk`,
      // disabled: false || accountIsDisabled,
      show: [isNotDisabled, role >= roles.TECH],
      permissionRequirement: [10, 9, roles.SUBADMIN, roles.TECH, roles.BILLING],
    },
    {
      label: 'DNS',
      match: new RegExp(/\/account\/(\d+)\/dns/),
      route: `${baseRoute}/dns`,
      // disabled: false || accountIsDisabled,
      show: [isNotDisabled, role >= roles.TECH],
      permissionRequirement: [10, 9, roles.SUBADMIN, roles.TECH],
    },
    {
      label: 'SSL',
      match: new RegExp('/ssl'),
      route: `${baseRoute}/ssl`,
      // disabled: false || accountIsDisabled,
      show: [isNotDisabled, (role >= roles.TECH || role === roles.APPONLY)],
      permissionRequirement: [10, 9, roles.SUBADMIN, roles.TECH, roles.APPONLY],
    },
    {
      label: 'CDN',
      match: new RegExp('/cdn'),
      route: `${baseRoute}/cdn`,
      // disabled: false || accountIsDisabled,
      show: [isNotDisabled, role >= roles.TECH],
      permissionRequirement: [10, 9, roles.SUBADMIN, roles.TECH],
    },
    {
      label: 'CI/CD Integrations',
      match: new RegExp('/integrations'),
      route: `${baseRoute}/integrations`,
      // disabled: false || accountIsDisabled,
      show: [isNotDisabled, role >= roles.TECH, !account.isOnP20Account],
      permissionRequirement: [10, 9, roles.SUBADMIN, roles.TECH],
    },
    {
      label: 'Migration Secrets',
      match: new RegExp('/migration-secrets'),
      route: '/migration-secrets',
      show: [isAdmin && FLAGS.WOOSAAS],
    },
    {
      label: 'Release Notes',
      route: `${baseRoute}/release-notes`,
      match: new RegExp('/release-notes'),
      show: [true],
    },
    {
      label: 'Support',
      match: new RegExp('/support'),
      route: `${baseRoute}/support`,
      show: [true, role >= roles.APPONLY],
      permissionRequirement: roles.APPONLY,
      children: supportChildren,
    },
    {
      label: 'Configs',
      match: new RegExp('/configs'),
      route: `${baseRoute}/configs`,
      show: [isAdmin],
      children: configsChildren,
    },
  ];
};
