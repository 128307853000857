import Color from 'color';

export const tint = (color, steps) => {
  return Color(color).lighten(0.05 * steps).hex();
};

export const shade = (color, steps) => {
  return Color(color).darken(0.05 * steps).hex();
};

/* Style Guide Colors */

/* -- Palette */
export const storm = '#1C5673';// primary
export const mint = '#32DE8A'; // secondary
export const cornflower = '#5A92D5';
export const ice_tint = '#F8F9FA';
export const dark_navy = '#103040';
export const indigo = '#3f51b5';

export const danger = '#E01B4A';
export const warning = '#EEA321';
export const success = mint; // secondary

/* -- Monochrome */
export const white = '#FFFFFF';
export const light_gray = '#CED4DA';
export const medium_gray = '#ADB5BD';
export const ice_shade = '#9AA9B4';
export const dark_gray = '#495057';
export const pagelyBlack = '#212529';

/* Deprecated Colors */

const primary = storm;
const primary10 = tint(primary, 4);
const primary20 = primary;// primary
const primary30 = shade(primary, 4);
// const primary40 = '#103040';

const secondary = mint;
const secondary10 = tint(secondary, 4);
const secondary20 = secondary; // secondary
const secondary30 = shade(secondary, 4);
const secondary40 = shade(secondary, 8);

const accent = cornflower;
const accent10 = tint(cornflower, 8);
const accent20 = tint(cornflower, 4);
const accent40 = shade(cornflower, 4);

const red10 = danger;
const red20 = tint(danger, 4);

const orange = shade(warning, 4);
const golden = warning;
const yellow = tint(warning, 4);

const light10 = white;
const light20 = ice_tint;
const light25 = shade(ice_tint, 1);
const light30 = shade(ice_tint, 2);
const light40 = shade(ice_tint, 3);

const sea10 = ice_shade;
const sea20 = shade(ice_shade, 4);
const sea30 = shade(ice_shade, 8);

const dark10 = light_gray;
const dark20 = medium_gray;
const dark30 = tint(pagelyBlack, 4);
const dark40 = pagelyBlack;

export const palette = {
  primary,
  secondary,
  storm,
  mint,
  cornflower,
  ice_tint,
  dark_navy,
  danger,
  warning,
  success,
  white,
  light_gray,
  medium_gray,
  ice_shade,
  dark_gray,
  pagelyBlack,
};

export default {
  primary10,
  primary20,
  primary30,

  secondary10,
  secondary20,
  secondary30,
  secondary40,

  accent,
  accent10,
  accent20,
  accent40,

  green10: secondary10,
  green20: secondary20,
  green30: secondary30,
  green40: secondary40,
  red10,
  red20,
  orange,
  golden,
  yellow,
  blue10: accent10,
  blue20: accent20,
  blue30: accent,
  blue40: accent40,
  navy10: primary10,
  navy20: primary20,
  navy30: primary30,
  navy40: dark_navy,
  light10,
  light20,
  light25,
  light30,
  light40,
  sea10,
  sea20,
  sea30,
  dark10,
  dark20,
  dark30,
  dark40,
  primary,
  secondary,
};
