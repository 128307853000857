import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as CONFIGS from './constants';

export const fetchConfigs = (scope, options) => {
  const {
    accountId, appId, serverId, poolId, configType, ...params
  } = options;

  let url;

  switch (scope) {
    case 'account':
      url = `/serverconfig/gateway-config/account/${accountId}/${configType}`;
      break;
    case 'app':
      url = `/serverconfig/gateway-config/app/${appId}/${configType}`;
      break;
    case 'server':
      url = `/serverconfig/gateway-config/server/${serverId}/${configType}`;
      break;
    case 'server-account':
      url = `/serverconfig/gateway-config/server/${serverId}/account/${accountId}/${configType}`;
      break;
    case 'server-app':
      url = `/serverconfig/gateway-config/server/${serverId}/app/${appId}/${configType}`;
      break;
    case 'pool':
      url = `/serverconfig/gateway-config/pool/${poolId}/${configType}`;
      break;
    case 'pool-account':
      url = `/serverconfig/gateway-config/pool/${poolId}/account/${accountId}/${configType}`;
      break;
    case 'pool-app':
      url = `/serverconfig/gateway-config/pool/${poolId}/app/${appId}/${configType}`;
      break;
    default:
      return;
  }

  return makeApiAction(
    ({ api }) => {
      let queryString = '';
      if (params) {
        queryString = qs.stringify({ ...params }, { addQueryPrefix: true });
      }
      return api().get(`${url}${queryString}`);
    },
    CONFIGS.FETCH_CONFIGS_REQUEST,
    CONFIGS.FETCH_CONFIGS_SUCCESS,
    CONFIGS.FETCH_CONFIGS_FAILURE,
    { scope, ...options },
    true,
  );
};

export const resetSearch = () => ({
  configType: CONFIGS.FETCH_CONFIGS_RESET,
});
