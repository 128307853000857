import moment from 'moment';

const EXTRA_TIME_PADDING = 300; // 5 minutes

export const roundedUpDuration = (value) => {
  const milliseconds = Math.abs(value + EXTRA_TIME_PADDING) * 1000;
  const duration = moment.duration(milliseconds);
  const hours = Math.floor(duration.asHours());
  const niceHours = hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''} ` : '';
  const minutes = moment.utc(milliseconds).format('m');
  return `${niceHours + minutes} minute${minutes !== 1 ? 's' : ''}`;
};

export const getValidationTypeToShow = (jobStepId) => {
  switch (jobStepId) {
    case 'acm-validation-update':
    case 'acm-dns-validate':
      return 'acm';
    case 'pressdns-validation':
      return 'ns';
    case 'domain-pointing-cdn':
    case 'domain-pointing-cdn-validation':
    case 'domain-dns-validation':
    case 'dns-propagation':
    case 'verify-site-access':
    case 'pressdns-update':
      return 'non-acm';
    default:
      return 'all';
  }
};
