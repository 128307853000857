import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';

import {
  baseCollectionState,
  load,
  fail,
  success,
} from 'shared/utils/redux/apiReducer';

import * as LOGGING from './constants';

export const initialState = {
  ...baseCollectionState,
};

export const gitWebhookLogs = (state = initialState, action) => {
  switch (action.type) {
    case LOGGING.GET_JOB_DEPLOYMENT_LOG_SUCCESS: {
      const data = action.params?.appId === state.params?.appId
        ? {
          ...state.data,
          ...action.data.data,
        } : {
          ...action.data.data,
        };

      return {
        ...success(state, action),
        data,
      };
    }
    case LOGIN_LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};

export const gitIntegrationLogs = (state = initialState, action) => {
  switch (action.type) {
    case LOGGING.GET_JOB_DEPLOYMENT_LOG_SUCCESS:
      return {
        ...success(state, action),
        data: {
          ...state.data,
          ...action.data.data,
        },
      };
    case LOGIN_LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
};
