import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CircleCheck from 'shared/styleguide/Icons/CheckCircleOutline';
import { styles } from './styles';

export const Success = ({
  classes, primaryMessage, secondaryMessage, action,
}) => (
  <div>
    <div className={classes.successStateContent}>
      <CircleCheck
        color="secondary"
        style={{
          width: 72,
          height: 72,
        }}
      />
      {
        primaryMessage && (
          <div className={classes.successStateSpaceTop}>
            <Typography color="textPrimary" className={classes.primarySuccessMessage}>
              {primaryMessage}
            </Typography>
            <div className={classes.secondarySuccessMessage}>
              {secondaryMessage}
            </div>
            {action}
          </div>
        )
      }
    </div>
  </div>
);

Success.displayName = 'Success';
Success.propTypes = {
  action: PropTypes.node,
  classes: PropTypes.object,
  primaryMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondaryMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default withStyles(styles)(Success);
