import React from 'react';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { localizeCurrency } from 'shared/utils';
import { Billing } from '../../types';
import { PlanData } from './types';

const formatAddonDescription = (addon: Billing.SubscriptionBySubscriptionIdAndAccountId.Addon): string => {
  if (!addon.name) return '';
  const [prefix, addonName] = addon.name.split(':').map((s) => s.trim());
  if (!addonName) {
    return `${addon.quantity} ${prefix}`;
  }
  if (addon.name.startsWith('Region')) {
    return `${addon.quantity} ${addonName}`;
  }
  return `${addon.quantity} (${addonName})`;
};

const calcAddonCost = (addon) => {
  if (!addon.unitPrice) return 0;
  return (addon.unitPrice * addon.quantity) / 100;
};

const AddonRow = ({ description, cost }: {description: string; cost: number}) => {
  return (
    <div
      data-testid="addon-row"
      css={(theme) => ({
        borderBottom: `1px solid ${theme.color400}`, paddingBottom: remMapper('xxsmall'), display: 'flex', justifyContent: 'space-between', marginBottom: remMapper('small'),
      })}
    >
      <div>
        {description}
      </div>
      <div css={{ display: 'flex' }}><strong css={{ alignSelf: 'flex-end' }}>{localizeCurrency(cost)}</strong></div>
    </div>
  );
};

export const AddonRows = ({ planData }: { planData: PlanData}): JSX.Element => {
  return (
    <div>
      {
       planData.serverAddons.length > 0 && (
         <>
           <span css={{ marginBottom: remMapper('xxsmall'), display: 'block' }}><strong>Servers</strong></span>
           {
             planData.serverAddons.map((addon, i) => {
               return (
                 <AddonRow key={i} description={formatAddonDescription(addon)} cost={calcAddonCost(addon)} />
               );
             })
           }
         </>
       )
      }
      {
       planData.databaseAddons.length > 0 && (
         <>
           <span css={{ marginBottom: remMapper('xxsmall'), display: 'block' }}><strong>Database</strong></span>
           {
             planData.databaseAddons.map((addon, i) => {
               return (
                 <AddonRow key={i} description={formatAddonDescription(addon)} cost={calcAddonCost(addon)} />
               );
             })
           }
         </>
       )
      }
      {
       planData.regionAddons.length > 0 && (
         <>
           <span css={{ marginBottom: remMapper('xxsmall'), display: 'block' }}><strong>Regions</strong></span>
           {
             planData.regionAddons.map((addon, i) => {
               return (
                 <AddonRow key={i} description={formatAddonDescription(addon)} cost={calcAddonCost(addon)} />
               );
             })
           }
         </>
       )
      }
      {
       planData.otherAddons.length > 0 && (
       <>
         <span css={{ marginBottom: remMapper('xxsmall'), display: 'block' }}><strong>Other Addons</strong></span>
         {
             planData.otherAddons.map((addon, i) => {
               return (
                 <AddonRow key={i} description={formatAddonDescription(addon)} cost={calcAddonCost(addon)} />
               );
             })
           }
       </>
       )
      }
    </div>
  );
};
