import React, { useState, Fragment } from 'react';
import { object, bool, func } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import qs from 'qs';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import TrashIcon from '@material-ui/icons/Delete';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import {
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import UnlockIcon from '@material-ui/icons/LockOutlined';
import IntegrationsIcon from '@material-ui/icons/SettingsInputComponent';

//
import DnsIcon from '@material-ui/icons/Dns';
import SSLIcon from '@material-ui/icons/Lock';
import CDNIcon from '@material-ui/icons/CloudCircle';
//
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import {
  ErrorText,
} from 'shared/styleguide/typography';
import { consolidateErrors } from 'shared/utils/validation';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import WordPressIcon from 'shared/styleguide/Icons/SVGs/WordPress';

import Loading from 'shared/styleguide/atoms/Loading';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import InfoText from 'shared/styleguide/molecules/InfoText';

import CDNLink from 'shared/modules/cdn/Link';
import DNSLink from 'shared/modules/dns/Link';
import SSLLink from 'shared/modules/ssl/Link';
import { GIT_INTEGRATION_TAG } from 'shared/modules/metadata/constants';

import { updateApp } from 'shared/modules/app/redux/actions';
import { fetchAppsForAccount } from 'shared/modules/apps/redux/actions';
import DestructiveAction from 'shared/styleguide/molecules/DestructiveAction';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { NewAppContext } from 'shared/modules/apps/context/NewAppContext';
import { useMergeState } from 'shared/hooks/useMergeState';
import Credentials from './Credentials';

const AppHeader = (props) => {
  const {
    account, app, appMeta, dns, getPrimaryDomain, linkedCerts, tags, location,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(props.stateMocks?.errors || false);
  const [showEditLabel, setShowEditLabel] = useState(false);
  const [appLabelField, setAppLabelField] = useState(app.label || app.name);

  // what we know about the app
  const managedState = useMergeState({
    /*
      appId={app.id}
    appName={app.name}
    open={showCredentials === true}
    onOpen={setShowCredentials}
     */
    newAppId: app.id,
    newAppName: app.name,
    appCreateJob: null,
    showCredentials: 'initial',
    timestamp: null,
  });

  const primaryDomain = getPrimaryDomain();

  // this is for GTM tracking
  const metadata = {
    accountId: account.id,
    primaryDomain: app.name,
  };

  if (!props.app.loading && !primaryDomain) {
    return <div style={{ padding: 20 }}><Empty>No Primary Domain</Empty></div>;
  } else if (!primaryDomain || dns.loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const handleDeleteApp = async () => {
    setLoading(true);
    try {
      await props.deleteApp(app.id, metadata);
      props.history.replace(props.match.url.replace(/\/apps\/.*/, '/apps'));
    } catch (err) {
      setLoading(false);
      setErrors(consolidateErrors(err));
    }
  };

  const handleDisableApp = async () => {
    try {
      await props.disableApp(app.id, metadata);
      setErrors(null);
      dispatch(fetchAppsForAccount(account.id));
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
  };

  const handleEnableApp = async () => {
    try {
      await props.enableApp(app.id, metadata);
      setErrors(null);
      dispatch(fetchAppsForAccount(account.id));
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
  };

  const handleSaveLabel = async () => {
    try {
      await dispatch(updateApp({ appId: app.id, update: { label: appLabelField } }));
      setErrors(null);
      setShowEditLabel(false);
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
  };

  let linkedCert = null;
  if (linkedCerts.data
    && linkedCerts.data.length === 1
    && linkedCerts.data[0].links.length === 1
    && linkedCerts.data[0].links[0].appId === app.id
  ) {
    linkedCert = linkedCerts.data[0].links[0]; //eslint-disable-line
  }

  const hasGitSetUp = tags.server.data.length > 0 && (
    tags.server.data.map((t) => t.tagId).includes(GIT_INTEGRATION_TAG)
  );

  // check query string and load credentials modal if set
  const query = location.search ? qs.parse(location.search, { ignoreQueryPrefix: true }) : null;
  if (managedState.state.showCredentials === 'initial' && query?.credentials === 'show') {
    managedState.mergeState({ showCredentials: 'show' });
  }

  const fsaDeleteMessage = app.fsa ? ' Mercury Dynamic Site Acceleration configuration will be deleted.' : '';
  const stagingConfig = appMeta.data.find((tag) => tag.keyId === 'app:staging-config');

  return (
    <Fragment>
      <Box direction="row" wrap="wrap" data-testid="app-header" justify="space-between">
        {/* Left side */}
        <Box>
          <span>
            {
              showEditLabel ? (
                <Box direction="row" align="center" margin={{ bottom: 'xsmall' }}>
                  <WideTextField
                    value={appLabelField}
                    onChange={(e) => setAppLabelField(e.target.value)}
                    margin="none"
                    placeholder={app.name}
                  />
                  <span>
                    <IconButton size="small" onClick={handleSaveLabel}>
                      <SaveIcon fontSize="small" />
                    </IconButton>
                  </span>
                  <span>
                    <IconButton size="small" onClick={() => setShowEditLabel(false)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Box>
              ) : (
                <Typography variant="h2" color="textPrimary">
                  {app.label || app.name}
                  <IconButton size="small" onClick={() => setShowEditLabel(true)} css={{ marginLeft: '0.5rem' }}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Typography>
              )
            }
          </span>
          <Box
            css={{
              marginTop: remMapper('xxsmall'),
              marginBottom: remMapper('xxsmall'),
            }}
          >
            <TextLink
              variant="subtitle2"
              color="textSecondary"
              noUnderline
              href={`http://${primaryDomain.fqdn}`}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              {primaryDomain.fqdn}
            </TextLink>
          </Box>
          <Box direction="row" gap="xsmall">
            <Typography component="span" variant="body2" color="textSecondary">App ID:</Typography>
            <Typography component="span" variant="body2" color="textPrimary">{app.id}</Typography>
          </Box>
          <ErrorBoundary>
            <Box
              direction="row"
              wrap="wrap"
              padding={{ top: 'xsmall' }}
            >
              {
                stagingConfig?.value?.type && (
                  <GhostTag color={stagingConfig.value.type === 'primary' ? 'primary' : 'accent'}>{stagingConfig.value.type}</GhostTag>
                )
              }
              {
                props.isAdmin && tags.app.data.length > 0
                && (
                  tags.app.data.map((t) => <GhostTag key={t.tagId}>{t.tagId}</GhostTag>)
                )
              }
            </Box>
          </ErrorBoundary>

        </Box>

        {/* right side */}
        <Box
          gap="small"
        >
          {/* icon buttons */}
          <Box
            direction="column"
          >
            <Box direction="row" style={{ marginLeft: -14 }} justify="flex-end">
              <Tooltip title="View App Credentials" placement="top">
                <IconButton
                  css={(theme) => ({
                    'padding': 0,
                    'marginRight': `${remMapper('xsmall')}`,
                    '&:hover': {
                      color: theme.palette.primary.main,
                    },
                  })}
                  onClick={() => managedState.mergeState({ showCredentials: 'show' })}
                >
                  <WordPressIcon />
                </IconButton>
              </Tooltip>
              <NewAppContext.Provider value={managedState}>
                <Credentials
                  accountId={account.id}
                  appId={app.id}
                  appName={app.name}
                />
              </NewAppContext.Provider>
              {
                dns.loaded
                  && dns.data.id
                  && dns.data.appId === app.id
                  && dns.apiErrorStatusCode !== 400
                  ? (
                    <DNSLink appId={dns.loaded && dns.data.id}>
                      <Tooltip title="DNS" placement="top">
                        <IconButton
                          css={{
                            padding: 0,
                            marginRight: `${remMapper('xsmall')}`,
                          }}
                        >
                          <DnsIcon />
                        </IconButton>
                      </Tooltip>
                    </DNSLink>
                  ) : (
                    <Tooltip title="No PressDNS zone configured" placement="top">
                      <div>
                        <IconButton
                          disabled
                          css={{
                            padding: 0,
                            marginRight: `${remMapper('xsmall')}`,
                          }}
                        >
                          <DnsIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
              }

              <SSLLink certId={linkedCert && linkedCert.certId}>
                <Tooltip
                  placement="top"
                  title={linkedCert ? 'SSL' : 'This app has multiple SSL Certificates'}
                  leaveTouchDelay={100}
                >
                  <IconButton
                    css={{
                      padding: 0,
                      marginRight: `${remMapper('xsmall')}`,
                    }}
                  >
                    {
                      linkedCert ? (<SSLIcon />) : (<UnlockIcon />)
                    }
                  </IconButton>

                </Tooltip>
              </SSLLink>

              <CDNLink appId={app.id}>
                <Tooltip title="CDN" placement="top">
                  <IconButton
                    css={{
                      padding: 0,
                      marginRight: `${remMapper('xsmall')}`,
                    }}
                  >
                    <CDNIcon />
                  </IconButton>
                </Tooltip>
              </CDNLink>
              <Link to={`/account/${account.id}/apps/${app.id}/integrations`}>
                <Tooltip title={hasGitSetUp ? 'Integrations' : 'Integration is not enabled'} placement="top">
                  <span>
                    <IconButton
                      disabled={!hasGitSetUp}
                      css={{
                        padding: 0,
                        marginRight: `${remMapper('xsmall')}`,
                      }}
                    >
                      <IntegrationsIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Link>
            </Box>
          </Box>
          {/* deactivation */}
          <Box direction="column" justify="space-between" align="flex-end">
            <Box
              direction="row"
              align="center"
              css={{
                gap: `${remMapper('xsmall')}`,
              }}
            >
              <Box justify="center" flex={1} style={{ width: '100%' }}>
                {
                  /* eslint-disable no-nested-ternary */
                  loading ? (<Loading />)
                    : !app.active ? (
                      <DestructiveAction
                        fullWidth
                        onConfirm={handleDeleteApp}
                        startIcon={<TrashIcon color="default" />}
                        disabled={app.doing || app.active}
                        explainerText="Deleting an App cannot be undone."
                        secondaryText={`Your site will become inaccessible. Backups will be stopped.${fsaDeleteMessage} Site files will be accessible for 90 days before being permanently deleted.`}
                      >
                        Delete App
                      </DestructiveAction>
                    ) : (
                      <Typography gutterBottom variant="caption" color="textSecondary">App must be deactivated before it can be deleted</Typography>
                    )
                  /* eslint-enable */
                }
              </Box>
              {
                app.active ? (
                  <DestructiveAction
                    onConfirm={handleDisableApp}
                    startIcon={<PowerIcon />}
                    disabled={app.doing}
                    loading={app.doing}
                    explainerText="An App must be deactivated before being fully deleted."
                    secondaryText={(
                      <Box>
                        <Typography paragraph component="span">
                          When an app is deactivated, it will stop accepting incoming requests and WP Cron will be paused. We will keep making backups unless you delete the app after deactivation. Any SSL certificates will need to be relinked to the app after reactivation.
                        </Typography>
                        <InfoText noMargin href="https://support.pagely.com/hc/en-us/articles/360031311871-Deactivating-an-App">Read more about deactivating an app here</InfoText>
                      </Box>
                    )}
                  >
                    Deactivate App
                  </DestructiveAction>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<PowerIcon />}
                    disabled={app.doing}
                    onClick={handleEnableApp}
                  >
                    Activate App
                  </Button>

                )
              }
              {
                errors && (
                  <Box margin={{ top: 'small' }}>
                    <ErrorText>Error: {errors}</ErrorText>
                  </Box>
                )
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

AppHeader.propTypes = {
  account: object.isRequired,
  app: object.isRequired,
  appMeta: object.isRequired,
  deleteApp: func,
  disableApp: func,
  dns: object,
  enableApp: func,
  getPrimaryDomain: func,
  handleToggleAdvancedOptions: func,
  history: object,
  isAdmin: bool,
  isOnP20Account: bool,
  linkedCerts: object,
  location: object,
  match: object,
  stateMocks: object,
  tags: object,
};

export default withRouter(AppHeader);
