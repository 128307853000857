export const NORMAL = 'normal';
export const URGENT = 'urgent';

export const DISK = 'Disk';
export const SECURITY = 'Security';
export const BILLING = 'Billing';

export const TARGET_ID = 'TARGET.ID';
export const MESSAGE = 'MESSAGE';

export const urgencyTagMap = {
  [URGENT]: 'red',
};

export const readStatusMap = {
  READ: 'read',
  UNREAD: 'unread',
};

export const alertStatuses = {
  OPEN: 'open',
  CLOSED: 'closed',
  RESPONDED: 'responded',
};

export const alertTypes = {
  DISK,
  SECURITY,
  BILLING,
};

export const templateTypes = {
  PUSH: 'push',
  EMAIL: 'email',
};

export const statusColorMap = {
  closed: 'smoke',
  open: 'red',
  responded: 'green',
};
