import React from 'react';
import { object } from 'prop-types';

import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  Add,
} from '@material-ui/icons';

import Box from 'shared/styleguide/atoms/Box';

import InfoText from 'shared/styleguide/molecules/InfoText';
import FromQueries from './FromQueries';
import ToQueries from './ToQueries';

const Queries = ({
  form, values,
}) => {
  return (
    <Box margin={{ top: 'small' }}>
      <Box direction="row" align="center" flex={1} gap="small" justify="flex-start">
        <Box css={{ flexBasis: '10%' }}>
          <Box padding="xsmall">
            <Typography variant="body1">
              <strong>Query</strong>
            </Typography>
          </Box>
        </Box>
        <Box>
          <RadioGroup
            value={form.queryMode}
            row
            disabled={form.status === 'loading'}
            onChange={(event) => form.handleSetQueryMode(event.target.value)}
          >
            <FormControlLabel labelPlacement="end" value="update" control={<Radio />} label="Update and append" />
            <FormControlLabel labelPlacement="end" value="replace-all" control={<Radio />} label="Replace all" />
          </RadioGroup>
        </Box>
      </Box>
      <InfoText type="warning">
        Raw strings should be passed as query values. Ensure they are NOT URL encoded.
      </InfoText>
      <Box key="query">
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Box padding={{ left: 'xsmall', right: 'small' }}>
              <FromQueries
                values={values}
                form={form}
              />
              <Box direction="row" justify="flex-end" padding={{ bottom: 'xxsmall' }}>
                <Tooltip title="Add another query" placement="top">
                  <IconButton
                    onClick={() => form.addQuery('from')}
                    size="small"
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <ToQueries
              values={values}
              form={form}
            />
            <Box direction="row" justify="flex-end" padding={{ bottom: 'xxsmall' }}>
              <Tooltip title="Add another query" placement="top">
                <IconButton
                  onClick={() => form.addQuery('to')}
                  size="small"
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

Queries.propTypes = {
  form: object,
  values: object,
};

export default Queries;
