import React from 'react';
import { object } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Hidden } from '@material-ui/core';

import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import Box from 'shared/styleguide/atoms/Box';
import AccountNav from 'shared/modules/webapp/components/Navigation/Sidebar';
import SidebarItem from 'shared/modules/webapp/components/Navigation/Sidebar/SidebarItem';
import SystemStatus from 'shared/modules/support/routes/Status/SystemStatus';
import { AdminLinks } from './navigation';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      background: theme.palette.background.default,
      borderRight: `1px solid ${theme.palette.background.border}`,
    },
    navsContainer: {
      display: 'flex',
      height: '100%',
      overflow: 'auto',
    },
    adminSidebar: {
      minWidth: 256,
    },
  };
});

export const LeftNav = ({ account, permissions }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.navsContainer}>
        <ErrorBoundary>
          <Switch>
            <Route
              path="/account/:accountID"
              render={({ match, location }) => (
                <AccountNav
                  isAws={account && account.awsCustomerId !== null}
                  match={match}
                  location={location}
                  permissions={permissions}
                />
              )}
            />
            <Route
              path="/"
              render={({ location }) => (
                <div className={classes.adminSidebar}>
                  <List component="nav" role="navigation" disablePadding>
                    <Box column padding="medium">
                      {AdminLinks.map((navItem) => {
                        const isActive = navItem.match.test(location.pathname);
                        return (
                          <SidebarItem
                            key={navItem.label}
                            location={location}
                            navitem={navItem}
                            isActive={isActive}
                          />
                        );
                      })}
                    </Box>
                  </List>
                </div>
              )}
            />
          </Switch>
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <Hidden mdDown implementation="css">
          <SystemStatus />
        </Hidden>
      </ErrorBoundary>
    </div>
  );
};

LeftNav.propTypes = {
  account: object,
  children: object,
  location: object.isRequired,
  match: object.isRequired,
  permissions: object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
  permissions: state.permissions,
  directory: state.permissions.directory,
});

export default connect(mapStateToProps)(LeftNav);
