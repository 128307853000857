import { differenceInMilliseconds, add } from 'date-fns';
import moment from 'moment';
import { Expiry } from './types';

export const clientExpiryOptions = [
  {
    label: '5 minutes',
    value: { minutes: 5 },
  },
  {
    label: '10 minutes',
    value: { minutes: 10 },
  },
  {
    label: '1 hour',
    value: { hours: 1 },
  },
  {
    label: '1 day',
    value: { days: 1 },
  },
  {
    label: '1 week',
    value: { weeks: 1 },
  },
  {
    label: '1 month',
    value: { months: 1 },
  },
  {
    label: '1 year',
    value: { years: 1 },
  },
  {
    label: 'Never',
    value: 'never',
  },
];

export const formatedExpiry = (when: Duration): string => moment.utc(add(new Date(), when)).format('YYYY-MM-DDTHH:mm:ssZ');

export const clientExpiryToServerExpiry = (expiry: Expiry): string => {
  if (expiry === 'never') return null;
  return formatedExpiry(expiry);
};

export const serverExpiryToClientExpiry = (ISODate: string): Expiry | string => {
  const isInThePast = new Date() > new Date(ISODate);
  if (!ISODate || isInThePast) return 'never';

  let smallestDiff = Infinity;
  let nearestIndx = 0;
  for (let i = 0; i < clientExpiryOptions.length; i++) {
    const { value } = clientExpiryOptions[i];
    if (value !== 'never') {
      const diff = Math.abs(
        differenceInMilliseconds(
          add(new Date(), value as Duration),
          new Date(ISODate),
        ),
      );
      if (diff < smallestDiff) {
        smallestDiff = diff;
        nearestIndx = i;
      }
    }
  }
  return clientExpiryOptions[nearestIndx].value;
};
