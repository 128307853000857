import React from 'react';
import {
  object, string,
} from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';

import List from './List';
import SingleEmail from './Email';

const EmailsRoutes = ({ match }) => {
  return (
    <Box padding="medium">
      <Typography variant="h2" gutterBottom>Email Template Management</Typography>

      <Box>
        <Switch>
          <Route
            path={`${match.path}/:templateId`}
            component={SingleEmail}
          />
          <Route
            path={`${match.path}`}
            component={List}
          />
        </Switch>
      </Box>
    </Box>
  );
};

EmailsRoutes.propTypes = {
  match: object.isRequired,
  parentPath: string,
};

export default EmailsRoutes;
