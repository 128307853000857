import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { Lock } from '@material-ui/icons';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import { AccountProps } from 'shared/modules/account/types';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import Box from 'shared/styleguide/atoms/Box';
import Button, { ButtonStatus } from 'shared/styleguide/atoms/Buttons/NewButton';
import { TYPE_CREDENTIALS } from 'admin/modules/notes/constants';
import { useCreateNoteMutation } from 'admin/modules/notes/query';

interface RowProp {
  label: string;
  value: string;
  index: number;
  onChange: (value: string, index: number, type: string) => void;
  onRemove: (index) => void;
}

const CredentialRow = ({
  label, value, index, onChange, onRemove,
}: RowProp) => {
  if (['password', 'username'].includes(label.toLowerCase())) {
    return (
      <Box>
        <Box direction="row" align="center" justify="space-between">
          <Box direction="row" gap="small" flex={1}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              id="label"
              placeholder="Label"
              value={label}
              onChange={(event) => { onChange(event.target.value, index, 'label'); }}
            />

            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Value"
              type={label.toLowerCase() === 'password' ? 'password' : 'text'}
              value={value}
              onChange={(event) => { onChange(event.target.value, index, 'value'); }}
            />
          </Box>

          <IconButton onClick={() => onRemove(index)}>
            <RemoveIcon />
          </IconButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box direction="row" align="center" justify="space-between">
      <Box flex={1} gap="small">
        <div>
          <Typography variant="h5"><strong>Label</strong></Typography>
          <TextField
            fullWidth
            variant="outlined"
            id="label"
            placeholder="e.g. Username"
            defaultValue={label}
            onChange={(event) => { onChange(event.target.value, index, 'label'); }}
          />
        </div>
        <div>
          <Typography variant="h5"><strong>Value</strong></Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="e.g. SomeUser"
            defaultValue={value}
            rows={3}
            minRows={3}
            multiline
            onChange={(event) => { onChange(event.target.value, index, 'value'); }}
          />
        </div>
      </Box>

      <IconButton onClick={() => onRemove(index)}>
        <RemoveIcon />
      </IconButton>
    </Box>
  );
};

export const SubmitNote = ({ account }: { createNote: (note: Record<string, any>) => Promise<void>; account: AccountProps }) => {
  const [status, setStatus] = useState('initial');
  const [createNote, result] = useCreateNoteMutation();

  const initialItems = [
    {
      label: 'Username', value: '', type: '', key: (+new Date()),
    },
    {
      label: 'Password', value: '', type: 'password', key: (+new Date() + 2),
    },
    {
      label: '', value: '', type: '', key: (+new Date() + 4),
    },
  ];
  const [items, setItems] = useState(initialItems);

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        label: '', value: '', type: '', key: (+new Date()),
      },
    ]);
  };

  const removeItem = (index) => {
    setItems([
      ...items.slice(0, index),
      ...items.slice(index + 1),
    ]);
  };

  const updateItem = (value, index, type) => {
    const newItem = {
      ...items[index],
      [type]: value,
    };

    setItems([
      ...items.slice(0, index),
      newItem,
      ...items.slice(index + 1),
    ]);
  };

  const handleSubmit = async () => {
    const body = {
      accountId: account.id,
      accountName: account.name,
      title: 'User-Submitted credentials',
      noteType: TYPE_CREDENTIALS,
      noteContentType: 'application/json',
      note: JSON.stringify(items.map((item) => ({ label: item.label, value: item.value }))),
    };

    setStatus('loading');

    try {
      await createNote(body);

      setStatus('success');

      setTimeout(() => {
        setStatus('initial');
        setItems(initialItems);
      }, 3000);
    } catch (err) {
      setStatus('initial');
    }
  };

  return (
    <Box flex="1 1 50%">
      <Typography variant="h3" gutterBottom>Submit Secure Notes</Typography>
      <Box as={Paper}>
        {
          items.map((item, index) => {
            return (
              <>
                {
                index > 0 && (
                  <Divider />
                )
              }
                <Box padding="medium">
                  <CredentialRow
                    key={item.key}
                    {
                    ...item
                    }
                    index={index}
                    onChange={updateItem}
                    onRemove={removeItem}
                  />
                </Box>
              </>
            );
          })
        }
        <Divider />

        <Box direction="row" justify="space-between" padding="medium">
          <Button variant="outlined" onClick={handleAddItem} startIcon={<AddIcon />}>
            Add Row
          </Button>

          <Button variant="contained" status={status as ButtonStatus} onClick={handleSubmit} startIcon={<Lock />}>
            Submit
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

export default connect(
  (state) => ({
    // @ts-ignore
    account: state.account,
  }),
)(SubmitNote);
