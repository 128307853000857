import React from 'react';
import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Linker from 'shared/styleguide/atoms/Links/Linker';

const Links = () => (
  <Box align="center" css={{ textAlign: 'center' }}>
    <Box align="center" margin={{ bottom: 'medium' }} direction="column" gap="xsmall">
      <Button fullWidth component={Linker} to="/password-reset/" color="secondary">
        Forgot Password
      </Button>
      <Button fullWidth component={Linker} to="/mfa-reset/" color="secondary">
        Disable 2-Factor Authentication
      </Button>
    </Box>
    <Typography variant="body2" component="p">If you need additional help, <br />please email <TextLink href="mailto:support@pagely.com">support@pagely.com</TextLink>.</Typography>
    <Box align="center" margin={{ top: 'medium' }} direction="column">
      <Button color="default" component={Linker} to="/login">Back To Login</Button>
    </Box>
  </Box>
);

export default Links;
