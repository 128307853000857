import React, { Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';

import { css } from '@emotion/react';
import Loading from 'shared/styleguide/atoms/Loading';

import Empty from 'shared/styleguide/atoms/Empty';
import { ErrorText } from 'shared/styleguide/typography';
import { setMode, deleteReleaseNote } from 'shared/modules/releasenotes/redux/actions';
import Box from 'shared/styleguide/atoms/Box';
import ReleaseNote from './ReleaseNote';

const ViewReleaseNotes = (props) => {
  const {
    releaseNotes, isAdmin,
  } = props;

  if (releaseNotes.status === 'loading') {
    return (
      <Loading />
    );
  }

  if (releaseNotes.status === 'failed') {
    return (
      <Empty><ErrorText>Failed to load release notes</ErrorText></Empty>
    );
  }

  if (releaseNotes.status === 'success' && releaseNotes.data.length === 0) {
    return (
      <Empty>There are no release notes to view</Empty>
    );
  }

  // place drafts at the top of the list, independant of date
  const sortedNotes = {
    'draft': [],
    'published': [],
  };
  releaseNotes.data.forEach((note) => {
    const len = sortedNotes[note.status].length;
    sortedNotes[note.status][len] = note;
  });

  return (
    <Box css={css`border-left: 1px solid var(--color-200);`}>
      {sortedNotes.draft.length > 0
        && sortedNotes.draft.map((note) => (
          <Fragment key={`rn-${note.releaseDate}`}>
            <ReleaseNote
              note={note}
              setMode={props.setMode}
              deleteReleaseNote={props.deleteReleaseNote}
              isAdmin={isAdmin}
            />
          </Fragment>
        ))}
      {sortedNotes.published.length > 0
        && sortedNotes.published.map((note) => (
          <Fragment key={`rn-${note.releaseDate}`}>
            <ReleaseNote
              note={note}
              setMode={props.setMode}
              deleteReleaseNote={props.deleteReleaseNote}
              isAdmin={isAdmin}
            />
          </Fragment>
        ))}
    </Box>
  );
};

ViewReleaseNotes.propTypes = {
  deleteReleaseNote: func.isRequired,
  isAdmin: bool,
  releaseNotes: object.isRequired,
  setMode: func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    releaseNotes: state.releaseNotes,
  };
};

const mapDispatchToProps = {
  setMode, deleteReleaseNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewReleaseNotes);
