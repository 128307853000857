import React, { useEffect, useState } from 'react';

import { Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { remMapper } from 'shared/styleguide/theme/spacing';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';
import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';
import { ErrorText } from 'shared/styleguide/typography';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import {
  useGetAccountLimitsByAccountIdQuery,
  useGetSubscriptionBySubscriptionIdAndAccountIdQuery,
  useGetSubscriptionIdsByAccountIdQuery,
  useGetSubscriptionPlansAndAddonCatalogQuery,
  useUpdateSubscriptionPlanMutation,
} from '../../query';
import { planIdToName } from './constants';
import { PlanOptions } from './PlanOptions';
import { LimitedAddons } from './LimitedAddons';
import { PlanSummary } from './PlanSummary';
import { PlanHeader } from './PlanHeader';
import { AddonRows } from './AddonRows';
import {
  additionalAddonsFilter,
  extractAddons,
  isLimitedAddon,
  marshalledAddons,
} from './addonUtils';
import { PlanData } from './types';
import { Billing } from '../../types';

export const Plan = (): JSX.Element => {
  const { billingCustomerId, accountId } = useSelector((state: any) => ({ billingCustomerId: state.account?.billingCustomerId, accountId: state.account?.id }));
  const { data: subscriptionIds, isSuccess: subscriptionIdsObtained, error: subscriptionIdsError } = useGetSubscriptionIdsByAccountIdQuery(billingCustomerId, { skip: !billingCustomerId });
  const {
    data: subscription, isSuccess: subscriptionObtained, error: subscriptionError, isFetching: isFetchingSubscription,
  } = useGetSubscriptionBySubscriptionIdAndAccountIdQuery({ accountId: billingCustomerId, subscriptionId: subscriptionIds?.[0] }, { skip: !subscriptionIdsObtained && !subscriptionIds?.[0] });
  const {
    data: limits, isSuccess: limitsObtained, error: limitsError,
  } = useGetAccountLimitsByAccountIdQuery(accountId, { skip: !accountId });
  const [switchPlanMutation, { isLoading: isSwitchingPlan }] = useUpdateSubscriptionPlanMutation();
  const { data: catalog, isSuccess: catalogObtained } = useGetSubscriptionPlansAndAddonCatalogQuery(null);
  const planDataLoaded = billingCustomerId && subscriptionIdsObtained && subscriptionObtained && limitsObtained && catalogObtained;
  const errorMessageToErrors = {
    'Error fetching Subscription Ids': subscriptionIdsError,
    'Error fetching Subscriptions': subscriptionError,
    'Error fetching Plan Limits': limitsError,
  };
  const errorFetchingData = Object.values(errorMessageToErrors).some(Boolean);

  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);

  const handlePlanClick = (planId: string) => {
    setSelectedPlanId(planId);
  };

  // Limits data takes a while to update (~10 seconds) so we optmistically display whatever
  // they've selected otherwise it would display old data.
  const [optimisticLimitsData, setOptimisticLimitsData] = useState(null);
  const [shouldUseOptimisticLimitsData, setShouldUseOptimisticLimitsData] = useState(false);

  const switchPlan = () => {
    if (selectedPlanId) {
      switchPlanMutation({
        customerId: billingCustomerId,
        subscriptionId: subscriptionIds?.[0],
        planId: selectedPlanId,
        billingFrequency: subscription.billingFrequency,
      });
      setShouldUseOptimisticLimitsData(true);
    }
  };

  let content: JSX.Element = (
    <div data-testid="loading">
      <LoadingDots />
    </div>
  );

  const noSubscriptions = (subscriptionIdsObtained && subscriptionIds?.length === 0) || !billingCustomerId;
  if (noSubscriptions) {
    content = (
      <Paper>
        <Box data-testid="no-subs" padding="small">
          <Empty>There are no active subscriptions</Empty>
        </Box>
      </Paper>
    );
  }

  if (errorFetchingData) {
    content = (
      <>
        {
          Object.entries(errorMessageToErrors).filter(([_, hasError]) => Boolean(hasError)).map(([message, _]) => <ErrorText key={message}>{message}</ErrorText>)
        }
      </>
    );
  }
  const [displayedPlanData, setDisplayedPlanData] = useState<PlanData | null>(null);
  const [limitedAddons, setLimitedAddons] = useState(null);
  const [currentPlanData, setCurrentPlanData] = useState(null);

  useEffect(() => {
    if (planDataLoaded) {
      const addonEntries = Object.entries(subscription.addons);
      const limitedAddonEntries = addonEntries.filter(([addonKey, _]) => isLimitedAddon(addonKey));
      setLimitedAddons(Object.fromEntries(limitedAddonEntries));
    }
  }, [planDataLoaded, subscription]);

  useEffect(() => {
    if (planDataLoaded) {
      const planCost = subscription.planAmount / 100;
      const additionalCost = Object.entries(subscription.addons).reduce((total, [_, addon]) => total + addon.unitPrice * addon.quantity, 0) / 100;
      const totalCost = planCost + additionalCost;
      setCurrentPlanData({
        billingFrequency: subscription.billingFrequency,
        planCost,
        additionalCost,
        totalCost,
        planId: subscription.planId,
        bandwidth: subscription.addons['included-bandwidth']?.quantity || 0,
        cdn: subscription.addons['included-cdn']?.quantity || 0,
      });
    }
  }, [planDataLoaded, subscription]);

  useEffect(() => {
    if (currentPlanData) {
      setDisplayedPlanData(formatDisplayedPlanData({
        selectedPlanId, subscription, limits, catalog, optimisticLimitsData, shouldUseOptimisticLimitsData, currentPlanData, limitedAddons,
      }));
    }
  }, [currentPlanData, selectedPlanId, subscription, limits, catalog, optimisticLimitsData, shouldUseOptimisticLimitsData, limitedAddons]);

  if (planDataLoaded && displayedPlanData && currentPlanData) {
    content = (
      <div
        data-testid="main-content"
        css={{
          display: 'flex',
          gap: remMapper('mediumLarge'),
        }}
      >
        <ErrorBoundary>
          <aside css={{ flex: 1 }}>
            <PlanOptions
              currentPlanPrice={currentPlanData.planCost}
              currentFrequency={currentPlanData.billingFrequency}
              currentPlanId={currentPlanData.planId}
              currentBandwidth={currentPlanData.bandwidth}
              currentCDN={currentPlanData.cdn}
              selectedPlanId={selectedPlanId}
              setSelectedPlanId={handlePlanClick}
            />
          </aside>
        </ErrorBoundary>
        <section css={(theme) => ({ flex: 2.5, color: theme.color900 })}>
          <header css={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: remMapper('medium'),
          }}
          >
            <PlanHeader planData={displayedPlanData} />
          </header>
          <section
            css={{
              marginBottom: remMapper('medium'),
            }}
          >
            <AddonRows planData={displayedPlanData} />
          </section>
          <section
            css={{
              marginBottom: remMapper('medium'),
            }}
          >
            <LimitedAddons addons={displayedPlanData.limitedAddons} limits={displayedPlanData.limits} />
          </section>
          <div>
            <PlanSummary
              planData={displayedPlanData}
              isSwitchingPlan={isSwitchingPlan}
              selectedPlanId={selectedPlanId}
              isFetchingSubscription={isFetchingSubscription}
              subscriptionPlanId={subscription.planId}
              switchPlan={switchPlan}
              setOptimisticLimitsData={setOptimisticLimitsData}
            />
          </div>
        </section>
      </div>
    );
  }
  return content;
};

function formatDisplayedPlanData(
  {
    selectedPlanId, subscription, limits, catalog, optimisticLimitsData, shouldUseOptimisticLimitsData, currentPlanData, limitedAddons,
  },
): PlanData {
  const {
    server: serverAddons, database: databaseAddons, regions: regionAddons, other: otherAddons,
  } = marshalledAddons(subscription.addons);
  const shouldPreviewPlanChange = selectedPlanId && selectedPlanId !== subscription.planId;
  if (shouldPreviewPlanChange) {
    const [_planId, selectedPlan] = Object.entries<Billing.SubscriptionPlansAndAddonCatalog.Plan>(catalog.plans).find(([planId, _plan]) => planId === selectedPlanId);
    let {
      server: selectedServerAddons, database: selectedDatabaseAddons, other: selectedOtherAddons,
    } = marshalledAddons(selectedPlan.addons);
    // preserve additional addons
    selectedDatabaseAddons = selectedDatabaseAddons.concat(databaseAddons.filter(additionalAddonsFilter));
    selectedServerAddons = selectedServerAddons.concat(serverAddons.filter(additionalAddonsFilter));
    selectedOtherAddons = selectedOtherAddons.concat(otherAddons.filter(additionalAddonsFilter));
    const selectedLimitedAddons = extractAddons(selectedPlan.addons, isLimitedAddon);

    const selectedPlanCost = selectedPlan[`${currentPlanData.billingFrequency}Price`].price / 100;
    const selectedPlanLimits = JSON.parse(JSON.stringify(limits));

    const additionalAddonsEntries = Object.entries<Billing.SubscriptionBySubscriptionIdAndAccountId.Addon>(subscription.addons).filter(([_, addon]) => additionalAddonsFilter(addon));
    const additionalAppQuantity = additionalAddonsEntries.filter(([addonKey, _]) => addonKey.match(/wordpress/)).reduce((total, [_, addon]) => total + addon.quantity, 0);
    const additionalBandwidthQuantity = additionalAddonsEntries.filter(([addonKey, _]) => addonKey.match(/bandwidth/)).reduce((total, [_, addon]) => total + addon.quantity, 0);
    const additionalDiskQuantity = additionalAddonsEntries.filter(([addonKey, _]) => addonKey.match(/storage/)).reduce((total, [_, addon]) => total + addon.quantity, 0);
    const additionalCDNQuantity = additionalAddonsEntries.filter(([addonKey, _]) => addonKey.match(/cdn/)).reduce((total, [_, addon]) => total + addon.quantity, 0);

    selectedPlanLimits.apps.limit = selectedPlan.addons['included-wordpress-apps'].quantity + additionalAppQuantity;
    selectedPlanLimits.bandwidth.limit = selectedPlan.addons['included-bandwidth'].quantity + additionalBandwidthQuantity;
    selectedPlanLimits.disk.limit = selectedPlan.addons['included-storage-ebs'].quantity + additionalDiskQuantity;
    selectedPlanLimits.cdn.limit = selectedPlan.addons['included-cdn'].quantity + additionalCDNQuantity;

    const appUsageInRange = selectedPlanLimits.apps.limit >= limits.apps.used;
    const bandwidthUsageInRange = selectedPlanLimits.bandwidth.limit >= limits.bandwidth.used;
    const diskUsageInRange = selectedPlanLimits.disk.limit >= limits.disk.used;
    const cdnUsageInRange = selectedPlanLimits.cdn.limit >= limits.cdn.used;
    const ableToSwitchPlans = appUsageInRange && bandwidthUsageInRange && diskUsageInRange && cdnUsageInRange;

    return {
      planName: selectedPlan.name,
      planCost: selectedPlanCost,
      totalCost: selectedPlanCost + currentPlanData.additionalCost,
      additionalCost: currentPlanData.additionalCost, // should stay the same for now until more granular control is added?
      billingFrequency: currentPlanData.billingFrequency,
      nextBillingDate: subscription.nextBillingAt,
      serverAddons: selectedServerAddons,
      databaseAddons: selectedDatabaseAddons,
      regionAddons, // stays the same
      otherAddons: selectedOtherAddons,
      limitedAddons: selectedLimitedAddons,
      limits: selectedPlanLimits,
      planId: selectedPlanId,
      ableToSwitchPlans,
    };
  }
  return {
    // return the current plan data
    planName: planIdToName[subscription.planId] || subscription.plan,
    billingFrequency: currentPlanData.billingFrequency,
    nextBillingDate: subscription.nextBillingAt,
    planCost: currentPlanData.planCost,
    serverAddons,
    databaseAddons,
    regionAddons,
    otherAddons,
    limitedAddons,
    limits: shouldUseOptimisticLimitsData ? optimisticLimitsData : limits,
    planId: subscription.planId,
    additionalCost: currentPlanData.additionalCost,
    totalCost: currentPlanData.totalCost,
  };
}

export default Plan;
