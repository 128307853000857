import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';

import {
  getAllValidations,
} from 'shared/modules/app/redux/dns/actions';

import DNSList from './DNSList';

const DNS = (props) => {
  const {
    dns, app, getAllValidations,
  } = props;
  const appId = app.data.id;
  const isFsaEnabled = app.data.fsa?.status === 'active';

  useEffect(() => {
    if (dns.status === 'pristine' || (dns.params.appId && dns.params.appId !== appId)) {
      getAllValidations(appId);
    }
  }, [appId, dns, getAllValidations]);

  return (
    <div>
      <Box>

        {
          (dns.status === 'loading' || !app.data?.aliases)
            // also validations goes here
            ? (
              <Loading />
            )
            : (
              <DNSList validationRecords={dns.data} domains={app.data.aliases} isFsaEnabled={isFsaEnabled} />
            )
        }
      </Box>
    </div>
  );
};

DNS.propTypes = {
  account: object,
  app: object,
  dns: object,
  getAllValidations: func,
};

export default connect(
  (state) => ({
    account: state.account,
    app: state.app.app,
    dns: state.app.dns,
  }),
  {
    getAllValidations,
  },
)(DNS);
