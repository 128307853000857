import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import Success from 'shared/styleguide/atoms/Success';
import Loading from 'shared/styleguide/atoms/Loading';
import RaisedButton from 'shared/styleguide/atoms/Buttons/NewButton';
import { emailAddressVerifyRequest } from 'shared/modules/account/redux/actions';
import { jssStyles, inlineStyles } from './styles';

export class VerifyEmail extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    errMessage: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    requestErr: PropTypes.bool,
    statusCode: PropTypes.number,
    verifyChangeEmail: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { match: { params }, verifyChangeEmail } = this.props;
    const { token, accountID } = params;

    verifyChangeEmail(accountID, { token });
  }

  renderSuccess = () => {
    const { classes, match, history } = this.props;
    return (
      <div className={classes.successRoot}>
        <div className={classes.closeButton}>
          <IconButton
            onClick={() => history.push(`/account/${match.params.accountID}`)}
          >
            <CloseIcon {...inlineStyles.closeIcon} />
          </IconButton>
        </div>
        <div className={classes.successContent}>
          <Success />
          <div>We&apos;ve successfully updated your email.</div>
        </div>
      </div>
    );
  };

  renderLoading = () => {
    return (
      <Loading margin={{}} text="Verifying your email address..." color="secondary" />
    );
  };

  renderVerificationError = (errMessage) => {
    const {
      classes, match, isFetching, history, statusCode,
    } = this.props;
    const errMessageArray = errMessage.indexOf('.') > -1
      ? errMessage.split(/[,.]/).filter((item) => { return item.trim() !== ''; }) : null;

    const getErrorMessages = () => {
      if (Array.isArray(errMessageArray) && errMessageArray.length > 1) {
        return errMessageArray.slice(1, errMessageArray.length).map((message) => (
          <span key={message}>{message}<br /></span>
        ));
      }
      return null;
    };

    return (
      <div className={classes.errorRoot}>
        <div className={classes.closeButton}>
          <IconButton
            onClick={() => history.push(`/account/${match.params.accountID}`)}
          >
            <CloseIcon {...inlineStyles.closeIcon} />
          </IconButton>
        </div>
        <div className={classes.errorContent}>
          <span className={classes.primaryErrorMessage}>{`${(errMessageArray && errMessageArray[0]) || errMessage}.`}<br /></span>
          <div className={classes.secondaryErrorMessage}>
            {getErrorMessages()}
          </div>
          {
            statusCode === 422 ? (
              <RaisedButton
                variant="contained"
                label="Go to Settings"
                disabled={isFetching}
                onClick={() => history.push(`/account/${match.params.accountID}/settings`)}
                {...inlineStyles.actionButton}
              />
            ) : null
          }
        </div>
      </div>
    );
  };

  render() {
    const {
      classes, isFetching, requestErr, errMessage, statusCode,
    } = this.props;
    let content;

    if (!isFetching && requestErr && errMessage.length > 0) {
      switch (statusCode) {
        case 422:
          content = this.renderVerificationError(
            'Your verification token has expired. Please re-enter your email and we\'ll send a new confirmation',
          );
          break;
        default:
          content = this.renderVerificationError(errMessage);
          break;
      }
    }

    if (!isFetching && !requestErr) {
      content = this.renderSuccess();
    }

    return (
      <Dialog
        open
      >
        <DialogContent className={classes.root}>
          {content || this.renderLoading()}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.requests.emailVerifyRequest.isFetching,
});

const mapDispatchToProps = {
  verifyChangeEmail: emailAddressVerifyRequest,
};

export default withStyles(jssStyles)(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail),
);
