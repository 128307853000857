import React, { Fragment, useState } from 'react';
import {
  object, string, number, bool,
} from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import {
  Typography, RadioGroup, Radio, FormControlLabel, FormControl,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import colors from 'shared/styleguide/theme';
import { LegendTypography } from 'shared/modules/disk/routes/DiskSummary/Graphs/graphUtils';

import graphColors from 'shared/styleguide/theme/graphColors';

import { remMapper } from 'shared/styleguide/theme/spacing';
import { NumberLike } from 'shared/types/types';
import { getFirstAndLastDayIndexes } from '../analyticsUtils';
import Line from './types/Line';
import { generateColor } from './generateColors';

const creditColor = '#EEA320';
const cpuColor = '#29ABE2';

const lineSettings = {
  lineTension: 0,
  borderCapStyle: 'butt',
  borderWidth: 3,
  pointBorderWidth: 3,
  pointHoverRadius: 5,
  pointRadius: 0,
  pointHitRadius: 10,
  spanGaps: true,
};

const dataExistsIndex = {
  cpu: 2,
  byMount: 5,
  byPath: 3,
};

const Figure = (props) => {
  const {
    path, apps, data, columns, legendPosition, showXLabel, showYLabel, maxXTicks, maxYTicks, theme,
    leftAxis, rightAxis,
  } = props;
  const { series, meta } = data;

  const yAxes = [];
  const datasets = [];

  // Option per Server ID
  const options = {
    legend: {
      position: legendPosition || 'top',
      usePointStyle: true,
    },
    animation: false,
    responsiveAnimationDuration: 0,
  };

  const label = Object.entries(meta.filters)
    .filter(([key, value]: [string, string]) => key !== 'accountId' && value.length > 0)
    .map(([key, value]) => {
      if (key === 'type') {
        return `type:${value}`;
      }

      if (meta.filters.type?.length && ['appIds', 'username'].includes(key)) {
        return null;
      }

      if (path === 'byPath' && !meta.filters.type?.length && key === 'location') {
        return 'type:location';
      }
      return key;
    });

  const leftColors = graphColors;
  const rightColors = generateColor(creditColor, colors.yellow, series.length);

  const gridColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  // we want to make a new line for every entity in the series
  series.forEach((entity, index) => {
    const leftIndex = entity.columns.indexOf(leftAxis);
    if (leftIndex > -1) {
      const leftData = entity.values
        .filter((v, i) => {
          return !(getFirstAndLastDayIndexes(entity.values).includes(i) && !v[dataExistsIndex[path]]);
        })
        .map((v) => ({
          y: v[leftIndex],
          t: new Date(v[0]),
        }));

      const yMaxLeft = Math.max.apply(null, leftData.map((v) => v.y));
      yAxes.push({
        id: leftAxis,
        type: 'linear',
        position: 'left',
        ticks: {
          precision: 1,
          suggestedMax: yMaxLeft + 1,
          suggestedMin: 0,
          fontColor: props.theme.palette.text.secondary,
        },
        gridLines: {
          color: gridColor,
        },
      });

      let seriesLabel;
      const entityTags = Object.entries(entity.tags);
      if (entityTags.length === 1) {
        // eg type:location
        seriesLabel = entityTags.map((entry) => entry.join(': '));
      } else {
        // eg serverName
        seriesLabel = entityTags.filter(([key, _]) => key !== 'type').map(([tagLabel, value]: [string, NumberLike]) => {
          const niceLabel = tagLabel === 'appIds' ? 'app' : tagLabel;
          const niceValue = (tagLabel === 'appIds' && apps[value]?.name) ? `${value} - ${apps[value]?.tagLabel || apps[value]?.name}` : value;
          return [niceLabel, niceValue].join(': ');
        });
      }

      datasets.push({
        axis: 'left',
        data: leftData,
        label: seriesLabel,
        yAxisID: leftAxis,
        ...lineSettings,
        borderWidth: path === 'cpu' ? 2 : 3,
        borderColor: leftColors[index],
        pointBorderColor: leftColors[index],
        pointBackgroundColor: '#ffffff',
        backgroundColor: `${leftColors[index]}38`,
        fill: path === 'cpu',
      });
    }

    const rightIndex = entity.columns.indexOf(rightAxis);
    if (rightIndex > -1) {
      const rightData = entity.values.map((v) => ({
        y: v[rightIndex],
        t: new Date(v[0]),
      }));

      const yMaxRight = Math.max.apply(null, rightData.map((v) => v.y));
      const yMinRight = Math.min.apply(null, rightData.map((v) => v.y));

      yAxes.push(
        {
          id: rightAxis,
          type: 'linear',
          position: 'right',
          ticks: {
            precision: 1,
            suggestedMax: yMaxRight + 1,
            suggestedMin: 0,
            maxTicksLimit: maxYTicks || null,
            fontColor: props.theme.palette.text.secondary,
          },
          scaleLabel: {
            display: showYLabel || false,
          },
          gridLines: {
            color: gridColor,
          },
        },
      );

      datasets.push({
        axis: 'right',
        data: rightData,
        yAxisID: rightAxis,
        label: [columns.ordered[rightIndex].label],
        ...lineSettings,
        borderColor: rightColors[index],
        pointBorderColor: rightColors[index],
        backgroundColor: `${rightColors[index]}10`,
        fill: path === 'cpu',
      });
    }
  });

  let skipCount = 0;
  const legend = datasets.map((item, i) => {
    const backgroundColor = item.axis === 'right' ? item.pointBorderColor : graphColors[i - skipCount];
    if (item.axis === 'right') skipCount++; // make sure we're grabbing the same color as the datasets
    return (
      <Box direction="row" gap="xsmall" padding={{ top: 'xsmall' }} margin={{ left: 'medium' }} key={`legend-${item.label.join('-')}`}>
        <Box style={{ backgroundColor, width: 14, minWidth: 14 }} />
        <Box wrap="wrap">
          {
            item.label.map((legendLabel) => {
              return <LegendTypography key={`legend-legendLabel-${legendLabel}`}>{legendLabel}</LegendTypography>;
            })
          }
        </Box>
      </Box>
    );
  });

  return (
    <Fragment>
      <Box margin={{ bottom: 'medium' }}>
        <Typography variant="h5">
          {
                `By ${label.filter(Boolean).join(', ')}`
              }
        </Typography>
      </Box>
      <Box flex={1} direction="row">
        <Box style={{ width: 'calc(100% - 200px)', height: 500 }}>
          <Line
            data={{
              datasets,
            }}
            options={{
              ...options,
              maintainAspectRatio: false,
              title: {
                display: false,
                text: `${series[0].name}${label.length > 0 ? ` by ${label.filter(Boolean).join(', ')}` : ''}`,
              },
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
                titleFontColor: '#000',
                borderColor: '#CCC',
                cornerRadius: 0,
                borderWidth: 1,
                backgroundColor: '#FFF',
                bodyFontColor: '#000',
                bodySpacing: 10,
                callbacks: {
                  labelColor: (tooltipItem, chart) => {
                    const color = chart.data.datasets[tooltipItem.datasetIndex].borderColor;
                    return {
                      backgroundColor: color,
                      borderColor: '#FFF',
                      borderWidth: 0,
                    };
                  },
                },
              },
              scales: {
                xAxes: [{
                  type: 'time',
                  time: {
                    displayFormats: {
                      day: 'MMM D, YY',
                      hour: 'Do, hA',
                    },
                    round: 'minute',
                    minUnit: 'minute',
                  },
                  ticks: {
                    maxTicksLimit: maxXTicks || null,
                    fontColor: props.theme.palette.text.secondary,
                  },
                  scaleLabel: {
                    display: showXLabel || false,
                  },
                  gridLines: {
                    color: gridColor,
                  },
                }],
                yAxes,
              },
            }}
          />
        </Box>
        <Box
          style={{
            minWidth: 250, maxWidth: 250, marginTop: 4,
          }}
          direction="column"
        >
          <Box
            style={{
              height: 442, overflow: 'auto',
            }}
            justify="flex-start"
            direction="column"
            gap="xsmall"
          >
            {legend}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

interface GraphProps {
  apps?: Record<string, any>;
  columns: {
    yAxis: any[];
    xAxis: any[];
  };
  data: {
    filter: Record<string, any>;
    series: Record<string, any>;
    meta: {
      filters: Record<string, any>;
    };
  };
  defaultLeftAxis?: string;
  defaultRightAxis?: string;
  fromDate?: string;
  legendPosition?: string;
  maxXTicks?: number;
  maxYTicks?: number;
  path?: string;
  showXLabel?: boolean;
  showYLabel?: boolean;
  theme: any;
  toDate?: string;
}

const Graph = (props: GraphProps) => {
  const [leftAxis, setLeftAxis] = useState(props.defaultLeftAxis || 'cpu_max');
  const [rightAxis, setRightAxis] = useState(props.defaultRightAxis || 'balance_min');

  const {
    path, apps, data, columns, legendPosition, showXLabel, showYLabel, maxXTicks, maxYTicks, theme,
  } = props;
  const { series, meta } = data;

  return (
    <Fragment>
      <Box direction="row" padding="xsmall">
        <FormControl component="fieldset">
          <RadioGroup aria-label="leftAxis" name="yAxesLeft" value={leftAxis} onChange={(e) => { setLeftAxis(e.target.value); }} row>
            {
                (columns.yAxis)
                  .filter((axis) => !axis.rightAxis)
                  .map((axis) => (
                    <FormControlLabel key={axis.name} value={axis.name} control={<Radio />} label={axis.label} />
                  ))
              }
          </RadioGroup>
        </FormControl>
        {
          (columns.yAxis.filter((axis) => !axis.rightAxis)).length > 0 && (
            <FormControl component="fieldset" css={{ borderLeft: '1px solid black', paddingLeft: remMapper('small') }}>
              <RadioGroup aria-label="rightAxis" name="yAxesRight" value={rightAxis} onChange={(e) => { setRightAxis(e.target.value); }} row>
                {
                  (columns.yAxis)
                    .filter((axis) => axis.rightAxis)
                    .map((axis) => (
                      <FormControlLabel key={axis.name} value={axis.name} control={<Radio />} label={axis.label} />
                    ))
                }
              </RadioGroup>
            </FormControl>
          )
        }
      </Box>
      <Box padding="xsmall">
        <Figure {...props} rightAxis={rightAxis} leftAxis={leftAxis} />
      </Box>
    </Fragment>
  );
};

export default withTheme(Graph);
