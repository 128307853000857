import makeApiAction from 'shared/utils/redux/apiActions';
import * as INTEGRATIONS from './constants';

export const fetchIntegrationsList = (accountId) => makeApiAction(
  ({ api }) => api().get(`/apps/integration?accountId=${accountId}`),
  INTEGRATIONS.LIST_INTEGRATIONS_REQUEST,
  INTEGRATIONS.LIST_INTEGRATIONS_SUCCESS,
  INTEGRATIONS.LIST_INTEGRATIONS_FAILURE,
  { accountId },
);

export const createIntegration = (data, actionOnly) => {
  return makeApiAction(
    ({ api }) => api().post('/apps/integration', {
      ...data,
    }),
    INTEGRATIONS.CREATE_INTEGRATION_REQUEST,
    INTEGRATIONS.CREATE_INTEGRATION_SUCCESS,
    INTEGRATIONS.CREATE_INTEGRATION_FAILURE,
    null,
    true,
    actionOnly,
  );
};

export const updateIntegration = (integrationId, data) => makeApiAction(
  ({ api }) => api().patch(`/apps/integration/${integrationId}`, {
    ...data,
  }),
  INTEGRATIONS.UPDATE_INTEGRATION_REQUEST,
  INTEGRATIONS.UPDATE_INTEGRATION_SUCCESS,
  INTEGRATIONS.UPDATE_INTEGRATION_FAILURE,
  {
    integrationId,
  },
  true,
);

export const deleteIntegration = (integrationId) => makeApiAction(
  ({ api }) => api().delete(`/apps/integration/${integrationId}`),
  INTEGRATIONS.DELETE_INTEGRATION_REQUEST,
  INTEGRATIONS.DELETE_INTEGRATION_SUCCESS,
  INTEGRATIONS.DELETE_INTEGRATION_FAILURE,
  {
    integrationId,
  },
  true,
);

export const fetchDeployToken = (integrationId) => makeApiAction(
  ({ api }) => api().get(`/apps/integration/${integrationId}?includeToken=1`),
  INTEGRATIONS.GET_INTEGRATION_TOKEN_REQUEST,
  INTEGRATIONS.GET_INTEGRATION_TOKEN_SUCCESS,
  INTEGRATIONS.GET_INTEGRATION_TOKEN_FAILURE,
  {
    integrationId,
  },
  true,
);
