import React, { useEffect } from 'react';
import {
  object, func, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Typography,
} from '@material-ui/core';

import { getAppsById } from 'shared/modules/app/redux/selectors';
import { resetJobNotification } from 'shared/modules/status/redux/actions';
import Box from 'shared/styleguide/atoms/Box';
import { SUCCESS, FAILURE, ACTION_REQUIRED } from 'shared/modules/status/redux/constants';
import { getNotificationForUser } from 'shared/modules/status/redux/selectors';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Toast from 'shared/styleguide/molecules/Toast';

import useNotifications from '../hooks/useNotifications';

const NotificationLink = ({ statusData, onResetJobNotification }) => {
  const { url, text, callback } = statusData;

  const handleCallback = () => {
    onResetJobNotification();
    callback();
  };

  return <TextLink css={(theme) => ({ color: theme.color500 })} to={url} onClick={handleCallback}>{text}</TextLink>;
};

NotificationLink.propTypes = {
  onResetJobNotification: func,
  statusData: object,
};

export const JobNotification = ({
  account, job, permissions, location, apps, resetJobNotification, isPagelyAdmin,
}) => {
  const notification = useNotifications(job, account.id, apps, permissions, isPagelyAdmin);

  useEffect(() => {
    return function cleanup() {
      resetJobNotification();
    };
  }, []);

  useEffect(() => {
    // location changed
    resetJobNotification();
  }, [location]);

  if (!job || !notification) {
    return null;
  }

  const { overallStatus } = job;

  const handleClose = () => {
    resetJobNotification();
    notification[overallStatus].callback();
  };

  const getNotificationType = () => {
    switch (overallStatus) {
      case FAILURE:
        return 'danger';
      case ACTION_REQUIRED:
        return 'warning';
      default:
        return 'success';
    }
  };

  const getNotificationText = (text) => {
    let notificationResult;
    switch (overallStatus) {
      case SUCCESS:
        notificationResult = 'complete!';
        break;
      case ACTION_REQUIRED:
        notificationResult = 'has action required.';
        break;
      case FAILURE:
        notificationResult = 'failed.';
        break;
      default:
        break;
    }
    return `${text} ${notificationResult}`;
  };

  const message = (
    <Box direction="row" gap="xxsmall" align="center">
      <Typography color="inherit" variant="body2">{getNotificationText(notification.text)}</Typography>
      {
        notification[overallStatus]?.url !== location.pathname
        && (
          <Box direction="row" gap="xxsmall" align="center" margin={{ left: 'xxsmall' }}>
            <Typography variant="body2">
              <NotificationLink statusData={notification[overallStatus]} onResetJobNotification={resetJobNotification} />
            </Typography>
          </Box>
        )
      }
    </Box>
  );

  return (
    <Toast
      isOpen={!!overallStatus}
      message={message}
      type={getNotificationType()}
      closable
      textColor="inherit"
      onClose={handleClose}
    />
  );
};

JobNotification.propTypes = {
  account: object,
  apps: object,
  isPagelyAdmin: bool,
  job: object,
  location: object,
  permissions: object,
  resetGitWebhookDeployJobs: func,
  resetJobNotification: func,
};

export default withRouter(connect(
  (state, ownProps) => {
    return {
      permissions: state.permissions,
      apps: getAppsById(state),
      job: getNotificationForUser(state, ownProps),
    };
  }, {
    resetJobNotification,
  },
)(JobNotification));
