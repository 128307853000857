import React from 'react';
import Box from 'shared/styleguide/atoms/Box';
import MigrationForm from './Migrations';
import SubmitNote from './SecureNote';

export const SecureNotes = () => {
  return (
    <Box direction="row" gap="medium" wrap="wrap">
      <MigrationForm />
      <SubmitNote />
    </Box>
  );
};

export default SecureNotes;
