import React, { useState } from 'react';
import {
  object, func, bool, oneOf, node, string, number, oneOfType,
} from 'prop-types';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import {
  Snackbar, SnackbarContent, IconButton, useTheme,
} from '@material-ui/core';
import { darkTheme } from 'shared/styleguide/theme/theme';

import Box from 'shared/styleguide/atoms/Box';
import InfoText from '../InfoText';

export const Toast = ({
  type = 'info',
  message = '',
  to = null,
  href = null,
  closable = false,
  isOpen = false,
  autoHideDuration = null,
  showIcon = true,
  textColor = 'textPrimary',
  onClose,
}) => {
  const [open, setOpen] = useState(isOpen);
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const content = (
    <Box flex={1} direction="row" gap="xsmall">
      <InfoText
        type={type}
        to={to}
        href={href}
        variant="body1"
        color={textColor}
        showIcon={showIcon}
        noMargin
      >
        {message}
      </InfoText>
    </Box>
  );

  const closeAction = closable ? (
    <Box direction="row" gap="xxsmall" align="center">
      <IconButton
        size="small"
        onClick={handleClose}
        style={{ color: theme.color100 }}
      >
        <Close color="inherit" fontSize="small" />
      </IconButton>
    </Box>
  ) : null;

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
    >
      <SnackbarContent
        message={content}
        action={closeAction}
        component="div"
      />
    </Snackbar>
  );
};

Toast.propTypes = {
  autoHideDuration: number,
  closable: bool,
  href: string,
  isOpen: bool,
  message: oneOfType([string, node, object]),
  onClose: func,
  showIcon: bool,
  textColor: string,
  to: string,
  type: string,
};

export default Toast;
