export const tagMap = {
  'paid': {
    color: 'green',
    label: 'paid',
  },
  'pending': {
    color: 'orange',
    label: 'pending',
  },
  'posted': {
    color: 'accent',
    label: 'posted',
  },
  'payment_due': {
    color: 'red',
    label: 'payment due',
  },
  'not_paid': {
    color: 'red',
    label: 'payment failed',
  },
  'voided': {
    color: 'accent',
    label: 'voided',
  },
};
