/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { sortValidations } from 'shared/modules/app/routes/DNS/utils.js';
import Box from 'shared/styleguide/atoms/Box';
import ValidationItem from 'shared/modules/app/routes/DNS/Validation/ValidationItem';
import InfoText from 'shared/styleguide/molecules/InfoText';
import { DNS_DOCS } from 'shared/utils/constants';
import { PCDN_REGEX } from 'shared/modules/dns/constants';

const DnsValidations = ({
  expanded,
  jobDetails,
  dnsJobs,
  validationRecords,
  fetchStatusJob,
  validationType,
}) => {
  const filteredDomains = validationRecords.filter((d) => !d.fqdn?.match(PCDN_REGEX));
  const { validations } = sortValidations(filteredDomains, [], validationType);

  return (
    <Fragment>
      <Box margin={{ top: 'xsmall' }}>
        <InfoText href={DNS_DOCS.url}>{DNS_DOCS.text}</InfoText>
      </Box>
      {
        validations.map((item) => (
          <Box key={item.domainId} direction="column" margin={{ top: 'small' }}>
            <ValidationItem
              domain={item}
              key={`validation-item-${item.domainId}`}
              job={item.jobId ? dnsJobs?.[item.jobId] : null}
              defaultExpanded={expanded}
              type={validationType}
            />
          </Box>
        ))
      }
    </Fragment>
  );
};

export default DnsValidations;
