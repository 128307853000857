import makeApiAction from 'shared/utils/redux/apiActions';
import * as FSA from './constants';

export const fetchFsaRecord = (appId) => makeApiAction(
  ({ api }) => {
    return api().get(`/fsa/apps/${appId}`);
  },
  FSA.GET_FSA_REQUEST,
  FSA.GET_FSA_SUCCESS,
  FSA.GET_FSA_FAILURE,
  { appId },
  true,
);

export const enableFsa = (appId, options) => makeApiAction(
  ({ api }) => api().post(`/fsa/apps/${appId}`, options),
  FSA.SETUP_FSA_REQUEST,
  FSA.SETUP_FSA_SUCCESS,
  FSA.SETUP_FSA_FAILURE,
  null,
  true,
);

export const disableFsa = (appId, options) => makeApiAction(
  ({ api }) => api().post(`/fsa/apps/${appId}/disable`, options),
  FSA.DISABLE_FSA_REQUEST,
  FSA.DISABLE_FSA_SUCCESS,
  FSA.DISABLE_FSA_FAILURE,
  null,
  true,
);
