import get from 'lodash/get';

import { deepClone } from 'shared/utils';

import {
  updatePropertyByPath, updatePropertyKeyByPath, updateMapKeyByPath, updateMapValByPath, getInitialOptions, appendSubProperty, appendSubMapProperty, appendProperty, updateOrder, appendRuleBlock, changeConfigType, undoable, deletePropertyByPath,
} from '../helpers';
import * as ARES_CONFIG from './constants';

const schema = (state = {}, action) => {
  switch (action.type) {
    case ARES_CONFIG.TOOLS_SCHEMA_PARSE:
      return action.payload;
    default:
      return state;
  }
};

const inputErrors = (state = {}, action) => {
  switch (action.type) {
    case ARES_CONFIG.TOOLS_SET_ERRORS:
      return action.payload;
    default:
      return state;
  }
};

const config = (state = {}, action) => {
  const newState = deepClone(state);
  switch (action.type) {
    case ARES_CONFIG.TOOLS_UPDATE_KEY:
      updatePropertyKeyByPath(newState, action.payload.path, action.payload.oldkey, action.payload.newkey);
      updatePropertyByPath(newState, `${action.payload.path}.${action.payload.newkey}`, getInitialOptions(`${action.payload.path}.${action.payload.newkey}`, action.payload.option, action.payload.type));
      return updateOrder(newState, action.payload.path, action.payload.option, action.payload.type);
    case ARES_CONFIG.TOOLS_UPDATE_ITEM:
      updatePropertyByPath(newState, action.payload.path, action.payload.value);
      return newState;
    case ARES_CONFIG.TOOLS_UPDATE_MAP_KEY:
      updateMapKeyByPath(newState, action.payload.path, action.payload.oldkey, action.payload.newkey);
      return newState;
    case ARES_CONFIG.TOOLS_UPDATE_MAP_VAL:
      updateMapValByPath(newState, action.payload.path, action.payload.value);
      return newState;
    case ARES_CONFIG.TOOLS_ADD_SUBPROPERTY:
      appendSubProperty(newState, action.payload.path, getInitialOptions(action.payload.path, action.payload.option, action.payload.type));
      return newState;
    case ARES_CONFIG.TOOLS_ADD_SUBMAPPROPERTY:
      appendSubMapProperty(newState, action.payload.path, getInitialOptions(action.payload.path, action.payload.option, action.payload.type));
      return newState;
    case ARES_CONFIG.TOOLS_ADD_PROPERTY:
      appendProperty(newState, `${action.payload.path}.${action.payload.propName}`, getInitialOptions(`${action.payload.path}.${action.payload.propName}`, action.payload.option, action.payload.type));
      return updateOrder(newState, action.payload.path, action.payload.option, action.payload.type);
    case ARES_CONFIG.TOOLS_ADD_RULEBLOCK:
      appendRuleBlock(newState, `${action.payload.path}.${action.payload.name}`, action.payload.option, action.payload.type);
      return updateOrder(newState, action.payload.path, action.payload.option, action.payload.type);
    case ARES_CONFIG.TOOLS_DELETE_RULEBLOCK:
      if (Object.keys(get(newState, action.payload.path)).length === 1) {
        deletePropertyByPath(newState, `${action.payload.path}`);
      } else {
        deletePropertyByPath(newState, `${action.payload.path}.${action.payload.blockIndex}`);
      }
      return newState;
    case ARES_CONFIG.TOOLS_DELETE_ITEM:
      deletePropertyByPath(newState, action.payload.path);
      return newState;
    case ARES_CONFIG.TOOLS_IMPORT_SUCCESS:
      return {
        config: action.payload.config,
      };
    case ARES_CONFIG.TOOLS_SUBMIT_REDIRECTS_WIZARD:
      return {
        config: action.payload.config,
      };
    case ARES_CONFIG.TOOLS_SHOW_CONFIG:
      return {
        config: action.payload.config,
      };
    case ARES_CONFIG.TOOLS_CHANGE_CONFIG:
      return changeConfigType(newState, action.payload.config, action.payload.source, action.payload.target, action.payload.option);
    default:
      return state;
  }
};

const undoableConfig = undoable(config);

const jsonOutput = (state = {}, action) => {
  switch (action.type) {
    case ARES_CONFIG.TOOLS_SHOW_CONFIG:
      return {
        ...state,
        modalOpen: false,
        inputErrors: '',
      };
    case ARES_CONFIG.TOOLS_SET_JSON_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
        inputErrors: '',
      };
    case ARES_CONFIG.TOOLS_JSON_OUTPUT_ERROR:
      return {
        ...state,
        inputErrors: action.payload,
      };
    default:
      return state;
  }
};

const imports = (state = {}, action) => {
  switch (action.type) {
    case ARES_CONFIG.TOOLS_SHOW_CONFIG:
      return {
        ...state,
        modalOpen: false,
        configSuccess: '',
        inputErrors: '',
        importConfig: false,
      };
    case ARES_CONFIG.TOOLS_IMPORT_ERROR:
      return {
        ...state,
        inputErrors: action.payload,
        configSuccess: false,
      };
    case ARES_CONFIG.TOOLS_SET_IMPORT_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
        inputErrors: '',
        configSuccess: '',
        importConfig: false,
      };
    case ARES_CONFIG.TOOLS_IMPORT_SUCCESS:
      return {
        ...state,
        inputErrors: '',
        configSuccess: true,
        importConfig: false,
      };
    case ARES_CONFIG.TOOLS_IMPORT_EXAMPLE:
      return {
        ...state,
        inputErrors: '',
        importConfig: action.payload,
      };
    case ARES_CONFIG.TOOLS_CLEAR_IMPORT_ERROR:
      return {
        ...state,
        inputErrors: '',
        importConfig: false,
      };
    default:
      return state;
  }
};

const redirectsWizard = (state = {}, action) => {
  switch (action.type) {
    case ARES_CONFIG.TOOLS_SHOW_CONFIG:
      return {
        ...state,
        modalOpen: false,
        inputErrors: '',
        redirectsWizardSuccess: false,
      };
    case ARES_CONFIG.TOOLS_SET_REDIRECTS_WIZARD_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
        inputErrors: '',
        redirectsWizardSuccess: false,
      };
    case ARES_CONFIG.TOOLS_REDIRECTS_WIZARD_ERROR:
      return {
        ...state,
        inputErrors: action.payload,
        redirectsWizardSuccess: false,
      };
    case ARES_CONFIG.TOOLS_SUBMIT_REDIRECTS_WIZARD:
      return {
        ...state,
        redirectsWizardSuccess: true,
        inputErrors: '',
      };
    default:
      return state;
  }
};

export {
  undoableConfig,
  inputErrors,
  jsonOutput,
  imports,
  redirectsWizard,
  schema,
};
