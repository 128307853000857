import React, { useState } from 'react';
import {
  func, number,
} from 'prop-types';
import { css } from '@emotion/react';
import { Typography, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Close } from '@material-ui/icons';

import Loading from 'shared/styleguide/atoms/Loading';
import { ErrorText } from 'shared/styleguide/typography';
import { Toggle } from 'shared/styleguide/atoms/Switches';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import { remMapper } from 'shared/styleguide/theme/spacing';
import MuiTextField from 'shared/styleguide/atoms/Input/TextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import { consolidateErrors } from 'shared/utils/validation';
import { GIT_WEBHOOK } from 'shared/modules/integrations/constants';
import { createIntegration } from 'shared/modules/integrations/redux/actions';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

const Checkboxes = ({ onChange }) => { //eslint-disable-line
  const steps = [ // eslint-disable-next-line
    <span>I have read and understand <TextLink href="https://support.pagely.com/hc/en-us/articles/360038759191">Pagely’s documentation on automatic deployments</TextLink>.</span>,
    'I understand that changes inside version control will overwrite the existing contents of this site.',
    'I have ensured that my repository/branch does not contain development files that are not intended to be deployed to a live site.',
    'I have ensured that my repository exactly reflects the contents of the site that I am automatically deploying to.',
    'I have notified my team that they should not make direct changes on the site, as they will be overwritten when an automatic deployment occurs.',
  ];

  const [checkboxes, setCheckboxes] = useState(steps.map(() => false));
  const handleChange = (index) => (e) => {
    const boxes = [...checkboxes];
    boxes[index] = e.target.checked;
    setCheckboxes(boxes);
    onChange(boxes.every(Boolean));
  };

  return (
    <List component="nav" aria-label="main mailbox folders">
      {
        steps.map((step, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <Checkbox
                checked={checkboxes[index]}
                onChange={handleChange(index)}
                value="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </ListItemIcon>
            <ListItemText>
              {step}
            </ListItemText>
          </ListItem>
        ))
      }
    </List>
  );
};

const CreateWebhook = ({
  appId, accountId, onClose, onCreating,
}) => {
  const dispatch = useDispatch();
  const initialState = { remote: '', branch: '', clearCache: true };
  const [values, setValues] = useState(initialState);
  const [status, setStatus] = useState('initial');
  const [checks, setChecks] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('initial');

    // eg. git@github.com:someuser/somerepo.git
    const regex = new RegExp(/^git@[^:]+:.+$/);

    if (!values.remote.match(regex)) {
      setStatus('error');
      setValues({ ...values, error: 'Invalid git remote URL. Only SSH remotes URLs allowed.' });
      return;
    }

    if (!values.branch) {
      setStatus('error');
      setValues({ ...values, error: 'Git branch is required.' });
      return;
    }

    if (!checks) {
      setStatus('error');
      setValues({ ...values, error: 'All steps must be completed' });
      return;
    }

    setStatus('loading');

    const data = {
      type: GIT_WEBHOOK,
      accountId,
      appIds: [appId],
      config: {
        clearCache: values.clearCache,
        git: {
          remote: values.remote,
          branch: values.branch,
        },
      },
    };

    try {
      await dispatch(createIntegration(data));
      setValues(initialState);
      setStatus('success');
      onCreating();
    } catch (err) {
      setStatus('error');
      setValues({ ...values, error: consolidateErrors(err) });
    }
  };

  if (status === 'loading') {
    return (
      <Box padding="medium" align="center">
        <Loading />
      </Box>
    );
  }

  return (
    <Box as={Paper}>
      <Box
        padding="medium"
        css={css`
          position: relative;
        `}
      >
        <Box>
          <IconButton
            onClick={() => onClose()}
            css={css`
              position: absolute;
              top: ${remMapper('xxsmall')};
              right: ${remMapper('xxsmall')};
            `}
          >
            <Close />
          </IconButton>
          <Box margin={{ top: 'small' }}>
            <Checkboxes onChange={setChecks} />
          </Box>
          <form onSubmit={handleSubmit}>
            <Box direction="row" gap="medium">
              <Box flex={3}>
                <MuiTextField
                  fullWidth
                  required
                  name="remote"
                  placeholder="Git Remote"
                  helperText="SSH Remote URL from Provider"
                  onChange={handleInputChange}
                  value={values.remote}
                />
              </Box>
              <Box flex={2}>
                <MuiTextField
                  required
                  name="branch"
                  placeholder="Git Branch"
                  helperText="Name of branch to deploy from"
                  onChange={handleInputChange}
                  value={values.branch}
                />
              </Box>
            </Box>

            <Box direction="row" align="center" justify="flex-start" gap="xxsmall" margin={{ top: 'small', bottom: 'medium' }}>
              <Toggle
                key="toggle-clearCache"
                id="toggle-clearCache"
                defaultToggled={values.clearCache || false}
                onToggle={(e, toggle) => setValues({ ...values, clearCache: toggle })}
                style={{ width: 'auto' }}
              />
              <Typography variant="body2" color="textSecondary">Clear cache after deploy to ensure changes are visible immediately</Typography>
            </Box>
            <Box margin={{ bottom: 'small' }}>
              <Typography variant="body1">
                Please double check the information before submitting it.
              </Typography>
            </Box>
            {
              values.error && (
                <Box margin={{ bottom: 'small' }} align="center">
                  <ErrorText>Error: {values.error}</ErrorText>
                </Box>
              )
            }
            <Box direction="row" justify="flex-end">
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                status={status === 'error' ? 'initial' : status}
                label="Create Integration"
              />
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

CreateWebhook.propTypes = {
  accountId: number.isRequired,
  appId: number.isRequired,
  onClose: func.isRequired,
  onCreating: func.isRequired,
};

export default CreateWebhook;
