import React from 'react';
import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import DnsValidations from '../DnsValidations';

interface Props {
  jobDetails: Record<string, any>;
  dnsJobs: Record<string, any>;
  validationRecords: Record<string, any>;
  fetchStatusJob: (value: string) => void;
  validationType: string;
  expanded: boolean;
  jobTypeLabel: string;
}

const StepActionRequired = ({
  jobDetails, dnsJobs, validationRecords, fetchStatusJob, validationType, expanded, jobTypeLabel,
}: Props) => {
  return (
    <Box>
      <Box margin={{ bottom: 'small' }}>
        <Typography component="span" variant="body1" color="textPrimary">Mercury Dynamic Site Acceleration {jobTypeLabel} has halted. Please perform the required actions and click the &quot;Resume {jobTypeLabel}&quot; button to continue.</Typography>
      </Box>
      <Typography component="span" variant="body1" color="textSecondary">Required Actions:</Typography>
      <Typography component="span" variant="body1" color="textPrimary">{jobDetails.messages.slice(-1)}</Typography>
      {
        validationRecords.length > 0
        && (
          // @ts-ignore
          <DnsValidations
            jobDetails={jobDetails}
            dnsJobs={dnsJobs}
            validationRecords={validationRecords}
            fetchStatusJob={fetchStatusJob}
            expanded={expanded}
            validationType={validationType}
          />
        )
      }
    </Box>
  );
};

export default StepActionRequired;
