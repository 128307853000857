import React, { Component } from 'react';
import { object, func, array } from 'prop-types';
import ArrowIcon from '@material-ui/icons/ArrowRightAlt';
import Grid from '@material-ui/core/Grid';
import TrashIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';
import colors from 'shared/styleguide/theme';
import { ColorText } from 'shared/styleguide/typography';
import ConfirmableButton from 'shared/styleguide/atoms/Buttons/ConfirmableButton';
import * as scss from './Editor.scss';

export default class ListRedirects extends Component {
  static propTypes = {
    initialRedirects: array,
    redirects: object,
    removeRedirect: func,
  };

  handleAddConfig = (newConfig) => {
    // we assume that the newConfig is already properly configured
    // do some config magic
    this.save(newConfig);
  };

  handleSetState = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { redirects, initialRedirects, removeRedirect } = this.props;
    return (
      <div>
        <Typography variant="caption">Please remember to &quot;Apply Changes&quot; after adding all redirects in order for changes to take effect</Typography>

        {
          Object.entries(redirects).map(([key, value]) => (
            <Grid container alignItems="center" justify="space-around" key={key} style={{ padding: '4px 0' }}>
              <Grid item className={scss.textfield}>
                {
                  initialRedirects.includes(key) ? key : (<ColorText color={colors.green20}>{key}</ColorText>)
                }
              </Grid>
              <Grid item><ArrowIcon style={{ margin: '0 8px' }} /></Grid>
              <Grid item className={scss.textfield}>
                {
                  initialRedirects.includes(key) ? value : (<ColorText color={colors.green20}>{value}</ColorText>)
                }
              </Grid>
              <Grid item style={{ minWidth: 96, display: 'flex', justifyContent: 'center' }}>
                <ConfirmableButton
                  icon={TrashIcon}
                  activeColor={colors.red10}
                  callback={() => removeRedirect(key)}
                  tooltip="Delete"
                />
              </Grid>
            </Grid>
          ))
        }
      </div>
    );
  }
}
