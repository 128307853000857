import React, {
  useState,
} from 'react';
import {
  shape, string, func, bool,
} from 'prop-types';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles,
} from '@material-ui/core';

import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import InfoText from 'shared/styleguide/molecules/InfoText';

import {
  dateOptions, formatDate,
} from 'shared/utils';
import EditWebhook from './EditWebhook';

const privateKeyProviders = ['github', 'gitlab'];

const getHelpUrl = (provider) => {
  switch (provider) {
    case 'github': return 'https://support.pagely.com/hc/en-us/articles/360038759191-Automatically-Deploying-WordPress-Sites-From-GitHub';
    case 'gitlab': return 'https://support.pagely.com/hc/en-us/articles/360041033711-Automatically-Deploying-WordPress-Sites-From-GitLab';
    case 'bitbucket': return 'https://support.pagely.com/hc/en-us/articles/360041082631-Automatically-Deploying-WordPress-Sites-From-Bitbucket';
    default: return 'https://support.pagely.com/hc/en-us/articles/360038759191';
  }
};

const styles = ({
  summary: {
    expandIcon: {
      alignSelf: 'flex-start',
    },
    expanded: {
      marginBottom: '0 !important',
    },
  },
  details: {
    root: {
      paddingTop: 0,
    },
  },
});

const InfoView = ({
  integration, setView,
}) => {
  const detailsClasses = makeStyles(styles.details)();

  const { git, clearCache } = integration.config;

  const data = [
    ['Remote', git.remote],
    ['Branch', git.branch],
    ['Webhook URL', git.webhook_url ? (
      <Box direction="row" gap="xsmall" key="webhook" align="center">
        <div>{git.webhook_url}</div>
        <CopyButton text={git.webhook_url} message="Webhook URL copied" />
      </Box>
    ) : (
      <ErrorText>Unable to create Webhook URL</ErrorText>
    )],
    ['SSH Public Key', git.public_key ? (
      <Box direction="row" gap="xsmall" key="pubkey" align="center">
        <div>{git.public_key}</div>
        <CopyButton text={git.public_key} message="SSH Public Key copied" />
      </Box>
    ) : (
      <ErrorText>Unable to create SSH Public Key</ErrorText>
    )],
    ['Clear Cache', `${Boolean(clearCache)}`],
    ['Active', `${integration.active}`],
  ];

  if (privateKeyProviders.includes(git.provider)) {
    data.push(['Provider Secret', (
      <Box direction="row" gap="xsmall" key="psecret" align="center">
        <div>{git.provider_secret}</div>
        <CopyButton text={git.provider_secret} message="Provider Secret copied" />
      </Box>
    )]);
  }

  return (
    <AccordionDetails classes={detailsClasses}>
      <Box flex={1}>
        <TwoColumn
          data={data}
          styles={{
            rightCol: {
              minWidth: 160,
            },
            leftCol: {
              wordBreak: 'break-word',
            },
            table: {
              marginTop: 0,
            },
          }}
        />
        <Box direction="row" justify="space-between" margin={{ top: 'small' }}>
          <InfoText href={getHelpUrl(git.provider)}>
            Troubleshooting automatic deployments
          </InfoText>
          <Box direction="row" justify="flex-end" margin={{ top: 'small' }}>

            <Button variant="outlined" onClick={() => setView('edit')}>Edit</Button>
          </Box>

        </Box>
      </Box>

    </AccordionDetails>
  );
};

InfoView.propTypes = {
  integration: shape({
    id: string.isRequired,
    created: string,
    config: shape({
      git: shape({
        provider: string.isRequired,
        remote: string.isRequired,
        branch: string,
        provider_secret: string,
        webhook_url: string.isRequired,
        public_key: string,
      }),
    }),
  }),
  setView: func,
};

const WebhookInfo = (props) => {
  const { integration, tokenError } = props;
  const [expanded, setExpanded] = useState(false);
  const [view, setView] = useState('info');

  const { git } = integration.config;

  return (
    <Box margin={{ top: 'medium' }} flex={1}>
      <Typography variant="h3">Git Webhook Integration</Typography>
      {
        tokenError
        && (
          <Box margin={{ top: 'small', bottom: 'xxsmall' }}>
            <ErrorText>Error creating Webhook Integration: Unable to create SSH Public Key.</ErrorText>
          </Box>
        )
      }
      <Box margin={{ top: 'small' }}>
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Box flex={1} justify="space-between" direction="row" align="center">
              <Typography variant="subtitle1">
                {git?.remote}
              </Typography>
              {
                integration.created
                && (
                  <Typography variant="body2" color="textSecondary">
                    created on {formatDate(integration.created, dateOptions)}
                  </Typography>
                )
              }
            </Box>
          </AccordionSummary>
          {
            git && (
              <div>
                {
                  view === 'info' && (
                    <InfoView setView={setView} {...props} />
                  )
                }
                {
                  view === 'edit' && (
                    <EditWebhook setView={setView} {...props} />
                  )
                }
              </div>
            )
          }
        </Accordion>
      </Box>
    </Box>
  );
};

WebhookInfo.propTypes = {
  integration: shape({
    id: string.isRequired,
    created: string,
    config: shape({
      git: shape({
        provider: string.isRequired,
        remote: string.isRequired,
        branch: string,
        provider_secret: string,
        webhook_url: string.isRequired,
        public_key: string,
      }),
    }),
  }),
  tokenError: bool,
};

export default WebhookInfo;
