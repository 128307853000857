import PropTypes from 'prop-types';
import { NumberLike } from './types/types';

export interface RecordType {
  id?: NumberLike;
  stsMessage: string;
  errMessage: string;
  statusCode: number;
  isFetching?: boolean;
  requestErr: boolean;
  validation: Record<string, number>;
}

export const recordBasePropTypes = {
  stsMessage: PropTypes.string,
  errMessage: PropTypes.string,
  statusCode: PropTypes.number,
  isFetching: PropTypes.bool,
  requestErr: PropTypes.bool,
  validation: PropTypes.object,
};

const recordBaseState: RecordType = {
  stsMessage: '',
  errMessage: '',
  statusCode: 0,
  isFetching: false,
  requestErr: false,
  validation: null,
};

export interface Collection extends RecordType {
  currentRecordIdentifier: string | null;
  currentRecordState: RecordType | null;
  currentRecordErrors: Error[] | null;
  records: RecordType[];
}

const collectionBaseState: Collection = {
  ...recordBaseState,
  currentRecordIdentifier: null,
  currentRecordState: null,
  currentRecordErrors: null,
  records: [],
};

/**
 * @deprecated  please use the methods in apiReducer
 */
export const record = (): RecordType => ({
  ...recordBaseState,
});

/**
 * @deprecated please use the methods in apiReducer
 */
export const collection = (): Collection => ({
  ...collectionBaseState,
});

/**
 * @deprecated please use the methods in apiReducer
 */
export const request = (stsMessage = ''): RecordType => ({
  ...recordBaseState,
  isFetching: true,
  stsMessage,
  validation: null,
});

/**
 * @deprecated please use the methods in apiReducer
 */
export const success = (): RecordType => ({
  ...recordBaseState,
});

/**
 * @deprecated please use the methods in apiReducer
 */
export const failure = (
  errMessage = '',
  statusCode = 0,
  validation: Record<string, any> = null,
): RecordType => ({
  ...recordBaseState,
  requestErr: true,
  errMessage,
  statusCode,
  validation,
});
