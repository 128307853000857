import colors from 'shared/styleguide/theme';
import userDialogStyles from 'shared/styleguide/atoms/Dialog/UserDialogStyles';

export const jssStyles = {
  root: {
    display: 'flex',
    border: `1px solid ${colors.navy30}`,
    width: 540,
    height: 400,
    backgroundColor: colors.navy40,
    fontSize: 20,
    color: colors.light10,
  },
  loadingRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  loadingMessage: {
    marginBottom: 0,
  },
  successRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  closeButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  successContent: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successMessage: {
    marginTop: 30,
    marginBottom: 30,
  },
  errorRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  errorContent: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  primaryErrorMessage: {
    color: colors.light10,
    fontSize: 20,
  },
  secondaryErrorMessage: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
    color: colors.blue10,
    fontSize: 16,
  },
};

export const inlineStyles = {
  dialog: userDialogStyles.muiInline,
  closeIcon: {
    inherit: colors.blue20,
    style: {
      color: colors.blue20,
    },
  },
  actionButton: {
    backgroundColor: colors.green20,
    buttonStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
      border: `1px solid ${colors.green20}`,
      width: 150,
      height: 42,
    },
    labelColor: colors.light10,
    labelStyle: {
      fontSize: 13,
      fontWeight: 'bold',
    },
  },
};
