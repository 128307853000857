import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Typography } from '@material-ui/core';

import HeadlineText from 'shared/styleguide/typography/HeadlineText';
import Box from 'shared/styleguide/atoms/Box';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Checkbox from 'shared/styleguide/atoms/Switches/Checkbox';
import TextArea from 'shared/styleguide/atoms/Input/TextArea';
import { CSRActions } from './CSRStepper';

import styles from './styles';

export const DownloadCSR = (props) => {
  const [hasCertificate, setHasCertificate] = useState(false);

  const {
    csr, classes, history, match: { params: { accountID } },
  } = props;

  return (
    <div
      className={classes.csrModalContainer}
    >
      <DialogContent>
        <HeadlineText gutterBottom>Copy Certificate Request</HeadlineText>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Below is the Certificate Request text block you will submit to your Certificate Authority
          </Typography>
          <Typography variant="body1" color="textPrimary" paragraph>
            Check the box and continue once you have received your new certificate
          </Typography>
        </Box>
        <div
          className={classes.csrCentered}
        >
          <TextArea
            code
            name="csr-download"
            readOnly
            value={csr}
          />
          <Box
            align="center"
            margin={{ top: 'small', bottom: 'small' }}
          >
            <CopyButton
              text={csr}
              message="CSR copied to clipboard"
            >
              <Button
                variant="contained"
                className="pull-right"
                label="Copy to Clipboard"
              />
            </CopyButton>
          </Box>
          <Checkbox
            label="I have recieved my new certificate from my Certificate Authority"
            onCheck={() => setHasCertificate(!hasCertificate)}
            checked={hasCertificate}
            className={classes.csrModalActionsContainer}
            labelPosition="end"
          />
        </div>
      </DialogContent>
      <CSRActions
        step={4}
        actions={[
          <Button
            key="back"
            variant="outlined"
            label="Back"
            color="default"
            disabled
          />,
          <Button
            key="upload"
            variant="contained"
            label="Upload New Certificate"
            disabled={!hasCertificate}
            onClick={() => history.push(`/account/${accountID}/ssl/add/cert`)}
          />,
        ]}
      />
    </div>
  );
};

DownloadCSR.propTypes = {
  classes: PropTypes.object.isRequired,
  csr: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(DownloadCSR));
