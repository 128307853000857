import React, { Fragment } from 'react';
import { object, shape } from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';

import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import { accountPropTypes } from 'shared/modules/account/models/model';

export const AdvancedInfo = ({ account, app }) => {
  const { servers } = account;
  if (!app.data.primaryServerId) {
    return null;
  }

  if (account.isOnP20Account) {
    const primary = servers.find((s) => s.id == app.data.primaryServerId); // eslint-disable-line eqeqeq
    const data = [
      ['Server ID', `${primary.id}`],
      ['Hostname', primary.hostname],
      ['File node', app.data.fileNode],
      ['Pool', primary.pool_id],
      ['External IP', (primary.ip_ext || primary.ip)],
      ['File Path', `/data/${app.data.fileNode}/dom${app.data.id}/dom${app.data.id}`],
      // ['SQL Server Id', 'someId'], // future feature
      // ['RDS Address', 'p20-aurora-1-cluster.rds.amazonaws.com'],
    ];

    if (app.data.sql && app.data.sql.success) {
      data.push(['RDS ID', `${app.data.sql.id}`]);
      data.push(['RDS Name', app.data.sql.hostname]);
    }
    return (
      <div key={primary.id} style={{ marginTop: 16 }}>
        <Typography variant="h5">Primary p20 node</Typography>
        <TwoColumn
          includeCopy
          key={primary.id}
          data={data}
        />
      </div>
    );
  }
  const appServers = servers.filter((server) => {
    return Number(server.pool_id) === app.data.poolId;
  });
  return (
    <Fragment>
      {
        appServers.map((server) => {
          const data = [
            ['Server ID', `${server.id}`],
            ['Hostname', server.hostname],
            ['File node', app.data.fileNode],
            ['Pool', server.pool_id],
            ['External IP', (server.ip_ext || server.ip)],
            ['File Path', `/data/${app.data.fileNode}/dom${app.data.id}/dom${app.data.id}`],
            // ['SQL Server Id', 'someId'], // future feature
            // ['RDS Address', 'p20-aurora-1-cluster.rds.amazonaws.com'],
          ];

          if (app.data.sql && app.data.sql.success) {
            data.push(['RDS ID', `${app.data.sql.id}`]);
            data.push(['RDS Name', app.data.sql.hostname]);
          }
          return (
            <Fragment key={server.id}>
              <TwoColumn
                includeCopy
                key={server.id}
                data={data}
              />
              <Divider />
            </Fragment>
          );
        })
      }
    </Fragment>
  );
};

AdvancedInfo.propTypes = {
  account: shape(accountPropTypes),
  app: object,
};

AdvancedInfo.displayName = 'AdvancedInfo';

export default AdvancedInfo;
