import React, { Fragment, useState } from 'react';
import {
  shape, string, bool, func,
} from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityIconOff from '@material-ui/icons/VisibilityOff';
import {
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import CopyButton from 'shared/styleguide/molecules/CopyButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import Box from 'shared/styleguide/atoms/Box';
import { ErrorText } from 'shared/styleguide/typography';

import { inline } from 'shared/modules/app/routes/Overview/styles';
import styles from './SSHDetails.scss';

const CredentialsTextInput = ({
  field, value, tooltip, copyMessage,
}) => {
  return (
    <Box
      direction="row"
      align="center"
    >
      <TextField
        id={field}
        autoComplete="off"
        margin="dense"
        fullWidth
        InputProps={inline.credentialsInputs}
        value={value}
      />
      <CopyButton
        tooltip={tooltip}
        text={value}
        message={copyMessage}
        fontSize="small"
      />
    </Box>
  );
};

const CredentialsPasswordInput = ({
  field, value, tooltip, copyMessage, visibilityMessage, passwordVisible, onPasswordVisibility,
}) => {
  return (
    <Box
      direction="row"
      align="center"
    >
      <TextField
        id={field}
        autoComplete="off"
        margin="dense"
        fullWidth
        InputProps={{
          ...inline.credentialsInputs,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title={visibilityMessage}
                placement="top"
              >
                <IconButton className={styles.credentialsVisibility} onClick={onPasswordVisibility}>
                  {
                    passwordVisible
                      ? (
                        <VisibilityIconOff />
                      ) : (
                        <VisibilityIcon />
                      )
                  }
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        type={passwordVisible ? 'text' : 'password'}
        value={value}
      />
      <CopyButton
        tooltip={tooltip}
        text={value || ''}
        message={copyMessage}
        fontSize="small"
      />
    </Box>
  );
};

const FtpCredentials = ({ isPrimary, credentials }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  const loginData = [
    [
      'Hostname',
      <CredentialsTextInput
        key="hostname"
        field="hostname"
        value="sftp.pagely.com"
        tooltip="Click to copy hostname"
        copyMessage="Hostname copied to clipboard"
      />,
    ],
    [
      'Port',
      <CredentialsTextInput
        key="port"
        field="port"
        value="22"
        tooltip="Click to copy port"
        copyMessage="Port copied to clipboard"
      />,
    ],
    [
      'Username',
      <Fragment key="username">
        {
          credentials.data?.username
            ? (
              <CredentialsTextInput
                field="username"
                value={credentials.data.username}
                tooltip="Click to copy username"
                copyMessage="Username copied to clipboard"
              />
            ) : (
              <ErrorText>Failed to load username</ErrorText>
            )
        }
      </Fragment>,
    ],
    [
      'Password',
      <Fragment key="password">
        {
          credentials.data?.password
            ? (
              <CredentialsPasswordInput
                field="password"
                value={credentials.data.password}
                tooltip="Click to copy password"
                copyMessage="Password copied to clipboard"
                visibilityMessage="Click to toggle password visibility"
                passwordVisible={passwordVisible}
                onPasswordVisibility={handlePasswordVisibility}
              />
            ) : (
              // if sftp was set up in brains (legacy) then password will be empty
              <Box direction="column">
                <Box>
                  <ErrorText>Unable to load password.</ErrorText>
                </Box>
                <Box>
                  <Typography variant="body2">
                    {
                      isPrimary
                        ? (
                          'To reset your password, please toggle the SFTP switch.'
                        ) : (
                          'Contact the account owner to reset the SFTP password.'
                        )
                    }
                  </Typography>
                </Box>
              </Box>
            )
        }
      </Fragment>,
    ],
  ];

  return (
    <TwoColumn
      data={loginData}
      styles={{
        table: {
          marginTop: 0,
        },
        leftCol: {
          width: 400,
        },
      }}
    />
  );
};

CredentialsTextInput.propTypes = {
  copyMessage: string,
  field: string,
  tooltip: string,
  value: string,
};

CredentialsPasswordInput.propTypes = {
  copyMessage: string,
  field: string,
  onPasswordVisibility: func,
  passwordVisible: bool,
  tooltip: string,
  value: string,
  visibilityMessage: string,
};

FtpCredentials.propTypes = {
  credentials: shape({
    data: shape({
      username: string,
      password: string,
    }),
  }),
  isPrimary: bool,
};

export default FtpCredentials;
