import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Modal from 'shared/styleguide/molecules/Modal/Modal';
import { getAllValidationMessages } from 'shared/utils/validation';

import {
  setCertDomainLink,
  updateCertDomainLink,
  resetCertDomainLink,
} from 'shared/modules/ssl/redux/certDomainLink/actions';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TlsVersionCipher from './TlsVersionCipher/TlsVersionCipher';
import Redirect from './Redirect/Redirect';
import HSTS from './HSTS/HSTS';
import Http2 from './HTTP2/HTTP2';

export const ModalContent = (props) => {
  const {
    link, user,
  } = props;

  if (!link.certId || !user.user) {
    return <Loading />;
  }

  const userType = user.user['@type'];

  return (
    <Box
      padding="small"
    >
      <Redirect />
      <Box margin={{ bottom: 'medium' }}>
        <Divider />
      </Box>
      <Http2 />
      <Box margin={{ bottom: 'medium' }}>
        <Divider />
      </Box>
      <TlsVersionCipher />
      <Box margin={{ bottom: 'medium' }}>
        <Divider />
      </Box>
      <HSTS
        userType={userType}
      />
    </Box>
  );
};

const parseFormValuesFromData = (
  {
    saved, stsMessage, errMessage, statusCode, isFetching, requestErr, ...data
  },
) => {
  let tlsCipher = data.tlsCipher || 'legacy';
  if (tlsCipher === 'Compatible') {
    tlsCipher = 'legacy';
  }
  if (tlsCipher === 'Modern') {
    tlsCipher = 'modern';
  }

  return ({
    ...data,
    tlsConfig: data.tlsConfig ? JSON.stringify(data.tlsConfig, null, 4) : null,
    redirectConfig: data.redirectConfig ? JSON.stringify(data.redirectConfig, null, 4) : null,
    tlsCipher,
    hstsTimeout: data.hstsTimeout ? `${data.hstsTimeout}` : '0',
    hstsIncludeSubdomains: data.hstsIncludeSubdomains || false,
    hstsPreload: data.hstsPreload || false,
  });
};

export const CertificateSettings = (props) => {
  const { link } = props;

  const initialValues = parseFormValuesFromData(link);

  const handleClose = () => {
    const {
      match, history,
    } = props;

    history.replace(`/account/${match.params.accountID}/ssl/cert/${match.params.certID}`);
  };

  const handleSave = (currentValues, formikBag) => {
    formikBag.setStatus('loading');

    const { updateLink } = props;
    const { aliasId, certId } = initialValues as any;
    const submitValues = {
      aliasId,
      certId,
    };

    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== null && currentValues[key] !== initialValues[key]) {
        if (['redirect', 'http2'].indexOf(key) > -1) {
          submitValues[key] = !!currentValues[key];
        } else {
          submitValues[key] = currentValues[key];
        }
      }
    });

    return updateLink(submitValues).then(() => {
      formikBag.setStatus('success');
      setTimeout(() => {
        handleClose();
      }, 3000);
    })
      .catch((error) => {
        const err = getAllValidationMessages(error);
        formikBag.setStatus('error');
        formikBag.setErrors({ general: err });
      // catch which ones fail
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      initialStatus="pristine"
      initialErrors={{ general: '' }}
      onSubmit={handleSave}
    >
      {
        (formProps) => (
          <Modal
            title={`Certificate Settings: ${link.domain}`}
            onClose={handleClose}
            scroll="paper"
            open
            actions={[(
              <Typography color="error" key="errorText" style={{ paddingRight: 16 }}>{formProps?.errors?.general}</Typography>
            ), (
              <Button
                key="save"
                onClick={formProps.handleSubmit}
                variant="contained"
                status={formProps.status}
                disabled={!formProps.dirty}
              >
                Save
              </Button>
            ),
            ]}
          >
            <ModalContent {...props} />
          </Modal>
        )
      }
    </Formik>
  );
};

const mapStateToProps = (state) => {
  const link = state.certDomainLink.data;
  return {
    user: state.user,
    cert: state.cert,
    link,
    certDomainLink: state.certDomainLink,
    saved: link.saved,
    initialValues: link.certId ? parseFormValuesFromData(link) : null,
  };
};

const mapDispatchToProps = {
  resetLink: resetCertDomainLink,
  setLink: setCertDomainLink,
  updateLink: updateCertDomainLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateSettings);
