import React from 'react';
import type { UserPool } from 'shared/modules/account/redux/sso/types';
import type { Account } from 'shared/modules/account/types';
import type { NumberLike } from 'shared/types/types';
import type { StatusKeys } from '../constants';
import UnavailableCopy from './UnavailableCopy';
import Begin from './Begin';
import Configuration from './Configuration';
import Test from './Test';
import Active from './Active';
import Complete from './Complete';

interface Props {
  account: Account;
  userPool: UserPool | null;
  updateStatus: (status: StatusKeys) => void;
  createUserPool: (ownerUserId: NumberLike, companyName: string) => Promise<UserPool>;
  finishSamlSetup: (userPoolId: string, samlUrl: string, samlName: string) => Promise<UserPool>;
  deleteIdentityProvider: (poolId: string, idpName: string) => Promise<null>;
  deleteUserPool: (poolId: string) => Promise<null>;
  updateAccount: (accountId: number, options?: Partial<Account>) => Promise<Account>;
  status: StatusKeys;
}

const SetupForm: React.FC<Props> = ({ status, ...props }) => {
  const stateMachine = {
    disabled: <Begin createUserPool={props.createUserPool} account={props.account} />,
    enabled: <Configuration userPool={props.userPool} updateStatus={props.updateStatus} />,
    finish: <Complete updateStatus={props.updateStatus} userPool={props.userPool} finishSamlSetup={props.finishSamlSetup} account={props.account} updateAccount={props.updateAccount} />,
    test: <Test userPool={props.userPool} updateStatus={props.updateStatus} />,
    active: <Active userPool={props.userPool} deleteIdentityProvider={props.deleteIdentityProvider} deleteUserPool={props.deleteUserPool} />,
    unavailable: <UnavailableCopy />,
  } as const;

  return stateMachine[status] ?? null;
};

export default SetupForm;
