import colors from 'shared/styleguide/theme';

export default {
  closeIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  dialog: {
    titleStyle: {
      color: colors.navy20,
      fontWeight: 600,
      fontSize: 32,
      lineHeight: 1.38,
      letterSpacing: -0.4,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    contentStyle: {
      padding: 0,
      width: '100%',
      maxWidth: 950,
    },
    bodyStyle: {
      padding: 0,
      width: '100%',
    },
  },
};
