export const RELEASE_NOTES_GET_REQUEST = 'RELEASE_NOTES_GET_REQUEST';
export const RELEASE_NOTES_GET_SUCCESS = 'RELEASE_NOTES_GET_SUCCESS';
export const RELEASE_NOTES_GET_FAILURE = 'RELEASE_NOTES_GET_FAILURE';

export const RELEASE_NOTES_CREATE_REQUEST = 'RELEASE_NOTES_CREATE_REQUEST';
export const RELEASE_NOTES_CREATE_SUCCESS = 'RELEASE_NOTES_CREATE_SUCCESS';
export const RELEASE_NOTES_CREATE_FAILURE = 'RELEASE_NOTES_CREATE_FAILURE';

export const RELEASE_NOTES_UPDATE_REQUEST = 'RELEASE_NOTES_UPDATE_REQUEST';
export const RELEASE_NOTES_UPDATE_SUCCESS = 'RELEASE_NOTES_UPDATE_SUCCESS';
export const RELEASE_NOTES_UPDATE_FAILURE = 'RELEASE_NOTES_UPDATE_FAILURE';

export const RELEASE_NOTES_DELETE_REQUEST = 'RELEASE_NOTES_DELETE_REQUEST';
export const RELEASE_NOTES_DELETE_SUCCESS = 'RELEASE_NOTES_DELETE_SUCCESS';
export const RELEASE_NOTES_DELETE_FAILURE = 'RELEASE_NOTES_DELETE_FAILURE';

export const RELEASE_NOTES_SET_MODE = 'RELEASE_NOTES_SET_MODE';
