import React, {
  useEffect, Fragment,
} from 'react';
import { func, number, object } from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import { ErrorText } from 'shared/styleguide/typography';
import Empty from 'shared/styleguide/atoms/Empty';
import SupportLink from 'shared/modules/support/Link';
import { LEGACY_DEPLOYER_TAG } from 'shared/modules/metadata/constants';
import { fetchServerTagsForAccount } from 'shared/modules/server/redux/actions';
import { fetchIntegrationsList } from 'shared/modules/integrations/redux/actions';
import { getAllValidationMessages } from 'shared/utils/validation';
import Loading from 'shared/styleguide/atoms/Loading';
import InfoText from 'shared/styleguide/molecules/InfoText';
import { FeatureToggle } from 'shared/flags';

import WebhookIntegrations from './WebhookIntegrations';
import CICDIntegrations from './CICDIntegrations';

export const Integrations = ({
  account, servertags, integrations, app, apps, match: { params: { appId } }, __storybookMocks, ...props
}) => {
  const {
    fetchIntegrationsList, fetchServerTags,
  } = props;
  useEffect(() => {
    if (servertags.status === 'pristine' || servertags.params?.accountId !== account.id) {
      fetchServerTags(account.id);
    }
    if (!account.isOnP20Account && (integrations.status === 'pristine' || integrations.params?.accountId !== account.id)) {
      // only fetch once
      fetchIntegrationsList(account.id);
    }
  }, [account, fetchServerTags, fetchIntegrationsList, servertags, integrations]);

  if (
    account.isOnP20Account
  ) {
    return (
      <Box padding="medium">
        <Empty>This feature is not available for accounts on shared hosting plans.</Empty>
      </Box>
    );
  }

  if (['loading', 'pristine'].includes(servertags.status)) {
    return (
      <Box padding="medium" align="center">
        <Loading />
      </Box>
    );
  }

  if (
    servertags.status === 'success'
    && servertags.data
    && servertags.data.map((t) => t.tagId).includes(LEGACY_DEPLOYER_TAG)
  ) {
    return (
      <Box padding="medium">
        <Empty>
          <Typography variant="body1" color="textSecondary" paragraph>
            This feature is not available for accounts with a legacy deployer.
            Please <SupportLink>contact support</SupportLink> to migrate to the new system.
          </Typography>
        </Empty>
      </Box>
    );
  }

  if (integrations.status === 'failed') {
    return (
      <Box margin={{ top: 'medium' }} flex={1} align="center">
        <Empty error style={{ width: '100%' }}>
          <ErrorText>
            An error occurred:&nbsp;
            {
              integrations.statusCode === 422
                ? (
                  getAllValidationMessages(integrations)
                ) : (
                  integrations.apiErrors
                )
            }
          </ErrorText>
        </Empty>
      </Box>
    );
  }

  return (
    <Fragment>
      <InfoText href="https://support.pagely.com/hc/en-us/articles/360047765872">
        How to deploy your WordPress Sites from version control
      </InfoText>
      <CICDIntegrations
        integrations={integrations}
        accountId={account.id}
        apps={apps}
        app={app}
      />
      <FeatureToggle
        stages={['production', 'preview', 'staging', 'development']} // excludes woosaas
      >
        <WebhookIntegrations
          integrations={integrations}
          fetchIntegrationsList={fetchIntegrationsList}
          accountId={account.id}
          appId={Number(appId)}
          __storybookMocks={__storybookMocks}
        />
      </FeatureToggle>
    </Fragment>
  );
};

Integrations.propTypes = {
  __storybookMocks: object,
  account: object,
  app: object,
  appId: number,
  apps: object,
  fetchIntegrationsList: func,
  fetchServerTags: func,
  integrations: object,
  match: object,
  servertags: object,
};

export default connect(
  (state) => ({
    account: state.account,
    app: state.app.app,
    apps: state.apps,
    integrations: state.integrations,
    servertags: state.tags.server,
  }),
  {
    fetchServerTags: fetchServerTagsForAccount,
    fetchIntegrationsList,
  },
)(Integrations);
