import React from 'react';
import { object, bool } from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import AppList from 'shared/modules/apps/components/AppListLoader';
import App from 'shared/modules/app';

const AppRoutes = ({ match, isAdmin }) => (
  <Switch>
    <Route path={`${match.path}/apps`} exact component={AppList} />
    <Route path={`${match.path}/apps/note`} component={AppList} />
    <Route path={`${match.path}/apps/:appID`} render={(props) => <App {...props} parentMatch={match} isAdmin={isAdmin} />} />
  </Switch>
);

AppRoutes.propTypes = {
  isAdmin: bool,
  match: object,
};

export default AppRoutes;
