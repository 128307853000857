import React from 'react';

import Box from 'shared/styleguide/atoms/Box';

const DiskWarningBanner = ({ children }) => {
  return (
    <Box
      column
      align="center"
      padding={{ top: 'xsmall', bottom: 'xsmall' }}
    >
      <Box padding="small">
        {children}
      </Box>
    </Box>
  );
};

export default DiskWarningBanner;
