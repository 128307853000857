/* eslint-disable react/prefer-stateless-function */

import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';

import {
  Button as MuiButton, Tooltip, Typography,
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';

import Box from 'shared/styleguide/atoms/Box';
import Linker from 'shared/styleguide/atoms/Links/Linker';

const shouldForwardProp = (prop) => isPropValid(prop) && !['hover'].includes(prop);

interface StyledCircleProps {
  hover: boolean;
}

const StyledCircle = styled('div', { shouldForwardProp })<StyledCircleProps>(
  ({ hover, theme }) => {
    return (css`
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 1px;
    background-color: ${hover ? theme.color900 : 'transparent'};
  `);
  },
);

interface StyledButtonProps {
  hover: boolean;
}

const StyledButton = styled(MuiButton, { shouldForwardProp })<StyledButtonProps>(
  ({ hover, theme }) => {
    return (css`
    background-color: transparent;
    border: 1px solid ${theme.palette.secondary.main};
    color: ${hover ? theme.color000 : theme.color900};
    min-width: 0px;
    padding: ${hover ? '4px' : '0px'};
    margin: ${hover ? '0px' : '4px'};
    transition: all 0.2s ease-out;
    &:hover {
      background-color: ${theme.color100};
      color: ${hover ? theme.color000 : theme.color900};
    }
    & .MuiButton-label {
      color: inherit
    }
  `);
  },
);

interface StyledLinkerProps {
  hover?: boolean;
  children: React.ReactNode;
  onClick?(): void;
}

const StyledLinker = styled(Linker, { shouldForwardProp })<StyledLinkerProps>(
  ({ hover, theme }) => (css`
    color: ${hover ? theme.palette.primary.main : theme.palette.text.secondary};
    text-decoration: ${hover ? 'underline' : 'transparent'};
    text-decoration-color: ${theme.palette.primary.main};
    &:hover {
      text-decoration: ${hover ? 'underline' : 'transparent'};
      text-decoration-color: ${theme.palette.primary.main};
    }
  `),
);

interface CircleLinkProps {
  href?: string;
  to?: string;
  label?: string;
  tooltip?: string;
  children?: React.ReactNode;
}

const CircleLink = ({
  label, tooltip, to, href, children,
}: CircleLinkProps): JSX.Element => {
  const [hover, setHover] = useState(false);
  const text = label || children;
  const button = (
    <StyledButton variant="outlined" color="secondary" hover={hover}>
      <StyledCircle hover={hover}>
        <ArrowForward />
      </StyledCircle>
    </StyledButton>
  );

  return (
    <Box direction="row" align="center" justify="flex-end">
      {
        text && (
          <Box margin={{ right: 'xxsmall' }}>
            <StyledLinker hover={hover} to={to} href={href} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
              <Typography color="inherit" variant="caption">{text}</Typography>
            </StyledLinker>
          </Box>
        )
      }
      <Linker to={to} href={href} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {
          tooltip
            ? <Tooltip title={tooltip}>{button}</Tooltip>
            : button
        }
      </Linker>
    </Box>
  );
};

interface CirclePostProps {
  href?: string;
  label?: string;
  postBody?: Record<string, any>;
  tooltip?: string;
  children?: any;
}

export const CirclePost = withTheme(({
  label, tooltip, href, children, postBody,
}: CirclePostProps) => {
  const [hover, setHover] = useState(false);
  const form = useRef();
  const text = label || children;
  const button = (
    <StyledButton variant="outlined" color="secondary" hover={hover} type="submit">
      <StyledCircle hover={hover}>
        <ArrowForward />
      </StyledCircle>
    </StyledButton>
  );

  return (
    <form action={href} method="POST" ref={form}>
      <Box direction="row" align="center" justify="flex-end">
        {Object.entries(postBody).map(([k, v]) => <input type="hidden" name={k} key={k} id={k} value={v} />)}
        {
          text && (
            <Box margin={{ right: 'medium' }}>
              {/* @ts-ignore */}
              <StyledLinker hover={hover} onClick={() => form.current?.submit()} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Typography color="inherit" variant="caption">{text}</Typography>
              </StyledLinker>
            </Box>
          )
        }
        {/* @ts-ignore */}
        <Linker onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          {
            tooltip
              ? <Tooltip title={tooltip}>{button}</Tooltip>
              : button
          }
        </Linker>
      </Box>
    </form>
  );
});

export default withTheme(CircleLink);
