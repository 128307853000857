import React from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import TrashIcon from '@material-ui/icons/Delete';

import Row from 'shared/styleguide/atoms/Row';
import Select from 'shared/styleguide/atoms/Select/Select';
import TextField from 'shared/styleguide/atoms/Input/TextField';

import {
  updateItem, deleteItem,
} from '../redux/actions';

import { getOptions } from '../helpers';

const SubProp = ({
  propLevels, value, updateItem, deleteItem, schema, type,
}) => {
  const optionsSubArr = getOptions(propLevels, schema, type);
  const items = [];

  const optionsSubArrKeys = Object.keys(optionsSubArr);
  const subRuleKeys = Object.keys(value);

  if (optionsSubArr === '') {
    // sub single value
    optionsSubArrKeys[0] = '';
  }
  Object.keys(optionsSubArrKeys).forEach((subValKey) => {
    const subVal = optionsSubArrKeys[subValKey];
    const subOptions = optionsSubArr[subVal];
    if (subValKey === '0' && subOptions.enum !== undefined && optionsSubArrKeys.length > 1) {
      const optsAvailableSelect = subOptions.enum.map(((item) => ({
        value: item,
        label: item,
      })));

      items.push(
        <div style={{ flex: 2, marginRight: 10 }} key={`subselect-${subValKey}`}>
          <Select
            onChange={(e) => {
              if (e.value !== null) {
                updateItem(`${propLevels}.${subVal}`, e.value);
              }
            }}
            placeholder={value[subRuleKeys[subValKey]]}
            options={optsAvailableSelect}
            value={value[subRuleKeys[subValKey]]}
          />
        </div>,
      );
    }

    if (subValKey === '0' && subOptions.type === 'string' && optionsSubArrKeys.length > 1) {
      items.push(
        <div style={{ flex: 2, marginRight: 10 }} key={`subselect-${subValKey}`}>
          <TextField
            name={`${propLevels}.${subValKey}`}
            autoComplete="off"
            fullWidth
            multiline
            margin="dense"
            value={value[subRuleKeys[subValKey]]}
            onChange={(e) => updateItem(`${propLevels}.${subVal}`, e.currentTarget.value)}
          />
        </div>,
      );
    }

    if (subValKey === '1' && subOptions.type === 'string' && optionsSubArrKeys.length > 1) {
      items.push(
        <div style={{ flex: 5 }} key={`subselect-${subValKey}`}>
          <TextField
            name={`${propLevels}.${subVal}`}
            autoComplete="off"
            fullWidth
            multiline
            margin="dense"
            value={value[subRuleKeys[subValKey]]}
            onChange={(e) => updateItem(`${propLevels}.${subVal}`, e.currentTarget.value)}
          />
        </div>,
      );
    }
  });

  return (
    <Row>
      {items}
      <div
        style={{
          width: 38, marginLeft: 10, paddingTop: 5, paddingBottom: 5,
        }}
      >
        <IconButton
          color="secondary"
          size="small"
          name="deleteSub"
          onClick={() => deleteItem(propLevels)}
        >
          <TrashIcon />
        </IconButton>
      </div>
    </Row>
  );
};

SubProp.propTypes = {
  deleteItem: any,
  propLevels: any,
  schema: any,
  state: any,
  type: any,
  updateItem: any,
  value: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, {
  updateItem, deleteItem,
})(SubProp);
