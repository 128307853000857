import React from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import { IconButton } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';

import Row from 'shared/styleguide/atoms/Row';
import TextField from 'shared/styleguide/atoms/Input/TextField';

import {
  updateItem, deleteItem, updateMapKey, updateMapVal,
} from '../redux/actions';

import SubPropMapKey from './SubPropMapKey';

const SubPropMap = ({
  propLevels, value, deleteItem, updateMapVal,
}) => {
  const items = Object.keys(value).map((subValKey, idx) => {
    return (
      <Row key={`subselectrow-${subValKey}`}>
        <div style={{ flex: 1, marginRight: 5 }} key={`subselectkey-${subValKey}`}>
          <SubPropMapKey
            name={`subMapKey.${propLevels}.${idx}`}
            key={`subMapKey.${propLevels}.${idx}`}
            idx={idx}
            subValKey={subValKey}
            propLevels={propLevels}
          />
        </div>
        <div style={{ flex: 1, marginLeft: 5 }} key={`subselectval-${idx}`}>
          <TextField
            name={`subMapVal.${propLevels}.${idx}`}
            key={`subMapVal.${propLevels}.${idx}`}
            autoComplete="off"
            fullWidth
            multiline
            margin="dense"
            value={value[subValKey]}
            onChange={(e) => { updateMapVal(`${propLevels}.${subValKey}`, e.currentTarget.value); }}
          />
        </div>
        <div
          style={{
            width: 38, marginLeft: 10, paddingTop: 5, paddingBottom: 5,
          }}
        >
          <IconButton
            color="secondary"
            size="small"
            name="deleteSubMap"
            onClick={() => deleteItem(`${propLevels}.${subValKey}`)}
          >
            <TrashIcon />
          </IconButton>
        </div>
      </Row>
    );
  });

  return items;
};

SubPropMap.propTypes = {
  deleteItem: any,
  propLevels: any,
  updateItem: any,
  updateMapVal: any,
  value: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, {
  updateItem, deleteItem, updateMapKey, updateMapVal,
})(SubPropMap);
