import makeApiAction, { makeSimpleApiAction } from 'shared/utils/redux/apiActions';

export const LETSENCRYPT_REGISTER_REQUEST = 'LETSENCRYPT_REGISTER_REQUEST';
export const LETSENCRYPT_REGISTER_SUCCESS = 'LETSENCRYPT_REGISTER_SUCCESS';
export const LETSENCRYPT_REGISTER_FAILURE = 'LETSENCRYPT_REGISTER_FAILURE';

export const LETSENCRYPT_STATUS_REQUEST = 'LETSENCRYPT_STATUS_REQUEST';
export const LETSENCRYPT_STATUS_SUCCESS = 'LETSENCRYPT_STATUS_SUCCESS';
export const LETSENCRYPT_STATUS_FAILURE = 'LETSENCRYPT_STATUS_FAILURE';

export const LETSENCRYPT_RESET = 'LETSENCRYPT_RESET';

export const LETSENCRYPT_SET_DOMAIN = 'LETSENCRYPT_SET_DOMAIN';

export const LETSENCRYPT_STATUS_CHECK_SITE_REQUEST = 'LETSENCRYPT_STATUS_CHECK_SITE_REQUEST';
export const LETSENCRYPT_STATUS_CHECK_SITE_SUCCESS = 'LETSENCRYPT_STATUS_CHECK_SITE_SUCCESS';
export const LETSENCRYPT_STATUS_CHECK_SITE_FAILURE = 'LETSENCRYPT_STATUS_CHECK_SITE_FAILURE';

export const LETSENCRYPT_STATUS_CHECK_CDN_REQUEST = 'LETSENCRYPT_STATUS_CHECK_CDN_REQUEST';
export const LETSENCRYPT_STATUS_CHECK_CDN_SUCCESS = 'LETSENCRYPT_STATUS_CHECK_CDN_SUCCESS';
export const LETSENCRYPT_STATUS_CHECK_CDN_FAILURE = 'LETSENCRYPT_STATUS_CHECK_CDN_FAILURE';

export const getStatus = (jobID) => makeApiAction(
  ({ api }) => api().get(`/ssl/letsencrypt/status/${jobID}`),
  LETSENCRYPT_STATUS_REQUEST,
  LETSENCRYPT_STATUS_SUCCESS,
  LETSENCRYPT_STATUS_FAILURE,
);

export const register = (accountId, domain, alternateDomains) => makeApiAction(
  ({ api }) => {
    const data = {
      accountId,
      domain,
    };
    if (alternateDomains && alternateDomains.length > 0) {
      data.alternateNames = alternateDomains;
    }
    return api().post('/ssl/letsencrypt/register', data);
  },
  LETSENCRYPT_REGISTER_REQUEST,
  LETSENCRYPT_REGISTER_SUCCESS,
  LETSENCRYPT_REGISTER_FAILURE,
);

export const checkSite = (url) => makeSimpleApiAction(
  ({ api }) => api().get('/ssl/letsencrypt/sitecheck', { params: { url } }),
  LETSENCRYPT_STATUS_CHECK_SITE_REQUEST,
  LETSENCRYPT_STATUS_CHECK_SITE_SUCCESS,
  LETSENCRYPT_STATUS_CHECK_SITE_FAILURE,
);

export const checkCdn = (url) => makeSimpleApiAction(
  ({ api }) => api().get('/ssl/letsencrypt/sitecheck', { params: { url } }),
  LETSENCRYPT_STATUS_CHECK_CDN_REQUEST,
  LETSENCRYPT_STATUS_CHECK_CDN_SUCCESS,
  LETSENCRYPT_STATUS_CHECK_CDN_FAILURE,
);

export const reset = () => ({
  type: LETSENCRYPT_RESET,
});

export const setDomain = (domain, appId) => ({
  type: LETSENCRYPT_SET_DOMAIN,
  domain,
  appId,
});
