import React from 'react';
import { Field } from 'formik';
import { Switch, Typography } from '@material-ui/core';
import { HeadlineText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';

export const HTTP2 = () => {
  return (
    <div style={{ width: '100%' }}>
      <HeadlineText>HTTP2</HeadlineText>
      <Box margin="medium" direction="row" align="center" gap="medium" justify="space-between">
        <Box>
          <Typography color="textPrimary">Allow HTTP2 Protocol</Typography>
          <Typography color="textSecondary">Requires SSL Certificate</Typography>
        </Box>
        <Box direction="row" margin={{ left: 'large' }} align="center">
          <Typography component="span">
            off
          </Typography>
          <Field
            type="checkbox"
            as={Switch}
            label="Enable HTTP2"
            name="http2"
            style={{
              width: 'auto',
              marginRight: 8,
            }}
          />
          <Typography component="span">
            on
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default (HTTP2);
