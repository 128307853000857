import React from 'react';
import {
  object,
} from 'prop-types';
import { connect } from 'react-redux';

import WarningIcon from '@material-ui/icons/Warning';
import {
  Typography, Snackbar, SnackbarContent, useTheme, Grow,
} from '@material-ui/core';

import { getAppsById } from 'shared/modules/app/redux/selectors';
import Box from 'shared/styleguide/atoms/Box';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

const aresFeatureMap = {
  'ares-redirects': {
    name: 'Redirects',
    subroute: 'redirects',
  },
  'ares-rate-limit': {
    name: 'Rate Limits',
    subroute: 'rate-limits',
  },
  'ares-access-controls': {
    name: 'Access Control',
    subroute: 'access',
  },
  'ares-cache-keys': {
    name: 'Cache Keys',
    subroute: 'cache',
  },
  'ares-header-management': {
    name: 'Headers',
    subroute: 'headers',
  },
  'ares-bot-lists': {
    name: 'Bot Blocking',
    subroute: 'bot-blocking',
  },
};

export const AresDeployNotification = ({
  account, pendingDeploys, apps, __storybookMocks,
}) => {
  const theme = useTheme();

  if (pendingDeploys.status === 'loading'
    || pendingDeploys.params?.accountId !== account.id
    || pendingDeploys.data.length === 0) {
    return null;
  }

  const appMessages = pendingDeploys.data.reduce((acc, detail) => {
    const isActiveApp = !!Object.values(apps).find((a) => a.id === detail.appId && a.active);

    if (!isActiveApp) {
      return acc;
    }

    const appAresLink = `/account/${account.id}/apps/${detail.appId}/ares/`;
    const features = detail.initiators.map((initiator) => {
      const { name, subroute } = aresFeatureMap[initiator];
      const featureLink = `${appAresLink}${subroute}`;
      return (
        <TextLink key={featureLink} to={featureLink} color="inherit">{name}</TextLink>
      );
    });

    acc.push(
      <Typography key={detail.appName} variant="body2" style={{ color: theme.color500 }}>
        <TextLink to={`/account/${account.id}/apps/${detail.appId}/ares/`} color="inherit">
          {`${detail.appName || apps[detail.appId].label || apps[detail.appId].name}: `}
        </TextLink>
        {
          features.map((f) => f).reduce((prev, curr) => [prev, ', ', curr])
        }
      </Typography>,
    );
    return acc;
  }, []);

  if (!appMessages.length) {
    return null;
  }

  const message = (
    <Box flex={1} direction="row" gap="xsmall">
      <Box align="center">
        <WarningIcon style={{ color: theme.palette.warning.main }} />
      </Box>
      <Box flex={1} align="left" direction="column">
        <Typography variant="body2" color="inherit">
          You have undeployed Ares changes:
        </Typography>
        {
          appMessages.map((m) => m)
        }
      </Box>
    </Box>
  );

  return (
    <Snackbar
      open={pendingDeploys.data.length > 0}
      TransitionComponent={__storybookMocks?.transitionComponent || Grow}
    >
      <SnackbarContent
        message={message}
        theme={theme}
      />
    </Snackbar>
  );
};

AresDeployNotification.propTypes = {
  account: object,
  apps: object,
  pendingDeploys: object,
};

export default connect(
  (state) => {
    return {
      permissions: state.permissions,
      apps: getAppsById(state),
      pendingDeploys: state.ares.pending,
    };
  }, {},
)(AresDeployNotification);
