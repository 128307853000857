import apiReducer, {
  baseCollectionState,
} from 'shared/utils/redux/apiReducer';
import { CONSTANTS as PUSHER } from 'shared/3rdparty/pusher';
import * as STATUS from 'shared/modules/status/redux/constants';
import * as DNS from './constants';
import * as APP from '../constants';

export const appDns = (state = baseCollectionState, action) => {
  switch (action.type) {
    case PUSHER.STATUS_UPDATE: {
      const job = structuredClone(action.data);
      // we only care about dns-validation type jobs that we're watching
      // TODO: MGMT-1413 don't do the 'watch' jobs thing, since it only works in the current tab
      if (job.type === STATUS.jobTypes.dnsValidation
        && state.jobs?.includes(job.id)) {
        const newState = structuredClone(state);
        const validatingDomainIndex = state.data.findIndex((domain) => {
          // eslint-disable-next-line eqeqeq
          return domain.jobId == job.id;
        });
        // set a jobStatus property on the domain
        newState.data[validatingDomainIndex].jobStatus = job.overallStatus;

        // if its not a "terminal" status, return
        if (!STATUS.endedStatuses.includes(job.overallStatus)) {
          return newState;
        }

        // update the corresponding validation record on our side
        if (job.detail?.stepDetail?.validate?.valid) {
          newState.data[validatingDomainIndex] = {
            ...newState.data[validatingDomainIndex],
            validated: true,
            lastValid: new Date().toISOString(),
            partial: true,
          };
        } else {
          newState.data[validatingDomainIndex] = {
            ...newState.data[validatingDomainIndex],
            validated: false,
            partial: true,
          };
        }

        // remove job from watch list
        delete newState.data[validatingDomainIndex].jobId;
        newState.jobs = newState.jobs ? newState.jobs.filter((j) => j !== job.id) : [];

        return newState;
      }
      return state;
    }
    case DNS.TRIGGER_VALIDATION_REQUEST: {
      const newState = structuredClone(state);

      const validatingDomainIndex = state.data.findIndex((domain) => {
        // eslint-disable-next-line eqeqeq
        return domain.domainId == action.domainId;
      });

      newState.data[validatingDomainIndex] = {
        validated: null,
        ...newState.data[validatingDomainIndex],
      };

      newState.jobs = []; // don't reset already existing jobs...
      if (state.jobs) {
        newState.jobs = [...state.jobs];
      }

      return newState;
    }
    case DNS.TRIGGER_VALIDATION_SUCCESS: {
      const newState = structuredClone(state);

      const validatingDomainIndex = state.data.findIndex((domain) => {
        // eslint-disable-next-line eqeqeq
        return domain.domainId == action.domainId;
      });

      newState.data[validatingDomainIndex] = {
        ...newState.data[validatingDomainIndex],
        jobId: action.data.id,
        lastChecked: new Date().toISOString(),
      };

      newState.jobs.push(action.data.id);

      return newState;
    }
    case DNS.GET_VALIDATION_SUCCESS: {
      const newState = structuredClone(state);

      const validatingDomainIndex = state.data.findIndex((domain) => {
        // eslint-disable-next-line eqeqeq
        return domain.domainId == action.domainId;
      });

      if (validatingDomainIndex > -1) {
        const updatedData = {
          ...newState.data[validatingDomainIndex],
          ...action.data,
          lastChecked: new Date().toISOString(),
        };

        delete updatedData.partial;

        newState.data[validatingDomainIndex] = updatedData;
      }

      return newState;
    }
    default:
      return (
        apiReducer(
          DNS.GET_VALIDATIONS_REQUEST,
          DNS.GET_VALIDATIONS_SUCCESS,
          DNS.GET_VALIDATIONS_FAILURE,
          APP.APP_RESET,
          baseCollectionState,
        )(state, action)
      );
  }
};
