import React, { useEffect, Fragment } from 'react';
import {
  object, func, number, string, array,
} from 'prop-types';
import { connect } from 'react-redux';

import {
  Typography,
} from '@material-ui/core';

import Layout from 'shared/styleguide/molecules/Layout';
import Empty from 'shared/styleguide/atoms/Empty';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import { ErrorText } from 'shared/styleguide/typography';
import { groupBy } from 'shared/utils/arrays';
import { getAllValidationMessages } from 'shared/utils/validation';
import * as API from 'shared/utils/redux/constants';
import isPagelyAdmin from 'shared/utils/isAdmin';

import { fetchTargetAlerts } from '../redux/actions';
import { readStatusMap, templateTypes } from '../constants';
import AlertsList from './components/AlertsList';

const AlertsListLoader = ({ alerts, accountId, readStatus }) => {
  if (alerts.length === 0) {
    return null;
  }

  return (
    <Box margin={{ bottom: 'large' }}>
      <Typography variant="h3">{readStatus === readStatusMap.READ ? 'Read' : 'Unread'}</Typography>
      <AlertsList alerts={alerts} accountId={accountId} />
    </Box>
  );
};

AlertsListLoader.propTypes = {
  accountId: number,
  alerts: array,
  readStatus: string,
};

export const AlertsDisplay = ({
  fetchTargetAlerts, accountId, alerts, user,
}) => {
  const isAdmin = user && isPagelyAdmin(user);

  useEffect(() => {
    if (alerts.params?.accountId !== accountId) {
      fetchTargetAlerts(accountId, { includeTemplate: templateTypes.PUSH });
    }
  }, []);

  if ([API.PRISTINE, API.LOADING].includes(alerts.status)) {
    return (
      <Loading />
    );
  }

  if (alerts.status === API.FAILED) {
    return (
      <Box flex={1} margin={{ top: 'large' }}>
        <Empty>
          <ErrorText>
            {
              alerts.statusCode === 422
                ? (
                  getAllValidationMessages(alerts)
                ) : (
                  'Unable to load alerts'
                )
            }
          </ErrorText>
        </Empty>
      </Box>
    );
  }

  const groupedAlerts = isAdmin ? alerts.data : groupBy(alerts.data, (a) => !a.readStatus);

  return (
    <Layout title="Alerts">
      {
        alerts.data.length > 0
          ? (
            <Fragment>
              <Box margin={{ bottom: `${isAdmin ? 'none' : 'small'}` }}>
                <Typography variant="body2" color="textSecondary">All times are in UTC timezone</Typography>
              </Box>
              {
            isAdmin
              ? (
                <Box flex={1}>
                  <AlertsList alerts={alerts.data} accountId={accountId} />
                </Box>
              ) : (
                groupedAlerts
                  .map((alerts, i) => {
                    if (alerts.length === 0) {
                      return null;
                    }
                    const readStatus = i === 0 ? readStatusMap.UNREAD : readStatusMap.READ;
                    return <AlertsListLoader key={readStatus} readStatus={readStatus} alerts={alerts} accountId={accountId} />;
                  })
              )
          }
            </Fragment>
          ) : (
            <Box flex={1} margin={{ top: 'large' }}>
              <Empty>
                <Typography variant="body1">You have no alerts</Typography>
              </Empty>
            </Box>
          )
      }

    </Layout>
  );
};

AlertsDisplay.propTypes = {
  accountId: number,
  alerts: object,
  fetchTargetAlerts: func,
  user: object,
};

export default connect(
  (state) => ({
    accountId: state.account.id,
    alerts: state.alerts,
    user: state.user.user,
  }),
  {
    fetchTargetAlerts,
  },
)(AlertsDisplay);
