import { getHttp } from 'shared/http';
import makeApiAction from 'shared/utils/redux/apiActions';

import * as ACCOUNT from 'shared/modules/account/redux/constants';
import * as AUTH from './constants';

export const emailAddressChangeRequest = (accountID, update, reduxFormId) => makeApiAction(
  () => getHttp().post(`/accounts/${accountID}/email-change`, update),
  AUTH.EMAIL_CHANGE_REQUEST_REQUEST,
  AUTH.EMAIL_CHANGE_REQUEST_SUCCESS,
  AUTH.EMAIL_CHANGE_REQUEST_FAILURE,
  { reduxFormId },
  true,
);

export const emailAddressChangeReset = () => ({
  type: AUTH.EMAIL_CHANGE_REQUEST_RESET,
});

export const emailAddressVerifyRequest = (accountID, update) => makeApiAction(
  () => getHttp().post(`/accounts/${accountID}/email-verify`, update),
  AUTH.EMAIL_VERIFY_REQUEST_REQUEST,
  AUTH.EMAIL_VERIFY_REQUEST_SUCCESS,
  AUTH.EMAIL_VERIFY_REQUEST_FAILURE,
);

export const resetAccountValidation = () => ({
  type: ACCOUNT.ACCOUNT_RESET_VALIDATION,
});

export const checkEmailChangeToken = (accountID) => makeApiAction(
  ({ api }) => api().get(`/accounts/${accountID}/email-check-token`),
  AUTH.EMAIL_CHECK_TOKEN_REQUEST,
  AUTH.EMAIL_CHECK_TOKEN_SUCCESS,
  AUTH.EMAIL_CHECK_TOKEN_FAILURE,
  { accountID },
  true,
);
