import React from 'react';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import type { AccountUrl } from '../../types/AccountUrl';

const LoginButton: React.FC<AccountUrl> = ({ url, company }) => (
  <Box margin={{ top: 'large' }}>
    <Button
      data-testid={`login-button=${url}`}
      variant="outlined"
      fullWidth
      href={url}
      label={company}
      color="secondary"
    />
  </Box>
);

export default LoginButton;
