import makeApiAction from 'shared/utils/redux/apiActions';
import * as QP from './constants';

export const fetchCacheParamsForApp = (appId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/ares/queryparams/${appId}`),
  QP.GET_PARAMS_REQUEST,
  QP.GET_PARAMS_SUCCESS,
  QP.GET_PARAMS_FAILURE,
  { appId },
);

export const setCacheParams = (appId, body) => makeApiAction(
  ({ api }) => api().put(`/serverconfig/ares/queryparams/${appId}`, body),
  QP.SET_PARAMS_REQUEST,
  QP.SET_PARAMS_SUCCESS,
  QP.SET_PARAMS_FAILURE,
  { appId },
  true,
);
