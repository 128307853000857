import apiReducer, {
  baseCollectionState,
  baseState,
  initialRequestMethod,
  successMethod,
  failMethod,
} from 'shared/utils/redux/apiReducer';
import { consolidateTeamPermissions } from './utils';
import * as TEAM from './constants';

export function accountCollaborators(state = {
  ...baseCollectionState,
  collaboratorDirectory: [],
  hasAlertsSettings: false,
}, action) {
  switch (action.type) {
    case TEAM.GET_RECIPIENTS_SUCCESS: {
      const newState = structuredClone(state);
      const alertTypes = ['Disk', 'Security', 'Billing', 'Maintenance', 'App Activity', 'SSL'];
      const recipients = action.data.data;

      recipients.forEach((recipient) => {
        // find the corresponding collab in collab directory
        const [, id] = recipient.user.split(':');
        const collabIndex = newState.collaboratorDirectory.findIndex((collab) => collab.sourceId === Number(id));

        // if we find a matching collaborator
        if (collabIndex > -1) {
          const collaborator = newState.collaboratorDirectory[collabIndex];
          newState.collaboratorDirectory[collabIndex].alerts = {
            // make sure every type of alert is created
            'Disk': [],
            'Security': [],
            'Billing': [],
            ...collaborator?.alerts,
            [recipient.alertType]: recipient.deliveryTypes,
          };
        }
        // TODO: put this back when we don't need to harcode alert types
        // add alertType to our list if we haven't already
        // if (!alertTypes.includes(recipient.alertType)) {
        //   alertTypes.push(recipient.alertType);
        // }
      });

      // mark that we have alerts info available
      newState.hasAlertsSettings = true;
      newState.alertTypes = alertTypes;

      return newState;
    }
    case TEAM.SET_RECIPIENT_DELIVERY_SUCCESS: {
      const newState = structuredClone(state);
      const recipient = action.userId;

      // find which collab to update
      const collabIndex = newState.collaboratorDirectory.findIndex((collab) => collab.sourceId === recipient);

      newState.collaboratorDirectory[collabIndex].alerts = {
        ...newState.collaboratorDirectory[collabIndex]?.alerts,
        [action.data.alertType]: action.data.deliveryTypes,
      };

      return newState;
    }
    case TEAM.ACCOUNT_COLLABORATOR_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter((c) => !(c.appId === action.params.appId && c.sourceId === action.params.sourceId)),
      };
    case TEAM.ACCOUNT_COLLABORATOR_CREATE_SUCCESS: {
      const newState = {
        ...state,
        data: [...state.data],
      };
      newState.data.push(action.data);
      return newState;
    }
    case TEAM.ACCOUNT_COLLABORATOR_UPDATE_SUCCESS: {
      // TODO:
      const newData = [...state.data];
      const updateCollabIdx = newData.findIndex((collab) => collab.sourceId === action.sourceId);
      // eslint-disable-next-line no-param-reassign
      Object.keys(action.data).forEach((k) => action.data[k] == null && delete action.data[k]);
      newData.splice(updateCollabIdx, 1, {
        ...state.data[updateCollabIdx],
        ...action.data,
      });

      return {
        ...state,
        data: newData,
      };
    }
    case TEAM.ACCOUNT_COLLABORATORS_GET_REQUEST:
      return initialRequestMethod(state, action);

    case TEAM.ACCOUNT_COLLABORATORS_GET_SUCCESS: {
      const newState = action.data.data.reduce(consolidateTeamPermissions, []);

      return {
        ...successMethod(state, action),
        collaboratorDirectory: newState,
        hasAlertsSettings: false,
      };
    }
    case TEAM.ACCOUNT_COLLABORATORS_GET_FAILURE:
      return failMethod(state, action);
    default: return state;
  }
}

const accountCollaboratorCreate = apiReducer(
  TEAM.ACCOUNT_COLLABORATOR_CREATE_REQUEST,
  TEAM.ACCOUNT_COLLABORATOR_CREATE_SUCCESS,
  TEAM.ACCOUNT_COLLABORATOR_CREATE_FAILURE,
  TEAM.ACCOUNT_COLLABORATOR_RESET,
  { ...baseState },
);

export function accountCollaborator(state = baseState, action) {
  return accountCollaboratorCreate(state, action);
}
