import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  object, func, number, array, bool,
} from 'prop-types';
import { connect } from 'react-redux';

import Box from 'shared/styleguide/atoms/Box';

import {
  getDiskAddonCalculation, resetDiskAddonCalculation, resetDiskAddonPurchase,
} from 'shared/modules/billing/redux/disk/actions';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import {
  fetchDiskReportJobs, fetchDiskUpgradeJobs, resetDiskUpgradeJobs, fetchRecentDiskUpgradeJobs,
} from 'shared/modules/status/redux/actions';
import { getSortedJobs } from 'shared/modules/status/redux/selectors';
import * as STATUS from 'shared/modules/status/redux/constants';
import { consolidateErrors } from 'shared/utils/validation';

import { listReports, requestFullReport } from '../../redux/actions';

import { filterOutCustomMounts } from './diskUtils';

import DiskProgressView from './DiskProgressView';

export const PlanSummary = ({
  accountId,
  mounts,
  match,
  account,
  listReports,
  requestFullReport,
  jobs,
  isAdmin,
  stateMocks,
}) => {
  const [reports, setReports] = useState([]);
  const [diskReportsStatus, setDiskReportsStatus] = useState('initial');
  const [errors, setErrors] = useState(null);

  const handleGetDiskReportJobStatus = () => {
    fetchDiskReportJobs(accountId, { type: STATUS.jobTypes.diskReport, maxResults: 1 });
  };

  const handleSetDiskReportsStatus = (value) => {
    setDiskReportsStatus(value);
  };
  const getReports = async (update = false) => {
    setErrors(null);
    if (update) {
      setDiskReportsStatus('loading');
    }
    try {
      const response = await listReports(match.params.accountID);
      setReports(response?.data);
      if (update) {
        setDiskReportsStatus('success');
      }
    } catch (err) {
      setErrors(consolidateErrors(err));
      if (update) {
        setDiskReportsStatus('failed');
      }
    }
  };

  const memoziedGetReports = useCallback(getReports, [setDiskReportsStatus, setErrors, listReports, match.params.accountID]);
  let reportJob;
  let diskReportJobs;
  if (isAdmin) {
    diskReportJobs = jobs[STATUS.jobTypes.diskReport].data?.filter((job) => Number(job.ownerId) === accountId);
  } else {
    // pusher does not send ownerId, but customer won't receive jobs from other accounts
    diskReportJobs = jobs[STATUS.jobTypes.diskReport].data;
  }

  if (diskReportJobs?.length > 0) {
    [reportJob] = diskReportJobs;
  }

  useEffect(() => {
    // get the already created reports on page load
    memoziedGetReports(false);
  }, []);

  useEffect(() => {
    if (diskReportJobs?.[0]?.overallStatus === STATUS.SUCCESS
      && diskReportsStatus === 'initial') {
      memoziedGetReports(true);
    }
  }, [diskReportJobs, diskReportsStatus, memoziedGetReports]);

  const filteredMounts = filterOutCustomMounts(mounts);

  const sortedMounts = Object.values(filteredMounts)
    .sort((a, b) => {
      return a.petname >= b.petname ? 1 : -1;
    });

  const reportsByServerId = reports?.reduce((acc, report) => {
    if (!acc[report.serverId]) {
      acc[report.serverId] = report;
    }
    return acc;
  }, {});

  return (
    <Box as={Paper} padding="medium">
      {
        sortedMounts.map((server) => {
          const hasReport = reportsByServerId?.[server.id]?.timestamp;
          return (
            <DiskProgressView
              key={server.id}
              serverId={server.id}
              serverHostname={server.hostname}
              hasReport={hasReport}
              usage={server.usage}
              storageMax={server.allowed}
              serverName={server.petname}
              label={server.label}
              match={match}
              multiServer={Object.keys(filteredMounts).length > 1}
              requestFullReport={requestFullReport}
              reportJob={reportJob}
              onHandleGetDiskReportJobStatus={handleGetDiskReportJobStatus}
              diskReportsStatus={diskReportsStatus}
              onSetDiskReportsStatus={handleSetDiskReportsStatus}
              isAdmin={isAdmin}
              stateMocks={stateMocks}
            />
          );
        })
      }
    </Box>
  );
};

PlanSummary.propTypes = {
  account: object.isRequired,
  accountId: number,
  isAdmin: bool,
  isUpgradable: bool,
  jobs: object,
  listReports: func,
  match: object,
  mounts: array,
  requestFullReport: func,
  stateMocks: object,
};

export default connect(
  (state) => ({
    diskAddonPurchase: state.billing.diskAddonPurchase, // MOCKS.purchaseSuccessJob // MOCKS.purchaseSuccessTicket,
    jobs: {
      [STATUS.jobTypes.diskReport]: {
        ...state.status[STATUS.jobTypes.diskReport],
        data: getSortedJobs(state, STATUS.jobTypes.diskReport),
      },
    },
    // jobs: MOCKS.jobs,
  }),
  {
    listReports,
    fetchDiskReportJobs,
    fetchDiskUpgradeJobs,
    fetchRecentDiskUpgradeJobs,
    requestFullReport,
    getDiskAddonCalculation,
    resetDiskAddonCalculation,
    resetDiskAddonPurchase,
    resetDiskUpgradeJobs,
  },
)(PlanSummary);
