import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import { populateChatInfo } from 'shared/utils/livechat';
import { p20Pools } from 'shared/modules/account/constants';

const LiveChat = ({ account, user }) => {
  const chatAvailable = !p20Pools.includes(account.defPoolId);

  return (
    <Paper>
      <Box padding="small">
        <Typography variant="h3" gutterBottom>
          Live Chat
        </Typography>
        <Box>
          <Typography>
            Did you know that our typical ticket response time is less than 15 minutes?
          </Typography>
          <Box direction="row" margin={{ top: 'small' }}>
            {
              chatAvailable ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    populateChatInfo({ user: user.user, account }, true);
                  }}
                >
                  Live Chat
                </Button>
              ) : (
                <Typography color="textSecondary">Live chat is not supported on your account.</Typography>
              )
            }
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

LiveChat.propTypes = {
  account: object,
  user: object,
};

export default connect(
  (state) => ({
    account: state.account,
    user: state.user,
  }),
)(LiveChat);
