import { Billing } from '../../types';
import { limitedAddonKeys } from './constants';
import { PlanData } from './types';

export const additionalAddonsFilter = (addon: Billing.SubscriptionBySubscriptionIdAndAccountId.Addon): boolean => {
  return addon.unitPrice > 0;
};

export const isLimitedAddon = (addonKey: string): boolean => Object.values(limitedAddonKeys).some((k) => addonKey.toLowerCase().match(k));

export const extractAddons = (addons: PlanData['limitedAddons'], filter: (f: string) => void): PlanData['limitedAddons'] => {
  const addonEntries = Object.entries(addons);
  const limitedAddonEntries = addonEntries.filter(([addonKey, _]) => filter(addonKey));
  return Object.fromEntries(limitedAddonEntries) as PlanData['limitedAddons'];
};

export const marshalledAddons = (addons: PlanData['limitedAddons']): {
  [key in 'server' | 'database' | 'regions' | 'other']: Billing.SubscriptionBySubscriptionIdAndAccountId.Addon[];
} => {
  const isServerAddon = (addon) => addon.name.startsWith('Server:') || addon.name.startsWith('Addon Server:');
  const isDatabaseAddon = (addon) => addon.name.startsWith('Database:') || addon.name.startsWith('Addon Database:') || addon.name.startsWith('Shared Database');
  const isRegionAddon = (addon) => addon.name.startsWith('Region:') || addon.name.startsWith('Addon Region:');

  const server = [];
  const database = [];
  const regions = [];
  let other = [];
  const addonMap = new Map(Object.entries(addons));

  // eslint-disable-next-line no-restricted-syntax
  for (const [addonKey, addon] of addonMap) {
    if (isLimitedAddon(addonKey)) {
      addonMap.delete(addonKey);
    }
    if (isServerAddon(addon)) {
      server.push(addon);
      addonMap.delete(addonKey);
    }
    if (isDatabaseAddon(addon)) {
      database.push(addon);
      addonMap.delete(addonKey);
    }
    if (isRegionAddon(addon)) {
      regions.push(addon);
      addonMap.delete(addonKey);
    }
  }
  // remaining addons that do not fall into the above buckets
  other = [...addonMap.values()];
  return {
    server,
    database,
    regions,
    other,
  };
};
