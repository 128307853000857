export const DISK_EARLIEST_DATE = '2020-06-02';

export const DEFAULT_DAY_SPREAD = {
  disk: 30,
  cpu: 7,
};

export const DIMENSIONS_TYPE_MAP = {
  initial: [],
  system: [
    'system',
  ],
  app: [
    'appIds',
  ],
  homedir: [
    'username',
  ],
  gitrepo: [
    'appIds',
  ],
  location: [
    'location',
  ],
};

export const DISK_ADDON_BASE_PRODUCT_ID = 23;
export const CDN_ADDON_BASE_PRODUCT_ID = 22;
export const BANDWIDTH_ADDON_BASE_PRODUCT_ID = 24;
export const SITES_ADDON_BASE_PRODUCT_ID = 26;

export const PERCENT_THRESHOLD = 85;
