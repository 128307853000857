import React from 'react';
import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';

import PoolReload from './PoolReload';
import ServerList from './ServerList';

export const Infrastructure =() =>  { // eslint-disable-line
  return (
    <Box padding="medium">
      <Typography variant="h2" gutterBottom>Infrastructure Actions</Typography>
      <ServerList />
      <PoolReload />
    </Box>
  );
};

export default Infrastructure;
