// import React from 'react';

import Profile from './Profile/page';
import Security from './Security';
import SSH from './SSH';

// const DummyComponent = () => (<div> hello!</div>);

export const routes = [
  {
    label: 'Profile',
    to: 'profile',
    match: new RegExp(/\/settings\/profile$/), // eslint-disable-line
    component: Profile,
  },
  {
    label: 'Security',
    to: 'security',
    match: new RegExp(/\/settings\/security$/), // eslint-disable-line
    component: Security,
  },
  {
    label: 'SSH',
    to: 'ssh',
    match: new RegExp(/\/settings\/ssh$/), // eslint-disable-line
    component: SSH,
  },
  // {
  //   label: 'Notifications',
  //   to: 'notifications',
  //   match: new RegExp(/\/settings\/notifications$/), // eslint-disable-line
  //   component: DummyComponent,
  // },
];
