import logger from 'shared/3rdparty/logger';

const sizes = {
  none: 0, //  0
  xxsmall: 0.25, // 4px
  xsmall: 0.75, // 12px
  small: 1, // 16px
  medium: 1.5, // 24px
  mediumLarge: 2.25, // 36px
  large: 2.5, // 40px
  xlarge: 4, // 64px
  xxlarge: 4.5, // 72px
} as const;

type Size = keyof typeof sizes;
type SizeValue = typeof sizes[keyof typeof sizes];
const spacing = (size: Size): SizeValue => {
  if (sizes[size] === undefined) {
    logger.log(`Incorret size passed to spacing. Size "${size}" is invalid.`);
  }
  return sizes[size];
};

export default spacing;

/**
 *
 * @param size: none 0 | xxsmall 4px | xsmall 12px | small 16px | medium 24px | mediumLarge 36px | large 40px | xlarge 64px;
 * @returns string
 */
export const remMapper = (size: Size): `${SizeValue}rem` => {
  return `${spacing(size)}rem`;
};
