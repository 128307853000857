import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  object, number,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import Empty from 'shared/styleguide/atoms/Empty';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import { GIT_WEBHOOK } from 'shared/modules/integrations/constants';
import {
  fetchIntegrationsList, deleteIntegration, fetchDeployToken,
} from 'shared/modules/integrations/redux/actions';
import { consolidateErrors } from 'shared/utils/validation';

import WebhookIntegration from './WebhookIntegration';
import Webhook from './Webhook';

export const WebhookIntegrations = ({
  integrations, appId, accountId, __storybookMocks,
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('pristine');
  const [error, setError] = useState(null);
  const [tokenError, setTokenError] = useState(__storybookMocks?.tokenError ?? false);
  const [creating, setCreating] = useState(__storybookMocks?.creating ?? false);
  const [tokenChecked, setTokenChecked] = useState(__storybookMocks?.tokenChecked ?? false);
  const [tokenStatus, setTokenStatus] = useState(__storybookMocks?.tokenStatus ?? 'pristine');
  const [checkForToken, setCheckForToken] = useState(__storybookMocks?.checkForToken ?? true);
  const [secondsSinceCreation, setSecondsSinceCreation] = useState(__storybookMocks?.secondsSinceCreation ?? 0);
  const [webhookLoaded, setWebhookLoaded] = useState(__storybookMocks?.webhookLoaded ?? false);

  const webhookIntegration = integrations.data?.find((integration) => {
    return integration.appIds.includes(Number(appId)) && integration.type === GIT_WEBHOOK;
  }) || null;

  const hasSsh = !!webhookIntegration?.config?.git?.public_key;

  const checkforSecrets = async (integrationId) => {
    setTokenChecked(true);

    if (hasSsh) {
      setError(null);
      setTokenStatus('success');
      setTokenError(false);
      setCheckForToken(false);
      return;
    }

    setTokenStatus('loading');

    const response = await dispatch(fetchDeployToken(integrationId));

    const publicKey = response.config?.git?.public_key;

    if (publicKey) {
      setError(null);
      setTokenStatus('success');
    } else {
      setTokenStatus('failed');
    }
  };

  const memoizedCheckForSecrets = useCallback(checkforSecrets, [checkforSecrets, fetchDeployToken, hasSsh]);

  const handleSetSecondsSinceCreation = (created) => {
    const timeDiff = Math.floor(((new Date()).getTime() - (new Date(created)).getTime()) / 1000);
    if (timeDiff !== secondsSinceCreation) {
      setSecondsSinceCreation(timeDiff);
    }
  };

  const memoizedHandleSetSecondsSinceCreation = useCallback(handleSetSecondsSinceCreation, [setSecondsSinceCreation, secondsSinceCreation]);

  useEffect(() => {
    if (webhookIntegration?.id && !webhookLoaded) {
      setWebhookLoaded(true);
    }

    if (webhookIntegration?.id && tokenStatus === 'failed' && !checkForToken && !hasSsh && !tokenError) {
      setTokenError(true);
    }

    if (hasSsh && tokenError) {
      setTokenError(false);
    }

    if (!__storybookMocks && checkForToken && (hasSsh || (!hasSsh && secondsSinceCreation > 900))) {
      // don't attempt to fetch token any more - 15 minute check length
      setCheckForToken(false);
    }

    if (!tokenChecked && !hasSsh && webhookIntegration) {
      if (webhookIntegration) {
        memoizedHandleSetSecondsSinceCreation(webhookIntegration.created);
      }

      if (!['loading', 'pristine'].includes(integrations.status)) {
        // initial check
        memoizedCheckForSecrets(webhookIntegration.id);
      }
    }

    const interval = setInterval(() => {
      if (webhookIntegration && tokenStatus !== 'loading' && checkForToken && !['loading', 'pristine'].includes(integrations.status)) {
        // subsequent check every 15 seconds
        memoizedCheckForSecrets(webhookIntegration.id);
      }

      if (webhookIntegration) {
        memoizedHandleSetSecondsSinceCreation(webhookIntegration.created);
      }
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [tokenChecked, tokenStatus, webhookIntegration, tokenError, setTokenError,
    checkForToken, status, hasSsh, setTokenStatus, secondsSinceCreation, webhookLoaded,
    memoizedHandleSetSecondsSinceCreation, memoizedCheckForSecrets, __storybookMocks]);

  useEffect(() => {
    setTokenChecked(false);
  }, [integrations.status]);

  const handleCreating = () => {
    setError(null);
    setCreating(true);
    setCheckForToken(true);
  };

  const handleDeleteWebhookIntegration = async (integrationId) => {
    setStatus('loading');
    setError(null);
    await dispatch(deleteIntegration(integrationId))
      .then(() => {
        dispatch(fetchIntegrationsList(accountId));
        setStatus('success');
        setError(null);
      })
      .catch((err) => {
        setStatus('failed');
        setError(consolidateErrors(err));
      });
  };

  const showWebhook = !checkForToken && !!webhookIntegration;

  const fetchingSecrets = ['pristine', 'loading', 'failed'].includes(tokenStatus)
    && webhookIntegration && !creating && !hasSsh;

  const webhookLoading = [status, integrations.status].includes('loading') || fetchingSecrets;

  if ((webhookLoading || error) && !tokenError) {
    return (
      <Box margin={{ top: 'medium' }} flex={1}>
        <Typography variant="h3">Git Webhook Integration</Typography>
        <Box margin={{ top: 'small' }}>
          {
            error ? (
              <Empty error style={{ width: '100%' }}>
                <ErrorText>{error}</ErrorText>
              </Empty>
            ) : <Loading />
          }
        </Box>
      </Box>
    );
  }

  return (showWebhook)
    ? (
      <Webhook
        key={webhookIntegration.id}
        integration={webhookIntegration}
        onDeleteWebhookIntegration={handleDeleteWebhookIntegration}
        tokenError={tokenError}
      />
    ) : (
      <WebhookIntegration
        integration={webhookIntegration}
        appId={appId}
        accountId={accountId}
        hasSsh={hasSsh}
        onCreating={handleCreating}
        creating={creating}
      />
    );
};

WebhookIntegrations.propTypes = {
  __storybookMocks: object,
  accountId: number,
  appId: number,
  integrations: object,
};

export default WebhookIntegrations;
