import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { jss, inline } from './styles';

export class Modal extends React.Component {
  static propTypes = {
    ...Dialog.propTypes,
    closable: PropTypes.bool,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    renderProp: PropTypes.func,
  };

  static defaultProps = {
    open: true,
    closable: true,
  };

  state = {
    title: this.props.title,
    subTitle: this.props.subTitle,
  };

  componentDidMount() {
    if (this.props.title) {
      this.handleSetTitle(this.props.title, this.props.subTitle);
    }
  }

  handleSetTitle = (title, subTitle) => this.setState({ title, subTitle });

  render() {
    const {
      renderProp, classes, closable, actions, onClose, overflow, ...rest
    } = this.props;
    return (
      <Dialog
        maxWidth="sm"
        PaperProps={{ style: inline.dialog }}
        onClose={onClose}
        fullWidth
        {...rest}
      >
        <DialogTitle>
          <Box>
            <Typography variant="h3">{this.state.title}</Typography>
            {
              this.state.subtitle && (
                <Typography variant="subtitle1" color="textSecondary">{this.state.subTitle}</Typography>
              )
            }
          </Box>
          {
            closable ? (
              <IconButton
                classes={{ root: classes.closeIcon }}
                size="small"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null
          }
        </DialogTitle>
        <DialogContent
          style={{ overflow }}
          scroll="body"
          css={{
            ...(!actions && { paddingBottom: remMapper('medium') }),
          }}
        >
          {
            renderProp && renderProp({ setTitle: this.handleSetTitle, closeModal: this.props.onClose })
          }

          {
            this.props.children
          }
        </DialogContent>
        {
          actions && (
            <DialogActions>
              {actions}
            </DialogActions>
          )
        }
      </Dialog>

    );
  }
}

export default withStyles(jss)(Modal);
