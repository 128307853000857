import React, { Component } from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import { Paper } from '@material-ui/core';
import OpenIcon from '@material-ui/icons/OpenInNew';
import Typography from '@material-ui/core/Typography';

import Row from 'shared/styleguide/atoms/Row';
import Select from 'shared/styleguide/atoms/Select/Select';
import NewButton from 'shared/styleguide/atoms/Buttons/NewButton';
import { HeadlineText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';

import {
  loadConfig, setPropLevels, updateItem, parseSchema, changeConfig, undoRedo,
} from '../redux/actions';

import schema from '../json/schema.json';
import RuleBlocks from '../components/RuleBlocks';
import NonBlockRules from '../components/NonBlockRules';
import ImportConfig from '../components/modal/ImportConfig';
import JsonOutput from '../components/modal/JsonOutput';
import RedirectsWizard from '../components/modal/RedirectsWizard';

import { sortTopBlocks, getConfigType, capitalize } from '../helpers';

const configsAvailable = ['redirects', 'rules', 'miss-rules'];
const configsAvailableSelect = configsAvailable.map(((item) => ({
  value: item,
  label: item,
})));

class Tool extends Component {
  static propTypes = {
    canRedo: any,
    canUndo: any,
    changeConfig: any,
    loadConfig: any,
    parseSchema: any,
    schema: any,
    setPropLevels: any,
    state: any,
    undoRedo: any,
  };

  componentDidMount() {
    this.props.loadConfig('redirects');
    this.props.parseSchema(schema);
  }

  handleChangeConfig = (configType) => (e) => {
    if (e.value !== null && e.value !== configType) {
      this.props.changeConfig(this.props.state.config, configType, e.value, this.props.schema);
      this.props.setPropLevels(e.value);
    }
  };

  handleReset = (configType) => (e) => {
    e.preventDefault();
    this.props.loadConfig(configType);
  };

  render() {
    if (!this.props.state.config || !this.props.schema.properties) {
      // don't load without schema loaded in store
      return null;
    }
    const configType = getConfigType(this.props.state.config);
    let configWrapper = '';
    let propLevels;
    if (configType === 'redirects') {
      configWrapper = 'redirects';
      propLevels = `config.${configType}`;
    } else {
      configWrapper = '';
      propLevels = 'config';
    }

    return (
      <Box>
        <Box margin={{ bottom: 'small' }}>
          <Box align="center" direction="row">
            <Typography variant="h4" style={{ paddingBottom: 6, marginRight: 5 }}>
              Living Doc
            </Typography>
            <div>
              <a
                href="https://docs.google.com/document/d/1NvYys6Vkd7WkSKHaUNiopqdxq7jWZli1qw1aNQtGQ9Q"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <OpenIcon />
              </a>
            </div>
          </Box>
          <Typography variant="body2">
            Please update with Bugs / Feature Requests
          </Typography>
        </Box>
        <Row style={{ alignItems: 'flex-start' }} fullWidth>
          <div style={{ width: '100%' }}>
            <HeadlineText>{capitalize(configType)}</HeadlineText>
            <Box
              row
              align="center"
              justify="flex-start"
              flex={1}
              margin={{ top: 'small', bottom: 'small' }}
            >
              <div style={{ marginRight: 10, minWidth: 236 }}>
                { /* eslint-disable jsx-a11y/no-onchange */}
                <Select
                  onChange={this.handleChangeConfig(configType)}
                  placeholder="Change Config Type..."
                  value={configsAvailableSelect.filter((v) => v.value === configType)}
                  options={configsAvailableSelect}
                />
              </div>
              <Box row gap="xsmall">
                {
                  configType === 'redirects'
                  && (<RedirectsWizard />)
                }
                <ImportConfig />
                <JsonOutput />
                <NewButton
                  color="default"
                  variant="outlined"
                  onClick={this.handleReset(configType)}
                >
                  Reset
                </NewButton>
                <NewButton
                  color="default"
                  variant="outlined"
                  disabled={!this.props.canUndo}
                  onClick={() => {
                    if (this.props.canUndo) {
                      this.props.undoRedo('undo');
                    }
                  }}
                >
                  Undo
                </NewButton>
                <NewButton
                  color="default"
                  variant="outlined"
                  disabled={!this.props.canRedo}
                  onClick={() => {
                    if (this.props.canRedo) {
                      this.props.undoRedo('redo');
                    }
                  }}
                >
                  Redo
                </NewButton>
              </Box>
            </Box>
            <Paper
              style={{
                padding: '20px', marginBottom: 20, marginTop: 20,
              }}
            >
              <div className="rulesSorter" style={{ maxWidth: '100%' }}>
                <NonBlockRules
                  value={sortTopBlocks(this.props.state.config, configWrapper).nonRuleBlocks}
                  propLevels={propLevels}
                />
                <RuleBlocks
                  value={sortTopBlocks(this.props.state.config, configWrapper).ruleBlocks}
                  propLevels={propLevels}
                />
              </div>
            </Paper>
          </div>
        </Row>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    undoableState: state.aresConfig.undoableConfig,
    schema: state.aresConfig.schema,
    canUndo: state.aresConfig.undoableConfig.past.length > 1,
    canRedo: state.aresConfig.undoableConfig.future.length > 0,
  };
};

export default connect(mapStateToProps, {
  loadConfig, setPropLevels, updateItem, parseSchema, changeConfig, undoRedo,
})(Tool);
