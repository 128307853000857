import React, { useState } from 'react';
import {
  number, array,
} from 'prop-types';
import { Collapse, Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { isObject } from 'shared/utils/types';
import { getIps } from '../../utils';
import NewList from './NewList';
import ListItem from './ListItem';

const RateLimits = ({
  appId, rateLimits, __storybookMocks,
}) => {
  const [wizardOpen, setWizardOpen] = useState(__storybookMocks?.wizardOpen || false);
  const [changed, setChanged] = useState(false);

  const existingRulesHash = rateLimits.map((r) => r.name);

  const handleSetChanged = (value) => {
    if (isObject(value) && !changed) {
      // formik validation triggered
      return setChanged(true);
    }

    if (value !== changed) {
      setChanged(value);
    }
  };

  const handleSetWizardOpen = (value) => {
    setWizardOpen(value);

    if (value) {
      setChanged(false);
    }
  };

  const handleValidate = ({ ips, ...values }, actions, mode) => {
    let hasErrors = false;

    // contruct object to send
    const body = { ...values };

    if (!body.name) {
      hasErrors = true;
      actions.setFieldError('name', 'Must name this configuration');
    }
    if (!body.type) {
      hasErrors = true;
      actions.setFieldError('type', 'Must choose a type');
    }

    if (mode === 'create' && existingRulesHash?.includes(body.name)) {
      hasErrors = true;
      actions.setFieldError('name', 'Rule with same name already exists');
    }

    if (hasErrors) {
      return [{}, hasErrors];
    }

    if (values.enabled === undefined) {
      body.enabled = true;
    }

    if (body.type === 'ip-list') {
      body.ips = getIps(ips);
    }

    return [body, false];
  };

  return (
    <div>
      <Box direction="row" justify="space-between" align="center" margin={{ bottom: 'small' }}>
        <Typography variant="h3">Disable Rate Limiting</Typography>
        {
          !wizardOpen && (
            <Button
              aria-label="open rate limits wizard"
              color="secondary"
              variant="contained"
              onClick={() => handleSetWizardOpen(true)}
            >
              New Configuration
            </Button>
          )
        }
      </Box>
      <Collapse in={wizardOpen} unmountOnExit>
        <NewList
          onClose={() => handleSetWizardOpen(false)}
          appId={appId}
          onValidate={handleValidate}
          listLabel="Configuration"
          changed={changed}
          onSetChanged={handleSetChanged}
        />
      </Collapse>

      {/* If its empty, show empty */}
      {rateLimits.length === 0 && (
        <Empty>There are no Rate Limit Configurations</Empty>
      )}
      {
        rateLimits.length > 0 && rateLimits.map((record) => {
          return (
            <ListItem
              key={record.id}
              appId={appId}
              record={record}
              onValidate={handleValidate}
            />
          );
        })
      }
    </div>
  );
};

RateLimits.propTypes = {
  appId: number.isRequired,
  rateLimits: array.isRequired,
};

export default RateLimits;
