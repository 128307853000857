/* eslint no-underscore-dangle: "off" */
const url = '/api';
// const url = process.env.REACT_APP_MGMT_API_BASE_URL || '/api';

if (typeof window !== 'undefined') {
  // Documentation: http://webpack.github.io/docs/configuration.html#output-publicpath
  window.__webpack_public_path__ = `${window.location.protocol}//${window.location.host}/`;
}

export const getAtomicBaseDomain = () => {
  switch (window.location.hostname) {
    case 'mgmt.pagely.com': return 'https://atomic.pagely.com';
    case 'mgmt-staging.pagely.com': return 'https://atomic-staging.pagely.com';
    case 'mgmt-test.prod.pagely.com': return 'https://atomic-test.prod.pagely.com';
    case 'localhost': return 'http://localhost:4000';
    default: return window.location.origin;
  }
};

export const getDeployEnvironment = () => {
  switch (window.location.hostname) {
    case 'mgmt.nstk.net':
    case 'mgmt.woosaas.godaddy.com':
    case 'mgmt.mwcs.godaddy.com': return 'woosaas';

    case 'atomic-beta.pagely.com': return 'production';
    case 'mgmt.pagely.com': return 'production';
    case 'atomic.pagely.com': return 'production';

    case 'atomic-test.prod.pagely.com': return 'preview';
    case 'mgmt-test.prod.pagely.com': return 'preview';

    case 'atomic.dev.pagely.com': return 'staging';
    case 'atomic-staging.pagely.com': return 'staging';
    case 'mgmt-staging.pagely.com': return 'staging';
    case 'mgmt.dev.pagely.com': return 'staging';
    default:
      // we're probably on localhost
      if (process.env?.STAGE?.includes('gd')) {
        return 'woosaas';
      }
      return 'development';
  }
};

export default {
  BASE_URL: url,
  APP_VERSION: process.env.TAG_VERSION,
};
