import React, { Fragment } from 'react';
import { object, string } from 'prop-types';

import { Switch, Route, Redirect } from 'react-router-dom';
import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';
import Layout from 'shared/styleguide/molecules/Layout';
import { routes } from './routes';
import Overview from './Overview';

const BillingRoutes = (props) => {
  const {
    match,
  } = props;
  const expired = false;

  const allRoutes = [...routes];
  const overview = allRoutes.shift();

  return (
    <Layout title="Payment and Plan">
      <Route
        exact
        path={`${match.path}`}
        render={() => (
          <Redirect to="billing/overview" />
        )}
      />
      <Route
        path={`${match.path}/:view*`}
        render={(props) => (<Navigation {...props} routes={routes} parentPath={match.url} />)}
      />
      <Switch>
        {
          allRoutes.concat().reverse().map((child) => {
            return (
              <Route key={child.to} path={`${match.path}/${child.to}`} component={child.component} />
            );
          })
        }
        <Route key={overview.to} path={`${match.path}`} render={(props) => (<Overview expired={expired} {...props} />)} />
      </Switch>
    </Layout>
  );
};

BillingRoutes.propTypes = {
  match: object.isRequired,
  parentPath: string,
};

export default BillingRoutes;
