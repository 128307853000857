import React from 'react';
import Box from 'shared/styleguide/atoms/Box';
import NoteListItem from './NoteListItem';
import type { Note } from '../../types';

export const NoteList = ({ notes }: { notes: Note[]}) => {
  return (
    <Box direction="column">
      {
        notes.map((note) => (
          <NoteListItem
            key={note.id}
            note={note}
          />
        ))
      }
    </Box>
  );
};
export default NoteList;
