import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import apiReducer, {
  baseCollectionState,
  load,
  success,
  fail,
} from 'shared/utils/redux/apiReducer';
import { ACCOUNT_SUCCESS } from 'shared/modules/account/redux/constants';
import {
  SERVER_TAGS_FAILURE,
  SERVER_TAGS_REQUEST,
  SERVER_TAGS_SUCCESS,
  SERVER_TAGS_RESET,
  SERVER_MOUNTS_REQUEST,
  SERVER_MOUNTS_SUCCESS,
  SERVER_MOUNTS_FAILURE,
} from './constants';

export const initialState = {
  ...baseCollectionState,
  accountId: null,
};

export function servertags(state = initialState, action) {
  switch (action.type) {
    case SERVER_TAGS_REQUEST:
      return {
        ...load(state, action),
        accountId: action.accountId,
      };
    case SERVER_TAGS_FAILURE:
      return {
        ...fail(state, action),
      };
    case SERVER_TAGS_SUCCESS:
      return {
        ...success(state, action),
        count: action.data['@count'],
      };

    case SERVER_TAGS_RESET:
    case LOGIN_LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
}

export const mounts = apiReducer(
  SERVER_MOUNTS_REQUEST,
  SERVER_MOUNTS_SUCCESS,
  SERVER_MOUNTS_FAILURE,
  ACCOUNT_SUCCESS,
  baseCollectionState,
);
