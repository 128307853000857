import React, { Fragment, useEffect } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { isObject } from 'shared/utils/types';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import Empty from 'shared/styleguide/atoms/Empty';
import LoadingCard from 'shared/styleguide/molecules/LoadingCard';
import Box from 'shared/styleguide/atoms/Box';
import { isGateway2Enabled } from 'shared/utils/functions';
import { fetchAppMetaTags } from 'shared/modules/metadata/redux/actions';
import { reloadAppByAppId } from 'shared/modules/server/redux/actions';
import Banner from 'shared/styleguide/molecules/Banner';
import { roles as ROLES } from 'shared/modules/permissions/user/actions';
import InfoText from 'shared/styleguide/molecules/InfoText';

import Navigation from './Navigation';
import { routes } from './routes';

export const Ares = ({
  tags, appMeta, fetchAppMetaTags, reloadAppByAppId, pendingDeploys, permissions, __storybookMocks, matchMock = null,
}) => {
  let match = useRouteMatch();
  if (matchMock) {
    match = matchMock;
  }
  const { url, path, params } = match;
  const currentRole = permissions.canAccess.find((perm) => perm.targetId === Number(params.accountID))?.role;

  useEffect(() => {
    if (appMeta.status === 'pristine' || appMeta.params?.appId !== params.appId) {
      fetchAppMetaTags(params.appId);
    }
  }, [appMeta.status, appMeta.params, params.appId]);

  if (currentRole === ROLES.APPONLYMINIMAL) {
    return (<Empty>Your account type does not have permission to access this feature.</Empty>);
  }

  if (!isGateway2Enabled(tags.server.data, tags.account.data)) {
    return (<Empty>This feature is only available on ARES-enabled accounts. Please request access by opening a support ticket</Empty>);
  }

  let hasCustomConfig;
  let mainRoute;
  let subRoutes = [];

  if (appMeta.status === 'success') {
    const enabledAresFeatures = appMeta.data.find((key) => key.keyId === 'system:ares-features')?.value || [];
    hasCustomConfig = appMeta.data.find((key) => key.keyId === 'system:ares.custom-configs') || false;

    let customConfig = {};

    if (hasCustomConfig) {
      customConfig = isObject(hasCustomConfig.value) ? hasCustomConfig.value : JSON.parse(hasCustomConfig.value);
    }

    subRoutes = routes.map((route, i) => {
      const extraProps = {
        customConfig: customConfig[route.fallbackKey],
        __storybookMocks,
      };

      // check metadata_app_keys for system:ares-features
      // eg ['access-control','rate-limit','header','cache','redirect']
      // if a feature isn't listed show the "dummy component"
      const hasFallback = Boolean(route.fallback) && Boolean(route.fallbackKey);

      const showFeature = (hasFallback && enabledAresFeatures.includes(route.fallbackKey)) || route.to === 'tester';

      const Component = showFeature ? route.component : route.fallback;

      if (i === 0) {
        mainRoute = <Route render={(props) => <Component {...props} {...extraProps} />} />;
      }

      return <Route key={route.to} path={`${path}/${route.to}`} render={(props) => <Component {...props} {...extraProps} />} />;
    });
  }

  // when the pendingDeploy notification shows it may cover something, so add margin
  const pendingMargin = pendingDeploys.data.length ? (pendingDeploys.data.length * 1.5) + 5 : 0;

  return (
    <Box>
      <InfoText type="warning">
        When any ARES rules are changed, any NGINX reload will cause the changes to be deployed, which can happen without your direct action.
      </InfoText>
      <Box
        direction="row"
        flex={1}
        gap="large"
        margin={{ bottom: pendingMargin }}
      >
        <Navigation
          routes={routes}
          fallback={routes[0].to}
          reloadPool={reloadAppByAppId}
          appId={params.appId}
          pendingDeploys={pendingDeploys.data}
        />
        <Box flex={1}>
          <ErrorBoundary>
            {
              appMeta.status !== 'success' ? (
                <LoadingCard />
              ) : (
                <Fragment>
                  {
                    hasCustomConfig && (
                      <Banner
                        subheading={(
                          <Typography color="inherit" variant="body2">There are some custom configurations on this app that are not yet editable in the new UI. If you have concerns that changes you make here may conflict with those configurations, please contact support.</Typography>
                        )}
                        type="info"
                        dismissable
                        rounded="top"
                      />
                    )
                  }
                  <Switch>
                    {subRoutes}
                    {mainRoute}
                  </Switch>
                </Fragment>
              )
            }
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

Ares.propTypes = {
  __storybookMocks: object,
  appMeta: object.isRequired,
  fetchAppMetaTags: func.isRequired,
  matchMock: object,
  pendingDeploys: object.isRequired,
  permissions: object.isRequired,
  reloadAppByAppId: func.isRequired,
  tags: object.isRequired,
};

export default connect(
  (state) => ({
    tags: state.tags,
    appMeta: state.app.metadata,
    pendingDeploys: state.ares.pending,
    permissions: state.permissions,
  }),
  {
    fetchAppMetaTags,
    reloadAppByAppId,
  },
)(Ares);
