import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { listReports } from 'shared/modules/disk/redux/actions';
import { jobTypes } from 'shared/modules/status/redux/constants';
import { roles as ROLES } from 'shared/modules/permissions/user/actions';
import { changeDiskRoles } from 'shared/modules/disk/constants';
import { fetchAppsForAccount } from 'shared/modules/apps/redux/actions';
import {
  resetDiskUpgradeJobs, resetAppCreateJobs, resetGitWebhookDeployJobs, resetDiskReportJobs,
} from 'shared/modules/status/redux/actions';
import { getTypeAndVersionFromJob } from 'shared/modules/status/utils';
import logger from 'shared/3rdparty/logger';

const {
  appCreate, gitWebhookDeploy, diskReport, diskUpgradeVps, diskUpgradeHA, webconfig, fsaSetup, fsaDisable,
} = jobTypes;

function useNotifications(job, accountId, apps, permissions, isPagelyAdmin) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState('pristine');
  const [notification, setNotification] = useState(null);
  const [hasPermission, setHasPermission] = useState(null);
  let currentRole = 0;

  const accountAccess = permissions.canAccess.find((perm) => perm.targetId === accountId);

  if (isPagelyAdmin !== null) {
    if (isPagelyAdmin) {
      currentRole = ROLES.PRIMARY;
    } else {
      currentRole = accountAccess?.role ?? 0;
    }
  }

  const updateNotificationUrl = (notification, job, url) => {
    const updatedNotification = { ...notification };
    updatedNotification[job.overallStatus].url = url;
    setNotification(updatedNotification);
  };

  const checkPermissions = (job, notification) => {
    setHasPermission(notification?.roles.includes(currentRole));

    if ([appCreate, gitWebhookDeploy].includes(job.type)) {
      const appAccess = accountAccess.allAppIds.includes(job.ownerId);
      setHasPermission(appAccess);
    }
  };

  const getReports = async (accountId) => {
    setStatus('loading');
    try {
      const response = await dispatch(listReports(accountId));
      const report = response?.data?.[0];
      setUrl(`/account/${accountId}/disk/${report.serverId}/${report.timestamp}/report/summary`);
      setStatus('success');
    } catch (err) {
      setStatus('failed');
    }
  };

  const getApps = (accountId) => {
    setStatus('loading');
    try {
      dispatch(fetchAppsForAccount(accountId));
    } catch (err) {
      logger.error(err);
    }
  };

  const getPhpVersionJobMeta = (job) => {
    const [subtype, version] = getTypeAndVersionFromJob(job);
    if (['php', 'docker-php-version'].includes(subtype)) {
      return [subtype, version];
    }

    return [];
  };

  const getNotificationParams = () => {
    switch (job?.type) {
      case appCreate:
        return {
          id: job?.id,
          text: `${apps[job?.ownerId]?.name} App creation`,
          roles: [...Object.values(ROLES)],
          SUCCESS: {
            url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
            text: 'Go to App dashboard',
            callback: () => dispatch(resetAppCreateJobs()),
          },
          FAILURE: {
            url: `/account/${accountId}/disk`,
            text: 'Go to Apps list',
            callback: () => dispatch(resetAppCreateJobs()),
          },
        };
      case fsaSetup:
        return {
          id: job?.id,
          text: `${apps[job?.ownerId]?.name} Mercury Dynamic Site Acceleration ${job.detail?.modifyDomains ? 'Modification' : 'Setup'}`,
          roles: [...Object.values(ROLES)],
          SUCCESS: {
            url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
            text: 'Go to App dashboard',
            callback: () => { },
          },
          ACTION_REQUIRED: {
            url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
            text: 'View required actions',
            callback: () => { },
          },
          FAILURE: {
            url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
            text: 'View failure details',
            callback: () => { },
          },
        };
      case fsaDisable:
        if (job.detail?.deleteOnCompletion) {
          // we don't want a notification for a disable + delete job
          return null;
        }
        return {
          id: job?.id,
          text: `${apps[job?.ownerId]?.name} Mercury Dynamic Site Acceleration Disable`,
          roles: [...Object.values(ROLES)],
          SUCCESS: {
            url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
            text: 'Go to App dashboard',
            callback: () => { },
          },
          ACTION_REQUIRED: {
            url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
            text: 'View required actions',
            callback: () => { },
          },
          FAILURE: {
            url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
            text: 'View failure details',
            callback: () => { },
          },
        };
      case gitWebhookDeploy:
        return {
          id: job?.id,
          text: 'App Git webhook deploy',
          roles: [...Object.values(ROLES)],
          SUCCESS: {
            url: `/account/${accountId}/apps/${job?.ownerId}/logs`,
            text: 'View Git deploy log',
            callback: () => dispatch(resetGitWebhookDeployJobs()),
          },
          FAILURE: {
            url: `/account/${accountId}/apps/${job?.ownerId}/integrations`,
            text: 'Go to App Git Integrations',
            callback: () => dispatch(resetGitWebhookDeployJobs()),
          },
        };
      case diskReport:
        return {
          id: job?.id,
          text: 'Disk Full Report creation',
          roles: changeDiskRoles,
          SUCCESS: {
            url: null,
            text: 'View Report',
            callback: () => dispatch(resetDiskReportJobs()),
          },
          FAILURE: {
            url: `/account/${accountId}/disk`,
            text: 'Go to Disk Management',
            callback: () => dispatch(resetDiskReportJobs()),
          },
        };
      case diskUpgradeVps:
        return {
          id: job?.id,
          text: 'Disk Upgrade',
          roles: changeDiskRoles,
          SUCCESS: {
            url: `/account/${accountId}/disk`,
            text: 'View Changes in Disk Management',
            callback: () => dispatch(resetDiskUpgradeJobs()),
          },
          FAILURE: {
            url: `/account/${accountId}/disk`,
            text: 'Go to Disk Management',
            callback: () => dispatch(resetDiskUpgradeJobs()),
          },
        };
      case diskUpgradeHA:
        return {
          id: job?.id,
          text: 'Disk Upgrade',
          roles: changeDiskRoles,
          SUCCESS: {
            url: `/account/${accountId}/disk`,
            text: 'View Changes in Disk Management',
            callback: () => dispatch(resetDiskUpgradeJobs()),
          },
          FAILURE: {
            url: `/account/${accountId}/disk`,
            text: 'Go to Disk Management',
            callback: () => dispatch(resetDiskUpgradeJobs()),
          },
        };
      case webconfig:
        if (getPhpVersionJobMeta(job).length > 0) {
          const [_, version] = getPhpVersionJobMeta(job);
          return {
            id: job?.id,
            text: `${apps[job?.ownerId]?.name} PHP version update to ${version}`,
            roles: [...Object.values(ROLES)],
            SUCCESS: {
              url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
              text: 'Go to App dashboard',
              callback: () => { },
            },
            FAILURE: {
              url: `/account/${accountId}/apps/${job?.ownerId}/overview`,
              text: 'Go to App dashboard',
              callback: () => { },
            },
          };
        }

        return null;
      default:
        return null;
    }
  };

  const memoizedCheckPermissions = useCallback(checkPermissions, [currentRole, accountAccess]);
  const memoizedGetReports = useCallback(getReports, [dispatch]);
  const memoizedGetApps = useCallback(getApps, [dispatch]);
  const memoizedGetNotificationParams = useCallback(getNotificationParams, [job, accountId, apps, dispatch]);

  useEffect(() => {
    if (job) {
      if (!notification || notification.id !== job.id) {
        setNotification(memoizedGetNotificationParams());
      }

      if (notification && hasPermission === null) {
        memoizedCheckPermissions(job, notification);
      }
      // using a switch to make adding new types easier
      if (hasPermission && (status === 'pristine' || (status === 'success' && notification?.id !== job.id))) {
        switch (job.type) {
          case diskReport:
            memoizedGetReports(accountId);
            break;
          case appCreate:
          case fsaSetup:
            memoizedGetApps(accountId);
            break;
          default:
            break;
        }
      }

      if (notification && !notification[job.overallStatus]?.url && url) {
        updateNotificationUrl(notification, job, url);
      }
    }
  }, [job, hasPermission, accountId, memoizedGetReports, memoizedCheckPermissions, notification, status, url, memoizedGetNotificationParams, memoizedGetApps]);

  useEffect(() => {
    if ([appCreate, fsaSetup].includes(job?.type) && status === 'loading' && apps[job?.ownerId]) {
      // wait for apps to load so we can grab the app name
      setStatus('success');
    }
  }, [job, apps, status]);

  if (!hasPermission || status === 'loading' || !notification) {
    return null;
  }

  return notification;
}

export default useNotifications;
