import qs from 'querystring';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as ANALYTICS from '../constants';

export const getDailyStats = (accountId, start, end) => makeApiAction(
  ({ api }) => api().get(`/analytics/accounts/${accountId}/daily?${qs.stringify({ start, end })}`),
  ANALYTICS.ANALYTICS_DAILY_REQUEST,
  ANALYTICS.ANALYTICS_DAILY_SUCCESS,
  ANALYTICS.ANALYTICS_DAILY_FAILURE,
  { accountId, start, end },
  true,
);

export const getAppDailyStats = (accountId, appId, start, end) => makeApiAction(
  ({ api }) => api().get(`/analytics/accounts/${accountId}/apps/${appId}/daily?${qs.stringify({ start, end })}`),
  ANALYTICS.ANALYTICS_APP_DAILY_REQUEST,
  ANALYTICS.ANALYTICS_APP_DAILY_SUCCESS,
  ANALYTICS.ANALYTICS_APP_DAILY_FAILURE,
  {
    accountId, start, end, appId,
  },
);

export const resetStats = () => ({
  type: ANALYTICS.ANALYTICS_DAILY_RESET,
});

export const resetAppStats = () => ({
  type: ANALYTICS.ANALYTICS_APP_DAILY_RESET,
});
