import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import Loading from 'shared/styleguide/atoms/Loading';

const SSHDetails = ({ account, app }) => {
  if (
    account.status === 'loading' || app.status === 'loading'
  ) {
    return (
      <Loading />
    );
  }

  const { servers } = account;

  const appServer = servers.length === 1 ? servers[0] : servers.find((s) => Number(s.id) === app.data.primaryServerId);

  const data = [
    ['Hostname', appServer.hostname],
    ['IP Address', appServer.ip_ext],
  ];

  if (app.data.primaryServerLabel) {
    data.unshift(['Server', app.data.primaryServerLabel]);
  }

  return (
    <div data-testid="serverinfo">
      <Typography variant="h3" gutterBottom>Server Info</Typography>
      <Box
        as={Paper}
        padding={{
          top: 'medium', bottom: 'medium', left: 'small', right: 'small',
        }}
      >
        <TwoColumn
          data={data}
          noMargin
        />
      </Box>
    </div>
  );
};

SSHDetails.propTypes = {
  account: object.isRequired,
  app: object.isRequired,
};

export default SSHDetails;
