import colors from 'shared/styleguide/theme';

export const jssStyles = {
  letsencryptContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  letsencryptValidatedIcon: {
    color: colors.green20,
    height: '50px',
    width: '50px',
  },
  letsencryptGreenText: {
    color: colors.green20,
  },
  letsencryptErrors: {
    color: colors.red10,
  },
  letsencryptSubs: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    padding: '0px 5px',
    width: '100%',
    alignItems: 'center',
  },
  letsencryptActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  letsencryptPrimaryButton: {
    'backgroundColor': colors.green20,
    'color': colors.light40,
    '&:disabled': {
      backgroundColor: colors.sea20,
    },
  },
  letsencryptPrimaryButtonDisabled: {
    backgroundColor: colors.sea20,
    color: colors.light40,
  },
  letsencryptCircularStatus: {
    color: colors.green20,
  },
  letsencryptLink: {
    'fontFamily': 'Montserrat',
    'fontSize': '17px',
    'fontWeight': 'bold',
    'lineHeight': 1.29,
    'color': colors.navy20,
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    'textDecoration': 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  letsencryptCompleteButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    fontSize: '17px',
  },
  letsencryptCompleteButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 60,
  },
  letsencryptSmallerText: {
    fontSize: '13px',
    color: colors.sea20,
    marginTop: 10,
  },

  dialog: {
    overflow: 'visible',
  },
};
