export default {
  csrModalContainer: {
    minHeight: 500,
    maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0px 5px',
    // padding: 30,
  },
  csrModalActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  csrCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};
