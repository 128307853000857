import React, { useState } from 'react';
import { number, object } from 'prop-types';
import { Collapse, Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import NewList from './NewList';
import ListItem from './ListItem';
import { validate } from './utils/validation';

const AccessHeaders = ({
  domains,
  appId,
  headers,
}) => {
  const [wizardOpen, setWizardOpen] = useState(false);

  const existingRulesHash = Object.keys(headers) || [];

  const handleValidate = (values, actions, mode) => {
    // contruct object to send
    const body = { appId };
    body.path = values.path;
    return validate(body, values, actions, mode, existingRulesHash);
  };

  const domainsList = domains.data?.filter((d) => !d.fqdn.match('sites.pressdns.com'))
    .map((domain) => ({
      value: domain.fqdn,
      label: domain.fqdn,
    })) || [];

  const paths = Object.entries(headers);

  return (
    <div>
      <Box direction="row" justify="space-between" align="center" margin={{ bottom: 'small' }}>
        <Typography variant="h3">Response Headers</Typography>
        {
          !wizardOpen && (
            <Button
              aria-label="open-headers-wizard"
              color="secondary"
              variant="contained"
              onClick={() => setWizardOpen(true)}
            >
              New Response Headers Rule
            </Button>
          )
        }
      </Box>
      <Collapse in={wizardOpen} unmountOnExit>
        <NewList
          onClose={() => setWizardOpen(false)}
          appId={appId}
          onValidate={handleValidate}
          domainsList={domainsList}
          listLabel="Headers"
          mode="create"
        />
      </Collapse>

      {/* If its empty, show empty */}
      { paths.length === 0 && (
        <Empty>There are no Response Headers Rules</Empty>
      )}
      {
        paths.length > 0 && paths.map(([key, record], i) => {
          return (
            <ListItem
              key={`${key}-${i}`}
              appId={appId}
              record={record}
              path={key}
              onValidate={handleValidate}
              domainsList={domainsList}
              mode="edit"
            />
          );
        })
      }
    </div>
  );
};

AccessHeaders.propTypes = {
  appId: number.isRequired,
  domains: object.isRequired,
  headers: object.isRequired,
};

export default AccessHeaders;
