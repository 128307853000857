export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_LOGOUT_SUCCESS = 'LOGIN_LOGOUT_SUCCESS';
export const LOGIN_LOGOUT_FAILURE = 'LOGIN_LOGOUT_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';

export const FORGOT_PASSWORD_SET_REQUEST = 'FORGOT_PASSWORD_SET_REQUEST';
export const FORGOT_PASSWORD_SET_SUCCESS = 'FORGOT_PASSWORD_SET_SUCCESS';
export const FORGOT_PASSWORD_SET_FAILURE = 'FORGOT_PASSWORD_SET_FAILURE';

export const VALIDATE_PASSWORD_TOKEN_REQUEST = 'VALIDATE_PASSWORD_TOKEN_REQUEST';
export const VALIDATE_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_PASSWORD_TOKEN_SUCCESS';
export const VALIDATE_PASSWORD_TOKEN_FAILURE = 'VALIDATE_PASSWORD_TOKEN_FAILURE';

export const RESET_MFA_REQUEST = 'RESET_MFA_REQUEST';
export const RESET_MFA_SUCCESS = 'RESET_MFA_SUCCESS';
export const RESET_MFA_FAILURE = 'RESET_MFA_FAILURE';
export const RESET_MFA_RESET = 'RESET_MFA_RESET';

export const RESET_MFA_SET_REQUEST = 'RESET_MFA_SET_REQUEST';
export const RESET_MFA_SET_SUCCESS = 'RESET_MFA_SET_SUCCESS';
export const RESET_MFA_SET_FAILURE = 'RESET_MFA_SET_FAILURE';

export const SSO_MFA_REQUEST_REQUEST = 'SSO_MFA_REQUEST_REQUEST';
export const SSO_MFA_REQUEST_SUCCESS = 'SSO_MFA_REQUEST_SUCCESS';
export const SSO_MFA_REQUEST_FAILURE = 'SSO_MFA_REQUEST_FAILURE';
export const SSO_MFA_VERIFY_REQUEST = 'SSO_MFA_VERIFY_REQUEST';
export const SSO_MFA_VERIFY_SUCCESS = 'SSO_MFA_VERIFY_SUCCESS';
export const SSO_MFA_VERIFY_FAILURE = 'SSO_MFA_VERIFY_FAILURE';
export const SSO_LOGIN_LOOKUP_REQUEST = 'SSO_LOGIN_LOOKUP_REQUEST';
export const SSO_LOGIN_LOOKUP_SUCCESS = 'SSO_LOGIN_LOOKUP_SUCCESS';
export const SSO_LOGIN_LOOKUP_FAILURE = 'SSO_LOGIN_LOOKUP_FAILURE';
