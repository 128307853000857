import React, { Fragment } from 'react';
import {
  array, func, object, string,
} from 'prop-types';
import { css } from '@emotion/react';
import CreatableSelect from 'react-select/creatable';

import { Formik, Form } from 'formik';

import {
  Switch, TextField, Typography, useTheme,
} from '@material-ui/core';

import { ErrorText } from 'shared/styleguide/typography';
import { themeProps } from 'shared/styleguide/atoms/Select/Select';
import Box from 'shared/styleguide/atoms/Box';
import FormLabel from 'shared/styleguide/molecules/RadioInput';
// import { // saving for later
//   dateOptions, formatDate,
// } from 'shared/utils';
import { getErrors, getErrorMessages } from '../../utils';
import { getHeaders, defaultExtensionsOptions } from './constants';

const defaultValues = {
  path: '',
  pathType: null,
};

const AccessHeadersDetails = ({
  initialValues, mode, onSubmit, formRef, domainsList, onSetChanged,
}) => {
  const theme = useTheme();
  const headers = getHeaders(theme);
  return (
    <Formik
      innerRef={formRef}
      initialStatus="pristine"
      initialValues={{
        ...defaultValues,
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validate={() => onSetChanged(true)}
    >
      {({
        values,
        errors,
        handleChange,
        setFieldValue,
      }) => (
        <Box
          as={Form}
          padding={{
            top: undefined, right: 'small', bottom: 'small', left: 'small',
          }}
        >
          <Box direction="row">
            <Box direction="column" flex={1}>
              <Box padding={{ bottom: 'small' }}>
                {
                  mode === 'create'
                  && <Typography gutterBottom variant="caption" color="textSecondary">Step 1</Typography>
                }
                <Typography gutterBottom variant="h5" color="initial">
                  <strong>
                    {`${mode === 'create' ? 'Choose ' : ''}Path and Extensions`}
                  </strong>
                </Typography>
              </Box>
              <div css={{ paddingBottom: 8 }}>
                <Box direction="column">
                  {
                    mode !== 'edit' ? (
                      <Typography variant="caption" color="textSecondary" gutterBottom>Select Path</Typography>
                    ) : (
                      <Typography variant="caption" color="textSecondary" gutterBottom>Path</Typography>
                    )
                  }
                </Box>
                <TextField
                  id="path"
                  name="path"
                  autoComplete="off"
                  role="textbox"
                  onChange={handleChange}
                  value={values.path}
                  error={Boolean(errors.path)}
                  helperText={Boolean(errors.path) && errors.path}
                  disabled={mode === 'edit'}
                  variant="outlined"
                  margin="dense"
                  css={{ marginTop: 0, width: 400 }}
                  placeholder="/example-path"
                />
              </div>

              {
                (mode !== 'edit' || values.extensions?.length > 0) && (
                  <Fragment>
                    {
                      mode !== 'edit' ? (
                        <Typography variant="caption" color="textSecondary" gutterBottom>Optional: Select Extensions</Typography>
                      ) : (
                        <Typography variant="caption" color="textSecondary" gutterBottom>Extensions</Typography>
                      )
                    }
                    <div>
                      <span css={{ display: 'inline-block' }}>
                        <CreatableSelect
                          css={{ minWidth: 300 }}
                          {...themeProps(theme)}
                          placeholder="Select or Type Extensions"
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(newValue) => setFieldValue('extensions', newValue)}
                          value={values.extensions || ''}
                          options={defaultExtensionsOptions}
                          isDisabled={mode === 'edit'}
                        />
                      </span>
                    </div>
                  </Fragment>
                )
              }

            </Box>
          </Box>
          <Box>
            <Box padding={{ top: 'medium', bottom: 'small' }}>
              {
                mode === 'create'
                && <Typography gutterBottom variant="caption" color="textSecondary">Step 2</Typography>
              }
              <Typography variant="h5" color="initial">
                <strong>
                  {`${mode === 'create' ? 'Choose ' : ''}Settings`}
                </strong>
              </Typography>
            </Box>
            <Box direction="column" margin={{ bottom: 'small' }} gap="small">
              {
                headers.map((field) => {
                  const { Options } = field;

                  const currentValue = values[field.value];
                  const currentErrors = errors[field.value] ? getErrorMessages(errors[field.value]).join(', ') : null;

                  return (
                    <Box direction="column" key={field.value} data-testid="header-type">
                      <Box direction="row" justify="space-between">
                        <FormLabel
                          labelPlacement="end"
                          control={(
                            <Switch
                              data-testid="toggle-header-type"
                              checked={Boolean(currentValue?.enabled)}
                              onChange={(e) => setFieldValue(field.value, { ...currentValue, enabled: e.target.checked })}
                            />
                          )}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              css={css`
                                line-height: 30px;
                                padding-top: 5px;
                              `}
                            >
                              {field.label}
                            </Typography>
                          </Box>
                        </FormLabel>
                        {
                          // leaving off for now
                          // currentValue?.enabled && (
                          //   <div css={{ textAlign: 'right' }}>
                          //     <Typography variant="caption" color="textSecondary">Last modified</Typography>
                          //     <Typography variant="body2" color="textSecondary">
                          //       {formatDate(currentValue.date, dateOptions)}
                          //     </Typography>
                          //   </div>
                          // )
                        }
                      </Box>

                      <Box margin={{ left: 3 }}>
                        <Options
                          domainsList={domainsList}
                          value={currentValue?.context || {}}
                          enabled={currentValue?.enabled}
                          onChange={(value) => {
                            setFieldValue(field.value, { ...currentValue, context: { ...currentValue?.context, ...value } });
                          }}
                          data-testid="header-option"
                        />
                        {
                          currentErrors && (
                            <Box margin={{ top: 'small' }}>
                              <ErrorText>{currentErrors}</ErrorText>
                            </Box>
                          )
                        }
                      </Box>
                    </Box>
                  );
                })
              }
            </Box>
          </Box>
          {
            errors && getErrors(errors, ['path'])
          }
        </Box>
      )}
    </Formik>
  );
};

AccessHeadersDetails.propTypes = {
  domainsList: array,
  formRef: object,
  initialValues: object,
  mode: string,
  onSetChanged: func,
  onSubmit: func,
};

export default (AccessHeadersDetails);
