import React, { useState } from 'react';
import {
  func, object, string,
} from 'prop-types';
import { css } from '@emotion/react';
import { DateRangePicker } from 'react-dates';
import Box from 'shared/styleguide/atoms/Box';

import { isInclusiveAfterDay } from 'shared/utils/moment';

import { dateRangePickerOverrides } from 'shared/utils/dateRangePicker';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { getInitialDates } from './analyticsUtils';
import { DISK_EARLIEST_DATE, DEFAULT_DAY_SPREAD } from '../constants';

export const AnalyticsDatePicker = ({
  onDatesChange, start, end, type,
}) => {
  const [focusState, setFocusState] = useState({
    dateFocused: null,
  });

  const resetDates = () => {
    const [startValue, endValue] = getInitialDates(DISK_EARLIEST_DATE, DEFAULT_DAY_SPREAD[type]);

    onDatesChange({
      startDate: startValue,
      endDate: endValue,
    });
  };

  const handleFocusChange = (focused) => {
    setFocusState({ dateFocused: focused });
  };

  const handleClose = (dates) => {
    if (!dates.startDate.isSame(start) || !dates.endDate.isSame(end)) {
      onDatesChange(dates);
    }
  };

  return (
    <Box id="analytics-dates" align="center" direction="row" margin={{ top: 'medium' }}>
      <div css={[dateRangePickerOverrides, css`.DateRangePickerInput__withBorder {
          width: 224px;
        }`]}
      >
        <DateRangePicker
          startDate={start}
          endDate={end}
          startDateId="start"
          endDateId="end"
          onDatesChange={onDatesChange}
          onClose={handleClose}
          focusedInput={focusState.dateFocused}
          onFocusChange={handleFocusChange}
          isOutsideRange={isInclusiveAfterDay}
        />
      </div>
      <Box direction="row" margin={{ left: 'small' }}>
        <Button
          color="default"
          variant="outlined"
          onClick={() => resetDates()}
        >
          Reset Dates
        </Button>
      </Box>
    </Box>
  );
};

AnalyticsDatePicker.propTypes = {
  end: object,
  onDatesChange: func,
  start: object,
  type: string,
};

export default AnalyticsDatePicker;
