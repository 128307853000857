import React from 'react';
import { object } from 'prop-types';
import moment from 'moment';

import {
  Typography, AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Box from 'shared/styleguide/atoms/Box';

import { pathPresetLabelsHash } from '../constants';

const Summary = ({
  values,
  form,
}) => {
  if (form.mode === 'edit') {
    const presetName = pathPresetLabelsHash?.[values.path];

    return (
      <AccordionSummary aria-controls="accessList-content" id={`accessList-${form.initialValues.idKey}-header`} expandIcon={<ExpandMoreIcon />}>
        <Box flex={1} justify="space-between" direction="row" align="center">
          <Box direction="row" align="center" gap="xsmall">
            <Typography component="span" variant="body1" color="textSecondary">{`path [${values.match}]:`}</Typography>
            <Typography component="span" variant="body1">{ presetName?.name || values.path } </Typography>
            {
              presetName?.exclusions
              && (
                <Typography component="span" variant="caption" color="textSecondary">(with exclusions)</Typography>
              )
            }
          </Box>
          <Box direction="row" align="center">
            <Typography variant="caption" color="textSecondary">
              {moment(form.initialValues.lastChanged).format('LLL')}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
    );
  }

  return <Box padding="small" />;
};

Summary.propTypes = {
  form: object,
  values: object,
};

export default Summary;
