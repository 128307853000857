import {
  useEffect, useRef, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deepClone } from 'shared/utils';
import { consolidateErrors } from 'shared/utils/validation';
import { useMergeState } from 'shared/hooks/useMergeState';

import { updateRedirect, deleteRedirect, clearAddedInUI } from '../../../redux/redirects/actions';
import { initialFormValues, resetState, initialFormState } from '../constants';
import {
  constructFromUrl, constructToUrl, createMatches, createRuleRewrites, parseValues,
} from '../utils';
import {
  validateValues, isParsable,
} from '../utils/validation';

const parse = require('url-parse');

function useForm(domainsList, appId, redirect, onCreateRedirect, mode, onSetShowCreate, initialValues, initialQueryMode) {
  const formRef = useRef();
  const { state, mergeState } = useMergeState(initialFormState);

  const {
    newlyAdded, saveAction, advancedEdit, isParsed, queryMode,
    simpleValues, status, showUpdated,
  } = state;

  const dispatch = useDispatch();
  const redirectsStatus = useSelector((state) => state.ares?.redirects?.status) || null;

  const submitForm = () => {
    if (formRef.current && (mode === 'edit' || isParsed || advancedEdit)) {
      formRef.current.handleSubmit();
    }

    mergeState({ saveAction: false });
  };

  const memoizedSubmitForm = useCallback(submitForm, [formRef, isParsed, mode]);

  const initialValuesString = JSON.stringify(initialValues);

  useEffect(() => {
    // updates when redirects are loaded or refreshed, eg after CSV import or delete

    mergeState({
      matchIdentifier: redirect?.matchIdentifier,
      mode,
      formRef,
      queryMode: initialQueryMode || 'update',
      newlyAdded: redirect?.addedInUI || false,
    });

    if (state.formRef?.current) {
      // set inital values
      state.formRef.current.setValues(deepClone(initialValues));
    }
  }, [initialValuesString, redirect?.addedInUI]);

  // only save if simple values are parsed
  useEffect(() => {
    const isSavable = (!advancedEdit && isParsed) || advancedEdit;

    if (saveAction && isSavable) {
      memoizedSubmitForm();
    }
  }, [saveAction, advancedEdit, isParsed, memoizedSubmitForm]);

  useEffect(() => {
    if ((status === 'success' || (newlyAdded && status === 'pristine')) && !showUpdated) {
      // show success icons on create, created & deleted redirects
      mergeState({ showUpdated: true });

      setTimeout(() => {
        mergeState({
          newlyAdded: false,
          showUpdated: false,
          status: 'pristine',
        });

        if (redirect?.addedInUI) {
          // remove addedInUI property from store
          dispatch(clearAddedInUI());
        }

        if (mode === 'create') {
          onSetShowCreate(true);
        }
      }, 3000);
    }
  }, [status, newlyAdded]);

  const validate = (values) => {
    const body = { ...values };

    let hasErrors = false;

    mergeState({
      errors: null,
    });

    const errorMessages = validateValues(body, domainsList);

    if (errorMessages.length > 0) {
      hasErrors = true;

      mergeState({
        errors: { general: errorMessages.join(' ') },
      });
    }

    return [body, hasErrors];
  };

  const handleSetQueryMode = (value) => {
    mergeState({
      queryMode: value,
      changed: true,
      advancedEdit: true,
    });
  };

  const changeDescription = (value) => {
    formRef.current.setValues({
      ...formRef.current.values,
      description: value,
    });

    mergeState({
      changed: true,
      advancedEdit: true,
    });
  };

  const changeSimpleFields = (type, value) => {
    mergeState({
      simpleValues: { ...simpleValues, [type]: value },
    });

    const from = parse(simpleValues.from);
    const to = parse(simpleValues.to);

    mergeState({
      queryMode: (to.query || from.query) ? 'update' : queryMode,
      expanded: false,
      advancedEdit: false,
      changed: true,
    });
  };

  const create = async (values) => {
    const body = {
      enabled: values.enabled,
      statusCode: values.statusCode,
      description: values.description,
      ruleMatch: {
        match: createMatches(values.from),
      },
      ruleRewrite: createRuleRewrites(values.from, values.to, queryMode),
    };

    mergeState({ status: 'loading' });

    try {
      await onCreateRedirect(appId, body);

      mergeState({
        status: 'success',
        ...resetState,
      });
    } catch (error) {
      mergeState({
        errors: {
          'general': consolidateErrors(error),
          ...error.response.data.body,
        },
      });
    }
  };

  const update = async (values) => {
    const body = {
      enabled: values.enabled,
      statusCode: values.statusCode,
      description: values.description,
      ruleMatch: {
        match: createMatches(values.from),
      },
      ruleRewrite: createRuleRewrites(values.from, values.to, queryMode),
    };

    mergeState({ status: 'loading' });

    try {
      const r = await dispatch(updateRedirect(appId, state.matchIdentifier, body));

      mergeState({
        status: 'success',
        initialValues: values,
        matchIdentifier: r.matchIdentifier,
        ...resetState,
      });
    } catch (error) {
      mergeState({
        errors: {
          'general': consolidateErrors(error),
          ...error.response.data.body,
        },
      });
    }
  };

  const deleteSingleRedirect = async () => {
    mergeState({ status: 'loading' });

    try {
      await dispatch(deleteRedirect(appId, state.matchIdentifier));

      mergeState({
        status: 'success',
        deleted: true,
        ...resetState,
      });
    } catch (error) {
      mergeState({
        errors: {
          'general': consolidateErrors(error),
          ...error.response.data.body,
        },
      });
    }
  };

  const submit = (values, actions) => {
    const [body, shouldReturn] = validate(values);

    if (shouldReturn) {
      return;
    }

    if (mode === 'create') {
      create(body, actions);
    }

    if (mode === 'edit') {
      update(body, actions);
    }
  };

  const cancel = () => {
    mergeState({ ...resetState });

    if (mode === 'create') {
      onSetShowCreate(false);
    }
  };

  const closeAdvanced = () => {
    mergeState({ expanded: false });
  };

  const changeUrlParams = (type, property, param, value) => {
    const { values } = formRef.current;

    const updatedPropertyValues = value ? {
      ...values[type][property],
      [param]: value,
    } : {};

    const updatedValues = {
      ...values,
      [type]: {
        ...values[type],
        [property]: {
          ...updatedPropertyValues,
        },
      },
    };

    formRef.current.setValues(updatedValues);

    mergeState({
      simpleValues: {
        from: constructFromUrl(updatedValues.from),
        to: constructToUrl(updatedValues.from, updatedValues.to),
      },
      advancedEdit: true,
      changed: true,
    });
  };

  const addQuery = (type) => {
    const currentQueryCount = formRef.current.values[type].query?.length || 0;
    const newValues = { ...formRef.current.values };
    const query = {
      key: null,
      value: null,
    };

    if (type === 'from') {
      query.compare = null;
    }

    if (!newValues[type].query) {
      newValues[type].query = [];
    }

    newValues[type].query[currentQueryCount] = query;
    formRef.current.setValues(newValues);
  };

  const changeQuery = (type, index, param, value) => {
    const newValues = { ...formRef.current.values };
    const query = {
      ...newValues[type].query[index],
      [param]: value,
    };

    newValues[type].query[index] = query;

    formRef.current.setValues({ ...newValues });

    mergeState({ changed: true });
  };

  const changeStatus = (value) => {
    const newValues = { ...formRef.current.values };
    if (value === 'disabled') {
      newValues.enabled = false;
    } else {
      newValues.statusCode = Number(value);
      newValues.enabled = true;
    }

    formRef.current.setValues(newValues);

    mergeState({
      advancedEdit: true,
      changed: true,
    });
  };

  const deleteQuery = (type, index) => {
    const updatedValues = { ...formRef.current.values };
    updatedValues[type].query.splice(index, 1);
    formRef.current.setValues(updatedValues);

    mergeState({ changed: true });
  };

  const parseSimpleValues = () => {
    if (!simpleValues.from && !simpleValues.to) {
      return;
    }

    if (!isParsable(simpleValues.from)) {
      mergeState({
        errors: { general: ['Invalid "from" value.'] },
      });

      return;
    }

    if (!isParsable(simpleValues.to)) {
      mergeState({
        errors: { general: ['Invalid "to" value.'] },
      });

      return;
    }

    mergeState({ errors: null });

    const newValues = parseValues(simpleValues, deepClone(initialFormValues));

    formRef.current.setValues(newValues);

    mergeState({ isParsed: true });
  };

  const showAdvanced = () => {
    parseSimpleValues();

    mergeState({ expanded: true });
  };

  const save = () => {
    mergeState({ errors: null });

    if (!advancedEdit) {
      if (!simpleValues.from && !simpleValues.to) {
        mergeState({
          errors: { general: ['No fields set.'] },
        });

        return;
      }

      mergeState({ isParsed: false });

      parseSimpleValues();
    }

    mergeState({ saveAction: true });
  };

  const methods = {
    save,
    deleteSingleRedirect,
    submitForm,
    submit,
    cancel,
    closeAdvanced,
    showAdvanced,
    changeStatus,
    changeUrlParams,
    changeDescription,
    addQuery,
    changeQuery,
    handleSetQueryMode,
    changeSimpleFields,
    deleteQuery,
  };

  return {
    ...state, redirectsStatus, ...methods,
  };
}

export default useForm;
