import React, { useState } from 'react';
import { func, number } from 'prop-types';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { consolidateErrors } from 'shared/utils/validation';

const fileDownload = require('js-file-download');

const Export = ({ exportRedirects, appId, onSetErrors }) => {
  const [status, setStatus] = useState('pristine');

  const handleExport = async () => {
    setStatus('loading');

    try {
      const data = await exportRedirects(appId);

      fileDownload(data, `redirects_${appId}.csv`);

      onSetErrors(null);
      setStatus('success');
      setTimeout(() => {
        setStatus('pristine');
      }, 3000);
    } catch (err) {
      setStatus('pristine');
      onSetErrors(`Error exporting redirects. ${consolidateErrors(err)}`);
    }
  };

  return (
    <div>
      <Button
        onClick={handleExport}
        status={status}
        label="Export To CSV"
        color="secondary"
      />
    </div>
  );
};

Export.propTypes = {
  appId: number,
  exportRedirects: func,
  onSetErrors: func,
};

export default Export;
