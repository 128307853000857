import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Paper, Typography } from '@material-ui/core';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';
import { consolidateErrors } from 'shared/utils/validation';
import { ErrorText } from 'shared/styleguide/typography';

import { ACCOUNT_DISABLED_TAG } from 'shared/modules/metadata/constants';
import { enableAccountAsAdmin, disableAccountAsAdmin, fetchAccountTags } from 'shared/modules/account/redux/actions';

const DisableAccount = ({
  account, disableAccount, enableAccount, user, tags, getAccountTags,
}) => {
  const [status, setStatus] = useState('initial');
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const currentTags = tags.data.map((t) => t.tagId);
  const isDisabled = currentTags.includes(ACCOUNT_DISABLED_TAG);

  const handleClick = () => {
    setStatus('loading');
    const args = [account.id, { name: user.name, reason: reason || 'None provided' }];
    const enableDisableAccount = () => (isDisabled ? enableAccount(...args) : disableAccount(...args));
    enableDisableAccount()
      .then(() => {
        getAccountTags(account.id).then(() => {
          setTimeout(() => { setStatus('initial'); }, 2000);
          setStatus('success');
        });
        // TODO: unhandled rejection
      })
      .catch((err) => {
        setStatus('failed');
        setError(consolidateErrors(err));
      });
  };

  return (
    <Paper>
      <Box padding="medium" direction="column">
        <Box>
          <Typography variant="h4" gutterBottom>{isDisabled ? 'Enable' : 'Disable'} Account</Typography>
        </Box>
        <Box padding={{ top: 'small' }}>
          <Typography variant="body1" color="textPrimary" paragraph>
            Disabling an account will make all sidebar items except <strong>Billing</strong> and <strong>Dashboard</strong> inaccessible.
            Account must be disabled and enabled manually - This will add a tag to the account.
          </Typography>
          <Typography variant="body1" color="textPrimary" paragraph>
            Accounts with an expired subscription will be disabled automatically.
          </Typography>
        </Box>
        <Box>
          <Box direction="row" align="center" gap="xsmall">
            <Box>
              <Typography variant="body2" color="textSecondary">
                Account is currently:
              </Typography>
            </Box>
            <Box><GhostTag color={isDisabled ? 'red' : 'green'}>{isDisabled ? 'Disabled' : 'Enabled'}</GhostTag></Box>
          </Box>
          <Box>
            <TextField
              placeholder="Reason"
              margin="normal"
              onChange={(e) => setReason(e.target.value)}
            />
            {
              error && (
                <ErrorText>{error}</ErrorText>
              )
            }
            <Box direction="row" justify="flex-end" margin={{ top: 'small' }}>
              {
                isDisabled ? (
                  <Button status={status} onClick={handleClick}>Enable</Button>
                ) : (
                  <Button status={status} onClick={handleClick}>Disable</Button>
                )
              }
            </Box>

          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

DisableAccount.propTypes = {
  account: object.isRequired,
  disableAccount: func.isRequired,
  enableAccount: func.isRequired,
  getAccountTags: func.isRequired,
  tags: object,
  user: object,
};

export default connect(
  (state) => ({
    account: state.account,
    tags: state.tags.account,
    user: state.user.user,
  }),
  {
    disableAccount: disableAccountAsAdmin,
    enableAccount: enableAccountAsAdmin,
    getAccountTags: fetchAccountTags,
  },
)(DisableAccount);
