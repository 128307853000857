import React, { Fragment } from 'react';
import { object } from 'prop-types';

import {
  Typography, TextField, Switch,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import { getErrorMessages } from '../../../utils';

const Auth = ({
  values,
  form,
}) => {
  const passwordPlaceholder = (form.mode === 'edit' && values.initialAuth && values.authUsername && !values.hasChangedPassword)
    ? Array(20).fill('•').join('') : '';

  return (
    <Fragment>
      <Box direction="row" align="center" padding={{ top: 'xsmall', bottom: 'xsmall' }}>
        <Typography variant="h5" color="initial"><strong>Auth</strong></Typography>
        <Box margin={{ bottom: 'small' }} />
        <Switch
          checked={Boolean(values.auth)}
          onChange={(e) => form.handleChange('auth', e.target.checked)}
        />
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <Typography gutterBottom variant="caption" color="textSecondary">
          If you are using a CDN for assets, Auth may have unpredictable effects. We recommend disabling CDN if you want to use Auth to control access.
        </Typography>
      </Box>
      <Box direction="column" padding={{ bottom: 'small' }}>
        <Typography variant="caption" color="textSecondary">
          Set User Name and Password
        </Typography>
      </Box>
      <Box>
        <Box margin={{ bottom: 'small' }}>
          <Box flex={0} margin={{ bottom: 'xsmall' }}>
            <TextField
              id="authUsername"
              name="authUsername"
              autoComplete="off"
              placeholder="User Name"
              error={Boolean(form.errors?.authUsername)}
              helperText={form.errors?.authUsername ? getErrorMessages(form.errors?.authUsername).join('; ') : null}
              onChange={(e) => form.handleChangeUsername(e.target.value)}
              value={values.authUsername}
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              margin="dense"
              variant="outlined"
              disabled={!values.auth}
            />
          </Box>
          <div>
            <TextField
              id="authPassword"
              name="authPassword"
              error={Boolean(form.errors?.authPassword)}
              helperText={form.errors?.authPassword ? getErrorMessages(form.errors?.authPassword).join('; ') : null}
              value={values.authPassword}
              autoComplete="off"
              onChange={(e) => form.handleChangePassword(e.target.value)}
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              margin="dense"
              variant="outlined"
              placeholder={passwordPlaceholder}
              disabled={!values.auth}
            />
            <Box>
              <Typography variant="caption" color="textSecondary">
                {
                  form.mode === 'create'
                    ? 'Please take note of your password - it will not be shown after being saved, but may be updated at any time.'
                    : 'Type to update password. Please take note of your password - it will not be shown after being saved, but may be updated again at any time.'
                }
              </Typography>
            </Box>
          </div>
        </Box>
      </Box>
    </Fragment>
  );
};

Auth.propTypes = {
  form: object,
  values: object,
};

export default Auth;
