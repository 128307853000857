import {
  loadMethod,
  successMethod,
  failMethod,
  baseCollectionState,
} from 'shared/utils/redux/apiReducer';

import * as RELEASE_NOTES from './constants';

export const releaseNotes = (state = baseCollectionState, action) => {
  switch (action.type) {
    case RELEASE_NOTES.RELEASE_NOTES_GET_REQUEST:
    case RELEASE_NOTES.RELEASE_NOTES_CREATE_REQUEST:
    case RELEASE_NOTES.RELEASE_NOTES_UPDATE_REQUEST:
    case RELEASE_NOTES.RELEASE_NOTES_DELETE_REQUEST:
      return {
        ...loadMethod(state, action),
      };
    case RELEASE_NOTES.RELEASE_NOTES_GET_SUCCESS:
      return {
        ...successMethod(state, action),
      };
    case RELEASE_NOTES.RELEASE_NOTES_DELETE_SUCCESS: {
      const filter = state.data.filter((note) => note.releaseDate !== `${action.releaseDate}T00:00:00+00:00`);
      return {
        ...successMethod(state, action),
        data: filter,
      };
    }
    case RELEASE_NOTES.RELEASE_NOTES_CREATE_SUCCESS:
      return {
        ...successMethod(state, action),
        data: [...state.data, action.data],
        mode: 'view',
      };
    case RELEASE_NOTES.RELEASE_NOTES_UPDATE_SUCCESS: {
      const updatedNoteIndex = state.data.findIndex((note) => note.releaseDate === action.releaseNoteDate);
      const newData = [...state.data];
      newData[updatedNoteIndex] = action.data;
      return {
        ...successMethod(state, action),
        data: newData,
        mode: 'view',
      };
    }
    case RELEASE_NOTES.RELEASE_NOTES_GET_FAILURE:
    case RELEASE_NOTES.RELEASE_NOTES_CREATE_FAILURE:
    case RELEASE_NOTES.RELEASE_NOTES_UPDATE_FAILURE:
    case RELEASE_NOTES.RELEASE_NOTES_DELETE_FAILURE:
      return {
        ...failMethod(state, action),
        errMessage: action.errMessage,
        statusCode: action.statusCode,
      };
    case RELEASE_NOTES.RELEASE_NOTES_SET_MODE:
      return {
        ...state,
        mode: action.value.mode,
        releaseNoteDate: action.value.releaseNoteDate,
      };
    default:
      return state;
  }
};
