import React, { useContext } from 'react';
import { node, number, string } from 'prop-types';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import AccountContext from 'shared/modules/account/context/AccountContext';

const AppLink = ({
  children, appId, subsection = 'overview', ...props
}) => {
  const accountId = useContext(AccountContext).id;

  let to = `/account/${accountId}/apps`;

  if (appId) {
    to += `/${appId}/${subsection}`;
  }

  return (
    <TextLink to={to} {...props}>
      {children}
    </TextLink>
  );
};

AppLink.propTypes = {
  appId: number,
  children: node,
  subsection: string,
};

AppLink.displayName = 'AppLink';

export default AppLink;
