import { fromUnixTime, format } from 'date-fns';
import moment from 'moment';
import qs from 'qs';

import * as STATUS from 'shared/modules/status/redux/constants';

export const generateLogs = (logList: any) => { // eslint-disable-line
  return logList.map((log) => {
    const { message } = log;
    let line = message.message;

    if (message.messageType === 'providerPayload') {
      line = JSON.stringify(line, null, 2);
    }

    if (message.messageType === 'finalPayload') {
      line = JSON.stringify(line, null, 2);
    }

    if (message.messageType === 'hookOutput') {
      line = `\n${JSON.parse(message.message).join('\n')}`;
    }

    return (
      `[${message.date} ${message.type}]\t${line}`
    );
  });
};

export const formatCloneLogs = (logList: { message: { message: string; timestamp: number; level: string } }[]): string => {
  return logList.map((log) => {
    const line = log.message.message;
    const { timestamp, level } = log.message;
    const date = format(fromUnixTime(timestamp), 'yyyy-mm-dd h:m:s');

    return (
      `[${date} ${level}]\t${line}`
    );
  }).join('\n');
};

export const createMeta = (state: any, job: any) => { // eslint-disable-line
  const logItem = state.data || {};
  const currentStatus = job?.overallStatus || logItem.status || null;
  const warnings = job?.detail?.warnings || logItem.detail?.warnings || null;

  return {
    integrationId: job.detail?.config?.id,
    started: job.started,
    link: qs.stringify({ logSection: 'deployment', logId: job.id }, { addQueryPrefix: true }),
    totalTime: logItem.ended ? moment(logItem.ended).diff(moment(logItem.started)) : null,
    warnings,
    currentStatus,
    displayStatus: currentStatus === STATUS.SUCCESS && warnings?.length ? STATUS.SUCCESS_WITH_WARNINGS : currentStatus,
  };
};

export const middleTruncate = (longString: string) => { // eslint-disable-line
  return [longString.substring(0, 40), ' . . . ', longString.substring(longString.length - 24)].join('');
};
