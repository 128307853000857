import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Typography, useMediaQuery } from '@material-ui/core';
import PagelyLogo from 'shared/styleguide/assets/Pagely_Logo_Light.svg';
import Box from 'shared/styleguide/atoms/Box';
import SSOLogin from 'shared/modules/authentication/routes/SSOLogin';
import SSOMFA from 'shared/modules/authentication/routes/SSOMFA';
import Wave from 'shared/styleguide/molecules/Wave';
import Card from 'shared/styleguide/atoms/Card/Paper';
import Banner from 'shared/styleguide/molecules/Banner';
import { getDeployEnvironment } from 'shared/config';
import { FeatureToggle } from 'shared/flags';

interface Props {
  isAdmin?: boolean;
}

const mainContainer = (isAdmin: boolean) => (theme: Record<string, any>) => {
  let background = theme.color800;

  if (isAdmin) {
    if (getDeployEnvironment() === 'woosaas') {
      background = `linear-gradient(122deg, ${theme.palette.primary.dark} 0%, ${theme.color800} 68%, ${theme.color.purple} 100%)`;
    } else {
      background = `linear-gradient(122deg, ${theme.color800} 0%, ${theme.color800} 62%, ${theme.palette.primary.dark} 100%)`;
    }
  }
  return ({
    height: '100vh',
    width: '100vw',
    background,
  });
};

const horizBoxStyles = (mobile) => ({
  width: mobile ? '100%' : '70%',
  maxWidth: mobile ? '' : 1200,
  minWidth: mobile ? '' : 900,
  height: '100vh',
  zIndex: 2,
});

const WarningBox = () => (
  <Box css={{ position: 'absolute', top: 0, width: '100%' }}>
    <Banner
      heading="Atomic is not optimized for mobile use."
      subheading="You will have difficulty after logging in."
      type="info"
      dismissable
      showIcon={false}
      underline
    />
  </Box>
);

const LoginRouter: React.FC<Props> = (props) => {
  const narrow = useMediaQuery('(max-width: 900px)');
  const mobile = useMediaQuery('(max-width: 500px)');

  return (
    <Box css={mainContainer(props.isAdmin ?? false)}>
      <Wave />
      <Box align="center">
        <Box align="center" css={horizBoxStyles(narrow)} direction={narrow ? 'column' : 'row'} justify={narrow ? 'center' : 'space-between'}>
          {narrow && <WarningBox />}
          <Box align="center" justify="center" padding="medium">
            <FeatureToggle
              stages="woosaas"
              fallback={(
                <img src={PagelyLogo} alt="Pagely Logo" css={{ width: 300, height: 'auto' }} />
                  )}
            >
              <Typography variant="h1">MWCS Admin Panel</Typography>
            </FeatureToggle>
          </Box>

          <Box align="center" justify="center" padding="medium">
            <Box css={{ width: mobile ? 268 : '' }} align="center" justify="space-between" as={Card} padding="medium">
              <Switch>
                <Route path="/sso-login" component={SSOLogin} />
                <Route path="/sso-mfa" component={SSOMFA} />
                <Route path="/sso-select" render={() => props.children} />
                <Route path="/login" render={() => props.children} />
                {/*
                there are a few "secret" routes...
                /password-reset/:token*
                /mfa-reset/:token*
              */}
                <Route path="/" render={() => props.children} />
              </Switch>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginRouter;
