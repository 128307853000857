import React, { Fragment } from 'react';
import { func, object, string } from 'prop-types';
import {
  Typography,
} from '@material-ui/core';

import Select from 'shared/styleguide/atoms/Select/Select';

export const createMountKeys = (mount) => {
  const keys = (mount.serverKeys.data || mount.serverKeys).find((kv) => kv.keyId === 'system:cluster-role');
  if (keys) {
    return `Role: ${keys.value}`;
  }
  return 'Role: VPS';
};

const MountOptions = ({ mounts, setMountPoint, value }) => {
  if (mounts.data.length > 1) {
    const options = mounts.data.map(((mount) => ({
      value: mount.server.mounts[0],
      label: mount.server.petname,
      groupLabel: (
        <Fragment>
          <Typography component="strong" variant="body1">{mount.server.petname}</Typography>&nbsp;
          <Typography variant="body2" color="textSecondary" component="span">{createMountKeys(mount)}</Typography>
        </Fragment>
      ),
    })));
    options.unshift({ value: null, label: 'Default', groupLabel: 'Default' });

    return (
      <div css={{ minWidth: 200 }}>
        <Typography variant="caption" component="label" htmlFor="mountpoint">
          Mount Point/Server
        </Typography>
        <Select
          id="mountpoint"
          defaultValue={{ value: null, label: 'Default', groupLabel: 'Default' }}
          value={options.find((i) => i.value === value)}
          onChange={(value) => setMountPoint(value.value)}
          options={options}
          getOptionLabel={(option) => option.groupLabel}
        />
      </div>
    );
  }
  return null;
};

MountOptions.propTypes = {
  mounts: object.isRequired,
  setMountPoint: func.isRequired,
  value: string,
};

export default React.memo(MountOptions);
