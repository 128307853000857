import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  Typography, useTheme, Grid, Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CodeText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextFileSelect from 'shared/styleguide/molecules/ValidatingTextFileSelect/ValidatingTextFileSelect';

import { consolidateErrors } from 'shared/utils/validation';
import { KEYREGEX } from 'shared/utils/regexConstants';

import {
  addSSHPublicKey,
  resetSSHPublicKey,
} from 'shared/modules/account/redux/actions';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    maxWidth: 'none',
  },
}));

export const AddPublicKey = ({
  account, addSSHPublicKey, accountSSHKey, resetSSHPublicKey, onClose,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [key, setKey] = useState();
  const [errors, setErrors] = useState('');

  const handleAddSSHKey = () => {
    const validated = KEYREGEX.test(key);
    if (validated) {
      setErrors('');
      addSSHPublicKey(account.id, key)
        .then(() => {
          setKey('');
          resetSSHPublicKey();
          onClose();
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setErrors(consolidateErrors(err));
          }
        });
    }
  };

  return (

    <Box
      margin={{ top: 'small', bottom: 'medium' }}
      padding="medium"
      style={{
        backgroundColor: theme.palette.action.hover,
        // This is bad practice, don't do this
        marginLeft: '-1.5rem',
        marginRight: '-1.5rem',
      }}
    >
      <Grid container spacing={4}>
        <Grid item md={8} sm={12}>
          <Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>Step 2</Typography>
            <Box direction="row" align="center" gap="xsmall">
              <Box>
                <Typography variant="body1" color="textPrimary" component="span">
                  Add a Public Key
                </Typography>
              </Box>
              <Box>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title={(
                    <Box>
                      <Typography css={{ color: 'var(--color-background)' }}>
                        Example SSH Key:
                      </Typography>
                      <CodeText>
                        ssh-rsa AAAAB3NzaC1yc2EAAAADAQb4leEMTBlQG5r6LQo+6YC==.... example@pagely.com
                      </CodeText>
                    </Box>
                  )}
                  position="right-center"
                >
                  <InfoIcon color="primary" fontSize="small" />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box>
            <TextFileSelect
              doing={false}
              done={false}
              onChange={(key) => setKey(key)}
              validator={(text) => {
                const validated = KEYREGEX.test(text);
                return ({
                  validated,
                  validationMessage: validated ? '' : 'Not a valid SSH public key, see the documentation below or check for extra spaces.',
                });
              }}
              serverValidationMessage={errors}
              fileRequirements=""
              action={(
                <Button
                  onClick={handleAddSSHKey}
                  disabled={!key || accountSSHKey.doing}
                  variant="contained"
                >
                  Add Public Key
                </Button>
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

AddPublicKey.propTypes = {
  account: object.isRequired,
  accountSSHKey: object.isRequired,
  addSSHPublicKey: func.isRequired,
  onClose: func.isRequired,
  resetSSHPublicKey: func.isRequired,
};

export default connect(
  (state) => ({
    account: state.account,
    accountSSHKey: state.accountSSHKey,
  }),
  {
    addSSHPublicKey,
    resetSSHPublicKey,
  },
)(AddPublicKey);
