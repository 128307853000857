import React, { Fragment, useEffect, useState } from 'react';
import {
  string, func, bool,
} from 'prop-types';

import { CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import NewButton from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';

const circularProgressStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
};

const checkIconStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -14,
  marginLeft: -12,
};

const UploadAttachmentButton = ({
  uploadStatus, errors, onChange,
}) => {
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  useEffect(() => {
    setShowCheckIcon(false);
    if (!errors && uploadStatus === 'success') {
      setShowCheckIcon(true);
      const successTimerId = setTimeout(
        () => setShowCheckIcon(false),
        3000,
      );
      return () => clearTimeout(successTimerId);
    }
  }, [errors, uploadStatus]);

  return (
    <Fragment>
      <Box margin={{ top: 'small' }}>
        <input
          style={{ display: 'none' }}
          accept="*"
          id="upload"
          multiple
          type="file"
          onChange={onChange}
          disabled={(uploadStatus === 'doing' || showCheckIcon)}
        />
        <label htmlFor="upload">
          <NewButton
            key="1"
            color="default"
            variant="outlined"
            component="span"
            disabled={uploadStatus === 'doing'}
          >
            Add Attachments
            {uploadStatus === 'doing'
              && <CircularProgress color="secondary" size={24} style={circularProgressStyle} />}
            {showCheckIcon
              && <CheckIcon color="secondary" size={24} style={checkIconStyle} />}
          </NewButton>
        </label>
      </Box>
    </Fragment>
  );
};

UploadAttachmentButton.propTypes = {
  errors: bool,
  onChange: func,
  storeDoing: bool,
  storeStatus: string,
  uploadStatus: string,
};

export default UploadAttachmentButton;
