import React, { Fragment, useState } from 'react';
import { array, bool } from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import { Divider, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import DestructiveAction from 'shared/styleguide/molecules/DestructiveAction';
import { pointToCdn, revertDns } from 'shared/modules/dns/redux/actions';

import Articles from './Articles';
import Validation from './Validation';

const DNSList = ({ validationRecords: records, domains, isFsaEnabled }) => {
  const [validationOpen, setValidationOpen] = useState(false);
  const [requestValidations, setRequestValidations] = useState([]);
  const dispatch = useDispatch();

  const handleRequestValidation = (ids) => (e) => {
    const map = {};
    ids.forEach((id) => { map[id] = true; });
    setRequestValidations(map);
    setValidationOpen(true);
  };

  const handleRevertDns = (domainId) => () => {
    dispatch(revertDns(domainId));
  };

  const handlePointDnsToCdn = (domainId) => () => {
    dispatch(pointToCdn(domainId));
  };

  // merge domain fqdn into validation data
  const validationRecords = records.map((record) => {
    const domain = domains.find((domain) => domain.id === record.domainId);
    return {
      fqdn: domain.fqdn,
      isPressDns: domain.isPressDns,
      ...record,
    };
  });
  return (
    <>
      <Box margin={{ bottom: 'small' }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Domain</TableCell>
                <TableCell align="center">Hosting Status</TableCell>
                <TableCell align="center">PressDNS Enabled</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                !validationRecords.length ? (
                  <TableRow>
                    <TableCell css={css`width: 100%;`} colSpan={4}><Empty>There are no DNS validation records available for this app yet.</Empty></TableCell>
                  </TableRow>
                ) : (
                  validationRecords.map((record) => (
                    <TableRow key={record.domainId}>
                      <TableCell>{record.fqdn}</TableCell>
                      <TableCell align="center">{record.validated ? <GhostTag color="green">valid</GhostTag> : <GhostTag color="red">invalid</GhostTag>}</TableCell>
                      <TableCell align="center">{record.isPressDns ? <GhostTag color="green">yes</GhostTag> : <GhostTag color="orange">no</GhostTag>}</TableCell>
                      <TableCell align="right">
                        <Box row gap="xxsmall" justify="flex-end">
                          {
                            isFsaEnabled && (
                              <>
                                {/* TODO: show if DNS validation pressDnsSet is true and DNS record has a previousDns value */}
                                {
                                  record.pressDnsIsSet && (
                                    <DestructiveAction
                                      color="secondary"
                                      variant="outlined"
                                      onConfirm={handleRevertDns(record.domainId)}
                                      explainerText="Traffic to your domain will go away from Pagely"
                                      secondaryText="Your previous DNS host was: 'abcde"
                                    >
                                      Revert DNS
                                    </DestructiveAction>
                                  )
                                }
                                {/* TODO: show if DNS validation pressDnsSet is false */}
                                {
                                  record.pressDnsIsSet === false && (
                                    <DestructiveAction
                                      color="secondary"
                                      variant="outlined"
                                      onConfirm={handlePointDnsToCdn(record.domainId)}
                                      explainerText="Traffic to your domain will go through Pagely"
                                    >
                                      Point DNS to CDN
                                    </DestructiveAction>
                                  )
                                }
                              </>
                            )
                          }
                          <Button
                            variant="outlined"
                            onClick={handleRequestValidation([record.domainId])}
                          >
                            Validate
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Box direction="row" justify="space-between">
          <Box padding={{ top: 'small', bottom: 'small' }}>
            <Typography
              variant="caption"
              color="textSecondary"
            >
              A <strong>Valid Hosting Status</strong> means that all of our validation rules are passing.
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
            >
              You can view validation rules for a domain once you click &quot;Validate&quot;.
            </Typography>
          </Box>
          {
            Boolean(validationRecords.length) && (
              <Box direction="row" padding="small" justify="flex-end">
                <Box justify="flex-end">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleRequestValidation(validationRecords.map((r) => r.domainId))}
                  >
                    Validate All Domains
                  </Button>
                </Box>
              </Box>
            )
          }
        </Box>
      </Box>
      <Validation domains={validationRecords} isOpen={validationOpen} setOpen={setValidationOpen} defaultValidations={requestValidations} />
      <Articles />
    </>
  );
};

DNSList.propTypes = {
  domains: array,
  isFsaEnabled: bool,
  validationRecords: array.isRequired,
};

export default DNSList;
