export interface Errors {
  username?: string;
  password?: string;
  mfa?: string;
}

export const validateForm = (username: string, password: string, mfa: string): Errors => {
  const errors: Errors = {};

  if (!username) errors.username = 'Username is required';
  if (!password) errors.password = 'Password is required';
  if ((mfa.length < 6 || mfa.length > 8) && mfa.length > 0) errors.mfa = 'Invalid MFA code provided';

  return errors;
};
