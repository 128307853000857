import React, { Fragment } from 'react';
import { Paper, Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import InfoText from 'shared/styleguide/molecules/InfoText';
import ConfirmAction from 'shared/styleguide/molecules/ConfirmAction';
import { PRESSDNS_REGEX } from 'shared/modules/dns/constants';
import Banner from 'shared/styleguide/molecules/Banner';
import MDView from 'shared/styleguide/atoms/Markdown/MDView';

import type { App } from 'shared/types/App';
import { INACTIVE } from './constants';

interface Props {
  app: App;
  state: Record<string, any>;
  onEnableFsa: any;
}

const Disabled = ({ app, state, onEnableFsa }: Props) => {
  const allDomainsArePressDns = !app.aliases
    .filter((domain) => !domain.fqdn.match(PRESSDNS_REGEX) && domain.active)
    .find((domain) => !domain.isPressDns);

  const banner = () => {
    if (!app.active) {
      return (
        <Banner
          subheading={(
            <span>App must be active in order to enable Mercury Dynamic Site Acceleration</span>
          )}
          padding="small"
          type="info"
          dismissable
          rounded="top"
        />
      );
    }

    return null;
  };

  const infoText = <InfoText href="https://support.pagely.com/hc/en-us/articles/360051132192">What is Mercury Dynamic Site Acceleration?</InfoText>;
  return (
    <Paper>
      {banner()}
      <Box padding="small" justify="space-between" direction="row">
        <Box>
          <Box direction="row" align="center" gap="xsmall" padding={{ bottom: 'xsmall' }}>
            <Typography component="span" variant="h5">Status: </Typography>
            <span>
              <GhostTag color="smoke">{INACTIVE}</GhostTag>
            </span>
          </Box>
          {infoText}
        </Box>
        <Box
          justify="center"
        >
          <Box>
            <ConfirmAction
              onConfirm={() => { onEnableFsa(); }}
              explainerText="You are enabling Mercury Dynamic Site Acceleration."
              secondaryText={(
                <Fragment>
                  <Box margin={{ bottom: 'xxsmall' }}>
                    {infoText}
                  </Box>

                  <Typography gutterBottom>
                    This process will take several minutes to complete. There will be no downtime during the conversion.
                  </Typography>
                  {
                    !allDomainsArePressDns && (
                      <Typography>
                        You will be prompted to update DNS records for non-PressDNS domains during setup.
                      </Typography>
                    )
                  }
                </Fragment>
              )}
              variant="contained"
              loading={state.enable === 'loading'}
              disabled={!app.active}
            >
              Enable
            </ConfirmAction>
          </Box>
        </Box>
      </Box>
      {
        state.errors && (
          <Box padding={{ left: 'small', right: 'small', bottom: 'small' }}>
            <Typography component="span" color="error"><MDView value={state.errors} /></Typography>
          </Box>
        )
      }
    </Paper>
  );
};

export default Disabled;
