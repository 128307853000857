import React, {
  useEffect, useState, Fragment,
} from 'react';
import {
  object, number, string, func, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Measure from 'react-measure';

import { Typography } from '@material-ui/core';

import { getDimensionsByPath } from 'shared/modules/analytics/redux/actions/disk';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import { hashCode, chunk } from 'shared/utils';

import { remMapper } from 'shared/styleguide/theme/spacing';
import { graphTypesSorter } from './graphUtils';

import DiskPieByLocation from './DiskPieByLocation';

const statsDateRange = {
  fromDate: moment().subtract(1, 'days').tz('UTC').format(),
  toDate: moment().tz('UTC').endOf('day').format(),
};

const DiskPieByServer = ({
  accountId, serverName, multiServer, getDimensionsByPath, pathDimensions,
}) => {
  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    if (pathDimensions.params?.accountId !== accountId) {
      getDimensionsByPath(accountId, {
        ...statsDateRange,
        serverName: [serverName],
      });
    }
  }, []);

  if (pathDimensions.status === 'loading' || pathDimensions.status === 'pristine') {
    return <Loading />;
  }

  const pieWidth = dimensions.width < 840 ? '100%' : 'calc(50% - 1.5rem)';
  const sortedTypes = pathDimensions.data.dimensions.type
    .filter((t) => t !== 'system') // we don't want a system pie graph
    .sort((a, b) => graphTypesSorter[a] - graphTypesSorter[b]);
  const graphTypes = ['overview', ...sortedTypes];

  return (
    <div>
      <Typography variant="h3" color="textPrimary" css={{ marginBottom: `${remMapper('small')}` }}>
        Location
        {
            multiServer && ` - ${serverName}`
          }
      </Typography>
      <Measure
        bounds
        onResize={(contentRect) => {
          setDimensions(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <Box ref={measureRef} direction="row" wrap="wrap" gap="medium">
            {
              graphTypes.map((graphType) => {
                return (
                  <Box key={`${serverName}-${graphType}`} flex={`1 1 ${pieWidth}`}>
                    <DiskPieByLocation
                      accountId={accountId}
                      serverName={serverName}
                      graphType={graphType}
                      dimensions={pathDimensions.data.dimensions}
                    />
                  </Box>
                );
              })
            }
          </Box>
        )}
      </Measure>
    </div>
  );
};

DiskPieByServer.propTypes = {
  accountId: number,
  getDimensionsByPath: func.isRequired,
  multiServer: bool,
  pathDimensions: object,
  serverName: string,
};

export default connect(
  (state) => ({
    pathDimensions: state.analytics.disk.byPath.dimensions,
  }), { getDimensionsByPath },
)(DiskPieByServer);
