import colors from 'shared/styleguide/theme';

export const jssStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  importCertFooter: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    width: '100%',
    alignItems: 'center',
  },
  importCertActions: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '30px 20px 26px 20px',
    justifyContent: 'flex-end',
  },
};

export const inlineStyles = {
  flatButton: {
    importCertPrimary: {
      'width': 300,

    },
    importCertSecondary: {
      width: 300,
      backgroundColor: colors.sea20,
      color: colors.light40,
    },
  },
};
