import makeApiAction from 'shared/utils/redux/apiActions';
import * as EMAIL from './constants';

export const getEmailTemplates = () => makeApiAction(
  ({ api }) => api().get('/email/templates'),
  EMAIL.GET_TEMPLATES_REQUEST,
  EMAIL.GET_TEMPLATES_SUCCESS,
  EMAIL.GET_TEMPLATES_FAILURE,
  {},
  true,
);

export const sendTestEmail = (body) => makeApiAction(
  ({ api }) => api().post('/email/test', body),
  EMAIL.SEND_TEST_EMAIL_REQUEST,
  EMAIL.SEND_TEST_EMAIL_SUCCESS,
  EMAIL.SEND_TEST_EMAIL_FAILURE,
  {},
  true,
);

export const updateTemplate = ({ templateId, body }) => makeApiAction(
  ({ api }) => api().patch(`/email/templates/${templateId}`, {
    ...body,
  }),
  EMAIL.UPDATE_TEMPLATE_REQUEST,
  EMAIL.UPDATE_TEMPLATE_SUCCESS,
  EMAIL.UPDATE_TEMPLATE_FAILURE,
  {},
  true,
);
