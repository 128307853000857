import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import Box from 'shared/styleguide/atoms/Box';
import Select from 'shared/styleguide/atoms/Select/Select';

export const ConfigsSelector = ({
  scopeList,
  configTypeList,
  appsList,
  serversList,
  // statusList, // when status is implemented
  scope,
  filter,
  required,
  onSetScope,
  onSetFilter,
}) => {
  const handleSetScope = (e) => onSetScope(e.value);
  const handleSetFilter = (type) => (e) => onSetFilter(type, e.value);

  const selectors = [
    {
      name: 'scope', label: 'Scope', id: 'scope', list: scopeList,
    },
    {
      name: 'configType', label: 'Type', id: 'configType', list: configTypeList,
    },
    scope.includes('server') && {
      name: 'server', label: 'Server', id: 'serverId', list: serversList,
    },
    scope.includes('app') && {
      name: 'app', label: 'App', id: 'appId', list: appsList,
    },
    // when status is implemented
    // scope.includes('status') && {
    //   name: 'status', label: 'Status', id: 'status', list: statusList,
    // },
  ];

  return (
    <Box row gap="xsmall" flex={1} style={{ width: '100%' }}>
      {
        Object.keys(selectors)
          .filter((x) => selectors[x])
          .map((selector) => {
            const s = selectors[selector];
            return (
              <Box key={`${s.id}`} flex={1}>
                {
                  s.id === 'scope'
                    ? <Typography variant="caption">Scope</Typography>
                    : <Typography variant="caption" color={required.includes(s.name) ? 'error' : 'textPrimary'}>{s.label}</Typography>
                }
                <Select
                  hideSelectedOptions={false}
                  placeholder={`Select ${s.label}...`}
                  value={
                    s.id === 'scope' ? s.list.filter((f) => f.value === scope) : (s.list.filter((f) => f.value === filter[s.id]) || null)
                  }
                  onChange={
                    s.id === 'scope' ? handleSetScope : handleSetFilter(s.id)
                  }
                  options={s.list}
                />
              </Box>
            );
          })
      }
    </Box>
  );
};

ConfigsSelector.propTypes = {
  appsList: PropTypes.array,
  configTypeList: PropTypes.array,
  filter: PropTypes.object,
  onSetFilter: PropTypes.func,
  onSetScope: PropTypes.func,
  required: PropTypes.array,
  scope: PropTypes.string,
  scopeList: PropTypes.array,
  serversList: PropTypes.array,
  // statusList: PropTypes.array, // when status is implemented
};

export default ConfigsSelector;
