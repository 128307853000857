import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import {
  requestAdminLoginIfNeeded,
  loginLogout,
  forgotPasswordAdmin,
  forgotPasswordSetAdmin as forgotPasswordSet,
  forgotPasswordReset,
} from 'shared/modules/authentication/redux/actions';
import { getUser } from 'shared/modules/permissions/user/actions';
import Loading from 'shared/styleguide/atoms/Loading';

// import PhotonLogin from 'shared/modules/authentication/containers/PhotonLogin/PhotonLogin';
import Misc from 'admin/modules/admin/components/AFls';

import Accounts from 'admin/modules/search/accounts/Accounts';
import AppSearch from 'admin/modules/search/apps/AppSearch';
import Alerts from 'admin/modules/alerts/components/Alerts';
import Settings from 'admin/modules/settings';
import BannedPlugins from 'admin/modules/admin/components/BannedPlugins/BannedPlugins';

import Email from 'admin/modules/email/components';
import { getDeployEnvironment } from 'shared/config';
import ReleaseNotes from 'shared/modules/releasenotes/pages/ManageReleaseNotes';
import { FeatureToggle } from 'shared/flags';
import Infrastructure from '../serverconfig/components';
import MigrationSecretsForm from '../migrationsecrets';
import { migrationSecretsPath } from '../migrationsecrets/constants';

const NotFound = () => (
  <div style={{ padding: 10 }}>
    <Typography variant="h1">Page Not Found</Typography>
  </div>
);

export const Routes = ({ user }) => {
  useEffect(() => {
    document.title = getDeployEnvironment() === 'woosaas' ? 'MWCS' : 'MGMT';
  }, []);
  // if user isn't loaded, wait for it to be loaded.
  if (!user.loaded) {
    return (<Loading />);
  }

  return (
    <Switch>
      <Route exact path="/" component={Accounts} />
      <Route path="/settings" component={Settings} />
      <Route path="/account-search" component={Accounts} />
      <Route path="/app-search" component={AppSearch} />
      <Route path="/alerts" component={Alerts} />
      <Route path="/email" component={Email} />
      <Route path="/banned-plugins" component={BannedPlugins} />
      <Route path="/release-notes" component={ReleaseNotes} />
      <Route path="/infrastructure" component={Infrastructure} />
      {/* <Route path="/photon-login" component={PhotonLogin} /> */}
      <Route path="/misc" component={Misc} />
      <FeatureToggle stages="woosaas">
        <Route path={`${migrationSecretsPath}/:channelId?`} component={MigrationSecretsForm} />
      </FeatureToggle>
      <Route component={NotFound} />
    </Switch>
  );
};

Routes.propTypes = {
  apiFailure: PropTypes.bool,
  classes: PropTypes.object,
  doGetUser: PropTypes.func.isRequired,
  doLogin: PropTypes.func.isRequired,
  doLogout: PropTypes.func.isRequired,
  forgotPasswordAdmin: PropTypes.func,
  forgotPasswordReset: PropTypes.func,
  forgotPasswordSet: PropTypes.func,
  login: PropTypes.shape({
    errMessage: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    requestErr: PropTypes.bool.isRequired,
  }),
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = {
  doLogin: requestAdminLoginIfNeeded,
  doLogout: loginLogout,
  doGetUser: getUser,
  forgotPasswordAdmin,
  forgotPasswordReset,
  forgotPasswordSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
