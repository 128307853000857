import React from 'react';
import PropTypes from 'prop-types';
import FileReaderInput from 'react-file-reader-input';

export const propTypes = {
  children: PropTypes.element,
  selectFile: PropTypes.func,
};

export class TextFileSelect extends React.Component {
  static propTypes = propTypes;

  handleChange = (event, results) => {
    results.forEach((result) => {
      const [e, file] = result;
      const text = e.target.result;
      this.props.selectFile(text, file.name, file.size);
    });
  };

  render() {
    return (
      <FileReaderInput
        as="text"
        onChange={this.handleChange}
        style={{ width: 'inherit' }}
      >
        {this.props.children}
      </FileReaderInput>
    );
  }
}

export default TextFileSelect;
