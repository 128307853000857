import React, { useEffect, useState } from 'react';
import { array, number, string } from 'prop-types';

import Pagination from '@material-ui/lab/Pagination';
import Box from 'shared/styleguide/atoms/Box';
import RedirectsItem from './RedirectsItem';

const PAGE_LENGTH = 10;

const RedirectsList = ({ appId, redirects, searchText }) => {
  const [page, setPage] = useState(1);
  const index = (page * PAGE_LENGTH) - PAGE_LENGTH;

  useEffect(() => {
    if (redirects.length <= PAGE_LENGTH) {
      setPage(1);
    }
  }, [redirects]);

  const shownRedirects = redirects.filter((redirect) => {
    const searchHaystack = redirect.matchIdentifier.concat(redirect.ruleRewrite.map((r) => `${r.host || ''}${r.path}`)).toLowerCase();
    return (searchHaystack).search(searchText) > -1;
  });

  return (
    <div>
      {
        shownRedirects
          .slice(index, index + PAGE_LENGTH)
          .map((redirect) => {
            return (
              <RedirectsItem
                key={redirect.matchIdentifier}
                appId={appId}
                redirect={redirect}
              />
            );
          })
      }
      {
        shownRedirects.length > PAGE_LENGTH && ( // use updated redirects to calculate
          <Box margin={{ top: 'small' }} justify="center" direction="row">
            <Pagination
              shape="rounded"
              count={Math.ceil(shownRedirects.length / PAGE_LENGTH)}
              page={page}
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </Box>
        )
      }
    </div>
  );
};

RedirectsList.propTypes = {
  appId: number,
  redirects: array.isRequired,
  searchText: string,
};

export default RedirectsList;
