import React, { Fragment } from 'react';
import {
  func, object, string, array, bool,
} from 'prop-types';

import {
  FormGroup, FormLabel, Collapse, Checkbox, FormControlLabel, Divider, RadioGroup, Radio,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

const Filters = ({
  displayFilters,
  onSelectAll,
  onChangeFilters,
  onSetTypeFilter,
  dimensions,
  options,
  type,
  path,
  apps,
  typeMap,
}) => {
  const isCpuOrSimpleMeta = (column, type) => {
    return ['serverName', 'mount', 'id', 'name'].includes(column) || (column === 'type' && type === 'cpu');
  };

  return (
    <Collapse in={displayFilters}>
      <Box gap="medium" flex={1} direction="row" padding="small">
        <Box flex={1} justify="flex-start" direction={type === 'cpu' ? 'row' : 'column'} gap="large">
          {
            Object.entries(dimensions)
              .map(([column, values]) => {
                if ((isCpuOrSimpleMeta(column, type) || (column === 'location' && path !== 'byPath')) && values.length === 1) {
                  return null;
                }
                if (isCpuOrSimpleMeta(column, type)) {
                  return (
                    <Box key={`${column}-${type}`} style={{ overflow: 'auto', maxHeight: 400 }}>
                      <FormGroup>
                        <FormLabel>{column}:</FormLabel>
                        <Box row justify="flex-start" wrap="wrap">
                          {
                            (values).map((option) => {
                              return (
                                <FormControlLabel
                                  key={`${column}-${type}-${option}`}
                                  control={(
                                    <Checkbox
                                      checked={(options[column] && options[column].includes(option)) || false}
                                      onChange={onChangeFilters(column, option)}
                                      value={option}
                                    />
                                  )}
                                  label={option}
                                />
                              );
                            })
                          }
                        </Box>
                      </FormGroup>
                    </Box>
                  );
                }
                if (column === 'type') {
                  const radioOptions = values.map((item) => {
                    return {
                      name: item,
                      label: item,
                    };
                  });
                  radioOptions.unshift({
                    name: 'location',
                    label: 'location',
                  });
                  return (
                    <Box flex={1} key={`${column}-${type}`}>
                      <Box style={{ width: 300 }} key={column}>
                        <FormGroup>
                          <FormLabel>{column}:</FormLabel>
                          <Box margin={{ top: 'xsmall' }}>
                            <RadioGroup
                              aria-label="leftAxis"
                              name="yAxesLeft"
                              value={(options[column] && radioOptions.find((f) => f?.name === options[column]?.[0]).name) || radioOptions[0].name}
                              onChange={onSetTypeFilter(column)}
                              row
                            >
                              {
                                radioOptions
                                  .map((option) => {
                                    return (
                                      <FormControlLabel key={option.name} value={option.name} control={<Radio />} label={option.label} />
                                    );
                                  })
                              }
                            </RadioGroup>
                          </Box>
                        </FormGroup>
                      </Box>
                    </Box>
                  );
                }
                return null;
              })
          }
        </Box>
        {
          Object.entries(dimensions)
            .filter(([column]) => {
              if (typeMap.includes(column)) {
                // only show type children
                return true;
              }
              return false;
            }).map(([column, values]) => {
              let columnTwoDirection = 'column';

              if (path !== 'byPath' && values.length > 3) {
                columnTwoDirection = 'row';
              }

              return (
                <Box flex={1} key={`right-column-${column}-${type}`} style={{ overflow: 'auto', maxHeight: 400 }}>
                  <FormGroup>
                    <FormLabel>{column === 'appIds' ? 'apps' : column}:</FormLabel>
                    {
                      type === 'disk'
                      && (
                        <Fragment>
                          <FormControlLabel
                            key={`select-all-checkbox-${column}`}
                            control={(
                              <Checkbox
                                checked={options[column]?.length === dimensions[column]?.length || false}
                                onChange={onSelectAll(column)}
                                value={column}
                              />
                            )}
                            label="all"
                          />
                          <Box margin={{ right: 'medium' }}><Divider /></Box>
                        </Fragment>
                      )
                    }
                    <Box direction={columnTwoDirection} justify={(values.length > 3 && path !== 'byPath') ? 'flex-start' : ''} wrap="wrap" padding={{ left: 'medium' }}>
                      {
                        (values).map((option) => {
                          return (
                            <FormControlLabel
                              key={`right-column-${column}-${type}-${option}`}
                              control={(
                                <Checkbox
                                  checked={(options[column] && options[column].includes(option)) || false}
                                  onChange={onChangeFilters(column, option)}
                                  value={option}
                                />
                              )}
                              label={(column === 'appIds' && apps[option]?.name) ? `${option} - ${apps[option]?.label || apps[option]?.name}` : option}
                            />
                          );
                        })
                      }
                    </Box>
                  </FormGroup>
                </Box>
              );
            })
        }
      </Box>
      <Divider />
    </Collapse>
  );
};

Filters.propTypes = {
  apps: object,
  dimensions: object.isRequired,
  displayFilters: bool,
  onChangeFilters: func.isRequired,
  onSelectAll: func.isRequired,
  onSetTypeFilter: func.isRequired,
  options: object.isRequired,
  path: string.isRequired,
  type: string.isRequired,
  typeMap: array.isRequired,
};

export default Filters;
