import React from 'react';
import { object } from 'prop-types';
import { hot } from 'react-hot-loader';
import { connect, Provider } from 'react-redux';

import { Route, Router as BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { darkTheme } from 'shared/styleguide/theme/theme';
import { ThemeProvider as MasterThemeProvider } from 'shared/modules/webapp/ThemeContext';

import LoginRouter from 'shared/modules/authentication/routes';
import store from './store';
import history from './history';
import App from './modules/layout/App';
import AdminLogin from './modules/login/Login';
import 'shared/styleguide/assets/importFonts';

require('shared/styleguide/css/app.css');
require('shared/styleguide/css/markdown.css');
require('shared/styleguide/assets/favicon.ico');

export const BaseComponent = ({ login }) => {
  // Let's never have to deal with whether or not our user is authenticated
  const isAuthenticated = login.isLoggedIn;

  if (!isAuthenticated) {
    return (
      <Route
        render={(props) => (
          <MuiThemeProvider theme={darkTheme}>
            <LoginRouter isAdmin>
              <AdminLogin {...props} />
            </LoginRouter>
          </MuiThemeProvider>
        )}
      />
    );
  }

  return (
    <Route path="/" component={App} />
  );
};

export const ConnectedApp = connect(({ login }) => ({ login }))(BaseComponent);

BaseComponent.propTypes = {
  location: object,
  login: object.isRequired,
};

const WrappedApp = () => (
  <MasterThemeProvider>
    <Provider store={store}>
      <BrowserRouter history={history}>
        <ConnectedApp />
      </BrowserRouter>
    </Provider>
  </MasterThemeProvider>

);

export default hot(module)(WrappedApp);
