import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import Loading from 'shared/styleguide/atoms/Loading';
import Select from 'shared/styleguide/atoms/Select/Select';
import { setFilter } from 'admin/modules/search/actions';
import AccountList, { STORAGE_KEY } from 'admin/modules/search/accounts/AccountList';
// import Bongo from 'admin/components/AFls/Bongo';
// import Lucky from 'admin/components/AFls/Lucky';
import Box from 'shared/styleguide/atoms/Box';
import { useGetAccountsWithFiltersQuery } from 'admin/query';

export const Accounts = (props) => {
  const {
    history,
    accounts,
  } = props;

  const { data, error, isLoading } = useGetAccountsWithFiltersQuery(accounts, { skip: !accounts.searchString });

  if (isLoading) {
    return (
      <Loading />
    );
  }

  let header = null;
  let subheader = null;
  let accountsToShow = data;

  // if !searchString && there are no recent accounts in the store,
  // then show "no recent accounts" and don't render table.
  if (accounts.searchString && data !== undefined) {
    header = 'Search Results';
    subheader = `Your search returned ${data?.length} ${data?.length === 1 ? 'result' : 'results'}`;
  } else {
    accountsToShow = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];

    header = accountsToShow?.length > 0 ? 'Recent Accounts' : 'Welcome to MGMT';
    subheader = accountsToShow?.length > 0 ? '' : 'Your search results will show up here';
  }
  const filters = {
    collaborators: { value: 'collaborators', label: 'Subscribers & Collaborators' },
    inactive: { value: 'inactive', label: 'Only inactive accounts' },
    nonsubscribers: { value: 'nonsubscribers', label: 'Only non-subscribers' },
  };

  return (
    <Box margin="large">
      {/* <Lucky /> */}
      <Box direction="row" justify="space-between" align="center" margin={{ bottom: 'small' }}>
        <div>
          <Typography variant="h2">{header}</Typography>
          <Typography variant="subtitle1">{subheader}</Typography>
        </div>
        <Box>
          <div css={{ width: 270 }}>
            {accounts.searchString ? (
              <Select
                hideSelectedOptions={false}
                isClearable={false}
                onChange={({ value }) => props.setFilter(value)}
                value={filters[accounts.filter]}
                options={Object.values(filters)}
              />
            ) : null}
          </div>
        </Box>
      </Box>
      {(error && accounts.searchString) ? (
        <div>
          <Typography color="error">
            {/* @ts-ignore */}
            {error.status}: {error.data.message}
          </Typography>
        </div>
      ) : null}
      {accountsToShow?.length > 0 && <div data-testid="accountlist"><AccountList key={`${accounts.filter}-${accounts.searchString}-${data?.length}`} history={history} accounts={accountsToShow} /></div>}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return { accounts: state.search.accounts };
};

const mapDispatchToProps = {
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
