export const planIdToName = {
  'plan-enterprise': 'Scale',
  'plan-shared': 'Legacy Shared',
  'plan-vps': 'Dev',
  'plan-dev-1': 'Developer 1',
  'plan-dev-2': 'Developer 2',
  'plan-perf-1': 'Performance',
  'plan-perf-2': 'Performance+',
  'plan-pref-1-HA': 'Performance HA',
  'plan-pref-2-HA': 'Performance+ HA',
};

export const limitedAddonKeys = {
  wordpress: 'wordpress',
  storage: 'storage',
  cdn: 'cdn',
  bandwidth: 'bandwidth',
};
