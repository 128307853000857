import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

const Select = (props) => (
  <CreatableSelect
    {...props}
  />
);
Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  style: PropTypes.object,
  value: PropTypes.any,
};

export default Select;
