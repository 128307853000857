import { css } from '@emotion/react';

export const window = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const horizontalContent = css`
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  height: calc(100% - 60px);
`;

export const leftSidebar = css`
  display: flex;
  flex-direction: column;
`;

export const mainView = css`
  flex: 1;
  overflow: auto;
  background-color: var(--color-background);
`;

export const rightSidebar = css`
  min-width: 350px;
  overflow-x: hidden;
  overflow-y: auto;
`;
