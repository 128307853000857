import React from 'react';

import {
  TableHead,
  TableCell,
  TableRow,
} from '@material-ui/core';

interface Props {
  label: string|null;
}

const ValidationItemRuleHeader = ({ label }: Props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell css={{ width: 90, padding: '0.5rem 0.75rem' }}>
          Type
        </TableCell>
        {
          label !== 'PressDNS Nameservers'
          && (
            <TableCell css={{
              minWidth: 60, width: 100, maxWidth: 240, padding: '0.5rem 0.75rem',
            }}
            >
              Name
            </TableCell>
          )
        }
        <TableCell css={{ padding: '0.5rem 0.75rem' }}>
          Value
        </TableCell>
        <TableCell css={{ width: 60, padding: '0.5rem 0.75rem' }}>
          Status
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ValidationItemRuleHeader;
