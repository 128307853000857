import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Location } from 'history';
import { authenticateWithSSOCode } from 'shared/modules/authentication/redux/actions';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import logger from 'shared/3rdparty/logger';

interface QueryString {
  state: string;
  code: string;
  error?: string;
}

interface State {
  pool: string;
  client_id: string;
  target: number;
  pusherId?: string;
}

interface AuthParams {
  params: [pool: State['pool'], code: QueryString['code'], client_id: State['client_id'], target: State['target'], pusherId?: State['pusherId']] | [];
  errorMessage: string;
  isMgmtClient: boolean;
}

// TODO: params should be typed as the params for `authenticateWithSSOCode` in actions.js (once that's ts-ified)
const getAuthParams = (location: Location): AuthParams => {
  let errorMessage = null;

  let code: string;
  let state: string;
  let error: string;
  let isMgmtClient = false;

  try {
    const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as QueryString;
    state = parsedParams.state;
    code = parsedParams.code;
    error = parsedParams.error;
    if (error) {
      logger.error(error);
      return { params: [], errorMessage: error, isMgmtClient };
    }
    if (state === undefined || code === undefined) {
      const missing = ['state', 'code'].filter((v) => !parsedParams[v]);
      errorMessage = `Required query parameters missing: ${missing}`;
      logger.error(errorMessage);
      return { params: [], errorMessage, isMgmtClient };
    }
  } catch (err) {
    logger.error(err);
    return { params: [], errorMessage: `Error parsing query parameters: ${err.message}`, isMgmtClient };
  }

  let pool: State['pool'];
  let client_id: State['client_id'];
  let target: State['target'];
  let pusherId: State['pusherId'];
  try {
    const parsedData = JSON.parse(window.atob(decodeURIComponent(state))) as State;
    pool = parsedData.pool;
    client_id = parsedData.client_id;
    target = parsedData.target;
    pusherId = parsedData.pusherId;
    if (pool === undefined || client_id === undefined || target === undefined) {
      const missing = ['pool', 'client_id', 'target'].filter((v) => parsedData[v] === undefined);
      errorMessage = `Required state data missing: ${missing}`;
      logger.error(errorMessage);
      return { params: [], errorMessage, isMgmtClient };
    }
    if (pusherId) {
      isMgmtClient = true;
    }
  } catch (err) {
    logger.error(err);
    return { params: [], errorMessage: `Unable to parse State provided: ${err.message}`, isMgmtClient };
  }
  return { params: [pool, code, client_id, target, pusherId], errorMessage, isMgmtClient };
};

/**
 * @returns title and body of a user facing error message, otherwise logs user in.
 */
const useSSOLogin = (): [title: string, body: string, hasError: boolean, isMgmtClient: boolean] => {
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    requestErr: loginError,
    errMessage: loginErrorMessage,
    statusCode: loginErrorStatusCode,
  } = useSelector((state: any) => state.login);

  const [hasError, setHasError] = useState(false);

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('Logging in ...');
  const [isMgmtClientLogin, setIsMgmtClientLogin] = useState(false);

  useEffect(() => {
    if (loginError) {
      setHasError(true);
      setTitle(`Error: ${loginErrorStatusCode}`);
      setBody(loginErrorMessage);
    }
  }, [loginError, loginErrorMessage, loginErrorStatusCode]);

  useEffect(() => {
    const { params: authParams, errorMessage, isMgmtClient } = getAuthParams(location);
    const confirmationText = `
      If you initiated this mgmt-client authentication, press OK.
      If you did not, press CANCEL and report the incident to an administrator.
    `;
    // eslint-disable-next-line
    if (isMgmtClient && !window.confirm(confirmationText)) {
      setIsMgmtClientLogin(true);
      setTitle('Cancelled');
      setBody('The mgmt-client authorization has been cancelled.');
      return;
    }
    if (!errorMessage) {
      dispatch(authenticateWithSSOCode(...authParams, isMgmtClient));
      if (isMgmtClient) {
        setIsMgmtClientLogin(true);
        setTitle('Success');
        setBody('You may close this window. Please return to mgmt client.');
      }
    } else {
      setHasError(true);
      setTitle('Error');
      setBody(errorMessage);
    }
  }, [dispatch, location]);

  return [title, body, hasError, isMgmtClientLogin];
};

const SSOLogin: React.FC = () => {
  const [title, body, hasError, isMgmtClientLogin] = useSSOLogin();

  return (
    <Box
      padding="large"
      css={{ width: '380px' }}
    >
      {
        (hasError || isMgmtClientLogin) ? (
          <>
            <Typography variant="h3">{title}</Typography>
            <br />
            <Typography variant="h5">{body}</Typography>

          </>
        ) : (
          <>
            <Box>
              <Loading margin={{ top: 'small' }} />
            </Box>
            <br />
            <Typography variant="h4">Logging in ...</Typography>
          </>
        )
      }
    </Box>
  );
};

export default SSOLogin;
