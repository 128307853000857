import React from 'react';
import {
  Checkbox,
  FormControl, FormControlLabel, Radio, RadioGroup,
  Typography,
} from '@material-ui/core';
import { Field, useField } from 'formik';
import { HeadlineText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import { AUTH_TYPE } from 'shared/utils/constants';

const hstsOptions = [
  {
    label: 'Disabled',
    value: '0',
  },
  {
    label: '5 Minutes',
    value: '300',
  },
  {
    label: '1 Hour',
    value: '3600',
  },
  {
    label: '30 Days',
    value: '2592000',
  },
  {
    label: '6 Months',
    value: '15768000',
  },
  {
    label: '1 Year',
    value: '31536000',
  },
];

export const HSTS = ({ userType }) => {
  const [field, meta, helpers] = useField('hstsTimeout');

  return (
    <div>
      <HeadlineText gutterBottom>HSTS</HeadlineText>
      <Typography>HTTP Strict Transport Security</Typography>
      <Box row gap="medium" flex={1} margin={{ left: 'medium', top: 'xsmall' }}>
        <Box flex={1}>
          <FormControl component="fieldset" disabled={userType === AUTH_TYPE.account}>
            <RadioGroup
              name="hstsTimeout"
              {...field}
            >
              {hstsOptions.map((item) => (
                <FormControlLabel key={item.label} control={<Radio />} {...item} />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box flex={1}>
          <Box direction="row" align="center">
            <FormControl disabled={userType === AUTH_TYPE.account}>
              <FormControlLabel
                control={(
                  <Field
                    as={Checkbox}
                    type="checkbox"
                    id="hstsIncludeSubdomains"
                    name="hstsIncludeSubdomains"
                    label="Include Subdomains"
                  />
              )}
                label="Include Subdomains"
              />
            </FormControl>
          </Box>
          <Box direction="row" align="center">
            <FormControl disabled={userType === AUTH_TYPE.account}>
              <FormControlLabel
                control={(
                  <Field
                    as={Checkbox}
                    type="checkbox"
                    id="hstsPreload"
                    name="hstsPreload"
                    label="Preload"
                  />
              )}
                label="Preload"
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default (HSTS);
