import makeApiAction from 'shared/utils/redux/apiActions';

import { getUserTypeAndId } from 'shared/utils';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const PERM_REQUEST = 'PERM_REQUEST';
export const PERM_SUCCESS = 'PERM_SUCCESS';
export const PERM_FAILURE = 'PERM_FAILURE';

export const roles = {
  PRIMARY: 10,
  SUPERADMIN: 9,
  SUBADMIN: 8,
  TECH: 6,
  BILLING: 4,
  APPONLY: 2,
  APPONLYMINIMAL: 1,
};

export const roleStrings = {
  10: 'Primary (Owner)',
  9: 'Super-Admin',
  8: 'Sub-Admin',
  6: 'Tech',
  4: 'Billing',
  2: 'App-Only',
  1: 'App-Only-Minimal',
};

export const roleDescriptions = {
  10: 'Primary (Owner)',
  9: 'This role has access to act almost identical to Account Owner but can not add admin users. They would have full access to application management, billing/plan adjustments, support, and various tech tools.',
  8: 'This role has access to add application specific user access. They would also have full access to application management, billing/plan adjustments, support, and various application tech tools.',
  6: 'This role can manage their own account. They would have full access to application management, support, and various application tech tools.',
  4: 'This role only has access to manage billing details and can see invoices and add-ons but not make changes to add-ons or subscriptions.',
  // TODO: add these descriptions
  2: 'App-Only',
  1: 'This role has access to the app files and the app DB.',
};

export const getUser = () => makeApiAction(
  ({ api }) => {
    let url;
    const [userType, userId] = getUserTypeAndId();

    switch (userType) {
      case 'Pagely.Model.Accounts.Account':
        url = `/accounts/${userId}`;
        break;
      case 'Pagely.Model.Accounts.Admin':
        url = `/accounts/admins/${userId}`;
        break;
      default:
        throw new Error(`Unable to fetch user of type: ${userType}`);
    }

    return api().get(url);
  },
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
);

export const getAccountPermissions = (accountId) => makeApiAction(
  ({ api }) => api().get(`/accounts/${accountId}/access`),
  PERM_REQUEST,
  PERM_SUCCESS,
  PERM_FAILURE,
  {
    accountId,
  },
);
