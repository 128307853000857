import Summary from './Summary';
import CommonFiles from './CommonFiles';
import DeletedApps from './DeletedApps';
import LargeFiles from './LargeFiles';

export const routes = [
  {
    label: 'Summary',
    to: 'summary',
    match: new RegExp(/\/summary$/), // eslint-disable-line
    component: Summary,
  },
  {
    label: 'Common Files',
    to: 'common-files',
    match: new RegExp(/\/common-files/), // eslint-disable-line
    component: CommonFiles,
  },
  {
    label: 'Deleted Apps',
    to: 'deleted',
    match: new RegExp(/\/deleted$/), // eslint-disable-line
    component: DeletedApps,
  },
  {
    label: 'Large Files',
    to: 'files',
    match: new RegExp(/\/files$/), // eslint-disable-line
    component: LargeFiles,
  },
];
