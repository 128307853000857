export const labelData = (series) => {
  const [tagType] = Object.entries(series[0].tags)[0];
  const columns = [...series[0].columns];
  columns.splice(1, 0, tagType);
  const data = [];
  series.forEach((entity) => {
    const tagValue = entity.tags[tagType];
    // generate a set of values
    entity.values.forEach((valueSet) => {
      const set = [...valueSet];
      // put in the tag column
      set.splice(1, 0, tagValue);
      data.push(set);
    });
  });

  return {
    columns,
    data,
  };
};
