import {
  useEffect, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  fetchGitWebhookDeployJobs, fetchGitIntegrationDeployJobs,
} from 'shared/modules/status/redux/actions';
import * as STATUS from 'shared/modules/status/redux/constants';
import { useMergeState } from 'shared/hooks/useMergeState';

import { INTEGRATION, WEBHOOK } from '../constants';

const tomorrowUTC = moment.utc().add(1, 'days').format('YYYY-MM-DD');
// logs are retained for 1 month, so just get jobs for that window
const oneMonthAgoUTC = moment.utc().subtract(1, 'months').format('YYYY-MM-DD');

const fetchParams = {
  statusIn: STATUS.gitDeployStatuses,
  startedDateRange: [oneMonthAgoUTC, tomorrowUTC],
};

const initialState = {
  fetchedIntegrationJobsOnce: false,
  fetchedWebhookJobsOnce: false,
};

export const useJobs = (accountId, appId, webhookJobs, integrationJobs) => {
  const { state, mergeState } = useMergeState({ ...initialState });
  const dispatch = useDispatch();

  const get = async (type) => {
    const fetch = type === INTEGRATION ? fetchGitIntegrationDeployJobs : fetchGitWebhookDeployJobs;
    const params = { ...fetchParams, appId };
    await dispatch(fetch(accountId, params));

    if (type === INTEGRATION) {
      mergeState({
        fetchedIntegrationJobsOnce: true,
      });
    }

    if (type === WEBHOOK) {
      mergeState({
        fetchedWebhookJobsOnce: true,
      });
    }
  };

  const hasFetched = (jobs) => {
    return ['success', 'failed'].includes(jobs.status) && jobs.params?.options?.appId === appId;
  };

  const memoizedGetJobs = useCallback(get, [accountId, appId, dispatch]);
  const memoizedHasFetched = useCallback(hasFetched, [appId]);

  useEffect(() => {
    if (appId && accountId) {
      if (integrationJobs.status !== 'loading' && integrationJobs.params?.options?.appId !== appId) {
        memoizedGetJobs(INTEGRATION);
      }

      if (webhookJobs.status !== 'loading' && webhookJobs.params?.options?.appId !== appId) {
        memoizedGetJobs(WEBHOOK);
      }
    }

    if (memoizedHasFetched(webhookJobs) && !state.fetchedWebhookJobsOnce) {
      mergeState({
        fetchedWebhookJobsOnce: true,
      });
    }

    if (memoizedHasFetched(integrationJobs) && !state.fetchedIntegrationJobsOnce) {
      mergeState({
        fetchedIntegrationJobsOnce: true,
      });
    }
  }, [state, webhookJobs, integrationJobs, accountId, appId, memoizedGetJobs, memoizedHasFetched]);

  const methods = {
    get,
  };

  const data = {
    fetchedOnce: {
      webhook: state.fetchedWebhookJobsOnce,
      integration: state.fetchedIntegrationJobsOnce,
    },
    ...state,
    webhook: { ...webhookJobs },
    integration: { ...integrationJobs },
  };

  return { ...data, ...methods };
};

export default useJobs;
