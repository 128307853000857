import React, { Component, Fragment } from 'react';
import {
  func, number, object, array,
} from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Toggle from 'shared/styleguide/atoms/Switches/Toggle';
import { ErrorText } from 'shared/styleguide/typography';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Select from 'shared/styleguide/atoms/Select/Select';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Box from 'shared/styleguide/atoms/Box';
import AppsDropdown from 'shared/styleguide/organisms/AppsDropdown';

import { roleStrings, roles as ROLES } from 'shared/modules/permissions/user/actions';
import { remMapper } from 'shared/styleguide/theme/spacing';

class EditCollaborator extends Component {
  static propTypes = {
    accountId: number.isRequired,
    addCollaborator: func.isRequired,
    apps: array.isRequired,
    collaborator: object.isRequired,
    editorRole: number.isRequired,
    onSetOpen: func,
    removeCollaborator: func.isRequired,
    updateCollaborator: func.isRequired,
    userPool: object,
  };

  constructor(props) {
    super(props);

    this.state = {
      role: props.collaborator.role,
      appIds: props.collaborator.appIds,
      error: '',
      status: 'initial',
      requireSSO: !props.collaborator.allowPasswordLogin,
    };

    this.roleValues = Object.keys(roleStrings)
      .filter((key) => {
        const role = parseInt(key, 10);
        return role < this.props.editorRole;
      })
      .map((key) => ({
        label: roleStrings[key],
        value: parseInt(key, 10),
      }));
  }

  modifyCollaborator = async () => {
    // For changing permissions on sites
    const {
      accountId,
      collaborator,
      collaborator: { appIds },
      addCollaborator,
      removeCollaborator,
      updateCollaborator,
    } = this.props;
    const { appIds: values } = this.state;

    const isChangingRole = collaborator.role !== this.state.role;

    if (this.state.role > 2 && collaborator.role > 2) {
      // all app access
      updateCollaborator(
        accountId,
        collaborator.sourceId,
        this.state.role,
        !this.state.requireSSO,
        0,
      );
    } else {
      let addThese;
      let deleteThese;
      if (this.state.role > 2 && collaborator.role <= 2) {
        addThese = [0];
        deleteThese = [...appIds];
      } else {
        addThese = isChangingRole ? [...values] : values.filter((v) => appIds.indexOf(v) === -1);
        deleteThese = isChangingRole ? [...appIds] : appIds.filter((a) => values.indexOf(a) === -1);
      }

      await Promise.all(deleteThese.map((app) => removeCollaborator(
        accountId,
        collaborator.sourceId,
        collaborator.role,
        app,
      )));

      await Promise.all(addThese.map((app) => addCollaborator(
        accountId,
        collaborator.accessedByName,
        collaborator.accessedByEmail,
        this.state.role,
        !this.state.requireSSO,
        app,
      )));

      this.setState({ status: 'initial' });
    }
  };

  removeFromTeam = () => {
    // For changing permissions on sites
    const {
      accountId,
      collaborator,
      collaborator: { appIds },
      removeCollaborator,
    } = this.props;

    const deleteThese = appIds;

    if (deleteThese.length > 0) {
      deleteThese.forEach((app) => {
        removeCollaborator(accountId, collaborator.sourceId, collaborator.role, app);
      });
    }
  };

  render() {
    const { collaborator, apps } = this.props;

    return (
      <Fragment>
        <DialogContent
          css={{
            overflow: 'visible',
          }}
        >
          <Box
            as="form"
            role="form"
            data-testid="editselect"
            css={{
              gap: `${remMapper('small')}`,
            }}
          >
            <Box
              row
              css={{
                gap: remMapper('small'),
              }}
            >
              <TextField
                placeholder="Name"
                name="name"
                disabled
                id={`${collaborator.sourceId}.name`}
                value={collaborator.accessedByName}
                css={{
                  flexGrow: 1,
                  margin: 0,
                }}
              />
              <TextField
                placeholder="Email"
                id="email"
                name="email"
                type="email"
                disabled
                value={collaborator.accessedByEmail}
                onChange={(e, email) => this.setState({ email })}
                errorText={collaborator?.validation?.body?.name?.messages.join(', ')}
                css={{
                  flexGrow: 1,
                  margin: 0,
                }}
              />
              {
                this.props.userPool && (
                  <Toggle
                    label="Require Single Sign On"
                    checked={this.state.requireSSO}
                    onChange={(e) => this.setState({ requireSSO: e.target.checked })}
                  />
                )
              }
            </Box>
            <Box
              row
            >
              <Select
                key="role"
                id="roleaccess"
                name="roleaccess"
                inputId="roleaccess"
                options={this.roleValues}
                onChange={(value) => this.setState({
                  role: parseInt(value.value, 10),
                })}
                value={this.roleValues.find((role) => (role.value === this.state.role))}
                isClearable={false}
                css={{
                  flexGrow: 1,
                }}
              />
            </Box>
            {
              (this.state.role === ROLES.APPONLYMINIMAL || this.state.role === ROLES.APPONLY)
              && (
                <Box row>
                  <Box
                    css={{
                      flexGrow: 1,
                    }}
                  >
                    <AppsDropdown
                      apps={apps}
                      values={this.state.appIds}
                      onChange={(values) => this.setState({ appIds: values ? values.map((v) => v.value) : [] })}
                      name="appaccess"
                      inputId="appaccess"
                      styles={{
                        container: (base) => ({
                          ...base,
                        }),
                        menu: (base) => ({ ...base, zIndex: 2, width: 275 }),
                      }}
                    />
                  </Box>
                </Box>
              )
            }
          </Box>
          {
            this.state.error
            && (
              <Box justify="center" align="center" margin={{ top: 'medium' }}>(
                <ErrorText>{this.state.error}</ErrorText>
                )
              </Box>
            )
          }
        </DialogContent>
        <DialogActions>
          <Box
            id="edit-actions"
            direction="row"
            justify="space-between"
            flex={1}
          >
            <Button
              data-testid="remove-collab"
              key="remove"
              variant="outlined"
              color="error"
              onClick={() => {
                this.removeFromTeam();
                this.props.onSetOpen(false);
              }}
            >
              Remove From team
            </Button>

            <Box direction="row" gap="small">
              <Button
                key="cancel"
                variant="outlined"
                color="default"
                onClick={() => {
                  this.props.onSetOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                data-testid="update-collab"
                key="proceed"
                variant="contained"
                disabled={
                  (this.state.role <= ROLES.APPONLY && (this.state.appIds.length === 0 || this.state.appIds[0] === 0))
                }
                status={this.state.status}
                onClick={async () => {
                  // if role <= APPONLY, make sure there are site selected.
                  // if a role has appIds set to [0], it means its set to all sites.
                  // which removes all permissions if changing to site only or sftp only
                  if (this.state.role <= ROLES.APPONLY && (this.state.appIds.length === 0 || this.state.appIds[0] === 0)) {
                    this.setState({ error: 'Must select App Access for this role' });
                    return;
                  }

                  this.setState({ status: 'loading' });

                  await this.modifyCollaborator();
                  this.props.onSetOpen(false);
                }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Fragment>
    );
  }
}

export default EditCollaborator;
