import { css } from '@emotion/react';

export const dateRangePickerOverrides = (theme) => {
  const colors = theme.palette.type === 'light' ? {
    background: theme.palette.background.paper,
    highlight: theme.palette.grey[300],
    disabled: theme.palette.action.disabled,
    border: theme.palette.grey[300],
    text: theme.palette.text.primary,
    hover: {
      background: theme.palette.grey[500],
      highlight: theme.color200,
      disabled: theme.palette.action.disabled,
      border: theme.palette.primary.main,
      text: theme.color000,
    },
  } : {
    background: theme.palette.background.paper,
    highlight: theme.palette.grey[700],
    disabled: theme.palette.action.disabled,
    border: theme.palette.grey[700],
    text: theme.palette.text.primary,
    hover: {
      background: theme.palette.grey[500],
      highlight: theme.color300,
      disabled: theme.palette.action.disabled,
      border: theme.palette.primary.main,
      text: theme.color000,
    },
  };

  return (css`
    .DateInput_input {
      line-height: 24px;
      color: ${theme.palette.text.secondary};
      padding: 7px 0px 3px;
      border-radius: 4px;
      font-size: 1rem !important;
      width: 80px;
      background-color: ${theme.palette.background.paper};
    }

    .DateRangePickerInput__withBorder {
      border-radius: 4px;
      border: 1px solid ${theme.palette.text.secondary}45;
      padding: 0 0 0px;
      width: 260px;
      background-color: ${theme.palette.background.paper};
    }

    .DateInput {
      padding: 0 0 0 10px;
      width: 90px;
      border-radius: 4px;
      background-color: ${theme.palette.background.paper};
    }

    .DateRangePickerInput_clearDates {
      padding: 6px;
      margin: 0;
      right: 3px;
      top: 3px;
      transform: none;
    }

    .DateRangePickerInput_arrow_svg {
      fill: ${theme.palette.text.secondary};
    }

    .DayPicker__horizontal, .DateRangePicker_picker, .CalendarMonthGrid__horizontal, .CalendarMonthGrid, .CalendarMonth{
      background-color: ${theme.palette.background.paper};
    }

    .CalendarDay__default, .DayPickerNavigation_button__default {
      background-color: ${colors.background};
      border-color: ${colors.border};
      color: ${colors.text};
      &:hover {
        color: ${colors.hover.text};
        background-color: ${theme.palette.colors.pink};
        border-color: ${theme.palette.colors.pink};
      }
    }

    .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
      background-color: ${theme.palette.action.disabledBackground};
      color: ${theme.palette.action.disabled};
      border-color: ${theme.palette.action.disabled};
    }

    .CalendarDay__selected_span, .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover  {
      background-color: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.main};
    }

    .CalendarDay__selected:active, .CalendarDay__selected:hover, .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover  {
      background-color: ${theme.palette.colors.pink};
      border-color: ${theme.palette.colors.pink};
    }

    .CalendarMonth_caption {
      color: ${colors.text};
    }

    .DayPickerNavigation_svg__horizontal {
      fill: ${colors.text};
      &:hover {
        fill: ${colors.hover.text};
      }
    }

`);
};
