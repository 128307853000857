import { development } from 'shared/flags';
import { NumberLike } from 'shared/types/types';
import { coreApi } from 'shared/utils/redux/query';
import { consolidateTeamPermissions } from './utils';

export const teamApi = coreApi.injectEndpoints({
  overrideExisting: development,
  endpoints: (builder) => ({
    getAccountCollaborators: builder.query<unknown, string>({
      query: (accountId) => `/accounts/${accountId}/collaborators?access=to`,
      transformResponse: (response: { data: unknown[] }, meta, arg) => {
        return response.data.reduce(consolidateTeamPermissions, []);
      },
    }),
  }),
});

export const {
  useGetAccountCollaboratorsQuery,
} = teamApi;
