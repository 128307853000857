import React, { useState } from 'react';
import {
  bool, func, number, object,
} from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import moment from 'moment-timezone';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityIconOff from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CircularProgress,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import logger from 'shared/3rdparty/logger';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import { consolidateErrors } from 'shared/utils/validation';
import Box from 'shared/styleguide/atoms/Box';
import TextMask from 'shared/styleguide/atoms/TextMask';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import { setRateLimits, deleteRateLimits, fetchRateLimit } from '../../redux/rateLimits/actions';
import { getErrorMessages, getErrors } from '../../utils';

export const ListItem = (props) => {
  const {
    record, updateList, deleteList, appId, onValidate, isExpanded = false, getSingleHandler,
  } = props;

  const { id: handlerId } = record;

  const [tokenState, setTokenState] = useState('hidden');
  const [expanded, setExpanded] = useState(isExpanded);
  const [deleteStatus, setDeleteStatus] = useState('pristine');
  const [changed, setChanged] = useState(false);

  const handleSetExpanded = (value) => {
    if (value !== expanded) {
      setExpanded(value);
    }
    if (value === false) {
      setChanged(false);
    }
  };

  const handleGetToken = async () => {
    // set state to loading token
    if (!record.token) {
      setTokenState('loading');
      await getSingleHandler(record.appId, handlerId);
    }
    setTokenState('visible');
  };

  const handleSubmit = async (values, actions) => {
    // contruct object to send
    const [body, shouldReturn] = onValidate(values, actions);
    if (shouldReturn) {
      return;
    }

    actions.setStatus('loading');
    try {
      await updateList(appId, handlerId, body);

      actions.setStatus('success');
      setTimeout(() => {
        actions.setStatus('pristine');
        setExpanded(false);
        setChanged(false);
      }, 3000);
    } catch (err) {
      actions.setStatus('pristine');
      actions.setErrors({
        'general': consolidateErrors(err),
        ...err.response.data.body,
      });
    }
  };

  const handleDelete = async () => {
    setDeleteStatus('loading');
    try {
      await deleteList(appId, handlerId);
      setExpanded(false);
      setDeleteStatus('pristine');
    } catch (err) {
      logger.error(err);
      setDeleteStatus('pristine');
    }
  };

  const ips = record.type === 'ip-list' ? record.value.join(',\n') : '';

  return (
    <Accordion expanded={expanded} onChange={() => handleSetExpanded(!expanded)} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary aria-controls="accessList-content" aria-label="accessList-content" id={`accessList-${record.idKey}-header`} expandIcon={<ExpandMoreIcon />}>
        <Box flex={1} justify="space-between" direction="row" align="center">
          <Box direction="row">
            <Typography component="span" variant="subtitle1" color="textSecondary">name:&nbsp;</Typography>
            <Typography component="span" variant="subtitle1">{record.name}</Typography>
          </Box>
          <Box direction="row" align="center" gap="small">
            {
              !record.enabled && (
                <GhostTag color="orange">Inactive</GhostTag>
              )
            }
            <Typography variant="caption" color="textSecondary">
              {moment(record.modifiedDate).format('LLL')}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Formik
          initialStatus="pristine"
          initialValues={{
            ...record,
            ips,
          }}
          onSubmit={handleSubmit}
          validateOnChange
          validate={() => setChanged(true)}
        >
          {({
            values,
            errors,
            status,
            handleChange,
          }) => (
            <Box direction="column" flex={1} as={Form}>
              <Box padding={{ bottom: 'small' }}>
                <Box direction="row" justify="space-between">
                  <div>
                    <TextField
                      id="name"
                      name="name"
                      autoComplete="off"
                      aria-label="Name"
                      placeholder="Name"
                      onChange={handleChange}
                      value={values.name}
                      error={Boolean(errors.name)}
                      helperText={Boolean(errors.name) && errors.name}
                      variant="outlined"
                      margin="dense"
                      css={{ marginTop: 0 }}
                    />
                  </div>
                  <div>
                    {values.enabled}
                    <FormControlLabel
                      label="Active"
                      name="enabled"
                      labelPlacement="start"
                      onChange={handleChange}
                      checked={values.enabled}
                      control={(<Switch />)}
                    />
                  </div>
                </Box>
                {
                  values.type === 'token' && (
                    <TwoColumn
                      data={[['deploy token', (
                        <Box direction="row" gap="xsmall" align="center" key="token">
                          {
                            (tokenState === 'hidden' || tokenState === 'loading') && (
                              <TextMask />
                            )
                          }
                          {
                            record.token && tokenState === 'visible' && (
                              <span>
                                {record.token.value}
                              </span>
                            )
                          }
                          <span>
                            {
                              tokenState === 'hidden' && (
                                <IconButton size="small" onClick={handleGetToken}>
                                  <VisibilityIcon />
                                </IconButton>
                              )
                            }
                            {
                              tokenState === 'visible' && (
                                <IconButton size="small" onClick={() => setTokenState('hidden')}>
                                  <VisibilityIconOff />
                                </IconButton>
                              )
                            }
                            {
                              record.token && (
                                <CopyButton
                                  tooltip="Click to copy token"
                                  text={record.token.value}
                                  message="Token copied to clipboard"
                                  fontSize="small"
                                />
                              )
                            }
                            {
                              tokenState === 'loading' && (
                                <CircularProgress indeterminate size={24} />
                              )
                            }
                          </span>
                        </Box>
                      )]]}
                    />
                  )
                }
                {
                  values.type === 'ip-list' && (
                    <Box>
                      <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
                        <Typography variant="body2" color="textSecondary" />
                      </Box>
                      <Box margin={{ bottom: 'small' }}>
                        <Typography component="small" variant="caption">Add comma-separated IP addresses or CIDR notation</Typography>
                        <TextField
                          fullWidth
                          id="ips"
                          name="ips"
                          error={Boolean(errors.ips)}
                          helperText={Boolean(errors.ips) && `${getErrorMessages(errors.ips).join(';')} - are you missing commas?`}
                          autoComplete="off"
                          onChange={handleChange}
                          value={values.ips}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                          placeholder="Add comma-separated IP addresses or CIDR notation"
                          margin="dense"
                          variant="outlined"
                          multiline
                          minRows={6}
                        />
                      </Box>
                    </Box>
                  )
                }
              </Box>
              <Box justify="space-between" direction="row" flex={1}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleDelete}
                  status={deleteStatus}
                >
                  Delete {values.type === 'token' ? 'token' : 'list'}
                </Button>
                <Button
                  variant={changed ? 'contained' : 'outlined'}
                  status={status}
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
};

ListItem.propTypes = {
  appId: number,
  changed: bool,
  deleteList: func.isRequired,
  getSingleHandler: func,
  isExpanded: bool,
  onSetChanged: func,
  onValidate: func,
  record: object.isRequired,
  updateList: func,
};

export default connect(
  null,
  {
    updateList: setRateLimits,
    deleteList: deleteRateLimits,
    getSingleHandler: fetchRateLimit,
  },
)(ListItem);
