import React, {
  useCallback, Fragment,
} from 'react';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import { getUploadIntegrations } from 'shared/modules/integrations/redux/selectors';
import { getSortedWebhookJobsForApp, getSortedIntegrationsJobsForApp } from 'shared/modules/status/redux/selectors';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import * as STATUS from 'shared/modules/status/redux/constants';

import { FeatureToggle } from 'shared/flags';
import { useJobs } from '../hooks/useJobs';
import LogsSection from './LogsSection';

import { INTEGRATION, WEBHOOK } from '../constants';
import CloneLogsSection from './CloneLogsSection';

export const DeployLogs = ({
  accountId, appId, webhookJobs, integrationJobs, integrations, __storybookMocks,
}) => {
  const unmemoizedJobs = useJobs(accountId, appId, webhookJobs, integrationJobs);
  const tempJobs = useCallback(unmemoizedJobs as unknown as any, [unmemoizedJobs, accountId, appId, webhookJobs, integrationJobs]);

  const jobs = __storybookMocks?.jobs ? {
    ...tempJobs,
    ...__storybookMocks.jobs,
  } : { ...tempJobs };
  return (
    <Fragment>
      <Box>
        <Typography data-testid="timezone-message" variant="body2" color="textSecondary">Webhook and CI/CD times are in UTC timezone</Typography>
      </Box>
      <Box direction="column">
        <FeatureToggle
          stages="woosaas"
        >
          <CloneLogsSection
            appId={appId}
          />
        </FeatureToggle>
        <ErrorBoundary>
          <LogsSection
            jobs={jobs}
            integrations={integrations}
            name="CI/CD Integration"
            type={INTEGRATION}
            __storybookMocks={__storybookMocks}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          {/* @ts-ignore */}
          <LogsSection
            jobs={jobs}
            name="Git Webhook"
            type={WEBHOOK}
            __storybookMocks={__storybookMocks}
          />
        </ErrorBoundary>
      </Box>
    </Fragment>
  );
};

export default connect(
  (state: { status: any }, ownProps: { appId: any }) => ({
    integrations: getUploadIntegrations(state),
    webhookJobs: {
      ...state.status[STATUS.jobTypes.gitWebhookDeploy],
      data: getSortedWebhookJobsForApp(state, STATUS.jobTypes.gitWebhookDeploy),
    },
    integrationJobs: {
      ...state.status[STATUS.jobTypes.gitIntegrationDeploy],
      data: getSortedIntegrationsJobsForApp(state, ownProps.appId, STATUS.jobTypes.gitIntegrationDeploy),
    },
  }),
  {},
)(DeployLogs);
