export const getLinksForApp = (certs) => {
  // for every certificate, build an object by aliasId/domainId
  let map = {};
  certs.forEach(({ cert, links }) => {
    map = links.reduce((acc, link) => {
      acc[link.aliasId] = {
        ...link,
        domainId: link.aliasId,
        expires: cert.expires,
      };
      return acc;
    }, map);
  });

  return map;
};
