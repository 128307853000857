import { dateFieldSorter } from 'shared/utils/sorting';
import {
  jobTypes, ABORTED, SUCCESS, FAILURE, ACTION_REQUIRED,
} from 'shared/modules/status/redux/constants';
import { getTypeAndVersionFromJob } from 'shared/modules/status/utils';

export const getFilteredJobsById = (state, jobType, validStatuses) => {
  return state.status[jobType].jobs
    && Object.values(state.status[jobType].jobs)
      .filter((j) => validStatuses.includes(j.overallStatus))
      .sort(dateFieldSorter('started', 'desc'));
};

export const getSortedJobs = (state, jobType) => {
  return state.status[jobType].jobs
    && Object.values(state.status[jobType].jobs)
      .sort(dateFieldSorter('started', 'desc'));
};

export const getSortedWebhookJobsForApp = (state, jobType) => {
  return state.status[jobType].jobs
    && Object.values(state.status[jobType].jobs)
      .filter((job) => job.overallStatus !== ABORTED)
      .sort(dateFieldSorter('started', 'desc'));
};

export const getSortedIntegrationsJobsForApp = (state, appId, jobType) => {
  return state.status[jobType].jobs
    && Object.values(state.status[jobType].jobs)
      .filter((job) => job.detail?.config?.appForDeploy?.id === appId && job.overallStatus !== ABORTED)
      .sort(dateFieldSorter('started', 'desc'));
};

export const getSortedFsaSetupJobsForApp = (state) => {
  const jobType = jobTypes.getFsaSetupJob(state.app.app.data);
  return state.status[jobType].jobs
    && Object.values(state.status[jobType].jobs)
      .filter((job) => job.ownerId === `${state.app.app.data.id}`)
      .sort(dateFieldSorter('started', 'desc'));
};

export const getSortedFsaJobsForApp = (state) => {
  const jobs = {
    ...state.status[jobTypes.fsaSetup]?.jobs,
    ...state.status[jobTypes.fsaSetupCloudflare]?.jobs,
    ...state.status[jobTypes.fsaDisable]?.jobs,
  };
  return Object.values(jobs)
    .filter((job) => {
      return job.ownerId === `${state.app.app.data.id}`
        && !job.detail?.deleteOnCompletion; // we don't want to see the delete job
    })
    .sort(dateFieldSorter('started', 'desc'));
};

export const getRecentPhpVersionJobForApp = (state, appId) => {
  const webconfigJobs = state.status[jobTypes.webconfig].jobs;
  const jobs = webconfigJobs && Object.values(webconfigJobs)
    .filter((job) => {
      const [type, _] = getTypeAndVersionFromJob(job);
      return ['php', 'docker-php-version'].includes(type) && appId.toString() === job.ownerId;
    })
    .sort(dateFieldSorter('started', 'desc'));

  return jobs[0] || null;
};

export const getRecentFsaSetupJobForApp = (state, appId) => {
  const fsaSetupJobs = state.status[jobTypes.fsaSetup].jobs;
  const jobs = fsaSetupJobs && Object.values(fsaSetupJobs)
    .sort(dateFieldSorter('started', 'desc'));

  return jobs[0] || null;
};

export const getNotificationForUser = (state, ownProps) => {
  const {
    type, overallStatus, TARGET,
  } = state.status.notification;

  const accountId = ownProps.account.id;

  if (!type || !overallStatus || !TARGET || ![SUCCESS, FAILURE, ACTION_REQUIRED].includes(overallStatus)) {
    return null;
  }

  const [, targetId] = TARGET.split(':');
  if (Number(targetId) !== accountId) {
    return null;
  }

  return state.status.notification;
};
