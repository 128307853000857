import colors from 'shared/styleguide/theme';

export default {
  uploadCertContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#f1f5f6',
  },
  uploadCertSubs: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    width: '100%',
    alignItems: 'center',
  },
  uploadCertActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0px 0 20px',
    justifyContent: 'center',
  },
  uploadCertPrimaryButton: {
    'width': 300,
    'backgroundColor': colors.navy20,
    'color': colors.light40,
    '&:disabled': {
      backgroundColor: colors.sea20,
    },
  },
  uploadCertPrimaryButtonDisabled: {
    width: 300,
    backgroundColor: colors.sea20,
    color: colors.light40,
  },
};
