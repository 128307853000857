import React from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import PermissionsContext from 'shared/modules/permissions/context/PermissionsContext';
import CollaboratorItem from 'customer/components/Navigation/CollabSwitcher/CollaboratorItem';
import { hashCode } from 'shared/utils';

const AccessTo = ({ history }) => {
  return (
    <PermissionsContext.Consumer>
      {
        (permissions) => (
          <Accordion component={Paper} isAdmin data-testid="team-access-to">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h4">**Accounts this Account has access to</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                permissions.isFetching
                  ? <Loading />
                  : (
                    <Grid container spacing={2}>
                      {
                        permissions.canAccess.map((c) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                            key={hashCode(`${c.sourceId}-${c.targetId}-${c.domain}`)}
                          >
                            <Box direction="column" css={{ width: '100%' }}>
                              <CollaboratorItem
                                {...c}
                                key={`${c.sourceId}-${c.targetId}`}
                                user={{}}
                                requestClosePopover={(link) => (e) => {
                                  e.preventDefault();
                                  history.push(link);
                                }}
                                mfaRequired={false}
                                hoverColor="action"
                              />
                              {
                                c.mfaRequired && (
                                  <Box padding={{ left: 'small', bottom: 'xsmall' }}>
                                    <Typography variant="caption" color="secondary">This account requires MFA</Typography>
                                  </Box>
                                )
                              }
                            </Box>
                          </Grid>
                        ))
                      }
                    </Grid>
                  )
              }

            </AccordionDetails>
          </Accordion>
        )
      }
    </PermissionsContext.Consumer>
  );
};

AccessTo.propTypes = {
  history: object.isRequired,
};

export default withRouter(AccessTo);
