import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { node, number } from 'prop-types';
import AccountContext from 'shared/modules/account/context/AccountContext';

const CDNLink = ({ children, appId, ...props }) => {
  const accountId = useContext(AccountContext).id;

  return <Link to={`/account/${accountId}/cdn${appId ? `/${appId}` : ''}`} {...props}>{children}</Link>;
};

CDNLink.propTypes = {
  appId: number,
  children: node,
};

CDNLink.displayName = 'CDNLink';

export default CDNLink;
