/* eslint-disable react/display-name */
import React from 'react';

import Empty from 'shared/styleguide/atoms/Empty';
import Card from 'shared/styleguide/atoms/Card/Card';
import SupportLink from 'shared/modules/support/Link';

import AccessControl from './AccessControl';
import CacheKeyMgmt from './CacheKeyMgmt';
import Headers from './Headers';
import Tester from './Tester';
import RateLimits from './RateLimits';
import Redirects from './Redirects';

// // eslint-disable-next-line react/prop-types
// const DummyComponent = ({ label }) => <div>Dummy Component for {label}</div>;
const FallbackComponent = ({ children }) => (
  <Card>
    <Empty css={{ width: '100%' }}>
      {children}
      <br />
      Features will be enabled once current configurations are migrated.
      <br />
      <span>
        You can <SupportLink>contact support</SupportLink> to request enabling this feature.
      </span>
    </Empty>
  </Card>
);

const baseRegex = '\/apps\/(\d+)\/ares'; // eslint-disable-line

export const routes = [
  {
    label: 'Access Control',
    to: 'access',
    match: new RegExp(`${baseRegex}\/access$`), // eslint-disable-line
    component: AccessControl,
    fallbackKey: 'access-control',
    fallback: () => (
      <FallbackComponent>
        Access Control gives you control over who can access the app.
        <br />
        You can restrict paths in the app by IP address or by password.
      </FallbackComponent>
    ),
  },
  {
    label: 'Rate Limits',
    to: 'rate-limits',
    match: new RegExp(`${baseRegex}\/rate-limits$`), // eslint-disable-line
    component: RateLimits,
    fallbackKey: 'rate-limit',
    fallback: () => (
      <FallbackComponent>
        This feature lets you disable rate limiting by IP address or token.
        <br />
        You can configure tokens used with cloud based load testing tools here.
      </FallbackComponent>
    ),
  },
  // {
  //   label: 'Bot Blocking',
  //   to: 'bot-blocking',
  //   match: new RegExp(`${baseRegex}\/bot-blocking$`), // eslint-disable-line
  //   component: () => <DummyComponent label="Bot Blocking" />,
  //   fallbackKey: 'bot',
  //   fallback: () => (
  //     <FallbackComponent>
  //       This feature lets you block automated traffic to your app.
  //       <br />
  //       You can block bots based on our recommeneded lists, by group, or by geographical location.
  //     </FallbackComponent>
  //   ),
  // },
  {
    label: 'Redirects',
    to: 'redirects',
    match: new RegExp(`${baseRegex}\/redirects$`), // eslint-disable-line
    component: Redirects,
    fallbackKey: 'redirect',
    fallback: () => (
      <FallbackComponent>
        This feature lets you configure URL redirects for your app.
      </FallbackComponent>
    ),
  },
  {
    label: 'Cache Keys',
    to: 'cache',
    match: new RegExp(`${baseRegex}\/cache$`), // eslint-disable-line
    component: CacheKeyMgmt,
    fallbackKey: 'cache',
    fallback: () => (
      <FallbackComponent>
        Cache Key Management lets you pass through URL params and bypass request caching.
      </FallbackComponent>
    ),
  },
  {
    label: 'Headers',
    to: 'headers',
    match: new RegExp(`${baseRegex}\/headers$`), // eslint-disable-line
    component: Headers,
    fallbackKey: 'header',
    fallback: () => (
      <FallbackComponent>
        This feature lets you set response headers.
      </FallbackComponent>
    ),
  },
  {
    label: 'Tester',
    to: 'tester',
    match: new RegExp(`${baseRegex}\/tester$`), // eslint-disable-line
    component: Tester,
    fallbackKey: '',
    fallback: () => (
      <FallbackComponent>
        This feature lets you test app domain URLs to view returned headers and redirects.
      </FallbackComponent>
    ),
  },
];
