export const PRESSDNS_REGEX = /^s\d+\.p\d+\.sites\.pressdns\.com$/;
export const PCDN_REGEX = /^s\d+\.pcdn\.co$/;

export const RECORD_TYPES = [
  {
    label: 'A',
    value: 'A',
    helpText: `
      IPv4 address - 
      Enter multiple addresses on separate lines.
      \n ie, 192.0.2.235
    `,
    expanding: true,
    defaultTtl: 300,
  },
  {
    label: 'CNAME',
    value: 'CNAME',
    helpText: `
      Canonical Name - 
      The domain name that you want to resolve to instead of subdomain.
      \n ie, www.example.com
    `,
    expanding: false,
    defaultTtl: (60 * 60), // seconds * minutes * hours
  },
  {
    label: 'MX',
    value: 'MX',
    expanding: true,
    helpText: `
      Mail Exchange - A priority and a domain name that specifies a mail server.
      \n ie, 10 mailserver.example.com, [priority] [mail server host name]
    `,
    defaultTtl: (60 * 60), // seconds * minutes * hours
  },
  {
    label: 'TXT',
    value: 'TXT',
    expanding: true,
    defaultTtl: 300,
    helpText: `
      A text record.
      \n ie, "Sample Text Entries" (must be enclosed in quotation marks ("))
    `,
  },
  {
    label: 'AAAA',
    value: 'AAAA',
    expanding: true,
    defaultTtl: 300,
    helpText: `
      IPv6 address. Enter multiple addresses on separate lines.
      \n 2001:0db8:85a3:0:0:8a2e:0370:7334
    `,
  },
  // {
  //   label: 'NS',
  //   value: 'NS',
  //   expanding: true,
  //   defaultTtl: 300,
  //   helpText: `
  //     Name Server - The domain name of a name server.
  //     \n ns1.amazon.com
  //   `,
  // },
  {
    label: 'PTR',
    value: 'PTR',
    expanding: false,
    defaultTtl: 300,
    helpText: `
      Pointer - The domain name that you want to return.
      \n www.example.com
    `,
  },
  // {
  //   label: 'CAA',
  //   value: 'CAA',
  // },
  {
    label: 'NAPTR',
    value: 'NAPTR',
    expanding: true,
    defaultTtl: (60 * 60), // seconds * minutes * hours
    helpText: `
      Name Pointer Authority - 
      \n [order] [preference] [flags] [services] [regexp] [replacement]
      \n 100 100 "U" "" "!^.*$!sip:info@bar.example.com!" .
    `,
  },
  {
    label: 'SPF',
    value: 'SPF',
    expanding: false,
    defaultTtl: (60 * 60), // seconds * minutes * hours
    helpText: `
      Sender Policy Framework - 
      \n ie, "v=spf1 ip4:192.168.0.1/16-all" (must be enclosed in quotation marks ("))
    `,
  },
  {
    label: 'SRV',
    value: 'SRV',
    expanding: true,
    defaultTtl: 300,
    helpText: `
      Service Locator - 
      \n [priority] [weight] [port] [server host name]
      \n 10 5 80 hostname.example.com 
    `,
  },
];
