import React from 'react';

import Rule from './components/Rule';
import OuterWrapper from './components/OuterWrapper';
import OperatorWrapper from './components/OperatorWrapper';

interface Props {
  rule: Record<string, any>;
  showOperatorColumn: boolean;
}

const ValidationItemRule = ({ rule, showOperatorColumn }: Props) => {
  if (rule?.operator && !rule.records.some((r) => r.operator)) {
    // the outer wrapper with label and table headings
    return (
      <OuterWrapper
        rule={rule}
        showOperatorColumn={showOperatorColumn}
      >
        {
          rule.records.map((subrule, index) => {
            return (
              <ValidationItemRule
                key={`${subrule.value || subrule.operator}-${index}`}
                rule={subrule}
                showOperatorColumn={showOperatorColumn}
              />
            );
          })
        }
      </OuterWrapper>
    );
  } else if (rule?.operator) {
    return (
      <OperatorWrapper rule={rule}>
        {
          rule.records.map((subrule, index) => {
            return (
              <ValidationItemRule
                key={`${subrule.value || subrule.operator}-${index}`}
                rule={subrule}
                showOperatorColumn={showOperatorColumn}
              />
            );
          })
        }
      </OperatorWrapper>
    );
  } else {
    return <Rule rule={rule} />;
  }
};

export default ValidationItemRule;
