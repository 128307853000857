import React from 'react';
import { any } from 'prop-types';

import Row from 'shared/styleguide/atoms/Row';

import PropValues from './PropValues';
import PropKeys from './PropKeys';
import { isArray } from '../helpers';

const Properties = ({ propLevels, value }) => {
  let formProps = [];
  if (!isArray(value)) {
    // match block
    formProps = Object.keys(value).map((key) => {
      return { [key]: value[key] };
    });
  } else {
    // non - match block
    formProps = value;
  }

  const items = Object.keys(formProps).map((idx) => {
    return (
      <Row
        style={{ alignItems: 'flex-start', paddingBottom: 5 }}
        key={`properties-${idx}`}
      >
        <PropKeys
          value={formProps[idx]}
          propLevels={propLevels}
        />
        <PropValues
          value={formProps[idx]}
          propLevels={propLevels}
        />
      </Row>
    );
  });

  return (
    <div>
      {items}
    </div>
  );
};

Properties.propTypes = {
  propLevels: any,
  value: any,
};

export default Properties;
