import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import { ErrorText, ColorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import colors from 'shared/styleguide/theme';

import { getValidationMessageForProperty } from 'shared/utils/validation';

export class ForgotPassword extends React.Component {
  static propTypes = {
    callForgotPassword: PropTypes.func,
    callForgotPasswordReset: PropTypes.func,
    forgotPassword: PropTypes.shape({
      status: PropTypes.string,
      statusCode: PropTypes.number,
      validation: PropTypes.object,
    }),
    validLink: PropTypes.shape({
      status: PropTypes.string,
      statusCode: PropTypes.number,
      validation: PropTypes.object,
    }),
  };

  state = {
    email: '',
  };

  componentWillUnmount() {
    this.props.callForgotPasswordReset();
  }

  handleRequest = () => this.props.callForgotPassword(this.state.email);

  render() {
    const { forgotPassword, validLink } = this.props;
    let error;
    if (forgotPassword.status === 'success') {
      return (
        <Fragment>
          <Typography variant="body1">An email has been sent to the provided address.</Typography>
          <Typography variant="body1">Please see it for further instructions.</Typography>
        </Fragment>
      );
    }

    if (forgotPassword.statusCode) {
      switch (forgotPassword.statusCode) {
        case 422:
          error = (
            <div>
              {forgotPassword.validation.body.email.messages.map((msg) => <ErrorText key={msg}>{msg}</ErrorText>)}
            </div>
          );
          break;
        default:
          error = (
            <ErrorText>An error happened when processing the request</ErrorText>
          );
      }
    }

    return (
      <Box column align="center">
        {
          validLink?.status === 'failed'
          && (
            <Fragment>
              <Box margin={{ bottom: 'small' }}>
                <ColorText color={colors.golden}>
                  {
                    validLink.statusCode === 422
                      ? (
                        getValidationMessageForProperty(validLink, 'body', 'passwordToken')
                      ) : (
                        'An error has occurred validating this link'
                      )
                  }
                </ColorText>
              </Box>
              <Typography variant="body1" gutterBottom>
                Enter your email to re-request a password reset
              </Typography>
              <Typography variant="body2">
                (password reset links are valid for 2 hours)
              </Typography>
            </Fragment>
          )
        }
        <WideTextField
          id="email"
          margin="normal"
          errorText={error}
          onChange={(e) => this.setState({ email: e.target.value })}
          FormHelperTextProps={{ component: 'span' }}
        />
        <Box margin={{ top: 'small' }}>
          <Button
            variant="contained"
            onClick={this.handleRequest}
            disabled={this.props.forgotPassword.status === 'loading' || !this.state.email || !this.state.email.match(/.+@.+/)}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPassword: state.requests.forgotPassword,
});
export default connect(mapStateToProps)(ForgotPassword);
