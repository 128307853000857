import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as ANALYTICS from '../constants';

export const getDiskUsageByMount = (accountId, data) => makeApiAction(
  // () => promise.resolve({ data: { data: cpu } }),
  ({ api }) => api().get(`/analytics/accounts/${accountId}/disk/by-mount${
    qs.stringify({
      ...data,
      /**
       * fromDate
       * toDate
       * columns
       * window
       * singleSeries
       * id
       * */
    }, { addQueryPrefix: true })
  }`),
  ANALYTICS.GET_DISK_BY_MOUNT_REQUEST,
  ANALYTICS.GET_DISK_BY_MOUNT_SUCCESS,
  ANALYTICS.GET_DISK_BY_MOUNT_FAILURE,
  { accountId },
  true,
);

export const getDimensionsByMount = (accountId, data) => makeApiAction(
  // () => promise.resolve({ data: { data: dimensions } }),
  ({ api }) => api().get(`/analytics/accounts/${accountId}/disk/by-mount/dimensions${
    qs.stringify({
      ...data,
      /**
       * fromDate
       * toDate
       * columns
       * window
       * singleSeries
       * id
       * */
    }, { addQueryPrefix: true })
  }`),
  ANALYTICS.GET_DISK_DIMENSIONS_BY_MOUNT_REQUEST,
  ANALYTICS.GET_DISK_DIMENSIONS_BY_MOUNT_SUCCESS,
  ANALYTICS.GET_DISK_DIMENSIONS_BY_MOUNT_FAILURE,
  { accountId, data },
  true,
);

export const getDiskUsageByPath = (accountId, data) => makeApiAction(
  // () => promise.resolve({ data: { data: cpu } }),
  ({ api }) => api().get(`/analytics/accounts/${accountId}/disk/by-path${
    qs.stringify({
      ...data,
      /**
       * fromDate
       * toDate
       * columns
       * window
       * singleSeries
       * type
       * id
       * */
    }, { addQueryPrefix: true })
  }`),
  ANALYTICS.GET_DISK_BY_PATH_REQUEST,
  ANALYTICS.GET_DISK_BY_PATH_SUCCESS,
  ANALYTICS.GET_DISK_BY_PATH_FAILURE,
  { accountId },
  true,
);

export const getDiskUsageByLocation = (accountId, data) => {
  const { pathType, ...params } = data;
  const paramString = qs.stringify({
    ...params,
    type: pathType || null,
  }, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => api().get(`/analytics/accounts/${accountId}/disk/by-path${paramString}`),
    ANALYTICS.GET_DISK_USAGE_BY_LOCATION_REQUEST_ACTION_ONLY,
    ANALYTICS.GET_DISK_USAGE_BY_LOCATION_SUCCESS_ACTION_ONLY,
    ANALYTICS.GET_DISK_USAGE_BY_LOCATION_FAILURE_ACTION_ONLY,
    { accountId, ...data },
    true,
  );
};

export const getDimensionsByPath = (accountId, data) => makeApiAction(
  // () => promise.resolve({ data: { data: dimensions } }),
  ({ api }) => api().get(`/analytics/accounts/${accountId}/disk/by-path/dimensions${
    qs.stringify({
      ...data,
      /**
       * fromDate
       * toDate
       * columns
       * window
       * singleSeries
       * id
       * */
    }, { addQueryPrefix: true })
  }`),
  ANALYTICS.GET_DISK_DIMENSIONS_BY_PATH_REQUEST,
  ANALYTICS.GET_DISK_DIMENSIONS_BY_PATH_SUCCESS,
  ANALYTICS.GET_DISK_DIMENSIONS_BY_PATH_FAILURE,
  { accountId, data },
  true,
);

export const resetDiskAnalytics = () => ({
  type: ANALYTICS.GET_DISK_RESET,
});
