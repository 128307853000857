import React from 'react';
import { number } from 'prop-types';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import DiskWarningBanner from '../../../components/DiskWarningBanner';

const CustomSetupMessage = ({ accountId }) => {
  return (
    <DiskWarningBanner>
      <Typography variant="h5" component="p" align="center" gutterBottom>
        Your custom configuration requires additional support to perform disk modifications.
      </Typography>
      <Box margin={{ top: 'small' }} align="center">
        <Link
          to={`/account/${accountId}/support/submit`}
        >
          <Button
            color="default"
            variant="outlined"
          >
            Contact Support
          </Button>
        </Link>
      </Box>
    </DiskWarningBanner>
  );
};

CustomSetupMessage.propTypes = {
  accountId: number,
};

export default CustomSetupMessage;
