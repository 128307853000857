import React, { Component } from 'react';
import {
  shape, func, string, array, object, bool, oneOfType, number,
} from 'prop-types';
import { connect } from 'react-redux';
import parse, { domToReact } from 'html-react-parser';
import cx from 'classnames';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getTicket, updateTicket, resetTicket } from 'shared/modules/support/redux/actions';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Loading from 'shared/styleguide/atoms/Loading';
import { ErrorText } from 'shared/styleguide/typography';
import { TextArea } from 'shared/styleguide/atoms/Input/TextArea';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import { isJsonValid } from 'shared/utils/isJsonValid';

import InfoText from 'shared/styleguide/molecules/InfoText';
import Linker from 'shared/styleguide/atoms/Links/Linker';
import { getInitials } from 'shared/utils';
import UploadAttachments from '../SubmitTicket/UploadAttachments';

import styles from '../../Support.scss';
import { mapStatusToColor } from '../../constants';

const Attachment = withStyles({
  root: {
    marginBottom: 6,
    marginRight: 6,
  },
})(Button);

export class Ticket extends Component {
  static propTypes = {
    accountId: number,
    attachments: shape({
      data: array,
      errAllMessages: array,
    }),
    getTicket: func.isRequired,
    match: shape({
      url: string,
    }),
    resetTicket: func.isRequired,
    ticket: shape({
      loading: bool,
      statusCode: number,
      data: shape({
        comments: array,
      }),
      validation: oneOfType([string, object]),
    }),
    ticketId: string,
    updateTicket: func.isRequired,
  };

  constructor(props) {
    super(props);

    const { match, ticketId } = this.props;
    const url = match.url.substring(0, match.url.indexOf(ticketId) - 1);

    this.recentTicketsUrl = url;
  }

  state = {
    editing: false,
    errors: null,
  };

  componentDidMount() {
    const { ticketId } = this.props.match.params;
    this.props.getTicket(ticketId);
  }

  componentDidUpdate(oldProps) {
    const { ticket, getTicket } = this.props;
    const { ticket: oldTicket } = oldProps;
    const { ticketId } = this.props.match.params;

    if (ticket.data?.comments?.length !== oldTicket?.data?.comments?.length) {
      this.setState({
        editing: false,
      });
      getTicket(ticketId);
    }
  }

  componentWillUnmount() {
    this.props.resetTicket();
  }

  handleUpdateTicket = (e) => {
    e.preventDefault();
    const { updateTicket, ticket } = this.props;
    const { attachments: { data } } = this.props;

    if (e.target.description.value === '') {
      this.setState({
        errors: {
          description: 'Reply must have a message body.',
        },
      });
      return;
    } else {
      this.setState({
        errors: null,
      });
    }

    let attachmentTokens = [];

    if (data.length > 0) {
      attachmentTokens = data.map(({ token }) => token);
    }

    const ticketData = {
      body: e.target.description.value,
      attachmentTokens,
    };

    updateTicket(ticket.data.id, ticketData);
  };

  isRelativeUrl = (url) => {
    const relativeUrlRegex = /^(?!www\.|mailto:|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*/;
    if (url.match(relativeUrlRegex)) {
      return true;
    }
  };

  render() {
    const { ticket, match } = this.props;
    const { editing, errors } = this.state;

    const errorMessages = ticket.validation?.body?.body?.messages.map((item) => {
      return isJsonValid(item) ? JSON.parse(item)?.base[0]?.description : item;
    });

    if (ticket.loading) {
      return (
        <Box as={Paper} padding="large">
          <Loading />
        </Box>
      );
    }

    const t = ticket.data;

    const buttonLabel = (t.status !== 'closed' && editing) ? 'Close Reply' : 'Reply';

    return (
      <div>
        <Paper>
          <Box padding="small">
            <Box key={`${t.id}`}>
              <form onSubmit={this.handleUpdateTicket}>
                <Box direction="row" justify="space-between">
                  <div>
                    <Typography id="ticketSubject" component="div" variant="h4">
                      {t.subject}
                    </Typography>
                    <Box direction="row" padding={{ top: 'xsmall' }} gap="small">
                      <Typography color="textSecondary" variant="body2" component="div">
                        #{t.id}
                      </Typography>
                      <Typography color="textSecondary" variant="body2" component="div">
                        Last Updated: {moment(t.updatedAt).format('M/D/YYYY ha')}
                      </Typography>
                    </Box>
                    <Box margin={{ top: 'small' }} direction="row">
                      {
                        t.status !== 'closed' ? (
                          <Button
                            variant={editing ? 'outlined' : 'contained'}
                            onClick={() => { this.setState({ editing: !editing }); }}
                          >
                            {buttonLabel}
                          </Button>
                        ) : <InfoText>This ticket is closed an cannot be updated.</InfoText>
                      }
                    </Box>
                  </div>
                  <div>
                    <GhostTag label={t.status} color={mapStatusToColor[t.status]} />
                  </div>
                </Box>
                {
                  editing && (
                    <div className={styles.updateRow}>
                      <TextArea
                        name="description"
                        id="description"
                        errorText={errors?.description}
                        className={cx({ [styles.error]: (errors?.description) })}
                      />
                      <Box direction="row" justify="space-between">
                        <Box>
                          <UploadAttachments />
                        </Box>
                        <Box margin={{ top: 'small' }}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            loading={ticket.status === 'loading'}
                            disabled={ticket.statusCode === 403}
                          >
                            Update Ticket
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  )
                }
                {errorMessages && errorMessages.length > 0
                  && (
                    <Box margin={{ bottom: 'small' }}>
                      <ErrorText>{errorMessages && errorMessages.length > 0
                        && (
                          <Box margin={{ bottom: 'small' }}>
                            <ErrorText>
                              <Box key={errorMessages[0]}>{`${errorMessages[0]}${errorMessages[1] ? `: ${errorMessages[1]}` : ''}`}</Box>
                            </ErrorText>
                          </Box>
                        )}
                      </ErrorText>
                    </Box>
                  )}
                {/* TODO: don't use url unless its for admins */}

                <Box padding="small">
                  {t.comments
                    && t.comments
                      .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))
                      .map((c) => (
                        <div key={c.id} className={styles.comment}>
                          {c.author && (
                            <Box direction="row" align="center" gap="xsmall">
                              <span>
                                <Avatar
                                  src={`https://www.gravatar.com/avatar/${c.author.gravatarId}?d=404&s=30`}
                                >
                                  {getInitials(c.author.name)}
                                </Avatar>
                              </span>
                              <span>{c.author.name}{c.author.userType === 'admin' && (' (Pagely)')}</span>
                            </Box>
                          )}
                          <div style={{ minWidth: 0 }} className={styles.commentBody}>
                            {parse(c.html_body, {
                              replace: ({ name, attribs, children }) => {
                                if (name === 'a' && attribs && this.isRelativeUrl(attribs.href)) {
                                  return React.createElement(React.Fragment, {}, domToReact(children, c.html_body));
                                }
                              },
                            })}
                            {c.attachments?.length > 0
                              && (
                                <div style={{ minWidth: 0 }} className={styles.commentBody}>
                                  <Box direction="row" align="center" margin={{ top: 'xsmall' }}>
                                    <AttachmentIcon />
                                    <Typography variant="h5" color="textSecondary">Attachments:</Typography>
                                  </Box>
                                  <Box direction="row" margin={{ top: 'xsmall' }} wrap="wrap">
                                    {c.attachments
                                      .map((a) => (
                                        <Attachment
                                          key={a.id}
                                          label={a.file_name}
                                          onClick={() => window.open(a.content_url, '_blank', 'noopener')}
                                          variant="outlined"
                                          color="default"
                                        />
                                      ))}
                                  </Box>
                                </div>
                              )}
                          </div>
                          <div>Posted {moment(c.created_at).format('M/D/YYYY h:mma')}</div>
                        </div>
                      ))}
                </Box>
              </form>
            </Box>
          </Box>
          <Box padding={{ left: 'small', bottom: 'small' }} justify="flex-start" direction="row">
            <Button
              component={Linker}
              variant="outlined"
              color="default"
              to={`/account/${match.params.accountID}/support/recent`}
            >
              Back to Recent Tickets
            </Button>
          </Box>
        </Paper>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    // ticket: MockTicket,
    attachments: state.support.attachments,
    ticket: state.support.ticket,
  }),
  { getTicket, updateTicket, resetTicket },
)(Ticket);
