import React, { useEffect } from 'react';
import PropTypes, { func, object, bool } from 'prop-types';
import { connect } from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';

import Box from 'shared/styleguide/atoms/Box';
import { fetchAccountTags } from 'shared/modules/account/redux/actions';

import styles from './styles';
import { sitemap } from './constants';
import MobileSidebar from './Mobile'; //eslint-disable-line
import SidebarItem from './SidebarItem';
import DisabledBanner from './DisabledBanner';

const useStyles = makeStyles(styles);

export const Sidebar = ({
  match,
  location,
  permissions,
  account,
  isMobile,
}) => {
  const classes = useStyles();
  const sidebarItems = sitemap({ match, permissions, account });

  return (
    <List
      component="nav"
      role="navigation"
      disablePadding
      className={classes.scrolling}
      css={(theme) => ({
        color: theme.color900,
        backgroundColor: theme.color000,
      })}
    >
      <Box
        column
        padding="medium"
        css={{
          gap: '6px',
        }}
      >
        {account.disabled && <DisabledBanner isExpanded />}
        {sidebarItems.map((navitem) => {
          const isActive = navitem.match.test(location.pathname);
          const currentParentId = location.pathname.match(navitem.parentId)?.[1] || '';

          return (
            <SidebarItem
              key={navitem.label}
              isMobile={isMobile}
              location={location}
              navitem={navitem}
              isActive={isActive}
              currentParentId={currentParentId}
            />
          );
        })}
      </Box>
    </List>
  );
};

Sidebar.propTypes = {
  account: object.isRequired,
  isMobile: bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.object,
};

export const Nav = (props) => {
  const { account, fetchAccountTags, tags } = props;
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    // this means the account data isn't loaded yet
    if (account.id !== 0 && tags.params?.accountId !== account.id) {
      fetchAccountTags(account.id);
    }
  }, [fetchAccountTags, account.id, tags.params]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Hidden mdUp implementation="css">
        <MobileSidebar {...props} isMobile />
      </Hidden>
    );
  }

  return (
    <div className={classes.drawer} aria-label="sidebar">
      <Sidebar {...props} isMobile={false} />
    </div>
  );
};

Nav.propTypes = {
  account: object.isRequired,
  fetchAccountTags: func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  tags: object.isRequired,
};

export default connect(
  (state) => ({ account: state.account, tags: state.tags.account }),
  {
    fetchAccountTags,
  },
)(Nav);
