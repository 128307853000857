import React, { useState, useRef, Fragment } from 'react';
import { func, number, object } from 'prop-types';
import { connect } from 'react-redux';
// import AsyncSelect from 'react-select/async';
import {
  Paper, TextField, Grid, Typography, IconButton,
} from '@material-ui/core';
import { Delete, Send } from '@material-ui/icons';

// import { themeProps } from 'shared/styleguide/atoms/Select/Select';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import CustomConfigDescription from '../../CustomConfigDescription';
import { testUrl } from '../../redux/redirects/actions';
import { fetchGeoIdentifiers } from '../../redux/accessControl/actions';
import Hops from './Hops';

// const filterList = (inputValue = '', list) => {
//   return list.filter((i) => i.label.toLowerCase().match(inputValue.toLowerCase()));
// };

export const RedirectsTester = ({
  appId, testUrl, __storybookMocks, fetchGeoIdentifiers,
}) => {
  // const [geoLocales, setGeoLocales] = useState([]);
  // const [locale, setLocale] = useState(null);
  const [headers, setHeaders] = useState([]);

  const [status, setStatus] = useState(__storybookMocks?.status || 'pristine');
  const [showAdvanced, setShowAdvanced] = useState(__storybookMocks?.showAdvanced || false);
  const [hops, setHops] = useState(__storybookMocks?.hops || []);
  const urlText = useRef();
  const errorMessage = useRef('');

  // const fetchGeoOptions = async (filter) => {
  //   if (geoLocales.length > 0) {
  //     return filterList(filter, geoLocales);
  //   }
  //   const response = await fetchGeoIdentifiers();
  //   const locales = response.data.map((locale) => {
  //     return {
  //       ...locale,
  //       value: locale.id,
  //     };
  //   });
  //   setGeoLocales(locales);
  //   return filterList(filter, locales);
  // };

  const handleTestUrl = async () => {
    setStatus('loading');
    const body = {
      url: urlText.current.value,
    };

    if (showAdvanced) {
      // can also contain countrycode and headers
      // if (locale) {
      //   body.countryCode = locale.id;
      // }
      const filteredHeaders = headers.filter(([key, value]) => Boolean(key) && Boolean(value));
      if (filteredHeaders.length > 0) {
        body.headers = Object.fromEntries(filteredHeaders);
      }
    }

    try {
      errorMessage.current = '';
      const response = await testUrl(appId, body);
      setHops(response.data);
      setStatus('success');
    } catch (err) {
      errorMessage.current = err;
      setStatus('pristine');
    }
  };

  const updateHeaders = (index, key, value) => {
    const newState = [...headers];
    newState[index] = [key, value];
    setHeaders(newState);
  };

  return (
    <Fragment>
      <CustomConfigDescription />
      <Box margin={{ bottom: 'small' }}>
        <Typography variant="h3">Tester</Typography>
      </Box>
      <Paper>
        <Box direction="row" padding="small" gap="small">
          <Box flex={1}>
            <TextField
              inputRef={urlText}
              margin="dense"
              variant="outlined"
              placeholder="URL"
              id="URL"
            />
            <Box direction="row" margin={{ top: 'small' }}>
              <TextLink
                variant="subtitle2"
                data-testid="show-advanced-headers"
                onClick={() => setShowAdvanced(!showAdvanced)}
              >
                {showAdvanced ? 'Hide' : 'Show'} Advanced Headers
              </TextLink>
            </Box>
            {
              showAdvanced && (
                <Box margin={{ top: 'small' }} data-testid="advanced-options">
                  <Grid container spacing={2}>
                    {/* disabled until geo spoofing is possible via gw2 */}
                    {/* <Grid xs={12} lg={4} item>
                      <Typography variant="caption">Geo Location</Typography>
                      <AsyncSelect
                        {...themeProps}
                        cacheOptions
                        defaultOptions
                        loadOptions={fetchGeoOptions}
                        options={geoLocales}
                        value={locale}
                        onChange={(value) => { setLocale(value); }}
                      />
                    </Grid> */}
                    <Grid xs={12} lg={8} item>
                      <div>
                        <Typography component="div" variant="caption" gutterBottom>Headers</Typography>
                        {
                          headers.map(([key, value], index) => {
                            return (
                              <Box direction="row" key={index} gap="small" margin={{ bottom: 'small' }} data-testid="header-row">
                                <TextField
                                  id="header name"
                                  placeholder="header name"
                                  value={key}
                                  onChange={(e) => updateHeaders(index, e.target.value, value)}
                                />
                                <TextField
                                  id="value"
                                  placeholder="value"
                                  value={value}
                                  onChange={(e) => updateHeaders(index, key, e.target.value)}
                                />
                                <IconButton
                                  aria-label="delete header value"
                                  onClick={() => {
                                    const newState = [...headers];
                                    newState.splice(index, 1);
                                    setHeaders(newState);
                                  }}
                                  color="default"
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                            );
                          })
                        }
                        <Button onClick={() => setHeaders([...headers, ['', '']])} color="default">Add Header</Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              )
            }
          </Box>
          <Box>
            <Box direction="row" margin={{ top: 'xsmall' }}>
              <Button
                aria-label="test url"
                variant="contained"
                color="secondary"
                startIcon={<Send />}
                onClick={handleTestUrl}
              >
                Test
              </Button>
            </Box>
          </Box>
        </Box>
        <Hops status={status} hops={hops} error={errorMessage === '' ? {} : errorMessage} />
      </Paper>
    </Fragment>
  );
};

RedirectsTester.propTypes = {
  appId: number,
  fetchGeoIdentifiers: func,
  testUrl: func.isRequired,
};

export default connect(
  (state) => ({
    appId: state.app.app.data?.id,
  }),
  {
    testUrl,
    fetchGeoIdentifiers,
  },
)(RedirectsTester);
