import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import store2 from 'store2';
import config from 'shared/config';

export interface Http extends AxiosInstance {
  all<T>(values: (T | Promise<T>)[]): Promise<T[]>;
  spread<T, R>(callback: (...args: T[]) => R): (array: T[]) => R;
}

export const getHttp = (options: AxiosRequestConfig = {}): Http => {
  const token = store2.get('atomic_token');

  const http: Partial<Http> = axios.create({
    timeout: 30000,
    baseURL: config.BASE_URL,
    headers: token ? {
      Authorization: `Bearer ${token}`,
    } : {},
    ...options,
  });
  http.spread = axios.spread;
  http.all = axios.all;

  return http as Http;
};
