import React, { useEffect } from 'react';
import {
  object, func, shape,
} from 'prop-types';

import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { getChargebeeAccount, updatePayment, updateChargebeeAddress } from 'shared/modules/billing/redux/actions';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';
import { accountPropTypes } from 'shared/modules/account/models/model';
import { PaymentCard } from './PaymentCard';
import { InvoiceAddress } from './InvoiceAddress';

const leftSideProps = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
};
const rightSideProps = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
};

export const Payment = ({
  account, getChargebeeAccount, customer, updatePayment, updateCustomer,
}) => {
  useEffect(() => {
    if (account.hasSubscription && account.billingCustomerId) {
      if (customer.status === 'pristine' || (customer.status === 'success' && customer.params.customerId !== account.billingCustomerId)) {
        getChargebeeAccount(account.billingCustomerId);
      }
    }
  }, [customer.status, customer.params, getChargebeeAccount, account.hasSubscription, account.billingCustomerId]);

  if (account.hasSubscription && (['loading', 'pristine'].includes(customer.status) || !customer.status)) {
    return (<LoadingDots />);
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item {...leftSideProps}>
          <PaymentCard customer={customer} updatePayment={updatePayment} updateCustomer={updateCustomer} />

        </Grid>
        <Grid item {...rightSideProps}>
          <InvoiceAddress customer={customer} updateCustomer={updateCustomer} />
        </Grid>
      </Grid>
    </div>
  );
};

Payment.propTypes = {
  account: shape(accountPropTypes),
  customer: object,
  getChargebeeAccount: func,
  updateCustomer: func,
  updatePayment: func,
};

export default connect(
  (state) => ({
    account: state.account,
    customer: state.billing.customer,
  }),
  {
    getChargebeeAccount,
    updateCustomer: updateChargebeeAddress,
    updatePayment,
  },
)(Payment);
