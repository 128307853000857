import React from 'react';
import { func, number, bool } from 'prop-types';
import { connect } from 'react-redux';

import { Collapse } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import { createRedirect } from '../../redux/redirects/actions';
import FormContent from './FormContent';

export const CreateRedirect = ({
  appId, createRedirect, showCreate, onSetShowCreate,
}) => {
  return (
    <Collapse in={showCreate}>
      <Box margin={{ bottom: 'medium' }}>
        <FormContent
          appId={Number(appId)}
          onCreateRedirect={createRedirect}
          mode="create"
          onSetShowCreate={onSetShowCreate}
        />
      </Box>
    </Collapse>
  );
};

CreateRedirect.propTypes = {
  appId: number,
  createRedirect: func,
  onSetShowCreate: func,
  showCreate: bool,
};

export default connect(
  null,
  {
    createRedirect,
  },
)(CreateRedirect);
