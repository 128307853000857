import React, { Fragment } from 'react';
import { object } from 'prop-types';
import _pick from 'lodash.pick';
import { Typography } from '@material-ui/core';

import tracker from 'shared/3rdparty/pageTracking';
import { populateChatInfo } from 'shared/utils/livechat';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';

const ChatCallout = ({ user, account }) => {
  const logFlightRisk = () => {
    const metadata = {
      ..._pick(account, ['companyName', 'id', 'billingPlanName', 'billingPlanId']),
      userName: user.name,
      userEmail: user.email,
    };
    tracker.push({
      event: 'atomic_potential_flight_risk',
      ...metadata,
    });
  };

  return (
    <Fragment>
      <Box>
        <Typography variant="body1" paragraph>
          We <strong>care</strong> about your success, even if Pagely isn’t for you. We genuinely ask for a moment of your time
          to discuss your transition plan, and will gladly assist even if you’re leaving Pagely.
        </Typography>

      </Box>
      <div>
        <Typography variant="body1" paragraph>
          <span style={{ marginRight: 8 }}>Chat with us right now:</span>
          <Button
            variant="outlined"
            onMouseEnter={logFlightRisk}
            onClick={() => {
              populateChatInfo({ user: user?.data, account }, true);
            }}
          >
            Start a conversation
          </Button>
        </Typography>
      </div>
    </Fragment>
  );
};

ChatCallout.propTypes = {
  account: object.isRequired,
  user: object.isRequired,
};

export default ChatCallout;
