import React from 'react';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleChecked from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { localizeCurrency } from 'shared/utils';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';
import {
  useGetSubscriptionPlansAndAddonCatalogQuery,
} from '../../query';
import { planIdToName } from './constants';

const PlanOptionButton = ({
  planName,
  bandwidth,
  cdn,
  planCost,
  onClick,
  selected = false,
  isCurrentPlan = false,
  frequency,
}: {
  planName: string;
  bandwidth: string;
  cdn: string;
  planCost: number;
  selected?: boolean;
  isCurrentPlan?: boolean;
  frequency: string;
  onClick(): void;
}): JSX.Element => {
  return (
    <button
      data-testid="plan-option-button"
      css={(theme) => ({
        'border': `1px solid ${selected ? theme.palette.secondary.main : theme.color400}`,
        'backgroundColor': `${theme.color100}`,
        'borderRadius': `${theme.borderRadius}`,
        'display': 'flex',
        'padding': remMapper('small'),
        '&:hover': {
          border: `1px solid ${theme.palette.secondary.main}`,
          outline: 'none',
          cursor: 'pointer',
        },
        'textAlign': 'left',
        'width': '100%',
      })}
      onClick={onClick}
      type="button"
    >
      <div css={{
        flex: '0.4 0 10px',
      }}
      >
        <Checkbox
          css={{
            'padding': 0,
            'marginTop': -3,
          }}
          icon={<CircleUnchecked />}
          checkedIcon={<CircleChecked />}
          checked={isCurrentPlan}
        />
      </div>
      <div css={{
        flex: 3,
      }}
      >
        <strong
          css={(theme) => ({
            color: theme.color900,
          })}
        >{planName}
        </strong> <br />
        <span css={(theme) => ({ color: theme.color600 })}>
          {bandwidth} <br />
          {cdn}
        </span>
      </div>
      <div css={{
        flex: 2,
        textAlign: 'right',
      }}
      >
        <strong
          css={(theme) => ({
            color: theme.color900,
          })}
        >{`${localizeCurrency(planCost, { maximumFractionDigits: 0 })} USD`}
        </strong><br />
        <span css={(theme) => ({ color: theme.color600 })}>
          <i>
            {frequency}
          </i>
        </span>
      </div>
    </button>
  );
};

export const PlanOptions = (
  {
    currentPlanPrice,
    currentPlanId,
    currentBandwidth,
    currentCDN,
    currentFrequency,
    selectedPlanId,
    setSelectedPlanId,
  }: {
    currentPlanPrice: number;
    currentFrequency: string;
    currentPlanId: string;
    currentBandwidth: number;
    currentCDN: number;
    selectedPlanId: string;
    setSelectedPlanId: (index: string) => void;
  },
): JSX.Element => {
  const { data: catalog, isSuccess: catalogObtained } = useGetSubscriptionPlansAndAddonCatalogQuery(null);
  if (!catalogObtained) {
    return <LoadingDots />;
  }
  const formatPlanData = (plan): { name: string; price: number; bandwidth: string; cdn: string; frequency: string } => ({
    name: plan.name,
    price: plan[`${currentFrequency}Price`].price / 100,
    bandwidth: `${plan.addons['included-bandwidth'].quantity} ${plan.addons['included-bandwidth'].unit} Bandwidth`,
    cdn: `${plan.addons['included-cdn'].quantity} ${plan.addons['included-cdn'].unit} CDN`,
    frequency: currentFrequency,
  });
  let catalogData: Array<[string, any]> = Object.entries(catalog.plans).map(([planId, plan]) => [planId, formatPlanData(plan)]);
  const currentPlanIsNotACatalogPlan = !(currentPlanId in catalog.plans);
  if (currentPlanIsNotACatalogPlan) {
    catalogData = [[currentPlanId, {
      name: planIdToName[currentPlanId],
      price: currentPlanPrice,
      bandwidth: `${currentBandwidth} GB Bandwidth`,
      cdn: `${currentCDN} GB CDN`,
      frequency: currentFrequency,
    }]];
  }

  const handlePlanSelect = (planId: string) => {
    if (planId === selectedPlanId) {
      setSelectedPlanId(null);
    } else {
      setSelectedPlanId(planId);
    }
  };

  return (
    <div
      css={{
        '& > button': {
          marginBottom: remMapper('xxsmall'),
        },
      }}
      data-testid="plan-options"
    >
      {catalogData.map(([planId, plan]) => {
        return (
          <PlanOptionButton
            key={planId}
            planName={plan.name}
            planCost={plan.price}
            bandwidth={plan.bandwidth}
            cdn={plan.cdn}
            selected={planId === selectedPlanId}
            isCurrentPlan={currentPlanId === planId}
            onClick={() => handlePlanSelect(planId)}
            frequency={plan.frequency}
          />
        );
      })}
    </div>
  );
};
