import {
  useEffect, useRef, useReducer,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  createAccessControlList, deleteAccessControlList, setAccessControlList,
} from '../../../redux/accessControl/actions';

import { reducer } from '../reducer';
import { labeledUSLocale } from '../constants';
import { validate } from '../utils/validation';

const initialFormState = {
  initialValues: null,
  mode: null,
  formRef: null,
  expanded: false,
};

function useForm(appId, locales, mode, onSetWizardOpen, initialValues, existingRules, mocks) {
  let formRef = useRef();
  if (mocks?.formRef) {
    formRef = mocks.formRef;
  }

  let [state, dispatchReducer] = useReducer(reducer, { ...initialFormState, formRef });
  if (mocks?.useReducer) {
    [state, dispatchReducer] = mocks.useReducer;
  }

  let dispatch = useDispatch();
  if (mocks?.useDispatch) {
    dispatch = mocks.useDispatch;
  }

  const initialValuesString = JSON.stringify(initialValues);

  useEffect(() => {
    dispatchReducer({
      type: 'initialize',
      initialValues,
      mode,
      formRef,
      locales,
    });
  }, [initialValuesString]);

  const submitForm = () => {
    dispatchReducer({
      type: 'update',
      errors: null,
    });

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleChange = (field, value) => {
    if (formRef.current) {
      formRef.current.setValues({
        ...formRef.current.values,
        [field]: value,
      });
    }

    dispatchReducer({
      type: 'update',
      errors: null,
      changed: true,
    });
  };

  const handleChangeGeoInverse = (geoInverse, geoCountries) => {
    if (formRef.current) {
      formRef.current.setValues({
        ...formRef.current.values,
        geoInverse,
        geoCountries,
      });
    }

    dispatchReducer({
      type: 'update',
      errors: null,
      changed: true,
    });
  };

  const handleChangePassword = (password) => {
    if (formRef.current) {
      formRef.current.setValues({
        ...formRef.current.values,
        authPassword: password,
        hasChangedPassword: !!password,
      });
    }

    dispatchReducer({
      type: 'update',
      errors: null,
      changed: true,
    });
  };

  const handleChangeUsername = (username) => {
    if (formRef.current) {
      formRef.current.setValues({
        ...formRef.current.values,
        authUsername: username,
        hasChangedUsername: !!username,
      });
    }

    dispatchReducer({
      type: 'update',
      errors: null,
      changed: true,
    });
  };

  const setExpanded = (value) => {
    dispatchReducer({
      type: 'update',
      expanded: value,
    });

    if (!value) {
      dispatchReducer({ type: 'reset' });
    }
  };

  const deleteRule = async () => {
    dispatchReducer({
      type: 'status',
      value: {
        delete: 'loading',
      },
    });

    if (!formRef.current) {
      return;
    }

    const { values } = formRef.current;

    const body = {
      match: values.match,
      path: values.path,
    };

    try {
      await dispatch(deleteAccessControlList(appId, values.idKey, body));

      dispatchReducer({ type: 'reset' });
    } catch (error) {
      dispatchReducer({
        type: 'apiError',
        error,
      });
    }
  };

  const updateGeoCountries = (values, body) => {
    if (values.geo && body.geoInverse && !body.geoCountries.find((c) => c === 'US')) {
      // always allow US
      body.geoCountries.push('US');
      // add labeled US locale to UI
      formRef.current.setValues({
        ...formRef.current.values,
        geoCountries: [...values.geoCountries, labeledUSLocale],
      });
    }

    return body.geoCountries;
  };

  const submit = async (values, actions) => {
    dispatchReducer({
      type: 'update',
      errors: null,
    });

    const [body, errors] = validate(values, mode, existingRules);

    if (errors) {
      return dispatchReducer({
        type: 'update',
        errors,
      });
    }

    body.geoCountries = updateGeoCountries(values, body);

    const idKey = values.idKey || [body.match, body.path, appId].join(':');

    dispatchReducer({
      type: 'status',
      value: {
        save: 'loading',
      },
    });

    try {
      const saveMethod = mode === 'create' ? createAccessControlList : setAccessControlList;

      await dispatch(saveMethod(appId, idKey, body));

      dispatchReducer({
        type: 'status',
        value: {
          save: 'success',
        },
      });

      setTimeout(() => {
        if (mode === 'create') {
          onSetWizardOpen(false);
        }

        dispatchReducer({
          type: 'update',
          expanded: false,
          changed: false,
          status: {
            save: 'pristine',
            delete: 'pristine',
          },
        });
      }, 3000);
    } catch (error) {
      dispatchReducer({
        type: 'apiError',
        error,
      });
    }
  };

  const methods = {
    deleteRule,
    submitForm,
    submit,
    handleChange,
    handleChangeGeoInverse,
    handleChangePassword,
    handleChangeUsername,
    setExpanded,
    updateGeoCountries,
  };

  return { ...state, ...methods };
}

export default useForm;
