import React from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import Select from 'shared/styleguide/atoms/Select/Select';
import NewButton from 'shared/styleguide/atoms/Buttons/NewButton';

import {
  addProperty, deleteRuleBlock,
} from '../redux/actions';

import Properties from './Properties';

import { getAvailableRuleBlocksOptions, getConfigType } from '../helpers';

const BlockRules = ({
  state, propLevels, value, addProperty, deleteRuleBlock, schema,
}) => {
  const blockName = Object.keys(value).length > 0 ? Object.keys(value)[0] : null;
  const propLevelsNew = `${propLevels}.${blockName}`;
  const configType = getConfigType(state.config);

  const createBlockRulesItems = () => {
    const blockRulesItems = Object.keys(value[blockName]).map((blockRuleIdx) => {
      // blockRuleIdx: int of block (eg. match block 1,2,3,etc)
      // only update proplevels on delete button and select
      const propLevelsUpdated = `${propLevelsNew}.${blockRuleIdx}`;
      const propValue = value[blockName][blockRuleIdx];
      const optsAvailable = getAvailableRuleBlocksOptions(value, blockName, blockRuleIdx, propLevelsNew, schema, configType);
      const optsAvailableSelect = optsAvailable.map(((item) => ({
        value: item,
        label: item,
      })));
      return (
        <div
          style={{
            borderBottom: '1px solid #DBDBDB',
            padding: 20,
          }}
          key={`blockRule-${blockRuleIdx}`}
        >
          <div style={{ maxWidth: 200, marginBottom: 10 }}>
            <Select
              onChange={(e) => {
                if (e.value !== null) {
                  addProperty(propLevelsUpdated, e.value, schema, configType);
                }
              }}
              options={optsAvailableSelect}
              placeholder="Add Property..."
              value="Add Property..."
            />
          </div>
          <Properties
            value={propValue}
            propLevels={propLevelsUpdated}
          />
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            <NewButton
              color="error"
              variant="outlined"
              name="deleteBlock"
              onClick={() => deleteRuleBlock(propLevelsNew, blockRuleIdx)}
            >
              Delete block
            </NewButton>
          </div>
        </div>
      );
    });
    return blockRulesItems;
  };

  return (
    <div>
      {createBlockRulesItems()}
    </div>
  );
};

BlockRules.propTypes = {
  addProperty: any,
  deleteRuleBlock: any,
  propLevels: any,
  schema: any,
  state: any,
  value: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, {
  addProperty, deleteRuleBlock,
})(BlockRules);
