import React from 'react';
import { string } from 'prop-types';

import {
  Typography,
} from '@material-ui/core';

import Success from 'shared/styleguide/atoms/Success';
import Box from 'shared/styleguide/atoms/Box';
import Card from 'shared/styleguide/atoms/Card/Card';
import WordPressIcon from 'shared/styleguide/Icons/SVGs/WordPress';

import styles from 'shared/modules/app/components/App.scss';

const CredentialsNewApp = ({ appName }) => {
  return (
    <Card id="newAppCreated" column className={styles.credentialsSections}>
      <Box align="center" direction="column" justify="center" style={{ width: '100%' }}>
        <Success
          classes={{
            successStateContent: styles.credentialsSuccess,
          }}
          primaryMessage={`Your New App ${appName} Has Been Created!`}
        />
      </Box>
      <Typography align="center" variant="h5" style={{ marginBottom: 7 }}>
        Please review your app login information below.
      </Typography>
      <Typography align="center" variant="h5">
        You can access this information again by clicking the <span className={styles.credentialsWPIcon}><WordPressIcon /></span> icon in the App Dashboard.
      </Typography>
    </Card>
  );
};

CredentialsNewApp.propTypes = {
  appName: string,
};

export default CredentialsNewApp;
