import type { AnyAction as ReduxAction } from 'redux';

export type Status = 'pristine' | 'success' | 'loading' | 'failed';

export interface Params {
  accountId?: string | number;
  appId?: string | number;
  [key: string]: any;
}

export interface Action<D = any> extends ReduxAction {
  data?: D;
  params?: Params;
  response?: any;
}

export interface ReduxFormAction extends Action {
  reduxFormId: string;
}

export const isReduxFormAction = (obj: Record<string, any>): obj is ReduxFormAction => {
  return typeof obj.reduxFormId !== 'undefined' || Boolean(obj?.params?.reduxFormId);
};
