import React, { Fragment } from 'react';
import {
  number, array, bool,
} from 'prop-types';

import {
  Typography, Breadcrumbs, Link,
} from '@material-ui/core';

import Layout from 'shared/styleguide/molecules/Layout';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';
import * as diskPropTypes from 'shared/modules/analytics/models/propTypes';

import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import { filterOutCustomMounts } from '../diskUtils';

import DiskPieByServer from '../Graphs/DiskPieByServer';
import DiskLineByVolume from '../Graphs/DiskLineByVolume';
import PlanSummary from './PlanSummary';

const DiskSummary = (props) => {
  const {
    isUpgradable,
    mounts,
    accountId,
    mountUsage,
    hasPermissionToChangeDisk,
  } = props;
  if (mountUsage.status === 'success' && mountUsage.data.length === 0) {
    return (
      <Box margin={{ top: 'large' }} style={{ width: '100%' }}>
        <Empty>No Disk data available.</Empty>
      </Box>
    );
  }

  const filteredMounts = filterOutCustomMounts(mounts);

  return (
    <Layout title="Disk">
      <ErrorBoundary>
        {
          hasPermissionToChangeDisk
            ? (
              <PlanSummary
                {...props}
              />
            ) : (
              <Box as={Paper} padding="medium" margin={{ bottom: 'medium' }}>
                <Box column>
                  <Typography variant="body1" color="textSecondary">
                    <strong>NOTE: </strong>Please contact the account owner if you would like to perform a disk modification.
                  </Typography>
                </Box>
              </Box>
            )
        }
      </ErrorBoundary>
      <Box margin={{ bottom: 'small' }}>
        <Typography variant="h2" gutterBottom>Disk Usage Overview</Typography>
        <Typography color="textSecondary">
          Click graphs to see more details in&nbsp;
          <TextLink to={`/account/${accountId}/analytics`}>
            PressFormance
          </TextLink>.
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Usage data is updated every 6 hours.
        </Typography>
      </Box>
      {
        Object.values(filteredMounts)
          .sort((a, b) => {
            return a.petname >= b.petname ? 1 : -1;
          })
          .map((server) => {
            return (
              <DiskPieByServer
                key={`${server.petname}-apps`}
                accountId={accountId}
                serverName={server.petname}
                multiServer={Object.keys(filteredMounts).length > 1}
              />
            );
          })
      }
      {
        Boolean(mountUsage.data?.series.length) && (
          <DiskLineByVolume
            accountId={accountId}
            diskUsage={mountUsage}
            mounts={mounts}
          />
        )
      }
      {
        !isUpgradable
        && (
          <Box as={Paper} padding="medium" margin={{ bottom: 'medium' }}>
            <Typography variant="h5" component="p" color="textSecondary" gutterBottom><strong>NOTE:</strong></Typography>
            <Box>
              {
                !isUpgradable && 'This account appears to have a custom configuration, data displayed may not be accurate. '
              }
            </Box>
          </Box>
        )
      }
    </Layout>
  );
};

DiskSummary.propTypes = {
  accountId: number,
  hasPermissionToChangeDisk: bool,
  isUpgradable: bool,
  mounts: array,
  mountUsage: diskPropTypes.usage,
};

export default DiskSummary;
