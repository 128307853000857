import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as SERVERS from './constants';

export const fetchServerTagsForAccount = (accountId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/servers/tags-by-account/${accountId}`),
  SERVERS.SERVER_TAGS_REQUEST,
  SERVERS.SERVER_TAGS_SUCCESS,
  SERVERS.SERVER_TAGS_FAILURE,
  { accountId },
);

export const getServerInfo = (accountId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/servers/by-account/${accountId}`),
  SERVERS.SERVER_META_REQUEST,
  SERVERS.SERVER_META_SUCCESS,
  SERVERS.SERVER_META_FAILURE,
  { accountId },
);

export const fetchMounts = (accountId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/accounts/${accountId}/mounts`),
  SERVERS.SERVER_MOUNTS_REQUEST,
  SERVERS.SERVER_MOUNTS_SUCCESS,
  SERVERS.SERVER_MOUNTS_FAILURE,
  { accountId },
);

export const getAppGatewayConfig = (appId, type, options = {}) => {
  const params = qs.stringify(options, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => api().get(`/serverconfig/gateway-config/app/${appId}/${type}${params}`, options),
    SERVERS.APP_GET_CONFIG_REQUEST,
    SERVERS.APP_GET_CONFIG_SUCCESS,
    SERVERS.APP_GET_CONFIG_FAILURE,
    { appId, requestType: type },
    true,
  );
};

export const deleteAppGatewayConfig = (appId, type, options = {}) => {
  const params = qs.stringify(options, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => {
      return (api().delete(`/serverconfig/gateway-config/app/${appId}/${type}${params}`, options)
        .then(() => {
          return api().post(`/serverconfig/apps/${appId}/push`);
        })
        // TODO: unhandled rejection
      );
    },
    SERVERS.APP_DELETE_CONFIG_REQUEST,
    SERVERS.APP_DELETE_CONFIG_SUCCESS,
    SERVERS.APP_DELETE_CONFIG_FAILURE,
    { appId, requestType: type },
  );
};

export const setAppGatewayConfig = (appId, type, options = {}) => makeApiAction(
  ({ api }) => {
    return (api().put(`/serverconfig/gateway-config/app/${appId}/${type}`, options)
      .then(() => {
        return api().post(`/serverconfig/apps/${appId}/push`);
      })
      // TODO: unhandled rejection
    );
  },
  SERVERS.APP_UPDATE_CONFIG_REQUEST,
  SERVERS.APP_UPDATE_CONFIG_SUCCESS,
  SERVERS.APP_UPDATE_CONFIG_FAILURE,
  { appId, requestType: type },
  true,
);

export const fetchAllPhpVersions = (accountId) => (dispatch, getState, { history, api }) => {
  return api().get(`/serverconfig/metadata/php-versions?accountId=${accountId}`)
    .then((response) => {
      dispatch({
        type: 'FETCH_PHP_VERSIONS_SUCCESS',
        data: response.data,
      });

      return response.data;
    })
    .catch((err) => {
      dispatch({
        type: 'FETCH_PHP_VERSIONS_FAILURE',
        error: err,
      });
    });
};

export const reloadAppByAppId = (appId) => makeApiAction(
  ({ api }) => api().post(`/serverconfig/ares/deploy/${appId}`),
  SERVERS.ARES_POOL_RELOAD_REQUEST,
  SERVERS.ARES_POOL_RELOAD_SUCCESS,
  SERVERS.ARES_POOL_RELOAD_FAILURE,
  { appId },
  true, // return promise
);
