/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ObjectInspector from 'react-inspector';

import {
  BottomNavigation, BottomNavigationAction as BottomNavigationItem, withStyles, Typography,
} from '@material-ui/core';
import ActionCode from '@material-ui/icons/Code';
import HardwareDeviceHub from '@material-ui/icons/DeviceHub';

import Box from 'shared/styleguide/atoms/Box';
import MDView from 'shared/styleguide/atoms/Markdown/MDView';

import {
  TYPE_CREDENTIALS,
  TYPE_EVENT,
  TYPE_ISSUE,
  TYPE_ADMIN,
  TYPE_CONTACT,
  TYPE_CONFIG,
  TYPE_REPORT,
  TYPE_SYSTEM,
  TYPE_PROMISE,
  TYPE_MIGRATION,
  TYPE_SHARED,
} from 'admin/modules/notes/constants';
import KeyValView from './KeyValView';

const styles = ({
  showJson: {
    margin: 0,
  },
  paper: {
    padding: 10,
  },
});

const NoteView = (props) => {
  const [showJson, setShowJson] = useState(false);

  const renderNote = (note) => {
    let data;
    const { classes } = props;

    switch (props.noteType) {
      case TYPE_ADMIN:
      case TYPE_ISSUE:
      case TYPE_EVENT:
      case TYPE_PROMISE:
        return (
          <Box padding="small">
            <MDView value={props.note} />
          </Box>
        );
      case TYPE_CONFIG:
        switch (props.noteContentType) {
          case 'application/json':
            try {
              data = JSON.parse(props.note);
            } catch (e) {
              return (
                <Typography variant="h3">Invalid JSON</Typography>
              );
            }

            if (showJson) {
              return (
                <div>
                  <pre className={classes.showJson}>{props.note}</pre>
                  <BottomNavigation>
                    <BottomNavigationItem
                      label="Show Heirarchy"
                      icon={<HardwareDeviceHub />}
                      onClick={() => setShowJson(false)}
                    />
                  </BottomNavigation>
                </div>
              );
            }

            return (
              <div>
                <ObjectInspector
                  data={data}
                  expandPaths={['$', '$.*']}
                />
                <BottomNavigation>
                  <BottomNavigationItem
                    label="Show Raw JSON"
                    icon={<ActionCode />}
                    onClick={() => setShowJson(true)}
                  />
                </BottomNavigation>
              </div>
            );
          default:
            return (
              <MDView className={classes.paper} value={props.note} />
            );
        }
      case TYPE_SHARED:
        return (
          <Box padding="small">
            {props.note}
          </Box>
        );
      case TYPE_CONTACT:
      case TYPE_MIGRATION:
      case TYPE_CREDENTIALS:
        try {
          data = JSON.parse(props.note);
        } catch (e) {
          return (
            <h3>Invalid JSON</h3>
          );
        }
        return (
          <KeyValView data={data} />
        );
      case TYPE_SYSTEM:
      case TYPE_REPORT:
      default:
        return (
          <Box padding="small">
            <MDView value={props.note} />
          </Box>
        );
    }
  };

  return (
    <Box style={{ overflow: 'hidden' }} flex={1}>
      <Box margin={{ left: 'small', right: 'small' }}>
        <Typography color="textPrimary" variant="h4" gutterBottom>{props.title}</Typography>
        <div>
          <div>
            <Typography component="span" variant="body2" color="textSecondary">From: </Typography>
            <Typography component="span" variant="body1" color="textPrimary">{props.authorName}</Typography>
          </div>
          <div>
            <Typography component="span" variant="body2" color="textSecondary">Account: </Typography>
            <Typography component="span" variant="body1" color="textPrimary">{props.accountName} ({props.accountId})</Typography>
          </div>
        </div>
      </Box>
      <Box flex={1} css={{ overflow: 'auto' }} padding="small">
        {renderNote(props.note)}
      </Box>
    </Box>
  );
};

const ThemedNoteView = withStyles(styles)(NoteView);
// ThemedNoteView.propTypes = propTypes;

export default ThemedNoteView;
