/* global dataLayer */
import logger from './logger';

/**
 * Our current analytics tracking is GTM, which uses "dataLayer"
 */

let client = null;

if (!client) {
  if (process.env.NODE_ENV === 'test' || process.env.STORYBOOK_RUNNING) {
    // set up the mock client
    logger.log('ℹ️ loading mock pageTracking');
    client = {
      push: () => { },
    };
  } else if (dataLayer) {
    client = dataLayer;
  }
}

export default {
  client,
  push: (...args) => {
    client.push(...args);
  },
};
