import React from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

import ValidatingTextFileSelect from 'shared/styleguide/molecules/ValidatingTextFileSelect/ValidatingTextFileSelect';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box/Box';
import { importExistingKeyAndCert, reset } from 'shared/modules/ssl/redux/cert/actions';
import { consolidateErrors } from 'shared/utils/validation';
import { keyValidate, certValidate } from 'shared/modules/ssl/certValidator';
import { jssStyles } from './styles';

export class Import extends React.Component {
  static propTypes = {
    cert: PropTypes.object,
    classes: PropTypes.object.isRequired,
    doing: PropTypes.bool.isRequired,
    done: PropTypes.bool.isRequired,
    goToCertDomainConfig: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    importKeyAndCert: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
  };

  state = {
    cert: null,
    key: null,
    certValidated: null,
    keyValidated: null,
  };

  componentDidMount() {
    this.props.setTitle('Import Existing Private Key & Certificate');
  }

  componentWillUnmount() {
    this.props.reset();
  }

  onCertChange = (text, certValidated) => {
    this.setState({
      cert: text,
      certValidated,
    });
  };

  onKeyChange = (text, keyValidated) => {
    this.setState({
      key: text,
      keyValidated,
    });
  };

  handleContinue = () => {
    const { history, match: { params: { accountID } }, cert } = this.props;
    history.push(`/account/${accountID}/ssl/cert/${cert.id}/link`);
  };

  handleReset = () => {
    this.setState({
      cert: null,
      key: null,
      certValidated: null,
      keyValidated: null,
    });
    this.props.reset();
  };

  handleSubmit = () => {
    this.props.importKeyAndCert(this.state.key, this.state.cert, this.props.match.params.accountID);
  };

  render() {
    const {
      classes, doing, done, cert,
    } = this.props;
    const serverError = cert.requestErr ? consolidateErrors({ response: cert.validation }) : null;

    if (done) {
      return (
        <div className={classes.root}>
          <div className={classes.importCertFooter}>
            <Box margin="medium">
              <Typography variant="h3">Certificate Imported!</Typography>
            </Box>
            <Divider
              style={{
                color: '#d8e5ef',
                width: '100%',
              }}
            />
            <div className={classes.importCertActions}>
              <Button
                color="secondary"
                onClick={this.handleReset}
              >
                Import Another
              </Button>
              <span key="span">&nbsp;&nbsp;</span>
              <Button
                color="default"
                onClick={this.handleContinue}
              >
                Link Certificate to Domains
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <ValidatingTextFileSelect
          fileRequirements=".crt, .cert, .txt, .pem"
          headerText="Private Key"
          onChange={this.onKeyChange}
          doing={doing}
          done={done}
          validator={keyValidate}
          serverValidationMessage={serverError}
        />
        <ValidatingTextFileSelect
          fileRequirements=".crt, .cert, .txt, .pem"
          headerText="Certificate"
          onChange={this.onCertChange}
          doing={doing}
          done={done}
          validator={certValidate}
          serverValidationMessage={serverError}
        />
        <div className={classes.importCertFooter}>
          <Divider
            style={{
              color: '#d8e5ef',
              width: '100%',
            }}
          />
          <div className={classes.importCertActions}>
            <Button
              color="secondary"
              variant="contained"
              key="submit"
              css={{ width: 150 }}
              onClick={this.handleSubmit}
              disabled={!this.state.keyValidated || !this.state.certValidated || doing || done}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const ModalWrapper = (props) => {
  const handleClose = () => {
    const { history, match: { params: { accountID } } } = props;
    history.push(`/account/${accountID}/ssl`);
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
      <div>
        <DialogTitle>
          Import Certificate
        </DialogTitle>
        <DialogContent>
          <Import {...props} />
        </DialogContent>
      </div>
    </Dialog>
  );
};

ModalWrapper.propTypes = {
  history: object,
  match: object,
};

const mapStateToProps = (state) => ({
  cert: state.cert,
  doing: state.cert.isFetching,
  done: !!state.cert.id,
});

const mapDispatchToProps = {
  importKeyAndCert: importExistingKeyAndCert,
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(jssStyles)(ModalWrapper));
