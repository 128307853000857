import React, { Component, Fragment } from 'react';
import {
  object, func, any,
} from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Box from 'shared/styleguide/atoms/Box';
import logger from 'shared/3rdparty/logger';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Success from 'shared/styleguide/atoms/Success';
import { Checkbox } from 'shared/styleguide/atoms/Switches';
import { deleteAccountAsAdmin } from 'shared/modules/account/redux/actions';

export class AdminCancelAccount extends Component {
  static propTypes = {
    account: object.isRequired,
    accountId: any.isRequired,
    deleteAccountSettings: func.isRequired,
    user: object.isRequired,
  };

  handleSubmit = async (currentValues, actions) => {
    const {
      deleteAccountSettings, user, accountId,
    } = this.props;
    let canSubmit = true;

    if (currentValues.reason === '') {
      actions.setFieldError('reason', 'Please provide a reason for canceling');
      canSubmit = false;
    }

    if (currentValues.confirm !== 'CONFIRM') {
      actions.setFieldError('confirm', 'Please type "CONFIRM"');
      canSubmit = false;
    }

    if (!canSubmit) {
      return;
    }

    // add in name
    const data = {
      ...currentValues,
      name: user.name,
    };

    actions.setStatus('loading');
    try {
      await deleteAccountSettings(accountId, data);

      actions.setStatus('success');

      setTimeout(() => {
        actions.setStatus('initial');
      }, 3000);

      actions.resetForm();
    } catch (err) {
      if (err.response.status === 422) {
        Object.keys(err.response.data.body).forEach((field) => {
          actions.setFieldError(field, err.response.data.body?.[field].messages.join('; '));
        });
      }
      actions.setStatus('failed');

      logger.error(err, { context: 'failed to delete account', metaData: { accountId, data } });
    }
  };

  render() {
    return (
      <Fragment>
        <Box>
          <Typography variant="body1" gutterBottom>
            <strong>
              NOTE: Customers on Chargebee must be canceled through Chargebee in the future.
            </strong>
          </Typography>
          <Formik
            initialStatus="initial"
            initialValues={{
              confirm: '',
              sendEmail: false,
              refundType: '',
              reason: '',
            }}
            onSubmit={this.handleSubmit}
          >
            {({
              handleSubmit, handleChange, values, errors, status, setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {
                  status === 'success' ? (
                    <Success
                      primaryMessage="Account Canceled"
                    />
                  ) : (
                    <Fragment>
                      <Box direction="row" gap="medium">
                        <Box padding={{ top: 'xsmall' }} direction="row" align="center" gap="xsmall">
                          <Box style={{ minWidth: 150 }}>
                            <FormControl variant="outlined" error={Boolean(errors.refundType)}>
                              <InputLabel
                                shrink
                                disableAnimation
                                id="demo-simple-select-filled-label"
                              >Refund Type
                              </InputLabel>
                              <Select
                                autoWidth
                                labelId="demo-simple-select-filled-label"
                                id="refundType"
                                value={values.refundType}
                                onChange={(e) => { setFieldValue('refundType', e.target.value, false); }}
                              >
                                <MenuItem value="none">
                                  <em>No Refund</em>
                                </MenuItem>
                                <MenuItem value="partial">Partial</MenuItem>
                                <MenuItem value="full">Full</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                        </Box>
                        <Box padding={{ top: 'medium', bottom: 'medium' }}>
                          <Checkbox
                            checked={Boolean(values.sendEmail)}
                            onCheck={(e, checked) => setFieldValue('sendEmail', checked, false)}
                            label="Send Cancelation Email to customer"
                          />
                        </Box>
                      </Box>
                      <Box>
                        <TextField
                          id="cancellation-reason"
                          name="reason"
                          variant="outlined"
                          maxRows={3}
                          rows={3}
                          fullWidth
                          multiline
                          spellCheck="false"
                          value={values.reason}
                          errorText={errors.reason}
                          onChange={(e) => { setFieldValue('reason', e.target.value, false); }}
                          label="Reason to cancel:"
                          required
                        />
                      </Box>
                      <Box padding={{ top: 'medium', bottom: 'medium' }}>
                        <Typography variant="body1" color="error" paragraph gutterBottom>
                          The Customer understands that we are cancelling their Pagely account, and they understand all <strong>their site data will be deleted</strong> from this service and they have <strong>downloaded all relevant data backups.</strong>
                        </Typography>
                        <Typography variant="body1" color="error" paragraph gutterBottom>
                          The Admin understands that the billing subscription will be closed <strong>immmediately</strong>, all customer data will be deleted in <strong>30 days</strong> and <strong>this is not reversible.</strong>
                        </Typography>
                        <Box style={{ maxWidth: 300, alignSelf: 'center' }} padding={{ top: 'medium' }}>
                          <Typography variant="body2" color="textPrimary" align="center">
                            Type &quot;CONFIRM&quot; below to confirm cancelling this account.
                          </Typography>
                          <TextField
                            name="confirm"
                            helperText="CONFIRM"
                            autoComplete="off"
                            margin="normal"
                            onChange={handleChange}
                            errorText={errors.confirm}
                            placeholder="I want to cancel this account"
                            value={values.name}
                          />
                          <Button
                            status={status}
                            color="error"
                            startIcon={<DeleteSweepIcon />}
                            type="submit"
                            disabled={(!values.confirm || values.confirm !== 'CONFIRM' || values.reason === '')}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            Cancel This Account
                          </Button>
                        </Box>
                      </Box>
                    </Fragment>
                  )
                }
              </form>
            )}
          </Formik>
        </Box>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, account } = state;
  return {
    user: user.user,
    account,
    accountId: account.id,
  };
};

const mapDispatchToProps = {
  deleteAccountSettings: deleteAccountAsAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCancelAccount);
