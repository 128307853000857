import { coreApi } from 'shared/utils/redux/query';

export interface ValidateTicketParams {
  tokenId: string;
  pin?: string;
  username?: string;
  password?: string;
}

const authApi = coreApi.injectEndpoints({
  endpoints: (build) => ({
    validateTicket: build.mutation<null, ValidateTicketParams>({
      query: ({
        tokenId, pin, username, password,
      }) => ({
        url: '/support/validate-ticket',
        method: 'POST',
        body: {
          tokenId, pin, username, password,
        },
      }),
    }),
  }),
});

export const {
  useValidateTicketMutation,
} = authApi;
