import React, { useState } from 'react';
import {
  object, array, bool, string,
} from 'prop-types';
import { Field, useField } from 'formik';
import { FormGroup, Typography, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { Warning } from '@material-ui/icons';
import InfoText from 'shared/styleguide/molecules/InfoText';
import Box from 'shared/styleguide/atoms/Box';
import { HeadlineText, ColorText } from 'shared/styleguide/typography';
import colors from 'shared/styleguide/theme';

const tlsOptions = [
  {
    label: 'Legacy', value: 'legacy', explanation: 'Min TLS Version: 1.0', warning: true,
  },
  { label: 'Modern', value: 'modern', explanation: 'Min TLS Version: 1.2' },
  { label: 'Edge', value: 'edge', explanation: 'Min TLS Version: 1.3' },
];

const TlsRadioButton = ({
  disabled, options, ...rest
}) => {
  const [field, meta, helpers] = useField(rest.name);

  return (
    <FormControl component="fieldset" error={meta.error}>
      <RadioGroup
        aria-label="cipher"
        name="cipher"
        {...field}
        {...rest}
        onChange={(e) => {
          rest.onChange(e);
          field.onChange(e);
        }}
      >
        <Grid container>
          {
            options.map((item) => (
              <Grid
                item
                container
                key={`${field.name}--${item.value}`}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <FormControlLabel control={<Radio />} {...item} />
                </Grid>
                <Grid item xs={8}>
                  <Typography color="textSecondary">
                    {item.explanation}
                  </Typography>
                  {
                    item.warning && (
                      <Box row align="center" css={(theme) => ({ color: theme.palette.warning.main })}><Typography component="span" color="inherit">This option is not recommended</Typography></Box>
                    )
                  }
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

TlsRadioButton.propTypes = {
  disabled: bool,
  field: object,
  meta: object,
  options: array,
};

export const TlsVersionCipher = ({ tlsCipher }) => {
  const [showEdgeWarning, setShowEdgeWarning] = useState(tlsCipher === 'edge');

  const watchChange = (e) => {
    setShowEdgeWarning(e.target.value === 'edge');
  };

  return (
    <Box margin={{ bottom: 'small' }}>
      <HeadlineText>TLS Minimum Version/Cipher</HeadlineText>
      <Box
        padding={{
          top: 'xsmall', bottom: 'xsmall', left: 'medium', right: 'medium',
        }}
      >
        <TlsRadioButton
          type="radio"
          id="tlsCipher"
          name="tlsCipher"
          onChange={watchChange}
          options={tlsOptions}
        />
      </Box>
      {
        showEdgeWarning && (
          <Box margin={{ bottom: 'small' }}>
            <ColorText color={colors.golden}>
              This setting may not be compatible with some browsers or services. Before changing your TLS version, we recommend reading <a href="https://support.pagely.com/hc/en-us/articles/360043674231" target="_blank" rel="noopener nofollow noreferrer">our guide</a>
            </ColorText>
          </Box>
        )
      }
      <InfoText
        href="https://support.pagely.com/hc/en-us/articles/360043846911"
      >
        Changing Your TLS Version
      </InfoText>
    </Box>
  );
};

TlsVersionCipher.propTypes = {
  tlsCipher: string,
};

export default (TlsVersionCipher);
