import React, {
  useEffect, useState, Fragment,
} from 'react';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';

import type { App, Mount } from 'shared/types/App';
import type { Account } from 'shared/modules/account/types.js';
import type { TagsReducer } from 'shared/modules/metadata/types.js';
import type { ReduxStore } from 'shared/utils/redux/apiReducer.js';
import { fetchAppCreateJobs } from 'shared/modules/status/redux/actions';
import {
  createApp,
  setAppCreateModalOpen,
} from 'shared/modules/app/redux/actions';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import ModalContainer from './ModalContainer.js';
import { useNewAppContext } from '../../context/NewAppContext.js';

interface Props {
  account: Account;
  app: App;
  disabled: boolean;
  isPagelyAdmin: boolean;
  mounts: ReduxStore<Mount[]>;
  serverTags: TagsReducer['server'];
}

const AppCreate = ({
  account,
  disabled,
  serverTags,
  mounts,
  isPagelyAdmin,
}: Props): React.ReactElement => {
  const { state: appState, mergeState: setAppState } = useNewAppContext();
  const dispatch = useDispatch();

  // eslint-disable-next-line
  useEffect(() => {
    if (!appState.createModalOpen) {
      // wait 1 second then set the new value in app store
      const successTimerId = setTimeout(
        () => setAppState({ createModalOpen: false }),
        1000,
      );
      return () => clearTimeout(successTimerId);
    }
  }, [appState.createModalOpen]);

  const handleCreateApp = (data) => {
    const { id } = account;
    return dispatch(createApp({ ...data, accountId: id }));
  };

  const handleSetNewAppData = (appId, appName) => {
    // use Context for this
    setAppState({
      newAppId: appId,
      newAppName: appName,
      showCredentials: 'initial',
      appCreateJob: 'loading',
      createModalOpen: true,
    });

    const opts = {
      maxResults: 1,
      appId,
    };
    dispatch(fetchAppCreateJobs(account.id, opts));
  };

  return (
    <Fragment>
      <Button
        onClick={() => setAppState({ createModalOpen: !appState.createModalOpen })}
        variant="contained"
        color="secondary"
        startIcon={<AddIcon color="inherit" />}
        disabled={disabled}
      >
        New App
      </Button>

      {/* Modal */}
      <ModalContainer
        open={appState.createModalOpen}
        onClose={() => setAppState({ createModalOpen: false })}
        serverTags={serverTags}
        onCreateApp={handleCreateApp}
        onSetNewAppData={handleSetNewAppData}
        mounts={mounts}
        accountId={account.id}
        isPagelyAdmin={isPagelyAdmin}
      />

    </Fragment>
  );
};

export default AppCreate;
