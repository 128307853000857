import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'shared/styleguide/molecules/Modal/Modal';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import {
  linkCertToDomain,
  fetchDomainsForCert,
  getLinkedCerts,
  resetCertDomainLink,
} from 'shared/modules/ssl/redux/certDomainLink/actions';
import { requestCert } from 'shared/modules/ssl/redux/cert/actions';
import AdminContext from 'shared/modules/permissions/context/AdminContext';
import { ReduxStore } from 'shared/utils/redux/apiReducer';
import LinkToDomain from './LinkToDomain';
import { Certificate, CertificateDomain } from '../../types/Certificate';

interface Props {
  addDomain: (certId: number, domainId: number) => void;
  cert: Certificate;
  certDomains: ReduxStore<CertificateDomain> & {
    domains: CertificateDomain['domains'];
  };
  certDomainsLinkAction: Record<string, any>;
  fetchDomains: () => void;
  history: Record<string, any>;
  linkedCerts: Record<string, any>;
  match: Record<string, any>;
  requestCert: () => void;
}

const LinkToDomainDialog = (props: Props) => {
  const [domains, setDomains] = useState([]);

  const handleAddDomains = () => {
    const { cert: { id } } = props;
    domains.forEach((domainId) => props.addDomain(id, domainId));
  };

  const {
    history, match, cert, ...rest
  } = props;
  return (
    <AdminContext.Consumer>
      {(isAdmin) => (
        <Modal
          title="Use Certificate For Domain"
          open
          onClose={() => history.replace(`/account/${match.params.accountID}/ssl/cert/${match.params.certID}`)}
          scroll="body"
          overflow="visible"
          actions={(
            <Button
              label="Link Domains"
              variant="contained"
              disabled={props.certDomains.status === 'loading' || !domains || !domains.length}
              onClick={handleAddDomains}
            />
          )}
        >
          <LinkToDomain
            {...rest}
            history={history}
            isAdmin={isAdmin}
            cert={cert}
            match={match}
            domains={domains}
            onSetDomains={(newDomains) => setDomains(newDomains)}
          />
        </Modal>
      )}
    </AdminContext.Consumer>
  );
};
const mapStateToProps = (state) => {
  return {
    cert: state.cert,
    linkedCerts: state.linkedCerts,
    certDomains: state.certDomainSearch,
    certDomainsLinkAction: state.certDomainsLinkAction,
  };
};

const mapDispatchToProps = {
  fetchDomains: fetchDomainsForCert,
  fetchLinkedCerts: getLinkedCerts,
  resetLink: resetCertDomainLink,
  addDomain: linkCertToDomain,
  requestCert,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkToDomainDialog);
