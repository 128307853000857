import React from 'react';
import {
  object, oneOfType, string,
} from 'prop-types';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTheme, Typography } from '@material-ui/core';

import Linker from 'shared/styleguide/atoms/Links/Linker';

const StyledLink = styled(Linker, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'noUnderline',
})(({
  color, hoverColor, noUnderline, theme,
}) => {
  const { colors } = theme.palette;

  return css`
    cursor: pointer;
    transition: all 0.1s ease-out; 
    outline: none;
    text-decoration: ${noUnderline ? 'none' : 'underline'};
    text-decoration-color: ${theme.palette.text.secondary};
    color: ${colors[color] || theme.palette.text.secondary};
    &:hover {
      color: ${colors.primary};
      text-decoration: underline;
      text-decoration-color: ${colors[hoverColor] || theme.palette.primary.main};
    }
  `;
});

const TextLink = ({
  children, variant = 'inherit', color = 'textSecondary', ...rest
}) => {
  const theme = useTheme();

  const colorProps = color ? { color } : null;

  return (
    <Typography component="span" variant={variant} {...colorProps}>
      <StyledLink theme={theme} {...colorProps} {...rest}>{children}</StyledLink>
    </Typography>
  );
};

TextLink.propTypes = {
  color: string,
  href: string,
  to: oneOfType([string, object]),
  variant: string,
};

export default TextLink;
