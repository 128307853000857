import qs from 'qs';
import moment from 'moment-timezone';
import makeApiAction from 'shared/utils/redux/apiActions';
// import promise from 'shared/utils/promiseUtils';
// import mockStatus from 'shared/modules/status/redux/mocks/status-api.json';
import { RECENT_HOURS_LIMIT } from 'shared/modules/disk/constants';
import { AUTH_TYPE } from 'shared/utils/constants';
import * as STATUS from './constants';
/**
 * ownerType: 'Pagely.Model.Apps.App' // needs to be a model name
 * ownerId: required
 * optional params are
 * maxResults,
 * startedDateRange,
 * completedDateRange
 * (those accept arrays of two dates eg ['2020-01-01', '2020-01-10'] )
 * */

export const resetJobNotification = () => ({
  type: STATUS.JOB_NOTIFICATION_RESET,
});

export const fetchGitWebhookDeployJobs = (accountId, options = {}) => {
  const params = qs.stringify({
    type: STATUS.jobTypes.gitWebhookDeploy,
    ...options,
  }, { addQueryPrefix: true });

  return makeApiAction(
    // () => promise.resolve(mockStatus, 2000),
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_GIT_WEBHOOK_DEPLOY_JOBS_REQUEST,
    STATUS.GET_GIT_WEBHOOK_DEPLOY_JOBS_SUCCESS,
    STATUS.GET_GIT_WEBHOOK_DEPLOY_JOBS_FAILURE,
    { accountId, options },
    true,
  );
};

export const resetGitWebhookDeployJobs = () => ({
  type: STATUS.GET_GIT_WEBHOOK_DEPLOY_JOBS_RESET,
});

export const fetchGitIntegrationDeployJobs = (accountId, options = {}) => {
  const params = qs.stringify({
    type: STATUS.jobTypes.gitIntegrationDeploy,
    ...options,
  }, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_GIT_INTEGRATION_DEPLOY_JOBS_REQUEST,
    STATUS.GET_GIT_INTEGRATION_DEPLOY_JOBS_SUCCESS,
    STATUS.GET_GIT_INTEGRATION_DEPLOY_JOBS_FAILURE,
    { accountId, options },
    true,
  );
};

export const resetGitIntegrationDeployJobs = () => ({
  type: STATUS.GET_GIT_INTEGRATION_DEPLOY_JOBS_RESET,
});

export const fetchAppCreateJobs = (accountId, options = {}) => {
  const params = qs.stringify({
    type: STATUS.jobTypes.appCreate,
    ...options,
  }, { addQueryPrefix: true });

  return makeApiAction(
    // () => promise.resolve(mockStatus, 2000),
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_APP_CREATE_JOBS_REQUEST,
    STATUS.GET_APP_CREATE_JOBS_SUCCESS,
    STATUS.GET_APP_CREATE_JOBS_FAILURE,
    { accountId, options },
    true,
  );
};

export const resetAppCreateJobs = () => ({
  type: STATUS.GET_APP_CREATE_JOBS_RESET,
});

export const fetchDiskReportJobs = (accountId, options = {}) => {
  const params = qs.stringify({
    type: STATUS.jobTypes.diskReport,
    ...options,
  }, { addQueryPrefix: true });

  return makeApiAction(
    // () => promise.resolve(mockStatus, 2000),
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_DISK_REPORT_JOBS_REQUEST,
    STATUS.GET_DISK_REPORT_JOBS_SUCCESS,
    STATUS.GET_DISK_REPORT_JOBS_FAILURE,
    { accountId, options },
    true,
  );
};

export const resetDiskReportJobs = () => ({
  type: STATUS.GET_DISK_REPORT_JOBS_RESET,
});

export const fetchDiskUpgradeJobs = (accountId, jobType, options = {}) => {
  const params = qs.stringify({
    type: jobType,
    ...options,
  }, { addQueryPrefix: true });

  const constants = jobType === STATUS.jobTypes.diskUpgradeVps
    ? [STATUS.GET_DISK_UPGRADE_VPS_JOBS_REQUEST,
      STATUS.GET_DISK_UPGRADE_VPS_JOBS_SUCCESS,
      STATUS.GET_DISK_UPGRADE_VPS_JOBS_FAILURE]
    : [STATUS.GET_DISK_UPGRADE_HA_JOBS_REQUEST,
      STATUS.GET_DISK_UPGRADE_HA_JOBS_SUCCESS,
      STATUS.GET_DISK_UPGRADE_HA_JOBS_FAILURE];

  return makeApiAction(
    // () => promise.resolve(mockStatus, 2000),
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    ...constants,
    { accountId, options },
    true,
  );
};

export const resetDiskUpgradeJobs = () => ({
  type: STATUS.GET_DISK_UPGRADE_JOBS_RESET,
});

export const fetchJobById = (jobId) => {
  return makeApiAction(
    ({ api }) => api().get(`/status/job/${jobId}`),
    STATUS.GET_JOB_STATUS_REQUEST,
    STATUS.GET_JOB_STATUS_SUCCESS,
    STATUS.GET_JOB_STATUS_FAILURE,
    { jobId },
    true,
  );
};

export const fetchRecentDiskUpgradeJobs = (accountId, jobType, options = {}) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const params = qs.stringify({
    type: jobType,
    completedDateRange: [
      moment().subtract(RECENT_HOURS_LIMIT, 'hours').format(dateFormat),
      moment().tz('UTC').format(dateFormat),
    ],
    ...options,
  }, { addQueryPrefix: true });

  return makeApiAction(
    // () => promise.resolve(mockStatus, 2000),
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_RECENT_DISK_UPGRADES_REQUEST_ACTION_ONLY,
    STATUS.GET_RECENT_DISK_UPGRADES_SUCCESS_ACTION_ONLY,
    STATUS.GET_RECENT_DISK_UPGRADES_FAILURE_ACTION_ONLY,
    { accountId, options },
    true,
  );
};

export const fetchRecentWebconfigJobs = (accountId, options = {}) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const params = qs.stringify({
    type: STATUS.jobTypes.webconfig,
    startedDateRange: [
      moment().subtract(1, 'hours').format(dateFormat),
      moment().tz('UTC').format(dateFormat),
    ],
    ...options,
  }, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_WEBCONFIG_JOBS_REQUEST,
    STATUS.GET_WEBCONFIG_JOBS_SUCCESS,
    STATUS.GET_WEBCONFIG_JOBS_FAILURE,
    { accountId, options },
    true,
  );
};

// with no date params, results are returned ordered by "created" desc
export const fetchLatestFsaSetupJob = (accountId, app) => {
  const appId = app.id;
  const type = STATUS.jobTypes.getFsaSetupJob(app);
  const params = qs.stringify({
    type,
    ownerId: appId,
    ownerType: AUTH_TYPE.app,
    maxResults: 1,
  }, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    type === STATUS.jobTypes.fsaSetup ? STATUS.GET_FSA_SETUP_JOBS_REQUEST : STATUS.GET_FSA_SETUP_CLOUDFLARE_JOBS_REQUEST,
    type === STATUS.jobTypes.fsaSetup ? STATUS.GET_FSA_SETUP_JOBS_SUCCESS : STATUS.GET_FSA_SETUP_CLOUDFLARE_JOBS_SUCCESS,
    type === STATUS.jobTypes.fsaSetup ? STATUS.GET_FSA_SETUP_JOBS_FAILURE : STATUS.GET_FSA_SETUP_CLOUDFLARE_JOBS_FAILURE,
    { accountId, appId },
    true,
  );
};

export const fetchLatestFsaDisableJob = (accountId, appId) => {
  const params = qs.stringify({
    type: STATUS.jobTypes.fsaDisable,
    ownerId: appId,
    ownerType: AUTH_TYPE.app,
    maxResults: 1,
  }, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_FSA_DISABLE_JOBS_REQUEST,
    STATUS.GET_FSA_DISABLE_JOBS_SUCCESS,
    STATUS.GET_FSA_DISABLE_JOBS_FAILURE,
    { accountId, appId },
    true,
  );
};

export const fetchFsaSetupJobsForAccount = (accountId) => {
  const params = qs.stringify({
    type: STATUS.jobTypes.fsaSetup,
  }, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_FSA_SETUP_JOBS_REQUEST,
    STATUS.GET_FSA_SETUP_JOBS_SUCCESS,
    STATUS.GET_FSA_SETUP_JOBS_FAILURE,
    { accountId },
    true,
  );
};

export const fetchFsaDisableJobsForAccount = (accountId) => {
  const params = qs.stringify({
    type: STATUS.jobTypes.fsaDisable,
  }, { addQueryPrefix: true });

  return makeApiAction(
    ({ api }) => api().get(`/status/job/account/${accountId}${params}`),
    STATUS.GET_FSA_DISABLE_JOBS_REQUEST,
    STATUS.GET_FSA_DISABLE_JOBS_SUCCESS,
    STATUS.GET_FSA_DISABLE_JOBS_FAILURE,
    { accountId },
    true,
  );
};
