import SubmitTicket from './SubmitTicket';
import Recent from './RecentTickets';
import Status from './Status';
import FAQ from './FAQ';
import Twitter from './Twitter';
import BannedPlugins from './BannedPlugins';
import MigrationForm from './MigrationForm';
import SharedNotes from './SharedNotes';

export const routes = [
  {
    label: 'Submit Ticket',
    to: 'submit',
    match: new RegExp(/\/support\/submit$/), // eslint-disable-line
    component: SubmitTicket,
  },
  {
    label: 'Recent Tickets',
    to: 'recent',
    match: new RegExp(/\/support\/((\d+)|recent)$/), // eslint-disable-line
    component: Recent,
  },
  {
    label: 'FAQ',
    to: 'faq',
    match: new RegExp(/\/support\/faq$/), // eslint-disable-line
    component: FAQ,
  },
  {
    label: 'System Status',
    to: 'status',
    match: new RegExp(/\/support\/status$/), // eslint-disable-line
    component: Status,
  },
  {
    label: 'Twitter',
    to: 'twitter',
    match: new RegExp(/\/support\/twitter$/), // eslint-disable-line
    component: Twitter,
  },
  {
    label: 'Secure Notes',
    to: 'migrations',
    match: new RegExp(/\/support\/migrations$/), // eslint-disable-line
    component: MigrationForm,
  },
  {
    label: 'Banned Plugins',
    to: 'banned',
    match: new RegExp(/\/support\/banned$/), // eslint-disable-line
    component: BannedPlugins,
  },
  {
    label: 'Shared Notes',
    to: 'shared',
    match: new RegExp(/\/support\/shared$/), // eslint-disable-line
    component: SharedNotes,
  },
];
