import React, { Fragment, useState } from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { consolidateErrors } from 'shared/utils/validation';
import { ErrorText } from 'shared/styleguide/typography';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Success from 'shared/styleguide/atoms/Success';
import Box from 'shared/styleguide/atoms/Box';
import SupportLink from 'shared/modules/support/Link';
import { checkEmailChangeToken, emailAddressChangeRequest } from 'shared/modules/account/redux/actions';

const ChangeEmailDialog = ({
  account, onClose, newEmailAddress, setStatus, changeEmailRequest,
}) => {
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');

  const handleRequestChange = async () => {
    try {
      await changeEmailRequest(account.id, { email: newEmailAddress, password });

      setStatus('success');
    } catch (err) {
      if (err?.response?.status === 422) {
        setErrors(consolidateErrors(err));
      } else {
        setErrors(consolidateErrors(err));
      }
    }
  };

  return (
    <Fragment>
      <DialogContent>
        <Box padding={{ bottom: 'small' }}>
          <DialogContentText color="textPrimary">
            <strong>NOTE:</strong> This will only update your profile information.<br />
            For account ownership transfers please <SupportLink>contact support</SupportLink>.
          </DialogContentText>
          <DialogContentText>
            You are changing the email associated with this account to:
          </DialogContentText>
          <DialogContentText color="textPrimary">
            <strong>{newEmailAddress}</strong>
          </DialogContentText>
        </Box>
        <DialogContentText>
          Please confirm your password to make these changes.
        </DialogContentText>
        <WideTextField
          autoFocus
          margin="normal"
          id="name"
          placeholder="Confirm Password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
          variant="filled"
        />
        {
          errors && (
            <ErrorText>{errors}</ErrorText>
          )
        }
      </DialogContent>
      <DialogActions>
        <Box direction="row" justify="space-between" flex={1} padding="xsmall">
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={handleRequestChange} variant="contained">
            Update Email Address
          </Button>
        </Box>
      </DialogActions>
    </Fragment>
  );
};

ChangeEmailDialog.propTypes = {
  account: object.isRequired,
  changeEmailRequest: func.isRequired,
  newEmailAddress: string.isRequired,
  onClose: func.isRequired,
  setStatus: func.isRequired,
};

const SuccessfulEmailDialog = ({
  newEmailAddress, onClose, account, checkEmailChangeToken,
}) => {
  const handleClose = async (event) => {
    setTimeout(() => {
      checkEmailChangeToken(account.id);
    }, 3000);

    onClose(event);
  };
  return (
    <Fragment>
      <DialogContent>
        <Success
          primaryMessage={(
            <DialogContentText color="textSecondary">
              Please follow the instructions sent to&nbsp;
              <Typography
                component="span"
                color="textPrimary"
              >
                {newEmailAddress}
              </Typography> to confirm this change
            </DialogContentText>
          )}
          secondaryMessage={(
            <DialogContentText variant="body2" color="textSecondary">
              No changes will be applied until after the address is confirmed from your email
            </DialogContentText>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Close
        </Button>
      </DialogActions>
    </Fragment>
  );
};

SuccessfulEmailDialog.propTypes = {
  account: object.isRequired,
  checkEmailChangeToken: func.isRequired,
  newEmailAddress: string.isRequired,
  onClose: func.isRequired,
};

export const ManageEmailDialog = ({
  newEmailAddress, account, open, onClose, changeEmailRequest, checkEmailChangeToken, initialState = 'initial',
}) => {
  const [status, setStatus] = useState(initialState);
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title">Change Email Address</DialogTitle>
      {
        status === 'initial' && (
          <ChangeEmailDialog
            account={account}
            newEmailAddress={newEmailAddress}
            onClose={onClose}
            setStatus={setStatus}
            changeEmailRequest={changeEmailRequest}
          />
        )
      }
      {
        status === 'success' && (
          <SuccessfulEmailDialog
            account={account}
            newEmailAddress={newEmailAddress}
            onClose={onClose}
            checkEmailChangeToken={checkEmailChangeToken}
          />
        )
      }
    </Dialog>
  );
};

ManageEmailDialog.propTypes = {
  account: object.isRequired,
  changeEmailRequest: func.isRequired,
  checkEmailChangeToken: func.isRequired,
  initialState: string,
  newEmailAddress: string.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
};

export default connect(
  (state) => ({ account: state.account }),
  {
    changeEmailRequest: emailAddressChangeRequest,
    checkEmailChangeToken,
  },
)(ManageEmailDialog);
