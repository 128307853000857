import tracker from 'shared/3rdparty/pageTracking';
import makeApiAction from 'shared/utils/redux/apiActions';

import * as ACCOUNT from './constants';

export const getSSHPublicKeys = (accountId) => makeApiAction(
  ({ api }) => api().get(`/accounts/${accountId}/ssh/keys`),
  ACCOUNT.ACCOUNT_SSH_KEYS_REQUEST,
  ACCOUNT.ACCOUNT_SSH_KEYS_SUCCESS,
  ACCOUNT.ACCOUNT_SSH_KEYS_FAILURE,
  { accountId },
  true,
);

export const addSSHPublicKey = (accountId, key) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_add_ssh_key',
    });
    return api().post(`/accounts/${accountId}/ssh/keys`, {
      key,
    });
  },
  ACCOUNT.ACCOUNT_SSH_KEY_CREATE_REQUEST,
  ACCOUNT.ACCOUNT_SSH_KEY_CREATE_SUCCESS,
  ACCOUNT.ACCOUNT_SSH_KEY_CREATE_FAILURE,
  { accountId },
  true,
);

export const deleteSSHPublicKey = (accountId, sshKeyId) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_delete_ssh_key',
    });

    return api().delete(`/accounts/${accountId}/ssh/keys/${sshKeyId}`, {
    });
  },
  ACCOUNT.ACCOUNT_SSH_KEY_DELETE_REQUEST,
  ACCOUNT.ACCOUNT_SSH_KEY_DELETE_SUCCESS,
  ACCOUNT.ACCOUNT_SSH_KEY_DELETE_FAILURE,
  { accountId, sshKeyId },
);

export const resetSSHPublicKey = () => ({
  type: ACCOUNT.ACCOUNT_SSH_KEY_RESET,
});
