import makeApiAction from 'shared/utils/redux/apiActions';
import * as BILLING from './constants';

export const getDiskAddonCalculation = ({ accountId, newSize }) => {
  return makeApiAction(
    ({ api }) => api().get(`/billing/accounts/${accountId}/storage?newSize=${newSize}`),
    BILLING.GET_DISK_ADDON_CALCULATION_REQUEST,
    BILLING.GET_DISK_ADDON_CALCULATION_SUCCESS,
    BILLING.GET_DISK_ADDON_CALCULATION_FAILURE,
    { accountId, newSize },
    true,
  );
};

export const resetDiskAddonCalculation = () => ({
  type: BILLING.GET_DISK_ADDON_CALCULATION_RESET,
});

export const simpleDiskUpgrade = ({ accountId, newSize }) => {
  return makeApiAction(
    ({ api }) => api().post(`/billing/accounts/${accountId}/storage`, {
      newSize,
    }),
    BILLING.SIMPLE_DISK_ADDON_PURCHASE_REQUEST,
    BILLING.SIMPLE_DISK_ADDON_PURCHASE_SUCCESS,
    BILLING.SIMPLE_DISK_ADDON_PURCHASE_FAILURE,
    { accountId, newSize },
    true,
  );
};

export const resetDiskAddonPurchase = () => ({
  type: BILLING.SIMPLE_DISK_ADDON_PURCHASE_RESET,
});
