import $RefParser from 'json-schema-ref-parser';

import { deepClone } from 'shared/utils';

import schema from '../json/schema.json';
import * as examples from '../json/examples/examples';
import redirectsInitial from '../json/redirects-initial.json';
import rulesInitial from '../json/rules-initial.json';
import missRulesInitial from '../json/miss-rules-initial.json';

import { stringToTemplatedString, cleanJson } from '../helpers';
import * as redirectHelpers from '../components/modal/redirectHelpers';

// validation
import * as APP from './constants';

const Ajv = require('ajv');

const ajv = new Ajv({ allErrors: true, coerceTypes: true });
ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

const validate = ajv.compile(schema);

const configInitial = {};
configInitial.redirects = redirectsInitial;
configInitial.rules = rulesInitial;
configInitial['miss-rules'] = missRulesInitial;

// actions

export const parseSchema = (schema) => async (dispatch) => {
  const parsed = await $RefParser.dereference(schema);
  if (!parsed) {
    dispatch({
      type: APP.TOOLS_SCHEMA_PARSE_ERROR, payload: 'Schema parse error',
    });
  } else {
    dispatch({ type: APP.TOOLS_SCHEMA_PARSE, payload: parsed });
  }
};

export const undoRedo = (type) => {
  switch (type) {
    case 'undo':
      return {
        type: APP.TOOLS_UNDO,
      };
    case 'redo':
      return {
        type: APP.TOOLS_REDO,
      };
    default:
      return '';
  }
};

export const updateItem = (path, value) => {
  return {
    type: APP.TOOLS_UPDATE_ITEM,
    payload: {
      path,
      value,
    },
  };
};

export const updateMapKey = (path, oldkey, newkey) => {
  return {
    type: APP.TOOLS_UPDATE_MAP_KEY,
    payload: {
      path,
      oldkey,
      newkey,
    },
  };
};

export const updateMapVal = (path, value) => {
  return {
    type: APP.TOOLS_UPDATE_MAP_VAL,
    payload: {
      path,
      value,
    },
  };
};

export const addSubProperty = (path, option, type) => {
  return {
    type: APP.TOOLS_ADD_SUBPROPERTY,
    payload: {
      path,
      option,
      type,
    },
  };
};

export const addSubMapProperty = (path, option, type) => {
  return {
    type: APP.TOOLS_ADD_SUBMAPPROPERTY,
    payload: {
      path,
      option,
      type,
    },
  };
};

export const addProperty = (path, propName, option, type) => {
  return {
    type: APP.TOOLS_ADD_PROPERTY,
    payload: {
      path,
      propName,
      option,
      type,
    },
  };
};

export const deleteRuleBlock = (path, blockIndex) => {
  return {
    type: APP.TOOLS_DELETE_RULEBLOCK,
    payload: {
      path,
      blockIndex,
    },
  };
};

export const addRuleBlock = (path, name, option, type) => {
  return {
    type: APP.TOOLS_ADD_RULEBLOCK,
    payload: {
      path,
      name,
      option,
      type,
    },
  };
};

export const updateKey = (path, oldkey, newkey, option, type) => {
  return {
    type: APP.TOOLS_UPDATE_KEY,
    payload: {
      path,
      oldkey,
      newkey,
      option,
      type,
    },
  };
};

export const changeConfig = (config, source, target, option) => {
  let wrapper = '';
  if (target === 'redirects') {
    wrapper = 'redirects';
  }
  return {
    type: APP.TOOLS_CHANGE_CONFIG,
    payload: {
      source,
      target,
      config,
      wrapper,
      option,
    },
  };
};

export const deleteItem = (path) => {
  return {
    type: APP.TOOLS_DELETE_ITEM,
    payload: {
      path,
    },
  };
};

export const loadConfig = (type = 'redirects', modalOpen = false) => {
  let wrapper = '';
  if (type === 'redirects') {
    wrapper = 'redirects';
  }
  const config = deepClone(configInitial[type]);
  return {
    type: APP.TOOLS_SHOW_CONFIG,
    payload: {
      type,
      wrapper,
      config,
      modalOpen,
    },
  };
};

export const setPropLevels = (type = 'redirects') => {
  let propLevels;
  if (type === 'redirects') {
    propLevels = `config.${type}`;
  } else {
    propLevels = 'config';
  }
  return {
    type: APP.TOOLS_SET_PROPLEVELS,
    payload: propLevels,
  };
};

export const setInputErrors = (value = null) => {
  return {
    type: APP.TOOLS_SET_ERRORS,
    payload: value,
  };
};

export const setModalOpen = (value = false, content = null) => {
  switch (content) {
    case 'jsonOutput':
      return {
        type: APP.TOOLS_SET_JSON_MODAL_OPEN,
        payload: value,
      };
    case 'import':
      return {
        type: APP.TOOLS_SET_IMPORT_MODAL_OPEN,
        payload: value,
      };
    case 'redirectsWizard':
      return {
        type: APP.TOOLS_SET_REDIRECTS_WIZARD_MODAL_OPEN,
        payload: value,
      };
    default:
      return '';
  }
};

export const validateConfig = (jsonConfig = null) => {
  const valid = validate(jsonConfig);
  if (!valid) {
    return {
      type: APP.TOOLS_JSON_OUTPUT_ERROR,
      payload: validate.errors,
    };
  }
  return {
    type: APP.TOOLS_JSON_OUTPUT_SUCCESS,
  };
};

export const jsonModalOpen = (value = false, jsonConfig = null) => {
  return (dispatch) => {
    dispatch(setModalOpen(value, 'jsonOutput'));
    if (jsonConfig) {
      dispatch(validateConfig(jsonConfig));
    }
  };
};

export const redirectsWizardModalOpen = (value = false) => {
  return (dispatch) => {
    dispatch(setModalOpen(value, 'redirectsWizard'));
  };
};

export const importModalOpen = (value = false) => {
  return (dispatch) => {
    dispatch(setModalOpen(value, 'import'));
  };
};

export const clearImportError = () => {
  return {
    type: APP.TOOLS_CLEAR_IMPORT_ERROR,
  };
};

export const submitImport = (value = null) => {
  // test for valid JSON
  try {
    JSON.parse(cleanJson(value));
  } catch (e) {
    return {
      type: APP.TOOLS_IMPORT_ERROR,
      payload: [{ message: 'Invalid JSON.', type: 'json' }],
    };
  }
  const jsonConfig = JSON.parse(cleanJson(value));
  stringToTemplatedString(jsonConfig);
  if (jsonConfig) {
    const valid = validate(jsonConfig);
    if (!valid) {
      return {
        type: APP.TOOLS_IMPORT_ERROR,
        payload: validate.errors,
      };
    } else {
      return {
        type: APP.TOOLS_IMPORT_SUCCESS,
        payload: {
          config: jsonConfig,
        },
      };
    }
  }
};

export const submitRedirectsWizard = (sourceVal = null, targetVal = null) => {
  const defaultExclusions = '^/(wp-admin|wp-login)(.*)$';
  const configJson = redirectHelpers.createConfig(sourceVal, targetVal, 301, defaultExclusions);
  if (!configJson) {
    return {
      type: APP.TOOLS_REDIRECTS_WIZARD_ERROR,
      payload: [{ message: 'Invalid source and/or target.' }],
    };
  } else {
    return {
      type: APP.TOOLS_SUBMIT_REDIRECTS_WIZARD,
      payload: {
        config: JSON.parse(configJson),
      },
    };
  }
};

export const importExample = (value) => {
  const jsonConfig = examples[value];
  if (jsonConfig) {
    const valid = validate(jsonConfig);
    if (!valid) {
      return {
        type: APP.TOOLS_IMPORT_ERROR,
        payload: validate.errors,
      };
    } else {
      return {
        type: APP.TOOLS_IMPORT_EXAMPLE,
        payload: jsonConfig,
      };
    }
  }
};
