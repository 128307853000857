/* eslint-disable react/prop-types */
import React, {
  useEffect, useState,
} from 'react';
import {
  object, number, string, func, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Card, Paper, Typography } from '@material-ui/core';

import { getDimensionsByPath, getDiskUsageByPath } from 'shared/modules/analytics/redux/actions/disk';
import Loading from 'shared/styleguide/atoms/Loading';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';

import Layout from 'shared/styleguide/molecules/Layout';
import graphColors from 'shared/styleguide/theme/graphColors';

import {
  ToolTipBox, LegendTypography,
} from './Graphs/graphUtils';

import DiskLine from './Graphs/DiskLine';
import DiskPieByLocation from './Graphs/DiskPieByLocation';

const oneDateRange = {
  fromDate: moment().subtract(1, 'days').tz('UTC').format(),
  toDate: moment().tz('UTC').endOf('day').format(),
};

const thirtyDateRange = {
  fromDate: moment().subtract(30, 'days').tz('UTC').format(),
  toDate: moment().tz('UTC').endOf('day').format(),
};

const toolTip = (e) => {
  return (
    <ToolTipBox
      direction="row"
      align="center"
      gap="xsmall"
    >
      <Box style={{ backgroundColor: e.point.serieColor, height: 16, width: 16 }} />
      <Box align="flex-end" style={{ marginTop: 3 }}>
        <Typography variant="body2"><strong>{e.point.serieId}</strong>: {e.point.data.yFormatted}MiB</Typography>
      </Box>
    </ToolTipBox>
  );
};

const LineChart = ({ diskUsage }) => {
  let maxValue = 0;

  const data = diskUsage.data.series
    .map((path, index) => {
      // ["time", "storage_per", "storage_used", "files_count"]
      const values = path.values.map((value) => {
        maxValue = value[2] > maxValue ? value[2] : maxValue;
        return {
          'x': moment(value[0]).format('M/D'),
          'y': value[2],
        };
      });

      return {
        id: 'p20',
        step: index,
        data: values.filter((v) => v.y),
        label: 'Overall Usage',
      };
    });

  const legend = data.map((item, i) => {
    return (
      <Box direction="row" gap="xsmall" padding={{ top: 'xsmall' }} align="center" key={item.id}>
        <Box style={{ backgroundColor: graphColors[i], height: 14, width: 14 }} />
        <Box>
          <LegendTypography>{item.label}</LegendTypography>
        </Box>
      </Box>
    );
  });

  return (
    <Card>
      <Box margin="small">
        <Typography variant="h5" gutterBottom>Overall Usage</Typography>
        <Box style={{ height: 200, minWidth: 400 }}>
          <DiskLine
            data={data}
            graphColors={graphColors}
            toolTip={toolTip}
            maxValue={maxValue * 1.3}
            unit="MiB"
          />
        </Box>
        <Box wrap="wrap" margin={{ top: 'medium' }} justify="center" direction="row" gap="small">
          {legend}
        </Box>
      </Box>
    </Card>
  );
};

const P20Graphs = ({
  account, pathUsage,
  getDimensionsByPath, getDiskUsageByPath, pathDimensions,
}) => {
  // const [lineChartData, setLineChartData] = useState([]);
  // const [status, setStatus] = useState('pristine');

  // const fetchGraphData = async ({ fetchDimensions, fetchUsage }) => {
  //   let { dimensions } = pathDimensions.data;

  //   if (fetchDimensions) {
  //     const response = await getDimensionsByPath(account.id, {
  //       ...oneDateRange,
  //     });

  //     dimensions = response.data.dimensions;
  //   }

  //   if (fetchUsage) {
  //     const usageResponse = await getDiskUsageByPath(account.id, {
  //       ...thirtyDateRange,
  //       appIds: dimensions.appIds,
  //       type: ['app'],
  //       singleSeries: true,
  //     });

  //     setLineChartData(usageResponse);
  //   }
  //   setStatus('ready');
  // };

  // useEffect(() => {
  //   const args = { fetchDimensions: false, fetchUsage: false };
  //   if (pathDimensions.params?.accountId !== account.id) {
  //     args.fetchDimensions = true;
  //   }

  //   if (pathUsage.params?.accountId !== account.id) {
  //     args.fetchUsage = true;
  //   }

  //   if (args.fetchDimensions || args.fetchUsage) {
  //     setStatus('loading');
  //     fetchGraphData(args);
  //   }
  // }, []);

  // if (pathDimensions.status === 'loading' || pathDimensions.status === 'pristine') {
  //   return <Loading />;
  // }

  return (
    <Layout title="Disk">
      <Paper>
        <Box padding="small">
          <Typography paragraph variant="body1">
            With a virtual private server (VPS) account, you can access detailed disk usage reports, self-service disk upgrades, graphs by location, and more.
          </Typography>
          <Box direction="row" align="center" justify="center">
            <Button component={AdapterLink} to="billing/plan">See Plan Options</Button>
          </Box>
        </Box>
      </Paper>

      <Box margin={{ top: 'small' }}>
        <Empty>No server disk data available</Empty>
      </Box>

      {/* <Box key="p20-app" margin={{ top: 'medium' }}>
        <DiskPieByLocation
          accountId={account.id}
          serverName={[]}
          graphType="app" // use app for p20
          dimensions={pathDimensions.data.dimensions}
        />
      </Box>
      {
        status === 'ready' && (
        <Box key="p20-line" margin={{ top: 'medium' }}>
          <LineChart
            diskUsage={lineChartData}
          />
        </Box>
        )
      } */}
    </Layout>
  );
};

P20Graphs.propTypes = {
  account: object,
  getDimensionsByPath: func.isRequired,
  multiServer: bool,
  pathDimensions: object,
  serverName: string,
};

export default connect(
  (state) => ({
    pathDimensions: state.analytics.disk.byPath.dimensions,
    pathUsage: state.analytics.disk.byPath.usage,
  }), { getDimensionsByPath, getDiskUsageByPath },
)(P20Graphs);
