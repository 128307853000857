export const CREATE_USER_POOL_REQUEST = 'SSO_CREATE_USER_POOL_REQUEST';
export const CREATE_USER_POOL_SUCCESS = 'SSO_CREATE_USER_POOL_SUCCESS';
export const CREATE_USER_POOL_FAILED = 'SSO_CREATE_USER_POOL_FAILED';

export const UPDATE_STATUS = 'SSO_UPDATE_STATUS';

export const FINISH_SAML_SETUP_REQUEST = 'SSO_FINISH_SAML_SETUP_REQUEST';
export const FINISH_SAML_SETUP_SUCCESS = 'SSO_FINISH_SAML_SETUP_SUCCESS';
export const FINISH_SAML_SETUP_FAILED = 'SSO_FINISH_SAML_SETUP_FAILED';

export const DELETE_IDP_REQUEST = 'SSO_DELETE_IDENTITY_PROVIDER_REQUEST';
export const DELETE_IDP_SUCCESS = 'SSO_DELETE_IDENTITY_PROVIDER_SUCCESS';
export const DELETE_IDP_FAILED = 'SSO_DELETE_IDENTITY_PROVIDER_FAILED';

export const DELETE_POOL_REQUEST = 'SSO_DELETE_POOL_REQUEST';
export const DELETE_POOL_SUCCESS = 'SSO_DELETE_POOL_SUCCESS';
export const DELETE_POOL_FAILED = 'SSO_DELETE_POOL_FAILED';
