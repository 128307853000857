import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import ErrorIcon from '@material-ui/icons/Error';

import { Typography } from '@material-ui/core';
import { clamp } from 'lodash';
import Box from 'shared/styleguide/atoms/Box';
import { remMapper } from 'shared/styleguide/theme/spacing';

const useStyles = (danger) => makeStyles((theme) => ({
  colorSecondary: {
    backgroundColor: danger ? '#f7bbc9' : theme.color200,
  },
  barColorSecondary: {
    backgroundColor: danger ? theme.palette.error.main : theme.palette.secondary.main,
  },
}));

interface ProgressBarProps {
  buffer?: number | boolean;
  displayDanger?: boolean;
  percentage?: number;
  variant?: LinearProgressProps['variant'];
}

export const ProgressBar = ({
  buffer, displayDanger, percentage, variant,
}: ProgressBarProps): JSX.Element => {
  const classes = useStyles(displayDanger)();
  const clampedPercentage = clamp(percentage, 0, 100);

  return (
    <LinearProgress
      variant={variant || 'determinate'}
      value={variant === 'buffer' ? (Number(buffer) / 100) * clampedPercentage : clampedPercentage}
      classes={classes}
      valueBuffer={(buffer && (100 - Number(buffer))) || 100}
      color="secondary"
    />
  );
};

interface ProgressViewProps {
  buffer?: number | boolean;
  className?: string;
  color?: string;
  errorText?: string;
  heading?: string | Record<string, any>;
  icon?: Record<string, any>;
  percentage?: number;
  showDanger?: boolean;
  style?: Record<string, any>;
  threshold: number;
  value1?: string;
  value2?: string;
  value3?: string | boolean;
  variant?: LinearProgressProps['variant'];
}

const ProgressView = ({
  heading,
  icon,
  value1,
  value2,
  value3,
  percentage,
  threshold,
  errorText,
  style,
  className,
  showDanger = true,
  variant,
  buffer,
}: ProgressViewProps): JSX.Element => {
  const displayDanger = showDanger && percentage > threshold;

  return (
    <div className={className} css={style}>
      <Typography component="span" color="textSecondary">{heading}</Typography>
      <Box flex={1} justify="space-between">
        <Box margin={{ top: 'xxsmall', bottom: 'small' }} align="flex-start">
          <Typography component="span" variant="h3" color="textSecondary" align="center">
            <span css={{ marginRight: remMapper('xxsmall') }}>{icon}</span>
            <Typography component="strong" variant="inherit" color="textPrimary">{value1}</Typography> / {value2}
          </Typography>
        </Box>
        {
          Boolean(value3)
          && (
            <Box margin={{ top: 'xxsmall', bottom: 'xsmall' }} align="flex-end">
              <Typography component="span" color="textSecondary">{value3}</Typography>
            </Box>
          )
        }
      </Box>
      <div>
        <ProgressBar variant={variant} buffer={buffer} displayDanger={displayDanger} percentage={percentage} />
      </div>
      {
        displayDanger && threshold !== null && percentage > threshold
        && (
          <Box align="center" direction="row" margin={{ top: 'xsmall' }}>
            <ErrorIcon color="error" css={{ marginRight: remMapper('xxsmall') }} /> <Typography color="textSecondary">{errorText}</Typography>
          </Box>
        )
      }
    </div>
  );
};

ProgressView.displayName = 'ProgressView';

export default ProgressView;
