import React, { Component } from 'react';
import PropTypes, { object, func } from 'prop-types';
import {
  Switch, Route, Link, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
  subscribe, unsubscribe, getChannel,
} from 'pusher-redux';

import AccessDeniedIcon from '@material-ui/icons/RemoveCircleOutline';

import { configurePusher, CONSTANTS as PUSHER, EVENTS } from 'shared/3rdparty/pusher';
import logger from 'shared/3rdparty/logger';
import colors from 'shared/styleguide/theme';
import Banner from 'shared/styleguide/molecules/Banner';
import Loading from 'shared/styleguide/atoms/Loading';

import { propTypes as accountProptypes } from 'shared/modules/account/models';
import { roles as ROLES } from 'shared/modules/permissions/user/actions';

// ------ Actions
import { fetchAccount } from 'shared/modules/account/redux/actions';
import { fetchDomainsForAccount } from 'shared/modules/app/redux/domains/actions';
import { fetchAppsForAccount, resetApp } from 'shared/modules/apps/redux/actions';
import { fetchPendingServerConfigDeploys } from 'shared/modules/ares/redux/pending/actions';
import { fetchServerTagsForAccount } from 'shared/modules/server/redux/actions';
import { resetAppStats, resetStats } from 'shared/modules/analytics/redux/actions';
import { resetDiskAnalytics } from 'shared/modules/analytics/redux/actions/disk';

import SSLPage from 'shared/modules/ssl';
// import SSHKeysPage from 'shared/modules/security/pages/SSHKeys';
import AnalyticsPlaceholder from 'shared/modules/analytics/components/AnalyticsPlaceholder';
import Analytics from 'shared/modules/analytics';
import Alerts from 'shared/modules/alerts';
import SupportPage from 'shared/modules/support';
import TeamPage from 'shared/modules/team';
import DiskPage from 'shared/modules/disk';
import SettingsPage from 'shared/modules/settings';
import Billing from 'shared/modules/billing';
import CDNManager from 'shared/modules/cdn/pages/CDNManager';
import CDN from 'shared/modules/cdn/pages/CDN';
import DNSZones from 'shared/modules/dns/pages/DNSZones';
import DNS from 'shared/modules/dns/pages/DNS';
import ReleaseNotes from 'shared/modules/releasenotes/pages/ListReleaseNotes';
import AppsList from 'shared/modules/apps';
import AppRoutes from 'shared/modules/app';
import SftpOnly from 'shared/modules/app/SftpOnly';
import Integrations from 'shared/modules/integrations';
import VerifyEmailPage from 'shared/modules/account/pages/VerifyEmail';
import Configs from 'admin/modules/aresconfig';
import JobNotification from 'shared/modules/status/components/JobNotification';
import AresDeployNotification from 'shared/modules/ares/routes/Notifications/AresDeployNotification';

// ------ Context
import AdminContext from 'shared/modules/permissions/context/AdminContext';
import PermissionsContext from 'shared/modules/permissions/context/PermissionsContext';
import AccountContext from 'shared/modules/account/context/AccountContext';

// ----- Presentational
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import AccountDashboard from 'shared/modules/dashboard/pages/AccountDashboard';

import AdBlockerBanner from 'shared/styleguide/organisms/Banners/AdBlocker';
import HolidayBanner from 'shared/modules/webapp/components/Banners/HolidayBanner';
import isPagelyAdmin from 'shared/utils/isAdmin';
import {
  setupChat, teardownChat, populateChatInfo, CHAT_UNAVAILABLE,
} from 'shared/utils/livechat';

import { remMapper } from 'shared/styleguide/theme/spacing';
import { getDeployEnvironment } from 'shared/config';
import Box from 'shared/styleguide/atoms/Box';
import NotificationBanner from 'shared/modules/webapp/components/Banners/NotificationBanner';
import { p20Pools } from '../constants';
import AccountHeader from '../components/AccountHeader';

const subscribeToAlerts = (userOrAccountId) => {
  let alertsChannel;
  try {
    alertsChannel = getChannel(`private-account-${userOrAccountId}-alerts`);
  } catch (err) {
    // noop
  }

  let eventsChannel;
  try {
    eventsChannel = getChannel(`private-account-${userOrAccountId}-events`);
  } catch (err) {
    // noop
  }

  if (!alertsChannel || !eventsChannel) {
    configurePusher();
  }

  if (!alertsChannel) {
    subscribe(`private-account-${userOrAccountId}-alerts`, EVENTS.ALERT, PUSHER.ALERT, { accountId: userOrAccountId });
  }

  if (!eventsChannel) {
    subscribe(`private-account-${userOrAccountId}-events`, EVENTS.STATUS_UPDATE, PUSHER.STATUS_UPDATE, { accountId: userOrAccountId });
  }
};

export class Account extends Component {
  static propTypes = {
    account: PropTypes.shape(accountProptypes).isRequired,
    fetchAccount: PropTypes.func.isRequired,
    fetchApps: PropTypes.func.isRequired,
    fetchDomains: PropTypes.func.isRequired,
    fetchPendingServerConfigDeploys: func,
    fetchServerTagsForAccount: func,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
      path: PropTypes.string.isRequired,
    }),
    pendingDeploys: object,
    permissions: object,
    resetApp: func,
    resetAppStats: func,
    resetDiskAnalytics: func,
    resetStats: func,
    user: object,
  };

  componentDidMount() {
    const { match: { params: { accountID } } } = this.props;
    if (accountID !== 'dash') {
      this.fetchData(accountID);
      this.props.fetchPendingServerConfigDeploys(Number(accountID));
    }
  }

  componentDidUpdate(prevProps) {
    const { user, match: { params: { accountID } }, pendingDeploys } = this.props;

    if (accountID !== prevProps.match.params.accountID && accountID !== 'dash') {
      // reset billing data on navigation
      const accountId = parseInt(accountID, 10);
      this.fetchData(accountID);
      if (pendingDeploys.status !== 'loading') {
        this.props.fetchPendingServerConfigDeploys(accountId);
      }
    }

    const isAdmin = user && isPagelyAdmin(user);

    // set up pusher subscription for alerts
    if (isAdmin && accountID !== 'dash') {
      subscribeToAlerts(accountID);
    } else if (user?.id) {
      subscribeToAlerts(user.id);
    }
  }

  componentWillUnmount() {
    teardownChat();
    const accountId = this.props.match.params.accountID;
    unsubscribe(`private-account-${accountId}-alerts`, EVENTS.ALERT, PUSHER.ALERT);
    unsubscribe(`private-account-${accountId}-events`, EVENTS.STATUS_UPDATE, PUSHER.STATUS_UPDATE);
    this.resetData();
  }

  fetchData = (accountID) => {
    if (accountID === 'dash') {
      return;
    }

    const {
      fetchDomains, fetchApps, permissions, account, fetchServerTagsForAccount,
    } = this.props;

    const accountId = parseInt(accountID, 10);

    if (!account.id || account.id !== accountId) {
      this.props.fetchAccount(accountId).then((data) => {
        if (getDeployEnvironment() === 'woosaas') {
          document.title = 'GoDaddy MWCS';
        } else {
          // eslint-disable-next-line no-lonely-if
          if (data.companyName) {
            document.title = `Atomic - ${data.companyName}`;
          } else {
            document.title = 'Pagely Atomic';
          }
        }

        // this is the only way I can think of to get accurate data.
        // if we don't do this, we can't guarantee we have the real account info, causing this problem:
        // we don't have the updated account here, so its too risky to do logic here :(
        // ie, account = main account with subscription, but accountID is to an account without a subscription

        const isAdmin = this.props.user && isPagelyAdmin(this.props.user);
        // Sometimes, user isn't loaded first so we don't know if we're an admin...
        let currentRole = 0;

        if (isAdmin !== null) { // isAdmin is null before user is loaded
          if (isAdmin) {
            currentRole = 10;
          } else {
            currentRole = permissions.canAccess.find((perm) => perm.targetId === accountId).role;
          }
        }

        // set up the bugsnag user data
        logger.client.setUser(data.id, data.email, data.name);
        logger.client.addMetadata('user', 'account_email', data.email);
        logger.client.addMetadata('user', 'account_name', data.name);
        logger.client.addMetadata('user', 'account_id', data.id);
        logger.client.addMetadata('user', 'user_email', this.props.user?.email);
        logger.client.addMetadata('user', 'user_name', this.props.user?.name);
        logger.client.addMetadata('user', 'role', currentRole);

        // set up livechat if
        const chatAvailable = !CHAT_UNAVAILABLE.includes(data.billingPlanId) && !p20Pools.includes(data.defPoolId);

        if (!isAdmin) {
          if (chatAvailable) {
            if (!window.zE) {
              setupChat();
            } else {
              populateChatInfo({ user: this.props.user, account: data }, false);
            }
          } else if (window.zE) {
            teardownChat();
          }
        }
      }).catch((err) => {
        // set up the bugsnag user data
        logger.client.setUser(this.props.user?.id, this.props.user?.email, this.props.user?.name);
        logger.client.addMetadata('user', 'accessing account', accountID);

        // eslint-disable-next-line
        console.log(err);
      });
    }

    fetchDomains(accountId);
    fetchApps(accountId);
    fetchServerTagsForAccount(accountId);
  };

  resetData = () => {
    const {
      resetStats,
      resetAppStats,
      resetApp,
      resetDiskAnalytics,
    } = this.props;

    resetStats();
    resetAppStats();
    resetDiskAnalytics();
    resetApp();
  };

  render() {
    const {
      account, match, user, permissions,
    } = this.props;

    if (!account.id && !account.isFetching && account.statusCode > 400) {
      return (
        <div>
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <AccessDeniedIcon
              color="error"
              style={{
                width: 80,
                height: 80,
              }}
            />
            <p style={{ marginBottom: 0 }}>{account.errMessage}</p>
            <p style={{ marginBottom: 0 }}><Link to={`/account/${user.id}`}>Back to your account</Link></p>
            <br />
            <div style={{
              fontSize: 16,
              lineHeight: 2,
              color: colors.navy30,
            }}
            >
              <p>You may be trying to access an account that you don&apos;t have permission to access</p>
              <p>If you are trying to access an account that requires Multi-Factor Authentication to be enabled,
                <br />
                you must have MFA enabled to access it. You can check your MFA settings <Link to={`/account/${user.id}/settings/security`}>here</Link>
              </p>
            </div>
          </div>
        </div>
      );
    }

    const isAdmin = this.props.user && isPagelyAdmin(this.props.user);

    if (!account.id || (account.id !== Number(match.params.accountID))) {
      return <Box padding="large" align="center"><Loading /></Box>;
    }

    return (
      <AccountContext.Provider value={account}>
        <div css={{
          padding: `${remMapper('mediumLarge')} ${remMapper('large')}`,
        }}
        >
          <div
            key={account.id}
            css={{
              position: 'relative',
            }}
          >
            <JobNotification account={account} isPagelyAdmin={isAdmin} />
            <AresDeployNotification account={account} isPagelyAdmin={isAdmin} />
            {/* HolidayBanner automatically displays between Dec 15th and Jan 2nd */}
            <HolidayBanner margin={{ bottom: 'mediumLarge' }} today={new Date()} />
            <ErrorBoundary>
              <NotificationBanner />
            </ErrorBoundary>
            <Route
              exact
              path={match.path}
              render={() => (
                <AdBlockerBanner />
              )}
            />
            {
            !account.active && permissions.canAccess.length > 0 && (
              <Banner
                heading="Account Deactivated"
                subheading={(
                  <span>
                    This account is marked as not active
                  </span>
                )}
                type="danger"
              />
            )
          }
            {
            account.disabled && permissions.canAccess.length > 0 && (
              <Banner
                heading="Account Disabled"
                subheading={(
                  <span>
                    This account is marked as disabled - you will not be able to make changes to anything but billing or contact support until the issue is resolved.
                  </span>
                )}
                type="danger"
              />
            )
          }
            <Switch>
              <Route
                exact
                path={match.path}
                render={() => {
                  return (
                    <PermissionsContext.Consumer>
                      {
                      (permissions) => {
                        // we need to either have permissions, or the user has to be a pagely admin.
                        const areSettingsReady = (permissions && permissions.canAccess.length > 0) || isAdmin;
                        return !areSettingsReady
                          ? <Loading />
                          : (
                            <AccountHeader
                              permissions={permissions}
                              account={account}
                              isPagelyAdmin={isAdmin}
                            />
                          );
                      }
                    }
                    </PermissionsContext.Consumer>
                  );
                }}
              />

            </Switch>
            <AdminContext.Provider value={isAdmin}>
              <div>
                <ErrorBoundary location={window.location.pathname}>
                  <Switch>
                    <Route path={`${match.path}/ssl`} component={SSLPage} />
                    <Route
                      path={`${match.path}/analytics`}
                      render={(props) => {
                        return account.isOnP20Account
                          ? (<AnalyticsPlaceholder />)
                          : <Analytics parentPath={`${match.path}/analytics`} {...props} />;
                      }}
                    />
                    <Route
                      path={`${match.path}/disk`}
                      render={(props) => {
                        return (
                          <ErrorBoundary>
                            <DiskPage {...props} account={account} user={this.props.user} parentPath={`${match.path}/disk`} />
                          </ErrorBoundary>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/integrations`}
                      component={Integrations}
                    />
                    <Route
                      path={`${match.path}/support`}
                      render={(props) => {
                        return (
                          <ErrorBoundary>
                            <SupportPage {...props} account={account} user={this.props.user} parentPath={`${match.path}/support`} />
                          </ErrorBoundary>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/team`}
                      render={(props) => {
                        return (
                          <PermissionsContext.Consumer>
                            {
                            (permissions) => {
                              // we need to either have permissions, or the user has to be a pagely admin.
                              const areSettingsReady = (permissions && permissions.canAccess.length > 0) || isAdmin;
                              return !areSettingsReady
                                ? <Loading />
                                : (
                                  <TeamPage
                                    {...props}
                                    permissions={permissions}
                                    isPagelyAdmin={isAdmin}
                                  />
                                );
                            }
                          }
                          </PermissionsContext.Consumer>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/settings`}
                      render={(props) => {
                        return (
                          <PermissionsContext.Consumer>
                            {
                            (permissions) => {
                              // we need to either have permissions, or the user has to be a pagely admin.
                              const areSettingsReady = (permissions && permissions.canAccess.length > 0) || isAdmin;
                              return !areSettingsReady
                                ? <Loading />
                                : (
                                  <SettingsPage
                                    {...props}
                                    parentPath={`${match.path}/settings`}
                                    permissions={permissions}
                                    isPagelyAdmin={isAdmin}
                                  />
                                );
                            }
                          }
                          </PermissionsContext.Consumer>
                        );
                      }}
                    />
                    <Route
                      path={`${match.path}/billing`}
                      render={(props) => {
                        return (
                          <PermissionsContext.Consumer>
                            {
                            (permissions) => {
                              // we need to either have permissions, or the user has to be a pagely admin.
                              const areSettingsReady = (permissions && permissions.canAccess.length > 0) || isAdmin;
                              return !areSettingsReady ? <Loading /> : <Billing account={account} permissions={permissions} isPagelyAdmin={isAdmin} {...props} />;
                            }
                          }
                          </PermissionsContext.Consumer>
                        );
                      }}
                    />
                    <Route path={`${match.path}/cdn/:cdnId`} component={CDNManager} />
                    <Route path={`${match.path}/cdn`} component={CDN} />
                    <Route path={`${match.path}/dns`} exact component={DNSZones} />
                    <Route path={`${match.path}/dns/:zoneId`} component={DNS} />
                    <Route path={`${match.path}/release-notes`} component={ReleaseNotes} />
                    <Route
                      path={`${match.path}/apps/:appId`}
                      render={(props) => (
                        <PermissionsContext.Consumer>
                          {
                          (permissions) => {
                            return permissions.directory?.[account.id]?.role === ROLES.APPONLYMINIMAL
                              ? (
                                <SftpOnly {...props} isAdmin={isAdmin} />
                              ) : (
                                <AppRoutes {...props} isAdmin={isAdmin} />
                              );
                          }
                        }
                        </PermissionsContext.Consumer>
                      )}
                    />
                    <Route path={`${match.path}/apps`} render={(props) => (<AppsList {...props} match={match} isAdmin={isAdmin} />)} />
                    <Route
                      path={`${match.path}/alerts`}
                      render={(props) => {
                        return (
                          <ErrorBoundary>
                            <Alerts {...props} match={match} isAdmin={isAdmin} />
                          </ErrorBoundary>
                        );
                      }}
                    />
                    <Route path={`${match.path}/email-verify/:token`} component={VerifyEmailPage} />
                    {
                    isAdmin && (
                      <Route
                        path={`${match.path}/configs`}
                        render={(props) => {
                          return (
                            <ErrorBoundary>
                              <Configs {...props} account={account} user={this.props.user} parentPath={`${match.path}/configs`} />
                            </ErrorBoundary>
                          );
                        }}
                      />
                    )
                  }
                    {/* {
                    isAdmin && (
                      <Route path={`${match.path}/configs/tools`} component={Tools} />
                    )
                  } */}
                    <Route
                      path={match.path}
                      render={(props) => <AccountDashboard {...props} isAdmin={isAdmin} />}
                    />
                  </Switch>
                </ErrorBoundary>
              </div>
            </AdminContext.Provider>
          </div>
        </div>
      </AccountContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    account: state.account,
    permissions: state.permissions,
    pendingDeploys: state.ares.pending,
  };
};
const mapDispatchToProps = {
  fetchAccount,
  fetchDomains: fetchDomainsForAccount,
  fetchApps: fetchAppsForAccount,
  fetchPendingServerConfigDeploys,
  fetchServerTagsForAccount,
  resetStats,
  resetAppStats,
  resetApp,
  resetDiskAnalytics,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
