import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import {
  certDomainLink, certDomains, certDomainSearch,
  certDomainsLinkAction,
  linkedCertRevert,
  linkedCerts,
} from 'shared/modules/ssl/redux/certDomainLink/reducer';
import {
  diskAddonPurchase,
  diskAddonCalculation,
} from 'shared/modules/billing/redux/disk/reducer';
import {
  customer, subscriptions, plans, invoices,
} from 'shared/modules/billing/redux/reducer';
import { servertags, mounts } from 'shared/modules/server/redux/reducer';
import { api } from 'shared/modules/webapp/redux/reducer';
import { status } from 'shared/modules/status/redux/reducer';
import { integrations } from 'shared/modules/integrations/redux/reducer';
import {
  account,
  accountCollaborator,
  accountCollaborators, accountSSHKey, accountSSHKeys, accountTags,
  emailVerifyRequest,
} from 'shared/modules/account/redux/reducer';
import { checkEmailToken } from 'shared/modules/account/redux/authorization/reducer';
import { certs } from 'shared/modules/ssl/redux/certs/reducer';
import {
  forgotPassword, login, resetMfa, ssoMfaRequest, ssoMfaVerify,
} from 'shared/modules/authentication/redux/reducer';
import { csr } from 'shared/modules/ssl/redux/csr/reducer';
import { permissions, user } from 'shared/modules/permissions/user/reducer';
import {
  app, appTags, metadata,
} from 'shared/modules/app/redux/reducer';
import { ares } from 'shared/modules/ares/redux/reducer';
import { appDns } from 'shared/modules/app/redux/dns/reducer';
import { domains, appDomains } from 'shared/modules/app/redux/domains/reducer';
import { appBackup, appBackups } from 'shared/modules/app/redux/backups/reducer';
import { apps } from 'shared/modules/apps/redux/reducer';
import { cert } from 'shared/modules/ssl/redux/cert/reducer';
import { letsencryptStatus } from 'shared/modules/ssl/redux/letsencrypt/reducer';

import { report, usage } from 'shared/modules/disk/redux/reducer';
import { dnsZones, dnsRecords } from 'shared/modules/dns/redux/reducer';
import {
  cdnApp, cdnForAccount, cdnCheck, fsaStatus,
} from 'shared/modules/cdn/redux/reducer';
import {
  tickets, ticket, bannedPlugins, attachments,
} from 'shared/modules/support/redux/reducer';
import {
  releaseNotes,
} from 'shared/modules/releasenotes/redux/reducer';
import {
  cpu, daily as analyticsDaily, appAnalytics, disk,
} from 'shared/modules/analytics/redux/reducers';
import { alerts } from 'shared/modules/alerts/redux/reducer';
import { sso } from 'shared/modules/team/redux/sso/reducer';
import { coreApi } from 'shared/utils/redux/query';
import { gitWebhookLogs, gitIntegrationLogs } from './app/redux/logging/reducer';

export const baseReducers = {
  api,
  login,
  requests: combineReducers({
    forgotPassword,
    resetMfa,
    emailVerifyRequest,
    checkEmailToken,
    auth: combineReducers({
      ssoMfaRequest,
      ssoMfaVerify,
    }),
  }),

  status,

  user,
  permissions,
  accountSSHKeys,
  accountSSHKey,

  // accounts
  account,

  integrations,

  // apps
  domains,
  apps,
  app: combineReducers({
    app,
    appDomains,
    dns: appDns,
    metadata,
  }),
  backups: combineReducers({ backup: appBackup, backups: appBackups }),
  logs: combineReducers({ gitWebhook: gitWebhookLogs, gitIntegration: gitIntegrationLogs }),
  ares,

  // team
  accountCollaborators,
  accountCollaborator,
  sso,

  // analytics
  analytics: combineReducers({
    cpu,
    disk,
    daily: analyticsDaily,
    app: appAnalytics,
  }),

  // billing
  billing: combineReducers({
    diskAddonCalculation,
    diskAddonPurchase,
    customer,
    subscriptions,
    invoices,
    plans,
  }),

  // CDN
  cdn: combineReducers({
    cdnApp,
    cdnForAccount,
    cdnCheck,
    fsaStatus,
  }),

  disk: combineReducers({
    usage,
    report,
  }),

  dns: combineReducers({
    dnsZones,
    dnsRecords,
  }),

  tags: combineReducers({
    server: servertags,
    account: accountTags,
    app: appTags,
  }),

  // ssl
  csr,
  cert,
  certs,
  certDomainLink,
  certDomains,
  certDomainSearch,
  linkedCerts,
  linkedCertRevert,
  certDomainsLinkAction,
  letsencryptStatus,

  // alerts
  alerts,

  // serverconfig
  mounts,

  // support
  support: combineReducers({
    attachments,
    tickets,
    ticket,
    bannedPlugins,
  }),

  // releasenotes
  releaseNotes,

  // reduxy
  form: formReducer,

  [coreApi.reducerPath]: coreApi.reducer,
};
