import React from 'react';
import { connect } from 'react-redux';
import type { Location } from 'history';
import type { match as Match } from 'react-router';
import Box from 'shared/styleguide/atoms/Box';
import { retrieveSsoUrls } from 'shared/modules/authentication/redux/actions';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import type { RetrieveSsoUrls } from '../../types/AccountUrl';
import LoginForm from './LoginForm';

interface Props {
  isAdmin: boolean;
  retrieveSsoUrls: RetrieveSsoUrls;
  a11yTitle: string;
  handleLogin?: (username: any, password: any, mfa: any, preserveUrl?: Record<string, any>) => ((dispatch: any, getState: any) => any);
  match: Match;
  location: Location;
  preserveUrl?: boolean;
  isFetching: boolean;
  errMessage: string;
}

/**
 * This is the root component for the Unified Login. All of the logic
 *  is contained within the LoginForm component.
 * This wrapping is primarily to clean up the formatting, so it's not all of the
 *  styling for the stuff around the login form and the login form itself, all
 *  in a single component.
 * @see LoginForm
 */
const UnifiedLogin: React.FC<Props> = ({
  isAdmin, retrieveSsoUrls: getSsoUrls, a11yTitle: ariaHeaderText, ...props
}) => (
  <Box a11yTitle={ariaHeaderText} as="main" css={{ width: '100%' }}>
    <LoginForm retrieveSsoUrls={(username: string) => getSsoUrls(username, isAdmin)} isAdmin={isAdmin} {...props} />
    <Box align="center" margin={{ top: 'medium' }}>
      <TextLink color="textPrimary" to="/help">Trouble logging in?</TextLink>
    </Box>
  </Box>
);

export default connect(null, { retrieveSsoUrls })(UnifiedLogin);
