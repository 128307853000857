export const SERVER_TAGS_REQUEST = 'SERVER_TAGS_REQUEST';
export const SERVER_TAGS_SUCCESS = 'SERVER_TAGS_SUCCESS';
export const SERVER_TAGS_FAILURE = 'SERVER_TAGS_FAILURE';

export const SERVER_META_REQUEST = 'SERVER_META_REQUEST';
export const SERVER_META_SUCCESS = 'SERVER_META_SUCCESS';
export const SERVER_META_FAILURE = 'SERVER_META_FAILURE';

export const SERVER_TAGS_RESET = 'SERVER_TAGS_RESET';

export const SERVER_MOUNTS_REQUEST = 'SERVER_MOUNTS_REQUEST';
export const SERVER_MOUNTS_SUCCESS = 'SERVER_MOUNTS_SUCCESS';
export const SERVER_MOUNTS_FAILURE = 'SERVER_MOUNTS_FAILURE';

export const APP_UPDATE_CONFIG_REQUEST = 'SERVER_APP_UPDATE_CONFIG_REQUEST';
export const APP_UPDATE_CONFIG_SUCCESS = 'SERVER_APP_UPDATE_CONFIG_SUCCESS';
export const APP_UPDATE_CONFIG_FAILURE = 'SERVER_APP_UPDATE_CONFIG_FAILURE';

export const APP_GET_CONFIG_REQUEST = 'SERVER_APP_GET_CONFIG_REQUEST';
export const APP_GET_CONFIG_SUCCESS = 'SERVER_APP_GET_CONFIG_SUCCESS';
export const APP_GET_CONFIG_FAILURE = 'SERVER_APP_GET_CONFIG_FAILURE';

export const APP_DELETE_CONFIG_REQUEST = 'SERVER_APP_DELETE_CONFIG_REQUEST';
export const APP_DELETE_CONFIG_SUCCESS = 'SERVER_APP_DELETE_CONFIG_SUCCESS';
export const APP_DELETE_CONFIG_FAILURE = 'SERVER_APP_DELETE_CONFIG_FAILURE';

export const ARES_POOL_RELOAD_REQUEST = 'SERVER_ARES_POOL_RELOAD_REQUEST';
export const ARES_POOL_RELOAD_SUCCESS = 'SERVER_ARES_POOL_RELOAD_SUCCESS';
export const ARES_POOL_RELOAD_FAILURE = 'SERVER_ARES_POOL_RELOAD_FAILURE';
