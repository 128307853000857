export const METADATA_ADD_TAG_REQUEST = 'METADATA_ADD_TAG_REQUEST';
export const METADATA_ADD_TAG_SUCCESS = 'METADATA_ADD_TAG_SUCCESS';
export const METADATA_ADD_TAG_FAILURE = 'METADATA_ADD_TAG_FAILURE';

export const METADATA_REMOVE_TAG_REQUEST = 'METADATA_REMOVE_TAG_REQUEST';
export const METADATA_REMOVE_TAG_SUCCESS = 'METADATA_REMOVE_TAG_SUCCESS';
export const METADATA_REMOVE_TAG_FAILURE = 'METADATA_REMOVE_TAG_FAILURE';

export const GET_APP_META_REQUEST = 'METADATA_GET_APP_META_REQUEST';
export const GET_APP_META_SUCCESS = 'METADATA_GET_APP_META_SUCCESS';
export const GET_APP_META_FAILURE = 'METADATA_GET_APP_META_FAILURE';
