import React, { useState, useEffect } from 'react';
import {
  object, array, bool,
} from 'prop-types';
import qs from 'qs';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { ResponsiveLine } from '@nivo/line';

import { Tooltip, Typography, useTheme } from '@material-ui/core';

import { getDiskUsageByLocation } from 'shared/modules/analytics/redux/actions/disk';
import { consolidateErrors } from 'shared/utils/validation';
import { ErrorText } from 'shared/styleguide/typography';
import Loading from 'shared/styleguide/atoms/Loading';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';

import { createGraphConfig, StatsBox } from './statsUtils';
import { GRAPH_TYPES } from './constants';

const DAYS_SPREAD = 30;

const AppDiskStats = ({
  match, diskGraphData, diskMeta, diskUsageData, isOnP20Account,
}) => {
  const theme = useTheme();
  const gridColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [diskUsage, setDiskUsage] = useState(diskUsageData ?? null);
  const [queryParams, setQueryParams] = useState(null);
  const [meta, setMeta] = useState(diskMeta ?? null);
  const [data, setData] = useState(diskGraphData ?? []);

  const fetchLocationUsage = async (accountId, params) => {
    let diskUsageResults;
    try {
      diskUsageResults = await dispatch(getDiskUsageByLocation(accountId, {
        ...params,
      }));
      setDiskUsage(diskUsageResults.data);
      setErrors(null);
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
  };

  const createDiskGraphData = (data) => {
    const metadata = {
      forceYAxis: true,
      maxValue: 0,
      firstTick: null,
      lastTick: null,
    };

    const graphData = data
      .map((path) => {
        const values = path.values
          .filter((e, i) => {
            // grab one value per day (data comes every 6 hrs)
            return ((i % 4 === 3) && e[2]);
          })
          .map((value) => {
            const date = value[0];
            const usage = value[2];

            if (usage > metadata.maxValue) {
              metadata.maxValue = usage;
            }

            if (metadata.forceYAxis && usage > 0) {
              metadata.forceYAxis = false;
            }
            return {
              'x': new Date(date),
              'y': usage,
            };
          });

        metadata.firstTick = values[0].x;
        metadata.lastTick = values[values.length - 1].x;

        return {
          id: GRAPH_TYPES.disk,
          data: values.filter((v) => v.y),
        };
      });

    setMeta({ [GRAPH_TYPES.disk]: metadata });

    return graphData;
  };

  useEffect(() => {
    if (!isOnP20Account) {
      const { accountID, appId } = match?.params;
      const params = {
        fromDate: moment().subtract(DAYS_SPREAD, 'days').tz('UTC').format(),
        toDate: moment().tz('UTC').endOf('day').format(),
        pathType: ['app'],
        appIds: [appId],
      };
      setQueryParams(params);
      fetchLocationUsage(accountID, params);
    }
  }, []);

  useEffect(() => {
    if (diskUsage?.series.length && !data.length && !isOnP20Account) {
      setData(createDiskGraphData(diskUsage.series));
    }
  }, [diskUsage, data, isOnP20Account]);

  if (errors) {
    return (
      <Box align="center" margin={{ top: 'medium' }}>
        <ErrorText>{errors}</ErrorText>
      </Box>
    );
  }

  if (isOnP20Account) {
    return (
      <Box css={css`width: 100%;`} margin={{ top: 'small' }}>
        <Empty>
          <Typography variant="body1">This feature is not available for your configuration.</Typography>
        </Empty>
      </Box>
    );
  }

  if (diskUsage && !diskUsage?.series.length) {
    return (
      <Box css={css`width: 100%;`} margin={{ top: 'small' }}>
        <Empty>
          <Typography variant="body1">No disk usage data available.</Typography>
        </Empty>
      </Box>
    );
  }

  if (!diskUsage || !data.length || !meta) {
    return <Loading />;
  }

  const paramString = queryParams ? `?${qs.stringify({ byPath: { ...queryParams } })}` : '';

  const graphProps = createGraphConfig(GRAPH_TYPES.disk, meta);

  return (
    <Tooltip title="View more details in PressFormance" placement="top">
      <Link to={`/account/${match.params.accountID}/analytics/disk-usage${paramString}`} css={css`width: 100%;`}>
        <StatsBox align="center">
          <ResponsiveLine
            data={data}
            {...graphProps}
            theme={{
              'textColor': theme.palette.text.secondary,
              'axis': {
                'domain': {
                  'line': {
                    'stroke': gridColor,
                    'strokeWidth': 1,
                  },
                },
                'ticks': {
                  'line': {
                    'stroke': gridColor,
                    'strokeWidth': 1,
                  },
                },
              },
              'grid': {
                'line': {
                  'stroke': gridColor,
                  'strokeWidth': 1,
                },
              },
            }}
          />
        </StatsBox>
      </Link>
    </Tooltip>
  );
};

AppDiskStats.propTypes = {
  diskGraphData: array,
  diskMeta: object,
  diskUsageData: object,
  isOnP20Account: bool,
  match: object,
};

export default AppDiskStats;
