import React from 'react';
import {
  number, func, object,
} from 'prop-types';
import { connect } from 'react-redux';

import useDebouncedCallback from 'shared/utils/hooks/useDebouncedCallback';

import LoadData from '../LoadData';
import {
  getDimensionsByMount,
  getDiskUsageByMount,
} from '../../redux/actions/disk';

export const LoadDiskByMount = ({
  accountId, getDimensions, getData, diskUsage, startDate, endDate, ...props
}) => {
  const debouncedGetData = useDebouncedCallback((accountId, options) => {
    getData(accountId, options);
  }, 2000, {});

  return (
    <LoadData
      {...props}
      accountId={accountId}
      getDimensions={getDimensions}
      getData={debouncedGetData}
      path="byMount"
      dimensions={diskUsage.dimensions}
      chartData={diskUsage.usage}
      defaultLeftAxis="storage_per"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

LoadDiskByMount.propTypes = {
  accountId: number,
  diskUsage: object.isRequired,
  endDate: object.isRequired,
  getData: func.isRequired,
  getDimensions: func.isRequired,
  startDate: object.isRequired,
};

export default connect(
  (state) => ({
    accountId: state.account.id,
    diskUsage: state.analytics.disk.byMount,
  }),
  {
    getData: getDiskUsageByMount,
    getDimensions: getDimensionsByMount,
  },
)(LoadDiskByMount);
