import React from 'react';
import { string } from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import { NOTICE_COLORS } from '../../constants';

const NoticeBox = styled(Box)(
  ({ color }) => {
    switch (color) {
      case NOTICE_COLORS.green:
        return css`
          background: #32de8a;
        `;
      case NOTICE_COLORS.orange:
        return css`
          background: #EEA322;
          color: #ffffff;
        `;
      case NOTICE_COLORS.red:
        return css`
          background: #e01b4a;
          color: #ffffff;
        `;
      default:
        return css`
          background: #ced4da;
        `;
    }
  },
  css`
    border-bottom: 1px solid #ebebeb;
  `,
);

const Notice = ({ text, color }) => {
  return (
    <NoticeBox padding="small" color={color}>
      <Typography
        variant="h3"
        css={css`
        font-family: Lato, Roboto, Helvetica, Arial, sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
      `}
      >
        {text}
      </Typography>
    </NoticeBox>
  );
};

Notice.propTypes = {
  color: string,
  text: string,
};

export default Notice;
