import React, { Fragment, useState } from 'react';
import { func, node } from 'prop-types';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import { IconButton, Typography } from '@material-ui/core';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import DangerButton from 'shared/styleguide/atoms/Buttons/DangerButton';
import Box from 'shared/styleguide/atoms/Box';

const DestructiveAction = ({
  onConfirm, children, explainerText, secondaryText, icon = null, ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <Fragment>
      {
        icon ? (
          <IconButton onClick={() => setOpen(true)} {...props}>
            {icon}
          </IconButton>
        ) : (
          <DangerButton variant="outlined" onClick={() => setOpen(true)} {...props}>
            {children}
          </DangerButton>
        )
      }
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {children}
        </DialogTitle>
        <DialogContent component="div">
          <Box>
            <DialogContentText component="div">
              <Typography component="div" color="textPrimary" variant="body1" gutterBottom>
                {explainerText}
              </Typography>
              {
                secondaryText && (
                  <Typography component="div" color="textSecondary" variant="body2">
                    {secondaryText}
                  </Typography>
                )
              }
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box direction="row" justify="space-between" flex={1}>
            <Button autoFocus color="default" variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <DangerButton
              variant="contained"
              onClick={() => {
                onConfirm();
                setOpen(false);
              }}
            >
              Yes, {children}
            </DangerButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

DestructiveAction.propTypes = {
  explainerText: node,
  icon: node,
  onConfirm: func.isRequired,
  secondaryText: node,
};

export default DestructiveAction;
