import React from 'react';
import {
  bool,
  oneOfType,
  string,
  func,
  node,
} from 'prop-types';
import { useTheme, Card as MuiCard } from '@material-ui/core';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledCard = styled(({
  theme, column, row, ...rest
}) => <MuiCard {...rest} />)(
  ({ theme }) => css`
    display: flex;
    color: ${theme.palette.text.primary};
  `,
  ({ column }) => column && css`
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  `,
  ({ row }) => row && css`
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  `,
);

const Card = ({
  as = 'div',
  column = true,
  row = false,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledCard
      as={as}
      column={column}
      row={row}
      theme={theme}
      {...props}
    />
  );
};

Card.propTypes = {
  as: oneOfType([string, func, node]),
  column: bool,
  row: bool,
};

Card.displayName = 'Card';

export default Card;
