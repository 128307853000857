import React from 'react';
import { object, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import ReactMarkdown from 'react-markdown';

import {
  Paper, Tooltip, Typography,
} from '@material-ui/core';

import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import PasswordRequirements from 'shared/styleguide/molecules/PasswordField/PasswordRequirements';
import {
  validatePassword,
} from 'shared/utils/validation';

import { updateAccount } from 'shared/modules/account/redux/actions';

const Password = ({
  account, user, updateAccount,
}) => {
  const isAdmin = user?.user?.adminAccount;

  const onSubmit = async (values, actions) => {
    let hasErrors = false;

    if (!isAdmin && !values.oldPassword) {
      actions.setFieldError('oldPassword', 'Current Password required');
      hasErrors = true;
    }

    if (!isAdmin && !values.currentPIN) {
      actions.setFieldError('currentPIN', 'Current PIN required');
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    const { confirmPassword, ...changed } = values;
    actions.setStatus('loading');

    try {
      await updateAccount(account.id, changed);
      actions.resetForm();
      setTimeout(() => {
        actions.setStatus('');
      }, 5000);
      actions.setStatus('success');
    } catch (err) {
      if (err.response?.status === 422) {
        const fields = err.response?.data?.body;
        Object.keys(fields).forEach((key) => {
          actions.setFieldError(key, fields[key].messages
            .join('\n\n\n ')
            .replace(/\\n/g, '\n\n\n')
            .replace(/^"|"$/g, ''));
        });
      }
      actions.setStatus('initial');
    }
  };

  return (
    <Paper>
      <Box padding="medium">
        <Typography variant="h4" color="textPrimary">Change Password </Typography>
        <Box>
          <Formik
            initialStatus="initial"
            initialValues={{
              currentPIN: '',
              oldPassword: '',
              password: '',
              confirmPassword: '',
            }}
            onSubmit={onSubmit}
            validate={(values) => {
              const errors = {};

              const [message, rules] = validatePassword(values.password);

              if (message !== null) {
                errors.password = message;
                errors.passwordRules = rules;
              }

              if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Passwords don't match";
              }

              return errors;
            }}
          >
            {({
              values,
              errors,
              status,
              handleChange,
              handleSubmit,
              isValid,
            }) => (
              <form onSubmit={handleSubmit}>
                {
                  !isAdmin && (
                    <Box direction="row" gap="medium" wrap="wrap">
                      <WideTextField
                        id="oldPassword"
                        autoComplete="off"
                        placeholder="Current Password"
                        value={values.oldPassword}
                        error={Boolean(errors.oldPassword)}
                        helperText={<ReactMarkdown source={errors.oldPassword} />}
                        onChange={handleChange}
                        margin="normal"
                        type="password"
                      />
                      <WideTextField
                        id="currentPIN"
                        autoComplete="off"
                        placeholder="Current PIN"
                        value={values.currentPIN}
                        error={Boolean(errors.currentPIN)}
                        helperText={<ReactMarkdown source={errors.currentPIN} />}
                        onChange={handleChange}
                        margin="normal"
                        type="password"
                      />
                    </Box>
                  )
                }
                <Box direction="row" gap="medium" wrap="wrap">
                  <Tooltip
                    disableHoverListener
                    arrow
                    title={(
                      <PasswordRequirements rules={errors?.passwordRules} />
                    )}
                  >
                    <WideTextField
                      id="password"
                      autoComplete="off"
                      placeholder="New Password"
                      required
                      value={values.password}
                      error={Boolean(errors.password)}
                      helperText={<ReactMarkdown source={errors.password} />}
                      onChange={handleChange}
                      margin="normal"
                      type="password"
                    />
                  </Tooltip>
                  <WideTextField
                    id="confirmPassword"
                    autoComplete="off"
                    placeholder="Confirm New Password"
                    required
                    value={values.confirmPassword}
                    error={Boolean(errors.confirmPassword)}
                    helperText={<ReactMarkdown source={errors.confirmPassword} />}
                    onChange={handleChange}
                    margin="normal"
                    type="password"
                  />
                </Box>
                <Box padding={{ top: 'small', bottom: 'small' }}>
                  <Typography variant="body2" color="textSecondary">
                    A confirmation email will be sent to your email ({account.email})
                  </Typography>
                </Box>
                <Box row padding={{ top: 'small', bottom: 'small' }}>
                  <Button
                    status={status}
                    type="submit"
                    variant="contained"
                    disabled={!isValid}
                  >
                    Save New Password
                  </Button>
                </Box>
              </form>

            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
};

Password.propTypes = {
  account: object,
  isAdmin: bool,
  updateAccount: func.isRequired,
  user: object,
};

export default connect(
  (state) => ({
    user: state.user,
    account: state.account,
  }),
  {
    updateAccount,
  },
)(Password);
