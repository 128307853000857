import CPU from './CPU/LoadCPU';
import DiskUsage from '../components/Disk';

export const routes = [
  {
    label: 'CPU',
    to: 'cpu',
    match: new RegExp(/\/analytics\/cpu$/), // eslint-disable-line
    component: CPU,
  },
  {
    label: 'Disk Usage',
    to: 'disk-usage',
    match: new RegExp(/\/analytics\/disk-usage$/), // eslint-disable-line
    // component: DummyComponent,
    component: DiskUsage,
  },
];
