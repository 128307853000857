import React from 'react';
import {
  object, shape, func, number, bool, string,
} from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import {
  accountPropTypes,
  collaboratorCollectionPropTypes, collaboratorPropTypes,
} from 'shared/modules/account/models/model';
import Loading from 'shared/styleguide/atoms/Loading';
import { consolidateTeamPermissions } from '../../redux/utils';
import Collaborator from './Collaborator';
import { useGetAccountCollaboratorsQuery } from '../../redux/query';

const collabSorter = (a, b) => {
  if (a.role < b.role) return 1;
  if (a.role > b.role) return -1;
  return 0;
};

const TeamList = (props) => {
  const {
    user, account, filter,
  } = props;
  const apps = props.apps.data;

  const { data: collaborators, error, isLoading } = useGetAccountCollaboratorsQuery(account.id);
  if (isLoading) {
    return <Loading />;
  }

  return (
    <TableContainer>
      <Table aria-label="collaborators table">
        <TableBody>
          {
              collaborators.filter(((r) => {
                const matchesName = r.accessedByName.toLowerCase().match(filter);
                const matchesEmail = r.accessedByEmail.toLowerCase().match(filter);
                return matchesEmail || matchesName;
              }))
              // sort them by primary owner
                .sort(collabSorter)
                .map((collab, index) => {
                  const isYou = collab.accessedByEmail === user.email;
                  return (
                    <Collaborator
                      isPagelyAdmin={props.isPagelyAdmin}
                      key={`${collab.sourceId}-${collab.targetId}-${filter}`}
                      editorRole={props.role}
                      isYou={isYou}
                      collab={collab}
                      accountId={account.id}
                      apps={apps}
                      userPool={props.userPool}
                      updateCollaborator={props.updateCollaborator}
                      removeCollaborator={props.removeCollaborator}
                      addCollaborator={props.addCollaborator}
                    />
                  );
                })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};
TeamList.propTypes = {
  account: shape(accountPropTypes),
  addCollaborator: func.isRequired,
  apps: object.isRequired,
  collaborator: shape(collaboratorPropTypes),
  collaborators: collaboratorCollectionPropTypes,
  filter: string,
  isPagelyAdmin: bool,
  removeCollaborator: func,
  resetCollaborator: func.isRequired,
  role: number,
  updateAccount: func,
  updateCollaborator: func.isRequired,
  user: object,
  userPool: object,
};

export default TeamList;
