import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from '@emotion/styled';

import Typography from '@material-ui/core/Typography';
import { TextArea } from 'shared/styleguide/atoms/Input/TextArea';
import Box from 'shared/styleguide/atoms/Box';
import CopyButton from 'shared/styleguide/molecules/CopyButton';

const CONFIG_ROWS = 24;

const CodeTextArea = styled(TextArea)`
  border: 1px solid #ccc;
  font-size: .9rem;
`;

const getAccountId = (value) => {
  if (!value) return null;
  const [type, id] = value.split(':');
  return type.includes('Accounts') ? id : null;
};

const ConfigsDetail = ({ data }) => {
  const stringDetails = {
    'Account Id': data.accountId,
    'Pool Id': data.poolId,
    'Server Id': data.serverId,
    'Mode': data.mode !== 'default' ? data.mode : null,
    'Initiator': data.initiator?.includes(':') ? getAccountId(data.initiator) : data.initiator,
    'Changer': data.lastEditedBy?.includes(':') ? getAccountId(data.lastEditedBy) : data.lastEditedBy,
    'Created': data.createdDate ? moment(data.createdDate).format('YYYY-MM-DD HH:mm') : null,
    'Modified': data.modifiedDate ? moment(data.modifiedDate).format('YYYY-MM-DD HH:mm') : null,
  };

  const horizontalDetails = Object.keys(stringDetails)
    .filter((k) => stringDetails[k])
    .map((key) => {
      return (
        <Box key={key} gap="small">
          <Typography variant="body2" gutterBottom>{key}</Typography>
          <Typography variant="body2">{stringDetails[key]}</Typography>
        </Box>
      );
    });

  return (

    <Box padding="small">
      {
        horizontalDetails.length > 0
        && (
          <Box row justify="flex-start" gap="small" margin={{ bottom: 'medium' }}>
            {horizontalDetails}
          </Box>
        )
      }
      {
        data.description
        && (
          <Box margin={{ bottom: 'medium' }}>
            <Typography variant="body2">Description</Typography>
            <Box margin={{ top: 'small' }}>
              <Typography variant="body2">{data.description}</Typography>
            </Box>
          </Box>
        )
      }
      <Box>
        <Box row align="center">
          <Typography variant="body2">Config</Typography>
          <CopyButton
            tooltip="Copy config"
            text={JSON.stringify(data.config, null, 2)}
            message="Config copied to clipboard!"
            fontSize="small"
          />
        </Box>
        <CodeTextArea
          autoFocus
          name="config"
          code
          readOnly
          maxRows={CONFIG_ROWS}
          fullWidth
          value={JSON.stringify(data.config, null, 2)}
        />
      </Box>
    </Box>
  );
};

ConfigsDetail.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
};

export default ConfigsDetail;
