import { development } from 'shared/flags';
import { coreApi } from 'shared/utils/redux/query';

export const supportApi = coreApi.injectEndpoints({
  overrideExisting: development,
  endpoints: (builder) => ({
    getArticlesByCategory: builder.query<{ data: unknown[]}, string>({
      query: (category) => `/support/articles?category=${category}`,
    }),
    getArticlesByLabel: builder.query<{ data: unknown[]}, string>({
      query: (label) => `/support/articles?labels[]=${label}`,
    }),
  }),
});

export const {
  useGetArticlesByCategoryQuery,
  useGetArticlesByLabelQuery,
} = supportApi;
