import React, { Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { any } from 'prop-types';

import {
  DialogTitle, DialogContent, DialogActions, Dialog, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import NewButton from 'shared/styleguide/atoms/Buttons/NewButton';
import TextArea from 'shared/styleguide/atoms/Input/TextArea';
import Box from 'shared/styleguide/atoms/Box';
import Success from 'shared/styleguide/atoms/Success';
import Select from 'shared/styleguide/atoms/Select/Select';
import ModalErrors from './ModalErrors';

import {
  setInputErrors, importModalOpen, submitImport, clearImportError, importExample,
} from '../../redux/actions';

import { inline } from './styles';

const ImportConfig = ({
  inputErrors, modalOpen, importModalOpen, submitImport, clearImportError, configSuccess, importExample, importConfig,
}) => {
  const inputRef = useRef(null);
  const MODAL_ROWS = 24;

  const handleSubmit = (e) => {
    e.preventDefault();
    submitImport(inputRef.current.value);
  };

  const handleClear = (e) => {
    e.preventDefault();
    inputRef.current.value = '';
    clearImportError();
  };

  const optsSelect = [
    { value: 'REDIRECTS_add_query_args', label: 'REDIRECTS: Add query args' },
    { value: 'REDIRECTS_simple_redirect_map', label: 'REDIRECTS: Simple redirect map' },
    { value: 'RULES_uploads_proxy_to_staging', label: 'RULES: Uploads proxy to staging' },
    { value: 'MISSRULES_rate_limit_bots', label: 'MISS-RULES: Rate limit bots' },
    { value: 'MISSRULES_rewrite', label: 'MISS-RULES: Rewrite' },
  ];

  let importConfigData;
  if (importConfig !== false) {
    importConfigData = JSON.stringify(importConfig, null, 2);
    if (inputRef?.current) {
      inputRef.current.value = importConfigData;
    }
  }
  let renderImportModalContent;

  if (configSuccess === true) {
    renderImportModalContent = (
      <DialogContent>
        <Box style={inline.modal}>
          <Success
            classes={{}}
            primaryMessage="Config imported successfully"
          />
        </Box>
      </DialogContent>
    );
  } else {
    renderImportModalContent = (
      <div>
        <form id="importConfigForm" onSubmit={handleSubmit}>
          <DialogContent>
            <div style={{ marginBottom: 0 }}>
              { /* eslint-disable jsx-a11y/no-onchange */}
              <Select
                onChange={(e) => {
                  if (e.value !== null) {
                    importExample(e.value);
                  }
                }}
                options={optsSelect}
                placeholder="Import Example..."
                value="Import Example..."
              />
            </div>
            <TextArea
              style={{ background: 'white' }}
              autoFocus
              inputRef={inputRef}
              code
              maxRows={MODAL_ROWS}
              rows={MODAL_ROWS}
              fullWidth
            />
            <div id="importErrors">
              <ModalErrors errorData={inputErrors} errorHeading="INVALID CONFIG" />
            </div>
          </DialogContent>
          <DialogActions>
            <Box row justify="space-between" flex={1}>
              <NewButton
                color="default"
                name="Clear"
                type="button"
                onClick={handleClear}
              >
                Clear
              </NewButton>
              <NewButton
                color="secondary"
                variant="contained"
                name="Submit"
                type="submit"
              >
                Submit
              </NewButton>
            </Box>
          </DialogActions>
        </form>
      </div>
    );
  }

  return (
    <Fragment>
      <NewButton
        color="default"
        variant="outlined"
        key="importConfig"
        name="importConfig"
        onClick={() => { importModalOpen(true); }}
      >
        Import JSON
      </NewButton>
      <Dialog
        PaperProps={{ style: inline.dialog }}
        open={modalOpen || false}
        onClose={() => { importModalOpen(false, null); }}
        maxWidth="md"
        fullWidth
      >
        <div>
          <DialogTitle>
            Import Json Config
            <div style={inline.closeIcon}>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => { importModalOpen(false, null); }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          {renderImportModalContent}
        </div>
      </Dialog>
    </Fragment>
  );
};

ImportConfig.propTypes = {
  clearImportError: any,
  configSuccess: any,
  importConfig: any,
  importExample: any,
  importModalOpen: any,
  inputErrors: any,
  modalOpen: any,
  submitImport: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    inputErrors: state.aresConfig.imports.inputErrors,
    modalOpen: state.aresConfig.imports.modalOpen,
    configSuccess: state.aresConfig.imports.configSuccess,
    importConfig: state.aresConfig.imports.importConfig,
  };
};

export default connect(mapStateToProps, {
  setInputErrors, importModalOpen, submitImport, clearImportError, importExample,
})(ImportConfig);
