import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  DialogContent,
  Dialog,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import { getSortedFsaSetupJobsForApp } from 'shared/modules/status/redux/selectors';
import { getAtomicBaseDomain } from 'shared/config';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import Card from 'shared/styleguide/atoms/Card/Card';
import { ErrorText } from 'shared/styleguide/typography';
import * as STATUS from 'shared/modules/status/redux/constants';

import {
  fetchApp, fetchCredentialsForApp,
} from 'shared/modules/app/redux/actions';
import type { NumberLike } from 'shared/types/types';
import type { ReduxStore } from 'shared/utils/redux/apiReducer';

import styles from 'shared/modules/app/components/App.scss';
import { useNewAppContext } from 'shared/modules/apps/context/NewAppContext';
import { App } from 'shared/types/App';
import { ApiAction } from 'shared/utils/redux/apiActions';
import CredentialsNewApp from './CredentialsNewApp';
import CredentialsPreview from './CredentialsPreview';
import CredentialsLogin from './CredentialsLogin';

const useStyles = makeStyles(() => {
  return ({
    paper: {
      overflowY: 'visible',
    },
  });
});

const dialogTitleStyles = () => ({
  root: {
    marginBottom: 0,
    padding: '8px 24px 2px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

// @ts-ignore
const DialogTitle = withStyles(dialogTitleStyles)((props: { classes: any; onClose: () => any; children?: any}) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      {children}
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

interface CredentialProps {
  accountId: number;
  app: ReduxStore<App & {
      credentials: Record<string, any>;
  }>;
  appCreation?: boolean;
  getCredentialsForApp: (appId: NumberLike) => any;
  getApp: (appId: NumberLike) => Promise<App>;
  fsaJobs: any;
}

export const Credentials = ({
  accountId, app, appCreation, getApp, getCredentialsForApp, fsaJobs,
}: CredentialProps): React.ReactElement => {
  const [credentialsError, setCredentialsError] = useState(null);
  // if context is undefined, then the app is loaded from within app overview
  // not apps list.
  const { state: appState, mergeState: setAppState } = useNewAppContext();
  // appCreation is likely true

  const loadedAppId = app.data?.id;
  const loadedAppAliases = app.data?.aliases;
  const credentials = app.data?.credentials;
  const credentialsUsername = credentials?.data?.username;
  const classes = useStyles();

  const open = !appState.createModalOpen && appState.showCredentials === 'show';

  useEffect(
    () => {
      if (open && appState.newAppId !== null) {
        if (appState.newAppId !== loadedAppId || !loadedAppAliases) {
          // get fresh app data, aliases and credentials
          getApp(appState.newAppId)
            .then(() => getCredentialsForApp(appState.newAppId))
            .catch((err) => {
              const errorMessage = err.response?.data?.message || '';
              setCredentialsError(`Unable fetch app. ${errorMessage}`);
            });
        }

        if (appState.newAppId === loadedAppId && !credentialsUsername) {
          // just get fresh app credentials
          getCredentialsForApp(appState.newAppId);
        }
      }
    }, [open, appState.newAppId, loadedAppId, loadedAppAliases, credentialsUsername, getApp, getCredentialsForApp],
  );

  const fsaJob = fsaJobs.data.find((j) => j?.ownerId === `${appState.appId}`);

  return (
    <Dialog
      classes={classes}
      open={open}
      onClose={() => {
        setAppState({
          showCredentials: 'hide',
        });
      }}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogTitle onClose={() => {
        setAppState({
          showCredentials: 'hide',
        });
      }}
      >
        <Box direction="row" align="center" style={{ height: 38 }}>
          {appState.appName} Credentials
          <span style={{ marginLeft: 5 }}>
            <CopyButton
              text={`${getAtomicBaseDomain()}/account/${accountId}/apps/${appState.appId}/overview?credentials=show`}
              message="Credentials url copied to clipboard"
              tooltip="Click to copy sharable link"
            />
          </span>
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          (app.status === 'loading' || credentials?.status === 'loading')
          && (
            <Box className={styles.credentialsSections} flex={1} as={Card} padding={{ top: 'large', bottom: 'large' }}>
              <Loading text="Gathering app information" />
            </Box>
          )
        }
        {
          (app.status === 'failed' || credentials?.status === 'failed')
          && (
            <Card column className={styles.credentialsSections}>
              <Box padding={{ top: 'large', bottom: 'large' }}>
                {
                  credentialsError
                    ? (
                      <ErrorText>ERROR: {credentialsError}</ErrorText>
                    ) : (
                      <ErrorText>ERROR: Failed to load credentials</ErrorText>
                    )
                }
              </Box>
            </Card>
          )
        }
        {
          app.status === 'success' && credentials?.status === 'success'
          && (
            <Fragment>
              {
                appCreation
                && (
                  <CredentialsNewApp appName={appState.appName} />
                )
              }
              {
                appCreation && fsaJob
                && (
                  <Card column className={styles.credentialsSections}>
                    <Typography variant="h4">
                      Mercury Dynamic Site Acceleration
                    </Typography>
                    <Box margin={{ top: 'small', bottom: 'xsmall' }}>
                      <Typography gutterBottom>
                        We are working on setting up Mercury Dynamic Site Acceleration for your site.
                      </Typography>
                    </Box>
                    <Box direction="row" align="center" gap="xsmall" padding={{ bottom: 'small' }}>
                      <Typography component="span" variant="h5">Setup Status: </Typography>
                      <span>
                        <GhostTag color={STATUS.tagMap[fsaJob.overallStatus]}>{fsaJob.overallStatus.replace('_', ' ')}</GhostTag>
                      </span>
                    </Box>
                    <Box direction="row" align="center" gap="xsmall" padding={{ bottom: 'xsmall' }}>
                      <Link
                        to={`/account/${accountId}/apps/${appState.appId}/overview`}
                      >
                        {
                          fsaJob.overallStatus === STATUS.ACTION_REQUIRED ? 'View required actions' : 'View Mercury Dynamic Site Acceleration setup progress'
                        }
                      </Link>
                    </Box>
                  </Card>
                )
              }
              <CredentialsPreview app={app} />
              <CredentialsLogin credentials={credentials} />
            </Fragment>
          )
        }
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    app: state.app.app,
    // @ts-ignore
    fsaRecord: state.app.app.data.fsa,
    fsaJobs: {
      // @ts-ignore
      ...state.status[STATUS.jobTypes.getFsaSetupJob(state.app.app.data)],
      data: getSortedFsaSetupJobsForApp(state),
    },
  };
};

export default connect(mapStateToProps, { getApp: fetchApp, getCredentialsForApp: fetchCredentialsForApp })(Credentials);
