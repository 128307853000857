import { css, keyframes } from '@emotion/react';

const dotAnimationKeyframes = keyframes`
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
`;

// using pink from theme

export const dotAnimation = css`
position: relative;
  top: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--color-primary);
  transform-origin: 5px 15px;
  animation: ${dotAnimationKeyframes} 2s infinite linear;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
  }

  &::before {
    left: -8.66px;
    top: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-primary);
    color: var(--color-primary);
  }

  &::after {
    left: 8.66px;
    top: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-primary);
    color: var(--color-primary);
  }
`;
