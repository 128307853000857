import React, {
  useState,
} from 'react';
import {
  shape, string, func,
} from 'prop-types';
import { useDispatch } from 'react-redux';

import { AccordionDetails, Typography } from '@material-ui/core';

import DestructiveAction from 'shared/styleguide/molecules/DestructiveAction';
import Box from 'shared/styleguide/atoms/Box';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import { Toggle } from 'shared/styleguide/atoms/Switches';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { ErrorText } from 'shared/styleguide/typography';
import { consolidateErrors } from 'shared/utils/validation';
import { updateIntegration } from 'shared/modules/integrations/redux/actions';
import { GIT_WEBHOOK } from 'shared/modules/integrations/constants';

const EditWebhook = ({
  integration, onDeleteWebhookIntegration, setView,
}) => {
  const dispatch = useDispatch();

  const oldValues = {
    active: integration.active,
    clearCache: integration.config?.clearCache,
  };

  const [active, setActive] = useState(Boolean(oldValues.active));
  const [clearCache, setClearCache] = useState(Boolean(oldValues.clearCache));
  const [errors, setErrors] = useState('');
  const [status, setStatus] = useState('initial');

  const handleSave = async () => {
    const hasChanges = (active !== oldValues.active) || (clearCache !== Boolean(oldValues.clearCache));
    setStatus('loading');

    try {
      if (hasChanges) {
        setErrors('');
        const data = {
          type: GIT_WEBHOOK,
          active,
          config: {
            git: {
              remote: integration.config.git.remote,
              branch: integration.config.git.branch,
            },
            clearCache,
          },
        };

        await dispatch(updateIntegration(integration.id, data));
      }
      setView('info');
      setStatus('success');
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
  };

  const data = [
    ['Git Remote', integration.config.git.remote],
    ['Clear Cache', (
      <Box key="clearCache" direction="row" align="center" justify="flex-start" gap="xxsmall">
        <Toggle
          key="toggle-clearCache"
          id="toggle-clearCache"
          defaultToggled={clearCache}
          onToggle={(e, toggle) => setClearCache(toggle)}
        />
        <Typography variant="body2" color="textSecondary">Clear cache after deploy to ensure changes are visible immediately</Typography>
      </Box>
    )],
    ['Active', (
      <Box key="active">
        <Toggle
          key="toggle-active"
          id="toggle-active"
          defaultToggled={active}
          onToggle={(e, toggle) => setActive(toggle)}
        />
      </Box>
    )],
  ];

  return (
    <AccordionDetails>
      <Box flex={1}>
        <TwoColumn
          styles={{
            rightCol: {
              flexBasis: 170,
            },
            leftCol: {
              flex: '1',
            },
            table: {
              marginTop: 0,
            },
          }}
          data={data}
        />
        {
          errors && (
            <Box margin={{ top: 'small' }} align="center">
              <ErrorText>{errors}</ErrorText>
            </Box>
          )
        }
        <Box direction="row" justify="space-between" margin={{ top: 'small' }}>
          <DestructiveAction
            onConfirm={() => onDeleteWebhookIntegration(integration.id)}
            explainerText="Are you sure you want to delete this integration?"
          >
            Delete Integration
          </DestructiveAction>
          <Button
            variant="contained"
            onClick={handleSave}
            status={status}
          >
            Save
          </Button>
        </Box>
      </Box>
    </AccordionDetails>
  );
};

EditWebhook.propTypes = {
  integration: shape({
    id: string.isRequired,
    created: string,
    config: shape({
      git: shape({
        provider: string.isRequired,
        remote: string.isRequired,
        branch: string,
        provider_secret: string,
        webhook_url: string.isRequired,
        public_key: string,
      }),
    }),
  }),
  onDeleteWebhookIntegration: func,
  setView: func,
};

export default EditWebhook;
