import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import Box from 'shared/styleguide/atoms/Box';

import Empty from 'shared/styleguide/atoms/Empty';
import ListHead from './ListHead';
import ListRow from './ListRow';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const CollapsibleTable = ({
  paginate,
  pageLength,
  headCells,
  ListRows,
  Detail,
  onSetOrder,
  onSetOrderBy,
  order,
  orderBy,
}) => {
  const [page, setPage] = useState(1);
  const [index, setIndex] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    onSetOrder(isAsc ? 'desc' : 'asc');
    onSetOrderBy(property);
  };

  const classes = useStyles();

  const rows = paginate ? ListRows.slice(index, index + pageLength) : ListRows;

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <ListHead
            classes={classes}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            collapsible
          />
          <TableBody>
            {
              rows
                .map((row, i) => {
                  return (
                    <ListRow key={`listrow-${i}`} row={row} Detail={Detail} />
                  );
                })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        paginate && ListRows.length > pageLength && (
          <Box margin={{ top: 'small' }} justify="center" direction="row">
            <Pagination
              shape="rounded"
              count={Math.ceil(ListRows.length / pageLength)}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                setIndex(((value - 1) * pageLength));
              }}
            />
          </Box>
        )
      }
      {
        rows.length === 0 && (
          <Paper>
            <Empty>No rows to display</Empty>
          </Paper>
        )
      }
    </Fragment>
  );
};

CollapsibleTable.propTypes = {
  Detail: PropTypes.func,
  headCells: PropTypes.array,
  ListRows: PropTypes.array,
  onSetOrder: PropTypes.func,
  onSetOrderBy: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  pageLength: PropTypes.number,
  paginate: PropTypes.bool,
};

export default CollapsibleTable;
