import React, { Component } from 'react';
import { object, bool, func } from 'prop-types';
import { css } from '@emotion/react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import logger from 'shared/3rdparty/logger';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import FormLabel from 'shared/styleguide/molecules/RadioInput';
import colors from 'shared/styleguide/theme';

export default class WhiteScreenEliminator extends Component {
  static propTypes = {
    app: object.isRequired,
    isAdmin: bool,
    resetAppOptions: func,
  };

  state = {
    url: false,
    plugins: false,
    themes: false,
    user: false,
    password: '',
  };

  handleResetOptions = (e) => {
    e.preventDefault();
    const {
      app, isAdmin, resetAppOptions,
    } = this.props;

    const appId = app.data.id;

    const data = Object.keys(this.state).reduce((acc, val) => {
      if (this.state[val] && val !== 'password') {
        acc.push(val);
      }
      return acc;
    }, []);

    if (data.length === 0) {
      return;
    }
    resetAppOptions({ appId, data }).then((response) => {
      // reset state
      const state = {
        url: false,
        plugins: false,
        themes: false,
        apiKey: false,
        user: false,
      };

      if (isAdmin) {
        state.password = response.password;
      }
      this.setState(state);
    }).catch((err) => {
      logger.error(err);
    });
  };

  render() {
    const { isAdmin } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">White Screen Eliminator</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box padding={{ left: 'small', right: 'small' }}>
            <Typography paragraph variant="body1">
              The white screen eliminator can be used to resolve certain issues with WordPress by resetting
              settings to their defaults.
            </Typography>
            <FormGroup>
              <FormLabel
                checked={Boolean(this.state.url)}
                onChange={(e) => this.setState({ url: e.target.checked })}
                control={(
                  <Checkbox />
                )}
              >
                <div>
                  <Typography variant="body1">URL</Typography>
                  <Typography variant="caption" color="textSecondary">Resets home/site URL to the app’s Primary Domain</Typography>
                </div>
              </FormLabel>
              <FormLabel
                checked={Boolean(this.state.plugins)}
                onChange={(e) => this.setState({ plugins: e.target.checked })}
                control={(
                  <Checkbox />
                )}
              >
                <div>
                  <Typography variant="body1">Plugins</Typography>
                  <Typography variant="caption" color="textSecondary">Disables all plugins</Typography>
                </div>
              </FormLabel>
              <FormLabel
                checked={Boolean(this.state.themes)}
                onChange={(e) => this.setState({ themes: e.target.checked })}
                control={(
                  <Checkbox />
                )}
              >
                <div>
                  <Typography variant="body1">Themes</Typography>
                  <Typography variant="caption" color="textSecondary">Resets themes to default WordPress theme</Typography>
                </div>
              </FormLabel>
              {
                isAdmin && (
                  <div css={css`
                    position: relative;
                    left: -8px;
                    border-left: 3px solid ${colors.secondary};
                    padding-left: 5px;
                  `}
                  >
                    <FormLabel
                      checked={Boolean(this.state.user)}
                      onChange={(e) => this.setState({ user: e.target.checked })}
                      control={(
                        <Checkbox />
                      )}
                    >
                      <div>
                        <Typography variant="body1">Add Support User</Typography>
                        <Typography variant="caption" color="textSecondary">Adds credentials for a support user</Typography>
                      </div>
                    </FormLabel>
                  </div>
                )
              }
            </FormGroup>
            <Box margin={{ top: 'small' }}>
              <div>
                <Button onClick={this.handleResetOptions}>Reset Options</Button>
              </div>
              {
                this.state.password && (
                  <div style={{ padding: '20px 0' }}>The new wordpress support password is: <strong>{this.state.password}</strong></div>
                )
              }
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
}
