import React, { Fragment, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  Switch,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

import Stepper from 'shared/styleguide/molecules/Stepper';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { FormikValues } from './types';

interface DomainProps {
  domain: any;
  primary: any;
  setPrimary: any;
  index: any;
  onRemove: any;
  onUpdateDomain: any;
  errors: any;
}

const Domain = ({
  domain, primary, setPrimary, index, onRemove, onUpdateDomain, errors,
}: DomainProps) => {
  const [name, setName] = useState(domain.value);

  return (
    <Box direction="column">
      <TextField
        placeholder={primary ? 'Primary Domain' : 'Additional Domain'}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          onUpdateDomain(e, index, domain);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => { onRemove(index); }}>
                <Delete />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box direction="row" align="center">
        <span>
          <FormControlLabel
            label="Set as app name"
            name="primary"
            labelPlacement="end"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                setPrimary(index);
              }
            }}
            checked={primary}
            control={(<Switch />)}
          />
        </span>
        <span><Typography color="error">{errors}</Typography></span>
      </Box>
    </Box>
  );
};

interface Props {
  step: number;
  useStepState: [number, any];
  fetchAvailability: any;
  onClose: any;
}

const AddDomains = ({
  step, useStepState, fetchAvailability, onClose,
}: Props) => {
  const [modalStep, setModalStep] = useStepState;
  const formikProps = useFormikContext<FormikValues>();
  const [domains, setDomains] = useState(formikProps.values.domainsWithKeys || [{ value: '', key: Math.random() }]);
  const [primary, setPrimary] = useState(formikProps.values.primaryIdx || 0);

  const handleCheckAvailability = async () => {
    try {
      // will not match correctly if we don't convert to lowercase
      const response = await fetchAvailability(formikProps.values.accountId, domains.map((d) => d.value.toLowerCase()));
      response[domains[primary].value.toLowerCase()].isPrimary = true;
      formikProps.setFieldValue('availibilityDomains', response);
      formikProps.setFieldValue('primaryIdx', primary);
      formikProps.setFieldValue('primaryDomain', domains[primary].value.toLowerCase());

      return true;
    } catch (err) {
      const { messages, ...domainErrors } = err.response.data.body.domains;
      // idk why but im having trouble setting formik errors :/
      const errors = {};
      Object.entries(domainErrors).forEach(([domain, messageObject]) => {
        // find the corresponding id for domain
        const dmn = domains.find((d) => {
          const strippedDomain = domain.replace(/"/g, '');

          // well actually, the error might just be matching domain and not tld
          return d.value === strippedDomain || d.value.includes(strippedDomain);
        });

        const { key } = dmn;
        // @ts-ignore
        const errorMessages = messageObject.messages.map((error) => {
          error.replace(/"/g, "'");
          return error;
        }).join(', ');
        errors[key.toString()] = errorMessages;
      });
      formikProps.setErrors(errors);
      // set field errors
      return false;
    }
  };

  const handleNext = async () => {
    // now we need to call the action that does the magic
    let cont = false;
    try {
      cont = await handleCheckAvailability();
    } catch (err) {
      // set formik error value
      console.log(err);
    }
    if (cont) {
      setModalStep(modalStep + 1);
    }
  };

  const handleBack = () => {
    setModalStep(modalStep - 1);
  };

  const handleMakePrimary = (index) => {
    setPrimary(index);
  };

  const handleRemove = (index) => {
    const tempDomains = [...domains];
    tempDomains.splice(index, 1);
    setDomains(tempDomains);
  };

  const handleUpdateDomain = (e, index) => {
    const tempDomains = [...domains];
    tempDomains[index] = {
      ...domains[index],
      value: e.target.value,
    };
    setDomains(tempDomains);
    formikProps.setFieldValue('domainsWithKeys', tempDomains);
  };

  return (
    <Fragment>
      <DialogContent>
        <Box direction="column">
          <Box direction="row" justify="space-between" margin={{ bottom: 'small' }}>
            <Box margin={{ bottom: 'small' }}>
              <Typography variant="h4">Add Domains</Typography>
            </Box>
            <span>
              <Button
                onClick={() => setDomains([...domains, { value: '', key: Math.random() }])}
                startIcon={<Add />}
              >
                Add Domain
              </Button>
            </span>
          </Box>
          <Box direction="column" gap="small">
            {
              domains.map((domain, i) => {
                return (
                  <Domain
                    key={`${domain.key}`}
                    index={i}
                    domain={domain}
                    primary={i === primary}
                    setPrimary={handleMakePrimary}
                    onRemove={handleRemove}
                    onUpdateDomain={handleUpdateDomain}
                    errors={formikProps.errors[domain.key.toString()]}
                  />
                );
              })
            }
          </Box>
        </Box>
        <Typography variant="caption" color="textSecondary">We recommend that you add a &quot;www.&quot; and a non-&quot;www.&quot; version of your domain</Typography>
      </DialogContent>

      <DialogActions>
        <Box margin={{ top: 'small' }} direction="row" justify="space-between" align="center" flex={1}>
          <div>
            <Button onClick={handleBack} variant="outlined" color="default">
              Back
            </Button>
          </div>
          <Box
            css={{ minWidth: '50%' }}
          >
            <Stepper
              startingStep={1}
              activeStep={step}
              classes={makeStyles({ root: { padding: 0 } })()}
              showLabels={false}
              steps={formikProps.values.stepperSteps}
            />
          </Box>

          <div>
            <Button
              onClick={handleNext}
              variant="contained"
              disabled={domains.map((d) => d.value).join('').length === 0}
            >
              Next
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Fragment>
  );
};

export default React.memo(AddDomains);
