import React from 'react';
import Box from 'shared/styleguide/atoms/Box';
import SubmitSharedNoteForm from './Form';
import { ListView } from './ListView';

export const SharedNotes = (): JSX.Element => {
  return (
    <Box gap="medium">
      <SubmitSharedNoteForm />
      <ListView />
    </Box>
  );
};

export default SharedNotes;
