import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';

import { Account } from 'shared/modules/account/types';
import { getDailyStats as getStats } from 'shared/modules/analytics/redux/actions';
import BandwidthIcon from 'shared/styleguide/Icons/SVGs/Bandwidth';
import Box from 'shared/styleguide/atoms/Box';
import ProgressView from 'shared/styleguide/molecules/ProgressView';
import { PRISTINE } from 'shared/utils/redux/constants';

import { PERCENT_THRESHOLD } from '../../constants';

const start = moment().subtract(30, 'days').startOf('day').toISOString();
const end = moment().endOf('day').toISOString();

interface CdnStatsProps {
  account: Account;
  getDailyStats: (account: number, startDate: string, endDate: string) => void;
  stats;
}

const CDNStats = ({
  account,
  getDailyStats,
  stats,
}: CdnStatsProps) => {
  // load CDN Stats
  useEffect(() => {
    if (stats.status === PRISTINE || stats.params.accountId !== account.id) {
      getDailyStats(account.id, start, end);
    }
  }, [account, stats, getDailyStats]);

  const cdnUsage = useMemo(() => {
    let cdn = 0;
    stats?.data?.forEach((day) => {
      cdn += day.cdnBandwidth;
    });
    return cdn;
  }, [account.id, stats.params.id, stats.data.length]);

  const { maxCdn } = account?.limits;
  const cdnPercent = (cdnUsage / (maxCdn || 1)) * 100;

  return (
    <Box flex={1}>
      <ProgressView
        {...{
          heading: 'CDN Bandwidth (last 30 days)',
          icon: <BandwidthIcon />,
          value1: `${cdnUsage.toFixed(cdnUsage > 1000 ? 0 : 2)}`,
          value2: `${maxCdn} GB`,
          percentage: cdnPercent,
          threshold: PERCENT_THRESHOLD,
          errorText: 'Upgrade Soon',
        }}
      />
    </Box>
  );
};

export default connect(
  (state: { account; analytics }) => ({
    account: state.account,
    stats: state.analytics.daily,
  }),
  {
    getDailyStats: getStats,
  },
)(CDNStats);
