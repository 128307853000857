import React, { useRef, Fragment } from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import NewButton from 'shared/styleguide/atoms/Buttons/NewButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Box from 'shared/styleguide/atoms/Box';
import { HeadlineText } from 'shared/styleguide/typography';
import colors from 'shared/styleguide/theme';

import Success from 'shared/styleguide/atoms/Success';

import ModalErrors from './ModalErrors';

import {
  redirectsWizardModalOpen, submitRedirectsWizard,
} from '../../redux/actions';

import { inline } from './styles';

const TitleText = ({ children }) => (
  <Typography variant="body1" style={{ marginTop: 10 }}>
    <strong>{children}</strong>
  </Typography>
);

const RedirectsWizard = ({
  inputErrors, modalOpen, redirectsWizardModalOpen, submitRedirectsWizard, redirectsWizardSuccess,
}) => {
  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  let renderRedirectsWizardModalContent = (
    <div>
      <DialogContent>
        <div
          style={{
            background: 'white', padding: 20, border: `1px solid ${colors.blue20}`, borderRadius: '4px',
          }}
        >

          <div style={{ width: '100%', marginRight: 20 }}>
            <TextField
              autoFocus
              style={{ width: '100%' }}
              inputRef={sourceRef}
              name="source"
              key="redirectsWizardSource"
              autoComplete="off"
              placeholder="Source"
              multiline
              margin="dense"
            />
          </div>
          <div style={{ width: '100%', marginRight: 20 }}>
            <TextField
              style={{ width: '100%' }}
              inputRef={targetRef}
              name="target"
              key="redirectsWizardTarget"
              autoComplete="off"
              placeholder="Target"
              multiline
              margin="dense"
            />
          </div>
          <Box margin={{ top: 'small', bottom: 'small' }}>
            <Typography variant="h4" style={{ marginTop: 10 }}>
              <strong>Instructions</strong>
            </Typography>
            <Typography variant="h6" style={{ marginTop: 10 }}>
              <strong>Source and Target may be in the following formats:</strong>
            </Typography>
            <Typography variant="body2">
              (Source and Target formats do not need to match)
            </Typography>
            <TitleText>Absolute path</TitleText>
            <Typography variant="body2">
              Must begin with a forward slash (eg. /page)
            </Typography>
            <TitleText>Hostname and path</TitleText>
            <Typography variant="body2">
              (eg. example.com/posts/post or my-subdomain.example.com/posts/* )
            </Typography>
            <TitleText>Scheme, hostname and path</TitleText>
            <Typography variant="body2">
              (eg. https://example.com/blog/article or http://www.domain.com/page )
            </Typography>
          </Box>
          <Box margin={{ top: 'small', bottom: 'small' }}>
            <Typography variant="h6" style={{ marginTop: 10 }}>
              <strong>Use wildcards for regex matching:</strong>
            </Typography>
            <TitleText>Asterisk - *</TitleText>
            <Typography variant="body2">
              Use in Source and Target to pass regex variables (eg /posts/* &gt; /blog/* ). <br />
              May be used multiple times in an url, creating respective matches (eg. /archive/*/articles/* &gt; /feature/*/* )
            </Typography>
            <TitleText>Forward-slash in square brackets - [/]</TitleText>
            <Typography variant="body2">
              Use at end of Source for optional trailing slash matches. (eg. /an-outdated-address[/] &gt; /the-new-address )
            </Typography>
          </Box>
        </div>
        <div id="importErrors">
          <ModalErrors errorData={inputErrors} errorHeading="NON-VALID INPUT" />
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ marginTop: 20 }}>
          <NewButton
            color="secondary"
            variant="contained"
            name="Submit"
            onClick={() => submitRedirectsWizard(sourceRef.current.value, targetRef.current.value)}
          >
            Submit
          </NewButton>
        </div>
      </DialogActions>
    </div>
  );

  if (redirectsWizardSuccess === true) {
    renderRedirectsWizardModalContent = (
      <DialogContent>
        <HeadlineText>Redirects Wizard</HeadlineText>
        <Box style={inline.modal}>
          <Success
            primaryMessage="Redirect created successfully"
          />
        </Box>
      </DialogContent>
    );
  }

  return (
    <Fragment>
      <NewButton
        color="default"
        variant="outlined"
        key="redirectsWizard"
        name="redirectsWizard"
        onClick={() => { redirectsWizardModalOpen(true); }}
      >
        Wizard
      </NewButton>
      <Dialog
        PaperProps={{ style: inline.dialog }}
        open={modalOpen || false}
        onClose={() => { redirectsWizardModalOpen(false, null); }}
        maxWidth="md"
        fullWidth
      >
        <div>
          <DialogTitle>
            Redirects Wizard
            <div style={inline.closeIcon}>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => { redirectsWizardModalOpen(false, null); }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          {renderRedirectsWizardModalContent}
        </div>
      </Dialog>
    </Fragment>
  );
};

RedirectsWizard.propTypes = {
  inputErrors: any,
  modalOpen: any,
  redirectsWizardModalOpen: any,
  redirectsWizardSuccess: any,
  submitRedirectsWizard: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    inputErrors: state.aresConfig.redirectsWizard.inputErrors,
    modalOpen: state.aresConfig.redirectsWizard.modalOpen,
    redirectsWizardSuccess: state.aresConfig.redirectsWizard.redirectsWizardSuccess,
  };
};

export default connect(mapStateToProps, {
  redirectsWizardModalOpen, submitRedirectsWizard,
})(RedirectsWizard);
