import React from 'react';
import moment from 'moment';
import qs from 'qs';
import { Link, useLocation } from 'react-router-dom';
import {
  SvgIcon, Tooltip, Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  mdiPin,
  mdiPinOutline,
} from '@mdi/js';
import { css } from '@emotion/react';

import Box from 'shared/styleguide/atoms/Box';
import colors from 'shared/styleguide/theme';
import NoteText from '../../components/NoteText';
import { useUpdateNoteMutation } from '../../query';
import type { Note } from '../../types';

const itemContainer = (theme) => css`
  transition: background-color 0.2s ease-out;
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover {
    transition: background-color 0.2s ease-out;
    text-decoration: none;
    background-color: ${theme.palette.action.hover};
  }
`;

interface NoteListItemProps {
  note: Note;
}

const NoteListItem = (props: NoteListItemProps) => {
  const {
    note,
  } = props;
  const [starUnstar, result] = useUpdateNoteMutation();

  const location = useLocation();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const pathToView = qs.stringify({
    ...query, noteView: 'view', noteId: note.id, noteType: note.noteType,
  }, { addQueryPrefix: true });

  return (
    <Box
      as={Link}
      to={`${location.pathname}${pathToView}`}
      css={itemContainer}
      padding={{
        top: 'xsmall', bottom: 'xsmall', left: 'small', right: 'small',
      }}
    >
      <Box direction="row" justify="space-between">
        <Box flex={1}>
          <Typography variant="body1" css={{ letterSpacing: 0.3 }}>
            <strong>
              {note.title}
            </strong>
          </Typography>

          <Typography component="div" variant="body2" color="textSecondary" gutterBottom css={{ maxWidth: 285 }}>
            <NoteText
              note={note.note}
              type={note.noteType}
              contentType={note.noteContentType}
            />
          </Typography>
        </Box>
        <div>
          <Tooltip title={note.starred ? 'Unpin' : 'Pin'}>
            <IconButton
              size="small"
              onClick={(e) => { e.preventDefault(); starUnstar({ id: note.id, starred: !note.starred }); }}
            >
              {note.starred ? (
                <SvgIcon
                  style={{ color: colors.golden }}
                  color="inherit"
                >
                  <path
                    d={mdiPin}
                  />
                </SvgIcon>
              ) : (
                <SvgIcon>
                  <path
                    d={mdiPinOutline}
                  />
                </SvgIcon>

              )}
            </IconButton>
          </Tooltip>
        </div>

      </Box>
      <Box direction="row" justify="space-between">
        <Typography component="span" variant="caption" color="textSecondary">{note.noteType}</Typography>
        <Typography component="span" variant="caption" color="textSecondary">{moment(note.created).fromNow(true)}</Typography>
      </Box>
    </Box>
  );
};

export default NoteListItem;
