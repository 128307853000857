import React, {
  useState,
} from 'react';
import {
  object, func, number, bool,
} from 'prop-types';
import { Typography } from '@material-ui/core';
import Empty from 'shared/styleguide/atoms/Empty';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';

import CreateWebhook from './CreateWebhook';
import ProgressStatus from './ProgressStatus';

export const WebhookIntegration = ({
  integration, onCreating, creating, appId, accountId, hasSsh,
}) => {
  const [creatorOpen, setCreatorOpen] = useState(false);

  const integrationProcessing = integration && creating && !hasSsh;

  if (integrationProcessing) {
    return (
      <Box margin={{ top: 'medium' }} flex={1}>
        <Typography variant="h3">Git Webhook Integration</Typography>
        <Box margin={{ top: 'small' }}>
          <ProgressStatus
            appId={appId}
            integration={integration}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box direction="row" justify="space-between" margin={{ top: 'medium', bottom: 'small' }} align="center">
        <Typography variant="h3">Git Webhook Integration</Typography>
        {
          (!creatorOpen) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setCreatorOpen(true)}
            >Create new Git Webhook Integration
            </Button>
          )
        }
      </Box>
      <Box>
        {
          creatorOpen ? (
            <Box>
              <CreateWebhook
                appId={Number(appId)}
                accountId={Number(accountId)}
                onClose={() => setCreatorOpen(false)}
                onCreating={onCreating}
              />
            </Box>
          ) : (
            <Empty>
              You don&lsquo;t have a Git Webhook Integration created yet
            </Empty>
          )
        }
      </Box>
    </Box>
  );
};

WebhookIntegration.propTypes = {
  accountId: number,
  appId: number,
  creating: bool,
  hasSsh: bool,
  integration: object,
  onCreating: func,
};

export default WebhookIntegration;
