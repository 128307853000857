import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/react';

import { propTypes } from 'shared/modules/account/models/account';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import { remMapper } from 'shared/styleguide/theme/spacing';

export const HeaderCell = ({ fieldName, value }) => {
  return (
    <Box direction="row" gap="xxsmall" align="flex-end">
      <Typography variant="body1" color="textSecondary" component="div">
        {fieldName}:{' '}
      </Typography>
      <Typography variant="body1" component="div">
        {value}
      </Typography>
    </Box>
  );
};

HeaderCell.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
};

export const HeaderLoading = () => (
  <Loading margin={{ top: 0 }} />
);

const AccountHeader = (props) => {
  const {
    account,
  } = props;

  if (account.isFetching) {
    return <HeaderLoading />;
  }

  return (
    <Box
      css={{
        marginBottom: remMapper('mediumLarge'),
      }}
    >
      <Box direction="row">
        <Typography variant="h2" color="textPrimary">
          <strong>{account.name}</strong>
          {
            !!account.companyName
            && <span css={css`text-transform: none;`}>&nbsp;({account.companyName})</span>
          }
        </Typography>
      </Box>
      <Box direction="row" align="flex-end" gap="large" margin={{ top: 'xsmall' }}>
        <HeaderCell fieldName="Account ID" value={account.id} />
        <HeaderCell fieldName="Email" value={account.email} />
      </Box>
      {
        account.requestErr && (
          <Typography variant="h5" color="error">
            {account.errMessage}
          </Typography>
        )
      }
    </Box>
  );
};

AccountHeader.propTypes = {
  account: PropTypes.shape(propTypes),
  isPagelyAdmin: PropTypes.bool,
  permissions: PropTypes.object,
};

export default AccountHeader;
