import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { node, number } from 'prop-types';
import AccountContext from 'shared/modules/account/context/AccountContext';

const DiskLink = ({ children, ...props }) => {
  const accountId = useContext(AccountContext).id;

  return <Link to={`/account/${accountId}/disk`} {...props}>{children}</Link>;
};

DiskLink.propTypes = {
  appId: number,
  children: node,
};

DiskLink.displayName = 'DiskLink';

export default DiskLink;
