import { getHttp } from 'shared/http';
import makeApiAction, { defaultShould } from 'shared/utils/redux/apiActions';

export const CERT_REQUEST = 'FETCH_CERTIFICATE_REQUEST';
export const CERT_SUCCESS = 'FETCH_CERTIFICATE_SUCCESS';
export const CERT_FAILURE = 'FETCH_CERTIFICATE_FAILURE';

export const CERT_UPLOAD_REQUEST = 'CERT_UPLOAD_REQUEST';
export const CERT_UPLOAD_SUCCESS = 'CERT_UPLOAD_SUCCESS';
export const CERT_UPLOAD_FAILURE = 'CERT_UPLOAD_FAILURE';

export const CERT_REMOVE_REQUEST = 'CERT_REMOVE_REQUEST';
export const CERT_REMOVE_SUCCESS = 'CERT_REMOVE_SUCCESS';
export const CERT_REMOVE_FAILURE = 'CERT_REMOVE_FAILURE';

export const IMPORT_EXISTING_REQUEST = 'IMPORT_EXISTING_CERTIFICATE_REQUEST';
export const IMPORT_EXISTING_SUCCESS = 'IMPORT_EXISTING_CERTIFICATE_SUCCESS';
export const IMPORT_EXISTING_FAILURE = 'IMPORT_EXISTING_CERTIFICATE_FAILURE';

export const CERT_RESET = 'CERT_RESET';

export const requestCert = (certID) => makeApiAction(
  ({ api }) => api().get(`/ssl/certs/${certID}`),
  CERT_REQUEST,
  CERT_SUCCESS,
  CERT_FAILURE,
  {
    certId: parseInt(certID, 10),
  },
);

export const uploadCert = (accountId, certificate) => makeApiAction(
  ({ api }) => api().post('/ssl/certs', {
    accountId,
    certificate,
  }),
  CERT_UPLOAD_REQUEST,
  CERT_UPLOAD_SUCCESS,
  CERT_UPLOAD_FAILURE,
);

export const removeCert = (certId) => makeApiAction(
  ({ api }) => api().delete(`/ssl/certs/${certId}`),
  CERT_REMOVE_REQUEST,
  CERT_REMOVE_SUCCESS,
  CERT_REMOVE_FAILURE,
  {
    certId,
  },
);

export const requestCertIfNeeded = (certID) => (dispatch, getState) => {
  if (defaultShould('cert')(getState(), certID)) {
    return dispatch(requestCert(certID));
  }

  return null;
};

export const reset = () => ({
  type: CERT_RESET,
});

export const importExistingKeyAndCert = (key, certificate, accountId) => makeApiAction(
  ({ api }) => api().post('/ssl/import', {
    key,
    certificate,
    accountId,
  }),
  IMPORT_EXISTING_REQUEST,
  IMPORT_EXISTING_SUCCESS,
  IMPORT_EXISTING_FAILURE,
);
