import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Formik } from 'formik';

import {
  Toolbar, IconButton, Button, Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import Box from 'shared/styleguide/atoms/Box';
import NoteEdit from 'admin/modules/notes/routes/Edit/NoteEdit';
import { consolidateErrors, getAllValidationsWithKeys } from 'shared/utils/validation';
import { useGetNoteByIdQuery, useUpdateNoteMutation } from '../../query';
import { serverExpiryToClientExpiry } from '../../expiryUtils';

const NoteEditor = (props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const {
    data, isUninitialized, isLoading,
  } = useGetNoteByIdQuery(props.noteId);
  const [updateNote, result] = useUpdateNoteMutation();

  if (isUninitialized || isLoading || !data) {
    return null;
  }
  const handleClose = () => {
    history.replace(match.url.replace(/\/note\/.*/, ''));
  };

  const handleSave = async (values, actions) => {
    const updatedValues = values;
    // this field only applies to shared notes which the frontend does not allow to edit
    delete updatedValues.expires;
    try {
      await updateNote({
        id: props.noteId,
        accountId: props.account.id,
        accountName: props.account.name,
        authorId: props.user.id,
        authorName: props.user.name,
        ...updatedValues,
      }).unwrap();
      handleClose();
    } catch (err) {
      actions.setErrors(getAllValidationsWithKeys({ response: err }));
    }
  };

  return (
    <Box anchor="right" open>
      <Formik
        initialValues={{
          note: data!.note,
          title: data!.title,
          noteType: data!.noteType,
          noteContentType: data!.noteContentType,
          expires: serverExpiryToClientExpiry(data!.expires),
          burnAfterReading: data!.burnAfterReading,
        }}
        onSubmit={handleSave}
        validate={({ note, title }) => {
          const errors = {} as any;

          if (!note) errors.note = 'Note is required';
          if (!title) errors.title = 'Title is required';

          return errors;
        }}
      >
        {
          ({
            handleSubmit, handleReset, dirty, values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Toolbar>
                <Box direction="row" justify="space-between" align="center" flex={1}>
                  <Box direction="row" gap="xsmall">
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={(!dirty || !values.note || !values.title)}
                    >
                      Update Note
                    </Button>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={handleReset}
                      disabled={!dirty}
                    >
                      Reset
                    </Button>
                  </Box>
                  <Box>
                    <IconButton
                      edge="end"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleClose}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
              </Toolbar>
              {
                (result.isError) && (
                  <Typography color="error">{consolidateErrors(result.error)}</Typography>
                )
              }
              <NoteEdit />
            </form>
          )
        }
      </Formik>
    </Box>
  );
};

export default NoteEditor;
