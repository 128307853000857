import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import { object, func } from 'prop-types';
import Loading from 'shared/styleguide/atoms/Loading';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import InfoText from 'shared/styleguide/molecules/InfoText';

import CreateIntegration from './components/CreateIntegration';
import { fetchIntegrationsList } from './redux/actions';
import { getUploadIntegrations } from './redux/selectors';
import List from './components/List';

export const Content = ({ type, integrations }) => { // eslint-disable-line
  switch (type) {
    case 'loading':
      return <Loading />;
    case 'empty':
      return <Empty>You don&apos;t have any CI/CD Integrations created yet</Empty>;
    case 'list':
      return <List integrations={integrations} />;
    default:
      return <Empty>there is no view for this yet</Empty>;
  }
};

export const Integrations = (props) => {
  const {
    account, integrations,
    apps, fetchIntegrationsList,
  } = props;
  const { accountID } = props.match.params;

  const [creatorOpen, setCreatorOpen] = useState(false);

  useEffect(() => {
    fetchIntegrationsList(accountID);
  }, [accountID, fetchIntegrationsList]);

  if (account.isOnP20Account) {
    return (
      <Box padding="medium">
        <Empty>This feature is not available for accounts on shared hosting plans.</Empty>
      </Box>
    );
  }

  let view = 'loading';
  if (integrations.data.length === 0 && integrations.status === 'success') {
    view = 'empty';
  } else if (
    integrations.data.length > 0 && integrations.status === 'success' && apps.status === 'success'
  ) {
    view = 'list';
  } else {
    view = 'loading';
  }

  return (
    <div>
      <Typography color="textPrimary" variant="h1" gutterBottom>CI/CD Integrations</Typography>
      <Box
        direction="row"
        justify="space-between"
        align="center"
        margin={{ top: 'small', bottom: 'small' }}
      >
        <Box direction="row" justify="space-between" flex={1}>
          <Box margin={{ top: 'xsmall' }}>
            <InfoText
              href="https://support.pagely.com/hc/en-us/articles/360047765872"
            >
              How to deploy your WordPress Sites from version control
            </InfoText>
          </Box>
          {
            (!creatorOpen && apps.status === 'success') && (
              <Box>
                <Button variant="contained" color="secondary" onClick={() => setCreatorOpen(true)}>Create new integration</Button>
              </Box>
            )
          }

        </Box>
      </Box>
      {
        creatorOpen && (
          <Box>
            <CreateIntegration
              onClose={() => setCreatorOpen(false)}
              accountId={accountID}
              apps={apps.data}
            />
          </Box>
        )
      }

      <Box>
        <Content type={view} integrations={integrations.data} />
      </Box>
    </div>
  );
};

Integrations.propTypes = {
  account: object.isRequired,
  apps: object.isRequired,
  fetchIntegrationsList: func.isRequired,
  integrations: object.isRequired,
  match: object.isRequired,
};

export default connect(
  (state) => ({
    apps: state.apps, // we need to be able to map appIds to apps,
    account: state.account,
    integrations: getUploadIntegrations(state),
  }),
  {
    fetchIntegrationsList,
  },
)(Integrations);
