/* eslint-disable react/prop-types */
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import { useFormikContext } from 'formik';
import { TYPE_SHARED, noteTypes } from 'admin/modules/notes/constants';
import NoteIcon from './NoteIcon';

const NoteTypeSelect = (props) => {
  const formik = useFormikContext();
  return (
    <FormControl margin="normal" fullWidth={props.fullWidth} error={Boolean(props.error)}>
      <InputLabel id="redux-form-select-label">{props.label}</InputLabel>
      <Select
        autoWidth
        labelId="redux-form-select-label"
        id="redux-form-select"
        name={props.name}
        value={props.value}
        onChange={(event) => {
          formik.resetForm();
          props.onChange(event);
          if (event.target.value === TYPE_SHARED) {
            formik.setFieldValue('title', 'Admin-Submitted shared');
          }
        }}
      >
        {Object.keys(noteTypes).map((typeLabel) => {
          const type = noteTypes[typeLabel];

          return type === 'break' ? (
            <Divider key={typeLabel} />
          ) : (
            <MenuItem
              key={type}
              value={type}
              name={type}
            >
              <div>
                <span style={{ marginRight: 10 }}>
                  <NoteIcon
                    type={type}
                    style={{ verticalAlign: 'middle' }}
                  />
                </span>
                {typeLabel}
              </div>
            </MenuItem>
          );
        })}
      </Select>
      {
      props.error && (
        <FormHelperText>{props.error}</FormHelperText>
      )
    }
    </FormControl>
  );
};

export default NoteTypeSelect;
