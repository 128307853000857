import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControlLabel, Switch, Typography,
} from '@material-ui/core';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import { useGetAppsWithFiltersQuery } from 'admin/query';
import { APPS_SEARCH_REQUEST } from '../constants';
import AppsList from './AppsList';

export const AppSearch = () => {
  const appsSearch = useSelector((state: { search: { apps: any} }) => state.search.apps);
  const { appSearchString } = appsSearch;
  const [activeOnly, setActiveOnly] = useState(Boolean(appsSearch.activeOnly));
  const dispatch = useDispatch();
  const result = useGetAppsWithFiltersQuery({ appSearchString, activeOnly: Number(activeOnly) }, { skip: !appSearchString });
  if (result.isLoading) {
    return (
      <Loading />
    );
  }

  const records = result.data || [];

  let header = null;
  let subheader = null;

  if (appsSearch.appSearchString) {
    header = 'Search Results';
    subheader = `Your search returned ${records.length} ${records.length === 1 ? 'result' : 'results'}`;
  } else {
    header = 'Welcome to MGMT';
    subheader = 'Your search results will show up here';
  }

  // if !appSearchString && there are no recent accounts in the store,
  // then show "no recent accounts" and don't render table.

  const handleCheckInactive = (e) => {
    setActiveOnly(e.target.checked);
    const search = { appSearchString, activeOnly: Number(e.target.checked) };
    dispatch({ type: APPS_SEARCH_REQUEST, params: search });
    result.refetch();
  };

  return (
    <Box margin="large">
      <Typography variant="h2" gutterBottom>{header}</Typography>
      <Typography variant="subtitle1">{subheader}</Typography>
      {
        appSearchString && (
          <Box>

            <FormControlLabel
              control={(
                <Switch
                  checked={activeOnly}
                  onChange={handleCheckInactive}
                  color="primary"
                />
              )}
              label="Exclude inactive apps"
            />
          </Box>
        )
      }
      {(result.error && appSearchString) ? (
        <div>
          <Typography color="error">
            {/* @ts-ignore */}
            {result.error.status}: {result.error.data.message}
          </Typography>
        </div>
      ) : null}
      {
        result.isFetching && (
          <Loading />
        )
      }
      {(records.length > 0 && !result.isFetching) ? <div data-testid="appsearchlist"><AppsList key={`${appSearchString}-${Number(activeOnly)}-${records.length}`} apps={records} /></div> : null}
    </Box>
  );
};

export default AppSearch;
