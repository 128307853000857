import React, { useState, useRef } from 'react';
import {
  array, func, number, string, bool,
} from 'prop-types';
import { css } from '@emotion/react';
import { connect } from 'react-redux';
import {
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { consolidateErrors } from 'shared/utils/validation';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { remMapper } from 'shared/styleguide/theme/spacing';

import { createRateLimit } from '../../redux/rateLimits/actions';

import ListDetails from './ListDetails';

export const NewList = ({
  onClose, onValidate, createRateLimit, appId, listLabel, domainsList, changed, onSetChanged,
}) => {
  const [status, setStatus] = useState('pristine');
  const [name, setName] = useState('');

  const formRef = useRef();

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.handleReset();
    }
    setName('');
    onClose();
    onSetChanged(false);
  };

  const handleSubmit = async (values, actions) => {
    // contruct object to send
    const [body, shouldReturn] = onValidate({ name, ...values }, actions, 'create');
    if (shouldReturn) {
      return;
    }
    setStatus('loading');
    try {
      await createRateLimit(appId, body);

      setStatus('success');
      setTimeout(() => {
        setStatus('pristine');
        onSetChanged(false);
        resetForm();
      }, 3000);
    } catch (err) {
      setStatus('pristine');
      actions.setErrors({
        'general': consolidateErrors(err),
        ...err.response.data.body,
      });
    }
  };

  return (
    <Box
      as={Paper}
      padding="small"
      margin={{ bottom: 'medium' }}
      css={css`
        position: relative;
      `}
      data-testid="ratelimitNew"
    >
      <IconButton
        onClick={resetForm}
        css={css`
          position: absolute;
          top: ${remMapper('xsmall')};
          right: ${remMapper('xsmall')};
        `}
      >
        <Close />
      </IconButton>

      <ListDetails
        initialValues={{}}
        type="allow"
        onSubmit={handleSubmit}
        formRef={formRef}
        domainsList={domainsList}
        changed={changed}
        onSetChanged={onSetChanged}
      />
      <Box justify="flex-end" direction="row" flex={1}>
        <Button
          aria-label="save or submit"
          status={status}
          color="secondary"
          onClick={submitForm}
          variant={changed ? 'contained' : 'outlined'}
          disabled={!changed}
        >
          Save {listLabel}
        </Button>
      </Box>
    </Box>
  );
};

NewList.propTypes = {
  appId: number,
  changed: bool,
  createRateLimit: func,
  domainsList: array,
  listLabel: string,
  onClose: func,
  onSetChanged: func,
  onValidate: func,
};

export default connect(
  null,
  {
    createRateLimit,
  },
)(NewList);
