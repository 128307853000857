import React, { useContext } from 'react';
import { node, number, object } from 'prop-types';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import AccountContext from 'shared/modules/account/context/AccountContext';

const SupportLink = ({ children, ticketId = '', ...props }) => {
  const account = useContext(AccountContext);
  let onClick;

  if (props.prefill) {
    onClick = () => {
      localStorage.setItem('support_ticket', JSON.stringify(props.prefill));
    };
  }

  return (
    <TextLink
      to={`/account/${account.id}/support${ticketId ? `/${ticketId}` : ''}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </TextLink>
  );
};

SupportLink.propTypes = {
  accountId: number,
  children: node,
  prefill: object,
  ticketId: number,
};

SupportLink.displayName = 'SupportLink';

export default SupportLink;
