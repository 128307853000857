import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';
import {
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  useTheme,
  makeStyles,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Stepper from 'shared/styleguide/molecules/Stepper';
import FormLabel from 'shared/styleguide/molecules/RadioInput';
import { DOCKER_SERVER_TAG, FSA_OPTIN } from 'shared/modules/metadata/constants';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import Select from 'shared/styleguide/atoms/Select/Select';
import Loading from 'shared/styleguide/atoms/Loading';
import InfoText from 'shared/styleguide/molecules/InfoText';

import { FeatureToggle } from 'shared/flags';
import type { TagsReducer } from 'shared/modules/metadata/types';
import type { Mount } from 'shared/types/App';
import type { ReduxStore } from 'shared/utils/redux/apiReducer';
import MountOptions from './MountOptions';
import { FormikValues } from './types';

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: 'visible',
  },
  dialogContentRoot: {
    overflowY: 'visible',
    overflow: 'visible',
  },
});

interface Props {
  accountTags: string[];
  mounts: ReduxStore<Mount[]>;
  serverTags: TagsReducer['server'];
  useStepState: [number, CallableFunction];
  onClose: () => void;
}

const BasicInfo = ({
  mounts, serverTags, useStepState, onClose, accountTags,
}: Props) => {
  const theme = useTheme();
  const formikProps = useFormikContext<FormikValues>();
  const [modalStep, setModalStep] = useStepState;
  const classes = useStyles();

  const {
    isPagelyAdmin,
    multisite,
    multisiteType,
    fsaEnabled,
    fsaSSL,
    phpVersion,
    primaryDomain,
    mountPoint,
    label,
  } = formikProps.values;

  const phpVersions = [
    {
      'label': 'Latest',
      'value': 'latest',
    },
    {
      'label': 'Pagely Recommended (stable)',
      'value': 'stable',
    },
  ];

  if (mounts.loading) {
    return (
      <Loading />
    );
  }
  let isDockerizedPHPAvailable = false;
  if (serverTags) {
    isDockerizedPHPAvailable = serverTags.data.some((tag) => tag.tagId === DOCKER_SERVER_TAG);
  }

  const handleNext = () => {
    setModalStep(modalStep + 1);
  };

  return (
    <Fragment>
      <DialogContent
        classes={{
          root: modalStep === 1 ? classes.dialogContentRoot : null,
        }}
        css={{ overflow: 'visible' }}
      >
        <Grid container spacing={4}>
          <Grid item sm={6}>
            <Box direction="column" gap="small" padding={{ bottom: 'medium' }}>
              <WideTextField
                value={label}
                fullWidth
                placeholder="App Label (optional)"
                onChange={formikProps.handleChange}
                id="label"
                error={label.length > 50}
                helperText={label.length > 50 ? 'App Label is too long' : 'You can change this label at any time.'}
              />
              <FeatureToggle
                flags={[isDockerizedPHPAvailable]}
              >
                <Box>
                  <div css={{ minWidth: 270, paddingBottom: '0.25rem' }}>
                    <Typography variant="caption" component="label" htmlFor="mountpoint">
                      PHP version
                    </Typography>
                    <Select
                      id="phpVersion"
                      value={phpVersions.find((i) => i.value === phpVersion)}
                      onChange={({ value }) => formikProps.setFieldValue('phpVersion', value)}
                      options={phpVersions}
                    />
                  </div>
                  <InfoText href="https://www.php.net/supported-versions.php">
                    How do PHP versions work?
                  </InfoText>
                </Box>
              </FeatureToggle>
              <FeatureToggle
                flags={[(mounts.data.length > 1)]}
              >
                <Box justify="flex-end">
                  <MountOptions
                    value={mountPoint}
                    mounts={mounts}
                    setMountPoint={(v) => formikProps.setFieldValue('mountPoint', v)}
                  />
                </Box>
              </FeatureToggle>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box direction="column">
              <FeatureToggle
                flags={[(isPagelyAdmin || accountTags.includes(FSA_OPTIN))]}
              >
                <div
                  css={{
                    border: `1px solid ${theme.palette.secondary.main}`,
                    borderRadius: theme.borderRadius,
                  }}
                >
                  <Box padding="small">
                    <div css={{ paddingBottom: 12 }}>
                      <FormControlLabel
                        label="Create app with Mercury Dynamic Site Acceleration enabled"
                        control={(
                          <Checkbox
                            checked={fsaEnabled}
                            onChange={(e) => {
                              formikProps.setFieldValue('fsaEnabled', !fsaEnabled);
                              formikProps.setFieldValue('fsaSSL', e.target.checked ? 'ACM' : null);
                              formikProps.setFieldValue('multisiteType', 'subfolder');
                            }}
                          />
                        )}
                        labelPlacement="end"
                      />
                      <div css={{ marginLeft: 22 }}>
                        <InfoText href="https://support.pagely.com/hc/en-us/articles/360051132192">What is Mercury Dynamic Site Acceleration?</InfoText>
                        <RadioGroup
                          name="fsaSSL"
                          value={fsaSSL}
                          onChange={formikProps.handleChange}
                        >
                          <FormControlLabel
                            value="ACM"
                            control={<Radio />}
                            label="Use Pagely SSL"
                            labelPlacement="end"
                            disabled={!fsaEnabled}
                          />
                          <FormLabel
                            value="upload"
                            control={<Radio />}
                            // label="Use my own certificate"
                            labelPlacement="end"
                            disabled // TODO post-MVP tmake this selectable
                            align="flex-start"
                          // disabled={!FLAGS.FSA_MANUAL || fsaEnabled}
                          >
                            <div>
                              <Typography variant="body1" color="textSecondary">Use my own certificate</Typography>
                              <Typography variant="caption" color="textSecondary">This option is not available yet</Typography>
                            </div>
                          </FormLabel>
                        </RadioGroup>
                      </div>
                    </div>
                    <Typography variant="body2"><strong>Notice:</strong> Mercury Dynamic Site Acceleration cannot be used with any other &quot;page/site accelerator&quot; like Cloudflare, Sucuri, etc.</Typography>
                  </Box>
                </div>
              </FeatureToggle>

              <Box padding="small">
                <div>
                  <FormControlLabel
                    label="MultiSite (off by default)"
                    control={(
                      <Checkbox
                        checked={multisite}
                        onChange={(e) => {
                          formikProps.setFieldValue('multisite', !multisite);
                          formikProps.setFieldValue('multisiteType', e.target.checked ? 'subfolder' : null);
                        }}
                      />
                    )}
                    labelPlacement="end"
                  />
                  {
                    multisite && (
                      <div css={{ marginLeft: 22 }}>
                        <RadioGroup
                          name="multisiteType"
                          value={multisiteType}
                          onChange={formikProps.handleChange}
                        >
                          <FormControlLabel
                            value="subfolder"
                            control={<Radio />}
                            label={`Sub Folders (ex, ${primaryDomain}/example)`}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="subdomain"
                            control={<Radio disabled={fsaEnabled} />}
                            label={`Sub Domains (ex, example.${primaryDomain})${fsaEnabled ? ' - not available with Mercury Dynamic Site Acceleration' : ''}`}
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </div>
                    )
                  }
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box direction="row" justify="space-between" align="center" flex={1}>
          <div>
            <Button onClick={onClose} variant="outlined" color="default">
              Close
            </Button>
          </div>
          <Box
            css={{ minWidth: '50%' }}
          >
            <Stepper
              startingStep={1}
              activeStep={modalStep}
              classes={makeStyles({ root: { padding: 0 } })()}
              showLabels={false}
              steps={formikProps.values.stepperSteps}
            />
          </Box>

          <div>
            <Button
              onClick={handleNext}
              disabled={label.length > 50}
              variant="contained"
            >
              Next
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Fragment>
  );
};

export default React.memo(BasicInfo);
