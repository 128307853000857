export const GET_HEADERS_REQUEST = 'ARES_GET_HEADERS_REQUEST';
export const GET_HEADERS_SUCCESS = 'ARES_GET_HEADERS_SUCCESS';
export const GET_HEADERS_FAILURE = 'ARES_GET_HEADERS_FAILURE';

export const SET_HEADERS_REQUEST = 'ARES_SET_HEADERS_REQUEST';
export const SET_HEADERS_SUCCESS = 'ARES_SET_HEADERS_SUCCESS';
export const SET_HEADERS_FAILURE = 'ARES_SET_HEADERS_FAILURE';

export const DELETE_HEADERS_REQUEST = 'ARES_DELETE_HEADERS_REQUEST';
export const DELETE_HEADERS_SUCCESS = 'ARES_DELETE_HEADERS_SUCCESS';
export const DELETE_HEADERS_FAILURE = 'ARES_DELETE_HEADERS_FAILURE';
