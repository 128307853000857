import React from 'react';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';
import { PCDN_REGEX } from 'shared/modules/dns/constants';

const ListRows = (items) => {
  return items.map((item) => {
    const statuses = [];
    if (item.fqdn?.match(PCDN_REGEX)) {
      statuses.push(
        <GhostTag key="pagely-managed" color="smoke">
          pagely managed
        </GhostTag>,
      );
    } else {
      if (item.primary) {
        statuses.push(
          <GhostTag color="primary" key="primary">
            primary
          </GhostTag>,
        );
      }

      if (item.is301) {
        statuses.push(
          <GhostTag color="green" key="redirect">
            301 redirected
          </GhostTag>,
        );
      }

      if (item.isPressDns) {
        statuses.push(
          <GhostTag color="green" key="pressdns">
            pressdns
          </GhostTag>,
        );
      }
      if (item?.provider === 'godaddy') {
        statuses.push(
          <GhostTag color="orange" key="provider">
            godaddy
          </GhostTag>,
        );
      }
    }

    return {
      data: item,
      display: [
        item.fqdn,
        <Box
          direction="row"
          gap="xsmall"
          align="center"
          justify="flex-end"
          key="statuses"
        >
          {statuses}
        </Box>,
      ],
    };
  });
};

export default ListRows;
