export const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'ACCOUNT_FAILURE';
export const ACCOUNT_CREATE_REQUEST = 'ACCOUNT_CREATE_REQUEST';
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_FAILURE = 'ACCOUNT_CREATE_FAILURE';

export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_FAILURE = 'ACCOUNT_DELETE_FAILURE';

export const ACCOUNT_UPDATE_REQUEST = 'ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';

export const ACCOUNT_TAGS_REQUEST = 'ACCOUNT_TAGS_REQUEST';
export const ACCOUNT_TAGS_SUCCESS = 'ACCOUNT_TAGS_SUCCESS';
export const ACCOUNT_TAGS_FAILURE = 'ACCOUNT_TAGS_FAILURE';
export const ACCOUNT_TAGS_RESET = 'ACCOUNT_TAGS_RESET';

export const ACCOUNT_SECRET_GET_REQUEST = 'ACCOUNT_SECRET_GET_REQUEST';
export const ACCOUNT_SECRET_GET_SUCCESS = 'ACCOUNT_SECRET_GET_SUCCESS';
export const ACCOUNT_SECRET_GET_FAILURE = 'ACCOUNT_SECRET_GET_FAILURE';

export const ACCOUNT_RESET_VALIDATION = 'ACCOUNT_RESET_VALIDATION';
