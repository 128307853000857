import React from 'react';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import { isString, isObject, isArray } from 'shared/utils/types';

export const getErrorMessages = (errors) => {
  if (isString(errors)) {
    return [errors];
  }

  if (isArray(errors)) {
    return [...errors];
  }

  if (!isObject(errors)) {
    return [];
  }

  return Object.values(errors).reduce((acc, val) => {
    const { messages, ...rest } = isObject(val) ? val : {};
    if (messages && isArray(messages) && messages.length) {
      acc.push(...messages);
    }

    if (isObject(rest)) {
      acc.push(...getErrorMessages(rest));
    }

    return acc;
  }, []);
};

export const getErrors = (errors, ignoreList) => {
  const { general, ...specificErrors } = errors;
  if (ignoreList) {
    ignoreList.forEach((err) => delete specificErrors[err]);
  }
  const messages = getErrorMessages(specificErrors);
  return (
    <Box margin={{ bottom: 'small' }} align="center">
      <ErrorText>
        {messages.length ? messages.join('; ') : general}
      </ErrorText>
    </Box>
  );
};

export const getIps = (ips) => {
  return ips.split(',').reduce((acc, ip) => {
    const cleanIp = ip.replace('\n', '').trim();
    if (cleanIp) {
      acc.push(cleanIp);
    }
    return acc;
  }, []);
};
