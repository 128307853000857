import React, { useEffect, useState } from 'react';
import { object, func, array } from 'prop-types';
import { connect } from 'react-redux';

import {
  Typography, Paper,
} from '@material-ui/core';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Empty from 'shared/styleguide/atoms/Empty';
import { consolidateErrors } from 'shared/utils/validation';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import InfoText from 'shared/styleguide/molecules/InfoText';

import { fetchCacheParamsForApp, setCacheParams } from '../../redux/cacheMgmt/actions';
import CustomConfigDescription from '../../CustomConfigDescription';
import FormContent from './FormContent';
import { defaultValues, nonCustomValues } from './constants';

export const CacheKeyMgmt = (props) => {
  const [changed, setChanged] = useState(false);
  const {
    cache, app, getParams, setParams, customConfig,
  } = props;
  const appId = app.data.id;

  useEffect(() => {
    // if access.params.appId !== our app, or its pristine
    // eslint-disable-next-line eqeqeq
    if (cache.status === 'pristine' || cache.params.appId != appId) {
      getParams(appId);
    }
  }, [cache.status, cache.params, appId, getParams]);

  if (['loading', 'pristine'].includes(cache.status)) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (cache.status === 'failed' && ![422, 404].includes(cache.statusCode)) {
    return (
      <div>
        <Empty>
          {cache.errMessage || 'An error occurred.'}
        </Empty>
      </div>
    );
  }

  const handleSubmit = async (values, actions) => {
    // contruct object to send

    const body = {
      ...defaultValues,
      ...values,
    };

    Object.entries(body).forEach(([key, value]) => {
      if (value === true) {
        body[key] = 'allow';
      }
      if (value === false) {
        body[key] = 'remove';
      }

      if (Array.isArray(value)) {
        // custom parameters
        value.forEach((v) => {
          // remove parameter from cache key
          if (v) {
            body[v] = 'remove';
          }
        });
      }
    });

    delete body.customParams;

    actions.setStatus('loading');
    try {
      await setParams(appId, {
        config: {
          parameters: body,
        },
      });

      actions.setStatus('success');
      setTimeout(() => {
        actions.setStatus('pristine');
        setChanged(false);
      }, 3000);
    } catch (err) {
      actions.setStatus('ready');
      actions.setErrors({
        'general': consolidateErrors(err),
        ...err.response.data.body,
      });
    }
  };

  const config = cache.data?.config?.parameters;
  let initialValues = {}; //eslint-disable-line

  if (config) {
    const customParams = Object.keys(config).filter((key) => !nonCustomValues.includes(key));
    initialValues = Object.fromEntries(Object.entries(config)
      .filter(([key, value]) => nonCustomValues.includes(key))
      .map(([key, value]) => [key, value === 'allow']));
    initialValues.customParams = customParams;
  }

  return (
    <div>
      <CustomConfigDescription customConfig={customConfig} />
      <Box margin={{ bottom: 'small' }}>
        <Typography variant="h3">Cache Key Management</Typography>
      </Box>
      <Paper>
        <Box padding="small">
          <InfoText>
            Including UTM parameters in the cache key may reduce cache hit rates, increase server response times, and negatively impact your site&apos;s performance as a whole. We recommend only including these parameters when absolutely necessary.
            For more information, see our&nbsp;
            <TextLink
              variant="body2"
              target="_blank"
              rel="noopener nofollow noreferrer"
              href="https://support.pagely.com/hc/en-us/articles/360050745472-Understanding-UTM-Parameters"
            >
              Understanding UTM Parameters
            </TextLink>
            &nbsp;and&nbsp;
            <TextLink
              variant="body2"
              target="_blank"
              rel="noopener nofollow noreferrer"
              href="https://support.pagely.com/hc/en-us/articles/230879508"
            >
              UTM Variables and Caching
            </TextLink>
            &nbsp;knowledgebase articles.
          </InfoText>
          <FormContent
            initialValues={initialValues}
            onSubmit={handleSubmit}
            changed={changed}
            onSetChanged={setChanged}
          />
        </Box>
      </Paper>
    </div>
  );
};

CacheKeyMgmt.propTypes = {
  app: object.isRequired,
  cache: object.isRequired,
  customConfig: array,
  getParams: func.isRequired,
  setParams: func.isRequired,
};

export default connect(
  (state) => ({
    app: state.app.app,
    cache: state.ares.cache,
  }),
  {
    getParams: fetchCacheParamsForApp,
    setParams: setCacheParams,
  },
)(CacheKeyMgmt);
