import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';

import Typography from '@material-ui/core/Typography';

import { getAllValidationMessages } from 'shared/utils/validation';
import Loading from 'shared/styleguide/atoms/Loading';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Select from 'shared/styleguide/atoms/Select/Select';
import Layout from 'shared/styleguide/molecules/Layout';
import Empty from 'shared/styleguide/atoms/Empty';
import { dateRangePickerOverrides } from 'shared/utils/dateRangePicker';
import { isInclusiveAfterDayUTC } from 'shared/utils/moment';

import { fetchAlerts } from 'shared/modules/alerts/redux/actions';

import AlertsList from './AlertsList';
import ForwardedAlertsSearch from './AlertsSearch';

const initialFilter = {
  fromDate: moment.utc().subtract(30, 'days').tz('UTC').startOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  toDate: moment.utc().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
};

const initialDates = {
  fromDate: moment.utc().subtract(30, 'days'),
  toDate: moment.utc(),
};

export const Alerts = ({
  alerts, fetchAlerts,
}) => {
  const accountIdRef = useRef();
  const searchRef = useRef();
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [requiredMessage, setRequiredMessage] = useState(null);
  const [filter, setFilter] = useState(initialFilter);
  const [dates, setDates] = useState(initialDates);

  const [focusState, setFocusState] = useState({
    dateFocused: null,
  });

  useEffect(() => {
    if (filter.search && !filter.accountId) {
      setRequiredMessage('You must enter an Account Id to use the Filter field.');
      accountIdRef.current.focus();
    } else {
      setRequiredMessage(null);
      fetchAlerts(filter);
    }
  }, [filter, fetchAlerts]);

  const handleSetFilter = (param, value) => {
    const updatedFilter = { ...filter };

    // grab values from selectors
    if (!['accountId', 'search'].includes(param)) {
      updatedFilter[param] = value;
    }

    // grab values from inputs
    updatedFilter.accountId = accountIdRef.current.value;
    updatedFilter.search = searchRef.current.value;

    // remove null/empty
    Object.keys(updatedFilter).forEach((f) => {
      if (!updatedFilter[f]) {
        delete updatedFilter[f];
      }
      if (value === null) {
        delete updatedFilter[param];
      }
    });

    setFilter({ ...updatedFilter });
  };

  const handleSetAccount = () => {
    if (accountIdRef.current.value) {
      setSearchDisabled(false);
    } else {
      setSearchDisabled(true);
    }
  };

  const closeCalendar = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      setFilter({
        ...filter,
        fromDate: moment.utc(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        toDate: moment.utc(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    if ((startDate && !endDate) || (!startDate && endDate)) {
      setRequiredMessage('To filter by date range, both start and end dates must be set.');
    }

    if (!startDate && !endDate) {
      const { fromDate, toDate, ...rest } = filter;
      setFilter({ ...rest });
    }
  };

  return (
    <Box padding="medium">
      <Layout title="Alerts">
        <Box>
          <Box row gap="xsmall" flex={1} style={{ width: '100%' }}>
            <Box flex={1}>
              <ForwardedAlertsSearch
                ref={accountIdRef}
                label="AccountId"
                field="accountId"
                onSearch={handleSetFilter}
                onChange={handleSetAccount}
                error={Boolean(requiredMessage) && Boolean(!accountIdRef.current.value)}
              />
            </Box>
            <Box flex={1}>
              <ForwardedAlertsSearch
                ref={searchRef}
                label="Filter"
                field="search"
                onSearch={handleSetFilter}
                disabled={searchDisabled}
              />
            </Box>
            <Box flex={1} style={{ maxWidth: 262 }}>
              <Typography variant="caption">Date Range</Typography>
              <div css={dateRangePickerOverrides}>
                <DateRangePicker
                  startDate={dates.fromDate}
                  endDate={dates.toDate}
                  startDateId="start"
                  endDateId="end"
                  onDatesChange={({ startDate, endDate }) => {
                    setDates({
                      fromDate: startDate,
                      toDate: endDate,
                    });

                    if (!startDate && !endDate) {
                      const { fromDate, toDate, ...rest } = filter;
                      setFilter({ ...rest });
                    }
                  }}
                  onClose={({ startDate, endDate }) => closeCalendar({ startDate, endDate })}
                  focusedInput={focusState.dateFocused}
                  onFocusChange={(focused) => setFocusState({ dateFocused: focused })}
                  isOutsideRange={isInclusiveAfterDayUTC}
                  showClearDates
                />
              </div>
            </Box>
            <Box flex={1}>
              <Typography variant="caption">Type</Typography>
              <Select
                hideSelectedOptions={false}
                placeholder="Select Type..."
                onChange={(e) => handleSetFilter('alertType', e.value)}
                options={
                  [
                    { value: null, label: 'All' },
                    { value: 'Disk', label: 'Disk' },
                    { value: 'Billing', label: 'Billing' },
                    { value: 'Security', label: 'Security' },
                    { value: 'Maintenance', label: 'Maintenance' },
                    { value: 'App Activity', label: 'App Activity' },
                    { value: 'SSL', label: 'SSL' },
                  ]
                }
              />
            </Box>
            <Box flex={1}>
              <Typography variant="caption">Status</Typography>
              <Select
                hideSelectedOptions={false}
                placeholder="Select Status..."
                onChange={(e) => handleSetFilter('status', e.value)}
                options={
                  [
                    { value: null, label: 'All' },
                    { value: 'open', label: 'Open' },
                    { value: 'responded', label: 'Responded' },
                    { value: 'closed', label: 'Closed' },
                  ]
                }
              />
            </Box>
          </Box>
        </Box>
        <Box align="center" margin={{ top: 'medium' }}>
          {
            requiredMessage && alerts.status !== 'loading'
            && (
              <Box align="center" margin={{ top: 'large' }}>
                <Typography align="center">{requiredMessage}</Typography>
              </Box>
            )
          }
          {
            !requiredMessage && alerts.status === 'success' && alerts.data.length > 0
            && <AlertsList items={alerts.data} />
          }
          {
            alerts.status === 'loading'
            && <Loading />
          }
          {
            !requiredMessage && alerts.status === 'success' && alerts.data.length === 0
            && (
              <Box margin={{ top: 'large' }} style={{ width: '100%' }}>
                <Empty>No results</Empty>
              </Box>
            )
          }
          {
            alerts.status === 'failed'
            && (
              <Box align="center" margin={{ top: 'large' }}>
                <ErrorText align="center">Failed to load alerts</ErrorText>
                {
                  alerts.apiErrors
                  && <ErrorText align="center">{alerts.apiErrors}: <br />{getAllValidationMessages(alerts)}</ErrorText>
                }
              </Box>
            )
          }
        </Box>
      </Layout>
    </Box>
  );
};

Alerts.propTypes = {
  alerts: PropTypes.shape({
    apiErrors: PropTypes.string,
    data: PropTypes.array.isRequired,
    status: PropTypes.string,
  }),
  fetchAlerts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
  };
};

export default connect(mapStateToProps, { fetchAlerts })(Alerts);
