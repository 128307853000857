/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { ClassNames } from '@emotion/react';
import YouTube from 'react-youtube';
import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import { Typography } from '@material-ui/core';

import YOUTUBE from 'shared/3rdparty/youtube';
import Loading from 'shared/styleguide/atoms/Loading';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';

import ArticleList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';
import { useGetArticlesByLabelQuery } from '../../redux/query';

export const FAQ = () => {
  const { data: articles, isLoading } = useGetArticlesByLabelQuery('faq');

  if (isLoading) {
    return (
      <div>
        <Typography variant="h4">Frequently Asked Questions</Typography>
        <Box margin={{ bottom: 'medium' }} style={{ textAlign: 'right' }}>
          <Loading />
        </Box>
      </div>
    );
  }

  if (articles.data.length === 0) {
    return (
      <div>
        <Typography variant="h4">Frequently Asked Questions</Typography>
        <Empty style={{ marginTop: 50 }}>
          No FAQ Articles found yet.
        </Empty>
      </div>
    );
  }

  return (
    <Box direction="row" wrap="wrap" gap="medium">
      <Box flex={1}>
        <Typography variant="h3" gutterBottom>Getting Started!</Typography>
        <Box as={Paper} padding="small">
          <Typography variant="body1" gutterBottom>
            See our video series on getting started! Click the &quot;Playlist&quot; (
            <ClassNames>
              {({ css }) => (
                <PlaylistIcon
                  className={css`
                      vertical-align: text-top;
                      padding-left: 2px;
                      padding-bottom: 3px;
                    `}
                />
              )}
            </ClassNames>
            ) button for more videos.
          </Typography>
          <YouTube
            opts={{
              playerVars: {
                autoplay: 0,
                modestbranding: 1,
                rel: 0,
                listType: 'playlist',
                list: YOUTUBE.ONBOARDING_PLAYLIST,
              },
            }}
          />
        </Box>
      </Box>
      <Box flex="500px">
        <Typography variant="h3" gutterBottom>Common Support Questions</Typography>
        <Box>
          <ArticleList
            topic="faq"
            columns={1}
            boxProps={{
              wrap: 'wrap',
            }}
            articles={articles.data}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FAQ;
