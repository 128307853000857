export const TWO_FACTOR_SETUP_REQUEST_REQUEST = 'TWO_FACTOR_SETUP_REQUEST_REQUEST';
export const TWO_FACTOR_SETUP_REQUEST_SUCCESS = 'TWO_FACTOR_SETUP_REQUEST_SUCCESS';
export const TWO_FACTOR_SETUP_REQUEST_FAILURE = 'TWO_FACTOR_SETUP_REQUEST_FAILURE';
export const TWO_FACTOR_VERIFY_REQUEST_REQUEST = 'TWO_FACTOR_VERIFY_REQUEST_REQUEST';
export const TWO_FACTOR_VERIFY_REQUEST_SUCCESS = 'TWO_FACTOR_VERIFY_REQUEST_SUCCESS';
export const TWO_FACTOR_VERIFY_REQUEST_FAILURE = 'TWO_FACTOR_VERIFY_REQUEST_FAILURE';
export const TWO_FACTOR_DISABLE_REQUEST_REQUEST = 'TWO_FACTOR_DISABLE_REQUEST_REQUEST';
export const TWO_FACTOR_DISABLE_REQUEST_SUCCESS = 'TWO_FACTOR_DISABLE_REQUEST_SUCCESS';
export const TWO_FACTOR_DISABLE_REQUEST_FAILURE = 'TWO_FACTOR_DISABLE_REQUEST_FAILURE';
export const TWO_FACTOR_SETUP_REQUEST_RESET = 'TWO_FACTOR_SETUP_REQUEST_RESET';
export const TWO_FACTOR_VERIFY_REQUEST_RESET = 'TWO_FACTOR_VERIFY_REQUEST_RESET';
export const TWO_FACTOR_DISABLE_REQUEST_RESET = 'TWO_FACTOR_DISABLE_REQUEST_RESET';
