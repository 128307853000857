import { combineReducers } from 'redux';
import { baseReducers } from 'shared/modules/reducers';
import { apps, accounts } from 'admin/modules/search/reducer';
import { configs } from 'admin/modules/aresconfig/routes/View/redux/reducer';
import { email } from 'admin/modules/email/redux/reducer';
import {
  undoableConfig,
  inputErrors,
  jsonOutput,
  imports,
  redirectsWizard,
  schema,
} from 'admin/modules/aresconfig/routes/Tool/redux/reducer';

const rootReducer = combineReducers({
  ...baseReducers,
  search: combineReducers({
    accounts,
    apps,
  }),
  email,
  aresConfig: combineReducers({
    undoableConfig,
    inputErrors,
    jsonOutput,
    imports,
    redirectsWizard,
    schema,
    configs,
  }),
});

export default rootReducer;
