import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { refType } from '@material-ui/utils';
import { useFormControl } from '@material-ui/core/FormControl';
import withStyles from '@material-ui/styles/withStyles';
import { capitalize } from 'shared/utils';
import Box from 'shared/styleguide/atoms/Box';

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    'display': 'inline-flex',
    'alignItems': 'center',
    'cursor': 'pointer',
    // For correct alignment with the text.
    'verticalAlign': 'middle',
    'WebkitTapHighlightColor': 'transparent',
    'marginLeft': -11,
    'marginRight': 16, // used for row presentation of radio/checkbox
    '&$disabled': {
      cursor: 'default',
    },
  },
  /* Styles applied to the root element if `labelPlacement="start"`. */
  labelPlacementStart: {
    flexDirection: 'row-reverse',
    marginLeft: 16, // used for row presentation of radio/checkbox
    marginRight: -11,
  },
  /* Styles applied to the root element if `labelPlacement="top"`. */
  labelPlacementTop: {
    flexDirection: 'column-reverse',
    marginLeft: 16,
  },
  /* Styles applied to the root element if `labelPlacement="bottom"`. */
  labelPlacementBottom: {
    flexDirection: 'column',
    marginLeft: 16,
  },
  /* Pseudo-class applied to the root element if `disabled={true}`. */
  disabled: {},
  /* Styles applied to the label's Typography component. */
  label: {
    '&$disabled': {
      color: theme.palette?.text?.disabled,
    },
  },
});

/**
 * Drop in replacement of the `Radio`, `Switch` and `Checkbox` component.
 * Use this component if you want to display an extra label.
 */
const FormControlLabel = React.forwardRef((props, ref) => {
  const {
    checked,
    classes,
    className,
    control,
    disabled: disabledProp,
    inputRef,
    label,
    labelPlacement = 'end',
    name,
    onChange,
    value,
    children,
    align,
    boxProps,
    ...other
  } = props;
  const muiFormControl = useFormControl();

  let disabled = disabledProp;
  if (typeof disabled === 'undefined' && typeof control.props.disabled !== 'undefined') {
    disabled = control.props.disabled;
  }
  if (typeof disabled === 'undefined' && muiFormControl) {
    disabled = muiFormControl.disabled;
  }

  const controlProps = {
    disabled,
  };

  ['checked', 'name', 'onChange', 'value', 'inputRef'].forEach((key) => {
    if (typeof control.props[key] === 'undefined' && typeof props[key] !== 'undefined') {
      controlProps[key] = props[key];
    }
  });

  return (
    <label
      className={clsx(
        classes.root,
        {
          [classes[`labelPlacement${capitalize(labelPlacement)}`]]: labelPlacement !== 'end',
          [classes.disabled]: disabled,
        },
        className,
      )}
      ref={ref}
      {...other}
    >
      <Box direction={labelPlacement === 'start' ? 'row-reverse' : 'row'} align={align || 'flex-start'} {...boxProps}>
        {React.cloneElement(control, controlProps)}
        {
          children
        }
      </Box>
    </label>
  );
});

FormControlLabel.displayName = 'RadioInput';

FormControlLabel.propTypes = {
  align: PropTypes.string,
  boxProps: PropTypes.object,
  /**
   * If `true`, the component appears selected.
   */
  checked: PropTypes.bool,
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * A control element. For instance, it can be be a `Radio`, a `Switch` or a `Checkbox`.
   */
  control: PropTypes.element.isRequired,
  /**
   * If `true`, the control will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Pass a ref to the `input` element.
   */
  inputRef: refType,
  /**
   * The text to be used in an enclosing label element.
   */
  label: PropTypes.node,
  /**
   * The position of the label.
   */
  labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
  /**
   * @ignore
   */
  name: PropTypes.string,
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange: PropTypes.func,
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};

export default withStyles(styles)(FormControlLabel);
