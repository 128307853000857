import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  object, func, number, array, bool, string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import logger from 'shared/3rdparty/logger';
import Box from 'shared/styleguide/atoms/Box';

import {
  getDiskAddonCalculation, resetDiskAddonCalculation, resetDiskAddonPurchase,
} from 'shared/modules/billing/redux/disk/actions';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import {
  fetchDiskReportJobs, fetchDiskUpgradeJobs, resetDiskUpgradeJobs, fetchRecentDiskUpgradeJobs,
} from 'shared/modules/status/redux/actions';
import { getSortedJobs } from 'shared/modules/status/redux/selectors';
import * as STATUS from 'shared/modules/status/redux/constants';
import { consolidateErrors } from 'shared/utils/validation';

import { listReports, requestFullReport } from '../../../redux/actions';

import { filterOutCustomMounts } from '../diskUtils';

import DiskProgressView from './DiskProgressView';
import DiskModifications from './DiskModifications';

export const PlanSummary = ({
  accountId,
  mounts,
  match,
  planAddonsSize,
  planName,
  planTotalSize,
  planSize,
  isUpgradable,
  getDiskAddonCalculation,
  resetDiskAddonCalculation,
  resetDiskAddonPurchase,
  listReports,
  requestFullReport,
  diskAddonPurchase,
  fetchDiskReportJobs,
  fetchDiskUpgradeJobs,
  resetDiskUpgradeJobs,
  fetchRecentDiskUpgradeJobs,
  jobs,
  isAdmin,
  stateMocks,
}) => {
  const [recentDiskJobs, setRecentDiskJobs] = useState(stateMocks?.recentDiskJobs || null);
  const [updatedAddonsSize, setUpdatedAddonsSize] = useState(stateMocks?.updatedAddonsSize || null);
  const [reports, setReports] = useState([]);
  const [showDiskChange, setShowDiskChange] = useState(stateMocks?.showDiskChange || false);
  const [newSize, setNewSize] = useState(stateMocks?.newSize || 0);
  const [diskJobType, setDiskJobType] = useState(null);
  const [diskReportsStatus, setDiskReportsStatus] = useState('initial');
  const [errors, setErrors] = useState(null);

  const getDiskJob = (jobType) => {
    fetchDiskUpgradeJobs(accountId, jobType, { maxResults: 1, statusIn: STATUS.runningStatuses });
  };

  const memoizedGetDiskJob = useCallback(getDiskJob, [accountId, fetchDiskUpgradeJobs]);

  const handleStorageChange = (e, v) => {
    setNewSize(v);
  };

  const handleStorageChangeCommitted = (e, v) => {
    resetDiskAddonPurchase();
    getDiskAddonCalculation({ accountId, newSize: v });
  };

  const handleShowDiskChange = () => {
    if (showDiskChange === true) {
      resetDiskAddonCalculation();
      resetDiskAddonPurchase();
      setNewSize(planTotalSize);
    }
    setShowDiskChange(!showDiskChange);
  };

  const handleGetDiskReportJobStatus = () => {
    fetchDiskReportJobs(accountId, { type: STATUS.jobTypes.diskReport, maxResults: 1 });
  };

  const handleGetDiskUpgradeJobStatus = () => {
    getDiskJob(diskJobType);
  };

  const handleSetDiskReportsStatus = (value) => {
    setDiskReportsStatus(value);
  };

  const handleGetRecentDiskJobs = async (jobType) => {
    try {
      const response = await fetchRecentDiskUpgradeJobs(accountId, jobType);
      setRecentDiskJobs(response);
    } catch (err) {
      logger.error(err);
    }
  };

  const getReports = async (update = false) => {
    setErrors(null);
    if (update) {
      setDiskReportsStatus('loading');
    }
    try {
      const response = await listReports(match.params.accountID);
      setReports(response?.data);
      if (update) {
        setDiskReportsStatus('success');
      }
    } catch (err) {
      setErrors(consolidateErrors(err));
      if (update) {
        setDiskReportsStatus('failed');
      }
    }
  };

  const memoziedGetReports = useCallback(getReports, [setDiskReportsStatus, setErrors, listReports, match.params.accountID]);

  let diskJob;
  if (jobs[diskJobType]?.data?.length === 1) {
    [diskJob] = jobs[diskJobType].data;
  }
  let reportJob;
  let diskReportJobs;
  if (isAdmin) {
    diskReportJobs = jobs[STATUS.jobTypes.diskReport].data?.filter((job) => Number(job.ownerId) === accountId);
  } else {
    // pusher does not send ownerId, but customer won't receive jobs from other accounts
    diskReportJobs = jobs[STATUS.jobTypes.diskReport].data;
  }

  if (diskReportJobs?.length > 0) {
    [reportJob] = diskReportJobs;
  }

  const purchaseSuccess = diskAddonPurchase.status === 'success' && diskAddonPurchase.params?.accountId === accountId;

  useEffect(() => {
    if (diskAddonPurchase.status === 'success' && diskAddonPurchase.data.jobId) {
      memoizedGetDiskJob(diskJobType);
    }
  }, [diskAddonPurchase, memoizedGetDiskJob, accountId, diskJobType]);

  useEffect(() => {
    if (diskReportJobs?.[0]?.overallStatus === STATUS.SUCCESS
      && diskReportsStatus === 'initial') {
      memoziedGetReports(true);
    }
  }, [diskReportJobs, diskReportsStatus, memoziedGetReports]);

  useEffect(() => {
    const diskJobType = planName.includes('[HA]') ? STATUS.jobTypes.diskUpgradeHA : STATUS.jobTypes.diskUpgradeVps;

    if (!recentDiskJobs) {
      handleGetRecentDiskJobs(diskJobType);
    }

    setDiskJobType(diskJobType);
    setNewSize(stateMocks?.newSize || planTotalSize);
    getReports();
    getDiskJob(diskJobType);
    return function cleanup() {
      resetDiskAddonCalculation();
      resetDiskUpgradeJobs();
    };
  }, []);

  useEffect(() => {
    if (diskJob?.overallStatus === STATUS.SUCCESS && !updatedAddonsSize) {
      // for display in plan summary
      setUpdatedAddonsSize(newSize - planTotalSize);
    }
  }, [diskJob, newSize, planTotalSize, setUpdatedAddonsSize, updatedAddonsSize]);

  const filteredMounts = filterOutCustomMounts(mounts);

  const sortedMounts = Object.values(filteredMounts)
    .sort((a, b) => {
      return a.petname >= b.petname ? 1 : -1;
    });

  const updatedAddonsSizeValue = updatedAddonsSize || planAddonsSize;

  const [firstServer] = sortedMounts;

  let maxSize = planTotalSize;

  let modificationSize = newSize;

  if (!purchaseSuccess && diskJob && firstServer) {
    // page was refreshed, use stats size for previous size
    maxSize = firstServer.allowed;
  }

  let modificationType = 'MODIFICATION';
  let modificationText = 'Modifying';

  if (diskJob) {
    if (diskJob.detail?.primary?.jenkinsParams?.SIZE) {
      // if there's a disk job, get the new size from that
      modificationSize = diskJob.detail?.primary?.jenkinsParams?.SIZE;
    }
  }

  if (modificationSize > maxSize) {
    modificationType = 'UPGRADE';
    modificationText = 'Upgrading';
  }

  if (modificationSize < maxSize) {
    modificationType = 'DOWNGRADE';
    modificationText = 'Downgrading';
  }

  const reportsByServerId = reports?.reduce((acc, report) => {
    if (!acc[report.serverId]) {
      acc[report.serverId] = report;
    }
    return acc;
  }, {});

  return (
    <Box as={Paper} padding="medium" margin={{ bottom: 'medium' }}>
      <Box row margin={{ bottom: 'small' }} justify="space-between">
        <Box>
          <Typography variant="h2" gutterBottom><strong>{planName}</strong></Typography>
          <Typography variant="h6" color="textSecondary">Plan storage: {planSize} GiB</Typography>
          {
            updatedAddonsSizeValue > 0
            && (!diskJob || diskJob.overallStatus === STATUS.SUCCESS)
            && <Typography variant="h6" color="textSecondary">Add-on Storage: {updatedAddonsSizeValue} GiB</Typography>
          }
          {
            diskJob && diskJob.overallStatus !== STATUS.SUCCESS
            && <Typography variant="h6" color="textSecondary">Add-on Storage: {modificationText}</Typography>
          }
        </Box>
      </Box>
      {
        sortedMounts.map((server) => {
          const hasReport = reportsByServerId?.[server.id]?.timestamp;
          return (
            <DiskProgressView
              key={server.id}
              serverId={server.id}
              serverHostname={server.hostname}
              hasReport={hasReport}
              usage={server.usage}
              storageMax={server.allowed}
              serverName={server.petname}
              label={server.label}
              match={match}
              multiServer={Object.keys(filteredMounts).length > 1}
              requestFullReport={requestFullReport}
              reportJob={reportJob}
              onHandleGetDiskReportJobStatus={handleGetDiskReportJobStatus}
              diskReportsStatus={diskReportsStatus}
              onSetDiskReportsStatus={handleSetDiskReportsStatus}
              isAdmin={isAdmin}
              diskJob={diskJob}
              newSize={newSize}
              planTotalSize={planTotalSize}
              stateMocks={stateMocks}
              purchaseSuccess={purchaseSuccess}
              isUpgradable={isUpgradable}
              modificationSize={modificationSize}
              modificationText={modificationText}
            />
          );
        })
      }
      <DiskModifications
        accountId={accountId}
        planSize={planSize}
        planTotalSize={planTotalSize}
        isUpgradable={isUpgradable}
        newSize={newSize}
        onChange={handleStorageChange}
        onChangeCommitted={handleStorageChangeCommitted}
        onCancel={handleShowDiskChange}
        purchaseSuccess={purchaseSuccess}
        diskJob={diskJob}
        diskAddonPurchase={diskAddonPurchase}
        onGetDiskUpgradeJobStatus={handleGetDiskUpgradeJobStatus}
        onShowDiskChange={handleShowDiskChange}
        showDiskChange={showDiskChange}
        errors={errors}
        isAdmin={isAdmin}
        stateMocks={stateMocks}
        sortedMounts={sortedMounts}
        modificationType={modificationType}
        recentDiskJobs={recentDiskJobs?.data}
      />
    </Box>
  );
};

PlanSummary.propTypes = {
  accountId: number,
  diskAddonPurchase: object,
  fetchDiskReportJobs: func,
  fetchDiskUpgradeJobs: func,
  fetchRecentDiskUpgradeJobs: func,
  getDiskAddonCalculation: func.isRequired,
  isAdmin: bool,
  isUpgradable: bool,
  jobs: object,
  listReports: func,
  match: object,
  mounts: array,
  planAddonsSize: number,
  planName: string,
  planSize: number,
  planTotalSize: number,
  requestFullReport: func,
  resetDiskAddonCalculation: func.isRequired,
  resetDiskAddonPurchase: func.isRequired,
  resetDiskUpgradeJobs: func.isRequired,
  stateMocks: object,
};

export default connect(
  (state) => ({
    diskAddonPurchase: state.billing.diskAddonPurchase, // MOCKS.purchaseSuccessJob // MOCKS.purchaseSuccessTicket,
    jobs: {
      [STATUS.jobTypes.diskUpgradeVps]: {
        ...state.status[STATUS.jobTypes.diskUpgradeVps],
        data: getSortedJobs(state, STATUS.jobTypes.diskUpgradeVps),
      },
      [STATUS.jobTypes.diskUpgradeHA]: {
        ...state.status[STATUS.jobTypes.diskUpgradeHA],
        data: getSortedJobs(state, STATUS.jobTypes.diskUpgradeHA),
      },
      [STATUS.jobTypes.diskReport]: {
        ...state.status[STATUS.jobTypes.diskReport],
        data: getSortedJobs(state, STATUS.jobTypes.diskReport),
      },
    },
    // jobs: MOCKS.jobs,
  }),
  {
    listReports,
    fetchDiskReportJobs,
    fetchDiskUpgradeJobs,
    fetchRecentDiskUpgradeJobs,
    requestFullReport,
    getDiskAddonCalculation,
    resetDiskAddonCalculation,
    resetDiskAddonPurchase,
    resetDiskUpgradeJobs,
  },
)(PlanSummary);
