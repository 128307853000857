import { labeledUSLocale } from '../constants';

export const createLabeledCountries = (geoCountries, geoInverse, locales) => {
  const localesWithUS = [...locales, labeledUSLocale];
  const labeledCountries = geoCountries ? geoCountries.map((c) => {
    return localesWithUS.find((locale) => locale.id === c);
  }) : [];

  if (geoInverse === true && !geoCountries?.find((c) => c === 'US')) {
    labeledCountries.push(labeledUSLocale);
  }

  return labeledCountries.filter((c) => c);
};
