import React, { Fragment, useState } from 'react';
import { func, node, string } from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import { Typography } from '@material-ui/core';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';

const useStyles = makeStyles((theme) => {
  return ({
    startIcon: (props) => ({
      color: !props.disabled && theme.palette.error.main,
    }),
    endIcon: (props) => ({
      color: !props.disabled && theme.palette.error.main,
    }),
  });
});

const ConfirmAction = ({
  onConfirm, children, explainerText, secondaryText, variant, ...props
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles(props);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <Button classes={classes} variant={variant || 'outlined'} onClick={() => setOpen(true)} {...props}>
        {
          children
        }
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {children}
        </DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText component="div">
              <Typography color="textPrimary" variant="body1" gutterBottom>
                {explainerText}
              </Typography>
              {
                secondaryText && (
                  <Typography color="textSecondary" variant="body2" component="div">
                    {secondaryText}
                  </Typography>

                )
              }
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box direction="row" justify="space-between" flex={1} padding={{ right: 'small', left: 'small', bottom: 'xsmall' }}>
            <Button autoFocus color="default" variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                onConfirm();
                setOpen(false);
              }}
            >
              Yes, {children}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

ConfirmAction.propTypes = {
  explainerText: node,
  onConfirm: func.isRequired,
  secondaryText: node,
  variant: string,
};

export default ConfirmAction;
