import get from 'lodash/get';

import { deepClone } from 'shared/utils';

import schema from './json/schema.json';

const SchemaTypes = {
  OBJECT: 'object',
  ARRAY: 'array',
  STRING: 'string',
};

const templatedStringProperties = [
  'path',
  'location',
  'uri',
  'value',
  'arg-value',
  'upstream-stack',
];

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export function isInteger(value) {
  return value === parseInt(value, 10);
}

export function isArray(value) {
  return Array.isArray(value);
}

export function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}

export function getOptions(path, schema, type) {
  let newobj = schema;
  let currentOption;
  let option;
  const pathRep = path.replace(/\.\d/g, '');
  const pathArr = pathRep.split('.');
  pathArr.shift();
  if (pathArr.length !== 0) {
    for (let i = 0; i < pathArr.length - 1; i++) {
      if (newobj.type === SchemaTypes.ARRAY) {
        newobj = newobj.items.properties[pathArr[i]];
      } else if (newobj.type === SchemaTypes.OBJECT) {
        newobj = newobj.properties[pathArr[i]];
      }
    }
    if (newobj.type === SchemaTypes.OBJECT) {
      currentOption = newobj.properties[pathArr.pop()];
    } else if (newobj.type === SchemaTypes.ARRAY) {
      currentOption = newobj.items.properties[pathArr.pop()];
    }
  } else {
    currentOption = newobj;
    const rulesList = ['redirects', 'rules', 'miss-rules'];

    const ruleTypes = rulesList.filter((x) => x !== type);
    option = {};
    Object.keys(currentOption.properties).forEach((item) => {
      if (!ruleTypes.includes(item)) {
        option[item] = currentOption.properties[item];
      }
    });
    return option;
  }

  if (isArray(currentOption?.type)) {
    // for case such as templated strings which can be type array or string
    // just use the first element from the schema
    currentOption.type = currentOption.type[0]; // eslint-disable-line
  }

  if (currentOption?.type === SchemaTypes.OBJECT) {
    option = currentOption.properties;
  } else if (currentOption?.type === SchemaTypes.ARRAY) {
    if (currentOption.items && currentOption.items.enum !== undefined) {
      option = currentOption.items.enum;
    } else if (currentOption?.description === 'input-array') {
      option = {
        optionType: 'input-array',
        value: [''],
      };
    } else {
      option = currentOption.items.properties;
    }
  } else if (currentOption.enum === undefined) {
    option = '';
    if (currentOption.default !== undefined) {
      option = currentOption.default;
    }
  } else if (currentOption.enum !== undefined) {
    option = currentOption.enum;
  }
  return option;
}

export function isBoolean(value) {
  return typeof value === 'boolean';
}

export function compact(array) {
  const filtered = array.filter((el) => {
    return el !== '';
  });
  return filtered;
}

export function getConfigType(currentConfig) {
  if (currentConfig.redirects !== undefined) {
    return 'redirects';
  } else if (currentConfig.rules !== undefined) {
    return 'rules';
  } else if (currentConfig['miss-rules'] !== undefined) {
    return 'miss-rules';
  }
}

export function getInitialOptions(path, option, type) {
  const opts = getOptions(path, option, type);
  let initialVal = '';
  if (isString(opts)) {
    // input string
    initialVal = opts;
  } else if (isObject(opts) && !opts?.optionType) {
    // sub item
    const obj = {};
    Object.keys(opts).forEach((item) => {
      // 2 values returned
      if (opts[item].type === SchemaTypes.STRING) {
        // sub string
        obj[item] = '';
      } else {
        // sub array
        [obj[item]] = opts[item].enum;
      }
    });
    initialVal = obj;
  } else if (isObject(opts) && opts?.optionType === 'input-array') {
    // sub array item
    initialVal = opts.value;
  } else if (isObject(opts) && opts?.optionType === 'map') {
    // sub array item
    initialVal = opts.value;
  } else {
    // selector, grab first element
    [initialVal] = opts;
  }
  return initialVal;
}

export function getOptionComment(path) {
  let newobj = schema;
  let currentOption;
  const pathRep = path.replace(/\.\d/g, '');
  const pathArr = pathRep.split('.');
  pathArr.shift();
  if (pathArr.length !== 0) {
    for (let i = 0; i < pathArr.length - 1; i++) {
      if (newobj.type === SchemaTypes.ARRAY) {
        newobj = newobj.items.properties[pathArr[i]];
      } else if (newobj.type === SchemaTypes.OBJECT) {
        newobj = newobj.properties[pathArr[i]];
      }
    }
    if (newobj.type === SchemaTypes.OBJECT) {
      currentOption = newobj.properties[pathArr.pop()];
    } else if (newobj.type === SchemaTypes.ARRAY) {
      currentOption = newobj.items.properties[pathArr.pop()];
    }
  }

  if (currentOption.comment) {
    return currentOption.comment;
  }
}

export function changeConfigType(newState, config, source, target, schema) {
  const newConfig = { ...config };
  let output = {};
  if (source === 'redirects') {
    if (newConfig.redirects.exitCode !== undefined) {
      delete newConfig.redirects.exitCode;
    }
    output = {
      [target]: [...newConfig.redirects.rules],
    };
  } else if (target === 'redirects') {
    output = {
      [target]: {
        exitCode: getInitialOptions('config.redirects.exitCode', schema, target),
        rules: [...newConfig[source]],
      },
    };
  } else {
    output = {
      [target]: [...newConfig[source]],
    };
  }
  return { ...newState, config: output };
}

export function handleJSONInputText(input) {
  if (input === '') {
    return '';
  } else {
    return input;
  }
}

export function isSet(object, path) {
  const pathArr = path.split('.');
  const reducer = (xs, x) => (xs && xs[x]);
  if (pathArr.reduce(reducer, object) === undefined) {
    return false;
  }
  return true;
}

export function reduceObj(obj, path) {
  let newobj = obj;
  const pathArr = path.split('.');
  for (let i = 0; i < pathArr.length - 1; i++) {
    newobj = newobj[pathArr[i]];
    if (typeof newobj === 'undefined') {
      // if path is undefined return it's parent
      return;
    }
  }
  return newobj;
}

export function deletePropertyByPath(obj, path) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  let a;
  if (!Array.isArray(newobj)) {
    delete newobj[last_element];
  } else {
    a = last_element;
    newobj.splice(a, 1);
  }
}

export function deleteByPath(obj, path) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  let a;
  if (!Number.isNaN(last_element)) {
    delete newobj[last_element];
  } else {
    a = last_element;
    newobj.splice(a, 1);
  }
}

export function updateRuleBlockByPath(obj, path, value) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  newobj[last_element] = value;
}

export function updateObjectByPath(obj, path, value) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  newobj[last_element] = value;
}

export function updatePropertyByPath(obj, path, value) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  if (isObject(value) && Object.keys(value)[0] !== '') {
    newobj[last_element] = [value];
  } else {
    newobj[last_element] = value;
  }
}

export function updatePropertyKeyByPath(obj, path, key, value) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => {
    return { [newProp]: old, ...others };
  };
  const newobj = reduceObj(obj, path);
  newobj[last_element] = renameProp(key, value, newobj[last_element]);
}

export function updateMapKeyByPath(obj, path, key, value) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  const mapObj = newobj[last_element];
  const output = {};
  Object.keys(mapObj).forEach((k) => {
    let newkey;
    if (k === key) {
      newkey = value;
    } else {
      newkey = k;
    }
    output[newkey] = mapObj[k];
  });
  newobj[last_element] = output;
}

export function updateMapValByPath(obj, path, value) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  if (isObject(value)) {
    newobj[last_element] = [value];
  } else {
    newobj[last_element] = value;
  }
}

export function appendProperty(obj, path, value) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  if (last_element === 'map' || last_element === 'args' || last_element === 'upstreams' || last_element === 'filters') {
    newobj[last_element] = value;
  } else if (newobj[last_element] === undefined) {
    if (isObject(value)) {
      newobj[last_element] = [value];
    } else {
      newobj[last_element] = value;
    }
  } else {
    newobj[last_element].push(value);
  }
}

export function appendSubProperty(obj, path, value) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  if (newobj[last_element] === undefined) {
    if (isObject(value)) {
      newobj[last_element] = [value];
    } else {
      newobj[last_element] = value;
    }
  } else {
    newobj[last_element].push(value);
  }
}

export function appendSubMapProperty(obj, path) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  const append = { '': '' };
  newobj[last_element] = { ...newobj[last_element], ...append };
}

export function appendRuleBlock(obj, path, option, type) {
  const pathArr = path.split('.');
  const last_element = pathArr[pathArr.length - 1];
  const newobj = reduceObj(obj, path);
  if (newobj[last_element] === undefined) {
    newobj[last_element] = [{}];
  } else {
    newobj[last_element].push({});
  }
  const firstPropertyOptions = getOptions(`${path}`, option, type);
  const firstProperty = Object.keys(firstPropertyOptions)[0];
  const blockIdx = newobj[last_element].length - 1;
  const firstPropertyPath = `${path}.${blockIdx}.${firstProperty}`;
  appendProperty(obj, `${path}.${blockIdx}.${firstProperty}`, getInitialOptions(firstPropertyPath, option, type));
}

export function sortTopBlocks(blocksArray, wrapper) {
  const blocks = {};
  blocks.nonRuleBlocks = [];
  blocks.ruleBlocks = [];
  let j = 0;
  let arr = [];
  if (wrapper !== '') {
    arr = blocksArray[wrapper];
  } else {
    arr = blocksArray;
  }
  Object.keys(arr).forEach((key) => {
    if (key === 'rules' || key === 'miss-rules') {
      blocks.ruleBlocks = { [key]: arr[key] };
    } else {
      blocks.nonRuleBlocks[j] = { [key]: arr[key] };
      j++;
    }
  });
  return blocks;
}

export function sortRuleBlocks(blocksArray) {
  const rulesList = ['match', 'matchExcept', 'actions'];
  const blocks = {};
  blocks.ruleBlockValues = [];
  blocks.ruleBlocks = [];
  let i = 0;
  let j = 0;
  Object.keys(blocksArray).forEach((key) => {
    if (rulesList.includes(key) === true) {
      blocks.ruleBlocks[i] = { [key]: blocksArray[key] };
      i++;
    } else {
      blocks.ruleBlockValues[j] = { [key]: blocksArray[key] };
      j++;
    }
  });
  return blocks;
}

export function getAvailableNonBlockRulesOptions(ruleBlock, propLevels, schema, type) {
  const rulesList = ['rules', 'miss-rules', 'match', 'matchExcept', 'actions'];
  const opts = getOptions(propLevels, schema, type);
  const optsArr = Object.keys(opts);
  const optsNonMatch = optsArr.filter((x) => !rulesList.includes(x));
  const optsArrCurrent = [];
  Object.keys(ruleBlock).forEach((i) => {
    [optsArrCurrent[i]] = Object.keys(ruleBlock[i]);
  });
  return optsNonMatch.filter((x) => !optsArrCurrent.includes(x));
}

export function getAvailableRuleBlocksOptions(value, blockName, blockRuleIdx, propLevels, option, type) {
  const rulesList = ['rules', 'miss-rules', 'match', 'matchExcept', 'actions'];
  const opts = getOptions(propLevels, option, type);
  const optsArr = Object.keys(opts);
  let optsArrCurrent = [];
  if (rulesList.includes(blockName) === true) {
    // match block
    optsArrCurrent = Object.keys(value[blockName][blockRuleIdx]);
  } else {
    // non-match block
    optsArrCurrent = Object.keys(value);
  }
  return optsArr.filter((x) => !optsArrCurrent.includes(x));
}

export function updateOrder(obj, path, option, type) {
  // re-order based on options
  const rulesOptions = getOptions(path, option, type);
  const newParentArr = {};
  Object.keys(rulesOptions).forEach((key) => {
    const newkey = `${path}.${key}`;
    if (isSet(obj, newkey) !== false) {
      newParentArr[key] = get(obj, newkey);
    }
  });
  updateRuleBlockByPath(obj, path, newParentArr);
  return obj;
}

export function undoable(reducer) {
  // Call the reducer with empty action to populate the initial state
  const initialState = {
    past: [],
    present: reducer(undefined, {}),
    future: [],
  };

  // Return a reducer that handles undo and redo
  return function helperReducer(state = initialState, action) {
    const { past, present, future } = deepClone(state);
    const previous = past[past.length - 1];
    const newPast = past.slice(0, past.length - 1);
    const next = future[0];
    const newFuture = future.slice(1);
    const newPresent = reducer(present, action);
    switch (action.type) {
      case 'TOOLS_UNDO':
        return {
          past: newPast,
          present: previous,
          future: [present, ...future],
        };
      case 'TOOLS_REDO':
        return {
          past: [...past, present],
          present: next,
          future: newFuture,
        };
      default:
        // Delegate handling the action to the passed reducer
        if (present === newPresent) {
          return state;
        }
        return {
          past: [...past, present],
          present: newPresent,
          future: [],
        };
    }
  };
}

export function stringToTemplatedString(obj) {
  // check for templatedStringProperties as string, convert to templated-string
  const newObj = obj;
  let rules;
  if (getConfigType(newObj) === 'redirects' && newObj?.redirects?.rules) {
    rules = newObj.redirects.rules;
  } else if (newObj?.rules) {
    rules = newObj.rules;
  } else if (newObj['miss-rules']) {
    rules = newObj['miss-rules'];
  } else {
    return;
  }

  if (isArray(rules[0]?.actions)) {
    rules[0].actions.forEach((item, i) => {
      Object.entries(item)
        .filter(([property, values]) => templatedStringProperties.includes(property) && (isString(values) || isInteger(values)))
        .forEach(([property, values]) => {
          rules[0].actions[i][property] = [{ type: SchemaTypes.STRING, value: values }];
        });
    });
  }
}

export const optimizeTemplatedStrings = (obj) => {
  // check for templated string objects and convert to a string if string type
  const newObj = deepClone(obj);
  let rules;
  if (getConfigType(newObj) === 'redirects' && newObj?.redirects?.rules) {
    rules = newObj.redirects.rules;
  } else if (newObj?.rules) {
    rules = newObj.rules;
  } else if (newObj['miss-rules']) {
    rules = newObj['miss-rules'];
  } else {
    return newObj;
  }

  if (isArray(rules[0]?.actions)) {
    rules[0].actions.forEach((item, i) => {
      Object.entries(item)
        .filter(([property, values]) => templatedStringProperties.includes(property)
          && isArray(values)
          && values.length === 1
          && values[0]?.type === 'string'
          && values[0]?.value)
        .forEach(([property, values]) => {
          rules[0].actions[i][property] = values[0].value;
        });
    });
  }

  return newObj;
};

export function cleanJson(json) {
  // remove comments
  const clean = json.replace(/([",{}[\]\s])(\/\/.*)$/gm, '$1');
  // remove blank lines
  clean.replace(/^\s*\n/gm, '');
  // remove dangling commas
  return clean.replace(/(,)([\s\r\n]*[}\]]+)/gm, '$2');
}
