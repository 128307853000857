import React, {
  useState,
} from 'react';

import ReloadIcon from '@material-ui/icons/Refresh';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Typography, IconButton, AccordionDetails, AccordionSummary, Accordion,
} from '@material-ui/core';

import { LazyLog } from 'react-lazylog';
import { fromUnixTime, format } from 'date-fns';
import Loading from 'shared/styleguide/atoms/Loading';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import { ErrorText } from 'shared/styleguide/typography';
import { useGetCloneLogsByAppIdQuery } from 'shared/utils/redux/query';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import { formatCloneLogs } from '../utils';

const Content = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const formattedLogs = formatCloneLogs(data);
  let latestDate = data.at(-1).message.timestamp;
  latestDate = format(fromUnixTime(latestDate), 'MMM, dd yyyy, hh:mm:ss');

  return (
    <Accordion
      data-testid="logitem-clone"
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography data-testid="log-section-heading" variant="h3"> Last Clone - {latestDate}</Typography>
      </AccordionSummary>
      <AccordionDetails data-testid="logdetails-clone">
        <Box flex={1} data-testid="logdata-clone">
          <LazyLog
            extraLines={1}
            enableSearch
            text={formattedLogs}
            height={400}
            caseInsensitive
            selectableLines
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

// TODO add ticket for Pagination
export const CloneLogsSection = ({ appId }: { appId: string }): JSX.Element => {
  const {
    data, error, isLoading, refetch,
  } = useGetCloneLogsByAppIdQuery(appId);
  let content;
  if (data?.data.length === 0) {
    content = (
      <Empty>
        There are no App clone logs to display. Logs are retained for 1 month.
      </Empty>
    );
  } else if (error) {
    let errorText = 'Error occurred while attempting to load app clone logs';
    if ('status' in error) {
      errorText = `${error.status} ${JSON.stringify(error.data)}`;
    }
    content = <ErrorText>{errorText}</ErrorText>;
  } else if (isLoading) {
    content = <Loading />;
  } else {
    content = <Content data={data.data} />;
  }
  return (
    <Box margin={{ bottom: 'medium' }}>
      <Box direction="row" justify="space-between" align="center">
        <Typography data-testid="app-clone-log-section-heading" variant="h3">App Clone Logs</Typography>
        <Box direction="row">
          {
            isLoading
              ? (
                <Box css={{ padding: 16, marginRight: 6 }}>
                  <Loading size="small" margin={{ top: 'none' }} />
                </Box>
              ) : (
                <IconButton
                  onClick={refetch}
                >
                  <ReloadIcon />
                </IconButton>
              )
          }
        </Box>
      </Box>
      <ErrorBoundary>
        {content}
      </ErrorBoundary>
    </Box>
  );
};

export default CloneLogsSection;
