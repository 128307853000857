import tracker from 'shared/3rdparty/pageTracking';
import makeApiAction from 'shared/utils/redux/apiActions';

import * as ACCOUNT from 'shared/modules/account/redux/constants';
import * as TEAM from './constants';

export const addSubscriptionToCollaborator = ({
  firstName,
  lastName,
  email,
  password,
  companyName,
  billingPlanId,
  accountId,
  region,
  billingToken,
  billingSource,
  addons,
  formId = null,
  tos,
  addressInfo,
  captchaToken,
}) => makeApiAction(
  ({ api }) => api().post(`/accounts/${accountId}/subscription`, {
    acceptTos: tos,
    addons,
    billingPlanId: `${billingPlanId}`,
    billingSource,
    billingToken,
    companyName,
    email,
    firstName,
    lastName,
    password,
    region,
    captchaToken,
    ...addressInfo,
  }),
  ACCOUNT.ACCOUNT_CREATE_REQUEST,
  ACCOUNT.ACCOUNT_CREATE_SUCCESS,
  ACCOUNT.ACCOUNT_CREATE_FAILURE,
  {
    reduxFormId: formId,
  },
  true,
);

export const fetchAccountCollaborators = (accountId) => makeApiAction(
  ({ api }) => api().get(`/accounts/${accountId}/collaborators?access=to`),
  TEAM.ACCOUNT_COLLABORATORS_GET_REQUEST,
  TEAM.ACCOUNT_COLLABORATORS_GET_SUCCESS,
  TEAM.ACCOUNT_COLLABORATORS_GET_FAILURE,
  { accountId },
);

export const addCollaborator = (accountId, name, email, role, allowPasswordLogin, appId = 0) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_add_collaborator',
      name,
      email,
      allowPasswordLogin,
      role,
    });

    return api().post(`/accounts/${accountId}/collaborators`, {
      name,
      email,
      role,
      allowPasswordLogin,
      appId,
    });
  },
  TEAM.ACCOUNT_COLLABORATOR_CREATE_REQUEST,
  TEAM.ACCOUNT_COLLABORATOR_CREATE_SUCCESS,
  TEAM.ACCOUNT_COLLABORATOR_CREATE_FAILURE,
  {},
  true,
);

export const updateCollaboratorPermissions = (targetId, sourceId, role, allowPasswordLogin, appId = 0) => makeApiAction(
  ({ api }) => {
    return api().patch(`/accounts/${targetId}/collaborators/${sourceId}`, {
      role,
      appId,
      allowPasswordLogin,
    });
  },
  TEAM.ACCOUNT_COLLABORATOR_UPDATE_REQUEST,
  TEAM.ACCOUNT_COLLABORATOR_UPDATE_SUCCESS,
  TEAM.ACCOUNT_COLLABORATOR_UPDATE_FAILURE,
  {
    accountId: targetId,
    sourceId,
  },
);

export const removeCollaborator = (accountId, sourceId, role, appId = 0) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_remove_collaborator',
      accountId,
      removed_user: sourceId,
      role,
    });
    return api().delete(`/accounts/${accountId}/collaborators/${sourceId}/${role}/${appId}`, {
    });
  },
  TEAM.ACCOUNT_COLLABORATOR_DELETE_REQUEST,
  TEAM.ACCOUNT_COLLABORATOR_DELETE_SUCCESS,
  TEAM.ACCOUNT_COLLABORATOR_DELETE_FAILURE,
  {
    sourceId,
    appId,
  },
  true,
);

export const resetCollaborators = () => ({
  type: TEAM.ACCOUNT_COLLABORATORS_RESET,
});

export const resetCollaborator = () => ({
  type: TEAM.ACCOUNT_COLLABORATOR_RESET,
});
