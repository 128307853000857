import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import Tooltip from '@material-ui/core/Tooltip';

import Box from 'shared/styleguide/atoms/Box';

const accountShortcuts = [
  {
    name: 'account',
    idVar: 'accountId',
    Icon: DashboardIcon,
  },
  {
    name: 'alerts',
    idVar: '',
    Icon: NotificationImportant,
  },
  {
    name: 'support',
    idVar: 'ticketId',
    Icon: ContactSupportIcon,
  },
];

const constructShortcutUrl = (accountId, shortcut, subIds) => {
  const baseLink = `/account/${accountId}`;
  let link = baseLink;

  if (shortcut.name !== 'account' && subIds[shortcut.idVar]) {
    link = `${baseLink}/${shortcut.name}/${subIds[shortcut.idVar]}`;
  } else if (shortcut.name !== 'account') {
    link = `${baseLink}/${shortcut.name}`;
  }

  return link;
};

const ShortcutsIcon = ({
  shortcut, accountId, classes, subIds,
}) => {
  const { Icon, disabled, text } = shortcut;

  const link = constructShortcutUrl(accountId, shortcut, subIds);

  if (!link) return null;

  return (
    <Tooltip title={shortcut.name} placement="top">
      <Link to={link}>
        <IconButton
          className={classes.shortcutIcon}
          disabled={disabled}
          title={text}
        >
          <Icon />
        </IconButton>
      </Link>
    </Tooltip>
  );
};

ShortcutsIcon.propTypes = {
  accountId: PropTypes.string,
  classes: PropTypes.object,
  Icon: PropTypes.node,
  shortcut: PropTypes.object,
  subIds: PropTypes.object,
  ticketId: PropTypes.string,
};

const AlertsShortcuts = ({ accountId, ticketId }) => {
  const classes = makeStyles(() => ({
    root: {
      height: 48,
      alignItems: 'center',
    },
    shortcutIcon: {
      marginRight: 8,
      width: 30,
      height: 30,
      padding: 1,
    },
  }))();

  return (
    <Box flex={1} row align="center" classes={classes}>
      {
        accountShortcuts
          .filter((s) => {
            // if the shortcut is "support" but there's no ticketID, don't create a shortcut
            return !(s.name === 'support' && !ticketId);
          })
          .map((shortcut, index) => {
            return (
              <ShortcutsIcon
                key={`${shortcut.name}-${index}`}
                accountId={accountId}
                subIds={{ ticketId }}
                shortcut={shortcut}
                classes={classes}
                index={index}
              />
            );
          })
      }
    </Box>
  );
};

AlertsShortcuts.propTypes = {
  accountId: PropTypes.string,
  ticketId: PropTypes.string,
};

export default AlertsShortcuts;
