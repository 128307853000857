import apiReducer, { baseCollectionState } from 'shared/utils/redux/apiReducer';
import { CONSTANTS as PUSHER } from 'shared/3rdparty/pusher';
import { dateFieldSorter } from 'shared/utils/sorting';
import * as ALERTS from './constants';

export const alertsReducer = apiReducer(
  ALERTS.FETCH_ALERTS_REQUEST,
  ALERTS.FETCH_ALERTS_SUCCESS,
  ALERTS.FETCH_ALERTS_FAILURE,
  ALERTS.FETCH_ALERTS_RESET,
  baseCollectionState,
);

const pusherUpsertAlerts = (state, action) => {
  // update alerts, don't completely overwrite data, then sort
  const newState = structuredClone(state);

  const index = newState.data.findIndex((a) => a.id === action.data.id);

  const alertIndex = index > -1 ? index : newState.data.length;

  newState.data[alertIndex] = {
    ...newState.data[alertIndex],
    ...action.data,
  };

  return {
    ...newState,
    data: [...newState.data.sort(dateFieldSorter('createdAt', 'desc'))],
  };
};

const alertsSuccess = (state, action, reducer) => {
  // get fresh alerts, replacing all data
  return {
    ...state,
    ...reducer(state, action),
    data: [...reducer(state, action).data.sort(dateFieldSorter('createdAt', 'desc'))],
  };
};

const alertsApiCall = (state, action, reducer) => {
  return {
    ...state,
    ...reducer(state, action),
  };
};

const alertsApiReset = (state, action, reducer) => {
  return {
    ...state,
    data: [],
    ...reducer(state, action),
  };
};

export const alerts = (state = { ...baseCollectionState }, action) => {
  switch (action.type) {
    case ALERTS.FETCH_ALERTS_REQUEST:
    case ALERTS.FETCH_ALERTS_FAILURE:
      return alertsApiCall(state, action, alertsReducer);
    case ALERTS.FETCH_ALERTS_SUCCESS:
      return alertsSuccess(state, action, alertsReducer);
    case ALERTS.FETCH_ALERTS_RESET:
      return alertsApiReset(state, action, alertsReducer);
    case PUSHER.ALERT:
      return pusherUpsertAlerts(state, action);
    default:
      return state;
  }
};
