import React, { Fragment, useState, useRef } from 'react';
import { object, func } from 'prop-types';

import {
  Paper, Typography, FormControlLabel, Checkbox, OutlinedInput,
} from '@material-ui/core';

// styleguide
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { useMergeState } from 'shared/hooks/useMergeState';
import CountrySelector from 'shared/styleguide/molecules/Dropdowns/Country';
import StateSelector from 'shared/styleguide/molecules/Dropdowns/States';
import CreditCard from 'shared/styleguide/organisms/CreditCard';
import logger from 'shared/3rdparty/logger';
import { remMapper } from 'shared/styleguide/theme/spacing';

const View = ({ customer, setView }) => {
  const {
    paymentMethod, card,
  } = customer;

  return (
    <Fragment>
      <Box margin={{ top: 'xsmall' }}>
        {
          paymentMethod === 'card' && (
            <Fragment>
              <Typography>Card ending in  ****{card.last4}</Typography>
              <Typography variant="body2" color="textSecondary">Expires {card.expiryMonth}/{card.expiryYear}</Typography>
            </Fragment>
          )
        }
        {
          paymentMethod === 'paypal_express_checkout' && (
            <Fragment>
              <Typography variant="body2" color="textSecondary">Using PayPal for payment</Typography>
            </Fragment>
          )
        }
        {!paymentMethod && (
          <Typography variant="body2" color="textSecondary">No payment method on file.</Typography>
        )}
      </Box>
      <Box direction="row" flex={1} justify="flex-end" margin={{ top: 'xsmall' }}>
        {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Button
          onClick={() => setView('edit')}
          variant="contained"
        >
          Update payment method
        </Button>
      </Box>
    </Fragment>
  );
};
View.propTypes = {
  customer: object,
  setView: func,
};

const Edit = ({
  customer, setView, updatePayment, updateCustomer,
}) => {
  const [status, setStatus] = useState('initial');
  const cardRef = useRef();
  const checkboxRef = useRef();

  const { state, mergeState: setState } = useMergeState({
    address1: '',
    address2: '',
    city: '',
    country: 'US',
    state: '',
    zip: '',
    vat: '',
  });

  const onChange = (field, value) => {
    setState({ [field]: value });
  };

  const onSubmit = async () => {
    setStatus('loading');

    // create chargebee token
    logger.client.leaveBreadcrumb('Requested Chargebee token', {
      firstName: customer.firstName,
      lastName: customer.lastName,
      customerId: customer.id,
    });

    let billingToken;
    try {
      const { token } = await cardRef.current.tokenize({}, {
        firstName: state.firstName,
        lastName: state.lastName,
        billingAddr1: state.address1,
        billingAddr2: state.address2,
        billingCity: state.city,
        billingState: state.state,
        billingZip: state.zip,
        billingCountry: state.country,
        billingVat: state.vat,
      });
      billingToken = token;
      setState({ token: billingToken });
      try {
        // update the account
        await updatePayment(customer.id, billingToken || state.token);

        if (checkboxRef.current.checked) {
          await updateCustomer(customer.id, {
            companyName: customer.companyName,
            firstName: customer.firstName,
            lastName: customer.lastName,
            address1: state.address1,
            address2: state.address2,
            city: state.city,
            country: state.country,
            state: state.state,
            zip: state.zip,
          });
        }

        setStatus('success');

        setTimeout(() => {
          setStatus('initial');
          setView('view');
        }, 3000);
      } catch (err) {
        logger.error(err, { context: 'Error updating account payment' });
      }
    } catch (err) {
      setState({ errors: { token: err.message } });
      if (err.name !== 'invalid_request') {
        logger.error(err, { context: 'Chargebee token error on updating payment' });
      }
    }
  };

  return (
    <Box direction="column" gap="small">
      <Box direction="row" gap="small">
        <OutlinedInput
          fullWidth
          id="firstName"
          value={state.firstName}
          onChange={(e) => onChange('firstName', e.target.value)}
          placeholder="First Name"
        />
        <OutlinedInput
          fullWidth
          id="lastName"
          value={state.lastName}
          onChange={(e) => onChange('lastName', e.target.value)}
          placeholder="Last Name"
        />
      </Box>
      <Box direction="column" gap="small" flex={1}>
        <OutlinedInput
          fullWidth
          id="address1"
          value={state.address1}
          onChange={(e) => onChange('address1', e.target.value)}
          placeholder="Address"
        />
        <OutlinedInput
          fullWidth
          id="address2"
          value={state.address2}
          onChange={(e) => onChange('address2', e.target.value)}
          placeholder="Address (Extra)"
        />
        <OutlinedInput
          fullWidth
          id="city"
          value={state.city}
          onChange={(e) => onChange('city', e.target.value)}
          placeholder="City"
        />
        <Box direction="row" gap="small" flex={1}>
          <Box flex={1}>
            {
              state.country === 'US' ? (
                <StateSelector
                  onChange={((value) => onChange('state', value.value))}
                  value={state.state}
                  hasError={Boolean(state.errors?.state)}
                />
              ) : (
                <OutlinedInput
                  fullWidth
                  id="state"
                  value={state.state}
                  onChange={(e) => onChange('state', e.target.value)}
                  placeholder="State"
                />
              )
            }
          </Box>
          <Box flex={1}>
            <OutlinedInput
              fullWidth
              id="zip"
              value={state.zip}
              onChange={(e) => onChange('zip', e.target.value)}
              placeholder="Zip/Postal Code"
            />
          </Box>
        </Box>
        <Box direction="row" flex={1}>
          <Box flex={1}>
            <CountrySelector
              onChange={((value) => onChange('country', value.value))}
              value={state.country}
              hasError={Boolean(state.errors?.country)}
            />
          </Box>
          <Box />
        </Box>
        <Box>
          <CreditCard
            ref={cardRef}
          />
          {
            state.token && (
              <Typography variant="caption" color="textSecondary">Card information has been saved.</Typography>
            )
          }
          {
            state.errors?.token && (
              <Typography color="error">
                {state.errors?.token}
              </Typography>
            )
          }
        </Box>
      </Box>
      <Box direction="row" flex={1} justify="space-between" gap="medium">
        <Box>
          <FormControlLabel
            inputRef={checkboxRef}
            control={(<Checkbox />)}
            label="Update billing address with this address"
          />
        </Box>
      </Box>
      <Box direction="row" align="center" justify="space-between">
        <div>
          <Button
            status={status}
            color="default"
            variant="outlined"
            onClick={() => setView('view')}
            css={{ whiteSpace: 'nowrap' }}
          >
            Discard Changes
          </Button>
        </div>
        <div>
          <Button
            status={status}
            variant="contained"
            onClick={onSubmit}
            css={{ whiteSpace: 'nowrap' }}
          >
            Save Changes
          </Button>
        </div>
      </Box>
    </Box>
  );
};

Edit.propTypes = {
  customer: object,
  setView: func,
  updateCustomer: func,
  updatePayment: func,
};

export const PaymentCard = ({
  customer, updatePayment, updateCustomer,
}) => {
  const [view, setView] = useState('view');

  return (
    <Paper>
      <Box padding="medium">
        <Typography
          variant="h3"
          css={{
            marginBottom: remMapper('medium'),
          }}
        >Payment Information
        </Typography>
        {
          view === 'view' && (
            <View customer={customer.data} setView={setView} />
          )
        }
        {
          view === 'edit' && (
            <Edit customer={customer.data} setView={setView} updatePayment={updatePayment} updateCustomer={updateCustomer} />
          )
        }
      </Box>
    </Paper>
  );
};

PaymentCard.propTypes = {
  customer: object,
  updateCustomer: func,
  updatePayment: func,
};

export default (PaymentCard);
