import React, { Component, Fragment } from 'react';
import {
  func, object, shape, any, bool, string,
} from 'prop-types';
import { connect, batch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';

import {
  fetchApp,
  resetApp,
  deleteApp,
  disableApp,
  enableApp,
  getSqlInfo,
  fetchTagsForApp,
  updateAppConfig,
  resetAppOptions,
} from 'shared/modules/app/redux/actions';

import {
  fetchDomainsForApp,
} from 'shared/modules/app/redux/domains/actions';

import {
  resetBackup,
  resetBackups,
} from 'shared/modules/app/redux/backups/actions';

import { getLinkedCerts } from 'shared/modules/ssl/redux/certDomainLink/actions';
import { fetchServerTagsForAccount } from 'shared/modules/server/redux/actions';
import { fetchAppMetaTags } from 'shared/modules/metadata/redux/actions';

import { ErrorText, Display1Text } from 'shared/styleguide/typography';
import { consolidateErrors } from 'shared/utils/validation';

import { resetAppStats } from 'shared/modules/analytics/redux/actions';
import { getDNSfromAppId, resetDNSRecords } from 'shared/modules/dns/redux/actions';
import { AppPropTypes } from 'shared/modules/apps/models';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import { isObject } from 'shared/utils/types';
import CollabRedirect from 'shared/styleguide/organisms/CollabRedirect';
import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';

import LoadingCard from 'shared/styleguide/molecules/LoadingCard';
import Banner from 'shared/styleguide/molecules/Banner';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import AppHeader from './routes/Overview/AppHeader';
import Overview from './routes/Overview';

import { children /* as routes */, redirects } from './routes/children';
import { FeatureToggle } from '../../flags';

export class AppRoutes extends Component {
  static propTypes = {
    account: object.isRequired,
    app: shape(AppPropTypes).isRequired,
    appDomains: object,
    appMeta: object,
    fetchAndLoadApp: func,
    fetchApp: func,
    fetchDomainsForApp: func,
    fetchServerTags: func,
    fetchTagsForApp: func,
    getSqlInfo: func,
    history: object.isRequired,
    isAdmin: bool,
    loadApp: func,
    match: shape({
      params: shape({
        accountID: string,
        appId: string,
      }),
      path: string,
      url: string,
    }),
    permissions: object.isRequired,
    promoteDomainToPrimary: func,
    removeAppDomain: func,
    resetAnalytics: any,
    resetAppStore: func,
    resetBackup: func,
    resetBackups: func,
    set301: func,
    setActive: func,
    user: any,
  };

  componentDidMount() {
    const {
      isAdmin,
    } = this.props;

    const { appId } = this.props.match.params;

    this.fetchAndLoadApp(appId, isAdmin);
  }

  componentDidUpdate(oldProps) { // TODO: make this a promise
    const { app, match, isAdmin } = this.props;
    const { appId } = this.props.match.params;

    if (oldProps.app?.data?.id !== Number(appId) && app.status === 'success' && app.params.appId !== appId) {
      this.fetchAndLoadApp(appId, isAdmin);
    }
  }

  componentWillUnmount() {
    this.props.resetAppStore();
  }

  getPrimaryDomain = () => this.props.appDomains.data.find((d) => d.primary) || null;

  fetchAndLoadApp = (appId, isAdmin) => {
    this.props.fetchApp(appId, { includeFsa: 1 }).then(() => {
      if (isAdmin) {
        this.props.getSqlInfo({ appId });
      }
    });

    this.props.loadApp();
  };

  render() {
    const {
      app, account, match, isAdmin, appMeta,
    } = this.props;

    if (
      app.status === 'loading'
      || appMeta.status === 'loading'
      || app.status === 'pristine'
      || appMeta.status === 'pristine'
    ) {
      return (
        <LoadingCard />
      );
    }

    if (!account.hasSubscription && !isAdmin) {
      return (
        <div>
          <Box align="center" margin={{ bottom: 'medium' }}>
            <Display1Text>
              Apps
            </Display1Text>
          </Box>
          <CollabRedirect text="create apps" account={account} />
        </div>
      );
    }

    if (app.statusCode === 404) {
      return (
        <Empty>
          {
            app.response?.data?.message
              ? <ErrorText>Error: {consolidateErrors(app)}</ErrorText>
              : 'App not found'
          }
        </Empty>
      );
    }

    const isOnP20Account = account.defPoolId === 20 || account.defPoolId === 0;

    const hasCustomConfig = appMeta.data.find((key) => key.keyId === 'system:ares.custom-configs') || false;

    const roleChildren = [...children];

    const appChildRoutes = [...roleChildren];

    if (hasCustomConfig?.value) {
      const { value } = hasCustomConfig;
      const config = isObject(value) ? JSON.stringify(hasCustomConfig.value) : value;
      if (config.includes('atomic redirects')) {
        // has simple redirect config that was not migrated to Ares UI
        // so show the legacy Redirects tab
        appChildRoutes.push(redirects);
      }
    }

    const routes = FeatureToggle({
      flags: [!!hasCustomConfig], // TODO: make this depend on meta tags
      children: appChildRoutes,
      fallback: roleChildren,
    });

    const previewMode = this.getPrimaryDomain()?.fqdn?.includes('pressdns') || false;

    return (
      <Fragment>
        <ErrorBoundary>
          <AppHeader
            {...this.props}
            app={app.data}
            isAdmin={this.props.isAdmin}
            getPrimaryDomain={this.getPrimaryDomain}
            isOnP20Account={isOnP20Account}
          />
        </ErrorBoundary>
        <Box margin={{ top: 'large' }}>
          <Route
            path={`${match.path}/:view`}
            render={(props) => (<Navigation {...props} routes={routes} parentPath={match.url} />)}
          />
        </Box>
        {
          previewMode && (
            <Banner
              heading="App is in preview mode"
              margin={{ bottom: 'mediumLarge' }}
              subheading={(
                this.props.isAdmin ? null
                  : (
                    <span>
                      Any load tests performed while on our <em>pressdns</em> domain will produce invalid results.
                      Your site will need to be on the <em>production domain</em> or a <em>temporary subdomain</em> in order to be cached and load-tested.
                      You can promote another domain to primary to go live.  <TextLink color="inherit" hoverColor="inherit" target="_blank" rel="noopener noreferrer" href="https://support.pagely.com/hc/en-us/articles/360034968292">Learn more about preview mode.</TextLink>
                    </span>
                  )
              )}
              type="info"
              dismissable={this.props.isAdmin}
              rounded="all"
            />
          )
        }
        <div data-classid="app-routes">
          <ErrorBoundary>
            <Switch>
              {
                routes.concat().reverse().map((child) => {
                  return (
                    <Route key={child.to} path={`${match.path}/${child.path || child.to}`} component={child.component} />
                  );
                })
              }
              <Route
                path={`${match.path}/:view*`}
                render={(props) => (<Overview {...props} parentPath={match.url} />)}
              />
            </Switch>
          </ErrorBoundary>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  account, app, ...state
}) => ({
  account,
  app: app.app,
  appDomains: app.appDomains,
  appMeta: app.metadata,
  linkedCerts: state.linkedCerts,
  dns: state.dns.dnsRecords,
  servertags: state.tags.server,
  tags: state.tags,
  user: state.user,
  permissions: state.permissions,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const resetAppStore = () => batch(() => {
    dispatch(resetApp());
    dispatch(resetBackups());
    dispatch(resetBackup());
    dispatch(resetAppStats());
  });

  const { appId } = ownProps.match.params;
  const accountId = ownProps.match.params.accountID;

  const loadApp = () => batch(() => {
    dispatch(fetchServerTagsForAccount(accountId));
    dispatch(fetchDomainsForApp(appId));
    dispatch(getLinkedCerts([appId], accountId));
    dispatch(getDNSfromAppId({ accountId, appId }));
    dispatch(fetchTagsForApp(appId));
    dispatch(fetchAppMetaTags(appId));
  });

  return {
    fetchApp: (...args) => dispatch(fetchApp(...args)),
    resetDNSRecords: (...args) => dispatch(resetDNSRecords(...args)),
    deleteApp: (...args) => dispatch(deleteApp(...args)),
    disableApp: (...args) => dispatch(disableApp(...args)),
    enableApp: (...args) => dispatch(enableApp(...args)),
    getSqlInfo: (...args) => dispatch(getSqlInfo(...args)),
    fetchTagsForApp: (...args) => dispatch(fetchTagsForApp(...args)),
    updateAppConfig: (...args) => dispatch(updateAppConfig(...args, { appId, accountId })),
    resetAppOptions: (...args) => dispatch(resetAppOptions(...args)),
    resetAppStore,
    loadApp,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
