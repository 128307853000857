import React, { useState } from 'react';
import { FixedSizeList as List } from 'react-window';

import { Divider, Typography } from '@material-ui/core';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import { ColorText } from 'shared/styleguide/typography';
import colors from 'shared/styleguide/theme';
import SearchBar from 'shared/styleguide/molecules/SearchBar';

import { useGetServerListQuery, useReloadServerPoolMutation } from 'admin/query';
import Row from './ServerListItem';

export const ServerList = () => { // eslint-disable-line

  const [filter, setFilter] = useState('');

  const { data: serverList, isUninitialized, isSuccess } = useGetServerListQuery();
  const [reloadPool, reloadPoolResult] = useReloadServerPoolMutation();

  const handlePoolReload = async (poolId) => {
    await reloadPool(poolId);
  };
  if (isUninitialized) {
    return null;
  }

  const filteredList = isSuccess ? serverList.filter((
    (r) => r.petname.toLowerCase().match(filter) || r.id.toString().match(filter)
  )) : [];

  return (
    <Box as={Paper} padding="medium" margin={{ bottom: 'small' }}>
      <Box direction="row" align="center" justify="space-between" margin={{ bottom: 'small' }}>
        <Typography variant="h3">Server List</Typography>
        <SearchBar
          text="Search Servers"
          onChange={(filter) => setFilter(filter)}
        />
      </Box>
      <div>
        {
          reloadPoolResult.isSuccess && (<ColorText color={colors.green30}>Pool {reloadPoolResult.originalArgs} Reloaded Successfully</ColorText>)
        }
        {
          reloadPoolResult.isError && (<ColorText color={colors.red10}>{reloadPoolResult.error.message}</ColorText>)
        }
      </div>
      <Row
        style={{ fontWeight: 'bold', width: '100%', minWidth: 900 }}
        index={0}
        data={{
          serverList: [{
            id: 'ID', petname: 'Name', group: 'Group', role: 'Role', roleId: 'Role Id', ipExternal: 'External IP', ipInternal: 'Internal IP', poolId: 'Pool Id',
          }],
        }}
        heading
      />
      <Divider />
      <List
        key={filteredList.length}
        height={400}
        itemCount={filteredList.length}
        itemData={{
          serverList: filteredList, onReloadPool: handlePoolReload, status: reloadPoolResult, poolId: reloadPoolResult.originalArgs,
        }}
        itemSize={60}
        itemKey={(index, { serverList }) => serverList[index].id}
        width="100%"
        overscanCount={3}
        style={{ minWidth: 900 }}
      >
        {Row}
      </List>
    </Box>
  );
};

export default ServerList;
