import React from 'react';

import {
  Typography,
  Table,
  TableBody,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import ValidationItemRuleHeader from '../ValidationItemRuleHeader';
import OperatorColumn from './OperatorColumn';

interface Props {
  rule: Record<string, any>;
  showOperatorColumn: boolean;
  children: any;
}

const niceLabels = {
  'default': 'Domain Rules',
  'P20 Validation Rules': ' Domain Rules',
  'HA Validation Rules': 'HA Domain Rules',
  'PressDNS Nameservers': 'PressDNS Nameserver Rules',
  'CloudFront FSA': 'Mercury Domain Rules',
  'ACM Validation Rules': 'SSL Validation Rules',
};

const getCurrentNS = (rule) => {
  if (rule.label === 'PressDNS Nameservers') {
    return rule.records?.[0]?.current || 'No nameserver values set.';
  }
  return undefined;
};

const OuterWrapper = ({ rule, showOperatorColumn, children }: Props) => {
  const currentNS = getCurrentNS(rule);

  return (
    <Box direction="row" align="stretch" gap="small" margin={{ bottom: 'small' }}>
      {
        showOperatorColumn && <OperatorColumn operator={rule.operator} />
      }
      <Box direction="column" flex={1}>
        <Box direction="column" margin={{ bottom: 'xsmall', left: 'xsmall' }}>
          <Typography variant="body2" component="span">
            <strong>{niceLabels[rule.label || 'default']}</strong>
          </Typography>
        </Box>
        <Box flex={1} css={(theme) => ({ border: `1px solid ${theme.color500}` })}>
          <Table>
            <ValidationItemRuleHeader label={rule.label} />
            <TableBody>
              {children}
            </TableBody>
          </Table>
        </Box>
        {
          currentNS
          && (
            <Box direction="row" gap="xsmall" margin={{ top: 'small' }} justify="flex-end">
              <Typography color="textSecondary" variant="body2" component="span">Current Nameservers:</Typography>
              <Typography color="textPrimary" variant="body2" component="span">{currentNS}</Typography>
            </Box>
          )
        }
      </Box>
    </Box>
  );
};

export default OuterWrapper;
