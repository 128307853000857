import React from 'react';
import { css } from '@emotion/react';

import { Typography, TypographyTypeMap } from '@material-ui/core';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { Table, Row, Item } from './Table';

interface Props {
  data: any[][];
  className?: string;
  styles?: Partial<Record<'table' | 'otherRow' | 'rightCol' | 'leftCol', any>>;
  includeCopy?: boolean;
  noMargin?: boolean;
  variant?: TypographyTypeMap['props']['variant'];
}

const TwoColumn = ({
  data, variant = 'body1', className, styles, includeCopy, noMargin,
}: Props): React.ReactElement => {
  return (
    <div
      className={className}
      css={[
        css`
          margin-top: ${remMapper(noMargin ? 'none' : 'small')};
        `,
        styles?.table,
      ]}
    >
      <Table>
        {
          data.map((d, i: number) => {
            const noBorder = i === (data.length - 1);
            return (
              <Row
                key={`table-row-${i}`}
                noBorder={noBorder}
                css={[
                  styles?.otherRow,
                  {
                    padding: '7px 0',
                    ...(!noBorder && {
                      borderBottomStyle: 'dashed',
                    }),
                  },
                ]}
                justify="flex-start"
              >
                <Item
                  css={[
                    css`
                    min-width: 90px;
                    flex-basis: 150px;
                    text-align: left;
                    margin-right: ${remMapper('medium')};
                  `,
                    styles?.rightCol,
                  ]}
                >
                  {
                    (typeof d[0] === 'string' || d[0] instanceof String)
                      ? (
                        <Typography
                          color="textPrimary"
                          variant={variant}
                          css={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                          }}
                        >
                          {d[0]}
                        </Typography>
                      ) : (
                        d[0]
                      )
                  }
                </Item>
                <Item
                  css={[
                    css`
                    text-align: left;
                    flex: 1;
                  `,
                    styles?.leftCol,
                  ]}
                >
                  {
                    (typeof d[1] === 'string' || d[1] instanceof String)
                      ? (
                        <Typography color="textSecondary">
                          {d[1]}
                        </Typography>
                      ) : (
                        d[1]
                      )
                  }
                </Item>
                {
                  includeCopy && (
                    <CopyButton
                      message={`${d[0]} copied`}
                      text={`${d[1]}`}
                      size="small"
                      color="default"
                    />
                  )
                }
              </Row>
            );
          })
        }
      </Table>
    </div>
  );
};

export default TwoColumn;
