import React from 'react';
import Box from 'shared/styleguide/atoms/Box';
import MFA from './MFA';
import SsoSettings from './SsoSettings';

/**
 * This is a carrier component that contains all the different cards on the page.
 * It has no real substance aside from rendering the page.
 */
const Settings: React.FC = () => (
  <Box gap="large" direction="column">
    <MFA />
    <SsoSettings />
  </Box>
);

export default Settings;
