import React, { Fragment } from 'react';
import { string, oneOfType, node } from 'prop-types';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/react';
import { remMapper } from 'shared/styleguide/theme/spacing';

const titleStyle = css`
  display: inline-block;
  margin-bottom: ${remMapper('mediumLarge')};
`;

const Layout = ({ children, title }) => {
  let Title = '';

  if (typeof title === 'string') {
    Title = (
      <Typography variant="h1" color="textPrimary" css={{ lineHeight: 0.85 }}>{title}</Typography>
    );
  } else {
    Title = title;
  }

  return (
    <Fragment>
      <span css={titleStyle}>{Title}</span>
      <div>{children}</div>
    </Fragment>
  );
};

Layout.propTypes = {
  title: oneOfType([string, node]).isRequired,
};

export default Layout;
