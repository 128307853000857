import React, { Component } from 'react';
import { connect } from 'react-redux';
import TrashIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

import { func, object } from 'prop-types';
import colors from 'shared/styleguide/theme';
import ConfirmableButton from 'shared/styleguide/atoms/Buttons/ConfirmableButton';
import {
  getAppGatewayConfig,
  deleteAppGatewayConfig,
  setAppGatewayConfig,
} from 'shared/modules/server/redux/actions';
import SupportLink from 'shared/modules/support/Link';

import exportData from 'shared/utils/exportData';
import logger from 'shared/3rdparty/logger';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import Row from 'shared/styleguide/atoms/Row';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import AddRedirect from './AddRedirect';
import ListRedirects from './ListRedirects';

// this will get configs (display)
// and make new configs
// and save configs
// and apply configs

export class RedirectManager extends Component {
  static propTypes = {
    app: object.isRequired,
    deleteAppGatewayConfig: func,
    getAppGatewayConfig: func,
    setAppGatewayConfig: func,
  };

  constructor(props) {
    super(props);

    this.state = {
      redirects: {},
      status: 'ready',
    };
    this.initialRedirects = [];
    const appId = props.app.id;

    props.getAppGatewayConfig(appId, 'vhosts', {
      file: 'atomic_redirect.json',
      // mode: '????'
    })
      .then((resp) => {
        const { config } = resp;
        if (config && config.redirects.rules[0]) {
          this.initialRedirects = Object.keys(config.redirects.rules[0].actions[0].map);

          this.setState({ redirects: config.redirects.rules[0].actions[0].map });
        }
      })
      .catch(() => {
        // fail silently.
      });
  }

  save = () => {
    const configuration = {
      redirects: {
        exitCode: 301,
        rules: [
          // add rules by type here >_>
          {
            name: 'Path To Path redirect',
            match: [{ field: 'all' }],
            actions: [{
              action: 'simple-redirect-map',
              map: this.state.redirects,
            }],
          },
        ],
      },
    };

    this.setState({ status: 'loading' });

    const appId = this.props.app.id;

    // if there are no configs at all - delete entire config
    if (Object.keys(this.state.redirects).length === 0) {
      this.props.deleteAppGatewayConfig(appId, 'vhosts', { file: 'atomic_redirect.json' })
        .then(() => {
          this.initialRedirects = Object.keys(this.state.redirects);
          this.setState({ status: 'success' }, () => {
            setTimeout(() => {
              this.setState({ status: 'ready' });
            }, 3000);
          });
        }).catch((err) => {
          logger.error(err);
        });
    } else {
      this.props.setAppGatewayConfig(appId, 'vhosts', { config: configuration, file: 'atomic_redirect.json' })
        .then(() => {
          this.initialRedirects = Object.keys(this.state.redirects);
          this.setState({ status: 'success' }, () => {
            setTimeout(() => {
              this.setState({ status: 'ready' });
            }, 3000);
          });
        }).catch((err) => {
          logger.error(err);
        });
    }
  };

  handleAddConfig = (newConfig) => {
    // we assume that the newConfig is already properly configured
    // do some config magic
    this.setState((state) => ({
      redirects: { ...state.redirects, ...newConfig },
    }));
  };

  handleRemoveRedirect = (key) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const currentState = { ...this.state.redirects };
    delete currentState[key];

    this.setState({ redirects: currentState });
  };

  handleSetState = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { redirects, status } = this.state;
    return (
      <Box as={Paper} padding="small" margin={{ top: 'small' }} justify="space-evenly">
        <Typography variant="h2">Custom Redirects</Typography>
        <AddRedirect {...this.props} onAddConfig={this.handleAddConfig} />
        <Box margin={{ top: 'small', bottom: 'xsmall' }}>
          <Typography variant="caption">Path should start with &apos;/&apos;; We currently don&apos;t support URL strings using &apos;?&apos; - Please submit a <SupportLink>support ticket</SupportLink> to use query parameter paths</Typography>
        </Box>
        <ListRedirects redirects={redirects} initialRedirects={this.initialRedirects} removeRedirect={this.handleRemoveRedirect} />
        <Row>
          {
            (Object.keys(redirects).length > 0
            || this.initialRedirects.length > 0) && (
              <Button
                onClick={this.save}
                loading={status === 'loading'}
                success={status === 'success'}
                color="secondary"
                variant="contained"
                style={{ color: 'white' }}
              >
                Apply Changes
              </Button>
            )
          }
          {
            this.initialRedirects.length > 0 && (
              <ConfirmableButton
                icon={TrashIcon}
                activeColor={colors.red10}
                callback={() => this.props.deleteAppGatewayConfig(this.props.app.id, 'vhosts', {
                  file: 'atomic_redirect.json',
                })}
                tooltip="Delete Entire Configuration"
              />
            )
          }
          <Button
            variant="outlined"
            color="default"
            style={{ marginLeft: 'auto' }}
            ref={(e) => { this.export = e; }}
            onClick={() => {
              const appName = this.props.app.name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
              const filename = `${appName}_redirects`;
              exportData(filename, this.state.redirects, ['From', 'To']);
            }}
          >
            Export
          </Button>
        </Row>
      </Box>
    );
  }
}

export default connect(
  (state) => ({
    app: state.app.app.data,
  }),
  {
    getAppGatewayConfig,
    deleteAppGatewayConfig,
    setAppGatewayConfig,
  },
)(RedirectManager);
