/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import {
  APPS_SEARCH_REQUEST,
  APPS_SEARCH_RESET,
  ACCOUNTS_SEARCH_REQUEST,
  ACCOUNTS_SEARCH_RESET,
  ACCOUNTS_SEARCH_SET_FILTER,
} from './constants';

export const appsInitialState = {
  appSearchString: '',
  activeOnly: 1,
};

export const accountsInitialState = {
  searchString: '',
  filter: 'collaborators',
};

export const accounts = createReducer(accountsInitialState, (builder) => {
  builder
    .addCase(ACCOUNTS_SEARCH_REQUEST, (state, action) => {
      state.searchString = action.searchString;
    })
    .addCase(ACCOUNTS_SEARCH_RESET, (state) => {
      state.searchString = '';
    })
    .addCase(ACCOUNTS_SEARCH_SET_FILTER, (state, action) => {
      state.filter = action.filter;
    });
});

export const apps = createReducer(appsInitialState, (builder) => {
  builder
    .addCase(APPS_SEARCH_REQUEST, (state, action) => {
      state.appSearchString = action.params.appSearchString;
      if (action.params.activeOnly) {
        state.activeOnly = action.params.activeOnly;
      }
    })
    .addCase(APPS_SEARCH_RESET, (state, action) => {
      state = appsInitialState;
    });
});
