import React, { Fragment } from 'react';
import {
  object, string,
} from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';

import { routes } from './routes';

const Settings = ({ match, parentPath }) => {
  return (
    <Fragment>
      <Route
        path={`${match.path}/:view*`}
        render={(props) => (<Navigation {...props} routes={routes} parentPath={match.url} />)}
      />
      <Switch>
        {
          routes.concat().reverse().map((child) => {
            return (
              <Route key={child.to} path={`${parentPath}/${child.to}`} component={child.component} />
            );
          })
        }
      </Switch>
    </Fragment>
  );
};

Settings.propTypes = {
  match: object.isRequired,
  parentPath: string,
};

export default Settings;
