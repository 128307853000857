import React from 'react';
import { connect } from 'react-redux';
import { object, bool, string } from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';
import Box from 'shared/styleguide/atoms/Box';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import Layout from 'shared/styleguide/molecules/Layout';
import { roles } from 'shared/modules/permissions/user/actions';

import { FeatureToggle } from 'shared/flags';
import Team from './routes/Team';
import { routes } from './routes';

export const TeamContainer = (props) => {
  const {
    permissions, account, isPagelyAdmin, match,
  } = props;

  const role = isPagelyAdmin ? roles.PRIMARY : permissions.directory[account.id].role;
  return (
    <Layout
      title="Team"
    >
      <FeatureToggle
        flags={[role >= roles.SUBADMIN]}
      >
        <Box>
          <Route
            path={`${match.path}/:view*`}
            render={(props) => (
              <Navigation
                {...props}
                routes={routes}
                parentPath={match.url}
                fallback="team"
                extraTabs={[
                  {
                    to: match.url,
                    value: 'team',
                    label: 'Team',
                  },
                ]}
              />
            )}
          />
        </Box>
      </FeatureToggle>
      <ErrorBoundary>
        <Switch>
          {
            routes.map((child) => {
              return (
                <Route key={child.to} path={`${match.url}/${child.to}`} component={child.component} />
              );
            })
          }
          <Route
            exact
            path={`${match.path}`}
            render={() => (
              <Team isPagelyAdmin={isPagelyAdmin} role={role} />
            )}
          />
        </Switch>
      </ErrorBoundary>
    </Layout>
  );
};

TeamContainer.propTypes = {
  account: object,
  isPagelyAdmin: bool,
  match: object,
  parentPath: string,
  permissions: object,
};

const mapStateToProps = (state) => ({
  account: state.account,
  user: state.user,
  permissions: state.permissions,
});
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamContainer);
