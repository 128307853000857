import * as EMAIL from './constants';

const initialState = {
  status: 'pristine',
  data: [],
  directory: {},
};

export const email = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL.GET_TEMPLATES_REQUEST: {
      return {
        ...initialState,
        status: 'loading',
      };
    }
    case EMAIL.GET_TEMPLATES_FAILURE: {
      return {
        ...initialState,
        status: 'failed',
      };
    }
    case EMAIL.GET_TEMPLATES_SUCCESS: {
      const directory = {};
      action.data.data.forEach((template) => {
        directory[template.id] = template;
      });

      return {
        ...initialState,
        status: 'success',
        data: action.data.data,
        directory,
        count: action.data['@count'],
      };
    }
    case EMAIL.UPDATE_TEMPLATE_SUCCESS: {
      const directory = { ...state.directory };
      const template = action.data;
      directory[template.id] = template;

      const data = [...state.data];
      const templateIndex = data.findIndex((t) => t.id === template.id);
      data[templateIndex] = template;

      return {
        ...state,
        status: 'success',
        data,
        directory,
        count: action.data['@count'],
      };
    }
    default:
      return state;
  }
};
