import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box from '../Box';

const Row = styled(Box)(
  css`
  display: flex;
  flex-direction: row;
  `,
  ({ align }) => css`
    align-items: ${align || 'center'};
  `,
  ({ justify }) => css`
    justify-content: ${justify};
  `,
);

export default Row;
