// These styles are meant to be supplied to corresponding props on MUI Dialog components.
// Convention: assign to the 'dialog' property on the inlineStyles object of your local stylesheet.
const muiInline = {
  contentStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: 600,
  },
  paperProps: {
    style: {
      backgroundColor: 'transparent',
    },
    zDepth: 0,
  },
};

export default { muiInline };
