import React, { Component } from 'react';
import { object, func, bool } from 'prop-types';

import {
  TableHead, TableRow, TableCell, Typography,
} from '@material-ui/core';

import Banner from 'shared/styleguide/molecules/Banner';
import { ErrorText } from 'shared/styleguide/typography';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Box from 'shared/styleguide/atoms/Box';
import { consolidateErrors } from 'shared/utils/validation';
import { PRESSDNS_REGEX } from 'shared/modules/dns/constants';
import {
  ACTIVE, ACTIVE_MODIFYING, PENDING_INACTIVE,
} from 'shared/modules/app/routes/FSA/constants';

import { remMapper } from 'shared/styleguide/theme/spacing';
import DomainsTable from './DomainsTable';
import ModifyDomains from './ModifyDomains';
import Detail from './DomainRow/Detail';
import ListRows from './DomainRow/Collapsed';
import MoveDomain from './MoveDomain';
import ValidationWrapper from '../DNS/Validation/Wrapper';
import Validation from '../DNS/Validation';

class Domains extends Component {
  static propTypes = {
    account: object.isRequired,
    app: object.isRequired,
    appDomains: object.isRequired,
    apps: object.isRequired,
    certLinks: object.isRequired,
    isStaging: bool,
    promoteDomainToPrimary: func.isRequired,
    validationRecords: object.isRequired,
  };

  state = {
    openMoveDialog: false,
    domain: null,
    validationOpen: false,
    requestValidations: [],
    edit: false,
  };

  handlePromoteDomain = (appId, id, pressdns, fqdn) => {
    this.props.promoteDomainToPrimary(appId, id, pressdns, fqdn)
      .then(() => {
        this.setState({ error: '' });
      })
      .catch((err) => {
        if (err) {
          this.setState({ moveStatus: 'error', error: consolidateErrors(err) });
        }
      });
  };

  render() {
    const {
      appDomains, app, validationRecords, certLinks, isStaging,
    } = this.props;
    const {
      domainFilter, validationOpen, requestValidations, edit,
    } = this.state;

    const unfilteredDomains = appDomains.data
      .map(((r) => {
        const decorated = { ...validationRecords[r.id], link: certLinks[r.id], ...r };
        if (certLinks[r.id]?.expires) {
          // when toggling always https, the response doesn't include an expiration on the cert
          // which overwrites the cert record, even though the cert has an expiration
          decorated.link.expires = certLinks[r.id]?.expires;
        }
        return decorated;
      }));
    const filteredDomains = unfilteredDomains
      .filter(((r) => r.fqdn.toLowerCase().match(domainFilter)));

    const onClose = () => this.setState({ edit: false });

    // During enable flow there are some steps/statuses that are domain modifications
    // are allowed on, if a step is not allowed we will show an error message from the api.

    // For the disable flow, we can block changes though.
    const fsaIsDisabling = app.data.fsa?.status === PENDING_INACTIVE;

    if (edit) {
      return (
        <ModifyDomains
          appDomains={appDomains}
          onClose={onClose}
          appId={app.data.id}
          isFsa={[ACTIVE, ACTIVE_MODIFYING].includes(app.data.fsa?.status)}
          fsaStatus={app.data.fsa?.status}
        />
      );
    }

    return (
      <Box>
        <Box margin={{ bottom: 'xsmall' }}>
          <Typography variant="h1">Manage Domains</Typography>
          <ErrorText>{this.state.error}</ErrorText>
        </Box>
        <>
          {
            fsaIsDisabling && (
              <Banner
                subheading={(
                  <Typography color="inherit" variant="body2">Modifying domains is not allowed while Mercury Dynamic Site Acceleration is being disabled.</Typography>
                )}
                type="info"
                dismissable
                rounded="top"
              />
            )
          }
          <DomainsTable
            paginate
            pageLength={filteredDomains.length > 75 ? 25 : 10}
            HeadRow={(
              <TableHead>
                <TableRow css={{ height: remMapper('xxlarge') }}>
                  <TableCell colSpan={3} css={(theme) => ({ backgroundColor: theme.color000 })}>
                    {
                      !isStaging && (
                      <Box align="center" direction="row">
                        <SearchBar
                          text="Search Domains"
                          onChange={(filter) => this.setState({ domainFilter: filter })}
                          css={{
                            flex: 1,
                            paddingRight: remMapper('medium'),
                          }}
                        />
                        <Box>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => this.setState({ edit: true })}
                            disabled={fsaIsDisabling}
                          >
                            Add/Remove Domains
                          </Button>
                        </Box>
                      </Box>
                      )
                    }
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            ListRows={ListRows(filteredDomains)}
            Detail={
              ({ data: domain }) => (
                <Detail
                  key={domain.id}
                  {...this.props}
                  onPromoteDomain={this.handlePromoteDomain}
                  onUpdateState={(stateObject) => this.setState(stateObject)}
                  onRequestValidation={(value) => this.setState({ requestValidations: value })}
                  setOpen={(value) => { this.setState({ validationOpen: value }); }}
                  domain={domain}
                  fsaIsDisabling={fsaIsDisabling}
                />
              )
            }
          />
          <ValidationWrapper
            domains={unfilteredDomains.filter((d) => !d.fqdn.match(PRESSDNS_REGEX))}
            onRequestValidation={(value) => this.setState({ requestValidations: value, validationOpen: true })}
          />
          {
            validationOpen && (
              <Validation
                domains={unfilteredDomains.filter((d) => !d.fqdn.match(PRESSDNS_REGEX))}
                isOpen={validationOpen}
                setOpen={(value) => { this.setState({ validationOpen: value }); }}
                defaultValidations={requestValidations}
              />
            )
          }
        </>
        {
          this.state.openMoveDialog && (
            <MoveDomain
              open
              onClose={() => { this.setState({ openMoveDialog: false, domain: null, moveStatus: 'ready' }); }}
              app={app}
              apps={this.props.apps}
              domain={this.state.domain}
            />
          )
        }
      </Box>
    );
  }
}

export default Domains;
