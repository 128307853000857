import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import {
  bool, object, string, func, node,
} from 'prop-types';

const Checkbox = ({ onCheck, labelPosition = 'end', ...props }) => {
  return (
    <FormControlLabel
      labelPlacement={labelPosition}
      disabled={props.disabled}
      {...props.labelProps}
      control={(
        <MuiCheckbox
          onChange={(e) => onCheck(e, e.target.checked)}
          {...props}
        />
      )}
      label={props.label}
    />
  );
};

Checkbox.propTypes = {
  disabled: bool,
  label: node,
  labelPosition: string,
  labelProps: object,
  onCheck: func,
};

export default Checkbox;
