import React, { Component, Fragment } from 'react';
import {
  shape, func, object, array, bool, number,
} from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { FormGroup, Link, Typography } from '@material-ui/core';

import { Today } from '@material-ui/icons';
import { createTicket, resetTicket, uploadAttachments } from 'shared/modules/support/redux/actions';
import { fetchAccountCollaborators } from 'shared/modules/team/redux/actions';
import { roleStrings } from 'shared/modules/permissions/user/actions';
import Select from 'shared/styleguide/atoms/Select/Select';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Loading from 'shared/styleguide/atoms/Loading';
import Card from 'shared/styleguide/atoms/Card/Card';
import { TextArea } from 'shared/styleguide/atoms/Input/TextArea';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Checkbox from 'shared/styleguide/atoms/Switches/Checkbox';
import Success from 'shared/styleguide/atoms/Success';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import { isJsonValid } from 'shared/utils/isJsonValid';

import { getTagsList } from 'shared/modules/account/redux/selectors';
import { VIC, ESLA } from 'shared/modules/metadata/constants';
import InfoText from 'shared/styleguide/molecules/InfoText';
import UploadAttachments from './UploadAttachments';

import styles, {
  row, half,
} from '../../Support.scss';
import LiveChat from './LiveChat';

export class SubmitTicket extends Component {
  static propTypes = {
    accountTags: array,
    apps: shape({
      data: array,
    }),
    attachments: shape({
      data: array,
      errAllMessages: array,
    }),
    createTicket: func.isRequired,
    support: shape({
      loading: bool,
      done: bool,
      data: array,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      site: '',
      reason: { label: '' },
      description: '',
      subject: '',
      collaborators: [],
      errors: {
        hasErrors: false,
        subject: '',
        body: '',
        ticketFocus: '',
        whichSite: '',
      },
    };

    // try to prefill data if there is any
    const prefill = localStorage.getItem('support_ticket');
    if (prefill) {
      this.state = {
        ...this.state,
        ...JSON.parse(prefill),
      };

      // reset localstorage
      localStorage.removeItem('support_ticket');
    }
  }

  componentDidMount() {
    this.props.fetchAccountCollaborators(this.props.accountId);
  }

  componentWillUnmount() {
    this.props.resetTicket();
  }

  checkForRole = (targetRole, isChecked) => {
    const { collaborators } = this.props;
    if (isChecked) {
      const targetGroup = collaborators.data.filter((f) => f.role === targetRole).map((email) => email.accessedByEmail);
      // filter out duplicates
      // eslint-disable-next-line react/no-access-state-in-setstate
      const collabs = this.state.collaborators.concat(targetGroup.filter((a) => !this.state.collaborators.includes(a)));

      this.setState({
        collaborators: collabs,
      });
    } else {
      this.setState((state) => ({
        collaborators: state.collaborators.filter((email) => {
          const target = collaborators.data.find((c) => c.accessedByEmail === email);

          return target.role !== targetRole;
        }),
      }));
    }
  };

  validate = (target, cb) => {
    const errors = {};

    if (target.description === '') {
      errors.description = 'This field is required';
    }
    if (target.subject === '') {
      errors.subject = 'This field is required';
    }
    if (target.whichSite === '') {
      errors.whichSite = 'This field is required';
    }
    if (target.ticketFocus === '') {
      errors.ticketFocus = 'This field is required';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: {
          ...errors,
          hasErrors: true,
        },
      });
    } else {
      this.setState({
        errors: {
          hasErrors: false,
        },
        description: target.description.value,
        subject: target.subject.value,
      }, cb);
    }
  };

  handleSubmitTicket = (e) => {
    e.preventDefault();

    const event = {
      description: e.target.description.value,
      subject: e.target.subject.value,
      whichSite: this.state.site,
      ticketFocus: this.state.reason.label,
    };

    this.validate(event, () => {
      const { accountId, apps, attachments: { data } } = this.props;
      const app = apps.data.find((a) => a.id === this.state.site);
      let attachmentTokens = [];
      if (data && data.length > 0) {
        attachmentTokens = data.map(({ token }) => token);
      }

      const ticketData = {
        subject: event.subject,
        body: event.description,
        attachmentTokens,
        accountId,
        requesterId: accountId,
        type: this.state.reason.value,
        ccAddresses: this.state.collaborators,
      };

      if (app) {
        ticketData.appId = app.id;
      }

      this.props.createTicket(ticketData);
    });
  };

  render() {
    const {
      apps, support, collaborators, accountTags,
    } = this.props;
    const { errors, description, subject } = this.state;

    const errorMessages = support.validation?.body?.body?.messages.map((item) => {
      return isJsonValid(item) ? JSON.parse(item)?.base?.[0]?.description : item;
    });

    if (support.loading || collaborators.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    if (support.done) {
      return (
        <div>
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <Success
              classes={{}}
              primaryMessage="Ticket submitted successfully"
              secondaryMessage={`Ticket #${support.data.id}`}
            />
          </div>
        </div>
      );
    }

    const options = apps.data.length > 0
      ? apps.data
        .map((a) => ({
          label: a.name,
          value: a.id,
        }))
        .concat([{ label: "I don't see my app", value: 0 }])
      : [{
        label: "I don't have any apps",
        value: 0,
      }];

    return (
      <Fragment>
        {
          (accountTags.includes(VIC) || accountTags.includes(ESLA)) && (
            <Box as={Card} padding="small" margin={{ bottom: 'small' }}>
              <Typography paragraph>If you would like to discuss an upgrade, downgrade, or future projects please click below to schedule a call.</Typography>
              <Box align="center" justify="center">
                <Button
                  component={Link}
                  href="https://hello.pagely.com/meetings/kirillmendeleyev/account-review"
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<Today />}
                  variant="contained"
                >
                  Contact Account Manager
                </Button>
              </Box>
            </Box>
          )
        }

        <Box as={Card} padding="medium">
          <form onSubmit={this.handleSubmitTicket} css={{ width: '100%' }}>
            <Box margin={{ bottom: 'medium' }}>
              <Select
                placeholder="Which Site?"
                className={cx({ [styles.error]: (errors.hasErrors && errors.whichSite) })}
                name="whichSite"
                aria-label="which site are you reporting"
                options={options}
                onChange={(d) => this.setState({ site: d.value })}
                isClearable={false}
              />
              <Box margin={{ top: 'small' }}>
                <InfoText>If you don&apos;t see the app you need, please check that you are on the right account.</InfoText>
              </Box>
            </Box>
            <Box direction="row" gap="small" align="center">
              <Box flex={1}>
                <Select
                  css={{ width: '100%' }}
                  name="ticketFocus"
                  aria-label="what is the ticket focus"
                  className={cx({ [styles.error]: (errors.hasErrors && errors.ticketFocus) })}
                  defaultValue={{
                    label: 'I need help doing...',
                    value: 'task',
                  }}
                  options={[
                    {
                      label: 'I have a question about...',
                      value: 'question',
                    },
                    {
                      label: 'I have a problem regarding...',
                      value: 'incident',
                    },
                    {
                      label: 'I need help doing...',
                      value: 'task',
                    },
                  ]}
                  onChange={(d) => this.setState({ reason: d })}
                  isClearable={false}
                />
              </Box>
              <Box flex={1}>
                <TextField
                  name="subject"
                  id="support-subject"
                  placeholder="Subject"
                  autoComplete="off"
                  fullWidth
                  margin="none"
                  errorText={errors.hasErrors && errors.subject}
                  defaultValue={subject}
                />
              </Box>
            </Box>
            <Box margin={{ top: 'small' }}>
              <TextArea
                name="description"
                placeholder="Please Describe the Issue"
                errorText={errors.hasErrors && errors.description}
                className={cx({ [styles.error]: (errors.hasErrors && errors.description) })}
                defaultValue={description}
                id="description"
              />
            </Box>
            <UploadAttachments />
            <div className={styles.checkboxes}>
              <div className={cx(styles.half, styles.checkboxColumn)}>
                <FormGroup>

                  <Checkbox
                    label="CC all Admins"
                    onCheck={(e, isChecked) => {
                      const targetRole = 9;
                      this.checkForRole(targetRole, isChecked);
                    }}
                  />
                  <Checkbox
                    label="CC all Tech"
                    onCheck={(e, isChecked) => {
                      const targetRole = 6;
                      this.checkForRole(targetRole, isChecked);
                    }}
                  />
                  <Checkbox
                    label="CC all Billing"
                    onCheck={(e, isChecked) => {
                      const targetRole = 4;
                      this.checkForRole(targetRole, isChecked);
                    }}
                  />
                </FormGroup>
              </div>
              <div className={cx(styles.half, styles.checkboxColumn)}>
                <FormGroup>
                  {
                      collaborators.collaboratorDirectory
                        .map((c) => {
                          return (
                            <Checkbox
                              key={`${c.sourceId}-${c.targetId}`}
                              label={`${c.accessedByName} (${roleStrings[c.role]})`}
                              checked={this.state.collaborators.includes(c.accessedByEmail)}
                              onCheck={(e, isChecked) => {
                                if (isChecked) {
                                  this.setState((state) => ({
                                    collaborators: [...state.collaborators, c.accessedByEmail],
                                  }));
                                } else {
                                  this.setState((state) => ({
                                    collaborators: state.collaborators.filter((f) => f !== c.accessedByEmail),
                                  }));
                                }
                              }}
                            />
                          );
                        })
                    }
                </FormGroup>
              </div>
            </div>
            <div>
              {errorMessages && errorMessages.length > 0
                  && (
                    <Box margin={{ bottom: 'small' }}>
                      <ErrorText key={errorMessages[0]}>{`${errorMessages[0]}${errorMessages[1] ? `: ${errorMessages[1]}` : ''}`}</ErrorText>
                    </Box>
                  )}
              <Box direction="row" justify="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color={errors.hasErrors ? 'error' : 'secondary'}
                  disabled={support.loading}
                >
                  Submit Ticket
                </Button>
              </Box>
            </div>
          </form>
        </Box>
        <Box margin={{ top: 'medium' }}>
          <LiveChat />
        </Box>
      </Fragment>
    );
  }
}

SubmitTicket.propTypes = {
  accountId: number,
  apps: object,
  attachments: object,
  collaborators: object,
  fetchAccountCollaborators: func,
  resetTicket: func,
  support: object,
};

export default connect(
  (state) => ({
    apps: state.apps,
    accountId: state.account.id,
    attachments: state.support.attachments,
    collaborators: state.accountCollaborators,
    support: state.support.ticket,
    accountTags: getTagsList(state),
  }),
  {
    createTicket, resetTicket, fetchAccountCollaborators, uploadAttachments,
  },
)(SubmitTicket);
