import React from 'react';
import Typography from '@material-ui/core/Typography';
import * as STATUS from 'shared/modules/status/redux/constants';

export const PENDING = 'pending';
export const PENDING_INACTIVE = 'pending-inactive';
export const ACTIVE = 'active';
export const INACTIVE = 'inactive';
export const ACTIVE_MODIFYING = 'active-modifying';
export const DELETING = 'deleting';
export const DELETED = 'deleted';
export const ACTION_REQUIRED = 'action-required';

export const fsaJobTypes = {
  [STATUS.jobTypes.fsaSetup]: {
    label: 'Setup',
    verb: <Typography component="span" color="secondary"><strong>enabling</strong></Typography>,
  },
  [STATUS.jobTypes.fsaSetupCloudflare]: {
    label: 'Cloudflare Setup',
    verb: <Typography component="span" color="secondary"><strong>enabling</strong></Typography>,
  },
  [ACTIVE_MODIFYING]: {
    label: 'Modification',
    verb: <Typography component="span" color="secondary"><strong>modifying</strong></Typography>,
  },
  [STATUS.jobTypes.fsaDisable]: {
    label: 'Disable',
    verb: <Typography component="span" color="error"><strong>disabling</strong></Typography>,
  },
};

export const skippableEnableSteps = [
  'verify-site-access',
];

export const skippableDisableSteps = [
  'domain-dns-validation',
  'verify-site-access',
  'dns-propagation',
  'verify-cdn-access',
];
