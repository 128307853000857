import makeApiAction from 'shared/utils/redux/apiActions';
import * as CHARGEBEE from './constants';

export const getCatalog = () => makeApiAction(
  ({ api }) => api().get('/go-billing/catalog'),
  CHARGEBEE.BILLING_PLANS_REQUEST,
  CHARGEBEE.BILLING_PLANS_SUCCESS,
  CHARGEBEE.BILLING_PLANS_FAILURE,
  null,
  true,
);

export const getChargebeeAccount = (customerId) => makeApiAction(
  ({ api }) => api().get(`/go-billing/account/${customerId}`),
  CHARGEBEE.GET_CUSTOMER_REQUEST,
  CHARGEBEE.GET_CUSTOMER_SUCCESS,
  CHARGEBEE.GET_CUSTOMER_FAILURE,
  {
    customerId,
  },
  true,
);

export const updateChargebeeAccount = (customerId, data) => makeApiAction(
  ({ api }) => api().patch(`/go-billing/account/${customerId}`, data),
  CHARGEBEE.UPDATE_CUSTOMER_REQUEST,
  CHARGEBEE.UPDATE_CUSTOMER_SUCCESS,
  CHARGEBEE.UPDATE_CUSTOMER_FAILURE,
  {
    customerId,
    data,
  },
  true,
);

export const updateChargebeeAddress = (customerId, data) => makeApiAction(
  ({ api }) => api().patch(`/go-billing/account/${customerId}/address`, data),
  CHARGEBEE.UPDATE_CUSTOMER_REQUEST,
  CHARGEBEE.UPDATE_CUSTOMER_SUCCESS,
  CHARGEBEE.UPDATE_CUSTOMER_FAILURE,
  {
    customerId,
    data,
  },
  true,
);

export const updatePayment = (customerId, billingToken) => makeApiAction(
  ({ api }) => api().patch(`/go-billing/account/${customerId}/payment-method`, { billingToken }),
  CHARGEBEE.UPDATE_PAYMENT_REQUEST,
  CHARGEBEE.UPDATE_PAYMENT_SUCCESS,
  CHARGEBEE.UPDATE_PAYMENT_FAILURE,
  {
    customerId,
  },
  true,
);

// why do we have to do this?
// the customer subscriptions api only returns a list of IDs
export const getCustomerSubscriptions = (customerId) => async (dispatch, getState, { history, api }) => {
  dispatch({
    type: CHARGEBEE.GET_SUBSCRIPTIONS_REQUEST,
    params: { customerId },
  });

  try {
    const list = await api().get(`/go-billing/account/${customerId}/subscriptions`);

    const res = await Promise.all(
      list.data.map((subId) => api().get(`/go-billing/account/${customerId}/subscription/${subId}`)),
    );

    const data = res.map((sub) => sub.data);

    dispatch({
      type: CHARGEBEE.GET_SUBSCRIPTIONS_SUCCESS,
      data,
      params: { customerId },
    });

    return new Promise((resolve) => resolve(data));
  } catch (err) {
    dispatch({
      type: CHARGEBEE.GET_SUBSCRIPTIONS_FAILURE,
      params: { customerId },
    });
    return new Promise((resolve, reject) => reject(err));
  }
};

export const getInvoices = (customerId) => makeApiAction(
  ({ api }) => api().get(`/go-billing/account/${customerId}/invoices`),
  CHARGEBEE.GET_INVOICES_REQUEST,
  CHARGEBEE.GET_INVOICES_SUCCESS,
  CHARGEBEE.GET_INVOICES_FAILURE,
  { customerId },
  true,
);

export const getChargebeeSubscription = (subscriptionId) => makeApiAction(
  ({ api }) => api().get(`/go-billing/subscription/${subscriptionId}`),
  CHARGEBEE.GET_SUBSCRIPTION_REQUEST,
  CHARGEBEE.GET_SUBSCRIPTION_SUCCESS,
  CHARGEBEE.GET_SUBSCRIPTION_FAILURE,
  { subscriptionId },
  true,
);
