import React, { useState, useEffect } from 'react';
import {
  object, number, string, func, oneOfType, array,
} from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Typography } from '@material-ui/core';

import { getDiskUsageByLocation } from 'shared/modules/analytics/redux/actions/disk';
import { consolidateErrors } from 'shared/utils/validation';
import Loading from 'shared/styleguide/atoms/Loading';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';

import { graphMap } from './graphUtils';
import DiskPieWrapper from './DiskPieWrapper';

const statsDateRange = {
  fromDate: moment().subtract(1, 'days').tz('UTC').format(),
  toDate: moment().tz('UTC').endOf('day').format(),
};

const DiskPieByLocation = ({
  accountId, serverName, graphType, dimensions, getDiskUsageByLocation, apps,
}) => {
  const [status, setStatus] = useState('pristine');
  const [errors, setErrors] = useState(null);
  const [diskUsage, setDiskUsage] = useState(null);
  const [queryParams, setQueryParams] = useState(null);

  const fetchLocationUsage = async (accountId, params) => {
    let diskUsageResults;
    setStatus('loading');
    try {
      diskUsageResults = await getDiskUsageByLocation(accountId, {
        ...statsDateRange,
        ...params,
      });
      setStatus('success');
      setDiskUsage(diskUsageResults.data);
      setErrors(null);
    } catch (err) {
      setStatus('failed');
      setErrors(consolidateErrors(err));
    }
  };

  useEffect(() => {
    const graphParams = graphMap(dimensions);
    const params = {
      ...statsDateRange,
      ...graphParams?.[graphType]?.params,
      serverName: Array.isArray(serverName) ? [...serverName] : [serverName],
    };
    setQueryParams(params);
    fetchLocationUsage(accountId, params);
  }, []);

  if (status === 'loading' || diskUsage === null || apps.status === 'loading') {
    return <Loading />;
  }

  const appNames = apps.data.reduce((acc, app) => {
    acc[app.id] = app;
    return acc;
  }, {});

  if (errors) {
    return errors;
  }

  if (!diskUsage.series.length) {
    return null;
  }

  return (
    <Box as={Paper} flex={1} padding="medium">
      <Typography variant="h5" color="textSecondary">{graphMap(dimensions)[graphType].title}</Typography>
      <Box style={{ minWidth: 300 }} align="center">
        <DiskPieWrapper
          appNames={appNames}
          serverName={serverName}
          diskUsage={diskUsage}
          graphType={graphType}
          graphMap={graphMap(dimensions)[graphType]}
          queryParams={queryParams}
          accountId={accountId}
        />
      </Box>
    </Box>
  );
};

DiskPieByLocation.propTypes = {
  accountId: number,
  apps: object,
  dimensions: object,
  getDiskUsageByLocation: func,
  graphType: string,
  serverName: oneOfType([string, array]),
};

export default connect(
  (state) => ({
    apps: state.apps,
  }), { getDiskUsageByLocation },
)(DiskPieByLocation);
