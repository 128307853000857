import React, { Fragment } from 'react';
import {
  func, object, number,
} from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton, InputAdornment } from '@material-ui/core';
import Toggle from 'shared/styleguide/atoms/Switches/Toggle';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import { roleStrings } from 'shared/modules/permissions/user/actions';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Select from 'shared/styleguide/atoms/Select/Select';
import AppsDropdown from 'shared/styleguide/organisms/AppsDropdown';

import RoleDescriptions from './RoleDescriptions';

export class AddCollaborator extends React.Component {
  static propTypes = {
    account: object,
    addCollaborator: func.isRequired,
    apps: object,
    collaborator: object,
    role: number,
    userPool: object,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      role: 6,
      values: [],
      pristine: true,
      requireSSO: true,
    };

    this.roleValues = Object.keys(roleStrings)
      .filter((key) => {
        const role = parseInt(key, 10);
        return role < this.props.role;
      })
      .map((key) => ({
        label: roleStrings[key],
        value: parseInt(key, 10),
      }));

    this.editDiv = null;
  }

  componentDidUpdate(oldProps) {
    if (oldProps.collaborator.status === 'loading' && this.props.collaborator.status === 'success') {
      this.resetForm();
    }
  }

  resetForm = () => {
    this.setState({
      name: '',
      email: '',
      role: null,
      values: [],
      pristine: true,
    });

    if (this.editDiv) {
      const inputs = this.editDiv.querySelectorAll('input[id]');

      inputs.forEach((input) => {
        input.value = ''; //eslint-disable-line
      });
    }
  };

  handleAdd = () => {
    const { account: { id } } = this.props;
    const {
      name, email, role, values, requireSSO,
    } = this.state;

    const allowPasswordLogin = !requireSSO;

    if (values.length > 0) {
      values.forEach((appId) => {
        this.props.addCollaborator(id, name, email, role, allowPasswordLogin, appId);
      });
    } else {
      this.props.addCollaborator(id, name, email, role, allowPasswordLogin);
    }
  };

  render() {
    const { validation, errMessage } = this.props.collaborator;
    const isButtonDisabled = (
      !this.state.role
      || !this.state.name
      || !this.state.email
      || (this.state.role <= 2 && this.state.values.length === 0)
      || this.props.collaborator.doing
    );
    return (
      <Fragment>
        <div
          ref={(div) => { this.editDiv = div; }}
          id="collab-edit"
          css={{ width: '100%' }}
        >
          <Box direction="column" justify="flex-start">
            <Box direction="row" flex={1}>
              <WideTextField
                placeholder="Name"
                name="name"
                id="name"
                margin="none"
                fullWidth
                css={{
                  marginRight: 15,
                }}
                value={this.state.name}
                onChange={(e, name) => this.setState({ name })}
                errorText={validation?.body?.name?.messages.join(', ')}
              />
              <WideTextField
                placeholder="Email"
                id="email"
                name="email"
                type="email"
                margin="none"
                onChange={(e, email) => this.setState({ email })}
                value={this.state.email}
                errorText={validation?.body?.email?.messages.join(', ')}
                style={{
                  minWidth: '0px',
                  width: '100%',
                }}
              />
            </Box>
            {
              this.props.userPool && (
                <Box>
                  <Toggle
                    label="Require Single Sign On"
                    checked={this.state.requireSSO}
                    onChange={(e) => this.setState({ requireSSO: e.target.checked })}
                  />
                </Box>
              )
            }
            <Box direction="row" gap="small" padding={{ top: 'xsmall' }} align="center">
              <Select
                key={String(this.state.role)}
                placeholder="Role"
                data-testid="collab-role"
                inputId="collab-role"
                options={this.roleValues}
                onChange={(value) => this.setState({
                  role: parseInt(value.value, 10),
                  pristine: false,
                })}
                value={this.roleValues.find((option) => option.value === this.state.role)}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: '100%',
                  }),
                }}
              />
            </Box>
            <Box direction="row" gap="small" padding={{ top: 'xsmall' }} align="center">
              {
                (this.state.role === 1 || this.state.role === 2)
                && (
                  <AppsDropdown
                    apps={this.props.apps.data}
                    values={this.state.values}
                    onChange={(values) => this.setState({ values: values.map((v) => v.value) })}
                    styles={{
                      container: (base) => ({
                        ...base,
                        flex: '1',
                      }),
                    }}
                  />
                )
              }
            </Box>
          </Box>
          {
            !this.state.pristine && (
              <RoleDescriptions role={this.state.role} />
            )
          }
        </div>
        {
          this.props.collaborator.status === 'failed' && errMessage && (
            <ErrorText>{errMessage}</ErrorText>
          )
        }
        <Box direction="row" justify="flex-end" margin={{ top: 'medium' }} gap="small">
          <Button
            variant="contained"
            disabled={isButtonDisabled}
            onClick={this.handleAdd}
            loading={this.props.collaborator.doing}
          >
            Invite
          </Button>
          {
            !this.state.pristine && (
            <Button
              data-testid="clear_form"
              variant="outlined"
              color="default"
              onClick={this.resetForm}
            >
              Clear Form
            </Button>
            )
          }
        </Box>
      </Fragment>
    );
  }
}

export default (AddCollaborator);
