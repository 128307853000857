import React, { useEffect, Fragment } from 'react';
import { object } from 'prop-types';

import { Typography, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Select from 'shared/styleguide/atoms/Select/Select';

import { getCurrentOption } from '../utils';
import { inputs } from '../constants';

const FromQueries = ({
  values, form,
}) => {
  useEffect(() => {
    if (!values.from.query || values.from.query?.length === 0) {
      form.addQuery('from');
    }
  }, [values.from.query, form]);

  return (
    <Fragment>
      {
        values.from.query?.length > 0 && values.from.query.map((query, index) => {
          let queryVal = query.value || '';

          if (['exists', 'empty'].includes(query.compare)) {
            queryVal = query.value ? `${query.value} (n/a)` : '(n/a)';
          }

          return (
            <Box key={`from-query-${index}`} padding={{ top: 'xsmall', bottom: 'xsmall' }}>
              <Box direction="row" alignItems="center" flex={1} align="center">
                <Box css={{ flexBasis: '33%' }}>
                  <TextField
                    variant="outlined"
                    value={query.key || ''}
                    onChange={(e) => form.changeQuery('from', index, 'key', e.target.value)}
                    placeholder="key"
                    autoComplete="off"
                    margin="dense"
                    fullWidth
                    multiline
                    disabled={form.status === 'loading'}
                  />
                </Box>
                <Box css={{ flexBasis: 'auto' }}>
                  <Box align="center" padding="xxsmall">
                    <strong>=</strong>
                  </Box>
                </Box>
                <Box css={{ flexBasis: '33%' }}>
                  <TextField
                    variant="outlined"
                    value={queryVal}
                    onChange={(e) => form.changeQuery('from', index, 'value', e.target.value)}
                    placeholder="value"
                    autoComplete="off"
                    margin="dense"
                    fullWidth
                    multiline
                    disabled={['exists', 'empty'].includes(query.compare) || form.status === 'loading'}
                  />
                </Box>
                <Box css={{ flexBasis: '16px' }} />
                <Box css={{ flexBasis: '32%' }}>
                  {
                    inputs.query.compareOptions
                    && (
                      <Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">method</Typography>
                        </Box>
                        <Select
                          key={`from-query-${index}-compare`}
                          id={`from-query-${index}-compare`}
                          name={`from-query-${index}-compare`}
                          isClearable={false}
                          onChange={(e) => form.changeQuery('from', index, 'compare', e?.value)}
                          value={getCurrentOption(inputs.query.compareOptions, query.compare, !!query.value && 'equals')}
                          options={inputs.query.compareOptions}
                          disabled={form.status === 'loading'}
                        />
                      </Box>
                    )
                  }
                </Box>
                <Box css={{ flexBasis: 'auto' }}>
                  <IconButton
                    onClick={() => form.deleteQuery('from', index)}
                    title="Delete"
                    size="small"
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          );
        })
      }
    </Fragment>
  );
};

FromQueries.propTypes = {
  form: object,
  values: object,
};

export default FromQueries;
