import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { jssStyles } from 'shared/modules/authentication/components/styles';
import { ssoMfaRequest, ssoMfaVerify } from 'shared/modules/authentication/redux/actions';
import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import RaisedButton from 'shared/styleguide/atoms/Buttons/NewButton';
import Loading from 'shared/styleguide/atoms/Loading';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

export const SSOMFA = ({
  classes, ssoMfaRequest, ssoMfaVerify, request, verify,
}) => {
  const [mfaCode, setCode] = useState();

  useEffect(() => {
    ssoMfaRequest();
  }, [
    ssoMfaRequest,
  ]);

  const formErrors = {};

  if (!request.done) {
    return <Loading />;
  }

  if (verify.done) {
    document.location.href = '/';// yes I'm bypassing the router. I just want to be done.
  }

  return (
    <Box css={{ minWidth: 400, justifyContent: 'center' }} direction="column" justify="space-between" flex={1}>
      <Typography align="center" variant="body1">
        Set up Multi-Factor Authentication (MFA)
      </Typography>

      <form
        className={classes.formRoot}
        data-cy="loginForm"
        onSubmit={(event) => {
          event.preventDefault();
          ssoMfaVerify(mfaCode);
        }}
      >
        <Box>
          <Typography align="center" variant="body2">
            Scan this code with your Multi-factor authentication app
          </Typography>
          <img src={request.data.qrCode} alt="QR Code" />
          <br />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            id="mfaCode"
            placeholder="Verification Code"
            onChange={(e) => setCode(e.target.value)}
            error={Boolean(formErrors.code)}
            hintText={formErrors.code}
            disabled={request.isFetching}
            name="mfaCode"
          />
          <RaisedButton
            variant="contained"
            fullWidth
            style={{
              marginTop: 12,
            }}
            type="submit"
            label={verify.isFetching ? 'Just a moment...' : 'Verify'}
            disabled={!request.done || verify.doing}
          />
          <Box
            align="center"
            margin={{ top: 'small' }}
            css={{
              textAlign: 'center',
            }}
          >
            <Typography variant="body2" component="span">Trouble logging in? Please email <TextLink href="mailto:support@pagely.com">support@pagely.com</TextLink> for help.</Typography>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

SSOMFA.propTypes = {
  request: object,
  ssoMfaRequest: func,
  ssoMfaVerify: func,
  verify: object,
};

const stateMap = (state) => ({
  request: state.requests.auth.ssoMfaRequest,
  verify: state.requests.auth.ssoMfaVerify,
});
const dispatchMap = {
  ssoMfaRequest,
  ssoMfaVerify,
};

export const MfaWithStyles = withStyles(jssStyles)(SSOMFA);

export default connect(stateMap, dispatchMap)(MfaWithStyles);
