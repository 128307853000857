import apiReducer, {
  baseCollectionState,
} from 'shared/utils/redux/apiReducer';
import { CONSTANTS as PUSHER } from 'shared/3rdparty/pusher';
import * as APP_DNS from 'shared/modules/app/redux/dns/constants';
import * as STATUSJOB from './constants';
/**
 * Okay, so here's the deal.
 * If there is an active status job
 * it'll be in this reducer under its jobid
 */

const {
  appCreate, gitWebhookDeploy, gitIntegrationDeploy, diskReport, diskUpgradeVps, diskUpgradeHA, dnsValidation, webconfig, fsaSetup, fsaSetupCloudflare, fsaDisable,
} = STATUSJOB.jobTypes;

const jobTypesArray = Object.values(STATUSJOB.jobTypes);

export const initialState = {
  [gitWebhookDeploy]: { data: [], jobs: {} },
  [gitIntegrationDeploy]: { data: [], jobs: {} },
  [appCreate]: { data: [], jobs: {} },
  [diskReport]: { data: [], jobs: {} },
  [diskUpgradeVps]: { data: [], jobs: {} },
  [diskUpgradeHA]: { data: [], jobs: {} },
  [dnsValidation]: { data: [], jobs: {} },
  [webconfig]: { data: [], jobs: {} },
  [fsaSetup]: { data: [], jobs: {} },
  [fsaSetupCloudflare]: { data: [], jobs: {} },
  [fsaDisable]: { data: [], jobs: {} },
  notification: {},
};

const appCreateReducer = apiReducer(
  STATUSJOB.GET_APP_CREATE_JOBS_REQUEST,
  STATUSJOB.GET_APP_CREATE_JOBS_SUCCESS,
  STATUSJOB.GET_APP_CREATE_JOBS_FAILURE,
  STATUSJOB.GET_APP_CREATE_JOBS_RESET,
  baseCollectionState,
);

const gitWebhookDeployReducer = apiReducer(
  STATUSJOB.GET_GIT_WEBHOOK_DEPLOY_JOBS_REQUEST,
  STATUSJOB.GET_GIT_WEBHOOK_DEPLOY_JOBS_SUCCESS,
  STATUSJOB.GET_GIT_WEBHOOK_DEPLOY_JOBS_FAILURE,
  STATUSJOB.GET_GIT_WEBHOOK_DEPLOY_JOBS_RESET,
  baseCollectionState,
);

const gitIntegrationDeployReducer = apiReducer(
  STATUSJOB.GET_GIT_INTEGRATION_DEPLOY_JOBS_REQUEST,
  STATUSJOB.GET_GIT_INTEGRATION_DEPLOY_JOBS_SUCCESS,
  STATUSJOB.GET_GIT_INTEGRATION_DEPLOY_JOBS_FAILURE,
  STATUSJOB.GET_GIT_INTEGRATION_DEPLOY_JOBS_RESET,
  baseCollectionState,
);

const diskReportReducer = apiReducer(
  STATUSJOB.GET_DISK_REPORT_JOBS_REQUEST,
  STATUSJOB.GET_DISK_REPORT_JOBS_SUCCESS,
  STATUSJOB.GET_DISK_REPORT_JOBS_FAILURE,
  STATUSJOB.GET_DISK_REPORT_JOBS_RESET,
  baseCollectionState,
);

const diskUpgradeVpsReducer = apiReducer(
  STATUSJOB.GET_DISK_UPGRADE_VPS_JOBS_REQUEST,
  STATUSJOB.GET_DISK_UPGRADE_VPS_JOBS_SUCCESS,
  STATUSJOB.GET_DISK_UPGRADE_VPS_JOBS_FAILURE,
  STATUSJOB.GET_DISK_UPGRADE_JOBS_RESET,
  baseCollectionState,
);

const diskUpgradeHAReducer = apiReducer(
  STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_REQUEST,
  STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_SUCCESS,
  STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_FAILURE,
  STATUSJOB.GET_DISK_UPGRADE_JOBS_RESET,
  baseCollectionState,
);

const dnsValidationReducer = apiReducer(
  STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_REQUEST,
  STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_SUCCESS,
  STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_FAILURE,
  STATUSJOB.GET_DISK_UPGRADE_JOBS_RESET,
  baseCollectionState,
);

const webconfigReducer = apiReducer(
  STATUSJOB.GET_WEBCONFIG_JOBS_REQUEST,
  STATUSJOB.GET_WEBCONFIG_JOBS_SUCCESS,
  STATUSJOB.GET_WEBCONFIG_JOBS_FAILURE,
  STATUSJOB.GET_WEBCONFIG_JOBS_RESET,
  baseCollectionState,
);

const fsaSetupReducer = apiReducer(
  STATUSJOB.GET_FSA_SETUP_JOBS_REQUEST,
  STATUSJOB.GET_FSA_SETUP_JOBS_SUCCESS,
  STATUSJOB.GET_FSA_SETUP_JOBS_FAILURE,
  STATUSJOB.GET_FSA_SETUP_JOBS_RESET,
  baseCollectionState,
);

const fsaSetupCloudflareReducer = apiReducer(
  STATUSJOB.GET_FSA_SETUP_CLOUDFLARE_JOBS_REQUEST,
  STATUSJOB.GET_FSA_SETUP_CLOUDFLARE_JOBS_SUCCESS,
  STATUSJOB.GET_FSA_SETUP_CLOUDFLARE_JOBS_FAILURE,
  STATUSJOB.GET_FSA_SETUP_CLOUDFLARE_JOBS_RESET,
  baseCollectionState,
);

const fsaDisableReducer = apiReducer(
  STATUSJOB.GET_FSA_DISABLE_JOBS_REQUEST,
  STATUSJOB.GET_FSA_DISABLE_JOBS_SUCCESS,
  STATUSJOB.GET_FSA_DISABLE_JOBS_FAILURE,
  STATUSJOB.GET_FSA_DISABLE_JOBS_RESET,
  baseCollectionState,
);

const upsertJob = (state, action, jobType, isEntireRecord) => {
  const output = {};
  const job = action.data;
  if (state[jobType].jobs[job.id]) {
    // update existing data - pusher sends the current step/status so we don't want to wipe that
    const existingJob = state[jobType].jobs[job.id];
    if (isEntireRecord && existingJob.partial) {
      // remove the partial key that may have been added by pusher reducer
      delete (existingJob.partial);
    }
    output[job.id] = {
      ...existingJob,
      ...job,
    };
  } else {
    // new job
    output[job.id] = job;
  }

  return {
    items: output,
    ownerId: job.ownerId,
  };
};

const upsertJobs = (state, action, jobType, isEntireRecord) => {
  const output = {};
  let ownerId;
  action.data.data.forEach((job) => {
    if (state[jobType].jobs[job.id]) {
      // update existing data - pusher sends the current step/status so we don't want to wipe that
      const existingJob = { ...state[jobType].jobs[job.id] };
      if (isEntireRecord && existingJob.partial) {
        // remove the partial key that may have been added by pusher reducer
        delete (existingJob.partial);
      }

      output[job.id] = {
        ...existingJob,
        ...job,
      };
    } else {
      // new job
      output[job.id] = job;
    }

    if (!ownerId) {
      ownerId = job.ownerId;
    }
  });

  return {
    items: output,
    ownerId,
  };
};

const statusSuccess = (state, action, jobType, reducer = null, isEntireRecord = true) => {
  let jobs;
  if (action.data.data) {
    // collection
    jobs = upsertJobs(state, action, jobType, isEntireRecord);
  } else {
    // not collection
    jobs = upsertJob(state, action, jobType, isEntireRecord);
  }

  const oldJobs = state[jobType].jobs;
  const currentJobs = Object.values(oldJobs)?.find((j) => j.ownerId === jobs.ownerId)
    ? { ...oldJobs }
    : {}; // remove jobs from other owner

  const prevJobsState = reducer ? reducer(state[jobType], action) : state[jobType];

  const newState = structuredClone(state);
  newState[jobType] = {
    ...prevJobsState,
    jobs: {
      ...currentJobs,
      ...jobs.items,
    },
  };
  return newState;
};

const statusApiCall = (state, action, jobType, reducer) => {
  const newState = structuredClone(state);
  newState[jobType] = {
    ...state[jobType],
    ...reducer(state[jobType], action),
  };
  return newState;
};

const statusApiReset = (state, action, jobType, reducer) => {
  const newState = structuredClone(state);
  newState[jobType] = {
    ...state[jobType],
    data: [],
    jobs: {},
    ...reducer(state[jobType], action),
  };
  return newState;
};

export const status = (state = initialState, action) => {
  const {
    appCreate, gitWebhookDeploy, gitIntegrationDeploy, diskReport, diskUpgradeVps, diskUpgradeHA, dnsValidation, webconfig, fsaSetup, fsaSetupCloudflare, fsaDisable,
  } = STATUSJOB.jobTypes;
  switch (action.type) {
    // app create
    case STATUSJOB.GET_APP_CREATE_JOBS_REQUEST:
    case STATUSJOB.GET_APP_CREATE_JOBS_FAILURE:
      return statusApiCall(state, action, appCreate, appCreateReducer);
    case STATUSJOB.GET_APP_CREATE_JOBS_SUCCESS:
      return statusSuccess(state, action, appCreate, appCreateReducer);
    // git webhook
    case STATUSJOB.GET_GIT_WEBHOOK_DEPLOY_JOBS_REQUEST:
    case STATUSJOB.GET_GIT_WEBHOOK_DEPLOY_JOBS_FAILURE:
      return statusApiCall(state, action, gitWebhookDeploy, gitWebhookDeployReducer);
    case STATUSJOB.GET_GIT_WEBHOOK_DEPLOY_JOBS_SUCCESS:
      return statusSuccess(state, action, gitWebhookDeploy, gitWebhookDeployReducer);
    case STATUSJOB.GET_GIT_WEBHOOK_DEPLOY_JOBS_RESET:
      return statusApiReset(state, action, gitWebhookDeploy, gitWebhookDeployReducer);
    // git integration
    case STATUSJOB.GET_GIT_INTEGRATION_DEPLOY_JOBS_REQUEST:
    case STATUSJOB.GET_GIT_INTEGRATION_DEPLOY_JOBS_FAILURE:
      return statusApiCall(state, action, gitIntegrationDeploy, gitIntegrationDeployReducer);
    case STATUSJOB.GET_GIT_INTEGRATION_DEPLOY_JOBS_SUCCESS:
      return statusSuccess(state, action, gitIntegrationDeploy, gitIntegrationDeployReducer);
    case STATUSJOB.GET_GIT_INTEGRATION_DEPLOY_JOBS_RESET:
      return statusApiReset(state, action, gitIntegrationDeploy, gitIntegrationDeployReducer);
    // disk report
    case STATUSJOB.GET_DISK_REPORT_JOBS_REQUEST:
    case STATUSJOB.GET_DISK_REPORT_JOBS_FAILURE:
      return statusApiCall(state, action, diskReport, diskReportReducer);
    case STATUSJOB.GET_DISK_REPORT_JOBS_SUCCESS:
      return statusSuccess(state, action, diskReport, diskReportReducer);
    // disk upgrade
    case STATUSJOB.GET_DISK_UPGRADE_VPS_JOBS_REQUEST:
    case STATUSJOB.GET_DISK_UPGRADE_VPS_JOBS_FAILURE:
      return statusApiCall(state, action, diskUpgradeVps, diskUpgradeVpsReducer);
    case STATUSJOB.GET_DISK_UPGRADE_VPS_JOBS_SUCCESS:
      return statusSuccess(state, action, diskUpgradeVps, diskUpgradeVpsReducer);
    // disk report HA
    case STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_REQUEST:
    case STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_FAILURE:
      return statusApiCall(state, action, diskUpgradeHA, diskUpgradeHAReducer);
    case STATUSJOB.GET_DISK_UPGRADE_HA_JOBS_SUCCESS:
      return statusSuccess(state, action, diskUpgradeHA, diskUpgradeHAReducer);
    // disk upgrade reset
    case STATUSJOB.GET_DISK_UPGRADE_JOBS_RESET:
      return (
        statusApiReset(state, action, diskUpgradeVps, diskUpgradeVpsReducer),
        statusApiReset(state, action, diskUpgradeHA, diskUpgradeHAReducer)
      );
    // DNS validation
    case STATUSJOB.GET_DNS_VALIDATION_JOBS_REQUEST:
    case STATUSJOB.GET_DNS_VALIDATION_JOBS_FAILURE:
      return statusApiCall(state, action, dnsValidation, dnsValidationReducer);
    case STATUSJOB.GET_DNS_VALIDATION_JOBS_SUCCESS:
      return statusSuccess(state, action, dnsValidation, dnsValidationReducer);
    case APP_DNS.TRIGGER_VALIDATION_SUCCESS:
      return statusSuccess(state, action, dnsValidation, dnsValidationReducer);
    // webconfig
    case STATUSJOB.GET_WEBCONFIG_JOBS_REQUEST:
    case STATUSJOB.GET_WEBCONFIG_JOBS_FAILURE:
      return statusApiCall(state, action, webconfig, webconfigReducer);
    case STATUSJOB.GET_WEBCONFIG_JOBS_SUCCESS:
      return statusSuccess(state, action, webconfig, webconfigReducer);
    // fsa setup
    case STATUSJOB.GET_FSA_SETUP_JOBS_REQUEST:
    case STATUSJOB.GET_FSA_SETUP_JOBS_FAILURE:
      return statusApiCall(state, action, fsaSetup, fsaSetupReducer);
    case STATUSJOB.GET_FSA_SETUP_JOBS_SUCCESS:
      return statusSuccess(state, action, fsaSetup, fsaSetupReducer);
    // fsa setup cloudflare
    case STATUSJOB.GET_FSA_SETUP_CLOUDFLARE_JOBS_REQUEST:
    case STATUSJOB.GET_FSA_SETUP_CLOUDFLARE_JOBS_FAILURE:
      return statusApiCall(state, action, fsaSetupCloudflare, fsaSetupCloudflareReducer);
    case STATUSJOB.GET_FSA_SETUP_CLOUDFLARE_JOBS_SUCCESS:
      return statusSuccess(state, action, fsaSetupCloudflare, fsaSetupCloudflareReducer);
    // fsa disable
    case STATUSJOB.GET_FSA_DISABLE_JOBS_REQUEST:
    case STATUSJOB.GET_FSA_DISABLE_JOBS_FAILURE:
      return statusApiCall(state, action, fsaDisable, fsaDisableReducer);
    case STATUSJOB.GET_FSA_DISABLE_JOBS_SUCCESS:
      return statusSuccess(state, action, fsaDisable, fsaDisableReducer);
    // general job
    case STATUSJOB.GET_JOB_STATUS_SUCCESS: {
      return statusSuccess(state, action, action.data.type);
    }
    // notification reset
    case STATUSJOB.JOB_NOTIFICATION_RESET: {
      return {
        ...state,
        notification: {},
      };
    }
    case PUSHER.STATUS_UPDATE: {
      if (!jobTypesArray.includes(action.data.type) || action.data.stepStatus === STATUSJOB.NA) {
        return state;
      }

      const { data } = action;
      // eslint-disable-next-line
      if (!data.hasOwnProperty('detail') || !data.hasOwnProperty('steps')) {
        // we will likely want to refetch the status job, since some data is missing
        // becuse we had to trim the pusher payload to avoid delivery errors
        data.partial = true;
      }

      const newState = structuredClone(state);
      const existingJobs = newState[data.type].jobs;

      // does a job for the event already exist?
      if (existingJobs[data.id]) {
        // just update the job
        existingJobs[data.id] = {
          ...existingJobs[data.id],
          ...data,
          timestamp: +new Date(),
        };

        if (!data.steps) {
          const currentStep = existingJobs[data.id].steps.find((s) => s.id === data.step);

          if (currentStep) {
            currentStep.status = data.stepStatus;
          } else {
            // no... then append the new step
            existingJobs[data.id].steps.push({
              id: data.step,
              label: data.step,
              status: data.stepStatus,
            });
          }
        }
      } else {
        const newJob = {
          timestamp: +new Date(),
          started: +new Date(),
          // create initial step
          steps: [{
            id: data.step,
            label: data.step,
            status: data.stepStatus,
          }],
          ...data, // will overwrite steps if they exist in data (that's ok)
        };

        // create new job
        newState[data.type] = {
          ...newState[data.type],
          jobs: {
            ...newState[data.type].jobs,
            [data.id]: newJob,
          },
        };
      }

      // notification
      const notificationJob = jobTypesArray.includes(data.type) && [STATUSJOB.SUCCESS, STATUSJOB.FAILURE, STATUSJOB.ACTION_REQUIRED].includes(data.overallStatus);

      if (notificationJob) {
        newState.notification = {
          timestamp: +new Date(),
          ...data,
        };
      }

      return newState;
    }

    default:
      return state;
  }
};
