import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {
  Divider,
  TableCell,
  TableRow,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { remMapper } from 'shared/styleguide/theme/spacing';

const ExpansionTableCell = styled(TableCell)`
  padding: 0;
  font-size: .9rem;
  border-bottom: none;
`;

const ListRow = ({ row, Detail }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow css={{ height: remMapper('xxlarge') }}>
        {
          row.display.map((cell, i) => {
            return <TableCell key={`rowcell-${i}`} align="left">{cell}</TableCell>;
          })
        }
        <TableCell align="right" css={{ width: 34 }}>
          {
            Detail && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )
          }
        </TableCell>
      </TableRow>
      {
        Detail && (
          <TableRow>
            <ExpansionTableCell colSpan={row.display.length + 1}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Detail data={row.data} />
                <Divider />
              </Collapse>
            </ExpansionTableCell>
          </TableRow>
        )
      }
    </Fragment>
  );
};

ListRow.propTypes = {
  Detail: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  item: PropTypes.object,
  row: PropTypes.object,
};

export default ListRow;
