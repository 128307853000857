import React from 'react';
import { object, bool } from 'prop-types';
import { Paper, Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import SupportLink from 'shared/modules/support/Link';
import PermissionsContext from 'shared/modules/permissions/context/PermissionsContext';
import { FeatureToggle } from 'shared/flags';
import Banner from 'shared/styleguide/molecules/Banner';
import ChatCallout from './ChatCallout';
import AdminDelete from './AdminDelete';

const CancelAccount = ({ account, isAdmin, user }) => {
  const hasChargebee = account.billingSouce === 'chargebee' && account.billingCustomerId;
  const isAws = account.awsCustomerId && (account.awsCustomerId !== null);

  return (
    <Paper>
      <Box padding="medium" direction="column">
        <Typography variant="h4" gutterBottom color="error">Cancel Account</Typography>
        {
          !isAdmin && (
            <ChatCallout account={account} user={user} />
          )
        }
        {
          isAws && (
            <Box margin={{ bottom: 'small' }}>
              <Banner
                heading="AWS Customer"
                subheading={(
                  <span>
                    Any changes to your subscription plan must go through the AWS platform. <strong>Your subscription cannot be canceled here</strong>
                  </span>
                )}
                type="warning"
              />
            </Box>
          )
        }
        {
          isAdmin && (
            <PermissionsContext.Consumer>
              {
                (permissions) => ((permissions.canAccess.length && account.active) ? (
                  <FeatureToggle
                    flags={[hasChargebee]}
                    fallback={(
                      <AdminDelete />
                    )}
                  >
                    <Box>
                      <Typography variant="h4" color="secondary" gutterBottom>
                        <strong>
                          NOTE: Customer Account Cancellations must be done through Chargebee.
                        </strong>
                      </Typography>
                      <Typography gutterBottom variant="body1">What happens when an account is cancelled?</Typography>
                      <Typography variant="body2" color="textSecondary" paragraph gutterBottom>
                        The Customer understands that we are cancelling their Pagely account, and they understand all <strong>their site data will be deleted</strong> from this service and they have <strong>downloaded all relevant data backups.</strong>
                      </Typography>
                      <Typography variant="body2" color="textSecondary" paragraph gutterBottom>
                        The Admin understands that the billing subscription will be closed <strong>immmediately</strong>, all customer data will be deleted in <strong>30 days</strong> and <strong>this is not reversible.</strong>
                      </Typography>
                    </Box>
                  </FeatureToggle>
                ) : (
                  <Typography variant="body1" color="error">This account is slated to be deleted</Typography>
                ))
              }
            </PermissionsContext.Consumer>
          )
        }
        {
          !isAdmin && (
            <Typography variant="body1">
              For security reasons, your account must be canceled manually - <br /><SupportLink>Please open a support ticket to close your account</SupportLink>
            </Typography>
          )
        }
      </Box>
    </Paper>
  );
};

CancelAccount.propTypes = {
  account: object,
  isAdmin: bool,
  user: object,
};

export default CancelAccount;
