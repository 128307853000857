/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  FormControlLabel, TextField, Radio, RadioGroup, Grid, Typography, Switch,
} from '@material-ui/core';
import { css } from '@emotion/react';
import CreatableSelect from 'react-select/creatable';

import { isString } from 'shared/utils/types';
import Box from 'shared/styleguide/atoms/Box';
import { themeProps } from 'shared/styleguide/atoms/Select/Select';
import FormLabel from 'shared/styleguide/molecules/RadioInput';
import InfoText from 'shared/styleguide/molecules/InfoText';

const defaultMethodOptions = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
  { label: 'PUT', value: 'PUT' },
  { label: 'PATCH', value: 'PATCH' },
  { label: 'DELETE', value: 'DELETE' },
];
export const defaultExtensionsOptions = [
  { label: 'js', value: 'js' },
  { label: 'css', value: 'css' },
];

const areValuesStrings = (x) => {
  return x.every((i) => (typeof i === 'string'));
};

const SingleCreateableSelect = (key, options, theme) => ({
  onChange, value, enabled,
}) => {
  let selectedValues = value?.control || value?.[key] || [];

  if (selectedValues && areValuesStrings(selectedValues)) {
    selectedValues = selectedValues.map((string) => ({
      value: string,
      label: string,
    }));
  }
  return (
    <Box direction="row">
      <CreatableSelect
        css={{ minWidth: 300 }}
        isMulti
        closeMenuOnSelect={false}
        {...themeProps(theme)}
        placeholder="Type or Select..."
        onChange={(newValue, actions) => {
          let sendableValues;
          if (actions.action === 'remove-value') {
            const currentValues = newValue || [];
            sendableValues = currentValues.filter((v) => v !== actions.removedValue?.value).map((v) => v.value);
          } else {
            sendableValues = newValue.map((v) => v.value);
          }
          onChange({ [key]: sendableValues, control: newValue });
        }}
        isClearable
        isDisabled={!enabled}
        value={selectedValues}
        options={options}
        role="combobox"
      />
    </Box>
  );
};

export const validHeaders = [
  'Access-Control-Allow-Origin',
  'Access-Control-Allow-Credentials',
  'Access-Control-Max-Age',
  'Access-Control-Allow-Methods',
  'Access-Control-Allow-Headers',
  'Access-Control-Expose-Headers',
  'Content-Security-Policy',
];

const commonHeaders = [
  'A-IM',
  'Accept',
  'Accept-Charset',
  'Accept-Datetime',
  'Accept-Encoding',
  'Accept-Language',
  'Access-Control-Request-Headers',
  'Access-Control-Request-Method',
  'Authorization',
  'Cache-Control',
  'Connection',
  'Content-Encoding',
  'Content-Length',
  'Content-MD5',
  'Content-Type',
  'Cookie',
  'Date',
  'DNT',
  'Expect',
  'Forwarded',
  'From',
  'Front-End-Https',
  'Host',
  'HTTP2-Settings',
  'If-Match',
  'If-Modified-Since',
  'If-None-Match',
  'If-Range',
  'If-Unmodified-Since',
  'Max-Forwards',
  'Origin',
  'Pragma',
  'Proxy-Authorization',
  'Proxy-Connection',
  'Range',
  'Referer',
  'Save-Data',
  'TE',
  'Trailer',
  'Transfer-Encoding',
  'Upgrade',
  'Upgrade-Insecure-Requests',
  'User-Agent',
  'Via',
  'Warning',
  'X-ATT-DeviceId',
  'X-Correlation-ID',
  'X-Csrf-Token',
  'X-Forwarded-For',
  'X-Forwarded-Host',
  'X-Forwarded-Proto',
  'X-Http-Method-Override',
  'X-Request-ID',
  'X-Requested-With',
  'X-UIDH',
  'X-Wap-Profile',
];

const defaultHeadersOptions = commonHeaders.map((h) => {
  return { label: h, value: h };
});

export const getHeaders = (theme) => [
  {
    label: 'Access-Control-Allow-Origin',
    value: 'Access-Control-Allow-Origin',
    Options: ({
      onChange, value, enabled, domainsList,
    }) => {
      const key = 'allow-origin';
      let selectedValues = value?.control || value?.[key] || [];

      if (!value?.control && Array.isArray(value?.[key])) {
        selectedValues = selectedValues.map((string) => ({
          value: string,
          label: string,
        }));
        onChange({ control: 'domainList', domains: selectedValues });
      }

      return (
        <Box>
          <RadioGroup
            value={isString(selectedValues) ? selectedValues : ''}
            onChange={(e) => {
              if (e.target.value === 'domainList') {
                onChange({ [key]: (value?.domains || []).map((v) => v.value), control: e.target.value });
                return;
              }
              onChange({ [key]: e.target.value, control: e.target.value });
            }}
          >
            <FormControlLabel
              labelPlacement="end"
              value="*"
              control={(
                <Radio disabled={!enabled} />
              )}
              label="* (wildcard)"
            />
            <FormControlLabel
              labelPlacement="end"
              value="$self"
              control={(
                <Radio disabled={!enabled} />
              )}
              label="Self"
            />
            <span>
              <FormControlLabel
                value="domainList"
                labelPlacement="end"
                control={<Radio disabled={!enabled} />}
                css={{ marginRight: 0 }}
              />
              <span css={{ display: 'inline-block' }}>
                <CreatableSelect
                  css={{ minWidth: 300 }}
                  {...themeProps(theme)}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={(newValue, actions) => {
                    let sendableValues;
                    if (actions.action === 'remove-value') {
                      const currentValues = newValue || [];
                      sendableValues = currentValues.filter((v) => v !== actions.removedValue?.value).map((v) => v.value);
                    } else {
                      sendableValues = newValue.map((v) => v.value);
                    }
                    onChange({ [key]: sendableValues, domains: newValue });
                  }}
                  isClearable
                  isDisabled={!enabled}
                  value={value?.domains}
                  options={domainsList}
                  placeholder="Select Domains"
                />
              </span>

            </span>
          </RadioGroup>
        </Box>
      );
    },
  },
  {
    label: 'Access-Control-Allow-Credentials',
    value: 'Access-Control-Allow-Credentials',
    Options: () => null,
  },
  {
    label: 'Access-Control-Max-Age',
    value: 'Access-Control-Max-Age',
    Options: ({ onChange, value, enabled }) => {
      const key = 'max-age';
      return (
        <Box direction="row">
          <TextField
            id="maxAge"
            name="maxAge"
            autoComplete="off"
            placeholder="Max Age"
            type="number"
            onChange={(e) => onChange({ [key]: parseInt(e.target.value, 10), control: e.target.value })}
            value={value?.[key] || ''}
            variant="outlined"
            margin="dense"
            disabled={!enabled}
          />
        </Box>
      );
    },
  },
  {
    label: 'Access-Control-Allow-Methods',
    value: 'Access-Control-Allow-Methods',
    Options: SingleCreateableSelect('allow-methods', defaultMethodOptions, theme),
  },
  {
    label: 'Access-Control-Allow-Headers',
    value: 'Access-Control-Allow-Headers',
    Options: SingleCreateableSelect('allow-headers', defaultHeadersOptions, theme),
  },
  {
    label: 'Access-Control-Expose-Headers',
    value: 'Access-Control-Expose-Headers',
    Options: SingleCreateableSelect('expose-headers', defaultHeadersOptions, theme),
  },
  {
    label: 'Content-Security-Policy',
    value: 'Content-Security-Policy',
    Options: ({ onChange, value, enabled }) => {
      const key = 'value';

      return (
        <Box direction="row">
          <Grid container spacing={4}>
            <Grid item sm={12} md={7}>
              <Box direction="column" flex={1}>
                <Typography gutterBottom variant="caption" color="textSecondary">Value</Typography>
                <TextField
                  id="csp"
                  name="csp"
                  aria-label="Content Security Policy Text Field"
                  autoComplete="off"
                  type="number"
                  onChange={(e) => onChange({ [key]: e.target.value })}
                  value={value?.[key] || ''}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  multiline
                  minRows={6}
                  disabled={!enabled}
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={5}>
              <Box direction="column">
                <Box padding={{ top: 'small', bottom: 'small' }}>
                  <FormLabel
                    labelPlacement="end"
                    control={(
                      <Switch
                        checked={Boolean(value?.['report-only'])}
                        disabled={!enabled}
                        onChange={(e) => onChange({ 'report-only': e.target.checked })}
                      />
                    )}
                  >
                    <Box>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        css={css`
                      line-height: 30px;
                      padding-top: 5px;
                    `}
                      >
                        Report Only
                      </Typography>
                    </Box>
                  </FormLabel>
                </Box>
                <Typography variant="caption" color="textSecondary" gutterBottom>Recipes</Typography>
                <InfoText
                  href="https://support.pagely.com/hc/en-us/articles/360052341472-Understanding-Content-Security-Policy-Headers"
                >
                  Understanding Content Security Policy Headers
                </InfoText>
              </Box>
            </Grid>
          </Grid>
        </Box>
      );
    },
  },

];
