import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@material-ui/core';
import React from 'react';
import { getAvatarURL, getInitials } from 'shared/utils';

interface AvatarProps extends MuiAvatarProps {
  /** Used to generate fallback initials */
  fullName: string;
  /** Used to generate gravatar img */
  email?: string;
  /** Specify gravatar size. See `s` param here: https://en.gravatar.com/site/implement/images/ */
  gravatarSize?: number;
}

/**
 * @returns An avatar which displays a users Gravatar via their `email` or
 * uses their `fullName` to display their initials. The `gravatarSize` may also be specified.
 *
 * Gravatar Docs: https://en.gravatar.com/site/implement/images/
 */
const Avatar: React.FC<AvatarProps> = ({
  fullName, email, gravatarSize, children, ...props
}) => {
  const src = email ? getAvatarURL({ email, s: gravatarSize }) : null;
  return (
    <MuiAvatar src={src} {...props}>{getInitials(fullName) || children}</MuiAvatar>
  );
};

export default Avatar;
