import apiReducer, {
  baseCollectionState,
  baseState,
  load,
  success,
  fail,
} from 'shared/utils/redux/apiReducer';
import * as BILLING from './constants';

export const invoices = apiReducer(
  BILLING.GET_INVOICES_REQUEST,
  BILLING.GET_INVOICES_SUCCESS,
  BILLING.GET_INVOICES_FAILURE,
  BILLING.RESET,
  baseCollectionState,
);

export const plans = apiReducer(
  BILLING.BILLING_PLANS_REQUEST,
  BILLING.BILLING_PLANS_SUCCESS,
  BILLING.BILLING_PLANS_FAILURE,
  BILLING.RESET,
  baseCollectionState,
);

export const customer = (state = baseState, action) => {
  switch (action.type) {
    case BILLING.GET_CUSTOMER_REQUEST:
      return load(state, action);
    case BILLING.UPDATE_CUSTOMER_FAILURE:
    case BILLING.GET_CUSTOMER_FAILURE:
      return fail(state, action);
    case BILLING.GET_CUSTOMER_SUCCESS:
      return success(state, action);
    case BILLING.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...success(state, action),
        data: {
          ...state.data,
          ...action.params.data,
        },
        params: {
          customerId: action.data.customerId,
        },
      };
    case BILLING.UPDATE_PAYMENT_SUCCESS:
      return {
        ...success(state, action),
        data: {
          ...state.data,
          card: action.data,
        },
      };
    default:
      return state;
  }
};

export const subscriptions = (state = baseCollectionState, action) => {
  switch (action.type) {
    case BILLING.GET_SUBSCRIPTIONS_REQUEST:
      return load(state, action);
    case BILLING.GET_SUBSCRIPTIONS_FAILURE:
      return fail(state, action);
    case BILLING.GET_SUBSCRIPTIONS_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};
