import { getHttp } from 'shared/http';
import makeApiAction from 'shared/utils/redux/apiActions';

import * as TFA from './constants';

export const twoFactorSetupRequest = (accountId, phone = null) => makeApiAction(
  () => {
    let url = `/accounts/${accountId}/2fa`;
    if (phone) {
      url += `?phone=${phone.replace('+', '%2B')}`;
    }
    return getHttp().get(url);
  },
  TFA.TWO_FACTOR_SETUP_REQUEST_REQUEST,
  TFA.TWO_FACTOR_SETUP_REQUEST_SUCCESS,
  TFA.TWO_FACTOR_SETUP_REQUEST_FAILURE,
  {
    accountId,
    phone,
  },
  true,
);

export const twoFactorVerificationRequest = (accountId, update) => makeApiAction(
  () => getHttp().post(`/accounts/${accountId}/2fa`, update),
  TFA.TWO_FACTOR_VERIFY_REQUEST_REQUEST,
  TFA.TWO_FACTOR_VERIFY_REQUEST_SUCCESS,
  TFA.TWO_FACTOR_VERIFY_REQUEST_FAILURE,
  {},
  true,
);

export const twoFactorSetupRequestForAdmin = (adminId, phone) => makeApiAction(
  () => getHttp().get(`/accounts/admins/${adminId}/2fa?phone=${phone}`),
  TFA.TWO_FACTOR_SETUP_REQUEST_REQUEST,
  TFA.TWO_FACTOR_SETUP_REQUEST_SUCCESS,
  TFA.TWO_FACTOR_SETUP_REQUEST_FAILURE,
);

export const twoFactorVerificationRequestForAdmin = (adminId, update) => makeApiAction(
  () => getHttp().post(`/accounts/admins/${adminId}/2fa`, update),
  TFA.TWO_FACTOR_VERIFY_REQUEST_REQUEST,
  TFA.TWO_FACTOR_VERIFY_REQUEST_SUCCESS,
  TFA.TWO_FACTOR_VERIFY_REQUEST_FAILURE,
);

export const twoFactorVerificationReset = () => ({
  type: TFA.TWO_FACTOR_VERIFY_REQUEST_RESET,
});

export const twoFactorDisableRequest = (accountId, code) => makeApiAction(
  () => getHttp().delete(`/accounts/${accountId}/2fa/${code}`),
  TFA.TWO_FACTOR_DISABLE_REQUEST_REQUEST,
  TFA.TWO_FACTOR_DISABLE_REQUEST_SUCCESS,
  TFA.TWO_FACTOR_DISABLE_REQUEST_FAILURE,
  {},
  true,
);

export const twoFactorDisableReset = () => ({
  type: TFA.TWO_FACTOR_DISABLE_REQUEST_RESET,
});
