import apiReducer, { baseCollectionState } from 'shared/utils/redux/apiReducer';

import * as CONFIGS from './constants';

export const configs = apiReducer(
  CONFIGS.FETCH_CONFIGS_REQUEST,
  CONFIGS.FETCH_CONFIGS_SUCCESS,
  CONFIGS.FETCH_CONFIGS_FAILURE,
  CONFIGS.FETCH_CONFIGS_RESET,
  { ...baseCollectionState },
);
