import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';

import { Account } from 'shared/modules/account/types';
import { getDailyStats as getStats } from 'shared/modules/analytics/redux/actions';
import BandwidthIcon from 'shared/styleguide/Icons/SVGs/Bandwidth';
import Box from 'shared/styleguide/atoms/Box';
import ProgressView from 'shared/styleguide/molecules/ProgressView';

import { PERCENT_THRESHOLD } from '../../constants';

const start = moment().subtract(30, 'days').startOf('day').toISOString();
const end = moment().endOf('day').toISOString();

interface BandwidthStatsProps {
  account: Account;
  getDailyStats: (account: number, startDate: string, endDate: string) => void;
  stats;
}

const BandwidthStats = ({
  account,
  getDailyStats,
  stats,
}: BandwidthStatsProps) => {
  // load CDN Stats
  useEffect(() => {
    if (stats.params.accountId && stats.params.accountId !== account.id) {
      getDailyStats(account.id, start, end);
    }
  }, [account, stats, getDailyStats]);

  const bandwidthUsage = useMemo(() => {
    let bandwidth = 0;
    stats?.data?.forEach((day) => {
      bandwidth += day.bandwidth;
    });
    return bandwidth;
  }, [account.id, stats.params.id, stats.data.length]);

  const { maxBandwidth } = account.limits;
  const bandwidthPercent = (bandwidthUsage / maxBandwidth) * 100;

  return (
    <Box flex={1}>
      <ProgressView
        {...{
          heading: 'Bandwidth (last 30 days)',
          icon: <BandwidthIcon />,
          value1: `${bandwidthUsage.toFixed(bandwidthUsage > 1000 ? 0 : 2)}`,
          value2: `${maxBandwidth} GB`,
          percentage: bandwidthPercent,
          threshold: PERCENT_THRESHOLD,
          errorText: 'Upgrade Soon',
        }}
      />
    </Box>
  );
};

export default connect(
  (state: { account; analytics }) => ({
    account: state.account,
    stats: state.analytics.daily,
  }),
  {
    getDailyStats: getStats,
  },
)(BandwidthStats);
