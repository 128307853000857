import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import { func, any } from 'prop-types';

const RadioButtonGroup = ({ valueSelected, ...props }) => {
  return (
    <RadioGroup
      {...props}
      value={valueSelected}
      onChange={(e) => props.onChange(e, e.target.value)}
    >
      {props.children}
    </RadioGroup>
  );
};

RadioButtonGroup.propTypes = {
  onChange: func.isRequired,
  valueSelected: any,
};

export default RadioButtonGroup;
