import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import Card from 'shared/styleguide/atoms/Card/Card';
import Box from 'shared/styleguide/atoms/Box';
import type { UserPool } from 'shared/modules/account/redux/sso/types';
import type { Account } from 'shared/modules/account/types';
import type { NumberLike } from 'shared/types/types';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import {
  createUserPool, updateStatus, finishSamlSetup,
  deleteIdentityProvider, deleteUserPool,
} from 'shared/modules/team/redux/sso/actions';
import { updateAccount as updateAccountAction, fetchAccountTags, fetchUserPool } from 'shared/modules/account/redux/actions';
import { Tag as AccountTag } from 'shared/modules/metadata/types';
import { STATUS_OPTIONS, StatusKeys } from './constants';
import SetupForm from './SetupForm';

interface Props {
  isPagelyAdmin: boolean;
  account: Account;
  userPool: UserPool | null;
  status: StatusKeys;
  accountTags: any;
  updateStatus: (status: StatusKeys) => void;
  createUserPool: (ownerUserId: NumberLike, companyName: string) => Promise<UserPool>;
  finishSamlSetup: (userPoolId: string, samlUrl: string, samlName: string) => Promise<UserPool>;
  fetchUserPool: (accountID: number, userType?: string) => Promise<UserPool>;
  updateAccount: (accountId: number, options?: Partial<Account>) => Promise<Account>;
  fetchAccountTags: (accountId: number) => Promise<{ data: AccountTag[] }>;
  deleteIdentityProvider: (poolId: string, idpName: string) => Promise<null>;
  deleteUserPool: (poolId: string) => Promise<null>;
}

export const SsoSettings: React.FC<Props> = (props) => {
  const {
    isPagelyAdmin, account, userPool, accountTags,
    fetchUserPool: fetchPool, fetchAccountTags: fetchTags, updateAccount,
  } = props;

  useEffect(() => {
    if (!userPool || userPool.ownerUserId !== account.id) {
      fetchPool(account.id);
    }

    // We are disabling the next line for exhaustive dependencies because we don't
    //  want this to re-run every time we change the userPool, we only want this to
    //  run when the page first loads.
    // But we could have looked up the userPool already by this point because
    //  the root <Teams /> page also looks it up.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, fetchPool]);

  useEffect(() => {
    if (accountTags.status === 'pristine' || accountTags.params.accountId !== account.id) {
      fetchTags(account.id);
    }
  }, [fetchTags, accountTags, account]);

  const statusOptions = STATUS_OPTIONS[props.status];

  return (
    <Box as={Card} gap="small">
      <Box direction="row" justify="space-between" fullWidth>
        <Typography variant="h3" gutterBottom>Single Sign On Setup</Typography>
        <GhostTag color={statusOptions.color}>{statusOptions.text}</GhostTag>
      </Box>
      <SetupForm
        account={account}
        updateAccount={updateAccount}
        userPool={props.userPool}
        status={props.status}
        createUserPool={props.createUserPool}
        updateStatus={props.updateStatus}
        finishSamlSetup={props.finishSamlSetup}
        deleteIdentityProvider={props.deleteIdentityProvider}
        deleteUserPool={props.deleteUserPool}
      />
    </Box>
  );
};

const mapStateToProps = (state: Record<string, any>) => ({
  account: state.account,
  userPool: state.sso.userPool,
  status: state.sso.status ?? 'unknown',
  isPagelyAdmin: state.user.user.adminAccount,
  accountTags: state.tags.account,
});

const mapDispatchToProps = {
  createUserPool,
  updateStatus,
  updateAccount: updateAccountAction,
  finishSamlSetup,
  fetchUserPool,
  fetchAccountTags,
  deleteUserPool,
  deleteIdentityProvider,
};

export default connect(mapStateToProps, mapDispatchToProps)(SsoSettings);
