import styled from '@emotion/styled';
import Box from 'shared/styleguide/atoms/Box';

import { formatMiB, formatDate } from 'shared/utils';
import {
  HUNDRED, THOUSAND, MILLION,
} from 'shared/utils/constants';

import { GRAPH_TYPES } from './constants';

export const StatsBox = styled(Box)`
  height: 250px;
  minWidth: 300px;
`;

export const createGraphConfig = (graphType, metadata) => {
  const meta = metadata[graphType];
  const axisLeft = {
    enable: true,
    tickValues: 5,
    legendOffset: -40,
    legendPosition: 'middle',
  };

  if (graphType === GRAPH_TYPES.requests) {
    axisLeft.format = (value) => {
      if (value > (MILLION - 1)) {
        return `${Math.round(value / MILLION)}M`;
      }
      return value;
    };
  }

  if (graphType === GRAPH_TYPES.cdn || graphType === GRAPH_TYPES.disk) {
    axisLeft.format = (value) => {
      if (meta.maxValue > (THOUSAND - 1)) {
        return `${(value / THOUSAND).toFixed(1)}`;
      }
      return value;
    };

    axisLeft.legend = formatMiB(meta.maxValue);
  }

  const config = {
    margin: {
      top: 20,
      right: 25,
      bottom: 40,
      left: 55,
    },
    colors: [
      'var(--color-accent)',
    ],
    xScale: {
      type: 'time',
    },
    yScale: {
      type: 'linear',
      stacked: false,
      min: 0,
      max: meta.forceYAxis ? HUNDRED : 'auto',
    },
    axisBottom: {
      enable: true,
      tickValues: 'every 7 days',
      format: (value) => {
        // Tue Aug 28 2018 17:00:00 GMT-0700 (MST)
        return formatDate(new Date(value), {
          month: 'short',
          day: 'numeric',
        });
      },
      tickRotation: '-45',
    },
    axisLeft,
    enableArea: true,
    enableGridX: false,
    enableGridY: false,
    enableDots: false,
    isInteractive: true,
  };

  return config;
};
