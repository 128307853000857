import React, { useEffect, useCallback, useContext } from 'react';
import { match as Match, useRouteMatch } from 'react-router';
import { connect } from 'react-redux';
import {
  Typography, Checkbox, FormControlLabel,
} from '@material-ui/core';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Box from 'shared/styleguide/atoms/Box';
import LoadingCard from 'shared/styleguide/molecules/LoadingCard';
import AdminContext from 'shared/modules/permissions/context/AdminContext';
import { useMergeState } from 'shared/hooks/useMergeState';

import { fetchCertsForAccount } from 'shared/modules/ssl/redux/certs/actions';
import CertTable from './CertTable';

const CertsList = ({ certs, ...props }) => {
  const match: Match = useRouteMatch();

  const { state, mergeState: setState } = useMergeState({
    searchText: '',
    showStaging: false,
    showExpired: true,
  });
  const isAdmin = useContext(AdminContext);

  useEffect(() => {
    // fetch certs if needed
    const accountID = parseInt((match.params as any).accountID, 10);
    if (certs.status === 'pristine' || accountID !== certs.params.accountId) {
      props.fetchCerts(accountID);
    }
  }, [certs.status, certs.params, props.fetchCerts]);

  const getFilteredCerts = useCallback(() => {
    const { showStaging, showExpired } = state;

    let filteredCerts = certs.data;
    if (!showStaging) {
      filteredCerts = filteredCerts.filter((cert) => cert.subject && !cert.subject.match('sites.pressdns.com'));
    }
    if (!showExpired) {
      filteredCerts = filteredCerts.filter((cert) => {
        const expires = new Date(cert.expires);
        // @ts-ignore
        return Date.now() < expires;
        // return true;
      });
    }

    return (state.searchText
      ? filteredCerts.filter(
        (item) => {
          let haystack = item.subject;
          if (item.subject === 'CloudFlare Origin Certificate') {
            // search by alt names
            // take all the alt names and mash em together
            haystack = item.alternateNames.join('');
          }
          return (
            haystack.match(state.searchText)
          );
        },
      )
      : filteredCerts);
  }, [state, certs.status, certs.data.length]);

  const certificates = getFilteredCerts();

  return (
    <Box>
      {
        certs.requestErr && (
          <Typography color="error" style={{ position: 'absolute', margin: 2 }}>
            {certs.errMessage}
          </Typography>
        )
      }
      {
        certs.stsMessage && (
          <Typography color="secondary" style={{ position: 'absolute', margin: 2 }}>
            {certs.stsMessage || 'ready'}
          </Typography>
        )
      }

      <Box direction="row" align="flex-end" justify="space-between" margin={{ top: 'small' }}>
        <Box>
          {
            isAdmin && (
              <Typography component="div" variant="body2" color="textSecondary">Total: {certs.records.length} certs</Typography>
            )
          }
          <Box direction="row" align="center">
            {
              isAdmin ? (
                <FormControlLabel
                  control={(
                    <Checkbox
                      style={{
                        display: 'inline-block',
                        width: 24,
                        verticalAlign: 'top',
                      }}
                      checked={state.showStaging}
                      onChange={(e) => setState({ showStaging: e.target.checked })}
                    />
                  )}
                  label="Show Staging Certificates"
                />
              ) : <Typography variant="caption" color="textSecondary" gutterBottom>Staging certificates are hidden</Typography>
            }
            {
              isAdmin && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      style={{
                        display: 'inline-block',
                        width: 24,
                        verticalAlign: 'top',
                      }}
                      checked={state.showExpired}
                      onChange={(e) => setState({ showExpired: e.target.checked })}
                    />
                  )}
                  label="Show Expired Certificates"
                />
              )
            }
          </Box>
        </Box>
        <SearchBar
          text="Search Certificates"
          onChange={(filter) => setState({ searchText: filter })}
        />
      </Box>
      {
        certs.status === 'loading' ? (
          <LoadingCard />
        ) : (
          <CertTable certs={certificates} />
        )
      }

    </Box>
  );
};

export default connect(
  (state: any) => ({
    certs: state.certs,
  }), {
    fetchCerts: fetchCertsForAccount,
  },
)(CertsList);
