import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import { jobTypes } from 'shared/modules/status/redux/constants';
import * as APP from './constants';

/**
 * can get by jobId as well
 * appId: required
 * optional params are
 * maxResults,
 * dateRange
 * (accepts array of two dates eg ['2020-01-01', '2020-01-10'] )
 * */

export const fetchWebhookDeploymentLogs = (appId, options = {}) => makeApiAction(
  ({ api }) => {
    const params = qs.stringify({
      ...options,
      jobType: jobTypes.gitWebhookDeploy,
    }, { addQueryPrefix: true });

    return api().get(`/apps/${appId}/git-deployment-log${params}`);
  },
  APP.GET_WEBHOOK_DEPLOYMENT_LOG_REQUEST,
  APP.GET_WEBHOOK_DEPLOYMENT_LOG_SUCCESS,
  APP.GET_WEBHOOK_DEPLOYMENT_LOG_FAILURE,
  { appId },
  true,
);

export const fetchIntegrationDeploymentLogs = (appId, options = {}) => makeApiAction(
  ({ api }) => {
    const params = qs.stringify({
      ...options,
      jobType: jobTypes.gitIntegrationDeploy,
    }, { addQueryPrefix: true });

    return api().get(`/apps/${appId}/git-deployment-log${params}`);
  },
  APP.GET_INTEGRATION_DEPLOYMENT_LOG_REQUEST,
  APP.GET_INTEGRATION_DEPLOYMENT_LOG_SUCCESS,
  APP.GET_INTEGRATION_DEPLOYMENT_LOG_FAILURE,
  { appId },
  true,
);

export const fetchDeploymentLogsForJob = (appId, jobId, actionOnly) => makeApiAction(
  ({ api }) => {
    const params = qs.stringify({
      jobId,
    }, { addQueryPrefix: true });

    return api().get(`/apps/${appId}/git-deployment-log${params}`);
  },
  APP.GET_JOB_DEPLOYMENT_LOG_REQUEST,
  APP.GET_JOB_DEPLOYMENT_LOG_SUCCESS,
  APP.GET_JOB_DEPLOYMENT_LOG_FAILURE,
  { appId, jobId },
  true,
  actionOnly,
);
