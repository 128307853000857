import React from 'react';

import {
  Typography, Card, List, ListItem, ListItemText,
} from '@material-ui/core';

import { css } from '@emotion/react';
import Box from 'shared/styleguide/atoms/Box';
import ShowVideo from 'shared/styleguide/organisms/ShowVideo';
import YOUTUBE from 'shared/3rdparty/youtube';
import { roles as ROLES } from 'shared/modules/permissions/user/actions';

import Loading from 'shared/styleguide/atoms/Loading';
import Linker from 'shared/styleguide/atoms/Links/Linker';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { Account } from 'shared/modules/account/types';
import { ReduxStore } from 'shared/utils/redux/apiReducer';
import { App } from 'shared/types/App';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import LinkButton from 'shared/styleguide/atoms/Buttons/Link';
import DashboardCardHeading from './DashboardCardHeading';

interface DashboardAppsListProps {
  account: Account;
  apps: ReduxStore<App[]>;
  permissions: any; // TODO;
}

export const DashboardAppsList = (props: DashboardAppsListProps) => {
  const renderApps = (apps) => {
    const components = [];

    const { account, permissions } = props;

    let route = 'sftp';

    if (permissions.isAdmin || permissions.directory[account.id].role > ROLES.APPONLYMINIMAL) {
      route = 'overview';
    }

    apps.forEach((app) => {
      if (app.active && app.status === 'q') {
        components.push(
          <ListItem key={app.id || app.name}>
            <Box direction="row" gap="small">
              <Typography component="span">{app.label || app.name}</Typography>&nbsp;<Typography color="textSecondary" component="span">(provisioning)</Typography>
            </Box>
          </ListItem>,
        );
      } else {
        components.push(
          <ListItem
          // @ts-ignore
            component={Linker}
            key={app.id}
            to={`/account/${account.id}/apps/${app.id}/${route}`}
            css={(theme) => (
              {
                'height': remMapper('large'),
                'padding': `0 ${remMapper('small')}`,
                'backgroundColor': theme.color100,
                'border': '1px solid transparent',
                'borderRadius': '6px',
                'boxSizing': 'border-box',
                'flexShrink': 0,
                'flexBasis': 'auto',
                'display': 'flex',
                'alignItems': 'center',
                'transition': '0.25s ease-in-out',
                '&:hover': {
                  borderColor: theme.color400,
                },
              }
            )}
          >
            <ListItemText disableTypography>
              <Typography component="span">{app.label || app.name}</Typography>
              {
                !app.active && <Typography color="textSecondary" component="span">(deactivated)</Typography>
              }
            </ListItemText>
          </ListItem>,
        );
      }
    });

    if (components.length === 0) {
      components.push(
        <Box
          key="no-apps"
          css={{
            padding: `${remMapper('medium')} 0`,
          }}
        >
          <Typography>
            No apps created yet. {
              account.isCollaborator && permissions.canAccessAccounts.length > 1 && (
                <>
                  <Button css={css`top: -2px`} variant="text" onClick={() => { document.getElementById('collab-switcher').click(); }}>Switch accounts</Button> to view apps from a collaborative account.
                </>
              )
            }
          </Typography>
        </Box>,
      );
    }
    return (
      <List
        component="nav"
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: remMapper('xsmall'),
          padding: 0,
        }}
      >
        {components}
      </List>
    );
  };

  const {
    apps,
  } = props;

  let content = null;

  if (apps.status === 'loading') {
    content = (<Loading />);
  }

  if (apps.apiErrorStatusCode === 403) {
    content = (<Box><Typography>No access to apps</Typography></Box>);
  }

  return (
    <div
      css={{ marginBottom: remMapper('mediumLarge') }}
    >
      <DashboardCardHeading>Apps <ShowVideo videoId={YOUTUBE.APP_CREATE_VIDEO_ID} title="Creating an App" /></DashboardCardHeading>
      <Card
        elevation={0}
        css={{
          padding: remMapper('medium'),
          maxHeight: 350,
          overflowY: 'auto',
        }}
      >
        {
            content || renderApps(structuredClone(apps.data).sort((a, b) => b.dateAdded - a.dateAdded))
          }
      </Card>
    </div>
  );
};

export default DashboardAppsList;
