import React from 'react';
import {
  array, object, func, bool,
} from 'prop-types';

import { SettingsBackupRestore, Delete } from '@material-ui/icons';
import {
  TableRow, TableCell, IconButton, Tooltip, FormControlLabel, Checkbox, Typography,
} from '@material-ui/core';
import {
  LOADING,
} from 'shared/utils/redux/constants';
import { PCDN_REGEX, PRESSDNS_REGEX } from 'shared/modules/dns/constants';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';
import { remMapper } from 'shared/styleguide/theme/spacing';

export const DomainRows = ({
  displayRows, onEditDomain, onSetPressDns, onRestoreDomain, onDeleteDomain, modifyDomains, isFsa,
}) => {
  return displayRows
    .map((row) => {
      const isPagelyDomain = Boolean(row.fqdn?.match(PRESSDNS_REGEX) || row.fqdn?.match(PCDN_REGEX));
      return (
        <TableRow css={{ height: remMapper('xxlarge') }} key={row.id || row.tempId}>
          <TableCell>
            <Box>
              {
                row.tempId ? (
                  <WideTextField
                    css={{
                      margin: 0,
                    }}
                    name="Domain Name"
                    placeholder="Domain Name"
                    onChange={(e) => onEditDomain(e.target.value.toLowerCase(), row.tempId)}
                    value={row.fqdn || ''}
                  />
                ) : row.fqdn
              }
            </Box>
          </TableCell>
          <TableCell
            css={{
              paddingLeft: 0,
            }}
          >
            {
              row.tempId && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={Boolean(row.isPressDns)}
                      onChange={(e) => onSetPressDns(e.target.checked, row.tempId)}
                    />
                  )}
                  label={<Typography variant="body2" color="textSecondary">Use PressDNS for this domain.</Typography>}
                />
              )
            }
          </TableCell>
          <TableCell>
            <Box direction="row" align="center" justify="flex-end" gap="xsmall">
              {
                row.primary && (
                  <GhostTag
                    key="primary"
                  >
                    Primary
                  </GhostTag>
                )
              }
              {
                row.fqdn && row.tempId && (
                  <GhostTag
                    color="green"
                    key="to-create-tag"
                  >
                    New Domain
                  </GhostTag>
                )
              }
              {
                row.delete && (
                  <GhostTag
                    color="red"
                    key="to-delete-tag"
                  >
                    To Be Deleted
                  </GhostTag>
                )
              }
              <Box margin={{ left: 'xsmall' }}>
                {
                  row.delete
                    ? (
                      <Tooltip title="Do not delete domain" placement="top">
                        <IconButton
                          color="default"
                          size="small"
                          name="domainIdsToRestore"
                          onClick={() => onRestoreDomain(row.id)}
                          disabled={modifyDomains.displayStatus === LOADING}
                        >
                          <SettingsBackupRestore />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={row.primary ? 'Primary domain may not be deleted' : 'Delete domain'} placement="top">
                        <span>
                          <IconButton
                            color="default"
                            size="small"
                            name="domainIdsToDelete"
                            onClick={() => onDeleteDomain(row.id, row.fqdn, row.tempId)}
                            disabled={isPagelyDomain || row.primary || modifyDomains.displayStatus === LOADING}
                          >
                            <Delete />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )
                }
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      );
    });
};

DomainRows.propTypes = {
  displayRows: array,
  isFsa: bool,
  modifyDomains: object,
  onDeleteDomain: func,
  onEditDomain: func,
  onRestoreDomain: func,
  onSetPressDns: func,
};

export default DomainRows;
