import { useReducer } from 'react';

export const reducer = (state, action) => {
  const { type, params } = action;
  switch (type) {
    case 'set': {
      return {
        ...state,
        ...params,
      };
    }
    default:
      return state;
  }
};

export const useMergeState = (initialState) => {
  const [state, dispatchReducer] = useReducer(reducer, initialState);

  const mergeState = (values) => {
    return dispatchReducer({
      type: 'set',
      params: values,
    });
  };

  return { state, mergeState };
};

export default { useMergeState };
