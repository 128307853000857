import React from 'react';
import ArticleList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';

const articles = [
  {
    id: 1,
    url: 'https://support.pagely.com/hc/en-us/articles/360024609632-Generating-SSH-Keys-on-MacOS',
    title: 'Generating SSH Keys on Mac',
    body: `SSH keys are the
    perfect way to securely connect to your server over SSH/SFTP.
    At Pagely, we take your security very seriously
    and require all of our VPS and Enterprise
    hosting customers to use SSH keys when
    authenticating with their environments.
    `,
  },
  {
    id: 2,
    url: 'https://support.pagely.com/hc/en-us/articles/360024924431-Generating-SSH-Keys-on-Windows',
    title: 'Generating SSH Keys on Windows',
    body: `To connect to your Pagely VPS over SSH,
    you'll first need to add an SSH key to your account. 
    If you don't already have an SSH key that you want to
   use, you'll need to generate one. In this article, 
   we'll show you how to check for existing SSH keys and
   generate a new SSH key pair.`,
  },
  {
    id: 3,
    url: 'https://support.pagely.com/hc/en-us/articles/203115864-Using-sFTP-on-your-VPS-Enterprise-Server',
    title: 'Windows FTP Instructions',
    body: `In order to connect to your VPS
    server via SFTP you will need to make sure you have entered
    your public ssh key in the Atomic control panel and that your
    FTP client supports key-based authentication.`,
  },
  {
    id: 4,
    url: 'https://support.pagely.com/hc/en-us/articles/203115864-Using-SFTP-on-your-VPS-Enterprise-Server',
    title: 'Using SFTP on your VPS/Enterprise Server',
    body: `In order to connect to
    your VPS server via SFTP you will need
    to make sure you have entered your public
    ssh key in the Atomic control pan`,
  },
];

const Footer = () => (
  <ArticleList
    topic="security"
    columns={3}
    boxProps={{
      row: true,
      wrap: 'wrap',
    }}
    articles={articles}
  />
);

export default Footer;
