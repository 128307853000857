export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export function isInteger(value) {
  return value === parseInt(value, 10);
}

export function isArray(value) {
  return Array.isArray(value);
}

export function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}
