/* eslint-disable react/prop-types */
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Box from 'shared/styleguide/atoms/Box';

import { remMapper } from 'shared/styleguide/theme/spacing';
import { jssStyles as jss } from './styles';

const DisplayOption = ({
  title,
  redirectTo,
  description,
  history,
  accountID,
}) => {
  const handleRedirect = () => {
    history.push(`/account/${accountID}/ssl/add/${redirectTo}`);
  };

  return (
    <Box
      role="button"
      onClick={handleRedirect}
      direction="row"
      gap="small"
      align="center"
      justify="left"
      padding="xsmall"
      css={jss.addCertButton}
    >
      <Box>
        <Box
          css={{
            marginBottom: remMapper('xxsmall'),
          }}
          row
        >
          <strong>
            {title}
          </strong>
          <ArrowForwardRoundedIcon
            css={{
              alignSelf: 'center',
            }}
            fontSize="inherit"
          />
        </Box>
        <Typography variant="body2">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export const AddSecurity = ({ history, match }: RouteComponentProps<{ accountID: string }>): JSX.Element => {
  const { accountID } = match.params;

  const options = [
    {
      title: 'Create CSR',
      redirectTo: 'csr',
      description: 'Generate the Certificate Signing Request you need in order to purchase a third-party certificate from a Certificate Authority.',
    },
    {
      title: 'Upload Certificate',
      redirectTo: 'cert',
      description: 'Start here after you have returned with a new certificate from your Certificate Authority.',
    },
    {
      title: 'Import Existing Private Key & Certificate',
      redirectTo: 'import',
      description: 'Already have a key and certificate? Import it here.',
    },
    {
      title: "Let's Encrypt!",
      redirectTo: 'letsencrypt',
      description: 'Instantly add & activate a free Domain Validated (DV) secure certificate on your site with recurring renewal.',
    },
  ];

  return (
    <div>
      <Box direction="column" gap="small">
        {
          options.map((option) => {
            return (
              <DisplayOption {...option} key={option.title} history={history} accountID={accountID} />
            );
          })
        }
      </Box>
    </div>
  );
};

export default withRouter(AddSecurity);
