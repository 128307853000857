import { deepClone } from 'shared/utils';

const reassembleValidations = (sorted, validationsRecords, domain, apex) => {
  const newValidationsRecords = { ...validationsRecords };
  // if we have apex NS records, use apex as key
  if (sorted.apexNs.length > 0) {
    // we have apex NS
    if (!newValidationsRecords[apex]) {
      newValidationsRecords[apex] = {};
    }

    newValidationsRecords[apex].ns = sorted.apexNs;
  }

  // now update with non-apex records
  if (!newValidationsRecords[domain.fqdn]) {
    newValidationsRecords[domain.fqdn] = {};
  }

  newValidationsRecords[domain.fqdn] = {
    // NS may already have been set with apexNs, so don't overwrite
    ns: newValidationsRecords[domain.fqdn].length > 0 ? newValidationsRecords[domain.fqdn] : sorted.ns,
    acm: sorted.acm,
    overallAcmValid: sorted.overallAcmValid,
    other: sorted.other,
  };

  // store non-record data for later merging
  const tDomain = { ...domain };
  tDomain.validationRules.records = [];
  newValidationsRecords[domain.fqdn].data = tDomain;

  if (sorted.apexNs.length > 0 && !newValidationsRecords[apex].data) {
    // we should set apex data now in case it doesn't get set in a future iteration
    const tempDomain = deepClone(tDomain);
    tempDomain.validationRules.records = [];
    newValidationsRecords[apex].data = {
      ...tempDomain,
      hasApexNs: false,
    };
  }

  return newValidationsRecords;
};

/**
 * If the NS record is for an apex that is not a domain in "invalidDomains"
 * separate the NS records out to their own "apex" view
 * Only show NS records on the "apex" record, not subdomains since they do not apply
 */
export const sortValidations = (
  invalidDomains,
  selectedDomains = [],
  validationType = 'all',
  simplePressDns = false,
  onlyInvalid = false,
  filterEmpty = false,
) => {
  // temporarily store records in an object with apex or subdomains as keys for easy sorting
  const validationsRecords = consolidateValidations(invalidDomains, simplePressDns);

  // filter based on args
  const filtered = getFilteredValidations(validationsRecords, validationType, onlyInvalid, simplePressDns, filterEmpty);

  let { validations } = filtered;

  if (selectedDomains.length > 0) {
    // we'll only show the selected validation
    validations = getSelectedValidations(validationsRecords, filtered.validations, selectedDomains);
  }

  return { validations, emptyRecordDomains: filtered.emptyRecordDomains };
};

const consolidateValidations = (domains, simplePressDns) => {
  let validationsRecords = {};
  domains.forEach((d) => {
    // must deep clone otherwise store will be mutated
    const domain = deepClone(d);
    const apex = domain.domainName;

    const sorted = {
      ns: [],
      acm: [],
      apexNs: [],
      other: [],
      overallAcmValid: true,
    };

    const { records } = domain.validationRules;

    records.forEach((r) => {
      // sort and update temp objects
      sortByType(r, domain, apex, simplePressDns, sorted);
    });

    // update the app validationsRecords blob
    const updatedValidationRecords = reassembleValidations(sorted, validationsRecords, domain, apex);
    validationsRecords = { ...updatedValidationRecords };
  });

  return validationsRecords;
};

const sortByType = (record, domain, apex, simplePressDns, sorted) => {
  if (record.label === 'PressDNS Nameservers') {
    const isApexNS = domain.fqdn !== apex && record.records.some((r) => r.name === '.');
    if (isApexNS) {
      // eslint-disable-next-line
      domain.hasApexNs = true;
      sorted.apexNs.push(record);
    } else {
      sorted.ns.push(record);
    }
  } else if (record.label === 'ACM Validation Rules') {
    if (!record.records.every((record) => record.validated)) {
      // eslint-disable-next-line
      sorted.overallAcmValid = false;
    }
    sorted.acm.push(record);
  } else if (!domain.isPressDns || (!simplePressDns && domain.isPressDns && domain.nsValid && !domain.validated)) {
    // eg A/CNAME
    // for PressDNS "other"s should only be shown if there's an internal problem pointing records correctly
    // we would not likely want to show that during app create, so we pass the 'simplePressDns' flag as true

    sorted.other.push(record);
  }
};

const getFilteredValidations = (validationsRecords, validationType, onlyInvalid, simplePressDns, filterEmpty) => {
  const emptyRecordDomains = [];
  // merge sorted rules with validation data
  const validations = [];
  Object.entries(validationsRecords).forEach(([domain, v]) => {
    const ns = v.ns || [];
    const acm = v.acm || [];
    const overallAcmValid = v.overallAcmValid || false;
    const other = v.other || [];
    const validation = { ...v.data };

    let validationsToShow;
    if (validationType === 'ns') {
      validationsToShow = ns;
      validation.validated = validation.nsValid;
    } else if (validation.isPressDns && simplePressDns) {
      if (simplePressDns && validation.nsValid) {
        validationsToShow = [];
      } else {
        validationsToShow = ns;
        validation.validated = validation.nsValid;
      }
    } else if (validationType === 'acm') {
      validation.validated = overallAcmValid;
      validationsToShow = acm;
    } else if (validationType === 'non-acm') {
      validationsToShow = [...ns, ...other];
    } else {
      validationsToShow = [...ns, ...acm, ...other];
    }

    if (onlyInvalid && validation.validated) {
      return;
    }

    validation.displayName = domain;
    validation.showNs = ns ? ns.length > 0 : false;

    if (validationsToShow.length === 0) {
      if (filterEmpty) {
        return;
      }

      emptyRecordDomains.push(v.data.domainId);
    }

    if (validationsToShow.length > 0) {
      // only add to validation rule if there are records to show
      validation.validationRules.records = validationsToShow;
    }

    validations.push(validation);
  });

  return { validations, emptyRecordDomains };
};

const getSelectedValidations = (validationsRecords, validations, selectedDomains) => {
  return validations.reduce((acc, d) => {
    const apex = d.domainName;
    const apexDomain = validations.find((v) => v.displayName === apex);

    if (d.hasApexNs
      && !acc.find((a) => a.domainName === apex)
      && !selectedDomains.find((s) => s === apexDomain.domainId.toString())
    ) {
      // show NS for apex ONCE even if it's not in selected domains
      // make sure it's just NS shown
      apexDomain.validationRules.records = validationsRecords[apex].ns;

      return [
        ...acc,
        apexDomain,
        d,
      ];
    }

    if (!selectedDomains.includes(d.domainId.toString())) {
      return acc;
    }

    return [
      ...acc,
      d,
    ];
  }, []);
};
