import { Typography } from '@material-ui/core';
import React, { ReactElement, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import * as STATUS from 'shared/modules/status/redux/constants';
import type { App } from 'shared/types/App';
import { NewAppContext, useNewAppContext } from '../context/NewAppContext';
import AppItem from './AppItem';

interface Props {
  apps: App[];
  appsTags: Record<string, string>;
  jobs: { data: any[] };
  fsaJobs: { data: any[] };
  isAdmin: boolean;
}

const AppsBySubscriptionList = ({
  apps, jobs, fsaJobs, isAdmin, appsTags,
}: Props): ReactElement[] => {
  const { state: appContext } = useNewAppContext();
  const { appCreateJob, newAppId } = appContext;
  const location = useLocation();

  // bucket these apps by subscriptionId
  const subscriptionEntries = useMemo((): Record<string, Array<any>> => {
    const appsBySubscription = {};
    apps.forEach((a) => {
      const subId = a.subscriptionId || 'pagely';

      if (appsBySubscription[subId]) {
        appsBySubscription[subId].push(a);
      } else {
        appsBySubscription[subId] = [a];
      }
    });
    return appsBySubscription;
  }, [apps]);

  const composeAppItem = (app): React.ReactElement => {
    const appCreating = jobs && jobs.data.find((j) => j.ownerId === app.id.toString());
    const job = appCreating ?? null;

    let appState = 'normal';

    if (app.id === newAppId && appCreateJob !== STATUS.SUCCESS) {
      if (appCreateJob === 'loading') {
        appState = 'loading';
      } else {
        appState = 'creating';
      }
    }

    const fsaJob = fsaJobs.data.find((j) => j?.ownerId === `${app.id}`);

    return (
      <AppItem
        key={app.id}
        type={appsTags[app.id]}
        appState={appState}
        appCreateJob={appCreateJob}
        app={app}
        createdApp={app?.data}
        job={job}
        fsaJob={fsaJob}
        appRoute={`${location.pathname}/${app.id}/overview`}
        isAdmin={isAdmin}
      />
    );
  };

  if (Object.keys(subscriptionEntries).length === 1 && Object.keys(subscriptionEntries)[0] === 'pagely') {
    return apps.map(composeAppItem);
  }

  return Object.entries(subscriptionEntries).map(([subscription, appsList]) => {
    return (
      <div key={subscription}>
        <Typography paragraph>Subscription Id: <strong>{subscription}</strong></Typography>
        {appsList.map(composeAppItem)}
      </div>
    );
  });
};

export default AppsBySubscriptionList;
