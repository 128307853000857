import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { object, func } from 'prop-types';

import { ErrorText } from 'shared/styleguide/typography';
import { enableSFTP, disableSFTP, getCustomIps } from 'shared/modules/app/redux/actions';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Loading from 'shared/styleguide/atoms/Loading';

export class CustomIps extends Component {
  static propTypes = {
    account: object.isRequired,
    app: object.isRequired,
    getCustomIps: func.isRequired,
  };

  state = {
    status: 'pristine',
  };

  async componentDidMount() {
    const {
      app,
      getCustomIps,
    } = this.props;

    try {
      const IPS = await getCustomIps({ appId: app.data.id });

      this.setState({
        ips: IPS?.data?.[app.data.id] || [],
        status: 'ready',
      });
    } catch (err) {
      this.setState({
        status: 'failed',
        err,
      });
    }
  }

  render() {
    const {
      account,
    } = this.props;

    if (this.state.status === 'failed') {
      return <ErrorText>{this.state.err.message}</ErrorText>;
    }

    if (
      !account.id || this.state.status !== 'ready'
    ) {
      return (
        <Loading />
      );
    }

    if (this.state.status === 'ready' && !this.state.ips.length) {
      return null;
    }

    return (
      <Box margin={{ top: 'medium' }}>
        <Typography variant="h3" gutterBottom>Custom IPs</Typography>
        <Box as={Paper} padding="small">
          <Box direction="row" gap="large">
            <Box>
              <Typography variant="body2">IP address</Typography>
            </Box>
            <Box>
              <Typography variant="body2">CNAME</Typography>
            </Box>
          </Box>
          {
            this.state.ips.map((record) => (
              <Box key={record.ip} direction="row" gap="medium" padding={{ top: 'xsmall', bottom: 'xsmall' }}>
                <Box>
                  <Typography variant="body2">{record.ip}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{record.cname}</Typography>
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box>
    );
  }
}

export default connect(
  (state) => ({
    account: state.account,
    app: state.app.app,
  }),
  {
    enableSFTP,
    disableSFTP,
    getCustomIps,
  },
)(CustomIps);
