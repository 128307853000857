import React, { useEffect, Fragment } from 'react';
import {
  func, object,
} from 'prop-types';
import { connect } from 'react-redux';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import { getAllBackups } from 'shared/modules/app/redux/backups/actions';
import InfoText from 'shared/styleguide/molecules/InfoText';

import BackupsList from './BackupsList';

export const BackupsLoader = ({
  backups, getAllBackups, match,
}) => {
  useEffect(() => {
    if (backups.status === 'pristine') {
      getAllBackups(match.params.appId);
    }
  }, [match.params.appId, backups, getAllBackups]);

  const infoText = (
    <InfoText>Backups are made daily and stored on Amazon S3 for 14 days</InfoText>
  );

  if (backups.status === 'loading') {
    return (
      <Fragment>
        {infoText}
        <Box align="center">
          <Loading />
        </Box>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {infoText}
      <Box>
        <ErrorBoundary>
          <BackupsList backups={backups.data} />
        </ErrorBoundary>
      </Box>
    </Fragment>
  );
};

BackupsLoader.propTypes = {
  backups: object,
  getAllBackups: func,
  match: object,
};

export default connect(
  (state) => ({
    backups: state.backups.backups,
  }),
  {
    getAllBackups,
  },
)(BackupsLoader);
