import React from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router';

import qs from 'qs';

import {
  Typography, Drawer, Toolbar, IconButton, Paper,
} from '@material-ui/core';
import { OpenInNew, Close } from '@material-ui/icons';

import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import RaisedButton from 'shared/styleguide/atoms/Buttons/NewButton';
import NewWindowContext from 'shared/styleguide/atoms/NewWindow/NewWindowContext';
import { NumberLike } from 'shared/types/types';
import NoteView from './NoteView';
import { useDeleteNoteMutation, useGetNoteByIdQuery, useGetSharedNoteByIdQuery } from '../../query';
import { TYPE_SHARED } from '../../constants';

interface NoteViewerProps {
  noteId: NumberLike;
  popupProps: any; // comes from the context
  noteType: string;
}

export const NoteViewer = (props: NoteViewerProps) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const [onDeleteNote] = useDeleteNoteMutation();
  const {
    data: regularNote, isError: isRegularNoteError, error: regularNoteError, isSuccess: regularNoteSuccess, isLoading: regularNoteLoading,
  } = useGetNoteByIdQuery(props.noteId, { skip: props.noteType === TYPE_SHARED });
  const {
    data: sharedNote, isError: isSharedNoteError, error: sharedNoteError, isSuccess: sharedNoteSuccess, isLoading: sharedNoteLoading,
  } = useGetSharedNoteByIdQuery(props.noteId, { skip: props.noteType !== TYPE_SHARED });
  let note;
  if (regularNoteSuccess) {
    note = regularNote;
  } else if (sharedNoteSuccess) {
    note = sharedNote;
  }
  let isError = false;
  const error = regularNoteError || sharedNoteError;
  const isLoading = regularNoteLoading || sharedNoteLoading;
  const isSuccess = regularNoteSuccess || sharedNoteSuccess;
  const isShared = note && note.noteType === TYPE_SHARED;

  if (isShared && isSharedNoteError) {
    isError = true;
  } else if (!isShared && isRegularNoteError) {
    isError = true;
  }

  const handleOpenPopOut = (children) => (e) => {
    props.popupProps.setPopupValue({
      isOpen: true,
      children: (
      // for some reason this is in light mode
        <Paper elevation={0} css={{ borderRadius: 0, border: 'none' }}>
          <div css={(theme) => ({ background: theme.palette.background.contrast, paddingTop: 16, height: '100%' })}>
            {children}
          </div>
        </Paper>
      ),
      props: {
        height: 800,
      },
    });
  };

  const handleClose = () => {
    history.replace(match.url.replace(/\/note\/.*/, ''));
  };

  const handleDelete = async () => {
    const {
      noteId,
    } = props;
    await onDeleteNote(noteId);

    history.replace(match.url.replace(/\/note\/.*/, ''));
  };

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const pathToEdit = qs.stringify({ ...query, noteView: 'edit' }, { addQueryPrefix: true });

  return (
    // @ts-ignore
    <Drawer width="33.3%" anchor="right" open data-testid="note-view-drawer">
      <Toolbar>
        <Box direction="row" justify="space-between" align="center" flex={1}>
          <Box direction="row">
            {
              !isShared && (
              <div>
                <RaisedButton
                  variant="contained"
                  style={{ marginLeft: 5, marginRight: 5 }}
                  label="Edit Note"
                  component={AdapterLink}
                  to={`${location.pathname}${pathToEdit}`}
                />
              </div>
              )
            }
            <RaisedButton
              variant="outlined"
              label="Delete"
              color="error"
              onClick={handleDelete}
            />
          </Box>
          <Box direction="row">
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleOpenPopOut(<NoteView {...note} />)}
            >
              <OpenInNew />
            </IconButton>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
      {
        isLoading && (
          <Loading />
        )
      }
      {
        isError && (
          <Box>
            <Typography color="error">{JSON.stringify(error)}</Typography>
          </Box>
        )
      }
      {
        isSuccess && (
          <NoteView {...note} />
        )
      }
    </Drawer>
  );
};

const NoteViewerWithPopup = (props) => (
  <NewWindowContext.Consumer>
    {(value) => <NoteViewer {...props} popupProps={value} />}
  </NewWindowContext.Consumer>
);

export default NoteViewerWithPopup;
