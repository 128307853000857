import React, { useState } from 'react';

import XIcon from '@material-ui/icons/Clear';
import { IconButton, InputAdornment } from '@material-ui/core';
import styled from '@emotion/styled';
import TextField from 'shared/styleguide/atoms/Input/TextField';

const SearchSpan = styled.span`
  display: inline-flex;
  align-items: flex-start;
  align-items: center;
  position: relative;
`;

interface SearchBarProps {
  text: string;
  onChange: (arg: string) => void;
  onClear?: () => void;
  textFieldProps?: Record<string, any>;
}

export const SearchBar = ({
  text, onChange, onClear, textFieldProps, ...rest
}: SearchBarProps): React.ReactElement => {
  const [filter, setFilter] = useState('');

  const handleUpdateSearch = (value) => {
    // display the typed value
    setFilter(value);

    // pass an escaped value back to the caller
    onChange(value.toLowerCase().replace(/[.?*+\\|{()[^$]/g, '\\$&'));
  };

  return (
    <SearchSpan {...rest}>
      <TextField
        placeholder={text}
        name={text}
        id={text}
        value={filter}
        onChange={(e) => handleUpdateSearch(e.target.value)}
        css={{
          width: '100%',
          margin: 0,
        }}
        autoComplete="off"
        margin="dense"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                css={{ right: -12 }}
                size="small"
                color="inherit"
                onClick={(() => {
                  handleUpdateSearch('');
                  if (onClear) {
                    onClear();
                  }
                })}
              >
                <XIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...textFieldProps}
      />
    </SearchSpan>
  );
};

export default SearchBar;
