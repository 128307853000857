import React, { useState } from 'react';
import {
  object, string, bool,
} from 'prop-types';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import classNames from 'classnames';
import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';
import { FeatureToggle } from 'shared/flags';
import Box from 'shared/styleguide/atoms/Box';
import styles from './styles';

const useStyles = makeStyles(styles);

const SidebarItem = (
  {
    isActive, navitem, currentParentId, location, isMobile,
  },
) => {
  const [isOpen, setIsOpen] = useState(isActive);
  const classes = useStyles();

  // Apps requires a parent Id to navigate within that section
  const hideCollapse = (navitem.requiresParentId && !currentParentId) || isMobile;

  const listItemContainerClasses = classNames({
    [classes.listItemContainer]: true,
    [classes.activeListItem]: isActive,
    [classes.listItemOpen]: isOpen,
  });
  const listItemClasses = classNames({
    [classes.activeListItem]: isActive,
  });
  return (
    <FeatureToggle
      key={navitem.label}
      flags={navitem.show}
    >
      <ListItem
        disableGutters
        classes={{
          root: classes.listItem,
        }}
        className={listItemClasses}
      >
        <div
          data-classid="sidebar-item"
          style={{ flex: '1' }}
        >
          <Box column flex={1} justify="center">
            <Box
              row
              flex={1}
              justify="center"
              className={listItemContainerClasses}
            >
              <Box direction="row" as={(props) => <AdapterLink {...props} />} to={navitem.route} data-cy={navitem.label} flex={1}>
                <ListItemText
                  classes={{ primary: classes.listItemText, root: classes.listItemTextRoot }}
                  primary={navitem.label}
                />
              </Box>
              {
                (navitem.children && !hideCollapse) && (
                  <IconButton
                    size="small"
                    onClick={() => setIsOpen(!isOpen)}
                    disableRipple
                  >
                    {
                      isOpen
                        ? <ExpandLessIcon />
                        : <ExpandMoreIcon />
                    }
                  </IconButton>
                )
              }
            </Box>
            {
              (isOpen) && (navitem.children && !hideCollapse)
              && (
                <div data-classid="sidebar-child-group">
                  <Box column>
                    {
                      navitem.children
                        .filter((child) => !child.hidden)
                        .map((child) => {
                          return (
                            <AdapterLink
                              to={`${navitem.route}${currentParentId && `/${currentParentId}`}/${child.to}`}
                              key={child.label}
                              className={classes.listItemSubItem}
                            >
                              <Typography
                                variant="body1"
                                component="span"
                                css={(theme) => ({
                                  'color': child.match.test(location.pathname) ? theme.palette.colors.pink : theme.color600,
                                })}
                              >
                                {child.label}
                              </Typography>
                            </AdapterLink>
                          );
                        })
                    }
                  </Box>
                </div>
              )
            }
          </Box>
        </div>
      </ListItem>
    </FeatureToggle>
  );
};

SidebarItem.propTypes = {
  currentParentId: string,
  isActive: bool,
  isMobile: bool,
  location: object.isRequired,
  navitem: object.isRequired,
};

export default (SidebarItem);
