import React from 'react';
import { connect } from 'react-redux';
import { object } from 'prop-types';

import Box from 'shared/styleguide/atoms/Box';
import { createReleaseNote } from 'shared/modules/releasenotes/redux/actions';

import EditForm from './EditForm';

const CreateReleaseNote = (props) => {
  const {
    user,
  } = props;

  return (
    <Box padding={{ top: 'xsmall', bottom: 'small' }}>
      <EditForm user={user} releaseNoteDate={null} />
    </Box>
  );
};

CreateReleaseNote.propTypes = {
  user: object,
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = {
  createReleaseNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReleaseNote);
