import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

require('shared/styleguide/css/markdown.css');

const MDView = (props) => (
  <span className="markdown-body">
    <ReactMarkdown
      source={props.value}
      {...props}

    />
  </span>
);

MDView.propTypes = {
  value: PropTypes.string,
};

export default MDView;
