import { combineReducers } from 'redux';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import apiReducer, { baseState, baseCollectionState } from 'shared/utils/redux/apiReducer';
import * as ANALYTICS from '../constants';

import {
  diskByMount,
  diskByPath,
  diskDimensionsByMount,
  diskDimensionsByPath,
} from './disk';

export const disk = combineReducers({
  byMount: combineReducers({
    dimensions: diskDimensionsByMount,
    usage: diskByMount,
  }),
  byPath: combineReducers({
    dimensions: diskDimensionsByPath,
    usage: diskByPath,
  }),
});

const dimensions = apiReducer(
  ANALYTICS.ANALYTICS_CPU_DIMENSIONS_REQUEST,
  ANALYTICS.ANALYTICS_CPU_DIMENSIONS_SUCCESS,
  ANALYTICS.ANALYTICS_CPU_DIMENSIONS_FAILURE,
  LOGIN_LOGOUT_SUCCESS,
  baseState,
);

const cpuInitial = {
  ...baseState,
  dimensions: baseState,
};
export const cpu = (state = cpuInitial, action) => {
  switch (action.type) {
    case ANALYTICS.ANALYTICS_CPU_DIMENSIONS_REQUEST:
    case ANALYTICS.ANALYTICS_CPU_DIMENSIONS_SUCCESS:
    case ANALYTICS.ANALYTICS_CPU_DIMENSIONS_FAILURE:
      return {
        ...state,
        dimensions: dimensions(state.dimensions, action),
      };

    default:
      return apiReducer(
        ANALYTICS.ANALYTICS_CPU_REQUEST,
        ANALYTICS.ANALYTICS_CPU_SUCCESS,
        ANALYTICS.ANALYTICS_CPU_FAILURE,
        LOGIN_LOGOUT_SUCCESS,
        cpuInitial,
      )(state, action);
  }
};

export const daily = apiReducer(
  ANALYTICS.ANALYTICS_DAILY_REQUEST,
  ANALYTICS.ANALYTICS_DAILY_SUCCESS,
  ANALYTICS.ANALYTICS_DAILY_FAILURE,
  ANALYTICS.ANALYTICS_DAILY_RESET,
  baseCollectionState,
);

export const appAnalytics = apiReducer(
  ANALYTICS.ANALYTICS_APP_DAILY_REQUEST,
  ANALYTICS.ANALYTICS_APP_DAILY_SUCCESS,
  ANALYTICS.ANALYTICS_APP_DAILY_FAILURE,
  ANALYTICS.ANALYTICS_APP_DAILY_RESET,
  baseCollectionState,
);
