import React from 'react';
import PropTypes, {
  oneOfType, string, number, object, array,
} from 'prop-types';
import moment from 'moment-timezone';
import styled from '@emotion/styled';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Box from 'shared/styleguide/atoms/Box';

import { isObject } from 'shared/utils/types';

const DetailTableCell = styled(TableCell)`
  padding: 10px;
  vertical-align: middle;
`;

const DetailTableHeadingCell = styled(TableCell)`
  width: 100px;
  text-transform: uppercase;
  vertical-align: middle;
`;

const Row = ({ field, val }) => {
  const rowVal = isObject(val)
    ? Object.entries(val).map(([k, v]) => <Row key={k} field={k} val={v} />)
    : val;

  return (
    <Table key={field} size="small">
      <TableBody>
        <TableRow>
          <DetailTableHeadingCell
            align="left"
          >
            {field}
          </DetailTableHeadingCell>
          <DetailTableCell
            align="left"
            padding={isObject(val) ? 'none' : 'normal'}
          >
            {rowVal}
          </DetailTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

Row.propTypes = {
  field: string,
  val: oneOfType([object, number, string, array]),
};

const AlertDetail = ({ data }) => {
  const stringDetails = {
    'Deadline': data.deadline,
    'Status Changer Source': data.statusChangerSource,
    'Status Changer Name': data.statusChangerName,
    'Status Changed': data.statusChanged,
  };

  const horizontalDetails = Object.keys(stringDetails)
    .filter((k) => stringDetails[k])
    .map((key) => {
      let value = stringDetails[key];
      if (['Deadline', 'Status Changed'].includes(key) && value) {
        value = `${moment.utc(value).format('YYYY-MM-DD HH:mm')} UTC`;
      }
      return (
        <Box key={key} gap="small">
          <Typography variant="body2" color="textPrimary" gutterBottom>{key}</Typography>
          <Typography variant="body2">{value || '...'}</Typography>
        </Box>
      );
    });

  let contextData;
  if (data.context) {
    contextData = Object.keys(data.context).map((k) => {
      return (
        <Row key={k} field={k} val={data.context[k]} />
      );
    });
  }

  return (
    <Box padding="small">
      <Box row>
        {horizontalDetails}
      </Box>
      {
        contextData && (
          <Box margin={{ top: 'medium' }}>
            <Typography variant="body2" color="textSecondary" gutterBottom>Context</Typography>
            {contextData}
          </Box>
        )
      }
    </Box>
  );
};

AlertDetail.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
};

export default AlertDetail;
