import React from 'react';
import styled from '@emotion/styled';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ReloadIcon from '@material-ui/icons/Autorenew';
import IconButton from '@material-ui/core/IconButton';

import {
  number, object, string, bool,
} from 'prop-types';
import Box from 'shared/styleguide/atoms/Box';

const ID = styled(Typography)`
  flex: 1 2;
  margin-right: 8px;
`;

const Name = styled(Typography)`
  flex: 2 1 0;
  margin-right: 8px;
`;

const Group = styled(Typography)`
  flex: 1 1 50px;
  margin-right: 8px;
`;

const Role = styled(Typography)`
  flex: 2 1 80px;
  margin-right: 8px;
`;

const IpExternal = styled(Typography)`
  flex: 2 1 80px;
  margin-right: 8px;
`;

const IpInternal = styled(Typography)`
  flex: 1 1 25px;
  margin-right: 8px;
`;
const PoolReload = styled(Typography)`
  flex: 1 1 0;
`;

const ServerListItem = ({
  index,
  style,
  heading,
  data: {
    serverList, onReloadPool, status, poolId,
  },
}) => {
  const server = serverList[index];
  return (
    <Box direction="row" align="center" css={style} padding={{ top: 'xsmall', bottom: 'xsmall' }}>
      <ID component="div">{server.id}</ID>
      <Name component="div">{server.petname}</Name>
      <Group component="div">{server.group}</Group>
      <Role component="div">{server.role}/{server.roleId}</Role>
      <IpInternal component="div">{server.poolId}</IpInternal>
      <IpExternal component="div">{server.ipExternal}</IpExternal>
      <IpInternal component="div">{server.ipInternal}</IpInternal>
      <PoolReload component="div">
        {
        heading ? 'Actions' : (
          <Tooltip title={`Reload Pool ${server.poolId}`} placement="top">
            <IconButton
              size="small"
              // eslint-disable-next-line eqeqeq
              disabled={status.isLoading && poolId == server.poolId}
              onClick={() => {
                onReloadPool(parseInt(server.poolId, 10));
              }}
              style={{
                height: 'auto',
                width: 'auto',
              }}
            >
              <ReloadIcon />
            </IconButton>
          </Tooltip>
        )
      }
      </PoolReload>
    </Box>
  );
};

ServerListItem.propTypes = {
  data: object.isRequired,
  heading: bool,
  index: number,
  style: object.isRequired,
};

export default ServerListItem;
