import Alerts from './Alerts';
import Settings from './Settings';

export const routes = [
  // The base 'Team' route is managed in the index coponent
  {
    label: 'Alerts',
    to: 'alerts',
    match: new RegExp(/\/team\/alerts$/), // eslint-disable-line
    component: Alerts,
  },
  {
    label: 'Settings',
    to: 'settings',
    match: new RegExp(/\/team\/settings$/), // eslint-disable-line
    component: Settings,
  },
];
