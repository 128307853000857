import React, { Component } from 'react';
import { object, func } from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Typography } from '@material-ui/core';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import TextArea from 'shared/styleguide/atoms/Input/TextArea';
import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import ExpandingTextField from 'shared/styleguide/molecules/ExpandingTextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { ErrorText } from 'shared/styleguide/typography';
import { consolidateErrors } from 'shared/utils/validation';

import { useMergeState } from 'shared/hooks/useMergeState';
import { Account } from 'shared/modules/account/types';
import { User } from 'shared/types/User';
import { useCreateNoteMutation } from 'admin/modules/notes/query';
import scss from '../../Support.scss';

export const MigrationForm = (props) => {
  const [createMigrationNote] = useCreateNoteMutation();

  const state = {
    status: 'ready',
    error: '',
  };
  const { state: { status, error }, mergeState: setState } = useMergeState(state);
  return (
    <Box flex="1 1 600px">
      <Typography variant="h3" gutterBottom>Migration Request</Typography>
      <Box as={Paper} padding="medium" margin={{ bottom: 'large' }}>
        <Formik
          initialValues={{
            domains: [''],
            provider: '',
            instructions: '',
            loginUrl: '',
            loginUsername: '',
            loginPassword: '',
            sshUrl: '',
            sshUsername: '',
            sshPassword: '',
          }}
          onSubmit={async ({ instructions, ...values }, actions) => {
            const { account, user } = props;

            const body = Object.entries(values).map(([key, value]) => ({ label: key, value, key })) as Record<string, any>[];
            body.push({ label: 'instructions', value: instructions });

            const note = {
              title: 'Migration Request',
              authorName: user.name,
              accountId: account.id,
              accountName: account.name,
              noteType: 'migration',
              noteContentType: 'application/json',
              note: JSON.stringify(body),
            };

            setState({ status: 'loading' });
            try {
              await createMigrationNote(note);
              setState({ status: 'success' });
              setTimeout(() => {
                setState({ status: 'ready' });
              }, 3000);
              actions.resetForm();
              actions.setFieldValue('domains', ['']);
            } catch (err) {
              setState({ error: consolidateErrors(err), status: 'ready' });
            }
          }}
        >
          {({
            handleSubmit, handleChange, values, errors, setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                <Typography variant="h5" gutterBottom><strong>Source Provider</strong></Typography>
                <TextField
                  fullWidth
                  name="provider"
                  id="support-provider"
                  autoComplete="off"
                  margin="dense"
                  onChange={handleChange}
                  errorText={errors.provider}
                  placeholder="WordPress VIP, WPEngine, etc."
                  helperText="Where the site is currently hosted - It's usually beneficial to let them know you are moving sites"
                  value={values.provider}
                />
              </Box>
              <Box margin={{ top: 'small', bottom: 'medium' }}>
                <Typography variant="h5" gutterBottom><strong>List of Domains/Sites to move</strong></Typography>
                <ExpandingTextField
                  variant="full"
                  id="domains"
                  name="domains"
                  margin="dense"
                  onChange={(value) => setFieldValue('domains', value, false)}
                  inputs={values.domains}
                  error={errors.domains}
                  placeholder="example.domain.com"
                  fullWidth
                />
              </Box>

              <Box direction="row" gap="medium" flex={1}>
                <Box flex={1}>
                  <Typography variant="h5" gutterBottom><strong>Source Login</strong></Typography>
                  <TextField
                    name="loginUrl"
                    placeholder="Source Login URL"
                    autoComplete="off"
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    errorText={errors.loginUrl}
                    value={values.loginUrl}
                  />
                  <TextField
                    name="loginUsername"
                    placeholder="Source Login Username"
                    autoComplete="off"
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    errorText={errors.loginUsername}
                    value={values.loginUsername}
                  />
                  <TextField
                    name="loginPassword"
                    placeholder="Source Login Password"
                    autoComplete="off"
                    fullWidth
                    margin="dense"
                    type="password"
                    onChange={handleChange}
                    errorText={errors.loginPassword}
                    value={values.loginPassword}
                  />
                </Box>
                <Box flex={1}>
                  <Typography variant="h5" gutterBottom><strong>SSH Login</strong></Typography>
                  <TextField
                    name="sshUrl"
                    placeholder="SSH URL"
                    autoComplete="off"
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    errorText={errors.sshUrl}
                    value={values.sshUrl}
                  />
                  <TextField
                    name="sshUsername"
                    placeholder="SSH Login Username"
                    autoComplete="off"
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    errorText={errors.sshUsername}
                    value={values.sshUsername}
                  />
                  <TextField
                    name="sshPassword"
                    placeholder="SSH Login Password"
                    autoComplete="off"
                    fullWidth
                    margin="dense"
                    type="password"
                    onChange={handleChange}
                    errorText={errors.sshPassword}
                    value={values.sshPassword}
                  />
                </Box>
              </Box>

              <Box margin={{ top: 'medium' }}>
                <Typography variant="h5"><strong>Special Instructions</strong></Typography>
                <TextArea
                  id="instructions"
                  name="instructions"
                  onChange={handleChange}
                  errorText={errors.instructions}
                  className={cx({ [scss.error]: (errors.instructions) })}
                  value={values.instructions}
                />
              </Box>

              <Box margin={{ top: 'small' }}>
                <Typography paragraph variant="body1">
                  The information provided on form will be securly encrypted and forwarded to our support system - please watch your email for a verification of receipt.
                  Our support staff will reach out to you to begin the process.
                  Migrations typically take 3-5 business days but may take longer due to backlog or size of your websites.
                </Typography>
              </Box>
              <div style={{ textAlign: 'center' }}>
                <ErrorText>{error}</ErrorText>
              </div>
              <Box direction="row" justify="flex-end" margin={{ top: 'small' }}>
                <Button
                  type="submit"
                  variant="contained"
                  loading={status === 'loading'}
                  success={status === 'success'}
                >
                  Submit Migration Request
                </Button>
              </Box>
            </form>
          )}

        </Formik>
      </Box>
    </Box>
  );
};

MigrationForm.propTypes = {
  account: object,
  createMigrationNote: func,
  user: object,
};

export default connect(
  (state: { account: Account; user: {data: User}}) => ({
    account: state.account,
    user: state.user.data,
  }),
)(MigrationForm);
