import React, { Fragment, useEffect } from 'react';
import { object, string, func } from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';
import Loading from 'shared/styleguide/atoms/Loading';
import { getAppsById } from 'shared/modules/app/redux/selectors';
import { getReport } from '../../redux/actions';
import { routes } from './routes';

const FullReport = ({
  match, report, parentPath, apps, getReport,
}) => {
  // routing params
  const { accountID, timestamp, serverId } = match.params;

  useEffect(() => {
    getReport(accountID, serverId, timestamp);
  }, [accountID, timestamp, serverId, getReport]);

  if (report.status === 'loading' || report.status === 'pristine') {
    return <Loading text="Fetching Report..." />;
  }

  return (
    <Fragment>
      <Typography variant="h1" gutterBottom>Full Usage Report</Typography>
      <Typography variant="subtitle" gutterBottom>Report for {report.data?.meta?.serverName} ({report.data?.meta?.serverId})</Typography>
      <Route
        path={`${match.path}/:view*`}
        render={(props) => (<Navigation {...props} routes={routes} parentPath={match.url} fallback="summary" />)}
      />
      <Switch>
        {
          routes.concat().reverse().map(({ component: Component, ...child }) => {
            return (
              <Route key={child.to} path={`${match.path}/${child.to}`} render={(props) => <Component {...props} apps={apps} report={report.data} />} />
            );
          })
          }
      </Switch>
    </Fragment>
  );
};

FullReport.propTypes = {
  apps: object,
  getReport: func,
  match: object.isRequired,
  parentPath: string,
  report: object.isRequired,
};

export default connect(
  (state) => {
    return ({
      account: state.account,
      report: state.disk.report,
      apps: getAppsById(state),
    });
  },
  {
    getReport,
  },
)(FullReport);
