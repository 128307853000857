// const defauldDelimiter = (item, index) => {
//   csvStr += item.join(columnDelimiter);
//   if (index < jsonData.length - 1) {
//     csvStr += lineDelimiter;
//   }
// };

// const nestedDelimiter = ([label, values], index) => {
//   csvStr += item.join(columnDelimiter);
//   if (index < jsonData.length - 1) {
//     csvStr += lineDelimiter;
//   }
// };

const parseJSONToCSVStr = (data, labels = Object.keys(data)) => {
  let jsonData;
  if (Array.isArray(data)) {
    jsonData = data;
  } else {
    jsonData = Object.entries(data);
  }

  if (jsonData.length === 0) {
    return '';
  }

  const columnDelimiter = ',';
  const lineDelimiter = '\n';

  const csvColumnHeader = labels.join(columnDelimiter);
  let csvStr = csvColumnHeader + lineDelimiter;

  jsonData.forEach((item, index) => {
    if (item instanceof Object) {
      csvStr += Object.values(item).join(columnDelimiter);
    } else {
      csvStr += item.join(columnDelimiter);
    }
    if (index < jsonData.length - 1) {
      csvStr += lineDelimiter;
    }
  });

  return encodeURIComponent(csvStr);
};

const exportToCsvFile = (filename, data, labels) => {
  const csvStr = parseJSONToCSVStr(data, labels);
  const dataUri = `data:text/csv;charset=utf-8,${csvStr}`;

  const exportFileDefaultName = `${filename}.csv`;

  const linkElement = document.createElement('a');
  linkElement.setAttribute('href', dataUri);
  linkElement.setAttribute('download', exportFileDefaultName);
  linkElement.click();
};

export default exportToCsvFile;
