import * as base from 'shared/baseStates';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import {
  CSR_CREATE_REQUEST,
  CSR_CREATE_SUCCESS,
  CSR_CREATE_FAILURE,
  CSR_RESET,
} from './actions';

export const initialState = {
  ...base.record(),
  requestStr: '',
};

export function csr(state = initialState, action) {
  switch (action.type) {
    case CSR_CREATE_REQUEST:
      return { ...state, ...base.request() };
    case CSR_CREATE_SUCCESS:
      return {
        ...state,
        ...base.success(),
        requestStr: action.data.csr,
      };
    case CSR_CREATE_FAILURE:
      return { ...state, ...base.failure(action.errMessage, action.statusCode) };

    case LOGIN_LOGOUT_SUCCESS:
    case CSR_RESET:
      return { ...initialState };

    default:
      return state;
  }
}
