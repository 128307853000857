import React, { useState } from 'react';
import { object, func } from 'prop-types';
import parse from 'html-react-parser';
import reactStringReplace from 'react-string-replace';
import styled from '@emotion/styled';

import { Typography } from '@material-ui/core';

import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import Box from 'shared/styleguide/atoms/Box';
import { hashCode } from 'shared/utils';

import Notice from './Notice';

const PreviewBox = styled(Box)`
  border: 1px solid #ebebeb;
  background-color: #f8f9fa;
`;

const replacerRegex = new RegExp(/{{(\w+)}}/g);

const Subject = ({ templateState, variableValues }) => {
  return (
    <Box direction="row" gap="xsmall">
      <Typography variant="subtitle1" color="textSecondary">
        <strong>SUBJECT:</strong>
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        {
          reactStringReplace(templateState.subject, replacerRegex, (match) => {
            if (variableValues[match]) {
              return (<Typography variant="inherit" component="span" color="secondary">{variableValues[match]}</Typography>);
            } else {
              return match;
            }
          })
        }
      </Typography>
    </Box>
  );
};

Subject.propTypes = {
  templateState: object.isRequired,
  variableValues: object.isRequired,
};

const Body = ({ templateState, variableValues }) => {
  return parse(templateState.value, {
    replace: (domNode) => {
      if (replacerRegex.test(domNode.data)) {
        return (
          <div>
            {
              reactStringReplace(domNode.data, replacerRegex, (match) => {
                if (variableValues[match]) {
                  return (<Typography variant="inherit" component="span" color="secondary">{variableValues[match]}</Typography>);
                } else {
                  return match;
                }
              })
            }
          </div>
        );
      } // TODO: replace attribute on a tags as well....?
    },
  });
};

Body.propTypes = {
  templateState: object.isRequired,
  variableValues: object.isRequired,
};

const PreviewView = ({ templateState, setVariableSubstitutions }) => {
  const [variableValues, setVariableValues] = useState({});

  const handleChange = (field) => (e) => {
    setVariableValues({ ...variableValues, [field]: e.target.value });
    setVariableSubstitutions({ ...variableValues, [field]: e.target.value });
  };

  const subjectKey = hashCode(Object.values(variableValues).join('-'));

  return (
    <Box direction="row" justify="space-between" align="flex-start" gap="large" padding={{ top: 'medium' }}>
      <Box flex={1}>
        <Box direction="column" gap="xsmall" margin={{ bottom: 'small' }}>
          <Box direction="row" gap="xsmall">
            <Typography variant="subtitle1" color="textSecondary">
              <strong>TEMPLATE ID:</strong>
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">{templateState.id}</Typography>
          </Box>
          <Subject key={subjectKey} templateState={templateState} variableValues={variableValues} />
        </Box>
        <PreviewBox>
          {
            templateState.noticeConfig.text
            && (
              <Notice text={templateState.noticeConfig.text} color={templateState.noticeConfig.class} />
            )
          }
          <Box padding="small">
            <Body key={subjectKey} templateState={templateState} variableValues={variableValues} />
          </Box>
        </PreviewBox>
      </Box>
      <Box>
        <Typography variant="subtitle1" color="textSecondary">
          <strong>AVAILABLE VARIABLES:</strong>
        </Typography>
        {
          templateState.variables
            ? templateState.variables
              .filter((v) => {
                return !['NOTICE_TEXT', 'NOTICE_COLOR'].includes(v.id);
              })
              .map((variable) => (
                <WideTextField
                  key={variable.id}
                  value={variableValues[variable.id] || ''}
                  onChange={handleChange(variable.id)}
                  placeholder={`Enter ${variable.label} here...`}
                  InputLabelProps={{
                    shrink: true,
                  }}

                />
              )) : (<Typography variant="subtitle1" color="textSecondary">No Variables Available</Typography>)
        }
      </Box>
    </Box>
  );
};

PreviewView.propTypes = {
  setVariableSubstitutions: func.isRequired,
  templateState: object.isRequired,
};

export default PreviewView;
