import qs from 'qs';
import store2 from 'store2';
import logger from 'shared/3rdparty/logger';
import tracker from 'shared/3rdparty/pageTracking';
import { getHttp } from 'shared/http';
import makeApiAction from 'shared/utils/redux/apiActions'; // eslint-disable-line import/no-cycle
import { consolidateErrors } from 'shared/utils/validation';
import { setToken } from 'shared/utils/AuthTypes';
import { getUserTypeAndId } from 'shared/utils';
import * as AUTH from './constants';
import { isUserAccount } from './utils';

const loginRequest = () => ({
  type: AUTH.LOGIN_REQUEST,
});

const loginSuccess = () => ({
  type: AUTH.LOGIN_SUCCESS,
});

const loginFailure = (errMessage, statusCode) => ({
  type: AUTH.LOGIN_FAILURE,
  errMessage,
  statusCode,
});

export const loginLogout = (redirectTo = null) => (dispatch, getState, { history, api }) => {
  let type = AUTH.LOGIN_LOGOUT_SUCCESS;

  try {
    // unauthorize your token
    api().get('/auth/logout').then(() => {
      // delete token from store and reset fetch auth headers
      store2.remove('atomic_token');
      store2.remove('atomic_accounts');
      delete getHttp().defaults.headers.common.Authorization;
      if (redirectTo && history) {
        history.replace(redirectTo);
      }
    });
  } catch (err) {
    type = AUTH.LOGIN_LOGOUT_FAILURE;
  }

  return dispatch({ type });
};

const requestLogin = (payload, clientId, preserveUrl, grantType = 'password', isMgmtClient) => {
  const data = qs.stringify(Object.assign(payload, {
    grant_type: grantType,
    client_id: clientId,
  }));

  return async (dispatch, getState, { history, api }) => {
    dispatch(loginRequest());
    // eslint-disable-next-line
    delete payload.password;

    try {
      const accessTokenResponse = await api().post('/auth/access_token', data);
      const { access_token } = accessTokenResponse.data;
      logger.client.leaveBreadcrumb('Storing token', payload);
      store2.set('atomic_token', access_token);
      if (isUserAccount(clientId)) {
        // payload.target will only be set in SSO context, and `0` represents password auth.
        setToken(payload.target ?? 0, access_token);
      }
      // eslint-disable-next-line no-param-reassign
      api().defaults.headers.common.Authorization = `Bearer ${access_token}`;
      logger.client.leaveBreadcrumb('Setting authorization header', payload);
    } catch (err) {
      // TODO: handle error
      const statusCode = err.response?.status;
      if (statusCode !== 422) {
        logger.error(err, { user: payload });
      }
      if (typeof err.response?.data === 'string') {
        dispatch(loginFailure(err.response.data, statusCode));
      } else if (err.response?.data?.message) {
        dispatch(loginFailure(err.response.data.message, statusCode));
      } else if (err.response?.data?.body) {
        dispatch(loginFailure(consolidateErrors(err), statusCode));
      } else {
        dispatch(loginFailure(err.message, statusCode));
      }
      return;
    }

    // validate access token and check if we need to set up mfa
    try {
      await api().get('/auth/validate');
    } catch (err) {
      const statusCode = err.response?.status;
      if (err.response?.data?.includes('MFA Setup Required')) {
        history.replace('/sso-mfa');
      }
      const errMessage = 'Unable to validate token';
      logger.error(err, { context: errMessage, user: payload });
      dispatch(loginFailure(errMessage, statusCode));
      return;
    }

    let redirectUrl = preserveUrl;
    if (isUserAccount(clientId) && payload.target) {
      redirectUrl = {
        pathname: `/account/${payload.target}`,
      };
    }

    finishLogin(dispatch, payload, history, redirectUrl, isMgmtClient);
  };
};

const finishLogin = (dispatch, payload, history, preserveUrl, isMgmtClient) => {
  try {
    if (isMgmtClient) {
      return;
    }
    dispatch(loginSuccess());

    const [, userId] = getUserTypeAndId();
    tracker.push({
      'event': 'atomic_login',
      'account_id': userId,
    });

    logger.client.leaveBreadcrumb('Redirecting', ({
      preserveUrl,
    }));
    if (preserveUrl) {
      history.replace(`${preserveUrl.pathname}${preserveUrl.search ? preserveUrl.search : ''}`);
    } else {
      history.replace('/');
    }
  } catch (err) {
    if (err.response?.status !== 422) {
      logger.error(err, { context: 'Unable to login', user: payload });
    }
    dispatch(loginFailure(err.message));
  }
};

export function requestAccountLogin({ preserveUrl, ...payload }) {
  return requestLogin(payload, '3', preserveUrl);
}

export function requestAdminLogin({ preserveUrl, ...payload }) {
  return requestLogin(payload, '2', preserveUrl);
}

function shouldRequestLogin(state) {
  const { login } = state;

  return !login.isFetching;
}

export function authenticateWithSSOCode(pool, code, clientId, target, pusherId, isMgmtClient) {
  return requestLogin({
    code, pool, target, pusherId,
  }, clientId, false, 'sso_code', isMgmtClient);
}

const requestSsoUrls = (username, isAdmin) => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { pusherId } = params;
  let path = `/auth/sso-login?username=${encodeURIComponent(username)}&isAdmin=${isAdmin.toString()}`;
  if (pusherId && pusherId.length > 0) {
    let initialState = {
      pusherId,
    };
    initialState = encodeURI(window.btoa(JSON.stringify(initialState)));
    path += `&initialState=${initialState}`;
  }
  return ({ api }) => api().get(path);
};

/**
 * @param {string} username
 */
export const retrieveSsoUrls = (username, isAdmin = false) => makeApiAction(
  requestSsoUrls(username, isAdmin),
  AUTH.SSO_LOGIN_LOOKUP_REQUEST,
  AUTH.SSO_LOGIN_LOOKUP_SUCCESS,
  AUTH.SSO_LOGIN_LOOKUP_FAILURE,
  {
    username,
  },
  true,
);

export function requestAdminLoginIfNeeded(username, password, mfa, preserveUrl = {}) {
  return (dispatch, getState) => {
    if (shouldRequestLogin(getState())) {
      return dispatch(requestAdminLogin({
        username, password, mfa, preserveUrl,
      }));
    }
    return null;
  };
}

export function requestAccountLoginIfNeeded(username, password, mfa, preserveUrl = {}) {
  return (dispatch, getState) => {
    if (shouldRequestLogin(getState())) {
      return dispatch(requestAccountLogin({
        username, password, mfa, preserveUrl,
      }));
    }
    return null;
  };
}

export const forgotPassword = (email) => makeApiAction(
  () => getHttp().post('/accounts/password-reset', {
    email,
  }),
  AUTH.FORGOT_PASSWORD_REQUEST,
  AUTH.FORGOT_PASSWORD_SUCCESS,
  AUTH.FORGOT_PASSWORD_FAILURE,
  {
    email,
  },
);

export const validatePasswordResetToken = (token) => makeApiAction(
  () => getHttp().get(`/accounts/password-reset/${token}`),
  AUTH.VALIDATE_PASSWORD_TOKEN_REQUEST,
  AUTH.VALIDATE_PASSWORD_TOKEN_SUCCESS,
  AUTH.VALIDATE_PASSWORD_TOKEN_FAILURE,
  { token },
  true,
);

export const forgotPasswordReset = () => ({
  type: AUTH.FORGOT_PASSWORD_RESET,
});

export const forgotPasswordSet = (token, password) => makeApiAction(
  () => getHttp().patch('/accounts/password-reset', {
    token,
    password,
  }),
  AUTH.FORGOT_PASSWORD_SET_REQUEST,
  AUTH.FORGOT_PASSWORD_SET_SUCCESS,
  AUTH.FORGOT_PASSWORD_SET_FAILURE,
  true,
);

export const forgotPasswordAdmin = (email) => makeApiAction(
  () => getHttp().post('/accounts/admins/password-reset', {
    email,
  }),
  AUTH.FORGOT_PASSWORD_REQUEST,
  AUTH.FORGOT_PASSWORD_SUCCESS,
  AUTH.FORGOT_PASSWORD_FAILURE,
  {
    email,
  },
);

export const forgotPasswordSetAdmin = (token, password) => makeApiAction(
  () => getHttp().patch('/accounts/admins/password-reset', {
    token,
    password,
  }),
  AUTH.FORGOT_PASSWORD_SET_REQUEST,
  AUTH.FORGOT_PASSWORD_SET_SUCCESS,
  AUTH.FORGOT_PASSWORD_SET_FAILURE,
);

export const ssoMfaRequest = () => makeApiAction(
  ({ api }) => api().get('/auth/sso/mfa'),
  AUTH.SSO_MFA_REQUEST_REQUEST,
  AUTH.SSO_MFA_REQUEST_SUCCESS,
  AUTH.SSO_MFA_REQUEST_FAILURE,
);

export const ssoMfaVerify = (code) => makeApiAction(
  ({ api }) => api().post('/auth/sso/mfa', { code }),
  AUTH.SSO_MFA_VERIFY_REQUEST,
  AUTH.SSO_MFA_VERIFY_SUCCESS,
  AUTH.SSO_MFA_VERIFY_FAILURE,
);

export const resetMfaAccount = (email) => makeApiAction(
  () => getHttp().post('/accounts/2fa/reset', {
    email,
  }),
  AUTH.RESET_MFA_REQUEST,
  AUTH.RESET_MFA_SUCCESS,
  AUTH.RESET_MFA_FAILURE,
  {
    email,
  },
);

export const resetMfaResetState = () => ({
  type: AUTH.RESET_MFA_RESET,
});

export const resetMfaResetAccount = (token, password) => makeApiAction(
  () => getHttp().patch('/accounts/2fa/reset', {
    token,
    password,
  }),
  AUTH.RESET_MFA_SET_REQUEST,
  AUTH.RESET_MFA_SET_SUCCESS,
  AUTH.RESET_MFA_SET_FAILURE,
);

export const resetMfaAdmin = (email) => makeApiAction(
  () => getHttp().post('/accounts/admins/2fa/reset', {
    email,
  }),
  AUTH.RESET_MFA_REQUEST,
  AUTH.RESET_MFA_SUCCESS,
  AUTH.RESET_MFA_FAILURE,
  {
    email,
  },
);

export const resetMfaResetAdmin = (token, password) => makeApiAction(
  () => getHttp().patch('/accounts/admins/2fa/reset', {
    token,
    password,
  }),
  AUTH.RESET_MFA_SET_REQUEST,
  AUTH.RESET_MFA_SET_SUCCESS,
  AUTH.RESET_MFA_SET_FAILURE,
);
