import React from 'react';
import { Timeline } from 'react-twitter-widgets';

import { Grid } from '@material-ui/core';

/**
  * This component causes this error:
  * Warning: componentWillMount has been renamed, and is not recommended for use. See https://fb.me/react-unsafe-component-lifecycles for details.
  * Move code with side effects to componentDidMount, and set initial state in the constructor.
  * Rename componentWillMount to UNSAFE_componentWillMount to suppress this warning in non-strict mode. In React 17.x, only the UNSAFE_ name will work. To rename all deprecated lifecycles to their new names, you can run `npx react-codemod rename-unsafe-lifecycles` in your project source folder.
  * Please update the following components: AbstractWidget [which comes from Timeline]
 */

const Twitter = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item sm={12} md={6}>
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'pagelysupport',
          }}
          options={{
            username: 'pagelysupport',
            height: '600',
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'pagely',
          }}
          options={{
            username: 'pagely',
            height: '600',
          }}
        />
      </Grid>
    </Grid>
    /*  ref={(e) => { this.timeline = e; }}
            onLoad={() => {
              this.timeline.children[0].children[0].sandbox = 'allow-same-origin allow-scripts';
            }} */
  //  <div ref={(e) => { this.timeline = e; }}>
  //  </div>
  );
};

export default Twitter;
