import React from 'react';
import Paper from '@material-ui/core/Paper';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

/*
Why we pass the props like this
https://github.com/styled-components/styled-components/issues/135
 */

const StyledPaper = styled(({
  isAdmin, ...rest
}) => <Paper {...rest} />)(
  ({ isAdmin, theme }) => isAdmin && css`
    border-left: 8px solid ${theme.color.green};
  `,
);

export default StyledPaper;
