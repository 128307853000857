import * as base from 'shared/baseStates';
import { processAccountUrls } from 'shared/utils/AuthTypes';
import makeApiReducer, {
  baseState,
  initialRequestMethod,
  successMethod,
  failMethod,
} from 'shared/utils/redux/apiReducer';

import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_SET_FAILURE,
  FORGOT_PASSWORD_SET_REQUEST,
  FORGOT_PASSWORD_SET_SUCCESS,
  FORGOT_PASSWORD_RESET,
  VALIDATE_PASSWORD_TOKEN_REQUEST,
  VALIDATE_PASSWORD_TOKEN_SUCCESS,
  VALIDATE_PASSWORD_TOKEN_FAILURE,
  RESET_MFA_REQUEST,
  RESET_MFA_SUCCESS,
  RESET_MFA_FAILURE,
  RESET_MFA_SET_REQUEST,
  RESET_MFA_SET_SUCCESS,
  RESET_MFA_SET_FAILURE,
  RESET_MFA_RESET,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_LOGOUT_SUCCESS,
  LOGIN_LOGOUT_FAILURE,
  SSO_LOGIN_LOOKUP_SUCCESS,
  SSO_MFA_REQUEST_REQUEST,
  SSO_MFA_REQUEST_SUCCESS,
  SSO_MFA_REQUEST_FAILURE,
  SSO_MFA_VERIFY_REQUEST, SSO_MFA_VERIFY_SUCCESS, SSO_MFA_VERIFY_FAILURE,
} from './constants';

export const initialState = {
  ...base.record(),
  isLoggedIn: false,
};

export function login(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...base.request(),
        isLoggedIn: state.isLoggedIn,
      };
    case LOGIN_SUCCESS:
      return {
        ...base.success(),
        isLoggedIn: true,
      };
    case LOGIN_FAILURE:
      return {
        ...initialState,
        ...base.failure(action.errMessage, action.statusCode),
        isLoggedIn: state.isLoggedIn,
      };
    case LOGIN_LOGOUT_SUCCESS:
    case LOGIN_LOGOUT_FAILURE:
      return { ...initialState };
    case SSO_LOGIN_LOOKUP_SUCCESS:
      return {
        ...state,
        authMethods: processAccountUrls(action.data.data),
      };
    default:
      return state;
  }
}

export function forgotPassword(
  state = {
    ...baseState,
    validateResetLink: {},
  },
  action,
) {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case FORGOT_PASSWORD_SET_REQUEST:
      return {
        ...state,
        ...initialRequestMethod(state, action),
      };
    case VALIDATE_PASSWORD_TOKEN_REQUEST:
      return {
        ...state,
        validateResetLink: {
          ...initialRequestMethod(state.validateResetLink, action),
        },
      };
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_SET_SUCCESS:
      return {
        ...state,
        ...successMethod(state, action),
      };
    case VALIDATE_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        validateResetLink: {
          ...successMethod(state.validateResetLink, action),
        },
      };
    case FORGOT_PASSWORD_FAILURE:
    case FORGOT_PASSWORD_SET_FAILURE:
      return {
        ...state,
        ...failMethod(state, action),
      };
    case VALIDATE_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        validateResetLink: {
          ...failMethod(state.validateResetLink, action),
        },
      };
    case FORGOT_PASSWORD_RESET:
      return {
        ...baseState,
        errAllMessages: [],
        validateResetLink: {
          ...state.validateResetLink,
        },
      };
    default:
      return state;
  }
}

const resetMfaRequest = makeApiReducer(
  RESET_MFA_REQUEST,
  RESET_MFA_SUCCESS,
  RESET_MFA_FAILURE,
  RESET_MFA_RESET,
);
const resetMfaSet = makeApiReducer(
  RESET_MFA_SET_REQUEST,
  RESET_MFA_SET_SUCCESS,
  RESET_MFA_SET_FAILURE,
  RESET_MFA_RESET,
);

export function resetMfa(state = { ...baseState }, action) {
  const newState = resetMfaRequest(state, action);
  return resetMfaSet(newState, action);
}

export const ssoMfaRequest = makeApiReducer(
  SSO_MFA_REQUEST_REQUEST,
  SSO_MFA_REQUEST_SUCCESS,
  SSO_MFA_REQUEST_FAILURE,
  RESET_MFA_RESET,
);

export const ssoMfaVerify = makeApiReducer(
  SSO_MFA_VERIFY_REQUEST,
  SSO_MFA_VERIFY_SUCCESS,
  SSO_MFA_VERIFY_FAILURE,
  RESET_MFA_RESET,
);
