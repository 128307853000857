import React from 'react';
import { node } from 'prop-types';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';

import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { lightTheme, darkTheme } from 'shared/styleguide/theme/theme';
import { makeThemeVariables } from 'shared/styleguide/theme/colors';

const defaultContextData = {
  dark: false,
  toggle: () => {
    // This is intentional
  },
};

const ThemeContext = React.createContext(defaultContextData);
const useTheme = () => React.useContext(ThemeContext);

const useEffectDarkMode = () => {
  const [themeState, setThemeState] = React.useState({
    dark: false,
    hasThemeMounted: false,
  });
  React.useEffect(() => {
    const lsDark = (localStorage.getItem('theme') || '').includes('dark');
    setThemeState({ ...themeState, dark: lsDark, hasThemeMounted: true });
  }, []);

  return [themeState, setThemeState];
};

// eslint-disable-next-line react/prop-types
const SingleThemeProvider = ({ children, theme }) => {
  const themeVariables = makeThemeVariables(theme);
  return (
    <StylesProvider injectFirst>
      <EmotionThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <div css={css`
                ${themeVariables};
                height: 100vh;
                width: 100vw;
              `}
          >
            {children}
          </div>
        </MuiThemeProvider>
      </EmotionThemeProvider>
    </StylesProvider>
  );
};

const ThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = useEffectDarkMode();

  if (!themeState.hasThemeMounted) {
    return <div />;
  }

  const toggle = () => {
    const dark = !themeState.dark;
    localStorage.setItem('theme', dark ? 'dark' : 'light');
    setThemeState({ ...themeState, dark });
  };

  const theme = themeState.dark ? darkTheme : lightTheme;

  return (
    <ThemeContext.Provider
      value={{
        dark: themeState.dark,
        toggle,
      }}
    >
      <SingleThemeProvider theme={theme}>
        {children}
      </SingleThemeProvider>
    </ThemeContext.Provider>

  );
};

ThemeProvider.propTypes = {
  children: node,
};

export { ThemeProvider, useTheme, SingleThemeProvider };
