import React, { Fragment } from 'react';
import {
  object, func, bool, array, number,
} from 'prop-types';
import { connect } from 'react-redux';

import {
  updateAppConfig,
  resetAppOptions,
} from 'shared/modules/app/redux/actions';
import { FeatureToggle } from 'shared/flags';

import { fetchRecentWebconfigJobs } from 'shared/modules/status/redux/actions';
import { fetchAllPhpVersions } from 'shared/modules/server/redux/actions';
import { tagsByServer } from 'shared/modules/server/redux/selectors';
import { DOCKER_SERVER_TAG } from 'shared/modules/metadata/constants';
import Box from 'shared/styleguide/atoms/Box';
import WhiteScreenEliminator from './WhiteScreenEliminator';
import WebServerConfig from './WebServerConfig';
import PhpVersion from './PhpVersion';

export const CustomerActions = ({
  accountId, app, isAdmin, updateAppConfig, resetAppOptions, tagsByServer, getAllPhpVersions, appMeta, fetchRecentWebconfigJobs,
}) => {
  let isDockerizedPHPAvailable = false;
  // find correct set of serverIds
  const { primaryServerId } = app.data;
  const serverTags = tagsByServer.find((tagsSet) => tagsSet.serverId === primaryServerId);
  if (serverTags) {
    isDockerizedPHPAvailable = serverTags.tags.some((tag) => tag === DOCKER_SERVER_TAG);
  }

  return (
    <Box>
      <WebServerConfig app={app} updateAppConfig={updateAppConfig} />
      <FeatureToggle
        flags={[isDockerizedPHPAvailable]}
      >
        <PhpVersion
          accountId={accountId}
          app={app}
          appMeta={appMeta}
          updateAppConfig={updateAppConfig}
          getAllPhpVersions={getAllPhpVersions}
          fetchRecentWebconfigJobs={fetchRecentWebconfigJobs}
        />
      </FeatureToggle>
      <WhiteScreenEliminator
        isAdmin={isAdmin}
        resetAppOptions={resetAppOptions}
        app={app}
      />
    </Box>
  );
};

CustomerActions.propTypes = {
  accountId: number,
  app: object,
  appMeta: object,
  fetchRecentWebconfigJobs: func.isRequired,
  getAllPhpVersions: func.isRequired,
  isAdmin: bool,
  isOnP20Account: bool,
  resetAppOptions: func.isRequired,
  tagsByServer: array,
  updateAppConfig: func.isRequired,
};

CustomerActions.displayName = 'CustomerActions';

export default connect(
  (state) => ({
    tagsByServer: tagsByServer(state.tags.server),
    appMeta: state.app.metadata,
  }),
  {
    resetAppOptions,
    updateAppConfig,
    getAllPhpVersions: fetchAllPhpVersions,
    fetchRecentWebconfigJobs,
  },
)(CustomerActions);
