import React from 'react';
import { string, array } from 'prop-types';
import { css } from '@emotion/react';
import {
  Typography,
} from '@material-ui/core';
import CopyButton from 'shared/styleguide/molecules/CopyButton';

import Box from 'shared/styleguide/atoms/Box';
import {
  hashCode, dateOptions, formatMiB, formatDate,
} from 'shared/utils';
import { shade } from 'shared/styleguide/theme';

const CommonFile = ({
  path, type, files, message,
}) => {
  return (
    <Box flex={1}>
      {
        message && (
          <Typography variant="body1" color="warning">
            {message}
          </Typography>
        )
      }
      <Box direction="column">
        {
          files.map((file) => {
            return (
              <Box
                key={hashCode(file.path)}
                css={css`
                        &:hover { 
                          background: ${shade('#FFFFFF', 1)};
                        }
                      `}
                padding="small"
              >
                <Typography component="span" gutterBottom color="textPrimary" variant="body1">
                  {file.path.replace(path, '')} <CopyButton size="small" text={path} message="Location copied" />
                </Typography>

                <Box direction="row" wrap="wrap" justify="flex-start" gap="large">
                  <span>
                    <Typography component="span" variant="body2" color="textSecondary">
                      Last Modified:
                    </Typography>&nbsp;
                    <Typography component="span" variant="body2">
                      {formatDate(file['modified-time'], dateOptions)}
                    </Typography>
                  </span>
                  <span>
                    <Typography component="span" variant="body2" color="textSecondary">
                      Type:
                    </Typography>&nbsp;
                    <Typography component="span" variant="body2">
                      {type}
                    </Typography>
                  </span>
                  <span>
                    <Typography component="span" variant="body2" color="textSecondary">
                      Size:
                    </Typography>&nbsp;
                    <Typography component="span" variant="body2">
                      {formatMiB(file.used, 3)}
                    </Typography>
                  </span>
                </Box>
              </Box>
            );
          })
        }
      </Box>
    </Box>
  );
};

CommonFile.propTypes = {
  files: array.isRequired,
  message: string.isRequired,
  path: string.isRequired,
  type: string.isRequired,
};

export default CommonFile;
