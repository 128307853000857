import md5 from 'md5';

export const getPhone = (phone) => {
  if (phone && typeof phone === 'string') {
    const phoneText = phone.replace(/[^\d]/g, '');

    if (phoneText.length === 10 && phoneText.charAt(0) !== '0') {
      return phoneText.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
    }
  }

  return phone ? `${phone}` : 'None';
};

export const getPool = ({ poolName, defPoolId }) => {
  return `${poolName || 'None'} - ${defPoolId || 'None'}`;
};

export const getTitle = (account) => {
  const { name = 'NOT PROVIDED', companyName } = account;

  return `${name} ${companyName ? `@${companyName}` : ''}`;
};
