import React from 'react';
import { array } from 'prop-types';

import {
  TableCell,
  TableRow,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import SortableTable from 'shared/styleguide/molecules/Table/SortableTable';

import { formatMiB } from 'shared/utils';

const Row = ({
  path, used, //eslint-disable-line
}) => {
  return (
    <TableRow>
      <TableCell>
        {path} <CopyButton text={path} message="Location copied" />
      </TableCell>
      <TableCell align="left">{formatMiB(used)}</TableCell>
    </TableRow>
  );
};

const ByDir = ({ home }) => {
  return (
    <Box margin={{ top: 'xsmall' }}>
      <SortableTable
        headCells={[
          {
            id: 'path',
            numeric: false,
            disablePadding: false,
            label: 'Location',
          },
          {
            id: 'used',
            numeric: true,
            disablePadding: false,
            label: 'Usage',
            align: 'left',
          },
        ]}
        rows={home}
        paginate
        renderRow={(path) => {
          return (
            <Row
              key={path.tags.Username || path.path}
              {...path}
            />
          );
        }}
      />
    </Box>
  );
};

ByDir.propTypes = {
  home: array.isRequired,
};

export default ByDir;
