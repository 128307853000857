import React, { useState } from 'react';
import {
  func, array, object, string,
} from 'prop-types';
import { css } from '@emotion/react';
import { connect } from 'react-redux';

import {
  mdiGithub,
  mdiBitbucket,
  mdiGitlab,
} from '@mdi/js';

import {
  Grid,
  Typography,
  IconButton,
  Paper,
  Link,
  SvgIcon,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CircleCI from 'shared/styleguide/Icons/SVGs/CircleCI';
import { Toggle } from 'shared/styleguide/atoms/Switches';
import { remMapper } from 'shared/styleguide/theme/spacing';
import Box from 'shared/styleguide/atoms/Box';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import AppsDropdown from 'shared/styleguide/organisms/AppsDropdown';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { createIntegration, fetchIntegrationsList } from '../redux/actions';

const LabelColumn = ({ children }) => {
  const leftGridSpacing = 4;

  return (
    <Grid item sm={4} md={leftGridSpacing}>
      {children}
    </Grid>

  );
};
const BodyColumn = ({ children }) => {
  const rightGridSpacing = 8;

  return (
    <Grid item sm={8} md={rightGridSpacing}>
      {children}
    </Grid>

  );
};

const linkProps = {
  color: 'textPrimary',
  variant: 'body1',
  target: '_blank',
  rel: 'noopener nofollow noreferrer',
  css: css`
    display: inline-flex;
    align-items: center;
  `,
};

export const CreateIntegration = ({
  onClose, accountId, apps, app, createIntegration, fetchIntegrationsList,
}) => {
  const [appIds, setAppIds] = useState(app ? [app.data?.id] : []);
  const [name, setName] = useState('');
  const [clearCache, setClearCache] = useState(true);
  const [status, setStatus] = useState('initial');

  const areAllSelected = appIds.length === apps.length;

  const handleCreateIntegration = async () => {
    setStatus('loading');
    try {
      const data = {
        appIds,
        accountId: apps[0].accountId,
        name,
        config: { clearCache },
      };
      if (!name) {
        delete data.name;
      }

      await createIntegration(data, true);

      fetchIntegrationsList(accountId);
      setName('');
      setAppIds(app ? [app.data?.id] : []);
      setStatus('initial');
      onClose();
    } catch (err) {
      setStatus('failed');
    }
  };

  return (
    <Box margin={{ bottom: 'medium' }}>
      <Paper
        css={css`
          position: relative;
        `}
      >
        <IconButton
          onClick={onClose}
          css={css`
          position: absolute;
          top: ${remMapper('small')};
          right: ${remMapper('small')};
        `}
        >
          <Close />
        </IconButton>

        <Box padding="medium">
          <Box>
            <Typography gutterBottom variant="caption" color="textSecondary"><strong>Step 1</strong></Typography>
            <Typography gutterBottom variant="subtitle1" color="textPrimary">Name your integration</Typography>
            <WideTextField
              id="name"
              autoComplete="off"
              placeholder="Name"
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              value={name}
              onChange={(e) => { setName(e.target.value); }}
            />
          </Box>

          <Box margin={{ top: 'medium' }} gap="medium">
            <Box>
              <Typography gutterBottom variant="caption" color="textSecondary"><strong>Step 2</strong></Typography>
              <Typography gutterBottom variant="subtitle1" color="textPrimary">Permissions</Typography>
              <Typography gutterBottom variant="caption" color="textSecondary">Choose apps this integration can deploy to</Typography>

              <Box direction="row" gap="small" padding={{ top: 'small' }} align="center">
                <Box flex={1}>
                  <AppsDropdown
                    apps={apps}
                    values={appIds}
                    onChange={(values) => setAppIds(values ? values.map((v) => v.value) : [])}
                  />
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={() => {
                      if (areAllSelected) {
                        setAppIds([]);
                      } else {
                        setAppIds(apps.map((a) => a.id));
                      }
                    }}
                  >
                    {
                      areAllSelected ? 'Unselect All' : 'Select All'
                    }
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography gutterBottom variant="caption" color="textSecondary"><strong>Step 3</strong></Typography>
              <Typography gutterBottom variant="subtitle1" color="textPrimary">Clear cache</Typography>

              <Box key="clearCache" direction="row" align="center" justify="flex-start" gap="xxsmall">
                <Toggle
                  key="toggle-clearCache"
                  id="toggle-clearCache"
                  defaultToggled={clearCache}
                  onToggle={(e, toggle) => setClearCache(toggle)}
                  // Switches/index.js needs to be convereted
                />
                <Typography variant="body2" color="textSecondary">Clear cache after deploy to ensure changes are visible immediately</Typography>
              </Box>
            </Box>
          </Box>
          <Box direction="row" justify="space-between" align="flex-end" margin={{ top: 'medium' }}>
            <Box direction="column" gap="xxsmall">
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>Guides on the Providers we support:</Typography>
              <Link
                {...linkProps}
                href="https://support.pagely.com/hc/en-us/articles/360047749731"

              >
                <SvgIcon>
                  <path
                    d={mdiGithub}
                  />
                </SvgIcon>
                &nbsp;
                <span>
                  GitHub Actions
                </span>
              </Link>
              <Link
                {...linkProps}
                href="https://support.pagely.com/hc/en-us/articles/360047699772"
              >
                <SvgIcon>
                  <path
                    d={mdiGitlab}
                  />
                </SvgIcon>
                &nbsp;
                <span>
                  GitLab CI
                </span>
              </Link>
              <Link
                {...linkProps}
                href="https://support.pagely.com/hc/en-us/articles/360047149452"
              >
                <SvgIcon>
                  <path
                    d={mdiBitbucket}
                  />
                </SvgIcon>
                &nbsp;
                <span>
                  BitBucket Pipelines
                </span>
              </Link>
              <Link
                {...linkProps}
                href="https://support.pagely.com/hc/en-us/articles/360050828232"
              >
                <CircleCI color="inherit" />
                &nbsp;
                <span>
                  CircleCI
                </span>
              </Link>
            </Box>
            <Button
              status={status}
              variant="contained"
              color="secondary"
              onClick={handleCreateIntegration}
            >
              Create Integration
            </Button>
          </Box>
        </Box>

      </Paper>
    </Box>
  );
};

CreateIntegration.propTypes = {
  accountId: string,
  app: object,
  apps: array.isRequired,
  createIntegration: func.isRequired,
  fetchIntegrationsList: func.isRequired,
  onClose: func.isRequired,
};

export default connect(
  null,
  {
    createIntegration,
    fetchIntegrationsList,
  },
)(CreateIntegration);
