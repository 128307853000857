import { NumberLike } from 'shared/types/types';

/**
 * This checks if a clientId matches the client ID defined on the backend for a user account.
 *
 * The reason we check if it is `'3'` or `3` is because clientId could be a number string, or
 *  a plain number. This ensures we don't miss by the wrong type being passed in.
 */
export function isUserAccount(clientId: NumberLike): boolean {
  return clientId === '3' || clientId === 3;
}

/**
 * This checks if a clientId matches the client ID defined on the backend for an admin account.
 *
 * The reason we check if it is `'2'` or `2` is because clientId could be a number string, or
 *  a plain number. This ensures we don't miss by the wrong type being passed in.
 */
export function isAdminAccount(clientId: NumberLike): boolean {
  return clientId === '2' || clientId === 2;
}
