import * as reducer from 'shared/utils/redux/apiReducer';
import * as DNS from './constants';

export const dnsZones = (state = reducer.baseCollectionState, action) => {
  const method = state.loaded ? reducer.loadMethod : reducer.initialRequestMethod;

  switch (action.type) {
    case DNS.DELETE_DNS_ZONE_REQUEST:
    case DNS.CREATE_DNS_ZONE_REQUEST:
    case DNS.GET_DNS_ZONE_REQUEST:
      return {
        ...method(state, action),
        done: false,
        doing: true,
      };
    case DNS.DELETE_DNS_ZONE_FAILURE:
    case DNS.CREATE_DNS_ZONE_FAILURE:
    case DNS.GET_DNS_ZONE_FAILURE:
      return {
        ...reducer.fail(state, action),
        apiErrors: action.errMessage,
        apiErrorStatusCode: action.statusCode,
      };
    case DNS.DELETE_DNS_ZONE_SUCCESS:
      return {
        ...reducer.successMethod(state, action),
        data: state.data.filter((z) => z.extId !== action.params.extId),
      };
    case DNS.CREATE_DNS_ZONE_SUCCESS:
      return {
        ...reducer.successMethod(state, action),
        data: [...state.data, action.data],
      };
    case DNS.GET_DNS_ZONE_SUCCESS:
      return {
        ...reducer.successMethod(state, action),
      };
    case DNS.DNS_ZONES_RESET:
      return reducer.baseCollectionState;
    default:
      return state;
  }
};

export const dnsRecords = (state = reducer.baseCollectionState, action) => {
  const method = state.loaded ? reducer.loadMethod : reducer.initialRequestMethod;

  switch (action.type) {
    case DNS.UPDATE_DNS_RECORD_REQUEST:
    case DNS.DELETE_DNS_RECORD_REQUEST:
    case DNS.GET_DNS_RECORD_REQUEST:
      return {
        ...method(state, action),
        done: false,
        doing: true,
      };
    case DNS.UPDATE_DNS_RECORD_FAILURE:
    case DNS.GET_DNS_RECORD_FAILURE:
      return {
        ...reducer.fail(state, action),
        apiErrors: action.errMessage,
        apiErrorStatusCode: action.statusCode,
      };
    case DNS.GET_DNS_RECORD_SUCCESS:
      return {
        ...reducer.successMethod(state, action),
      };
    case DNS.UPDATE_DNS_RECORD_SUCCESS:
    case DNS.DELETE_DNS_RECORD_SUCCESS:
      return {
        ...reducer.successMethod(state, action),
        data: {
          ...state.data,
          recordSet: action.data.recordSet,
        },
      };
    case DNS.DNS_RECORDS_RESET:
      return reducer.baseCollectionState;
    default:
      return state;
  }
};
