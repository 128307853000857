import React, { Fragment } from 'react';
import { object } from 'prop-types';
import {
  Typography, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Switch,
} from '@material-ui/core';

import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';

import { getErrorMessages } from '../../../utils';

const IpList = ({
  values,
  form,
}) => {
  const ipErrors = [
    form.errors?.ipListType,
    form.errors?.ips ? getErrorMessages(form.errors?.ips).join(', ') : null,
  ].filter((e) => e);

  return (
    <Fragment>
      <Box direction="row" align="center" padding={{ top: 'xsmall' }}>
        <Typography variant="h5" color="initial"><strong>IP List</strong></Typography>
        <Switch
          checked={Boolean(values.ip)}
          onChange={(e) => form.handleChange('ip', e.target.checked)}
        />
      </Box>
      <Box direction="column">
        <Box direction="row" margin={{ top: 'small', bottom: 'small' }}>
          <TextField
            variant="outlined"
            margin="dense"
            css={{ marginTop: 0 }}
            placeholder="Name (optional)"
            value={values.ipListName || ''}
            onChange={(e) => form.handleChange('ipListName', e.target.value)}
            disabled={!values.ip}
          />
        </Box>
        <Box>
          <Typography gutterBottom variant="caption" color="textSecondary">Choose Access</Typography>
        </Box>
        <FormControl>
          {
            /**
             * This is a little confusing
             * default, true, is to allow traffic, and
             * inverse (false) is to block it
             */
          }
          <RadioGroup
            value={values?.ipListType || ''}
            onChange={(e) => form.handleChange('ipListType', e.target.value)}
          >
            <FormControlLabel labelPlacement="end" value="allow" control={<Radio disabled={Boolean(!values.ip)} />} label="Only allow traffic from" />
            <FormControlLabel labelPlacement="end" value="deny" control={<Radio disabled={Boolean(!values.ip)} />} label="Block traffic from" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <Box padding={{ top: 'xsmall' }}>
          <Typography gutterBottom variant="caption" color="textSecondary">Add comma-separated IP addresses or CIDR notation</Typography>
        </Box>
        <TextField
          fullWidth
          id="ips"
          name="ips"
          autoComplete="off"
          onChange={(e) => form.handleChange('ipsString', e.target.value)}
          value={values.ipsString}
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          margin="dense"
          variant="outlined"
          multiline
          minRows={6}
          error={Boolean(form.errors?.ips)}
          disabled={!values.ip}
        />
      </Box>
      {
        !!ipErrors.length && (
          <Box align="center">
            <ErrorText>{ipErrors.join(', ')}</ErrorText>
          </Box>
        )
      }
    </Fragment>
  );
};

IpList.propTypes = {
  form: object,
  values: object,
};

export default IpList;
