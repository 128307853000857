export const keyValidate = (text) => {
  text = text.trim(); // eslint-disable-line no-param-reassign

  let validationMessage = null;

  if (
    text.match(/^-+BEGIN [\w\s]*PRIVATE KEY-+\n/)
    && text.match(/\n-+END [\w\s]*PRIVATE KEY-+$/)
  ) {
    return {
      validated: true,
      validationMessage,
    };
  }

  if (!text || !text.match(/^-+BEGIN [\w\s]*PRIVATE KEY-+\n/)) {
    validationMessage = 'Certificate must begin with -----BEGIN PRIVATE KEY----- or -----BEGIN RSA PRIVATE KEY-----';
  } else if (!text || !text.match(/\n-+END [\w\s]*PRIVATE KEY-+$/)) {
    validationMessage = 'Certificate must end with -----END PRIVATE KEY----- or -----END RSA PRIVATE KEY-----';
  }

  return {
    validated: false,
    validationMessage,
  };
};

export const certValidate = (text) => {
  text = text.trim(); // eslint-disable-line no-param-reassign

  let validationMessage = null;

  if (
    !text || (
      text.match(/^-+BEGIN [\w\s]*CERTIFICATE-+\n/)
      && text.match(/\n-+END [\w\s]*CERTIFICATE-+$/)
    )
  ) {
    return {
      validated: true,
      validationMessage,
    };
  }

  if (!text || !text.match(/^-+BEGIN [\w\s]*CERTIFICATE-+\n/)) {
    validationMessage = 'Certificate must begin with -----BEGIN CERTIFICATE-----';
  } else if (!text || !text.match(/\n-+END [\w\s]*CERTIFICATE-+$/)) {
    validationMessage = 'Certificate must end with -----END CERTIFICATE-----';
  }

  return {
    validated: false,
    validationMessage,
  };
};
