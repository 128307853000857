import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import Card from 'shared/styleguide/atoms/Card/Card';
import Box from 'shared/styleguide/atoms/Box';
import type { Account } from 'shared/modules/account/types';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import type { UserPool } from 'shared/modules/account/redux/sso/types';
import type { NumberLike } from 'shared/types/types';
import { createUserPool } from 'shared/modules/team/redux/sso/actions';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

interface Props {
  account: Account;
  createUserPool: (ownerUserId: NumberLike, companyName: string) => Promise<UserPool>;
}

export const Begin: React.FC<Props> = (props) => {
  const { account } = props;
  return (
    <Box fullWidth gap="large">
      <Box>
        <Typography variant="body1">
          Single Sign On is currently disabled and needs to be set up.
          In order for SAML to be set up for your collaborators, there is some configuration required for your identity provider.
          To begin the configuration, press the <b>Begin Setup</b> button below.
        </Typography>
      </Box>
      <Box justify="space-between" direction="row">
        <TextLink href="https://pagely.zendesk.com/hc/en-us/articles/4410414546331">Common Identity Providers and their setup.</TextLink>
        <Button data-testid="sso-begin-setup-button" onClick={() => props.createUserPool(account.id, account.companyName)}>
          Begin Setup
        </Button>
      </Box>
    </Box>
  );
};

export default Begin;
