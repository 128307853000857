import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Formik } from 'formik';

import {
  Drawer, Toolbar, IconButton, Button, Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import Box from 'shared/styleguide/atoms/Box';
import NoteEdit from 'admin/modules/notes/routes/Edit/NoteEdit';

import {
  consolidateErrors, getAllValidationsWithKeys,
} from 'shared/utils/validation';
import { useCreateNoteMutation } from '../../query';
import { NoteForm } from '../../types';
import { clientExpiryToServerExpiry } from '../../expiryUtils';

const NoteCreator = (props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const [createNote, result] = useCreateNoteMutation();

  const handleClose = () => {
    history.replace(match.url.replace(/\/note\/.*/, ''));
  };

  const handleSave = async ({ burnAfterReading, expires, ...values }: NoteForm, actions) => {
    try {
      await createNote({
        accountId: props.account.id,
        accountName: props.account.name,
        authorId: props.user.id,
        authorName: props.user.name,
        ...(expires && { expires: clientExpiryToServerExpiry(expires), burnAfterReading }),
        ...values,
      }).unwrap();
      handleClose();
    } catch (err) {
      actions.setErrors(getAllValidationsWithKeys({ response: err }));
    }
  };

  return (
    <Drawer anchor="right" open>
      <Formik
        initialValues={{
          note: '',
          title: '',
          noteType: 'admin',
          noteContentType: 'text/plain',
        }}
        onSubmit={handleSave}
        validate={({ note, title }) => {
          const errors = {} as any;

          if (!note) errors.note = 'Note is required';
          if (!title) errors.title = 'Title is required';

          return errors;
        }}
      >
        {
          ({
            handleSubmit, handleReset, dirty, values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Toolbar>
                <Box direction="row" justify="space-between" align="center" flex={1}>
                  <Box direction="row" gap="xsmall">
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={(!dirty || !values.note || !values.title)}
                    >
                      Save Note
                    </Button>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={handleReset}
                      disabled={!dirty}
                    >
                      Reset
                    </Button>
                  </Box>
                  <Box>
                    <IconButton
                      edge="end"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleClose}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
              </Toolbar>
              {
                (result.isError) && (
                  <Typography color="error">{consolidateErrors(result.error)}</Typography>
                )
              }
              <NoteEdit />
            </form>
          )
        }
      </Formik>
    </Drawer>
  );
};

export default NoteCreator;
