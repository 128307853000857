import React from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@material-ui/core';
import Box from '../Box';

export const Table = ({ children, className = '', ...rest }) => (
  <Box
    direction="column"
    css={{ width: '100%' }}
    className={className}
    {...rest}
  >
    {children}
  </Box>
);

export const TableHeading = ({ children, className = '', ...rest }) => (
  <Box
    direction="row"
    justify="space-between"
    className={className}
    {...rest}
  >
    {children}
  </Box>
);

export const Row = ({
  // eslint-disable-next-line react/prop-types
  children, className = '', noBorder = false, ...rest
}) => {
  const theme = useTheme();
  const borderColorWeight = theme.palette.type === 'light' ? 300 : 800;
  const border = `border-bottom: 1px solid ${theme.palette.grey[borderColorWeight]};`;
  return (
    <Box
      direction="row"
      justify="space-between"
      align="center"
      padding="xsmall"
      css={css`
        ${!noBorder && border}
        &:hover {
          background: ${theme.palette.action.hover};
        }
      `}
      className={className}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const Item = ({ children, className = '', ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
);
