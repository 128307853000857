import * as base from 'shared/baseStates';
import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  PERM_REQUEST,
  PERM_SUCCESS,
  PERM_FAILURE,
} from 'shared/modules/permissions/user/actions';
import {
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAILURE,
} from 'shared/modules/account/redux/constants';
import {
  LOGIN_LOGOUT_SUCCESS,
  LOGIN_LOGOUT_FAILURE,
} from 'shared/modules/authentication/redux/constants';
import isPagelyAdmin from 'shared/utils/isAdmin';
import {
  loadMethod,
  successMethod,
  failMethod,
} from 'shared/utils/redux/apiReducer';

export const initialState = {
  ...base.record(),
  user: null,
};

export function user(state = initialState, action) {
  // if user is an Admin and they updated Customer settings,
  // admin will lose "IsAdmin" privileges
  const isAdmin = isPagelyAdmin(state.user);

  // check if an Admin updated their own settings
  const isTargetAdmin = isPagelyAdmin(action.data);

  if (isAdmin && !isTargetAdmin) {
    return state;
  }

  switch (action.type) {
    case ACCOUNT_UPDATE_REQUEST:
    case USER_REQUEST:
      return {
        ...loadMethod(state, action),
        ...base.request(),
      };
    case ACCOUNT_UPDATE_SUCCESS:
    case USER_SUCCESS:
      return {
        ...successMethod(state, action),
        ...base.success(),
        user: action.data,
      };
    case ACCOUNT_UPDATE_FAILURE:
    case USER_FAILURE:
      return {
        ...initialState,
        ...failMethod(state, action),
        ...base.failure(action.errMessage, action.statusCode),
      };
    case LOGIN_LOGOUT_SUCCESS:
    case LOGIN_LOGOUT_FAILURE:
      return { ...initialState };
    default:
      return state;
  }
}

export const permInitialState = {
  ...base.record(),
  canAccess: [],
  whoCanAccess: [],
};

const mergeCanAccess = (permissionRecords) => {
  const reduced = permissionRecords.reduce((accumulator, curr) => {
    const target = accumulator.findIndex((acc) => acc.targetId === curr.targetId);
    if (target > -1) {
      accumulator[target].domains.push(curr.domain);
    } else {
      accumulator.push({
        ...curr,
        domains: [curr.domain],
      });
    }

    return accumulator;
  }, []);

  // we now have an array of account we can access
  return reduced;
};

export function permissions(state = permInitialState, action) {
  switch (action.type) {
    case PERM_REQUEST:
      return { ...state, ...base.request() };
    case USER_SUCCESS:
      return {
        isAdmin: isPagelyAdmin(action.data),
        ...state,
      };
    case PERM_SUCCESS: {
      // want to know how many organizations person has access to?
      // Merge canAccess permissions
      const directory = {};
      action.data.canAccess.forEach((account) => { directory[account.targetId] = account; });
      return {
        ...state,
        ...base.success(),
        ...action.data,
        canAccessAccounts: mergeCanAccess(action.data.canAccess),
        directory,
      };
    }
    case PERM_FAILURE:
      return { ...state, ...base.failure(action.errMessage, action.statusCode, action.validation) };
    case LOGIN_LOGOUT_FAILURE:
    case LOGIN_LOGOUT_SUCCESS:
      return { ...permInitialState };
    default:
      return state;
  }
}
