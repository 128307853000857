import { capitalize } from 'shared/utils';
import {
  schemes, validSchemeRegex, validSchemeDomainRegex, validFullUrlRegex,
} from '../constants';

const isValidField = (type, value) => {
  let regex;
  switch (type) {
    case 'scheme':
      return schemes.includes(value);
    case 'host':
      regex = new RegExp(/^[a-zA-Z0-9-.]+$/);
      return !!value?.match(regex);
    case 'query':
    case 'path':
      regex = new RegExp(/^[^=]+$/);
      return !!value?.match(regex);
    default:
      return false;
  }
};

const getQueryValidationMessages = (type, field, query) => {
  const messages = [];
  if (!query.key) {
    messages.push(`"${capitalize(type)}" query key must not be empty.`);
  }

  if (query.key && !isValidField(field, query.key)) {
    messages.push(`Invalid "${type}" query key.`);
  }

  if (type === 'from') {
    if (!query.value && !['exists', 'empty'].includes(query.compare)) {
      messages.push(`"${capitalize(type)}" query value must not be empty if method is one of [equals, starts-with, ends-with, contains].`);
    }

    if (!query.value && !query.compare) {
      messages.push(`"${capitalize(type)}" query value or method must be set.`);
    }
  } else if (!query.value) {
    messages.push(`"${capitalize(type)}" query value must be set.`);
  }

  if (messages.length > 0) {
    return messages.join(' ');
  }
};

const hasSlashSuffix = (value) => {
  return value.charAt(0) === '/';
};

const isAllowedHostname = (value, domainsList) => {
  if ((!value.compare || value.compare === 'equals') && !domainsList.includes(value.value)) {
    // entire domain name match not found
    return false;
  }

  if (value.compare && value.compare !== 'equals') {
    // allow partial domain match
    let valid = false;
    domainsList.forEach((appDomain) => {
      if (appDomain.includes(value.value)) {
        // partial match found
        valid = true;
      }
    });

    return valid;
  }

  return true;
};

export const validateValues = (values, domainsList) => {
  const body = { ...values };

  const hasValues = {
    fromMain: false,
    fromQuery: false,
    toMain: false,
    toQuery: false,
  };

  const errorMessages = [];

  Object.entries(body.from).forEach(([field, value]) => {
    if (field !== 'query' && value.value) {
      hasValues.from = true;

      if (field === 'host' && !isAllowedHostname(value, domainsList)) {
        errorMessages.push('"From" domain must match an app domain.');
      } else if (!isValidField(field, value.value)) {
        errorMessages.push(`Invalid "from" ${field} value.`);
      }
    }

    if (field === 'query') {
      let queryErrorMessage;
      value
        .filter((query) => !(!query.key && !query.value && !query.compare))
        .forEach((query) => {
          if (!hasValues.from && !errorMessages.length && (query.key && (query.compare || query.value))) {
            // at least one thing is set
            hasValues.from = true;
          }

          queryErrorMessage = getQueryValidationMessages('from', field, query);

          if (queryErrorMessage) {
            errorMessages.push(queryErrorMessage);
          }
        });
    }
  });

  Object.entries(body.to).forEach(([field, value]) => {
    if (field !== 'query' && value.value) {
      hasValues.to = true;
      if (!isValidField(field, value.value)) {
        errorMessages.push(`Invalid "to" ${field === 'host' ? 'domain' : field} value.`);
      }
    }

    if (field === 'query') {
      let queryErrorMessage;
      value
        .filter((query) => !(!query.key && !query.value))
        .forEach((query) => {
          if (!hasValues.to && !errorMessages.length && query.key && query.value) {
            // at least one thing is set
            hasValues.to = true;
          }

          queryErrorMessage = getQueryValidationMessages('to', field, query);
          if (queryErrorMessage) {
            errorMessages.push(queryErrorMessage);
          }
        });
    }
  });

  const { scheme, host, path } = body.from;
  if (
    !scheme?.value
    && !host?.value
    && path?.value
    && !['ends-with', 'contains'].includes(path?.compare)
    && !hasSlashSuffix(path.value)
  ) {
    errorMessages.push('Path-only "From" URLs must start with "/" unless method is one of [ends-with, contains].');
  }

  if (
    !body.to?.scheme?.value
    && !body.to?.host?.value
    && body.to?.path?.value
    && !hasSlashSuffix(body.to?.path.value)
  ) {
    errorMessages.push('Path-only "To" URLs must start with "/"');
  }

  if (!hasValues.from || !hasValues.to || errorMessages.length > 0) {
    if (!hasValues.from) {
      errorMessages.unshift('"From" is not set.');
    }

    if (!hasValues.to) {
      errorMessages.unshift('"To" is not set.');
    }

    return errorMessages;
  }

  return [];
};

export const isParsable = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  if (decodedUri.substring(0, 4) === 'http' && !(decodedUri.match(validSchemeRegex) || decodedUri.match(validSchemeDomainRegex) || decodedUri.match(validFullUrlRegex))) {
    return false;
  }

  return true;
};
