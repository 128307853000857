import React, { Component } from 'react';
import {
  string, func, arrayOf, bool,
} from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddBox';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';

import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import { Grid } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import { FloatingErrorText } from 'shared/styleguide/typography';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { remMapper } from 'shared/styleguide/theme/spacing';
import TextField from '../../atoms/Input/TextField';

export default class ExpandingTextField extends Component {
  static propTypes = {
    className: string,
    disableAdd: bool,
    disabled: bool,
    displayOnly: bool,
    error: string,
    fullWidth: bool,
    id: string.isRequired,
    inputs: arrayOf(string),
    onChange: func.isRequired,
    placeholder: string,
    variant: string,
    wide: bool,
    // validate: func,
  };

  constructor(props) {
    super(props);

    this.state = {
      inputs: this.props.inputs?.length ? this.props.inputs : [''],
      errors: this.props.error || '',
    };
  }

  componentDidUpdate(oldProps) {
    if ((!oldProps.error && this.props.error) && (!this.state.errors)) {
      this.setState({ errors: this.props.error });
    } else if (oldProps.error && !this.props.error && this.state.errors) {
      this.setState({ errors: '' });
    } else if (this.props.error && this.state.errors !== this.props.error) {
      this.setState({ errors: this.props.error });
    } else if (oldProps?.inputs?.length !== this.props.inputs?.length) {
      this.setState({ inputs: this.props.inputs?.length ? this.props.inputs : [''] });
    }
  }

  // validate = () => {
  //   const [isValid, error] = this.props.validate(this.state.inputs);
  //   // we can do something with validated in the future
  //   this.setState({ error });
  // }

  handleChange = (index, text) => {
    // update state for local copy
    // eslint-disable-next-line react/no-access-state-in-setstate
    const updatedInputs = this.state.inputs.slice(); // copy the array

    updatedInputs[index] = text;
    this.setState({ inputs: updatedInputs });

    // update parent with this.props.onChange
    this.props.onChange(updatedInputs);
  };

  render() {
    const {
      id, className, disabled, displayOnly, disableAdd, fullWidth, placeholder, wide = false, variant = 'compact',
    } = this.props;
    const TextFieldComponent = wide ? WideTextField : TextField;

    return (
      <div className={className}>
        <div style={{ position: 'relative', flex: fullWidth ? '1' : null }}>
          {
            this.state.inputs.map((input, index) => (
              <Box direction="row" key={`${id}-expanding-${index}`}>
                <Box flex={1}>
                  <TextFieldComponent
                    id={`${id}-expanding-${index}`}
                    margin="dense"
                    value={input}
                    onChange={(e, text) => {
                      e.preventDefault();
                      if (!displayOnly) {
                        this.handleChange(index, text);
                      }
                    }}
                    fullWidth={fullWidth}
                    autoFocus={this.state.inputs.length > 1 && index === this.state.inputs.length - 1} //eslint-disable-line
                    placeholder={index === this.state.inputs.length - 1 ? placeholder : ''} //eslint-disable-line
                    multiline
                    maxRows={4}
                    disabled={disabled}
                  />
                </Box>
                {
                  ((!disabled && !displayOnly && !disableAdd)) && (
                    index !== this.state.inputs.length - 1 ? (
                      <Box direction="column" align="center" justify="center">
                        <IconButton
                          onClick={() => {
                            this.setState((state) => {
                              const newState = [...state.inputs];
                              newState.splice(index, 1);
                              this.props.onChange(newState);
                              return ({
                                inputs: newState,
                              });
                            });
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Box>
                    ) : (
                      variant === 'compact' && (
                      <Box direction="column" align="center" justify="center">
                        <IconButton
                          onClick={() => {
                            this.setState((state) => ({
                              inputs: state.inputs.concat(''),
                            }));
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                      )
                    )
                  )
                }
              </Box>
            ))
          }
          {
            variant === 'full' && (
              <Button
                css={{ marginTop: remMapper('xsmall') }}
                variant="outlined"
                onClick={() => {
                  this.setState((state) => ({
                    inputs: state.inputs.concat(''),
                  }));
                }}
                startIcon={<AddCircleIcon />}
              >
                Add Row
              </Button>
            )
          }
          <FloatingErrorText>{this.state.errors}</FloatingErrorText>
        </div>
      </div>
    );
  }
}
