import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { css } from '@emotion/react';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { Checkbox, RadioButton, RadioButtonGroup } from 'shared/styleguide/atoms/Switches';
import Box from 'shared/styleguide/atoms/Box';

export default class Multisite extends Component {
  static propTypes = {
    app: object.isRequired,
    updateAppConfig: func,
  };

  state = {
    multisite: this.props.app.data.multisite,
    multisiteType: this.props.app.data.multisiteType,
  };

  render() {
    const { app, updateAppConfig } = this.props;

    const { multisite } = this.state;
    return (
      <Box direction="row">
        <Box flex={1}>
          <Checkbox
            label="Multisite"
            checked={multisite}
            onCheck={(e, isChecked) => this.setState((state) => ({ multisite: !multisite, multisiteType: isChecked ? (state.multisiteType || 'subfolder') : null }))}
          />
          {
            multisite && (
              <div css={css`margin-left: 20px;`}>
                <RadioButtonGroup name="multisite" valueSelected={this.state.multisiteType} onChange={(e, v) => { this.setState({ multisiteType: v }); }}>
                  <RadioButton
                    value="subfolder"
                    label="Sub Folders" // add example, domain.com/site1, domain.com/site2
                  />
                  <RadioButton
                    value="subdomain"
                    label="Sub Domains" // add example, domain.com/site1, domain.com/site2
                  />
                </RadioButtonGroup>
              </div>
            )
          }
        </Box>
        <Box flex={2} direction="column" gap="small">
          <div>
            <Button
              style={{ marginTop: 16, marginRight: 8 }}
              variant="outlined"
              color="secondary"
              disabled={app.doing}
              onClick={() => {
                const update = {
                  enabled: multisite,
                };

                if (multisite) {
                  update.type = this.state.multisiteType;
                }
                updateAppConfig({
                  type: 'multisite',
                  appId: app.data.id,
                  update,
                });
              }}
            >
              Update Configuration
            </Button>
          </div>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              disabled={app.doing}
              onClick={() => {
                updateAppConfig({
                  type: 'reset-wp-config-hosting',
                  appId: app.data.id,
                });
              }}
            >
              Reset wp-config-hosting.php
            </Button>
          </div>
        </Box>
      </Box>

    );
  }
}
