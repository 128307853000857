import React, { Component } from 'react';
import {
  string, func, bool, object, oneOfType,
} from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Close';
import { Tooltip, Typography } from '@material-ui/core';
import classnames from 'classnames';
import { slideRight, slideLeft } from './ConfirmableButton.scss';

class ConfirmableButton extends Component {
  static propTypes = {
    activeColor: string,
    callback: func,
    disabled: bool,
    expandText: string,
    icon: oneOfType([func, object]),
    label: string,
    size: string,
    tooltip: string,
  };

  state = {
    clicked: false,
  };

  handleClick = (...args) => {
    if (this.state.clicked) {
      this.props.callback(...args);
      this.setState({ clicked: false });
    } else {
      this.setState({ clicked: true });
    }
  };

  handleCancel = () => this.setState({ clicked: false });

  renderCancel = (size) => (
    <Tooltip title="Cancel">
      <span>
        <IconButton
          className={slideRight}
          key="cancel"
          onClick={this.handleCancel}
          size={size || 'medium'}
        >
          <CancelIcon />
        </IconButton>
      </span>
    </Tooltip>
  );

  renderButton = () => {
    const {
      icon,
      activeColor,
      disabled,
      expandText,
      callback,
      className,
      tooltip,
      size,
      ...rest
    } = this.props;
    const { clicked } = this.state;

    const style = {};

    if (clicked) {
      style.color = activeColor;
    }

    if (clicked) {
      return (
        <Tooltip title={`Confirm ${tooltip}`}>
          <span>
            <IconButton
              {...rest}
              size={size || 'medium'}
              color="inherit"
              style={style}
              className={classnames(className, clicked ? slideLeft : null)}
              key="delete"
              onClick={this.handleClick}
              disabled={disabled}
            >
              {React.createElement(icon)}
            </IconButton>
          </span>
        </Tooltip>
      );
    }
    return (
      <IconButton
        {...rest}
        size={size || 'medium'}
        color="default"
        style={style}
        className={classnames(className, clicked ? slideLeft : null)}
        key="delete"
        onClick={this.handleClick}
        disabled={disabled}
      >
        {React.createElement(icon)}
      </IconButton>
    );
  };

  render() {
    const {
      expandText,
      label,
      size,
    } = this.props;

    const { clicked } = this.state;

    const labelVariant = size === 'small' ? 'body2' : 'body1';

    return (
      <span
        style={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {this.renderButton()}
        <Typography variant={labelVariant}>
          {clicked ? expandText || 'Click to Confirm' : label}
        </Typography>
        {clicked ? this.renderCancel(size) : null}
      </span>
    );
  }
}

export default ConfirmableButton;
