/* eslint max-len: "off" */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { getAppsById } from 'shared/modules/app/redux/selectors';
import { createCSRAction, reset } from 'shared/modules/ssl/redux/csr/actions';
import { countries } from 'shared/utils/constants';
import RequiredForm from './RequiredForm';
import OptionalForm from './OptionalForm';
import ReviewSubmit from './ReviewSubmit';
import DownloadCSR from './DownloadCSR';
import SelectDomain from './SelectDomain';

export class CreateCSR extends Component {
  static propTypes = {
    appsById: PropTypes.object.isRequired,
    createCSRAction: PropTypes.func.isRequired,
    csr: PropTypes.shape({
      errMessage: PropTypes.string.isRequired,
      isFetching: PropTypes.bool.isRequired,
      requestErr: PropTypes.bool.isRequired,
      requestStr: PropTypes.string.isRequired,
    }),
    domains: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  state = {
    commonName: '',
    organizationName: '',
    countryName: '',
    stateOrProvinceName: '',
    localityName: '',
    organizationalUnitName: '',
    emailAddress: '',

    step: 0,
    title: 'Create A CSR',
  };

  onGenerateCSR = () => {
    const { match: { params: { accountID } } } = this.props;
    const { step, ...state } = this.state;

    // This strips out any keys that have empty strings in them from the state
    const csr = Object.keys(state).reduce((m, n) => {
      if (state[n] !== '') {
        return Object.assign(m, { [n]: state[n] });
      }
      return m;
    }, {});

    const country = countries.find((d) => {
      return d.cname === csr.countryName;
    });

    if (country) {
      csr.countryName = country.ccode;
    } else {
      csr.countryName = '';
    }

    csr.accountId = accountID;

    this.props.createCSRAction(csr)
      .then(() => {
        this.setState({ step: 4 });
      });
    // TODO: unhandled rejection
  };

  onSubmit = (fields, next) => {
    this.setState({ ...fields, step: next });
  };

  handleClose = (route) => {
    const { state: { title, step, ...state }, props: { history, match: { params: { accountID } } } } = this;
    const changes = Object.keys(state).reduce((m, n) => m + state[n], '');

    if (changes) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const result = confirm('Changes will be lost. Cancel to return to form.');

      if (result === true) {
        history.push(route);
        history.push(`/account/${accountID}/ssl`);
      }
    } else {
      // history.push(route);
      history.push(`/account/${accountID}/ssl`);
    }
  };

  render() {
    const { csr, domains, appsById } = this.props;
    const { state } = this;

    const content = () => {
      switch (state.step) {
        case 4:
          return (
            <DownloadCSR
              csr={csr.requestStr}
            />
          );
        case 3:
          return (
            <ReviewSubmit
              csr={csr}
              fields={state}
              onSubmit={this.onGenerateCSR}
              setStep={this.onSubmit}
            />
          );
        case 2:
          return (
            <OptionalForm
              fields={state}
              onSubmit={this.onSubmit}
            />
          );
        case 1:
          return (
            <RequiredForm
              fields={state}
              domains={domains}
              onSubmit={this.onSubmit}
            />
          );
        case 0:
        default:
          return (
            <SelectDomain
              fields={state}
              domains={domains}
              onSubmit={this.onSubmit}
              appsById={appsById}
            />
          );
      }
    };

    return (
      <Dialog open onClose={this.handleClose} maxWidth="md" fullWidth scroll="paper">
        <div>
          <DialogTitle>
            { state.title }
          </DialogTitle>
          {
            // Content should have DialogContent and DialogActions in them
            // Its redundant but wygd
            content()
          }
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    csr: state.csr,
    domains: state.domains.records,
    appsById: getAppsById(state),
  };
};
const mapDispatchToProps = {
  createCSRAction,
  reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCSR));
