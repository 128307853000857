import qs from 'qs';
import {
  useLocation,
} from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  return qs.parse(useLocation().search, { ignoreQueryPrefix: true });
};

export default useQuery;
