import React, { Fragment } from 'react';
import {
  string, func, object, number, bool,
} from 'prop-types';

import { Typography, CircularProgress, Card } from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';

import { remMapper } from 'shared/styleguide/theme/spacing';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';
import * as STATUSJOB from 'shared/modules/status/redux/constants';
import {
  ACTIVE,
  ACTIVE_MODIFYING,
  PENDING,
  INACTIVE,
  DELETING,
  DELETED,
} from 'shared/modules/app/routes/FSA/constants';
import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import CircleLink from 'shared/styleguide/atoms/Links/CircleLink';

import AppJobStatus from './AppJobStatus';

const StatusTags = (props) => {
  return (
    <GhostTag
      css={{
        border: 'none',
        background: 'none',
        margin: 0,
        padding: 0,
      }}
      {...props}
    />
  );
};

const AppItem = ({
  isAdmin,
  appState,
  app,
  createdApp,
  appCreateJob,
  job,
  fsaJob,
  appRoute,
  type,
}) => {
  let linkProps = {};
  // display app link if in failure state or Pagely Admin
  if (isAdmin || appState === 'normal') {
    linkProps = {
      as: AdapterLink,
      to: appRoute,
    };
  }

  if (app.status === 'q') linkProps = [];

  const getDisplayDataFromJob = () => {
    if (!fsaJob) {
      return [];
    }

    const status = fsaJob.overallStatus.replace('_', ' ');

    if (fsaJob.type === STATUSJOB.jobTypes.fsaDisable) {
      return ['Disable', status];
    }

    // the rest of the cases are for a setup job
    if (fsaJob.overallStatus === STATUSJOB.SUCCESS) {
      return ['', ''];
    }

    if (fsaJob.detail?.modifyDomains === true) {
      return ['Modification', status];
    }

    return ['Setup', status];
  };

  const getDisplayDataFromFsaRecord = () => {
    if (!app.fsa) {
      return [];
    }

    const status = app.fsa.status.replace('_', ' ');

    if ([INACTIVE, DELETING, DELETED].includes(status)) {
      return [];
    }

    // the rest of the cases are for a setup job
    if (status === ACTIVE) {
      return ['', ''];
    }

    // non active statuses will show as pending until the status job is loaded
    if (status === ACTIVE_MODIFYING) {
      return ['Modification', PENDING];
    }

    // PENDING, PENDING_INACTIVE
    return ['Setup', PENDING];
  };

  const getFsaTagForApp = () => {
    const [type, status] = fsaJob
      ? getDisplayDataFromJob()
      : getDisplayDataFromFsaRecord();

    if (type === undefined) {
      return null;
    }

    let color;
    switch (status) {
      case '':
      case STATUSJOB.SUCCESS:
        color = 'green';
        break;
      case STATUSJOB.FAILURE:
        color = 'red';
        break;
      case STATUSJOB.RUNNING:
      case STATUSJOB.PENDING:
      case STATUSJOB.ACTION_REQUIRED:
      default:
        color = 'orange';
        break;
    }

    return <StatusTags label={`Mercury ${type} ${status}`} color={color} />;
  };

  const fsaTags = getFsaTagForApp();

  return (
    <Card
      key={app.id}
      css={{
        marginBottom: remMapper('xsmall'),
        position: 'relative',
      }}
    >
      <Box direction="row" flex={1} align="stretch">
        <Box column flex={1}>
          {(appState === 'loading' || appState === 'creating') && (
            <Box
              direction="row"
              justify="space-between"
              align="center"
              flex={1}
              style={{ marginTop: '-10px' }}
            >
              {Object.keys(linkProps).length !== 0 ? (
                <Box {...linkProps} direction="row">
                  <Typography variant="h2" color="textPrimary">
                    {app.label || app.name}&nbsp;
                  </Typography>
                  <SettingsIcon />
                </Box>
              ) : (
                <Typography variant="h2" color="textPrimary">
                  {app.label || app.name}&nbsp;
                </Typography>
              )}
            </Box>
          )}
          {appState === 'normal' && (
            <Fragment>
              <Box column margin={{ bottom: 'xxsmall' }}>
                <Box
                  {...linkProps}
                  direction="row"
                  css={(theme) => ({ color: theme.palette.primary })}
                >
                  <Typography variant="h2">
                    {app.label || app.name}&nbsp;
                  </Typography>
                </Box>
              </Box>
              <Box row margin={{ bottom: 'xxsmall' }}>
                {app.primaryDomain ? (
                  <TextLink
                    variant="subtitle2"
                    color="textSecondary"
                    noUnderline
                    href={`http://${app.primaryDomain}`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {app.primaryDomain}
                  </TextLink>
                ) : null}
              </Box>
            </Fragment>
          )}
          {appState === 'loading' && (
            <Box
              justify="space-between"
              align="center"
              flex={1}
              padding={{ top: 'xsmall' }}
            >
              <CircularProgress color="primary" size={55} thickness={5} />
            </Box>
          )}
          <Box direction="row" gap="xsmall" margin={{ bottom: 'xxsmall' }}>
            <Typography component="span" variant="body2" color="textSecondary">
              App ID:
            </Typography>
            <Typography component="span" variant="body2" color="textPrimary">
              {app.id}
            </Typography>
          </Box>
          {appState === 'creating' && (
            <Fragment>
              <Box gap="xsmall" row align="center" margin={{ top: 'xsmall' }}>
                <Typography variant="body1">{fsaTags}</Typography>
              </Box>
              <AppJobStatus job={job} />
            </Fragment>
          )}
          {createdApp?.id
            && createdApp?.id === app?.id
            && appCreateJob === STATUSJOB.FAILURE && (
              <Box column margin={{ top: 'medium' }}>
                <Typography>
                  An error occurred during the wordpress installation for your
                  app, but our team has been notified and will have it running
                  asap!
                </Typography>
              </Box>
          )}
        </Box>
        {app.status !== 'q' && (
          <Box
            direction="column"
            justify="flex-end"
            style={{ flexGrow: 1, maxWidth: 120 }}
          >
            <CircleLink
              label="Go to app"
              tooltip="Go to app"
              to={linkProps.to}
            />
          </Box>
        )}
      </Box>
      {appState === 'normal' && (
        <div
          css={(theme) => ({
            position: 'absolute',
            top: 0,
            right: 0,
            borderBottom: `1px solid ${theme.color400}`,
            borderLeft: `1px solid ${theme.color400}`,
            borderRadius: '0 0 0 6px',
            height: remMapper('medium'),
            display: 'flex',
            gap: '12px',
            padding: '0 12px',
          })}
        >
          {(app.cdnProvider === 'cloudflare'
            || app.cdnProvider === 'godaddy-cloudflare') && (
            <StatusTags color="other" label="cloudflare" />
          )}
          <StatusTags
            label={app.active ? 'Active' : 'Inactive'}
            color={app.active ? 'green' : 'red'}
          />
          {type && (
            <StatusTags
              label={type}
              color={type === 'primary' ? 'primary' : 'accent'}
            />
          )}
          {app.status === 'q' && (
            <StatusTags
              label="provisioning"
              color="orange"
              css={{
                backgroundColor: 'transparent',
              }}
            />
          )}
          {fsaTags}
        </div>
      )}
    </Card>
  );
};

AppItem.propTypes = {
  app: object,
  appCreateJob: string,
  appRoute: string,
  appState: string,
  createdApp: object,
  fsaJob: object,
  isAdmin: bool,
  job: object,
  onFetchFsaJobs: func,
  onGetJobs: func,
  type: string,
};

export default AppItem;
