import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'shared/3rdparty/logger';
import 'shared/3rdparty/pageTracking';

require('react-dates/lib/css/_datepicker.css');
require('shared/styleguide/css/app.css');
require('shared/styleguide/assets/favicon.ico');

const rootEl = document.getElementById('app');

const render = () => {
  // eslint-disable-next-line
  const BaseComponent = require('./BaseComponent').default;

  ReactDOM.render(
    <BaseComponent />,
    rootEl,
  );
};

render();
