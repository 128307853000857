import React from 'react';
import {
  array, func, string,
} from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import colors from 'shared/styleguide/theme';

const margin = {
  top: 10, right: 10, bottom: 10, left: 10,
};

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    position: 'relative',
    width: 210,
    height: 210,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: margin.right,
    bottom: 0,
    left: margin.left,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 40,
    color: colors.dark20,
    textAlign: 'center',
    // This is important to preserve the chart interactivity
    pointerEvents: 'none',
    cursor: 'pointer',
  },
}));

const theme = {
  tooltip: {
    container: {
      background: 'none',
      padding: 0,
    },
  },
};

const DiskPie = ({
  data, graphColors, link, toolTip, sliceLabel, centerText,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <ResponsivePie
        theme={theme}
        colors={graphColors}
        data={data}
        margin={margin}
        sortByValue
        innerRadius={0.6}
        padAngle={0.7}
        cornerRadius={3}
        borderWidth={0}
        borderColor="#CCCCCC"
        enableRadialLabels={false}
        sliceLabel={sliceLabel}
        slicesLabelsSkipAngle={0}
        slicesLabelsTextColor="#FFFFFF"
        animate
        motionStiffness={90}
        motionDamping={15}
        onClick={link}
        tooltip={toolTip}
        defs={[
          {
            id: 'lines',
            type: 'patternLines',
            background: 'hsl(205, 15%, 62%)',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
          {
            id: 'dots',
            type: 'patternDots',
            background: 'hsl(205, 15%, 92%)',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
        ]}
        fill={[
          {
            match: {
              id: 'other',
            },
            id: 'lines',
          },
          {
            match: {
              id: 'unused',
            },
            id: 'dots',
          },
        ]}
        enableArcLabels={false}
        enableArcLinkLabels={false}
      />
      <Box className={classes.overlay}>
        {
          centerText && <Typography variant="h5">{centerText}</Typography>
        }
      </Box>
    </Box>
  );
};

DiskPie.propTypes = {
  centerText: string,
  data: array,
  graphColors: array,
  link: func,
  sliceLabel: func,
  toolTip: func,
};

export default withRouter(DiskPie);
