import React from 'react';
import {
  bool,
  func, object, string,
} from 'prop-types';
import { Formik, Form } from 'formik';

import {
  FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography,
} from '@material-ui/core';

import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';

import { getErrors } from '../../utils';

const defaultValues = {
  name: '',
  type: null,
  ips: '',
};

const BlockListDetails = ({
  initialValues, type, onSubmit, formRef, changed, onSetChanged,
}) => {
  const handleChange = (field, value) => {
    formRef.current.setFieldValue(field, value);
    if (!changed) {
      onSetChanged(true);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialStatus="pristine"
      initialValues={{
        ...defaultValues,
        ...initialValues,
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
      }) => (
        <Box as={Form}>
          <Box direction="row">
            <Box direction="column" flex={1}>
              <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
                <Typography gutterBottom variant="caption" color="textSecondary">Step 1</Typography>
                <Typography gutterBottom variant="body1" color="initial"><strong>Choose Name</strong></Typography>
              </Box>
              <Box direction="row">
                <TextField
                  required
                  role="textbox"
                  id="name"
                  name="name"
                  placeholder="Name"
                  autoComplete="off"
                  onChange={(e) => handleChange('name', e.target.value)}
                  value={values.name}
                  error={Boolean(errors.name)}
                  helperText={Boolean(errors.name) && errors.name}
                  disabled={type === 'edit'}
                  variant="outlined"
                  margin="dense"
                  css={{ marginTop: 0 }}
                />
              </Box>
            </Box>
            <Box direction="column" flex={1}>
              <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
                <Typography gutterBottom variant="caption" color="textSecondary">Step 2</Typography>
                <Typography gutterBottom variant="body1" color="initial"><strong>Disable rate limiting based on</strong></Typography>
              </Box>
              <FormControl component="fieldset">
                <RadioGroup data-testid="rate-limit-type" value={values.type} onChange={(e) => handleChange('type', e.target.value)}>
                  <FormControlLabel labelPlacement="end" value="token" control={<Radio disabled={type === 'edit'} inputProps={{ 'aria-label': 'token' }} />} label="Token" />
                  <FormControlLabel labelPlacement="end" value="ip-list" control={<Radio disabled={type === 'edit'} inputProps={{ 'aria-label': 'ip list' }} />} label="IP List" />
                </RadioGroup>
                {
                  errors.path && (
                    <ErrorText>{errors.path}</ErrorText>
                  )
                }
              </FormControl>
            </Box>
          </Box>
          {
            values.type === 'ip-list' && (
              <Box>
                <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
                  <Typography gutterBottom variant="caption" color="textSecondary">Step 3</Typography>
                  <Typography gutterBottom variant="body1" color="initial"><strong>Create IP List</strong></Typography>
                  <Typography variant="body2" color="textSecondary">
                    Add comma-separated IP addresses or CIDR notation
                  </Typography>
                </Box>
                <Box margin={{ bottom: 'small' }}>
                  <TextField
                    fullWidth
                    id="ips"
                    name="ips"
                    autoComplete="off"
                    onChange={(e) => handleChange('ips', e.target.value)}
                    value={values.ips}
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: true,
                    }}
                    margin="dense"
                    variant="outlined"
                    multiline
                    minRows={6}
                  />
                </Box>
              </Box>
            )
          }
          {
            errors && getErrors(errors, ['path'])
          }
        </Box>
      )}
    </Formik>
  );
};

BlockListDetails.propTypes = {
  changed: bool,
  formRef: object,
  initialValues: object,
  onSetChanged: func,
  onSubmit: func,
  type: string,
};

export default (BlockListDetails);
