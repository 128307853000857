import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router';

import Box from 'shared/styleguide/atoms/Box';
import ShowVideo from 'shared/styleguide/organisms/ShowVideo';
import YOUTUBE from 'shared/3rdparty/youtube';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import Layout from 'shared/styleguide/molecules/Layout';
import InfoText from 'shared/styleguide/molecules/InfoText';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import List from './List';

const CertList = (): React.ReactElement => {
  const match = useRouteMatch();
  return (
    <Layout
      title={(
        <Box>
          <Typography variant="h1">SSL Certificate Manager <ShowVideo videoId={YOUTUBE.MANAGE_SSL_VIDEO_ID} title="Managing SSL Certificates" /></Typography>
        </Box>
      )}
    >
      <InfoText
        href="https://support.pagely.com/hc/en-us/articles/360000032212-Redirecting-traffic-to-https-port-443-"
      >
        How to redirect traffic to https
      </InfoText>
      <InfoText
        href="https://support.pagely.com/hc/en-us/articles/360000519292"
        type="warning"
      >
        Have a site accelerator or cache in front of your site? (Cloudflare, Fastly, SiteLock, Sucuri, Incapsula, ...). <br />Please read this article before continuing.
      </InfoText>

      <Box margin={{ top: 'small' }}>
        <Box direction="row" align="center">
          <Box direction="row" gap="xsmall">
            <Button
              component={Link}
              to={`${match.url}/add`}
              variant="contained"
            >
              Add Certificate
            </Button>
            <Button
              component={Link}
              to={`${match.url}/add/cert`}
              variant="outlined"
            >
              Upload Certificate
            </Button>
          </Box>
        </Box>
      </Box>
      <ErrorBoundary>
        <List />
      </ErrorBoundary>

    </Layout>
  );
};

export default CertList;
