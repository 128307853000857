import React, { useState, useRef } from 'react';
import {
  bool, func, number, object, array,
} from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import logger from 'shared/3rdparty/logger';
import { fetchAvailability } from 'shared/modules/dns/redux/actions';
import { consolidateErrors } from 'shared/utils/validation';

import { getErrorMessages } from 'shared/modules/ares/utils';
import { getAllValidations } from 'shared/modules/app/redux/dns/actions';
import { getTagsList } from 'shared/modules/account/redux/selectors';
import * as JOBSTATUS from 'shared/modules/status/redux/constants';

import BasicInfo from './BasicInfo';
import AddDomains from './AddDomains';
import Summary from './Summary';
import SetPressDNS from './SetPressDNS';
import DNSInstructions from './DNSInstructions';

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: 'visible',
  },
  dialogContentRoot: {
    overflowY: 'visible',
  },
});

export const ModalContainer = ({
  app,
  dns,
  dnsJobs,
  onClose,
  open,
  mounts,
  serverTags,
  accountId,
  fetchAvailability,
  onCreateApp,
  onSetNewAppData,
  isPagelyAdmin,
  getAllValidations,
  accountTags,
}) => {
  const [modalStep, setModalStep] = useState(0);
  const classes = useStyles();
  const formRef = useRef();

  const memoizedStepOne = (
    <BasicInfo
      accountTags={accountTags}
      mounts={mounts}
      serverTags={serverTags}
      step={1}
      useStepState={[modalStep, setModalStep]}
      onClose={onClose}
    />
  );

  const memoizedStepTwo = (
    <AddDomains
      step={2}
      useStepState={[modalStep, setModalStep]}
      fetchAvailability={fetchAvailability}
      onClose={onClose}
    />
  );
  const memoizedStepThree = (
    <SetPressDNS
      step={3}
      useStepState={[modalStep, setModalStep]}
    />
  );
  const memoizedStepFour = (
    <Summary
      step={4}
      useStepState={[modalStep, setModalStep]}
      mounts={mounts}
    />
  );
  const memoizedStepFive = (
    <DNSInstructions
      step={5}
      useStepState={[modalStep, setModalStep]}
      app={app}
      dns={dns}
      dnsJobs={dnsJobs}
      getAllValidations={getAllValidations}
      onClose={onClose}
    />
  );

  const memoizedContent = [memoizedStepOne, memoizedStepTwo, memoizedStepThree, memoizedStepFour, memoizedStepFive];
  const stepperSteps = Array(memoizedContent.length - 1).fill('a'); // doesn't matter what you fill it with, we're not displaying it

  return (
    <Dialog
      open={open}
      onClose={
        () => {
          setModalStep(0);
          onClose();
        }
      }
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      classes={{
        paperFullWidth: modalStep === 0 ? classes.paperFullWidth : null,
      }}
    >
      <DialogTitle id="form-dialog-title">Create New App</DialogTitle>
      <Formik
        initialValues={{
          isPagelyAdmin,
          accountId,
          stepperSteps,
          fsaEnabled: null,
          fsaSSL: null,
          multisite: false,
          multisiteType: 'subfolder',
          phpVersion: 'stable',
          primaryDomain: '',
          label: '',
          mountPoint: null,
          domains: [],
          includeDNSInfo: true,
        }}
        innerRef={formRef}
        onSubmit={async (values, actions) => {
          const args = {};

          const requiredArgs = {
            'accountId': accountId,
            'name': values.primaryDomain,
            'primaryDomain': values.primaryDomain,
            'label': values.label, // technically not required but oh well
            'phpVersion': values.phpVersion, // not required but oh well
            'isPrimaryPressDns': Boolean(values.availibilityDomains[values.primaryDomain].usePressDNS),
          };

          const fsaArgs = {
            'fsaEnabled': values.fsaEnabled,
            'sslProvider': values.fsaSSL,
          };

          if (values.mountPoint) {
            args.mountPoint = values.mountPoint;
          }

          if (values.multisite) {
            args.multisite = values.multisite;
            args.multisiteType = values.multisiteType;
          }

          // don't include primary domain in domains array
          const additionalDomains = { ...values.availibilityDomains };
          delete additionalDomains[values.primaryDomain];

          const domains = Object.values(additionalDomains).map((d) => {
            const { domain, isPrimary, usePressDNS } = d;
            // this might be overkill but i guess better to be sure?
            if (domain === requiredArgs.primaryDomain && isPrimary && usePressDNS) {
              args.isPrimaryPressDns = Boolean(usePressDNS);
            }

            return {
              fqdn: domain,
              isPressDns: Boolean(usePressDNS),
            };
          });

          const endpointBody = {
            ...requiredArgs,
            ...fsaArgs,
            ...args,
            domains,
          };

          try {
            actions.setSubmitting(true);
            const res = await onCreateApp(endpointBody);
            const appName = res.label || res.name;
            onSetNewAppData(res.id, appName);

            actions.setSubmitting(false);

            setModalStep(modalStep + 1);
          } catch (err) {
            actions.setErrors({
              ...err.response.data.body,
              'general': consolidateErrors(err),
              'domains': getErrorMessages(err.response.data.body?.domains).join(', '),
            });

            if (err.response.status !== 422) {
              logger.error(new Error('Error on app create'), {
                metaData: {
                  error: err,
                  endpointBody,
                },
              });
            }
          }
        }}
      >
        {
          ({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                {
                  memoizedContent[modalStep]
                }
              </form>
            );
          }
        }
      </Formik>
    </Dialog>
  );
};

ModalContainer.propTypes = {
  accountId: number,
  accountTags: array.isRequired,
  app: object,
  dns: object,
  dnsJobs: object,
  fetchAvailability: func.isRequired,
  getAllValidations: func.isRequired,
  isPagelyAdmin: bool,
  mounts: object.isRequired,
  onClose: func.isRequired,
  onCreateApp: func.isRequired,
  onSetNewAppData: func.isRequired,
  open: bool,
  serverTags: object,
};

export default connect(
  (state) => ({
    accountTags: getTagsList(state),
    app: state.app.app.data,
    dns: state.app.dns,
    dnsJobs: state.status[JOBSTATUS.jobTypes.dnsValidation].jobs,
  }),
  {
    fetchAvailability,
    getAllValidations,
  },
)(ModalContainer);
