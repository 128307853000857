import React from 'react';

import {
  Typography,
} from '@material-ui/core';

import type { App } from 'shared/types/App';
import InfoText from 'shared/styleguide/molecules/InfoText';
import Box from 'shared/styleguide/atoms/Box';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import ValidationItem from 'shared/modules/app/routes/DNS/Validation/ValidationItem';

const viewDnsRecordsText = [
  'You can also view these records at any time in the DNS section under the appropriate zone,',
  ' or in the App view under the DNS tab.',
];

interface ValidationsProps {
  app: App;
  validations: Record<string, any>;
}

const Validations = ({
  app, validations,
}: ValidationsProps) => {
  const hasInvalidNs = validations.find((v) => v.isPressDns && !v.nsValid) || null;

  return (
    <Box>
      <Typography variant="body1">Please update these DNS records with your DNS provider or registrar (for NameServer changes). </Typography>
      <Box margin={{ top: 'xxsmall' }}>
        <InfoText href="https://support.pagely.com/hc/en-us/articles/360023200711-Managing-DNS-Records">Managing DNS Records</InfoText>
      </Box>
      <Box margin={{ top: 'small', bottom: 'small' }}>
        <Typography variant="body1" component="p">DNS changes may take up to 48 hours to fully propagate. {viewDnsRecordsText.join('')}</Typography>
      </Box>
      <Box direction="column" gap="small">
        {
          app.fsa
          && hasInvalidNs
          && (
            <Box>
              <Typography variant="body1" color="textPrimary">
                <strong>Mercury Dynamic Site Acceleration</strong>
                {' '}setup requires any PressDNS nameserver changes to be propagated in order to continue.
                Once DNS records are propagated, click the <Typography component="span" variant="inherit">&quot;Resume Setup&quot;</Typography> button
                on the <TextLink to={`/account/${app.accountId}/apps/${app.id}/overview`}>App Overview</TextLink>
                {' '}page to continue setup.
              </Typography>
            </Box>
          )
        }
        {
          validations.map((item) => (
            <ValidationItem
              domain={item}
              key={`validation-item-${item.domainId}`}
              job={null}
              defaultExpanded={false}
              type="non-acm"
            />
          ))
        }
      </Box>
    </Box>
  );
};

export default Validations;
