import { format } from 'shared/modules/ssl/models/certificate';
import * as base from 'shared/baseStates';
import apiReducer, * as API_REDUCER from 'shared/utils/redux/apiReducer';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import {
  CERT_UPLOAD_REQUEST,
  CERT_UPLOAD_SUCCESS,
  CERT_UPLOAD_FAILURE,
  CERT_REQUEST,
  CERT_SUCCESS,
  CERT_FAILURE,
  IMPORT_EXISTING_REQUEST,
  IMPORT_EXISTING_SUCCESS,
  IMPORT_EXISTING_FAILURE,
  CERT_RESET,
} from 'shared/modules/ssl/redux/cert/actions';
import {
  CERT_DOMAIN_ACTIVATE_SUCCESS,
  CERT_DOMAIN_DEACTIVATE_SUCCESS,
  CERT_DOMAIN_ADD_SUCCESS,
  CERT_DOMAIN_LINK_UPDATE_SUCCESS,
} from 'shared/modules/ssl/redux/certDomainLink/actions';

export const initialState = {
  ...API_REDUCER.base,
  id: 0,
  certType: '',
  issuedBy: '',
  subjectData: {
    name: '',
    email: '',
    state: '',
    country: '',
    locality: '',
    organization: '',
  },
  issuerData: {
    name: '',
    email: '',
    state: '',
    country: '',
    locality: '',
    organization: '',
  },
  startDate: '',
  expires: '',
  created: '',
  updated: null,
  domains: [],
  activeCount: 0,
  alternateNames: [],
  ...base.record(),
};

export function cert(state = initialState, action) {
  let index;
  let newDomains = [];
  switch (action.type) {
    case IMPORT_EXISTING_REQUEST:
    case CERT_REQUEST:
    case CERT_UPLOAD_REQUEST:
      return {
        ...state,
        ...base.request(),
        ...API_REDUCER.load(state, action),
      };
    case IMPORT_EXISTING_SUCCESS:
    case CERT_SUCCESS:
    case CERT_UPLOAD_SUCCESS:
      return {
        ...API_REDUCER.success(state, action),
        ...format(action.data),
        ...base.success(),
      };
    case IMPORT_EXISTING_FAILURE:
    case CERT_FAILURE:
    case CERT_UPLOAD_FAILURE:
      return {
        ...initialState,
        ...API_REDUCER.fail(state, action),
        ...base.failure(action.errMessage, action.statusCode, action.validation),
      };

    // both of the following just reinject the updated link into the domains list
    // having to do immutability manually is pain :(
    case CERT_DOMAIN_ACTIVATE_SUCCESS:
    case CERT_DOMAIN_DEACTIVATE_SUCCESS:
      newDomains = state.domains.map((item) => ({ ...item }));
      index = newDomains.findIndex((item) => (
        item.certId === action.data.certId
        && item.appId === action.data.appId
        && item.aliasId === action.data.aliasId
      ));
      newDomains[index] = action.data;
      return { ...state, domains: newDomains };

    case CERT_DOMAIN_LINK_UPDATE_SUCCESS:
      newDomains = state.domains.map((item) => ({ ...item }));
      index = state.domains.findIndex((item) => (
        item.certId === action.data.certId
        && item.appId === action.data.appId
        && item.aliasId === action.data.aliasId
      ));
      newDomains[index] = { ...action.data };
      return {
        ...state,
        domains: newDomains,
      };
    case CERT_DOMAIN_ADD_SUCCESS:
      return {

        ...state,
        domains: [
          action.data,
          ...state.domains.map((item) => ({ ...item })),
        ],
      };

    case LOGIN_LOGOUT_SUCCESS:
    case CERT_RESET:
      return { ...initialState };

    default:
      return state;
  }
}
