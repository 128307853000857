import React from 'react';
import { Typography } from '@material-ui/core';
import qs from 'qs';
import { UserPool } from 'shared/modules/account/redux/sso/types';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import type { StatusKeys } from '../constants';

interface Props {
  userPool: UserPool | null;
  updateStatus: (status: StatusKeys) => void;
}

const Test: React.FC<Props> = ({ userPool, updateStatus }) => {
  const { location } = window;
  const query = qs.stringify({
    response_type: 'code',
    client_id: userPool.clientId,
    redirect_uri: `${location.protocol}//${location.hostname}/sso-login`,
    state: btoa(JSON.stringify({
      pool: userPool.identifier,
      client_id: 3,
    })),
  });
  const url = `https://${userPool.domain}/login?${query}`;

  return (
    <Box gap="large" direction="column" fullWidth>
      <Box>
        <Typography variant="body2" color="textSecondary">Step 3</Typography>
        <Typography variant="h5">Test Single Sign On</Typography>
      </Box>
      <Box>
        <Typography variant="body1">
          Before we completely switch to SAML login, let’s make sure it works.
        </Typography>
        <Typography variant="body1">
          <b>Use the copy button below</b> to copy the url.
          Then paste into an <b>incognito window</b> and make sure you can access Atomic there.
          If you are able to log into atomic, setup is complete.
        </Typography>
      </Box>
      <Box fullWidth justify="center" align="center" direction="row" gap="medium">
        <div>
          <Typography variant="body1">
            {userPool.domain}
          </Typography>
        </div>
        <div><CopyButton text={url} message="Login URL copied!" /></div>
      </Box>
      <Box fullWidth justify="space-between" direction="row">
        <TextLink href="https://pagely.zendesk.com/hc/en-us/articles/4410414546331">Common Identity Providers and their setup.</TextLink>
        <Button data-testid="sso-complete-setup" onClick={() => updateStatus('active')}>
          Complete Setup
        </Button>
      </Box>
    </Box>
  );
};

export default Test;
