import React, { useCallback, Fragment } from 'react';
import {
  func, object, string, number, array,
} from 'prop-types';

import { Formik, Form } from 'formik';

import {
  Typography,
  Accordion,
  AccordionDetails,
  makeStyles,
  Divider,
} from '@material-ui/core';

import { deepClone } from 'shared/utils';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import useForm from './hooks/useForm';
import { initialFormValues } from './constants';

import Summary from './formComponents/Summary';
import Path from './formComponents/Path';
import Auth from './formComponents/Auth';
import IpList from './formComponents/IpList';
import Geo from './formComponents/Geo';
import Options from './formComponents/Options';

const useStyles = makeStyles(() => {
  return ({
    root: {
      borderBottom: '1px solid #ccc',
    },
  });
});

const FormContent = ({
  appId, locales, record, mode, onSetWizardOpen, existingRules, formStory,
}) => {
  const classes = useStyles();

  const initialValues = mode === 'edit' ? record : deepClone(initialFormValues);

  const unmemoizedForm = useForm(appId, locales, mode, onSetWizardOpen, initialValues, existingRules);

  const memoizedForm = useCallback(
    unmemoizedForm,
    [appId, mode, locales, onSetWizardOpen, initialValues, existingRules, unmemoizedForm],
  );

  const form = formStory ? { ...memoizedForm, ...formStory } : memoizedForm;

  return (
    <Formik
      initialStatus="pristine"
      initialValues={{
        ...initialValues,
      }}
      onSubmit={form.submit}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={form.formRef}
    >
      {({
        values,
      }) => (
        <Box
          as={Form}
          flex={1}
          margin={{ bottom: form.expanded ? 'small' : undefined }}
        >
          <Accordion
            className={classes.root}
            square
            expanded={form.expanded}
            onChange={() => form.setExpanded(!form.expanded)}
            TransitionProps={{ unmountOnExit: true }}
          >
            <Summary
              values={values}
              form={form}
            />
            <AccordionDetails>
              <Box direction="column" flex={1}>
                <Box
                  direction="column"
                  flex={1}
                >
                  <Typography variant="caption" color="textSecondary">
                    Note: Your Pagely server&apos;s local and external ips will be exempt from this rule to prevent potential conflicts.
                  </Typography>
                </Box>
                {
                  form.mode === 'create'
                  && (
                    <Fragment>
                      <Box>
                        <Path
                          values={values}
                          form={form}
                        />
                      </Box>
                      <Divider />
                    </Fragment>
                  )
                }
                <Box
                  padding={{
                    top: form.mode === 'create' ? 'small' : undefined,
                    bottom: 'small',
                  }}
                >
                  <Auth
                    values={values}
                    form={form}
                  />
                </Box>
                <Divider />
                <Box>
                  <IpList
                    values={values}
                    form={form}
                  />
                </Box>
                <Divider />
                <Box>
                  <Geo
                    values={values}
                    form={form}
                  />
                </Box>
                <Divider />
                <Box>
                  <Options
                    values={values}
                    form={form}
                  />
                </Box>
                {
                  form.errors?.general && !form.errors?.ips && (
                    <Box align="center">
                      <ErrorText>{form.errors.general}</ErrorText>
                    </Box>
                  )
                }
                <Box justify="space-between" direction="row" flex={1} margin={{ top: 'small' }}>
                  {
                    mode === 'edit' ? (
                      <Button
                        variant="outlined"
                        status={form.status?.delete}
                        color="error"
                        onClick={form.deleteRule}
                        data-classid="delete-list-button"
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="default"
                        onClick={() => onSetWizardOpen(false)}
                        data-classid="close-list-button"
                      >
                        Close Without Saving
                      </Button>
                    )
                  }
                  <Button
                    color="secondary"
                    variant={form.changed ? 'contained' : 'outlined'}
                    disabled={!form.changed}
                    status={form.status?.save}
                    onClick={form.submitForm}
                    data-classid="save-list-button"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Formik>
  );
};

FormContent.propTypes = {
  appId: number,
  existingRules: array,
  formStory: object,
  locales: array,
  mode: string,
  onSetWizardOpen: func,
  record: object,
};

export default FormContent;
