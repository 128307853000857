import React from 'react';

import { useTheme } from '@material-ui/core';

import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';

interface Props {
  operator: string|null;
}

const OperatorColumn = ({ operator }: Props) => {
  const theme = useTheme();
  const borderColorWeight = theme.palette.type === 'light' ? 300 : 800;
  const borderRight = `1px solid ${theme.palette.grey[borderColorWeight]};`;

  return (
    <Box justify="center" align="center" padding={{ right: 'small' }} css={{ width: 80, borderRight, boxSizing: 'border-box' }}>
      {
        operator && <span><GhostTag>{operator}</GhostTag></span>
      }
    </Box>
  );
};

export default OperatorColumn;
