import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { Reducer } from 'redux';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import type { Status, Action } from './types';

export const apiReducerPropTypes = {
  status: PropTypes.string,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  doing: PropTypes.bool,
  done: PropTypes.bool,
  failed: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  apiErrors: PropTypes.object,
  apiErrorStatusCode: PropTypes.number,
  validation: PropTypes.object,
  params: PropTypes.object,
};

export type ReduxStore<T> = State<T>;
interface State<T = unknown> {
  status: Status;
  data: T; // it might be an array or an object
  params: Record<string, any>;
  validation: Record<string, any>;
  apiErrors: any;
  loaded?: boolean;
  loading?: boolean;
  doing?: boolean;
  done?: boolean;
  failed?: boolean;
  errMessage?: any;
  apiErrorStatusCode: number;
  statusCode ?: number;
  response?: any;
}

export const baseState: State = {
  status: 'pristine',
  loaded: false,
  loading: false,
  doing: false,
  done: false,
  failed: false,
  data: {},
  apiErrors: null,
  apiErrorStatusCode: null,
  validation: null,
  params: {},
};

export const baseStatePropTypes = {
  status: PropTypes.string,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  doing: PropTypes.bool,
  done: PropTypes.bool,
  failed: PropTypes.bool,
  data: PropTypes.object,
  apiErrors: PropTypes.any,
  apiErrorStatusCode: PropTypes.number,
  validation: PropTypes.object,
  params: PropTypes.object,
};

export const baseCollectionState = {
  status: 'pristine',
  loaded: false,
  loading: false,
  doing: false,
  done: false,
  failed: false,
  data: [],
  apiErrors: null,
  apiErrorStatusCode: null,
  validation: null,
  params: {},
};

export const baseCollectionStatePropTypes = {
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  doing: PropTypes.bool,
  done: PropTypes.bool,
  failed: PropTypes.bool,
  data: PropTypes.array,
  apiErrors: PropTypes.any,
  apiErrorStatusCode: PropTypes.number,
  validation: PropTypes.object,
  params: PropTypes.object,
};

/**
 * @deprecated please use 'load' import instead
 */
export const initialRequest = ({
  status: 'loading',
  doing: true,
  loading: true,
  apiErrors: null,
  apiErrorStatusCode: null,
  failed: false,
  params: {},
});

/** @deprecated please use 'load' import instead */
export const initialRequestMethod = (state: State, action: Action): State => ({
  ...state,
  status: 'loading',
  doing: true,
  loading: true,
  failed: false,
  params: action.params || {},
  apiErrors: null,
  apiErrorStatusCode: null,
});

/** @deprecated please use 'load' import instead */
export const loadMethod = (state: State, action: Action): State => ({
  ...state,
  status: 'loading',
  doing: true,
  failed: false,
  apiErrors: null,
  apiErrorStatusCode: null,
  statusCode: null,
  errMessage: null,
  validation: null,
  params: action.params || {},
});

/* eslint-disable no-prototype-builtins */
function getData<Data = any>(action: Action<Data>): { data: Action<Data>['data'] } | Action<Data>['data'] {
  if (action.data.hasOwnProperty('@count')) {
    return action.data;
  } else if (action.data.hasOwnProperty('data') && Object.keys(action.data).length === 1) {
    return action.data;
  }

  return {
    data: action.data,
  };
}
/* eslint-enable no-prototype-builtins */

/**
 * @deprecated please use 'success' import instead
 */
export const successMethod = (state: State, action: Action): State => ({
  ...state,
  status: 'success',
  ...getData(action),
  params: action.params || {},
  response: action.response,
  loaded: true,
  loading: false,
  doing: false,
  done: true,
  failed: false,
  statusCode: action.statusCode,
  apiErrors: null,
  validation: null,
});

/**
 * @deprecated please use 'fail' import instead
 */
export const failMethod = (state: State, action: Action): State => ({
  ...state,
  status: 'failed',
  doing: false,
  done: false,
  loading: false,
  failed: true,
  statusCode: action.statusCode,
  errMessage: action.errMessage,
  apiErrors: action.data || action.errMessage,
  apiErrorStatusCode: action.statusCode,
  validation: action.validation,
  response: action.response,
  params: action.params || { ...state.params },
});

export const filterFields = (reducerObject: Record<string, any>): State => {
  return omit(reducerObject, ['doing', 'done', 'loading', 'failed', 'loaded']) as State;
};

export const base: Partial<State> = {
  status: 'pristine',
  data: {},
  apiErrors: null,
  apiErrorStatusCode: null,
  validation: null,
  params: {},
};

export const baseCollection: Partial<State> = {
  status: 'pristine',
  data: [],
  apiErrors: null,
  apiErrorStatusCode: null,
  validation: null,
  params: {},
};

export const load = (state: State, action: Action): State => filterFields(loadMethod(state, action));
export const fail = (state: State, action: Action): State => filterFields(failMethod(state, action));
export const success = (state: State, action: Action): State => filterFields(successMethod(state, action));

export default function apiReducer(
  requestType: string | string[],
  successType: string | string[],
  failType: string | string[],
  resetType = LOGIN_LOGOUT_SUCCESS,
  initialValue: State = { ...baseState },
  initReducer: Reducer = initialRequestMethod,
  requestReducer: Reducer = loadMethod,
  successReducer: Reducer = successMethod,
  failReducer: Reducer = failMethod,
) {
  return function reducer(state: State = initialValue, action: Action): State {
    let method: Reducer = () => {};

    if (Array.isArray(requestType) && requestType.includes(action.type)) {
      method = state.loaded ? requestReducer : initReducer;
      return method(state, action);
    }
    if (Array.isArray(successType) && successType.includes(action.type)) {
      return successReducer(state, action);
    }
    if (Array.isArray(failType) && failType.includes(action.type)) {
      return failReducer(state, action);
    }
    if (Array.isArray(resetType) && resetType.includes(action.type)) {
      return {
        ...initialValue,
      };
    }

    switch (action.type) {
      case requestType:
        method = state.loaded ? requestReducer : initReducer;
        return method(state, action);
      case successType:
        return successReducer(state, action);
      case failType:
        return failReducer(state, action);
      case resetType:
      case LOGIN_LOGOUT_SUCCESS:// logout should reset all state regardless
        return {
          ...initialValue,
        };
      default:
        return state;
    }
  };
}
