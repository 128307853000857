import React from 'react';
import { object, string } from 'prop-types';
import { Link } from 'react-router-dom';

import Button from 'shared/styleguide/atoms/Buttons/Button';
import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';
import SupportLink from 'shared/modules/support/Link';

const CollabRedirect = ({ account, children = null, text }) => {
  return (
    <Empty>
      {
        text && (<span>Please purchase a subscription to {text}:</span>)
      }
      { children }
      <Box margin="medium">
        {/* <Link
          to={{
            pathname: '/billing/plan',
            state: {
              user: account,
            },
          }}
        >
          <Button type="ghost" color="smoke">Choose A Hosting Plan</Button>
        </Link> */}
        <SupportLink>Contact support to choose a plan</SupportLink>
      </Box>
    </Empty>
  );
};

CollabRedirect.propTypes = {
  account: object.isRequired,
  text: string,
};

export default CollabRedirect;
