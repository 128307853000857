/* eslint-disable react/prefer-stateless-function */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@material-ui/core';

interface LinkerProps {
  href: string;
  rel?: string;
  target?: string;
  to: string | Record<string, any>;
  children: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

type Ref = HTMLAnchorElement;

const Linker = React.forwardRef<Ref, LinkerProps>(({
  to, href, children, target, rel, ...rest
}, ref) => {
  if (to) {
    return <RouterLink ref={ref} to={to} css={{ cursor: 'pointer' }} {...rest}>{children}</RouterLink>;
  } else if (href) {
    return <Link ref={ref} href={href} target={target || '_blank'} rel={rel || 'nofollow noopener noreferrer'} css={{ cursor: 'pointer' }} {...rest}>{children}</Link>;
  }
  return <Link ref={ref} css={{ cursor: 'pointer' }} {...rest}>{children}</Link>;
});

Linker.displayName = 'Linker';

export default Linker;
