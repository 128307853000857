import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AutoComplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import HeadlineText from 'shared/styleguide/typography/HeadlineText';
import { countries } from 'shared/utils/constants';
import { CSRActions } from './CSRStepper';
import styles from './styles';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  ignoreCase: true,
});

export class RequiredForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    fields: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { fields, domains } = props;
    const { commonName, countryName, organizationName } = fields;

    this.state = {
      commonName,
      countryName,
      organizationName,
      domains: domains.map((d) => d.fqdn).slice(0, 100), // mui auto-complete will not handle more items
      formErrors: {},
    };
  }

  commonNameChange = (e, commonName) => {
    this.setState({ commonName, formErrors: {} });
  };

  organizationNameChange = (event, organizationName) => {
    this.setState({ organizationName, formErrors: {} });
  };

  countryNameChange = (e, countryName) => {
    this.setState({
      countryName: countryName || '',
      formErrors: {},
    });
  };

  validate = (commonName, organizationName, countryName) => {
    const errors = {};

    if (!commonName) errors.commonName = 'Common name is required';
    if (!organizationName) errors.organizationName = 'Organization is required';
    if (!countryName) {
      errors.countryName = 'Country is required';
    } else if (!countries.find((d) => d.cname === countryName)) {
      errors.countryName = 'Choose a valid country from drop down list';
    }

    return errors;
  };

  goBack = () => {
    const { onSubmit } = this.props;
    onSubmit({}, 0);
  };

  doSubmit = (commonName, organizationName, countryName) => {
    const { onSubmit } = this.props;
    const formErrors = this.validate(commonName, organizationName, countryName);

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors });
    } else {
      onSubmit({ commonName, organizationName, countryName }, 2);
    }
  };

  render() {
    const {
      commonName, organizationName, countryName, formErrors,
    } = this.state;
    const { classes } = this.props;

    const countryInput = (params) => (
      <TextField
        {...params}
        fullWidth
        margin="normal"
        placeholder="Country"
        error={Boolean(formErrors.countryName)}
        helperText={formErrors.countryName}
      />
    );

    return (
      <form
        onSubmit={(event) => {
          event.preventDefault();
          this.doSubmit(commonName, organizationName, countryName);
        }}
        className={classes.csrModalContainer}
      >
        <DialogContent>
          <HeadlineText>Required Fields</HeadlineText>
          <TextField
            margin="normal"
            fullWidth
            placeholder="Common Name (full domain name, prefix with *. for wildcard)"
            errorText={formErrors.commonName}
            value={commonName}
            onChange={this.commonNameChange}
          />
          <br />
          <TextField
            margin="normal"
            fullWidth
            placeholder="Organization"
            value={organizationName}
            onChange={this.organizationNameChange}
            errorText={formErrors.organizationName}
            name="organization"
          />
          <br />
          <AutoComplete
            id="countryautocomplete"
            fullWidth
            placeholder="Country"
            inputValue={countryName}
            // TODO: not supported in MUI API
            // errorText={formErrors.countryName}
            // hintText="Country"
            onChange={this.countryNameChange}
            // getOptionLabel={(option) => option.cname}
            filterOptions={filterOptions}
            options={countries.map((c) => c.cname)}
            renderInput={countryInput}
          />
        </DialogContent>
        <CSRActions step={1} onClick={this.goBack} />
      </form>
    );
  }
}

export default withStyles(styles)(RequiredForm);
