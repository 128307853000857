import React, { Component } from 'react';
import { shape, func } from 'prop-types';
import { connect } from 'react-redux';

import SnackBar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

import {
  getReleaseNotes, setMode,
} from 'shared/modules/releasenotes/redux/actions';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Layout from 'shared/styleguide/molecules/Layout';
import colors from 'shared/styleguide/theme';
import Loading from 'shared/styleguide/atoms/Loading';

import ViewReleaseNotes from '../components/ViewReleaseNotes';
import CreateReleaseNote from '../components/CreateReleaseNote';
import UpdateReleaseNote from '../components/UpdateReleaseNote';

const StyledSnackBar = withStyles({
  root: {
    backgroundColor: colors.navy40,
  },
})(SnackbarContent);

export class ReleaseNotes extends Component {
  static propTypes = {
    getReleaseNotes: func.isRequired,
    match: shape({}),
    releaseNotes: shape({}),
    setMode: func,
  };

  state = {
    errors: null,
  };

  componentDidMount() {
    const {
      setMode, getReleaseNotes,
    } = this.props;
    setMode('view');
    getReleaseNotes();
  }

  componentDidUpdate(prevProps) {
    const {
      releaseNotes,
    } = this.props;

    if (releaseNotes.mode === 'view' && releaseNotes.mode !== prevProps.releaseNotes.mode) {
      this.props.getReleaseNotes();
    }

    if (releaseNotes.apiErrors && !this.state.errors) {
      if (releaseNotes.validation && releaseNotes.validation.body.releasenotes.messages) {
        this.setState({
          errors: `Error: ${releaseNotes.validation.body.releasenotes.messages.join('; ')}`,
        });
      } else {
        this.setState({
          errors: releaseNotes.apiErrors,
        });
      }
    }

    if (!releaseNotes.apiErrors && this.state.errors) {
      this.setState({
        errors: null,
      });
    }
  }

  render() {
    const {
      releaseNotes, setMode,
    } = this.props;

    return (
      <Box padding="medium">
        <Layout title="Release Notes">
          {releaseNotes && releaseNotes.mode === 'view'
            && (
              <div style={{ textAlign: 'right', marginBottom: 20 }}>
                <Button
                  variant="contained"
                  style={{ marginLeft: 0, marginRight: 5 }}
                  label="Create New"
                  onClick={() => setMode('create')}
                />
              </div>
            )}
          <
            >
            {
              releaseNotes && releaseNotes.status === 'loading' && releaseNotes.mode === 'view'
              && (
                <Loading />
              )
            }
            {
              releaseNotes && releaseNotes.status === 'success' && releaseNotes.mode === 'view'
              && (
                <ViewReleaseNotes isAdmin {...this.props} />
              )
            }
            {
              releaseNotes && releaseNotes.mode === 'create'
              && (
                <CreateReleaseNote />
              )
            }
            {
              releaseNotes && releaseNotes.releaseNoteDate && releaseNotes.mode === 'edit'
              && (
                <UpdateReleaseNote />
              )
            }
            {
              releaseNotes && this.state.errors
              && (
                <SnackBar
                  key="releaseNoteApiError"
                  open
                >
                  <StyledSnackBar message={this.state.errors} />
                </SnackBar>
              )
            }
          </>
        </Layout>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    releaseNotes: state.releaseNotes,
  };
};

const mapDispatchToProps = {
  getReleaseNotes,
  setMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
