import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Box from 'shared/styleguide/atoms/Box';
import Button, { ButtonStatus } from 'shared/styleguide/atoms/Buttons/NewButton';
import { TYPE_SHARED } from 'admin/modules/notes/constants';
import { useCreateNoteMutation } from 'admin/modules/notes/query';
import { StoreState } from 'shared/types/types';
import { formatedExpiry } from 'admin/modules/notes/expiryUtils';

export const SubmitSharedNote = (): JSX.Element => {
  const { user, account } = useSelector((state: StoreState) => state);
  const [status, setStatus] = useState('initial');
  const [createNote] = useCreateNoteMutation();

  const initialNote = '';
  const [note, setNote] = useState(initialNote);

  const handleSubmit = async () => {
    const body = {
      authorName: user.name,
      authorId: user.id,
      accountId: account.id,
      accountName: account.name,
      title: 'User-Submitted shared',
      noteType: TYPE_SHARED,
      noteContentType: 'text/plain',
      note,
      expires: formatedExpiry({ days: 1 }),
      burnAfterReading: false,
    };

    setStatus('loading');

    try {
      await createNote(body);

      setStatus('success');

      setTimeout(() => {
        setStatus('initial');
        setNote(initialNote);
      }, 3000);
    } catch (err) {
      setStatus('initial');
    }
  };

  return (
    <Box flex="1 1 50%">
      <Typography variant="h3" gutterBottom>Submit Shared Note</Typography>
      <Box as={Paper}>
        <Box padding={{ top: 'medium', left: 'medium' }}>
          <Typography
            paragraph
            variant="body1"
            css={{
              marginBottom: 0,
            }}
          >
            Shared notes are securely shared with our support team and automatically deleted daily.
          </Typography>
        </Box>
        <Box
          direction="row"
          align="center"
          justify="space-between"
          padding={{
            top: 0, right: 'medium', left: 'medium', bottom: 0,
          }}
        >
          <Box flex={1} gap="small">
            <div>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Secured shared note"
                defaultValue={note}
                minRows={3}
                multiline
                onChange={(event) => { setNote(event.target.value); }}
              />
            </div>
          </Box>
        </Box>
        <Box direction="row" padding="medium" justify="flex-end">
          <Button variant="contained" status={status as ButtonStatus} onClick={handleSubmit} startIcon={<Lock />}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SubmitSharedNote;
