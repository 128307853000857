import React from 'react';
import { connect } from 'react-redux';

import TrashIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

import { any } from 'prop-types';
import Row from 'shared/styleguide/atoms/Row';
import Select from 'shared/styleguide/atoms/Select/Select';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';

import {
  updateItem, deleteItem, addSubProperty, addSubMapProperty,
} from '../redux/actions';

import SubProp from './SubProp';
import SubPropMap from './SubPropMap';
import SubPropArrayInput from './SubPropArrayInput';

import {
  getOptions, isString, isArray, isObject, isBoolean, getConfigType,
} from '../helpers';

const AddSubButton = ({ onClick }) => { //eslint-disable-line
  return (
    <div
      style={{
        width: 38, marginLeft: 10, paddingTop: 5, paddingBottom: 5,
      }}
    >
      <Button
        style={{
          height: 38, width: 38, minWidth: 20,
        }}
        name="subProp"
        onClick={onClick}
      >
        +
      </Button>
    </div>
  );
};

const PropValues = ({
  state, propLevels, value, updateItem, deleteItem, addSubProperty, addSubMapProperty, schema,
}) => {
  const configType = getConfigType(state.config);
  const propName = Object.keys(value)[0];
  const propValue = value[propName];
  const propLevelsNew = `${propLevels}.${propName}`;
  let propValueSelect = propValue;
  if (isBoolean(propValue)) {
    propValueSelect = propValue.toString();
  }
  let items;
  let optsArrSelect;
  const opts = getOptions(propLevelsNew, schema, configType);
  const parentOpts = getOptions(propLevels, schema, configType);
  let valType = '';

  if (isString(opts)) {
    valType = 'valInput';
  } else {
    if (isObject(opts) && !opts?.optionType) {
      if (parentOpts[propName].description !== undefined && parentOpts[propName].description === 'map') {
        items = (
          <div
            key={`subPropMapDiv.${propLevelsNew}`}
          >
            <SubPropMap
              key={`subPropMap.${propLevelsNew}`}
              value={propValue}
              propLevels={propLevelsNew}
            />
          </div>
        );
        valType = 'subMapVal';
      } else {
        items = Object.keys(propValue).map((idx) => {
          return (
            <div
              key={`subProp-${idx}`}
            >
              <SubProp
                value={propValue[idx]}
                propLevels={`${propLevelsNew}.${idx}`}
              />
            </div>
          );
        });
        valType = 'subVal';
      }
    }
    if (isArray(opts) || (isObject(opts) && opts?.optionType)) {
      // selectors or input-array (eg. upstreams)
      if (parentOpts[propName].description !== undefined && parentOpts[propName].description === 'input-array') {
        items = (
          <div
            key="subProp-array"
          >
            <SubPropArrayInput
              value={propValue}
              propLevels={propLevelsNew}
            />
          </div>
        );
        valType = 'subValInputArray';
      } else {
        optsArrSelect = opts.map((item) => {
          if (isBoolean(item)) {
            return ({
              value: item,
              label: item.toString(),
            });
          } else {
            return ({
              value: item,
              label: item,
            });
          }
        });
        valType = 'valSelect';
      }
    }
  }

  return (
    <div style={{ width: 'calc(100% - 180px)' }}>
      <Row style={{ alignItems: 'flex-start' }}>
        <div style={{ width: 'calc(100% - 38px)' }}>
          {valType === 'valSelect'
            && (
              <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                { /* eslint-disable jsx-a11y/no-onchange */}
                <Select
                  onChange={(e) => {
                    if (e.value !== null) {
                      updateItem(propLevelsNew, e.value);
                    }
                  }}
                  options={optsArrSelect}
                  placeholder={propValueSelect}
                  value={propValue}
                />
              </div>
            )}
          {valType === 'valInput'
            && (
              <div>
                <TextField
                  name={propName}
                  id={propName}
                  autoComplete="off"
                  fullWidth
                  multiline
                  margin="dense"
                  value={propValue}
                  onChange={(e) => updateItem(propLevelsNew, e.currentTarget.value)}
                />
              </div>
            )}
          {valType === 'subValInputArray'
            && (
              <div>
                {items}
              </div>
            )}
          {valType === 'subVal'
            && (
              <div>
                {items}
              </div>
            )}
          {valType === 'subMapVal'
            && (
              <div>
                {items}
              </div>
            )}
        </div>
        <div>
          <div
            style={{
              width: 38, marginLeft: 10, paddingTop: 5, paddingBottom: 5,
            }}
          >
            <div style={{
              border: '1px solid #ccc',
            }}
            >
              <IconButton
                color="inherit"
                size="small"
                name="deleteSub"
                onClick={() => deleteItem(propLevelsNew)}
              >
                <TrashIcon />
              </IconButton>
            </div>
          </div>
          {
            (valType === 'subVal' || valType === 'subValInputArray')
            && (
              <AddSubButton
                onClick={() => addSubProperty(propLevelsNew, schema, configType)}
              />
            )
          }
          {
            valType === 'subMapVal'
            && (
              <AddSubButton
                onClick={() => addSubMapProperty(propLevelsNew, schema, configType)}
              />
            )
          }
        </div>
      </Row>
    </div>
  );
};

PropValues.propTypes = {
  addSubMapProperty: any,
  addSubProperty: any,
  deleteItem: any,
  propLevels: any,
  schema: any,
  state: any,
  updateItem: any,
  value: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, {
  updateItem, deleteItem, addSubProperty, addSubMapProperty,
})(PropValues);
