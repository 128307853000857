import React from 'react';
import { connect } from 'react-redux';
import { object } from 'prop-types';

import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import { updateReleaseNote } from 'shared/modules/releasenotes/redux/actions';

import EditForm from './EditForm';

const UpdateReleaseNote = (props) => {
  const {
    releaseNotes,
  } = props;
  const { releaseNoteDate } = releaseNotes;
  if (releaseNoteDate) {
    const releaseNote = releaseNotes.data.find((note) => (releaseNoteDate === note.releaseDate));
    return (
      <Box>
        <Box padding={{ top: 'xsmall', bottom: 'small' }}>
          <EditForm key={releaseNoteDate} releaseNote={releaseNote} />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box gap="medium" direction="column">
          <div>
            <ErrorText>
              The requested Release Note does not exist.
            </ErrorText>
          </div>
        </Box>
      </Box>
    );
  }
};

UpdateReleaseNote.propTypes = {
  releaseNotes: object,
};

const mapStateToProps = (state) => {
  return {
    releaseNotes: state.releaseNotes,
  };
};

const mapDispatchToProps = {
  updateReleaseNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReleaseNote);
