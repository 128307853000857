import { combineReducers } from 'redux';
import apiReducer, {
  baseState,
  baseCollectionState,
  load,
  success,
  fail,
} from 'shared/utils/redux/apiReducer';

import * as SERVERS from 'shared/modules/server/redux/constants';
import * as AC from './accessControl/constants';
import * as QP from './cacheMgmt/constants';
import * as RL from './rateLimits/constants';
import * as HEADERS from './headers/constants';
import * as REDIRECTS from './redirects/constants';
import * as PENDING from './pending/constants';

export const access = (state = baseCollectionState, action) => {
  switch (action.type) {
    case AC.GET_LISTS_REQUEST: {
      return {
        ...load(state, action),
      };
    }
    case AC.SET_LIST_FAILURE:
    case AC.GET_LISTS_FAILURE: {
      return {
        ...fail(state, action),
      };
    }
    case AC.GET_LISTS_SUCCESS: {
      const unprocessed = success(state, action);
      const data = unprocessed.data.map((record) => {
        const { match, path, appId } = record;
        const idKey = [match, path, appId].join(':');

        return {
          idKey,
          ...record,
        };
      });

      return {
        ...unprocessed,
        data,
      };
    }
    case AC.DELETE_LIST_SUCCESS: {
      const data = [...state.data];
      const index = data.findIndex((e) => e.idKey === action.params.key);
      if (index > -1) {
        data.splice(index, 1, { idKey: action.params.key, ...action.data });
      }

      return {
        ...success(state, action),
        data: state.data.filter((r) => r.idKey !== action.key),
      };
    }
    case AC.SET_LIST_SUCCESS: {
      const data = [...state.data];
      const index = data.findIndex((e) => e.idKey === action.params.key);
      if (index > -1) {
        data.splice(index, 1, { idKey: action.params.key, ...action.data });
      }

      return {
        ...success(state, action),
        data,
      };
    }
    case AC.CREATE_LIST_SUCCESS: {
      const { key } = action.params;
      const idKey = key;

      return {
        ...success(state, action),
        data: [
          {
            idKey,
            ...action.data,
          },
          ...state.data,
        ],
      };
    }
    default: return state;
  }
};

export const cache = (state = baseState, action) => {
  switch (action.type) {
    case QP.GET_PARAMS_REQUEST: {
      return {
        ...load(state, action),
      };
    }
    case QP.GET_PARAMS_FAILURE: {
      return {
        ...fail(state, action),
      };
    }
    case QP.SET_PARAMS_SUCCESS:
    case QP.GET_PARAMS_SUCCESS: {
      return {
        ...success(state, action),
      };
    }
    default: return state;
  }
};

export const headers = (state = baseCollectionState, action) => {
  switch (action.type) {
    case HEADERS.GET_HEADERS_REQUEST: {
      return {
        ...load(state, action),
      };
    }
    case HEADERS.GET_HEADERS_FAILURE: {
      return {
        ...fail(state, action),
      };
    }
    case HEADERS.GET_HEADERS_SUCCESS: {
      return {
        ...success(state, action),
      };
    }
    case HEADERS.SET_HEADERS_SUCCESS: {
      const data = { ...state.data, ...action.data.data };

      return {
        ...success(state, action),
        data,
      };
    }
    case HEADERS.DELETE_HEADERS_SUCCESS: {
      const data = { ...state.data };
      delete data[action.key];
      return {
        ...success(state, action),
        data,
      };
    }
    default: return state;
  }
};

export const rateLimit = (state = baseCollectionState, action) => {
  switch (action.type) {
    case RL.GET_RATE_LIMITS_REQUEST: {
      return {
        ...load(state, action),
      };
    }
    case RL.GET_RATE_LIMITS_FAILURE: {
      return {
        ...fail(state, action),
      };
    }
    case RL.GET_RATE_LIMITS_SUCCESS: {
      return {
        ...success(state, action),
      };
    }
    case RL.CREATE_RATE_LIMITS_SUCCESS: {
      const data = [...state.data, action.data];

      return {
        ...success(state, action),
        data,
      };
    }
    case RL.GET_RATE_LIMIT_SUCCESS:
    case RL.SET_RATE_LIMITS_SUCCESS: {
      const idx = state.data.findIndex((d) => d.id === action.handlerId);
      const data = [...state.data];
      data.splice(idx, 1, action.data);

      return {
        ...success(state, action),
        data,
      };
    }
    case RL.DELETE_RATE_LIMITS_SUCCESS: {
      const idx = state.data.findIndex((d) => d.id === action.handlerId);
      const data = [...state.data];
      data.splice(idx, 1);

      return {
        ...success(state, action),
        data,
      };
    }
    default: return state;
  }
};

export const redirects = (state = baseCollectionState, action) => {
  switch (action.type) {
    case REDIRECTS.CREATE_REDIRECT_REQUEST:
    case REDIRECTS.FETCH_REDIRECTS_REQUEST:
    case REDIRECTS.DELETE_REDIRECT_REQUEST:
    case REDIRECTS.UPDATE_REDIRECT_REQUEST: {
      return {
        ...load(state, action),
      };
    }
    case REDIRECTS.CREATE_REDIRECT_SUCCESS: {
      const redirect = { ...action.data, addedInUI: true };
      return {
        ...success(state, action),
        data: [
          ...state.data,
          redirect,
        ],
      };
    }
    case REDIRECTS.CLEAR_ADDED_IN_UI: {
      const data = state.data.map((redirect) => {
        const { addedInUI, ...rest } = redirect;
        return rest;
      });

      return {
        ...state,
        data,
      };
    }
    case REDIRECTS.FETCH_REDIRECTS_SUCCESS: {
      return {
        ...success(state, action),
      };
    }
    case REDIRECTS.UPDATE_REDIRECT_SUCCESS: {
      const data = [...state.data];
      const index = data.findIndex((e) => e.matchIdentifier === action.params.matchIdentifier);
      if (index > -1) {
        data.splice(index, 1, { ...action.data });
      }
      return {
        ...success(state, action),
        data,
      };
    }
    case REDIRECTS.CREATE_REDIRECT_FAILURE:
    case REDIRECTS.FETCH_REDIRECTS_FAILURE:
    case REDIRECTS.UPDATE_REDIRECT_FAILURE: {
      return {
        ...fail(state, action),
      };
    }
    case REDIRECTS.DELETE_REDIRECT_SUCCESS: {
      const data = [...state.data];
      const index = data.findIndex((e) => e.matchIdentifier === action.params?.matchIdentifier);
      if (index > -1) {
        data.splice(index, 1);
      }
      return {
        ...state,
        ...success(state, action),
        data,
        params: {
          ...action.params,
        },
      };
    }
    default: return state;
  }
};

export const getInitiatorName = (type) => {
  switch (type) {
    case REDIRECTS.CREATE_REDIRECT_SUCCESS:
    case REDIRECTS.UPDATE_REDIRECT_SUCCESS:
    case REDIRECTS.DELETE_REDIRECT_SUCCESS:
    case REDIRECTS.IMPORT_UPDATES_REDIRECTS:
      return 'ares-redirects';
    case RL.CREATE_RATE_LIMITS_SUCCESS:
    case RL.SET_RATE_LIMITS_SUCCESS:
    case RL.DELETE_RATE_LIMITS_SUCCESS:
      return 'ares-rate-limit';
    case HEADERS.SET_HEADERS_SUCCESS:
    case HEADERS.DELETE_HEADERS_SUCCESS:
      return 'ares-header-management';
    case QP.SET_PARAMS_SUCCESS:
      return 'ares-cache-keys';
    case AC.CREATE_LIST_SUCCESS:
    case AC.SET_LIST_SUCCESS:
    case AC.DELETE_LIST_SUCCESS:
      return 'ares-access-controls';
    default:
      break;
  }
};

const updatePendingData = (state, action, feature) => {
  const data = [...state.data];
  const initiators = [feature];

  const index = data.findIndex((e) => e.appId === action.appId);

  if (index > -1) {
    data.splice(index, 1, {
      'appId': action.appId,
      'appName': state.data[index].appName,
      'initiators': [...new Set([...state.data[index].initiators, ...initiators])],
    });
  } else {
    data.push({
      'appId': action.appId,
      'appName': null, // will be added in component
      initiators,
    });
  }
  return data;
};

export const pending = (state = baseCollectionState, action) => {
  // ares changes will update the "pending" store
  const feature = getInitiatorName(action.type);
  if (feature) {
    return {
      ...success(state, action),
      data: [...updatePendingData(state, action, feature)],
      params: {
        ...state.params,
        ...action.params,
      },
    };
  }

  switch (action.type) {
    case SERVERS.ARES_POOL_RELOAD_SUCCESS: {
      // remove only the deployed app item from data
      const data = [...state.data];

      const index = data.findIndex((e) => e.appId === Number(action.appId));
      if (index > -1) {
        data.splice(index, 1);
      }
      return {
        ...baseCollectionState,
        data,
        params: {
          ...state.params,
        },
      };
    }
    default:
      return apiReducer(
        PENDING.FETCH_PENDING_REQUEST,
        PENDING.FETCH_PENDING_SUCCESS,
        PENDING.FETCH_PENDING_FAILURE,
        null,
        baseCollectionState,
      )(state, action);
  }
};

export const ares = combineReducers({
  access,
  cache,
  headers,
  rateLimit,
  redirects,
  pending,
});
