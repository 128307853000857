import React, { useEffect, useState, Fragment } from 'react';
import {
  array, object, string, bool,
} from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { css } from '@emotion/react';

import { useTheme } from '@material-ui/core/styles';

import { Typography, useMediaQuery } from '@material-ui/core';

import { remMapper } from 'shared/styleguide/theme/spacing';
import Loading from 'shared/styleguide/atoms/Loading';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';

import AppDiskStats from './AppDiskStats';
import { createGraphConfig, StatsBox } from './statsUtils';
import { GRAPH_TYPES } from './constants';

const StatsCard = ({
  title, graphType, graphData, graphMeta, children,
}) => {
  const theme = useTheme();
  const singleCardView = useMediaQuery(theme.breakpoints.down('md'));
  const gridColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  return (
    <Box
      css={css`
        width: ${singleCardView ? '100%' : `calc(50% - (${remMapper('medium')} / 2))`};
      `}
    >
      <Box as={Paper} flex={1} padding="medium" margin={{ bottom: 'medium' }}>
        <Box>
          <Typography variant="h5" color="textPrimary">{title}</Typography>
        </Box>
        {
          children || (
            <StatsBox align="center">
              <ResponsiveLine
                data={[{
                  id: graphType,
                  data: graphData,
                }]}
                {...createGraphConfig(graphType, graphMeta)}
                theme={{
                  'textColor': theme.palette.text.secondary,
                  'axis': {
                    'domain': {
                      'line': {
                        'stroke': gridColor,
                        'strokeWidth': 1,
                      },
                    },
                    'ticks': {
                      'line': {
                        'stroke': gridColor,
                        'strokeWidth': 1,
                      },
                    },
                  },
                  'grid': {
                    'line': {
                      'stroke': gridColor,
                      'strokeWidth': 1,
                    },
                  },
                }}
              />
            </StatsBox>
          )
        }
      </Box>
    </Box>
  );
};

StatsCard.propTypes = {
  graphCardWidth: string,
  graphData: array,
  graphMeta: object,
  graphType: string,
  title: string,
};

const AppStats = ({
  analytics, match, diskGraphData, diskMeta, diskUsageData, isOnP20Account,
}) => {
  const theme = useTheme();
  const singleCardView = useMediaQuery(theme.breakpoints.down('md'));
  const [meta, setMeta] = useState({});
  const [requests, setRequests] = useState([]);
  const [cdn, setCdn] = useState([]);

  const createGraphData = (data, keys) => {
    const metadata = {};

    const graphData = keys.map((key) => {
      metadata[key] = {
        forceYAxis: true,
        maxValue: 0,
        firstTick: null,
        lastTick: null,
      };

      const records = data.map((day) => {
        if (metadata[key].forceYAxis && day[key] > 0) {
          metadata[key].forceYAxis = false;
        }

        if (day[key] > metadata[key].maxValue) {
          metadata[key].maxValue = day[key];
        }

        return {
          x: new Date(day.period),
          y: day[key],
        };
      });

      metadata[key].firstTick = records[0].x;
      metadata[key].lastTick = records[records.length - 1].x;

      return records;
    });

    setMeta(metadata);

    return graphData;
  };

  useEffect(() => {
    const records = createGraphData(analytics, [GRAPH_TYPES.requests, GRAPH_TYPES.cdn]);
    setRequests(records[0]);
    setCdn(records[1]);
  }, []);

  if (!requests.length) {
    return <Loading />;
  }

  return (
    <Fragment>
      <Box margin={{ bottom: 'small' }}>
        <Typography variant="h3">PressFormance</Typography>
      </Box>
      <Box
        flex={1}
        direction={singleCardView ? 'column' : 'row'}
        gap="medium"
      >
        <StatsCard
          title="Requests"
          graphType={GRAPH_TYPES.requests}
          graphData={requests}
          graphMeta={meta}
        />
        <StatsCard
          title="CDN"
          graphType={GRAPH_TYPES.cdn}
          graphData={cdn}
          graphMeta={meta}
        />
      </Box>
      <Box
        flex={1}
        direction={singleCardView ? 'column' : 'row'}
        gap="medium"
      >
        <StatsCard
          title="Disk Usage"
        >
          <Box flex={1} align="center">
            <AppDiskStats
              match={match}
              diskGraphData={diskGraphData}
              diskMeta={diskMeta}
              diskUsageData={diskUsageData}
              isOnP20Account={isOnP20Account}
            />
          </Box>
        </StatsCard>
      </Box>
    </Fragment>
  );
};

AppStats.propTypes = {
  analytics: array.isRequired,
  diskGraphData: array,
  diskMeta: object,
  diskUsageData: object,
  isOnP20Account: bool,
  match: object.isRequired,
};

export default AppStats;
