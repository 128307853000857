import React, {
  useCallback, useEffect,
} from 'react';
import {
  object, func, array, number, string, bool,
} from 'prop-types';
import { isBefore, format } from 'date-fns';
import { useSelector } from 'react-redux';

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControl,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { getDeployEnvironment } from 'shared/config';

import { useMergeState } from 'shared/hooks/useMergeState';
import { getTypeAndVersionFromJob } from 'shared/modules/status/utils';
import { getRecentPhpVersionJobForApp } from 'shared/modules/status/redux/selectors';
import { AUTH_TYPE } from 'shared/utils/constants';
import * as STATUS from 'shared/modules/status/redux/constants';
import { capitalize } from 'shared/utils';
import Loading from 'shared/styleguide/atoms/Loading';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import FormLabel from 'shared/styleguide/molecules/RadioInput';
import InfoText from 'shared/styleguide/molecules/InfoText';
import SupportLink from 'shared/modules/support/Link';
import { usePurchaseLtsAddonMutation } from 'shared/modules/billing/query';
import { ErrorText } from 'shared/styleguide/typography';
import Success from 'shared/styleguide/atoms/Success';

const initialStateValues = {
  status: 'pristine',
  jobStatus: 'pristine',
  fetchedOnce: false,
  versionStatus: 'pristine',
  availableVersions: [],
  selectedVersion: null,
  phpVersion: null,
  expanded: false,
  versionLocked: false,
  lockModalOpen: false,
  error: false,
};

const LoadingDisplay = ({ status, job, fetchedOnce }) => {
  if (!fetchedOnce) {
    return (
      <Box>
        <Loading size="small" margin={{ top: 0 }} />
      </Box>
    );
  }

  const { runningStatuses, tagMap, FAILURE } = STATUS;

  let statusMessage = status;
  const [, newVersion] = getTypeAndVersionFromJob(job);

  if (runningStatuses.includes(status)) {
    statusMessage = `Updating to ${newVersion}`;
  }

  if (status === FAILURE) {
    statusMessage = `Failed updating to ${newVersion}`;
  }

  return (
    <Box direction="row" gap="small">
      {
        runningStatuses.includes(status)
        && (
          <Box>
            <Loading size="small" margin={{ top: 0 }} />
          </Box>
        )
      }
      <Box>
        <GhostTag color={tagMap[status]}>{statusMessage}</GhostTag>
      </Box>
    </Box>
  );
};

LoadingDisplay.propTypes = {
  fetchedOnce: bool,
  job: object,
  status: string,
};

// eslint-disable-next-line react/prop-types
const ConfirmModal = ({ onConfirm, open, setOpen }) => {
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Lock PHP Version
      </DialogTitle>
      <DialogContent>
        <Box padding={{ top: 'medium' }}>
          <DialogContentText>
            <Typography color="textPrimary" variant="body1" gutterBottom>
              By locking your PHP version you are opting out of automatic version upgrades to new releases. We only recommend locking as a temporary measure to triage compatibility issues.
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Please note: when a PHP version stops receiving security fixes we will need to upgrade PHP to the next secure version.
            </Typography>
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box direction="row" justify="space-between" flex={1} padding={{ right: 'small', left: 'small', bottom: 'xsmall' }}>
          <Button autoFocus color="default" variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onConfirm();
              setOpen(false);
            }}
          >
            I understand
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmPurchaseModal = ({
// eslint-disable-next-line react/prop-types
  onClose, onConfirm, open, isLoading, isSuccess, isError,
}) => {
  const InitialMessage = () => (
    <>
      Please confirm that you would like to purchase Long Term PHP Support for this app.
      <br />
      <br />
      <b>This will add an additional $100/month to your bill.</b>
    </>
  );

  const SuccessMessage = () => (
    <>
      <Success />
      Thank you for purchasing Long Term PHP Support. You may now close this window.
    </>
  );

  const ErrorMessage = () => (
    <ErrorText>
      There was an error processing your request. Please try again later.
    </ErrorText>
  );

  let Message = InitialMessage;
  if (isSuccess) {
    Message = SuccessMessage;
  }
  if (isError) {
    Message = ErrorMessage;
  }
  const shouldShowConfirmButton = !isLoading && !isSuccess && !isError;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
    >
      <DialogTitle>
        Long Term PHP Support
      </DialogTitle>
      <DialogContent>
        <Box padding={{ top: 'medium' }}>
          <DialogContentText>
            <Typography color="textPrimary" variant="body1" gutterBottom>
              <Message />
            </Typography>
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box direction="row" justify="space-between" flex={1} padding={{ right: 'small', left: 'small', bottom: 'xsmall' }}>
          <Button autoFocus color="default" variant="outlined" onClick={onClose} disabled={isLoading}>
            {shouldShowConfirmButton ? 'Cancel' : 'Close'}
          </Button>
          {shouldShowConfirmButton && (
            <Button
              variant="contained"
              onClick={() => {
                onConfirm();
              }}
            >
              Confirm
            </Button>
          )}
          {isLoading && (
            <LoadingDots
              style={{
                alignSelf: 'center',
                margin: 0,
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

// eslint-disable-next-line react/prop-types
const LtsPurchaseButton = ({ fetchVersions }) => {
  const { accountId } = useSelector((state) => ({ accountId: state.account?.id }));
  const [open, setOpen] = React.useState(false);
  const [purchasePlanMutation, { isSuccess, isError, isLoading }] = usePurchaseLtsAddonMutation();

  const onConfirm = () => {
    purchasePlanMutation(accountId);
  };

  const onClose = async () => {
    setOpen(false);
    fetchVersions();
  };
  return (
    <Box align="center" justify="center">
      <Button onClick={() => setOpen(true)}>Purchase Long Term PHP Support</Button>
      <ConfirmPurchaseModal open={open} onClose={onClose} onConfirm={onConfirm} isError={isError} isSuccess={isSuccess} isLoading={isLoading} />
    </Box>
  );
};

export const PhpVersion = (props) => {
  const {
    accountId, app, updateAppConfig, getAllPhpVersions, fetchRecentWebconfigJobs,
  } = props;
  const unmemoizedJobs = useSelector((state) => getRecentPhpVersionJobForApp(state, app.data.id));
  const permissions = useSelector((state) => state.permissions);
  const account = useSelector((state) => state.account);
  const job = useCallback(unmemoizedJobs, [unmemoizedJobs]);
  const { runningStatuses, SUCCESS, FAILURE } = STATUS;

  const initialState = { ...initialStateValues };

  if (props.initialVersions) {
    initialState.availableVersions = props.initialVersions;
  }

  const currentVersion = app.data?.config?.php || app.data?.config?.['docker-php-version'] || '7.X';
  if (currentVersion) {
    initialState.selectedVersion = currentVersion;
    initialState.phpVersion = currentVersion;
    initialState.versionLocked = !Number.isNaN(Number(currentVersion));
  }
  const { state, mergeState } = useMergeState(initialState);

  const {
    status,
    jobStatus,
    versionStatus,
    selectedVersion, expanded, fetchedOnce, phpVersion, versionLocked, lockModalOpen,
  } = state;

  const isWooSaas = getDeployEnvironment() === 'woosaas';
  const allAvailableVersions = state.availableVersions;
  const availableCommunityVersions = allAvailableVersions.filter((v) => !v.label.includes('zend'));
  const availableZendVersions = allAvailableVersions.filter((v) => v.label.includes('zend'));
  const zendHasBeenPurchased = availableZendVersions.length > 0;
  const accountCanPurchaseZend = !account.isOnP20Account && !isWooSaas && permissions?.directory?.[accountId]?.role >= 9;
  const shouldShowPurchaseZendButton = !zendHasBeenPurchased && accountCanPurchaseZend;

  const fetchVersions = async () => {
    const response = await getAllPhpVersions(accountId);
    mergeState({ availableVersions: response.data });
  };

  const fetchVersionsIfNone = useCallback(async () => {
    if (allAvailableVersions.length === 0) {
      await fetchVersions();
    }
    mergeState({ status: 'ready' });
  }, [getAllPhpVersions, allAvailableVersions]);

  const fetchJobs = useCallback(async () => {
    await fetchRecentWebconfigJobs(accountId, { ownerId: app.data.id, ownerType: AUTH_TYPE.app });
    mergeState({ fetchedOnce: true });
  }, [getAllPhpVersions, allAvailableVersions]);

  useEffect(() => {
    mergeState({ status: 'loading' });
    fetchVersionsIfNone();
  }, [fetchVersionsIfNone]);

  useEffect(() => {
    // pusher will provide jobs, but good to check for app jobs once on load
    fetchJobs(accountId, { ownerId: app.data.id, ownerType: AUTH_TYPE.app });
  }, []);

  useEffect(() => {
    if (job?.overallStatus && jobStatus !== job.overallStatus) {
      if ([...runningStatuses, FAILURE].includes(job.overallStatus)) {
        const [, newVersion] = getTypeAndVersionFromJob(job);
        mergeState({
          jobStatus: job.overallStatus,
          phpVersion: newVersion,
          selectedVersion: newVersion,
        });
      }
    }

    if (jobStatus !== 'pristine' && job?.overallStatus === SUCCESS) {
      // show success for 3 seconds
      mergeState({ jobStatus: SUCCESS });
      const successTimerId = setTimeout(
        () => mergeState({ jobStatus: 'pristine' }),
        3000,
      );
      return () => clearTimeout(successTimerId);
    }
  }, [job?.overallStatus, jobStatus]);

  const handleSetExpanded = (value) => {
    mergeState({ expanded: value });
    if (value === false) {
      // reset
      mergeState({
        versionStatus: 'pristine',
        selectedVersion: phpVersion,
      });
    }
  };

  const handleLockVersion = (e) => {
    const selectedVersionObject = allAvailableVersions.find((v) => (selectedVersion === v.label || selectedVersion === v.base));
    // set version to the number version
    if (e.target.checked) {
      mergeState({
        versionLocked: e.target.checked,
        selectedVersion: selectedVersionObject?.base,
      });
    } else {
      mergeState({
        versionLocked: e.target.checked,
        selectedVersion: selectedVersionObject?.label,
      });
    }
  };

  const handleSubmit = async () => {
    mergeState({ versionStatus: 'loading' });

    try {
      await updateAppConfig({
        type: 'php-version',
        appId: app.data.id,
        update: {
          phpVersion: selectedVersion,
        },
      });
      mergeState({ versionStatus: 'success' });
    } catch (err) {
      mergeState({ versionStatus: 'pristine', error: true });
    }

    setTimeout(() => mergeState({ versionStatus: 'pristine', error: false }), 5000);
  };

  const handleChange = () => {
    // we need to show a modal if version lock is selected....
    if (versionLocked) {
      mergeState({ lockModalOpen: true });
    } else {
      handleSubmit();
    }
  };

  const now = new Date();

  const upgradingOrFetching = runningStatuses.includes(job?.overallStatus) || !fetchedOnce;
  const phpVersionObject = allAvailableVersions.find((v) => (phpVersion === v.label || phpVersion === v.base));

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={() => handleSetExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box direction="row" justify="space-between" flex={1}>
          <Typography variant="subtitle1">PHP Version</Typography>
          <Box direction="row">
            {
              ((job && jobStatus !== 'pristine') || !fetchedOnce) ? (
                <LoadingDisplay
                  status={jobStatus}
                  job={job}
                  fetchedOnce={fetchedOnce}
                />
              ) : (
                <GhostTag>{phpVersionObject?.base || phpVersion}</GhostTag>
              )
            }
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div>
            <Typography variant="body2" paragraph>
              Changing PHP version can result in a minute or two of downtime. Pagely automatically maintains your PHP version for you by upgrading incremental patches and security updates.
            </Typography>
          </div>
          {
            status === 'loading' && (
              <LoadingDots />
            )
          }

          {
            status === 'ready' && (
              <>
                <Box direction="row">
                  <FormControl
                    component="fieldset"
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <RadioGroup
                      aria-label="version"
                      name="version"
                      value={selectedVersion}
                      onChange={(e) => mergeState({ selectedVersion: e.target.value })}
                    >
                      <InfoText href="https://www.php.net/supported-versions.php">
                        See the official PHP Versions schedule
                      </InfoText>
                      <Box padding={{ left: 'small' }}>
                        {
                         availableCommunityVersions
                           .map((version) => {
                             /*
                                * No Longer a requirement: App may be moved back to previously-installed version of PHP, but no further.
                                * Versions whose EOL is within 6 months from now should not be shown in the version selector
                              */
                             const eol = new Date(version.securityEol);
                             const isDeprecated = isBefore(eol, now);
                             const isInSunset = version?.label.includes('sunset');
                             // const isInSunset = isBefore(eol, sixMonthsFromNow);

                             if (isDeprecated && selectedVersion !== version.base) {
                               return null;
                             }

                             return (
                               <FormLabel
                                 labelPlacement="end"
                                 key={version.label}
                                  // if locked, value is version.base, otherwise version.label
                                 value={versionLocked ? version.base : version.label}
                                 control={<Radio disabled={upgradingOrFetching} />}
                                 align="center"
                               >
                                 <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
                                   <Box direction="row" align="center">
                                     {
                                        versionLocked ? (
                                          <Typography component="label" color={isInSunset ? 'textSecondary' : 'textPrimary'}>
                                            {version.base} {version.label && (<Typography component="em" variant="caption" color="textSecondary">({capitalize(version.label)} Version)</Typography>)}
                                          </Typography>
                                        ) : (
                                          <Typography component="label" color={isInSunset ? 'textSecondary' : 'textPrimary'}>
                                            {capitalize(version.label)} Version {version.label && (<Typography component="em" variant="caption" color="textSecondary">(currently {version.base})</Typography>)}
                                          </Typography>
                                        )
                                      }
                                     {
                                        isInSunset && (
                                          <Tooltip
                                            css={{ marginLeft: 4 }}
                                            title={`This version is deprecated and will only receive security updates until ${format(eol, 'LLL d yyy')}`}
                                          >
                                            <InfoIcon fontSize="small" color="primary" />
                                          </Tooltip>
                                        )
                                      }
                                   </Box>
                                   {
                                    version.base === '8.3' && (
                                      <Typography
                                        component="em"
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        Only compatibile with Wordpress 6.4 and higher
                                      </Typography>
                                    )
                                   }
                                   {
                                      version.stagingOnly && (
                                        <Typography component="em" variant="body2" color="textSecondary">
                                          Some extensions may not be supported on this version.
                                        </Typography>
                                      )
                                    }

                                 </Box>
                               </FormLabel>
                             );
                           })
                        }
                        <div>
                          <FormLabel
                            // if locked, value is version.base, otherwise version.label
                            control={(
                              <Checkbox
                                name="version-lock"
                                checked={versionLocked && !selectedVersion.includes('zend')}
                                onChange={handleLockVersion}
                                disabled={selectedVersion.includes('zend')}
                              />
                      )}
                            align="center"
                          >
                            <Box align="flex-end">
                              <Typography variant="caption" color="textPrimary">
                                Do not automatically upgrade my PHP Version
                              </Typography>
                              {versionLocked && (
                              <Typography variant="caption" color="textSecondary">This is not recommended</Typography>
                              )}
                            </Box>
                          </FormLabel>
                        </div>
                      </Box>
                    </RadioGroup>
                    {shouldShowPurchaseZendButton ? (
                      <LtsPurchaseButton fetchVersions={fetchVersions} />
                    ) : zendHasBeenPurchased && (
                      <RadioGroup
                        aria-label="version"
                        name="version"
                        value={selectedVersion}
                        onChange={(e) => mergeState({ selectedVersion: e.target.value })}
                      >
                        <Typography variant="h5">Long Term Support PHP</Typography>
                        <Box padding={{ left: 'small' }}>
                          {
                         availableZendVersions
                           .map((version) => {
                             return (
                               <FormLabel
                                 labelPlacement="end"
                                 key={version.label}
                                 value={version.label}
                                 control={<Radio disabled={upgradingOrFetching} />}
                                 align="center"
                               >
                                 <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
                                   <Box direction="row" align="center">
                                     <Typography component="label" color="textPrimary">
                                       {version.base}
                                     </Typography>
                                   </Box>
                                 </Box>
                               </FormLabel>
                             );
                           })
                        }
                        </Box>
                      </RadioGroup>
                    )}
                  </FormControl>
                </Box>

                {
                  (job?.overallStatus === FAILURE || state.error) && (
                    <Box margin={{ top: 'small' }}>
                      <Typography variant="paragraph" color="error">
                        Changing PHP Versions seems to have failed.
                        Please <SupportLink color="textSecondary">contact support</SupportLink> with the Job ID
                        &nbsp;&quot;<pre style={{ display: 'inline' }}>{job?.id}</pre>&quot;.
                      </Typography>
                    </Box>
                  )
                }
                <Box direction="row" margin={{ top: 'medium' }} justify="space-between">
                  <div>
                    <Button
                      color="secondary"
                      status={versionStatus}
                      disabled={app.doing || phpVersion === selectedVersion || runningStatuses.includes(job?.overallStatus)}
                      variant={phpVersion !== selectedVersion ? 'contained' : 'outlined'}
                      onClick={handleChange}
                    >
                      Update Version
                    </Button>
                  </div>
                </Box>
              </>
            )
          }

          <ConfirmModal open={lockModalOpen} setOpen={(v) => mergeState({ lockModalOpen: v })} onConfirm={handleSubmit} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

PhpVersion.propTypes = {
  accountId: number,
  app: object,
  fetchRecentWebconfigJobs: func.isRequired,
  getAllPhpVersions: func.isRequired,
  initialVersions: array, // for storybook testing only
  updateAppConfig: func.isRequired,
};

export default PhpVersion;
