import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  object, func,
} from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import * as diskPropTypes from 'shared/modules/analytics/models/propTypes';
import isPagelyAdmin from 'shared/utils/isAdmin';
import { roles as ROLES } from 'shared/modules/permissions/user/actions';
import { getDiskUsageByMount, getDimensionsByMount } from 'shared/modules/analytics/redux/actions/disk';
import { fetchAccountTags } from 'shared/modules/account/redux/actions';
import { round } from 'shared/utils';
import { getTotalDiskAddonsSize, dimensionsLoaded } from 'shared/modules/analytics/components/analyticsUtils';

import DiskFallbackHeader from '../../../components/DiskFallbackHeader';
import {
  FAILED, EMPTY, LOADING, changeDiskRoles,
} from '../../../constants';
import { logFailedDependencies } from '../diskUtils';
import DiskSummary from './DiskSummary';

const DiskSummaryLoader = (props) => {
  const {
    account, getDiskUsageByMount, getDimensionsByMount, mountUsage, mountDimensions, billingPlans, subscription, match, user, permissions, accountTags, fetchAccountTags,
  } = props;

  const [currentPlan, setCurrentPlan] = useState({});
  const [mounts, setMounts] = useState([]);
  const [usageFetched, setUsageFetched] = useState(null);

  const isAdmin = user && isPagelyAdmin(user);

  let currentRole = 0;

  if (isAdmin !== null) {
    if (isAdmin) {
      currentRole = ROLES.PRIMARY;
    } else {
      currentRole = permissions.canAccess.find((perm) => perm.targetId === account.id).role;
    }
  }

  const hasPermissionToChangeDisk = changeDiskRoles.includes(currentRole);

  const getMounts = () => {
    const mountsWithServerMeta = mountUsage.data.series
      .map((mount) => {
        const values = mount.values.filter((m) => m.every((r) => r !== null));
        const server = account.servers.find((s) => mount.tags.serverName === s.petname);
        const usage = values.slice(-1)[0][4];
        const allowed = round(values.slice(-1)[0][5], 'ten', 1);

        return {
          id: server?.id || null,
          hostname: server?.hostname || null,
          petname: server?.petname || null,
          location: mount.tags.location,
          label: server?.label || null,
          type: server?.label === 'secondary' ? 'custom' : 'standard',
          usage,
          allowed,
        };
      });
    setMounts(mountsWithServerMeta);
  };

  const getAccountPlan = async () => {
    const accountPlan = billingPlans.data.find((p) => p.id === account.billingPlanId);
    // TODO: use individual mount's allowed value for totalSize if/when we can do custom mount upgrades
    const subscriptionPlanName = account.billingPlanName;
    const hasNoDiskUpgradeTag = accountTags.data.some((tag) => tag.tagId === 'account:no-disk-upgrade');
    let planAddonsSize = 0;
    if (hasPermissionToChangeDisk && subscription.data.length > 0) {
      planAddonsSize = getTotalDiskAddonsSize(subscription.data[0].addOns);
    }

    setCurrentPlan({
      planName: subscriptionPlanName,
      planTotalSize: accountPlan.diskSpaceInc + planAddonsSize,
      planAddonsSize,
      planSize: accountPlan.diskSpaceInc,
      isUpgradable: !hasNoDiskUpgradeTag,
    });
  };

  const memoizedGetMounts = useCallback(getMounts, [mountUsage, account.servers]);
  const memoizedGetAccountPlan = useCallback(getAccountPlan, [billingPlans, subscription, account, mountUsage]);

  useEffect(() => {
    getDimensionsByMount(account.id);
    if (accountTags.params.accountId !== account.id) {
      fetchAccountTags(account.id);
    }
  }, [account.id, accountTags.params.accountId, getDimensionsByMount, fetchAccountTags]);

  useEffect(() => {
    if (mountUsage.status === 'success'
      && billingPlans.data.find((p) => p.id === account.billingPlanId)) {
      memoizedGetMounts();
      memoizedGetAccountPlan();
    }
  }, [mountUsage, account.billingPlanId, billingPlans, memoizedGetMounts, memoizedGetAccountPlan]);

  useEffect(() => {
    if (dimensionsLoaded(mountDimensions, Number(match.params.accountID))
      && accountTags.status === 'success'
      && accountTags.params.accountId === Number(match.params.accountID)
      && !usageFetched) {
      getDiskUsageByMount(account.id, {
        serverName: mountDimensions.data.dimensions.serverName,
        location: mountDimensions.data.dimensions.location,
        fromDate: moment().subtract(30, 'days').tz('UTC').format(),
        toDate: moment().tz('UTC').endOf('day').format(),
      });
      setUsageFetched(true);
    }
  }, [usageFetched, mountDimensions, account.id, getDiskUsageByMount, match.params.accountID, accountTags]);

  const dependencies = {
    mountUsage, mountDimensions, billingPlans, accountTags,
  };

  if (Object.values({ billingPlans, subscription }).some((x) => ['failed'].includes(x.status))) {
    logFailedDependencies({ billingPlans, subscription });
    return <DiskFallbackHeader account={account} mode={FAILED} message="Unable to display disk data" />;
  }

  if (['pristine', 'loading'].includes(billingPlans.status)) {
    return <DiskFallbackHeader account={account} mode={LOADING} />;
  }

  if (Object.values(dependencies).some((x) => ['failed'].includes(x.status))) {
    logFailedDependencies(dependencies);
    return (
      <DiskFallbackHeader account={account} mode={FAILED} message="Unable to display disk data" />
    );
  }

  if (mountDimensions.status === 'success' && mountDimensions.data?.dimensions?.length === 0) {
    return (
      <DiskFallbackHeader account={account} mode={EMPTY} message="No server disk data available" />
    );
  }

  if ([mountUsage.status, mountDimensions.status, billingPlans.status, accountTags.status].some((x) => ['pristine', 'loading'].includes(x))
    || !currentPlan.planName) {
    return (
      <DiskFallbackHeader account={account} mode={LOADING} />
    );
  }

  return (
    <DiskSummary
      {...props}
      mounts={mounts}
      planName={currentPlan.planName}
      planSize={currentPlan.planSize}
      planAddonsSize={currentPlan.planAddonsSize}
      planTotalSize={currentPlan.planTotalSize}
      isUpgradable={currentPlan.isUpgradable}
      hasPermissionToChangeDisk={hasPermissionToChangeDisk}
      isAdmin={isAdmin}
    />
  );
};

DiskSummaryLoader.propTypes = {
  account: object,
  accountTags: object,
  billingPlans: object,
  fetchAccountTags: func,
  getDimensionsByMount: func,
  getDiskUsageByMount: func,
  match: object,
  mountDimensions: object,
  mountUsage: diskPropTypes.usage,
  permissions: object,
  subscription: object,
  user: object,
};

export default connect(
  (state) => {
    return {
      account: state.account,
      accountTags: state.tags.account,
      billingPlans: state.billing.billingPlans,
      mountUsage: state.analytics.disk.byMount.usage,
      mountDimensions: state.analytics.disk.byMount.dimensions,
      permissions: state.permissions,
      subscription: state.billing.billingSubscription,
      user: state.user.user,
    };
  }, {
    getDimensionsByMount,
    getDiskUsageByMount,
    fetchAccountTags,
  },
)(DiskSummaryLoader);
