import moment from 'moment';

export const isInclusiveAfterDay = (day) => {
  const d = moment(day);
  const now = moment().endOf('day');
  return d.isAfter(now);
};

export const isInclusiveAfterDayUTC = (day) => {
  const d = moment.utc(day);
  const now = moment.utc().endOf('day');
  return d.isAfter(now);
};
