import React, { useContext } from 'react';
import { node, number } from 'prop-types';
import AccountContext from 'shared/modules/account/context/AccountContext';
import CircleLink from 'shared/styleguide/atoms/Links/CircleLink';

const TeamLink = ({ children, ...props }) => {
  const accountId = useContext(AccountContext).id;

  return <CircleLink to={`/account/${accountId}/team`} {...props}>{children}</CircleLink>;
};

TeamLink.propTypes = {
  accountId: number,
  children: node,
};

TeamLink.displayName = 'TeamLink';

export default TeamLink;
