import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  withStyles, Typography,
} from '@material-ui/core';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import { ColorText } from 'shared/styleguide/typography';
import colors from 'shared/styleguide/theme';

import ForgotPasswordSet from 'shared/modules/authentication/components/ForgotPasswordSet';
import ForgotPassword from 'shared/modules/authentication/components/ForgotPassword';
import { jssStyles } from 'shared/modules/authentication/components/styles';

import {
  validatePasswordResetToken,
} from 'shared/modules/authentication/redux/actions';

export class PasswordReset extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    forgotPasswordReset: PropTypes.func.isRequired,
    forgotPasswordSet: PropTypes.func.isRequired,
    forgotPasswordState: PropTypes.object,
    match: PropTypes.object,
    validatePasswordResetToken: PropTypes.func.isRequired,
    validLink: PropTypes.shape({
      status: PropTypes.string,
      statusCode: PropTypes.number,
      validation: PropTypes.object,
    }),
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.token) {
      this.props.validatePasswordResetToken(match.params.token);
    }
  }

  handleChangeState = (key, value) => {
    const state = { formErrors: {} };
    state[key] = value;
    this.setState(state);
  };

  render() {
    const {
      match, forgotPasswordState, validLink,
    } = this.props;

    const loginLink = () => (
      <Box align="center" margin={{ top: 'small' }}>
        <TextLink color="textPrimary" noUnderline to={forgotPasswordState?.status === 'success' ? '/login' : '/help'}>
          {forgotPasswordState?.status === 'success' ? 'Go to Login page' : 'Cancel'}
        </TextLink>
      </Box>
    );

    if (match.params.token && validLink?.status === 'loading') {
      return (
        <Box>
          <Loading />
          <Box margin={{ top: 'medium' }}>
            <Typography variant="body1">
              <ColorText color={colors.light20}>Verifying password reset link</ColorText>
            </Typography>
          </Box>
        </Box>
      );
    }

    if (match.params.token) {
      return (
        <>
          {
            validLink?.status === 'failed'
              ? (
                <Fragment>
                  <Typography align="center">Reset Password</Typography>
                  <div>
                    <ForgotPassword
                      callForgotPassword={this.props.forgotPassword}
                      callForgotPasswordReset={this.props.forgotPasswordReset}
                      validLink={validLink}
                    />
                    {loginLink()}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography align="center">Set New Password</Typography>

                  <div>
                    <ForgotPasswordSet
                      match={match}
                      callForgotPasswordReset={this.props.forgotPasswordReset}
                      callForgotPasswordSet={this.props.forgotPasswordSet}
                    />
                    {loginLink()}
                  </div>
                </Fragment>
              )
          }
        </>

      );
    }

    return (
      <div>
        <Typography align="center">Forgot Password</Typography>

        <ForgotPassword
          callForgotPassword={this.props.forgotPassword}
          callForgotPasswordReset={this.props.forgotPasswordReset}
        />
        {loginLink()}
      </div>
    );
  }
}

export default withStyles(jssStyles)(connect(
  (state) => ({
    forgotPasswordState: state.requests.forgotPassword,
    validLink: state.requests.forgotPassword.validateResetLink,
  }),
  {
    validatePasswordResetToken,
  },
)(PasswordReset));
