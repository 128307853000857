import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as AC from './constants';
import { sortAccessRulesById } from './selectors';

export const fetchAccessControlForApp = (appId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/ares/access-control/${appId}`),
  AC.GET_LISTS_REQUEST,
  AC.GET_LISTS_SUCCESS,
  AC.GET_LISTS_FAILURE,
  { appId },
);

export const setAccessControlList = (appId, key, body) => makeApiAction(
  ({ api }) => api().put(`/serverconfig/ares/access-control/${appId}`, body),
  AC.SET_LIST_REQUEST,
  AC.SET_LIST_SUCCESS,
  AC.SET_LIST_FAILURE,
  { appId, key },
  true,
);

export const createAccessControlList = (appId, key, body) => makeApiAction(
  ({ api }) => api().put(`/serverconfig/ares/access-control/${appId}`, body),
  AC.CREATE_LIST_REQUEST,
  AC.CREATE_LIST_SUCCESS,
  AC.CREATE_LIST_FAILURE,
  { appId, key },
  true,
);

export const deleteAccessControlList = (appId, key, { match, path }) => makeApiAction(
  ({ api }) => {
    const queryParams = qs.stringify({
      match, path,
    }, { addQueryPrefix: true });
    return api().delete(`/serverconfig/ares/access-control/${appId}${queryParams}`);
  },
  AC.DELETE_LIST_REQUEST,
  AC.DELETE_LIST_SUCCESS,
  AC.DELETE_LIST_FAILURE,
  { appId, key },
  true,
);

export const fetchGeoIdentifiers = () => makeApiAction(
  ({ api }) => api().get('/serverconfig/ares/geo'),
  'FETCHING_GEO_REQUEST',
  'FETCHING_GEO_SUCCESS',
  'FETCHING_GEO_FAILURE',
  {},
  true,
  true, // action-only
);

export const deleteOrUpdateList = (appId, key, changes) => (dispatch, getState) => {
  const state = getState().ares.access;
  const recordMaps = sortAccessRulesById(state);

  // what are you trying to delete?
  const { type, ...body } = changes;
  const nonTargetTypes = ['access', 'block', 'geo'].filter((i) => i !== type);

  // check if there are non-target properies
  const shouldSet = nonTargetTypes.some((type) => Boolean(recordMaps[type][key]));

  if (shouldSet) {
    return dispatch(setAccessControlList(appId, key, body));
  }
  return dispatch(deleteAccessControlList(appId, key, body));
};

export const createOrUpdateList = (appId, changes) => (dispatch, getState) => {
  const state = getState().ares.access;
  const recordMaps = sortAccessRulesById(state);

  // what are you trying to create?
  const { type, ...body } = changes; // if type is set here, don't want to pass it through to action creator
  const { match, path } = changes;

  // we have to construct the key
  const key = [match, path, appId].join(':');

  const nonTargetTypes = ['access', 'block', 'geo'];

  // check if there are non-target properies
  const shouldSet = nonTargetTypes.some((type) => Boolean(recordMaps[type][key]));

  if (shouldSet) {
    return dispatch(setAccessControlList(appId, key, body));
  }
  return dispatch(createAccessControlList(appId, key, body));
};
