/* eslint-disable react/prop-types */
import React, {
  Component, createRef, Fragment, useRef,
} from 'react';
import {
  string, func, object, oneOfType, number,
} from 'prop-types';

import Divider from '@material-ui/core/Divider';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Select from 'shared/styleguide/atoms/Select/Select';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import ExpandingTextField from 'shared/styleguide/molecules/ExpandingTextField';
import { ErrorText } from 'shared/styleguide/typography';
import { consolidateErrors } from 'shared/utils/validation';
import ConfirmAction from 'shared/styleguide/molecules/ConfirmAction';
import InfoText from 'shared/styleguide/molecules/InfoText';
import { useMergeState } from 'shared/hooks/useMergeState';
import { validateRecord } from './validation';
import { RECORD_TYPES } from '../constants';
import styles from '../DNS.scss';

export const AddRecord = (props) => {
  const { state, mergeState } = useMergeState({
    type: RECORD_TYPES[0],
    subdomain: '',
    values: '',
    ttl: 'auto',
    errors: {},
    loading: false,
    googleRecordsError: undefined,
  });
  const valuesRef: any = useRef('');

  const clearForm = () => {
    mergeState({
      type: RECORD_TYPES[0],
      subdomain: '',
      values: '',
      ttl: '',
      errors: {},
    });

    valuesRef.current = '';
  };

  const validate = (subdomain, values) => {
    const recordType = state.type.value;
    const errors = validateRecord(recordType, subdomain, values);

    mergeState({ errors });
    return !errors.subdomain && !errors.values;
  };

  const handleAddGoogle = () => {
    const data = {
      accountId: props.accountId,
      appId: props.zone.appId,
      zoneId: props.zone.extId,
    };
    mergeState({ loading: true });

    props.addGoogleRecords(data).then(() => {
      mergeState({ loading: false });
    }).catch((err) => {
      if (err) {
        mergeState({ googleRecordsError: consolidateErrors(err, 'zoneId', true), loading: false });
      }
    });
  };

  const handleSubmit = async () => {
    // if we have values, set them to the var
    if (!valuesRef.current || state.values) {
      valuesRef.current = [state.values];
    }

    let subdomain = state.subdomain; //eslint-disable-line

    // validate form
    const isValid = validate(state.subdomain, valuesRef.current);

    if (!isValid) {
      return;
    }

    if (subdomain.length > 0 && subdomain !== '@' && subdomain.charAt(subdomain.length - 1) !== '.') {
      subdomain = `${subdomain}.`;
    }

    if (subdomain === '@') {
      subdomain = '';
    }

    // set TTL if not already set
    let ttl = state.ttl; //eslint-disable-line
    if (!state.ttl || state.ttl === 'auto') {
      ttl = `${state.type.defaultTtl}`;
    }

    const data = {
      name: (subdomain + props.zone.apex),
      type: state.type.value,
      values: (valuesRef.current.filter((v) => !!v)),
      ttl,
      accountId: props.accountId,
      appId: props.zone.appId,
      zoneId: props.zone.extId,
    };

    // submit data
    try {
      mergeState({ loading: true });
      await props.updateDNSRecord(data);
      clearForm();
    } catch (err) {
      const errors = { request: 'Unable to complete the request. Please check the values' };
      if (err?.response?.status === 422) {
        errors.request = consolidateErrors(err);
      }
      mergeState({
        errors,
      });
    } finally {
      mergeState({ loading: false });
    }
  };

  const options = RECORD_TYPES;

  return (
    <Box flex={1}>
      <Box
        direction="column"
      >
        <Box direction="row" gap="small">
          <Box direction="column" align="center" justify="center">
            <Select
              className={styles.select}
              id="add-record-select"
              options={options}
              onChange={(d) => mergeState({ type: d })}
                // value={state.type.value}
              defaultValue={{ label: 'A', value: 'a' }}
              isClearable={false}
              isSearchable={false}
            />
          </Box>
          <Box direction="column" align="center" justify="center">
            <WideTextField
              margin="dense"
              placeholder="Subdomain"
              name="subdomain"
              id="subdomain"
              onChange={(e) => mergeState({ subdomain: e.target.value })}
              value={state.subdomain}
            />
          </Box>
          <Box direction="column" align="center" justify="flex-end">
            <Box margin={{ bottom: 'small' }}><i>.{props.zone.apex}</i></Box>
          </Box>
        </Box>
        <Box direction="row" gap="small">
          <Box direction="column" align="center" justify="center">
            <Select
              className={styles.select}
              options={[
                { label: 'Default', value: 'auto' },
                { label: '1m', value: 60 },
                { label: '5m', value: 300 },
                { label: '1h', value: 3600 },
                { label: '12h', value: 43200 },
                { label: '1d', value: 86400 },
              ]}
              onChange={(d) => mergeState({ ttl: d.value })}
              defaultValue={{ label: 'Default', value: 'auto' }}
              isClearable={false}
              isSearchable={false}
            />
          </Box>
          <Box direction="column" align="center" justify="center">
            {
                state.type.expanding
                  ? (
                    <ExpandingTextField
                      id="add-record"
                      fullWidth
                      wide
                      inputs={valuesRef.current}
                      onChange={(inputs) => {
                        // update our variables with new inputs
                        // not state though, because we don't need to re-render
                        valuesRef.current = inputs;
                        // @ts-ignore
                        if (inputs.join('') && state.errors?.values) {
                          mergeState({ errors: { values: '' } });
                        }
                      }}
                    />
                  )
                  : (
                    <WideTextField
                      margin="dense"
                      placeholder="Value"
                      name="value"
                      id="value"
                      value={state.values}
                      onChange={(e) => mergeState({ values: e.target.value })}
                    />
                  )
              }
          </Box>

        </Box>
        <div>
          <Box margin={{ top: 'small' }}>
            <InfoText>
              {state.type.helpText}
            </InfoText>
          </Box>
          <div>
            <ErrorText>
              {
                  Object.entries(state.errors)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join('; ')
                }
            </ErrorText>
          </div>
        </div>
      </Box>
      <Box direction="row" justify="flex-end" margin={{ bottom: 'small' }}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          loading={state.loading}
        >
          Add Record
        </Button>
      </Box>
      <Divider />
      <Box direction="row" align="center" gap="small" margin={{ top: 'small' }}>
        <ConfirmAction
          onConfirm={handleAddGoogle}
          explainerText="Add Google Workspace DNS records? If you already have records for calendar, docs, drive, or mail, they may be overwritten."
          secondaryText={(
            <Fragment>
              This will create the necessary records for Google Workspace:
              <br />
              CNAME records for calendar, docs, drive, and mail
              <br />
              MX records for gmail
            </Fragment>
            )}
          variant="outlined"
          color="default"
          loading={state.loading}
        >
          Or Add Google Workspace Records
        </ConfirmAction>
      </Box>
      {state.googleRecordsError && (
      <ErrorText>
        {state.googleRecordsError}
      </ErrorText>
      )}
    </Box>
  );
};

export default AddRecord;
