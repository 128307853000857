import React from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';

import Select from 'shared/styleguide/atoms/Select/Select';

import {
  updateItem, deleteItem, updateKey,
} from '../redux/actions';

import { getOptions, getConfigType } from '../helpers';

const PropKeys = ({
  state, propLevels, value, updateKey, schema,
}) => {
  const configType = getConfigType(state.config);
  const propName = Object.keys(value)[0];
  const rulesList = ['rules', 'match', 'matchExcept', 'actions'];
  const opts = getOptions(propLevels, schema, configType);
  let optsArr = Object.keys(opts);
  optsArr = optsArr.filter((x) => !rulesList.includes(x));
  const optsCurrent = get(state, propLevels);
  const optsArrCurrent = Object.keys(optsCurrent);
  const optsAvailable = optsArr.filter((x) => !optsArrCurrent.includes(x));
  const optsAvailableSelect = optsAvailable.map(((item) => ({
    value: item,
    label: item,
  })));
  optsAvailableSelect.unshift({ value: null, label: propName });

  return (
    <div
      style={{ width: 180, marginRight: 10 }}
    >
      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        { /* eslint-disable jsx-a11y/no-onchange */}
        <Select
          onChange={(e) => {
            if (e.value !== null) {
              updateKey(propLevels, propName, e.value, schema, configType);
            }
          }}
          placeholder={propName}
          options={optsAvailableSelect}
          value={propName}
        />
      </div>
    </div>
  );
};

PropKeys.propTypes = {
  propLevels: any,
  schema: any,
  state: any,
  updateKey: any,
  value: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, {
  updateItem, deleteItem, updateKey,
})(PropKeys);
