import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as APP_DNS from './constants';

export const getAllValidations = (appId) => makeApiAction(
  ({ api }) => api().get(`/dns/validation/app/${appId}`),
  APP_DNS.GET_VALIDATIONS_REQUEST,
  APP_DNS.GET_VALIDATIONS_SUCCESS,
  APP_DNS.GET_VALIDATIONS_FAILURE,
  {
    appId,
  },
);

export const getValidationForDomain = (domainId) => makeApiAction(
  ({ api }) => api().get(`/dns/validation/${domainId}`),
  APP_DNS.GET_VALIDATION_REQUEST,
  APP_DNS.GET_VALIDATION_SUCCESS,
  APP_DNS.GET_VALIDATION_FAILURE,
  {
    domainId,
  },
);

export const triggerValidation = ({ domainId, appId }) => makeApiAction(
  ({ api }) => api().post(`/dns/validation/${domainId}/validate`),
  APP_DNS.TRIGGER_VALIDATION_REQUEST,
  APP_DNS.TRIGGER_VALIDATION_SUCCESS,
  APP_DNS.TRIGGER_VALIDATION_FAILURE,
  {
    appId,
    domainId,
  },
);

export const generateValidationRules = ({ domainId }) => makeApiAction(
  ({ api }) => api().post(`/dns/validation/${domainId}/generate`),
  APP_DNS.GENERATE_VALIDATION_REQUEST,
  APP_DNS.GENERATE_VALIDATION_SUCCESS,
  APP_DNS.GENERATE_VALIDATION_FAILURE,
  {
    domainId,
  },
);

export const fetchTtls = ({
  accountId, appId, aRecords, cnameRecords,
}) => makeApiAction(
  ({ api }) => api().get(`/dns/account/${accountId}/app/${appId}/ttl?${qs.stringify({ aRecords, cnameRecords })}`),
  APP_DNS.GENERATE_VALIDATION_REQUEST,
  APP_DNS.GENERATE_VALIDATION_SUCCESS,
  APP_DNS.GENERATE_VALIDATION_FAILURE,
  {
    accountId, appId, aRecords, cnameRecords,
  },
  true,
  true,
);
