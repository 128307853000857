import React from 'react';
import PropTypes from 'prop-types';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

const CertType = ({ type, name, alternateNames }) => {
  let color = 'turquoise';
  let content = 'DV Cert';

  if (alternateNames.length > 2) {
    color = 'salmon';
    content = 'SAN Cert';
  }

  if (name.match(/^\*/)) {
    color = 'lightPurple';
    content = 'Wildcard';
  }

  if (type === 'EV') {
    color = 'green';
    content = 'EV Cert';
  } else if (type === 'OV') {
    color = 'darkBlue';
    content = 'OV Cert';
  }

  return (
    <GhostTag
      color={color}
    >
      {content}
    </GhostTag>
  );
};

CertType.propTypes = {
  alternateNames: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CertType;
