import apiReducer, * as reducer from 'shared/utils/redux/apiReducer';
import { GIT_WEBHOOK } from 'shared/modules/integrations/constants';
import * as INTEGRATIONS from './constants';

export const integrations = (state = { ...reducer.baseCollectionState }, action) => {
  switch (action.type) {
    case INTEGRATIONS.CREATE_INTEGRATION_REQUEST: {
      return {
        ...reducer.loadMethod(state, action),
      };
    }
    case INTEGRATIONS.CREATE_INTEGRATION_FAILURE: {
      return {
        ...reducer.failMethod(state, action),
        apiErrors: action.errMessage,
        apiErrorStatusCode: action.statusCode,
        done: true,
      };
    }
    case INTEGRATIONS.CREATE_INTEGRATION_SUCCESS: {
      return {
        ...reducer.success(state, action),
        data: [action.data, ...state.data],
      };
    }
    case INTEGRATIONS.GET_INTEGRATION_TOKEN_SUCCESS:
    case INTEGRATIONS.UPDATE_INTEGRATION_SUCCESS: {
      const newData = [...state.data];
      const replaceIndex = newData.findIndex((i) => i.id === action.integrationId);
      const oldRecord = newData[replaceIndex];
      // config is sometimes null, so congif.git can throw an error
      const git = (action.data.type === GIT_WEBHOOK && action.type === INTEGRATIONS.UPDATE_INTEGRATION_SUCCESS)
        ? { ...oldRecord.config?.git }
        : { ...action.data.config?.git };

      const replacedData = {
        ...oldRecord,
        ...action.data,
        config: {
          ...oldRecord.config,
          ...action.data.config,
          git,
        },
      };
      newData.splice(replaceIndex, 1, replacedData);
      return {
        ...state,
        data: newData,
      };
    }
    case INTEGRATIONS.DELETE_INTEGRATION_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((i) => i.id !== action.integrationId),
      };
    }
    default:
      return apiReducer(
        INTEGRATIONS.LIST_INTEGRATIONS_REQUEST,
        INTEGRATIONS.LIST_INTEGRATIONS_SUCCESS,
        INTEGRATIONS.LIST_INTEGRATIONS_FAILURE,
        INTEGRATIONS.INTEGRATIONS_RESET,
        { ...reducer.baseCollectionState },
      )(state, action);
  }
};
