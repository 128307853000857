import React from 'react';
import { object, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import ReactMarkdown from 'react-markdown';

import {
  Paper, Typography,
} from '@material-ui/core';

import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';

import { updateAdminAccount } from 'admin/modules/admin/actions';

const Password = ({
  account, updateAccount,
}) => {
  const onSubmit = (values, actions) => {
    let hasErrors = false;

    if (values.password.length < 8) {
      actions.setFieldError('password', 'New Password is too short');
      hasErrors = true;
    }

    if (values.password !== values.confirmPassword) {
      actions.setFieldError('confirmPassword', 'Passwords don\'t match');
      hasErrors = true;
    }

    if (!values.oldPassword) {
      actions.setFieldError('oldPassword', 'Current Password required');
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    const { confirmPassword, ...changed } = values;
    actions.setStatus('loading');

    updateAccount(account.id, changed)
      .then(() => {
        actions.resetForm();
        setTimeout(() => {
          actions.setStatus('');
        }, 5000);
        actions.setStatus('success');
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const fields = err.response.data.body;
          Object.keys(fields).forEach((key) => {
            actions.setFieldError(key, fields[key].messages
              .join('\n\n\n ')
              .replace(/\\n/g, '\n\n\n')
              .replace(/^"|"$/g, ''));
          });
        }

        actions.setStatus('failure');
        actions.setSubmitting(false);
      });
  };

  return (
    <Paper>
      <Box padding="medium">
        <Typography variant="h4">Change Password</Typography>
        <Box>
          <Formik
            initialStatus="initial"
            initialValues={{
              currentPIN: '',
              oldPassword: '',
              password: '',
              confirmPassword: '',
            }}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              status,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box direction="row" gap="medium" wrap="wrap">
                  <WideTextField
                    id="oldPassword"
                    autoComplete="off"
                    placeholder="Current Password"
                    value={values.oldPassword}
                    error={Boolean(errors.oldPassword)}
                    helperText={<ReactMarkdown source={errors.oldPassword} />}
                    onChange={handleChange}
                    margin="normal"
                    type="password"
                  />
                </Box>
                <Box direction="row" gap="medium" wrap="wrap">
                  <WideTextField
                    id="password"
                    autoComplete="off"
                    placeholder="New Password"
                    required
                    value={values.password}
                    error={Boolean(errors.password)}
                    helperText={<ReactMarkdown source={errors.password} />}
                    onChange={handleChange}
                    margin="normal"
                    type="password"
                  />
                  <WideTextField
                    id="confirmPassword"
                    autoComplete="off"
                    placeholder="Confirm New Password"
                    required
                    value={values.confirmPassword}
                    error={Boolean(errors.confirmPassword)}
                    helperText={<ReactMarkdown source={errors.confirmPassword} />}
                    onChange={handleChange}
                    margin="normal"
                    type="password"
                  />
                </Box>
                <Box padding={{ top: 'small', bottom: 'small' }}>
                  <Typography variant="body2" color="textSecondary">
                    A confirmation email will be sent to your email ({account.email})
                  </Typography>
                </Box>
                <Box row padding={{ top: 'small' }} justify="flex-end">
                  <Button
                    status={status}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={(
                      !values.password
                      || !values.confirmPassword
                    )}
                  >
                    Save New Password
                  </Button>
                </Box>
              </form>

            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
};

Password.propTypes = {
  account: object,
  isAdmin: bool,
  updateAccount: func.isRequired,
  user: object,
};

export default connect(
  (state) => ({
    user: state.user,
    account: state.user.user,
  }),
  {
    updateAccount: updateAdminAccount,
  },
)(Password);
