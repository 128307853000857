export const currentPlan = {
  'planName': 'Pagely Corp',
  'planTotalSize': 50,
  'planAddonsSize': 0,
  'planSize': 50,
  'isUpgradable': true,
};

export const mounts = [
  {
    'id': 1004152, 'hostname': 'pagelycorp-b1.vps.pagelyhosting.com', 'petname': 'pagelycorp-b1', 'location': '/data/s1004152', 'label': 'primary', 'usage': '13.00', 'allowed': '50',
  }, {
    'id': 1004082, 'hostname': 'pagelycorp-b2.vps.pagelyhosting.com', 'petname': 'pagelycorp-b2', 'location': '/data/s1004082', 'label': 'secondary', 'usage': '11.00', 'allowed': '50',
  },
];

export const diskUsage = {
  'meta': {
    'window': 360,
    'filters': {
      'appIds': ['11012', '17251', '17528', '23206', '24901', '24902', '25399', '26825', '27560', '28514', '28515', '28516', '28705', '28899', '30507', '30893', '30979', '30980', '31477', '33669', '4121', '6731'], 'serverName': ['pagelycorp-b1'], 'mount': [], 'location': [], 'type': ['app'], 'username': [],
    },
    'fromDate': '2020-07-09T20:22:45+00:00',
    'toDate': '2020-07-10T23:59:59+00:00',
    'seriesCount': 10,
    'minTime': '2020-07-09T18:00:00Z',
    'maxTime': '2020-07-10T18:00:00Z',
  },
  'series': [{
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '17528' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 5.41, 2605, 55210], ['2020-07-10T00:00:00Z', 5.41, 2605, 55210], ['2020-07-10T06:00:00Z', 5.41, 2605, 55210], ['2020-07-10T12:00:00Z', 5.41, 2605, 55210], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '23206' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 2.1, 1011, 19291], ['2020-07-10T00:00:00Z', 2.1, 1011, 19291], ['2020-07-10T06:00:00Z', 2.1, 1011, 19291], ['2020-07-10T12:00:00Z', 2.1, 1011, 19291], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '26825' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 1.14, 551, 9607], ['2020-07-10T00:00:00Z', 1.14, 551, 9607], ['2020-07-10T06:00:00Z', 1.14, 551, 9607], ['2020-07-10T12:00:00Z', 1.14, 551, 9607], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '17251' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 0.62, 299, 18346], ['2020-07-10T00:00:00Z', 0.62, 299, 18346], ['2020-07-10T06:00:00Z', 0.62, 299, 18346], ['2020-07-10T12:00:00Z', 0.62, 299, 18346], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '11012' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 0.55, 263, 6393], ['2020-07-10T00:00:00Z', 0.55, 263, 6393], ['2020-07-10T06:00:00Z', 0.55, 263, 6393], ['2020-07-10T12:00:00Z', 0.55, 263, 6393], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '30980' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 0.51, 245, 18343], ['2020-07-10T00:00:00Z', 0.51, 245, 18343], ['2020-07-10T06:00:00Z', 0.51, 245, 18343], ['2020-07-10T12:00:00Z', 0.51, 245, 18343], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '30893' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 0.46, 222, 10407], ['2020-07-10T00:00:00Z', 0.46, 222, 10407], ['2020-07-10T06:00:00Z', 0.46, 222, 10407], ['2020-07-10T12:00:00Z', 0.46, 222, 10407], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '28514' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 0.42, 200, 10865], ['2020-07-10T00:00:00Z', 0.42, 200, 10865], ['2020-07-10T06:00:00Z', 0.42, 200, 10865], ['2020-07-10T12:00:00Z', 0.42, 200, 10865], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'pagelycorp-b1', 'type': 'app', 'appIds': '4121' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 0.41, 199, 13318], ['2020-07-10T00:00:00Z', 0.41, 199, 13318], ['2020-07-10T06:00:00Z', 0.41, 199, 13318], ['2020-07-10T12:00:00Z', 0.41, 199, 13318], ['2020-07-10T18:00:00Z', 0, null, null]],
  }, {
    'name': 'Details', 'tags': { 'serverName': 'other', 'type': 'other', 'appIds': 'other' }, 'columns': ['time', 'storage_per', 'storageUsed_max', 'numFiles_max'], 'values': [['2020-07-09T18:00:00Z', 2.38, 1146, 100415], ['2020-07-10T00:00:00Z', 2.38, 1147, 100415], ['2020-07-10T06:00:00Z', 2.38, 1147, 100415], ['2020-07-10T12:00:00Z', 2.38, 1146, 100415], ['2020-07-10T18:00:00Z', 0, 0, 0]],
  }],
};

export const mockPieData = [
  {
    'id': '69832',
    'step': 0,
    'label': '69832',
    'value': 5,
  },
  {
    'id': '65721',
    'step': 1,
    'label': '65721',
    'value': 10,
  },
  {
    'id': '21596',
    'step': 2,
    'label': '21596',
    'value': 25,
  },
  {
    'id': '98534',
    'step': 3,
    'label': '98534',
    'value': 40,
  },
  {
    'id': 'other',
    'step': 4,
    'label': 'other',
    'value': 20,
  },
];
