import React from 'react';
import { connect } from 'react-redux';

import { object, func } from 'prop-types';
import { withTheme } from '@material-ui/core';
import SharedLogin from 'shared/modules/authentication/routes/Login';

import {
  requestAdminLoginIfNeeded,
  loginLogout,
  forgotPasswordAdmin,
  forgotPasswordSetAdmin as forgotPasswordSet,
  forgotPasswordReset,
} from 'shared/modules/authentication/redux/actions';
import { getUser, getAccountPermissions } from 'shared/modules/permissions/user/actions';

const AdminLogin = ({
  login, location, match, doLogin, forgotPasswordAdmin, forgotPasswordReset, forgotPasswordSet,
}) => (
  <SharedLogin
    {...login}
    preserveUrl={location.pathname !== '/login'}
    match={match}
    location={location}
    handleLogin={doLogin}
    forgotPassword={forgotPasswordAdmin}
    forgotPasswordReset={forgotPasswordReset}
    forgotPasswordSet={forgotPasswordSet}
    accountType="admin"
  />
);

AdminLogin.propTypes = {
  doLogin: func,
  forgotPasswordAdmin: func,
  forgotPasswordReset: func,
  forgotPasswordSet: func,
  location: object,
  login: object,
  match: object,
  theme: object,
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    apiFailure: state.api.fatalError,
    user: state.user,
    account: state.account,
    permissions: state.permissions,
  };
};
const mapDispatchToProps = {
  doLogin: requestAdminLoginIfNeeded,
  doLogout: loginLogout,
  doGetUser: getUser,
  forgotPasswordAdmin,
  forgotPasswordReset,
  forgotPasswordSet,
  getAccountPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AdminLogin));
