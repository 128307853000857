import React from 'react';
import { shape } from 'prop-types';
import ArticleList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';
import { ArticleCollectionPropTypes } from 'shared/styleguide/organisms/ArticlesList/models';
import Loading from 'shared/styleguide/atoms/Loading';

import { useGetArticlesByCategoryQuery } from 'shared/modules/support/redux/query';
import DashboardCardHeading from './DashboardCardHeading';

export const DashboardArticlesList = () => {
  const { data: articles, error, isSuccess } = useGetArticlesByCategoryQuery('Getting Started');

  return (
    <div>
      <DashboardCardHeading>Getting Started With Pagely</DashboardCardHeading>
      {
          isSuccess ? (
            <ArticleList
              topic="gettingStarted"
              columns={3}
              max={6}
              articles={articles.data}
              boxProps={{
                direction: 'row',
                wrap: 'wrap',
                minWidth: 300,
              }}
            />
          ) : <Loading />
        }
    </div>
  );
};

DashboardArticlesList.propTypes = {
  articles: shape(ArticleCollectionPropTypes),
};

export default DashboardArticlesList;
