import React, { useEffect, useCallback, ReactElement } from 'react';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import Loading from 'shared/styleguide/atoms/Loading';
import type { App } from 'shared/types/App';
import Box from 'shared/styleguide/atoms/Box';
import { useMergeState } from 'shared/hooks/useMergeState';
import { getSortedFsaJobsForApp } from 'shared/modules/status/redux/selectors';
import { fetchLatestFsaSetupJob, fetchLatestFsaDisableJob, fetchJobById } from 'shared/modules/status/redux/actions';
import { fetchFsaRecord, enableFsa, disableFsa } from 'shared/modules/app/redux/fsa/actions';
import { getAllValidations, triggerValidation, fetchTtls } from 'shared/modules/app/redux/dns/actions';
import { getDNSZones } from 'shared/modules/dns/redux/actions';
import * as STATUS from 'shared/modules/status/redux/constants';
import { PRESSDNS_REGEX } from 'shared/modules/dns/constants';
import { LOADING, PRISTINE } from 'shared/utils/redux/constants';
import { consolidateErrors } from 'shared/utils/validation';

import {
  ACTIVE, INACTIVE, PENDING, PENDING_INACTIVE,
} from './constants';
import Display from './Display';

interface Props {
  __storybookMocks: any;
  app: App;
  disableFsa: any;
  dns: Record<string, any>;
  dnsJobs: Record<string, any>;
  enableFsa: any;
  fetchFsaRecord: any;
  fetchJobById: any;
  fetchLatestFsaDisableJob: any;
  fetchLatestFsaSetupJob: any;
  fetchStatusJob: any;
  fetchTtls: any;
  fsaJob: any;
  fsaRecord: Record<string, any>;
  getAllValidations: any;
  getDNSZones: any;
  handleEnableFsa: any;
  handleFetchValidations: any;
  job: Record<string, any>;
  jobs: Record<string, any>;
  onEnableFsa: any;
  onFetchAllValidations: any;
  state: Record<string, any>;
  validationRecords: any;
}

/* eslint-disable no-shadow */
export const FSARouter = ({
  app,
  fsaRecord,
  dns,
  dnsJobs,
  jobs,
  getAllValidations,
  enableFsa,
  disableFsa,
  fetchFsaRecord,
  fetchLatestFsaSetupJob,
  fetchLatestFsaDisableJob,
  fetchJobById,
  fetchTtls,
  getDNSZones,
  __storybookMocks,
}: Props): ReactElement => {
  const { state, mergeState } = useMergeState(__storybookMocks || {
    resumed: false,
    hasLoadedJobs: false,
    hasLoadedRecord: false,
    fetchingRecord: false,
    fetchingFsaJob: false,
    hasLoadedTtl: false,
    force: false,
    createLECertificate: true,
    ttl: 0,
    errors: null,
  });

  const { fetchingRecord, fetchingFsaJob } = state;

  const handleEnableFsa = async (resumed = false, options = {}) => {
    mergeState({ enable: LOADING, errors: null });
    try {
      await enableFsa(app.id, {
        ...options,
        sslProvider: 'ACM',
      });
    } catch (err) {
      mergeState({ errors: consolidateErrors(err) });
    }
    mergeState({ enable: PRISTINE, resumed });

    handleFetchFsaRecord();
    handleFetchFsaJobs();
  };

  const handleDisableFsa = async (resumed = false, options) => {
    mergeState({ disable: LOADING, errors: null });
    const { force, createLECertificate } = state;
    try {
      await disableFsa(app.id, { force, createLECertificate, ...options });
    } catch (err) {
      mergeState({ errors: consolidateErrors(err) });
    }
    mergeState({ disable: PRISTINE, resumed });

    handleFetchFsaRecord();
    handleFetchFsaJobs();
  };

  const handleFetchFsaRecord = async () => {
    mergeState({ fetchingRecord: true });
    try {
      await fetchFsaRecord(app.id);
    } catch (err) {
      // need to swallow errors here in case of 404
    }

    mergeState({
      hasLoadedRecord: true,
      fetchingRecord: false,
    });
  };

  const handleFetchFsaJobs = async () => {
    mergeState({ fetchingFsaJob: true });
    await Promise.all([fetchLatestFsaSetupJob(app.accountId, app), fetchLatestFsaDisableJob(app.accountId, app.id)]);
    mergeState({
      fetchingFsaJob: false,
      hasLoadedJobs: true,
    });
  };

  const handleFetchFsaJob = async (jobId) => {
    mergeState({ fetchingFsaJob: true });
    await fetchJobById(jobId);
    mergeState({
      fetchingFsaJob: false,
    });
  };

  const handleFetchValidations = () => {
    getAllValidations(app.id);
  };

  const handleFetchTtls = async (aRecords, cnameRecords) => {
    let ttls;
    try {
      ttls = await fetchTtls({
        accountId: app.accountId, appId: app.id, aRecords, cnameRecords,
      });
      const maxTtl = Object.values(ttls.data).reduce((acc, records) => {
        let value = acc;
        Object.values(records).forEach((record) => {
          if (record.maxTtl > acc) {
            value = record.maxTtl;
          }
        });
        return value;
      }, 0);
      mergeState({ hasLoadedTtl: true, ttl: maxTtl });
    } catch (err) {
      mergeState({ errors: consolidateErrors(err), hasLoadedTtl: true });
    }
  };

  const handleGetTtlsForZones = async () => {
    const zones = await getDNSZones({ accountId: app.accountId });
    const domains = app.aliases.filter((domain) => !domain.fqdn.match(PRESSDNS_REGEX) && domain.active);

    const aRecords = [];
    const cnameRecords = [];

    domains.forEach((domain) => {
      if (zones?.data.find((z) => z.apex === `${domain.fqdn}.`)) {
        aRecords.push(domain.fqdn);
      } else {
        cnameRecords.push(domain.fqdn);
      }
    });

    handleFetchTtls(aRecords, cnameRecords);
  };

  // jobs are sorted by date, so just grab the first one
  const fsaJob = jobs?.data?.[0];

  const shouldFetchFsa = useCallback(() => {
    if (fsaJob?.overallStatus !== STATUS.SUCCESS) {
      return false;
    }

    if (
      !fsaRecord
      || (STATUS.jobTypes.isFsaSetupJob(fsaJob.type) && [INACTIVE, PENDING].includes(fsaRecord?.status))
    ) {
      return true;
    }

    if (fsaJob.type === STATUS.jobTypes.fsaDisable && [ACTIVE, PENDING_INACTIVE].includes(fsaRecord?.status)) {
      return true;
    }

    return false;
  }, [fsaJob, fsaRecord]);

  useEffect(() => {
    handleFetchFsaRecord();
    handleFetchFsaJobs();
    handleFetchValidations();
  }, []);

  useEffect(() => {
    if (shouldFetchFsa() && !fetchingRecord) {
      // the job completed but the fsa record is stale, so refetch
      handleFetchFsaRecord();
    }
  }, [fsaJob, fsaRecord, fetchingRecord, shouldFetchFsa]);

  useEffect(() => {
    if (fsaJob && fsaJob?.partial && fetchingFsaJob === false) {
      // the job is updated by pusher but is missing data
      // so we need to refetch
      handleFetchFsaJob(fsaJob.id);
    }
  }, [fsaJob, fetchingFsaJob]);

  return (
    <Box>
      <Typography variant="h3" gutterBottom>Mercury Dynamic Site Acceleration</Typography>
      {
        (state.hasLoadedRecord && state.hasLoadedJobs)
          ? (
            <Display
              app={app}
              state={state}
              mergeState={mergeState}
              fsaJob={fsaJob}
              fsaRecord={fsaRecord}
              validationRecords={dns}
              dnsJobs={dnsJobs}
              fetchStatusJob={fetchJobById}
              onFetchAllValidations={handleFetchValidations}
              onEnableFsa={handleEnableFsa}
              onDisableFsa={handleDisableFsa}
              onGetTtlsForZones={handleGetTtlsForZones}
            />
          ) : (
            <Box padding={{ top: 'small', bottom: 'medium' }}>
              <Loading margin={{ top: null }} />
            </Box>
          )
      }
    </Box>
  );
};

export default connect(
  (state) => ({
    // @ts-ignore
    app: state.app.app.data,
    // @ts-ignore
    dns: state.app.dns,
    // @ts-ignore
    dnsJobs: state.status[STATUS.jobTypes.dnsValidation].jobs,
    // @ts-ignore
    fsaRecord: state.app.app.data.fsa,
    jobs: {
      data: getSortedFsaJobsForApp(state),
    },
  }),
  {
    enableFsa,
    disableFsa,
    fetchLatestFsaSetupJob,
    fetchLatestFsaDisableJob,
    fetchJobById,
    fetchFsaRecord,
    getAllValidations,
    triggerValidation,
    fetchTtls,
    getDNSZones,
  },
)(FSARouter);
