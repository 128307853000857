import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import isEqual from 'lodash.isequal';
import find from 'lodash.find';

class ChartComponent extends React.Component {
  static getLabelAsKey = (d) => d.label;

  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]).isRequired,
    datasetKeyProvider: PropTypes.func,
    getDatasetAtEvent: PropTypes.func,
    getElementAtEvent: PropTypes.func,
    getElementsAtEvent: PropTypes.func,
    height: PropTypes.number,
    legend: PropTypes.object,
    options: PropTypes.object,
    plugins: PropTypes.arrayOf(PropTypes.object),
    redraw: PropTypes.bool,
    type(props, propName, componentName) {
      if (!Chart.controllers[props[propName]]) {
        return new Error(
          `Invalid chart type \`${props[propName]}\` supplied to`
          + ` \`${componentName}\`.`,
        );
      }

      return undefined;
    },
    width: PropTypes.number,
  };

  static defaultProps = {
    legend: {
      display: true,
      position: 'bottom',
    },
    type: 'doughnut',
    height: 150,
    width: 300,
    redraw: false,
    options: {},
    datasetKeyProvider: ChartComponent.getLabelAsKey,
  };

  componentDidMount() {
    this.chart_instance = undefined;
    this.renderChart();
  }

  shouldComponentUpdate(nextProps) {
    const {
      type,
      options,
      plugins,
      legend,
      height,
      width,
    } = this.props;

    if (nextProps.redraw === true) {
      return true;
    }

    if (height !== nextProps.height || width !== nextProps.width) {
      return true;
    }

    if (type !== nextProps.type) {
      return true;
    }

    if (!isEqual(legend, nextProps.legend)) {
      return true;
    }

    if (!isEqual(options, nextProps.options)) {
      return true;
    }

    const nextData = this.transformDataProp(nextProps);

    if (!isEqual(this.shadowDataProp, nextData)) {
      return true;
    }

    return !isEqual(plugins, nextProps.plugins);
  }

  componentDidUpdate() {
    if (this.props.redraw) {
      this.chart_instance.destroy();
      this.renderChart();
      return;
    }

    this.updateChart();
  }

  componentWillUnmount() {
    this.chart_instance.destroy();
  }

  transformDataProp(props) {
    const { data } = props;
    if (typeof data === 'function') {
      const node = this.element;
      return data(node);
    }
    return data;
  }

  // Chart.js directly mutates the data.dataset objects by adding _meta proprerty
  // this makes impossible to compare the current and next data changes
  // therefore we memoize the data prop while sending a fake to Chart.js for mutation.
  // see https://github.com/chartjs/Chart.js/blob/master/src/core/core.controller.js#L615-L617
  memoizeDataProps() {
    if (!this.props.data) {
      return;
    }

    const data = this.transformDataProp(this.props);

    this.shadowDataProp = {
      ...data,
      datasets: data.datasets && data.datasets.map((set) => {
        return {
          ...set,
        };
      }),
    };

    return data; // eslint-disable-line consistent-return
  }

  updateChart() {
    const { options } = this.props;
    const nextData = this.memoizeDataProps(this.props);

    if (!this.chart_instance) return;

    if (options) {
      this.chart_instance.options = Chart.helpers.configMerge(this.chart_instance.options, options);
    }

    // Pipe datasets to chart instance datasets enabling
    // seamless transitions
    const currentDatasets = (this.chart_instance.config?.data && this.chart_instance.config?.data.datasets) || [];
    const nextDatasets = nextData.datasets || [];

    // use the key provider to work out which series have been added/removed/changed
    const currentDatasetKeys = currentDatasets.map(this.props.datasetKeyProvider);
    const nextDatasetKeys = nextDatasets.map(this.props.datasetKeyProvider);
    const newDatasets = nextDatasets.filter((d) => currentDatasetKeys.indexOf(this.props.datasetKeyProvider(d)) === -1);

    // process the updates (via a reverse for loop so we can safely splice deleted datasets out of the array
    for (let idx = currentDatasets.length - 1; idx >= 0; idx -= 1) {
      const currentDatasetKey = this.props.datasetKeyProvider(currentDatasets[idx]);

      if (nextDatasetKeys.indexOf(currentDatasetKey) === -1) {
        // deleted series
        currentDatasets.splice(idx, 1);
      } else {
        const retainedDataset = find(nextDatasets, (d) => this.props.datasetKeyProvider(d) === currentDatasetKey);
        if (retainedDataset) {
          // update it in place if it is a retained dataset
          currentDatasets[idx].data.splice(retainedDataset.data.length);
          retainedDataset.data.forEach((point, pid) => {
            currentDatasets[idx].data[pid] = retainedDataset.data[pid];
          });

          const { data, ...otherProps } = retainedDataset;
          currentDatasets[idx] = {
            data: currentDatasets[idx].data,
            ...currentDatasets[idx],
            ...otherProps,
          };
        }
      }
    }
    // finally add any new series
    newDatasets.forEach((d) => currentDatasets.push(d));
    const { datasets, ...rest } = nextData;

    if (this.chart_instance.config) {
      this.chart_instance.config.data = {
        ...this.chart_instance.config.data,
        ...rest,
      };
    }

    this.chart_instance.update();
  }

  handleOnClick = (event) => {
    const instance = this.chart_instance;

    const {
      getDatasetAtEvent,
      getElementAtEvent,
      getElementsAtEvent,
    } = this.props;

    if (getDatasetAtEvent && typeof getDatasetAtEvent === 'function') {
      getDatasetAtEvent(instance.getDatasetAtEvent(event), event);
    }

    if (getElementAtEvent && typeof getElementAtEvent === 'function') {
      getElementAtEvent(instance.getElementAtEvent(event), event);
    }

    if (getElementsAtEvent && typeof getElementsAtEvent === 'function') {
      getElementsAtEvent(instance.getElementsAtEvent(event), event);
    }
  };

  ref = (element) => {
    this.element = element;
  };

  renderChart() {
    const {
      options, legend, type, plugins,
    } = this.props;
    const node = this.element;
    const data = this.memoizeDataProps();

    if (typeof legend !== 'undefined' && !isEqual(ChartComponent.defaultProps.legend, legend)) {
      options.legend = legend;
    }

    this.chart_instance = new Chart(node, {
      type,
      data,
      options,
      plugins,
    });
  }

  render() {
    const { height, width } = this.props;

    return (
      <canvas // eslint-disable-line jsx-a11y/no-static-element-interactions
        ref={this.ref}
        className="canvas-chart"
        height={height}
        width={width}
        onClick={this.handleOnClick}
      />
    );
  }
}

export default ChartComponent;
// eslint-disable-next-line
export const defaults = Chart.defaults;
export { Chart };
