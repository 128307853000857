import apiReducer, {
  baseCollectionState,
} from 'shared/utils/redux/apiReducer';
import * as ANALYTICS from '../constants';

export const diskByMount = apiReducer(
  ANALYTICS.GET_DISK_BY_MOUNT_REQUEST,
  ANALYTICS.GET_DISK_BY_MOUNT_SUCCESS,
  ANALYTICS.GET_DISK_BY_MOUNT_FAILURE,
  ANALYTICS.GET_DISK_RESET,
  baseCollectionState,
);

export const diskByPath = apiReducer(
  ANALYTICS.GET_DISK_BY_PATH_REQUEST,
  ANALYTICS.GET_DISK_BY_PATH_SUCCESS,
  ANALYTICS.GET_DISK_BY_PATH_FAILURE,
  ANALYTICS.GET_DISK_RESET,
  baseCollectionState,
);

export const diskDimensionsByMount = apiReducer(
  ANALYTICS.GET_DISK_DIMENSIONS_BY_MOUNT_REQUEST,
  ANALYTICS.GET_DISK_DIMENSIONS_BY_MOUNT_SUCCESS,
  ANALYTICS.GET_DISK_DIMENSIONS_BY_MOUNT_FAILURE,
  ANALYTICS.GET_DISK_RESET,
  baseCollectionState,
);

export const diskDimensionsByPath = apiReducer(
  ANALYTICS.GET_DISK_DIMENSIONS_BY_PATH_REQUEST,
  ANALYTICS.GET_DISK_DIMENSIONS_BY_PATH_SUCCESS,
  ANALYTICS.GET_DISK_DIMENSIONS_BY_PATH_FAILURE,
  ANALYTICS.GET_DISK_RESET,
  baseCollectionState,
);
