import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';
import { trimDomain } from 'shared/utils';
import Box from 'shared/styleguide/atoms/Box';
import { useLazyGetAppsWithFiltersQuery } from 'admin/query';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import { APPS_SEARCH_REQUEST, APPS_SEARCH_RESET } from 'admin/modules/search/constants';

interface AppSearchInputProps {
  classes: Record<string, unknown>;
  currentValues: Record<string, unknown>;
  filter: string;
  form: string;
  history: History<unknown>;
  initialized: boolean;
  resetSearchAndString: () => void;
  resetSearchField: (args) => void;
  searching: boolean;
}

export const AppSearchInput = (props: AppSearchInputProps): JSX.Element => {
  const appSearch = useSelector((state: { search: { apps: any} }) => state.search.apps);
  const [appSearchText, setAppSearchText] = useState('');
  const [trigger, result] = useLazyGetAppsWithFiltersQuery();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (appSearchText === '') {
      return false;
    }
    const search = { appSearchString: trimDomain(appSearchText), activeOnly: appSearch.activeOnly };

    const { history } = props;
    dispatch({ type: APPS_SEARCH_REQUEST, params: search });
    trigger(search, true);
    history.push('/app-search');
    return false;
  };

  return (
    <Box direction="row" gap="xsmall">
      <form onSubmit={handleSubmit} autoComplete="off" aria-label="form">
        <SearchBar
          data-test-id="app-search-bar"
          text="App Search"
          onChange={(value) => setAppSearchText(value)}
          onClear={() => { dispatch({ type: APPS_SEARCH_RESET }); }}
        />
      </form>
    </Box>
  );
};

export default AppSearchInput;
