import { UPLOAD } from 'shared/modules/integrations/constants';
import { dateFieldSorter } from 'shared/utils/sorting';

export const getUploadIntegrations = (state) => {
  return {
    ...state.integrations,
    data: state.integrations.data
      .filter((integration) => integration.type === UPLOAD)
      .sort(dateFieldSorter('created', 'desc')),
  };
};
