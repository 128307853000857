import React from 'react';
import Box from '../../atoms/Box/Box';
import Card from '../../atoms/Card/Card';
import Loading from '../../atoms/Loading';

const LoadingCard = () => (
  <Box as={Card} align="center" justify="center" padding="large">
    <Loading margin={{ top: 0 }} />
  </Box>
);

LoadingCard.displayName = 'LoadingCard';

export default LoadingCard;
