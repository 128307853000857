export const sidebarWidth = 256;
export const mobileSidebarWidth = 56;

export default (theme) => ({
  sidebarWidth,
  mobileSidebarWidth,
  'drawer': {
    [theme.breakpoints.down('sm')]: {
      width: mobileSidebarWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: sidebarWidth,
    },
  },
  'root': {
    'display': 'flex',
  },
  'scrolling': {
    overflow: 'hidden auto',
    flex: '1',
  },
  'disabledIcon': {
    color: theme.palette.error.main,
  },
  'activeListItem': {
    'backgroundColor': theme.color100,
    'borderColor': `${theme.palette.primary.main}`,
  },
  'listItem': {
    'width': sidebarWidth,
    'cursor': 'pointer',
    'flex': '1',
    'padding': 0,
    'borderRadius': theme.borderRadius,
    'border': '1px solid transparent',
    '&:hover': {
      'borderColor': `${theme.color300} `,
      'backgroundColor': `${theme.color100}`,
    },
    '&$activeListItem': {
      'borderColor': `${theme.palette.primary.main}`,
      'borderBottomLeftRadius': theme.borderRadius,
      'borderBottomRightRadius': theme.borderRadius,
    },
    '& $listItemOpen': {
      'backgroundColor': `${theme.color100}`,
    },
  },
  'listItemOpen': {
  },
  'listItemContainer': {
    'borderRadius': theme.borderRadius,
    'display': 'relative',
    'padding': '0 15px',
    '&:hover *': {
      color: `${theme.color900} !important`,
    },
  },
  'listItemText': {
    letterSpacing: 0.4,
    lineHeight: 'normal',
    height: 38, // 40 - 2 for border
    display: 'flex',
    alignItems: 'center',
  },
  'listItemTextRoot': {
    margin: 0,
  },
  'listItemSubItem': {
    'padding': '0 16px',
    'display': 'flex',
    'alignItems': 'center',
    'height': 40,
    'backgroundColor': theme.color100,
    '&:last-child': {
      'borderBottomLeftRadius': '6px',
      'borderBottomRightRadius': '6px',
    },
    '&$activeListItemSubItem': {
      color: theme.palette.primary.main,
    },
    '&:hover span': {
      color: theme.palette.primary.main,
    },
  },
});

export const mobileStyles = (theme) => ({
  drawer: {
    width: sidebarWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    minWidth: 'unset',
    maxWidth: 'unset',
  },
  drawerOpen: {
    width: sidebarWidth,
    minWidth: 'unset',
    maxWidth: 'unset',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    minWidth: 'unset',
    maxWidth: 'unset',
    width: mobileSidebarWidth,
    [theme.breakpoints.up('sm')]: {
      width: mobileSidebarWidth,
    },
  },
});
