import React from 'react';
import { object, func, array } from 'prop-types';
import Select from 'shared/styleguide/atoms/Select/Select';

import CustomOption from './CustomOption';

export const AppsDropdown = ({
  apps, onChange, values, ...rest
}) => {
  const options = apps
    .filter((app) => app.active)
    .map((app) => ({
      value: app.id,
      ...app,
      label: app.label || app.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Select
      isMulti
      placeholder="Select apps"
      value={values.map((value) => options.find((v) => v.value === value))}
      onChange={onChange}
      closeMenuOnSelect={false}
      options={options}
      components={{ Option: CustomOption }}
      {...rest}
    />
  );
};

AppsDropdown.propTypes = {
  apps: array.isRequired,
  initial: object,
  onChange: func.isRequired,
  values: array.isRequired,
};
export default (AppsDropdown);
