import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTheme, Typography, TypographyProps } from '@material-ui/core';

interface FancyTypographyProps extends Omit<TypographyProps, 'color'> {
  color?: string;
  filled?: boolean;
  noMargin?: boolean;
}

const FancyTypography = ({
  // eslint-disable-next-line react/prop-types
  color, filled, noMargin, ...rest
  // these props are destructured to avoid passing them into the dom element
}: FancyTypographyProps) => <Typography color="initial" {...rest} />;

const StyledTag = styled(FancyTypography)(
  ({ noMargin, theme }) => {
    return (css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 22px;
    max-height: 24px;
    padding: 0px 8px;
    line-height: 22px;
    margin-right: ${noMargin ? 0 : '5px'};
    white-space: nowrap;
    cursor: default;
    color: ${theme.color600};
    background-color: ${theme.color200};
    border-radius: 30px;
    font-size: 13px;
    text-transform: lowercase;

    button {
      background: transparent;
      border: none;
      display: inline-block;
      cursor: pointer;
    }
  `);
  },
  ({
    color, filled, noMargin, theme,
  }) => {
    const { colors } = theme.palette;

    if (!color || !colors[color]) {
      return css``;
    }
    return css`
      :before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        background-color: ${colors[color]};
    }
    `;
  },
);

export interface GhostTagProps extends Omit<TypographyProps, 'color'> {
  filled?: boolean;
  label?: string;
  noMargin?: boolean;
  onRemove?(): void;
  children?: React.ReactNode;
  className?: string;
  color?: string;
}

const GhostTag = ({
  children, label = undefined, className = undefined, onRemove = undefined, noMargin = undefined, ...rest
}: GhostTagProps): JSX.Element => {
  const theme = useTheme();

  return (
    <StyledTag variant="body2" theme={theme} noMargin={noMargin} className={className} {...rest}>
      {label || children}
      {
        onRemove && (
          <button type="button" onClick={onRemove} css={css`margin-left: 4px;`}>X</button>
        )
      }
    </StyledTag>
  );
};

GhostTag.displayName = 'GhostTag';

export default GhostTag;
