import {
  createTheme, ThemeOptions,
} from '@material-ui/core/styles';
import type { PagelyColors } from 'materialui';
import { getDeployEnvironment } from 'shared/config';
import { remMapper } from './spacing';
import { themePresets as typography } from '../typography';

const env = getDeployEnvironment();

const monochromeLight = {
  color000: '#ffffff',
  color100: '#fafafa',
  color200: '#f3f3f3',
  color300: '#e8e8e8',
  color400: '#e3e3e3',
  color500: '#a0a0a0',
  color600: '#585858',
  color700: '#292929',
  color800: '#1d1d1d',
  color900: '#111111',
};

const monochromeDark = {
  color000: '#111111',
  color100: '#1a1a1a',
  color200: '#212121',
  color300: '#292929',
  color400: '#303030',
  color500: '#787878',
  color600: '#8c8c8c',
  color700: '#a0a0a0',
  color800: '#c5c5c5',
  color900: '#d3d3d3',
};

const pagelyColors: PagelyColors = {
  primary: '#FF00C7',
  pink: '#FF00C7',
  purple: '#744BC4',
  blue: '#00A8FF',
  green: '#00D682',
  yellow: '#FFEE00',
  orange: '#FF6F00',
  red: '#FF0000',
};

const mwcsColors = {
  primary: '#61EDEA',
  pink: '#FF20A5',
  purple: '#744BC4',
  blue: '#1976D2',
  green: '#02C54C',
  yellow: '#FEDC45',
  orange: '#F38F48',
  red: '#FD341D',
};

const boxShadow = `
  0 2px 20px -2px rgba(0,206,142,0.75),
  0 4px 10px -4px rgba(0,206,142,0.5)
`;

// TODO: move this into `getTheme` eventually?
const getDefaultTheme = (type: 'light' | 'dark', colorSet): ReturnType<typeof createTheme> => createTheme({
  borderRadius: '6px',
  color: colorSet,
  ...(type === 'light' ? monochromeLight : monochromeDark),
  palette: {
    type,
    accent: {
      main: colorSet.pink,
    },
    success: {
      main: colorSet.green,
    },
    primary: {
      main: colorSet.primary,
    },
    secondary: {
      main: colorSet.green,
    },
    error: {
      main: colorSet.red,
    },
    warning: {
      main: colorSet.orange,
    },
    info: {
      main: colorSet.blue,
    },
  },
} as ThemeOptions);

const getTheme = (type: 'light' | 'dark'): ReturnType<typeof createTheme> => {
  const colorSet = env === 'woosaas' ? mwcsColors : pagelyColors;
  const defaultTheme = getDefaultTheme(type, colorSet);
  return createTheme(defaultTheme, {
    palette: {
      ...defaultTheme.palette,
      action: {
        disabled: defaultTheme.color400,
        disabledBackground: defaultTheme.color100,
        hover: 'none',
        active: defaultTheme.color800,
      },
      text: {
        primary: defaultTheme.color900,
        secondary: defaultTheme.color600,
        link: defaultTheme.palette.secondary.light,
      },
      background: {
        paper: defaultTheme.color000,
        default: defaultTheme.color000,
        contrast: defaultTheme.color100,
        border: defaultTheme.color300,
      },
      common: {
        black: monochromeLight.color900,
        white: monochromeLight.color000,
      },
      colors: {
        ...defaultTheme.color,
        inherit: 'inherit',
        primary: defaultTheme.color.primary,
        secondary: defaultTheme.color.green,
        accent: defaultTheme.color.pink,
        action: defaultTheme.palette.action.hover,
        smoke: defaultTheme.color500,
      },
    },
    typography: {
      ...typography,
      color: defaultTheme.color900,
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiTypography: {
        color: 'textPrimary',
      },
      MuiPaper: {
        elevation: 0,
      },
      MuiCard: {
        elevation: 0,
      },
      MuiLink: {
        rel: 'noopener nofollow noreferrer',
      },
    },
    overrides: {
      MuiStepper: {
        horizontal: {
          border: 'none',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: defaultTheme.color000,
        },
        elevation0: {
          border: `1px solid ${defaultTheme.color400}`,
        },
        // Dialogs
        elevation24: {
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
        rounded: {
          borderRadius: remMapper('xsmall'),
        },
      },
      MuiPopover: {
        paper: {
          borderRadius: '12px',
        },
      },
      MuiTabs: {
        root: {
          minHeight: 'unset',
          borderBottom: `1px solid ${defaultTheme.color400}`,
          color: defaultTheme.color600,
        },
        flexContainer: {
          gap: remMapper('medium'),
        },
      },
      MuiTab: {
        root: {
          'minHeight': remMapper('large'),
          'height': remMapper('large'),
          'textTransform': 'none',
          'fontSize': '1rem',
          'padding': 0,
          'minWidth': 'unset !important',
          'color': defaultTheme.palette.text.primary,
          '&:hover': {
            textDecoration: 'none',
            color: defaultTheme.palette.primary.main,
            transition: defaultTheme.transitions.create(['color'], {
              easing: defaultTheme.transitions.easing.easeOut,
            }),
          },
          '&$selected': {
            color: defaultTheme.palette.primary.main,
          },
        },
      },
      MuiLinearProgress: {
        root: {
          borderRadius: '8px',
          height: '8px',
        },
        dashed: {
          top: '2px',
        },
        colorPrimary: {
          backgroundColor: defaultTheme.color300,
        },
      },
      MuiTypography: {
        h3: {
          '&.MuiTypography-gutterBottom': {
            marginBottom: '.5em',
          },
          '& .MuiButtonBase-root': {
            verticalAlign: 'baseline',
          },
          '& .MuiIconButton-sizeSmall': {
            'position': 'relative',
            '& .MuiIconButton-label': {
              position: 'absolute',
              right: -11,
              bottom: -5,
            },
          },
        },
      },
      MuiAccordion: {
        root: {
          'marginBottom': '8px',
          'borderRadius': '6px',
          '&:first-child': {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            'marginTop': 0,
            'marginBottom': '24px',
            '&:last-child': {
              marginBottom: '24px',
            },
          },
        },
        rounded: {
          'borderRadius': '6px',
          '&:first-child': {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          'paddingLeft': '24px',
          'minHeight': '60px',
          'backgroundColor': defaultTheme.color000,
          'borderRadius': '6px',
          'margin': 0,
          '&$expanded': {
            'borderBottomLeftRadius': 0,
            'borderBottomRightRadius': 0,
            'backgroundColor': defaultTheme.color100,
          },
        },
      },
      MuiAccordionDetails: {
        root: {
          padding: '24px',
          borderTop: `1px solid ${defaultTheme.color400}`,
        },
      },
      MuiInputLabel: {
        root: {
          zIndex: 3,
          pointerEvents: 'none',
        },
      },
      MuiCircularProgress: {
        root: {
          color: defaultTheme.color900,
        },
        colorPrimary: {
          color: defaultTheme.color900,
        },
        colorSecondary: {
          color: defaultTheme.color900,
        },
      },
      MuiButton: {
        root: {
          'borderRadius': 30,
          'textTransform': 'none',
          'padding': '3px 16px',
          '&:disabled': {
            backgroundColor: defaultTheme.color200,
            color: defaultTheme.color500,
          },
        },
        text: {
          'border': 'none',
          'textDecoration': 'underline',
          '&:hover': {
            color: defaultTheme.palette.primary.main,
            // textDecoration: 'none',
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            outline: 0,

          },
        },
        outlined: {
          'color': defaultTheme.color900,
          'border': `1px solid ${defaultTheme.color900}`,
          'backgroundColor': 'transparent',
          'padding': '3px 16px',
          '&:hover': {
            color: defaultTheme.palette.text.primary,
            textDecoration: 'none',
            outline: 0,
          },
          '&:focus': {
            color: defaultTheme.palette.text.primary,
            textDecoration: 'none',
          },
          '& svg': {
            color: defaultTheme.color900,
          },
        },
        contained: {
          'backgroundColor': defaultTheme.color.green,
          'color': monochromeLight.color900,
          'boxShadow': 'none',
          '&:hover': {
            'backgroundColor': defaultTheme.color.green,
            'textDecoration': 'none',
            'outline': 0,
            boxShadow,
          },
          get '&:focus'() {
            return this['&:hover'];
          },
          '&:disabled': {
            'backgroundColor': defaultTheme.color200,
            'color': defaultTheme.color500,
            '& .dark-MuiCircularProgress-svg': {
              color: monochromeDark.color900,
            },
          },
          '& .MuiCircularProgress-svg': {
            color: monochromeLight.color900,
          },
          '& .light-MuiCircularProgress-svg': {
            color: monochromeLight.color900,
          },
          '& .dark-MuiCircularProgress-svg': {
            color: monochromeDark.color000,
          },
          '& .light-MuiSvgIcon-root': {
            color: monochromeLight.color900,
          },
          '& .dark-MuiSvgIcon-root': {
            color: monochromeDark.color000,
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: defaultTheme.color.green,
          },
        },
        outlinedPrimary: {
          'border': `1px solid ${defaultTheme.palette.primary.main}`,
          'transition': 'background-color 0.2s ease-out',
          '&:hover': {
            'color': defaultTheme.color800,
            'backgroundColor': defaultTheme.palette.primary.main,
          },
        },
        outlinedSecondary: {
          'border': `1px solid ${defaultTheme.palette.secondary.main}`,
          'transition': 'background-color 0.2s ease-out',
          '&:hover': {
            color: defaultTheme.palette.secondary.main,
          },
          '&:focus': {
            color: defaultTheme.palette.secondary.main,
          },
        },
      },
      MuiIconButton: {
        root: {
          color: defaultTheme.color800,
        },
      },
      MuiCard: {
        root: {
          padding: `${remMapper('medium')}`,
          borderRadius: `${remMapper('xsmall')}`,
          overflow: 'unset',
        },
      },
      MuiCardContent: {
        root: {
          padding: 0,
        },
      },
      MuiCardMedia: {
        root: {
          paddingBottom: `${remMapper('xsmall')}`,
        },
      },
      MuiCardActions: {
        root: {
          padding: 0,
        },
      },
      MuiDrawer: {
        paper: {
          maxWidth: '33%',
          minWidth: 350,
        },
      },
      MuiTableContainer: {
        root: {
          color: defaultTheme.color900,
          border: `1px solid ${defaultTheme.color400}`,
          borderRadius: remMapper('xsmall'),
        },
      },
      MuiTableHead: {
        root: {
          'borderBottom': `1px solid ${defaultTheme.color400} `,
        },
      },
      MuiTableCell: {
        'root': {
          'padding': '16px 24px',
          'color': defaultTheme.color900,
          'borderBottom': `1px solid ${defaultTheme.color400}`,
        },
        'head': {
          'fontWeight': 'bold',
          'backgroundColor': defaultTheme.color100,
          '&:first-child': {
            'borderBottomLeftRadius': '0 !important',
          },
          '&:last-child': {
            'borderBottomRightRadius': '0 !important',
          },
        },
      },
      MuiTableRow: {
        root: {
          'transition': defaultTheme.transitions.create(['background-color', 'color'], {
            easing: defaultTheme.transitions.easing.easeOut,
          }),
          '&:hover': {
            background: defaultTheme.palette.action.hover,
          },
          '&:last-child .MuiTableCell-root': {
            borderBottom: 'none',
          },
          '&:last-child .MuiTableCell-root:first-child': {
            borderBottomLeftRadius: remMapper('xsmall'),
          },
          '&:last-child .MuiTableCell-root:last-child': {
            borderBottomRightRadius: remMapper('xsmall'),
          },
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
        },
      },
      MuiDialogTitle: {
        root: {
          'backgroundColor': defaultTheme.color200,
          'border': 'none',
          'borderRadius': '12px 12px 0 0',
          'display': 'flex',
          'alignItems': 'center',
          'height': '60px',
          'box-sizing': 'border-box',
          '& h2,h3,h6': {
            'fontWeight': 600,
            'fontSize': defaultTheme.typography.h6.fontSize,
          },
        },
      },
      MuiDialogContent: {
        root: {
          padding: remMapper('medium'),
          paddingBottom: 0,
        },
      },
      MuiDialogContentText: {
        root: {
          marginBottom: 0,
        },
      },
      MuiDialogActions: {
        root: {
          padding: remMapper('medium'),
        },
      },
      MuiAvatar: {
        root: {
          'border': 'none',
          '&:hover': {
            outline: `2px solid ${defaultTheme.color.green}`,
            boxShadow,
          },
          get '&:active, &:focus'() {
            return this['&:hover'];
          },
        },
        colorDefault: {
          backgroundColor: defaultTheme.color900,
          color: defaultTheme.color000,
          fontSize: remMapper('small'),
        },
      },
      MuiOutlinedInput: {
        'root': {
          'borderRadius': '6px',
          'backgroundColor': defaultTheme.color100,
          '& fieldset': {
            'borderColor': defaultTheme.color400,
          },
        },
        'input': {
          'padding': '8px 16px',
          '&::placeholder': {
            'color': defaultTheme.color500,
            'opacity': 1,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          '&$disabled': {
            color: defaultTheme.color300,
          },
        },
      },
      MuiInputBase: {
        root: {
          'minHeight': remMapper('large'),
          'borderRadius': '6px',
          '&$disabled': {
            'color': defaultTheme.color500,
            '-webkit-text-fill-color': defaultTheme.color500,
            '-webkit-opacity': '1',
          },
        },
      },
      MuiFormLabel: {
        root: {
          '&$disabled': {
            color: defaultTheme.color300,
          },
        },
      },
      MuiSnackbarContent: {
        root: {
          flexWrap: 'initial',
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: defaultTheme.color200,
        },
      },
    },
  } as ThemeOptions);
};

export const lightTheme = getTheme('light');

export const darkTheme = getTheme('dark');
