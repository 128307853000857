import React from 'react';
import { object } from 'prop-types';
import AcUnit from '@material-ui/icons/AcUnit';
import { Typography } from '@material-ui/core';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Banner from 'shared/styleguide/molecules/Banner';

const HolidayBanner = ({ today, margin }) => {
  const holidayStartDate = new Date(today.getFullYear(), 10, 14); // 12am November 14th
  const holidayEndDate = new Date(today.getFullYear(), 0, 3); // 12am January 3rd

  return (today > holidayStartDate || today < holidayEndDate) && (
    <Banner
      icon={(
        <AcUnit color="inherit" />
      )}
      margin={margin}
      subheading={(
        <Typography variant="subtitle1" component="span" color="inherit">
          <strong>During the Holidays</strong> please consider not making any <strong>major site changes</strong> due to our lighter support schedule. For our holiday support hours, please visit:&nbsp;
          <strong>
            <TextLink
              href="https://pagely.com/holiday-schedule/"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              https://pagely.com/holiday-schedule/
            </TextLink>
          </strong>
        </Typography>
      )}
      type="success"
      dataId="holiday-banner"
    />
  );
};

HolidayBanner.propTypes = {
  margin: object,
  today: object,
};

export default HolidayBanner;
