import React, { useRef } from 'react';
import { any } from 'prop-types';
import { connect } from 'react-redux';

import TextField from 'shared/styleguide/atoms/Input/TextField';

import {
  updateItem, deleteItem, updateMapKey, updateMapVal,
} from '../redux/actions';

const SubPropMapKey = ({
  propLevels, idx, subValKey, updateMapKey,
}) => {
  const inputRef = useRef(null);
  const identifier = `subMapKey.${propLevels}.${idx}`;

  return (
    <TextField
      name={identifier}
      key={identifier}
      autoComplete="off"
      inputRef={inputRef}
      fullWidth
      multiline
      margin="dense"
      defaultValue={subValKey}
      onBlur={(e) => { updateMapKey(propLevels, subValKey, e.currentTarget.value); }}
    />
  );
};

SubPropMapKey.propTypes = {
  idx: any,
  propLevels: any,
  state: any,
  subValKey: any,
  updateMapKey: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    schema: state.aresConfig.schema,
  };
};

export default connect(mapStateToProps, {
  updateItem, deleteItem, updateMapKey, updateMapVal,
})(SubPropMapKey);
