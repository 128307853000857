import React from 'react';
import {
  object, shape, func, number, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import Card from 'shared/styleguide/atoms/Card/Card';
import { updateAccount, fetchUserPool } from 'shared/modules/account/redux/actions';
import {
  removeCollaborator,
  addCollaborator,
  updateCollaboratorPermissions,
  resetCollaborator,
} from 'shared/modules/team/redux/actions';
import {
  accountPropTypes,
  collaboratorCollectionPropTypes, collaboratorPropTypes,
} from 'shared/modules/account/models/model';

import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import ShowVideo from 'shared/styleguide/organisms/ShowVideo';
import YOUTUBE from 'shared/3rdparty/youtube';

import AccessTo from './AccessTo';
import { AddCollaborator } from './AddCollaborator';
import TeamList from './CollaboratorList';

export class Team extends React.Component {
  static propTypes = {
    account: shape(accountPropTypes),
    addCollaborator: func.isRequired,
    apps: object.isRequired,
    collaborator: shape(collaboratorPropTypes),
    collaborators: collaboratorCollectionPropTypes,
    fetchUserPool: func.isRequired,
    isPagelyAdmin: bool,
    removeCollaborator: func,
    resetCollaborator: func.isRequired,
    role: number,
    updateAccount: func,
    updateCollaborator: func,
    user: object,
    userPool: object,
  };

  state = {
    filter: '',
  };

  componentDidMount() {
    const { account, userPool } = this.props;

    if (!userPool || userPool.ownerUserId !== account.id) {
      this.props.fetchUserPool(account.id).catch((err) => {
        // TODO: handle this gracefully
      });
    }
  }

  roleValues = null;

  render() {
    const { account } = this.props;
    return (
      <div>
        {
          this.props.isPagelyAdmin && (
            <Box margin={{ bottom: 'medium' }}>
              <AccessTo />
            </Box>
          )
        }

        <Typography variant="h3" gutterBottom css={{ lineHeight: '0.8rem' }}>Add a New Collaborator <ShowVideo videoId={YOUTUBE.ADD_COLLABORATOR_VIDEO_ID} title="Add A New Collaborator" style={{ verticalAlign: 'bottom' }} /></Typography>
        <Box as={Card} padding="medium" margin={{ bottom: 'mediumLarge' }} style={{ overflow: 'visible' }}>
          <AddCollaborator
            {...{
              account,
              addCollaborator: this.props.addCollaborator,
              collaborator: this.props.collaborator,
              role: this.props.role,
              apps: this.props.apps,
              userPool: this.props.userPool,
            }}
          />
        </Box>

        <Box
          direction="row"
          align="flex-end"
          justify="space-between"
          margin={{
            bottom: 'medium',
          }}
        >
          <Typography variant="h3" gutterBottom>Manage Your Team of Collaborators</Typography>
          <SearchBar
            text="Search Collaborators"
            onChange={(filter) => this.setState({ filter })}
          />
        </Box>
        <Box data-testid="team-list">
          <div css={{ width: '100%' }}>
            {
              !this.props.user || this.props.apps.loading
                ? <Loading />
                : (
                  <TeamList {...this.props} filter={this.state.filter} />
                )
            }
          </div>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  account: state.account,
  collaborators: state.accountCollaborators,
  collaborator: state.accountCollaborator,
  apps: state.apps,
  userPool: state.sso.userPool,
});

const mapDispatchToProps = {
  addCollaborator,
  removeCollaborator,
  updateCollaborator: updateCollaboratorPermissions,
  resetCollaborator,
  updateAccount,
  fetchUserPool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
