import tracker from 'shared/3rdparty/pageTracking';
import { format } from 'shared/modules/account/models';
import * as base from 'shared/baseStates';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';

import apiReducer, {
  baseCollectionState,
  initialRequestMethod,
  loadMethod,
  successMethod,
  failMethod,
  load,
  success,
  fail,
} from 'shared/utils/redux/apiReducer';

import * as SERVER from 'shared/modules/server/redux/constants';
import * as TFA from 'shared/modules/authentication/redux/mfa/constants';

import {
  accountCollaborator,
  accountCollaborators,
} from 'shared/modules/team/redux/reducer';

import {
  twoFactorDisableRequest,
  twoFactorSetupRequest,
  twoFactorVerificationRequest,
} from 'shared/modules/authentication/redux/mfa/reducer';

import {
  accountSSHKey,
  accountSSHKeys,
} from 'shared/modules/security/redux/reducer';

import { ACCOUNT_DISABLED_TAG } from 'shared/modules/metadata/constants';
import {
  emailChangeRequest,
  emailVerifyRequest,
} from './authorization/reducer';

import * as ACCOUNT from './constants';
import { p20Pools } from '../constants';

export const accountInitialState = {
  id: 0,
  active: false,
  name: '',
  pool: '',
  phone: '',
  email: '',
  title: '',
  avatarURL: '',
  companyName: '',
  billingPlanName: null,
  billingPlanId: null,
  servers: [],
  ...base.record(),
};

export function account(state = accountInitialState, action) {
  switch (action.type) {
    case ACCOUNT.ACCOUNT_RESET_VALIDATION:
      return {
        ...state,
        validation: null,
        failed: false,
      };
    case ACCOUNT.ACCOUNT_REQUEST:
    case ACCOUNT.ACCOUNT_CREATE_REQUEST:
    case ACCOUNT.ACCOUNT_UPDATE_REQUEST:
      return {
        ...state,
        ...loadMethod(state, action),
        ...base.request(),
      };
    case ACCOUNT.ACCOUNT_SUCCESS:
    case ACCOUNT.ACCOUNT_CREATE_SUCCESS:
    case ACCOUNT.ACCOUNT_UPDATE_SUCCESS: {
      const accountInfo = format(action.data);
      const isOnP20Account = p20Pools.includes(accountInfo.defPoolId);
      const isCollaborator = !accountInfo.hasSubscription;

      const newState = {
        ...successMethod(state, action),
        ...accountInfo,
        ...base.success(),
      };

      newState.isCollaborator = isCollaborator;
      newState.isOnP20Account = isOnP20Account;

      // don't do side effects in reducers, kids.
      /**
       * this is "safe" because its a one-way tracking event that
       * will never change the data we use here.
       * This should be done in the action thunk
       * but the current actions abstraction makes it difficult
       */
      tracker.push({
        isCollaborator,
        isOnP20Account,
        name: accountInfo.name,
        email: accountInfo.email,
        companyName: accountInfo.companyName,
        billingPlanName: accountInfo.billingPlanName,
      });

      return newState;
    }
    case ACCOUNT.ACCOUNT_FAILURE:
    case ACCOUNT.ACCOUNT_CREATE_FAILURE:
    case ACCOUNT.ACCOUNT_UPDATE_FAILURE:
      return {
        ...state,
        ...failMethod(state, action),
        ...base.failure(action.errMessage, action.statusCode, action.validation),
      };
    case TFA.TWO_FACTOR_VERIFY_REQUEST_SUCCESS:
      return { ...state, mfaEnabled: true };
    case TFA.TWO_FACTOR_DISABLE_REQUEST_SUCCESS:
      return { ...state, mfaEnabled: false };

    case ACCOUNT.ACCOUNT_TAGS_SUCCESS: {
      if (action?.data?.data.find((t) => t.tagId === ACCOUNT_DISABLED_TAG)) {
        return {
          ...state,
          disabled: true,
          data: {
            ...state.data,
            disabled: true,
          },
        };
      }
      return {
        ...state,
        disabled: false,
        data: {
          ...state.data,
          disabled: false,
        },
      };
    }

    case SERVER.SERVER_META_SUCCESS: {
      const mergedServers = state.servers.map((server) => {
        // find the corresponding account server
        const current = action.data?.data?.find((s) => s.id == server.id); // eslint-disable-line

        // put stuff in it.
        return { ...server, ...current };
      });

      return {
        ...structuredClone(state),
        servers: mergedServers,
      };
    }

    case LOGIN_LOGOUT_SUCCESS:
      return { ...accountInitialState };
    default:
      return state;
  }
}

export const accountTags = apiReducer(
  ACCOUNT.ACCOUNT_TAGS_REQUEST,
  ACCOUNT.ACCOUNT_TAGS_SUCCESS,
  ACCOUNT.ACCOUNT_TAGS_FAILURE,
  ACCOUNT.ACCOUNT_TAGS_RESET,
  { ...baseCollectionState },
  load,
  load,
  success,
  fail,
);

export {
  accountCollaborator,
  accountCollaborators,
  accountSSHKey,
  accountSSHKeys,
  emailChangeRequest,
  emailVerifyRequest,
  twoFactorDisableRequest,
  twoFactorSetupRequest,
  twoFactorVerificationRequest,
};
