/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';

import {
  Typography,
  Grid,
  Divider,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';

import Stepper from 'shared/styleguide/molecules/Stepper';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { ReduxStore } from 'shared/utils/redux/apiReducer';
import { Mount } from 'shared/types/App';
import { createMountKeys } from './MountOptions';
import { FormikValues } from './types';

interface Props {
  step: number;
  useStepState: [number, CallableFunction];
  mounts: ReduxStore<Mount[]>;
}

const Summary = ({ step, useStepState, mounts }: Props) => {
  const [modalStep, setModalStep] = useStepState;
  const { values, isSubmitting, errors } = useFormikContext<FormikValues>();

  const handleBack = () => {
    setModalStep(modalStep - 1);
  };

  const findMountLabel = (mountId) => {
    if (!mountId) {
      return 'default for account';
    }

    const mount = mounts.data.find((m) => m.server.mounts[0] === mountId);
    const { server } = mount;
    const role = createMountKeys(mount);
    return `${server.petname}, ${role}`;
  };

  const mountLabel = mounts.data.length > 1 ? findMountLabel(values.mountPoint) : '';
  return (
    <Fragment>
      <DialogContent>
        <Box margin={{ bottom: 'small' }}>
          <Typography variant="h4">Summary</Typography>
        </Box>
        <Grid container>
          <Grid item sm>
            <Box gap="xsmall" direction="column">
              {
                values.label && (
                  <Box>
                    <Typography><Typography component="span" color="textSecondary">App Name:</Typography> {values.label}</Typography>
                  </Box>
                )
              }
              <Box>
                <Typography component="span" color="textSecondary">Domains:</Typography>
                <Typography>
                  [Primary] {values.primaryDomain}
                </Typography>
                {
                  values.domainsWithKeys.length > 1 && (
                    <Typography css={{ paddingLeft: '1rem' }} color="textSecondary">
                      + {values.domainsWithKeys.length - 1} domain{values.domainsWithKeys.length > 1 && 's'}
                    </Typography>
                  )
                }
              </Box>
              <Box>
                <Typography>
                  <Typography component="span" color="textSecondary"> PHP Version:</Typography> {values.phpVersion}
                </Typography>
              </Box>
              {
                mounts.data.length > 1 && (
                  <Box>
                    <Typography>Mount point is {mountLabel}</Typography>
                  </Box>
                )
              }
              {
                values.multisite && (
                  <Box>
                    <Typography>
                      Multisite enabled with {values.multisiteType} structure
                    </Typography>
                  </Box>
                )
              }
              {
                values.fsaEnabled && (
                  <Box>
                    <Typography>
                      Mercury Dynamic Site Acceleration enabled
                    </Typography>
                    <Typography css={{ paddingLeft: '1rem' }} color="textSecondary">
                      + Using {
                        values.fsaSSL === 'ACM' ? 'Pagely SSL' : 'uploaded SSL certificate'
                      }
                    </Typography>
                  </Box>
                )
              }
            </Box>
          </Grid>
          <Grid item sm={1}>
            <Divider orientation="vertical" css={{ margin: '0 auto' }} />
          </Grid>
          <Grid item sm>
            <Typography paragraph variant="body1">
              Next, we’ll set up the server directories for you and create a WordPress installation.
            </Typography>
            {
              values.fsaEnabled && (
                <Typography paragraph variant="body1">
                  Mercury Dynamic Site Acceleration setup will be initialized, and may require you to make DNS changes during setup. Setup progress and required actions will be displayed in the App view.
                </Typography>
              )
            }
            <Typography paragraph variant="body1">
              <strong>
                If DNS changes are required, DNS record information will be displayed on the next screen.
              </strong>
            </Typography>
          </Grid>
        </Grid>
        {
          // @ts-ignore
          errors.general && (
            <Box align="center" padding={{ top: 'small' }}>
              {/* @ts-ignore */}
              <Typography color="error">{errors.general}</Typography>
              {
                // @ts-ignore
                errors.domains && (
                  <>
                    {/* @ts-ignore */}
                    <Typography color="error">Domain: {errors.domains}</Typography>
                  </>
                )
              }
            </Box>
          )
        }

      </DialogContent>
      <DialogActions>
        <Box margin={{ top: 'small' }} direction="row" justify="space-between" align="center" flex={1}>
          <div>
            <Button onClick={handleBack} variant="outlined" color="default">
              Back
            </Button>
          </div>
          <Box
            css={{ minWidth: '50%' }}
          >
            <Stepper
              startingStep={1}
              activeStep={step}
              classes={makeStyles({ root: { padding: 0 } })()}
              showLabels={false}
              steps={values.stepperSteps}
            />
          </Box>

          <div>
            <Button
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              Create App
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Fragment>
  );
};

export default React.memo(Summary);
