function bongoaudio() {
  const tracks = [
    'https://actions.google.com/sounds/v1/alarms/bugle_tune.ogg',
    'https://actions.google.com/sounds/v1/alarms/bugle_tune.ogg',
    'https://actions.google.com/sounds/v1/alarms/bugle_tune.ogg',
    'https://actions.google.com/sounds/v1/alarms/bugle_tune.ogg',
    'https://actions.google.com/sounds/v1/cartoon/metallic_twang_high.ogg',
    'https://actions.google.com/sounds/v1/alarms/bugle_tune.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/deflate.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/horror/hallow_wind.ogg',
    // 'https://actions.google.com/sounds/v1/horror/monster_alien_growl_calm.ogg',
    'https://actions.google.com/sounds/v1/household/phone_ringing.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/cartoon/cartoon_cowbell.ogg',
    'https://actions.google.com/sounds/v1/science_fiction/alien_beam.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/cartoon/pop.ogg',
    'https://actions.google.com/sounds/v1/science_fiction/robot_code.ogg',
  ];

  const playItem = Math.floor(Math.random() * Math.floor((tracks.length - 1)));

  const play = new Audio(tracks[playItem]);
  play.play();
}

function down() {
  const pawsup = document.querySelector('.paws.up');
  const pawsdown = document.querySelector('.paws.down');

  pawsup.classList.add('hide');
  pawsdown.classList.remove('hide');

  bongoaudio();
}

function up() {
  const pawsup = document.querySelector('.paws.up');
  const pawsdown = document.querySelector('.paws.down');

  pawsup.classList.remove('hide');
  pawsdown.classList.add('hide');
}

export {
  bongoaudio,
  up,
  down,
};
