import React from 'react';
import {
  Typography, Card, CardContent, CardActions,
} from '@material-ui/core';
import store2 from 'store2';

import SupportLink from 'shared/modules/support/Link';
import Box from 'shared/styleguide/atoms/Box';
import { CirclePost } from 'shared/styleguide/atoms/Links/CircleLink';
import { remMapper } from 'shared/styleguide/theme/spacing';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import type { Account } from 'shared/modules/account/types';
import DashboardCardHeading from './DashboardCardHeading';

interface AdminLinkProps {
  account: Account;
}

const AdminLinks = ({ account }: AdminLinkProps): JSX.Element => {
  const accessToken = store2.get('atomic_token');
  if (account.defPoolId === 20 || account.defPoolId === 0) {
    return null;
  }

  const link = `https://p${account.defPoolId}-admin.pagelyhosting.com/login.php`;

  return (
    <div
      css={{ marginBottom: remMapper('mediumLarge') }}
    >
      <DashboardCardHeading>Admin Tools</DashboardCardHeading>
      <Box
        as={Card}
      >
        <CardContent css={{
          marginBottom: remMapper('small'),
        }}
        >
          <Typography variant="h5" paragraph>
            <strong>PHPMyAdmin & Clone Tool</strong>
          </Typography>
          <Typography variant="body2" paragraph>
            phpMyAdmin comes preinstalled out of the box.
          </Typography>
          <Typography variant="body2">
            You can request to have the <TextLink href="https://support.pagely.com/hc/en-us/articles/115001695031-Advanced-Config-Guide-Pagely-Clone-Tool">Pagely Clone Tool</TextLink> enabled on your account via a <SupportLink>support ticket</SupportLink>.
          </Typography>
        </CardContent>
        <Box as={CardActions} direction="row" justify="flex-end">
          <CirclePost label="Sign in to phpMyAdmin & Pagely Clone Tool" href={link} postBody={{ accessToken }} />
        </Box>
      </Box>
    </div>
  );
};

AdminLinks.displayName = 'AdminLinks';

export default AdminLinks;
