import React from 'react';
import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import SupportLink from 'shared/modules/support/Link';

interface Props {
  jobDetails: Record<string, any>;
  jobId: string;
  jobStep: Record<string, any>;
  skipButton: any;
}

const StepFailure = ({
  jobDetails, jobId, jobStep, skipButton,
}: Props) => {
  return (
    <Box>
      <Typography paragraph>
        Something failed when running the {jobStep.label} step:
      </Typography>
      <Typography variant="body1" color="error">{jobDetails.messages.slice(-1)}</Typography>
      <Typography variant="body1" color="error" paragraph>Job ID: {jobId}</Typography>
      <Typography variant="body1">Please <SupportLink color="textSecondary">contact support</SupportLink> with the Job ID.</Typography>
      <Box direction="row" margin={{ top: 'small' }} flex={1} gap="small">
        <div>
          {skipButton()}
        </div>
      </Box>
    </Box>
  );
};

export default StepFailure;
