// import React from 'react';

import SFTPView from './SSHDetails';
import BackupsLoader from './Backups/BackupsLoader';
import Domains from './Domains/DomainsLoader';
import RedirectUi from './Redirect';
import Integrations from './Integrations';
import StatsLoader from './Stats/StatsLoader';
import AppMain from './Overview';
import DNS from './DNS';
import Logs from './Logging';
import Ares from '../../ares';

export const redirects = {
  label: 'Redirects',
  to: 'redirects',
  match: new RegExp(/\/apps\/(\d+)\/redirects$/), // eslint-disable-line
  component: RedirectUi,
};

export const children = [
  {
    label: 'Overview',
    to: 'overview',
    match: new RegExp(/\/apps\/(\d+)\/overview$/), // eslint-disable-line
    component: AppMain,
  },
  {
    label: 'Performance',
    to: 'performance',
    match: new RegExp(/\/apps\/(\d+)\/performance$/), // eslint-disable-line
    component: StatsLoader,
  },
  {
    label: 'Domains',
    to: 'domains',
    match: new RegExp(/\/apps\/(\d+)\/domains$/), // eslint-disable-line
    component: Domains,
  },
  {
    label: 'DNS',
    to: 'dns',
    match: new RegExp(/\/apps\/(\d+)\/dns$/), // eslint-disable-line
    component: DNS,
  },
  {
    label: 'SFTP',
    to: 'sftp',
    match: new RegExp(/\/apps\/(\d+)\/sftp$/), // eslint-disable-line
    component: SFTPView,
  },
  {
    label: 'ARES',
    to: 'ares/',
    path: 'ares/:subview*',
    match: new RegExp(/\/apps\/(\d+)\/ares/), // eslint-disable-line
    component: Ares,
  },
  // {
  //   label: 'CDN',
  //   to: 'cdn',
  //   match: new RegExp(/\/apps\/\d+\/cdn$/), // eslint-disable-line
  //   component: () => (<div>CDN</div>),
  // },
  {
    label: 'Integrations',
    to: 'integrations',
    match: new RegExp(/\/apps\/\d+\/integrations$/), // eslint-disable-line
    component: Integrations,
  },
  {
    label: 'Backups',
    to: 'backups',
    match: new RegExp(/\/apps\/(\d+)\/backups$/), // eslint-disable-line
    component: BackupsLoader,
  },
  {
    label: 'Logs',
    to: 'logs',
  match: new RegExp(/\/apps\/\d+\/logs$/), // eslint-disable-line
    component: Logs,
  },
];
