import React, {
  ReactChild, useState,
} from 'react';

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import AppLink from 'shared/modules/apps/Link';
import { tagMap } from 'shared/modules/status/redux/constants';

import { providerColorMap, providerNameMap } from '../constants';

interface Props {
    cdnList: any;
    filter?: string;
    onEdit: (cdnRecord) => void;
}

const PAGE_LENGTH = 10;

export const getOriginUrlString = (record) => {
  return providerNameMap[record.extProvider] === 'CloudFlare'
    ? record.extConfig?.domain
    : record.originUrl;
};

const CDNListTable = (props: Props): ReactChild => {
  const { cdnList, filter, onEdit } = props;
  const [page, setPage] = useState(1);
  const index = page * PAGE_LENGTH - PAGE_LENGTH;

  const showStatus = cdnList.some((p) => providerNameMap[p.extProvider] === 'CloudFlare');

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Origin URL</TableCell>
              <TableCell>CDN URL</TableCell>
              <TableCell>CDN Provider</TableCell>
              <TableCell>App Id</TableCell>
              {
              showStatus && (
                <TableCell>Status</TableCell>
              )
            }
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            cdnList
              .filter(
                (r) => {
                  const searchTerm = getOriginUrlString(r);
                  return searchTerm?.toLowerCase().match(filter);
                },
              )
              .sort((a, b) => {
                const aUrl = getOriginUrlString(a);
                const bUrl = getOriginUrlString(b);
                return aUrl > bUrl;
              })
              .slice(index, index + PAGE_LENGTH)
              .map((z) => (
                <TableRow key={z.id}>
                  <TableCell>
                    {getOriginUrlString(z)}
                  </TableCell>
                  <TableCell>{z.cdnUrl}</TableCell>
                  <TableCell>
                    {z.extProvider && (
                    <GhostTag
                      color={providerColorMap[z.extProvider]}
                    >
                      {providerNameMap[z.extProvider]}
                    </GhostTag>
                    )}
                  </TableCell>
                  <TableCell><AppLink appId={z.appId}>{z.appId}</AppLink></TableCell>
                  {
                    showStatus && (
                      <TableCell>
                        <GhostTag
                          color={tagMap[z.extConfig?.status]}
                        >
                          {z.extConfig?.status}
                        </GhostTag>
                      </TableCell>
                    )
                  }
                  <TableCell>
                    <Tooltip title="Edit CDN">
                      <IconButton
                        style={{
                          height: 'auto',
                          width: 'auto',
                        }}
                        onClick={() => onEdit(z)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))

          }
          </TableBody>
        </Table>
      </TableContainer>
      {
        cdnList.length > PAGE_LENGTH && (
        <Box
          margin={{ top: 'small' }}
          justify="center"
          direction="row"
        >
          <Pagination
            shape="rounded"
            count={Math.ceil(cdnList.length / PAGE_LENGTH)}
            page={page}
            onChange={(event, value) => {
              setPage(value);
            }}
          />
        </Box>
        )
      }
    </>
  );
};

export default CDNListTable;
