import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import {
  addTagToType, removeTagFromType, getAllTags, createTag,
} from 'shared/modules/metadata/redux/actions';

import AddTag from './AddTags';

const TagContainer = ({
  appTags, addTag, removeTag, getAllTags, createTag,
}) => {
  return (
    <Box margin={{ top: 'small' }}>
      <Typography variant="h3" gutterBottom>Meta Tags</Typography>
      <Box direction="row" as={Paper} padding="small" isAdmin>
        <Box direction="row" wrap="wrap" flex={1}>
          {
            appTags.data.length > 0 ? (
              (appTags.data).map((tag) => (
                <GhostTag
                  key={tag.tagId}
                  onRemove={() => { removeTag('app', tag.tagId, { appId: tag.appId }); }}
                >
                  {tag.tagId}
                </GhostTag>
              ))
            ) : (
              <Box flex={1} justify="center">
                <Typography variant="caption" color="textSecondary">There are no tags applied</Typography>
              </Box>
            )
          }
        </Box>
        <Box flex={2}>
          <AddTag
            {...{
              appTags,
              appId: appTags.params.appId,
              addTag,
              getAllTags,
              createTag,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

TagContainer.propTypes = {
  addTag: func,
  appTags: object,
  createTag: func,
  getAllTags: func,
  removeTag: func,
};

export default connect(
  (state) => ({ appTags: state.tags.app }),
  {
    addTag: addTagToType,
    removeTag: removeTagFromType,
    getAllTags,
    createTag,
  },
)(TagContainer);
