import { isArray, isObject } from 'shared/utils/types';
import { validHeaders } from '../constants';

export const validate = (body, values, actions, mode, existingRules) => {
  let hasErrors = false;
  const headers = [];

  if (!body.path) {
    hasErrors = true;
    actions.setFieldError('path', 'Must select a path. For app root, use "/".');
  }

  const extensions = isArray(values.extensions) ? values.extensions?.map((d) => d.value).join() : '';

  if (mode !== 'edit' && existingRules.includes(`${body.path}::${extensions}`)) {
    hasErrors = true;
    actions.setFieldError('path', 'Rule with same path and extensions already exists');
  }

  if (hasErrors) {
    return [{}, hasErrors];
  }

  const headerErrors = {};
  // map headers to correct API expectation
  validHeaders.forEach((header) => {
    // hasErrors = false;
    const headerValue = values[header];
    if (headerValue) {
      const contextValues = headerValue?.context || {};
      const { control, domains, ...context } = contextValues;

      let contextLength = 0;
      if (context) {
        contextLength = isObject(context)
          ? Object.values(context).flat().length // checks nested arrays for values
          : context.length;
      }

      // 'Access-Control-Allow-Credentials' does not have a context, if so skip this check
      if (headerValue.enabled && header !== 'Access-Control-Allow-Credentials') {
        if (!context || !contextLength) {
          headerErrors[header] = true;
          actions.setFieldError(header, `${header} value must be set`);
        }
        if (header === 'Content-Security-Policy' && context && context.value?.includes('\n')) {
          context.value = context.value.trim().replace(/\n/g, ' ');
        }
      }

      headers.push({
        ...body,
        type: header,
        enabled: headerValue.enabled,
        extensions,
        context: context || {},
      });
    }

    if (!headerValue?.enabled) {
      delete headerErrors[header];
    }
  });

  if (Object.keys(headerErrors).length) {
    return [{}, true];
  }

  return [headers, false];
};
