import React from 'react';
import {
  object, string,
} from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';
import Layout from 'shared/styleguide/molecules/Layout';
import { children as routes } from './routes/children';

const Configs = ({ match, parentPath }) => {
  return (
    <Layout title="Config">
      <Route
        exact
        path={`${match.path}`}
        render={() => (
          <Redirect to="configs/view" />
        )}
      />
      <Route
        path={`${match.path}/:view`}
        render={(props) => (<Navigation {...props} routes={routes} parentPath={match.url} />)}
      />
      <Switch>
        {
          routes.concat().reverse().map((child) => {
            return (
              <Route key={child.to} path={`${parentPath}/${child.to}`} component={child.component} />
            );
          })
        }
      </Switch>
    </Layout>
  );
};

Configs.propTypes = {
  match: object.isRequired,
  parentPath: string,
};

export default Configs;
