import store2 from 'store2';
import logger from 'shared/3rdparty/logger';
import { AuthType } from 'shared/types/Auth';
import { AccountUrl } from 'shared/modules/authentication/types/AccountUrl';

export function switchToAccount(accountId: number): void {
  const authTypes = store2.get('atomic_accounts', {}) as Record<number, AuthType>;
  if (!authTypes[accountId]) {
    return; // Sanity Check! Should never happen, but just in case.
  }
  if (authTypes[accountId].token) {
    store2.set('atomic_token', authTypes[accountId].token);
  } else {
    store2.remove('atomic_token');
  }
}

export function logoutOfAccount(accountId: number): AuthType {
  const authTypes = store2.get('atomic_accounts', {}) as Record<number, AuthType>;
  delete authTypes[accountId].token;
  store2.set('atomic_accounts', authTypes);

  return authTypes[accountId];
}

export function getAuthTypes(): Record<number, AuthType> {
  return store2.get('atomic_accounts', {}) as Record<number, AuthType>;
}

export function getAuthType(accountId: number): AuthType | null {
  const authTypes = store2.get('atomic_accounts', {}) as Record<number, AuthType>;
  return authTypes[accountId] ?? authTypes[0];
}

export function setToken(accountId: number, token: string): AuthType | Partial<AuthType> {
  const authTypes = store2.get('atomic_accounts', {}) as Record<number, (AuthType | Partial<AuthType>)>;
  if (!authTypes[accountId]) {
    // This can happen if your token expired, you're logged out, and all your localstorage cleared
    // or if this is called before processAccountUrls, sso is logged in but you're not logged into atomic
    logger.log('Storing token', { accountId, authTypes });
    authTypes[accountId] = {};
  }
  authTypes[accountId].token = token;
  store2.set('atomic_accounts', authTypes);

  return authTypes[accountId];
}

export function processAccountUrls(accountUrls: AccountUrl[]): Record<number, AuthType> {
  const authTypes: Record<number, AuthType> = {
    0: { mode: 'password' },
  };

  accountUrls.forEach((accountUrl) => {
    const [, id] = accountUrl.ownerUserId.split(':');
    authTypes[parseInt(id, 10)] = {
      mode: 'sso',
      accountUrl,
    };
  });
  store2.set('atomic_accounts', authTypes);

  return authTypes;
}
