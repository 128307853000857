export const EMAIL_CHANGE_REQUEST_REQUEST = 'EMAIL_CHANGE_REQUEST_REQUEST';
export const EMAIL_CHANGE_REQUEST_SUCCESS = 'EMAIL_CHANGE_REQUEST_SUCCESS';
export const EMAIL_CHANGE_REQUEST_FAILURE = 'EMAIL_CHANGE_REQUEST_FAILURE';
export const EMAIL_VERIFY_REQUEST_REQUEST = 'EMAIL_VERIFY_REQUEST_REQUEST';
export const EMAIL_VERIFY_REQUEST_SUCCESS = 'EMAIL_VERIFY_REQUEST_SUCCESS';
export const EMAIL_VERIFY_REQUEST_FAILURE = 'EMAIL_VERIFY_REQUEST_FAILURE';

export const EMAIL_CHANGE_REQUEST_RESET = 'EMAIL_CHANGE_REQUEST_RESET';
export const EMAIL_VERIFY_REQUEST_RESET = 'EMAIL_VERIFY_REQUEST_RESET';

export const EMAIL_CHECK_TOKEN_REQUEST = 'ACCOUNT_EMAIL_CHECK_TOKEN_REQUEST';
export const EMAIL_CHECK_TOKEN_SUCCESS = 'ACCOUNT_EMAIL_CHECK_TOKEN_SUCCESS';
export const EMAIL_CHECK_TOKEN_FAILURE = 'ACCOUNT_EMAIL_CHECK_TOKEN_FAILURE';
