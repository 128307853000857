import apiReducer, {
  baseState,
} from 'shared/utils/redux/apiReducer';
import * as DISK from './constants';

export const usage = apiReducer(
  DISK.GET_VOLUME_STORAGE_REQUEST,
  DISK.GET_VOLUME_STORAGE_SUCCESS,
  DISK.GET_VOLUME_STORAGE_FAILURE,
  DISK.RESET_USAGE,
  { ...baseState },
);
export const report = apiReducer(
  DISK.GET_REPORT_REQUEST,
  DISK.GET_REPORT_SUCCESS,
  DISK.GET_REPORT_FAILURE,
  DISK.RESET_USAGE,
  { ...baseState },
);
