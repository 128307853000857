import React, { useEffect } from 'react';
import {
  shape, func, number, object,
} from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import store from 'store2';

import {
  Checkbox, FormControlLabel, Typography,
} from '@material-ui/core';
import { getTickets } from 'shared/modules/support/redux/actions';
// import { MockTickets } from 'shared/modules/support/mocks';
import { useMergeState } from 'shared/hooks/useMergeState';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';
import Loading from 'shared/styleguide/atoms/Loading';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

import InfoText from 'shared/styleguide/molecules/InfoText';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import CircleLink from 'shared/styleguide/atoms/Links/CircleLink';
import styles from '../../Support.scss';

import { localStorageKeys, mapStatusToColor } from '../../constants';

const recent_tickets = store.namespace(localStorageKeys.recent);

const Ticket = ({ ticket: t }) => {
  const today = moment();
  const responseDate = moment(t.updatedAt);
  let ticketBlurb = t.description;
  let latestResponse;
  if (Array.isArray(t.comments) && t.comments.length > 0) {
    const lastComment = t.comments.slice(-1)[0];
    latestResponse = (
      <Box margin={{ bottom: 'xsmall' }}>
        <span
          className={styles.latestMessage}
        >
          Latest message:
        </span>
      </Box>
    );
    ticketBlurb = lastComment?.plain_body;
  }
  return (
    <Box as={Paper} key={`${t.id}`} padding="small">
      <Box direction="row" justify="space-between" align="center">
        <Box direction="row" gap="medium" align="center">
          <Typography variant="h4" component="span">
            <TextLink to={`${t.id}`} color="textPrimary">{t.subject}</TextLink>
          </Typography>
        </Box>
        <div>
          <GhostTag label={t.status} color={mapStatusToColor[t.status]} noMargin />
        </div>
      </Box>
      <Box direction="row" padding={{ top: 'xsmall' }} gap="small">
        <Typography color="textSecondary" variant="body2" component="div">
          #{t.id}
        </Typography>
        <Typography color="textSecondary" variant="body2" component="div">
          {
            responseDate.isSame(today, 'day')
              ? responseDate.fromNow()
              : responseDate.format('M/D/YYYY')
          }
        </Typography>
      </Box>
      <Box padding={{ top: 'xsmall' }}>
        <Typography variant="body1" color="textPrimary" component="div">
          {latestResponse}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div" className={styles.ticketBlurb}>
          {ticketBlurb}
        </Typography>
      </Box>
      <Box direction="row" justify="flex-end">
        <CircleLink to={`${t.id}`}>
          View ticket
        </CircleLink>
      </Box>
    </Box>
  );
};

export const RecentTickets = (props) => {
  const { tickets, accountId } = props;
  const initialFilters = recent_tickets.getAll({ closed: false, solved: true });
  const { state: filters, mergeState: setFilters } = useMergeState(initialFilters);

  useEffect(() => {
    props.getTickets(accountId);
  }, []);

  const handleCheck = (type) => (e) => {
    setFilters({ [type]: e.target.checked });
    // set local storage
    recent_tickets.set(type, e.target.checked);
  };

  if (tickets.status === 'loading') {
    return (
      <div>
        <InfoText>New tickets may take a few minutes to appear on the list.</InfoText>
        <Loading />
      </div>
    );
  }

  if (tickets.status === 'success' && tickets.data.length === 0) {
    return (
      <Empty>
        <Box align="center">
          <Typography paragraph>
            There are no ticket to display.
          </Typography>
          <InfoText>
            New tickets may take a few minutes to appear on the list.
          </InfoText>
        </Box>
      </Empty>
    );
  }

  const filterTickets = (ticket) => {
    if (!filters.closed && ticket.status === 'closed') {
      return false;
    }
    if (!filters.solved && ticket.status === 'solved') {
      return false;
    }
    return true;
  };

  const filteredTickets = tickets.data
    .filter(filterTickets);

  return (
    <div>
      <InfoText noMargin css={{ marginBottom: 20 }}>New tickets may take a few minutes to appear on the list.</InfoText>
      <Box direction="row" css={{ marginBottom: 7 }}>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={filters.closed}
                onChange={handleCheck('closed')}
                color="primary"
              />
            )}
            label="Show closed tickets"
          />
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={filters.solved}
                onChange={handleCheck('solved')}
                color="primary"
              />
            )}
            label="Show solved tickets"
          />
        </div>
      </Box>
      <Box direction="column" gap="xsmall">
        {
          filteredTickets
            .map((t) => (
              <Ticket key={`${t.id}`} ticket={t} accountId={accountId} />
            ))
        }
        {
          filteredTickets.length === 0 && (
            <Empty>
              There are no tickets to display.
            </Empty>
          )
        }
      </Box>
    </div>
  );
};

RecentTickets.propTypes = {
  accountId: number.isRequired,
  getTickets: func.isRequired,
  match: shape({}),
  tickets: shape({}),
};

Ticket.propTypes = {
  ticket: object,
};

export default connect(
  (state) => ({
    accountId: state.account.id,
    tickets: state.support.tickets,
  }),
  { getTickets },
)(RecentTickets);
