import React from 'react';
import Box from 'shared/styleguide/atoms/Box/Box';
import Bongo from './Bongo';
import Lucky from './Lucky';

// eslint-disable-next-line react/display-name
export default () => {
  return (
    <Box flex={1}>
      <Lucky />
      <Bongo />
    </Box>
  );
};
