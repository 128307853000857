export const LIST_INTEGRATIONS_REQUEST = 'integrations/LIST_INTEGRATIONS_REQUEST';
export const LIST_INTEGRATIONS_SUCCESS = 'integrations/LIST_INTEGRATIONS_SUCCESS';
export const LIST_INTEGRATIONS_FAILURE = 'integrations/LIST_INTEGRATIONS_FAILURE';

export const CREATE_INTEGRATION_REQUEST = 'integrations/CREATE_INTEGRATION_REQUEST';
export const CREATE_INTEGRATION_SUCCESS = 'integrations/CREATE_INTEGRATION_SUCCESS';
export const CREATE_INTEGRATION_FAILURE = 'integrations/CREATE_INTEGRATION_FAILURE';

export const UPDATE_INTEGRATION_REQUEST = 'integrations/UPDATE_INTEGRATION_REQUEST';
export const UPDATE_INTEGRATION_SUCCESS = 'integrations/UPDATE_INTEGRATION_SUCCESS';
export const UPDATE_INTEGRATION_FAILURE = 'integrations/UPDATE_INTEGRATION_FAILURE';

export const DELETE_INTEGRATION_REQUEST = 'integrations/DELETE_INTEGRATION_REQUEST';
export const DELETE_INTEGRATION_SUCCESS = 'integrations/DELETE_INTEGRATION_SUCCESS';
export const DELETE_INTEGRATION_FAILURE = 'integrations/DELETE_INTEGRATION_FAILURE';

export const GET_INTEGRATION_TOKEN_REQUEST = 'integrations/GET_INTEGRATION_TOKEN_REQUEST';
export const GET_INTEGRATION_TOKEN_SUCCESS = 'integrations/GET_INTEGRATION_TOKEN_SUCCESS';
export const GET_INTEGRATION_TOKEN_FAILURE = 'integrations/GET_INTEGRATION_TOKEN_FAILURE';

export const INTEGRATIONS_RESET = 'integrations/INTEGRATIONS_RESET';
