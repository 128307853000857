import React from 'react';
import {
  number, func, object,
} from 'prop-types';
import { connect } from 'react-redux';

import { getAppsById } from 'shared/modules/app/redux/selectors';

import useDebouncedCallback from 'shared/utils/hooks/useDebouncedCallback';

import LoadData from '../LoadData';
import {
  getDimensionsByPath,
  getDiskUsageByPath,
} from '../../redux/actions/disk';

export const LoadDiskByPath = ({
  accountId, apps, getDimensions, getData, diskUsage, startDate, endDate, ...props
}) => {
  const debouncedGetData = useDebouncedCallback((accountId, options) => {
    getData(accountId, options);
  }, 2000, {});

  return (
    <LoadData
      {...props}
      accountId={accountId}
      apps={apps}
      getDimensions={getDimensions}
      getData={debouncedGetData}
      path="byPath"
      dimensions={diskUsage.dimensions}
      chartData={diskUsage.usage}
      defaultLeftAxis="storage_per"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

LoadDiskByPath.propTypes = {
  accountId: number,
  apps: object.isRequired,
  diskUsage: object.isRequired,
  endDate: object.isRequired,
  getData: func.isRequired,
  getDimensions: func.isRequired,
  startDate: object.isRequired,
};

export default connect(
  (state) => ({
    accountId: state.account.id,
    apps: getAppsById(state),
    diskUsage: state.analytics.disk.byPath,
  }),
  {
    getData: getDiskUsageByPath,
    getDimensions: getDimensionsByPath,
  },
)(LoadDiskByPath);
