import React, { useCallback, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableRow, Typography,
} from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { IdentityProvider, UserPool } from 'shared/modules/account/redux/sso/types';
import Box from 'shared/styleguide/atoms/Box';
import DestructiveAction from 'shared/styleguide/molecules/DestructiveAction';
import InfoText from 'shared/styleguide/molecules/InfoText';

interface IdentityProviderRowProps {
  onDelete: () => void;
  idp: IdentityProvider;
  isLastRow: boolean;
}

const IdentityProviderRow: React.FC<IdentityProviderRowProps> = ({ idp, onDelete, isLastRow }) => {
  const createdOn = idp.creationDate
    && moment(idp.creationDate).format('[Created on] MMM DD, YYYY');

  const css = isLastRow && {
    borderBottom: '0px',
  };

  return (
    <TableRow>
      <TableCell css={css}>{idp.providerName}</TableCell>
      <TableCell css={css}>{createdOn}</TableCell>
      <TableCell css={css} align="right">
        <DestructiveAction
          data-testid={`delete-idp-${idp.providerName}`}
          icon={<TrashIcon />}
          onConfirm={onDelete}
          explainerText="You are about to delete an identity provider for Single Sign On."
          secondaryText="In order to fully delete the integration, you will want to delete the configuration on the Identity Provider service you used. Once the integration is deleted, you and all collaborators will need to log in with username and password to access Atomic."
        >
          Delete Identity Provider
        </DestructiveAction>
      </TableCell>
    </TableRow>
  );
};

interface Props {
  userPool: UserPool | null;
  deleteIdentityProvider: (poolId: string, idpName: string) => Promise<null>;
  deleteUserPool: (poolId: string) => Promise<null>;
}

const Active: React.FC<Props> = ({ userPool, deleteIdentityProvider, deleteUserPool }) => {
  const idps: IdentityProvider[] = userPool.identityProviders;

  const deleteAction = useCallback(
    (idpName: string) => deleteIdentityProvider(userPool.identifier, idpName),
    [userPool, deleteIdentityProvider],
  );

  useEffect(() => {
    if (userPool.identityProviders.length < 1) {
      deleteUserPool(userPool.identifier);
    }
  }, [deleteUserPool, userPool]);

  return (
    <Box gap="medium" fullWidth>
      <Box>
        <Typography variant="body1" paragraph>
          SAML is now active for all collaborators on this account.
          When logging in with username, collaborators should be prompted to
          choose a workspace to log into, instead of using username and password.
        </Typography>
        <InfoText>
          MFA can not be required when Single Sign On is active
        </InfoText>
      </Box>
      <Box>
        <Table>
          <TableBody>
            {
              idps.map((idp, idx) => (
                <IdentityProviderRow
                  onDelete={() => deleteAction(idp.providerName)}
                  key={`${idp.providerName}-${idx}`}
                  idp={idp}
                  isLastRow={idx === idps.length - 1}
                />
              ))
            }
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default Active;
