import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Typography,
  AccordionSummary,
} from '@material-ui/core';

import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';

interface Props {
  domainStatus: string;
  type: string;
  domain: Record<string, any>;
}

const ValidationItemSummary = ({
  domainStatus, type, domain,
}: Props) => {
  const map = {
    valid: 'green',
    invalid: 'red',
    validating: 'orange',
  };

  const ruleValidTag = (valid) => {
    return valid
      ? <GhostTag color="green">Valid</GhostTag>
      : <GhostTag color="red">Invalid</GhostTag>;
  };

  const rulesLabel = () => {
    if (!domain.showNs) {
      // no need to show a label for rules by themselves
      return '';
    }

    const showTypeLabel = (type && type !== 'non-acm') || false;

    const typeLabelHash = {
      ns: 'NameServers',
      acm: 'ACM',
      all: '',
    };

    return `${showTypeLabel ? typeLabelHash[type].toUpperCase() : ''} Rules - `;
  };

  const nonNSRuleCount = domain.validationRules.records.reduce((acc, r) => {
    let count = acc;
    if (r.nsValid === undefined) {
      count++;
    }
    return count;
  }, 0);

  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />}>
      <Box direction="row" justify="space-between" flex={1}>
        <Typography component="span" variant="subtitle1">
          {domain.displayName}
        </Typography>
        {
          domainStatus === 'validating'
            ? (
              <GhostTag color={map[domainStatus]}>{domainStatus}</GhostTag>
            ) : (
              <Box direction="row" gap="small">
                {
                  domain.nsType === 'pagely' && domain.showNs
                  && (
                    <div>
                      <Typography variant="body1" color="textSecondary" component="span">NameServers - </Typography>
                      {ruleValidTag(domain.nsValid)}
                    </div>
                  )
                }
                {
                  nonNSRuleCount > 0
                  && (
                    <div>
                      <Typography variant="body1" color="textSecondary" component="span">{rulesLabel()}</Typography>
                      <GhostTag color={map[domainStatus]}>{domainStatus}</GhostTag>
                    </div>
                  )
                }
              </Box>
            )
        }
      </Box>
    </AccordionSummary>
  );
};

export default ValidationItemSummary;
