import React, { useEffect } from 'react';
import { func, object, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Paper } from '@material-ui/core';

import { accountPropTypes } from 'shared/modules/account/models/model';
// styleguide
import Box from 'shared/styleguide/atoms/Box';
import { localizeCurrency } from 'shared/utils';

import { InvoicesLink } from 'shared/modules/billing/Link';
import { getInvoices } from 'shared/modules/billing/redux/actions';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

import { tagMap } from '../../Invoices/utils';

const InvoiceOverview = ({ getInvoices, account, invoices }) => {
  useEffect(() => {
    if (account.billingCustomerId) {
      if (invoices.status === 'pristine' || (invoices.status === 'success' && invoices.params.customerId !== account.billingCustomerId)) {
        getInvoices(account.billingCustomerId);
      }
    }
  }, [invoices.status, account.billingCustomerId, getInvoices, invoices.params]);

  const latestInvoice = invoices.data?.[0];

  return (
    <Paper>
      <Box padding="small">
        <Typography variant="h3" color="textPrimary" gutterBottom>Latest Invoice</Typography>

        {
          invoices.status === 'loading' && (
            <Box padding="small">
              <LoadingDots />
            </Box>
          )
        }
        {
          (invoices.status === 'success' && latestInvoice) && (
            <Box direction="row" justify="space-between" align="center" margin={{ top: 'xsmall', bottom: 'small' }}>
              <Typography>Total: {localizeCurrency(latestInvoice.total / 100)}</Typography>
              <Typography>
                <GhostTag color={tagMap[latestInvoice.status].color}>{tagMap[latestInvoice.status].label}</GhostTag>
              </Typography>
            </Box>
          )
        }
        <Box direction="row" justify="flex-end" padding={{ top: 'xsmall' }}>
          <InvoicesLink>
            View All Invoices
          </InvoicesLink>
        </Box>
      </Box>
    </Paper>
  );
};

InvoiceOverview.propTypes = {
  account: shape(accountPropTypes),
  getInvoices: func,
  invoices: object,
};

export default connect(
  (state) => ({
    account: state.account,
    invoices: state.billing.invoices,
  }),
  {
    getInvoices,
  },
)(InvoiceOverview);
