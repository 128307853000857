/* eslint-disable react/prop-types */
import React from 'react';
import {
  Paper, Typography, Grid, Divider,
} from '@material-ui/core';

import type { App } from 'shared/types/App';
import StepLabel from 'shared/styleguide/molecules/StepLabel';
import Box from 'shared/styleguide/atoms/Box';
import InfoText from 'shared/styleguide/molecules/InfoText';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import {
  jobTypes, notSuccessOrNAStatuses, PENDING, FAILURE, SUCCESS, RUNNING, ACTION_REQUIRED, NA,
} from 'shared/modules/status/redux/constants';
import JobStep from 'shared/styleguide/molecules/JobStep';
import Loading from 'shared/styleguide/atoms/Loading';

import { fsaJobTypes, ACTIVE_MODIFYING } from './constants';
import FSAContent from './FSAContent';
import TeardownFsa from './components/TeardownFsa';

interface Props {
  app: App;
  state: Record<string, any>;
  fsaRecord: Record<string, any>;
  dnsJobs: Record<string, any>;
  job: Record<string, any>;
  fetchStatusJob: any;
  onFetchAllValidations: any;
  validationRecords: any;
  onEnableFsa: any;
  onDisableFsa: any;
  mergeState: (...args: any) => void;
}

const Partial = ({
  app,
  state,
  dnsJobs,
  job,
  validationRecords,
  fetchStatusJob,
  onFetchAllValidations,
  onEnableFsa,
  onDisableFsa,
  mergeState,
}: Props) => {
  if (!job || !job.detail) {
    return null;
  }

  // merge domain fqdn into validation data
  const validations = validationRecords.data.map((record) => {
    const domain = app.aliases.find((d) => d.id === record.domainId);
    if (domain) {
      return {
        fqdn: domain.fqdn,
        isPressDns: domain.isPressDns,
        ...record,
      };
    }
    return record;
  });

  let currentStepIndex = null;

  if (job.overallStatus === SUCCESS) {
    // last step
    currentStepIndex = job.steps.length - 1;
  } else {
    currentStepIndex = job.steps.findIndex((s) => notSuccessOrNAStatuses.includes(s.status));
  }

  let currentStepStatus = job.steps[currentStepIndex].status;
  if (job.steps[currentStepIndex].status === PENDING) {
    // show RUNNING spinner because a step may be pending for a while
    currentStepStatus = RUNNING;
  }

  const { steps } = job;
  steps[currentStepIndex].status = currentStepStatus;

  const currentStep = {
    ...steps[currentStepIndex],
    index: currentStepIndex,
  };

  const fsaHalted = [FAILURE, ACTION_REQUIRED].includes(currentStepStatus);

  const resumeMethod = jobTypes.isFsaSetupJob(job.type) ? onEnableFsa : onDisableFsa;
  const resumeApiStatus = jobTypes.isFsaSetupJob(job.type) ? state.enable : state.disable;

  const displayJobType = job.detail?.modifyDomains ? ACTIVE_MODIFYING : job.type;

  return (
    <Box>
      <Box direction="row" justify="space-between">
        <Box margin={{ bottom: 'xxsmall' }}>
          <InfoText href="https://support.pagely.com/hc/en-us/articles/360051132192">What is Mercury Dynamic Site Acceleration?</InfoText>
        </Box>
      </Box>
      <Paper>
        <Box padding="small">
          <Typography>
            We are working on {fsaJobTypes[displayJobType].verb} Mercury Dynamic Site Acceleration for your site.
            You can navigate away and come back to this page at any time.
            Additional information or steps will appear here, as necessary.
          </Typography>
        </Box>
        <Divider />
        {
          job.overallStatus === PENDING
            ? (
              <Box padding="large">
                <Loading margin={{ top: null }} />
              </Box>
            ) : (
              <Box padding="small">
                <Grid container>
                  <Grid item sm={3}>
                    <Box>
                      {
                        steps
                          .filter((s) => s.status !== NA)
                          .map((jobStep) => {
                            return (
                              <JobStep key={jobStep.id} jobStatus={jobStep} />
                            );
                          })
                      }
                    </Box>
                    <TeardownFsa {...{
                      app, state, onDisableFsa, mergeState,
                    }}
                    />
                  </Grid>
                  <Grid item sm={9}>
                    <Box padding={{ left: 'small' }}>
                      <Box direction="row" justify="space-between" gap="small">
                        <StepLabel
                          index={null}
                          label={currentStep.label}
                          description={currentStep.description}
                        />
                        {
                          fsaHalted && (
                            <Box margin={{ top: 'xsmall' }}>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  resumeMethod(
                                    true,
                                    {
                                      createLECertificate: Boolean(job.detail.createLECertificate),
                                    },
                                  );
                                }}
                                status={resumeApiStatus}
                                disabled={resumeApiStatus === 'loading'}
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                Resume {fsaJobTypes[displayJobType].label}
                              </Button>
                            </Box>
                          )
                        }
                      </Box>
                      <FSAContent
                        state={state}
                        job={job}
                        isSetupJob={jobTypes.isFsaSetupJob(job.type)}
                        displayJobType={displayJobType}
                        jobStep={currentStep}
                        jobId={job.id}
                        jobDetails={job.detail.stepDetail[currentStep.id]}
                        fetchStatusJob={fetchStatusJob}
                        validationRecords={validations}
                        onFetchAllValidations={onFetchAllValidations}
                        dnsJobs={dnsJobs}
                        resumeMethod={resumeMethod}
                        resumeApiStatus={resumeApiStatus}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )
        }
      </Paper>
    </Box>
  );
};

export default Partial;
