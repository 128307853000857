/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes, { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import {
  requestAdminLoginIfNeeded,
  loginLogout,
  forgotPasswordAdmin,
  forgotPasswordSetAdmin as forgotPasswordSet,
  forgotPasswordReset,
} from 'shared/modules/authentication/redux/actions';
import { getUser, getAccountPermissions } from 'shared/modules/permissions/user/actions';
import ApiErrorMessage from 'shared/modules/webapp/components/ApiErrorMessage';

import RightNav from 'admin/modules/layout/Navigation/RightNav';
import LeftNav from 'admin/modules/layout/Navigation/LeftNav';
import TopNav from 'admin/modules/layout/Navigation/TopNav';

import Account from 'shared/modules/account/pages/Account';
import PermissionsContext from 'shared/modules/permissions/context/PermissionsContext';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import AdminContext from 'shared/modules/permissions/context/AdminContext';

import Empty from 'shared/styleguide/atoms/Empty';
import Loading from 'shared/styleguide/atoms/Loading';
import Banner from 'shared/styleguide/molecules/Banner';
import Box from 'shared/styleguide/atoms/Box';

import UserContext from 'shared/modules/permissions/context/UserContext';
import { getDeployEnvironment } from 'shared/config';
import Routes from './Routes';
import {
  window, leftSidebar, mainView, horizontalContent,
} from './App.style.js';

class AccountWrapper extends Component {
  static propTypes = {
    getAccountPermissions: func.isRequired,
    location: object.isRequired,
    match: object.isRequired,
    permissions: object.isRequired,
    user: object.isRequired,
  };

  // this component literally just grabs permissions for an account
  componentDidMount() {
    const { match: { params: { accountID } } } = this.props;
    this.fetchData(accountID);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { accountID } } } = this.props;

    if (accountID !== prevProps.match.params.accountID) {
      this.fetchData(accountID);
    }
  }

  fetchData = (accountID) => {
    this.props.getAccountPermissions(accountID);
  };

  render() {
    if (this.props.permissions.isFetching) {
      return <Loading>Loading permissions...</Loading>;
    }

    if (this.props.user.user && !this.props.user.user.adminAccount && !this.props.permissions.canAccess.length) {
      return <Empty>This account is deactivated</Empty>;
    }

    return (
      <PermissionsContext.Provider value={this.props.permissions}>
        {
          !this.props.permissions.canAccess.length && (
            <Banner
              heading="Account Canceled"
              subheading={(
                <span>
                  This account has no permissions and may be slated for deletion
                </span>
              )}
              type="danger"
            />
          )
        }
        <Account {...this.props} />
      </PermissionsContext.Provider>
    );
  }
}

export class App extends Component { //eslint-disable-line
  static propTypes = {
    apiFailure: PropTypes.bool,
    classes: PropTypes.object,
    doGetUser: PropTypes.func.isRequired,
    doLogin: PropTypes.func.isRequired,
    doLogout: PropTypes.func.isRequired,
    forgotPasswordAdmin: PropTypes.func,
    forgotPasswordReset: PropTypes.func,
    forgotPasswordSet: PropTypes.func,
    location: object,
    login: PropTypes.shape({
      errMessage: PropTypes.string.isRequired,
      isFetching: PropTypes.bool.isRequired,
      isLoggedIn: PropTypes.bool.isRequired,
      requestErr: PropTypes.bool.isRequired,
    }),
    match: object,
    user: PropTypes.object,
  };

  componentDidMount() {
    if (this.props.login.isLoggedIn && !this.props.user.isFetching) {
      try {
        this.props.doGetUser();
      } catch (e) {
        this.handleLogout();
      }
    }
  }

  componentDidUpdate({ login: { isLoggedIn }, user: { user } }) {
    if (isLoggedIn !== this.props.login.isLoggedIn && this.props.login.isLoggedIn && !this.props.user.isFetching) {
      try {
        this.props.doGetUser();
      } catch (e) {
        this.handleLogout();
      }
    }
  }

  handleLogout = (redirectTo = null) => {
    this.props.doLogout(redirectTo);
  };

  render() {
    const { login, apiFailure, user: { user } } = this.props;

    const HIDE_RIGHT_SIDEBAR = (getDeployEnvironment() === 'woosaas' || localStorage.getItem('hideRightSidebar') === 'true');

    return (
      <div css={window}>
        <UserContext.Provider value={user}>
          <ErrorBoundary>
            <TopNav
              menuItems={[
                { name: 'Admin Settings', link: '/settings/profile' },
              ]}
              email={user && user.email}
              userName={user && user.name}
              handleLogout={() => this.handleLogout('/login')}
            />
          </ErrorBoundary>
          <div css={horizontalContent}>
            <div css={leftSidebar}>
              <ErrorBoundary>
                <Route path="/:anything*" component={LeftNav} />
              </ErrorBoundary>
            </div>
            <div css={mainView} data-testid="admin-main-panel">
              <AdminContext.Provider value={Boolean(true)}>
                <div>
                  <div style={{ opacity: login.isLoggedIn ? 1 : 0, position: 'relative' }}>
                    <div data-classid="main-view">
                      <ErrorBoundary>
                        {
                          this.props.user.user ? (
                            <Switch>
                              <Route path="/account/:accountID" render={(props) => (<AccountWrapper {...this.props} {...props} />)} />
                              <Route render={(props) => (<Routes {...props} {...this.props} />)} />
                            </Switch>
                          ) : (
                            <Loading />
                          )
                        }
                      </ErrorBoundary>
                    </div>
                  </div>
                  <ErrorBoundary>
                    {apiFailure ? <ApiErrorMessage /> : null}
                  </ErrorBoundary>
                </div>
              </AdminContext.Provider>
            </div>
            {
              !HIDE_RIGHT_SIDEBAR && (
                <Box
                  direction="column"
                  css={{
                    position: 'relative',
                  }}
                >
                  <ErrorBoundary>
                    <RightNav />
                  </ErrorBoundary>
                </Box>
              )
            }
          </div>
        </UserContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    apiFailure: state.api.fatalError,
    user: state.user,
    account: state.account,
    permissions: state.permissions,
  };
};
const mapDispatchToProps = {
  doLogin: requestAdminLoginIfNeeded,
  doLogout: loginLogout,
  doGetUser: getUser,
  forgotPasswordAdmin,
  forgotPasswordReset,
  forgotPasswordSet,
  getAccountPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
