import React, {
  useState, Fragment,
} from 'react';
import {
  object, number, func, bool, array,
} from 'prop-types';

import {
  Typography, Divider, Collapse,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { FeatureToggle, FLAGS } from 'shared/flags';
import { RECENT_HOURS_LIMIT } from '../../../constants';
import DiskChange from './DiskChange';
import CustomSetupMessage from './CustomSetupMessage';
import RecentModificationsMessage from './RecentModificationsMessage';

const ChangeDiskSize = ({
  accountId,
  planSize,
  planTotalSize,
  newSize,
  onChange,
  onChangeCommitted,
  isUpgradable,
  recentDiskJobs,
  showDiskChange,
  onShowDiskChange,
}) => {
  const [hasRemainingTime, setHasRemainingTime] = useState(false);
  const hasRecentJobs = recentDiskJobs?.length > 0 && hasRemainingTime;

  const setRemaining = (time) => {
    setHasRemainingTime(time > 0);
  };

  const showDiskChangeView = isUpgradable && !hasRecentJobs;

  const showCloseIcon = !isUpgradable || (isUpgradable && hasRecentJobs);

  return (
    <Fragment>
      <Collapse in={showDiskChange}>
        <Box padding={{ top: 'small', bottom: 'large' }}>
          <Divider />
        </Box>
        <Box direction="row" justify="space-between" align="flex-start">
          <Typography variant="h3">Change Plan Disk Size</Typography>
          {
            showCloseIcon
            && (
              <IconButton
                size="small"
                onClick={() => onShowDiskChange(false)}
              >
                <CloseIcon
                  fontSize="small"
                  color="default"
                />
              </IconButton>
            )
          }
        </Box>
        {
          !isUpgradable
          && (
            <CustomSetupMessage
              accountId={accountId}
              isUpgradable={isUpgradable}
            />
          )
        }
        {
          isUpgradable && recentDiskJobs?.length > 0
          && (
            <RecentModificationsMessage
              onSetHasRemainingTime={setRemaining}
              hasRemainingTime={hasRemainingTime}
              recentDiskJobs={recentDiskJobs}
            />
          )
        }
        {
          showDiskChangeView
          && (
            <Fragment>
              <Box
                margin={{ top: 'xsmall' }}
              >
                <Typography variant="h5" component="p" color="textSecondary" gutterBottom>
                  Disk upgrades are performed immediately with no downtime, and usually take 2-5 minutes to complete.
                </Typography>
                <Typography variant="h5" component="p" color="textSecondary" gutterBottom>
                  Changes may only be made <strong>once every {RECENT_HOURS_LIMIT} hours.</strong>
                </Typography>
              </Box>
              <DiskChange
                accountId={accountId}
                planSize={planSize}
                planTotalSize={planTotalSize}
                onChange={onChange}
                onChangeCommitted={onChangeCommitted}
                newSize={newSize}
              />
            </Fragment>
          )
        }
      </Collapse>
      {
        (showDiskChangeView || !showDiskChange)
        && (
          <Fragment>
            <Box row>
              <Button
                variant={
                  showDiskChange ? 'outlined' : 'contained'
                }
                disabled={FLAGS.BLUESNAP_PAYMENT_DISABLED}
                onClick={onShowDiskChange}
              >
                {
                  showDiskChange ? 'Don\'t Make Any Changes' : 'Change Disk Size'
                }
              </Button>
            </Box>
            <Typography css={{ marginTop: '1rem' }}>Automatic Disk Upgrades are temporarily disabled while we transition our billing system.</Typography>
          </Fragment>
        )
      }
    </Fragment>
  );
};

ChangeDiskSize.propTypes = {
  accountId: number,
  diskAddonCalculation: object,
  diskAddonPurchase: object,
  isUpgradable: bool,
  newSize: number,
  onChange: func,
  onChangeCommitted: func,
  onShowDiskChange: func,
  permissions: object,
  planSize: number,
  planTotalSize: number,
  recentDiskJobs: array,
  showDiskChange: bool,
  user: object,
};

export default ChangeDiskSize;
