export const API_REQUEST_START = 'API_REQUEST_START';
export const API_REQUEST_STOP = 'API_REQUEST_STOP';
export const API_FATAL = 'API_FATAL';
export const API_RESET = 'API_RESET';

export const baseState = {
  activeCalls: 0,
  fatalError: false,
  fatalResponse: null,
};

export function api(state = baseState, action) {
  switch (action.type) {
    case API_REQUEST_START:
      return { ...state, activeCalls: state.activeCalls + 1 };
    case API_REQUEST_STOP:
      return { ...state, activeCalls: state.activeCalls - 1 };
    case API_FATAL:
      return {
        ...state, ...action, fatalError: true, fatalResponse: action.response,
      };
    case API_RESET:
      return { ...baseState, activeCalls: state.activeCalls };
    default:
      return state;
  }
}
