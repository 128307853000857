export const jss = {
  subtitle: {
    display: 'inline-block',
    paddingLeft: 30,
  },
  title: {
    paddingRight: 30,
  },
  closeIcon: {
    color: 'inherit !important',
    position: 'absolute',
    right: 6,
    top: 6,
  },
};

export const inline = {
  dialog: {
    borderRadius: 8,
    overflowY: 'visible',
  },
};
