import React, { Component } from 'react';
import {
  object, number, string, oneOfType,
} from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Loading from 'shared/styleguide/atoms/Loading';

import DNSItem from './DNSItem';

export class DNSRecords extends Component {
  static propTypes = {
    accountId: oneOfType([number, string]),
    dns: object,
    filter: string,
    zone: object,
  };

  render() {
    const { dns } = this.props;

    const dnsRecords = dns.data?.recordSet || [];

    return (
      <>
        {
          ['pristine', 'loading'].includes(dns.status) && (
          <Box direction="row" align="center" justify="center" css={{ width: '100%', height: 30 }}>
            <Loading margin={{ top: 'none' }} />
          </Box>
          )
        }
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell key="type">Type</TableCell>
                <TableCell key="name" align="left">Name</TableCell>
                <TableCell key="value" align="left">Value</TableCell>
                <TableCell key="ttl" align="left">TTL</TableCell>
                <TableCell key="actions" align="left" width={60}>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                dnsRecords
                  .filter(((r) => r.Name.toLowerCase().match(this.props.filter)))
                  .sort((a, b) => {
                    if (a.readOnly && !b.readOnly) {
                      return 1;
                    } else if (!a.readOnly && b.readOnly) {
                      return -1;
                    } return 0;
                  })
                  .map((r, i) => <DNSItem record={r} key={`${r.Name}+${i}`} accountId={this.props.accountId} zone={this.props.zone} />)
                }
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default DNSRecords;
