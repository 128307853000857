import React from 'react';
import { object, number, array } from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { Typography } from '@material-ui/core';

import * as diskPropTypes from 'shared/modules/analytics/models/propTypes';
import Box from 'shared/styleguide/atoms/Box';
import graphColors from 'shared/styleguide/theme/graphColors';

import {
  ToolTipBox, LegendTypography, goToPressFormance,
} from './graphUtils';

import DiskLine from './DiskLine';

const toolTip = (e) => {
  return (
    <ToolTipBox
      direction="row"
      align="center"
      gap="xsmall"
    >
      <Box style={{ backgroundColor: e.point.serieColor, height: 16, width: 16 }} />
      <Box align="flex-end" style={{ marginTop: 3 }}>
        <Typography variant="body2"><strong>{e.point.serieId}</strong>: {e.point.data.yFormatted}GiB</Typography>
      </Box>
    </ToolTipBox>
  );
};

const DiskLineWrapper = ({
  accountId, mounts, diskUsage, history,
}) => {
  let maxValue = 0;
  const data = diskUsage.data.series
    .filter((path) => {
      return mounts.find((m) => m.location === path.tags.location);
    }).map((path, index) => {
      const values = path.values.map((value) => {
        maxValue = value[4] > maxValue ? value[4] : maxValue;
        return {
          'x': moment(value[0]).format('M/D'),
          'y': value[4],
        };
      });

      return {
        id: path.tags.location,
        step: index,
        data: values.filter((v) => v.y),
        label: mounts.find((m) => m.location === path.tags.location).petname,
      };
    });

  const legend = data.map((item, i) => {
    return (
      <Box direction="row" gap="xsmall" padding={{ top: 'xsmall' }} align="center" key={item.label + item.id}>
        <Box style={{ backgroundColor: graphColors[i], height: 14, width: 14 }} />
        <Box>
          <LegendTypography>{item.id}</LegendTypography>
        </Box>
      </Box>
    );
  });

  return (
    <Box style={{ width: '100%' }}>
      <Box style={{ height: 200, minWidth: 400 }}>
        <DiskLine
          data={data}
          link={goToPressFormance(history, accountId)}
          graphColors={graphColors}
          toolTip={toolTip}
          maxValue={maxValue}
        />
      </Box>
      <Box wrap="wrap" margin={{ top: 'medium' }} justify="center" direction="row" gap="medium">
        {legend}
      </Box>
    </Box>
  );
};

DiskLineWrapper.propTypes = {
  accountId: number,
  diskUsage: diskPropTypes.usage,
  history: object,
  mounts: array,
};

export default withRouter(DiskLineWrapper);
