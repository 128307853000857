import React from 'react';
import {
  object, string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';
import Layout from 'shared/styleguide/molecules/Layout';
import CollabRedirect from 'shared/styleguide/organisms/CollabRedirect';

import { routes } from './routes';
import AdBlockerBanner from '../../styleguide/organisms/Banners/AdBlocker';

const Analytics = ({ account, match, parentPath }) => {
  return (
    <Layout
      title="PressFormance"
    >
      <AdBlockerBanner />
      <Route
        exact
        path={`${match.path}`}
        render={() => (
          <Redirect to="analytics/cpu" />
        )}
      />
      <Route
        path={`${match.path}/:view`}
        render={(props) => (<Navigation {...props} routes={routes} parentPath={match.url} />)}
      />
      <Switch>
        {
          account.isCollaborator
            ? (
              <CollabRedirect account={account} text="manage analytics" />
            ) : (
              routes.concat().reverse().map((child) => {
                return (
                  <Route key={child.to} path={`${parentPath}/${child.to}`} component={child.component} />
                );
              })
            )
        }
      </Switch>
    </Layout>
  );
};

Analytics.propTypes = {
  account: object.isRequired,
  match: object.isRequired,
  parentPath: string,
};

export default connect(
  (state) => {
    return {
      account: state.account,
    };
  }, null,
)(Analytics);
