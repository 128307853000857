import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Paper } from '@material-ui/core';

import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { ErrorText } from 'shared/styleguide/typography';

import Box from 'shared/styleguide/atoms/Box';
import { consolidateErrors } from 'shared/utils/validation';

import { updateAccount } from 'shared/modules/account/redux/actions';
import { ProfileAvatarSection } from 'shared/modules/settings/routes/Profile/Profile';
import type { Account } from 'shared/modules/account/types';
import { User } from 'shared/types/User';

interface ProfileProps {
  account: Account;
  updateAccountSettings: (...args) => Promise<void>;
  user?: {
    user?: User;
    data: User;
  };
}

export const Profile: React.FC<ProfileProps> = (props) => {
  const { account, user } = props;
  const isAdmin = user?.user?.adminAccount;
  const userId = user.data.id;
  const disabled = !isAdmin && (userId !== account.id);

  const handleSubmitProfile = ({ email, ...values }, actions) => {
    const {
      updateAccountSettings,
    } = props;

    actions.setStatus('loading');

    updateAccountSettings(account.id, values)
      .then(() => {
        setTimeout(() => {
          actions.setStatus('initial');
        }, 5000);
        actions.setStatus('success');
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const fields = err.response.data.body;
          Object.keys(fields).forEach((key) => {
            actions.setFieldError(key, fields[key].messages.join(''));
          });
        } else {
          actions.setFieldError('general', consolidateErrors(err));
        }

        actions.setStatus('failure');
        actions.setSubmitting(false);
      });
  };

  return (
    <Paper>
      <Box direction="row" padding="large" gap="medium" wrap="wrap">
        <Box direction="column" style={{ flexBasis: 144 }}>
          <ProfileAvatarSection name={account && account.username} email={account.email} />
        </Box>
        <Box direction="column" flex={1}>
          <Formik
            initialStatus="initial"
            initialValues={{
              username: account.username,
              name: account.name,
              email: account.email,
            }}
            onSubmit={handleSubmitProfile}
          >
            {({
              handleSubmit, handleChange, values, errors, status,
            }) => (
              <Box as="form" onSubmit={handleSubmit}>
                <Box direction="row">
                  <WideTextField
                    name="name"
                    placeholder="Name"
                    autoComplete="off"
                    margin="normal"
                    onChange={handleChange}
                    // @ts-ignore
                    errorText={errors.hasErrors && errors.name}
                    value={values.name}
                    disabled
                  />
                </Box>
                <Box direction="row">
                  <WideTextField
                    name="username"
                    placeholder="Username"
                    autoComplete="off"
                    margin="normal"
                    onChange={handleChange}
                    // @ts-ignore
                    errorText={errors.hasErrors && errors.username}
                    value={values.username}
                    disabled
                  />
                </Box>
                <Box direction="row">
                  <WideTextField
                    name="email"
                    placeholder="Email"
                    type="email"
                    autoComplete="off"
                    margin="normal"
                    onChange={handleChange}
                    // @ts-ignore
                    errorText={errors.hasErrors && errors.email}
                    value={values.email}
                    disabled={disabled}
                  />
                </Box>

                {
                  errors.general
                  && <ErrorText>{errors.general}</ErrorText>
                }
                <Box align="flex-end" padding={{ top: 'small' }}>
                  <Button
                    status={status || 'pristine'}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    Update Profile
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
};

export default connect(
  (state: { user }) => ({
    account: state.user.user,
    user: state.user,
  }),
  {
    updateAccountSettings: updateAccount,
  },
)(Profile);
