import React, { ReactNode, PropsWithChildren } from 'react';
import { getDeployEnvironment } from './config';
import ErrorBoundary from './modules/webapp/components/ErrorBoundary';

/* This is the master control of whether features are available or not */

const released = true;
export const development = process.env.NODE_ENV === 'development'; // not available on staging or production
const releaseStage = getDeployEnvironment();

// features can be grouped together by domain, so they are all released at the same time

const FSA_MANUAL = development; // make this true when ready for production...

export const FLAGS = {
  IN_PROGRESS: development,
  SHOW: true,
  HIDE: false,
  FSA_MANUAL,
  BLUESNAP_PAYMENT_DISABLED: true,
  WOOSAAS: releaseStage === 'woosaas',
} as const;

interface Props extends PropsWithChildren<any> {
  stages?: string[] | string;
  flags?: boolean[] | boolean;
  fallback?: ReactNode;
}

export const FeatureToggle: any = ({
  stages = '', flags = false, children, fallback,
}: Props) => {
  let wrappedChildren;
  if (children instanceof Array) {
    wrappedChildren = children;
  } else {
    wrappedChildren = (
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    );
  }

  // if current build is in an allowed stage
  if (stages.length > 0 && (stages === releaseStage || stages.includes(releaseStage))) {
    return wrappedChildren;
  }

  if (Array.isArray(flags)) {
    if (flags.every((flag) => flag)) {
      return wrappedChildren;
    }
  } else if (flags && typeof flags === 'boolean') {
    return wrappedChildren;
  }

  if (fallback) {
    return fallback;
  }

  return null;
};
