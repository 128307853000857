import React, { useState, forwardRef, Fragment } from 'react';
import { string } from 'prop-types';

import {
  Slide, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';

import YouTube from 'react-youtube';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import InfoVideo from '../../Icons/SVGs/InfoVideo';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const ShowVideo = ({ videoId, title, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Tooltip title="Watch video" placement="top">
        <IconButton
          {...props}
          onClick={handleClickOpen}
          size="small"
        >
          <InfoVideo fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>

          <YouTube
            videoId={videoId}
            opts={{
              playerVars: {
                autoplay: 0,
                modestbranding: 1,
                rel: 0,
              },
            }}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Close
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Go There
          </Button> */}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

ShowVideo.propTypes = {
  title: string,
  videoId: string,
};

export default ShowVideo;
