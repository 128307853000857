export const APPS_SEARCH_REQUEST = 'APPS_SEARCH_REQUEST';
export const APPS_SEARCH_SUCCESS = 'APPS_SEARCH_SUCCESS';
export const APPS_SEARCH_FAILURE = 'APPS_SEARCH_FAILURE';
export const APPS_SEARCH_RESET = 'APPS_SEARCH_RESET';
export const APPS_SEARCH_SET_FILTER = 'APPS_SEARCH_SET_FILTER';

export const ACCOUNTS_SEARCH_REQUEST = 'ACCOUNTS_SEARCH_REQUEST';
export const ACCOUNTS_SEARCH_SUCCESS = 'ACCOUNTS_SEARCH_SUCCESS';
export const ACCOUNTS_SEARCH_FAILURE = 'ACCOUNTS_SEARCH_FAILURE';
export const ACCOUNTS_SEARCH_RESET = 'ACCOUNTS_SEARCH_RESET';
export const ACCOUNTS_SEARCH_SET_FILTER = 'ACCOUNTS_SEARCH_SET_FILTER';
