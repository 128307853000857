import React from 'react';
import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import DnsValidations from '../DnsValidations';

interface Props {
  jobDetails: Record<string, any>;
  dnsJobs: Record<string, any>;
  validationRecords: Record<string, any>;
  fetchStatusJob: (value: string) => void;
  validationType: string;
  expanded: boolean;
}

const StepRunning = ({
  jobDetails, dnsJobs, validationRecords, fetchStatusJob, validationType, expanded,
}: Props) => {
  if (validationRecords.length === 0) {
    return null;
  }

  return (
    <Box>
      <Box margin={{ bottom: 'small' }}>
        <Typography component="span" variant="body1" color="textPrimary">We will continue to atomatically re-check the following DNS records until they are validated or the number of maximum retries has been reached. You may update these DNS records at any time during this process.</Typography>
      </Box>
      <Typography component="span" variant="body1" color="textSecondary">Invalid DNS records:</Typography>
      <Typography component="span" variant="body1" color="textPrimary">{jobDetails.messages.slice(-1)}</Typography>
      {
        // @ts-ignore
        <DnsValidations
          jobDetails={jobDetails}
          dnsJobs={dnsJobs}
          validationRecords={validationRecords}
          fetchStatusJob={fetchStatusJob}
          expanded={expanded}
          validationType={validationType}
        />
      }
    </Box>
  );
};

export default StepRunning;
