import makeApiAction from 'shared/utils/redux/apiActions';
import { toHex } from 'shared/utils';
import * as REDIRECTS from './constants';

export const testUrl = (appId, body) => makeApiAction(
  ({ api }) => api().post(`/serverconfig/ares/testing/${appId}/url-tester`, body),
  'REDIRECTS_TESTER_REQUEST',
  'REDIRECTS_TESTER_SUCCESS',
  'REDIRECTS_TESTER_FAILURE',
  { appId },
  true,
);

export const createRedirect = (appId, body) => makeApiAction(
  ({ api }) => api().post(`/serverconfig/ares/redirects/${appId}`, body),
  REDIRECTS.CREATE_REDIRECT_REQUEST,
  REDIRECTS.CREATE_REDIRECT_SUCCESS,
  REDIRECTS.CREATE_REDIRECT_FAILURE,
  { appId, action: 'create' },
  true,
);

export const importRedirects = (appId, body) => makeApiAction(
  ({ api }) => api().post(`/serverconfig/ares/redirects/${appId}/batch`, body),
  REDIRECTS.IMPORT_REDIRECTS_REQUEST,
  REDIRECTS.IMPORT_REDIRECTS_SUCCESS,
  REDIRECTS.IMPORT_REDIRECTS_FAILURE,
  { appId, action: 'import' },
  true,
);

export const importUpdatesRedirects = (appId) => {
  return { type: REDIRECTS.IMPORT_UPDATES_REDIRECTS, appId, data: {} };
};

export const clearAddedInUI = () => {
  return { type: REDIRECTS.CLEAR_ADDED_IN_UI, data: {} };
};

export const exportRedirects = (appId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/ares/redirects/${appId}/csv`),
  REDIRECTS.EXPORT_REDIRECTS_REQUEST,
  REDIRECTS.EXPORT_REDIRECTS_SUCCESS,
  REDIRECTS.EXPORT_REDIRECTS_FAILURE,
  { appId, action: 'export' },
  true,
);

export const fetchRedirects = (appId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/ares/redirects/${appId}`),
  REDIRECTS.FETCH_REDIRECTS_REQUEST,
  REDIRECTS.FETCH_REDIRECTS_SUCCESS,
  REDIRECTS.FETCH_REDIRECTS_FAILURE,
  { appId, action: 'fetch' },
  true,
);

export const updateRedirect = (appId, matchIdentifier, body) => makeApiAction(
  ({ api }) => api().patch(`/serverconfig/ares/redirects/${appId}/${toHex(matchIdentifier)}`, body),
  REDIRECTS.UPDATE_REDIRECT_REQUEST,
  REDIRECTS.UPDATE_REDIRECT_SUCCESS,
  REDIRECTS.UPDATE_REDIRECT_FAILURE,
  { appId, matchIdentifier, action: 'update' },
  true,
);

export const deleteRedirect = (appId, matchIdentifier) => makeApiAction(
  ({ api }) => api().delete(`/serverconfig/ares/redirects/${appId}/${toHex(matchIdentifier)}`),
  REDIRECTS.DELETE_REDIRECT_REQUEST,
  REDIRECTS.DELETE_REDIRECT_SUCCESS,
  REDIRECTS.DELETE_REDIRECT_FAILURE,
  {
    appId, matchIdentifier, action: 'delete', successDelay: 3000,
  },
  true,
);

export const removeRedirectFromStore = (ruleOrder) => (dispatch) => {
  dispatch({
    type: REDIRECTS.REMOVE_REDIRECT_FROM_STORE, params: { ruleOrder },
  });
};
