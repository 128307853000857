import React, { useState, useEffect, Fragment } from 'react';
import {
  number, func, object,
} from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';
import moment from 'moment-timezone';

import { Typography } from '@material-ui/core';

import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';

import useDebouncedCallback from 'shared/utils/hooks/useDebouncedCallback';

import { getInitialDates } from '../../components/analyticsUtils';
import { DISK_EARLIEST_DATE, DEFAULT_DAY_SPREAD } from '../../constants';

import LoadData from '../../components/LoadData';
import AnalyticsDatePicker from '../../components/AnalyticsDatePicker';

import { getDimensions, getCPUData } from '../../redux/actions/cpu';

const type = 'cpu';

const LoadCPU = ({
  accountId, getDimensions, getData, cpuData, fromDate, toDate, ...props
}) => {
  const debouncedGetData = useDebouncedCallback((accountId, options) => {
    getData(accountId, options);
  }, 2000, {});

  const [dates, setDates] = useState({
    fromDate: fromDate ?? null,
    toDate: toDate ?? null,
  });

  useEffect(() => {
    const [startValue, endValue] = getInitialDates(DISK_EARLIEST_DATE, DEFAULT_DAY_SPREAD[type]);

    const location = qs.parse(props.location?.search, { ignoreQueryPrefix: true });
    const query = location?.[type];

    setDates({
      fromDate: query?.fromDate ? moment(query.fromDate) : startValue,
      toDate: query?.toDate ? moment(query.toDate) : endValue,
    });
  }, []); //eslint-disable-line

  const handleDatesChange = (calendarDates) => {
    setDates({
      fromDate: calendarDates.startDate ?? dates.fromDate,
      toDate: calendarDates.endDate ?? dates.toDate,
    });
  };

  if (!dates.fromDate || !dates.toDate) {
    return <Box direction="column" align="center"><Loading /></Box>;
  }

  return (
    <Fragment>
      <Box direction="row" align="center" margin={{ top: 'medium', bottom: 'medium' }} justify="space-between">
        <AnalyticsDatePicker
          start={dates.fromDate}
          end={dates.toDate}
          onDatesChange={handleDatesChange}
          type={type}
        />
        <Typography component="div" variant="h6" color="textSecondary">PressFormance data is updated every 6 hours.</Typography>
      </Box>
      <Box margin={{ top: 'large' }}>
        <LoadData
          {...props}
          accountId={accountId}
          getDimensions={getDimensions}
          getData={debouncedGetData}
          path={type}
          type={type}
          dimensions={cpuData.dimensions}
          chartData={cpuData}
          defaultLeftAxis="cpu_max"
          defaultRightAxis="balance_min"
          startDate={dates.fromDate}
          endDate={dates.toDate}
        />
      </Box>
    </Fragment>
  );
};

LoadCPU.propTypes = {
  accountId: number,
  cpuData: object.isRequired,
  fromDate: object,
  getData: func.isRequired,
  getDimensions: func.isRequired,
  location: object.isRequired,
  toDate: object,
};

export default connect(
  (state) => ({
    accountId: state.account.id,
    cpuData: state.analytics.cpu,
  }),
  {
    getDimensions,
    getData: getCPUData,
  },
)(LoadCPU);
