export const SUPPORT_ARTICLES_REQUEST = 'SUPPORT_ARTICLES_REQUEST';
export const SUPPORT_ARTICLES_SUCCESS = 'SUPPORT_ARTICLES_SUCCESS';
export const SUPPORT_ARTICLES_FAILURE = 'SUPPORT_ARTICLES_FAILURE';

export const SUPPORT_CREATE_TICKET_REQUEST = 'SUPPORT_CREATE_TICKET_REQUEST';
export const SUPPORT_CREATE_TICKET_SUCCESS = 'SUPPORT_CREATE_TICKET_SUCCESS';
export const SUPPORT_CREATE_TICKET_FAILURE = 'SUPPORT_CREATE_TICKET_FAILURE';

export const SUPPORT_GET_TICKETS_REQUEST = 'SUPPORT_GET_TICKETS_REQUEST';
export const SUPPORT_GET_TICKETS_SUCCESS = 'SUPPORT_GET_TICKETS_SUCCESS';
export const SUPPORT_GET_TICKETS_FAILURE = 'SUPPORT_GET_TICKETS_FAILURE';

export const SUPPORT_UPLOAD_ATTACHMENTS_REQUEST = 'SUPPORT_UPLOAD_ATTACHMENTS_REQUEST';
export const SUPPORT_UPLOAD_ATTACHMENTS_SUCCESS = 'SUPPORT_UPLOAD_ATTACHMENTS_SUCCESS';
export const SUPPORT_UPLOAD_ATTACHMENTS_FAILURE = 'SUPPORT_UPLOAD_ATTACHMENTS_FAILURE';

export const SUPPORT_ATTACHMENTS_RESET = 'SUPPORT_ATTACHMENTS_RESET';
export const SUPPORT_ATTACHMENTS_RESET_ERRORS = 'SUPPORT_ATTACHMENTS_RESET_ERRORS';

export const SUPPORT_DELETE_ATTACHMENT = 'SUPPORT_DELETE_ATTACHMENT';
export const SUPPORT_DELETE_ATTACHMENT_REQUEST = 'SUPPORT_DELETE_ATTACHMENT_REQUEST';
export const SUPPORT_DELETE_ATTACHMENT_SUCCESS = 'SUPPORT_DELETE_ATTACHMENT_SUCCESS';
export const SUPPORT_DELETE_ATTACHMENT_FAILURE = 'SUPPORT_DELETE_ATTACHMENT_FAILURE';

export const SUPPORT_GET_TICKET_REQUEST = 'SUPPORT_GET_TICKET_REQUEST';
export const SUPPORT_GET_TICKET_SUCCESS = 'SUPPORT_GET_TICKET_SUCCESS';
export const SUPPORT_GET_TICKET_FAILURE = 'SUPPORT_GET_TICKET_FAILURE';

export const SUPPORT_UPDATE_TICKET_REQUEST = 'SUPPORT_UPDATE_TICKET_REQUEST';
export const SUPPORT_UPDATE_TICKET_SUCCESS = 'SUPPORT_UPDATE_TICKET_SUCCESS';
export const SUPPORT_UPDATE_TICKET_FAILURE = 'SUPPORT_UPDATE_TICKET_FAILURE';

export const SUPPORT_TICKET_RESET = 'SUPPORT_TICKET_RESET';
export const SUPPORT_TICKETS_RESET = 'SUPPORT_TICKETS_RESET';
export const SUPPORT_ARTICLES_RESET = 'SUPPORT_ARTICLES_RESET';

export const SUPPORT_GET_BANNED_PLUGINS_REQUEST = 'SUPPORT_GET_BANNED_PLUGINS_REQUEST';
export const SUPPORT_GET_BANNED_PLUGINS_SUCCESS = 'SUPPORT_GET_BANNED_PLUGINS_SUCCESS';
export const SUPPORT_GET_BANNED_PLUGINS_FAILURE = 'SUPPORT_GET_BANNED_PLUGINS_FAILURE';

export const SUPPORT_ADD_BANNED_PLUGINS_REQUEST = 'SUPPORT_ADD_BANNED_PLUGINS_REQUEST';
export const SUPPORT_ADD_BANNED_PLUGINS_SUCCESS = 'SUPPORT_ADD_BANNED_PLUGINS_SUCCESS';
export const SUPPORT_ADD_BANNED_PLUGINS_FAILURE = 'SUPPORT_ADD_BANNED_PLUGINS_FAILURE';

export const localStorageKeys = {
  recent: 'support/recent_tickets',
};

export const mapStatusToColor = {
  open: 'green',
  pending: 'orange',
  closed: null,
  solved: null,
};
