import makeApiAction from 'shared/utils/redux/apiActions';

export const CERTS_REQUEST = 'CERTS_REQUEST';
export const CERTS_SUCCESS = 'CERTS_SUCCESS';
export const CERTS_FAILURE = 'CERTS_FAILURE';
export const CERTS_RESET = 'CERTS_RESET';

export const fetchCertsForAccount = (accountId) => makeApiAction(
  ({ api }) => api().get(`/ssl/certs?accountId=${accountId}`),
  CERTS_REQUEST,
  CERTS_SUCCESS,
  CERTS_FAILURE,
  { accountId },
);

export const resetCertsForAccount = () => ({
  type: CERTS_RESET,
});
