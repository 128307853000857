import apiReducer, {
  baseCollectionState,
} from 'shared/utils/redux/apiReducer';
import * as APP from '../constants';

export const appBackups = apiReducer(
  APP.APP_GET_BACKUPS_REQUEST,
  APP.APP_GET_BACKUPS_SUCCESS,
  APP.APP_GET_BACKUPS_FAILURE,
  APP.APP_BACKUPS_RESET,
  baseCollectionState,
);

export const appBackup = apiReducer(
  APP.APP_GET_BACKUP_REQUEST,
  APP.APP_GET_BACKUP_SUCCESS,
  APP.APP_GET_BACKUP_FAILURE,
  APP.APP_BACKUP_RESET,
);
