import qs from 'qs';
import { getHttp } from 'shared/http';
import makeApiAction from 'shared/utils/redux/apiActions';

export const CDN_REQUEST = 'CDN_REQUEST';
export const CDN_SUCCESS = 'CDN_SUCCESS';
export const CDN_FAILURE = 'CDN_FAILURE';

export const CDN_APP_REQUEST = 'CDN_APP_REQUEST';
export const CDN_APP_SUCCESS = 'CDN_APP_SUCCESS';
export const CDN_APP_FAILURE = 'CDN_APP_FAILURE';

export const CDN_CHECK_REQUEST = 'CDN_CHECK_REQUEST';
export const CDN_CHECK_SUCCESS = 'CDN_CHECK_SUCCESS';
export const CDN_CHECK_FAILURE = 'CDN_CHECK_FAILURE';
export const CDN_CHECK_RESET = 'CDN_CHECK_RESET';

export const CDN_CNAME_REMOVE_REQUEST = 'CDN_CNAME_REMOVE_REQUEST';
export const CDN_CNAME_REMOVE_SUCCESS = 'CDN_CNAME_REMOVE_SUCCESS';
export const CDN_CNAME_REMOVE_FAILURE = 'CDN_CNAME_REMOVE_FAILURE';

export const CDN_CNAME_ADD_REQUEST = 'CDN_CNAME_ADD_REQUEST';
export const CDN_CNAME_ADD_SUCCESS = 'CDN_CNAME_ADD_SUCCESS';
export const CDN_CNAME_ADD_FAILURE = 'CDN_CNAME_ADD_FAILURE';

export const CDN_PURGE_CACHE_REQUEST = 'CDN_PURGE_CACHE_REQUEST';
export const CDN_PURGE_CACHE_SUCCESS = 'CDN_PURGE_CACHE_SUCCESS';
export const CDN_PURGE_CACHE_FAILURE = 'CDN_PURGE_CACHE_FAILURE';

export const CDN_FSA_STATUS_REQUEST = 'CDN_FSA_STATUS_REQUEST';
export const CDN_FSA_STATUS_SUCCESS = 'CDN_FSA_STATUS_SUCCESS';
export const CDN_FSA_STATUS_FAILURE = 'CDN_FSA_STATUS_FAILURE';

export const CDN_APP_RESET = 'CDN_APP_RESET';

export const requestCdn = (accountId) => makeApiAction(
  () => getHttp().get(`/cdn?accountId=${accountId}`),
  CDN_REQUEST,
  CDN_SUCCESS,
  CDN_FAILURE,
  { accountId },
);

export const requestCdnForApp = (appId, options = {}) => makeApiAction(
  ({ api }) => {
    let query = '';
    if (options && Object.keys(options).length) {
      query = qs.stringify(options, { addQueryPrefix: true });
    }
    return api().get(`/cdn/apps/${appId}${query}`);
  },
  CDN_APP_REQUEST,
  CDN_APP_SUCCESS,
  CDN_APP_FAILURE,
  {
    appId,
  },
);

export const purgeCDN = ({ appId }) => makeApiAction(
  () => getHttp().post(`/cdn/apps/${appId}/purge`, {
  }),
  CDN_PURGE_CACHE_REQUEST,
  CDN_PURGE_CACHE_SUCCESS,
  CDN_PURGE_CACHE_FAILURE,
);

export const removeCNAME = ({ appId, appUrl }) => makeApiAction(
  () => getHttp().post(`/cdn/apps/${appId}/cnames`, {
    removeCnames: [appUrl],
    // removeCs: [appUrl],
  }),
  CDN_CNAME_REMOVE_REQUEST,
  CDN_CNAME_REMOVE_SUCCESS,
  CDN_CNAME_REMOVE_FAILURE,
);

export const addCNAME = ({ appId, appUrl }) => makeApiAction(
  () => getHttp().post(`/cdn/apps/${appId}/cnames`, {
    addCnames: [appUrl],
    // addUrls: [appUrl],
  }),
  CDN_CNAME_ADD_REQUEST,
  CDN_CNAME_ADD_SUCCESS,
  CDN_CNAME_ADD_FAILURE,
  null,
  true,
);

export const cdnCheckFetch = (cdnId) => makeApiAction(
  () => getHttp().get(`/cdn/${cdnId}/check`),
  CDN_CHECK_REQUEST,
  CDN_CHECK_SUCCESS,
  CDN_CHECK_FAILURE,
  {
    cdnId,
  },
);

export const cdnCheckReset = () => ({
  type: CDN_CHECK_RESET,
});

export const getCdnFsaStatus = (appId) => makeApiAction(
  ({ api }) => api().get(`/fsa/apps/${appId}/status`),
  CDN_FSA_STATUS_REQUEST,
  CDN_FSA_STATUS_SUCCESS,
  CDN_FSA_STATUS_FAILURE,
  {
    appId,
  },
);
