import React from 'react';
import { object } from 'prop-types';
import moment from 'moment';

import {
  Typography, Link,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import { middleTruncate } from '../utils';

export const LogMeta = ({ logs }) => {
  const {
    data, totalTime, integrationId, warnings,
  } = logs;

  const logItem = data || {};

  return (
    <Box padding={{ bottom: 'small' }}>
      <Box direction="row" justify="space-between" wrap="wrap" gap="small">
        <Typography gutterBottom color="textPrimary" variant="body1">
          <Typography color="textSecondary" component="span">Run time: </Typography>
          {
            totalTime
              ? moment.utc(totalTime).format('m:ss')
              : logs.displayStatus
          }
        </Typography>
        {
          integrationId && (
            <Typography gutterBottom color="textPrimary" variant="body1">
              <Typography color="textSecondary" component="span">Integration ID: </Typography>
              {integrationId}
            </Typography>
          )
        }
        {
          logItem.name && (
            <Typography gutterBottom color="textPrimary" variant="body1">
              <Typography color="textSecondary" component="span">Pushed by: </Typography>
              {logItem.name}
            </Typography>
          )
        }
        {
          logItem.headCommitHash && (
            <Typography gutterBottom color="textPrimary" variant="body1">
              <Typography color="textSecondary" component="span">Commit Hash: </Typography>
              {logItem.headCommitHash}
            </Typography>
          )
        }
      </Box>
      {
        (logItem.compare || logItem.repoUrl)
        && (
          <Box wrap="wrap" style={{ wordBreak: 'break-all' }}>
            {
              logItem.compare && (
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Compare URL:&nbsp;
                  <Link href={logItem.compare} title={logItem.compare} target="_blank">{middleTruncate(logItem.compare)}</Link>
                </Typography>
              )
            }
            {
              logItem.repoUrl && (
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  Repo URL:&nbsp;
                  <Link href={logItem.repoUrl} target="_blank">{logItem.repoUrl}</Link>
                </Typography>
              )
            }
          </Box>
        )
      }
      {
        warnings?.length && (
          <Box direction="row" justify="space-between" wrap="wrap" gap="small">
            <Typography gutterBottom color="textPrimary" variant="body1">
              <Typography color="textSecondary" component="span">Warnings: </Typography>
              {warnings.join('; ')}
            </Typography>
          </Box>
        )
      }
    </Box>
  );
};

LogMeta.propTypes = {
  logs: object,
};

export default LogMeta;
