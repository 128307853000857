import React from 'react';
import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import * as STATUS from 'shared/modules/status/redux/constants';
import { dateOptions } from 'shared/utils';

interface AppJobStatusProps {
  job: {
    started?: string;
    overallStatus: string;
    steps: {id: string; status: string; label: string}[];
    timestamp: number;
  };
}

const AppJobStatus: React.FC<AppJobStatusProps> = ({ job }) => {
  const started = job?.timestamp;
  const startDisplay = started ? new Date(started).toLocaleString([], dateOptions) : STATUS.PENDING;

  return (
    <div>
      <Box gap="xsmall" row align="center" margin={{ top: 'xsmall' }}>
        <Typography variant="body1">
          <GhostTag color={STATUS.tagMap[job?.overallStatus]}>
            {
              // stalled represents an error during app creation that Ops quickly fixes
              [STATUS.RUNNING, STATUS.STALLED].includes(job?.overallStatus) ? (
                'CREATING'
              ) : (
                `CREATION ${job?.overallStatus}`
              )
            }
          </GhostTag>
        </Typography>
      </Box>
      <Box gap="xsmall" row align="center" margin={{ top: 'small' }}>
        <Typography variant="body2">Time started: {startDisplay}</Typography>
      </Box>
      <Box>
        {
          job && job.steps.map((jobStatus) => {
            return (
              <Box key={jobStatus.id} direction="row" margin="xsmall" gap="medium" align="center">
                <Box>
                  {STATUS.iconMap[jobStatus.status]}
                </Box>
                <Box>
                  <Typography variant="subtitle2">{jobStatus.label}</Typography>
                </Box>
              </Box>
            );
          })
        }
      </Box>
    </div>
  );
};

export default AppJobStatus;
