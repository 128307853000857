import colors from 'shared/styleguide/theme';

export const jssStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'inherit',
  },
  sectionContainers: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    alignItems: 'center',
  },
  headerBlock: {
    backgroundColor: colors.light10,
  },
  headerText: {
    fontSize: 17,
    lineHeight: 1.54,
    textAlign: 'center',
    color: colors.sea20,
  },
  smallText: {
    color: colors.sea20,
    textAlign: 'center',
    marginTop: '0.5rem',
  },
  textareaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'inherit',
  },
  clickableText: {
    'border': 'none',
    'backgroundColor': 'transparent',
    'fontSize': 13,
    'color': colors.navy20,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  validatingSpinnerOrSuccessText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.green20,
  },
  errorText: {
    color: colors.red10,
  },
};

export const inlineStyles = {
  circleCheckOutlineIcon: {
    style: {
      color: colors.green20,
      height: '50px',
      width: '50px',
    },
  },
};
