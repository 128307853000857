import React, { Fragment, useEffect } from 'react';
import {
  object, func, shape,
} from 'prop-types';

import { connect } from 'react-redux';
import { Paper, Typography } from '@material-ui/core';

// styleguide
import Box from 'shared/styleguide/atoms/Box';
import { getChargebeeAccount } from 'shared/modules/billing/redux/actions';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';

import { PaymentLink } from 'shared/modules/billing/Link';
import { accountPropTypes } from 'shared/modules/account/models/model';
import InfoText from 'shared/styleguide/molecules/InfoText';

export const InvoiceAddress = ({
  account, getChargebeeAccount, customer,
}) => {
  useEffect(() => {
    if (account.hasSubscription && account.billingCustomerId) {
      if (customer.status === 'pristine' || (customer.status === 'success' && customer.params.customerId !== account.billingCustomerId)) {
        getChargebeeAccount(account.billingCustomerId);
      }
    }
  }, [customer.status, customer.params, getChargebeeAccount, account]);

  if (!account.hasSubscription) {
    return null;
  }

  if (['loading', 'pristine'].includes(customer.status) || !customer.status) {
    return (<LoadingDots />);
  }

  return (
    <Paper>
      <Box padding="small">
        <Typography variant="h3" gutterBottom>Billing Address</Typography>
        <InfoText>
          This is the name and address that will appear on invoices.
        </InfoText>
        <Box margin={{ bottom: 'xsmall' }}>
          {
            [
              customer.data.address1,
              customer.data.city,
              customer.data.zip,
            ].some(Boolean) ? (
              <Fragment>
                <Typography>{customer.data.address1}</Typography>
                <Typography>{customer.data.address2}</Typography>
                <Typography>{customer.data.city}, {customer.data.state} {customer.data.zip}</Typography>
              </Fragment>
              ) : (
                <Typography variant="body2" color="textSecondary">No billing address on file.</Typography>
              )
          }
        </Box>

        <Box direction="row" justify="flex-end" padding={{ top: 'xsmall' }}>
          <PaymentLink>
            Edit billing address
          </PaymentLink>
        </Box>
      </Box>
    </Paper>
  );
};

InvoiceAddress.propTypes = {
  account: shape(accountPropTypes),
  customer: object,
  getChargebeeAccount: func,
};

export default connect(
  (state) => ({
    account: state.account,
    customer: state.billing.customer,
  }),
  {
    getChargebeeAccount,
  },
)(InvoiceAddress);
