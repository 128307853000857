import makeApiAction from 'shared/utils/redux/apiActions';

import * as TFA from 'shared/modules/authentication/redux/mfa/constants';

export const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'ACCOUNT_FAILURE';
export const ACCOUNT_UPDATE_REQUEST = 'ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';

export const fetchAdminAccount = (accountID) => makeApiAction(
  ({ api }) => api().get(`/accounts/admin/${accountID}`),
  ACCOUNT_REQUEST,
  ACCOUNT_SUCCESS,
  ACCOUNT_FAILURE,
);

export const updateAdminAccount = (accountID, update, reduxFormId) => makeApiAction(
  ({ api }) => api().patch(`/accounts/admins/${accountID}`, update),
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAILURE,
  { accountID, ...update, reduxFormId },
  true,
);

export const twoFactorSetupRequestForAdmin = (adminId, phone) => makeApiAction(
  ({ api }) => api().get(`/accounts/admins/${adminId}/2fa?phone=${phone}`),
  TFA.TWO_FACTOR_SETUP_REQUEST_REQUEST,
  TFA.TWO_FACTOR_SETUP_REQUEST_SUCCESS,
  TFA.TWO_FACTOR_SETUP_REQUEST_FAILURE,
  {},
  true,
);

export const twoFactorVerificationRequestForAdmin = (adminId, update) => makeApiAction(
  ({ api }) => api().post(`/accounts/admins/${adminId}/2fa`, update),
  TFA.TWO_FACTOR_VERIFY_REQUEST_REQUEST,
  TFA.TWO_FACTOR_VERIFY_REQUEST_SUCCESS,
  TFA.TWO_FACTOR_VERIFY_REQUEST_FAILURE,
  {},
  true,
);
