/* eslint-disable max-len */
import React from 'react';
import SVGIcon from '@material-ui/core/SvgIcon';

const Atomic = (props) => (
  <SVGIcon
    {...props}
  >
    <path style={{ fill: 'none' }} d="M0,0h24v24H0V0z" />
    <g>
      <path
        key={1}
        d="M20.5,17.6c-0.1,0-0.2,0-0.3,0l-2.2-3.7L17,14.6l2.2,3.7c-0.1,0.2-0.3,0.4-0.3,0.6l-4.2,0l0,1.3l4.3,0 c0.3,0.7,0.9,1.2,1.7,1.2c1,0,1.8-0.8,1.8-1.8C22.4,18.4,21.5,17.6,20.5,17.6z"
      />
      <path
        key={2}
        d="M4.9,18.3L7,14.5l-1.1-0.6l-2.1,3.7c-0.1,0-0.2,0-0.3,0c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c0.8,0,1.4-0.5,1.7-1.2 l4.4,0l0-1.3l-4.4,0C5.1,18.6,5,18.4,4.9,18.3z"
      />
      <circle key={3} cx="12" cy="14.5" r="3.7" />
      <path
        key={4}
        d="M9.6,10.2l2.2-3.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0l2.2,3.7l1.1-0.6l-2.2-3.7c0.3-0.3,0.4-0.7,0.4-1.2 c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8c0,0.4,0.2,0.9,0.4,1.2L8.5,9.6L9.6,10.2z"
      />
    </g>
  </SVGIcon>
);
Atomic.propTypes = SVGIcon.propTypes;
Atomic.defaultProps = {
  viewBox: '0 0 24 24',
};

export default Atomic;
