import React from 'react';
import { object } from 'prop-types';
import moment from 'moment';

import {
  TableCell,
  TableRow,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import Empty from 'shared/styleguide/atoms/Empty';

import {
  hashCode, dateOptions, formatDate, formatMiB,
} from 'shared/utils';
import SortableTable from 'shared/styleguide/molecules/Table/SortableTable';

const Row = ({
  path, used, ...rest//eslint-disable-line
}) => {
  // backend may return 1970-01-01T00:00:00Z for an undefined date
  const badDate = !rest['deleted-at'] || moment(rest['deleted-at']).isBefore('2000-01-01');
  const deletedAt = badDate ? 'unknown' : formatDate(rest['deleted-at'], dateOptions);
  return (
    <TableRow>
      <TableCell>
        {path} <CopyButton text={path} message="Location copied" />
      </TableCell>
      <TableCell align="left">{formatMiB(used)}</TableCell>
      <TableCell align="left">{deletedAt}</TableCell>
    </TableRow>
  );
};

const DeletedApps = ({ report }) => {
  const deletedApps = report['deleted-apps'];
  if (!deletedApps || deletedApps.length === 0) {
    return (
      <Box style={{ width: '100%' }} margin={{ top: 'xsmall' }}>
        <Empty>You have no deleted apps</Empty>
      </Box>
    );
  }

  return (
    <Box margin={{ top: 'xsmall' }}>
      <SortableTable
        headCells={[
          {
            id: 'path',
            numeric: false,
            disablePadding: false,
            label: 'Location',
          },
          {
            id: 'used',
            numeric: true,
            disablePadding: false,
            label: 'Usage',
            align: 'left',
          },
          {
            id: 'deleted-at',
            numeric: false,
            disablePadding: false,
            label: 'Deleted At',
            align: 'left',
          },
        ]}
        rows={deletedApps}
        paginate
        pageLength={30}
        renderRow={(path) => {
          return (
            <Row
              key={hashCode(path.path)}
              {...path}
            />
          );
        }}
      />
    </Box>
  );
};

DeletedApps.propTypes = {
  report: object.isRequired,
};

export default DeletedApps;
