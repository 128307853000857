import ConfigsView from './View/pages/ConfigsView';
import Tool from './Tool/pages';

export const children = [
  {
    label: 'View',
    to: 'view',
    match: new RegExp(/\/configs\/view$/), // eslint-disable-line
    component: ConfigsView,
  },
  {
    label: 'Config Tool',
    to: 'tool',
    match: new RegExp(/\/configs\/tool/), // eslint-disable-line
    component: Tool,
  },
];
