import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { any } from 'prop-types';

import {
  DialogTitle, DialogContent, DialogActions, Dialog, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Box from 'shared/styleguide/atoms/Box';
import NewButton from 'shared/styleguide/atoms/Buttons/NewButton';
import { TextArea } from 'shared/styleguide/atoms/Input/TextArea';
import CopyButton from 'shared/styleguide/molecules/CopyButton';

import ModalErrors from './ModalErrors';

import {
  setInputErrors, jsonModalOpen,
} from '../../redux/actions';

import {
  optimizeTemplatedStrings,
} from '../../helpers';

import { inline } from './styles';

const JsonOutput = ({
  state, inputErrors, modalOpen, jsonModalOpen,
}) => {
  const MODAL_ROWS = 24;

  const optimizedConfig = optimizeTemplatedStrings(state.config);

  return (
    <Fragment>
      <NewButton
        color="default"
        variant="outlined"
        key="JsonOutput"
        name="JsonOutput"
        onClick={() => { jsonModalOpen(true, optimizedConfig); }}
      >
        Export Config
      </NewButton>
      <Dialog
        PaperProps={{ style: inline.dialog }}
        open={modalOpen || false}
        onClose={() => { jsonModalOpen(false, null); }}
        maxWidth="md"
        fullWidth
      >
        <div>
          <DialogTitle>
            Json Config Output
            <div style={inline.closeIcon}>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => { jsonModalOpen(false, null); }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <TextArea
              autoFocus
              style={{ background: 'white' }}
              name="jsonOutput"
              code
              readOnly
              maxRows={MODAL_ROWS}
              rows={MODAL_ROWS}
              fullWidth
              value={JSON.stringify(optimizedConfig, null, 2)}
            />
            <div id="importErrors">
              <ModalErrors errorData={inputErrors} errorHeading="NON-VALID CONFIG" />
            </div>
          </DialogContent>
          <DialogActions>
            <Box row justify="space-between" flex={1}>
              <NewButton
                onClick={() => { jsonModalOpen(false, null); }}
                color="default"
                variant="outlined"
              >
                Close
              </NewButton>

              <CopyButton text={JSON.stringify(optimizedConfig, null, 2)} message="Copied Config">
                <NewButton
                  variant="contained"
                  color="secondary"
                  onClick={(e) => { e.preventDefault(); }}
                >
                  Copy
                </NewButton>
              </CopyButton>
            </Box>
          </DialogActions>
        </div>
      </Dialog>
    </Fragment>
  );
};

JsonOutput.propTypes = {
  inputErrors: any,
  jsonModalOpen: any,
  modalOpen: any,
  state: any,
};

const mapStateToProps = (state) => {
  return {
    state: state.aresConfig.undoableConfig.present,
    inputErrors: state.aresConfig.jsonOutput.inputErrors,
    modalOpen: state.aresConfig.jsonOutput.modalOpen,
  };
};

export default connect(mapStateToProps, {
  setInputErrors, jsonModalOpen,
})(JsonOutput);
