export const GET_FSA_REQUEST = 'FSA_GET_FSA_REQUEST';
export const GET_FSA_SUCCESS = 'FSA_GET_FSA_SUCCESS';
export const GET_FSA_FAILURE = 'FSA_GET_FSA_FAILURE';

export const SETUP_FSA_REQUEST = 'FSA_SETUP_FSA_REQUEST';
export const SETUP_FSA_SUCCESS = 'FSA_SETUP_FSA_SUCCESS';
export const SETUP_FSA_FAILURE = 'FSA_SETUP_FSA_FAILURE';

export const DISABLE_FSA_REQUEST = 'FSA_DISABLE_FSA_REQUEST';
export const DISABLE_FSA_SUCCESS = 'FSA_DISABLE_FSA_SUCCESS';
export const DISABLE_FSA_FAILURE = 'FSA_DISABLE_FSA_FAILURE';
