import React from 'react';
import { useLocation } from 'react-router';
import { Lock } from '@material-ui/icons';
import Box from 'shared/styleguide/atoms/Box';

import Password from 'shared/modules/security/components/Password';
import PIN from 'shared/modules/security/components/PIN';
import Banner from 'shared/styleguide/molecules/Banner';
import MFA from './MFA';

const SecurityPage = () => {
  const location = useLocation();
  return (
    <Box direction="column" gap="medium">
      {
        location.state?.referrer === 'login' && (
          <Banner
            type="warning"
            heading="An account you have access to requires MFA"
            subheading={
              location.state?.bannerText
            }
            icon={<Lock color="primary" />}
          />
        )
      }
      <MFA />
      <Password />
      <PIN />
    </Box>
  );
};

export default SecurityPage;
