import React, { Fragment, useState, useEffect } from 'react';
import {
  shape, func, bool, object,
} from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import Loading from 'shared/styleguide/atoms/Loading';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';

import {
  accountPropTypes,
  collaboratorCollectionPropTypes,
} from 'shared/modules/account/models/model';
import { PRISTINE } from 'shared/utils/redux/constants';
import { roles } from 'shared/modules/permissions/user/actions';

import { fetchAccountCollaborators } from 'shared/modules/team/redux/actions';
import { getRecipientsForAccount, setDeliveryForRecipient } from '../../redux/alerts/actions';
import AlertRow from './Row';

const collabSorter = (a, b) => {
  if (a.role < b.role) return 1;
  if (a.role > b.role) return -1;
  return 0;
};

export const TeamAlertSettings = ({
  account, collaborators, permissions, isPagelyAdmin,
  getRecipientsForAccount, fetchAccountCollaborators, setDeliveryForRecipient,
}) => {
  const header = makeStyles(() => ({
    head: {
      background: 'none',
    },
  }))();
  const firstColumn = makeStyles((theme) => ({
    head: {
      background: 'none',
      borderBottom: 'none',
      paddingBottom: theme.spacing(0.5),
    },
    body: {
      borderBottom: 'none',
    },
  }))();

  const role = isPagelyAdmin ? roles.PRIMARY : permissions.directory[account.id].role;

  useEffect(() => {
    if (collaborators.status === PRISTINE || collaborators.params?.accountId !== account.id) {
      fetchAccountCollaborators(account.id);
    }
    if (!collaborators.hasAlertsSettings) {
      getRecipientsForAccount(account.id);
    }
  }, [account.id, fetchAccountCollaborators, getRecipientsForAccount, collaborators]);

  const [filter, setFilter] = useState('');

  if (!collaborators.hasAlertsSettings || !collaborators?.alertTypes.length) {
    return <Loading />;
  }

  return (
    <Fragment>
      <Typography variant="h2" gutterBottom>Alert Recipients</Typography>
      <Box>
        <Typography variant="body1" color="textSecondary" gutterBottom>Choose which collaborators will recieve which alerts</Typography>
        <Typography variant="caption" color="textSecondary" gutterBottom>Collaborators will be able to view selected types of alerts within Atomic. If &apos;via Email&apos; delivery is selected, they will also receive those alerts via email.</Typography>
        <Box margin={{ top: 'small' }}>
          <TableContainer>
            <Table aria-label="alerts table">
              <TableHead>
                <TableRow>
                  <TableCell classes={firstColumn}>
                    <SearchBar
                      text="Search Collaborators"
                      onChange={(filter) => setFilter(filter)}
                      style={{ paddingBottom: '12px' }}
                    />
                  </TableCell>
                  {
                    collaborators.alertTypes.map((alertType) => (
                      <TableCell key={alertType} classes={header} align="center">{alertType}</TableCell>
                    ))
                  }
                  <TableCell key="emails" classes={header} align="center">via Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  collaborators.collaboratorDirectory
                    // sort owners to the top
                    .sort(collabSorter)
                    .filter((c) => (!filter || c.accessedByEmail.toLowerCase().match(filter) || c.accessedByName.toLowerCase().match(filter)))
                    .map((collaborator) => (
                      <AlertRow key={collaborator.sourceId} setDeliveryForRecipient={setDeliveryForRecipient} alertTypes={collaborators.alertTypes} myRole={role} {...collaborator} />
                    ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};

TeamAlertSettings.propTypes = {
  account: shape(accountPropTypes),
  collaborators: collaboratorCollectionPropTypes,
  fetchAccountCollaborators: func,
  getRecipientsForAccount: func,
  isPagelyAdmin: bool,
  permissions: object.isRequired,
  setDeliveryForRecipient: func,
};

export default connect(
  (state) => ({
    account: state.account,
    collaborators: state.accountCollaborators,
    permissions: state.permissions,
    isPagelyAdmin: state.user.user.adminAccount,
  }),
  {
    getRecipientsForAccount,
    fetchAccountCollaborators,
    setDeliveryForRecipient,
  },
)(TeamAlertSettings);
