import { css, keyframes } from '@emotion/react';

import colors from 'shared/styleguide/theme';

export const jss = {
  button: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.5,
    textAlign: 'center',
    textTransform: 'uppercase',
    borderRadius: 4,
  },
  label: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  nav: {
    '& span': {
      top: -3,
    },
  },
};

export const raised = {
  green: {
    backgroundColor: colors.green20,
  },
  red: {
    backgroundColor: colors.red10,
    color: colors.light10,
  },
  blue: {
    backgroundColor: colors.blue30,
  },
  nav: {
    backgroundColor: colors.blue20,
  },
  navy: {
    backgroundColor: colors.navy20,
    color: colors.light20,
  },
};

export const flat = {
  green: {
    color: colors.green30,
    border: `solid 1px ${colors.green20}`,
  },
  red: {
    color: colors.red10,
    border: `solid 1px ${colors.red10}`,
  },
  blue: {
    color: colors.blue30,
    border: `solid 1px ${colors.blue30}`,
  },
  nav: {
    color: colors.blue20,
    border: 'solid 2px #979797',
  },
  navy: {
    color: colors.navy20,
    border: `solid 1px ${colors.navy20}`,
  },
  smoke: {
    color: colors.sea30,
    border: 'solid 1px #627C85',
    lineHeight: 1,
  },
};

export const ghost = {
  smoke: {
    color: colors.sea30,
    border: 'solid 1px #627C85',
    lineHeight: 1,
    height: 'unset',
    minWidth: 79,
    padding: '4px 8px',
  },
  smokeLight: {
    color: '#C5D9E8',
    border: 'solid 1px #C5D9E8',
    lineHeight: 1,
    height: 'unset',
    minWidth: 79,
    padding: '4px 8px',
  },
};

export const disabledText = colors.dark20;

const slideRightKeyframes = keyframes`
  0% {
    transform: translateX(-50%);
  }
  50%{
    transform: translateX(8%);
  }
  65%{
    transform: translateX(-4%);
  }
  80%{
    transform: translateX(4%);
  }
  95%{
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const slideLeftKeyframes = keyframes`
  0% {
    transform: translateX(50%);
  }
  50%{
    transform: translateX(-8%);
  }
  65%{
    transform: translateX(4%);
  }
  80%{
    transform: translateX(-4%);
  }
  95%{
    transform: translateX(2%);
  }
  100% {
    transform: translateX(0%);
  }
`;

export const slideRight = css`
  animation-name: ${slideRightKeyframes};
  -webkit-animation-name: ${slideRightKeyframes};

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
`;

export const slideLeft = css`
  animation-name: ${slideLeftKeyframes};
  -webkit-animation-name: ${slideLeftKeyframes};

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
`;
