import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Divider, Tooltip, Typography, Fab,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/NoteAddOutlined';

import QueryString from 'qs';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Box from 'shared/styleguide/atoms/Box';
import AccountTags from 'shared/modules/webapp/components/AccountTags';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';
import NoteFilter from './NoteFilters';
import NoteList from './NoteList';

import { useGetNotesForAccountQuery, useGetSharedNotesForAccountQuery } from '../../query';

export const NotesSidebar = ({ account }) => {
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState([]);
  const location = useLocation();

  // load all the notes
  const {
    data: regularNotes, isLoading: notesLoading, isSuccess: notesSuccess, error: notesError,
  } = useGetNotesForAccountQuery({ accountId: account.id }, { skip: !account.id });
  const {
    data: sharedNotes, isLoading: sharedNotesLoading, isSuccess: sharedNotesIsSuccess, error: sharedNotesError,
  } = useGetSharedNotesForAccountQuery({ accountId: account.id }, { skip: !account.id });
  const isLoading = notesLoading || sharedNotesLoading;
  const isSuccess = notesSuccess && sharedNotesIsSuccess;
  const error = notesError || sharedNotesError;
  let notes = [];
  if (isSuccess) {
    notes = regularNotes.data?.concat(sharedNotes.data || []);
  }

  const filteredNotes = (notes || []).filter((note) => {
    if (!searchText && !filterType) {
      return true;
    }

    const filterByType = filterType?.length > 0 ? filterType.includes(note.noteType) : true;

    return filterByType && (
      note.note.toLowerCase().includes(searchText)
      || note.title.toLowerCase().includes(searchText)
    );
  });

  const sortedNotes = useMemo(() => {
    return filteredNotes.concat().sort((notea, noteb) => {
      return Number(noteb.starred) - Number(notea.starred);
    });
  }, [filteredNotes]);

  const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  const pathToCreate = QueryString.stringify({ ...query, noteView: 'create' }, { addQueryPrefix: true });

  return (
    <Paper css={{ maxWidth: 400, minHeight: '100%', border: 'none' }} elevation={0}>
      <Box direction="row">
        {
          account.name && (
            <Box padding="xsmall" flex={1}>
              <Typography variant="subtitle1">{account.companyName || account.name}</Typography>
              <span>
                <Typography variant="body2" color="textSecondary" component="span">Customer since </Typography>
                {/* <Typography variant="body1" color="textPrimary" component="span">{moment(account.dateAdded * 1000).format('MM/DD/YYYY')}</Typography> */}
              </span>
            </Box>
          )
        }
      </Box>
      <Box padding={{ left: 'xsmall', bottom: 'xxsmall' }} direction="row">
        <AccountTags account={account} />
      </Box>
      <NoteFilter onFilter={(type) => {
        setFilterType(type);
      }}
      />
      <Divider />
      <Box direction="row" justify="space-between" margin={{ bottom: 'xsmall' }}>
        <SearchBar
          // @ts-ignore
          css={{ flex: '1', padding: '8px 8px 0' }}
          textFieldProps={{
            fullWidth: true,
            variant: 'standard',
            style: { bottom: 5, marginLeft: 8 },
          }}
          fullWidth
          text="Search Notes"
          onChange={(filter) => setSearchText(filter)}
        />

      </Box>
      { error && (
        <Typography variant="body1" color="error">{error}</Typography>
      )}
      { isLoading && (
        <LoadingDots />
      )}
      {
        isSuccess && (
          <NoteList notes={sortedNotes} />
        )
      }
      <div css={{ height: 60, width: '100%' }}>
        <div
          css={{
            position: 'absolute',
            bottom: 16,
            right: 16,
          }}
        >
          <Tooltip title="Add Note">
            <Fab color="secondary" aria-label="add note" size="medium" component={Link} to={`${location.pathname}${pathToCreate}`}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
};

export default NotesSidebar;
