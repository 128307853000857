import React from 'react';
import ChartComponent from '../Chart';

class Line extends React.Component {
  render() {
    return (
      <ChartComponent
        {...this.props}
        ref={(ref) => { this.chart_instance = ref && ref.chart_instance; }}
        type="line"
      />
    );
  }
}

export default Line;
