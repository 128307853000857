import React from 'react';
import { Typography } from '@material-ui/core';

export const providerColorMap = {
  'cloudflare': 'primary',
  'godaddy-cloudflare': 'primary',
  'cloudfront': 'secondary',
  'godaddy-cloudfront': 'secondary',
};

export const providerNameMap = {
  'cloudflare': 'CloudFlare',
  'godaddy-cloudflare': 'CloudFlare',
  'cloudfront': 'CloudFront',
  'godaddy-cloudfront': 'CloudFront',
};

export const articles = [
  {
    id: 1,
    url: 'https://support.pagely.com/hc/en-us/articles/360006614192-PressCDN',
    title: 'PressCDN Documentation',
    body: (
      <Typography variant="body2" color="textSecondary" component="span">
        This is an all-inclusive article that will cover a broad range of
        CDN-related topics:
        <ul>
          <li>Bandwidth vs CDN</li>
          <li>Setting up a Custom CDN Host</li>
          <li> Using a HTTPS CDN Host</li>
          <li>Using your CDN URLs</li>
          <li>Useful CDN Information</li>
        </ul>
      </Typography>
    ),
  },
  {
    id: 2,
    url: 'https://support.pagely.com/hc/en-us/articles/360002161552-How-to-enable-CDN-in-WordPress-ARES-',
    title: 'How Do I Enable CDN in WordPress?',
    body: 'Every site will have CDN enabled by default and we highly recommend leaving CDN enabled on any active site. This will help overall performance by having static assets loading geographically closer to your site visitors and keep your bandwidth use low (Bandwidth vs CDN).',
  },
  {
    id: 3,
    url: 'https://support.pagely.com/hc/en-us/articles/200237680-How-do-I-purge-the-CDN-',
    title: 'How Do I Purge the CDN?',
    body: 'Via Atomic: Login and go to CDN &gt; Find the url you wish to purge cache for that is associated with your application and select edit...',
  },
  {
    id: 4,
    url: 'https://support.pagely.com/hc/en-us/articles/209015317-Optimizing-Your-WordPress-Site',
    title: 'Optimizing Your WordPress Site',
    body: "In this article, will be talking about the main points recommended to optimize your WordPress for speed and scale with Pagely's system. The items are not in any specific order, but we have seen if they are generally applied, it makes a huge difference in how fast a site is and how many CPU/memory resources are conserved (ie. scale better).",
  },
];
