export default {
  ONBOARDING_PLAYLIST: 'PL_duHz229BYKnSY9gbsGCwSmnBS4XA7GA',
  APP_CREATE_VIDEO_ID: 'M-Sa_jjaNhs',
  ADD_COLLABORATOR_VIDEO_ID: 'oME2ijWvU6Q',
  MANAGE_BILLING_VIDEO_ID: 'c13VYmCPcSs',
  MANAGE_DNS_VIDEO_ID: 'YnYKDdZEeSk',
  MANAGE_SSL_VIDEO_ID: '0ZayRFQq764',
  SSH_SETUP_VIDEO_ID: '47SytjlLCjI',
  GENERATING_SSH_MAC_VIDEO_ID: 'hKr2xMqU9Sw',
  GENERATING_SSH_WINDOWS_VIDEO_ID: 'Ct9CCC05EHE',
  SIGNUP: 'WAWPcqcidZ8',
};
