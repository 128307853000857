import React, { useEffect, useState } from 'react';
import { string, object, func } from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';
import { getEmailTemplates } from '../redux/actions';

const ROWS_PER_PAGE = 10;
const FIRST_PAGE = 1;

const TemplatesRow = ({
  name, subject, match, uuid,
}) => {
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell align="left">{subject}</TableCell>
      <TableCell align="right">
        <Button
          component={AdapterLink}
          variant="outlined"
          to={`${match.url}/${uuid}`}
        >
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
};

TemplatesRow.propTypes = {
  match: object,
  name: string,
  subject: string,
  uuid: string,
};

export const TemplatesList = ({ getEmailTemplates, email }) => {
  const match = useRouteMatch();

  const [templates, setTemplates] = useState(email.data);
  const [page, setPage] = useState(FIRST_PAGE);

  const fetchTemplates = async () => {
    if (email.status === 'pristine') {
      const response = await getEmailTemplates();
      setTemplates(response.data);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Subject</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                email.status === 'success'
                && templates
                  .slice((page - 1) * ROWS_PER_PAGE, (page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE)
                  .map((template) => {
                    return (
                      <TemplatesRow
                        key={template.id}
                        name={template.id}
                        subject={template.subject}
                        match={match}
                        uuid={template.id}
                      />
                    );
                  })
              }

            </TableBody>
          </Table>
        </TableContainer>
        {
          email.status === 'loading' && (
            <Loading />
          )
        }
        <Box margin="small" justify="center" direction="row">
          <Pagination
            shape="rounded"
            count={parseInt(email.count / 10, 10)}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Box>
      </Box>
    </>
  );
};

TemplatesList.propTypes = {
  email: object.isRequired,
  getEmailTemplates: func,
};

export default connect(
  ({ email }) => ({
    email,
  }),
  {
    getEmailTemplates,
  },
)(TemplatesList);
