import { FLAGS } from 'shared/flags';

export const AdminLinks = [
  {
    label: 'Admin Account Settings',
    match: new RegExp('settings'),
    route: '/settings/profile',
    show: [true],
  },
  {
    label: 'Manage Email Templates',
    match: new RegExp('email'),
    route: '/email',
    show: [true],
  },
  {
    label: 'Banned Plugins',
    match: new RegExp('banned-plugins'),
    route: '/banned-plugins',
    show: [true],
  },
  {
    label: 'Infrastructure',
    match: new RegExp('infrastructure'),
    route: '/infrastructure',
    show: [true],
  },
  {
    label: 'Alerts',
    match: new RegExp('alerts'),
    route: '/alerts',
    show: [true],
  },
  {
    label: 'Migration Secrets',
    match: new RegExp('/migration-secrets'),
    route: '/migration-secrets',
    show: [FLAGS.WOOSAAS],
  },
  {
    label: 'Release Notes',
    match: new RegExp('release-notes'),
    route: '/release-notes',
    show: [true],
  },
  {
    label: 'Miscellaneous',
    match: new RegExp('misc'),
    route: '/misc',
    show: [true],
  },
];
