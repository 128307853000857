import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip, Typography } from '@material-ui/core';

import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import colors from 'shared/styleguide/theme';

import { getServerInfo } from 'shared/modules/server/redux/actions';

import { remMapper } from 'shared/styleguide/theme/spacing';
import { Account } from 'shared/modules/account/types';
import { NumberLike } from 'shared/types/types';
import DashboardCardHeading from './DashboardCardHeading';

const sftpTwoColumnStyles = {
  otherRow: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  rightCol: {
    flexBasis: 'auto',
    minWidth: 0,
    marginRight: 20,
  },
  leftCol: {
    wordBreak: 'break-all',
  },
};

export const SFTPInfo = (props: { account: Account; getServerMeta: (accountId: NumberLike) => void }) => {
  const { account, getServerMeta } = props;
  useEffect(() => {
    if (!account.isOnP20Account && account.servers[0] && account.servers[0].label === undefined) {
      getServerMeta(account.id);
    }
  });

  return (
    <div css={{ marginBottom: remMapper('mediumLarge') }}>
      <DashboardCardHeading>SSH/SFTP Information</DashboardCardHeading>
      <Paper
        style={{
          maxHeight: 350,
          overflowX: 'visible',
          overflowY: 'auto',
          padding: 20,
        }}
      >
        <Typography variant="h5"><strong>Hostnames</strong></Typography>
        {
            account.isOnP20Account ? (
              <TwoColumn
                styles={sftpTwoColumnStyles}
                data={[
                  ['Address', 'sftp.pressftp.com'],
                ]}
                noMargin
              />
            ) : (
              <TwoColumn
                styles={sftpTwoColumnStyles}
                noMargin
                data={account.servers
                  .sort((a, b) => {
                    if (a.label < b.label) { return -1; }
                    if (a.label > b.label) { return 1; }
                    return 0;
                  })
                  .map((s) => {
                    let label = s.label as unknown as JSX.Element; //eslint-disable-line

                    if (account.servers.length === 2 && s.label === 'secondary') {
                      label = (
                        <Typography>
                          <Tooltip
                            title={(
                              <Typography variant="subtitle2" style={{ padding: 10 }}>
                                The secondary node should <strong>only</strong> be used to <br />check logs or other tasks <strong>not involving file uploads</strong>
                              </Typography>
                            )}
                            style={{
                              padding: '5px 8px',
                            }}
                          >
                            <IconButton
                              style={{
                                verticalAlign: 'bottom',
                                width: 'auto',
                                height: 'auto',
                                padding: 0,
                                marginRight: 4,
                              }}
                            >
                              <WarningIcon style={{ color: colors.blue30 }} color="inherit" />
                            </IconButton>
                          </Tooltip>
                          {s.label}
                        </Typography>
                      );
                    }

                    return [label, s.hostname];
                  })}
              />
            )
          }
      </Paper>
    </div>
  );
};

export default connect(
  () => ({}),
  {
    getServerMeta: getServerInfo,
  },
)(SFTPInfo);
