import React, { useEffect } from 'react';
import { object, func, array } from 'prop-types';
import { connect } from 'react-redux';

import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import { fetchHeadersForApp } from '../../redux/headers/actions';
import CustomConfigDescription from '../../CustomConfigDescription';
import AccessHeaders from './AccessHeaders';

export const Headers = ({
  app, domains, getHeadersList, headers, customConfig,
}) => {
  useEffect(() => {
    // if access.params.appId !== our app, or its pristine
    if (headers.status === 'pristine' || headers.params.appId !== app.data.id) {
      getHeadersList(app.data.id);
    }
  }, [headers.status, headers.params?.appId, app.data.id, getHeadersList]);

  if (['loading', 'pristine'].includes(headers.status)) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (headers.status === 'failed' && headers.statusCode !== 422) {
    return (
      <div>
        <Empty>
          {headers.errMessage || 'An error occurred.'}
        </Empty>
      </div>
    );
  }

  return (
    <Box direction="column">
      <CustomConfigDescription customConfig={customConfig} />
      <AccessHeaders domains={domains} appId={app.data?.id} headers={headers.data} />
    </Box>
  );
};

Headers.propTypes = {
  app: object.isRequired,
  customConfig: array,
  domains: object.isRequired,
  getHeadersList: func.isRequired,
  headers: object.isRequired,
};

export default connect(
  (state) => {
    return ({
      app: state.app.app,
      domains: state.app.appDomains,
      headers: state.ares.headers,
    });
  },
  {
    getHeadersList: fetchHeadersForApp,
  },
)(Headers);
