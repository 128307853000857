import React, { Component, Fragment } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import logger from 'shared/3rdparty/logger';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import isEquivalent from 'shared/utils/areObjectsEquivalent';
import { getBackup } from 'shared/modules/app/redux/backups/actions';

export class Backup extends Component {
  static propTypes = {
    backup: object.isRequired,
    backupWithLinks: object,
    getBackup: func,
  };

  state = {
    haveLinks: false,
    loading: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { backup } = this.props;
    const { backupWithLinks } = nextProps;
    if (!isEquivalent(nextState, this.state)) {
      return true;
    }
    if (!isEquivalent(nextProps.backupWithLinks, this.props.backupWithLinks)) {
      return backupWithLinks.data.id ? backupWithLinks.data.id === backup.id : true;
    }
    return true;
  }

  handleRequestLinks = () => {
    const { backup } = this.props;

    this.setState({
      loading: true,
    }, async () => {
      try {
        const response = await this.props.getBackup(backup.appId, backup.id);

        this.setState({
          loading: false,
          haveLinks: true,
          links: {
            fileLink: response.fileLink,
            sqlLink: response.sqlLink,
          },
        });
      } catch (err) {
        logger.error(err);
      }
    });
  };

  render() {
    const { backup: row, backupWithLinks } = this.props;
    return (
      <Fragment>
        <TableRow key={row.name} data-testid="backup-row">
          <TableCell component="th" scope="row">
            <Typography variant="h6">
              {moment.utc(row.updated).format('MMM DD YYYY HH:mm UTC')}
            </Typography>
          </TableCell>
          <TableCell align="center">{row.posts}</TableCell>
          <TableCell align="center">{row.plugins}</TableCell>
          <TableCell align="center">{row.themes}</TableCell>
          <TableCell align="right">
            <Box direction="row" gap="small" justify="flex-end">
              <Box>
                <Button
                  variant="outlined"
                  loading={this.state.loading}
                  onClick={this.handleRequestLinks}
                  disabled={this.state.haveLinks}
                >
                  Request Download
                </Button>
              </Box>
              <Box>
                <Button
                  disabled={!this.state.haveLinks}
                  variant="outlined"
                  href={backupWithLinks?.data?.sqlLink}
                  download
                  target="_blank"
                >
                  SQL
                </Button>
              </Box>
              <Box>
                <Button
                  href={backupWithLinks?.data?.fileLink}
                  download
                  target="_blank"
                  disabled={!this.state.haveLinks}
                  variant="outlined"
                >
                  File
                </Button>
              </Box>
            </Box>

          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    backupWithLinks: state.backups.backup,
  }),
  {
    getBackup,
  },
)(Backup);
