export const ANALYTICS_DAILY_REQUEST = 'ANALYTICS_DAILY_REQUEST';
export const ANALYTICS_DAILY_SUCCESS = 'ANALYTICS_DAILY_SUCCESS';
export const ANALYTICS_DAILY_FAILURE = 'ANALYTICS_DAILY_FAILURE';

export const ANALYTICS_APP_DAILY_REQUEST = 'ANALYTICS_APP_DAILY_REQUEST';
export const ANALYTICS_APP_DAILY_SUCCESS = 'ANALYTICS_APP_DAILY_SUCCESS';
export const ANALYTICS_APP_DAILY_FAILURE = 'ANALYTICS_APP_DAILY_FAILURE';

export const ANALYTICS_CPU_RESET = 'ANALYTICS_CPU_RESET';
export const ANALYTICS_DAILY_RESET = 'ANALYTICS_DAILY_RESET';
export const ANALYTICS_APP_DAILY_RESET = 'ANALYTICS_APP_DAILY_RESET';

// get CPU usage
export const ANALYTICS_CPU_DIMENSIONS_REQUEST = 'ANALYTICS_CPU_DIMENSIONS_REQUEST';
export const ANALYTICS_CPU_DIMENSIONS_SUCCESS = 'ANALYTICS_CPU_DIMENSIONS_SUCCESS';
export const ANALYTICS_CPU_DIMENSIONS_FAILURE = 'ANALYTICS_CPU_DIMENSIONS_FAILURE';

export const ANALYTICS_CPU_REQUEST = 'ANALYTICS_CPU_REQUEST';
export const ANALYTICS_CPU_SUCCESS = 'ANALYTICS_CPU_SUCCESS';
export const ANALYTICS_CPU_FAILURE = 'ANALYTICS_CPU_FAILURE';

// getting disk usage high level
export const GET_DISK_BY_MOUNT_REQUEST = 'ANALYTICS_GET_DISK_BY_MOUNT_REQUEST';
export const GET_DISK_BY_MOUNT_SUCCESS = 'ANALYTICS_GET_DISK_BY_MOUNT_SUCCESS';
export const GET_DISK_BY_MOUNT_FAILURE = 'ANALYTICS_GET_DISK_BY_MOUNT_FAILURE';

export const GET_DISK_BY_PATH_REQUEST = 'ANALYTICS_GET_DISK_BY_PATH_REQUEST';
export const GET_DISK_BY_PATH_SUCCESS = 'ANALYTICS_GET_DISK_BY_PATH_SUCCESS';
export const GET_DISK_BY_PATH_FAILURE = 'ANALYTICS_GET_DISK_BY_PATH_FAILURE';

export const GET_DISK_DIMENSIONS_BY_MOUNT_REQUEST = 'ANALYTICS_GET_DISK_DIMENSIONS_BY_MOUNT_REQUEST';
export const GET_DISK_DIMENSIONS_BY_MOUNT_SUCCESS = 'ANALYTICS_GET_DISK_DIMENSIONS_BY_MOUNT_SUCCESS';
export const GET_DISK_DIMENSIONS_BY_MOUNT_FAILURE = 'ANALYTICS_GET_DISK_DIMENSIONS_BY_MOUNT_FAILURE';

export const GET_DISK_DIMENSIONS_BY_PATH_REQUEST = 'ANALYTICS_GET_DISK_DIMENSIONS_BY_PATH_REQUEST';
export const GET_DISK_DIMENSIONS_BY_PATH_SUCCESS = 'ANALYTICS_GET_DISK_DIMENSIONS_BY_PATH_SUCCESS';
export const GET_DISK_DIMENSIONS_BY_PATH_FAILURE = 'ANALYTICS_GET_DISK_DIMENSIONS_BY_PATH_FAILURE';

export const GET_DISK_USAGE_BY_LOCATION_REQUEST_ACTION_ONLY = 'GET_DISK_USAGE_BY_LOCATION_REQUEST_ACTION_ONLY';
export const GET_DISK_USAGE_BY_LOCATION_SUCCESS_ACTION_ONLY = 'GET_DISK_USAGE_BY_LOCATION_SUCCESS_ACTION_ONLY';
export const GET_DISK_USAGE_BY_LOCATION_FAILURE_ACTION_ONLY = 'GET_DISK_USAGE_BY_LOCATION_FAILURE_ACTION_ONLY';

export const GET_DISK_RESET = 'ANALYTICS_GET_DISK_RESET';
