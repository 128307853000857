import React from 'react';
import Box from 'shared/styleguide/atoms/Box';

import Password from './Password';
import MFA from './MFA';

const SecurityRoute = () => {
  return (
    <Box direction="column" gap="medium">
      <MFA />
      <Password />
    </Box>
  );
};

export default SecurityRoute;
