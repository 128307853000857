import * as colors from 'shared/styleguide/theme';

export const graphColors = [
  '#00C61B',
  '#0058CC',
  '#9F0162',
  '#00C2F9',
  '#FFC33B',
  '#009719',
  '#00BDA9',
  colors.danger,
  colors.indigo,
  '#8400CD',
  '#FF6E3A',
  colors.cornflower,
  '#00EFF9',
];

export default graphColors;
