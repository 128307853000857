import React from 'react';
import qs from 'qs';
import {
  func, object, string, bool,
} from 'prop-types';
import { css } from '@emotion/react';
import { Formik, Form } from 'formik';
import {
  Typography, Switch,
} from '@material-ui/core';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import FormLabel from 'shared/styleguide/molecules/RadioInput';
import ExpandingTextField from 'shared/styleguide/molecules/ExpandingTextField';
import { ErrorText, CodeText } from 'shared/styleguide/typography';
import { fields } from './constants';

const stringifyValues = (values) => {
  const testString = 'testvalue';

  const object = {};
  Object.keys(values).forEach((key) => {
    if (key === 'customParams') {
      values[key].forEach((element) => {
        object[element] = testString;
      });
    } else if (values[key]) {
      object[key] = testString;
    }
  });

  return qs.stringify(object);
};

const FormContent = ({
  onSubmit, initialValues, changed, onSetChanged,
}) => {
  const validate = () => {
    if (!changed) {
      onSetChanged(true);
    }
  };

  return (
    <Formik
      initialStatus="pristine"
      initialValues={{
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validateOnChange
      validate={validate}
    >
      {({
        values,
        errors,
        status,
        handleSubmit,
        setFieldValue,
      }) => (
        <Box as={Form} onSubmit={handleSubmit}>
          <Box padding="medium">
            <Typography gutterBottom variant="subtitle1"><strong>Parameters to include in cache key</strong></Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              UTM, GCLID and FBCLID parameters are stripped from the cache key by default to improve cache hit rates and response times. We recommend only enabling these options when deemed absolutely necessary, as they can negatively impact your site&apos;s performance.
            </Typography>
            <Box margin={{ top: 'small' }} direction="column" gap="small">
              {
                fields.map((field) => {
                  return (
                    <Box key={field.value}>
                      <FormLabel
                        labelPlacement="end"
                        control={(
                          <Switch
                            checked={values[field.value] || false}
                            onChange={(e) => setFieldValue(field.value, e.target.checked)}
                          />
                        )}
                      >
                        <Box>
                          <Typography
                            css={css`
                                line-height: 30px;
                                padding-top: 5px;
                              `}
                            variant="body1"
                            color="textPrimary"
                          >
                            {field.label}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">{field.description}</Typography>
                        </Box>
                      </FormLabel>
                    </Box>
                  );
                })
              }
            </Box>
          </Box>
          <Box padding={{
            top: 'xsmall', left: 'medium', right: 'medium', bottom: 'medium',
          }}
          >
            <Typography gutterBottom variant="subtitle1"><strong>Custom parameters to exclude from cache key</strong></Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Non-UTM/GCLID/FBCLID parameters are included in cache keys by default. If you&apos;d like to ignore additional query strings to improve your cache hit rates, you may enter them here.
            </Typography>
            <Box margin={{ top: 'small' }} direction="column">
              <ExpandingTextField
                id="edit-record"
                fullWidth
                inputs={values.customParams}
                onChange={(inputs) => { setFieldValue('customParams', inputs); }}
              />
            </Box>
          </Box>
          {
            stringifyValues(values) && (
              <Box padding={{ left: 'medium', right: 'medium', bottom: 'medium' }}>
                <Typography gutterBottom variant="subtitle1"><strong>Preview</strong></Typography>
                <Box
                  margin={{ top: 'small' }}
                  padding="xsmall"
                  css={{
                    maxWidth: 'calc(100vw - 600px)',
                    border: '1px solid lightgray',
                    borderRadius: 4,
                    background: 'whitesmoke',
                  }}
                >
                  <CodeText css={{
                    overflowWrap: 'break-word', whiteSpace: 'inherit', margin: 0, lineHeight: '1.6',
                  }}
                  >
                    {
                      stringifyValues(values)
                    }
                  </CodeText>
                </Box>
              </Box>
            )
          }
          <Box direction="row" justify="flex-end">
            <Button
              color="secondary"
              type="submit"
              variant={changed ? 'contained' : 'outlined'}
              disabled={!changed}
              status={status}
            >
              Save
            </Button>
          </Box>
          {
            errors.general && (
              <ErrorText>{errors.general}</ErrorText>
            )
          }
        </Box>
      )}
    </Formik>
  );
};

FormContent.propTypes = {
  changed: bool,
  formRef: object,
  initialValues: object,
  onSetChanged: func,
  onSubmit: func,
  type: string,
};

export default (FormContent);
