import { consolidateErrors } from 'shared/utils/validation';
import { isArray } from 'shared/utils/types';
import { deepClone } from 'shared/utils';

import { initialFormValues } from './constants';
import { createLabeledCountries } from './utils';

export const reducer = (state, action) => {
  const { type, ...params } = action;
  switch (type) {
    case 'initialize': {
      const { initialValues, mode, locales } = params;

      const updatedValues = {
        ...initialValues,
        ipsString: isArray(initialValues.ips) ? initialValues.ips.join(',\n') : '',
        initialAuth: !!initialValues.authUsername,
        auth: !!initialValues.authUsername,
        authPassword: '', // redacted
        ip: !!initialValues.ips?.length,
        geo: !!initialValues.geoCountries?.length,
        geoCountries: [...createLabeledCountries(initialValues.geoCountries, initialValues.geoInverse, locales)],
      };

      if (state.formRef?.current) {
        state.formRef.current.setValues(deepClone(updatedValues));
      }

      return {
        ...state,
        ...params,
        changed: false,
        errors: null,
        expanded: mode === 'create',
        initialValues: updatedValues,
        status: {
          save: 'pristine',
          delete: 'pristine',
        },
      };
    }
    case 'reset': {
      if (state.formRef.current) {
        state.formRef.current.setValues(state.mode === 'create' ? deepClone(initialFormValues) : state.initialValues);
      }

      return {
        ...state,
        expanded: false,
        changed: false,
        errors: null,
        status: {
          save: 'pristine',
          delete: 'pristine',
        },
      };
    }
    case 'update': {
      return {
        ...state,
        ...params,
      };
    }
    case 'status': {
      return {
        ...state,
        status: {
          ...state.status,
          ...action.value,
        },
      };
    }
    case 'apiError': {
      return {
        ...state,
        status: 'pristine',
        errors: {
          ...state.errors,
          general: consolidateErrors(action.error),
          ...action.error.response.data.body,
        },
      };
    }
    case 'errors': {
      return {
        ...state,
        errors: {
          ...action.value,
        },
      };
    }
    default:
      return state;
  }
};
