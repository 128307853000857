import React from 'react';
import {
  string, bool, number,
} from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import GoIcon from '@material-ui/icons/Reply';
import {
  Typography, Link, IconButton,
} from '@material-ui/core';
import { roleStrings } from 'shared/modules/permissions/user/actions';

import Box from 'shared/styleguide/atoms/Box';
import Avatar from '../Avatar';

const Person = (props) => {
  const {
    name,
    email,
    showGoToButton,
    id,
  } = props;

  return (
    <Box>
      <Box direction="row" align="center">
        <Avatar
          email={email}
          fullName={name}
        />
        <Box column margin={{ left: 'xsmall' }}>
          <Typography variant="h5" gutterBottom component="span">
            {name}&nbsp;&nbsp;
            {props.role && (
              <span>
                <Typography component="span" color="textSecondary" variant="body2">{roleStrings[props.role]}</Typography>
              </span>
            )}
            {
              showGoToButton && (
                <IconButton
                  id="pagely-collab-link"
                  component={RouterLink}
                  to={`/account/${id}/settings/profile`}
                  size="small"
                  style={{ transform: 'scaleX(-1)' }}
                >
                  <GoIcon fontSize="small" />
                </IconButton>
              )
            }
          </Typography>
          <Box direction="row" align="center">
            <Link
              color="textSecondary"
              href={`mailto:${email}`}
            >
              {email}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Person.propTypes = {
  email: string,
  id: number,
  name: string,
  role: number,
  showGoToButton: bool,
};

export default Person;
