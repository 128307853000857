import React from 'react';
import Display2Text from 'shared/styleguide/typography/Display2Text';
import ShowVideo from 'shared/styleguide/organisms/ShowVideo';
import YOUTUBE from 'shared/3rdparty/youtube';

const Heading = () => (
  <span><Display2Text>PressDNS <ShowVideo videoId={YOUTUBE.MANAGE_DNS_VIDEO_ID} title="Managing DNS" /></Display2Text></span>
);

Heading.displayName = 'PressDNSHeading';

export default Heading;
