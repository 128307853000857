import React from 'react';
import PropTypes, {
  any, string, bool, object,
} from 'prop-types';

import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import WarnIcon from '@material-ui/icons/Error';
import Loading from 'shared/styleguide/atoms/Loading/Dots';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import Empty from 'shared/styleguide/atoms/Empty';
import Checkbox from 'shared/styleguide/atoms/Switches/Checkbox';
import { LOADING, PRISTINE, SUCCESS } from 'shared/utils/redux/constants';
import Box from 'shared/styleguide/atoms/Box';

export default class LinkToDomain extends React.Component {
  static propTypes = {
    addDomain: PropTypes.func.isRequired,
    cert: PropTypes.object.isRequired,
    certDomains: PropTypes.shape({
      data: PropTypes.array.isRequired,
      domains: PropTypes.array,
      params: object,
      status: string.isRequired,
    }),
    certDomainsLinkAction: PropTypes.shape({
      failed: PropTypes.array.isRequired,
      failedMessage: any,
      finished: PropTypes.array.isRequired,
      ids: PropTypes.array.isRequired,
    }).isRequired,
    domains: PropTypes.array.isRequired,
    fetchDomains: PropTypes.func.isRequired,
    fetchLinkedCerts: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isAdmin: bool,
    linkedCerts: PropTypes.shape({
      data: PropTypes.array,
      errMessage: string,
      fetched: any,
      isFetching: PropTypes.bool,
    }).isRequired,
    match: PropTypes.object.isRequired,
    onSetDomains: PropTypes.func,
    renderSave: PropTypes.bool,
    requestCert: PropTypes.func,
    resetLink: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      fetchDomains,
      fetchLinkedCerts,
      match,
      cert,
      certDomains,
      linkedCerts,
      certDomainsLinkAction,
      history,
      requestCert,
    } = this.props;

    if (
      cert.id
      && (certDomains.status === PRISTINE || certDomains.params?.certId !== cert.id)
    ) {
      fetchDomains(match.params.accountID, cert.subjectData, cert.alternateNames, { certId: cert.id });
    }

    if (certDomains.status === SUCCESS && !linkedCerts.fetched && !linkedCerts.isFetching && !linkedCerts.errMessage) {
      fetchLinkedCerts([...new Set(certDomains.data.map((d) => d.appId))]);
    }
    if (certDomainsLinkAction.ids.length > 0 && certDomainsLinkAction.finished.length === certDomainsLinkAction.ids.length) {
      requestCert(cert.id);
      history.push(
        `/account/${match.params.accountID}/ssl/cert/${match.params.certID}`,
      );
    }
  }

  componentDidUpdate() {
    this.componentDidMount();
  }

  handleSetDomain = (domainId) => {
    let domains = [...this.props.domains];

    if (domains.indexOf(domainId) > -1) {
      domains = domains.filter((id) => id !== domainId);
    } else {
      domains.push(domainId);
    }
    if (this.props.onSetDomains) {
      this.props.onSetDomains(domains);
    }
  };

  handleAddDomains = () => {
    const { cert: { id } } = this.props;
    this.props.domains.forEach((domainId) => this.props.addDomain(id, domainId));
  };

  getDomainLabel = (domain) => {
    // check to see if domain is already linked
    if (this.props.linkedCerts.data.filter(
      (cert) => cert.links.filter(
        (certDomain) => certDomain.aliasId === domain.id,
      ).length > 0,
    ).length > 0) {
      return (
        <Typography color="textSecondary" component="span">
          {`${domain.fqdn} (already linked)`}
        </Typography>
      );
    }
    return domain.fqdn;
  };

  renderUpdating = () => {
    const { certDomains: { data: domains }, certDomainsLinkAction } = this.props;

    const filteredDomains = domains.filter((domain) => certDomainsLinkAction.ids.indexOf(domain.id) > -1);

    return (
      <div>
        {filteredDomains.length === 0 && (
          <Empty>
            No domains found matching this certificate
          </Empty>
        )}
        {filteredDomains.map((domain) => {
          let status;
          let statusError;
          if (certDomainsLinkAction.finished.indexOf(domain.id) > -1) {
            status = (
              <CheckIcon
                color="secondary"
                style={{ marginRight: 16 }}
              />
            );
          } else if (certDomainsLinkAction.failed.indexOf(domain.id) > -1) {
            status = (
              <WarnIcon
                color="error"
                style={{ marginRight: 16 }}
              />
            );
            statusError = certDomainsLinkAction.failedMessage[domain.id];
          } else {
            status = (
              <Loading />
            );
          }

          return (
            <Box
              row
              align="center"
              key={domain.id}
              margin={{ bottom: 'small' }}
            >
              {status}
              <Typography component="span">
                {domain.fqdn}
              </Typography>
              <Typography component="span" css={{ paddingLeft: 10 }} color="error">{statusError}</Typography>
            </Box>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      linkedCerts, certDomains: { status, data: domains }, renderSave, cert, certDomainsLinkAction, isAdmin,
    } = this.props;

    if ([PRISTINE, LOADING].includes(status) || !domains || !linkedCerts.fetched) {
      return <Loading />;
    }

    if (certDomainsLinkAction.ids.length > 0) {
      return this.renderUpdating();
    }

    const findWildCards = (domains) => {
      return domains.filter((d) => d.charAt(0) === '*');
    };

    const wildCardDomains = findWildCards(cert.alternateNames);

    let filteredDomains = [];

    filteredDomains = domains.filter((domain) => {
      // filter out any domains that are ALREADY IN cert.domains
      // ie, if you find domain in cert.domains, filter it OUT
      return !cert.domains.find((dom) => dom.aliasId === domain.id)
        // filter out any wildcard selectors
        && (isAdmin || domain.fqdn.charAt(0) !== '*');
    });

    // is the current cert a wildcard cert?
    // if so, it can only apply at the level that the wildcard is at
    // AND ALSO SANS
    if (wildCardDomains.length > 0) {
      const domainSet = new Set();
      wildCardDomains.forEach((wildCardDomain) => {
        // split at the correct level
        const [, tail] = wildCardDomain.split('*'); // this will give us ["", ".whatever.com"] from "*.whatever.com"
        filteredDomains.forEach((domain) => {
          // they gotta match the regex of a wildcard
          // eslint-disable-next-line no-useless-escape
          const subdomainRegex = /^[\w\-]+/;
          const apexRegex = tail.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
          const regex = new RegExp(subdomainRegex.source + apexRegex, 'g');
          // .test called multiple times on the same global regular expression instance will advance past the previous match
          // meaning it will fail the next time, so re-make the regex expression if you need to do more stuff with it

          // IF A CERT IS IN THE ALTERNATE NAMES... ALWAYS ADD IT
          if (cert.alternateNames.includes(domain.fqdn)) {
            domainSet.add(domain);
          }

          if (regex.test(domain.fqdn)) {
            // add domainSet
            domainSet.add(domain);
          }
        });
      });
      // should have list of allowed domains now...
      filteredDomains = [...domainSet];
    }

    if (!filteredDomains.length) {
      return <Empty>There are no domains available to link to this certificate</Empty>;
    }

    return (
      <div>
        {filteredDomains.map((domain) => (
          <div
            key={domain.id}
          >
            <Checkbox
              onCheck={() => this.handleSetDomain(domain.id)}
              label={this.getDomainLabel(domain)}
            />
          </div>
        ))}
        {renderSave && (
          <Button
            variant="contained"
            label="Link Domain"
            disabled={this.props.certDomains.status === LOADING || !this.props.domains.length}
            onClick={this.handleAddDomains}
          />
        )}
      </div>
    );
  }
}
