import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import Loading from 'shared/styleguide/atoms/Loading';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import {
  fetchDomainsForApp,
  promoteDomainToPrimary,
  removeAppDomain,
  set301,
  setActive,
} from 'shared/modules/app/redux/domains/actions';
import { flushAliasCache } from 'shared/modules/app/redux/actions';
import { getLinkedCerts, updateCertDomainLink } from 'shared/modules/ssl/redux/certDomainLink/actions';
import {
  getAllValidations,
} from 'shared/modules/app/redux/dns/actions';

import Domains from './index';
import { getValidationsByDomainId } from '../../redux/dns/selectors';
import { getLinksForApp } from '../../../ssl/redux/certDomainLink/selectors';

const sortDomains = (a, b) => {
  if (a.primary) {
    return -1;
  }
  if (b.primary) {
    return 1;
  }
  if (a.fqdn < b.fqdn) {
    return -1;
  }
  if (a.fqdn > b.fqdn) {
    return 1;
  }

  return 0;
};

const DomainsLoader = (props) => {
  const appId = props.app.data.id;

  const { appDomains, linkedCerts, dns } = props;

  useEffect(() => {
    if (appDomains.status === 'pristine' || (appDomains.params?.appId !== appId)) {
      props.fetchDomainsForApp(appId);
    }
    if (linkedCerts.status === 'pristine' || (linkedCerts.params?.appId !== appId)) {
      props.getLinkedCerts([appId]);
    }
    if (dns.status === 'pristine' || (dns.params.appId && dns.params.appId !== appId)) {
      props.getAllValidations(appId);
    }
  }, [appId]);

  if (props.appDomains.status === 'loading' && props.appDomains.params?.dispatchId !== 'bulk-modify') {
    return (
      <Loading />
    );
  }

  const domains = [...props.appDomains.data];
  domains.sort(sortDomains);

  const sortedAppDomains = {
    ...props.appDomains,
    data: domains,
  };

  const stagingConfig = props.appMeta.data.find((tag) => tag.keyId === 'app:staging-config');
  const isStaging = stagingConfig?.value?.type === 'staging';

  return (
    <ErrorBoundary>
      <Domains
        {...props}
        appDomains={sortedAppDomains}
        validationRecords={dns.validationRecords}
        certLinks={linkedCerts.certLinks}
        isStaging={isStaging}
      />
    </ErrorBoundary>
  );
};

DomainsLoader.propTypes = {
  app: object.isRequired,
  appDomains: object,
  appMeta: object,
  dns: object,
  fetchDomainsForApp: func,
  getAllValidations: func,
  getLinkedCerts: func,
  linkedCerts: object,
};

const mapStateToProps = ({
  account, app, ...state
}) => ({
  account,
  app: app.app,
  appDomains: app.appDomains,
  dns: {
    ...app.dns,
    validationRecords: getValidationsByDomainId(app.dns),
  },
  appMeta: app.metadata,
  apps: state.apps,
  linkedCerts: {
    status: state.linkedCerts.status,
    params: state.linkedCerts.params,
    certLinks: getLinksForApp(state.linkedCerts.data || []),
  },
});
const mapDispatchToProps = {
  flushCache: flushAliasCache,
  fetchDomainsForApp,
  getLinkedCerts,
  promoteDomainToPrimary,
  removeAppDomain,
  set301,
  setActive,
  updateCertDomainLink,
  getAllValidations,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainsLoader);
