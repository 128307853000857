import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';

import { Typography } from '@material-ui/core';

import { Toggle } from 'shared/styleguide/atoms/Switches';
import { ErrorText } from 'shared/styleguide/typography';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';

import {
  enableSFTP, disableSFTP, fetchFtpCredentialsForApp,
} from 'shared/modules/app/redux/actions';
import { roles as ROLES } from 'shared/modules/permissions/user/actions';

import InfoText from 'shared/styleguide/molecules/InfoText';
import FtpCredentials from './FtpCredentials';

export const FTP20 = ({
  account, app, fetchFtpCredentialsForApp, permissions, ...props
}) => {
  const appId = app.data?.id;
  const ftpEnabled = app.data?.ftpEnabled;
  const ftpCredentials = app.data?.ftpCredentials;

  useEffect(
    () => {
      if (appId && ftpEnabled) {
        // just get fresh app credentials
        fetchFtpCredentialsForApp(appId);
      }
    }, [appId, ftpEnabled, fetchFtpCredentialsForApp],
  );

  if (
    !account.id
  ) {
    return (
      <Loading />
    );
  }

  const role = permissions?.directory[account.id]?.role;

  const toggleSFTP = (e, toggle) => {
    if (toggle) { // request enable
      props.enableSFTP(app.data.id);
    } else { // request disable
      props.disableSFTP(app.data.id);
    }
  };

  return (
    <Fragment>
      <Typography key="sftp-status" variant="h3">
        SFTP is {app.data.ftpEnabled ? 'enabled' : 'disabled'}
      </Typography>
      <Box as={Paper} padding="small" margin={{ top: 'xsmall' }} data-testid="p20-sftp">
        <Box direction="row" justify="space-between" align="center" gap="small">
          <Box direction="row" align="center" gap="small" justify="flex-start">
            {
              // only app owner can enable/disable
              role === ROLES.PRIMARY
              && (
                <Toggle
                  key="toggle-sftp"
                  id="toggle-sftp"
                  defaultToggled={app.data.ftpEnabled}
                  disabled={app.doing || app.failed}
                  onToggle={(e, toggle) => toggleSFTP(e, toggle)}
                  style={{ width: 'auto' }}
                />
              )
            }
          </Box>
        </Box>
        {
          (app.failed && app.errMessage) && (
            <ErrorText>{app.errMessage}</ErrorText>
          )
        }
        {
          ftpCredentials?.status === 'loading' && app.data?.ftpEnabled
          && (
            <Box padding={{ top: 'large', bottom: 'large' }}>
              <Loading />
            </Box>
          )
        }
        {
          ftpCredentials?.status === 'success' && app.data?.ftpEnabled
          && (
            <Fragment>
              <FtpCredentials isPrimary={role === ROLES.PRIMARY} credentials={ftpCredentials} />
              <Box margin={{ top: 'small' }}>
                <InfoText
                  href="https://support.pagely.com/hc/en-us/articles/203115864-Using-SFTP-on-your-VPS-Enterprise-Server#legacy-sftp"
                >
                  Using SFTP on your legacy account
                </InfoText>
              </Box>
            </Fragment>
          )
        }
        {
          role < ROLES.PRIMARY && !app.data?.ftpEnabled
          && (
            <Typography variant="body2">
              Contact the account owner to enable SFTP.
            </Typography>
          )
        }
      </Box>
    </Fragment>
  );
};

FTP20.propTypes = {
  account: object.isRequired,
  app: object.isRequired,
  disableSFTP: func.isRequired,
  enableSFTP: func.isRequired,
  fetchFtpCredentialsForApp: func,
  permissions: object.isRequired,
};

export default connect(
  (state) => ({
    account: state.account,
    app: state.app.app,
    permissions: state.permissions,
  }),
  {
    enableSFTP,
    disableSFTP,
    fetchFtpCredentialsForApp,
  },
)(FTP20);
