/* eslint-disable no-param-reassign */
import * as base from 'shared/baseStates';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import {
  CERT_DOMAIN_LINK_SET,
  CERT_DOMAIN_LINK_UPDATE_REQUEST,
  CERT_DOMAIN_LINK_UPDATE_SUCCESS,
  CERT_DOMAIN_LINK_UPDATE_FAILURE,
  CERT_DOMAIN_LINK_RESET,
  CERT_DOMAIN_ACTIVATE_REQUEST,
  CERT_DOMAIN_ACTIVATE_SUCCESS,
  CERT_DOMAIN_ACTIVATE_FAILURE,
  CERT_DOMAIN_DEACTIVATE_REQUEST,
  CERT_DOMAIN_DEACTIVATE_SUCCESS,
  CERT_DOMAIN_DEACTIVATE_FAILURE,
  CERT_APP_ALIASES_REQUEST,
  CERT_APP_ALIASES_SUCCESS,
  CERT_APP_ALIASES_FAILURE,
  CERT_DOMAIN_ADD_REQUEST,
  CERT_DOMAIN_ADD_SUCCESS,
  CERT_DOMAIN_ADD_FAILURE,
  CERT_LINKED_SEARCH_REQUEST,
  CERT_LINKED_SEARCH_SUCCESS,
  CERT_LINKED_SEARCH_FAILURE,
  CERT_DOMAIN_REVERT_REQUEST,
  CERT_DOMAIN_REVERT_SUCCESS,
  CERT_DOMAIN_REVERT_FAILURE,
  CERT_DOMAIN_RESET,
} from 'shared/modules/ssl/redux/certDomainLink/actions';
import { CERT_REQUEST, CERT_FAILURE, CERT_SUCCESS } from 'shared/modules/ssl/redux/cert/actions';
import apiReducer, * as API_REDUCER from 'shared/utils/redux/apiReducer';

const initialState = {
  ...base.record(),
  ...API_REDUCER.baseState,
  data: {
    saved: false,
    deactivated: false,
  },
};

export function certDomainLink(state = initialState, action) {
  switch (action.type) {
    case CERT_DOMAIN_LINK_SET:
    case CERT_DOMAIN_ADD_SUCCESS:
      return { ...API_REDUCER.successMethod(state, action) };
    case CERT_DOMAIN_LINK_UPDATE_REQUEST:
      action.data = { ...action.data, isFetching: true, saved: false };
      return { ...API_REDUCER.loadMethod(state, action) };
    case CERT_DOMAIN_LINK_UPDATE_SUCCESS:
      action.data = { ...action.data, isFetching: false, saved: true };
      return { ...API_REDUCER.successMethod(state, action) };
    case CERT_DOMAIN_LINK_UPDATE_FAILURE:
      return {
        ...state,
        ...base.failure(action.errMessage, action.statusCode),
        ...API_REDUCER.failMethod(state, action),
      };

    // activate/deactivate
    case CERT_DOMAIN_ACTIVATE_REQUEST:
    case CERT_DOMAIN_DEACTIVATE_REQUEST:
      action.data = { saved: false };
      return {
        ...state, isFetching: true, saved: false, ...API_REDUCER.loadMethod(state, action),
      };
    case CERT_DOMAIN_ACTIVATE_SUCCESS:
      action.data = { ...action.data, saved: true };
      return {
        ...state, ...action.data, ...base.success(), ...API_REDUCER.successMethod(state, action),
      };
    case CERT_DOMAIN_DEACTIVATE_SUCCESS:
      action.data.saved = true;
      action.data.deactivated = true;

      return { ...base.success(), ...API_REDUCER.successMethod(state, action) };
    case CERT_DOMAIN_ACTIVATE_FAILURE:
    case CERT_DOMAIN_DEACTIVATE_FAILURE:
      return {
        ...state,
        ...base.failure(action.errMessage, action.statusCode),
        ...API_REDUCER.failMethod(state, action),
      };

    case CERT_DOMAIN_LINK_RESET:
    case LOGIN_LOGOUT_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
}

const certDomainsState = {
  ...initialState,
  ...API_REDUCER.baseCollection,
  domains: [],
  domainsFetched: false,
  certId: null,
  aliasId: null,
};

export function certDomains(state = certDomainsState, action) {
  let idx;
  switch (action.type) {
    case CERT_REQUEST:
      return {
        ...API_REDUCER.load({}, action),
      };
    case CERT_FAILURE:
      return {
        ...API_REDUCER.fail({}, action),
      };
    case CERT_SUCCESS:
      return {
        // ...certDomainsState,
        ...API_REDUCER.success({}, action),
        // domainsFetched: true,
        domains: action.data.domains,
        // isFetching: false,
      };

    case CERT_DOMAIN_ADD_SUCCESS:
      return {
        ...state, ...action.data, isFetching: false, saved: true,
      };

    case CERT_APP_ALIASES_FAILURE:
      return {
        ...state,
        ...base.failure(action.errMessage, action.statusCode),
      };

    case CERT_DOMAIN_ACTIVATE_SUCCESS:
    case CERT_DOMAIN_DEACTIVATE_SUCCESS:
    case CERT_DOMAIN_LINK_UPDATE_SUCCESS:
      idx = state.domains.findIndex((item) => item.appId === action.data.appId && item.certId === action.data.certId && item.aliasId === action.data.aliasId);
      if (idx !== false) {
        const newState = {
          ...state,
          domains: state.domains.map((item) => ({ ...item })),
        };
        newState.domains[idx] = action.data;

        return newState;
      }
      return state;

    case CERT_DOMAIN_RESET:
    case CERT_DOMAIN_REVERT_SUCCESS:
    case LOGIN_LOGOUT_SUCCESS:
      return { ...certDomainsState };
    default:
      return state;
  }
}

export function certDomainSearch(state = certDomainsState, action) {
  switch (action.type) {
    case CERT_APP_ALIASES_REQUEST:
      return {
        ...state,
        ...API_REDUCER.load({}, action),
        isFetching: true,
        saved: false,
      };

    case CERT_APP_ALIASES_SUCCESS:
      return {
        ...certDomainsState,
        ...API_REDUCER.success({}, action),
        // domainsFetched: true,
        // domains: action.data.data,
        // isFetching: false,
      };
    case LOGIN_LOGOUT_SUCCESS:
    case CERT_DOMAIN_LINK_RESET:
      return { ...certDomainsState, ...API_REDUCER.base };
    default:
      return state;
  }
}

const certDomainsLinkState = {
  ...base.record(),
  ids: [],
  finished: [],
  failed: [],
  failedMessage: {},
};

export function certDomainsLinkAction(state = certDomainsLinkState, action) {
  let failedMessage;
  let ids;
  let finished;
  let failed;

  switch (action.type) {
    case CERT_DOMAIN_ADD_REQUEST:
      ids = [];
      ids.push(...state.ids);
      ids.push(action.aliasId);
      return { ...state, ids };

    case CERT_DOMAIN_ADD_SUCCESS:
      finished = [];
      finished.push(...state.finished);
      finished.push(action.aliasId);
      return { ...state, finished };

    case CERT_DOMAIN_ADD_FAILURE:
      failed = [];
      failed.push(...state.failed);
      failed.push(action.aliasId);
      failedMessage = { ...state.failedMessage };
      failedMessage[action.aliasId] = action.errMessage;
      return {
        ...state,
        ...base.failure(action.errMessage, action.statusCode),
        failed,
        failedMessage,
      };

    case CERT_DOMAIN_LINK_RESET:
    case LOGIN_LOGOUT_SUCCESS:
      return { ...certDomainsLinkState };
    default:
      return state;
  }
}

const baseLinkedCertState = {
  ...base.collection(),
  fetched: false,
};

export function linkedCerts(state = baseLinkedCertState, action) {
  switch (action.type) {
    case CERT_LINKED_SEARCH_REQUEST:
      return { ...state, isFetching: true, fetched: false };
    case CERT_LINKED_SEARCH_SUCCESS:
      return {
        ...state, isFetching: false, data: action.data.data, fetched: true,
      };
    case CERT_LINKED_SEARCH_FAILURE:
      return {
        ...state,
        ...base.failure(action.errMessage, action.statusCode),
        fetched: false,
        isFetching: false,
      };
    case CERT_DOMAIN_LINK_RESET:
    case LOGIN_LOGOUT_SUCCESS:
      return { ...baseLinkedCertState };
    default:
      return state;
  }
}

export const linkedCertRevert = apiReducer(
  CERT_DOMAIN_REVERT_REQUEST,
  CERT_DOMAIN_REVERT_SUCCESS,
  CERT_DOMAIN_REVERT_FAILURE,
  CERT_DOMAIN_LINK_RESET,
);
