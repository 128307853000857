import React, { Fragment } from 'react';
import {
  func, object, string, array, bool,
} from 'prop-types';
import qs from 'qs';
import {
  IconButton, Tooltip,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';

import { getAtomicBaseDomain } from 'shared/config';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import exportData from 'shared/utils/exportData';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { labelData } from './Chart/utils';

const FilterButtons = ({
  displayFilters,
  onDisplayFilters,
  onResetFilters,
  chartData,
  startDate,
  endDate,
  path,
  options,
  location,
  selectableFilters,
}) => {
  const getShareLink = () => {
    const query = qs.stringify({
      [path]: { ...options },
    }, { addQueryPrefix: true });

    return `${getAtomicBaseDomain()}${location.pathname}${query}`;
  };

  return (
    <Box direction="row" align="center" justify="flex-start" gap="small" margin={{ bottom: 'xsmall' }}>
      {
        selectableFilters
        && (
          <Fragment>
            <Box>
              <Button
                color="default"
                variant="outlined"
                onClick={() => { onDisplayFilters(!displayFilters); }}
              >{displayFilters ? 'Hide Filters' : 'Show Filters'}
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="default"
                style={{ marginLeft: 'auto' }}
                onClick={() => onResetFilters()}
              >
                Reset Filters
              </Button>
            </Box>
          </Fragment>
        )
      }
      <Box>
        <Button
          variant="outlined"
          color="default"
          onClick={() => {
            const filename = `pressformance_${startDate.format('YYYY-MM-D')}_${endDate.format('YYYY-MM-D')}`;
            const dataset = labelData(chartData.data.series);
            exportData(filename, dataset.data, dataset.columns);
          }}
        >
          Export to CSV
        </Button>
      </Box>
      <Box direction="row" align="center" style={{ height: 38 }}>
        <CopyButton
          text={getShareLink()}
          message={`${path === 'byMount' ? 'Overview' : 'Details'} url copied to clipboard`}
          tooltip="Click to copy sharable link"
        >
          <IconButton
            size="small"
            color="secondary"
            onClick={(e) => { e.preventDefault(); }}
          >
            <Tooltip
              title="Click to copy sharable link"
              placement="top"
            >
              <ShareIcon
                fontSize="small"
              />
            </Tooltip>
          </IconButton>
        </CopyButton>
      </Box>
    </Box>
  );
};

FilterButtons.propTypes = {
  chartData: object.isRequired,
  displayFilters: bool,
  endDate: object.isRequired,
  location: object.isRequired,
  onDisplayFilters: func.isRequired,
  onResetFilters: func.isRequired,
  options: object.isRequired,
  path: string.isRequired,
  selectableFilters: array,
  startDate: object.isRequired,
};

export default FilterButtons;
