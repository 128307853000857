import Invoices from './Invoices';
import Payment from './Payment';
import Plan from './Plan';
import Overview from './Overview';

export const routes = [
  {
    label: 'Overview',
    to: 'overview',
    match: new RegExp(/\/billing\/overview$/), // eslint-disable-line
    component: Overview,
  },
  {
    label: 'Plan',
    to: 'plan',
    match: new RegExp(/\/billing\/plan$/), // eslint-disable-line
    component: Plan,
  },
  {
    label: 'Payment',
    to: 'payment',
    match: new RegExp(/\/billing\/payment$/), // eslint-disable-line
    component: Payment,
  },
  {
    label: 'Invoices',
    to: 'invoices',
    match: new RegExp(/\/billing\/invoices$/), // eslint-disable-line
    component: Invoices,
  },
];
