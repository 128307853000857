/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import { styled, withStyles } from '@material-ui/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  jss, flat, raised, disabledText, ghost,
} from './styles';

const useStyles = ({
  root: jss.button,
});

const RaisedButton = styled(({ styleColor, ...other }) => <MuiButton {...other} />)(({
  styleColor,
}) => (raised[styleColor]));

const FlatButton = styled(({ styleColor, ...other }) => <MuiButton {...other} />)(({
  styleColor,
}) => (flat[styleColor]));

const GhostButton = styled(({ styleColor, ...other }) => <MuiButton {...other} />)(({
  styleColor,
}) => (ghost[styleColor]));

/**
 * @deprecated
 * @param {*} props
 * @returns
 */
export const Button = (props) => {
  const {
    color, type, label, children, classes, variant, loading, ...rest
  } = props;

  // eslint-disable-next-line
  const labelColor = (type === 'raised' || variant === "raised") ? '#FFFFFF' : (type === 'flat' || variant === "flat") ? flat[color] : disabledText;
  const labelStyle = {
    color: (rest.disabled || loading) ? disabledText : labelColor,
  };

  if (loading) {
    labelStyle.paddingRight = (loading && 25);
  }

  // if loading, disabled = true and show loading spinner next to it
  if (type === 'raised' || variant === 'raised') {
    return (
      <RaisedButton
        variant="contained"
        styleColor={color}
        color="primary"
        classes={classes}
        type={type === 'submit' ? 'submit' : 'button'}
        endIcon={loading && (<CircularProgress size={25} />)}
        {...rest}
      >
        {label || children}
      </RaisedButton>
    );
  } else if (type === 'ghost' || variant === 'outlined') {
    return (
      <GhostButton
        variant="outlined"
        classes={classes}
        styleColor={color}
        color="inherit"
        type={type === 'submit' ? 'submit' : 'button'}
        endIcon={loading && (<CircularProgress size={25} />)}
        {...rest}
      >
        {label || children}
      </GhostButton>
    );
  }

  return (
    <FlatButton
      variant="outlined"
      classes={classes}
      styleColor={color}
      color="inherit"
      type={type === 'submit' ? 'submit' : 'button'}
      endIcon={loading && (<CircularProgress size={25} />)}
      {...rest}
    >
      {label || children}
    </FlatButton>
  );
};

Button.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.oneOf(['red', 'green', 'blue', 'nav', 'navy', 'smoke', 'smokeLight']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['raised', 'flat', 'ghost', 'submit', 'button']),
  variant: PropTypes.oneOf(['raised', 'flat', 'ghost', 'submit', 'button']),
};

Button.defaultProps = {
  type: 'raised',
};

export default withStyles(useStyles)(Button);
