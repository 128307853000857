export const schemes = ['http', 'https'];

export const defaultCompareOptions = [
  {
    label: 'Equals',
    value: 'equals',
  },
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Starts with',
    value: 'starts-with',
  },
  {
    label: 'Ends with',
    value: 'ends-with',
  },
];

export const inputs = {
  scheme: {
    label: 'Scheme',
    valueInput: 'select',
    valueInputOptions: schemes.map((scheme) => { return { label: scheme, value: scheme }; }),
    fromPlaceholder: 'any',
    toPlaceholder: 'unchanged',
  },
  host: {
    label: 'Domain',
    valueInput: 'text',
    compareOptions: defaultCompareOptions,
    placeholder: 'unchanged',
  },
  path: {
    label: 'Path',
    valueInput: 'text',
    compareOptions: defaultCompareOptions,
    placeholder: 'unchanged',
  },
  query: {
    label: 'Query',
    keyInput: 'text',
    valueInput: 'text',
    keyPlaceholder: 'unchanged',
    valuePlaceholder: 'unchanged',
    compareOptions: [
      ...defaultCompareOptions,
      {
        label: 'Key exists',
        value: 'exists',
      },
      {
        label: 'Key not exists',
        value: 'empty',
      },
    ],
  },
};

export const initialFormValues = {
  from: {
    scheme: {},
    host: {},
    path: {},
    query: [],
  },
  to: {
    scheme: {},
    host: {},
    path: {},
    query: [],
  },
  enabled: true,
  statusCode: 301,
  description: null,
};

export const resetState = {
  simpleValues: ({ from: null, to: null }),
  advancedEdit: false,
  expanded: false,
  changed: false,
  errors: null,
};

export const initialFormState = {
  ...resetState,
  initialValues: null,
  mode: null,
  formRef: null,
  queryMode: 'update',
  status: 'pristine',
  isParsed: false,
  saveAction: false,
  showUpdated: false,
  deleted: false,
  newlyAdded: false,
};

// http(s)(://) scheme only
export const validSchemeRegex = new RegExp(/^(https?)(:[/]{2})?$/);

// http(s)://something.com (with optional trailing slash)
export const validSchemeDomainRegex = new RegExp(/^(https?:[/]{2})([a-zA-Z0-9-.]+\.[a-zA-Z0-9-]+\/?)?$/);

// http(s)://something.com/anything including spaces?whatever=hithere in path
export const validFullUrlRegex = new RegExp(/^(https?:[/]{2})([a-zA-Z0-9-.]+\.[a-zA-Z0-9-]+)[/|?]{1}(.+)$/);
