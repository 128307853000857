import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import TrashIcon from '@material-ui/icons/Delete';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { object, func } from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import Card from 'shared/styleguide/atoms/Card/Card';
import Box from 'shared/styleguide/atoms/Box';

import ConfirmableButton from 'shared/styleguide/atoms/Buttons/ConfirmableButton';
import Loading from 'shared/styleguide/atoms/Loading';
import { getDNSZones, createZone, deleteZone } from 'shared/modules/dns/redux/actions';
import { fetchDomainsForAccount } from 'shared/modules/app/redux/domains/actions';
import colors from 'shared/styleguide/theme';
import Layout from 'shared/styleguide/molecules/Layout';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

import Empty from 'shared/styleguide/atoms/Empty';
import AddZone from '../components/AddZone';
import Heading from '../components/Heading';

const PAGE_LENGTH = 10;

const styles = {
  rightTableCell: {
    width: 60,
  },
};

export class DNSZones extends Component {
  static propTypes = {
    account: object.isRequired,
    createZone: func,
    deleteZone: func,
    dns: object.isRequired,
    domains: object,
    fetchDomainsForAccount: func.isRequired,
    getDNSZones: func.isRequired,
  };

  state = { page: 1 };

  componentDidMount() {
    const { domains } = this.props;
    if (!domains.params || domains.params.accountId !== this.props.account.id || !domains.params.options) {
      this.props.fetchDomainsForAccount(this.props.account.id, { noPressDns: 1 });
    }
    this.props.getDNSZones({ accountId: this.props.account.id });
  }

  handleSubmit = ({ appId, domain }) => {
    const accountId = this.props.account.id;
    return this.props.createZone({ appId, accountId, domain });
  };

  render() {
    const { dns, domains } = this.props;
    const { page } = this.state;
    const index = (page * PAGE_LENGTH) - PAGE_LENGTH;

    const dnsZones = dns.data;

    let availableZones = domains.records;

    if (dns.loaded && domains.records.length) {
      availableZones = domains.records.filter((a) => {
        const apex = `${a.fqdn}.`;

        const matchesExistingZone = dnsZones.find((z) => {
          const sanitizedZoneApex = z.apex.replace(/[.]/g, '[.]');
          // will match if a base domain zone exists, or if exact match
          const regex = new RegExp(`^(.*)${sanitizedZoneApex}$`);

          return Boolean(apex.match(regex));
        });

        return !matchesExistingZone && a.active;
      });
    }

    return (
      <Layout
        title={(
          <Heading />
        )}
      >
        <Box>
          <Typography variant="h3" gutterBottom><strong>Add a Zone</strong></Typography>
        </Box>
        <Box direction="column" margin={{ bottom: 'mediumLarge' }}>
          <Card>
            <AddZone aliases={availableZones} onSubmit={this.handleSubmit} />
          </Card>
        </Box>
        <Box>
          <Typography variant="h3" gutterBottom><strong>DNS Zones</strong></Typography>
        </Box>
        <Box margin={{ bottom: 'medium' }}>
          {
            (['pristine', 'loading'].includes(dns.status) || !dnsZones)
              ? (
                <Loading />
              ) : (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Domain</TableCell>
                          <TableCell align="left">Date Updated </TableCell>
                          <TableCell align="left" css={styles.rightTableCell}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          dnsZones.length > 0
                          && dnsZones
                            .slice(index, index + PAGE_LENGTH)
                            .map((r) => (
                              <TableRow key={r.id}>
                                <TableCell align="left">
                                  <TextLink key={r.id} to={{ pathname: `dns/${r.id}`, state: r }}>
                                    {r.apex}
                                  </TextLink>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="body2">
                                    {moment(r.dateUpdated).format('MMM DD YYYY HH:mm')}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <ConfirmableButton
                                    disabled={dns.doing}
                                    icon={TrashIcon}
                                    size="small"
                                    activeColor={colors.red10}
                                    callback={() => this.props.deleteZone({ zoneId: r.extId, appId: r.appId, accountId: r.accountId })}
                                    tooltip="Delete Zone"
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {
                    dnsZones.length > PAGE_LENGTH && ( // use updated redirects to calculate
                      <Box margin={{ top: 'small' }} justify="center" direction="row">
                        <Pagination
                          shape="rounded"
                          count={Math.ceil(dnsZones.length / PAGE_LENGTH)}
                          page={page}
                          onChange={(event, value) => {
                            this.setState({ page: value });
                          }}
                        />
                      </Box>
                    )
                  }
                  {dnsZones.length === 0 && (
                    <Box flex={1} padding="xsmall">
                      <Empty>
                        No DNS Zones to display
                      </Empty>
                    </Box>
                  )}
                </>
              )
          }
        </Box>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    account: state.account,
    domains: state.domains,
    dns: state.dns.dnsZones,
  }),
  {
    getDNSZones,
    createZone,
    deleteZone,
    fetchDomainsForAccount,
  },
)(DNSZones);
