export const initialFormValues = {
  idKey: '',
  path: '',
  match: '',
  preset: '',
  authUsername: '',
  authPassword: '',
  auth: false,
  ip: false,
  geo: false,
  ips: [],
  ipsString: '',
  ipListName: '',
  geoCountries: [],
  geoInverse: undefined,
  excludePagelyStatus: undefined,
  forbiddenMessage: undefined,
  errorLogMessage: undefined,
};

export const initialFormState = {
  initialValues: null,
  geoLocales: [],
  mode: null,
  formRef: null,
  status: {
    save: 'pristine',
    delete: 'pristine',
  },
  changed: false,
  errors: false,
};

export const labeledUSLocale = {
  id: 'US',
  value: 'US',
  label: 'United States of America',
};

export const pathPresetLabelsHash = {
  'all': {
    name: 'all',
  },
  'wp-admin-wp-login-minus': {
    name: 'wp-admin + wp-login.php',
    exclusions: true,
  },
  'wp-admin-wp-login': {
    name: 'wp-admin + wp-login.php',
  },
  'wp-admin-minus': {
    name: 'wp-admin',
    exclusions: true,
  },
  'wp-admin': {
    name: 'wp-admin',
  },
  'wp-login': {
    name: 'wp-login.php',
  },
};
