/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';

type Props = {
  label: string;
  index: number | null;
  description: string;
  [k: string]: any;
};

const StepLabel = ({
  label, index, description, ...props
}: Props) => {
  return (
    <Box padding={{ top: 'xsmall', bottom: 'xsmall' }} {...props}>
      {
        index !== null && <Typography gutterBottom variant="caption" color="textSecondary">Step {index + 1}</Typography>
      }
      <Typography gutterBottom variant="h5" color="initial"><strong>{label}</strong></Typography>
      <Typography gutterBottom variant="body1" color="textSecondary">{description}</Typography>
    </Box>
  );
};

export default StepLabel;
