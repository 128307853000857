import React from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';

interface CustomProps {
  testId?: string;
  hintText?: string; // this is deprecated by MUI
  rows?: number; // this is deprecated by MUI
  inputRef?: React.Ref<HTMLInputElement>;
  errorText?: string | Record<string, string> | boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: string) => void;
  style?: React.CSSProperties;
}

export type StyleGuildTextFieldProps = CustomProps & Partial<TextFieldProps>;

const TextField = ({
  testId = '',
  hintText,
  inputRef,
  errorText,
  onChange: oldOnChange,
  rows,
  placeholder,
  ...props
}: StyleGuildTextFieldProps): JSX.Element => {
  const specialProps: Partial<TextFieldProps> = {};

  if (testId) {
    specialProps.inputProps = {
      'data-testid': testId,
    };
  }

  if (oldOnChange) {
    specialProps.onChange = (e) => oldOnChange(e, e.target.value);
  }

  return (
    <MuiTextField
      variant="outlined"
      inputRef={inputRef}
      margin="normal"
      placeholder={placeholder}
      helperText={hintText || errorText}
      error={Boolean(errorText)}
      minRows={rows}
      {...specialProps}
      {...props}
    />
  );
};

export default TextField;
