import React from 'react';
import { number, array } from 'prop-types';

import { Typography } from '@material-ui/core';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import * as diskPropTypes from 'shared/modules/analytics/models/propTypes';

import DiskLineWrapper from './DiskLineWrapper';

const DiskLineByVolume = ({
  accountId, diskUsage, mounts,
}) => {
  return (
    <Box>
      <Typography variant="h3">
        Volume
      </Typography>
      <Box as={Paper} style={{ flexGrow: 1 }} padding="medium" margin={{ top: 'small', bottom: 'medium' }}>
        <Box align="center" style={{ width: '100%' }}>
          <DiskLineWrapper
            accountId={accountId}
            diskUsage={diskUsage}
            mounts={mounts}
          />
        </Box>
      </Box>
    </Box>
  );
};

DiskLineByVolume.propTypes = {
  accountId: number,
  diskUsage: diskPropTypes.usage,
  mounts: array,
};

export default DiskLineByVolume;
