export const nonCustomValues = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',
  'fbclid',
];

export const defaultValues = {
  utm_source: false,
  utm_medium: false,
  utm_campaign: false,
  utm_term: false,
  utm_content: false,
  gclid: false,
  fbclid: false,
};

export const fields = [
  {
    label: 'utm_source',
    value: 'utm_source',
    description: 'The source parameter allows you to track where the traffic originated from.',
  },
  {
    label: 'utm_medium',
    value: 'utm_medium',
    description: 'The medium parameter tracks what type of traffic the visitor originated from.',
  },
  {
    label: 'utm_campaign',
    value: 'utm_campaign',
    description: 'The campaign name parameter allows you to track the performance of a specific campaign.',
  },
  {
    label: 'utm_term',
    value: 'utm_term',
    description: 'The keyword parameter allows you to track which keyword term a website visitor came from. This parameter is specifically used for paid search ads.',
  },
  {
    label: 'utm_content',
    value: 'utm_content',
    description: 'If have multiple links pointing to the same URL, this will help you track which link was clicked.',
  },
  {
    label: 'gclid',
    value: 'gclid',
    description: 'Google Click Identifier, is a unique tracking parameter that Google uses to sync information between your Google Ads and Google Analytics.',
  },
  {
    label: 'fbclid',
    value: 'fbclid',
    description: 'Facebook Click Identifiers are a tracking parameter that is automatically added to outbound links on Facebook and collecting user data.',
  },
];
