export const APPS_REQUEST = 'APPS_REQUEST';
export const APPS_SUCCESS = 'APPS_SUCCESS';
export const APPS_FAILURE = 'APPS_FAILURE';

export const APP_CREATE_REQUEST = 'APP_CREATE_REQUEST';
export const APP_CREATE_SUCCESS = 'APP_CREATE_SUCCESS';
export const APP_CREATE_FAILURE = 'APP_CREATE_FAILURE';

export const APP_DELETE_REQUEST = 'APP_DELETE_REQUEST';
export const APP_DELETE_SUCCESS = 'APP_DELETE_SUCCESS';
export const APP_DELETE_FAILURE = 'APP_DELETE_FAILURE';
export const APP_RESET = 'APP_RESET';

export const APP_DOMAINS_FETCH_REQUEST = 'APP_DOMAINS_FETCH_REQUEST';
export const APP_DOMAINS_FETCH_SUCCESS = 'APP_DOMAINS_FETCH_SUCCESS';
export const APP_DOMAINS_FETCH_FAILURE = 'APP_DOMAINS_FETCH_FAILURE';

export const APP_DOMAIN_RESET = 'APP_DOMAIN_RESET';
