export const GET_VALIDATIONS_REQUEST = 'APP_DNS_GET_VALIDATIONS_REQUEST';
export const GET_VALIDATIONS_SUCCESS = 'APP_DNS_GET_VALIDATIONS_SUCCESS';
export const GET_VALIDATIONS_FAILURE = 'APP_DNS_GET_VALIDATIONS_FAILURE';

export const GET_VALIDATION_REQUEST = 'APP_DNS_GET_VALIDATION_REQUEST';
export const GET_VALIDATION_SUCCESS = 'APP_DNS_GET_VALIDATION_SUCCESS';
export const GET_VALIDATION_FAILURE = 'APP_DNS_GET_VALIDATION_FAILURE';

export const TRIGGER_VALIDATION_REQUEST = 'APP_TRIGGER_VALIDATION_REQUEST';
export const TRIGGER_VALIDATION_SUCCESS = 'APP_TRIGGER_VALIDATION_SUCCESS';
export const TRIGGER_VALIDATION_FAILURE = 'APP_TRIGGER_VALIDATION_FAILURE';

export const GENERATE_VALIDATION_REQUEST = 'APP_GENERATE_VALIDATION_REQUEST';
export const GENERATE_VALIDATION_SUCCESS = 'APP_GENERATE_VALIDATION_SUCCESS';
export const GENERATE_VALIDATION_FAILURE = 'APP_GENERATE_VALIDATION_FAILURE';
