import React, { useEffect, useState } from 'react';
import { func, object, string } from 'prop-types';
import { css } from '@emotion/react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import SendIcon from '@material-ui/icons/Send';
import { Paper, Typography } from '@material-ui/core';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { ErrorText } from 'shared/styleguide/typography';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';

import Box from 'shared/styleguide/atoms/Box';
import { consolidateErrors } from 'shared/utils/validation';

import { checkEmailChangeToken, emailAddressChangeRequest, updateAccount } from 'shared/modules/account/redux/actions';
import Avatar from 'shared/styleguide/molecules/Avatar';
import ChangeEmail from './ChangeEmail';

export const ProfileAvatarSection = (props) => {
  return (
    <section>
      <Avatar
        css={css`
                height: 132px;
                width: 132px;
                font-size: 4rem;
                margin-bottom: 16px;
                border-width: 10px;
              `}
        fullName={props.name}
        email={props.email}
        gravatarSize={144}
      />
      <Typography color="textSecondary" variant="body2">Gravatar image is managed at&nbsp;
        <a
          href="https://www.gravatar.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          gravatar.com
        </a>
      </Typography>
    </section>
  );
};

ProfileAvatarSection.propTypes = {
  email: string,
  name: string,
};

export const Profile = (props) => {
  const { account, user, checkEmailToken } = props;
  const isAdmin = user?.user?.adminAccount;
  const [showChangeEmailFlow, setShowEmailFlow] = useState(false);
  const [changeEmailRequestStatus, setChangeEmailRequestStatus] = useState('initial');
  const [changeEmailRequestErrors, setChangeEmailRequestErrors] = useState('');
  const userId = user.data.id;
  const disabled = !isAdmin && (userId !== account.id);

  useEffect(() => {
    props.checkEmailChangeToken(account.id);
  }, []);

  const handleRequestChange = async () => {
    const {
      changeEmailRequest,
      account,
    } = props;
    setChangeEmailRequestStatus('loading');

    try {
      await changeEmailRequest(account.id, { email: checkEmailToken.data.email });
      setTimeout(() => {
        setChangeEmailRequestStatus('initial');
      }, 5000);
      setChangeEmailRequestStatus('success');
    } catch (err) {
      if (err?.response?.status === 422) {
        setChangeEmailRequestErrors(consolidateErrors(err));
      } else {
        setChangeEmailRequestErrors(consolidateErrors(err));
      }
      setChangeEmailRequestStatus('failed');
    }
  };

  const handleSubmitProfile = ({ email, ...values }, actions) => {
    const {
      updateAccountSettings, account, changeEmailRequest,
    } = props;

    if (email !== account.email && !isAdmin) {
      setShowEmailFlow(true);
      return;
    } else if (isAdmin && email !== account.email) {
      actions.setStatus('loading');
      changeEmailRequest(account.id, { email })
        .then(() => {
          // set some text about changing email
          actions.setFieldError('adminOnly', `Change Email Request sent to ${email}`);
        })
        .catch((err) => {
          if (err.response?.status === 422) {
            const fields = err.response?.data?.body;
            Object.keys(fields).forEach((key) => {
              actions.setFieldError(key, fields[key].messages.join(', '));
            });
          } else {
            actions.setFieldError('general', consolidateErrors(err));
          }

          actions.setStatus('failure');
          actions.setSubmitting(false);
        });
    }

    updateAccountSettings(account.id, values)
      .then(() => {
        setTimeout(() => {
          actions.setStatus('initial');
        }, 5000);
        actions.setStatus('success');
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          const fields = err.response?.data?.body;
          Object.keys(fields).forEach((key) => {
            actions.setFieldError(key, fields[key].messages.join(', '));
          });
        } else {
          actions.setFieldError('general', consolidateErrors(err));
        }

        actions.setStatus('failure');
        actions.setSubmitting(false);
      });
  };

  return (
    <Paper>
      <Box direction="row" padding="large" gap="medium" wrap="wrap">
        <Box direction="column" style={{ flexBasis: 144 }}>
          <ProfileAvatarSection email={account.email} name={account.name} />
        </Box>
        <Box direction="column" flex={1}>
          <Formik
            enableReinitialize
            initialStatus="initial"
            initialValues={{
              name: account.name,
              companyName: account.companyName,
              email: (checkEmailToken?.data?.email || account.email),
            }}
            onSubmit={handleSubmitProfile}
          >
            {({
              handleSubmit, handleChange, values, errors, status,
            }) => (
              <Box as="form" onSubmit={handleSubmit}>
                <Box direction="row">
                  <WideTextField
                    name="name"
                    placeholder="Name"
                    autoComplete="off"
                    margin="normal"
                    onChange={handleChange}
                    errorText={errors.name}
                    value={values.name}
                    disabled={disabled}
                  />
                </Box>
                <Box direction="row" align="center" gap="small">
                  <WideTextField
                    name="email"
                    placeholder="Email"
                    type="email"
                    autoComplete="off"
                    margin="normal"
                    onChange={handleChange}
                    errorText={errors.email}
                    value={values.email}
                    disabled={disabled}
                  />
                </Box>
                {
                  checkEmailToken?.status === 'success'
                  && (
                    <Box direction="row">
                      <Button
                        status={changeEmailRequestStatus}
                        variant="outlined"
                        color="secondary"
                        startIcon={<SendIcon color="secondary" />}
                        onClick={handleRequestChange}
                      >
                        Resend Confirmation
                      </Button>
                    </Box>
                  )
                }
                <Box direction="row">
                  <WideTextField
                    name="companyName"
                    placeholder="Company Name"
                    autoComplete="off"
                    margin="normal"
                    onChange={handleChange}
                    errorText={errors.companyName}
                    value={values.companyName}
                    disabled={disabled}
                  />
                </Box>
                {
                  errors.general
                  && <ErrorText>{errors.general}</ErrorText>
                }
                {
                  changeEmailRequestErrors
                  && <ErrorText>{changeEmailRequestErrors}</ErrorText>
                }
                <Box align="flex-end" padding={{ top: 'small' }}>
                  {
                    isAdmin
                    && errors.adminOnly
                    && <Typography color="secondary" gutterBottom>{errors.adminOnly}</Typography>
                  }
                  <Button
                    status={status}
                    type="submit"
                    variant="contained"
                  >
                    Update Profile
                  </Button>
                </Box>
                {
                  showChangeEmailFlow && (
                    <ChangeEmail
                      open={showChangeEmailFlow}
                      onClose={() => setShowEmailFlow(false)}
                      newEmailAddress={values.email}
                    />
                  )
                }
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
};

Profile.propTypes = {
  account: object.isRequired,
  changeEmailRequest: func.isRequired,
  checkEmailChangeToken: func.isRequired,
  checkEmailToken: object.isRequired,
  updateAccountSettings: func.isRequired,
  user: object.isRequired,
};

export default connect(
  (state) => ({
    account: state.account,
    user: state.user,
    checkEmailToken: state.requests.checkEmailToken,
  }),
  {
    updateAccountSettings: updateAccount,
    checkEmailChangeToken,
    changeEmailRequest: emailAddressChangeRequest,
  },
)(Profile);
