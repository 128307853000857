import moment from 'moment-timezone';
import { DISK_ADDON_BASE_PRODUCT_ID } from '../constants';

export const getInitialDates = (earliestDate, defaultDaysSpread) => {
  const end = moment().tz('UTC');
  let start = moment().tz('UTC').subtract(defaultDaysSpread, 'days');

  if (earliestDate && start.isBefore(earliestDate)) {
    start = moment(earliestDate).tz('UTC').subtract(defaultDaysSpread, 'days');
  }

  return [start, end];
};

export const getFirstAndLastDayIndexes = (array) => {
  // data may not have been aggregated
  const lastIndex = array.length - 1;
  return [0, 1, 2, 3, lastIndex, lastIndex - 1, lastIndex - 2, lastIndex - 3];
};

export const getTotalDiskAddonsSize = (addons) => {
  return addons.reduce((acc, addon) => {
    if (addon.details.baseProductId === DISK_ADDON_BASE_PRODUCT_ID) {
      return acc + (addon.details.quantity * addon.quantity);
    }
    return acc;
  }, 0);
};

export const getTotalAddonsSize = (addons, baseProductId) => {
  return addons.reduce((acc, addon) => {
    if (addon.details.baseProductId === baseProductId) {
      return acc + (addon.details.quantity * addon.quantity);
    }
    return acc;
  }, 0);
};

export const dimensionsLoaded = (dimensions, accountId) => {
  if (dimensions.data?.dimensions?.serverName?.length > 0
    && dimensions.params?.accountId === accountId
    && dimensions.status === 'success'
  ) {
    return true;
  }
};

export const canGetUsageFromDimensions = (dimensions, usage, accountId) => {
  if (dimensionsLoaded(dimensions, accountId)
    && (usage.status === 'pristine' || (usage.status === 'success' && usage.params?.accountId !== accountId))
  ) {
    return true;
  }
};
