import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  setCertDomainLink,
  resetCertDomainLink,
} from 'shared/modules/ssl/redux/certDomainLink/actions';

const CertDomainLoader = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const { cert, setLink, match: { params: { certID, appID, aliasID } } } = props;

    const certDetails = cert.domains.find((domain) => {
      return (
        domain.certId === parseInt(certID, 10)
        && domain.appId === parseInt(appID, 10)
        && domain.aliasId === parseInt(aliasID, 10)
      );
    });

    if (certDetails) {
      setLink(certDetails);
      setLoaded(true);
    }
  }, []);

  const {
    children,
  } = props;

  return (
    loaded
      ? children : null
  );
};

export default connect(
  (state:any) => {
    return {
      certDomainLink: state.certDomainLink,
      cert: state.cert,
    };
  },
  {
    resetLink: resetCertDomainLink,
    setLink: setCertDomainLink,
  },
)(CertDomainLoader);
