import qs from 'qs';
import { getHttp } from 'shared/http';
import makeApiAction from 'shared/utils/redux/apiActions';

export const CERT_DOMAIN_LINK_SET = 'CERT_DOMAIN_LINK_SET';

export const CERT_DOMAIN_LINK_UPDATE_REQUEST = 'CERT_DOMAIN_LINK_UPDATE_REQUEST';
export const CERT_DOMAIN_LINK_UPDATE_SUCCESS = 'CERT_DOMAIN_LINK_UPDATE_SUCCESS';
export const CERT_DOMAIN_LINK_UPDATE_FAILURE = 'CERT_DOMAIN_LINK_UPDATE_FAILURE';

export const CERT_DOMAIN_ACTIVATE_REQUEST = 'CERT_DOMAIN_ACTIVATE_REQUEST';
export const CERT_DOMAIN_ACTIVATE_SUCCESS = 'CERT_DOMAIN_ACTIVATE_SUCCESS';
export const CERT_DOMAIN_ACTIVATE_FAILURE = 'CERT_DOMAIN_ACTIVATE_FAILURE';

export const CERT_DOMAIN_DEACTIVATE_REQUEST = 'CERT_DOMAIN_DEACTIVATE_REQUEST';
export const CERT_DOMAIN_DEACTIVATE_SUCCESS = 'CERT_DOMAIN_DEACTIVATE_SUCCESS';
export const CERT_DOMAIN_DEACTIVATE_FAILURE = 'CERT_DOMAIN_DEACTIVATE_FAILURE';

export const CERT_APP_ALIASES_REQUEST = 'CERT_APP_ALIASES_REQUEST';
export const CERT_APP_ALIASES_SUCCESS = 'CERT_APP_ALIASES_SUCCESS';
export const CERT_APP_ALIASES_FAILURE = 'CERT_APP_ALIASES_FAILURE';

export const CERT_DOMAIN_ADD_REQUEST = 'CERT_DOMAIN_ADD_REQUEST';
export const CERT_DOMAIN_ADD_SUCCESS = 'CERT_DOMAIN_ADD_SUCCESS';
export const CERT_DOMAIN_ADD_FAILURE = 'CERT_DOMAIN_ADD_FAILURE';

export const CERT_LINKED_SEARCH_REQUEST = 'CERT_LINKED_SEARCH_REQUEST';
export const CERT_LINKED_SEARCH_SUCCESS = 'CERT_LINKED_SEARCH_SUCCESS';
export const CERT_LINKED_SEARCH_FAILURE = 'CERT_LINKED_SEARCH_FAILURE';

export const CERT_DOMAIN_REVERT_REQUEST = 'CERT_DOMAIN_REVERT_REQUEST';
export const CERT_DOMAIN_REVERT_SUCCESS = 'CERT_DOMAIN_REVERT_SUCCESS';
export const CERT_DOMAIN_REVERT_FAILURE = 'CERT_DOMAIN_REVERT_FAILURE';

export const CERT_DOMAIN_LINK_RESET = 'CERT_DOMAIN_LINK_RESET';
export const CERT_DOMAIN_RESET = 'CERT_DOMAIN_RESET';

export const setCertDomainLink = (certDomainLinkObj) => ({
  type: CERT_DOMAIN_LINK_SET,
  data: certDomainLinkObj,
});

export const updateCertDomainLink = ({ returnPromise, ...values }) => {
  if (typeof values.hstsTimeout === 'string') {
    values.hstsTimeout = parseInt(values.hstsTimeout, 10); // eslint-disable-line
  }

  return makeApiAction(
    ({ api }) => api().patch('/ssl/link', {
      ...values,
    }),
    CERT_DOMAIN_LINK_UPDATE_REQUEST,
    CERT_DOMAIN_LINK_UPDATE_SUCCESS,
    CERT_DOMAIN_LINK_UPDATE_FAILURE,
    {},
    returnPromise,
  );
};

export const resetCertDomainLink = () => ({
  type: CERT_DOMAIN_LINK_RESET,
});

export const activateDomainCert = (certId, appId, aliasId) => makeApiAction(
  ({ api }) => api().patch('/ssl/activate', {
    certId,
    appId,
    aliasId,
  }),
  CERT_DOMAIN_ACTIVATE_REQUEST,
  CERT_DOMAIN_ACTIVATE_SUCCESS,
  CERT_DOMAIN_ACTIVATE_FAILURE,
);

export const deactivateDomainCert = (certId, appId, aliasId) => makeApiAction(
  ({ api }) => api().patch('/ssl/deactivate', {
    certId,
    appId,
    aliasId,
  }),
  CERT_DOMAIN_DEACTIVATE_REQUEST,
  CERT_DOMAIN_DEACTIVATE_SUCCESS,
  CERT_DOMAIN_DEACTIVATE_FAILURE,
);

export const fetchDomainsForCert = (accountId, subjectData, altNames, params) => makeApiAction(
  ({ api }) => {
    // ensure unique search list
    let search = [subjectData.name, ...altNames].reduce((p, c) => {
      if (p.indexOf(c) < 0) p.push(c);
      return p;
    }, []);

    if (altNames.length > 100) {
      search = search.join(',');
    }

    return api().get('/apps/aliases', {
      params: {
        accountId,
        search,
      },
    });
  },
  CERT_APP_ALIASES_REQUEST,
  CERT_APP_ALIASES_SUCCESS,
  CERT_APP_ALIASES_FAILURE,
  { accountId, subject: subjectData.name, ...params },
  true,
);

export const linkCertToDomain = (certId, aliasId) => makeApiAction(
  ({ api }) => api().post('/ssl/link', {
    certId,
    aliasId,
    cipher: 'modern',
  }),
  CERT_DOMAIN_ADD_REQUEST,
  CERT_DOMAIN_ADD_SUCCESS,
  CERT_DOMAIN_ADD_FAILURE,
  {
    certId,
    aliasId,
  },
);

export const getLinkedCerts = (appIds, accountId) => makeApiAction(
  ({ api }) => {
    return api().get(`/ssl/linked/search?${qs.stringify({ appIds, accountId })}`);
  },
  CERT_LINKED_SEARCH_REQUEST,
  CERT_LINKED_SEARCH_SUCCESS,
  CERT_LINKED_SEARCH_FAILURE,
  { appIds },
);

export const revertDomainCert = (certId, domainId) => makeApiAction(
  ({ api }) => api().post(`/ssl/certs/${certId}/domains/${domainId}/revert`),
  CERT_DOMAIN_REVERT_REQUEST,
  CERT_DOMAIN_REVERT_SUCCESS,
  CERT_DOMAIN_REVERT_FAILURE,
);
