import React from 'react';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import MuiToggle from '@material-ui/core/Switch';

interface Props {
  onToggle?: (e: React.SyntheticEvent, t: boolean) => void;
  defaultToggled?: boolean;
  defaultChecked?: boolean;
  labelPosition?: FormControlLabelProps['labelPlacement'];
  toggled?: boolean;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  value?: string;
  id?: string;
}

const Toggle = ({
  onToggle, defaultToggled, labelPosition, toggled, ...props
}: Props): JSX.Element => {
  const specialProps: Record<string, any> = {};

  if (onToggle) {
    specialProps.onChange = (e) => onToggle(e, e.target.checked);
  }

  if (props.label) {
    return (
      <FormControlLabel
        {...props}
        labelPlacement={labelPosition}
        label={props.label}
        control={(
          <MuiToggle
            defaultChecked={defaultToggled || props.defaultChecked}
            {...specialProps}
            {...props}
            checked={props.checked || toggled}
            value={props.value}
            disabled={props.disabled}
          />
        )}
      />
    );
  } else {
    return (
      <MuiToggle
        defaultChecked={defaultToggled || props.defaultChecked}
        {...specialProps}
        {...props}
        checked={props.checked || toggled}
        value={props.value}
        disabled={props.disabled}
      />
    );
  }
};

export default Toggle;
