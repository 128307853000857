import * as THREE from 'three';
import Dot from './dot.png';
import { WaveOptions } from './types';
import * as C from './constants';

/**
 * The code that manages the animation for the waves.
 * @param {HTMLDivElement} container
 * @param {object} options
 * @param {number} options.interval
 * @param {number} options.separation
 * @param {number} options.amountX
 * @param {number} options.amountY
 * @param {number} options.mouseSpeedDivisor Slows speed of mouse tracking. Higher is slower.
 * @param {number} options.yOffset
 * @param {number} options.amplitude The height of the dots movement
 * @param {number} options.scaleX The size multiplier as the movement moves in the X direction
 * @param {number} options.scaleY The size multiplier as the movement moves in the Y direction
 * @returns () => void
 * @link https://github.com/pagely/pagely-com/blob/main/src/waves-animation.js
 */
export function wavesAnimation(container: HTMLDivElement, {
  fps = C.FPS,
  separation = C.SEPARATION,
  amountX = C.AMOUNTX,
  amountY = C.AMOUNTY,
  mouseSpeedDivisor = C.MOUSE_SPEED_DIVISOR,
  yOffset = C.YOFFSET,
  amplitude = C.AMPLITUDE,
  scaleX = C.SCALE_X,
  scaleY = C.SCALE_Y,
}: WaveOptions): () => void {
  const clock = new THREE.Clock();
  const interval = 1 / fps;

  let delta = 0;

  let camera: THREE.PerspectiveCamera;
  let scene: THREE.Scene;
  let renderer: THREE.WebGLRenderer;

  let particles: THREE.Sprite[] = [];
  let particle: THREE.Sprite;
  let count = 0;

  let mouseX = -300;

  let windowHalfX = window.innerWidth / 2;

  let containerWidth = 0;
  let containerHeight = 0;

  function init() {
    containerWidth = container.offsetWidth;
    containerHeight = container.offsetHeight;

    camera = new THREE.PerspectiveCamera(75, containerWidth / containerHeight, 1, 10000);
    camera.position.z = 1000;

    scene = new THREE.Scene();

    const map = new THREE.TextureLoader().load(Dot);
    const material = new THREE.SpriteMaterial({ map });
    const sprite = new THREE.Sprite(material);

    particles = [];

    let i = 0;
    for (let ix = 0; ix < amountX; ix++) {
      for (let iy = 0; iy < amountY; iy++) {
        particle = sprite.clone();
        particle.position.x = ix * separation - ((amountX * separation) / 2);
        particle.position.z = iy * separation - ((amountY * separation) / 2);
        particles[i++] = particle;
        scene.add(particle);
      }
    }

    renderer = new THREE.WebGLRenderer({
      alpha: true,
      powerPreference: 'high-performance',
    });

    renderer.setClearColor(0xffffff, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(containerWidth, containerHeight);

    container.appendChild(renderer.domElement);

    document.addEventListener('mousemove', onDocumentMouseMove, false);
    document.addEventListener('touchstart', onDocumentTouchStart, false);
    document.addEventListener('touchmove', onDocumentTouchMove, false);

    window.addEventListener('resize', onWindowResize, false);
  }

  function onWindowResize() {
    containerWidth = container.offsetWidth;
    containerHeight = container.offsetHeight;

    windowHalfX = containerWidth / 2;

    camera.aspect = containerWidth / containerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(containerWidth, containerHeight);
  }

  function onDocumentMouseMove(event) {
    mouseX = (event.clientX / mouseSpeedDivisor) - windowHalfX;
  }

  function onDocumentTouchStart(event) {
    if (event.touches.length === 1) {
      event.preventDefault();

      mouseX = (event.touches[0].pageX / mouseSpeedDivisor) - windowHalfX;
    }
  }

  function onDocumentTouchMove(event) {
    if (event.touches.length === 1) {
      event.preventDefault();

      mouseX = (event.touches[0].pageX / mouseSpeedDivisor) - windowHalfX;
    }
  }

  function animate() {
    requestAnimationFrame(animate);

    // Lock framerate.
    delta += clock.getDelta();
    if (delta > interval) {
      render();
      delta %= interval;
    }
  }

  function render() {
    camera.position.x += (-mouseX - camera.position.x) * 0.05;
    camera.position.y += (-yOffset - camera.position.y) * 0.05;
    camera.lookAt(scene.position);

    let i = 0;

    for (let ix = 0; ix < amountX; ix++) {
      for (let iy = 0; iy < amountY; iy++) {
        particle = particles[i++];
        particle.position.y = (Math.sin((ix + count) * 0.3) * amplitude) + (Math.sin((iy + count) * 0.5) * amplitude);
        particle.scale.x = (Math.sin((ix + count) * 0.3) + 2) * scaleX + (Math.sin((iy + count) * 0.5) + 2) * scaleY;
        particle.scale.y = particle.scale.x;
      }
    }

    renderer.render(scene, camera);

    count += 0.1;
  }

  if (container !== null) {
    init();
    animate();
  }

  return () => {
    particles.forEach((p) => p.parent?.remove(p));
    scene.clear();
    renderer.dispose();
    document.removeEventListener('mousemove', onDocumentMouseMove);
    document.removeEventListener('touchstart', onDocumentTouchStart);
    document.removeEventListener('touchmove', onDocumentTouchMove);
    window.removeEventListener('resize', onWindowResize);

    container.removeChild(renderer.domElement);
  };
}
