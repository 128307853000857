import React, { Fragment } from 'react';
import { object } from 'prop-types';

import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

const Status = ({ values, form }) => {
  return (
    <Fragment>
      <Box direction="row" align="center" flex={1} gap="small" justify="flex-start">
        <Box css={{ flexBasis: '12%' }}>
          <Box padding="xsmall">
            <Typography variant="body1">
              <strong>Redirect Code</strong>
            </Typography>
          </Box>
        </Box>
        <Box css={{ flexBasis: '88%' }}>

          <RadioGroup
            value={values.enabled ? values.statusCode.toString() : 'disabled'}
            onChange={(event) => form.changeStatus(event.target.value)}
            disabled={form.status === 'loading'}
            row
          >
            <FormControlLabel labelPlacement="end" value="301" control={<Radio />} label="301 Redirect" />
            <FormControlLabel labelPlacement="end" value="302" control={<Radio />} label="302 Redirect" />
            <FormControlLabel labelPlacement="end" value="disabled" control={<Radio />} label="Disabled" />
          </RadioGroup>
        </Box>
      </Box>
      {/* uncomment when support doc is available
      <Box direction="row" align="center">
        <InfoIcon size="small" color="primary" css={{ marginRight: 4 }} />
        <Typography
          component={Link}
          target="_blank"
          rel="nofollow noopener noreferrer"
          href="link to forthcoming article"
        >
          What does this do?
        </Typography>
      </Box> */}
    </Fragment>
  );
};

Status.propTypes = {
  form: object,
  values: object,
};

export default Status;
