/* eslint-disable no-nested-ternary */
import React from 'react';
import { object } from 'prop-types';
import {
  Typography, Card, CardContent, CardActions, CardMedia,
} from '@material-ui/core';
import { isString } from 'shared/utils/types';

import { truncate } from 'shared/utils';
import Box from 'shared/styleguide/atoms/Box';
import CircleLink from 'shared/styleguide/atoms/Links/CircleLink';

const Article = ({ article, articleProps }) => {
  if (article.type === 'placeholder') {
    return <Box flex={1} />;
  }

  let fallbackSnippet = article.snippet;

  if (!article.snippet) {
    fallbackSnippet = isString(article.body) ? (truncate(article.body?.replace(/<[^>]+>/g, ''), 200) || '') : article.body;
  }

  const link = article.htmlUrl || article.url;

  return (
    <Box
      as={Card}
      flex={1}
      justify="space-between"
      style={{
        ...articleProps?.style,
      }}
    >
      <CardContent>
        <Box margin={{ bottom: 'xsmall' }}>
          <Typography variant="h5">
            <strong>{article.title}</strong>
          </Typography>
          {
            article.date
              ? (
                <Box margin={{ top: 'xxsmall' }}>
                  <Typography variant="caption" color="textSecondary">{article.date.format('LL')}</Typography>
                </Box>
              ) : null
          }
        </Box>
        {
          article.img ? (
            <CardMedia>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <img style={{ width: '100%' }} src={article.img[1] || article.img} alt="" />
              </a>
            </CardMedia>
          ) : null
        }
        <Box>
          {
            article.snippet
              ? <Typography variant="body2" color="textSecondary">{article.snippet}</Typography>
              : (
                isString(fallbackSnippet) ? (
                  <Typography variant="body2" color="textSecondary">{fallbackSnippet}</Typography>
                ) : (
                  fallbackSnippet
                )
              )
          }
        </Box>
      </CardContent>
      <Box as={CardActions} direction="row" justify="flex-end">
        <CircleLink label="Read more" href={link} />
      </Box>
    </Box>
  );
};

Article.propTypes = {
  article: object,
  articleProps: object,
};

export default Article;
