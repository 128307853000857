import React, { Fragment, useMemo } from 'react';
import { array, bool, string } from 'prop-types';
import { useTable, usePagination, useSortBy } from 'react-table';
import store2 from 'store2';

import Pagination from '@material-ui/lab/Pagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { TableContainer } from '@material-ui/core';
import Select from 'shared/styleguide/atoms/Select/Select';
import Box from 'shared/styleguide/atoms/Box';

const PageSize = ({ maxLength, onChange, namespaceKey }) => { //eslint-disable-line
  const options = [10, 20, 50, 100].filter((val) => val <= maxLength).map((pageLength) => ({ value: pageLength, label: `Show ${pageLength}` }));
  const defaultOption = store2.get(`pagination.${namespaceKey}.pageSize`, 10);
  const defaultOptionIndex = options.findIndex(({ value }) => defaultOption === value);

  return (
    <Select
      css={{
        position: 'absolute', right: 8, bottom: 8, width: 120,
      }}
      name="pagination-size"
      placeholder="Page Size"
      options={options}
      onChange={onChange}
      defaultValue={options[defaultOptionIndex]}
      isSearchable={false}
    />
  );
};

export const ReactTable = (props) => {
  const {
    data, columns, sort, paginate, memoDependecies = [], namespaceKey,
  } = props;
  const store = store2.namespace(`pagination.${namespaceKey}`);

  const memoizedRows = useMemo(() => data, memoDependecies); //eslint-disable-line
  const memoizedColumns = useMemo(() => columns, memoDependecies); //eslint-disable-line

  const tableProps = [];
  if (sort) {
    tableProps.push(useSortBy);
  }
  if (paginate) {
    tableProps.push(usePagination);
  }

  const initialPaginationState = store.getAll({ pageSize: 10, pageIndex: 0 });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // paginated rows,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedRows,
      initialState: initialPaginationState,
    },
    ...tableProps,
  );

  if (namespaceKey && store.getAll().pageIndex !== pageIndex) {
    store.set('pageIndex', pageIndex);
  }

  return (
    <>
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {
              headerGroups.map((headerGroup) => (
                <TableRow key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    let regularProps = column.getHeaderProps();
                    if (sort && column.type !== 'nosort') {
                      regularProps = column.getHeaderProps(column?.getSortByToggleProps());
                      regularProps.onClick = new Proxy(regularProps.onClick, {
                        apply(target, thisArg, args) {
                          // have to figure out next state of sorting
                          // null -> false -> true
                          let nextState = null;
                          switch (column.isSortedDesc) {
                            case null: nextState = false; break;
                            case false: nextState = true; break;
                            case true: nextState = null; break;
                            default: nextState = false; // it can be undefined, which we should treat as null
                          }
                          store.set('sortBy', [{ id: column.id, desc: nextState }]);
                          return Reflect.apply(target, thisArg, args);
                        },
                      });
                    }

                    return (
                      <TableCell
                        key={column.id}
                        {...column.props}
                        {...regularProps}
                      >
                        {column.render('Header')}
                        {sort && column.type !== 'nosort' ? (
                          <TableSortLabel
                            active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                          />
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            }
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {
              (page || rows).map((row) => {
                prepareRow(row);
                return (
                  <TableRow key={row.id} {...row.getRowProps()} hover>
                    {
                      row.cells.map((cell) => {
                        return (
                          <TableCell key={cell.id} {...cell.column.props} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        (paginate) && (
          <Box padding={{ top: 'small', bottom: 'xsmall' }} flex={1} direction="row" justify="center" css={{ position: 'relative', minHeight: 32 }} id="paginate-controls">
            {
              pageCount > 1 && (
                <Pagination
                  shape="rounded"
                  count={pageCount}
                  page={pageIndex + 1}
                  onChange={(event, page) => {
                    gotoPage(page - 1);
                    if (namespaceKey) {
                      store.set('pageIndex', page - 1);
                    }
                  }}
                />
              )
            }
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            {
              // check that there are more rows than min page size
              memoizedRows.length > 10 && (
                <PageSize
                  maxLength={memoizedRows.length}
                  onChange={(e) => {
                    setPageSize(Number(e.value));
                    if (namespaceKey) {
                      store.set('pageSize', Number(e.value));
                    }
                  }}
                  namespaceKey={namespaceKey}
                />
              )
            }
          </Box>
        )
      }
    </>
  );
};

ReactTable.propTypes = {
  columns: array.isRequired,
  data: array.isRequired,
  memoDependecies: array,
  namespaceKey: string,
  paginate: bool,
  sort: bool,
};

export default ReactTable;
