import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import {
  ACTIVE, ACTIVE_MODIFYING, PENDING, ACTION_REQUIRED,
} from 'shared/modules/app/routes/FSA/constants';
import { LOADING, SUCCESS } from 'shared/utils/redux/constants';
import ConfirmAction from 'shared/styleguide/molecules/ConfirmAction';
import Box from 'shared/styleguide/atoms/Box';
import { hasChanges } from './utils';

const SaveButton = ({
  saveChanges, domainsToModify, modifyDomains, fsaStatus,
}) => {
  const domainsToDelete = domainsToModify?.filter((d) => d.delete).map((d) => d.fqdn) || [];
  const domainsToCreate = domainsToModify?.filter((d) => !d.delete && d.tempId && d.fqdn).map((d) => d.fqdn) || [];

  const isFsa = [PENDING, ACTIVE, ACTIVE_MODIFYING, ACTION_REQUIRED].includes(fsaStatus);

  const fsaMessage = (
    <div>
      <Typography variant="body2">
        Mercury Dynamic Site Acceleration configuration will be updated.
        {
          domainsToCreate.length > 0
          && ' Newly created domains will not be accessible until modifications are complete.'
        }
      </Typography>
      {
        fsaStatus === PENDING && (
          <Typography variant="body2">
            Mercury setup is in progress. Applying domain modifications will cause Mercury setup to restart from the beginning with the newly updated domains.
          </Typography>
        )
      }
    </div>
  );

  return (
    <ConfirmAction
      onConfirm={() => saveChanges()}
      secondaryText={(
        <Fragment>
          <Box direction="column" gap="xsmall">
            {
              domainsToCreate.length > 0
              && (
                <div>
                  <Typography color="textSecondary">
                    The following domains will be <strong>created</strong>:
                  </Typography>
                  <Box margin={{ left: 'medium', bottom: 'xsmall' }}>
                    <Typography color="textPrimary">
                      {domainsToCreate.join(', ')}
                    </Typography>
                  </Box>
                </div>
              )
            }
            {
              domainsToDelete.length > 0
              && (
                <div>
                  <Typography color="textSecondary">
                    The following domains will be <strong>deleted</strong> and will be inaccessible:
                  </Typography>
                  <Box margin={{ left: 'medium', bottom: 'xsmall' }}>
                    <Typography color="textPrimary">
                      {domainsToDelete.join(', ')}
                    </Typography>
                  </Box>
                </div>
              )
            }
            {
              isFsa && fsaMessage
            }
            <div>
              <Typography align="center" color="textPrimary">
                Are you sure you want to proceed?
              </Typography>
            </div>
          </Box>
        </Fragment>
      )}
      variant={hasChanges(domainsToModify) || modifyDomains.displayStatus === SUCCESS ? 'contained' : 'outlined'}
      disabled={!hasChanges(domainsToModify) || modifyDomains.displayStatus === LOADING}
      status={modifyDomains.displayStatus}
      color="secondary"
    >
      Save Changes
    </ConfirmAction>
  );
};

SaveButton.propTypes = {
  domainsToModify: PropTypes.array,
  fsaStatus: PropTypes.string,
  modifyDomains: PropTypes.object,
  saveChanges: PropTypes.func,
};

export default SaveButton;
