import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Route, Switch,
} from 'react-router-dom';
import { withStyles, Paper } from '@material-ui/core';
import Modal from 'shared/styleguide/molecules/Modal/Modal';
import {
  AddSecurity,
  CreateCSR,
  UploadCert,
  Import,
  LE,
} from './AddCertFlow';
import styles from './styles';

export const CertAddModal = (props) => {
  const [title, setTitle] = useState('Add Certificate');
  const [open, setOpen] = useState(false);

  useLayoutEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Paper>
      <Switch>
        <Route
          render={() => (
            <Modal
              title={title}
              onClose={() => props.history.replace(`/account/${props.match.params.accountID}/ssl`)}
              open={open}
              scroll="body"
              renderProp={() => (
                <AddSecurity
                  setTitle={() => { }}
                  history={props.history}
                  match={props.match}
                />
              )}
              overflow="visible"
            />
          )}
          path="/account/:accountID/ssl/add"
          exact
        />
        <Route
          render={() => (
            <CreateCSR
              setTitle={() => { }}
              history={props.history}
              match={props.match}
            />
          )}
          path="/account/:accountID/ssl/add/csr"
        />
        <Route
          render={() => (
            <UploadCert
              setTitle={() => { }}
              history={props.history}
              match={props.match}
            />
          )}
          path="/account/:accountID/ssl/add/cert"
        />
        <Route
          render={() => (
            <Import
              setTitle={() => { }}
              history={props.history}
              match={props.match}
            />
          )}
          path="/account/:accountID/ssl/add/import"
        />
        <Route
          render={() => (
            <LE
              setTitle={() => { }}
              // closeModal={closeModal}
              history={props.history}
              match={props.match}
            />
          )}
          path="/account/:accountID/ssl/add/letsencrypt"
        />
      </Switch>
    </Paper>
  );
};

CertAddModal.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountID: PropTypes.string,
    }).isRequired,
  }),
};

export default withStyles(styles)(CertAddModal);
