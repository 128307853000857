import React, { Fragment, useState } from 'react';
import { shape, string } from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityIconOff from '@material-ui/icons/VisibilityOff';
import {
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';

import CopyButton from 'shared/styleguide/molecules/CopyButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import Box from 'shared/styleguide/atoms/Box';
import Card from 'shared/styleguide/atoms/Card/Card';
import { ErrorText } from 'shared/styleguide/typography';

import styles from 'shared/modules/app/components/App.scss';

import { inline } from './styles';

const CredentialsLogin = ({ credentials }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => { setPasswordVisible(!passwordVisible); };

  const loginData = [
    [
      'Username',
      <Box
        direction="row"
        align="center"
        key="username"
      >
        {
          credentials.status === 'success' && credentials.data?.username
          && (
            <Fragment>
              <TextField
                id="username"
                className={styles.credentialsInputs}
                autoComplete="off"
                margin="dense"
                fullWidth
                InputProps={inline.credentialsInputs}
                value={credentials.data.username}
              />
              <CopyButton
                tooltip="Click to copy username"
                text={credentials.data.username}
                message="Username copied to clipboard"
                fontSize="small"
              />
            </Fragment>
          )
        }
        {
          credentials.status === 'failed'
          && (
            <ErrorText>Failed to load username</ErrorText>
          )
        }
      </Box>,
    ],
    [
      'Password',

      <Box
        direction="row"
        align="center"
        key="password"
      >
        {
          credentials.status === 'success' && credentials.data?.password
          && (
            <Fragment>
              <TextField
                id="password"
                className={styles.credentialsInputs}
                autoComplete="off"
                margin="dense"
                fullWidth
                InputProps={{
                  ...inline.credentialsInputs,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Click to toggle password visibility"
                        placement="top"
                      >
                        <IconButton className={styles.credentialsVisibility} onClick={handlePasswordVisibility}>
                          {
                            passwordVisible
                              ? (
                                <VisibilityIconOff />
                              ) : (
                                <VisibilityIcon />
                              )
                          }
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                type={passwordVisible ? 'text' : 'password'}
                value={credentials.data?.password}
              />
              <CopyButton
                tooltip="Click to copy password"
                text={credentials.data?.password || ''}
                message="Password copied to clipboard"
                fontSize="small"
              />
            </Fragment>
          )
        }
        {
          (credentials.status === 'failed' || !credentials.data?.password)
          && (
            <ErrorText>Failed to load password</ErrorText>
          )
        }
      </Box>,
    ],
  ];

  return (
    <Card column className={styles.credentialsSections}>
      <Typography variant="h3">
        Logins & Access
      </Typography>
      <TwoColumn
        key="login-columns"
        data={loginData}
        className={styles.credentialsData}
      />
      <Typography variant="body1" paragraph>
        Each app has a unique username and password which is separate from your Atomic username and password.
      </Typography>
      <Typography variant="body1">
        If this app account is deleted or the app password is changed, the credentials shown here will no longer work. <br />
      </Typography>
    </Card>
  );
};

CredentialsLogin.propTypes = {
  credentials: shape({
    data: shape({
      username: string,
      password: string,
    }),
  }),
};

export default CredentialsLogin;
