import { useMergeState } from 'shared/hooks/useMergeState';
import { consolidateErrors } from 'shared/utils/validation';
import type { AccountUrl, RetrieveSsoUrls } from '../types/AccountUrl';

interface State {
  username: string;
  isFetching: boolean;
  submitted: boolean;
  error: string;
  ssoUrls: AccountUrl[];
}

interface Return {
  setUsername: (username: string) => void;
  state: State;
  searchUsername: (username?: string | null) => Promise<void>;
}

export const useSsoSelect = (retrieveSsoUrls: RetrieveSsoUrls): Return => {
  const { state, mergeState } = useMergeState<State>({
    username: '',
    isFetching: false,
    submitted: false,
    error: '',
    ssoUrls: [],
  });

  const searchUsername = async (username: null | string = null) => {
    mergeState({
      error: '',
      isFetching: true,
      ssoUrls: [],
    });

    const uname = username ?? state.username;
    if (uname) {
      try {
        const { data } = await retrieveSsoUrls(uname);
        mergeState({
          ssoUrls: data,
          isFetching: false,
          submitted: true,
          error: '',
        });
      } catch (err) {
        mergeState({
          error: consolidateErrors(err).replace(/^username: /, ''),
          isFetching: false,
          submitted: false,
        });
      }
    }
  };

  const setUsername = (username: string) => mergeState({ username, isFetching: false, submitted: false });

  return {
    setUsername,
    state,
    searchUsername,
  };
};
