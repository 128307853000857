import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import Box from 'shared/styleguide/atoms/Box';

import Profile from './Profile';
import CancelAccount from './CancelAccount';
import DisableAccount from './CancelAccount/DisableAccount';

const ProfilePage = ({ user, account }) => {
  const isAdmin = user.user.adminAccount;

  return (
    <Box direction="column" gap="medium">
      <Profile />
      {
        isAdmin && (
          <DisableAccount />
        )
      }
      <CancelAccount account={account} isAdmin={isAdmin} user={user} />
    </Box>
  );
};

ProfilePage.propTypes = {
  account: object.isRequired,
  user: object.isRequired,
};

export default connect(
  ({ user, account }) => ({ user, account }),
  null,
)(ProfilePage);
