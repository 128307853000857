import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import tracker from 'shared/3rdparty/pageTracking';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import {
  fetchApp,
  resetApp,
  deleteApp,
  disableApp,
  enableApp,
  getSqlInfo,
  enableSFTP,
  resetAppOptions,
  flushAliasCache,
  updateAppConfig,
  fetchTagsForApp,
} from 'shared/modules/app/redux/actions';

import {
  set301,
  setActive,
  moveAppDomain,
  fetchDomainsForApp,
  promoteDomainToPrimary,
  removeAppDomain,
} from 'shared/modules/app/redux/domains/actions';
import {
  resetBackup,
  resetBackups,
  getAllBackups,
} from 'shared/modules/app/redux/backups/actions';
import AdminContext from 'shared/modules/permissions/context/AdminContext';

import type { ReduxStore } from 'shared/utils/redux/apiReducer';
import type { App, Domain } from 'shared/types/App';
import { FeatureToggle, FLAGS } from 'shared/flags';
import Box from 'shared/styleguide/atoms/Box';
import { getLinkedCerts, updateCertDomainLink } from 'shared/modules/ssl/redux/certDomainLink/actions';
import { getServerInfo } from 'shared/modules/server/redux/actions';
import { getDNSfromAppId, resetDNSRecords } from 'shared/modules/dns/redux/actions';
import TagManager from 'shared/modules/app/routes/TagManager';
import { getTagsList } from 'shared/modules/account/redux/selectors';
import { FSA_OPTIN } from 'shared/modules/metadata/constants';
import { NumberLike } from 'shared/types/types';
import { remMapper } from 'shared/styleguide/theme/spacing';
import { Account } from 'shared/modules/account/types';
import AdminActions from '../Actions/AdminActions';
import CustomerActions from '../Actions/CustomerActions';
import FSAOverview from '../FSA';
import AppInfo from './AppInfo';

interface OverviewProps {
  account: Account;
  accountTags: string[];
  app?: ReduxStore<App>;
  appDomains?: ReduxStore<Domain[]>;
  appMeta?: Record<string, any>;
  fetchApp?(appId: NumberLike, additionalParams: Record<string, any>): Promise<App>;
  getServerMeta?(accountId: string): void;
  isAdmin?: boolean;
  match?: {
    params?: {
      appID?: string;
      accountID?: string;
    };
  };
  parentPath?: string;
  promoteDomainToPrimary?(): void;
  removeAppDomain?(): void;
  resetApp?(): void;
  set301?(): void;
  setActive?(): void;
}

export class Overview extends Component<OverviewProps, Record<string, never>> {
  componentDidMount() {
    const accountId = this.props.match.params.accountID;
    this.props.getServerMeta(accountId);
    if (this.props.app.data.id) {
      tracker.push({
        appId: this.props.app.data.id,
      });
    }
  }

  render() {
    const {
      app, account, accountTags, appMeta,
    } = this.props;

    const { isOnP20Account } = account;
    const fsaDataAvailable = app?.data?.fsa;
    const stagingConfig = appMeta?.data.find((tag) => tag.keyId === 'app:staging-config');

    return (
      <div css={{
        display: 'grid',
        ...(!fsaDataAvailable && { gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))' }),
        gap: remMapper('mediumLarge'),
      }}
      >
        <ErrorBoundary>
          {
            account?.servers.length > 0 && (
              <AppInfo app={app} account={account} />
            )
          }
        </ErrorBoundary>
        <div>
          <ErrorBoundary>
            <AdminContext.Consumer>
              {
                (isAdmin) => (
                  <FeatureToggle
                    flags={[(FLAGS.FSA_MANUAL || isAdmin || accountTags.includes(FSA_OPTIN)), stagingConfig?.value?.type !== 'staging']}
                  >
                    {/* @ts-ignore */}
                    <FSAOverview fsaRecord={app.fsa} />
                  </FeatureToggle>
                )
              }
            </AdminContext.Consumer>
          </ErrorBoundary>
          <div data-testid="app-advanced-options">
            <ErrorBoundary>
              <AdminContext.Consumer>
                {
                  (isAdmin) => (
                    <Box margin={{ top: 'mediumLarge' }}>
                      <Typography variant="h3" gutterBottom>Tools</Typography>
                      <CustomerActions
                        isOnP20Account={isOnP20Account}
                        isAdmin={isAdmin}
                        app={app}
                        accountId={account.id}
                      />
                      {
                        isAdmin && (
                          <Fragment>
                            {/* @ts-ignore */}
                            <AdminActions isAdmin={isAdmin} app={app} isOnP20Account={isOnP20Account} />
                            <TagManager />
                          </Fragment>
                        )
                      }
                    </Box>
                  )
                }
              </AdminContext.Consumer>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  account, app, backups, ...state
}) => ({
  account,
  accountTags: getTagsList(state),
  app: app.app,
  apps: state.apps,
  appMeta: app.metadata,
  appDomains: app.appDomains,
  dns: state.dns.dnsRecords,
});

const mapDispatchToProps = {
  flushCache: flushAliasCache,
  fetchApp,
  fetchDomainsForApp,
  getDNSfromAppId,
  resetApp,
  resetDNSRecords,
  deleteApp,
  disableApp,
  enableApp,
  enableSFTP,
  promoteDomainToPrimary,
  removeAppDomain,
  updateAppConfig,
  getSqlInfo,
  fetchTagsForApp,
  set301,
  setActive,
  getAllBackups,
  resetBackup,
  resetBackups,
  resetAppOptions,
  getServerMeta: getServerInfo,
  moveAppDomain,
  getLinkedCerts,
  updateCertDomainLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
