import * as METADATA from 'shared/modules/metadata/redux/constants';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import apiReducer, {
  baseCollectionState,
  baseState,
  failMethod,
  successMethod,
  load,
  success,
  fail,
} from 'shared/utils/redux/apiReducer';

import * as APP from './constants';
import * as FSA from './fsa/constants';

const appFetch = apiReducer(
  APP.APP_FETCH_REQUEST,
  APP.APP_FETCH_SUCCESS,
  APP.APP_FETCH_FAILURE,
  APP.APP_RESET,
  baseState,
);

const appCreate = apiReducer(
  APP.APP_CREATE_REQUEST,
  APP.APP_CREATE_SUCCESS,
  APP.APP_CREATE_FAILURE,
  APP.APP_RESET,
);

export const appTags = (state = baseCollectionState, action) => {
  switch (action.type) {
    case APP.APP_TAGS_FETCH_REQUEST:
      return {
        ...load(state, action),
      };
    case APP.APP_TAGS_FETCH_FAILURE:
      return {
        ...fail(state, action),
      };
    case APP.APP_TAGS_FETCH_SUCCESS:
      return {
        ...success(state, action),
      };

    case METADATA.METADATA_REMOVE_TAG_SUCCESS:
      return {
        ...state,
        data: state.data.filter((tag) => tag.tagId !== action.data.tagId),
      };

    case METADATA.METADATA_ADD_TAG_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.data],
      };

    case APP.APP_RESET:
      return { ...baseCollectionState };

    case METADATA.METADATA_ADD_TAG_REQUEST:
    case METADATA.METADATA_ADD_TAG_FAILURE:
    case METADATA.METADATA_REMOVE_TAG_REQUEST:
    case METADATA.METADATA_REMOVE_TAG_FAILURE:
    default: return state;
  }
};

export const metadata = apiReducer(
  METADATA.GET_APP_META_REQUEST,
  METADATA.GET_APP_META_SUCCESS,
  METADATA.GET_APP_META_FAILURE,
  APP.APP_RESET,
  {
    ...baseCollectionState,
  },
);

const appCredentials = apiReducer(
  APP.APP_CREDENTIALS_FETCH_REQUEST,
  APP.APP_CREDENTIALS_FETCH_SUCCESS,
  APP.APP_CREDENTIALS_FETCH_FAILURE,
  APP.APP_RESET,
  baseState,
);

const appFtpCredentials = apiReducer(
  APP.APP_FTP_CREDENTIALS_FETCH_REQUEST,
  APP.APP_FTP_CREDENTIALS_FETCH_SUCCESS,
  APP.APP_FTP_CREDENTIALS_FETCH_FAILURE,
  APP.APP_RESET,
  baseState,
);

export const app = (state = baseState, action) => {
  switch (action.type) {
    case APP.APP_CREATE_REQUEST:
    case APP.APP_CREATE_SUCCESS:
    case APP.APP_CREATE_FAILURE:
      return appCreate(state, action);

    case LOGIN_LOGOUT_SUCCESS:
      return baseState;

    case APP.APP_UPDATE_CONFIG_REQUEST:
    case APP.APP_DISABLE_SFTP_REQUEST:
    case APP.APP_ENABLE_SFTP_REQUEST:
    case APP.APP_DELETE_REQUEST:
    case APP.APP_DISABLE_REQUEST:
    case APP.APP_ENABLE_REQUEST:
      return { ...state, doing: true };

    case APP.UPDATE_APP_REQUEST:
      return {
        ...load(state, action),
      };

    case APP.UPDATE_APP_SUCCESS:
      return {
        ...success(state, action),
      };

    case APP.APP_DELETE_SUCCESS:
      return {
        ...state,
        doing: false,
        data: action.data,
        deleted: true,
      };

    case APP.APP_DISABLE_SUCCESS:
    case APP.APP_ENABLE_SUCCESS:
      return {
        ...state,
        doing: false,
        data: {
          ...state.data,
          active: action.data.active,
        },
      };

    case APP.APP_ENABLE_SFTP_SUCCESS:
      return {
        ...state,
        doing: false,
        data: {
          ...state.data,
          ftpEnabled: action.params.ftpEnabled,
          ftpCredentials: appFtpCredentials(state.ftpCredentials, action),
        },
      };
    case APP.APP_DISABLE_SFTP_SUCCESS:
      return {
        ...state,
        doing: false,
        data: {
          ...state.data,
          ftpEnabled: action.params.ftpEnabled,
          ftpCredentials: {},
        },
      };

    case APP.APP_UPDATE_CONFIG_SUCCESS:
      return {
        ...successMethod(state, action), // this is to preserve any props we manually added on
        data: {
          ...state.data,
          config: successMethod(state, action).data.config || state.data.config,
        },
      };

    case APP.APP_GET_SQL_INFO_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          sql: {
            success: null,
          },
        },
      };

    case APP.APP_GET_SQL_INFO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          sql: {
            success: true,
            ...action.data,
          },
        },
      };

    case APP.APP_GET_SQL_INFO_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          sql: {
            success: false,
            ...action.data,
          },
        },
      };

    case APP.APP_CREDENTIALS_FETCH_REQUEST:
    case APP.APP_CREDENTIALS_FETCH_SUCCESS:
    case APP.APP_CREDENTIALS_FETCH_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          credentials: appCredentials(state.credentials, action),
        },
      };

    case APP.APP_FTP_CREDENTIALS_FETCH_REQUEST:
    case APP.APP_FTP_CREDENTIALS_FETCH_SUCCESS:
    case APP.APP_FTP_CREDENTIALS_FETCH_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          ftpCredentials: appFtpCredentials(state.ftpCredentials, action),
        },
      };
    case APP.APP_CREATE_MODAL_OPEN:
      return {
        ...state,
        createModalOpen: action.open,
      };

    case APP.UPDATE_APP_FAILURE:
    case APP.APP_UPDATE_CONFIG_FAILURE:
    case APP.APP_ENABLE_SFTP_FAILURE:
    case APP.APP_DISABLE_SFTP_FAILURE:
    case APP.APP_DELETE_FAILURE:
    case APP.APP_DISABLE_FAILURE:
    case APP.APP_ENABLE_FAILURE:
      return {
        ...failMethod(state, action),
        errMessage: action.errMessage,
      };

    case APP.APP_DOMAIN_PROMOTE_SUCCESS:
      if (action.params.autoNameApp) {
        return {
          ...state,
          data: {
            ...state.data,
            name: action.params.domain,
          },
        };
      }
      return state;
    case FSA.GET_FSA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          fsa: {
            ...action.data,
          },
        },
      };
    default:
      return appFetch(state, action);
  }
};
