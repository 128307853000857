import React, { Fragment, useState } from 'react';
import { object, func } from 'prop-types';

import {
  Paper, Typography, OutlinedInput,
} from '@material-ui/core';

// styleguide
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { useMergeState } from 'shared/hooks/useMergeState';
import CountrySelector from 'shared/styleguide/molecules/Dropdowns/Country';
import StateSelector from 'shared/styleguide/molecules/Dropdowns/States';
import logger from 'shared/3rdparty/logger';
import { flattenValidation } from 'shared/utils/validation';
import InfoText from 'shared/styleguide/molecules/InfoText';

const View = ({ customer, setView }) => {
  const {
    firstName, lastName, companyName,
  } = customer;

  const hasAddress = [
    customer.address1,
    customer.city,
    customer.zip,
  ].some(Boolean);

  return (
    <Fragment>
      <InfoText>
        This is the name and address that will appear on invoices.
      </InfoText>
      <Box margin={{ top: 'xsmall' }}>
        {
          hasAddress ? (
            <Fragment>
              <Typography>{customer.address1}</Typography>
              <Typography>{customer.address2}</Typography>
              <Typography>{customer.city}, {customer.state} {customer.zip}</Typography>
            </Fragment>
          ) : (
            <Typography variant="body2" color="textSecondary">No billing address on file.</Typography>
          )
        }

      </Box>
      <Box direction="row" flex={1} justify="flex-end">
        {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Button
          onClick={() => setView('edit')}
          variant="contained"
        >
          Update billing address
        </Button>
      </Box>
    </Fragment>
  );
};
View.propTypes = {
  customer: object,
  setView: func,
};

const Edit = ({ customer, setView, updateCustomer }) => {
  const [status, setStatus] = useState('initial');

  const { state, mergeState: setState } = useMergeState({
    address1: '',
    address2: '',
    city: '',
    country: 'US',
    state: '',
    zip: '',
    vat: '',
    ...customer,
    errors: {},
  });

  const onChange = (field, value) => {
    setState({ [field]: value });
  };

  const onSubmit = async () => {
    setStatus('loading');
    setState({ errors: {} });

    logger.client.leaveBreadcrumb('Update Chargebee Customer', {
      firstName: customer.firstName,
      lastName: customer.lastName,
      customerId: customer.id,
    });

    try {
      // update the account
      await updateCustomer(customer.id, {
        companyName: customer.companyName,
        firstName: customer.firstName,
        lastName: customer.lastName,
        address1: state.address1,
        address2: state.address2,
        city: state.city,
        country: state.country,
        state: state.state,
        zip: state.zip,
      });

      setStatus('success');

      setTimeout(() => {
        setStatus('initial');
        setView('view');
      }, 3000);
    } catch (err) {
      logger.error(err, { context: 'Error updating customer' });
      setState({ errors: flattenValidation(err.response.data.body) });
      setStatus('initial');
    }
  };

  return (
    <Box direction="column" gap="small">
      <Box direction="column" gap="small" flex={1}>
        <OutlinedInput
          fullWidth
          id="address1"
          value={state.address1}
          onChange={(e) => onChange('address1', e.target.value)}
          error={Boolean(state.errors?.address1)}
          helperText={state.errors?.address1 ?? ''}
          placeholder={state.address1 || 'Address'}
        />
        <OutlinedInput
          fullWidth
          id="address2"
          value={state.address2}
          onChange={(e) => onChange('address2', e.target.value)}
          placeholder={state.address2 || 'Address (Extra)'}
          error={Boolean(state.errors?.address2)}
          helperText={state.errors?.address2 ?? ''}
        />
        <OutlinedInput
          fullWidth
          id="city"
          value={state.city}
          onChange={(e) => onChange('city', e.target.value)}
          placeholder={state.city || 'City'}
          error={Boolean(state.errors?.city)}
          helperText={state.errors?.city ?? ''}
        />
        <Box direction="row" gap="small" flex={1}>
          <Box flex={1}>
            {
              state.country === 'US' ? (
                <StateSelector
                  onChange={((value) => onChange('state', value.value))}
                  value={state.state}
                  hasError={Boolean(state.errors?.state)}
                />
              ) : (
                <OutlinedInput
                  fullWidth
                  id="state"
                  value={state.state}
                  onChange={(e) => onChange('state', e.target.value)}
                  placeholder={state.state || 'State'}
                  error={Boolean(state.errors?.email)}
                  helperText={state.errors?.email ?? ''}
                />
              )
            }
          </Box>
          <Box flex={1}>
            <OutlinedInput
              fullWidth
              id="zip"
              value={state.zip}
              onChange={(e) => onChange('zip', e.target.value)}
              placeholder={state.zip || 'Zip/Postal Code'}
              error={Boolean(state.errors?.zip)}
              helperText={state.errors?.zip ?? ''}
            />
          </Box>
        </Box>
        <Box direction="row" flex={1}>
          <Box flex={1}>
            <CountrySelector
              onChange={((value) => onChange('country', value.value))}
              value={state.country}
              hasError={Boolean(state.errors?.country)}
              placeholder={state.country || 'Country'}
            />
          </Box>
          <Box />
        </Box>
      </Box>
      <Box>
        <Typography>
          This is the name and addresss that will appear on invoices. Updating this address will not change the address associated with your payment.
        </Typography>
        {
          Object.keys(state.errors).length > 0 && (
            <Typography color="error">There was a problem updating your address</Typography>
          )
        }
      </Box>
      <Box direction="row" align="center" justify="space-between">
        <div>
          <Button
            status={status}
            color="default"
            variant="outlined"
            onClick={() => setView('view')}
            css={{ whiteSpace: 'nowrap' }}
          >
            Discard Changes
          </Button>
        </div>
        <div>
          <Button
            status={status}
            variant="contained"
            onClick={onSubmit}
            css={{ whiteSpace: 'nowrap' }}
          >
            Save Changes
          </Button>
        </div>
      </Box>
    </Box>
  );
};

Edit.propTypes = {
  customer: object,
  setView: func,
  updateCustomer: func,
};

export const InvoiceAddress = ({
  customer, updateCustomer,
}) => {
  const [view, setView] = useState('view');

  return (
    <Paper>
      <Box padding="medium">
        <Typography
          variant="h3"
          css={{
            marginBottom: '9px',
          }}
        >Billing Address
        </Typography>
        {
          view === 'view' && (
            <View customer={customer.data} setView={setView} />
          )
        }
        {
          view === 'edit' && (
            <Edit customer={customer.data} setView={setView} updateCustomer={updateCustomer} />
          )
        }
      </Box>
    </Paper>
  );
};

InvoiceAddress.propTypes = {
  customer: object,
  updateCustomer: func,
};

export default (InvoiceAddress);
