/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityIconOff from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import { css } from '@emotion/react';
import { Toggle } from 'shared/styleguide/atoms/Switches';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import AppsDropdown from 'shared/styleguide/organisms/AppsDropdown';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import Box from 'shared/styleguide/atoms/Box';
import Button, { ButtonStatus } from 'shared/styleguide/atoms/Buttons/NewButton';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import DestructiveAction from 'shared/styleguide/molecules/DestructiveAction';
import { ErrorText } from 'shared/styleguide/typography';
import { consolidateErrors } from 'shared/utils/validation';
import { UPLOAD } from 'shared/modules/integrations/constants';
import {
  dateOptions, formatDate,
} from 'shared/utils';
import { getAppsById } from 'shared/modules/app/redux/selectors';
import TextMask from 'shared/styleguide/atoms/TextMask';

import InfoText from 'shared/styleguide/molecules/InfoText';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import { updateIntegration, deleteIntegration, fetchDeployToken } from '../redux/actions';

const rightColStyles = css({
  flexBasis: 170,
});

const EditView = ({ setView, apps, ...props }) => {
  const [appIds, setAppIds] = useState(props.appIds);
  const [name, setName] = useState(props.name);
  const [active, setActive] = useState(Boolean(props.active));
  const [errors, setErrors] = useState('');
  const [clearCache, setClearCache] = useState(Boolean(props.config?.clearCache));
  const [status, setStatus] = useState('initial');

  const areAllSelected = appIds.length === apps.length;

  const handleSave = async () => {
    const hasChanges = (name !== props.name)
      || (appIds !== props.appIds)
      || (clearCache !== Boolean(props.config?.clearCache))
      || (active !== Boolean(props.active));

    setStatus('loading');
    try {
      // if there are no changes... just close the thing.
      if (hasChanges) {
        setErrors('');
        const data = {
          name,
          appIds,
          config: { clearCache },
          active,
        };

        await props.updateIntegration(props.id, data);
      }
      setView('info');
      setStatus('success');
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
  };

  const handleDelete = () => {
    props.deleteIntegration(props.id, { type: UPLOAD });
  };

  const data = [
    ['Integration ID', props.id],
    ['Integration Secret', props.token ?? 'token hidden for safety reasons'], // future feature
    ['Permissions', (
      <Box direction="row" gap="small" key="permissions" align="center">
        <Box flex={1}>
          <AppsDropdown
            apps={apps}
            values={appIds}
            onChange={(values) => setAppIds(values.map((v) => v.value))}
          />
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="default"
            onClick={() => {
              if (areAllSelected) {
                setAppIds([]);
              } else {
                setAppIds(apps.map((a) => a.id));
              }
            }}
          >
            {
              areAllSelected ? 'Unselect All' : 'Select All'
            }
          </Button>
        </Box>
      </Box>
    )],
    ['Clear Cache', (
      <Box key="clearCache" direction="row" align="center" justify="flex-start" gap="xxsmall">
        <Toggle
          key="toggle-clearCache"
          id="toggle-clearCache"
          defaultToggled={clearCache}
          onToggle={(e, toggle) => setClearCache(toggle)}
          // Switches/index.js needs to be convereted
        />
        <Typography variant="body2" color="textSecondary">Clear cache after deploy to ensure changes are visible immediately</Typography>
      </Box>
    )],
    ['Active', (
      <Box key="active">
        <Toggle
          key="toggle-active"
          id="toggle-active"
          defaultToggled={active}
          onToggle={(e, toggle) => setActive(toggle)}
        />
      </Box>
    )],
  ];
  return (
    <AccordionDetails>
      <Box flex={1}>
        <WideTextField
          id="name"
          autoComplete="off"
          placeholder="Name"
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          value={name}
          onChange={(e) => { setName(e.target.value); }}
        />
        <TwoColumn
          styles={{
            rightCol: rightColStyles,
            leftCol: {
              flex: '1',
            },
            table: {
              marginTop: 0,
            },
          }}
          data={data}
        />
        {
          errors && (
            <Box margin={{ top: 'small' }} align="center">
              <ErrorText>{errors}</ErrorText>
            </Box>
          )
        }
        <Box direction="row" justify="space-between" margin={{ top: 'small' }}>
          <DestructiveAction
            onConfirm={handleDelete}
            explainerText="Deleting this integration will cause any CI/CD pipelines using this integration to break."
            secondaryText="Please be sure to update any third party repository that may be using this integration before deleting it."
          >
            Delete Integration
          </DestructiveAction>
          <Button
            variant="contained"
            onClick={handleSave}
            status={status as ButtonStatus}
          >
            Save
          </Button>
        </Box>
      </Box>
    </AccordionDetails>
  );
};

const InfoView = ({
  appIds, directory, setView, token, ...props
}) => {
  const [tokenState, setTokenState] = useState('hidden');
  const appChips = appIds.map((appId) => {
    const label = directory[appId]?.name ?? 'unknown app';
    return (
      <GhostTag
        key={appId}
        label={`${label} - ${appId}`}
      />
    );
  });

  const handleGetToken = async () => {
    // set state to loading token
    if (!token) {
      setTokenState('loading');
      await props.fetchDeployToken(props.id);
    }
    setTokenState('visible');
  };

  const data = [
    ['Integration ID', (
      <Box direction="row" gap="xsmall" align="center" key="id">
        {props.id}
        <CopyButton
          tooltip="Click to copy Integration ID"
          text={props.id}
          message="Integration ID copied to clipboard"
          fontSize="small"
        />
      </Box>
    )],
    ['Integration Secret', (
      <Box direction="row" gap="xsmall" align="center" key="token">
        {
          (tokenState === 'hidden' || tokenState === 'loading') && (
            <TextMask />
          )
        }
        {
          token && tokenState === 'visible' && (
            <span>
              {token}
            </span>
          )
        }
        <span>
          {
            tokenState === 'hidden' && (
              <IconButton size="small" onClick={handleGetToken}>
                <VisibilityIcon color="inherit" />
              </IconButton>
            )
          }
          {
            tokenState === 'visible' && (
              <IconButton size="small" onClick={() => setTokenState('hidden')}>
                <VisibilityIconOff />
              </IconButton>
            )
          }
          {
            token && (
              <CopyButton
                tooltip="Click to copy token"
                text={token}
                message="Token copied to clipboard"
                fontSize="small"
              />
            )
          }
          {
            tokenState === 'loading' && (
              <CircularProgress variant="indeterminate" size={24} />
            )
          }
        </span>
      </Box>
    )], // future feature
    ['Permissions', (
      <Box key="permissions" gap="xxsmall" direction="row" wrap="wrap">
        {appChips}
      </Box>
    )],
    ['Clear Cache', `${Boolean(props.config?.clearCache)}`],
    ['Active', `${Boolean(props.active)}`],
  ];
  return (
    <AccordionDetails>
      <Box flex={1}>
        <TwoColumn
          data={data}
          styles={{
            rightCol: rightColStyles,
            leftCol: {
              flex: '1',
            },
            table: {
              marginTop: 0,
            },
          }}
        />

        <Box direction="row" justify="space-between" margin={{ top: 'small' }}>
          <InfoText
            href="https://support.pagely.com/hc/en-us/articles/360047765872"
          >
            How do I use my Integration Secret?
          </InfoText>
          <Button variant="outlined" onClick={() => setView('edit')}>Edit</Button>
        </Box>
      </Box>
    </AccordionDetails>
  );
};

interface ListItemProps {
  appIds: string[];
  apps: any[];
  created: string;
  directory: Record<string, any>;
  fetchDeployToken(): void;
  id: string;
  name?: string;
  updateIntegration(): void;
  token: string;
}

const ListItem = (props: ListItemProps) => {
  const {
    name, created,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [view, setView] = useState('info');

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        setView('info');
        setExpanded(!expanded);
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Typography component="div" variant="subtitle1">{
              name
                ? (
                  <span>
                    <Typography component="span" color="textSecondary">Name:&nbsp;</Typography>
                    {name}
                  </span>
                ) : (
                  `Integration created ${formatDate(created)}`
                )
            }
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            css={{
              textAlign: 'right',
            }}
          >
            {
              name && (
                <Typography variant="body2" color="textSecondary">
                  Created on {formatDate(created, dateOptions)}
                </Typography>
              )
            }
          </Grid>
        </Grid>
      </AccordionSummary>
      {
        view === 'info' && (
          <InfoView setView={setView} {...props} />
        )
      }
      {
        view === 'edit' && (
          <EditView setView={setView} {...props} />
        )
      }
    </Accordion>
  );
};

export default connect(
  (state) => {
    return ({
      directory: getAppsById(state),
      // @ts-ignore
      apps: state.apps.data,
    });
  },
  {
    updateIntegration,
    deleteIntegration,
    fetchDeployToken,
  },
)(ListItem);
