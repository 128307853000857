import React from 'react';
import { object } from 'prop-types';

import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import { dateOptions } from 'shared/utils';
import Volumes from './Volumes';
import ByApp from './ByApp';
import ByDir from './ByDir';

const FullReportSummary = ({ apps, report }) => {
  return (
    <div>
      <Box margin={{ bottom: 'small' }}>
        <Typography variant="caption" color="textSecondary">Report Generated on {new Date(report.meta.timestamp).toLocaleString([], dateOptions)}</Typography>
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Typography variant="h3" gutterBottom>Volumes</Typography>
        {
          report.summary.home && (

            <Volumes volumes={report.summary.volumes} />
          )
        }
      </Box>
      <Box margin={{ bottom: 'large' }}>
        <Typography variant="h3" gutterBottom>Apps</Typography>
        {
          report.summary.app && (
            <ByApp apps={apps} app={report.summary.app} />
          )
        }
      </Box>
      {
        report.summary.home && (
          <Box margin={{ bottom: 'large' }}>
            <Typography variant="h3" gutterBottom>Home Directories</Typography>
            <ByDir home={report.summary.home} />
          </Box>
        )
      }
    </div>
  );
};

FullReportSummary.propTypes = {
  apps: object.isRequired,
  report: object.isRequired,
};

export default FullReportSummary;
