import { format } from 'shared/modules/ssl/models/certificate';
import * as base from 'shared/baseStates';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import {
  CERTS_REQUEST,
  CERTS_SUCCESS,
  CERTS_FAILURE,
  CERTS_RESET,
} from 'shared/modules/ssl/redux/certs/actions';
import { CERT_REMOVE_SUCCESS, IMPORT_EXISTING_SUCCESS } from 'shared/modules/ssl/redux/cert/actions';
import {
  CERT_DOMAIN_ADD_SUCCESS,
  CERT_DOMAIN_REVERT_SUCCESS,
} from 'shared/modules/ssl/redux/certDomainLink/actions';
import {
  baseCollectionState, fail, load, success,
} from 'shared/utils/redux/apiReducer';

export const initialState = {
  ...base.collection(),
  ...baseCollectionState,
  accountId: null,
};

const prepCerts = (action) => {
  return [
    ...action.data.data.map(format),
  ];
};

export function certs(state = initialState, action) {
  switch (action.type) {
    case CERTS_REQUEST:
      return {
        ...state,
        ...base.request(),
        ...load(state, action),
        accountId: action.accountId,
      };
    case CERTS_FAILURE:
      // TODO: make sure we don't rely on requestErr and stsMessage before
      // removing the deprecated base.failure
      return {
        ...state,
        ...fail(state, action),
        ...base.failure(action.errMessage, action.statusCode, action.validation),
      };
    case CERTS_SUCCESS:
      return {
        ...state,
        ...base.success(),
        ...success(state, action),
        data: prepCerts(action),
        records: prepCerts(action),
        status: 'ready',
      };

    case CERT_REMOVE_SUCCESS:
      return {
        ...state,
        status: 'ready',
        data: state.data.filter((item) => item.id !== action.certId),
        records: state.records.filter((item) => item.id !== action.certId),
      };
    case CERTS_RESET:
    case LOGIN_LOGOUT_SUCCESS:
    case CERT_DOMAIN_ADD_SUCCESS:
    case IMPORT_EXISTING_SUCCESS:
    case CERT_DOMAIN_REVERT_SUCCESS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
