import React, { useCallback } from 'react';
import {
  func, object, string, number,
} from 'prop-types';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import { Formik, Form } from 'formik';

import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { deepClone } from 'shared/utils';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { remMapper } from 'shared/styleguide/theme/spacing';

import useForm from './hooks/useForm';
import { initialFormValues } from './constants';
import UrlParts from './formComponents/UrlParts';
import Queries from './formComponents/Queries';
import Description from './formComponents/Description';
import Status from './formComponents/Status';
import Summary from './formComponents/Summary';

const useStyles = makeStyles(() => {
  return ({
    root: {
      borderBottom: '1px solid #ccc',
    },
  });
});

const FormContent = ({
  domains, appId, redirect, onCreateRedirect, currentValues, mode, onSetShowCreate, initialQueryMode, formStory,
}) => {
  const classes = useStyles();

  const initialValues = JSON.parse(JSON.stringify(mode === 'edit' ? currentValues : initialFormValues));

  const domainsList = domains?.data?.map((domain) => domain.fqdn) || [];

  const unmemoizedForm = useForm(domainsList, appId, redirect, onCreateRedirect, mode, onSetShowCreate, initialValues, initialQueryMode);

  const memoizedForm = useCallback(
    unmemoizedForm,
    [domainsList, appId, redirect, onCreateRedirect, mode, onSetShowCreate, initialValues, initialQueryMode, unmemoizedForm],
  );

  const form = formStory || memoizedForm;

  if (form.deleted && !form.showUpdated) {
    return null;
  }

  return (
    <Formik
      initialStatus="pristine"
      initialValues={{
        ...initialValues,
      }}
      onSubmit={form.submit}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={form.formRef}
    >
      {({
        values,
      }) => (
        <Box
          as={Form}
          flex={1}
        >
          <Accordion
            className={classes.root}
            square
            expanded={form.expanded}
            TransitionProps={{ unmountOnExit: true }}
          >
            <Summary
              values={values}
              form={form}
            />
            <AccordionDetails>
              <Box
                css={css`
                  position: relative;
                `}
                flex={1}
              >
                {
                  !form.changed
                  && (

                    <Box direction="row" justify="flex-end">
                      <Tooltip title="Close" placement="top">
                        <IconButton
                          onClick={form.closeAdvanced}
                          size="small"
                          css={css`
                              position: absolute;
                              top: -${remMapper('xsmall')};
                              right: -${remMapper('xsmall')};
                            `}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )
                }
                <Box margin={{ top: 'xsmall', bottom: 'xsmall' }}>
                  <Status
                    values={values}
                    form={form}
                  />
                </Box>
                <Grid container spacing={2} alignItems="center" alignContent="center">
                  <Grid item xs={1} />
                  <Grid item xs={4}>
                    <Box margin={{ left: 'small' }}>
                      <Typography variant="body1" align="center">
                        <strong>From</strong>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={1} />
                  <Grid item xs={4}>
                    <Typography variant="body1" align="center">
                      <strong>To</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <UrlParts
                  values={values}
                  form={form}
                />
                <Queries
                  values={values}
                  form={form}
                />
                <Description
                  values={values}
                  form={form}
                />
                <Box direction="row" justify="space-between" margin={{ top: 'xsmall' }}>
                  <Button
                    variant="outlined"
                    onClick={form.cancel}
                    label={`${form.mode === 'create' ? 'Cancel' : 'Close'} Without Saving`}
                  />
                  <Button
                    variant={form.changed ? 'contained' : 'outlined'}
                    disabled={!form.changed}
                    status={form.status === 'error' ? 'initial' : form.status}
                    label="Save"
                    color="secondary"
                    onClick={form.submitForm}
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Formik>
  );
};

FormContent.propTypes = {
  appId: number,
  currentValues: object,
  domains: object,
  formStory: object,
  initialQueryMode: string,
  mode: string,
  onCreateRedirect: func,
  onSetShowCreate: func,
  redirect: object,
};

export default connect(
  (state) => ({
    domains: state.app.appDomains,
  }), {},
)(FormContent);
