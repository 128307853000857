/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';

import { Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';

import { string, func } from 'prop-types';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import scss from '../Editor.scss';

export default class SimpleRedirect extends Component {
  static propTypes = {
    appName: string,
    onClose: func,
  };

  state = {
    source: {
      scheme: 'keep',
      host: this.props.appName,
      path: '',
    },
    destination: {
      scheme: 'keep',
      host: '',
      path: '',
    },
    exitMode: '301',
    args: {},
  };

  handleSetState = (key, value) => {
    if (value instanceof Object) {
      this.setState((state) => ({ [key]: { ...state[key], ...value } }));
    } else {
      this.setState({ [key]: value });
    }
  };

  handleAdd = () => {
    const { source, destination } = this.state;
    // format the object necessary for the config
    const sourceKey = '';
    const configuration = {
      [sourceKey]: {
        path: destination.path,
        host: destination.host,
      },
    };
  };

  render() {
    const {
      source, destination, exitMode,
    } = this.state;
    return (
      <div>
        <div className={scss.rowSection}>
          <div className={scss.heading}>
            <Typography variant="h2">Source:</Typography>
          </div>
          <Grid
            container
            spacing={32}
            alignItems="center"
          >
            <Grid className={scss.leftColumn} item md={2}>
              <Typography variant="button">
                Scheme:
              </Typography>
            </Grid>
            <Grid item md={10}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Scheme"
                  name="scheme"
                  value={source.scheme}
                  onChange={(e, value) => { this.handleSetState('source', { scheme: value }); }}
                  row
                >
                  <FormControlLabel labelPlacement="end" value="http" control={<Radio />} label="http://" />
                  <FormControlLabel labelPlacement="end" value="https" control={<Radio />} label="https://" />
                  <FormControlLabel labelPlacement="end" value="keep" control={<Radio />} label="Either" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={32}
            alignItems="center"
          >
            <Grid className={scss.leftColumn} item md={2}>
              <Typography variant="button">
                Host:
              </Typography>
            </Grid>
            <Grid item md={10}>
              <TextField
                id="source-host"
                placeholder="Host"
                value={source.host}
                onChange={(e) => this.handleSetState('source', { host: e.target.value })}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={32}
            alignItems="center"
          >
            <Grid className={scss.leftColumn} item md={2}>
              <Typography variant="button">
                path:
              </Typography>
            </Grid>
            <Grid item md={10}>
              <TextField
                id="source-path"
                placeholder="Path"
                value={source.path}
                onChange={(e) => this.handleSetState('source', { path: e.target.value })}
                margin="normal"
              />
            </Grid>
          </Grid>
        </div>
        <div className={scss.rowSection}>
          <div className={scss.heading}>
            <Typography variant="h2">Destination:</Typography>
          </div>
          <Grid
            container
            spacing={32}
            alignItems="center"
          >
            <Grid className={scss.leftColumn} item md={2}>
              <Typography variant="button">
                Scheme:
              </Typography>
            </Grid>
            <Grid item md={10}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Scheme"
                  name="dest-scheme"
                  value={destination.scheme}
                  onChange={(e, value) => { this.handleSetState('destination', { scheme: value }); }}
                  row
                >
                  <FormControlLabel labelPlacement="end" value="http" control={<Radio />} label="http://" />
                  <FormControlLabel labelPlacement="end" value="https" control={<Radio />} label="https://" />
                  <FormControlLabel labelPlacement="end" value="keep" control={<Radio />} label="Either" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={32}
            alignItems="center"
          >
            <Grid className={scss.leftColumn} item md={2}>
              <Typography variant="button">
                Host:
              </Typography>
            </Grid>
            <Grid item md={10}>
              <TextField
                id="dest-path"
                placeholder="Path"
                value={destination.host}
                onChange={(e) => this.handleSetState('destination', { host: e.target.value })}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={32}
            alignItems="center"
          >
            <Grid className={scss.leftColumn} item md={2}>
              <Typography variant="button">
                path:
              </Typography>
            </Grid>
            <Grid item md={10}>
              <TextField
                id="dest-path"
                placeholder="Path"
                value={destination.path}
                onChange={(e) => this.handleSetState('destination', { path: e.target.value })}
                margin="normal"
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            spacing={32}
            alignItems="center"
          >
            <Grid className={scss.leftColumn} item md={2} container alignItems="flex-end" justify="flex-end">
              <Tooltip
                title={(
                  <div>
                    301 redirects are the default, permanent redirection mode. All requests 301 are redirected to Primary.
                    <br />
                    302 redirects aren&apos;t cached by the browser and are good as a safe/testing mode.
                  </div>
                )}
                placement="top-start"
              >
                <InfoIcon />
              </Tooltip>

              <Typography variant="button">
                Exit Mode:
              </Typography>
            </Grid>
            <Grid item md={10}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Exit Mode"
                  name="exitMode"
                  value={exitMode}
                  onChange={(e, value) => { this.handleSetState('exitMode', value); }}
                  row
                >
                  <FormControlLabel labelPlacement="end" value="301" control={<Radio />} label="301" />
                  <FormControlLabel labelPlacement="end" value="302" control={<Radio />} label="302" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div>
          <DialogActions>
            <Button onClick={() => { this.props.onClose(); }} color="default">
              Discard
            </Button>
            <Button onClick={this.handleAdd}>
              Add
            </Button>
          </DialogActions>
        </div>
        <pre>
          {JSON.stringify(this.state, null, 2)}
        </pre>
      </div>
    );
  }
}
