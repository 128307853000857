export const jssStyles = {
  addCertButton: (theme) => ({
    'borderRadius': theme.borderRadius,
    'backgroundColor': 'transparent',
    'cursor': 'pointer',
    'textDecoration': 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  csrIcon: (theme) => ({
    backgroundColor: theme.palette.colors.orange,
    color: theme.palette.common.white,
    borderRadius: theme.borderRadius * 2,
  }),
  uploadIcon: (theme) => ({
    backgroundColor: theme.palette.colors.orange,
    color: theme.palette.common.white,
    borderRadius: theme.borderRadius * 2,
  }),
  letsEncryptIcon: (theme) => ({
    backgroundColor: theme.palette.colors.blue,
    color: theme.palette.common.white,
    borderRadius: theme.borderRadius * 2,
  }),
  importIcon: (theme) => ({
    backgroundColor: theme.palette.colors.pink,
    color: theme.palette.common.white,
    borderRadius: theme.borderRadius * 2,
  }),
};
