import React from 'react';
import { object, func, bool } from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TrashIcon from '@material-ui/icons/Delete';

import { format } from 'date-fns';
import { css } from '@emotion/react';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import colors from 'shared/styleguide/theme';
import Box from 'shared/styleguide/atoms/Box';
import ConfirmableButton from 'shared/styleguide/atoms/Buttons/ConfirmableButton';
import MDView from 'shared/styleguide/atoms/Markdown/MDView';
import { convertToUtc } from 'shared/utils';

const contentWrapper = css`
  border-left: 1px solid var(--color-200);
  padding-bottom: 20px;

  & h4 {
    padding-left: 24px;
    border-left: 2px solid var(--color-secondary);
    margin-bottom: 20px;
  }

  & ul {
    list-style-type: none;
    padding-left: 0px;
  }

  & li {
    font-size: 14px;
    padding-left: 24px;
    position: relative;
  }
  
  & li::after {
    background-color: var(--color-900);
    width: 2px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
  }
`;
const dateWrapper = css`
  padding-left: 24px;
  border-left: 2px solid var(--color-900);
  min-width: 250px;
`;

const ReleaseNote = ({
  note, isAdmin, setMode, deleteReleaseNote,
}) => {
  const formattedReleaseDate = format(convertToUtc(new Date(note.releaseDate)), 'MMMM do, y');

  return (
    <>
      <Box direction="row">
        <Box flex="20%">
          {/* date */}
          <Typography variant="body2" css={dateWrapper}>{formattedReleaseDate}</Typography>
          {
            isAdmin && (
              <Box align="center" direction="row" padding={{ left: 'medium' }}>
                {new Date(note.releaseDate) > new Date()
                  && (
                    <GhostTag color="orange">future</GhostTag>
                  )}
                <GhostTag color={note.status === 'published' ? 'green' : 'smoke'}>{note.status}</GhostTag>
                <Tooltip title="Edit">
                  <IconButton
                    style={{
                      height: 'auto',
                      width: 'auto',
                    }}
                    onClick={() => setMode('edit', note.releaseDate)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <ConfirmableButton
                  icon={TrashIcon}
                  activeColor={colors.red10}
                  callback={() => deleteReleaseNote(formattedReleaseDate)}
                  tooltip="Delete"
                />
              </Box>
            )
          }
        </Box>
        <Box flex="80%" css={contentWrapper}>
          <MDView value={note.notes} />
        </Box>
      </Box>
    </>
  );
};

ReleaseNote.propTypes = {
  deleteReleaseNote: func.isRequired,
  isAdmin: bool,
  note: object.isRequired,
  setMode: func.isRequired,
};

export default ReleaseNote;
