import React from 'react';
import { object, string } from 'prop-types';

import { Switch, Route, Redirect } from 'react-router-dom';
import Navigation from 'shared/styleguide/molecules/HorizontalNavigation';
import Layout from 'shared/styleguide/molecules/Layout';
import Ticket from './routes/Ticket';
import { routes } from './routes';

const Support = ({ match, parentPath }) => {
  return (
    <Layout title="Support">
      <Route
        exact
        path={`${match.path}`}
        render={() => (
          <Redirect to="support/submit" />
        )}
      />
      <Route
        path={`${match.path}/:view*`}
        render={(props) => (<Navigation {...props} routes={routes} parentPath={match.url} fallback="recent" />)}
      />
      <Switch>
        {
          routes.concat().reverse().map((child) => {
            return (
              <Route key={child.to} path={`${parentPath}/${child.to}`} component={child.component} />
            );
          })
        }
        <Route exact path={`${match.path}/:ticketId`} component={Ticket} />
      </Switch>
    </Layout>
  );
};

Support.propTypes = {
  match: object.isRequired,
  parentPath: string,
};

export default Support;
