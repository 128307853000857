import React from 'react';
import { darken, useTheme } from '@material-ui/core';
import { countries } from 'shared/utils/constants';
import Select from 'shared/styleguide/atoms/Select/Select';

interface Props {
  onChange: any;
  value: string;
  styles: Record<string, any>;
  hasError: boolean;
}

const options = countries.map(({ ccode, cname }) => ({
  label: cname,
  value: ccode,
}));

const CountrySelector = ({
  onChange, value, styles, hasError,
}: Props) => {
  const theme = useTheme();
  return (
    <div>
      <Select
        aria-labelledby="country-label"
        id="country-select"
        options={options}
        isClearable={false}
        onChange={onChange}
        value={options.find((option) => option.value === value)}
        styles={{
          control: (provided) => (hasError ? {
            ...provided, borderColor: 'red',
          } : {
            ...provided,
            background: theme.color100,
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 100,
            background: theme.color100,
          }),
          ...styles,
        }}
      />
    </div>
  );
};

export default CountrySelector;
