import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AppsIcon from '@material-ui/icons/Apps';
import CDNIcon from '@material-ui/icons/CloudCircle';

import { Typography } from '@material-ui/core';
import Card from 'shared/styleguide/atoms/Card/Card';
import Box from 'shared/styleguide/atoms/Box';
import { HeadlineText } from 'shared/styleguide/typography';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Layout from 'shared/styleguide/molecules/Layout';

import {
  updateDNSRecord,
  getDNSZones,
  getDNSRecords,
  resetDNSRecords,
  addGoogleRecords,
} from 'shared/modules/dns/redux/actions';
import Loading from 'shared/styleguide/atoms/Loading';
import AppLink from 'shared/modules/apps/Link';
import CDNLink from 'shared/modules/cdn/Link';

import AddRecord from '../components/AddRecord';
import DNSTable from '../components/DNSRecords';
import Heading from '../components/Heading';

import styles from '../DNS.scss';

class DNS extends Component {
  static propTypes = {
    addGoogleRecords: func,
    dnsRecords: object,
    dnsZone: object,
    getDNSRecords: func,
    getDNSZones: func,
    location: object,
    match: object,
    resetDNSRecords: func,
    updateDNSRecord: func,
  };

  state = {
    filter: '',
  };

  componentDidMount() {
    const { params: { zoneId, accountID } } = this.props.match;
    const { state: zone } = this.props.location;
    let zoneIdentifier = zone && zone.extId;

    if (!zoneIdentifier) {
      // fallback in case router Link didn't provide what we needed
      // we don't have zones! panic!
      if (!this.props.dnsZone || !this.props.dnsZone.loaded) {
        this.props.getDNSZones({ accountId: accountID }).then((zones) => {
          zoneIdentifier = zones.data.find((z) => `${z.id}` === zoneId).extId;
          // we probably don't have anything then.
          this.props.getDNSRecords({ accountId: accountID, zoneId: zoneIdentifier });
        });
        // TODO: unhandled rejection
      } else {
        zoneIdentifier = this.props.dnsZone.data.find((z) => `${z.id}` === zoneId).extId;
        this.props.getDNSRecords({ accountId: accountID, zoneId: zoneIdentifier });
      }
    }

    if (zoneIdentifier && (this.props.dnsRecords.status !== 'success' || this.props.dnsRecords.params?.zoneId !== zoneIdentifier)) {
      this.props.getDNSRecords({ accountId: accountID, zoneId: zoneIdentifier });

      // no idea how this happens on refresh but here we are
      if (!this.props.dnsZone || !this.props.dnsZone.loaded) {
        this.props.getDNSZones({ accountId: accountID });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetDNSRecords();
  }

  render() {
    const { dnsRecords } = this.props;
    const { params: { accountID, zoneId } } = this.props.match;

    if (!this.props.dnsZone.loaded) {
      return (
        <Loading />
      );
    }
    const zones = this.props.dnsZone.data.filter((z) => z.id === parseInt(zoneId, 10));

    if (zones.length === 0) {
      return (
        <div className={styles.dns}>
          <div className={styles.headline}>
            <HeadlineText>
              Invalid zone ID
            </HeadlineText>
          </div>
        </div>
      );
    }

    const zone = zones[0];

    return (
      <Layout
        // title="pressDns"
        title={(
          <Heading />
        )}
      >
        <div className={styles.dns}>
          <div>
            <Card className={styles.dnsCardRow}>
              <Typography variant="h3">{zone.apex}</Typography>
              <div>
                <AppLink appId={zone.appId}>
                  <Tooltip title="App" placement="top">
                    <IconButton>
                      <AppsIcon />
                    </IconButton>
                  </Tooltip>
                </AppLink>
                <CDNLink appId={zone.appId}>
                  <Tooltip title="CDN" placement="top">
                    <IconButton>
                      <CDNIcon />
                    </IconButton>
                  </Tooltip>
                </CDNLink>
              </div>
            </Card>
          </div>
          <div>
            <Typography variant="h3" gutterBottom><strong>Add a Record</strong></Typography>
          </div>
          <div>
            <Card className={styles.dnsCard}>
              <AddRecord accountId={accountID} zone={zone} updateDNSRecord={this.props.updateDNSRecord} addGoogleRecords={this.props.addGoogleRecords} />
            </Card>
          </div>
          <Box margin={{ top: 'medium', bottom: 'xxsmall' }} direction="row" align="flex-end" justify="space-between">
            <Typography component="div" variant="h3" gutterBottom>DNS Records</Typography>
            <SearchBar text="Filter Records" onChange={(filter) => this.setState({ filter })} />
          </Box>
          <DNSTable filter={this.state.filter} dns={dnsRecords} accountId={accountID} zone={zone} />
        </div>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    dnsRecords: state.dns.dnsRecords,
    dnsZone: state.dns.dnsZones,
  }), {
    addGoogleRecords,
    updateDNSRecord,
    getDNSZones,
    getDNSRecords,
    resetDNSRecords,
  },
)(DNS);
