/* eslint-disable react/prop-types */
import { ButtonBase, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import Box from 'shared/styleguide/atoms/Box';
import {
  TYPE_ADMIN, TYPE_CONFIG, TYPE_CONTACT, TYPE_CREDENTIALS, TYPE_EVENT, TYPE_ISSUE, TYPE_MIGRATION, TYPE_PROMISE, TYPE_REPORT, TYPE_SYSTEM,
} from '../../constants';
import { IconMap } from '../../components/NoteIcon';

// set types of notes per filter
const filterTypes = {
  concerns: [TYPE_ISSUE, TYPE_SYSTEM, TYPE_PROMISE],
  events: [TYPE_EVENT],
  docs: [TYPE_REPORT, TYPE_CONFIG, TYPE_CONTACT, TYPE_ADMIN],
  credential: [TYPE_CREDENTIALS, TYPE_MIGRATION],
};

const FilterButton = ({
  icon: Icon, label, type, handleFilter, active,
}) => {
  const theme = useTheme();
  return (
    <Box as={ButtonBase} onClick={() => { handleFilter(type); }} padding="xxsmall">
      <Icon fontSize="small" color={active ? 'secondary' : 'inherit'} css={{ color: active ? '' : theme.palette.action.active }} />
      <Typography variant="caption" color={active ? 'secondary' : 'textSecondary'}>{label}</Typography>
    </Box>
  );
};

const NoteFilter = ({ onFilter }) => {
  const [active, setActive] = useState('all');
  const buttonTypes = [
    {
      icon: IconMap(TYPE_PROMISE),
      key: 'concerns',
      type: 'concerns',
      label: 'Concerns',
    },
    {
      icon: IconMap(TYPE_EVENT),
      key: 'events',
      type: 'events',
      label: 'Event',
    },
    {
      icon: IconMap(TYPE_REPORT),
      key: 'docs',
      type: 'docs',
      label: 'Docs',
    },
    {
      icon: IconMap(TYPE_CREDENTIALS),
      key: 'credential',
      type: 'credential',
      label: 'Credentials',
    },
  ];

  const handleFilter = (type) => {
    setActive(type);
    onFilter(filterTypes[type]);
  };

  return (
    <Box direction="row" justify="space-between" padding={{ left: 'xsmall', right: 'xsmall' }}>
      {
        buttonTypes.map((item) => (
          <FilterButton
            active={active === item.key}
            {...item}
            key={item.key}
            handleFilter={handleFilter}
          />
        ))
      }

      <FilterButton
        active={active === 'all'}
        handleFilter={() => { handleFilter('all'); }}
        label="All"
        icon={IconMap(TYPE_ADMIN)}
      />
    </Box>
  );
};

export default NoteFilter;
