import makeApiAction from 'shared/utils/redux/apiActions';
import { AUTH_TYPE } from 'shared/utils/constants';
import * as TEAM from '../constants';

export const getRecipientsForAccount = (targetId) => makeApiAction(
  ({ api }) => api().get(`/status/alerts/recipients/${AUTH_TYPE.account}:${targetId}`),
  TEAM.GET_RECIPIENTS_REQUEST,
  TEAM.GET_RECIPIENTS_SUCCESS,
  TEAM.GET_RECIPIENTS_FAILURE,
  { accountId: targetId },
);

export const setDeliveryForRecipient = (targetId, sourceId, body) => makeApiAction(
  ({ api }) => api().put('/status/alerts/recipients', {
    ...body,
    recipient: `${AUTH_TYPE.account}:${sourceId}`,
    targetId: `${AUTH_TYPE.account}:${targetId}`,
  }),
  TEAM.SET_RECIPIENT_DELIVERY_REQUEST,
  TEAM.SET_RECIPIENT_DELIVERY_SUCCESS,
  TEAM.SET_RECIPIENT_DELIVERY_FAILURE,
  { accountId: targetId, userId: sourceId },
);
