import React, { Component } from 'react';
import { func, array } from 'prop-types';
import cx from 'classnames';

import AddIcon from '@material-ui/icons/Add';

import InfoText from 'shared/styleguide/molecules/InfoText';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Select from 'shared/styleguide/atoms/Select/Select';

import styles from '../DNS.scss';

export class AddZone extends Component {
  static propTypes = {
    aliases: array.isRequired,
    onSubmit: func,
  };

  state = {
    app: {},
    status: 'pristine',
    error: '',
  };

  handleSubmit = () => {
    const { value, label } = this.state.app;
    if (!value || !label) {
      this.setState({ error: 'Please select a zone' });
      return;
    }

    this.setState({ status: 'loading' });
    this.props.onSubmit({ appId: value, domain: label })
      .then(() => {
        this.setState({ app: {}, error: false, status: 'success' }, () => {
          setTimeout(() => {
            this.setState({ status: 'pristine' });
          }, 3000);
        });
      });
    // TODO: unhandled rejection
  };

  sortBySubdomains = (a, b) => {
    // get # of '.' of a and b
    const aDots = a.fqdn.concat().replace(/[^.]/g, '').length; // 2
    const bDots = b.fqdn.concat().replace(/[^.]/g, '').length; // 1

    return aDots - bDots;
  };

  render() {
    const { aliases } = this.props;

    const options = aliases
      .filter((n) => n.fqdn.indexOf('www.') === -1)
      .sort(this.sortBySubdomains)
      .map((a) => ({
        label: a.fqdn,
        value: a.appId,
      }));

    return (
      <Box direction="column" flex={1}>
        <Box direction="row" gap="small" align="center" margin={{ bottom: 'small' }}>
          <Box flex={1}>
            <Select
              className={cx({ [styles.selectError]: Boolean(this.state.error) })}
              options={options}
              disabled={!aliases.length}
              onChange={(d) => this.setState({ app: d })}
              value={this.state.app.value ? options.find((app) => this.state.app.value === app.value) : null}
              isClearable={false}
            />
          </Box>
          <Box>
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="secondary"
              loading={this.state.status === 'loading'}
              success={this.state.status === 'success'}
              startIcon={<AddIcon />}
            >
              New Zone
            </Button>
          </Box>
        </Box>
        <InfoText noMargin>We do not support &apos;www.&apos; domains as DNS Zones</InfoText>
      </Box>
    );
  }
}

export default AddZone;
