import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as HEADERS from './constants';

export const fetchHeadersForApp = (appId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/ares/${appId}/headers`),
  HEADERS.GET_HEADERS_REQUEST,
  HEADERS.GET_HEADERS_SUCCESS,
  HEADERS.GET_HEADERS_FAILURE,
  { appId },
);

export const setHeaders = (appId, body) => makeApiAction(
  ({ api }) => api().put(`/serverconfig/ares/${appId}/headers`, { headers: body }),
  HEADERS.SET_HEADERS_REQUEST,
  HEADERS.SET_HEADERS_SUCCESS,
  HEADERS.SET_HEADERS_FAILURE,
  { appId, body },
  true,
);

export const deleteHeadersForApp = (appId, key, path, extensions) => makeApiAction(
  ({ api }) => {
    const queryString = qs.stringify({
      path,
      extensions,
    }, { addQueryPrefix: true, arrayFormat: 'brackets' });
    return api().delete(`/serverconfig/ares/${appId}/headers${queryString}`);
  },
  HEADERS.DELETE_HEADERS_REQUEST,
  HEADERS.DELETE_HEADERS_SUCCESS,
  HEADERS.DELETE_HEADERS_FAILURE,
  { appId, key },
);
