import store2 from 'store2';
import parse from 'html-react-parser';
import md5 from 'md5';
import moment from 'moment';
import { addMinutes } from 'date-fns';

import logger from 'shared/3rdparty/logger';
import { isObject } from 'shared/utils/types';
import { THOUSAND } from './constants';

/* eslint-disable no-bitwise */
export const hashCode = (str: string): number => {
  let hash = 0;
  let i = 0;
  let chr = 0;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const toHex = (str: string): string => {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const localizeCurrency = (price: number, options: Intl.NumberFormatOptions = {}): string => price.toLocaleString([], { style: 'currency', currency: 'USD', ...options });

export const dateOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

// Should be its own util
export const formatDate = (value: string, opts?: Intl.DateTimeFormatOptions): string => {
  const defaultOpts = { month: 'short', day: 'numeric' };
  // overwrite defaults with opts
  const options = Object.assign(defaultOpts, opts);

  // Tue Aug 28 2018 17:00:00 GMT-0700 (MST)
  const date = new Date(value);

  let locale = (window.navigator.language);
  if (!locale) { // helps with testing, also good to provide a fallback
    locale = 'en-US';
  }

  const formattedDate = date.toLocaleDateString(locale, options);
  return formattedDate;
};

export const convertToUtc = (date: Date): Date => {
  return addMinutes(date, date.getTimezoneOffset());
};

export const truncate = (name: string, maxLength = 20): string => {
  if (name && typeof name === 'string' && name.length > maxLength) {
    return `${name.substring(0, maxLength - 3)}...`;
  }

  return name;
};

export const trimDomain = (domain: string): string => {
  // trim the search string
  const trimmedDomain = domain.trim().replace(/\/|http(s?):\/\/|(www\.)/g, '');

  return trimmedDomain;
};

export const getAvatarURL = ({
  email = 'no@no.com',
  s = 40,
}: { email: string; s?: number }): string => {
  return `https://www.gravatar.com/avatar/${md5(email.trim().toLowerCase())}?s=${s}&d=404&r=g`;
};

export function getUserTypeAndId(): string[] {
  const token = store2.get('atomic_token');
  const tokenData = JSON.parse(
    window.atob(
      token.split('.')[1]
        .replace('-', '+')
        .replace('_', '/'),
    ),
  );
  return tokenData.sub.split(':');
}

export function joinWithNewLines(messages: string): string {
  const output = Array.isArray(messages) ? messages.join('\n') : messages;
  return output.replace(/\\n/g, '\n').replace(/"/g, '');
}

export function boldMD(text: string): string {
  return text.replace(/[*]{2}(\S(.*?\S)?)[*]{2}/gm, '<strong>$1</strong>');
}

export function errorsMD(messages: string): ReturnType<typeof parse> {
  return parse(boldMD(joinWithNewLines(messages)));
}

export const truncateDecimals = (value: number, places: number): number => {
  let temp = `${value}`;
  const [, decimal] = temp.split('.');
  if (decimal?.length >= places) {
    temp = value.toFixed(places);
  }
  return Number(temp);
};

export function formatMB(number: number, places = 2): string {
  return number > (THOUSAND - 1) ? `${truncateDecimals((number / THOUSAND), places)} GB` : `${truncateDecimals(number, places)} MB`;
}

export function formatMiB(number: number, places = 2): string {
  return number > (1024 - 1) ? `${truncateDecimals((number / 1024), places)} GiB` : `${truncateDecimals(number, places)} MiB`;
}

export function convertMiB(number: string, formatted = true): number | string {
  const MibToMBRatio = 1.048576;
  const sizeMB = truncateDecimals((parseFloat(number) * MibToMBRatio), 2);
  if (formatted) {
    return formatMB(sizeMB);
  }
  return sizeMB;
}

export function convertGiB(number: string, formatted = true): string | number {
  const GibToGBRatio = 1.07374;
  const sizeGB = truncateDecimals((parseFloat(number) * GibToGBRatio), 2);
  if (formatted) {
    return `${sizeGB} GB`;
  }
  return sizeGB;
}

export const round = (value: number, type: 'decimal' | 'ten', factor: number, direction: 'up' | 'down'): number => {
  const multiplier = 10 ** factor;
  let method = 'round';
  if (direction === 'up') {
    method = 'ceil';
  }
  if (direction === 'down') {
    method = 'floor';
  }

  if (type === 'decimal') {
    return Math[method]((value * multiplier) / multiplier);
  }

  if (type === 'ten') {
    return Math[method](value / multiplier) * multiplier;
  }
};

export const chunk = <T>(arr: T[], chunkSize: number): T[] => {
  const wrapper = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    wrapper.push(arr.slice(i, i + chunkSize));
  }
  return wrapper;
};

export const flattenVariables = (context: Record<string, unknown> | unknown, result = {}, prefix = ''): Record<string, unknown> => {
  return Object.entries(context).reduce((acc, [key, value]) => {
    const variableName = prefix ? `${prefix}.${key}` : key;

    if (isObject(value)) {
      return { ...acc, ...flattenVariables(value, acc, variableName) };
    }

    return { ...acc, [variableName.toUpperCase()]: value };
  }, { ...result });
};

// TODO: Update this type to be less permissive
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const replaceVariables = (template: Record<string, unknown> | any, variables: any): Record<string, unknown> => {
  let replaced = template;

  for (let i = 0; i < Object.keys(variables).length; i++) {
    const match = Object.keys(variables)[i];
    replaced = replaced.split(`{{${match}}}`).join(variables[match]);
  }

  return replaced;
};

export const formatDurationFromSeconds = (value: number): string => {
  const milliseconds = Math.abs(value) * 1000;
  const duration = moment.duration(milliseconds);
  const minutes = Math.floor(duration.asMinutes());
  const hours = Math.floor(minutes / 60);
  const hoursDisplay = hours > 0 ? `${hours}:` : '';
  return `${hoursDisplay}${moment(milliseconds).format('mm')}:${moment(milliseconds).format('ss')}`;
};

export function deepClone(obj: Record<string, unknown>): Record<string, unknown> {
  let clone;

  try {
    clone = JSON.parse(JSON.stringify(obj));
  } catch (err) {
    logger.error(err);
  }

  if (isObject(clone)) {
    return clone;
  }
}

export const getInitials = (fullName: string): string => {
  if (typeof fullName !== 'string') {
    return '';
  }
  if (fullName.length === 2) return fullName;
  const allNames = fullName.trim().split(' ');
  const firstLetter = allNames.shift()?.charAt(0) ?? '';
  const lastLetter = allNames.pop()?.charAt(0) ?? '';
  return `${firstLetter}${lastLetter}`.toUpperCase();
};
