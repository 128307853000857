import qs from 'qs';
import { getHttp } from 'shared/http';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as METADATA from './constants';

export const getAllTags = (type, search) => (dispatch, getState, { history, api }) => {
  const params = qs.stringify({ type, search }, { addQueryPrefix: true });

  return api().get(`/metadata/tags${params}`);
};

export const createTag = (type, tag) => (dispatch, getState, { history, api }) => {
  return api().post('/metadata/tags', { type, tag });
};

export const addTagToType = (type, tagId, meta) => {
  let cbUrl;
  switch (type) {
    case 'app': cbUrl = `/metadata/apps/${meta.appId}/tags`; break;
    case 'server': cbUrl = ` /metadata/servers/${meta.serverId}/tags`; break;
    case 'account':
    default:
      cbUrl = `/metadata/accounts/${meta.accountId}/tags`; break;
  }

  return makeApiAction(
    ({ api }) => api().post(cbUrl, { tagId }),
    METADATA.METADATA_ADD_TAG_REQUEST,
    METADATA.METADATA_ADD_TAG_SUCCESS,
    METADATA.METADATA_ADD_TAG_FAILURE,
    {},
    true,
  );
};

export const removeTagFromType = (type, tagId, meta) => {
  let cbUrl;
  switch (type) {
    case 'app': cbUrl = `/metadata/apps/${meta.appId}/tags/${tagId}`; break;
    case 'server': cbUrl = ` /metadata/servers/${meta.serverId}/tags/${tagId}`; break;
    case 'account':
    default:
      cbUrl = `/metadata/accounts/${meta.accountId}/tags/${tagId}`; break;
  }

  return makeApiAction(
    ({ api }) => api().delete(cbUrl),
    METADATA.METADATA_REMOVE_TAG_REQUEST,
    METADATA.METADATA_REMOVE_TAG_SUCCESS,
    METADATA.METADATA_REMOVE_TAG_FAILURE,
  );
};

export const fetchAppMetaTags = (appId) => makeApiAction(
  ({ api }) => api().get(`/metadata/apps/${appId}/keys`),
  METADATA.GET_APP_META_REQUEST,
  METADATA.GET_APP_META_SUCCESS,
  METADATA.GET_APP_META_FAILURE,
  {
    appId,
  },
  true,
);
