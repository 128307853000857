import * as base from 'shared/baseStates';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import apiReducer, {
  baseCollectionState,
  baseState,
  failMethod,
  successMethod,
  load,
  success,
  fail,
} from 'shared/utils/redux/apiReducer';

import {
  DOMAINS_REQUEST,
  DOMAINS_SUCCESS,
  DOMAINS_FAILURE,
} from 'shared/modules/app/redux/domains/constants';
import {
  CERT_DOMAIN_LINK_UPDATE_SUCCESS,
  CERT_LINKED_SEARCH_SUCCESS,
} from 'shared/modules/ssl/redux/certDomainLink/actions';

import * as APP from '../constants';

export const initialState = {
  ...base.collection(),
};

export function domains(state = initialState, action) {
  switch (action.type) {
    case DOMAINS_REQUEST:
    case DOMAINS_FAILURE:
      return { ...initialState, ...base.failure(action.errMessage, action.statusCode) };
    case DOMAINS_SUCCESS:
      return {
        ...initialState,
        ...base.success(),
        records: action.data.data,
        params: action.params,
      };

    case LOGIN_LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
}

const appDomainsFetch = apiReducer(
  APP.APP_DOMAINS_FETCH_REQUEST,
  APP.APP_DOMAINS_FETCH_SUCCESS,
  APP.APP_DOMAINS_FETCH_FAILURE,
  APP.APP_RESET,
  baseCollectionState,
);

export const appDomains = (state = baseCollectionState, action) => {
  switch (action.type) {
    case LOGIN_LOGOUT_SUCCESS:
      return {
        ...baseCollectionState,
      };

    case APP.MOVE_APP_DOMAIN_REQUEST:
    case APP.APP_DOMAIN_FLUSH_CACHE_REQUEST:
    case APP.APP_DOMAIN_PROMOTE_REQUEST:
    case APP.APP_DOMAIN_301_REQUEST:
    case APP.APP_DOMAIN_ACTIVE_REQUEST:
    case APP.APP_DOMAIN_REMOVE_REQUEST:
      return {
        ...state,
        doing: true,
      };
    case APP.APP_DOMAINS_MODIFY_REQUEST: {
      const { failures, ...rest } = state;
      return {
        ...rest,
        ...load(state, action),
      };
    }
    case APP.APP_DOMAIN_FLUSH_CACHE_FAILURE:
    case APP.APP_DOMAIN_PROMOTE_FAILURE:
    case APP.APP_DOMAIN_301_FAILURE:
    case APP.APP_DOMAIN_ACTIVE_FAILURE:
    case APP.MOVE_APP_DOMAIN_FAILURE:
    case APP.APP_DOMAINS_MODIFY_FAILURE:
      return fail(state, action);
    case APP.APP_DOMAIN_FLUSH_CACHE_SUCCESS:
      return {
        ...success(state, action),
        data: state.data, // dont change the data
      };

    case APP.APP_DOMAIN_301_SUCCESS:
    case APP.APP_DOMAIN_ACTIVE_SUCCESS:
      return {
        ...state,
        doing: false,
        data: state.data.map((domain) => (domain.id === action.params.domainId ? {
          ...action.data,
        } : {
          ...domain,
        })),
      };
    case APP.APP_DOMAIN_PROMOTE_SUCCESS:
      return {
        ...state,
        doing: false,
        data: state.data.map((domain) => (domain.id === action.params.domainId ? {
          ...action.data,
          primary: true,
        } : {
          ...domain,
          primary: false,
        })),
      };
    case APP.MOVE_APP_DOMAIN_SUCCESS:
    case APP.APP_DOMAIN_REMOVE_SUCCESS:
      return {
        ...state,
        doing: false,
        data: state.data.filter((domain) => domain.id !== action.params.domainId),
      };
    case APP.APP_DOMAIN_ADD_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data,
          action.data,
        ],
      };
    case APP.APP_DOMAINS_MODIFY_SUCCESS: {
      // action.data may have successes or failures
      let data = [
        ...state.data,
      ];

      // failures
      const failures = {
        create: [],
        delete: [],
      };

      let hasFailures = false;

      if (action.data.create?.failures?.length) {
        hasFailures = true;
        failures.create = action.data.create.failures;
      }

      if (action.data.delete?.failures?.length) {
        hasFailures = true;
        failures.delete = action.data.delete.failures;
      }

      // successes
      if (action.data.create?.successes?.length) {
        data.push(...action.data.create.successes);
      }

      if (action.data.delete?.successes?.length) {
        data = data.filter((d) => !action.data.delete.successes.includes(d.id));
      }

      const result = {
        ...success(state, action),
        data,
      };

      if (hasFailures) {
        result.failures = failures;
      }

      return result;
    }
    /* this is a little tricky.... */
    case CERT_LINKED_SEARCH_SUCCESS: {
      // check that this is for the right account, appId
      if (action.appIds && action.appIds.length === 1 && action.appIds[0] === state.params.appId) {
        const dataCopy = [...state.data];

        // get the list of links so you can iterate over it easily
        let links = [];
        action.data.data.forEach((datum) => {
          links = [...links, ...datum.links];
        });

        links.forEach((link) => {
          // find the right domain to add the link data to
          const domainIndex = dataCopy.findIndex((domain) => domain.id === link.aliasId);
          dataCopy[domainIndex] = { ...dataCopy[domainIndex], link };
        });

        return {
          ...state,
          data: dataCopy,
        };
      } else {
        return state;
      }
    }

    case CERT_DOMAIN_LINK_UPDATE_SUCCESS: {
      // check that this is for the right account, appId
      if (action.data.appId && action.data.appId == state.params.appId) { //eslint-disable-line
        const dataCopy = [...state.data];

        const domainIndex = dataCopy.findIndex((domain) => domain.id === action.data.aliasId);
        dataCopy[domainIndex] = { ...dataCopy[domainIndex], link: action.data };

        return {
          ...state,
          data: dataCopy,
        };
      }
      return state;
    }
    case APP.APP_DOMAINS_MODIFY_RESET: {
      const { failures, ...rest } = state;
      return rest;
    }
    default:
      return appDomainsFetch(state, action);
  }
};
