import React from 'react';

const Moon = () => (
  <svg
    style={{ width: 12, height: 12 }}
    className="SVGInline-svg SVGInline--cleaned-svg SVG-svg DarkModeIcon-svg SVG--color-svg"
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.914 0a6.874 6.874 0 0 0-1.26 3.972c0 3.875 3.213 7.017 7.178 7.017.943 0 1.843-.178 2.668-.5C15.423 13.688 12.34 16 8.704 16 4.174 16 .5 12.41.5 7.982.5 3.814 3.754.389 7.914 0z"
      fillRule="evenodd"
    />
  </svg>
);

Moon.displayName = 'MoonIcon';

export default Moon;
