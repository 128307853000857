import React from 'react';
import { object } from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';

const Description = ({
  values, form,
}) => {
  return (
    <Box padding="xsmall">
      <Box direction="column" alignItems="center">
        <Box margin={{ bottom: 'xsmall' }}>
          <Typography variant="body1">
            <strong>Description</strong>
          </Typography>
        </Box>
        <TextField
          key="description"
          value={values.description || ''}
          onChange={(e) => form.changeDescription(e.target.value)}
          placeholder="Description"
          autoComplete="off"
          margin="dense"
          fullWidth
          multiline
          disabled={form.status === 'loading'}
        />
      </Box>
    </Box>
  );
};

Description.propTypes = {
  form: object,
  values: object,
};

export default Description;
