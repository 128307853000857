import React, { Component } from 'react';
import { bool } from 'prop-types';

import { Typography } from '@material-ui/core';

import Banner from 'shared/styleguide/molecules/Banner';
import logger from 'shared/3rdparty/logger';

export default class AdBlockerBanner extends Component {
  static propTypes = {
    forceShow: bool,
  };

  state = {
    isUsingAdblock: this.props.forceShow || false,
  };

  componentDidMount() {
    if (document.getElementById('FziSUWqVLolP')) {
      this.setState({ isUsingAdblock: false });
      logger.client.addMetadata('user', 'is using ad blocker', false);
    } else {
      this.setState({ isUsingAdblock: true });
      logger.client.addMetadata('user', 'is using ad blocker', true);
    }
  }

  render() {
    if (this.state.isUsingAdblock) {
      return (
        <Banner
          showIcon
          subheading={(
            <Typography color="inherit">
              Please disable your Ad Blocker for this domain.
              It interferes with the microservices powering this
              application and will degrade your user experience
              considerably. We <strong>DO NOT</strong> serve ads, or track you for
              advertising purposes inside this application.
              Basically we just want to keep you logged in
              during your session. Help us, help you.
            </Typography>
          )}
          type="danger"
          dismissable
        />
      );
    }
    return null;
  }
}
