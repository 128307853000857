import makeApiAction from 'shared/utils/redux/apiActions';
import * as RL from './constants';

export const fetchRateLimit = (appId, handlerId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/ares/${appId}/rate-limiting/${handlerId}`),
  RL.GET_RATE_LIMIT_REQUEST,
  RL.GET_RATE_LIMIT_SUCCESS,
  RL.GET_RATE_LIMIT_FAILURE,
  { appId, handlerId },
);

export const fetchRateLimitsForApp = (appId) => makeApiAction(
  ({ api }) => api().get(`/serverconfig/ares/${appId}/rate-limiting`),
  RL.GET_RATE_LIMITS_REQUEST,
  RL.GET_RATE_LIMITS_SUCCESS,
  RL.GET_RATE_LIMITS_FAILURE,
  { appId },
);

export const createRateLimit = (appId, body) => makeApiAction(
  ({ api }) => api().put(`/serverconfig/ares/${appId}/rate-limiting`, body),
  RL.CREATE_RATE_LIMITS_REQUEST,
  RL.CREATE_RATE_LIMITS_SUCCESS,
  RL.CREATE_RATE_LIMITS_FAILURE,
  { appId },
  true,
);

export const setRateLimits = (appId, handlerId, body) => makeApiAction(
  ({ api }) => api().put(`/serverconfig/ares/${appId}/rate-limiting/${handlerId}`, body),
  RL.SET_RATE_LIMITS_REQUEST,
  RL.SET_RATE_LIMITS_SUCCESS,
  RL.SET_RATE_LIMITS_FAILURE,
  { appId, handlerId },
  true,
);

export const deleteRateLimits = (appId, handlerId, body) => makeApiAction(
  ({ api }) => api().delete(`/serverconfig/ares/${appId}/rate-limiting/${handlerId}`, body),
  RL.DELETE_RATE_LIMITS_REQUEST,
  RL.DELETE_RATE_LIMITS_SUCCESS,
  RL.DELETE_RATE_LIMITS_FAILURE,
  { appId, handlerId },
  true,
);
