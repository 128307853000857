import { adminApi } from 'admin/query';

export const leaderboardApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    getNotesLeaderboard: build.query({
      query: () => '/notes/leaderboard',
      transformErrorResponse(baseQueryReturnValue, meta, arg) {
        if (baseQueryReturnValue.status >= 500) {
          return {
            error: `Status ${baseQueryReturnValue.status}: check the network panel`,
          };
        } else {
          return baseQueryReturnValue;
        }
      },
    }),
  }),
});

export const {
  useGetNotesLeaderboardQuery,
} = leaderboardApi;
