export const ACCOUNT_COLLABORATORS_GET_REQUEST = 'TEAM_COLLABORATORS_GET_REQUEST';
export const ACCOUNT_COLLABORATORS_GET_SUCCESS = 'TEAM_COLLABORATORS_GET_SUCCESS';
export const ACCOUNT_COLLABORATORS_GET_FAILURE = 'TEAM_COLLABORATORS_GET_FAILURE';

export const ACCOUNT_COLLABORATORS_RESET = 'ACCOUNT_COLLABORATORS_RESET';

export const ACCOUNT_COLLABORATOR_CREATE_REQUEST = 'ACCOUNT_COLLABORATOR_CREATE_REQUEST';
export const ACCOUNT_COLLABORATOR_CREATE_SUCCESS = 'ACCOUNT_COLLABORATOR_CREATE_SUCCESS';
export const ACCOUNT_COLLABORATOR_CREATE_FAILURE = 'ACCOUNT_COLLABORATOR_CREATE_FAILURE';

export const ACCOUNT_COLLABORATOR_UPDATE_REQUEST = 'ACCOUNT_COLLABORATOR_UPDATE_REQUEST';
export const ACCOUNT_COLLABORATOR_UPDATE_SUCCESS = 'ACCOUNT_COLLABORATOR_UPDATE_SUCCESS';
export const ACCOUNT_COLLABORATOR_UPDATE_FAILURE = 'ACCOUNT_COLLABORATOR_UPDATE_FAILURE';

export const ACCOUNT_COLLABORATOR_DELETE_REQUEST = 'ACCOUNT_COLLABORATOR_DELETE_REQUEST';
export const ACCOUNT_COLLABORATOR_DELETE_SUCCESS = 'ACCOUNT_COLLABORATOR_DELETE_SUCCESS';
export const ACCOUNT_COLLABORATOR_DELETE_FAILURE = 'ACCOUNT_COLLABORATOR_DELETE_FAILURE';

export const ACCOUNT_COLLABORATOR_RESET = 'ACCOUNT_COLLABORATOR_RESET';

export const GET_RECIPIENTS_REQUEST = 'TEAM_GET_RECIPIENTS_REQUEST';
export const GET_RECIPIENTS_SUCCESS = 'TEAM_GET_RECIPIENTS_SUCCESS';
export const GET_RECIPIENTS_FAILURE = 'TEAM_GET_RECIPIENTS_FAILURE';

export const SET_RECIPIENT_DELIVERY_REQUEST = 'TEAM_SET_RECIPIENT_DELIVERY_REQUEST';
export const SET_RECIPIENT_DELIVERY_SUCCESS = 'TEAM_SET_RECIPIENT_DELIVERY_SUCCESS';
export const SET_RECIPIENT_DELIVERY_FAILURE = 'TEAM_SET_RECIPIENT_DELIVERY_FAILURE';
