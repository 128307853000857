import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { SpeakerNotes, SpeakerNotesOff } from '@material-ui/icons';

import Notes from 'admin/modules/notes/routes';
import Leaderboard from 'admin/modules/leaderboard/components/Leaderboard';
import NewWindowContext from 'shared/styleguide/atoms/NewWindow/NewWindowContext';
import NewWindow from 'shared/styleguide/atoms/NewWindow/NewWindow';
import { useMergeState } from 'shared/hooks/useMergeState';
import Paper from 'shared/styleguide/atoms/Paper/Paper';
import Box from 'shared/styleguide/atoms/Box';

const RightNav = ({ login, location }) => {
  const { state: popupValue, mergeState: setPopupValue } = useMergeState({
    isOpen: false,
  });
  const [showNotes, setShowNotes] = useState(true);

  useEffect(() => {
    if (location.pathname === '/misc') {
      setShowNotes(false);
    }
  }, [location.pathname]);

  if (!login.isLoggedIn) {
    return null;
  }

  return (
    <>
      <Box css={{ position: 'absolute', top: 4, right: 4 }}>
        <IconButton
          size="small"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setShowNotes(!showNotes)}
          edge="start"
        >
          {
            showNotes ? (
              <SpeakerNotesOff fontSize="small" />
            ) : (
              <SpeakerNotes fontSize="small" color="primary" />
            )
          }
        </IconButton>
      </Box>
      {
        showNotes && (
          <Box
            as={Paper}
            square
            direction="column"
            flex={1}
            css={{
              width: 'calc(100vw/4)', overflowY: 'auto', borderRadius: 0, borderTop: 'none',
            }}
          >
            <NewWindowContext.Provider value={{ ...popupValue, setPopupValue }}>
              <Switch>
                <Route path="/account/:accountID/:anything*" component={Notes} />
                <Route component={Leaderboard} />
              </Switch>
              {
                popupValue.isOpen && (
                  <NewWindow {...popupValue.props} onClose={() => setPopupValue({ isOpen: false })}>
                    {popupValue.children}
                  </NewWindow>
                )
              }
            </NewWindowContext.Provider>
          </Box>
        )
      }
    </>
  );
};

RightNav.propTypes = {
  location: PropTypes.object.isRequired,
  login: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
  }),
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { login: state.login };
};

export default withRouter(connect(mapStateToProps)(RightNav));
