import React from 'react';
import { object, func, number } from 'prop-types';

import { Typography } from '@material-ui/core';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';

const DiskChangeQuote = ({
  planTotalSize, newSize, addonCalculation, onPurchase, diskAddonPurchase,
}) => {
  const {
    requestedStorage, currentStorage, changeInPrice, billingCycle,
  } = addonCalculation.data;

  let modification;
  let modifier = '';

  if ((requestedStorage - currentStorage) > 0) {
    modification = (requestedStorage - currentStorage) > 0 && `Add ${requestedStorage - currentStorage} GiB`;
  }

  if ((requestedStorage - currentStorage) < 0) {
    modification = (requestedStorage - currentStorage) < 0 && `Subtract ${currentStorage - requestedStorage} GiB`;
    modifier = '-';
  }

  return (
    <Box padding={{ left: 'large', right: 'small' }} alignItems="center" align="center">
      <Typography variant="h4">
        {modification}
      </Typography>
      <Box margin={{ top: 'small' }} align="center">
        <Typography variant="h4">
          <strong>{modifier}${Math.abs(changeInPrice)} {billingCycle}</strong>
        </Typography>
      </Box>
      <Box row margin={{ top: 'medium' }} align="center">
        <Button
          status={diskAddonPurchase.status}
          variant="contained"
          onClick={onPurchase}
          disabled={newSize === planTotalSize}
        >
          {
            requestedStorage < currentStorage ? 'Downgrade' : 'Upgrade'
          }
        </Button>
      </Box>
    </Box>
  );
};

DiskChangeQuote.propTypes = {
  addonCalculation: object,
  diskAddonPurchase: object,
  newSize: number,
  onPurchase: func,
  planTotalSize: number,
};

export default DiskChangeQuote;
