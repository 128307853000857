import React from 'react';
import { object, array } from 'prop-types';

import {
  TableCell,
  TableRow,
} from '@material-ui/core';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import Box from 'shared/styleguide/atoms/Box';
import SortableTable from 'shared/styleguide/molecules/Table/SortableTable';

import { formatMiB } from 'shared/utils';

/* eslint-disable react/prop-types */
const Row = ({
  path, used, app, ...props
}) => {
  return (
    <TableRow>
      <TableCell align="left">
        {
          app ? (
            <TextLink
              to={`/account/${app.accountId}/apps/${app.id}/overview`}
            >
              {app.name}
            </TextLink>
          ) : (
            `App Id: ${props.tags.AppId}`

          )
        }
      </TableCell>
      <TableCell align="left">
        {path} <CopyButton text={path} message="Location copied" />
      </TableCell>
      <TableCell align="left">{formatMiB(used)}</TableCell>
    </TableRow>
  );
};
/* eslint-enable react/prop-types */

const ByApp = ({ app, apps }) => {
  return (
    <Box margin={{ top: 'xsmall' }}>

      <SortableTable
        headCells={[
          {
            id: 'path.tags.AppId',
            numeric: false,
            disablePadding: false,
            label: 'App',
            align: 'left',
            sort: false,
          },
          {
            id: 'path',
            numeric: false,
            disablePadding: false,
            align: 'left',
            label: 'Location',
          },
          {
            id: 'used',
            numeric: false,
            disablePadding: false,
            label: 'Usage',
            align: 'left',
          },
        ]}
        rows={app}
        paginate
        renderRow={(path) => {
          return (
            <Row
              key={path.tags.AppId || path.path}
              {...path}
              app={apps[path.tags.AppId]}
            />
          );
        }}
      />
    </Box>
  );
};

ByApp.propTypes = {
  app: array.isRequired,
  apps: object.isRequired,
};

export default ByApp;
