import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMergeState } from 'shared/hooks/useMergeState';
import {
  PRISTINE, SUCCESS, FAILED,
} from 'shared/utils/redux/constants';
import { getAllValidationMessages } from 'shared/utils/validation';
import type { StoreState } from 'shared/types/types';

interface State<TCallArgs extends unknown[]> {
  args: TCallArgs;
  dispatchId: string;
  callCount: number;
  statusCode: number | null;
  error?: string | null;
  errorMessage?: string | null;
  validation?: Record<string, any> | null;
  timeout?: number;
  status: string;
  data?: Record<string, any>;
  displayStatus: string;
}

type ApiCall<TCallArgs extends unknown[]> = (
  callback: (
    dispatchId: string,
    ...args: TCallArgs
  ) => void,
  args: TCallArgs,
  customTimeout?: number | null,
  customDispatchId?: string | null,
) => void;

interface Result<TCallArgs extends unknown[]> extends State<TCallArgs> {
  call: ApiCall<TCallArgs>;
}

/**
* @desc You must set dispatchId in the action creator params to use this hook.
*/
export const useCallApi = <TCallArgs extends unknown[]>(storeState: StoreState, includeFrozenData: boolean = false): Result<TCallArgs> => {
  const { state, mergeState } = useMergeState({
    args: [],
    status: PRISTINE,
    displayStatus: PRISTINE,
    callCount: 0,
    dispatchId: uuidv4(), // we initialize with a new random id to ensure fresh data
    statusCode: null,
    error: null,
    errorMessage: null,
    validation: null,
    timeout: 3000,
  } as State<TCallArgs>);

  const {
    status, displayStatus, callCount, dispatchId, timeout,
  } = state;

  const call: ApiCall<TCallArgs> = (
    callback,
    args,
    customTimeout,
    customDispatchId,
  ) => {
    const updates: Partial<State<TCallArgs>> = {
      args,
      dispatchId,
      statusCode: null,
      error: null,
      errorMessage: null,
      validation: null,
    };

    if (customTimeout) {
      updates.timeout = customTimeout;
    }

    if (customDispatchId) {
      updates.dispatchId = customDispatchId;
    }

    callback(customDispatchId || dispatchId, ...args);

    mergeState({
      ...updates,
      callCount: callCount + 1,
    });
  };

  useEffect(() => {
    if (
      storeState?.params?.dispatchId === dispatchId
      && status !== storeState?.status
    ) {
      const updates: Partial<State<TCallArgs>> = {
        status: storeState.status,
        displayStatus: storeState.status,
        statusCode: storeState.statusCode,
        error: storeState.errMessage,
        errorMessage: storeState.statusCode === 422 ? getAllValidationMessages(storeState) : storeState.errMessage,
        validation: storeState.validation,
      };

      if (includeFrozenData) {
        updates.data = Object.freeze(storeState.data);
      }

      mergeState(updates);
    }

    if (
      storeState?.params?.dispatchId === dispatchId
      && storeState?.status === displayStatus
      && [SUCCESS, FAILED].includes(storeState.status)
    ) {
      // reset displayStatus after timeout milliseconds
      // only for SUCCESS and FAILED calls
      // useful for animating buttons etc
      const successTimerId = setTimeout(
        () => mergeState({ displayStatus: PRISTINE }),
        timeout,
      );
      return () => clearTimeout(successTimerId);
    }
    return undefined;
  }, [storeState, includeFrozenData, status, displayStatus, dispatchId, mergeState, timeout]);

  return { ...state, call };
};

export default { useCallApi };
