import React, { Fragment, useState, useCallback } from 'react';
import PropTypes, { shape } from 'prop-types';
import moment from 'moment-timezone';

import CheckIcon from '@material-ui/icons/Check';

import CollapsibleTable from 'shared/styleguide/molecules/CollapsibleTable';
import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import { getComparator, stableSort } from 'shared/utils/sorting';
import { statusColorMap } from 'shared/modules/alerts/constants';

import AlertDetail from './AlertDetail';
import AlertsShortcuts from './AlertsShortcuts';

import { headCells } from '../constants';

export const AlertsList = ({ items }) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdDate');

  const alerts = items.map((alert) => {
    const [, accountId] = alert.targetId.split(':');
    return {
      ...alert,
      accountId,
    };
  });

  const sortedItems = stableSort(alerts, getComparator(order, orderBy));

  const memoizedSortedItems = useCallback(sortedItems, [sortedItems, alerts, order, orderBy]);

  const handleSetOrderBy = (value) => {
    setOrderBy(value);
  };

  const handleSetOrder = (value) => {
    setOrder(value);
  };

  const ListRows = memoizedSortedItems.map((item) => {
    const readTag = item.readStatus === 1 ? <CheckIcon color="secondary" /> : null;
    const statusTag = <GhostTag color={statusColorMap[item.status]}>{item.status}</GhostTag>;

    const status = (
      <Box row gap="xsmall" key={`status-${item.file}`}>
        <Box>{readTag}</Box>
        <Box>{statusTag}</Box>
      </Box>
    );

    let accountInfo = item.name;
    if (item.email) {
      accountInfo = <Fragment>{accountInfo}<br />{item.email}</Fragment>;
    }
    if (item.companyName) {
      accountInfo = <Fragment>{accountInfo}<br />{item.companyName}</Fragment>;
    }

    return {
      data: item,
      display: [
        status,
        item.accountId,
        accountInfo,
        `${moment.utc(item.createdAt).format('YYYY-MM-DD HH:mm')} UTC`,
        item.alertType,
        <AlertsShortcuts key={`shortcuts-${item.file}`} accountId={item.accountId} ticketId={item.associatedTicketId} />,
      ],
    };
  });

  return (
    <CollapsibleTable
      pageLength={10}
      headCells={headCells}
      ListRows={ListRows}
      Detail={AlertDetail}
      onSetOrder={handleSetOrder}
      onSetOrderBy={handleSetOrderBy}
      order={order}
      orderBy={orderBy}
      paginate
    />
  );
};

AlertsList.propTypes = {
  accountId: PropTypes.number,
  items: PropTypes.arrayOf(shape({})),
};

export default AlertsList;
