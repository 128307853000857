import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';

import { Typography } from '@material-ui/core';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Card from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import { ColorText } from 'shared/styleguide/typography';
import colors from 'shared/styleguide/theme';

import { reloadPool } from 'admin/modules/serverconfig/actions';

export class PoolReload extends Component {
  static propTypes = {
    reloadPool: func.isRequired,
  };

  state = {
    pool: '',
    showSuccess: false,
    showError: '',
  };

  render() {
    const { reloadPool } = this.props;
    const { pool } = this.state;
    return (
      <Box as={Card} margin={{ bottom: 'small' }} padding="small">
        <Typography variant="h3" gutterBottom>Pool Reload</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          This action reloads the webserver config for the pool. This is not a search.
        </Typography>
        <Box direction="row" align="center">
          <TextField
            variant="outlined"
            margin="dense"
            placeholder="Pool ID"
            value={pool}
            type="number"
            style={{ marginRight: 20 }}
            onChange={(e, value) => this.setState({ pool: value })}
          />
          <Button
            variant="contained"
            onClick={() => {
              reloadPool(parseInt(pool, 10))
                .then(() => this.setState({ showSuccess: true, showError: '' }))
                .catch((err) => this.setState({ showError: `Error: ${err.message}`, showSuccess: false }));
            }}
          >
            Trigger Pool Reload
          </Button>
        </Box>
        <Box>
          {
            this.state.showSuccess && (<ColorText color={colors.green30}>Information updated successfully</ColorText>)
          }
          {
            this.state.showError && (<ColorText color={colors.red10}>{this.state.showError}</ColorText>)
          }
        </Box>
      </Box>
    );
  }
}

export default connect(
  () => ({}),
  {
    reloadPool,
  },
)(PoolReload);
