/* eslint-disable react/no-multi-comp */
import React from 'react';
import { object, func, bool } from 'prop-types';

import { Typography, Tooltip, Switch } from '@material-ui/core';

import { ErrorText } from 'shared/styleguide/typography';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { formatDate, dateOptions } from 'shared/utils';

import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';

import SSLLink from 'shared/modules/ssl/Link';
import { PRESSDNS_REGEX } from 'shared/modules/dns/constants';

export const DomainDetails = ({ domain, appDomains, ...props }) => {
  const {
    set301,
    updateCertDomainLink,
    onPromoteDomain,
    onUpdateState,
    onRequestValidation,
    setOpen,
    fsaIsDisabling,
  } = props;

  const columnData = [
    [
      'Enable 301  Redirect',
      <Box direction="row" align="center" key="301">
        <Switch
          disabled={domain.primary}
          defaultChecked={domain.is301}
          onChange={(e) => set301(domain.appId, domain.id, e.target.checked)}
          name="is301"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <Typography variant="body2" color="textSecondary">
          Manage redirects under the &quot;ARES&quot; Tab
        </Typography>
      </Box>,
    ],
    [
      'SSL Certificate',
      <Typography key="ssllink" variant="body2" color="textSecondary">
        {domain.link
          ? `expires ${formatDate(domain.link.expires, dateOptions)}`
          : 'This domain does not have an attached SSL Cert.'}
        <SSLLink
          certId={domain?.link?.certId}
          variant="body2"
          css={{ marginLeft: '.5rem' }}
        >
          {domain.link ? 'View Certificate' : 'Go to SSL Page'}
        </SSLLink>
      </Typography>,
    ],
    [
      'Hosting Status',
      domain.validated ? (
        <GhostTag color="green">Valid</GhostTag>
      ) : (
        <GhostTag color="red">Invalid</GhostTag>
      ),
    ],
  ];

  if (domain?.link?.certId) {
    columnData.splice(1, 0, [
      'Always use HTTPS',
      <Switch
        key="redirect"
        name="redirect"
        disabled={!domain.link?.certId}
        defaultChecked={domain.link?.redirect}
        onChange={(e) => updateCertDomainLink({
          redirect: e.target.checked,
          certId: domain.link.certId,
          aliasId: domain.id,
        })}
      />,
    ]);
  }

  if (domain.isPressDns !== null) {
    columnData.push([
      'PressDNS',
      domain.isPressDns ? (
        <GhostTag color="green">yes</GhostTag>
      ) : (
        <GhostTag color="orange">no</GhostTag>
      ),
    ]);
  }
  if (domain.provider === 'godaddy') {
    columnData.push([
      'Provider',
      <GhostTag key="provider" color="orange">
        GoDaddy
      </GhostTag>,
    ]);
  }

  return (
    <Box padding={{ left: 'medium', right: 'medium', bottom: 'medium' }}>
      <Box margin={{ bottom: 'small' }}>
        <TwoColumn variant="body2" data={columnData} />
      </Box>
      {appDomains.failed
      && appDomains.apiErrorStatusCode === 409
      && appDomains.params.domainId === domain.id ? (
        <Box
          direction="row"
          key="error"
          justify="center"
          padding={{ bottom: 'small' }}
        >
          <ErrorText>{appDomains.apiErrors}</ErrorText>
        </Box>
        ) : null}
      <Box direction="row" justify="space-between" gap="small">
        <Box direction="row" gap="xsmall">
          <span>
            <Tooltip
              title={domain.primary ? 'Make Another Domain Primary First' : ''}
              position="top"
            >
              <span>
                <Button
                  variant="outlined"
                  disabled={
                    !!domain.fqdn.match(PRESSDNS_REGEX)
                    || domain.primary
                    || appDomains.doing
                    || fsaIsDisabling
                  }
                  onClick={() => onUpdateState({ openMoveDialog: true, domain })}
                >
                  Move Domain
                </Button>
              </span>
            </Tooltip>
          </span>
          <span>
            <Button
              disabled={domain.primary || appDomains.doing}
              variant="outlined"
              onClick={() => {
                onPromoteDomain(
                  domain.appId,
                  domain.id,
                  !domain.fqdn.match(PRESSDNS_REGEX),
                  domain.fqdn,
                );
              }}
            >
              Promote to primary
            </Button>
          </span>
          <span>
            <Button
              disabled={!!domain.fqdn.match(PRESSDNS_REGEX)}
              variant="outlined"
              onClick={() => {
                onRequestValidation({ [domain.id]: true });
                setOpen(true);
              }}
            >
              Validate DNS
            </Button>
          </span>
        </Box>
      </Box>
    </Box>
  );
};

DomainDetails.propTypes = {
  appDomains: object,
  domain: object,
  fsaIsDisabling: bool,
  onPromoteDomain: func.isRequired,
  onRequestValidation: func.isRequired,
  onUpdateState: func.isRequired,
  set301: func.isRequired,
  setOpen: func.isRequired,
  updateCertDomainLink: func.isRequired,
};

export default DomainDetails;
