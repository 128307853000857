import React, { useReducer } from 'react';
import { object, array } from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

import {
  collaboratorPropTypes,
} from 'shared/modules/account/models/model';
import { roles as ROLES } from 'shared/modules/permissions/user/actions';

import Person from 'shared/styleguide/molecules/Person';

const reducer = (state, action) => {
  switch (action.type) {
    case 'toggle': {
      // remove email from
      return { ...state, sendEmails: action.toggled };
    }
    case 'check': {
      const newState = structuredClone(state);
      const { alertTypes, pushAlerts } = newState;
      pushAlerts[alertTypes.indexOf(action.alertType)] = action.checked;
      // check if emails can still be sent
      if (!pushAlerts.some(Boolean)) {
        newState.disableSendEmail = true;
      } else {
        newState.disableSendEmail = false;
      }
      return newState;
    }
    default:
      return state;
  }
};

const AlertRow = (props) => {
  const { alertTypes, alerts: deliverySettings, myRole } = props;
  const firstColumn = makeStyles((theme) => ({
    head: {
      background: 'none',
      borderBottom: 'none',
      paddingBottom: theme.spacing(0),
    },
    body: {
      lineHeight: '0.8rem',
    },
  }))();
  const initialState = {
    alertTypes,
    pushAlerts: deliverySettings ? Object.entries(deliverySettings).reduce((acc, [type, deliveries]) => {
      acc[alertTypes.indexOf(type)] = deliveries.includes('push');
      return acc;
    }, []) : [],
    sendEmails: Boolean(deliverySettings && Object.values(deliverySettings).find((s) => s?.includes('email'))),
    disableSendEmail: deliverySettings && ((myRole < props.role) || ([].concat(...Object.values(deliverySettings)).length === 0)),
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const getDeliverySettings = (alerts, deliveryTypes) => {
    return alerts.reduce((acc, current, index) => {
      if (current) {
        const alertType = state.alertTypes[index];
        acc.push({
          alertType,
          deliveryTypes,
        });
      }
      return acc;
    }, []);
  };

  const handleCheck = (e) => {
    const body = {
      alertType: e.target.name,
      deliveryTypes: [],
    };
    if (e.target.checked) {
      body.deliveryTypes.push('push');
      if (state.sendEmails) {
        body.deliveryTypes.push('email');
      }
    }
    dispatch({
      type: 'check',
      checked: e.target.checked,
      ...body,
    });

    props.setDeliveryForRecipient(props.targetId, props.sourceId, body);
  };

  const toggleEmails = (e) => {
    dispatch({
      type: 'toggle',
      toggled: e.target.checked,

    });
    let newDeliverySettings = [];

    if (e.target.checked) {
      // get list of checkmarked things
      newDeliverySettings = getDeliverySettings(state.pushAlerts, ['push', 'email']);
    } else {
      // get list of checkmarked things
      newDeliverySettings = getDeliverySettings(state.pushAlerts, ['push']);
    }

    newDeliverySettings.forEach((setting) => {
      props.setDeliveryForRecipient(props.targetId, props.sourceId, setting);
    });
  };

  return (
    <TableRow hover>
      <TableCell classes={firstColumn}>
        <Person
          name={props.accessedByName}
          email={props.accessedByEmail}
          id={props.sourceId}
          role={props.role}
        />
      </TableCell>
      {
        alertTypes.map((alertType, i) => {
          const isOwner = props.role === ROLES.PRIMARY;
          const checked = isOwner || state.pushAlerts[i];
          return (
            <TableCell key={alertType} align="center" padding="none">
              <Checkbox
                name={alertType}
                checked={checked}
                onChange={handleCheck}
                disabled={isOwner}
              />
            </TableCell>
          );
        })
      }
      <TableCell key="emails" align="center">
        <Switch
          name="email"
          checked={state.sendEmails}
          disabled={state.disableSendEmail}
          onChange={toggleEmails}
        />
      </TableCell>
    </TableRow>
  );
};

AlertRow.propTypes = {
  ...collaboratorPropTypes,
  alerts: object,
  alertTypes: array,
};

export default AlertRow;
