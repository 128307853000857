/* eslint-disable react/jsx-key */
/* eslint react/no-multi-comp: "off", no-nested-ternary: "off" */
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes, {
  object, func, any, shape,
} from 'prop-types';
import { useRouteMatch } from 'react-router';

import { Tooltip, Typography, useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Visibility';
import TrashIcon from '@material-ui/icons/Delete';

import { arrayOfPropTypes } from 'shared/modules/ssl/models/certificate';
import Linker from 'shared/styleguide/atoms/Links/Linker';
import { removeCert } from 'shared/modules/ssl/redux/cert/actions';
import CertType from 'shared/modules/ssl/components/CertType';
import ReactTable from 'shared/styleguide/organisms/ReactTable';
import Box from 'shared/styleguide/atoms/Box';
import Empty from 'shared/styleguide/atoms/Empty';

const ExpiresCell = ({
  row: {
    original: { expires, ...original },
  },
}) => {
  const theme = useTheme();
  const m = moment(expires);
  const now = moment();
  const weekWarn = moment().add(7, 'days');
  const monthWarn = moment().add(30, 'days');
  const ninetyDayWarn = moment().add(90, 'days');

  let warnColor = {};
  let message = m.fromNow(true);
  const date = m.format('ll');

  // Is the cert Let's Encrypt
  if (original.source === 'LETSENCRYPT') {
    if (m.isBefore(now)) {
      message = `expired for ${message}`;
      warnColor = { color: theme.palette.error.main };
    } else if (m.isBefore(ninetyDayWarn)) {
      // no color
      message = `renews in ${message}`;
    } else if (m.isBefore(monthWarn)) {
      // renews in 30 days, show golden
      message = `renews in ${message}`;
      warnColor = { color: theme.palette.warning.light };
    } else if (m.isBefore(weekWarn)) {
      // renews in 7 days, show golden
      message = `renews in ${message}`;
      warnColor = { color: theme.palette.warning.main };
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (m.isBefore(now)) {
      //eslint-disable-line
      message = `expired for ${message}`;
      warnColor = { color: theme.palette.error.main };
    } else if (m.isBefore(ninetyDayWarn)) {
      // no color
      message = `expires in ${message}`;
    } else if (m.isBefore(monthWarn)) {
      // expires in 30 days, show golden
      message = `expires in ${message}`;
      warnColor = { color: theme.palette.warning.light };
    } else if (m.isBefore(weekWarn)) {
      // expires in 7 days, show golden
      message = `expires in ${message}`;
      warnColor = { color: theme.palette.warning.main };
    }
  }

  return (
    <span
      style={
        Object.keys(warnColor).length > 0
          ? warnColor
          : { color: theme.palette.text.primary }
      }
      title={expires}
    >
      {date}
      {m.isBefore(ninetyDayWarn) && (
        <Fragment>
          <br />
          {message}
        </Fragment>
      )}
    </span>
  );
};

ExpiresCell.propTypes = {
  row: PropTypes.shape({
    expires: PropTypes.string,
    original: object,
  }),
};

const DomainsAttachedCell = ({
  row: {
    original: { activeCount, ...original },
  },
}) => {
  const plural = activeCount === 1 ? '' : 's';
  return (
    <Tooltip
      interactive
      title={(
        <div style={{ maxHeight: 150, overflow: 'scroll' }}>
          {original.alternateNames.map((site) => (
            <div key={site}>{site}</div>
          ))}
        </div>
      )}
      placement="right"
    >
      <span style={{ textAlign: 'center' }}>
        {`${activeCount} active domain${plural}`}
        <br />
        {`${original.domainCount} total domain${plural}`}
      </span>
    </Tooltip>
  );
};

DomainsAttachedCell.propTypes = {
  row: PropTypes.shape({
    original: shape({
      activeCount: PropTypes.number.isRequired,
    }),
  }),
};

const CertTypeCell = ({ row: { original } }) => (
  <CertType
    type={original.certType}
    name={original.subjectData.name} // eslint-disable-line no-underscore-dangle
    alternateNames={original.alternateNames} // eslint-disable-line no-underscore-dangle
  />
);

CertTypeCell.propTypes = {
  row: shape({
    original: PropTypes.shape({
      certType: PropTypes.string.isRequired,
    }),
  }),
};

const IssuedByCell = ({
  row: {
    original: { issuedBy, ...original },
  },
}) => {
  return (
    <span title={issuedBy} style={{ textAlign: 'center' }}>
      {issuedBy || original?.issuerData?.organization}
    </span>
  );
};

IssuedByCell.propTypes = {
  row: object,
};

const SubjectCell = ({
  row: {
    original: { subject, ...original },
  },
}) => {
  const match = useRouteMatch();

  let children = subject;
  if (subject === 'CloudFlare Origin Certificate') {
    children = (
      <Box>
        <Typography variant="subtitle1">
          {original.alternateNames[0]}
        </Typography>
        <Typography variant="caption">{subject}</Typography>
      </Box>
    );
  }

  return (
    <Linker
      to={`${match.url}/cert/${original.id}`}
      color="textPrimary"
      variant="subtitle2"
    >
      {children}
    </Linker>
  );
};

SubjectCell.propTypes = {
  row: object,
};

export const CertList = (props) => {
  const { certs } = props;
  const [removing, setRemoving] = useState(null);

  if (certs.length === 0) {
    return (
      <Box fullWidth>
        <Empty>No results.</Empty>
      </Box>
    );
  }

  const handleRemove = (certId) => {
    setRemoving(certId);
    props.removeCert(certId);
  };

  /* eslint-disable react/prop-types */
  const ActionsCell = (props) => {
    const {
      row: { original },
    } = props;
    const match = useRouteMatch();

    return (
      <div css={{ marginRight: 10 }}>
        <IconButton
          component={Linker}
          to={`${match.url}/cert/${original.id}`}
          size="small"
        >
          <ViewIcon />
        </IconButton>
        <IconButton
          onClick={() => handleRemove(original.id)}
          disabled={original.activeCount > 0 || removing === original.id}
          size="small"
        >
          <TrashIcon />
        </IconButton>
      </div>
    );
  };
  /* eslint-enable react/prop-types */

  const columns = [
    {
      Header: 'Subject',
      accessor: 'subject',
      Cell: SubjectCell,
      props: {
        css: {
          maxWidth: 350,
        },
      },
    },
    {
      Header: 'Expires',
      accessor: 'expires',
      Cell: ExpiresCell,
    },
    {
      Header: 'Domains Attached',
      accessor: 'activeCount',
      Cell: DomainsAttachedCell,
      props: {
        css: {
          minWidth: 160,
        },
      },
    },
    {
      Header: 'Cert Type',
      accessor: 'certType',
      Cell: CertTypeCell,
      props: {
        css: {
          minWidth: 140,
        },
      },
    },
    {
      Header: 'Issuer',
      accessor: 'issuedBy',
      Cell: IssuedByCell,
    },
    {
      Header: 'Actions',
      accessor: 'delete',
      Cell: ActionsCell,
      width: 40,
      props: {
        align: 'center',
        handleRemove,
        removing,
        css: {
          minWidth: 80,
        },
      },
    },
  ];

  return (
    <>
      <ReactTable
        key="certlistTable"
        data={certs}
        columns={columns}
        sort
        paginate
        memoDependecies={[certs]}
        namespaceKey="ssl.certTable"
      />
    </>
  );
};

CertList.propTypes = {
  certs: arrayOfPropTypes,
  removeCert: func,
  total: any,
};

const mapDispatchToProps = {
  removeCert,
};

export default connect(null, mapDispatchToProps)(CertList);
