import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'shared/utils';
import { TYPE_CONTACT, TYPE_CREDENTIALS, TYPE_MIGRATION } from 'admin/modules/notes/constants';

const NoteText = (props) => {
  let text = '';

  switch (props.type) {
    case TYPE_MIGRATION:
    case TYPE_CREDENTIALS:
      text = 'Encrypted Data';
      break;
    case TYPE_CONTACT:
      try {
        text = JSON.parse(props.note);
        text = text.slice(0, 2).map((d) => `${d.label}: ${d.value}`).join(' | ');
      } catch (e) {
        text = 'Invalid JSON';
      }
      break;
    default:
      text = truncate(props.note, 200); // css control from here with ellipses
  }

  return (
    <div
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {text}
    </div>
  );
};

NoteText.propTypes = {
  contentType: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default NoteText;
