import React, { useState, useCallback } from 'react';
import PropTypes, { shape } from 'prop-types';
import moment from 'moment-timezone';

import { getComparator, stableSort } from 'shared/utils/sorting';
import CollapsibleTable from 'shared/styleguide/molecules/CollapsibleTable';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

import ConfigsShortcuts from './ConfigsShortcuts';
import ConfigsDetail from './ConfigsDetail';

import { headCells } from '../redux/constants';

const statusColorMap = {
  draft: 'smoke',
  published: 'green',
};

export const ConfigsList = ({ items, accountId }) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdDate');

  const sortedItems = stableSort(items, getComparator(order, orderBy));

  const memoizedSortedItems = useCallback(sortedItems, [sortedItems, items, order, orderBy]);

  const handleSetOrderBy = (value) => {
    setOrderBy(value);
  };

  const handleSetOrder = (value) => {
    setOrder(value);
  };

  const ListRows = memoizedSortedItems.map((item) => {
    return {
      data: item,
      display: [
        item.type,
        item.appId || null,
        item.file,
        <GhostTag key={`rowStatus-${item.file}`} color={statusColorMap[item.status]}>{item.status}</GhostTag>,
        item.modifiedDate ? `${moment(item.modifiedDate).tz('UTC').format('YYYY-MM-DD HH:mm')} UTC` : '',
        <ConfigsShortcuts key={`rowShortcuts-${item.file}`} accountId={accountId} appId={item.appId} />,
      ],
    };
  });

  return (
    <CollapsibleTable
      pageLength={10}
      headCells={headCells}
      ListRows={ListRows}
      Detail={ConfigsDetail}
      onSetOrder={handleSetOrder}
      onSetOrderBy={handleSetOrderBy}
      order={order}
      orderBy={orderBy}
      paginate
    />
  );
};

ConfigsList.propTypes = {
  accountId: PropTypes.number,
  items: PropTypes.arrayOf(shape({})),
};

export default ConfigsList;
