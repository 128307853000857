import apiReducer, { baseCollectionState } from 'shared/utils/redux/apiReducer';

import * as ACCOUNT from './constants';

const accountSSHKeysCollection = apiReducer(
  ACCOUNT.ACCOUNT_SSH_KEYS_REQUEST,
  ACCOUNT.ACCOUNT_SSH_KEYS_SUCCESS,
  ACCOUNT.ACCOUNT_SSH_KEYS_FAILURE,
  ACCOUNT.ACCOUNT_SSH_KEYS_RESET,
  { ...baseCollectionState },
);
export const accountSSHKeys = (state = { ...baseCollectionState }, action) => {
  switch (action.type) {
    case ACCOUNT.ACCOUNT_SSH_KEY_CREATE_SUCCESS: {
      const items = [...state.data];
      items.push(action.data);
      return {
        ...state,
        data: items,
      };
    }
    case ACCOUNT.ACCOUNT_SSH_KEY_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter((k) => k.id !== action.params.sshKeyId),
      };
    default:
      return accountSSHKeysCollection(state, action);
  }
};

export const accountSSHKey = apiReducer(
  ACCOUNT.ACCOUNT_SSH_KEY_CREATE_REQUEST,
  ACCOUNT.ACCOUNT_SSH_KEY_CREATE_SUCCESS,
  ACCOUNT.ACCOUNT_SSH_KEY_CREATE_FAILURE,
  ACCOUNT.ACCOUNT_SSH_KEY_RESET,
);
