import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import BillingIcon from '@material-ui/icons/AttachMoney';
import AppsIcon from '@material-ui/icons/Apps';

import { Typography } from '@material-ui/core';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import ReactTable from 'shared/styleguide/organisms/ReactTable';
import { arrayOfPropTypes } from 'shared/modules/account/models/account';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import AtomicIcon from 'shared/styleguide/Icons/Atomic';

export const STORAGE_KEY = 'recentAccounts';

const addToLocalStorage = (account) => {
  const ACCOUNTS_LIMIT = 10;
  const oldItems = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];

  // we don't want duplicates
  // is it already in the thing?
  const findIndex = oldItems.findIndex((item) => item.id === account.id);
  if (findIndex === -1) {
    if (oldItems.length >= ACCOUNTS_LIMIT) {
      oldItems.pop();
    }
  } else {
    oldItems.splice(findIndex, 1);
  }

  oldItems.unshift(account);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(oldItems));
};

export const AccountList = (props) => {
  const { accounts, history } = props;

  const accountShortcuts = [
    {
      name: 'settings/profile',
      Icon: SettingsIcon,
    },
    {
      name: 'billing',
      Icon: BillingIcon,
    },
    {
      name: 'apps',
      Icon: AppsIcon,
    },
  ];

  const handleShortcutClick = (accountID, shortcutName, account) => {
    addToLocalStorage(account);
    history.push(`account/${accountID}/${shortcutName}`);
  };

  const getNameCell = ({ row }) => {
    return (
      <TextLink
        to={`/account/${row.original.id}`}
        onClick={() => {
          addToLocalStorage(row.original);
        }}
      >
        {row.original.name}
        {row.original.collabMatchName ? (
          <div>
            <Typography
              component="span"
              color="textSecondary"
              variant="body2"
            >
              Collaborator: {row.original.collabMatchName}
            </Typography>
          </div>
        ) : null}
        {!row.original.active ? (
          <div>
            <GhostTag color="red">Deactivated</GhostTag>
          </div>
        ) : null}
      </TextLink>
    );
  };

  const getShortcutsCell = ({ row }) => {
    return (
      <Fragment>
        {accountShortcuts.map((shortcut, index) => {
          const { Icon, disabled, text } = shortcut;
          return (
            <IconButton
              onClick={() => handleShortcutClick(row.original.id, shortcut.name, row.original)}
              style={{
                marginRight: 8, width: 30, height: 30, padding: 1,
              }}
              disabled={disabled}
              key={shortcut.name}
              title={text}
            >
              <Icon
                key={`shortcut-${index}`}
              />
            </IconButton>
          );
        })}
        <span>
          <IconButton
            style={{
              marginRight: 8, width: 30, height: 30, padding: 1,
            }}
            title="Photon"
            component="a"
            href={`https://photon.pagely.com/pagely/accounts/details/${row.original.id}`}
            target="_photon"
            key="photon"
          >
            <AtomicIcon />
          </IconButton>
        </span>
      </Fragment>
    );
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: getNameCell,
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Company name',
      accessor: 'companyName',
    },
    {
      Header: 'Shortcuts',
      accessor: 'shortcuts',
      Cell: getShortcutsCell,
      props: {
        align: 'right',
      },
    },
  ];

  return (
    <ReactTable
      columns={columns}
      data={accounts}
      paginate
    />
  );
};

AccountList.propTypes = {
  accounts: arrayOfPropTypes,
  history: PropTypes.object.isRequired,
};

export default AccountList;
