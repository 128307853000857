import React, { Component } from 'react';
import { connect } from 'react-redux';

import AddIcon from '@material-ui/icons/AddBox';
import { Typography } from '@material-ui/core';

import { func, object } from 'prop-types';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Card from 'shared/styleguide/atoms/Card/Card';

import { getBannedPlugins, addBannedPlugins } from 'shared/modules/support/redux/actions';

import AddPlugin from './AddPlugin';
import PluginsTable from './PluginsTable';
import styles from './BannedPlugins.scss';

export class BannedPluginsList extends Component {
  static propTypes = {
    addBannedPlugins: func.isRequired,
    bannedPlugins: object.isRequired,
    getBannedPlugins: func.isRequired,
  };

  state = {
    showAddField: false,
  };

  componentDidMount() {
    this.props.getBannedPlugins();
  }

  render() {
    const { bannedPlugins } = this.props;

    if (!bannedPlugins.loaded) {
      return (
        <Box padding="medium">
          <Typography variant="h2" gutterBottom>Banned Plugins List</Typography>
          <Card className={styles.card}>
            <div style={{ textAlign: 'right', marginBottom: 20 }}>
              <Loading />
            </div>
          </Card>
        </Box>
      );
    }

    return (
      <Box padding="medium">
        <Typography variant="h2" gutterBottom>Banned Plugins List</Typography>
        <div>
          <div style={{ textAlign: 'right', marginBottom: 20 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              label="Add To List"
              onClick={() => { this.setState((state) => ({ showAddField: !state.showAddField })); }}
            />
          </div>

          <div>{this.state.showAddField && (<AddPlugin onSubmit={this.props.addBannedPlugins} />)}</div>

          <PluginsTable
            data={bannedPlugins.data}
          />
        </div>
      </Box>
    );
  }
}

export default connect(
  ({ support: { bannedPlugins } }) => ({ bannedPlugins }),
  {
    getBannedPlugins,
    addBannedPlugins,
  },
)(BannedPluginsList);
