import {
  useEffect, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as STATUS from 'shared/modules/status/redux/constants';
import { fetchDeploymentLogsForJob } from 'shared/modules/app/redux/logging/actions';
import { consolidateErrors } from 'shared/utils/validation';
import { useMergeState } from 'shared/hooks/useMergeState';

import { INTEGRATION, WEBHOOK } from '../constants';
import { generateLogs, createMeta } from '../utils';

const initialState = {
  data: [],
  formattedLogs: null,
  status: 'pristine',
  errors: null,
  displayStatus: null,
  warnings: null,
  totalTime: null,
  logsLength: 0,
  fetchedOnce: false,
};

const logStoreType = {
  [INTEGRATION]: 'gitIntegration',
  [WEBHOOK]: 'gitWebhook',
};

export const useLogs = (job, type, expanded) => {
  const { state, mergeState } = useMergeState({ ...initialState });

  const dispatch = useDispatch();
  // LOGS data from store
  const unmemoizedData = useSelector((state) => {
    return state.logs?.[logStoreType[type]]?.data?.[job.id];
  }) || {};

  const logItemData = useCallback(unmemoizedData, [unmemoizedData]);

  const getLogsForJob = async () => {
    mergeState({ status: 'loading' });

    try {
      const response = await dispatch(fetchDeploymentLogsForJob(job.ownerId, job.id));
      const data = response?.data?.[job.id];
      const hasNewLogs = data.logs?.length && data.logs.length !== state.logsLength;

      const update = {
        fetchedOnce: true,
      };

      if (!hasNewLogs) {
        // no formatting to do, so set status to 'success' now
        update.status = 'success';
      }
      mergeState({ ...update });
    } catch (error) {
      mergeState({
        status: 'failed',
        error: `Error: ${consolidateErrors(error)}`,
      });
    }
  };

  // GET LOGS if a job is updated and item is expanded
  const memoizedGetLogs = useCallback(getLogsForJob, [dispatch, job.ownerId, job.id]);
  const jobWatchString = job.id ? JSON.stringify([job.overallStatus, job.detail.stepDetail]) : '';
  useEffect(() => {
    // get logs if none exist or unfinished
    if (jobWatchString !== '' && expanded && !STATUS.endedStatuses.includes(logItemData.status)) {
      memoizedGetLogs();
    }
  }, [jobWatchString, expanded]);

  // FORMAT LOGS
  const logsWatchString = logItemData?.logs?.length || 0;
  useEffect(() => {
    const updatedLogs = logItemData?.logs || [];
    if (updatedLogs?.length && updatedLogs.length !== state.logsLength) {
      mergeState({
        formattedLogs: generateLogs(updatedLogs).join('\n'),
        status: 'success',
        logsLength: updatedLogs?.length,
      });
    }
  }, [logsWatchString]);

  const meta = createMeta({ ...state, data: logItemData }, job);

  return { ...state, data: logItemData, ...meta };
};

export default useLogs;
