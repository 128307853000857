import React from 'react';
import { any, string } from 'prop-types';
import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import { dotAnimation } from './Loading.css';

const Loading = ({
  size = undefined, text = '', margin = { top: 'large' }, ...rest
}) => {
  return (
    <Box flex={1} justify="center" align="center" margin={margin} {...rest}>
      <div css={{
        transform: size === 'small' ? 'scale(.7)' : 'none',
        height: 30,
      }}
      >
        <div role="progressbar" css={dotAnimation} />
      </div>
      {
        text && <Typography style={{ marginTop: 30 }}>{text}</Typography>
      }
    </Box>
  );
};

Loading.propTypes = {
  margin: any,
  size: string,
  text: string,
};

Loading.displayName = 'Loading';

export default Loading;
