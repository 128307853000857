import React from 'react';
import { Typography } from '@material-ui/core';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import Loading from 'shared/styleguide/atoms/Loading';
import type { Account } from 'shared/modules/account/types';
import type { ReduxStore } from 'shared/utils/redux/apiReducer';
import type { App } from 'shared/types/App';

interface AppInfoProps {
  account: Account & Partial<ReduxStore<any>>;
  app: ReduxStore<App>;
}

export const AppInfo = ({ account, app }: AppInfoProps): JSX.Element => {
  const { servers, isOnP20Account } = account;

  if (
    account.status === 'loading' || app.status === 'loading'
  ) {
    return (
      <Loading />
    );
  }

  if (!app.data.primaryServerId) {
    return null;
  }

  const appServer = servers.length === 1 ? servers[0] : servers.find((s) => Number(s.id) === app.data.primaryServerId);

  const data = [
    ['Server Name', appServer?.petname || 'no name set'],
    ['Hostname', appServer.hostname],
    ['IP Address', appServer.ip_ext],
  ];

  if (servers.length > 1 && app.data.primaryServerLabel) {
    data.unshift(['Server', app.data.primaryServerLabel]);
  }

  if (isOnP20Account) {
    const serverAddress = 'wp20-gw2.host.pressdns.com';
    const RecordAddress = '35.168.216.102';

    data.push(['CNAME', serverAddress]);
    data.push(['A Record', RecordAddress]);
  } else if (servers.length) {
    const serverAddress = `wp${servers[0].pool_id}.host.pressdns.com`;
    data.push(['CNAME', serverAddress]);
    data.push(['A Record', `${appServer.ip_ext || appServer.ip} (${appServer.label})`]);
  }

  // Add Webserver info
  let nginx = 'NGINX + Apache'; // nginx + apache true, toggle "whats off"
  if (app.data.config && app.data.config.nginx) {
    nginx = 'NGINX Only'; // eslint-disable-line
  }
  data.push(['Webserver', nginx]);

  data.push(['File Path', `/data/${app.data.fileNode}/dom${app.data.id}/dom${app.data.id}`]);

  return (
    <Box data-testid="app-info">
      <Typography variant="h3" gutterBottom>Primary Server Info</Typography>
      <Box as={Paper} padding="medium">
        <TwoColumn
          data={data}
          includeCopy
          noMargin
        />
      </Box>
    </Box>
  );
};

AppInfo.displayName = 'AppInfo';

export default AppInfo;
