import React, { Component } from 'react';

import AddIcon from '@material-ui/icons/AddBox';

import { func } from 'prop-types';
import Button from 'shared/styleguide/atoms/Buttons/Button';
import Select from 'shared/styleguide/atoms/Select/Select';
import CreatableSelect from 'shared/styleguide/atoms/Select/CreatableSelect';
import TextField from 'shared/styleguide/atoms/Input/TextField';

import styles from './BannedPlugins.scss';

export default class AddPlugin extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
  };

  state = {
    name: '',
    location: '',
    type: '',
    platform: '',
    errors: {
      name: '',
      location: '',
    },
  };

  options = [{ value: 'wordpress', label: 'WordPress' }];

  handleChange = (e, value, field) => {
    e && e.preventDefault(); // eslint-disable-line
    const state = {};
    state[field] = value;
    this.setState(state);
  };

  handleSubmit = () => {
    const data = { ...this.state };

    delete data.errors;

    this.props.onSubmit(data);

    this.setState({
      name: '',
      location: '',
      type: '',
      platform: '',
      errors: {
        name: '',
        location: '',
      },
    });
  };

  render() {
    return (
      <div className={styles.addContainer}>
        <div className={styles.halfWidth}>
          <CreatableSelect
            id="add-platform-select"
            options={this.options}
            onChange={(d) => this.handleChange(null, d.value, 'platform')}
            isClearable={false}
            placeholder="Select platform or add one"
          />
        </div>
        <div className={styles.halfWidth}>
          <TextField
            placeholder="Name"
            fullWidth
            name="name"
            id="name"
            onChange={(e, text) => this.handleChange(e, text, 'name')}
            errorText={this.state.errors.name}
            value={this.state.name}
          />
        </div>
        <div className={styles.halfWidth}>
          <Select
            // className={styles.select}
            id="add-record-select"
            options={[{ value: 'folder', label: 'Folder' }, { value: 'file', label: 'File' }]}
            onChange={(d) => this.handleChange(null, d.value, 'type')}
            isClearable={false}
            isSearchable={false}
            placeholder="Select type"
          />
        </div>
        <div className={styles.halfWidth}>
          <TextField
            placeholder="Location"
            fullWidth
            name="location"
            id="location"
            onChange={(e, text) => this.handleChange(e, text, 'location')}
            errorText={this.state.errors.location}
            value={this.state.location}
          />
        </div>
        <div className={styles.button}>
          <Button
            onClick={this.handleSubmit}
            type="raised"
            color="navy"
            startIcon={<AddIcon />}
          >
            New Record
          </Button>
        </div>
      </div>
    );
  }
}
