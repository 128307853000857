import logger from 'shared/3rdparty/logger';

export const filterOutCustomMounts = (mounts) => {
  // filter out "fake/custom" mounts by just using the mount on server with the highest allowed value
  const filteredMounts = {};
  mounts
    .filter((m) => m.id)
    .forEach((m) => {
      if (!filteredMounts[m.id] || (filteredMounts[m.id] && m.allowed > filteredMounts[m.id].allowed)) {
        filteredMounts[m.id] = {
          ...m,
        };
      }
    });
  return filteredMounts;
};

export const logFailedDependencies = (dependencies) => {
  const dependenciesArray = Object.values(dependencies);
  const allServiceNames = Object.keys(dependencies).join(', ');
  dependenciesArray.filter((x) => x.status === 'failed')
    .forEach((state, i) => {
      const serviceName = Object.keys(dependencies)[i];
      logger.error(new Error(`Unable to display disk data, one of [${allServiceNames}] has failed.`), {
        metaData: {
          service: serviceName,
          code: state.statusCode,
          message: state.errMessage,
          state,
        },
      });
    });
};
