export const GET_LISTS_REQUEST = 'ARES_GET_LISTS_REQUEST';
export const GET_LISTS_SUCCESS = 'ARES_GET_LISTS_SUCCESS';
export const GET_LISTS_FAILURE = 'ARES_GET_LISTS_FAILURE';

export const SET_LIST_REQUEST = 'ARES_SET_LIST_REQUEST';
export const SET_LIST_SUCCESS = 'ARES_SET_LIST_SUCCESS';
export const SET_LIST_FAILURE = 'ARES_SET_LIST_FAILURE';

export const CREATE_LIST_REQUEST = 'ARES_CREATE_LIST_REQUEST';
export const CREATE_LIST_SUCCESS = 'ARES_CREATE_LIST_SUCCESS';
export const CREATE_LIST_FAILURE = 'ARES_CREATE_LIST_FAILURE';

export const DELETE_LIST_REQUEST = 'ARES_DELETE_LIST_REQUEST';
export const DELETE_LIST_SUCCESS = 'ARES_DELETE_LIST_SUCCESS';
export const DELETE_LIST_FAILURE = 'ARES_DELETE_LIST_FAILURE';

export const FETCH_GEO_REQUEST = 'ARES_FETCH_GEO_REQUEST';
export const FETCH_GEO_SUCCESS = 'ARES_FETCH_GEO_SUCCESS';
export const FETCH_GEO_FAILURE = 'ARES_FETCH_GEO_FAILURE';
