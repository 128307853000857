import tracker from 'shared/3rdparty/pageTracking';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as APP from '../constants';

export const getAllBackups = (appId) => makeApiAction(
  ({ api }) => api().get(`/apps/${appId}/backups`, {
  }),
  APP.APP_GET_BACKUPS_REQUEST,
  APP.APP_GET_BACKUPS_SUCCESS,
  APP.APP_GET_BACKUPS_FAILURE,
);

export const getBackup = (appId, backupId) => makeApiAction(
  ({ api }) => {
    tracker.push({
      event: 'atomic_app_get_backups',
      appId,
    });

    return api().get(`/apps/${appId}/backups/${backupId}`, {
    });
  },
  APP.APP_GET_BACKUP_REQUEST,
  APP.APP_GET_BACKUP_SUCCESS,
  APP.APP_GET_BACKUP_FAILURE,
  {},
  true,
);

export const resetBackups = () => ({
  type: APP.APP_BACKUPS_RESET,
});

export const resetBackup = () => ({
  type: APP.APP_BACKUP_RESET,
});
