import React from 'react';
import { array } from 'prop-types';

import { Paper, Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import { remMapper } from 'shared/styleguide/theme/spacing';

const CustomConfigDescription = ({ customConfig }) => {
  if (customConfig) {
    const messages = [...customConfig];

    const atomicRedirectsIndex = customConfig.findIndex((v) => v === 'atomic redirects');
    if (atomicRedirectsIndex > -1) {
      messages.splice(atomicRedirectsIndex, 1, 'Legacy redirects found: use REDIRECTS tab to edit them');
    }

    return (
      <Paper
        css={{ marginBottom: remMapper('small') }}
      >
        <Box padding="small">
          <Typography variant="subtitle2" gutterBottom>Summary of custom configurations:</Typography>
          <Typography variant="body2" color="textSecondary">{messages.join('.\n')}</Typography>
        </Box>
      </Paper>
    );
  }
  return <Box margin={{ bottom: 'small' }} />;
};

CustomConfigDescription.propTypes = {
  customConfig: array,
};

export default CustomConfigDescription;
