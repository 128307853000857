import React from 'react';
import { useTheme } from '@material-ui/core';
import { states } from 'shared/utils/constants';
import Select from 'shared/styleguide/atoms/Select/Select';

interface Props {
  onChange: any;
  value: string;
  styles: Record<string, any>;
  hasError: boolean;
}

const options = states.map(({ name, abbr }) => ({
  label: name,
  value: abbr,
}));

const StateSelector: React.FC<Props> = ({
  onChange, value, styles, hasError,
}) => {
  const theme = useTheme();

  return (
    <div>
      <Select
        aria-labelledby="state-label"
        id="state-select"
        options={options}
        isClearable={false}
        onChange={onChange}
        value={options.find((option) => [option.value, option.label].includes(value))}
        placeholder="State"
        styles={{
          control: (provided) => (hasError ? {
            ...provided, borderColor: 'red',
          } : {
            ...provided,
            background: theme.color100,
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 100,
            background: theme.color100,
          }),
          ...styles,
        }}
      />
    </div>
  );
};

export default StateSelector;
