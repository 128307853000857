export function validateRecord(recordType, subdomain, values) {
  const missingValues = !values.length || values.includes('');
  const errors = {
    values: missingValues ? 'You must add a valid value' : '',
  };

  if (!subdomain && !['TXT', 'MX', 'A', 'SRV'].includes(recordType)) {
    errors.subdomain = 'Subdomain is required';
  }

  if (missingValues) {
    return errors;
  }

  // check if records are enclosed in quotation marks for TXT + SPF record types
  const quotesRegex = /^"[^"]+"$/;
  if (['TXT', 'SPF'].includes(recordType)) {
    for (let i = 0; i < values.length; i++) {
      const isValid = quotesRegex.test(values[i]);
      errors.values = isValid ? '' : recordType.concat(' values must be enclosed in quotation marks (")');
      if (!isValid) {
        break;
      }
    }
  }

  if (!errors.values) {
    delete errors.values;
  }

  return errors;
}
