import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const WordPress = () => (
  <SvgIcon fontSize="inherit">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="1px"
      y="1px"
      width="22"
      height="22"
      viewBox="0 0 226 226"
    >
      <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
        <path d="M0,226.00002v-226.00002h226.00002v226.00002z" fill="none" />
        <g fill="currentColor">
          <path
            d="M113.27163,0c-62.13641,0 -112.72837,50.59195 -112.72837,112.72837c0,62.13642 50.59195,112.72837 112.72837,112.72837c62.13642,0 112.72837,-50.59194 112.72837,-112.72837c0,-62.13641 -50.59194,-112.72837 -112.72837,-112.72837zM113.27163,5.16106c59.28426,0 107.56731,48.28306 107.56731,107.56731c0,59.28426 -48.28305,107.56731 -107.56731,107.56731c-59.28425,0 -107.56731,-48.28305 -107.56731,-107.56731c0,-59.28425 48.28306,-107.56731 107.56731,-107.56731zM113.27163,16.02644c-33.78456,0 -63.39273,17.21485 -80.67548,43.46154c2.27494,0.06791 4.41407,0.27163 6.2476,0.27163c10.11839,0 25.80529,-1.35817 25.80529,-1.35817c5.19501,-0.30559 5.73828,7.53786 0.54327,8.14904c0,0 -5.33083,0.50932 -11.13702,0.8149l35.3125,104.57933l21.1875,-63.29087l-15.21154,-41.28846c-5.19501,-0.30559 -10.05048,-0.8149 -10.05048,-0.8149c-5.22896,-0.30559 -4.65174,-8.45463 0.54327,-8.14904c0,0 16.02644,1.35817 25.53365,1.35817c10.11839,0 25.80529,-1.35817 25.80529,-1.35817c5.22897,-0.30559 5.73828,7.53786 0.54327,8.14904c0,0 -5.33083,0.50932 -11.13702,0.8149l35.04087,103.76442l9.50721,-32.05288c4.88942,-12.5631 7.33413,-22.95312 7.33413,-31.23798c0,-11.95192 -4.17638,-20.20283 -7.8774,-26.62019c-4.88942,-7.97927 -9.50721,-14.83804 -9.50721,-22.81731c0,-8.86208 6.79087,-17.11298 16.29808,-17.11298c0.4414,0 0.91677,-0.03395 1.35817,0c-17.21485,-15.75481 -40.30379,-25.26202 -65.46394,-25.26202zM198.02163,66.27885c0.4414,3.08985 0.8149,6.48528 0.8149,10.05048c0,9.8128 -1.83353,20.71214 -7.33413,34.4976l-29.60817,85.29327c28.72536,-16.77343 48.07933,-47.73978 48.07933,-83.39183c0,-16.80739 -4.41406,-32.69802 -11.95192,-46.44952zM24.99038,73.34135c-5.36478,12.01984 -8.42067,25.36388 -8.42067,39.38702c0,38.26653 22.34195,71.23618 54.59856,86.92308zM114.90144,121.14904l-28.79327,84.20673c8.65836,2.54658 17.65625,4.07452 27.16346,4.07452c11.23888,0 22.0024,-2.17308 32.05288,-5.70433c-0.23768,-0.40745 -0.33954,-0.61117 -0.54327,-1.08654z"
          />
        </g>
      </g>
    </svg>
  </SvgIcon>
);
WordPress.displayName = 'WordPressIcon';
export default WordPress;
