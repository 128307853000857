/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@material-ui/core';
import * as STATUS from 'shared/modules/status/redux/constants';
import Box from 'shared/styleguide/atoms/Box';

const JobStep = ({ jobStatus }) => {
  return (
    <Box key={jobStatus.id} direction="row" margin="xsmall" gap="medium" align="center">
      <Box>
        {STATUS.iconMap[jobStatus.status]}
      </Box>
      <Box>
        <Typography variant="subtitle2">{jobStatus.label}</Typography>
      </Box>
    </Box>
  );
};

export default JobStep;
