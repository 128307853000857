import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Account } from 'shared/modules/account/types';
import { getDiskUsageByMount, getDimensionsByMount } from 'shared/modules/analytics/redux/actions/disk';
import Box from 'shared/styleguide/atoms/Box';
import ProgressView from 'shared/styleguide/molecules/ProgressView';
import DiskLink from 'shared/modules/disk/Link';
import DiskIcon from 'shared/styleguide/Icons/SVGs/Disk';
import Loading from 'shared/styleguide/atoms/Loading';
import { LOADING, PRISTINE } from 'shared/utils/redux/constants';

import { PERCENT_THRESHOLD } from '../../constants';

const start = moment().subtract(30, 'days').startOf('day');
const end = moment().endOf('day');

interface DiskStatsProps {
  account: Account;
  getDiskUsage: (account: number, options: Record<string, any>) => Promise<any>;
  getDimensions: (account: number, options: Record<string, any>) => Promise<any>;
  mountDimensions;
  mountUsage;
}

const DiskStats = ({
  account,
  getDiskUsage,
  getDimensions,
  mountDimensions,
  mountUsage,
}: DiskStatsProps) => {
  // load Disk Stats
  const fetchDimensions = useCallback(async () => {
    if (mountDimensions.status === PRISTINE || (mountDimensions.status !== LOADING && mountDimensions.params?.accountId && mountDimensions.params?.accountId !== account.id)) {
      const res = await getDimensions(account.id, {
        columns: ['time', 'storage_used', 'storage_total'],
      });
      const serverName = res.data?.dimensions?.serverName;
      await getDiskUsage(account.id, {
        serverName,
        fromDate: start.format(),
        toDate: end.format(),
      });
    }
  }, [account.id, mountDimensions, mountUsage]);

  useEffect(() => {
    fetchDimensions();
  }, [fetchDimensions]);

  const storageMax = 0;

  if (!mountUsage.data?.series?.length) {
    return <Loading />;
  }

  const mountData = mountUsage.data.series
    .map((mount, index, array) => {
      // check that "inodes_used", "storage_used", "storage_total" are not null
      const values = mount.values.filter((m) => m.every((r) => r !== null));

      if (values.length < 1) {
        return 0;
      }
      const server = account.servers.find((s) => mount.tags.serverName === s.petname);

      const usage = values.slice(-1)[0][4];
      const totalMax = array.length === 1 ? account.limits.totalDisk : values.slice(-1)[0][5];
      /* eslint-enable prefer-destructuring */
      return {
        name: mount.tags?.serverName,
        usage,
        allowed: totalMax,
      };
    });

  const diskUsage = mountData;

  return (
    <Box flex={1} gap="small">
      {
        diskUsage.map(({ usage, allowed, name }) => {
          const diskPercent = (usage / allowed) * 100;
          return (
            <ProgressView
              key={name}
              {...{
                heading: (<DiskLink>Disk ({name})</DiskLink>),
                icon: <DiskIcon />,
                value1: `${usage}`,
                value2: `${allowed} GiB`,
                percentage: diskPercent,
                threshold: PERCENT_THRESHOLD,
                errorText: 'Upgrade Soon',
              }}
            />
          );
        })
      }
    </Box>
  );
};

export default connect(
  (state: { account; analytics }) => ({
    account: state.account,
    mountUsage: state.analytics.disk.byMount.usage,
    mountDimensions: state.analytics.disk.byMount.dimensions,
  }),
  {
    getDimensions: getDimensionsByMount,
    getDiskUsage: getDiskUsageByMount,
  },
)(DiskStats);
