import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';

import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  makeStyles,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import { deepClone } from 'shared/utils';
import InfoText from 'shared/styleguide/molecules/InfoText';
import Stepper from 'shared/styleguide/molecules/Stepper';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { FormikValues } from './types';

interface Props {
  step: number;
  useStepState: [number, CallableFunction];
}

const SetPressDNS = ({ step, useStepState }: Props) => {
  const [modalStep, setModalStep] = useStepState;
  const formikProps = useFormikContext<FormikValues>();

  const domains = Object.values(formikProps.values.availibilityDomains);
  const allDomains = domains.map((d) => d.domain);
  const parentDomains = {};

  // sort domains into tlds and subs
  domains.forEach((domain) => {
    // if its a subdomain without a parent
    if (!allDomains.includes(domain.tld)) {
      parentDomains[domain.domain] = { ...domain, subdomains: [] };
    } else if (domain.domain === domain.tld) {
      if (parentDomains[domain.tld]) {
        parentDomains[domain.tld] = { subdomains: parentDomains[domain.tld].subdomains, ...domain };
      } else {
        parentDomains[domain.domain] = { ...domain, subdomains: [] };
      }
    } else {
      if (!parentDomains[domain.tld]) {
        parentDomains[domain.tld] = { subdomains: [] };
      }
      // if its a subdomain on an existing domain?
      parentDomains[domain.tld].subdomains.push(domain);
    }
  });

  const setPressDns = (domainsToUpdate, value) => {
    const { values, setFieldValue } = formikProps;
    const availabilityDomains = deepClone(values.availibilityDomains) as Record<string, Record<string, unknown>>;
    domainsToUpdate.forEach((d) => {
      availabilityDomains[d] = {
        ...availabilityDomains[d],
        usePressDNS: value,
      };
    });
    setFieldValue('availibilityDomains', availabilityDomains);
  };

  const handleNext = () => {
    // if we are opted into FSA and pressDNS, add a step.
    const { values, setFieldValue } = formikProps;

    if (values.stepperSteps.length === 4) {
      setFieldValue('stepperSteps', values.stepperSteps.concat(['a']));
    }

    // now we need to call the action that does the magic
    setModalStep(modalStep + 1);
  };

  const handleBack = () => {
    setModalStep(modalStep - 1);
  };

  const handleSetPressDns = (parentDomain, value) => {
    const childDomains = parentDomains[parentDomain].subdomains.map((s) => s.domain);
    const parentAndChildDomains = [parentDomain, ...childDomains];

    setPressDns(parentAndChildDomains, value);
  };

  const { values } = formikProps;
  return (
    <Fragment>
      <DialogContent>
        <Box margin={{ bottom: 'small' }}>
          <Typography variant="h4">Set PressDNS</Typography>
          <Box margin={{ top: 'xxsmall' }}>
            <InfoText href="https://support.pagely.com/hc/en-us/articles/213428437-Using-PressDNS-">Using PressDNS</InfoText>
          </Box>
        </Box>
        <Box>
          <Grid container>
            {
              Object.entries(parentDomains).map(([key, value]: [string, Record<string, any>]) => {
                return (
                  <Grid container spacing={3} key={key} alignItems="center">
                    <Grid item md={4} xs={5}>
                      <Grid container>
                        <Typography gutterBottom>{key}</Typography>
                      </Grid>
                      {
                        value.subdomains.length > 0 && (
                          <Grid container css={{ paddingLeft: '1rem' }}>
                            <Box direction="column">
                              {
                                value.subdomains.map((subdomain) => {
                                  return (
                                    <Typography gutterBottom key={subdomain.domain}>{subdomain.domain}</Typography>
                                  );
                                })
                              }
                            </Box>
                          </Grid>
                        )
                      }
                    </Grid>
                    <Grid item md={8} xs={7}>
                      <FormControlLabel
                        label={`Use PressDNS for ${value.subdomains.length === 0 ? 'this domain' : 'these domains'}`}
                        control={(
                          <Checkbox
                            checked={values.availibilityDomains[key].usePressDNS || false}
                            onChange={(e) => {
                              handleSetPressDns(key, e.target.checked);
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })
            }
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box margin={{ top: 'small' }} direction="row" justify="space-between" align="center" flex={1}>
          <div>
            <Button onClick={handleBack} variant="outlined" color="default">
              Back
            </Button>
          </div>
          <Box
            css={{ minWidth: '50%' }}
          >
            <Stepper
              startingStep={1}
              activeStep={step}
              classes={makeStyles({ root: { padding: 0 } })()}
              showLabels={false}
              steps={formikProps.values.stepperSteps}
            />
          </Box>

          <div>
            <Button
              onClick={handleNext}
              variant="contained"
            >
              Next
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Fragment>
  );
};

export default React.memo(SetPressDNS);
