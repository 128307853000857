import React, { forwardRef } from 'react';
import { node, string } from 'prop-types';
import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import { useTheme } from '@material-ui/core';
import { css } from '@emotion/react';
import Box from 'shared/styleguide/atoms/Box';
import { ErrorText } from 'shared/styleguide/typography';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';

const textFieldStyles = (theme) => css`
  border-radius: ${theme.borderRadius};
  border: 1px solid ${theme.color400};
  box-sizing: border-box;
  padding: 0 14px;
  height: 40px;
  background-color: ${theme.overrides.MuiOutlinedInput.root.backgroundColor};

  &:focus {
    border-color: ${theme.palette.primary.main};
  }
  &:hover {
    border-color: ${theme.color900}
  }
  & iframe {
    height: 1rem !important;
  }
`;

const getStyles = (theme) => (
  {
    base: {
      'color': theme.typography.color,
      'fontFamily': theme.typography.fontFamily,
      'fontSize': theme.typography.fontSize,
      'iconColor': theme.palette.primary.main,
      '::placeholder': {
        color: theme.color600,
      },
    },
    invalid: {
      'color': theme.palette.warning.main || '#FF7C4A',

      ':focus': {
        color: theme.palette.error.main || '#e44d5f',
      },
      '::placeholder': {
        color: theme['invalid-placeholder'] || '#FFCCA5',
      },
    },
  }
);

const classes = {
  focus: 'cb-focus',
  invalid: 'cb-invalid',
  empty: 'cb-empty',
  complete: 'cb-complete',
};

const CreditCard = forwardRef(({ errorText }, ref) => { // eslint-disable-line react/display-name
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <div id="credit-card-field">
      <Box
        padding="small"
        css={[textFieldStyles(theme)]}
        justify="center"
      >
        <ErrorBoundary>
          <CardComponent
            ref={ref}
            icon
            styles={styles}
            classes={classes}
            fonts={[theme.typography.fontFamily]}
            currency="USD"
          />
        </ErrorBoundary>
      </Box>
      {
        errorText && (
          <ErrorText variant="caption">{errorText}</ErrorText>
        )
      }
    </div>
  );
});

CreditCard.propTypes = {
  errorText: string,
  ref: node,
};

export default CreditCard;
