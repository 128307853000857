import React, { useState } from 'react';
import { connect } from 'react-redux';
import { object, func, bool } from 'prop-types';
import { Typography, Collapse } from '@material-ui/core';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';

import {
  updateAppConfig,
  resetAppOptions,
  enableSFTP,
} from 'shared/modules/app/redux/actions';

import AdvancedInfo from './AdvancedInfo';
import Multisite from './Multisite';

export const AdminActions = ({
  app, account, updateAppConfig,
}) => {
  const [showAdvancedInfo, setShowAdvancedInfo] = useState(false);

  return (
    <Box margin={{ top: 'medium' }} data-testid="app-admin-actions">
      <Typography variant="h3" gutterBottom>Admin Actions</Typography>
      <Box as={Paper} padding="small" isAdmin>
        <Multisite app={app} updateAppConfig={updateAppConfig} isAdmin />
        <Box padding={{ top: 'small' }} isAdmin>
          <div>
            <Button variant={showAdvancedInfo ? 'outlined' : 'contained'} color="secondary" onClick={() => { setShowAdvancedInfo(!showAdvancedInfo); }}>
              {showAdvancedInfo ? 'Hide Server Info' : 'View Server Info'}
            </Button>
          </div>
          <Collapse in={showAdvancedInfo}>
            <AdvancedInfo account={account} app={app} />
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

AdminActions.propTypes = {
  account: object.isRequired,
  app: object,
  enableSFTP: func.isRequired,
  isOnP20Account: bool,
  updateAppConfig: func.isRequired,
};

export default connect(
  (state) => ({
    account: state.account,
  }),
  {
    enableSFTP,
    resetAppOptions,
    updateAppConfig,
  },
)(AdminActions);
