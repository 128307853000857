import React, { Fragment } from 'react';
import PropTypes, { bool, object, func } from 'prop-types';
import store2 from 'store2';
import { connect } from 'react-redux';
import { Typography, Divider } from '@material-ui/core';

import { css } from '@emotion/react';
import { accountPropTypes } from 'shared/modules/account/models/model';
import { AppCollectionPropTypes } from 'shared/modules/apps/models';
import Card from 'shared/styleguide/atoms/Card/Card';

import RSS from 'shared/styleguide/organisms/RSS/RSS';
import Loading from 'shared/styleguide/atoms/Loading';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import { FeatureToggle } from 'shared/flags';
import { currentSurveyId, completedSurveyKey, msaOptin } from 'shared/3rdparty/typeform';
import { FSA_OPTIN } from 'shared/modules/metadata/constants';
import Box from 'shared/styleguide/atoms/Box';
import { getTagsList } from 'shared/modules/account/redux/selectors';

import DiskStats from 'shared/modules/analytics/components/Stats/Disk';
import Bandwidth from 'shared/modules/analytics/components/Stats/Bandwidth';
import CDN from 'shared/modules/analytics/components/Stats/CDN';
import Banner from 'shared/styleguide/molecules/Banner';
import CollabAccounts from '../components/CollabAccounts';
import AdminLinks from '../components/AdminLinks';
import AdminInfo from '../components/AdminInfo';
import SFTPInfo from '../components/SFTPInfo';
import AppsList from '../components/AppsList';
import DashboardCardHeading from '../components/DashboardCardHeading';
import ArticlesList from '../components/ArticlesList';
import SurveySection from '../components/Survey';

export class AccountDashboard extends React.Component {
  static propTypes = {
    account: PropTypes.shape(accountPropTypes),
    accountTags: PropTypes.array,
    apps: PropTypes.shape(AppCollectionPropTypes),
    getArticles: PropTypes.func,
    history: PropTypes.object.isRequired,
    isAdmin: bool,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    user: object,
  };

  componentWillUnmount() {
    // this.props.resetPlans();
  }

  render() {
    const {
      account,
      accountTags,
      apps,
      isAdmin,
      user,
      permissions,
    } = this.props;

    if (!account.id) {
      return (
        <Loading />
      );
    }

    if (account.hasSubscription && !account.provisioned) {
      return (
        <div>
          {
            isAdmin && (<AdminInfo account={account} />)
          }
          <Box margin={{ bottom: 'medium' }}>
            <Banner
              heading="Account Provisioning"
              type="success"
              subheading={(
                <>
                  <Typography color="inherit" component="div" variant="h5" gutterBottom>
                    Thanks for joining us!
                  </Typography>
                  <Typography color="inherit" component="div" variant="h5">
                    We&apos;re still working on setting up your account,
                    but you&apos;ll be able to create new apps and host your content as soon as that&apos;s done.
                  </Typography>
                </>
              )}
            />
          </Box>
          <Box>
            {
              account.isCollaborator
              && <CollabAccounts account={account} permissions={permissions} />
            }
            <ArticlesList />
          </Box>
          {
            !isAdmin
            && (
              <Fragment>
                <Box padding={{ top: 'large', bottom: 'medium' }}>
                  <Divider />
                </Box>
                <Fragment>
                  <DashboardCardHeading>Pagely News</DashboardCardHeading>
                  <RSS key="https://pagely.com/feed/" url="https://pagely.com/feed/" />
                </Fragment>
              </Fragment>
            )
          }
        </div>
      );
    }

    return (
      <div>
        {
          isAdmin && (<AdminInfo account={account} />)
        }
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              width: '50%',
              marginRight: 12,
            }}
          >
            <ErrorBoundary>
              {
                account.isCollaborator
                && <CollabAccounts account={account} permissions={permissions} />
              }
            </ErrorBoundary>
            <ErrorBoundary>
              <AppsList apps={apps} account={account} history={this.props.history} permissions={permissions} />
            </ErrorBoundary>
            <ErrorBoundary>

              <AdminLinks account={account} />
            </ErrorBoundary>

            <FeatureToggle
              flags={[
                account.status === 'success',
                account.servers.length > 0,
              ]}
            >
              <ErrorBoundary>
                <Box>
                  {/* might need to move this to other column if Typeform is not present */}
                  <SFTPInfo account={account} />
                </Box>
              </ErrorBoundary>
            </FeatureToggle>
          </div>

          <div
            style={{
              width: '50%',
              marginLeft: 12,
            }}
          >
            <FeatureToggle
              // THIS ONE IS FOR NORMAL SURVEYS
              flags={[
                false, // turn the survey off by setting to false
                // don't show it to admins
                !isAdmin,
                // no current survey active
                Boolean(currentSurveyId),
                // survey id not in session storage
                (!store2.get(completedSurveyKey)
                  // new survey id is available
                  || store2.get(completedSurveyKey) !== currentSurveyId),
                // user.user !== null,
              ]}
            >
              <SurveySection
                currentSurveyId={currentSurveyId}
                account={account}
                user={user?.user}
                heading="Customer Survey"
              />
            </FeatureToggle>

            <FeatureToggle
              // THIS ONE IS FOR MSA OPT-IN
              flags={[
                false, // turn the survey off by setting to false
                // don't show it to customers that already have it enabled
                !accountTags.includes(FSA_OPTIN),
                // don't show it to admins
                !isAdmin,
                // no current survey active
                Boolean(msaOptin),
                // survey id not in session storage
                (!store2.get(completedSurveyKey)
                  // new survey id is available
                  || store2.get(completedSurveyKey) !== currentSurveyId),
                // user.user !== null,
              ]}
            >
              <SurveySection
                data-testid="msa-optin"
                currentSurveyId={msaOptin}
                account={account}
                user={user?.user}
                heading="Beta Access" // defaults to Survey, for beta testing we're using "Beta Access"
              />
            </FeatureToggle>

            <FeatureToggle flags={[account.hasSubscription]}>
              <Box margin={{ bottom: 'medium' }}>
                <DashboardCardHeading>Current Usage</DashboardCardHeading>
                <Card>
                  <Box flex={1} css={css`min-width: 300px;`} padding={{ bottom: 'xsmall' }} justify="flex-start" gap="small">
                    <Bandwidth />
                    <CDN />
                    <FeatureToggle
                      flags={[!account.isOnP20Account]}
                    >
                      <DiskStats />
                    </FeatureToggle>
                  </Box>
                </Card>
              </Box>
            </FeatureToggle>
          </div>
        </div>
        {
          !isAdmin
          && (
            <Fragment>
              <Box padding={{ bottom: 'medium' }}>
                <Divider />
              </Box>
              <Fragment>
                <DashboardCardHeading>Pagely News</DashboardCardHeading>
                <ErrorBoundary>

                  <RSS key="https://pagely.com/feed/" url="https://pagely.com/feed/" />
                </ErrorBoundary>
              </Fragment>
            </Fragment>
          )
        }
        <Box padding={{ top: 'large', bottom: 'mediumLarge' }}>
          <Divider />
        </Box>
        <ErrorBoundary>
          <ArticlesList />
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  accountTags: getTagsList(state),
  apps: state.apps,
  permissions: state.permissions,
  user: state.user,
});

export default connect(mapStateToProps)(AccountDashboard);
