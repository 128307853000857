/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import ArticleList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';
import Loading from 'shared/styleguide/atoms/Loading';
import { useGetArticlesByLabelQuery } from 'shared/modules/support/redux/query';
import type { Account } from 'shared/modules/account/types';
import type { App } from 'shared/types/App';
import type { Action } from 'shared/utils/redux/types';
import SSHDetails from './SSHDetails';
import FTP20 from './FTP20';
import CustomIps from './CustomIps';

interface SFTPRouteProps {
  account: Account;
  app: Action<App>;
}

export const SFTPRoute = (props: SFTPRouteProps) => {
  const {
    account,
    app,
  } = props;

  const { data: articles, isSuccess } = useGetArticlesByLabelQuery('sftp');

  if (
    !account.id
      || app.status === 'loading'
  ) {
    return (
      <Loading />
    );
  }

  return (
    <Fragment>
      {
        (account.isOnP20Account)
          ? (<FTP20 account={account} />)
          : (
            <Fragment>
              <SSHDetails account={account} app={app} />
              <CustomIps />
            </Fragment>
          )
      }
      {
        isSuccess && (
          <Box padding={{ top: 'mediumLarge' }}>
            <Typography variant="h3" gutterBottom>Common SSH/SFTP Questions</Typography>
            <ArticleList
              topic="sftp"
              columns={3}
              boxProps={{
                row: true,
                wrap: 'wrap',
              }}
              articles={articles.data}
            />
          </Box>
        )
      }
    </Fragment>
  );
};

export default connect(
  (state: { account: Account; app: { app: any}}) => ({
    account: state.account,
    app: state.app.app,
  }),
  {
  },
)(SFTPRoute);
