import React, { useState, useRef } from 'react';
import {
  number, object, func, bool, array, string,
} from 'prop-types';
import { connect } from 'react-redux';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import moment from 'moment-timezone';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { consolidateErrors } from 'shared/utils/validation';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { deleteHeadersForApp, setHeaders } from '../../redux/headers/actions';
import AccessHeadersDetails from './ListDetails';

export const ListItem = (props) => {
  const {
    record, updateList, deleteList, appId, onValidate, path: key, domainsList, mode, isExpanded = false,
  } = props;

  const [expanded, setExpanded] = useState(isExpanded);
  const [changed, setChanged] = useState(false);
  const [status, setStatus] = useState({
    save: 'pristine',
    delete: 'pristine',
  });

  const [path, extensions] = key.split('::');

  const initialValues = record.reduce((acc, current) => {
    acc[current.type] = {
      enabled: current.enabled,
      context: current.context,
      date: current.modifiedDate,
    };

    if (current.modifiedDate > acc.lastModified) {
      acc.lastModified = current.modifiedDate;
    }

    return acc;
  }, {
    key,
    path,
    extensions: extensions?.split(',').filter(((a) => Boolean(a))).map((element) => ({ value: element, label: element })),
    pathType: extensions ? 'extension' : 'path',
    lastModified: record[0]?.modifiedDate,
  });

  const formRef = useRef();

  const handleSetChanged = (value) => {
    setChanged(value);
  };

  const handleSetExpanded = (value) => {
    if (value !== expanded) {
      setExpanded(value);
    }
    if (value === false) {
      setChanged(false);
    }
  };

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const handleSubmit = async (values, actions) => {
    // contruct object to send
    const [body, shouldReturn] = onValidate(values, actions, mode);
    if (shouldReturn) {
      return;
    }

    setStatus({
      ...status,
      save: 'loading',
    });
    try {
      await updateList(appId, body);

      setStatus({
        ...status,
        save: 'success',
      });
      setTimeout(() => {
        handleSetExpanded(false);
        setStatus({
          ...status,
          save: 'pristine',
        });
      }, 3000);
    } catch (err) {
      setStatus({
        ...status,
        save: 'pristine',
      });
      actions.setErrors({
        'general': consolidateErrors(err),
        ...err.response.data.body,
      });
    }
  };

  const handleDelete = async () => {
    setStatus({
      ...status,
      delete: 'loading',
    });
    try {
      await deleteList(appId, key, path, extensions?.split(',').filter(((a) => Boolean(a))));
      handleSetExpanded(false);
      setStatus({
        ...status,
        delete: 'pristine',
      });
    } catch (err) {
      setStatus({
        ...status,
        delete: 'pristine',
      });
      formRef.current.setErrors({
        'general': consolidateErrors(err),
        ...err.response.data.body,
      });
    }
  };

  const ListDetails = AccessHeadersDetails;

  const extensionsDisplay = extensions ? ` [${extensions}]` : '';

  const matchLabel = path ? 'path' : 'extensions';
  return (
    <Accordion expanded={expanded} onChange={() => handleSetExpanded(!expanded)} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary aria-controls="headerList-content" aria-label="headersList-content" id={`accessList-${record.idKey}-header`} expandIcon={<ExpandMoreIcon />}>
        <Box flex={1} justify="space-between" direction="row" align="center">
          <Box direction="row">
            <Typography component="span" variant="subtitle1" color="textSecondary">{`${matchLabel}: `}</Typography>
            <Typography component="span" variant="subtitle1">{`${path} ${extensionsDisplay}`}</Typography>
          </Box>
          <Box direction="row" align="center">
            <Typography variant="caption" color="textSecondary">
              {!!initialValues.lastModified && moment(initialValues.lastModified).format('LLL')}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box direction="column" flex={1}>
          <ListDetails
            initialValues={initialValues}
            onSubmit={handleSubmit}
            formRef={formRef}
            domainsList={domainsList}
            mode={mode}
            onSetChanged={handleSetChanged}
          />
          <Box justify="space-between" direction="row" flex={1}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              status={status.delete}
            >
              Delete
            </Button>
            <Button
              aria-label="save or submit"
              variant={changed ? 'contained' : 'outlined'}
              status={status.save}
              onClick={submitForm}
              disabled={!changed}
              color="secondary"
            >
              Save
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

ListItem.propTypes = {
  appId: number,
  deleteList: func,
  domainsList: array,
  isExpanded: bool,
  key: string,
  mode: string,
  onValidate: func,
  path: string,
  record: array,
  updateList: func,
};

export default connect(
  null,
  {
    updateList: setHeaders,
    deleteList: deleteHeadersForApp,
  },
)(ListItem);
