export const CHAT_UNAVAILABLE = [undefined, null];

// chat only works on production scripts at this time
export const setupChat = () => {
  const zendeskChatScript = document.createElement('script');
  zendeskChatScript.type = 'text/javascript';
  zendeskChatScript.src = process.env.REACT_APP_ZENDESK_URL;
  zendeskChatScript.id = 'ze-snippet';
  document.head.appendChild(zendeskChatScript);

  zendeskChatScript.onload = () => {
    if (window.zE) {
      window.zESettings = {
        webWidget: {
          chat: {
            prechatForm: {
              greeting: {
                '*': 'Please open a ticket for non-urgent issues and use live chat for urgent support issues only',
              },
            },
          },
        },
      };

      window.zE('messenger', 'hide');

      let atomicToken = localStorage.getItem('atomic_token');
      if (atomicToken) {
        atomicToken = atomicToken.replace(/^"|"$/g, '');

        fetch('/api/support/zd-auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${atomicToken}`,
          },
          body: JSON.stringify({ }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.jwt) {
              window.zE('messenger', 'loginUser', (cb) => cb(data.jwt));
            }
          })
          .catch((error) => console.error('Error authenticating Zendesk user:', error));
      }
    }
  };
};

export const teardownChat = () => {
  if (document.getElementById('ze-snippet')) {
    document.getElementById('ze-snippet').outerHTML = '';
  }
  if (window.zE) {
    window.zE('messenger', 'hide');
  }
};

function cleanString(input) {
  const cleaned = input.replace(/[^a-zA-Z0-9. ]/g, '');
  return cleaned.replace(/ /g, '_');
}

export const populateChatInfo = ({ user, account }, open = false, retry = 0) => {
  try {
    // tag strings with non-alphanumeric characters will be ignored (must remove @ symbols etc.)
    const tags = [
      `name: ${cleanString(user.name)}`,
      `email: ${cleanString(user.email)}`,
      `account id:${account.id}`,
      `account: ${cleanString(account.companyName)}`,
      `pagely_chat_user:${user.id}`,
      `pagely_chat_account:${account.id}`,
    ];
    window.zE('messenger:set', 'conversationTags', tags);
    if (open) {
      window.zE('messenger', 'show');
      window.zE('messenger', 'open');
    }
  } catch (e) {
    // if 5 seconds worth of retries have elapsed...give up
    if (retry > 50) {
      // eslint-disable-next-line no-console
      console.error('ZD widget never initialized', e);
      // eslint-disable-next-line no-alert
      window.alert('We were unable to open a support chat. Please contact pagely support by opening a support ticket');
      return;
    }
    // zd widget not yet initialized
    // eslint-disable-next-line no-param-reassign
    setTimeout(() => populateChatInfo({ user, account }, open, ++retry), 100);
  }
};
