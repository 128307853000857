import moment from 'moment/moment';
import {
  CDN_APP_REQUEST,
  CDN_APP_SUCCESS,
  CDN_APP_FAILURE,
  CDN_CHECK_REQUEST,
  CDN_CHECK_SUCCESS,
  CDN_CHECK_FAILURE,
  CDN_APP_RESET,
  CDN_REQUEST,
  CDN_SUCCESS,
  CDN_FAILURE,
  CDN_CNAME_REMOVE_REQUEST,
  CDN_CNAME_REMOVE_SUCCESS,
  CDN_CNAME_REMOVE_FAILURE,
  CDN_CNAME_ADD_REQUEST,
  CDN_CNAME_ADD_SUCCESS,
  CDN_CNAME_ADD_FAILURE,
  CDN_PURGE_CACHE_REQUEST,
  CDN_PURGE_CACHE_SUCCESS,
  CDN_PURGE_CACHE_FAILURE,
  CDN_CHECK_RESET,
  CDN_FSA_STATUS_REQUEST,
  CDN_FSA_STATUS_SUCCESS,
  CDN_FSA_STATUS_FAILURE,
} from 'shared/modules/cdn/redux/actions';
// eslint-disable-next-line
import apiReducer from 'shared/utils/redux/apiReducer';
// eslint-disable-next-line
import * as reducer from 'shared/utils/redux/apiReducer';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';

export const initialState = {
  ...reducer.baseState,
};

export const cdnForAccount = apiReducer(
  CDN_REQUEST,
  CDN_SUCCESS,
  CDN_FAILURE,
  LOGIN_LOGOUT_SUCCESS,
  reducer.baseCollectionState,
);

const cdnAppFetch = apiReducer(
  CDN_APP_REQUEST,
  CDN_APP_SUCCESS,
  CDN_APP_FAILURE,
  CDN_APP_RESET,
);

export const cdnCheck = apiReducer(
  CDN_CHECK_REQUEST,
  CDN_CHECK_SUCCESS,
  CDN_CHECK_FAILURE,
  CDN_CHECK_RESET,
);

export const fsaStatus = apiReducer(
  CDN_FSA_STATUS_REQUEST,
  CDN_FSA_STATUS_SUCCESS,
  CDN_FSA_STATUS_FAILURE,
);

export const cdnApp = (state = initialState, action) => {
  const method = state.loaded ? reducer.loadMethod : reducer.initialRequestMethod;
  switch (action.type) {
    case CDN_PURGE_CACHE_REQUEST:
    case CDN_CNAME_REMOVE_REQUEST:
    case CDN_CNAME_ADD_REQUEST:
      return {
        ...method(state, action),
        done: false,
        doing: true,
      };
    case CDN_PURGE_CACHE_FAILURE:
    case CDN_CNAME_REMOVE_FAILURE:
    case CDN_CNAME_ADD_FAILURE:
      return {
        ...reducer.failMethod(state, action),
        apiErrors: action.errMessage,
        apiErrorStatusCode: action.statusCode,
        done: true,
      };
    case CDN_PURGE_CACHE_SUCCESS: {
      return {
        ...reducer.successMethod(state, action),
        data: {
          ...state.data,
          datePurged: moment().format('X'),
        },
      };
    }
    case CDN_CNAME_REMOVE_SUCCESS:
    case CDN_CNAME_ADD_SUCCESS:
      if (action.data && !(action.data.mappedCnames instanceof Array)) {
        action.data.mappedCnames = Object.values(action.data.mappedCnames); //eslint-disable-line
      }
      return {
        ...reducer.successMethod(state, action),
        data: {
          ...state.data,
          mappedCnames: action.data.mappedCnames,
        },
      };
    default:
      return cdnAppFetch(state, action);
  }
};
