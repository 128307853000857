export const styles = {
  successStateContent: {
    marginBottom: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowWrap: 'normal',
    textAlign: 'center',
  },
  successStateSpaceTop: {
    marginTop: 30,
  },
  primarySuccessMessage: {
    fontSize: 20,
  },
  secondarySuccessMessage: {
    marginTop: 20,
    fontSize: 16,
  },
};
