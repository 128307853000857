import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';

import { Paper, Typography } from '@material-ui/core';

import ShowVideo from 'shared/styleguide/organisms/ShowVideo';
import YOUTUBE from 'shared/3rdparty/youtube';

import Box from 'shared/styleguide/atoms/Box';
import Footer from 'shared/modules/security/components/Footer';

import AddUsername from './AddUsername';
import ViewKeys from './ViewKeys';

const SSHPage = ({ account }) => {
  return (
    <Box direction="column" gap="medium">
      <Paper>
        <Box padding="medium">
          <Typography variant="h4" gutterBottom>SSH Keys <ShowVideo videoId={YOUTUBE.SSH_SETUP_VIDEO_ID} title="Setting Up SSH Keys" /></Typography>
          {
            !account.sshUsername ? (
              <AddUsername />
            ) : (
              <ViewKeys />
            )
          }
        </Box>
      </Paper>
      <Box>
        <Typography variant="h3" gutterBottom>SSH Key Help Topics</Typography>
        <Footer />
      </Box>
    </Box>
  );
};

SSHPage.propTypes = {
  account: object.isRequired,
};

export default connect(
  (state) => ({ account: state.account }),
  null,
)(SSHPage);
