import React, { Fragment } from 'react';
import { object } from 'prop-types';

import {
  Typography,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Checkbox from 'shared/styleguide/atoms/Switches/Checkbox';

import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import { getErrorMessages } from '../../../utils';

const Options = ({
  values,
  form,
}) => {
  return (
    <Fragment>
      <Box direction="row" align="center" padding={{ top: 'xsmall', bottom: 'xsmall' }}>
        <Typography variant="h5" color="initial"><strong>Options</strong></Typography>
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <Typography gutterBottom variant="caption" color="textSecondary">
          These customizations will be applied to all access rules for the selected path.
        </Typography>
      </Box>
      <Box>
        <Box margin={{ bottom: 'small' }}>
          <Typography gutterBottom variant="caption" color="textSecondary">401 / 403 message</Typography>
          <Box flex={0}>
            <WideTextField
              id="forbiddenMessage"
              name="forbiddenMessage"
              autoComplete="off"
              error={Boolean(form.errors?.forbiddenMessage)}
              helperText={form.errors?.forbiddenMessage ? getErrorMessages(form.errors?.forbiddenMessage).join('; ') : null}
              onChange={(e) => form.handleChange('forbiddenMessage', e.target.value)}
              value={values.forbiddenMessage || ''}
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              margin="dense"
              variant="outlined"
              style={{ minWidth: '100%' }}
            />
          </Box>
        </Box>
      </Box>
      <Box margin={{ bottom: 'small' }}>
        <Typography gutterBottom variant="caption" color="textSecondary">Nginx error log message</Typography>
        <Box flex={0}>
          <WideTextField
            id="errorLogMessage"
            name="errorLogMessage"
            autoComplete="off"
            error={Boolean(form.errors?.errorLogMessage)}
            helperText={form.errors?.errorLogMessage ? getErrorMessages(form.errors?.errorLogMessage).join('; ') : null}
            onChange={(e) => form.handleChange('errorLogMessage', e.target.value)}
            value={values.errorLogMessage || ''}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            margin="dense"
            variant="outlined"
            style={{ minWidth: '100%' }}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

Options.propTypes = {
  form: object,
  values: object,
};

export default Options;
