import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { WaveOptions } from './types';
import { wavesAnimation } from './waves-animation';

interface Return {
  container: React.MutableRefObject<HTMLDivElement>;
  preferReducedMotion: boolean;
}

export function useWave(options: WaveOptions): Return {
  const container = useRef<HTMLDivElement>();
  const preferReducedMotion = useMediaQuery('(prefers-reduced-motion)');

  useEffect(() => {
    if (container && !preferReducedMotion) {
      return wavesAnimation(container.current, options);
    }

    return () => {
      // this is intentional
    };
  }, [container, preferReducedMotion, options]);

  return { container, preferReducedMotion };
}
