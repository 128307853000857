import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Divider from '@material-ui/core/Divider';

import Modal from 'shared/styleguide/molecules/Modal/Modal';
import { updateCertDomainLink } from 'shared/modules/ssl/redux/certDomainLink/actions';
import { getAllValidationMessages } from 'shared/utils/validation';

import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import { ErrorText } from 'shared/styleguide/typography';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import InfoText from 'shared/styleguide/molecules/InfoText';
import Redirect from './Redirect/Redirect';
import TlsVersionCipher from './TlsVersionCipher/TlsVersionCipher';
import HSTS from './HSTS/HSTS';
import Http2 from './HTTP2/HTTP2';

export const BulkDomainActions = (props) => {
  const {
    updateLink, initialValues, domains, user, open, onClose,
  } = props;

  const handleSave = (currentValues, formikBag) => {
    return Promise.all( // for all domains, update them one by one
      domains.map((domain) => updateLink({
        aliasId: domain.aliasId,
        certId: domain.certId,
        ...currentValues,
        returnPromise: true,
      })),
    )
      .then(() => {
        formikBag.setStatus('done');
        setTimeout(() => {
          onClose();
        }, 3000);
      })
      .catch((error) => {
        const err = getAllValidationMessages(error);
        formikBag.setStatus('error');
        // catch which ones fail
      });
  };

  const userType = 'admin';

  return (
    <Formik
      initialValues={initialValues}
      initialStatus="pristine"
      initialErrors={{ general: '' }}
      onSubmit={handleSave}
    >
      {(formProps) => (
        <Modal
          title="Update settings for all domains"
          open={open}
          scroll="body"
          onClose={onClose}
          actions={[(
            <ErrorText key="errorText" style={{ paddingRight: 16 }}>{formProps?.errors?.general}</ErrorText>
          ), (
            <Button
              key="save"
              onClick={formProps.handleSubmit}
              variant="contained"
              status={formProps.status}
              disabled={!formProps.dirty}
            // disabled={!formProps.dirty || this.props.invalid}
            >
              Save
            </Button>
          ),
          ]}
          renderProp={() => (
            <div>
              <Box
                padding="small"
              >
                {
                  (!user.user)
                    ? (<Loading />)
                    : [(
                      <Box column key="form">
                        <InfoText>
                          If you want a setting &quot;off&quot;, toggle it on and off again. Preview changes below.
                        </InfoText>
                        <ErrorBoundary>
                          <Redirect />
                        </ErrorBoundary>
                        <Divider />
                        <ErrorBoundary>
                          <Http2 />
                        </ErrorBoundary>
                        <Divider />
                        <ErrorBoundary>
                          <TlsVersionCipher />
                        </ErrorBoundary>
                        <Divider />
                        <ErrorBoundary>
                          <HSTS
                            userType={userType}
                          />
                        </ErrorBoundary>
                      </Box>
                    ), (
                      <Box style={{ marginTop: 8 }} key="currentValues" column>
                        <h3>Current Changes</h3>
                        <pre>
                          {
                            JSON.stringify(formProps.values, null, 2)
                          }
                        </pre>
                      </Box>
                    )]
                }
              </Box>
            </div>
          )}
        />
      )}
    </Formik>
  );
};

const mapStateToProps = (state, { match: { params: { appID, certID } } }) => {
  if (state.cert.status === 'pristine' || state.cert.domains.length === 0) {
    return {
      domains: state.certDomains.data,
      user: state.user,
      cert: state.cert,
      initialValues: null,
    };
  }

  const { domains } = state.cert;

  const initialValues = domains.reduce((acc, currentDomain) => {
    // for each property in acc,
    Object.entries(acc).forEach(([key, value]) => {
      // check if its the same between the domains
      if (value !== currentDomain[key]) {
        acc[key] = null;
      }
      if (key === 'hstsTimeout') {
        // form requires a string
        acc[key] = `${value}`;
      }
    });
    return acc;
  }, {
    'redirect': domains[0].redirect,
    'http2': domains[0].http2 || null,
    'minTlsVersion': domains[0].minTlsVersion || null,
    'tlsCipher': domains[0].tlsCipher || null,
    'hstsTimeout': domains[0].hstsTimeout,
    'hstsIncludeSubdomains': domains[0].hstsIncludeSubdomains,
    'hstsPreload': domains[0].hstsPreload,
  });

  return {
    domains,
    user: state.user,
    cert: state.cert,
    initialValues,
  };
};

const mapDispatchToProps = {
  updateLink: updateCertDomainLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkDomainActions);
