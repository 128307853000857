import React, { Component } from 'react';
import PropTypes, { array } from 'prop-types';
import { useTable } from 'react-table';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { DialogContent, Typography } from '@material-ui/core';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import HeadlineText from 'shared/styleguide/typography/HeadlineText';
import { CSRActions } from './CSRStepper';
import styles from './styles';

const labels = [
  { key: 'commonName', label: 'Common Name ' },
  { key: 'organizationName', label: 'Organization ' },
  { key: 'countryName', label: 'Country ' },
  { key: 'stateOrProvinceName', label: 'State/Province ' },
  { key: 'localityName', label: 'City/Locality ' },
  { key: 'organizationalUnitName', label: 'Organizational Unit ' },
  { key: 'emailAddress', label: 'Email ' },
];

const ReviewTable = (props) => {
  const { data } = props;

  const columns = React.useMemo(
    () => props.columns, [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows, // Instead of using 'rows', we'll use page,
  } = useTable(
    {
      columns,
      data,
    },
  );

  return (
    <Table {...getTableProps()}>
      <TableBody {...getTableBodyProps()}>
        {
          rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow key={row.id} {...row.getRowProps()} hover>
                {
                  row.cells.map((cell) => {
                    return (
                      <TableCell key={cell.id} {...cell.getCellProps()} css={cell.column?.style}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })
                }
              </TableRow>
            );
          })
        }
      </TableBody>
    </Table>
  );
};

ReviewTable.propTypes = {
  columns: array.isRequired,
  data: array.isRequired,
};

export class ReviewSubmit extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    csr: PropTypes.shape({
      errMessage: PropTypes.string.isRequired,
      isFetching: PropTypes.bool.isRequired,
      requestErr: PropTypes.bool.isRequired,
      requestStr: PropTypes.string.isRequired,
    }),
    fields: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
  };

  goBack = () => {
    const { setStep } = this.props;
    setStep({}, 2);
  };

  render() {
    const {
      classes, csr, fields, onSubmit,
    } = this.props;

    const rows = labels.map((d) => ({
      label: d.label,
      value: fields[d.key] || '',
    }));

    return (
      <div
        className={classes.csrModalContainer}
      >
        <DialogContent>
          <div>
            <HeadlineText gutterBottom>Review</HeadlineText>
            <Typography variant="body1" gutterBottom color="textPrimary">Please verify this information before submitting:</Typography>
          </div>
          <Box padding={{ top: 'xsmall', bottom: 'small' }}>
            <ReviewTable
              data={rows}
              columns={[
                {
                  Header: '',
                  accessor: 'label',
                  totalWidth: 160,
                  style: {
                    borderRight: 'none', textAlign: 'right', marginRight: 8, width: 160,
                  },
                  // eslint-disable-next-line react/prop-types
                  Cell: (({ row: { original: { label } } }) => {
                    return (
                      <Typography variant="body2" color="textSecondary">{label}</Typography>
                    );
                  }),
                },
                {
                  Header: '',
                  accessor: 'value',
                  // eslint-disable-next-line react/prop-types
                  Cell: (({ row: { original: { value } } }) => {
                    return (
                      <Typography variant="body1" color="textPrimary">{value}</Typography>
                    );
                  }),
                },
              ]}
            />
          </Box>
        </DialogContent>
        <CSRActions
          step={3}
          actions={[
            <Button
              key="1"
              variant="outlined"
              className="pull-left"
              label="Go Back"
              color="default"
              disabled={csr.isFetching}
              onClick={this.goBack}
            />,
            <Button
              key="2"
              variant="contained"
              className="pull-right"
              label={csr.isFetching ? 'Processing...' : 'Generate CSR'}
              onClick={onSubmit}
            />,
          ]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ReviewSubmit);
