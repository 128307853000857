import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';

import * as ANALYTICS from '../constants';

export const getCPUData = (accountId, data) => makeApiAction(
  // () => promise.resolve({ data: { data: cpu } }),
  ({ api }) => {
    return api().get(`/analytics/accounts/${accountId}/server-cpu${
      qs.stringify({
        ...data,
      /**
       * fromDate
       * toDate
       * columns
       * window
       * singleSeries
       * id
       * */
      }, { arrayFormat: 'brackets', addQueryPrefix: true })
    }`);
  },
  ANALYTICS.ANALYTICS_CPU_REQUEST,
  ANALYTICS.ANALYTICS_CPU_SUCCESS,
  ANALYTICS.ANALYTICS_CPU_FAILURE,
  { accountId, data },
  true,
);

export const getDimensions = (accountId, data) => makeApiAction(
  // () => promise.resolve({ data: { data: dimensions } }),
  ({ api }) => api().get(`/analytics/accounts/${accountId}/server-cpu/dimensions${
    qs.stringify({
      ...data,
      /**
       * fromDate
       * toDate
       * columns
       * window
       * singleSeries
       * id
       * */
    }, { addQueryPrefix: true })
  }`),
  ANALYTICS.ANALYTICS_CPU_DIMENSIONS_REQUEST,
  ANALYTICS.ANALYTICS_CPU_DIMENSIONS_SUCCESS,
  ANALYTICS.ANALYTICS_CPU_DIMENSIONS_FAILURE,
  { accountId, data },
  true,
);
