import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import type { Location } from 'history';
import type { match as Match } from 'react-router';
import Box from 'shared/styleguide/atoms/Box';
import PasswordReset from './PasswordReset';
import { MFAReset } from './MFAReset';
import Links from '../components/Links';
import UnifiedLogin from './UnifiedLogin';
import { forgotPassword as ForgotPassword } from '../redux/reducer';
import {
  forgotPasswordReset as ForgotPasswordReset,
  forgotPasswordSet as ForgotPasswordSet,
  requestAccountLoginIfNeeded,
} from '../redux/actions';
import ValidateTicket from './ValidateTicket';

interface Props {
  accountType: 'admin' | 'account';
  errMessage: string;
  forgotPassword?: typeof ForgotPassword;
  forgotPasswordReset?: typeof ForgotPasswordReset;
  forgotPasswordSet?: typeof ForgotPasswordSet;
  handleLogin: typeof requestAccountLoginIfNeeded;
  headerText?: string;
  isFetching: boolean;
  isLoggedIn: boolean;
  location: Location;
  match?: Match;
  mfaOptional?: boolean;
  preserveUrl?: boolean;
  requestErr: boolean;
}

export const Login: React.FC<Props> = (props) => {
  const {
    headerText = 'Welcome to MGMT',
    accountType,
    forgotPassword,
    forgotPasswordSet,
    forgotPasswordReset,
    ...restOfProps
  } = props;
  const mobile = useMediaQuery('(max-width: 500px)');

  restOfProps.preserveUrl ??= false;

  return (
    <Box css={{ minWidth: mobile ? '100%' : 360 }} direction="column" justify="center" flex={1} data-testid="login-router">
      <Switch>
        <Route
          path="/password-reset/:token*"
          render={(routeProps) => (
            <PasswordReset
              {...routeProps}
              forgotPassword={forgotPassword}
              forgotPasswordSet={forgotPasswordSet}
              forgotPasswordReset={forgotPasswordReset}
            />
          )}
        />
        <Route path="/mfa-reset/:token*" render={(routeProps) => <MFAReset {...routeProps} accountType={accountType} />} />
        <Route path="/validate-ticket/:tokenId*" render={() => <ValidateTicket />} />
        <Route path="/help" render={Links} />
        <Route render={(routeProps) => <UnifiedLogin isAdmin={accountType === 'admin'} a11yTitle={headerText} {...restOfProps} {...routeProps} />} />
      </Switch>
    </Box>
  );
};

export default Login;
