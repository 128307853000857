export const GET_TEMPLATES_REQUEST = 'EMAIL_GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'EMAIL_GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'EMAIL_GET_TEMPLATES_FAILURE';

export const UPDATE_TEMPLATE_REQUEST = 'EMAIL_UPDATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_SUCCESS = 'EMAIL_UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'EMAIL_UPDATE_TEMPLATE_FAILURE';

export const SEND_TEST_EMAIL_REQUEST = 'EMAIL_SEND_TEST_EMAIL_REQUEST';
export const SEND_TEST_EMAIL_SUCCESS = 'EMAIL_SEND_TEST_EMAIL_SUCCESS';
export const SEND_TEST_EMAIL_FAILURE = 'EMAIL_SEND_TEST_EMAIL_FAILURE';
