import apiReducer, {
  baseCollectionState,
  baseState,
  initialRequestMethod,
  successMethod,
  failMethod,
} from 'shared/utils/redux/apiReducer';

import {
  SUPPORT_ARTICLES_REQUEST,
  SUPPORT_ARTICLES_SUCCESS,
  SUPPORT_ARTICLES_FAILURE,
  SUPPORT_ARTICLES_RESET,

  SUPPORT_GET_TICKETS_REQUEST,
  SUPPORT_GET_TICKETS_SUCCESS,
  SUPPORT_GET_TICKETS_FAILURE,

  SUPPORT_GET_TICKET_REQUEST,
  SUPPORT_GET_TICKET_SUCCESS,
  SUPPORT_GET_TICKET_FAILURE,

  SUPPORT_UPDATE_TICKET_REQUEST,
  SUPPORT_UPDATE_TICKET_SUCCESS,
  SUPPORT_UPDATE_TICKET_FAILURE,

  SUPPORT_GET_BANNED_PLUGINS_REQUEST,
  SUPPORT_GET_BANNED_PLUGINS_SUCCESS,
  SUPPORT_GET_BANNED_PLUGINS_FAILURE,
  SUPPORT_ADD_BANNED_PLUGINS_REQUEST,
  SUPPORT_ADD_BANNED_PLUGINS_SUCCESS,
  SUPPORT_ADD_BANNED_PLUGINS_FAILURE,

  SUPPORT_CREATE_TICKET_REQUEST,
  SUPPORT_CREATE_TICKET_SUCCESS,
  SUPPORT_CREATE_TICKET_FAILURE,

  SUPPORT_UPLOAD_ATTACHMENTS_REQUEST,
  SUPPORT_UPLOAD_ATTACHMENTS_SUCCESS,
  SUPPORT_UPLOAD_ATTACHMENTS_FAILURE,

  SUPPORT_DELETE_ATTACHMENT,
  SUPPORT_ATTACHMENTS_RESET,
  SUPPORT_ATTACHMENTS_RESET_ERRORS,

  SUPPORT_TICKET_RESET,
  SUPPORT_TICKETS_RESET,
} from '../constants';

export const tickets = apiReducer(
  SUPPORT_GET_TICKETS_REQUEST,
  SUPPORT_GET_TICKETS_SUCCESS,
  SUPPORT_GET_TICKETS_FAILURE,
  SUPPORT_TICKETS_RESET,
  { ...baseCollectionState },
);

export const attachments = (
  state = {
    ...baseCollectionState,
    errAllMessages: [],
  },
  action,
) => {
  switch (action.type) {
    case SUPPORT_UPLOAD_ATTACHMENTS_REQUEST: {
      return {
        ...initialRequestMethod(state, action),
      };
    }
    case SUPPORT_UPLOAD_ATTACHMENTS_SUCCESS: {
      const { upload: { attachment, token } } = action.data;
      return {
        ...successMethod(state, action),
        data: [
          ...state.data,
          {
            token,
            content_url: attachment.content_url,
            file_name: attachment.file_name,
            id: attachment.id,
          },
        ],
      };
    }
    case SUPPORT_DELETE_ATTACHMENT: {
      const filteredAttachments = state.data.filter((a) => a.id !== action.data);
      return {
        ...state,
        data: filteredAttachments,
      };
    }
    case SUPPORT_UPLOAD_ATTACHMENTS_FAILURE: {
      const newErrorMessage = {
        file: action.params.file.name,
        messages: action.errMessage,
      };
      return {
        ...failMethod(state, action),
        errMessage: action.errMessage || '',
        statusCode: action.statusCode,
        errAllMessages: [
          ...state.errAllMessages,
          newErrorMessage,
        ],
      };
    }
    case SUPPORT_ATTACHMENTS_RESET_ERRORS: {
      return {
        ...state,
        errMessage: null,
        statusCode: null,
        errAllMessages: [],
      };
    }
    case SUPPORT_CREATE_TICKET_SUCCESS:
    case SUPPORT_UPDATE_TICKET_SUCCESS:
    case SUPPORT_ATTACHMENTS_RESET:
      return {
        ...baseCollectionState,
        errAllMessages: [],
      };
    default: return state;
  }
};

export const ticket = (state = baseState, action) => {
  switch (action.type) {
    case SUPPORT_UPDATE_TICKET_REQUEST:
    case SUPPORT_GET_TICKET_REQUEST:
    case SUPPORT_CREATE_TICKET_REQUEST:
      return {
        ...initialRequestMethod(state, action),
      };
    case SUPPORT_UPDATE_TICKET_SUCCESS:
      return {
        ...successMethod(state, action),
        data: {
          ...state.data,
          comments: [{
            'id': `newComment-${+new Date()}`,
            'html_body': `<div class="zd-comment"><p>${action.params.data.body}</p></div>`,
            'author': {
              name: 'me',
            },
          }, ...state.data?.comments],
        },
      };
    case SUPPORT_CREATE_TICKET_SUCCESS:
    case SUPPORT_GET_TICKET_SUCCESS:
      return {
        ...successMethod(state, action),
      };
    case SUPPORT_UPDATE_TICKET_FAILURE:
    case SUPPORT_GET_TICKET_FAILURE:
    case SUPPORT_CREATE_TICKET_FAILURE:
      return {
        ...failMethod(state, action),
        errMessage: action.errMessage || '',
        statusCode: action.statusCode,
      };
    case SUPPORT_TICKET_RESET: return baseState;
    default: return state;
  }
};

export const bannedPlugins = (state = baseCollectionState, action) => {
  switch (action.type) {
    case SUPPORT_ADD_BANNED_PLUGINS_REQUEST:
      return {
        ...initialRequestMethod(state, action),
      };
    case SUPPORT_ADD_BANNED_PLUGINS_FAILURE:
      return {
        ...failMethod(state, action),
        errMessage: action.errMessage || '',
        statusCode: action.statusCode,
      };
    case SUPPORT_ADD_BANNED_PLUGINS_SUCCESS:
      return {
        ...successMethod(state, action),
        data: [...state.data, action.data],
      };
    default:
      return apiReducer(
        SUPPORT_GET_BANNED_PLUGINS_REQUEST,
        SUPPORT_GET_BANNED_PLUGINS_SUCCESS,
        SUPPORT_GET_BANNED_PLUGINS_FAILURE,
        SUPPORT_TICKETS_RESET,
        { ...baseCollectionState },
      )(state, action);
  }
};
