import { roles as ROLES } from 'shared/modules/permissions/user/actions';

export const EMPTY = 'empty';
export const LOADING = 'loading';
export const FAILED = 'failed';
export const COLLAB = 'collab';

export const RECENT_HOURS_LIMIT = 6;

export const changeDiskRoles = [
  ROLES.PRIMARY,
  ROLES.SUPERADMIN,
  ROLES.SUBADMIN,
  ROLES.BILLING,
];
