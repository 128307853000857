import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import axios from 'axios';
import {
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@material-ui/core';

import Paper from 'shared/styleguide/atoms/Card/Paper';
import Box from 'shared/styleguide/atoms/Box';
import RSS from 'shared/styleguide/organisms/RSS/RSS';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

const StatusTable = ({ status }) => {
  return (
    <TableContainer css={{ border: 'none', borderRadius: '0' }}>
      <Table>
        { status.components.map((component) => (
          <TableRow key={component.name} row justify="space-between" padding={{ bottom: 'small' }}>
            <TableCell>{component.name}</TableCell>
            <TableCell align="right"><GhostTag>{component.status}</GhostTag></TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
};

StatusTable.propTypes = {
  status: object,
};

const Status = ({ __storybookMocks }) => {
  const [status, setStatus] = useState(__storybookMocks || { status: 'Loading...' });

  const fetchStatus = async () => {
    try {
      const response = await axios.get('https://pagely.statuspage.io/index.json');
      setStatus({ status: response.data?.status?.description, full: response.data });
    } catch {
      setStatus({ status: "Couldn't load data" });
    }
  };

  useEffect(() => {
    if (__storybookMocks) return;
    fetchStatus();
  }, []);

  return (
    <Box direction="row" wrap="wrap" gap="medium">
      <Box flex="450px">
        <Typography gutterBottom variant="h3">System Status</Typography>
        <Box as={Paper}>
          <Box padding="small">
            <Typography gutterBottom variant="body1" component="span">
              Status: {
              status.status ?? status
            }
            </Typography>
            <Typography variant="body2" color="textSecondary">You can view the full history and detailed status at <a href="http://status.pagely.com">status.pagely.com</a></Typography>
          </Box>
          {
            status.full && (
              <>
                <Divider />
                <StatusTable status={status?.full} />
              </>
            )
          }
        </Box>
      </Box>
      <Box flex="400px">
        <Typography gutterBottom variant="h3">Status Updates</Typography>
        <RSS
          url="https://pagely.statuspage.io/history.rss"
        />
      </Box>
    </Box>
  );
};

export default Status;
