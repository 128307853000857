import React, { useState } from 'react';
import {
  string, node, object, oneOf, oneOfType, bool,
} from 'prop-types';
import {
  IconButton, Typography, useTheme, SvgIcon,
} from '@material-ui/core';
import {
  CheckCircleOutline, InfoOutlined, Warning, Cancel,
} from '@material-ui/icons';
import { mdiAlertOutline } from '@mdi/js';

import Box from 'shared/styleguide/atoms/Box';

const Banner = ({
  heading,
  subheading,
  icon,
  type, // one of [ info, success, danger, warning ]
  dismissable,
  closeSize = 'small',
  padding,
  dataId,
  underline = false,
  showIcon = true,
  rounded = 'all',
  margin = {},
}) => {
  const theme = useTheme();

  const [show, setShow] = useState(true);

  const handleClose = () => { setShow(false); };

  const iconProps = !heading ? { fontSize: 'small' } : null;

  const iconMap = {
    info: <InfoOutlined color="primary" {...iconProps} />,
    success: <CheckCircleOutline color="secondary" {...iconProps} />,
    warning: <SvgIcon style={{ color: theme.palette.warning.main }}><path d={mdiAlertOutline} /></SvgIcon>,
    danger: <Warning color="error" {...iconProps} />,
  };

  let displayIcon = null;
  if (icon || showIcon) {
    displayIcon = icon || iconMap[type];
  }

  if (!show) {
    return null;
  }

  let borders;

  switch (rounded) {
    case 'all':
      borders = {
        borderRadius: theme.borderRadius,
      };
      break;
    case 'top':
      borders = {
        borderTopLeftRadius: theme.borderRadius,
        borderTopRightRadius: theme.borderRadius,
      };
      break;
    case 'bottom':
      borders = {
        borderBottomLeftRadius: theme.borderRadius,
        borderBottomRightRadius: theme.borderRadius,
      };
      break;
    default:
      borders = null;
  }

  return (
    <Box
      data-id={dataId}
      css={{
        position: 'relative',
        color: theme.color100,
        backgroundColor: theme.color900,
        borderBottom: underline ? `2px solid ${theme.palette.primary.main}` : 'none',
        ...borders,
      }}
      margin={margin}
    >
      {
        dismissable && (
          <Box css={{ position: 'absolute', right: '0', top: '0' }}>
            <IconButton
              size={closeSize}
              onClick={handleClose}
              css={{
                opacity: '0.5',
                color: 'inherit',
              }}
            >
              <Cancel />
            </IconButton>
          </Box>
        )
      }
      <Box
        padding={padding || 'medium'}
      >
        <Box direction="column" gap="xxsmall">
          {
            heading && (
              <Box direction="row" gap="xxsmall">
                {
                  showIcon && (
                    <Box>{displayIcon}</Box>
                  )
                }
                <Typography
                  component="div"
                  variant="h3"
                  css={{
                    color: 'inherit',
                  }}
                >
                  {heading}
                </Typography>
              </Box>
            )
          }
          {
            subheading
            && (
              <Box direction="row" gap="xxsmall">
                {
                  showIcon && !heading && (
                    <Box>{displayIcon}</Box>
                  )
                }
                <Typography
                  component="div"
                  variant="body1"
                  css={{
                    marginTop: showIcon ? 2 : 0,
                    color: 'inherit',
                  }}
                >
                  {subheading}
                </Typography>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  );
};

Banner.displayName = 'Banner';

Banner.propTypes = {
  className: string,
  closeSize: string,
  dataId: string,
  dismissable: bool,
  heading: string,
  icon: node,
  margin: object,
  padding: string,
  rounded: string,
  showIcon: bool,
  style: object,
  subheading: oneOfType([string, node]),
  type: oneOf(['info', 'success', 'danger', 'warning', 'plain']).isRequired,
  underline: bool,
};

export default Banner;
