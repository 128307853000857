import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import { ButtonGroup, Typography } from '@material-ui/core';

import {
  FilterList, Sort,
} from '@material-ui/icons';
import { css } from '@emotion/react';
import { fetchAppCreateJobs, fetchFsaSetupJobsForAccount } from 'shared/modules/status/redux/actions';
import { getSortedJobs } from 'shared/modules/status/redux/selectors';
import * as STATUS from 'shared/modules/status/redux/constants';
import { PRISTINE, LOADING } from 'shared/utils/redux/constants';
import { roles as ROLES } from 'shared/modules/permissions/user/actions';
import Layout from 'shared/styleguide/molecules/Layout';
import {
  resetApp,
} from 'shared/modules/app/redux/actions';
import PermissionsContext from 'shared/modules/permissions/context/PermissionsContext';

import { fetchMounts } from 'shared/modules/server/redux/actions';
import { fetchAppMetaTags } from 'shared/modules/metadata/redux/actions';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Loading from 'shared/styleguide/atoms/Loading';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import CollabRedirect from 'shared/styleguide/organisms/CollabRedirect';

import Credentials from 'shared/modules/app/routes/Overview/Credentials';
import { useMergeState } from 'shared/hooks/useMergeState';

import { getDeployEnvironment } from 'shared/config';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import { remMapper } from 'shared/styleguide/theme/spacing';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import AppCreate from './AppCreate';
import { NewAppContext } from '../context/NewAppContext';
import AppsBySubscriptionList from './AppsBySubscriptions';

const normalizeTags = (data) => {
  const tagMapping = {};
  // array where
  data.forEach((res) => {
    const stagingTag = res.data.find((tagObj) => tagObj.keyId === 'app:staging-config');
    tagMapping[stagingTag.appId] = stagingTag.value.type;
  });

  return tagMapping;
};

const STATUS_FILTERS = [
  {
    key: '',
    label: 'All',
  },
  {
    key: 'a',
    label: 'Active',
  },
  {
    key: 'i',
    label: 'Inactive',
  },
];

type SortOrderType = 'created' | 'name';
const OrderByButton = ({ sortOrder, setSortOrder }: { sortOrder: SortOrderType; setSortOrder(t: SortOrderType): void }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverId = anchorEl ? 'sort-popover' : undefined;
  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const sortOrderTypes: SortOrderType[] = ['created', 'name'];
  return (
    <>
      <Popover
        id={popOverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom' }}
        transformOrigin={{ 'horizontal': 'center', 'vertical': 'top' }}
        css={({
          'margin-top': '10px',
        })}
      >
        <Box padding="small">
          <ButtonGroup
            color="secondary"
            variant="outlined"
          >
            {
                sortOrderTypes.map((type) => (
                  <Button
                    key={`sort_order_${type}`}
                    variant={type === sortOrder ? 'contained' : 'outlined'}
                    onClick={() => setSortOrder(type)}
                    css={{
                      'text-transform': 'capitalize',
                    }}
                  >
                    {type}
                  </Button>
                ))
                }
          </ButtonGroup>
        </Box>
      </Popover>
      <IconButton
        size="small"
        onClick={handleOpenPopover}
      >
        <Sort />
      </IconButton>
    </>
  );
};

export const AppListLoader = (props) => {
  const [sortOrder, setSortOrder] = useState<'created' | 'name'>('created');
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentStatus, setCurrentStatus] = useState({
    key: '',
    label: 'All',
  });
  const isWooSaas = getDeployEnvironment() === 'woosaas';
  const permissions = useContext(PermissionsContext);

  // what does it do?
  // fetch all necessary data
  useEffect(() => {
    const { accountId, mounts, apps } = props;
    if (apps.data.length > 0) {
      if (mounts.status === 'pristine') {
        props.fetchMounts(accountId);
      }
      props.fetchFsaSetupJobsForAccount(accountId);
    }
    props.fetchAppCreateJobs(accountId, { maxResults: 1 });
  }, []);

  const appsTags = useRef({});
  useEffect(() => {
    async function fetchTags() {
      if (props.apps.data.length) {
        const res = await Promise.all(props.apps.data.map((a) => props.fetchAppMetaTags(a.id)));
        appsTags.current = normalizeTags(res);
      }
    }
    if (isWooSaas) {
      fetchTags();
    }
  }, [props.apps.data]);

  const {
    jobs, account, user, serverTags, fsaJobs,
  } = props;

  const [filter, setFilter] = useState('');

  const defaultApp = {
    newAppId: null,
    newAppName: '',
    appCreateJob: null,
    showCredentials: 'hide',
    timestamp: null,
    createModalOpen: false,
  };

  const managedState = useMergeState(defaultApp);

  // TODO: make this a useEffect
  useEffect(() => {
    const accountId = account.id;
    const {
      newAppId, showCredentials, appCreateJob, timestamp,
    } = managedState.state;

    if (jobs && newAppId) {
      const job = jobs.data.find((j) => j.ownerId === newAppId.toString());
      if (job && job.overallStatus !== appCreateJob) {
        managedState.mergeState({
          appCreateJob: job.overallStatus,
          timestamp: job?.timestamp,
        });
      }

      if (job && timestamp !== job.timestamp) {
        let prevStatus;
        job.steps.forEach((step) => {
          // if a step update get's skipped by the poller, we have to backfill by calling the endpoint
          // otherwise that step will display pending/running/stalled even if it's success
          if (STATUS.endedStatuses.includes(step.status) && [STATUS.PENDING, STATUS.RUNNING, STATUS.STALLED].includes(prevStatus)) {
            fetchAppCreateJobs(accountId, { newAppId, maxResults: 1 });
            managedState.mergeState({
              appCreateJob: job.overallStatus,
              timestamp: job?.timestamp,
            });
          }
          prevStatus = step.status;
        });
      }

      if (showCredentials === 'initial' && appCreateJob === STATUS.SUCCESS) {
        managedState.mergeState({
          showCredentials: 'show',
          appCreateJob: null,
        });
      }
    }
  }, [managedState.state, jobs.length]);

  if (!account.provisioned) {
    return (
      <Layout
        title="Apps"
      >
        <Empty>
          <Typography gutterBottom variant="caption">Your account has not been provisioned yet.</Typography>
          {
            permissions.canAccessAccounts.length > 1 && (
              <Typography variant="caption">
                <Button css={css`top: -2px`} variant="text" onClick={() => { document.getElementById('collab-switcher').click(); }}>Switch accounts</Button> to view apps from a collaborative account.
              </Typography>
            )
          }
        </Empty>
      </Layout>
    );
  }

  if (
    (!props.apps.loaded && !props.apps.failed)
      || [PRISTINE, LOADING].includes(fsaJobs.status)
  ) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (props.apps.failed) {
    if (props.apps.apiErrorStatusCode === 403) {
      return (
        <Layout
          title="Apps"
        >
          <Typography color="textSecondary">You do not have permissions to view apps</Typography>
        </Layout>
      );
    }
    return (
      <Layout
        title="Apps"
      >
        <Empty>
          <Typography color="textSecondary">Could not fetch apps</Typography>
        </Empty>
      </Layout>
    );
  }

  const isPagelyAdmin = user.user && user.user.adminAccount;
  if (account.isCollaborator && !isPagelyAdmin) {
    return (
      <Layout
        title="Apps"
      >
        <CollabRedirect account={account} text="create apps">
          {
            permissions.canAccessAccounts.length > 1 && (
              <>
                <Button css={css`top: -2px`} variant="text" onClick={() => { document.getElementById('collab-switcher').click(); }}>Switch accounts</Button> to view apps from a collaborative account.
              </>
            )
          }
        </CollabRedirect>
      </Layout>
    );
  }

  const apps = props.apps.data;

  const filteredApps = apps
    .filter(((r) => (
      [r.name, r?.label, `${r.id}`].join('').match(filter)
      && (currentStatus.key === '' || r.active === (currentStatus.key === 'a'))
    )))
    .sort((a, b) => {
      if (sortOrder === 'created') {
        return b.dateAdded - a.dateAdded;
      } else if (sortOrder === 'name') {
        const labelA = a.label ?? a.name;
        const labelB = b.label ?? b.name;
        return labelA.localeCompare(labelB);
      }
      // default to 'created'
      return b.dateAdded - a.dateAdded;
    });

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const getFilterStatus = () => {
    return currentStatus.key !== '' ? currentStatus.label.toLowerCase() : '';
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Layout
      title="Apps"
    >
      {/* filter/action ribbon */}
      <NewAppContext.Provider value={managedState}>
        <Box direction="row" align="center" justify="space-between" gap="xsmall" margin={{ bottom: 'mediumLarge' }}>
          <SearchBar
            text="Search Apps"
            onChange={(text) => setFilter(text)}
            // @ts-ignore
            css={{
              flex: 1,
              marginRight: remMapper('xxsmall'),
            }}
          />
          <OrderByButton sortOrder={sortOrder} setSortOrder={setSortOrder} />
          <IconButton
            size="small"
            onClick={handleOpenFilter}
          >
            <FilterList />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom' }}
            transformOrigin={{ 'horizontal': 'center', 'vertical': 'top' }}
            css={({
              'margin-top': '10px',
            })}
          >
            <Box padding="small">
              <ButtonGroup
                color="secondary"
                variant="outlined"
              >
                {
                STATUS_FILTERS.map((f) => (
                  <Button
                    key={`status_filter_${f.key}`}
                    variant={f.key === currentStatus.key ? 'contained' : 'outlined'}
                    onClick={() => setCurrentStatus(f)}
                  >
                    {f.label}
                  </Button>
                ))
                }
              </ButtonGroup>
            </Box>
          </Popover>
          {
            apps.length > 0 && (
              <div css={{ marginLeft: remMapper('small'), minWidth: remMapper('xxlarge'), textAlign: 'right' }}>
                <Typography component="div" variant="subtitle2" color="textSecondary">
                  {account.limits.maxApps ? `${props.apps.data.length} / ${account.limits.maxApps} sites used` : `${filteredApps.length} ${getFilterStatus()} apps`}
                </Typography>
              </div>
            )
          }
          {
            (props.permissions.directory?.[account.id]?.role > ROLES.APPONLY || isPagelyAdmin) && (
              <div>
                <ErrorBoundary>
                  <AppCreate
                    account={account}
                    isPagelyAdmin={isPagelyAdmin}
                    disabled={!account.provisioned
                    || (props.apps.data.length >= account.limits.maxApps)}
                    serverTags={serverTags}
                    app={props.app}
                    mounts={props.mounts}
                  />
                </ErrorBoundary>
              </div>
            )
          }
        </Box>
        <ErrorBoundary>
          {/* show credentials I guess? */}
          {
            managedState.state.showCredentials !== 'hide' && (
              <Credentials
                accountId={account.id}
                appCreation
              />
            )
          }
        </ErrorBoundary>
        {/* // this is where we render the list! */}
        <ErrorBoundary>
          {
            apps.length === 0 ? (
              <Empty>
                No Apps created yet! You can create new apps with the &quot;New App&quot; button above.
              </Empty>
            ) : (
              /* @ts-ignore */
              <AppsBySubscriptionList
                apps={filteredApps}
                jobs={jobs}
                fsaJobs={fsaJobs}
                isAdmin={isPagelyAdmin}
                appsTags={appsTags.current}
              />
            )
          }
        </ErrorBoundary>
      </NewAppContext.Provider>
    </Layout>
  );
};

export default connect(
  (state: any) => ({
    account: state.account,
    apps: state.apps,
    app: state.app.app,
    credentials: state.app.app.data.credentials,
    accountId: state.account.id,
    mounts: state.mounts,
    jobs: {
      ...state.status[STATUS.jobTypes.appCreate],
      data: getSortedJobs(state, STATUS.jobTypes.appCreate),
    },
    fsaJobs: {
      // @ts-ignore
      ...state.status[STATUS.jobTypes.fsaSetup],
      ...state.status[STATUS.jobTypes.fsaSetupCloudflare],
      data: [
        ...getSortedJobs(state, STATUS.jobTypes.fsaSetup),
        ...getSortedJobs(state, STATUS.jobTypes.fsaSetupCloudflare),
      ],
    },
    serverTags: state.tags.server,
    permissions: state.permissions,
    user: state.user,
  }),
  {
    resetApp,
    fetchAppCreateJobs,
    fetchFsaSetupJobsForAccount,
    fetchMounts,
    fetchAppMetaTags,
  },
)(AppListLoader);
