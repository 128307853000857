/* eslint-disable react/prop-types */
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Paper } from '@material-ui/core';

// styleguide
import Box from 'shared/styleguide/atoms/Box';
import { getChargebeeAccount } from 'shared/modules/billing/redux/actions';

import { PaymentLink } from 'shared/modules/billing/Link';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';

const PaymentOverview = ({
  getChargebeeAccount, account, customer,
}) => {
  useEffect(() => {
    if (account.hasSubscription && account.billingCustomerId) {
      if (customer.status === 'pristine' || (customer.status === 'success' && customer.params.customerId !== account.billingCustomerId)) {
        getChargebeeAccount(account.billingCustomerId);
      }
    }
  }, [customer.status, customer.params, getChargebeeAccount, account.hasSubscription, account.billingCustomerId]);

  if (!account.hasSubscription) {
    return (
      <Paper>
        <Box padding="small">
          <Typography variant="h3" gutterBottom>Payment Information</Typography>
        </Box>
      </Paper>
    );
  }

  if (['loading', 'pristine'].includes(customer.status) || !customer.status) {
    return (
      <Paper>
        <Box padding="small">
          <Typography variant="h3" gutterBottom>Payment Information</Typography>
          <Box>
            <LoadingDots />
          </Box>
        </Box>
      </Paper>
    );
  }

  const {
    paymentMethod, card,
  } = customer.data;
  return (
    <Paper>
      <Box padding="small">
        <Typography variant="h3" gutterBottom>Payment Information</Typography>

        <Box>
          {
            paymentMethod === 'card' && (
              <Fragment>
                <Typography>Card ending in  ****{card.last4}</Typography>
                <Typography variant="body2" color="textSecondary">Expires {card.expiryMonth}/{card.expiryYear}</Typography>
              </Fragment>
            )
          }
          {
            paymentMethod === 'paypal_express_checkout' && (
              <Fragment>
                <Typography variant="body2" color="textSecondary">Using PayPal for payment</Typography>
              </Fragment>
            )
          }
          {
            !paymentMethod && (
              <Typography variant="body2" color="textSecondary">No payment method on file.</Typography>
            )
          }
        </Box>

        <Box direction="row" justify="flex-end" padding={{ top: 'small' }}>
          <PaymentLink>
            Edit payment method
          </PaymentLink>
        </Box>
      </Box>
    </Paper>
  );
};

export default connect(
  (state) => ({
    account: state.account,
    customer: state.billing.customer,
  }),
  {
    getChargebeeAccount,
  },
)(PaymentOverview);
