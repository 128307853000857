import _pick from 'lodash.pick';

export const consolidateTeamPermissions = (acc, currObj) => {
  const isInTeam = acc.findIndex((v) => (v.sourceId === currObj.sourceId
    && v.accessedByEmail === currObj.accessedByEmail));
  if (isInTeam === -1) {
    // filter out extra props actually...
    acc.push({
      ..._pick(currObj, [
        'sourceId',
        'targetId',
        'role',
        'accessedByName',
        'accessedByEmail',
        'mfaRequired',
        'alerts',
        'allowPasswordLogin',
      ]),
      appIds: [currObj.appId],
      domains: [currObj.domain],
    });
    return acc;
  }
  // push appId into
  acc[isInTeam].appIds.push(currObj.appId);
  return acc;
};
