import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';

import {
  any, oneOf, string, bool,
} from 'prop-types';

import { sizesProp, genericSizesProp } from 'shared/styleguide/theme/sizes';
import { remMapper } from 'shared/styleguide/theme/spacing';

const rectMapper = (top, right, bottom, left) => {
  return `${top ? remMapper(top) : 0} ${right ? remMapper(right) : 0} ${bottom ? remMapper(bottom) : 0} ${left ? remMapper(left) : 0}`;
};

const StyledBox = styled('div', {
  shouldForwardProp: (prop) => {
    // allowed props:
    if (['isAdmin'].includes(prop)) {
      return true;
    }
    return isPropValid(prop) && !['fullWidth', 'direction'].includes(prop);
  },
})(
  css`
    display: flex;
  `,
  ({ align }) => align && css`
    align-items: ${align};
  `,
  ({ justify }) => justify && css`
    justify-content: ${justify};
  `,
  ({ gap }) => gap && css`
    gap: ${remMapper(gap)};
  `,
  ({ flex }) => flex && css`
    flex: ${flex || 1};
  `,
  ({ direction }) => css`
    flex-direction: ${direction};
  `,
  ({ wrap }) => css`
    flex-wrap: ${wrap || 'unset'};
  `,
  ({ position }) => position && css`
    position: ${position || 'inherit'};
  `,
  ({ pull }) => pull && css`
    float: ${pull || 'inherit'};
  `,
  ({ padding }) => {
    const def = typeof padding === 'string' ? padding : 0;
    return padding && css`
      padding: ${rectMapper(padding.top || def, padding.right || def, padding.bottom || def, padding.left || def)};
    `;
  },
  ({ margin }) => {
    const def = typeof margin === 'string' ? margin : 0;
    return margin && css`
      margin: ${rectMapper(margin.top || def, margin.right || def, margin.bottom || def, margin.left || def)};
    `;
  },
  ({ theme, hoverColor }) => {
    const colors = theme.palette?.colors;
    return hoverColor && colors?.[hoverColor] && css`
      transition: background ease-out 0.2s;
      &:hover {
        background-color: ${colors[hoverColor]};
      }
    `;
  },
  ({ fullWidth }) => fullWidth && css`
    width: 100%;
  `,
);

const Box = ({
  as = undefined,
  a11yTitle = undefined,
  direction = 'column',
  wrap = undefined,
  justify = undefined,
  align = undefined,
  gap = undefined,
  flex = undefined,
  margin = undefined,
  padding = undefined,
  pull = undefined,
  position = undefined,
  hoverColor = undefined,
  fullWidth = false,
  row = undefined,
  column = undefined,
  ...props
}) => {
  // don't passthrough row or col, let direction be the source of truth
  let dir = row ? 'row' : direction;
  dir = column ? 'column' : dir;

  return (
    <StyledBox
      as={as}
      aria-label={a11yTitle}
      direction={dir}
      wrap={wrap}
      justify={justify}
      align={align}
      flex={flex}
      gap={gap}
      pull={pull}
      margin={margin}
      padding={padding}
      position={position}
      hoverColor={hoverColor}
      fullWidth={fullWidth}
      {...props}
    />
  );
};

Box.propTypes = {
  a11yTitle: string,
  align: oneOf(['left', 'right', 'center', 'flex-end', 'flex-start', 'stretch']),
  as: any,
  background: string,
  color: string,
  column: bool,
  direction: oneOf(['column', 'row', 'row-reverse', 'initial']),
  flex: any,
  fullWidth: bool,
  gap: sizesProp,
  hoverColor: string,
  justify: string,
  margin: genericSizesProp,
  padding: genericSizesProp,
  position: string,
  pull: oneOf(['left', 'right']),
  row: bool,
  wrap: string,
};

export default Box;
