/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import InfoText from 'shared/styleguide/molecules/InfoText';
import {
  tagMap, FAILURE, ACTION_REQUIRED, RUNNING,
} from 'shared/modules/status/redux/constants';
import ConfirmAction from 'shared/styleguide/molecules/ConfirmAction';
import { DNS_DOCS } from 'shared/utils/constants';

import { fsaJobTypes, skippableEnableSteps, skippableDisableSteps } from './constants';
import { roundedUpDuration, getValidationTypeToShow } from './utils';

import StepRunning from './components/StepRunning';
import StepActionRequired from './components/StepActionRequired';
import StepFailure from './components/StepFailure';

const FSAContent = ({
  state,
  job,
  isSetupJob,
  displayJobType,
  jobDetails,
  jobId,
  jobStep,
  dnsJobs,
  validationRecords,
  fetchStatusJob,
  onFetchAllValidations,
  resumeMethod,
  resumeApiStatus,
}) => {
  const invalidRecords = jobDetails?.data?.invalidDomains || jobDetails?.data?.invalidAcmRecords || [];
  const invalidDomainIds = Array.isArray(invalidRecords) ? invalidRecords.map((r) => r.domainId) : [];
  const validationstoShow = validationRecords.filter((v) => invalidDomainIds.includes(v.domainId));

  useEffect(() => {
    if (invalidDomainIds.length > 0 && jobStep.status === ACTION_REQUIRED) {
      // make sure we have the updated validations
      onFetchAllValidations();
    }
  }, [jobStep.id, jobStep.status]);

  const isSkippable = () => {
    if (isSetupJob) {
      return skippableEnableSteps.includes(jobStep.id);
    }

    return skippableDisableSteps.includes(jobStep.id);
  };

  const skipButton = () => {
    if (isSkippable()) {
      // all skippable steps are related to DNS propagation
      // so this message works for all of them
      return (
        <ConfirmAction
          onConfirm={() => {
            resumeMethod(
              true,
              {
                skipCurrentStep: true,
                createLECertificate: Boolean(job.detail.createLECertificate),
                force: Boolean(job.detail.force),
              },
            );
          }}
          explainerText={`You are skipping the ${jobStep.label} step.`}
          secondaryText="Skipping this step may result in site downtime if DNS propagation is not complete."
          variant="outlined"
          loading={resumeApiStatus === 'loading'}
        >
          Skip {jobStep.label}
        </ConfirmAction>
      );
    }

    return null;
  };

  const getInvalidDomainsWithNSRules = () => {
    return validationstoShow.filter((d) => {
      return d.rules?.records.find((r) => r.label === 'PressDNS Nameservers');
    });
  };

  const memoizedInvalidDomainsWithNSRules = useCallback(getInvalidDomainsWithNSRules, [validationstoShow]);

  const validationType = getValidationTypeToShow(jobStep.id);

  return (
    <Box direction="column" gap="small" flex={1}>
      <Box>
        {
          jobStep.id === 'dns-propagation' && jobDetails?.data?.wait > 0
          && (
            <div>
              <Typography color="textSecondary">
                Estimated time to complete this step: {roundedUpDuration(jobDetails.data.wait)}
              </Typography>
              <Box margin={{ top: 'small' }}>
                <InfoText href={DNS_DOCS.url}>{DNS_DOCS.text}</InfoText>
              </Box>
            </div>
          )
        }
      </Box>
      {
        [FAILURE, ACTION_REQUIRED].includes(jobStep.status)
        && (
          <Box direction="row" gap="xsmall" align="center" flex={1}>
            <Box>
              <GhostTag filled color={tagMap[jobStep.status]}>{jobStep.status.replace('_', ' ')}</GhostTag>
            </Box>
          </Box>
        )
      }
      {
        jobStep.status === RUNNING
        && (
          <StepRunning
            jobDetails={jobDetails}
            dnsJobs={dnsJobs}
            validationRecords={validationstoShow}
            fetchStatusJob={fetchStatusJob}
            expanded={memoizedInvalidDomainsWithNSRules().length === 1}
            validationType={validationType}
          />
        )
      }
      {
        jobStep.status === ACTION_REQUIRED
        && (
          <StepActionRequired
            jobDetails={jobDetails}
            dnsJobs={dnsJobs}
            validationRecords={validationstoShow}
            fetchStatusJob={fetchStatusJob}
            expanded={memoizedInvalidDomainsWithNSRules().length === 1}
            jobTypeLabel={fsaJobTypes[displayJobType].label}
            validationType={validationType}
          />
        )
      }
      {
        jobStep.status === FAILURE
          ? (
            <StepFailure
              jobDetails={jobDetails}
              jobId={jobId}
              jobStep={jobStep}
              skipButton={skipButton}
            />
          ) : (
            <Box direction="row" justify="flex-start" margin={{ top: 'medium' }}>
              {skipButton()}
            </Box>
          )
      }
    </Box>
  );
};

export default FSAContent;
