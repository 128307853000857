import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import { DOMAINS_REQUEST, DOMAINS_SUCCESS, DOMAINS_FAILURE } from './constants';

import * as APP from '../constants';

export const fetchDomainsForAccount = (accountId, options = {}) => makeApiAction(
  ({ api }) => {
    const params = qs.stringify({ ...options, accountId }, { addQueryPrefix: false });
    return api().get(`/apps/aliases?${params}`);
  },
  DOMAINS_REQUEST,
  DOMAINS_SUCCESS,
  DOMAINS_FAILURE,
  { accountId, options: Boolean(Object.keys(options).length) },
);

export const fetchDomainsForApp = (appId) => makeApiAction(
  ({ api }) => api().get(`/apps/${appId}/aliases`),
  APP.APP_DOMAINS_FETCH_REQUEST,
  APP.APP_DOMAINS_FETCH_SUCCESS,
  APP.APP_DOMAINS_FETCH_FAILURE,
  { appId },
  true,
);

export const promoteDomainToPrimary = (appId, domainId, autoNameApp = false, domain = null) => makeApiAction(
  ({ api }) => api().post(`/apps/${appId}/aliases/${domainId}/makeprimary`, {
    autoNameApp,
  }),
  APP.APP_DOMAIN_PROMOTE_REQUEST,
  APP.APP_DOMAIN_PROMOTE_SUCCESS,
  APP.APP_DOMAIN_PROMOTE_FAILURE,
  {
    appId,
    domainId,
    autoNameApp,
    domain,
  },
  true,
);

// should probably get moved to domain actions?
export const removeAppDomain = (appId, domainId) => makeApiAction(
  ({ api }) => api().delete(`/apps/${appId}/aliases/${domainId}`),
  APP.APP_DOMAIN_REMOVE_REQUEST,
  APP.APP_DOMAIN_REMOVE_SUCCESS,
  APP.APP_DOMAIN_REMOVE_FAILURE,
  {
    appId,
    domainId,
  },
);

export const moveAppDomain = (appId, aliasId, toAppId, params = {}) => makeApiAction(
  ({ api }) => api().post(`/apps/${appId}/aliases/${aliasId}/move`, {
    toAppId,
  }),
  APP.MOVE_APP_DOMAIN_REQUEST,
  APP.MOVE_APP_DOMAIN_SUCCESS,
  APP.MOVE_APP_DOMAIN_FAILURE,
  {
    appId, aliasId, toAppId, ...params,
  },
  true,
);

export const addDomain = (appId, domain) => makeApiAction(
  ({ api }) => api().post(`/apps/${appId}/aliases`, {
    fqdn: domain,
  }),
  APP.APP_DOMAIN_ADD_REQUEST,
  APP.APP_DOMAIN_ADD_SUCCESS,
  APP.APP_DOMAIN_ADD_FAILURE,
  {
    appId,
    domain,
  },
  true,
);

export const bulkModifyDomains = (dispatchId, appId, body) => makeApiAction(
  ({ api }) => api().post(`/apps/${appId}/aliases/bulk`, body),
  APP.APP_DOMAINS_MODIFY_REQUEST,
  APP.APP_DOMAINS_MODIFY_SUCCESS,
  APP.APP_DOMAINS_MODIFY_FAILURE,
  {
    appId,
    dispatchId,
  },
);

export const bulkModifyDomainsReset = () => ({
  type: APP.APP_DOMAINS_MODIFY_RESET,
});

export const appDomainReset = () => ({
  type: APP.APP_DOMAIN_RESET,
});

export const set301 = (appId, domainId, redirect) => makeApiAction(
  ({ api }) => api().patch(`/apps/${appId}/aliases/${domainId}`, {
    is301: redirect,
  }),
  APP.APP_DOMAIN_301_REQUEST,
  APP.APP_DOMAIN_301_SUCCESS,
  APP.APP_DOMAIN_301_FAILURE,
  {
    appId,
    domainId,
    redirect,
  },
);

export const setActive = (appId, domainId, isActive) => makeApiAction(
  ({ api }) => api().patch(`/apps/${appId}/aliases/${domainId}`, {
    isActive,
  }),
  APP.APP_DOMAIN_ACTIVE_REQUEST,
  APP.APP_DOMAIN_ACTIVE_SUCCESS,
  APP.APP_DOMAIN_ACTIVE_FAILURE,
  {
    appId,
    domainId,
    isActive,
  },
);
