/* eslint-disable max-len */
import React from 'react';
import SVGIcon from '@material-ui/core/SvgIcon';

const LogoWorkdmark = ({ ...props }) => (
  <SVGIcon {...props} width="83px" height="27px" viewBox="0 0 83 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Pagely Logo</title>
    <g id="UI-Kit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Hero-Text-Card-(Dark-mode)" transform="translate(-86.000000, -55.000000)" fill="currentColor" fillRule="nonzero">
        <g id="Pagely-Logo" transform="translate(86.045705, 55.433063)">
          <path d="M80.9335494,5.85282938 C81.7248499,5.85073736 82.4394015,6.32578989 82.7436746,7.0562546 C83.0479476,7.7867193 82.8819459,8.62856393 82.3231536,9.18884239 C81.7643612,9.74912084 80.9229605,9.917358 80.1916902,9.61502616 C79.4604199,9.31269432 78.9834667,8.59940695 78.9834667,7.80810373 C78.982779,7.28932352 79.1885584,6.79159294 79.5553917,6.42475962 C79.922225,6.0579263 80.4199556,5.85214689 80.9387358,5.85282938 M80.9387358,6.20810373 C80.2878844,6.19016427 79.6910736,6.56841222 79.4294745,7.16464646 C79.1678754,7.76088071 79.2936681,8.45617259 79.7475888,8.92295446 C80.2015096,9.38973632 80.8930166,9.53490091 81.4963252,9.29005792 C82.0996338,9.04521492 82.4944044,8.45920228 82.4946515,7.80810373 C82.5033365,6.93801396 81.8087287,6.22372563 80.9387358,6.20810373 M80.2074554,6.66191248 L81.0580227,6.66191248 C81.5766613,6.66191248 81.8359806,6.86936791 81.8359806,7.30502431 C81.848963,7.46747538 81.7937166,7.62799629 81.6835053,7.74804784 C81.5732941,7.86809938 81.4180717,7.9368391 81.2551053,7.93776337 L81.9111831,8.94132901 L81.4755267,8.94132901 L80.8246353,7.95591572 L80.5393841,7.95591572 L80.5393841,8.94132901 L80.1841167,8.94132901 L80.1841167,6.66191248 L80.2074554,6.66191248 Z M80.5575365,7.64473258 L80.9620746,7.64473258 C81.3069692,7.64473258 81.4807131,7.54619125 81.4807131,7.3076175 C81.4807131,7.06904376 81.3121556,7.00162075 81.0658023,7.00162075 L80.5471637,7.00162075 L80.5575365,7.64473258 Z" id="Shape" />
          <path d="M0,20.1724473 L0,0.56012966 L7.87293355,0.56012966 C11.9649919,0.56012966 14.4025932,3.08071313 14.4025932,6.63857374 C14.4025932,10.1964344 11.9649919,12.719611 7.87293355,12.719611 L3.72382496,12.719611 L3.72382496,20.1724473 L0,20.1724473 Z M3.72641815,9.60777958 L7.70437601,9.60777958 C9.8904376,9.60777958 10.7876823,8.20745543 10.7876823,6.63857374 C10.7876823,5.06969206 9.8904376,3.66936791 7.70437601,3.66936791 L3.72641815,3.66936791 L3.72641815,9.60777958 Z" id="Shape" />
          <path d="M13.9513776,13.1111831 C13.9513776,8.79611021 16.4175041,5.82690438 20.6210697,5.82690438 C22.3844408,5.82690438 23.9247974,6.55559157 24.73906,7.64213938 L24.73906,6.04991896 L28.1854133,6.04991896 L28.1854133,20.1724473 L24.73906,20.1724473 L24.73906,18.5179903 C24.0648298,19.6382496 22.4414911,20.3954619 20.6755267,20.3954619 C16.4175041,20.3954619 13.9513776,17.4262561 13.9513776,13.1111831 M24.9905997,13.1111831 C24.9905997,10.5179903 23.476175,8.79611021 21.1526742,8.79611021 C18.8836305,8.79611021 17.3692058,10.5050243 17.3692058,13.1111831 C17.3692058,15.717342 18.8836305,17.4262561 21.1552674,17.4262561 C23.489141,17.4262561 24.9931929,15.717342 24.9931929,13.1111831" id="Shape" />
          <path d="M40.3967585,6.04991896 L43.8431118,6.04991896 L43.8431118,20.9555916 C43.8431118,23.8988655 41.7141005,25.882658 38.547812,25.882658 L31.5721232,25.882658 L31.5721232,23.1416532 L38.0732577,23.1416532 C39.4450567,23.1416532 40.4252836,22.104376 40.4252836,20.6210697 L40.4252836,18.6035656 C39.7251216,19.6952998 38.09141,20.3954619 36.3358185,20.3954619 C32.0777958,20.3954619 29.6116694,17.4262561 29.6116694,13.1111831 C29.6116694,8.79611021 32.0777958,5.82949757 36.2787682,5.82949757 C38.0447326,5.82949757 39.5850891,6.55818476 40.3967585,7.64473258 L40.3967585,6.04991896 Z M40.6560778,13.1111831 C40.6560778,10.5179903 39.1416532,8.79611021 36.8155592,8.79611021 C34.4894652,8.79611021 33.0294976,10.4972447 33.0294976,13.1111831 C33.0294976,15.7251216 34.5153971,17.4262561 36.8103728,17.4262561 C39.1053485,17.4262561 40.6508914,15.717342 40.6508914,13.1111831" id="Shape" />
          <path d="M58.9354943,15.4658023 C58.4038898,18.4635332 55.7692058,20.3928687 52.2943274,20.3928687 C47.6732577,20.3928687 45.095624,16.7623987 45.095624,13.0515397 C45.095624,9.34068071 47.3931929,5.82949757 52.0142626,5.82949757 C56.7494327,5.82949757 58.8784441,9.27585089 58.8784441,12.6366288 C58.8784441,13.1137763 58.849919,13.5338736 58.823987,13.8139384 L48.4019449,13.8139384 C48.6612642,16.0829822 50.110859,17.5403566 52.2917342,17.5403566 C54.0576985,17.5403566 55.206483,16.8116694 55.56953,15.4658023 L58.9354943,15.4658023 Z M48.4849271,11.5345219 L55.4320908,11.5345219 C55.2920583,9.74262561 54.0602917,8.39675851 52.0142626,8.39675851 C50.0823339,8.39675851 48.8479741,9.40551053 48.4849271,11.5345219" id="Shape" />
          <rect id="Rectangle" x="60.36953" y="0" width="3.4178282" height="20.1724473" />
          <polygon id="Path" points="78.1692058 6.04991896 73.236953 19.4982172 71.0534846 25.8878444 67.7730956 25.8878444 69.7646677 20.1724473 64.6094003 6.04991896 68.1102107 6.04991896 71.3880065 15.7458671 74.6398703 6.04991896" />
        </g>
      </g>
    </g>
  </SVGIcon>
);
LogoWorkdmark.propTypes = SVGIcon.propTypes;
LogoWorkdmark.displayName = 'PagelyLogo';

export default LogoWorkdmark;
