import * as base from 'shared/baseStates';
import { LOGIN_LOGOUT_SUCCESS } from 'shared/modules/authentication/redux/constants';
import {
  LETSENCRYPT_STATUS_REQUEST,
  LETSENCRYPT_STATUS_SUCCESS,
  LETSENCRYPT_STATUS_FAILURE,
  LETSENCRYPT_REGISTER_REQUEST,
  LETSENCRYPT_REGISTER_SUCCESS,
  LETSENCRYPT_REGISTER_FAILURE,
  LETSENCRYPT_RESET,
  LETSENCRYPT_SET_DOMAIN,
  LETSENCRYPT_STATUS_CHECK_SITE_REQUEST,
  LETSENCRYPT_STATUS_CHECK_SITE_SUCCESS,
  LETSENCRYPT_STATUS_CHECK_SITE_FAILURE,
  LETSENCRYPT_STATUS_CHECK_CDN_REQUEST,
  LETSENCRYPT_STATUS_CHECK_CDN_SUCCESS,
  LETSENCRYPT_STATUS_CHECK_CDN_FAILURE,
} from 'shared/modules/ssl/redux/letsencrypt/actions';
import { CERT_DOMAIN_DEACTIVATE_SUCCESS } from 'shared/modules/ssl/redux/certDomainLink/actions';
import { CERT_RESET } from 'shared/modules/ssl/redux/cert/actions';

export const initialState = {
  id: null,
  status: null,
  step: null,
  domain: null,
  appId: null,
  siteChecking: false,
  siteChecked: false,
  cdnChecking: false,
  cdnChecked: false,
  ...base.record(),
};

export function letsencryptStatus(state = initialState, action) {
  switch (action.type) {
    case LETSENCRYPT_STATUS_REQUEST:
    case LETSENCRYPT_REGISTER_REQUEST:
      return {
        ...state,
        submitted: true,
        ...base.request(),
      };
    case LETSENCRYPT_STATUS_SUCCESS:
    case LETSENCRYPT_REGISTER_SUCCESS:
    case CERT_DOMAIN_DEACTIVATE_SUCCESS:
      return {
        ...state,
        submitted: true,
        ...action.data,
        ...base.success(),
      };
    case LETSENCRYPT_STATUS_FAILURE:
    case LETSENCRYPT_REGISTER_FAILURE:
      return {
        ...state,
        submitted: true,
        status: 'FAILED',
        ...base.failure(action.errMessage, action.statusCode, action.validation),
      };

    case LETSENCRYPT_STATUS_CHECK_SITE_REQUEST:
      return {
        ...state,
        siteChecking: true,
      };
    case LETSENCRYPT_STATUS_CHECK_SITE_FAILURE:
      return {
        ...state,
        siteChecking: false,
      };
    case LETSENCRYPT_STATUS_CHECK_SITE_SUCCESS:
      return {
        ...state,
        siteChecking: false,
        siteChecked: true,
      };

    case LETSENCRYPT_STATUS_CHECK_CDN_REQUEST:
      return {
        ...state,
        cdnChecking: true,
      };
    case LETSENCRYPT_STATUS_CHECK_CDN_FAILURE:
      return {
        ...state,
        cdnChecking: false,
      };
    case LETSENCRYPT_STATUS_CHECK_CDN_SUCCESS:
      return {
        ...state,
        cdnChecking: false,
        cdnChecked: true,
      };

    case LETSENCRYPT_RESET:
    case CERT_RESET:
      return { ...initialState };

    case LETSENCRYPT_SET_DOMAIN:
      return {
        ...initialState,
        domain: action.domain,
        appId: action.appId,
      };

    case LOGIN_LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return state;
  }
}
