import PropTypes from 'prop-types';
import moment from 'moment';

export const propTypes = {
  activeCount: PropTypes.number.isRequired,
  alternateNames: PropTypes.array.isRequired,
  certType: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  domains: PropTypes.array.isRequired,
  expires: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  issuedBy: PropTypes.string.isRequired,
  issuerData: PropTypes.shape({
    country: PropTypes.string,
    email: PropTypes.string,
    locality: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  startDate: PropTypes.string.isRequired,
  subject: PropTypes.string,
  subjectData: PropTypes.shape({
    country: PropTypes.string,
    email: PropTypes.string,
    locality: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  updated: PropTypes.string,
};

export const arrayOfPropTypes = PropTypes
  .arrayOf(PropTypes.shape(propTypes).isRequired).isRequired;

const formatCertificate = (cert) => {
  const raw = JSON.parse(cert.rawData);

  return {
    ...cert,
    subjectData: {
      name: cert.subject,
      email: raw.subject.E,
      state: raw.subject.ST,
      country: raw.subject.C,
      locality: raw.subject.L,
      organization: raw.subject.O,
    },
    issuerData: {
      name: raw.issuer.CN,
      email: raw.issuer.E,
      state: raw.issuer.ST,
      country: raw.issuer.C,
      locality: raw.issuer.L,
      organization: raw.issuer.O,
    },
    activeCount: cert.activeCount || 0,
    domains: cert.domains || [],
    alternateNames: cert.alternateNames || [],
  };
};

export const formatForStorybook = (cert) => {
  const expires = moment('2018-06-01T00:00:00Z').toISOString();
  return formatCertificate({
    ...cert,
    expires,
  });
};

export default formatCertificate;
