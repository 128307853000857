export const APP_RESET_OPTIONS_REQUEST = 'APP_RESET_OPTIONS_REQUEST';
export const APP_RESET_OPTIONS_SUCCESS = 'APP_RESET_OPTIONS_SUCCESS';
export const APP_RESET_OPTIONS_FAILURE = 'APP_RESET_OPTIONS_FAILURE';

export const APP_ENABLE_SFTP_REQUEST = 'APP_ENABLE_SFTP_REQUEST';
export const APP_ENABLE_SFTP_SUCCESS = 'APP_ENABLE_SFTP_SUCCESS';
export const APP_ENABLE_SFTP_FAILURE = 'APP_ENABLE_SFTP_FAILURE';

export const APP_DISABLE_SFTP_REQUEST = 'APP_DISABLE_SFTP_REQUEST';
export const APP_DISABLE_SFTP_SUCCESS = 'APP_DISABLE_SFTP_SUCCESS';
export const APP_DISABLE_SFTP_FAILURE = 'APP_DISABLE_SFTP_FAILURE';

export const APP_UPDATE_CONFIG_REQUEST = 'APP_UPDATE_CONFIG_REQUEST';
export const APP_UPDATE_CONFIG_SUCCESS = 'APP_UPDATE_CONFIG_SUCCESS';
export const APP_UPDATE_CONFIG_FAILURE = 'APP_UPDATE_CONFIG_FAILURE';

export const APP_DOMAIN_FLUSH_CACHE_REQUEST = 'APP_DOMAIN_FLUSH_CACHE_REQUEST';
export const APP_DOMAIN_FLUSH_CACHE_SUCCESS = 'APP_DOMAIN_FLUSH_CACHE_SUCCESS';
export const APP_DOMAIN_FLUSH_CACHE_FAILURE = 'APP_DOMAIN_FLUSH_CACHE_FAILURE';

export const APP_GET_SQL_INFO_REQUEST = 'APP_GET_SQL_INFO_REQUEST';
export const APP_GET_SQL_INFO_SUCCESS = 'APP_GET_SQL_INFO_SUCCESS';
export const APP_GET_SQL_INFO_FAILURE = 'APP_GET_SQL_INFO_FAILURE';

export const APPS_REQUEST = 'APPS_REQUEST';
export const APPS_SUCCESS = 'APPS_SUCCESS';
export const APPS_FAILURE = 'APPS_FAILURE';

export const APP_CREATE_REQUEST = 'APP_CREATE_REQUEST';
export const APP_CREATE_SUCCESS = 'APP_CREATE_SUCCESS';
export const APP_CREATE_FAILURE = 'APP_CREATE_FAILURE';

export const UPDATE_APP_REQUEST = 'APP_UPDATE_APP_REQUEST';
export const UPDATE_APP_SUCCESS = 'APP_UPDATE_APP_SUCCESS';
export const UPDATE_APP_FAILURE = 'APP_UPDATE_APP_FAILURE';

export const APP_DELETE_REQUEST = 'APP_DELETE_REQUEST';
export const APP_DELETE_SUCCESS = 'APP_DELETE_SUCCESS';
export const APP_DELETE_FAILURE = 'APP_DELETE_FAILURE';

export const APP_DISABLE_REQUEST = 'APP_DISABLE_REQUEST';
export const APP_DISABLE_SUCCESS = 'APP_DISABLE_SUCCESS';
export const APP_DISABLE_FAILURE = 'APP_DISABLE_FAILURE';

export const APP_ENABLE_REQUEST = 'APP_ENABLE_REQUEST';
export const APP_ENABLE_SUCCESS = 'APP_ENABLE_SUCCESS';
export const APP_ENABLE_FAILURE = 'APP_ENABLE_FAILURE';

export const APP_FETCH_REQUEST = 'APP_FETCH_REQUEST';
export const APP_FETCH_SUCCESS = 'APP_FETCH_SUCCESS';
export const APP_FETCH_FAILURE = 'APP_FETCH_FAILURE';
export const APP_RESET = 'APP_RESET';

export const APP_DOMAINS_FETCH_REQUEST = 'APP_DOMAINS_FETCH_REQUEST';
export const APP_DOMAINS_FETCH_SUCCESS = 'APP_DOMAINS_FETCH_SUCCESS';
export const APP_DOMAINS_FETCH_FAILURE = 'APP_DOMAINS_FETCH_FAILURE';

export const APP_TAGS_FETCH_REQUEST = 'APP_TAGS_FETCH_REQUEST';
export const APP_TAGS_FETCH_SUCCESS = 'APP_TAGS_FETCH_SUCCESS';
export const APP_TAGS_FETCH_FAILURE = 'APP_TAGS_FETCH_FAILURE';

export const APP_GET_IP_LIST_REQUEST = 'APP_GET_IP_LIST_REQUEST';
export const APP_GET_IP_LIST_SUCCESS = 'APP_GET_IP_LIST_SUCCESS';
export const APP_GET_IP_LIST_FAILURE = 'APP_GET_IP_LIST_FAILURE';

export const APP_CREDENTIALS_FETCH_REQUEST = 'APP_CREDENTIALS_FETCH_REQUEST';
export const APP_CREDENTIALS_FETCH_SUCCESS = 'APP_CREDENTIALS_FETCH_SUCCESS';
export const APP_CREDENTIALS_FETCH_FAILURE = 'APP_CREDENTIALS_FETCH_FAILURE';

export const APP_FTP_CREDENTIALS_FETCH_REQUEST = 'APP_FTP_CREDENTIALS_FETCH_REQUEST';
export const APP_FTP_CREDENTIALS_FETCH_SUCCESS = 'APP_FTP_CREDENTIALS_FETCH_SUCCESS';
export const APP_FTP_CREDENTIALS_FETCH_FAILURE = 'APP_FTP_CREDENTIALS_FETCH_FAILURE';

export const APP_DOMAIN_PROMOTE_REQUEST = 'APP_DOMAIN_PROMOTE_REQUEST';
export const APP_DOMAIN_PROMOTE_SUCCESS = 'APP_DOMAIN_PROMOTE_SUCCESS';
export const APP_DOMAIN_PROMOTE_FAILURE = 'APP_DOMAIN_PROMOTE_FAILURE';

export const APP_DOMAIN_REMOVE_REQUEST = 'APP_DOMAIN_REMOVE_REQUEST';
export const APP_DOMAIN_REMOVE_SUCCESS = 'APP_DOMAIN_REMOVE_SUCCESS';
export const APP_DOMAIN_REMOVE_FAILURE = 'APP_DOMAIN_REMOVE_FAILURE';

export const APP_DOMAIN_301_REQUEST = 'APP_DOMAIN_301_REQUEST';
export const APP_DOMAIN_301_SUCCESS = 'APP_DOMAIN_301_SUCCESS';
export const APP_DOMAIN_301_FAILURE = 'APP_DOMAIN_301_FAILURE';

export const APP_DOMAIN_ACTIVE_REQUEST = 'APP_DOMAIN_ACTIVE_REQUEST';
export const APP_DOMAIN_ACTIVE_SUCCESS = 'APP_DOMAIN_ACTIVE_SUCCESS';
export const APP_DOMAIN_ACTIVE_FAILURE = 'APP_DOMAIN_ACTIVE_FAILURE';

export const APP_DOMAIN_ADD_REQUEST = 'APP_DOMAIN_ADD_REQUEST';
export const APP_DOMAIN_ADD_SUCCESS = 'APP_DOMAIN_ADD_SUCCESS';
export const APP_DOMAIN_ADD_FAILURE = 'APP_DOMAIN_ADD_FAILURE';
export const APP_DOMAIN_RESET = 'APP_DOMAIN_RESET';

export const APP_DOMAINS_MODIFY_REQUEST = 'APP_DOMAINS_MODIFY_REQUEST';
export const APP_DOMAINS_MODIFY_SUCCESS = 'APP_DOMAINS_MODIFY_SUCCESS';
export const APP_DOMAINS_MODIFY_FAILURE = 'APP_DOMAINS_MODIFY_FAILURE';
export const APP_DOMAINS_MODIFY_RESET = 'APP_DOMAINS_MODIFY_RESET';

export const APP_GET_BACKUPS_REQUEST = 'APP_GET_BACKUPS_REQUEST';
export const APP_GET_BACKUPS_SUCCESS = 'APP_GET_BACKUPS_SUCCESS';
export const APP_GET_BACKUPS_FAILURE = 'APP_GET_BACKUPS_FAILURE';
export const APP_BACKUPS_RESET = 'APP_BACKUPS_RESET';

export const APP_GET_BACKUP_REQUEST = 'APP_GET_BACKUP_REQUEST';
export const APP_GET_BACKUP_SUCCESS = 'APP_GET_BACKUP_SUCCESS';
export const APP_GET_BACKUP_FAILURE = 'APP_GET_BACKUP_FAILURE';
export const APP_BACKUP_RESET = 'APP_BACKUP_RESET';

export const MOVE_APP_DOMAIN_REQUEST = 'APP_MOVE_APP_DOMAIN_REQUEST';
export const MOVE_APP_DOMAIN_SUCCESS = 'APP_MOVE_APP_DOMAIN_SUCCESS';
export const MOVE_APP_DOMAIN_FAILURE = 'APP_MOVE_APP_DOMAIN_FAILURE';

export const APP_CREATE_MODAL_OPEN = 'APP_CREATE_MODAL_OPEN';
