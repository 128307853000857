import React, {
  useEffect, useState, useCallback,
} from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import {
  Grid, Typography, Divider, TextField, Collapse, IconButton, Icon,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import CircleCheck from 'shared/styleguide/Icons/CheckCircleOutline';

import logger from 'shared/3rdparty/logger';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import NarrowTextField from 'shared/styleguide/atoms/Input/CodeField';
import { ErrorText } from 'shared/styleguide/typography';
import { consolidateErrors } from 'shared/utils/validation';
import { fetchAccount } from 'shared/modules/account/redux/actions';
import { twoFactorSetupRequest, twoFactorVerificationRequest, twoFactorVerificationReset } from 'shared/modules/authentication/redux/mfa/actions';

const MFAFlow = ({
  account, twoFactorSetupRequest, twoFactorVerificationRequest, twoFactorVerificationReset,
}) => {
  const [code, setCode] = useState(null);
  const [phone, setPhone] = useState(null);
  const [showPhone, setShowPhone] = useState(!account.extMfaId);
  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState(null);

  const fetchCode = async (accountId, phone) => {
    try {
      const code = await twoFactorSetupRequest(accountId, phone);
      setCode(code);
      setShowPhone(false);
    } catch (err) {
      if (err.response.status === 422) {
        setShowPhone(true);
      }
      logger.error(err, { context: 'Requesting an MFA QR code' });
    }
  };
  const memoizedFetch = useCallback(fetchCode, [account.id]);

  useEffect(() => {
    if (account.extMfaId) {
      memoizedFetch(account.id, null);
    }
  }, [memoizedFetch, account.id, account.extMfaId]);

  const handleSubmitVerification = async () => {
    try {
      // try to verify code
      await twoFactorVerificationRequest(account.id, { code: verificationCode });
      // if successful, reset MFA setup
      twoFactorVerificationReset();
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
  };

  const handleRequestWithPhone = (e) => {
    e.preventDefault();
    fetchCode(account.id, phone);
  };

  const leftGridSpacing = 3;
  const rightGridSpacing = 9;

  return (
    <Box padding={{ top: 'medium' }}>
      {/* Step 1 */}
      <Grid container>
        <Grid item sm={4} md={leftGridSpacing} />
        <Grid item sm={8} md={rightGridSpacing}>
          <Box>
            <Typography gutterBottom variant="caption" color="textSecondary">Step 1</Typography>
            <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
              <Typography gutterBottom variant="body1" color="initial"><strong>Use an Authenticator App</strong></Typography>
            </Box>
            <Typography gutterBottom variant="body2" color="textPrimary">
              We recommend using&nbsp;
              <TextLink href="https://authy.com/download/" target="_blank" rel="nofollow noopener noreferrer">Authy</TextLink>,&nbsp;
              <TextLink href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target="_blank" rel="nofollow noopener noreferrer">Google Authenticator</TextLink>, or&nbsp;
              <TextLink href="https://www.microsoft.com/en-us/store/p/microsoft-authenticator/9nblgggzmcj6" target="_blank" rel="nofollow noopener noreferrer">Microsoft Authenticator</TextLink>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box padding={{ top: 'small', bottom: 'small' }}>
        <Divider />
      </Box>
      <Grid container>
        <Grid container item sm={4} md={leftGridSpacing} justifyContent="center" alignContent="center" alignItems="center">
          {
            account.extMfaId && (
              <Icon size="large" color="secondary"><CircleCheck /></Icon>
            )
          }
        </Grid>
        <Grid item sm={8} md={rightGridSpacing}>
          <Box>
            <Typography gutterBottom variant="caption" color="textSecondary">Step 2</Typography>
            <Box padding={{ top: 'xsmall', bottom: 'xsmall' }} direction="row" justify="space-between" align="center">
              <Typography gutterBottom variant="body1" color="initial"><strong>{account.extMfaId ? '(Optional) Edit' : 'Add'} Your Phone Number</strong></Typography>
              <IconButton size="small" onClick={() => setShowPhone(!showPhone)}>
                <ExpandMoreIcon />
              </IconButton>
            </Box>
            <Collapse in={showPhone}>
              <Typography gutterBottom variant="body2" color="textPrimary">
                Once you add your phone number, a code will generate below.
              </Typography>
              <Typography gutterBottom variant="body2" color="textPrimary">
                If you are using Authy, you will get a notification on your phone to complete setup.
              </Typography>
              <Box direction="row" gap="medium" align="flex-start" padding={{ top: 'xsmall', bottom: 'small' }}>
                <Box>
                  <TextField
                    id="code"
                    autoComplete="off"
                    placeholder="Phone Number"
                    helperText="Enter your Phone Number with Country Code"
                    onChange={(e) => setPhone(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: true,
                    }}
                    margin="dense"
                    variant="outlined"
                  />
                </Box>
                <Box style={{ position: 'relative', top: 9 }}>
                  <Button
                    variant="outlined"
                    onClick={(handleRequestWithPhone)}
                  >
                    Use Phone Number
                  </Button>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </Grid>
      </Grid>
      <Box padding={{ top: 'small', bottom: 'small' }}>
        <Divider />
      </Box>
      {/* Step 2 */}
      <Grid container>
        <Grid container item sm={4} md={leftGridSpacing} justifyContent="center" alignContent="center" alignItems="center">
          {
            code?.qrCode ? (
              <Box>
                <img src={code.qrCode} alt="QR Code" />
              </Box>
            ) : (
              <Typography variant="caption" color="textSecondary">QR Code will appear here after you set the phone number above.</Typography>
            )
          }
        </Grid>
        <Grid item sm={8} md={rightGridSpacing}>
          <Box>
            <Typography gutterBottom variant="caption" color="textSecondary">Step 3</Typography>
            <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
              <Typography gutterBottom variant="body1" color="initial"><strong>Scan This QR Code</strong></Typography>
            </Box>
            <Typography gutterBottom variant="body2" color="textPrimary">Open the authenticator app</Typography>
            <Typography gutterBottom variant="body2" color="textPrimary">Tap the &quot;+&quot; icon or &quot;Add Account&quot;</Typography>
            <Typography gutterBottom variant="body2" color="textPrimary">Scan the image on the left using your device&apos;s camera</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box padding={{ top: 'small', bottom: 'small' }}>
        <Divider />
      </Box>
      {/* Step 3 */}
      <Grid container>
        <Grid item sm={4} md={leftGridSpacing} />
        <Grid item sm={8} md={rightGridSpacing}>
          <Box>
            <Typography gutterBottom variant="caption" color="textSecondary">Step 4</Typography>
            <Box padding={{ top: 'xsmall', bottom: 'xsmall' }}>
              <Typography gutterBottom variant="body1" color="initial"><strong>Enter Verification Code</strong></Typography>
            </Box>
            <Typography gutterBottom variant="body2" color="textPrimary">Enter the 6-digit code generated by the app</Typography>
          </Box>
          <Box>
            <form>
              <Box direction="row" gap="medium" align="center" padding={{ top: 'small' }}>
                <Box>
                  <NarrowTextField
                    id="code"
                    autoComplete="off"
                    placeholder="Code"
                    value={verificationCode}
                    onChange={(e) => { setVerificationCode(e.target.value); }}
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: true,
                    }}
                    margin="dense"
                    variant="outlined"
                  />
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    disabled={!verificationCode}
                    onClick={(handleSubmitVerification)}
                  >
                    Verify and Enable
                  </Button>
                </Box>
              </Box>
              {
                errors && (
                  <ErrorText>{errors}</ErrorText>
                )
              }
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

MFAFlow.propTypes = {
  account: object,
  fetchAccount: func.isRequired,
  twoFactorSetupRequest: func.isRequired,
  twoFactorVerificationRequest: func.isRequired,
  twoFactorVerificationReset: func.isRequired,
};

export default connect(
  (state) => ({ account: state.account }),
  {
    fetchAccount,
    twoFactorSetupRequest,
    twoFactorVerificationRequest,
    twoFactorVerificationReset,
  },
)(MFAFlow);
