import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import { baseStatePropTypes } from 'shared/utils/redux/apiReducer';
import {
  resetMfaResetAccount,
  resetMfaResetAdmin,
  resetMfaResetState,
} from 'shared/modules/authentication/redux/actions';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

export class ResetMFASet extends React.Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
      }),
    }),
    resetMfa: PropTypes.shape(baseStatePropTypes),
    resetMfaResetAccount: PropTypes.func,
    resetMfaResetAdmin: PropTypes.func,
    resetMfaResetState: PropTypes.func,
  };

  state = {
    password: '',
  };

  componentWillUnmount() {
    this.props.resetMfaResetState();
  }

  handleRequest = () => {
    return this.props.accountType === 'account'
      ? this.props.resetMfaResetAccount(this.props.match.params.token, this.state.password)
      : this.props.resetMfaResetAdmin(this.props.match.params.token, this.state.password);
  };

  render() {
    let error = [];
    if (this.props.resetMfa.done) {
      const link = this.props.accountType === 'account'
        ? `/account/${this.props.resetMfa.data.id}/settings`
        : '/settings';

      return (
        <Typography variant="body2">Multi-Factor authentication has been disabled. Please <TextLink to={link}>login</TextLink> to re-enable.</Typography>
      );
    }

    if (this.props.resetMfa.apiErrorStatusCode) {
      switch (this.props.resetMfa.apiErrorStatusCode) {
        case 422:
          if (this.props.resetMfa.validation.body.password) {
            error.push(
              <ErrorText key="password">
                {
                  this.props.resetMfa.validation.body.password.messages.map((msg) => <div key={`password-error-${msg}`}>{msg}</div>)
                }
              </ErrorText>,
            );
          }
          if (this.props.resetMfa.validation.body.token) {
            error.push(
              <ErrorText key="token">
                {
                  this.props.resetMfa.validation.body.token.messages.map((msg) => <div key={`token-error-${msg}`}>{msg}</div>)
                }
              </ErrorText>,
            );
          }
          break;
        case 404:
          error = (
            <ErrorText key="unknown">The link is no longer valid. Please restart the process to disable multi-factor.</ErrorText>
          );
          break;
        default:
          error = (
            <ErrorText key="unknown">An error happened when processing the request</ErrorText>
          );
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          margin="normal"
          id="newpassword"
          placeholder="Enter Password for Verification"
          errorText={error.length > 0 && (<Fragment>{error}</Fragment>)}
          type="password"
          onChange={(e) => this.setState({ password: e.target.value })}
        />
        <ErrorText>
          {error}
        </ErrorText>
        <Box margin={{ top: 'small' }}>
          <Button
            variant="contained"
            onClick={this.handleRequest}
            disabled={this.props.resetMfa.doing || !this.state.password}
          >
            Disable Multi-Factor Authentication
          </Button>
        </Box>
      </div>
    );
  }
}

const mapDispatchToProps = {
  resetMfaResetState,
  resetMfaResetAccount,
  resetMfaResetAdmin,
};

const mapStateToProps = (state) => ({
  resetMfa: state.requests.resetMfa,
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetMFASet);
