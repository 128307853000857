/* eslint no-underscore-dangle: "off" */

import React, { Component, Fragment, createRef } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import moment from 'moment';

import { Divider } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Empty from 'shared/styleguide/atoms/Empty';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import Loading from 'shared/styleguide/atoms/Loading';
import Layout from 'shared/styleguide/molecules/Layout';
import Display2Text from 'shared/styleguide/typography/Display2Text';
import Box from 'shared/styleguide/atoms/Box';
import Paper from 'shared/styleguide/atoms/Card/Paper';
import colors from 'shared/styleguide/theme';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import { getHttp } from 'shared/http';
import ArticleList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';

import {
  requestCdnForApp,
  addCNAME,
  removeCNAME,
  purgeCDN,
  cdnCheckFetch,
  cdnCheckReset,
  getCdnFsaStatus,
} from 'shared/modules/cdn/redux/actions';
import SupportLink from 'shared/modules/support/Link';

import InfoText from 'shared/styleguide/molecules/InfoText';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import { capitalize } from 'shared/utils';
import { articles, providerNameMap } from '../constants';
import { heading } from './CDN.scss';
import { getOriginUrlString } from '../components/CDNListTable';

export class CDNManager extends Component {
  static propTypes = {
    cdnCheck: object,
    cdnCheckFetch: func,
    cdnCheckReset: func,
    cdnFsaStatus: object,
    fetchCDN: func.isRequired,
    getCdnFsaStatus: func.isRequired,
    location: object.isRequired,
    match: object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddField: false,
      appIsActive: undefined, // we're being optimistic here
      siteCheckLoading: false,
      errors: { cnames: '' },
      ...this.props.__storybookMocks,
    };
    this.cnameInput = createRef();
  }

  componentDidMount() {
    const {
      match,
      fetchCDN,
    } = this.props;
    fetchCDN(match.params.cdnId, { includeExtConfig: true });
  }

  componentDidUpdate(prevProps) {
    const {
      cdn,
      cdnCheckFetch,
      getCdnFsaStatus,
    } = this.props;
    if (
      cdn.status === 'success'
      && (prevProps.cdn.data.id !== cdn.data.id)
    ) {
      cdnCheckFetch(cdn.data.id);

      if (
        !this.state.siteCheckLoading
        && this.state.appIsActive === undefined
      ) {
        this.getClassicCdnAppStatus(cdn.data.extUrl);
      }

      if (cdn.data.isFsa) {
        getCdnFsaStatus(cdn.data.appId);
      }
    }
  }

  componentWillUnmount() {
    this.props.cdnCheckReset();
  }

  getClassicCdnAppStatus = (extUrl) => {
    this.setState({ siteCheckLoading: true });
    getHttp().get(`/ssl/letsencrypt/sitecheck?url=http://${extUrl}/wp-admin/images/wordpress-logo.svg`)
      .then(() => {
        this.setState({
          appIsActive: true,
        });
      })
      .catch(() => {
        this.setState({
          appIsActive: false,
        });
      });
    this.setState({ siteCheckLoading: false });
  };

  handleRequestPurge = () => {
    const { cdn } = this.props;

    this.props.purgeCdn({
      appId: cdn.data.appId,
    });
  };

  handleRemoveCname = (cname) => {
    const { cdn } = this.props;
    this.props.removeCname({
      appId: cdn.data.appId,
      appUrl: cname,
    });
  };

  handleAddCname = (e) => {
    e.preventDefault();
    const { cdn } = this.props;
    const { value } = this.cnameInput.current;

    this.setState({
      errors: { cname: '' },
    }, () => {
      // submit
      this.props.addCname({
        appId: cdn.data.appId,
        appUrl: value,
      }).then(() => {
        // clear value
        this.cnameInput.current.value = '';

        // reset state
        this.setState({
          showAddField: false,
          errors: { cname: '' },
        });
      }).catch((err) => {
        // accumulate the errors
        const errors = err.response.data.body.addCnames;
        this.setState({ // yikes.
          errors: { cnames: Object.values(errors)[1].messages.join('; ') },
        });
      });
    });
  };

  renderClassicCdnActive = () => {
    const { cdnCheck } = this.props;
    if (cdnCheck.status === 'loading') {
      return (<Typography color="textSecondary">Loading...</Typography>);
    } else if (cdnCheck.data.active) {
      return (<Typography color="secondary">Yes</Typography>);
    }
    return (<Typography color="error">No</Typography>);
  };

  renderDateAdded = () => {
    const { cdn } = this.props;
    return moment(cdn.data.dateAdded, 'X').format('MMM DD YYYY HH:mm');
  };

  renderCdnConfiguration = () => {
    const { cdn } = this.props;

    let cdnConfigValue = 'Classic CDN';

    if (cdn.data.isFsa === undefined) {
      cdnConfigValue = 'Loading...';
    } else if (cdn.data.isFsa === true) {
      cdnConfigValue = 'Mercury Dynamic Site Acceleration';
    }

    return (<Typography color="textSecondary">{cdnConfigValue}</Typography>);
  };

  renderClassicCdnAccessible = () => {
    const { appIsActive } = this.state;
    if (appIsActive === undefined) {
      return (<Typography color="textSecondary">Loading...</Typography>);
    } else if (appIsActive === true) {
      return (<Typography color="secondary">Yes</Typography>);
    }
    return (<Typography color="error">No</Typography>);
  };

  renderFsaAccessible = () => {
    const { cdnFsaStatus } = this.props;
    if (cdnFsaStatus.data.responseIsCloudFront === undefined) {
      return (<Typography color="textSecondary">Loading...</Typography>);
    } else if (cdnFsaStatus.data.responseIsCloudFront) {
      return (<Typography color="secondary">Yes</Typography>);
    } else {
      return (<Typography color="error">No</Typography>);
    }
  };

  renderFsaActive = () => {
    const { cdnFsaStatus } = this.props;
    if (cdnFsaStatus.data.active === undefined) {
      return (<Typography color="textSecondary">Loading...</Typography>);
    } else if (cdnFsaStatus.data.active) {
      return (<Typography color="secondary">Yes</Typography>);
    } else {
      return (<Typography color="error">No</Typography>);
    }
  };

  renderCnamesAdd = () => {
    if (this.props.user['@type'] === 'Pagely.Model.Accounts.Admin') {
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => { this.setState((state) => ({ showAddField: !state.showAddField })); }}
        >
          New Custom CNAME
        </Button>
      );
    }

    return null;
  };

  renderCnames = () => {
    const { cdn } = this.props;
    if (!Object.values(cdn.data).length) {
      return null;
    }
    return cdn.data?.mappedCnames?.length === 0
      ? (
        <Box css={css`width: 100%;`} margin={{ bottom: 'medium ' }}>
          <Empty>You have no custom CNAMES</Empty>
        </Box>
      ) : (
        cdn.data?.mappedCnames
          .filter((z) => z.cname && !z.cname.match(/\.pcdn\.co$/))
          .map((z) => (
            <GhostTag key={z.cname} onRemove={() => this.handleRemoveCname(z.cname)}>
              {z.cname}
            </GhostTag>
          ))
      );
  };

  renderPurge = () => {
    const { cdn } = this.props;
    return (
      <Box direction="row" align="center" gap="xsmall">
        <Typography color="textSecondary">Last purged:&nbsp;
          {
            cdn.data.datePurged
              ? `${moment(cdn.data.datePurged, 'X').format('MMM DD YYYY HH:mm')} (local)`
              : 'Never'
          }
        </Typography>
      </Box>
    );
  };

  renderPurgeControls = () => {
    const { cdn } = this.props;
    return (
      <Box margin={{ right: 'xsmall' }}>
        <Button
          variant="outlined"
          loading={cdn.doing}
          onClick={this.handleRequestPurge}
        >
          Purge Cache
        </Button>
      </Box>
    );
  };

  renderCnameControls = () => {
    return (
      <Box
        margin={{ top: 'xsmall', bottom: 'xsmall' }}
        padding={{ left: 'small' }}
        css={css`
          position: relative;
          left: -8px;
          border-left: 3px solid ${colors.secondary};
        `}
      >
        <Box>
          <TextField
            margin="dense"
            id="test"
            placeholder="CNAME"
            inputRef={this.cnameInput}
            errorText={this.state.errors.cnames}
            fullWidth
          />
        </Box>
        <Box direction="row" justify="space-between" margin={{ top: 'small' }}>
          <Button
            color="default"
            variant="outlined"
            onClick={() => { this.setState({ showAddField: false }); }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={this.handleAddCname}
          >
            Add CNAME
          </Button>
        </Box>
      </Box>
    );
  };

  render() {
    const { cdn } = this.props;
    const cdnZoneData = [
      ['Provider', providerNameMap[cdn.data.extProvider]],
      ['Origin URL', cdn.data.originUrl],
      ['CDN Address', cdn.data.cdnUrl],
      ['Target CNAME', cdn.data.extUrl],
      ['Date Added', this.renderDateAdded()],
      ['Configuration', this.renderCdnConfiguration()],
      ['Accessible', cdn.data.isFsa ? this.renderFsaAccessible() : this.renderClassicCdnAccessible()],
      ['Active', cdn.data.isFsa ? this.renderFsaActive() : this.renderClassicCdnActive()],
      ['Domain Names (CNAMES)', this.renderCnames()],
      ['Cache', this.renderPurge()],
    ];

    if (cdn.status === 'success' && !cdn.data.extUrl) {
      cdnZoneData.splice(3, 1);
    }

    return (
      <Layout title={(
        <div className={heading}>
          <Display2Text>PressCDN Zones</Display2Text>
        </div>
      )}
      >
        <Box margin={{ bottom: 'xsmall' }}>
          <Typography variant="h3">Manage Zone</Typography>
        </Box>
        <Box as={Paper} padding="medium">
          {
            cdn.loading || !cdn.loaded
              ? <Loading />
              : (
                <Fragment>
                  <Box flex={1}>
                    <TwoColumn
                      data={cdnZoneData}
                      styles={{
                        rightCol: {
                          minWidth: 220,
                        },
                        leftCol: {
                          wordBreak: 'break-word',
                        },
                        table: {
                          marginTop: 0,
                        },
                      }}
                    />
                  </Box>
                  {
                    (cdn.failed && cdn.apiErrorStatusCode === 429) && (
                      <Box margin={{ top: 'small' }} align="center">
                        <Typography
                          color="error"
                        >
                          You may only purge once every three minutes
                        </Typography>
                      </Box>
                    )
                  }
                  <Box direction="row" align="center" justify="flex-end" margin={{ top: 'small' }}>
                    {this.renderPurgeControls()}
                    {this.state.showAddField ? this.renderCnameControls() : null}
                    {this.props.user['@type'] === 'Pagely.Model.Accounts.Admin' && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => { this.setState((state) => ({ showAddField: !state.showAddField })); }}
                      >
                        New Custom CNAME
                      </Button>
                    )}
                  </Box>
                  <Box margin={{ top: 'small' }}>
                    <InfoText>
                      We currently do not allow custom CDN CNAMEs to be added to CDN via Atomic. If you wish to use a custom CDN domain, please <SupportLink>submit a ticket</SupportLink>.
                    </InfoText>
                  </Box>
                </Fragment>
              )
          }
        </Box>
        <Box padding={{ top: 'large', bottom: 'medium' }}>
          <Divider />
        </Box>
        <Typography variant="h3" gutterBottom>Common CDN Questions</Typography>
        <ArticleList
          topic="cdnmgmt"
          columns={3}
          boxProps={{
            row: true,
            wrap: 'wrap',
          }}
          articles={articles}
        />
      </Layout>
    );
  }
}

CDNManager.propTypes = {
  addCname: func,
  cdn: object,
  purgeCdn: func,
  removeCname: func,
  user: object,
};

export default connect(
  (state) => {
    const { cdn, account, user: { user } } = state;
    return {
      user,
      account,
      cdn: cdn.cdnApp,
      cdnCheck: cdn.cdnCheck,
      cdnFsaStatus: cdn.fsaStatus,
    };
  },
  {
    fetchCDN: requestCdnForApp,
    addCname: addCNAME,
    removeCname: removeCNAME,
    purgeCdn: purgeCDN,
    cdnCheckFetch,
    cdnCheckReset,
    getCdnFsaStatus,
  },
)(CDNManager);
