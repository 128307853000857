import { useState } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { getAllValidationMessages } from 'shared/utils/validation';

const PAGE_LENGTH = 10;

export const useJobList = (jobs, integrations) => {
  const location = useLocation();

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [page, setPage] = useState(1);
  const [index, setIndex] = useState(0);

  const getJobsData = () => {
    if (jobs.data?.length === 0) {
      return [];
    }

    return jobs.data.slice(index, index + PAGE_LENGTH)
      .map((job) => {
        let specialProps = {};
        // check if a specific one should be open
        if ((params.logSection === 'deployment') && (params.logId === job.id)) {
          specialProps = {
            params,
            defaultExpanded: true,
          };
        }

        let integrationName;
        if (integrations) {
          // only CI/CD logs have an integration name
          const integration = integrations?.data?.find((integration) => integration.id === job.detail?.config?.id) || null;
          // in case an integration is deleted, we can use the existing name from job
          integrationName = job.detail?.config?.id && !integration ? job.detail?.config?.name : integration?.name;
        }

        return {
          job,
          integrationName,
          specialProps,
        };
      });
  };

  const handleSetPage = (value) => {
    setPage(value);
  };

  const handleSetIndex = (value) => {
    setIndex(value);
  };

  const getErrors = () => {
    if (jobs.statusCode === 422) {
      return getAllValidationMessages(jobs);
    }

    return jobs.errMessage;
  };

  const jobsItems = getJobsData();

  return {
    errors: jobs.status === 'failed' ? `Error: ${getErrors()}` : '',
    loadingJobs: jobs.status === 'loading',
    jobsItems,
    setPage: handleSetPage,
    setIndex: handleSetIndex,
    page,
    pageLength: PAGE_LENGTH,
    count: jobsItems.length > PAGE_LENGTH ? Math.ceil(jobsItems.length / PAGE_LENGTH) : jobsItems.length,
  };
};

export default useJobList;
