/* eslint max-len: "off" */
import React from 'react';
import {
  Link, RouteComponentProps, withRouter,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';

import type { History } from 'history';
import PagelyLogo from 'shared/styleguide/assets/Logo';
import ToggleTheme from 'shared/modules/webapp/ToggleTheme';
import Box from 'shared/styleguide/atoms/Box';
import AtomicIcon from 'shared/styleguide/Icons/Atomic';
import Avatar from 'shared/styleguide/molecules/Avatar';
import { FeatureToggle } from 'shared/flags';
import { remMapper } from 'shared/styleguide/theme/spacing';
import AccountSearchInput from '../../../search/accounts/AccountSearchInput';
import AppSearchInput from '../../../search/apps/AppSearchInput';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    height: 60,
    padding: '0 40px 0 40px',
    display: 'flex',
    justifyContent: 'center',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',

  },
  search: {
    'position': 'relative',
    'marginRight': theme.spacing(2),
    'marginLeft': 0,
    'display': 'flex',
    'flexFlow': 'row',
  },
  logo: {
    width: (256 + 8),
    height: '100%',
    // @ts-ignore
    borderRight: `1px solid ${theme.palette.background.border}`,
  },
}));

interface TopNavProps {
  handleLogout: () => void;
  history: History<unknown>;
  menuItems: { link: string; name: string }[];
  userName: string;
  email: string;
  avatarURL?: string;
}

export const TopNav: React.FC<RouteComponentProps & TopNavProps> = (props) => {
  const {
    menuItems, userName, handleLogout, history, email,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar} elevation={0} color="inherit">
        <Toolbar disableGutters variant="dense" css={{ height: '100%' }}>
          <Box className={classes.logo} justify="center">
            <Link to="/">
              <FeatureToggle
                stages="woosaas"
                fallback={(
                  <PagelyLogo
                    alt="Pagely logo"
                    css={(theme) => ({
                      height: 'unset',
                      width: 'unset',
                      color: theme.color900,
                    })}
                  />
                )}
              >
                <Typography variant="h1">MWCS Admin Panel</Typography>
              </FeatureToggle>

            </Link>
          </Box>
          <div className={classes.search}>
            <Box gap="small" direction="row" padding={{ left: 'small' }} align="center">
              <AccountSearchInput history={history} />
              {/* @ts-ignore */}
              <AppSearchInput history={history} />
            </Box>
          </div>
          <div className={classes.grow} />
          <Box direction="row" align="center">
            <ToggleTheme />

            <Link to={menuItems[0].link} style={{ textDecoration: 'none' }}>
              <Tooltip title={menuItems[0].name} placement="top">
                <Avatar
                  email={email}
                  css={{
                    maxHeight: 36,
                    maxWidth: 36,
                  }}
                  fullName={userName}
                >
                  <SettingsIcon />
                </Avatar>
              </Tooltip>
            </Link>
            <span
              style={{
                display: 'inline-block',
                width: 10,
              }}
            />
            <span
              style={{
                display: 'inline-block',
                width: 10,
              }}
            />
            <Tooltip title="Logout">
              <IconButton
                style={{ marginLeft: -12 }}
                onClick={handleLogout}
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

TopNav.displayName = 'TopNav';

export default withRouter(TopNav);
