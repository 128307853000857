export const GIT_INTEGRATION_TAG = 'system:git-workflows';

export const ACCOUNT_DISABLED_TAG = 'system:disabled';

export const LEGACY_DEPLOYER_TAG = 'system:legacy-deployer';

export const DOCKER_SERVER_TAG = 'system:webconfig-use-docker-compose';

export const ARES_ENABLED_TAG = 'system:gateway2-installed';

export const VIC = 'account:vic';

export const ESLA = 'account:esla';

export const FSA_OPTIN = 'account:feature-fsa';
