import React, { useState, useCallback } from 'react';
import {
  object, string, array, func,
} from 'prop-types';

import {
  useParams,
} from 'react-router-dom';

import { CloudUpload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// import SearchBar from 'shared/styleguide/molecules/SearchBar';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import AdapterLink from 'shared/styleguide/atoms/Buttons/AdapterLink';

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    'minWidth': 'unset !important',
    'padding': `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    'color': theme.palette.text.secondary,
    'transition': theme.transitions.create(['background-color', 'color'], {
      easing: theme.transitions.easing.easeOut,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.action.hover,
      transition: theme.transitions.create(['background-color', 'color'], {
        easing: theme.transitions.easing.easeOut,
      }),
    },
  },
  wrapper: {
    alignItems: 'flex-end',
  },
}));

export const AresNavigation = ({
  routes, fallback = '', reloadPool, appId, pendingDeploys,
}) => {
  const [status, setStatus] = useState('pristine');
  const classes = useStyles();
  const { subview } = useParams();

  const value = subview ?? fallback;

  const handleReload = async () => {
    setStatus('loading');

    try {
      await reloadPool(appId);

      setStatus('success');
    } catch (err) {
      setStatus('pristine');
    }

    setTimeout(() => setStatus('pristine'), 3000);
  };

  const appHasPendingDeploys = () => {
    return pendingDeploys.filter((item) => item.appId === Number(appId)).length > 0;
  };

  const memoizedAppHasPendingDeploys = useCallback(appHasPendingDeploys, [pendingDeploys, appId]);

  const buttonIsActivated = memoizedAppHasPendingDeploys() || status !== 'pristine';

  return (
    <Box className={classes.container} margin={{ bottom: 'small' }}>
      {/* <SearchBar css={{ right: -17 }} /> */}
      <Box padding={{ top: 'small', bottom: 'large' }}>
        <Tabs
          orientation="vertical"
          value={value || fallback}
          aria-label="ares nav tabs"
        >
          {
            routes?.map((child) => {
              return (
                <Tab
                  key={child.to}
                  classes={{ root: classes.tabRoot, wrapper: classes.wrapper }}
                  component={AdapterLink}
                  color="textSecondary"
                  to={`${child.to}`}
                  value={child.to ?? fallback}
                  label={child.label}
                />
              );
            })
          }
        </Tabs>
      </Box>
      <Box direction="row" justify="flex-end">
        <Button
          status={status}
          onClick={handleReload}
          startIcon={<CloudUpload />}
          color={buttonIsActivated ? 'secondary' : 'default'}
          variant={buttonIsActivated ? 'contained' : 'outlined'}
        >
          Deploy Changes
        </Button>
      </Box>
    </Box>
  );
};

AresNavigation.propTypes = {
  appId: string,
  extraTabs: array,
  fallback: string,
  match: object,
  parentPath: string,
  pendingDeploys: array,
  reloadPool: func,
  routes: array.isRequired,
};

export default AresNavigation;
