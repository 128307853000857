import React from 'react';
import { object, string } from 'prop-types';
import store2 from 'store2';
import { Widget as TypeformWidget } from '@typeform/embed-react';

import { Paper } from '@material-ui/core';

import { completedSurveyKey } from 'shared/3rdparty/typeform';
import Box from 'shared/styleguide/atoms/Box';

import DashboardCardHeading from './DashboardCardHeading';

const SurveySection = ({
  user, account, heading = 'Survey', currentSurveyId, ...rest
}) => {
  const hiddenFields = {
    email: account.email,
    accountId: account.id,
    name: user.name,
    company: account.companyName,
  };

  const saveToStore = () => {
    store2.set(completedSurveyKey, currentSurveyId);
  };

  return (
    <Box margin={{ bottom: 'medium' }} {...rest}>
      <DashboardCardHeading>{heading}</DashboardCardHeading>
      <Box>
        <Paper>
          <TypeformWidget
            id={currentSurveyId}
            hidden={hiddenFields}
            hideHeaders
            style={{
              width: '100%',
              height: 700, // will need to be updated depending on the form, we don't want scrollbars
              position: 'relative',
              borderRadius: 4,
              marginTop: 4,
            }}
            onSubmit={saveToStore}
          />
        </Paper>
      </Box>
    </Box>
  );
};

SurveySection.propTypes = {
  account: object,
  currentSurveyId: string,
  heading: string,
  user: object,
};

export default SurveySection;
