import React from 'react';
import { css } from '@emotion/react';
import { useMediaQuery } from '@material-ui/core';
import WaveStatic from './wave-dots-static.png';
import WaveStatic1080p from './wave-dots-static-1080p.png';
import { WaveOptions } from './types';
import { useWave } from './useWave';

const Wave: React.FC<WaveOptions> = (props) => {
  const { container, preferReducedMotion } = useWave(props);
  const isSmallerScreen = useMediaQuery('(max-width: 1080px)');

  const styles = css`
  position: absolute;
  left: 0;
  right: 0;
  top: ${preferReducedMotion ? '0' : '20%'};
  bottom: 0;
  opacity: .18;
  `;

  const imageStyles = css`
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  `;

  return (
    <div ref={container} css={styles}>
      {preferReducedMotion && <img css={imageStyles} src={isSmallerScreen ? WaveStatic1080p : WaveStatic} alt="" />}
    </div>
  );
};

export default Wave;
