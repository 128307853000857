import React, { useEffect } from 'react';
import { object, func, array } from 'prop-types';
import { connect } from 'react-redux';

import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import { fetchRateLimitsForApp } from '../../redux/rateLimits/actions';
import CustomConfigDescription from '../../CustomConfigDescription';
import RateLimits from './RateLimits';

export const RateLimitLoader = ({
  app, rateLimits, getRateLimits, customConfig,
}) => {
  useEffect(() => {
    if (rateLimits.status === 'pristine' || rateLimits.params?.appId !== app.data.id) {
      getRateLimits(app.data.id);
    }
  }, [rateLimits.status, rateLimits.params?.appId, app.data.id, getRateLimits]);

  if (['loading', 'pristine'].includes(rateLimits.status)) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (rateLimits.status === 'failed' && rateLimits.statusCode !== 422) {
    return (
      <div>
        <Empty>
          {rateLimits.errMessage || 'An error occurred.'}
        </Empty>
      </div>
    );
  }

  return (
    <Box direction="column">
      <CustomConfigDescription customConfig={customConfig} />

      <RateLimits appId={app.data?.id} rateLimits={rateLimits.data} />
    </Box>
  );
};

RateLimitLoader.propTypes = {
  app: object.isRequired,
  customConfig: array,
  getRateLimits: func.isRequired,
  rateLimits: object.isRequired,
};

export default connect(
  (state) => {
    return ({
      app: state.app.app,
      rateLimits: state.ares.rateLimit,
    });
  },
  {
    getRateLimits: fetchRateLimitsForApp,
  },
)(RateLimitLoader);
