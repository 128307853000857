import React from 'react';
import {
  Check, Close, MoreHoriz, NewReleases,
} from '@material-ui/icons';

import { CircularProgress } from '@material-ui/core';

export const GET_JOB_STATUS_REQUEST = 'STATUS_GET_JOB_STATUS_REQUEST';
export const GET_JOB_STATUS_SUCCESS = 'STATUS_GET_JOB_STATUS_SUCCESS';
export const GET_JOB_STATUS_FAILURE = 'STATUS_GET_JOB_STATUS_FAILURE';
export const STATUS_RESET = 'STATUS_RESET';

export const GET_APP_CREATE_JOBS_REQUEST = 'GET_APP_CREATE_JOBS_REQUEST';
export const GET_APP_CREATE_JOBS_SUCCESS = 'GET_APP_CREATE_JOBS_SUCCESS';
export const GET_APP_CREATE_JOBS_FAILURE = 'GET_APP_CREATE_JOBS_FAILURE';
export const GET_APP_CREATE_JOBS_RESET = 'GET_APP_CREATE_JOBS_RESET';

export const GET_GIT_WEBHOOK_DEPLOY_JOBS_REQUEST = 'GET_GIT_WEBHOOK_DEPLOY_JOBS_REQUEST';
export const GET_GIT_WEBHOOK_DEPLOY_JOBS_SUCCESS = 'GET_GIT_WEBHOOK_DEPLOY_JOBS_SUCCESS';
export const GET_GIT_WEBHOOK_DEPLOY_JOBS_FAILURE = 'GET_GIT_WEBHOOK_DEPLOY_JOBS_FAILURE';
export const GET_GIT_WEBHOOK_DEPLOY_JOBS_RESET = 'GET_GIT_WEBHOOK_DEPLOY_JOBS_RESET';

export const GET_GIT_INTEGRATION_DEPLOY_JOBS_REQUEST = 'GET_GIT_INTEGRATION_DEPLOY_JOBS_REQUEST';
export const GET_GIT_INTEGRATION_DEPLOY_JOBS_SUCCESS = 'GET_GIT_INTEGRATION_DEPLOY_JOBS_SUCCESS';
export const GET_GIT_INTEGRATION_DEPLOY_JOBS_FAILURE = 'GET_GIT_INTEGRATION_DEPLOY_JOBS_FAILURE';
export const GET_GIT_INTEGRATION_DEPLOY_JOBS_RESET = 'GET_GIT_INTEGRATION_DEPLOY_JOBS_RESET';

export const GET_DISK_REPORT_JOBS_REQUEST = 'GET_DISK_REPORT_JOBS_REQUEST';
export const GET_DISK_REPORT_JOBS_SUCCESS = 'GET_DISK_REPORT_JOBS_SUCCESS';
export const GET_DISK_REPORT_JOBS_FAILURE = 'GET_DISK_REPORT_JOBS_FAILURE';
export const GET_DISK_REPORT_JOBS_RESET = 'GET_DISK_REPORT_JOBS_RESET';

export const GET_DISK_UPGRADE_VPS_JOBS_REQUEST = 'GET_DISK_UPGRADE_VPS_JOBS_REQUEST';
export const GET_DISK_UPGRADE_VPS_JOBS_SUCCESS = 'GET_DISK_UPGRADE_VPS_JOBS_SUCCESS';
export const GET_DISK_UPGRADE_VPS_JOBS_FAILURE = 'GET_DISK_UPGRADE_VPS_JOBS_FAILURE';

export const GET_DISK_UPGRADE_HA_JOBS_REQUEST = 'GET_DISK_UPGRADE_HA_JOBS_REQUEST';
export const GET_DISK_UPGRADE_HA_JOBS_SUCCESS = 'GET_DISK_UPGRADE_HA_JOBS_SUCCESS';
export const GET_DISK_UPGRADE_HA_JOBS_FAILURE = 'GET_DISK_UPGRADE_HA_JOBS_FAILURE';

export const GET_RECENT_DISK_UPGRADES_REQUEST_ACTION_ONLY = 'GET_RECENT_DISK_UPGRADES_REQUEST_ACTION_ONLY';
export const GET_RECENT_DISK_UPGRADES_SUCCESS_ACTION_ONLY = 'GET_RECENT_DISK_UPGRADES_SUCCESS_ACTION_ONLY';
export const GET_RECENT_DISK_UPGRADES_FAILURE_ACTION_ONLY = 'GET_RECENT_DISK_UPGRADES_FAILURE_ACTION_ONLY';

export const GET_DISK_UPGRADE_JOBS_RESET = 'GET_DISK_UPGRADE_JOBS_RESET';

export const GET_DNS_VALIDATION_JOBS_REQUEST = 'GET_DNS_VALIDATION_JOBS_REQUEST';
export const GET_DNS_VALIDATION_JOBS_FAILURE = 'GET_DNS_VALIDATION_JOBS_FAILURE';
export const GET_DNS_VALIDATION_JOBS_SUCCESS = 'GET_DNS_VALIDATION_JOBS_SUCCESS';
export const GET_DNS_VALIDATION_JOBS_RESET = 'GET_DNS_VALIDATION_JOBS_RESET';

export const GET_WEBCONFIG_JOBS_REQUEST = 'GET_WEBCONFIG_JOBS_REQUEST';
export const GET_WEBCONFIG_JOBS_FAILURE = 'GET_WEBCONFIG_JOBS_FAILURE';
export const GET_WEBCONFIG_JOBS_SUCCESS = 'GET_WEBCONFIG_JOBS_SUCCESS';
export const GET_WEBCONFIG_JOBS_RESET = 'GET_WEBCONFIG_JOBS_RESET';

export const GET_FSA_SETUP_JOBS_REQUEST = 'GET_FSA_SETUP_JOBS_REQUEST';
export const GET_FSA_SETUP_JOBS_FAILURE = 'GET_FSA_SETUP_JOBS_FAILURE';
export const GET_FSA_SETUP_JOBS_SUCCESS = 'GET_FSA_SETUP_JOBS_SUCCESS';
export const GET_FSA_SETUP_JOBS_RESET = 'GET_FSA_SETUP_JOBS_RESET';

export const GET_FSA_SETUP_CLOUDFLARE_JOBS_REQUEST = 'GET_FSA_SETUP_CLOUDFLARE_JOBS_REQUEST';
export const GET_FSA_SETUP_CLOUDFLARE_JOBS_FAILURE = 'GET_FSA_SETUP_CLOUDFLARE_JOBS_FAILURE';
export const GET_FSA_SETUP_CLOUDFLARE_JOBS_SUCCESS = 'GET_FSA_SETUP_CLOUDFLARE_JOBS_SUCCESS';
export const GET_FSA_SETUP_CLOUDFLARE_JOBS_RESET = 'GET_FSA_SETUP_CLOUDFLARE_JOBS_RESET';

export const GET_FSA_DISABLE_JOBS_REQUEST = 'GET_FSA_DISABLE_JOBS_REQUEST';
export const GET_FSA_DISABLE_JOBS_FAILURE = 'GET_FSA_DISABLE_JOBS_FAILURE';
export const GET_FSA_DISABLE_JOBS_SUCCESS = 'GET_FSA_DISABLE_JOBS_SUCCESS';
export const GET_FSA_DISABLE_JOBS_RESET = 'GET_FSA_DISABLE_JOBS_RESET';

export const JOB_NOTIFICATION_RESET = 'JOB_NOTIFICATION_RESET';

export const jobTypes = {
  appCreate: 'app-create',
  gitWebhookDeploy: 'git-webhook-deploy',
  gitIntegrationDeploy: 'integration-webhook-deploy',
  diskReport: 'disk-detailed-report',
  diskUpgradeVps: 'disk-upgrade-vps',
  diskUpgradeHA: 'disk-upgrade-ha',
  dnsValidation: 'dns-validation',
  webconfig: 'workers-webconfig',
  fsaSetup: 'fsa-setup',
  fsaSetupCloudflare: 'fsa-setup-cloudflare',
  fsaDisable: 'fsa-disable',
};

jobTypes.isFsaSetupJob = (step) => [jobTypes.fsaSetup, jobTypes.fsaSetupCloudflare].includes(step);
jobTypes.getFsaSetupJob = (app) => (app.cdnProvider === 'godaddy-cloudflare'
  ? jobTypes.fsaSetupCloudflare
  : jobTypes.fsaSetup);

export const PENDING = 'PENDING';
export const RUNNING = 'RUNNING';
export const ABORTED = 'ABORTED';
export const FAILURE = 'FAILURE';
export const SUCCESS = 'SUCCESS';
export const STALLED = 'STALLED';
export const NA = 'NA';
export const ACTION_REQUIRED = 'ACTION_REQUIRED';

// a pseudo status
export const SUCCESS_WITH_WARNINGS = 'SUCCESS WITH WARNINGS';

export const defaultStatuses = [
  PENDING,
  RUNNING,
  FAILURE,
  ACTION_REQUIRED,
  SUCCESS,
  STALLED,
];

export const notSuccessOrNAStatuses = [
  PENDING,
  RUNNING,
  FAILURE,
  ACTION_REQUIRED,
  STALLED,
];

export const gitDeployStatuses = [...defaultStatuses];

export const diskReportStatuses = [...defaultStatuses];

export const fsaSetupStatuses = [...defaultStatuses, SUCCESS_WITH_WARNINGS];

export const fsaDisableStatuses = [...defaultStatuses, SUCCESS_WITH_WARNINGS];

export const runningStatuses = [PENDING, RUNNING];

export const endedStatuses = [FAILURE, ABORTED, SUCCESS];

export const tagMap = {
  [PENDING]: 'smoke',
  [SUCCESS]: 'green',
  [SUCCESS_WITH_WARNINGS]: 'accent',
  [RUNNING]: 'orange',
  [STALLED]: 'orange',
  [ACTION_REQUIRED]: 'orange',
  [FAILURE]: 'red',
  FAILED: 'red',
  [ABORTED]: 'red',
};

export const iconMap = {
  [PENDING]: <MoreHoriz color="inherit" />,
  [SUCCESS]: <Check color="secondary" />,
  [STALLED]: (
    <CircularProgress color="secondary" variant="indeterminate" size={24} />
  ),
  [RUNNING]: (
    <CircularProgress color="secondary" variant="indeterminate" size={24} />
  ),
  [ACTION_REQUIRED]: (
    <span css={(theme) => ({ color: theme.palette.warning.light })}>
      <NewReleases color="inherit" />
    </span>
  ),
  [FAILURE]: <Close color="error" />,
  [ABORTED]: <Close color="error" />,
};
