import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import Empty from 'shared/styleguide/atoms/Empty';
import { LEGACY_DEPLOYER_TAG } from 'shared/modules/metadata/constants';
import { fetchServerTagsForAccount } from 'shared/modules/server/redux/actions';
import { fetchIntegrationsList } from 'shared/modules/integrations/redux/actions';
import SupportLink from 'shared/modules/support/Link';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import DeployLogs from './components/DeployLogs';

export const Logging = ({
  account, servertags, fetchServerTags, fetchIntegrationsList, // eslint-disable-line
}) => {
  const matchParams = useParams<{ appId: string }>();
  const { appId } = matchParams;

  React.useEffect(() => {
    if (servertags.status === 'pristine') {
      fetchServerTags(account.id);
    }
  }, [servertags, fetchServerTags, account.id]);

  React.useEffect(() => {
    fetchIntegrationsList(account.id);
  }, [account.id, fetchIntegrationsList]);

  if (account.isOnP20Account) {
    return (
      <Fragment>
        <Typography variant="h4">Deployment Logs</Typography>
        <Box margin={{ top: 'small' }}>
          <Empty data-testid="p20-message">Git Deployment Logs are not available for accounts on shared hosting plans.</Empty>
        </Box>
      </Fragment>
    );
  }

  if (['pristine', 'loading'].includes(servertags.status)) {
    return (
      <Fragment>
        <Typography variant="h4">Deployment Logs</Typography>
        <Box margin={{ top: 'small' }} data-testid="loading">
          <Loading />
        </Box>
      </Fragment>
    );
  }

  if (
    servertags.status === 'success'
    && servertags.data
    && servertags.data.map((t) => t.tagId).includes(LEGACY_DEPLOYER_TAG)
  ) {
    return (
      <Fragment>
        <Typography variant="h4">Deployment Logs</Typography>
        <Box margin={{ top: 'small' }}>
          <Empty>
            <Typography variant="body1" color="textSecondary">
              Git Deployment Logs are not available for accounts with a legacy deployer.
              Please <SupportLink>contact support</SupportLink> to migrate to the new system and use Git Deployments
            </Typography>
          </Empty>
        </Box>
      </Fragment>
    );
  }

  return (
    // @ts-ignore
    <DeployLogs accountId={account.id} appId={Number(appId)} />
  );
};

Logging.propTypes = {
  account: object,
  fetchIntegrationsList: func,
  fetchServerTags: func,
  servertags: object,
};

export default connect(
  (state: { account: any; tags: { server: any } }) => ({
    account: state.account,
    servertags: state.tags.server,
  }),
  {
    fetchServerTags: fetchServerTagsForAccount,
    fetchIntegrationsList,
  },
)(Logging);
