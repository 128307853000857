import { Typography } from '@material-ui/core';
import React from 'react';
import { UserPool } from 'shared/modules/account/redux/sso/types';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import type { StatusKeys } from '../constants';

interface Props {
  userPool: UserPool | null;
  updateStatus: (status: StatusKeys) => void;
}

const Configuration: React.FC<Props> = ({ userPool, updateStatus }) => {
  return (
    <Box gap="medium" direction="column">
      <Box>
        <Typography variant="body2" color="textSecondary">Step 1</Typography>
        <Typography variant="h5">Set Up Identity Provider</Typography>
      </Box>
      <div>
        <Typography variant="body1">
          Single Sign On will be enabled on your account once the following setup is complete.
          In order for you and your collaborators to use it to log into Atomic,
          please go to your identity provider service (Okta, Auth0, Azure, etc.)
          and add the following information.
        </Typography>
      </div>
      <Box gap="small">
        <TwoColumn
          noMargin
          includeCopy
          data={[
            ['Service Type', 'SAML'],
            ['App Name', 'Pagely - Atomic'],
            ['Single Sign On URL', `https://${userPool?.domain}/saml2/idpresponse`],
            ['Audience URI', `urn:amazon:cognito:sp:${userPool?.awsId}`],
            ['Name ID Format', 'EmailAddress'],
            ['Application Username', 'Email'],
          ]}
        />
        <div>
          <hr css={{ width: '100%' }} />
        </div>
        <TwoColumn
          noMargin
          includeCopy
          data={[
            ['Attribute Statement', 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
            ['Attribute Value', 'user.email'],
          ]}
        />
      </Box>
      <div>
        <Typography variant="body1">
          Once you have added these values to your identity provider service, you should get back an <b>Identity Provider Metadata</b> URL.
          You will need it for the next step. SAML 2.0 is not configured until you have completed the setup instructions.
        </Typography>
      </div>
      <Box justify="space-between" direction="row">
        <TextLink href="https://pagely.zendesk.com/hc/en-us/articles/4410414546331">Common Identity Providers and their setup.</TextLink>
        <Button data-testid="sso-continue-setup" onClick={() => updateStatus('finish')}>
          Continue Setup
        </Button>
      </Box>
    </Box>
  );
};

export default Configuration;
