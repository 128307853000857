import React from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import PermissionsContext from 'shared/modules/permissions/context/PermissionsContext';
import CollaboratorItem from 'customer/components/Navigation/CollabSwitcher/CollaboratorItem';
import { hashCode } from 'shared/utils';

const AccessTo = ({
  history, match, children, gridProps,
}) => {
  return (
    <PermissionsContext.Consumer>
      {
        (permissions) => (
          <div>
            {
              permissions.isFetching
                ? <Loading />
                : (
                  <Grid container spacing={2}>
                    {
                      permissions.canAccess
                        // eslint-disable-next-line eqeqeq
                        .filter((a) => a.targetId != match?.params?.accountID)
                        .map((c) => (
                          <Grid
                            item
                            xs={gridProps.xs || 12}
                            sm={gridProps.sm || 6}
                            lg={gridProps.lg || 4}
                            key={hashCode(`${c.sourceId}-${c.targetId}-${c.domain}`)}
                          >
                            {
                              children
                                ? (
                                  children({ ...c })
                                ) : (
                                  <Box direction="column" css={{ width: '100%' }}>
                                    <CollaboratorItem
                                      {...c}
                                      key={`${c.sourceId}-${c.targetId}`}
                                      user={{}}
                                      requestClosePopover={(link) => (e) => {
                                        e.preventDefault();
                                        history.push(link);
                                      }}
                                      mfaRequired={false}
                                      hoverColor="action"
                                    />
                                    {
                                      c.mfaRequired && (
                                        <Box padding={{ left: 'small', bottom: 'xsmall' }}>
                                          <Typography variant="caption" color="secondary">This account requires MFA</Typography>
                                        </Box>
                                      )
                                    }
                                  </Box>
                                )
                            }
                          </Grid>
                        ))
                    }
                  </Grid>
                )
            }
          </div>

        )
      }
    </PermissionsContext.Consumer>
  );
};

AccessTo.propTypes = {
  gridProps: object,
  history: object.isRequired,
  match: object.isRequired,
};

export default withRouter(AccessTo);
