import React, { Fragment } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';

import { updateAccount } from 'shared/modules/account/redux/actions';
import { INVALID_USERNAME_REGEX } from 'shared/utils/regexConstants';

const AddUsername = ({ account, updateUsername }) => {
  const onSubmit = (values, actions) => {
    // Validate Username
    const isValidUsername = !INVALID_USERNAME_REGEX.test(values.sshUsername);
    if (!isValidUsername) {
      actions.setFieldError('sshUsername', 'Username can only contain numbers, letters, dashes (-), and underlines (_)');
      return;
    }

    actions.setStatus('loading');

    updateUsername(account.id, values)
      .then(() => {
        actions.resetForm();
        setTimeout(() => {
          actions.setStatus('initial');
        }, 5000);
        actions.setStatus('success');
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          const fields = err.response?.data?.body;
          Object.keys(fields).forEach((key) => {
            actions.setFieldError(key, fields[key].messages.join(', '));
          });
        }

        actions.setStatus('failure');
        actions.setSubmitting(false);
      });
  };

  return (
    <Fragment>
      <Box>
        <Typography variant="body2" color="textSecondary" gutterBottom>Step 1</Typography>
        <Typography variant="body1" color="initial" gutterBottom>Create a Username</Typography>
      </Box>
      <Box>
        <Formik
          initialStatus="initial"
          initialValues={{
            sshUsername: '',
          }}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            status,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box direction="row" gap="medium" align="center">
                <WideTextField
                  id="sshUsername"
                  autoComplete="off"
                  placeholder="SSH Username"
                  value={values.sshUsername}
                  error={Boolean(errors.sshUsername)}
                  helperText={errors.sshUsername || "It will be prefixed with 'client_'"}
                  onChange={handleChange}
                  margin="normal"
                  css={{
                    '& p': {
                      marginLeft: 0,
                    },
                  }}
                />
                <Box css={{
                  marginBottom: 10,
                }}
                >
                  <Button
                    status={status}
                    type="submit"
                    variant="contained"
                    disabled={(
                      !values.sshUsername
                    )}
                  >
                    Create Username
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Fragment>
  );
};

AddUsername.propTypes = {
  account: object.isRequired,
  updateUsername: func.isRequired,
};

export default connect(
  (state) => ({ account: state.account }),
  {
    updateUsername: updateAccount,
  },
)(AddUsername);
