import React from 'react';
import { Typography } from '@material-ui/core';
import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { Errors } from './Utils/Validate';

interface Props {
  visible: boolean;
  isFetching: boolean;
  errMessage: string;
  setPassword: (value: string) => void;
  setMfa: (value: string) => void;
  setErrors: (errors: Errors) => void;
  formErrors: Errors;
}

const PasswordField: React.FC<Props> = ({
  visible, isFetching, errMessage, setPassword, setMfa, formErrors, setErrors,
}) => {
  const error = errMessage.length > 0 ? (
    <Box justify="center" align="center" margin={{ top: 'medium', bottom: 'medium' }}>
      <Typography color="error">
        {errMessage}
      </Typography>
    </Box>
  ) : null;

  return (
    <Box style={{ display: visible ? '' : 'none' }}>
      <TextField
        testId="password-field"
        fullWidth
        margin="normal"
        id="password"
        placeholder="Password"
        type="password"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value); setErrors({}); }}
        error={Boolean(formErrors.password) || Boolean(errMessage)}
        hintText={formErrors.password}
        disabled={isFetching}
        name="password"
        autoComplete="current-password"
      />
      <TextField
        testId="mfa-field"
        fullWidth
        margin="normal"
        id="mfa"
        placeholder="Two Factor"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMfa(e.target.value)}
        errorText={formErrors.mfa}
        hintText={formErrors.mfa}
        type="number"
        disabled={isFetching}
        name="mfa"
      />
      {error}
      <Box margin={{ top: 'medium' }}>
        <Button
          data-testid="login-button"
          variant="contained"
          fullWidth
          type="submit"
          label={isFetching ? 'Just a moment...' : 'Sign In'}
          color="secondary"
          disabled={isFetching}
        />
      </Box>
    </Box>
  );
};

export default PasswordField;
