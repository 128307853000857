import React, { useEffect } from 'react';
import {
  object, func, bool, shape,
} from 'prop-types';

import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { roles as ROLES } from 'shared/modules/permissions/user/actions';
import Box from 'shared/styleguide/atoms/Box';

import { accountPropTypes } from 'shared/modules/account/models/model';
import { getCustomerSubscriptions } from '../../redux/actions';
// components
import OtherAccounts from './OtherAccounts';
import SubscriptionOverview from './components/SubscriptionOverview';
import PaymentOverview from './components/PaymentOverview';
import InvoiceOverview from './components/InvoiceOverview';
import InvoiceAddress from './components/InvoiceAddressOverview';

const leftSideProps = {
  xs: 12,
  sm: 12,
  md: 8,
  lg: 7,
};

const rightSideProps = {
  xs: 12,
  sm: 12,
  md: 4,
  lg: 5,
};

export const Overview = ({
  __storybookMocks = {}, isPagelyAdmin, account, subscriptions, getCustomerSubscriptions, permissions,
}) => {
  // load data necessary to figure out what to show, then show content
  useEffect(() => {
    if (account.hasSubscription && account.billingCustomerId) {
      if (subscriptions.status === 'pristine' || (subscriptions.status === 'success' && subscriptions.params.customerId !== account.billingCustomerId)) {
        getCustomerSubscriptions(account.billingCustomerId);
      }
    }
  }, [subscriptions.status, subscriptions.params, getCustomerSubscriptions, account.hasSubscription]);

  const hasSubscription = __storybookMocks?.hasSubscription || account.hasSubscription;
  const isAccountHolder = __storybookMocks?.isAccountHolder || permissions.directory[account.id].role === ROLES.PRIMARY;

  return (
    <div>
      {
        (!isAccountHolder) && (
          <Box margin={{ bottom: 'medium' }}>
            <OtherAccounts />
          </Box>
        )
      }

      <Grid container spacing={3}>
        <Grid item {...leftSideProps}>
          <SubscriptionOverview hasSubscription={hasSubscription} isPagelyAdmin={isPagelyAdmin} subscriptions={subscriptions} />
        </Grid>
        <Grid item {...rightSideProps}>
          <Box gap="medium" direction="column">
            <InvoiceOverview />
            <PaymentOverview />
            <InvoiceAddress />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

Overview.propTypes = {
  account: shape(accountPropTypes),
  getCustomerSubscriptions: func,
  isPagelyAdmin: bool,
  permissions: object,
  subscriptions: object,
};

export default connect(
  (state) => ({
    account: state.account,
    subscriptions: state.billing.subscriptions,
    permissions: state.permissions,
  }),
  {
    getCustomerSubscriptions,
  },
)(Overview);
