import React from 'react';
import PropTypes, { number, object } from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import { ErrorText } from 'shared/styleguide/typography';
import { errorsMD } from 'shared/utils';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextField from 'shared/styleguide/atoms/Input/TextField';

export class ForgotPasswordSet extends React.Component {
  static propTypes = {
    callForgotPasswordReset: PropTypes.func,
    callForgotPasswordSet: PropTypes.func,
    forgotPassword: PropTypes.shape({
      status: PropTypes.string,
      statusCode: number,
      validation: object,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
      }),
    }),
  };

  state = {
    password: '',
  };

  componentWillUnmount() {
    this.props.callForgotPasswordReset();
  }

  handleRequest = () => this.props.callForgotPasswordSet(this.props.match.params.token, this.state.password);

  render() {
    const { forgotPassword } = this.props;
    let error = [];
    if (forgotPassword.status === 'success') {
      return (
        <Box align="center" margin={{ top: 'small' }}>
          <Typography variant="body1" align="center">Password has been saved.</Typography>
        </Box>
      );
    }

    if (forgotPassword.statusCode) {
      switch (forgotPassword.statusCode) {
        case 422:
          if (forgotPassword.validation.body.password) {
            const validationError = errorsMD(forgotPassword.validation.body.password.messages);
            error.push(
              <ErrorText key="password">
                {validationError}
              </ErrorText>,
            );
          }
          if (forgotPassword.validation.body.token) {
            error.push(
              <div key="token">
                {
                  forgotPassword.validation.body.token.messages.map((msg) => <ErrorText key={`token-error-${msg}`}>{msg}</ErrorText>)
                }
              </div>,
            );
          }
          break;
        default:
          error = (
            <Typography variant="body2" key="unknown" color="error">An error happened when processing the request</Typography>
          );
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 500,
        }}
      >
        <TextField
          margin="normal"
          id="newpassword"
          errorText={error.length ? error : ''}
          FormHelperTextProps={{ component: 'span' }}
          type="password"
          onChange={(e) => this.setState({ password: e.target.value })}
        />
        <Box margin={{ top: 'small' }}>
          <Button
            variant="contained"
            onClick={this.handleRequest}
            disabled={this.props.forgotPassword.status === 'loading' || !this.state.password}
          >
            Set Password
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPassword: state.requests.forgotPassword,
});
export default connect(mapStateToProps)(ForgotPasswordSet);
