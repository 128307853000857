import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import 'rss-parser/dist/rss-parser.min';

import Moment from 'moment-timezone';
import { Typography } from '@material-ui/core';

import ArticleList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';
import Loading from 'shared/styleguide/atoms/Loading';
import { hashCode } from 'shared/utils';

const RSSParser = require('rss-parser');

class RSS extends React.Component {
  static propTypes = {
    err: PropTypes.any,
    feed: PropTypes.any,
    limit: PropTypes.number,
    url: PropTypes.string.isRequired,
  };

  static defaultProps = {
    limit: 5,
  };

  state = {
    loaded: false,
    feed: [],
    // err: null,
  };

  componentDidMount() {
    if (this.props.feed) {
      this.setState({ feed: this.props.feed, loaded: true });
    } else {
      /* eslint-disable */
      const AbortController = window.AbortController;
      this.controller = new AbortController();
      const signal = this.controller.signal;
      /* eslint-enable */
      // RssParser is global from the import...bad design from the makers,
      // but it was the easiest to use rss parser I found.
      const RssParser = new RSSParser();// eslint-disable-line

      // let's try something crazy.

      fetch(this.props.url, {
        method: 'GET',
        signal,
      }).then(async (response) => {
        const xmlString = await response.text();

        RssParser.parseString(xmlString, (err, feed) => this.setState({ feed, loaded: true, err }));
      }).catch();
    }
  }

  componentWillUnmount() {
    try {
      this.controller.abort();
    } catch (err) {
      return; //eslint-disable-line
    }
  }

  render() {
    if (!this.state.loaded) {
      return <Loading />;
    }

    let count = 0;
    const items = this.state.feed ? this.state.feed.items.filter(() => count++ < this.props.limit) : [];

    if (items.length === 0) {
      return (
        <Fragment>
          <Typography>Could not read news feed from Pagely.com</Typography>
          <Typography>This could be from having an ad blocker enabled. We do not have ads on Atomic, so you can safely disable it.</Typography>
        </Fragment>
      );
    }

    const articles = items.map((item) => {
      const {
        pubDate, title, link, contentSnippet,
      } = item;
      // we're not showing images even if they exist
      // but this is how we would grab them
      // const img = item.content.match(/<img\s+[^>]*src="(https:\/\/[^\s"]+)/i);
      const date = new Moment(pubDate);
      const snippet = contentSnippet.length > 500 ? `${contentSnippet.substring(0, 500)}...` : contentSnippet;
      return {
        title,
        url: link,
        snippet: snippet.replace('[…]', '...'),
        date,
        id: hashCode(title),
      };
    });

    return (
      <ArticleList
        topic="rss"
        columns={1}
        boxProps={{
          wrap: 'wrap',
        }}
        articles={articles}
      />
    );
  }
}

export default RSS;
