import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as PENDING from './constants';

export const fetchPendingServerConfigDeploys = (accountId, options) => {
  const params = options ? qs.stringify({ ...options }, { addQueryPrefix: true }) : '';

  return makeApiAction(
    ({ api }) => api().get(`/serverconfig/gateway-config/pending/account/${accountId}${params}`),
    PENDING.FETCH_PENDING_REQUEST,
    PENDING.FETCH_PENDING_SUCCESS,
    PENDING.FETCH_PENDING_FAILURE,
    { accountId },
    true,
  );
};
