import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Spinner from '@material-ui/core/CircularProgress';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextArea from 'shared/styleguide/atoms/Input/TextArea';
import TextFileSelect from 'shared/styleguide/molecules/TextFileSelect';
import CheckCircleOutlineIcon from 'shared/styleguide/Icons/CheckCircleOutline';
import { jssStyles, inlineStyles } from './styles';
import InfoText from '../InfoText';

export class ValidatingTextFileSelect extends React.Component {
  static propTypes = {
    action: PropTypes.node,
    classes: PropTypes.object.isRequired,
    doing: PropTypes.bool.isRequired,
    done: PropTypes.bool.isRequired,
    fileRequirements: PropTypes.string.isRequired,
    headerText: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    serverValidationMessage: PropTypes.string,
    validator: PropTypes.func.isRequired,
  };

  state = {
    text: '',
    validated: true,
  };

  handleFileSelect = (text) => {
    this.textarea.value = text;
    const validation = this.handleValidate(text);
    this.setState({ text, ...validation });
    this.props.onChange(text, validation.validated);
  };

  handleClear = () => {
    this.textarea.value = '';
    const validation = this.handleValidate('');
    // shouldn't we reset to initial state here?
    this.setState({ text: '', validated: true, validationMessage: '' });
    this.props.onChange('', validation.validated);
  };

  handleTextChange = (e) => {
    (e.target.value).trim();
    const text = (e.target.value).trim();
    const validation = this.handleValidate(text);
    this.setState({
      text,
      ...validation,
    });

    this.props.onChange(text, validation.validated);
  };

  handleValidate = (text) => {
    return this.props.validator(text);
  };

  renderDoing = () => (
    <div
      className={this.props.classes.validatingSpinnerOrSuccessText}
    >
      <Spinner
        color="secondary"
      />
      <br />
      <Typography color="textSecondary">Validating...</Typography>
    </div>
  );

  renderDone = () => (
    <div
      className={this.props.classes.validatingSpinnerOrSuccessText}
    >
      <CheckCircleOutlineIcon
        {...inlineStyles.circleCheckOutlineIcon}
      />
      <br />
      <Typography color="textSecondary">Success!</Typography>
    </div>
  );

  renderValidation = () => (
    <div>
      {this.state.validationMessage ? (
        <span
          className={this.props.classes.errorText}
        >
          {this.state.validationMessage}
        </span>
      ) : null}
      {this.props.serverValidationMessage ? (
        <span
          className={this.props.classes.errorText}
        >
          {this.props.serverValidationMessage}
        </span>
      ) : null}
    </div>
  );

  render() {
    const {
      classes, headerText, fileRequirements, doing, done, action,
    } = this.props;
    return (
      <Fragment>

        <Box>
          {(fileRequirements || headerText) && (
            <Box>
              {
                headerText ? (
                  <Typography variant="h5" gutterBottom><strong>{headerText}</strong></Typography>
                ) : null
              }
              <InfoText>
                {fileRequirements ? `Accepts file types ${fileRequirements}` : ''}
              </InfoText>
            </Box>
          )}
          <div className={`${classes.sectionContainers} ${classes.selectButtonContainer}`}>
            {doing ? this.renderDoing() : null}
            {done ? this.renderDone() : null}
            <div className={classes.textareaContainer}>
              <Box direction="row" justify="space-between" align="center" style={{ width: '100%' }}>
                <Box><Typography variant="body2" color="textSecondary">Paste the file&apos;s text in field below or upload from file</Typography></Box>

                <Button
                  color="default"
                  variant="outlined"
                  onClick={this.handleClear}
                >
                  Clear content
                </Button>
              </Box>
              <Box margin={{ top: 'xsmall', bottom: 'small' }} style={{ width: '100%' }}>
                <TextArea
                  fullWidth
                  id="upload"
                  name="upload"
                  inputRef={(e) => { this.textarea = e; }}
                  code
                  value={this.state.text}
                  onChange={this.handleTextChange}
                  disabled={doing || done}
                  margin="none"
                />
                <Box margin={{ top: 'small' }}>
                  {this.renderValidation()}
                </Box>
              </Box>
            </div>
            <Box style={{ width: '100%' }} direction="row-reverse" justify="space-between" align="center">
              <Box>
                {
                  action
                }
              </Box>
              {
                doing || done ? null : (
                  <Box margin={{ bottom: 'small' }}>
                    <TextFileSelect
                      key="textfileselect"
                      selectFile={this.handleFileSelect}
                      accept={fileRequirements}
                    >
                      <Button
                        variant="outlined"
                        color="default"
                        label="Select File"
                      />
                    </TextFileSelect>
                  </Box>
                )
              }
            </Box>
          </div>
        </Box>
      </Fragment>
    );
  }
}

export default withStyles(jssStyles)(ValidatingTextFileSelect);
