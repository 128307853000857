import React from 'react';
import { object } from 'prop-types';

import {
  Typography,
  AccordionSummary,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import {
  Save, Cancel,
  LibraryAddCheck, Delete, Edit,
} from '@material-ui/icons';

import { ErrorText } from 'shared/styleguide/typography';
import Loading from 'shared/styleguide/atoms/Loading';
import CircleCheck from 'shared/styleguide/Icons/CheckCircleOutline';
import colors from 'shared/styleguide/theme';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';
import Box from 'shared/styleguide/atoms/Box';
import ConfirmableButton from 'shared/styleguide/atoms/Buttons/ConfirmableButton';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

import SimpleFields from './SimpleFields';

const useStyles = makeStyles(() => {
  return ({
    content: {
      '&$expanded': {
        marginBottom: 0,
      },
    },
    expanded: {},
  });
});

const Summary = ({
  values, form,
}) => {
  const classes = useStyles();

  if (form.showUpdated && form.deleted) {
    return (
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Box flex={1} padding="xxsmall" direction="row" align="center" justify="flex-start">
          <Box css={{ width: '40px' }}>
            <CircleCheck
              style={{
                width: 24,
                height: 24,
              }}
              color="secondary"
            />
          </Box>
          <Box flex={1}>
            <Typography>
              Redirect deleted.
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
    );
  }

  const minHeight = form.mode === 'create' ? 70 : 0;

  return (
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" classes={form.errors?.general ? classes : {}}>
      <Box flex={1}>
        <Box flex={1} direction="row" css={{ minHeight }}>
          {
            // inline status icon
            form.showUpdated && form.mode === 'edit' && (
              <Box css={{ width: '40px' }} direction="row" align="center" justify="flex-start">
                {
                  form.newlyAdded
                    ? (
                      // will show on newly created item
                      <LibraryAddCheck color="secondary" />
                    ) : (
                      // for create or update
                      <CircleCheck
                        style={{
                          width: 24,
                          height: 24,
                          padding: 2,
                        }}
                        color="secondary"
                      />
                    )
                }
              </Box>
            )
          }
          {
            form.status === 'loading' && (
              <Box css={{ width: '40px' }} direction="row" align="center" justify="flex-start">
                <Loading size="small" margin={{ top: 0, right: 'small' }} />
              </Box>
            )
          }
          {
            form.showUpdated && form.mode === 'create'
            && (
              <Box css={{ width: '40px' }} direction="row" align="center" justify="flex-start">
                <CircleCheck
                  style={{
                    width: 24,
                    height: 24,
                    padding: 2,
                  }}
                  color="secondary"
                />
              </Box>
            )
          }
          <SimpleFields
            form={form}
            values={values || form.initialValues}
          />

          {
            form.mode === 'edit'
              ? (
                <Box css={{ width: '160px' }}>
                  <Box direction="row" justify="flex-end" align="center">
                    <Box flex={0} margin={{ right: 'xxsmall' }}>
                      {
                        !!values.statusCode && values.enabled ? <GhostTag color={values.statusCode === 301 ? 'green' : 'accent'}>{values.statusCode}</GhostTag>
                          : <GhostTag color="orange">Disabled</GhostTag>
                      }
                    </Box>
                    <Box align="center">
                      {
                        (form.mode === 'create' || form.changed)
                          ? (
                            <Tooltip title="Save" placement="top">
                              <IconButton
                                onClick={form.save}
                                size="small"
                              >
                                <Save color="secondary" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Edit" placement="top">
                              <IconButton
                                onClick={form.showAdvanced}
                                size="small"
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          )
                      }
                    </Box>
                    <Box align="center">
                      <ConfirmableButton
                        icon={Delete}
                        activeColor={colors.red10}
                        callback={form.deleteSingleRedirect}
                        tooltip="Delete redirect"
                        color="default"
                        size="small"
                        disabled={form.redirectsStatus === 'loading'}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box css={{ width: '85px' }} direction="column" align="flex-end">
                  <Box flex={1} direction="row" justify="flex-end" align="center">
                    <Box align="center">
                      <Tooltip title="Save" placement="top">
                        <IconButton
                          onClick={form.save}
                          size="small"
                        >
                          <Save />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box align="center">
                      <Tooltip title="Cancel without saving" placement="top">
                        <IconButton
                          onClick={form.cancel}
                          size="small"
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              )
          }
        </Box>
        {
          form.mode === 'create'
          && (
            <Box margin={{ top: 'xsmall' }} align="flex-start">
              <TextLink variant="body2" color="textSecondary" onClick={form.showAdvanced}>
                Advanced Configuration
              </TextLink>
            </Box>
          )
        }
        {
          form.errors?.general && (
            <Box margin={{ top: 'xsmall' }} align="center">
              <ErrorText>{form.errors.general}</ErrorText>
            </Box>
          )
        }
      </Box>
    </AccordionSummary>
  );
};

Summary.propTypes = {
  form: object,
  values: object,
};

export default Summary;
