import React from 'react';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { AccountUrl } from '../../types/AccountUrl';
import LoginButton from './LoginButton';

interface Props {
  setUsePassword: (value: boolean) => void;
  ssoUrls: AccountUrl[];
}

const SsoField: React.FC<Props> = ({ setUsePassword, ssoUrls }) => (
  <div>
    <Box>
      {ssoUrls.map((url) => (
        <LoginButton
          key={`${url.ownerUserId}-${url.identityProvider}`}
          {...url}
        />
      ))}
    </Box>
    <Box align="center" margin={{ top: 'medium', bottom: 'medium' }}>
      <Button onClick={() => setUsePassword(true)} variant="outlined" fullWidth>
        Sign In with password
      </Button>
    </Box>
  </div>
);

export default SsoField;
