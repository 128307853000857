import React, { useState, useCallback } from 'react';
import { object } from 'prop-types';

import {
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import CollapsibleTable from 'shared/styleguide/molecules/CollapsibleTable';
import {
  formatMiB, hashCode,
} from 'shared/utils';
import { getComparator, stableSort } from 'shared/utils/sorting';

import CommonFile from './CommonFile';

const headCells = [
  {
    id: 'path',
    numeric: false,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'types',
    numeric: false,
    disablePadding: false,
    label: 'Types',
  },
  {
    id: 'count',
    numeric: false,
    disablePadding: false,
    label: 'Number of files',
  },
  {
    id: 'used',
    numeric: false,
    disablePadding: false,
    label: 'Usage',
  },
];

const Detail = ({ data }) => {
  return (
    <Box padding={{ top: 'small', bottom: 'small' }}>
      {
        data.data
          .map((file) => (
            <CommonFile key={hashCode(file.path + file.types)} {...file} types={data.types} />
          ))
      }
    </Box>
  );
};

Detail.propTypes = {
  data: object,
};

const CommonFiles = ({ report }) => {
  const [selected, setSelected] = useState(['log', 'sql', 'backup']);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('path');

  const handleChange = (event) => {
    if (event.target.checked) {
      // add it to selected
      setSelected([...selected, event.target.name]);
    } else {
      // remove it from selected
      setSelected(selected.filter((s) => s !== event.target.name));
    }
  };

  const handleSetOrderBy = (value) => {
    setOrderBy(value);
  };

  const handleSetOrder = (value) => {
    setOrder(value);
  };

  const files = report.alerts.filter((alert) => selected.includes(alert.type));

  // sorting api response into single data per path (TODO: make backend do this)
  const paths = [...new Set(files.map((file) => file.path))];
  const sortedFiles = [];

  paths.forEach((path) => {
    const matches = files.filter((file) => file.path === path);
    const sortedIndex = sortedFiles.findIndex((file) => file.path === path);

    const used = matches.reduce((acc, file) => {
      return acc + file.used;
    }, 0);

    const count = matches.reduce((acc, file) => {
      return acc + file.files.length;
    }, 0);

    const types = matches.reduce((acc, file) => {
      acc.push(file.type);
      return acc;
    }, []);

    if (!sortedFiles[sortedIndex]) {
      sortedFiles.push({
        path,
        used,
        types: types.join(', '),
        count,
        data: matches,
      });
    } else {
      const updatedTypes = sortedFiles[sortedIndex].types.split(', ');
      sortedFiles[sortedIndex] = {
        path,
        used: sortedFiles[sortedIndex].used + used,
        types: [...updatedTypes, ...types].join(', '),
        count: sortedFiles[sortedIndex].used + count,
        data: [...sortedFiles[sortedIndex].data, ...matches],
      };
    }
  });
  // end sorting api response

  // sorting by column
  const sortedItems = stableSort(sortedFiles, getComparator(order, orderBy));
  const memoizedSortedItems = useCallback(sortedItems, [sortedItems, sortedFiles, order, orderBy]);

  const ListRows = memoizedSortedItems.map((item) => {
    return {
      data: item,
      display: [
        item.path,
        item.types,
        item.count,
        formatMiB(item.used, 3),
      ],
    };
  });

  return (
    <Box direction="column">
      <Typography component="p" gutterBottom color="textPrimary" variant="body1">
        File types that are likely to be using up disk space.
      </Typography>
      <Box direction="column" margin={{ top: 'small', bottom: 'small' }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Filter By Type:</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={selected.includes('log')} onChange={handleChange} name="log" />}
              label="Logs"
            />
            <FormControlLabel
              control={<Checkbox checked={selected.includes('sql')} onChange={handleChange} name="sql" />}
              label="SQL"
            />
            <FormControlLabel
              control={<Checkbox checked={selected.includes('backup')} onChange={handleChange} name="backup" />}
              label="Backups"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <CollapsibleTable
        paginate
        pageLength={30}
        headCells={headCells}
        ListRows={ListRows}
        Detail={Detail}
        onSetOrder={handleSetOrder}
        onSetOrderBy={handleSetOrderBy}
        order={order}
        orderBy={orderBy}
      />
    </Box>
  );
};

CommonFiles.propTypes = {
  data: object,
  report: object.isRequired,
};

export default CommonFiles;
