import React, { useState } from 'react';
import { any } from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';

import TwoColumn from 'shared/styleguide/atoms/Table/TwoColumn';
import Box from 'shared/styleguide/atoms/Box';

import CertType from '../../components/CertType';

const CertHeading = (props) => {
  const {
    subjectData, startDate, expires, certType, alternateNames,
  } = props;

  const [useGMT, handleSetGMT] = useState(false);

  const start = moment(startDate);
  const until = moment(expires);

  if (useGMT) {
    start.utc();
    until.utc();
  }
  const emdash = '\u2014';

  const Toggle = withStyles({
  })(Switch);

  const data = [
    ['Issued By', props.issuedBy],
    ['Type', (
      <CertType
        key={`type-${subjectData.name}`}
        type={certType}
        name={subjectData.name}
        alternateNames={alternateNames}
      />
    )],
    ['Valid Dates', [(
      <Box direction="row" align="center" key={`dates-${subjectData.name}`} gap="medium">
        <div>
          {
            until.isBefore(moment.now())
              ? (
                <Typography color="error">
                  {start.format('LLL')} {emdash} {until.format('LLL')}
                </Typography>
              )
              : (`${start.format('LLL')} ${emdash} ${until.format('LLL')}`)
          }
        </div>
        <div>
          <FormControlLabel
            control={(
              <Toggle
                checked={useGMT}
                onChange={(e) => { handleSetGMT(e.target.checked); }}
                value="useGMT"
              />
            )}
            label="Use GMT"
          />
        </div>
      </Box>
    )],
    ]];
  return (
    <Box flex={1}>
      <TwoColumn
        data={data}
      />
    </Box>
  );
};

CertHeading.propTypes = {
  alternateNames: any,
  certType: any,
  expires: any,
  issuedBy: any,
  startDate: any,
  subjectData: any,
};

export default CertHeading;
