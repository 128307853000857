import React, { useEffect, Fragment, useState } from 'react';
import { object, func, array } from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Empty from 'shared/styleguide/atoms/Empty';
import Loading from 'shared/styleguide/atoms/Loading';
import SearchBar from 'shared/styleguide/molecules/SearchBar';
import { useMergeState } from 'shared/hooks/useMergeState';

import {
  fetchRedirects, importRedirects, exportRedirects,
} from '../../redux/redirects/actions';
import CustomConfigDescription from '../../CustomConfigDescription';
import CreateRedirect from './CreateRedirect';
import RedirectsList from './RedirectsList';
import Import from './Import';
import Export from './Export';

const initialStateValues = {
  showCreate: false,
  importOpen: false,
  errors: null,
  pristine: true,
};

export const Redirects = ({
  redirects, match: { params: { appId } }, fetchRedirects, importRedirects, exportRedirects, customConfig, __storybookMocks,
}) => {
  const initialState = __storybookMocks ? { ...initialStateValues, ...__storybookMocks } : initialStateValues;
  const { state, mergeState } = useMergeState(initialState);
  const [searchText, setSearchText] = useState('');

  const {
    showCreate, importOpen, pristine, errors,
  } = state;

  useEffect(() => {
    if (pristine && Number(appId) !== redirects.params.appId) {
      fetchRedirects(Number(appId));
    }

    if (Number(appId) === redirects.params?.appId && redirects.status === 'success') {
      mergeState({ pristine: false });
    }
  }, [appId, redirects.status, redirects.params, fetchRedirects, pristine]);

  if (pristine && redirects.status === 'failed') {
    return (
      <div>
        <Empty>
          {redirects.errMessage || 'An error occurred.'}
        </Empty>
      </div>
    );
  }

  if (pristine && ['pristine', 'loading'].includes(redirects.status)) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <CustomConfigDescription customConfig={customConfig} />

      <Typography variant="h3">Redirects</Typography>
      <Box direction="row" justify="space-between" align="center" margin={{ bottom: 'small' }}>
        <Box direction="row" gap="xsmall" justify="flex-end" align="center" flex={1}>
          <SearchBar
            css={{ flex: '1', padding: '8px 8px 0 0' }}
            textFieldProps={{
              fullWidth: true,
              variant: 'outlined',
            }}
            text="Filter"
            onChange={(filter) => setSearchText(filter)}
          />
          {
            !showCreate
            && (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => mergeState({ showCreate: true, errors: null })}
                  label="Create Redirect"
                />
              </div>
            )
          }
          <Import
            appId={Number(appId)}
            importRedirects={importRedirects}
            open={importOpen}
            setOpen={(value) => mergeState({ importOpen: value, errors: null })}
            fetchRedirects={fetchRedirects}
          />
          <Export
            appId={Number(appId)}
            exportRedirects={exportRedirects}
            onSetErrors={(value) => mergeState({ errors: value })}
          />
        </Box>
      </Box>
      {
        !!errors
        && (
          <Box margin={{ bottom: 'small' }} align="center">
            <Typography color="error">{errors}</Typography>
          </Box>
        )
      }
      <CreateRedirect
        showCreate={showCreate}
        onSetShowCreate={(value) => mergeState({ showCreate: value })}
        appId={Number(appId)}
        redirects={redirects.data}
      />
      {
        redirects.status === 'success' && redirects.data.length === 0
          ? (
            <Empty>
              There are no redirects yet.
            </Empty>
          ) : (
            <Fragment>
              <RedirectsList
                appId={Number(appId)}
                redirects={redirects.data}
                searchText={searchText}
              />
            </Fragment>
          )
      }
    </div>
  );
};

Redirects.propTypes = {
  customConfig: array,
  exportRedirects: func,
  fetchRedirects: func,
  importRedirects: func,
  match: object.isRequired,
  redirects: object.isRequired,
};

export default connect(
  (state) => ({
    redirects: state.ares.redirects,
  }),
  {
    fetchRedirects,
    importRedirects,
    exportRedirects,
  },
)(Redirects);
