import React, { useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { getAppDailyStats } from 'shared/modules/analytics/redux/actions';
import Loading from 'shared/styleguide/atoms/Loading';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import Empty from 'shared/styleguide/atoms/Empty';
import Stats from './Stats';

const StatsLoader = ({
  analytics, match, getAppDailyStats, isOnP20Account,
}) => {
  const paramsAppId = analytics.params?.appId;

  useEffect(() => {
    if (match) {
      const { appId } = match.params;
      const accountId = match.params.accountID;

      if (paramsAppId !== appId || analytics.status === 'pristine') {
        const start = moment().subtract(30, 'days').toISOString();
        const end = moment().endOf('day').toISOString();

        getAppDailyStats(accountId, appId, start, end);
      }
    }
  }, [getAppDailyStats, match, paramsAppId, analytics.status]);

  return (
    <ErrorBoundary>
      {
        (!analytics.failed && analytics.data.length > 1) ? ( // eslint-disable-line
          analytics.loading ? <Loading />
            : <Stats analytics={analytics.data} match={match} isOnP20Account={isOnP20Account} />
        )
          : <Empty>No data to display</Empty>
      }
    </ErrorBoundary>
  );
};

StatsLoader.propTypes = {
  analytics: object,
  getAppDailyStats: func,
  isOnP20Account: bool,
  match: object,
};

export default connect(
  (state) => ({
    analytics: state.analytics.app,
    isOnP20Account: state.account.isOnP20Account,
  }),
  {
    getAppDailyStats,
  },
)(StatsLoader);
