import React, { Component } from 'react';
import { object, func } from 'prop-types';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Toggle } from 'shared/styleguide/atoms/Switches';
import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

const WebServerConfig = (props) => {
  const { app, updateAppConfig } = props;
  /*
    nginx: true === "nginx only"
    nginx: false === "nginx + apache"
     */

  // nginx + apache (o    ) nginx
  // we default to nginx: false (aka, default to nginx + apache)

  // if config is empty, defaults are
  let nginx = false; // nginx + apache true, toggle "whats off"

  if (app.data?.config?.nginx) {
      nginx = app.data.config.nginx; // eslint-disable-line
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box direction="row" justify="space-between" flex={1}>
          <Typography variant="subtitle1">Webserver Configuration</Typography>
          <GhostTag>nginx{nginx ? '' : ' + Apache'}</GhostTag>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div>
            <Typography variant="body2" paragraph>
              For compatibility, our default setup uses NGINX in front of Apache. This allows NGINX to handle requests, then passes them off to Apache to serve PHP.
              Unless you need Apache for specific functionality, such as .htaccess files, you can choose to use our NGINX Only setup instead. This is recommended for high traffic sites.
              <strong> You will no longer have .htaccess support if you are on Nginx only.</strong>
            </Typography>
            <Typography variant="body2" paragraph>
              Before changing this setting on production sites, we always recommend that you test with a staging site first.
            </Typography>
          </div>
          <Box direction="row" justify="center" align="center">
            <Typography variant="body1" style={{ marginRight: 11 }}>NGINX + Apache</Typography>
            <div style={{ display: 'inline' }}>
              <Toggle
                toggled={Boolean(nginx)}
                disabled={app.doing}
                label="NGINX Only"
                labelPosition="end"
                onToggle={(e, isInputChecked) => {
                  const version = isInputChecked ? 'nginx' : 'nginx-apache';
                  updateAppConfig({
                    type: 'server-type',
                    appId: app.data.id,
                    update: {
                      serverType: version,
                    },
                  });
                }}
              />
            </div>
          </Box>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

WebServerConfig.propTypes = {
  app: object.isRequired,
  updateAppConfig: func,
};

export default WebServerConfig;
