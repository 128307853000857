import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { History } from 'history';
import {
  ACCOUNTS_SEARCH_REQUEST, ACCOUNTS_SEARCH_RESET,
} from 'admin/modules/search/constants';
import Box from 'shared/styleguide/atoms/Box';
import SearchBar from 'shared/styleguide/molecules/SearchBar';

export const AccountSearchInput = (props: { history: History<unknown> }): JSX.Element => {
  const { history } = props;
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchText === '') {
      return false;
    }
    dispatch({ type: ACCOUNTS_SEARCH_REQUEST, searchString: searchText });
    history.push('/account-search');
    return false;
  };

  return (
    <Box>
      <form onSubmit={handleSubmit} autoComplete="off" aria-label="form">
        <SearchBar
          text="Account Search"
          onChange={(value) => setSearchText(value)}
          onClear={() => { dispatch({ type: ACCOUNTS_SEARCH_RESET }); }}
        />
      </form>
    </Box>
  );
};

export default AccountSearchInput;
