import React, { useState } from 'react';
import {
  object, func, bool,
} from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import Dialog from 'shared/styleguide/molecules/Modal';
import Select from 'shared/styleguide/atoms/Select/Select';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import { consolidateErrors } from 'shared/utils/validation';

import { moveAppDomain } from 'shared/modules/app/redux/domains/actions';

const MoveDomain = (props) => {
  const {
    app, apps, domain,
  } = props; // objects
  const { onMoveApp, onClose } = props; // fuctions

  const [destinationApp, setDestinationApp] = useState();
  const [moveStatus, setMoveStatus] = useState();
  const [errors, setErrors] = useState();

  const handleMoveApp = (domain, moveToApp, cb) => {
    /* eslint-disable prefer-destructuring */
    // shouldn't need to confirm, just map API request
    setMoveStatus('loading');

    const appId = domain.appId;
    const aliasId = domain.id;
    const toAppId = moveToApp.appId;

    onMoveApp(appId, aliasId, toAppId, { domainId: aliasId })
      .then(cb)
      .catch((err) => {
        if (err) {
          setMoveStatus('error');
          setErrors(consolidateErrors(err));
        }
      });
    /* eslint-enable prefer-destructuring */
  };

  const options = apps.data
    // filter out the current app (can't move to itself)
    .filter((d) => app.data.id !== d.id)
    .map((d) => ({
      label: d.name,
      value: d.id,
      appId: d.id,
    }));

  const currentApp = domain;

  return (
    <Dialog
      title="Move domain to another app"
      open={props.open}
      overflow="visible"
      apps={apps}
      onClose={onClose}
      actions={[
        <Button
          key="1"
          variant="outlined"
          color="default"
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="2"
          variant="contained"
          loading={moveStatus === 'loading'}
          onClick={() => {
            handleMoveApp(domain, destinationApp, onClose);
          }}
        >
          Confirm Move
        </Button>,
      ]}
    >
      <Box padding="medium">
        <Typography variant="body1" paragraph>Which app would you like to move this domain to?</Typography>
        <Box margin={{ top: 'xsmall', bottom: 'medium' }}>
          <Select
            options={
              options
            }
            onChange={(d) => setDestinationApp(d)}
            styles={{
              menu: (base) => ({ ...base, zIndex: 4 }),
            }}
            value={destinationApp}
            isClearable={false}
            placeholder="Select an app to move domain to"
          />
        </Box>

        {currentApp
          && (
            <Typography variant="body1" color="textSecondary" paragraph>
              Currently moving the domain <Typography component="span" color="textPrimary">{currentApp.fqdn}</Typography>
            </Typography>
          )}

        {
          destinationApp && (
            <Typography variant="body2" color="textSecondary" paragraph>
              <span>Confirming will move this domain and any SSL Certificates to <Typography component="span" color="textPrimary">{destinationApp.label}</Typography>.</span>
            </Typography>
          )
        }

        {moveStatus === 'error' && (<Typography component="span" color="error">{errors}</Typography>)}
      </Box>
    </Dialog>
  );
};

MoveDomain.propTypes = {
  app: object.isRequired,
  apps: object.isRequired,
  domain: object.isRequired,
  onClose: func.isRequired,
  onMoveApp: func.isRequired,
  open: bool,
};

export default connect(
  null,
  {
    onMoveApp: moveAppDomain,
  },
)(MoveDomain);
