import makeApiAction, { ApiAction } from 'shared/utils/redux/apiActions';

import * as SSO_POOL from './constants';
import { UserPool } from './types';

export const fetchUserPool = (accountID: number, userType: string = 'Pagely.Model.Accounts.Account'): ApiAction<UserPool> => makeApiAction(
  ({ api }) => api().get(`/auth/sso/pools/by-owner/${userType}/${accountID}`),
  SSO_POOL.SSO_POOL_REQUEST,
  SSO_POOL.SSO_POOL_SUCCESS,
  SSO_POOL.SSO_POOL_FAILURE,
  { accountID },
);
