import React from 'react';
import { object } from 'prop-types';

import { Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';

import { constructFromUrl, constructToUrl } from '../utils';

const SimpleFields = ({
  values, form,
}) => {
  const fromDisplay = constructFromUrl(values.from);
  const toDisplay = constructToUrl(values.from, values.to);

  return (
    <Box direction="row" alignItems="center" flex={1} gap="small" align="center">
      <Box css={{ flexBasis: '48%' }}>
        <Box>
          {
            form.mode === 'create'
              ? (
                <TextField
                  variant="outlined"
                  value={form.simpleValues.from || ''}
                  onChange={(e) => form.changeSimpleFields('from', e.target.value)}
                  autoComplete="off"
                  margin="dense"
                  fullWidth
                  multiline
                  disabled={form.advancedEdit || form.status === 'loading'}
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  placeholder="/example-from-url"
                />
              ) : (
                <Typography>{fromDisplay || ''}</Typography>
              )
          }
        </Box>
      </Box>
      <Box css={{ flexBasis: 'auto' }}>
        <Box align="center">
          <ArrowForward color="action" />
        </Box>
      </Box>
      <Box css={{ flexBasis: '48%' }}>
        <Box>
          {
            form.mode === 'create'
              ? (
                <TextField
                  variant="outlined"
                  value={form.simpleValues.to || ''}
                  onChange={(e) => form.changeSimpleFields('to', e.target.value)}
                  autoComplete="off"
                  margin="dense"
                  fullWidth
                  multiline
                  disabled={form.advancedEdit || form.status === 'loading'}
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  placeholder="/example-to-url"
                />
              ) : (
                <Typography>{toDisplay || ''}</Typography>
              )
          }

        </Box>
      </Box>
    </Box>
  );
};

SimpleFields.propTypes = {
  form: object,
  values: object,
};

export default SimpleFields;
