import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ColorText } from 'shared/styleguide/typography';

interface Props {
  rules: Array<boolean>;
}

const useStyles = makeStyles((theme) => {
  return ({
    root: {
      color: 'inherit',
    },
  });
});

const PasswordRequirements = ({
  rules = [null, null, null],
}: Props) => {
  const [
    isLengthValid,
    isRepeatCharsValid,
    areRulesValid,
    isSequentialCharsValid,
  ] = rules;
  const theme = useTheme();
  const classes = useStyles();

  const colorMap = (value) => {
    switch (value) {
      case true:
        return theme.palette.secondary.main;
      case false:
        return theme.palette.warning.main;
      case null:
      default: return undefined;
    }
  };

  // true is green, false is red
  return (
    <div>
      <Typography className={classes.root} variant="subtitle1" gutterBottom>
        Password Requirements:
      </Typography>
      <Typography className={classes.root} variant="body2"><ColorText color={colorMap(isLengthValid)}>Must be at least 8 characters long</ColorText></Typography>
      <Typography className={classes.root} variant="body2"><ColorText color={colorMap(isRepeatCharsValid)}>Must not have 4 or more consecutive repeated characters</ColorText></Typography>
      <Typography className={classes.root} variant="body2"><ColorText color={colorMap(isSequentialCharsValid)}>Must not include 4 consecutive sequential characters (abcd, 1234, etc)</ColorText></Typography>
      <ColorText color={colorMap(areRulesValid)}>
        <Typography className={classes.root} variant="body2">Must match at least two of the following rules:</Typography>
        <ul>
          <Typography className={classes.root} variant="body2" component="li">one lower and one uppercase letter</Typography>
          <Typography className={classes.root} variant="body2" component="li">one number</Typography>
          <Typography className={classes.root} variant="body2" component="li">one non-alphanumeric character (symbols, unicode, etc)</Typography>
        </ul>
      </ColorText>
    </div>
  );
};

export default React.memo(PasswordRequirements);
