export const CREATE_REDIRECT_REQUEST = 'ARES_CREATE_REDIRECT_REQUEST';
export const CREATE_REDIRECT_SUCCESS = 'ARES_CREATE_REDIRECT_SUCCESS';
export const CREATE_REDIRECT_FAILURE = 'ARES_CREATE_REDIRECT_FAILURE';

export const IMPORT_REDIRECTS_REQUEST = 'ARES_IMPORT_REDIRECTS_REQUEST';
export const IMPORT_REDIRECTS_SUCCESS = 'ARES_IMPORT_REDIRECTS_SUCCESS';
export const IMPORT_REDIRECTS_FAILURE = 'ARES_IMPORT_REDIRECTS_FAILURE';

export const EXPORT_REDIRECTS_REQUEST = 'ARES_EXPORT_REDIRECTS_REQUEST';
export const EXPORT_REDIRECTS_SUCCESS = 'ARES_EXPORT_REDIRECTS_SUCCESS';
export const EXPORT_REDIRECTS_FAILURE = 'ARES_EXPORT_REDIRECTS_FAILURE';

export const FETCH_REDIRECTS_REQUEST = 'ARES_FETCH_REDIRECTS_REQUEST';
export const FETCH_REDIRECTS_SUCCESS = 'ARES_FETCH_REDIRECTS_SUCCESS';
export const FETCH_REDIRECTS_FAILURE = 'ARES_FETCH_REDIRECTS_FAILURE';

export const UPDATE_REDIRECT_REQUEST = 'ARES_UPDATE_REDIRECT_REQUEST';
export const UPDATE_REDIRECT_SUCCESS = 'ARES_UPDATE_REDIRECT_SUCCESS';
export const UPDATE_REDIRECT_FAILURE = 'ARES_UPDATE_REDIRECT_FAILURE';

export const REORDER_REDIRECT_REQUEST = 'ARES_REORDER_REDIRECT_REQUEST';
export const REORDER_REDIRECT_SUCCESS = 'ARES_REORDER_REDIRECT_SUCCESS';
export const REORDER_REDIRECT_FAILURE = 'ARES_REORDER_REDIRECT_FAILURE';

export const DELETE_REDIRECT_REQUEST = 'ARES_DELETE_REDIRECT_REQUEST';
export const DELETE_REDIRECT_SUCCESS = 'ARES_DELETE_REDIRECT_SUCCESS';
export const DELETE_REDIRECT_FAILURE = 'ARES_DELETE_REDIRECT_FAILURE';

export const REMOVE_REDIRECT_FROM_STORE = 'ARES_REMOVE_REDIRECT_FROM_STORE';

export const IMPORT_UPDATES_REDIRECTS = 'ARES_IMPORT_UPDATES_REDIRECTS';

export const CLEAR_ADDED_IN_UI = 'ARES_CLEAR_ADDED_IN_UI';
