import React, { useState, forwardRef } from 'react';
import styled from '@emotion/styled';

import SearchIcon from '@material-ui/icons/Search';
import XIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

import { string, func, bool } from 'prop-types';
import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';

const SearchWrapper = styled(Box)`
  position: relative;
  bottom: -5px;
`;

const AlertsSearch = ({
  field, label, onSearch, onChange, disabled, error,
}, ref) => {
  const [filter, setFilter] = useState('');

  const handleChange = (e, value) => {
    if (e.target.name === 'accountId') {
      onChange(value);
    }
    setFilter(value);
  };

  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      onSearch(field, filter);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFilter('');
    onSearch(field, null);
  };

  return (
    <SearchWrapper row>
      {
        field === 'accountId' && <SearchIcon />
      }
      <TextField
        inputRef={ref}
        name={field}
        id={field}
        placeholder={label}
        value={filter}
        onChange={(e, v) => handleChange(e, v)}
        onKeyDown={handleSearch}
        style={{ bottom: 8, marginLeft: 8 }}
        autoComplete="off"
        margin="dense"
        disabled={disabled || false}
        error={error}
      />
      <IconButton
        style={{ top: -5 }}
        size="small"
        onClick={((e) => handleReset(e))}
      >
        <XIcon />
      </IconButton>
    </SearchWrapper>
  );
};

const ForwardedAlertsSearch = forwardRef(AlertsSearch);

AlertsSearch.propTypes = {
  disabled: bool,
  error: bool,
  field: string.isRequired,
  label: string.isRequired,
  onChange: func,
  onSearch: func.isRequired,
};

export default ForwardedAlertsSearch;
