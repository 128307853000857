import React from 'react';
import { array } from 'prop-types';
import moment from 'moment';

import ReactTable from 'shared/styleguide/organisms/ReactTable';

export const PluginsList = (props) => {
  const { data } = props;

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 30,
    },
    {
      Header: 'Platform',
      accessor: 'platform',
      width: 90,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 50,
      type: 'nosort',
    },
    {
      Header: 'Location',
      accessor: 'location',
      minWidth: 150,
    },
    {
      Header: 'Date Added',
      accessor: 'dateAdded',
      width: 110,
      Cell: ({ row: { original: { dateAdded } } }) => {
        return (moment(dateAdded).format('MMM D, YYYY'));
      },
    },
  ];

  return (
    <ReactTable
      columns={columns}
      data={data}
      paginate
      sort
    />
  );
};

PluginsList.propTypes = {
  data: array.isRequired,
};

export default PluginsList;
