import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

import { fetchAccountTags } from 'shared/modules/account/redux/actions';
import { TagsReducer } from 'shared/modules/metadata/types';
import { NumberLike } from 'shared/types/types';
import { Account } from 'shared/modules/account/types';

const colorMap = {
  'vic': 'red',
  'ares': 'green',
  'esla': 'yellow',
  'gw1': 'lilac',
};

interface AccountTagsProps {
  account: Account;
  accountTags: TagsReducer['account'];
  getAccountTags(accountId: NumberLike): void;
}

const AccountTags = ({ account, accountTags, getAccountTags }: AccountTagsProps) => {
  const {
    active, billingPlanName, awsCustomerId, id: accountId, billingSource,
  } = account;

  useEffect(() => {
    if (accountId && accountTags.params.accountId !== accountId) {
      getAccountTags(accountId);
    }
  }, [accountId, accountTags, getAccountTags]);

  if (accountTags.status !== 'success' || accountTags.data.length === 0) {
    return null;
  }

  const activeString = active ? 'active' : 'inactive';
  const defaultTags = [
    activeString,
  ];
  if (billingPlanName) {
    defaultTags.push(billingPlanName);
  }

  const isAWS = (awsCustomerId !== null
    && billingSource === 'aws');

  if (isAWS) {
    defaultTags.unshift('AWS');
  }

  const tagsAutoGenerated = defaultTags.map((tagName) => (
    <GhostTag
      key={tagName}
    >
      {tagName}
    </GhostTag>
  ));

  return (
    <Box gap="xxsmall" direction="row" wrap="wrap">
      {tagsAutoGenerated}
      {
        accountTags.data.map((tag) => {
          const [tagType, tagName] = tag.tagId.split(':');

          return (
            <GhostTag key={tag.tagId} color={colorMap[tagName]}>{tagName}</GhostTag>
          );
        })
      }
    </Box>
  );
};

export default connect(
  (state: { tags: TagsReducer}) => ({
    accountTags: state.tags.account,
  }),
  {
    getAccountTags: fetchAccountTags,
  },
)(AccountTags);
