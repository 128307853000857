import React, { useState } from 'react';
import { object, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import {
  Paper, Typography,
} from '@material-ui/core';

import WideTextField from 'shared/styleguide/atoms/Input/WideTextField';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';

import { updateAccount, getSecretAnswer } from 'shared/modules/account/redux/actions';

const QUESTIONS = {
  'smaiden': "Mother's maiden name",
  'shigh': 'Name of Highschool',
  'sbirth': 'Where you were born',
  'ssocial': 'Any 4 digit PIN',
};

const ShowPinToAdmin = ({ account, ...props }) => {
  const [secrets, setSecrets] = useState({ showSecrets: 'initial' });

  const handleFetchPin = async () => {
    setSecrets({ showSecrets: 'loading' });
    const answer = await props.fetchSecretPin(account.id);
    setSecrets({ showSecrets: 'ready', question: account.authQuestion, answer });
  };

  return (
    <Box padding={{ top: 'small', bottom: 'small' }}>
      <Typography gutterBottom variant="body2">As an Admin, you can request to see the user&apos;s secret question and answer for verification purposes:</Typography>
      {
        secrets.showSecrets === 'ready' ? (
          <Box padding="xsmall">
            <Typography variant="body1" gutterBottom>{QUESTIONS[secrets.question] || secrets.question}</Typography>
            <Typography variant="body1">{secrets.answer}</Typography>
          </Box>
        ) : (
          <Box padding="xsmall" direction="row">
            <Button
              variant="outlined"
              loading={secrets.showSecrets === 'loading'}
              onClick={(handleFetchPin)}
            >
              Request User Secrets
            </Button>
          </Box>
        )
      }
    </Box>
  );
};

ShowPinToAdmin.propTypes = {
  account: object,
  fetchSecretPin: func,
};

const PIN = ({
  account, user, getSecretAnswer, updateAccount,
}) => {
  const isAdmin = user?.user?.adminAccount;

  const onSubmit = (values, actions) => {
    let hasErrors = false;

    if (values.newPin.length < 4) {
      actions.setFieldError('newPin', 'New PIN is too short');
      hasErrors = true;
    }

    if (values.newPin !== values.confirmNewPin) {
      actions.setFieldError('confirmNewPin', 'PINs don\'t match');
      hasErrors = true;
    }

    if (!isAdmin && !values.oldPassword) {
      actions.setFieldError('oldPassword', 'Current Password required');
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    const { confirmNewPin, newPin, ...changed } = values;
    actions.setStatus('loading');

    updateAccount(account.id, { ...changed, secretAnswer: newPin })
      .then(() => {
        actions.resetForm();
        setTimeout(() => {
          actions.setStatus('');
        }, 5000);
        actions.setStatus('success');
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          const fields = err.response?.data?.body;
          Object.entries(fields).forEach(([key, value]) => {
            actions.setFieldError(key, value.messages.join(', '));
          });
        }

        actions.setStatus('failure');
        actions.setSubmitting(false);
      });
  };

  return (
    <Paper>
      <Box padding="medium">
        <Typography variant="h4" color="textPrimary">Change Pin </Typography>
        <Box>
          <Formik
            initialStatus="initial"
            initialValues={{
              oldPassword: '',
              newPin: '',
              confirmNewPin: '',
            }}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              status,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box direction="row">
                  {
                    isAdmin ? (
                      <ShowPinToAdmin key={account.id} account={account} isAdmin={isAdmin} fetchSecretPin={getSecretAnswer} />
                    ) : (
                      <WideTextField
                        id="oldPassword"
                        autoComplete="off"
                        placeholder="Current Password"
                        value={values.oldPassword}
                        error={Boolean(errors.oldPassword)}
                        helperText={errors.oldPassword}
                        onChange={handleChange}
                        margin="normal"
                        type="password"
                      />
                    )
                  }
                </Box>
                <Box direction="row" gap="medium" wrap="wrap">
                  <WideTextField
                    id="newPin"
                    autoComplete="off"
                    placeholder="New PIN"
                    required
                    value={values.newPin}
                    error={Boolean(errors.newPin)}
                    helperText={errors.newPin}
                    onChange={handleChange}
                    margin="normal"
                    type="password"
                  />
                  <WideTextField
                    id="confirmNewPin"
                    autoComplete="off"
                    placeholder="Confirm New PIN"
                    required
                    value={values.confirmNewPin}
                    error={Boolean(errors.confirmNewPin)}
                    helperText={errors.confirmNewPin}
                    onChange={handleChange}
                    margin="normal"
                    type="password"
                  />
                </Box>
                <Box padding={{ top: 'small', bottom: 'small' }}>
                  <Typography variant="body2" color="textSecondary">
                    A confirmation email will be sent to your email ({account.email})
                  </Typography>
                </Box>
                <Box row padding={{ top: 'small', bottom: 'small' }}>
                  <Button
                    status={status}
                    type="submit"
                    variant="contained"
                    disabled={(
                      !values.newPin
                      || !values.confirmNewPin
                    )}
                  >
                    Save New PIN
                  </Button>
                </Box>
              </form>

            )}
          </Formik>
        </Box>
      </Box>
    </Paper>
  );
};

PIN.propTypes = {
  account: object,
  getSecretAnswer: func.isRequired,
  isAdmin: bool,
  updateAccount: func.isRequired,
  user: object,
};

export default connect(
  (state) => ({
    user: state.user,
    account: state.account,
  }),
  {
    updateAccount,
    getSecretAnswer,
  },
)(PIN);
