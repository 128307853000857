import React, { useRef, useState } from 'react';
import {
  bool, func, number, object,
} from 'prop-types';
import { CSVReader, jsonToCSV } from 'react-papaparse';
import { useDispatch } from 'react-redux';

import { Close, Warning as WarningIcon } from '@material-ui/icons';

import {
  Dialog, DialogTitle, DialogContent, Radio,
  RadioGroup, FormControlLabel, Grid, IconButton, Typography, useTheme,
} from '@material-ui/core';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import {
  importUpdatesRedirects,
} from '../../redux/redirects/actions';

import template from './template.json';

const fileDownload = require('js-file-download');

const csv = jsonToCSV([template]);

const Import = ({
  open, setOpen, importRedirects, appId, fetchRedirects, onRefreshRedirects, importStory,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [rows, setRows] = useState(importStory?.rows || []);
  const [errors, setErrors] = useState(importStory?.errors || []);
  const [status, setStatus] = useState('pristine');
  const [messages, setMessages] = useState(importStory?.messages || { general: [], failed: [] });
  const [option, setOption] = useState('add');

  const buttonRef = useRef();

  const uploadFile = async (e) => {
    setStatus('loading');

    const errors = [];

    const params = {
      redirects: rows,
    };

    if (option === 'replace') {
      params.replace = true;
    }

    if (option === 'deleteAll') {
      params.deleteAll = true;
    }

    const response = await importRedirects(appId, params);

    const {
      deleted, created, updated, failed,
    } = response?.data;

    if (created.count > 0 || updated.count > 0 || deleted.count > 0) {
      // redirects updated, set deploy as pending
      dispatch(importUpdatesRedirects(appId));
    }

    if (created.count > 0 || updated.count > 0) {
      try {
        await fetchRedirects(appId);
      } catch (e) {
        errors.push('Unable to fetch redirects.');
      }
    }

    const messages = {
      general: [`${rows.length} redirect${rows.length > 1 ? 's' : ''} submitted.`],
      failed: [],
    };

    if (deleted.count > 0) {
      messages.general.push(`${deleted.count} redirect${deleted.count > 1 ? 's' : ''} deleted.`);
    }

    if (created.count > 0) {
      messages.general.push(`${created.count} redirect${created.count > 1 ? 's' : ''} created.`);
    }

    if (updated.count > 0) {
      messages.general.push(`${updated.count} redirect${updated.count > 1 ? 's' : ''} updated.`);
    }

    if (failed.count > 0) {
      messages.failed.push(`${failed.count} redirect${failed.count > 1 ? 's' : ''} failed to import.`);
      const importErrors = failed.data.map((item) => {
        return `Row ${item.row} [${item.matchIdentifier}]: ${item.errors.join(' ')}`;
      });
      errors.push(...importErrors);
    }

    setErrors(errors);
    setMessages(messages);
    setStatus('pristine');

    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.removeFile(e);
      }
      setStatus('pristine');
    }, 3000);
  };

  const validateFields = (fields) => {
    const validFields = Object.keys(template);

    const invalidFields = [];

    fields.forEach((field) => {
      if (!validFields.includes(field)) {
        invalidFields.push(field);
      }
    });

    return invalidFields;
  };

  const handleOnFileLoad = (rows) => {
    if (rows.length === 0 || !rows[0]?.data) {
      return setErrors(['No data found in CSV.']);
    }

    const fields = Object.keys(rows[0].data);
    const invalidFields = validateFields(fields);

    if (invalidFields.length > 0) {
      return setErrors([`Invalid CSV fields: ${invalidFields.join(', ')}.`]);
    }

    const data = rows.map((row) => {
      return row.data;
    });

    setRows(data);
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
    setErrors([]);
    setMessages([]);
  };

  const handleOnRemoveFile = () => {
    setRows([]);
  };

  const handleRemoveFile = (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setRows([]);
    setErrors([]);
    setMessages([]);
    setStatus('pristine');
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setOpen(true)}
        label="Import From CSV"
      />
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle>
          <Box
            direction="row"
            justify="space-between"
          >
            Import Redirects
            <IconButton
              size="small"
              onClick={handleClose}
              css={{
                color: 'inherit !important',
                position: 'absolute',
                right: 3,
                top: 3,
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box gap="small" direction="column" padding="medium" margin={{ bottom: 'medium' }}>
            <Box margin={{ bottom: 'small' }}>
              <Typography variant="body2" color="textSecondary">
                <strong>IMPORTANT:</strong> Redirects import can not be undone. <strong>Be sure to export redirects before importing</strong> so that you have a backup.
              </Typography>
            </Box>
            <Grid container spacing={4}> {/* Row */}
              <Grid item sm={3}>
                {/* steps and description */}
                <Box direction="column">
                  <Typography variant="caption" color="textSecondary">Step 1</Typography>
                  <Typography variant="subtitle1" color="textPrimary">Download Template</Typography>
                </Box>
              </Grid>

              <Grid item sm={9}>
                <Box direction="row" padding={{ top: 'xxsmall' }}>
                  <Typography component="span">Download our&nbsp;
                    <TextLink
                      color="textSecondary"
                      onClick={() => fileDownload(csv, 'redirects-template.csv')}
                    >
                      Redirects CSV Template
                    </TextLink>
                    &nbsp;then simply add your redirects to the csv.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={4}> {/* Row */}
              <Grid item sm={3}>
                <Box direction="column">
                  <Typography variant="caption" color="textSecondary">Step 2</Typography>
                  <Typography variant="subtitle1" color="textPrimary">Upload your CSV</Typography>
                </Box>
              </Grid>
              <Grid item sm={9}>
                <Box padding={{ top: 'xxsmall' }}>
                  <CSVReader
                    ref={buttonRef}
                    onRemoveFile={handleOnRemoveFile}
                    onFileLoad={handleOnFileLoad}
                    config={{
                      header: true,
                      skipEmptyLines: 'greedy',
                    }}
                    progressBarColor={theme.palette.primary.main}
                  >
                    {({ file }) => (
                      <Box>
                        <div>
                          <Button
                            onClick={handleOpenDialog}
                            disabled={rows.length > 0}
                          >
                            Select CSV file
                          </Button>
                        </div>
                        {
                          file && (
                            <Box direction="row" align="center">
                              <Typography component="span">{file.name}</Typography>
                              <IconButton
                                onClick={handleRemoveFile}
                              >
                                <Close />
                              </IconButton>
                            </Box>
                          )
                        }
                      </Box>
                    )}
                  </CSVReader>
                  <Box>
                    <Box margin={{ top: 'small', bottom: 'xsmall' }}>
                      <RadioGroup
                        value={option}
                        row
                        onChange={(event) => setOption(event.target.value)}
                      >
                        <FormControlLabel labelPlacement="end" value="add" control={<Radio />} label="Add new" />
                        <FormControlLabel labelPlacement="end" value="replace" control={<Radio />} label="Update existing / add new" />
                        <FormControlLabel labelPlacement="end" value="deleteAll" control={<Radio />} label="Delete existing, then add new" />
                      </RadioGroup>
                    </Box>
                    <Box direction="row" justify="flex-end">
                      <Button
                        variant="contained"
                        disabled={rows.length === 0}
                        onClick={uploadFile}
                        status={status}
                      >
                        Upload
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {
              (messages?.general?.length > 0 || messages?.failed?.length > 0)
              && (
                <Box margin={{ top: 'small' }} align="center">
                  {
                    messages.general.map((message, i) => (
                      <Box key={`${message}-${i}`} direction="row" gap="small" margin={{ bottom: 'xsmall' }} align="center">
                        <Typography>{message}</Typography>
                      </Box>
                    ))
                  }
                  {
                    messages.failed.map((message, i) => (
                      <Box key={`${message}-${i}`} direction="row" gap="small" margin={{ bottom: 'xsmall' }} align="center">
                        <WarningIcon fontSize="small" color="error" css={{ marginRight: 5 }} />
                        <Typography>{message}</Typography>
                      </Box>
                    ))
                  }
                </Box>
              )
            }
            {
              errors.length > 0
              && (
                <Box margin={{ top: 'xsmall' }} direction="column">
                  <div>
                    <Typography color="error" gutterBottom>ERRORS:</Typography>
                  </div>
                  {
                    errors.map((error, i) => (
                      <div key={`${error}-${i}`}>
                        <Typography color="error" gutterBottom>{error}</Typography>
                      </div>
                    ))
                  }
                </Box>
              )
            }
            {/* hidden until doc is available */}
            {/* <Box margin={{ top: 'xsmall', bottom: 'xsmall' }} direction="row" align="center">
              <InfoIcon fontSize="small" color="primary" css={{ marginRight: 4 }} />
              <Typography
                component={LinkButton}
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="link to forthcoming article"
              >
                How do I import redirects?
              </Typography>
            </Box> */}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Import.propTypes = {
  appId: number,
  fetchRedirects: func,
  importRedirects: func,
  importStory: object,
  onRefreshRedirects: func,
  open: bool,
  setOpen: func,
};

export default Import;
