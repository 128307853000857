import React, { Component } from 'react';
import {
  object, func, number, oneOfType, string,
} from 'prop-types';
import ArrowLeft from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import {
  IconButton,
} from '@material-ui/core';

import logger from 'shared/3rdparty/logger';
import Loading from 'shared/styleguide/atoms/Loading';
import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';

import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

export default class AddTag extends Component {
  static propTypes = {
    addTag: func,
    appId: oneOfType([number, string]),
    appTags: object,
    createTag: func,
    getAllTags: func,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      tags: [],
      appliedTags: [],
      status: 'loading',
    };

    this.deduped = false;
    this.setTags();
  }

  componentDidUpdate() {
    const { appTags } = this.props;
    if (
      // Make sure all tags are loaded
      (!this.deduped)
      && (this.state.tags.length > 0)
      && (appTags.status === 'success')
    ) {
      const currentTags = appTags.data.map((tag) => tag.tagId);
      // eslint-disable-next-line react/no-access-state-in-setstate
      const tags = this.state.tags.filter((tag) => !currentTags.includes(tag.id));

      this.deduped = true;
      this.setState({ tags, appliedTags: currentTags });
    }

    if (
      (appTags.status === 'success')
      && (appTags.data.length < this.state.appliedTags.length)
    ) {
      const currentTags = appTags.data.map((tag) => tag.tagId);
      const tg = this.state.appliedTags.find((t) => !currentTags.includes(t));
      const missingTag = this.allTags.find((t) => t.id === tg);

      this.setState((state) => ({ tags: [...state.tags, missingTag], appliedTags: currentTags }));
    }
  }

  setTags = () => {
    const {
      getAllTags,
    } = this.props;
    Promise.all([getAllTags('app'), getAllTags('config')])
      .then(([appResponse, configResponse]) => {
        const allTags = [...appResponse.data.data, ...configResponse.data.data];
        this.allTags = allTags;
        this.setState({ status: 'ready', tags: allTags });
      }).catch((err) => {
        logger.error(err);
      });
  };

  handleCreateAndAddTag = (e) => {
    const { createTag } = this.props;
    e.preventDefault();
    this.setState({ status: 'loading', filter: '' });

    createTag('app', this.state.filter)
      .then(async (response) => {
        await this.props.addTag('app', response.data.id, { appId: this.props.appId });
        this.setState({ status: 'ready', filter: '' });
      }).catch((err) => {
        logger.error(err);
      });
  };

  handleAddTag = (tag) => (e) => {
    e.preventDefault();

    this.props.addTag('app', tag.id, { appId: this.props.appId })
      .then(() => {
        this.setState((state) => ({ tags: state.tags.filter((t) => t.id !== tag.id) }));
      }).catch((err) => {
        logger.error(err);
      });
  };

  render() {
    const { tags, filter, status } = this.state;
    return (
      <div>
        <div>
          <Box direction="row" align="center" margin={{ bottom: 'xsmall' }}>
            <TextField
              placeholder="Add Tag"
              name="Add Tag"
              id="add-tag"
              margin="none"
              value={filter}
              onChange={(e) => this.setState({ filter: e.target.value })}
            />
            <div>
              <IconButton
                size="small"
                onClick={this.handleCreateAndAddTag}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Box>
        </div>
        <div>
          {
            status === 'loading'
              ? <Loading />
              : tags.map((tag) => (
                <GhostTag
                  key={tag.id}
                  onClick={this.handleAddTag(tag)}
                  component="span"
                >
                  <Box direction="row" align="center">
                    <ArrowLeft css={{ marginLeft: -10 }} color="action" />
                    <span css={{ paddingRight: 5 }}>{tag.tag}</span>
                  </Box>
                </GhostTag>
              ))
          }
        </div>
      </div>
    );
  }
}
