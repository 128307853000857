/* eslint react/no-array-index-key: "off" */

import React, { Fragment, useState } from 'react';
import { array } from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export const KeyValView = ({ data }) => {
  const [spacing, setSpacing] = useState('initial');
  const index = data.findIndex((o) => o.label === 'instructions');
  const rest = [...data];
  const instructions = index > -1 ? rest.splice(index, 1)[0] : null;

  return (
    <Fragment>
      { instructions && (
        <div css={{ whiteSpace: spacing }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">White space</FormLabel>
            <RadioGroup row aria-label="position" name="position" value={spacing} onChange={(e) => setSpacing(e.target.value)}>
              <FormControlLabel
                value="initial"
                control={<Radio color="primary" />}
                label="Initial"
                labelPlacement="left"
              />
              <FormControlLabel
                value="break-spaces"
                control={<Radio color="primary" />}
                label="Break Spaces"
                labelPlacement="left"
              />
              <FormControlLabel
                value="pre"
                control={<Radio color="primary" />}
                label="Pre"
                labelPlacement="left"
              />
            </RadioGroup>
          </FormControl>
          <Typography gutterBottom variant="body1" color="textSecondary">
            <strong>Instructions:</strong>&nbsp;
            {instructions.value}
          </Typography>
        </div>
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((row, i) => {
              return (
                <TableRow key={`item-${i}`}>
                  <TableCell>
                    <Typography color="textSecondary">
                      <strong>
                        {row.label}
                      </strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textPrimary">
                      {row.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </Fragment>
  );
};

KeyValView.propTypes = {
  data: array.isRequired,
};

export default KeyValView;
