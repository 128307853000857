import colors from 'shared/styleguide/theme';

export const jssStyles = {

  pagelyLogo: {
    height: 60,
  },

  subHeader: {
    paddingBottom: 40,
    fontSize: 18,
    fontWeight: 300,
    color: colors.blue10,
  },
  formRoot: {
  },
  forgotPasswordLink: {
    cursor: 'pointer',
  },
};
