import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import SunIcon from 'shared/styleguide/Icons/SVGs/Sun';
import MoonIcon from 'shared/styleguide/Icons/SVGs/Moon';
import { useTheme } from './ThemeContext';

const ThemeToggle = withStyles((theme) => ({
  switchBase: {
    'background': theme.palette.common.black,
    'fill': theme.palette.common.white,
    'padding': 2,
    'top': 11,
    'left': 11,
    '&:hover': {
      'background': theme.palette.grey[500],
      'fill': theme.palette.common.white,
    },
    '&$checked': {
      'left': 'auto',
      'transform': 'translateX(120%)',
      'background': theme.palette.common.white,
      'box-shadow': `${theme.palette.grey[500]} 1px 1px 6px 0px`,
      'fill': theme.palette.warning.main,
      '&:hover': {
        'background': theme.palette.common.white,
      },
    },
  },
  checked: {},
}))(({ classes, value, ...props }) => {
  return (
    <Switch
      classes={classes}
      checked={!value}
      {...props}
    />
  );
});

export default function ToggleTheme() {
  const { toggle: handleSeTheme, dark } = useTheme();
  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <ThemeToggle
            style={{ padding: 2 }}
            checkedIcon={<SunIcon />}
            icon={<MoonIcon />}
            onChange={handleSeTheme}
            value={dark}
          />
        )}
      />
    </FormGroup>
  );
}
