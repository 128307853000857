import React from 'react';
import { connect } from 'react-redux';
import LockIcon from '@material-ui/icons/Lock';
import { Typography } from '@material-ui/core';
import Card from 'shared/styleguide/atoms/Card/Card';
import Checkbox from 'shared/styleguide/atoms/Switches/Checkbox';
import Box from 'shared/styleguide/atoms/Box';
import { roles } from 'shared/modules/permissions/user/actions';
import { updateAccount } from 'shared/modules/account/redux/actions';
import InfoText from 'shared/styleguide/molecules/InfoText';
import type { Account } from 'shared/modules/account/types';

interface Props {
  isPagelyAdmin: boolean;
  permissions: Record<string, any>[];
  account: Account;
  sso: { status: string; userPool: Record<string, any> };
  updateAccount: (accountID: number, update: Record<string, any>, reduxFormId?: any) => void;
}

export const MFA: React.FC<Props> = (props) => {
  const {
    isPagelyAdmin, permissions, account, sso,
  } = props;

  const role = isPagelyAdmin ? roles.PRIMARY : permissions[account.id].role;

  if (!(props.isPagelyAdmin || role === roles.PRIMARY)) {
    return null;
  }

  const canUseMfa = account.mfaEnabled && !(sso.status === 'active');

  return (
    <Box as={Card}>
      <Typography variant="h3" gutterBottom>Multi-Factor Authentication (MFA)</Typography>
      <Box>
        <Box>
          <Box>
            <Checkbox
              data-testid="require-collaborators-to-have-mfa-enabled"
              disabled={!canUseMfa}
              defaultChecked={account.mfaSecondaryEnforced}
              onCheck={(e: React.ChangeEvent, value: boolean) => props.updateAccount(account.id, {
                mfaSecondaryEnforced: value,
              })}
              label="Require Collaborators to have MFA enabled"
            />
          </Box>
          <Box>
            {
              !canUseMfa && (
                <Box align="flex-start">
                  <InfoText
                    noMargin
                    to={`/account/${account.id}/settings/security`}
                  >
                    Account must have <strong>MFA enabled</strong> before being required
                  </InfoText>
                  {
                    sso.status === 'active' && (
                      <InfoText
                        noMargin
                      >
                        MFA may not be used in conjunction with SSO
                      </InfoText>
                    )
                  }
                </Box>
              )
            }
          </Box>
          {
            account.mfaSecondaryEnforced && (
              <Box>
                <span>
                  An indicator (<LockIcon color="error" style={{ verticalAlign: 'bottom' }} />) will
                  appear for collaborators on the account switcher dropdown
                </span>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: Record<string, any>) => ({
  account: state.account,
  isPagelyAdmin: state.user.user.adminAccount,
  permissions: state.permissions.directory,
  sso: state.sso,
});

const mapDispatchToProps = {
  updateAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(MFA);
