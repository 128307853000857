import React from 'react';
import PropTypes, {
  func, number, bool, node,
} from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

const steps = ['Select Domain', 'Required', 'Optional', 'Review', 'Download'];

const CSRStepper = ({ step }) => (
  <Stepper activeStep={step} alternativeLabel classes={makeStyles({ root: { padding: 0 } })()}>
    {
      steps.map((stepContent, index) => {
        return (
          <Step completed={step > index} key={index}>
            <StepLabel>{stepContent}</StepLabel>
          </Step>
        );
      })
    }
  </Stepper>
);

CSRStepper.propTypes = {
  step: PropTypes.number,
};

export const CSRActions = ({
  onClick, step, disabled, actions,
}) => {
  return (
    <DialogActions disableSpacing classes={makeStyles({ root: { justifyContent: 'center' } })()}>
      <div style={{ width: '100%' }}>
        <CSRStepper step={step} />
        <Box margin={{ top: 'small' }} direction="row" justify="space-between">
          {
            actions || [
              <Button
                key="1"
                disabled={step === 0}
                variant="outlined"
                className="pull-left"
                label="Back"
                color="default"
                onClick={onClick}
              />,
              <Button
                key="2"
                disabled={disabled}
                variant="contained"
                className="pull-right"
                type="submit"
                label="Continue"
              />,
            ]
          }
        </Box>
      </div>
    </DialogActions>
  );
};

CSRActions.propTypes = {
  actions: node,
  disabled: bool,
  onClick: func,
  step: number,
};

export default CSRStepper;
