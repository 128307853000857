import { useRef, useCallback } from 'react';

import { debounce } from 'lodash';

// debounce custom hook https://stackoverflow.com/questions/59183495/cant-get-lodash-debounce-to-work-properly-executed-multiple-times-reac
const useDebouncedCallback = (callback, delay, opts) => {
  const callbackRef = useRef();
  callbackRef.current = callback;
  return useCallback(debounce(
    (...args) => callbackRef.current(...args),
    delay,
    opts,
  ), []);
};

export default useDebouncedCallback;
