import React, { Fragment } from 'react';
import { object } from 'prop-types';

import { Grid, Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import TextField from 'shared/styleguide/atoms/Input/TextField';
import Select from 'shared/styleguide/atoms/Select/Select';

import { getCurrentOption } from '../utils';
import { inputs } from '../constants';

const UrlParts = ({
  values, form,
}) => {
  return (
    <Fragment>
      {
        Object.entries(inputs)
          .filter(([property, _]) => property !== 'query')
          .map(([property, propertyValues]) => {
            return (
              <Box key={property} padding="xsmall">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={1}>
                    <Typography variant="body1">
                      <strong>{propertyValues.label}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box margin={{ left: 'small' }}>
                      {
                        inputs[property].valueInput === 'select'
                          ? (
                            <Box>
                              <Box>
                                <Typography variant="caption" color="textSecondary">scheme</Typography>
                              </Box>
                              <Select
                                key={`from-${property}-value`}
                                id={`from-${property}-value`}
                                name={`from-${property}-value`}
                                isClearable
                                onChange={(e) => form.changeUrlParams('from', property, 'value', e?.value)}
                                value={getCurrentOption(inputs[property].valueInputOptions, values.from[property]?.value)}
                                options={inputs[property].valueInputOptions}
                                disabled={form.status === 'loading'}
                                placeholder={propertyValues.fromPlaceholder}
                              />
                            </Box>
                          ) : (
                            <TextField
                              variant="outlined"
                              value={values.from[property]?.value}
                              onChange={(e) => form.changeUrlParams('from', property, 'value', e.target.value)}
                              placeholder={propertyValues.label}
                              autoComplete="off"
                              margin="dense"
                              fullWidth
                              multiline
                              disabled={form.status === 'loading'}
                            />
                          )
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    {
                      inputs[property].compareOptions
                      && (
                        <Box>
                          <Box>
                            <Typography variant="caption" color="textSecondary">method</Typography>
                          </Box>
                          <Select
                            key={`from-${property}-compare`}
                            id={`from-${property}-compare`}
                            name={`from-${property}-compare`}
                            isClearable={false}
                            onChange={(e) => form.changeUrlParams('from', property, 'compare', e?.value)}
                            value={getCurrentOption(inputs[property].compareOptions, values.from[property]?.compare, values.from[property]?.value ? 'equals' : null)}
                            options={inputs[property].compareOptions}
                            disabled={form.status === 'loading'}
                          />
                        </Box>
                      )
                    }
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={4}>
                    {
                      inputs[property].valueInput === 'select'
                        ? (
                          <Box>
                            <Box>
                              <Typography variant="caption" color="textSecondary">scheme</Typography>
                            </Box>
                            <Select
                              key={`to-${property}-value`}
                              id={`to-${property}-value`}
                              name={`to-${property}-value`}
                              isClearable
                              onChange={(e) => form.changeUrlParams('to', property, 'value', e?.value)}
                              value={getCurrentOption(inputs[property].valueInputOptions, values.to[property]?.value)}
                              options={inputs[property].valueInputOptions}
                              disabled={form.status === 'loading'}
                              placeholder={propertyValues.toPlaceholder}
                            />
                          </Box>
                        ) : (
                          <TextField
                            variant="outlined"
                            value={values.to[property]?.value}
                            onChange={(e) => form.changeUrlParams('to', property, 'value', e.target.value)}
                            placeholder={propertyValues.placeholder}
                            autoComplete="off"
                            margin="dense"
                            fullWidth
                            multiline
                            disabled={form.status === 'loading'}
                          />
                        )
                    }
                  </Grid>
                </Grid>
              </Box>
            );
          })
      }
    </Fragment>

  );
};

UrlParts.propTypes = {
  form: object,
  values: object,
};

export default UrlParts;
