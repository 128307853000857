import colors from 'shared/styleguide/theme';

export const inline = {
  dialog: {
    borderRadius: 8,
    backgroundColor: colors.light20,
    overflowY: 'visible',
  },
  closeIcon: {
    color: colors.light10,
    position: 'absolute',
    right: 0,
    top: 6,
  },
  modal: {
    padding: 50,
    textAlign: 'center',
  },
};
