import React, { Fragment } from 'react';
import { object } from 'prop-types';
import Select from 'react-select';

import {
  Typography, FormControl, FormControlLabel, Radio, RadioGroup, Switch, useTheme,
} from '@material-ui/core';

import { themeProps } from 'shared/styleguide/atoms/Select/Select';
import { ErrorText } from 'shared/styleguide/typography';
import Box from 'shared/styleguide/atoms/Box';

import { labeledUSLocale } from '../constants';

const Geo = ({
  values,
  form,
}) => {
  const theme = useTheme();
  const geoErrors = [form.errors?.geoInverse, form.errors?.geoCountries].filter((e) => e);

  return (
    <Fragment>
      <Box direction="row" align="center" padding={{ top: 'xsmall', bottom: 'xsmall' }}>
        <Typography variant="h5" color="initial"><strong>Geo</strong></Typography>
        <Switch
          checked={Boolean(values.geo)}
          onChange={(e) => form.handleChange('geo', e.target.checked)}
        />
      </Box>
      <Box direction="column" gap="small">
        <Box direction="column">
          <Box margin={{ bottom: 'small' }}>
            <Typography gutterBottom variant="caption" color="textSecondary">Geo rules will only apply to content, assets will not be affected.</Typography>
          </Box>
          <Typography gutterBottom variant="caption" color="textSecondary">Choose Access</Typography>
          <FormControl>
            {
              /**
               * This is a little confusing
               * default, true, is to allow traffic, and
               * inverse (false) is to block it
               */
            }
            <RadioGroup
              value={typeof values?.geoInverse === 'boolean' ? values.geoInverse.toString() : ''}
              onChange={(e) => {
                // deleting all items in react select changes value to null, so fall back to empty array
                const geoCountries = values?.geoCountries ? [...values.geoCountries] : [];
                const usIndex = geoCountries.findIndex(((c) => c?.id === 'US'));

                if (e.target.value === 'true' && usIndex === -1) {
                  // have to also add US to allowed list
                  geoCountries.push(labeledUSLocale);
                }

                if (e.target.value === 'false' && usIndex !== -1) {
                  // have to also remove US to block list
                  geoCountries.splice(usIndex, 1);
                }

                form.handleChangeGeoInverse(e.target.value === 'true', geoCountries);
              }}
            >
              <FormControlLabel labelPlacement="end" value="true" control={<Radio disabled={Boolean(!values.geo)} />} label="Only allow traffic from" />
              <FormControlLabel labelPlacement="end" value="false" control={<Radio disabled={Boolean(!values.geo)} />} label="Block traffic from" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box direction="column">
          <Box padding={{ bottom: 'xsmall' }}>
            <Typography gutterBottom variant="caption" color="textSecondary">Choose Locales</Typography>
          </Box>
          <Box css={{ minWidth: 280 }}>
            <Select
              {...themeProps(theme)}
              isMulti
              closeMenuOnSelect={false}
              cacheOptions
              defaultOptions
              options={form.locales}
              value={values.geoCountries}
              onChange={(value) => form.handleChange('geoCountries', value)}
              isDisabled={!values.geo}
            />
            {
              values.geoInverse && (
                <Typography variant="caption" color="textSecondary" css={{ paddingTop: 4 }}>
                  To prevent blocking US traffic, US will be added to allowed traffic list
                </Typography>
              )
            }
          </Box>
        </Box>
      </Box>
      {
        !!geoErrors.length && (
          <Box align="center" margin={{ top: 'small' }}>
            <ErrorText>{geoErrors.join(', ')}</ErrorText>
          </Box>
        )
      }
    </Fragment>
  );
};

Geo.propTypes = {
  form: object,
  values: object,
};

export default Geo;
