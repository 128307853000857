import React, { useState, Fragment, useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import { Paper, Typography } from '@material-ui/core';

import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

import { twoFactorDisableRequest } from 'shared/modules/account/redux/actions';

import MFAFlow from './MFAFlow';

const MFAEnabled = () => {
  return (
    <Box>
      <Box direction="row" justify="space-between" align="center" padding={{ top: 'small', bottom: 'small' }}>
        <Box direction="row" gap="xsmall">
          <Box>
            <Typography variant="h4" gutterBottom>Status:</Typography>
          </Box>
          <Box>
            <GhostTag color="green">Enabled</GhostTag>
          </Box>
        </Box>
      </Box>

      <Typography variant="body2" color="textSecondary">For additional security, you will be prompted to enter a unique code when you log in.</Typography>
    </Box>
  );
};

MFAEnabled.propTypes = {

};

const MFADisabled = () => {
  const [showSetup, setShowSetup] = useState(false);
  return (
    <Fragment>
      <Box>
        <Box direction="row" justify="space-between" align="center" padding={{ top: 'small', bottom: 'small' }}>
          <Box direction="row" gap="xsmall">
            <Box>
              <Typography variant="h4" gutterBottom>Status:</Typography>
            </Box>
            <Box>
              <GhostTag color="red">Disabled</GhostTag>
            </Box>
          </Box>
          <Box>
            <Button
              onClick={() => setShowSetup(true)}
            >
              Enable MFA
            </Button>
          </Box>
        </Box>

        <Typography variant="body2" color="textSecondary">Multi-Factor Authentication is another security layer that can be enabled to prevent unauthorized access to your user account. While enabled, you will need to enter an additional code when logging in and changing sensitive information.</Typography>
      </Box>
      {
        showSetup && (
          <MFAFlow />
        )
      }
    </Fragment>
  );
};

export const MFA = ({ account, twoFactorDisableRequest }) => {
  const [mfaEnabled, setMfaEnabled] = useState(account.mfa);

  useEffect(() => {
    setMfaEnabled(account.mfa);
  }, [account.mfa]);

  return (
    <Box direction="column" gap="medium">
      <Paper>
        <Box padding="medium">
          <Typography variant="h4" gutterBottom>Multi-Factor Authentication (MFA)</Typography>
          {
            mfaEnabled ? (
              <MFAEnabled requestDisable={twoFactorDisableRequest} account={account} />
            ) : (
              <MFADisabled />
            )
          }
        </Box>
      </Paper>
    </Box>
  );
};

MFA.propTypes = {
  account: object.isRequired,
  twoFactorDisableRequest: func.isRequired,
};

export default connect(
  (state) => ({ account: state.user.user }),
  {
    twoFactorDisableRequest,
  },
)(MFA);
