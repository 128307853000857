import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Pagination from '@material-ui/lab/Pagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import Box from 'shared/styleguide/atoms/Box';
import ListRow from 'shared/styleguide/molecules/CollapsibleTable/ListRow';
import { PCDN_REGEX } from 'shared/modules/dns/constants';

export const DomainsTable = ({
  paginate,
  pageLength,
  HeadRow,
  ListRows,
  Detail,
}) => {
  const [page, setPage] = useState(1);
  const [index, setIndex] = useState(0);

  const rows = paginate ? ListRows.slice(index, index + pageLength) : ListRows;

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          {/* custom heading */}
          {HeadRow}
          <TableBody>
            {
              rows
                .map((row, i) => {
                  const showDetail = !row.data.fqdn?.match(PCDN_REGEX);
                  return (
                    <ListRow key={`listrow-${i}`} row={row} Detail={showDetail && Detail} />
                  );
                })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        paginate && ListRows.length > pageLength && (
          <Box margin={{ top: 'small' }} justify="center" direction="row">
            <Pagination
              shape="rounded"
              count={Math.ceil(ListRows.length / pageLength)}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                setIndex(((value - 1) * pageLength));
              }}
            />
          </Box>
        )
      }
    </>
  );
};

DomainsTable.propTypes = {
  Detail: PropTypes.func,
  HeadRow: PropTypes.node,
  ListRows: PropTypes.array,
  pageLength: PropTypes.number,
  paginate: PropTypes.bool,
};

export default DomainsTable;
