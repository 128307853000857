/* eslint-disable react/prop-types */
import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';

const PagelyConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.action.disabled,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

// eslint-disable-next-line react/display-name
export default ({
  startingStep = 0,
  activeStep,
  steps,
  showLabels,
  ...props
}) => {
  return (
    <Stepper
      activeStep={activeStep - startingStep}
      alternativeLabel
      connector={<PagelyConnector />}
      {...props}
    >
      {
        steps.map((stepContent, index) => {
          return (
            <Step completed={(activeStep - startingStep) > index} key={index}>
              <StepLabel>{ showLabels ? stepContent : ''}</StepLabel>
            </Step>
          );
        })
      }
    </Stepper>
  );
};
