import React, { useState } from 'react';

import Pagination from '@material-ui/lab/Pagination';
import Box from 'shared/styleguide/atoms/Box';
import IntegrationsItem from './IntegrationsItem';

const PAGE_LENGTH = 5;
interface IntegrationsListProps {
  integrations: any[];
}

const IntegrationsList = ({ integrations }: IntegrationsListProps): JSX.Element => {
  const [page, setPage] = useState(1);
  const index = (page * PAGE_LENGTH) - PAGE_LENGTH;

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      {
        integrations
          .slice(index, index + PAGE_LENGTH)
          .map((integration) => {
            return (
              <IntegrationsItem key={integration.id} {...integration} />
            );
          })
      }
      {
        integrations.length > PAGE_LENGTH && ( // use updated redirects to calculate
          <Box margin={{ top: 'small' }} justify="center" direction="row">
            <Pagination
              shape="rounded"
              count={Math.ceil(integrations.length / PAGE_LENGTH)}
              page={page}
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </Box>
        )
      }
    </div>
  );
};

export default IntegrationsList;
