import React from 'react';
import { object, bool } from 'prop-types';
import moment from 'moment';

import { Typography, Paper } from '@material-ui/core';

// styleguide
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import { PlanLink } from 'shared/modules/billing/Link';
import SupportLink from 'shared/modules/support/Link';
import LoadingDots from 'shared/styleguide/atoms/Loading/Dots';

const SubscriptionOverview = ({
  hasSubscription, isPagelyAdmin, subscriptions,
}) => {
  if (hasSubscription && (['loading', 'pristine'].includes(subscriptions.status) || !subscriptions.status)) {
    return (
      <Paper>
        <Box padding="medium">
          <LoadingDots />
        </Box>
      </Paper>
    );
  }

  if (!hasSubscription && isPagelyAdmin) {
    return (
      <Paper>
        <Box padding="small">
          <Empty>
            <Typography variant="body1">
              There is no active subscription on this account
            </Typography>

            <Typography variant="body1" paragraph>
              Admins can add a new subscription under Plan Management
            </Typography>
            <Box direction="row" justify="flex-end" padding={{ top: 'small' }}>
              <PlanLink>
                Manage plan
              </PlanLink>
            </Box>
          </Empty>
        </Box>
      </Paper>
    );
  }

  if (!hasSubscription) {
    return (
      <Paper>
        <Box padding="small">
          <Typography variant="h4" gutterBottom>No Active subscriptions</Typography>

          <Typography variant="body1">
            We are transitioning to a new billing system and are unable to process plan changes through Atomic.
            Please contact our sales team or <SupportLink>support</SupportLink> regarding plan changes.
          </Typography>
          <Box direction="row" align="center" justify="flex-end" margin={{ top: 'small' }}>
            <PlanLink>Manage plan</PlanLink>
          </Box>
        </Box>
      </Paper>
    );
  } else {
    return (
      <Box
        as={Paper}
        flex={1}
        direction="column"
        gap="small"
        padding="small"
      >
        {
          subscriptions.data.map((subscription) => {
            return (
              <Box key={subscription.planId}>
                <Box direction="row" align="flex-end" gap="xsmall">
                  <Typography component="span" variant="h3" gutterBottom>Plan: {subscription.plan}</Typography>
                </Box>
                {
                  Object.keys(subscription.addons).length > 0 && (
                    <Box margin={{ bottom: 'small' }}>
                      {
                        Object.values(subscription.addons).map((addon) => (
                          <Typography
                            key={addon.name}
                            variant="body1"
                            color="textSecondary"
                            css={{ lineHeight: '2' }}
                          >
                            {[addon.quantity, addon?.unit?.includes('iB') ? addon.unit : null, addon.name].join(' ')}
                          </Typography>
                        ))
                      }
                    </Box>
                  )
                }
                <Typography gutterBottom variant="body2" color="textSecondary">
                  Renews {subscription.billingFrequency}. Next renewal will be on <Typography color="textPrimary" component="span" variant="inherit">{moment(subscription.nextBillingAt).format('MMMM Do YYYY')}</Typography>.
                </Typography>

              </Box>
            );
          })
        }
        <Box direction="row" align="center" justify="flex-end" margin={{ top: 'small' }}>
          <PlanLink>Manage plan</PlanLink>
        </Box>
      </Box>
    );
  }
};

SubscriptionOverview.propTypes = {
  hasSubscription: bool,
  isPagelyAdmin: bool,
  subscriptions: object,
};

export default (SubscriptionOverview);
