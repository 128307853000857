import React from 'react';

import Box from 'shared/styleguide/atoms/Box';

import OperatorColumn from './OperatorColumn';

interface Props {
  rule: Record<string, any>;
  children: any;
}

const OperatorWrapper = ({ rule, children }: Props) => {
  return (
    <Box direction="row" align="stretch" gap="small" flex={1}>
      {
        rule.records.length > 1 && <OperatorColumn operator={rule.operator} />
      }
      <Box flex={1}>
        {children}
      </Box>
    </Box>
  );
};

export default OperatorWrapper;
