export const ACCOUNT_SSH_KEY_CREATE_REQUEST = 'ACCOUNT_SSH_KEY_CREATE_REQUEST';
export const ACCOUNT_SSH_KEY_CREATE_SUCCESS = 'ACCOUNT_SSH_KEY_CREATE_SUCCESS';
export const ACCOUNT_SSH_KEY_CREATE_FAILURE = 'ACCOUNT_SSH_KEY_CREATE_FAILURE';

export const ACCOUNT_SSH_KEY_DELETE_REQUEST = 'ACCOUNT_SSH_KEY_DELETE_REQUEST';
export const ACCOUNT_SSH_KEY_DELETE_SUCCESS = 'ACCOUNT_SSH_KEY_DELETE_SUCCESS';
export const ACCOUNT_SSH_KEY_DELETE_FAILURE = 'ACCOUNT_SSH_KEY_DELETE_FAILURE';
export const ACCOUNT_SSH_KEY_RESET = 'ACCOUNT_SSH_KEY_RESET';

export const ACCOUNT_SSH_KEYS_REQUEST = 'ACCOUNT_SSH_KEYS_REQUEST';
export const ACCOUNT_SSH_KEYS_SUCCESS = 'ACCOUNT_SSH_KEYS_SUCCESS';
export const ACCOUNT_SSH_KEYS_FAILURE = 'ACCOUNT_SSH_KEYS_FAILURE';
export const ACCOUNT_SSH_KEYS_RESET = 'ACCOUNT_SSH_KEYS_RESET';
