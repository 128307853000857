export const GET_WEBHOOK_DEPLOYMENT_LOG_REQUEST = 'APP_INTEGRATION_GET_WEBHOOK_DEPLOYMENT_LOG_REQUEST';
export const GET_WEBHOOK_DEPLOYMENT_LOG_SUCCESS = 'APP_INTEGRATION_GET_WEBHOOK_DEPLOYMENT_LOG_SUCCESS';
export const GET_WEBHOOK_DEPLOYMENT_LOG_FAILURE = 'APP_INTEGRATION_GET_WEBHOOK_DEPLOYMENT_LOG_FAILURE';

export const GET_JOB_DEPLOYMENT_LOG_REQUEST = 'APP_INTEGRATION_GET_JOB_DEPLOYMENT_LOG_REQUEST';
export const GET_JOB_DEPLOYMENT_LOG_SUCCESS = 'APP_INTEGRATION_GET_JOB_DEPLOYMENT_LOG_SUCCESS';
export const GET_JOB_DEPLOYMENT_LOG_FAILURE = 'APP_INTEGRATION_GET_JOB_DEPLOYMENT_LOG_FAILURE';

export const GET_INTEGRATION_DEPLOYMENT_LOG_REQUEST = 'APP_INTEGRATION_GET_INTEGRATION_DEPLOYMENT_LOG_REQUEST';
export const GET_INTEGRATION_DEPLOYMENT_LOG_SUCCESS = 'APP_INTEGRATION_GET_INTEGRATION_DEPLOYMENT_LOG_SUCCESS';
export const GET_INTEGRATION_DEPLOYMENT_LOG_FAILURE = 'APP_INTEGRATION_GET_INTEGRATION_DEPLOYMENT_LOG_FAILURE';
