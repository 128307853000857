import React from 'react';
import {
  number, array, func, string,
} from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { withRouter } from 'react-router-dom';
import { useTheme } from '@material-ui/core';

const DiskLine = ({
  data, graphColors, link, toolTip, maxValue, unit,
}) => {
  const theme = useTheme();
  const gridColor = theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700];
  return (
    <ResponsiveLine
      data={data}
      theme={{
        'textColor': theme.palette.text.secondary,
        'axis': {
          'domain': {
            'line': {
              'stroke': gridColor,
              'strokeWidth': 1,
            },
          },
          'ticks': {
            'line': {
              'stroke': gridColor,
              'strokeWidth': 1,
            },
          },
        },
        'grid': {
          'line': {
            'stroke': gridColor,
            'strokeWidth': 1,
          },
        },
      }}
      margin={{
        top: 10, right: 15, bottom: 30, left: 55,
      }}
      yScale={{
        type: 'linear', min: 0, max: (Math.ceil(maxValue / 10) * 10) + 10, stacked: false, reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      xScale={{
        type: 'time',
        format: '%m/%d',
        precision: 'day',
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        format: '%m/%d',
        tickValues: 'every 3 days',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: unit || 'GiB',
        legendOffset: -40,
        legendPosition: 'middle',
        tickValues: 5,
      }}
      colors={graphColors}
      enableArea={false}
      enablePoints={false}
      enableGridX={false}
      enableGridY
      lineWidth={3}
      gridXValues={5}
      gridYValues={5}
      pointSize={5}
      pointColor="#f8f8f8"
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel="y"
      pointLabelYOffset={-12}
      enableCrosshair={false}
      useMesh
      onClick={link}
      tooltip={toolTip}
    />
  );
};

DiskLine.propTypes = {
  data: array,
  graphColors: array,
  link: func,
  maxValue: number,
  toolTip: func,
  unit: string,
};

export default withRouter(DiskLine);
