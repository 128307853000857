export const BILLING_PLANS_REQUEST = 'CBBILLING_PLANS_REQUEST';
export const BILLING_PLANS_SUCCESS = 'CBBILLING_PLANS_SUCCESS';
export const BILLING_PLANS_FAILURE = 'CBBILLING_PLANS_FAILURE';

export const GET_CUSTOMER_REQUEST = 'CBBILLING_GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'CBBILLING_GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'CBBILLING_GET_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMER_REQUEST = 'CBBILLING_UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'CBBILLING_UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'CBBILLING_UPDATE_CUSTOMER_FAILURE';

export const GET_SUBSCRIPTION_REQUEST = 'CBBILLING_GET_SUBSCRIPTION_REQUEST';
export const GET_SUBSCRIPTION_SUCCESS = 'CBBILLING_GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILURE = 'CBBILLING_GET_SUBSCRIPTION_FAILURE';

export const GET_SUBSCRIPTIONS_REQUEST = 'CBBILLING_GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_SUCCESS = 'CBBILLING_GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAILURE = 'CBBILLING_GET_SUBSCRIPTIONS_FAILURE';

export const GET_INVOICES_REQUEST = 'BILLING_GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'BILLING_GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'BILLING_GET_INVOICES_FAILURE';

export const UPDATE_PAYMENT_REQUEST = 'CBBILLING_UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'CBBILLING_UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'CBBILLING_UPDATE_PAYMENT_FAILURE';

export const RESET = 'CBBILLING_RESET';
